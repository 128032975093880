import React from "react";

interface IProps {
  width?: number;
  height?: number;
  viewBox?: string;
  fill?: string;
  color?: string;
}

const CafexIcon: React.FC<IProps> = (props) => {
  const { width = 35, height = 30, viewBox = "13 4 15 30", fill = "none" } = props;

  return (
    <>
      <svg
        width={width}
        height={height}
        viewBox={viewBox}
        fill={fill}
        xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_26891_1489)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26.4537 27.397C21.8178 32.0343 14.3015 32.0343 9.66565 27.397C5.02974 22.7594 5.02974 15.2406 9.66565 10.6031C14.3015 5.96562 21.8178 5.96562 26.4537 10.6031L34.8478 19L26.4537 27.397ZM13.8627 23.1984C11.5447 20.8797 11.5447 17.1203 13.8627 14.8016C16.1806 12.4828 19.9387 12.4828 22.2567 14.8016L26.4537 19L22.2567 23.1984C19.9387 25.5173 16.1806 25.5173 13.8627 23.1984Z"
            fill="url(#paint0_linear_26891_1489)"
          />
          <path
            d="M26.4534 19L30.6504 23.1984L26.4534 27.397L22.2563 23.1984L26.4534 19Z"
            fill="url(#paint1_linear_26891_1489)"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_26891_1489"
            x1="6.18873"
            y1="30.875"
            x2="36.1969"
            y2="8.97339"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#0065ff" />
            <stop offset="1" stopColor="#00a3ff" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_26891_1489"
            x1="28.5518"
            y1="21.0992"
            x2="24.3534"
            y2="25.2962"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#0755cc" />
            <stop offset="1" stopColor="#006fff" stopOpacity="0" />
          </linearGradient>
          <clipPath id="clip0_26891_1489">
            <rect width="39" height="38" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default CafexIcon;
