import React, { useMemo } from "react";

// MUI

// Icons
import BlockIcon from "@material-ui/icons/Block";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import WarningIcon from "@material-ui/icons/Warning";

// Utils
import { DatasetStatuses } from "src/pages/private/ProjectsModule/utils";

// Open API
import { DataSourceDto } from "@rapidcanvas/rc-api-core";

// Types
import { NodeData } from "src/types";
import { VectorDBIcon } from "icons/NewUX/VectorDBIcon";

type Props = {
  data: NodeData;
  connector: DataSourceDto | any;
};

const statusIconMap: { [key: string]: React.ReactNode } = {
  [DatasetStatuses.UnBuilt]: <BlockIcon />,
  [DatasetStatuses.Pending]: <VectorDBIcon width={20} height={20} viewBox="0 0 16 16" />,
  [DatasetStatuses.Empty]: <WarningIcon />,
  [DatasetStatuses.Skipped]: <SkipNextIcon />,
  [DatasetStatuses.Built]: <VectorDBIcon width={20} height={20} viewBox="0 0 16 16" />
};

const statusNameMap: { [key: string]: string } = {
  [DatasetStatuses.UnBuilt]: "Unbuilt",
  [DatasetStatuses.Pending]: "Pending...",
  [DatasetStatuses.Empty]: "Empty",
  [DatasetStatuses.Skipped]: "Skipped",
  [DatasetStatuses.Built]: "Built"
};

const useAttributes = (props: Props) => {
  const { data, connector } = props || {};

  const icon = useMemo(() => {
    return (
      statusIconMap[data?.status] || <VectorDBIcon width={20} height={20} viewBox="0 0 16 16" />
    );
  }, [data, connector]);

  const status = useMemo(() => {
    return statusNameMap[data?.status] || "";
  }, [data?.status]);

  return { icon, status };
};

export default useAttributes;
