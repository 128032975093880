import React from "react";
import {
  Grid,
  TextField,
  IconButton,
  Tooltip,
  makeStyles,
  Theme,
  Checkbox,
  FormControlLabel
} from "@material-ui/core";
import { Close, SendSharp } from "@material-ui/icons";
import { isEmpty } from "lodash";
import ReadOnlyCodeEditor from "src/pages/Projects/common/ReadOnlyCodeEditor";
import CopilotModelSelection from "./CopilotModelSelection";

interface IStyleProps {
  hasCode: boolean;
  showFullCode: boolean;
}
const useStyles = makeStyles<Theme, IStyleProps>({
  inputField: {
    width: "100%"
  },
  footer: {
    width: "100%",
    textAlign: "left"
  },
  editorGrid: {
    height: "130px",
    padding: "4px",
    borderColor: "#0000003b",
    borderStyle: "solid",
    borderWidth: "1px 1px 0px 1px",
    borderRadius: "4px 4px 0px 0px",
    position: "relative"
  },
  root: {
    borderTopRightRadius: ({ hasCode }) => (hasCode ? "0px" : "4px"),
    borderTopLeftRadius: ({ hasCode }) => (hasCode ? "0px" : "4px")
  },
  inputRoot: {
    borderTopRightRadius: ({ hasCode }) => (hasCode ? "0px" : "4px"),
    borderTopLeftRadius: ({ hasCode }) => (hasCode ? "0px" : "4px"),
    paddingBottom: "52px",
    whiteSpace: "pre-line"
  },
  footerField: {
    position: "relative"
  },
  optionsBox: {
    position: "absolute",
    bottom: 0,
    margin: 0,
    left: 0,
    display: "flex",
    flexDirection: "row",
    wrap: "nowrap",
    padding: "0px 14px"
  },
  checkbox: {
    visibility: ({ showFullCode }) => (showFullCode ? "visible" : "hidden"),
    flexGrow: 1
  },
  checkboxLabel: {
    fontSize: "0.875rem"
  },

  closeIcon: {
    height: 16
  },
  iconWrap: {
    position: "absolute",
    top: "12px",
    right: "10px",
    zIndex: 1,
    background: "#fff"
  },
  generateBarRightActions: {
    gap: "12px",
    width: "auto",
    alignItems: "center"
  }
});

interface IProps {
  query: string;
  isGenerating: boolean;
  selectedCode: string;
  highlightedCode: string;
  isDisabled: boolean;
  handleSend: () => void;
  setQuery: (value: React.SetStateAction<string>) => void;
  inputRef: React.MutableRefObject<HTMLDivElement | null>;
  useFullCode: boolean;
  setUseFullCode: React.Dispatch<React.SetStateAction<boolean>>;
  onClearCode: () => void;
  setGptModel: React.Dispatch<React.SetStateAction<string>>;
  gptModel: string;
}
export const CopilotFooter: React.FC<IProps> = ({
  highlightedCode,
  selectedCode,
  query,
  inputRef,
  isGenerating,
  isDisabled,
  handleSend,
  setQuery,
  useFullCode,
  onClearCode,
  setUseFullCode,
  gptModel,
  setGptModel
}) => {
  const classes = useStyles({
    hasCode: !isEmpty(highlightedCode),
    showFullCode: isEmpty(selectedCode)
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setQuery(e.target.value);
  };

  const handleSubmit = () => {
    handleSend();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if ((event.metaKey || event.ctrlKey) && event.key === "Enter") {
      // For Command + Enter
      event.preventDefault();
      setQuery(query + "\n");
    } else if (isDisabled) {
      return;
    } else if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSubmit();
    }
  };

  return (
    <Grid container direction="column" wrap="nowrap" className={classes.footer}>
      {highlightedCode && (
        <Grid container className={classes.editorGrid}>
          <Grid item className={classes.iconWrap}>
            <IconButton size="small" onClick={onClearCode}>
              <Close className={classes.closeIcon} />
            </IconButton>
          </Grid>
          <ReadOnlyCodeEditor
            key="copilotSelectedCodeEditor"
            code={highlightedCode}
            options={{ lineNumbers: "off" }}
          />
        </Grid>
      )}
      <Grid container direction="column" wrap="nowrap" className={classes.footerField}>
        <TextField
          className={classes.inputField}
          variant="outlined"
          placeholder="Ask Code Assistant"
          value={query}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          data-testid="ai-copilot-generate-code-bar"
          classes={{
            root: classes.root
          }}
          InputProps={{
            inputRef,
            multiline: true,
            classes: {
              root: classes.inputRoot
            },
            minRows: 3,
            maxRows: 3
          }}
        />
        <Grid container direction="row" wrap="nowrap" className={classes.optionsBox}>
          <FormControlLabel
            control={
              <Checkbox
                checked={useFullCode}
                onChange={(e) => setUseFullCode(e.target.checked)}
                color="primary"
                size="small"
              />
            }
            classes={{
              label: classes.checkboxLabel
            }}
            className={classes.checkbox}
            label="Use Full Code"
          />

          <Grid item container direction="row" className={classes.generateBarRightActions}>
            <CopilotModelSelection setGptModel={setGptModel} gptModel={gptModel} />
            <Tooltip
              arrow
              title={
                isGenerating
                  ? "Wait until existing query is complete"
                  : !query?.trim()
                    ? "Add Query to enable"
                    : ""
              }
              placement="top">
              {/* div has to enclose button to display tooltip when disabled */}
              <div>
                <IconButton
                  color="primary"
                  size="small"
                  data-testid="ai-copilot-generate-code-btn"
                  disabled={!query?.trim() || isGenerating}
                  onClick={handleSubmit}>
                  <SendSharp fontSize="small" />
                </IconButton>
              </div>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(CopilotFooter);
