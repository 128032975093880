import React from "react";
import { CircularProgress, Grid, makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  root: {
    padding: ({ noPadding, padding }: { padding?: string | number; noPadding?: boolean }) =>
      padding ? padding : noPadding ? 0 : 40
  },
  absoluteCentered: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)"
  }
}));

interface OwnProps {
  isAbsolute?: boolean;
  className?: string;
  size?: number;
  noPadding?: boolean;
  padding?: string | number;
}

const Spinner = ({ isAbsolute, className, noPadding, size, padding }: OwnProps) => {
  const classes = useStyles({ noPadding, padding });

  return (
    <Grid
      container
      justifyContent="center"
      className={clsx(classes.root, className, {
        [classes.absoluteCentered]: isAbsolute
      })}>
      <CircularProgress color="secondary" size={size} />
    </Grid>
  );
};

Spinner.defaultProps = {
  isAbsolute: false
};

export default Spinner;
