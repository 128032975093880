import { getAPIWithRethrow } from "../Apis.service";

export const getArtifactSignedUrl = async (path: string) =>
  await getAPIWithRethrow(`/v2/artifacts/signed-url`, { params: { path } });

export const downloadArtifactSignedUrl = async (path: string) =>
  await getAPIWithRethrow(`/v2/artifacts/download-signed-url`, { params: { path } });

export const getModelsByName = async (name: string) =>
  await getAPIWithRethrow(`/v2/prediction-services/model-details/${name}`);

export const downloadModelSignedUrl = async (name: string, path: string) =>
  await getAPIWithRethrow(
    `/v2/prediction-services/models/download-signed-url/${name}?path=${path}`
  );
