// @REFACTOR: The file needs to be refactored.
import React, { useMemo } from "react";
import { Box, Button, Card, CardContent, Divider, Tooltip } from "@material-ui/core";
import { generatePath, useNavigate } from "react-router-dom";
import { TrashIcon } from "icons/NewUX/TrashIcon";

import { RenderText } from "components/custom";

import { useScenariosStore, useDrawerStore } from "../../../stores/zustand/stores";
import { sideComponentSetter } from "../../../stores/zustand/stores.selectors";

import DeleteNodeModal, {
  psMessage
} from "../../../pages/ViewData/DeleteNodeModal/DeleteNodeModal";

import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";

import NewThemeWrapper from "src/styles/NewThemeWrapper";
import useEntities from "hooks/api/entities/useEntities";
import useEntityDetails from "hooks/api/entities/useEntityDetails";
import { checkIfDefaultScenario } from "src/pages/private/ProjectsModule/utils";
import { useStyles as useDrawerStyles } from "./components/styling";
import { WebPaths } from "src/routing/routes";
import _ from "lodash";
import MoreOptions from "src/layout/NavBars/components/SubTopNavBar/MoreOptions";
import CommonLoader from "src/components/CommonLoader";

import VectorDBReadonly from "src/pages/private/ProjectsModule/pages/Dag/components/Nodes/VectorDB/VectorDBReadonly";
import ApiConnectorIcon from "icons/NewUX/ApiConnectorIcon";
import VectorDetails from "./components/VectorDetails";

const VectorModalV2 = ({ data }: $TSFixMe) => {
  const { cardMargin, drawerContent, content, datasetItemGap, flex } = useDrawerStyles();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);

  const navigate = useNavigate();
  const [scenarios] = useScenariosStore((state) => [state.scenarios]);
  const setSideComponent = useDrawerStore(sideComponentSetter);
  const isDefaultScenario = useMemo(
    () => checkIfDefaultScenario(null, scenarios, data?.scenarioId),
    [data?.scenarioId, scenarios]
  );

  const { data: entity, isLoading } = useEntities({
    id: data.itemId,
    scenarioId: data?.scenarioId,
    ...(!!data?.isJobCanvas ? { jobRunId: data?.jobProps?.jobRunId } : {}),
    options: { refetchOnMount: true }
  });

  const { data: entityDetails, isLoading: isEntityDetailsLoading } = useEntityDetails(
    data.itemId,
    data.scenarioId,
    data.jobProps?.jobRunId,
    {
      enabled: !!data.itemId,
      refetchOnMount: true
    }
  );
  const handleAddApiConnectorRecipe = () => {
    const url = `${WebPaths.APIConnectorRecipeContainer}?entity=${data.itemId}`;
    navigate(
      generatePath(url, {
        projectId: data.projectId,
        scenarioId: data?.scenarioId
      })
    );
  };

  const onDelete = () => {
    setIsDeleteModalOpen(true);
  };

  const isUnbuilt = useMemo(() => {
    return !entity?.status || !["BUILT"].includes(entity?.status);
  }, [entity?.status]);

  const onClose = () => {
    setSideComponent({
      sideComponent: null,
      sideComponentProps: null
    });
  };

  // << ENDS - Confirm update text input

  return (
    <NewThemeWrapper>
      <Box className={drawerContent}>
        {entity && (
          <DeleteNodeModal
            open={isDeleteModalOpen}
            onClose={() => {
              setIsDeleteModalOpen(false);
            }}
            deleteNote={
              <div>
                <span>Note: </span>
                {psMessage}
              </div>
            }
            projectId={data?.projectId}
            nodeId={data.itemId}
            nodeName={data.label}
            nodeType="vector_db"
            onAfterSubmit={onClose}
          />
        )}

        <SubTopNavBarWrapper
          variant="drawer"
          onDrawerClose={onClose}
          subTopNavBarLeftSection={{
            component: (
              <>
                <VectorDBReadonly data={data} />
                <span style={{ maxWidth: 200 }}>
                  <RenderText
                    color="textSecondary"
                    isOverflowTooltip
                    data-testid="sidebar-entity-name">
                    {data?.label}
                  </RenderText>
                </span>
              </>
            )
          }}
        />

        <div className={content} style={{ marginTop: 44 }}>
          {isLoading || isEntityDetailsLoading ? (
            <CommonLoader />
          ) : (
            <>
              <Card className={cardMargin}>
                <CardContent>
                  <div className={flex}>
                    <div style={{ display: "flex", gap: "10px" }} className={datasetItemGap}>
                      {!data?.isJobCanvas && isDefaultScenario && (
                        <Tooltip title={isUnbuilt ? "Not allowed on unbuilt nodes" : ""}>
                          <span>
                            <Button
                              variant="outlined"
                              size="small"
                              disabled={isUnbuilt}
                              color="primary"
                              startIcon={<ApiConnectorIcon />}
                              onClick={handleAddApiConnectorRecipe}>
                              Code
                            </Button>
                          </span>
                        </Tooltip>
                      )}
                    </div>
                    {!data?.isJobCanvas && (
                      <div style={{ display: "flex", gap: "10px" }} className="moreIcon">
                        <Divider orientation="vertical" />

                        <MoreOptions
                          showTitle
                          options={[
                            ...(isDefaultScenario
                              ? [
                                  {
                                    label: "Delete",
                                    icon: <TrashIcon viewBox="0 0 20 22" />,
                                    action: onDelete,
                                    testId: "sidebar-entity-delete-icon"
                                  }
                                ]
                              : [])
                          ]}
                        />
                      </div>
                    )}
                  </div>
                </CardContent>
              </Card>

              <Card className={cardMargin}>
                <CardContent>
                  {entityDetails ? (
                    <VectorDetails entityDetails={entityDetails} />
                  ) : (
                    <CommonLoader />
                  )}
                </CardContent>
              </Card>
            </>
          )}
        </div>
      </Box>
    </NewThemeWrapper>
  );
};

export default VectorModalV2;
