import React, { useMemo } from "react";
import _, { map, orderBy, toLower } from "lodash";
import { Alert } from "@material-ui/lab";
import { Card, FormControl, Grid, InputLabel, TextField, Tooltip } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";

import PreviewImageSelector from "src/pages/Projects/ProjectSettings/PreviewImageSelector";
import SelectEnvironment from "src/pages/private/ProjectsModule/pages/PredictionService/components/SelectEnvironment";
import {
  CreateDataAppRequestDtoDataAppTypeEnum,
  DataappAskAIConfigInputTypeEnum,
  DataAppDtoDataAppTypeEnum,
  PublishAppTemplateRequestDtoAppTypeEnum
} from "@rapidcanvas/rc-api-core";
import { IRecipes } from "./CreateDataApp";
import { Select } from "src/components";
import { dataAppConfigFields } from "./CreateDataAppForm";
import { offlineImages, cdnImages } from "src/pages/DataApps/common/DataAppImages";
import { DescriptionInfo } from "src/pages/private/ProjectsModule/utils";
import DataAppEnvironment from "./DataAppEnvironment";
import DataAppUploadZip from "./DataAppUploadZip";
import DataAppType from "./DataAppSelectType";
import PythonVersion from "./PythonVersion";
import SelectAskAIInputType from "./SelectAskAIInputType";
import DataAppSelectJob from "./DataAppSelectJob";
import DataAppMetaData from "./DataAppMetaData";
import DataAppModelControls from "./DataAppModelControls";

interface IProps {
  values: any;
  dataAppType: "auto-create" | "import-zip";
  type: CreateDataAppRequestDtoDataAppTypeEnum;
  recipes: IRecipes[];
  disabledAddDataAppActionMessage?: string;
  errorMsgs: { [x: string]: string | undefined };
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  recipeLoading: boolean;
  onInputValChange: (
    e: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>
  ) => void;
  projectId?: string;
}

const CreateDataAppInputs: React.FC<IProps> = (props) => {
  const {
    recipes,
    values,
    type,
    errorMsgs,
    projectId,
    dataAppType,
    recipeLoading,
    disabledAddDataAppActionMessage,
    onChange,
    onInputValChange
  } = props;

  const images = !!process.env.ENVIRONMENT ? map(cdnImages, (image) => `/${image}`) : offlineImages;

  const allRecipes = useMemo(
    () =>
      orderBy(recipes, (item) => toLower(item.displayName)).map((recipe) => ({
        label: recipe.displayName as string,
        value: recipe.id as string,
        disabled: !recipe.allowed,
        helpText: recipe.helpText
      })),
    [recipes]
  );

  const handleSetImageBase64 = (value: string) => {
    onChange({
      target: { value, name: dataAppConfigFields.imageBase64 }
    } as React.ChangeEvent<HTMLInputElement>);
  };

  const handleRecipeChange = (e: any) => {
    onChange({
      target: { value: e.target.value, name: dataAppConfigFields.recipeId }
    } as React.ChangeEvent<HTMLInputElement>);
  };

  const handlePythonVersionChange = (
    event: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>
  ) => {
    onChange({
      target: { value: event.target.value, name: dataAppConfigFields.pythonVersion }
    } as React.ChangeEvent<HTMLInputElement>);
  };

  const handleEnvChange = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    onChange({
      target: { value: e.target.value, name: dataAppConfigFields.customEnvId }
    } as React.ChangeEvent<HTMLInputElement>);
  };

  const handleZipEnvChange = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    onChange({
      target: { value: e.target.value, name: dataAppConfigFields.zipEnv }
    } as React.ChangeEvent<HTMLInputElement>);
  };

  const handleEnvZipChange = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    onChange({
      target: { value: e.target.value, name: dataAppConfigFields.zipFile }
    } as React.ChangeEvent<HTMLInputElement>);
  };

  return (
    <Card style={{ padding: "16px", minHeight: "100%" }}>
      <Grid container spacing={3}>
        <Grid
          item
          xs={7}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            width: "calc(100% - 200px)"
          }}>
          {dataAppType === "auto-create" && (
            <SelectAskAIInputType
              disabledAddDataAppActionMessage={disabledAddDataAppActionMessage}
              value={_.get(values, dataAppConfigFields.inputType)}
              onChange={onInputValChange}
            />
          )}
          <Grid container direction="column">
            <TextField
              fullWidth
              multiline
              maxRows={3}
              minRows={3}
              name={dataAppConfigFields.description}
              size="small"
              data-testid="dataAppDescription"
              value={_.get(values, dataAppConfigFields.description)}
              label="DataApp Description"
              variant="outlined"
              error={!!_.get(errorMsgs, dataAppConfigFields.description)}
              helperText={_.get(errorMsgs, dataAppConfigFields.description)}
              onChange={onChange}
            />
            <span
              style={{
                fontSize: "12px",
                fontWeight: 400,
                fontStyle: "italic",
                opacity: 0.7
              }}>
              {DescriptionInfo}
            </span>
          </Grid>
          {type !== CreateDataAppRequestDtoDataAppTypeEnum.Custom &&
          dataAppType === "auto-create" ? (
            _.get(values, dataAppConfigFields.inputType) !==
            DataappAskAIConfigInputTypeEnum.PredictionService ? (
              <SelectEnvironment
                tooltipInfoMsg="The chosen environment will be used for queries in AskAI within this DataApp"
                shouldSetDefaultValue
                projectId={projectId}
                name={dataAppConfigFields.customEnvId}
                label="Environment"
                value={_.get(values, dataAppConfigFields.customEnvId, "")}
                onChange={handleEnvChange}
              />
            ) : (
              <Alert severity="info">
                Queries in AskAI within this DataApp utilize the environment linked to the
                Prediction Service being used
              </Alert>
            )
          ) : (
            <></>
          )}
          {dataAppType === "auto-create" && (
            <>
              {_.get(values, dataAppConfigFields.inputType) ===
                DataappAskAIConfigInputTypeEnum.JobCanvas && (
                <DataAppSelectJob
                  style={{
                    width: "175%",
                    border: "1px solid #c7c7c7",
                    padding: "16px",
                    borderRadius: "4px"
                  }}
                  projectId={projectId!}
                  value={_.get(values, dataAppConfigFields.projectRunId)}
                  onChange={onChange}
                />
              )}
            </>
          )}
          {dataAppType === "import-zip" && (
            <Grid
              style={{
                display: "flex",
                gap: "10px",
                flexDirection: "column"
              }}>
              <DataAppEnvironment
                value={_.get(values, dataAppConfigFields.zipEnv)}
                onChange={handleZipEnvChange}
              />
              <DataAppUploadZip
                value={_.get(values, dataAppConfigFields.zipFile)}
                onChange={handleEnvZipChange}
              />
              <DataAppType value={_.get(values, dataAppConfigFields.appType)} onChange={onChange} />
              {_.get(values, dataAppConfigFields.appType) ===
                PublishAppTemplateRequestDtoAppTypeEnum.Streamlit && (
                <PythonVersion
                  value={_.get(values, dataAppConfigFields.pythonVersion)}
                  onChange={handlePythonVersionChange}
                />
              )}
              <DataAppMetaData
                value={_.get(values, dataAppConfigFields.metadata)}
                onChange={onChange}
              />
            </Grid>
          )}
          {type === CreateDataAppRequestDtoDataAppTypeEnum.RapidModel && (
            <Grid style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel shrink htmlFor={dataAppConfigFields.recipeId}>
                  Recipe Name *
                </InputLabel>
                <Select
                  required
                  values={allRecipes}
                  isLoading={recipeLoading}
                  data-testid="dataAppRecipeName"
                  name={dataAppConfigFields.recipeId}
                  variant="outlined"
                  placeholder="Rapid Model Recipe not available"
                  label="Recipe Name"
                  value={_.get(values, dataAppConfigFields.recipeId, "")}
                  onChange={handleRecipeChange}
                />
              </FormControl>
              <Tooltip title="Only rapid model recipes that have been successfully built—such as Binary Classification, Regression, and Multi-Class Classification—are applicable for this type of DataApp">
                <InfoOutlined fontSize="small" />
              </Tooltip>
            </Grid>
          )}
          {_.get(values, dataAppConfigFields.inputType) ===
            DataAppDtoDataAppTypeEnum.RapidModel && (
            <DataAppModelControls
              value={_.get(values, dataAppConfigFields.systemMessage) ?? ""}
              onChange={onChange}
            />
          )}
        </Grid>
        <Grid item xs={5}>
          <PreviewImageSelector
            noText
            images={images}
            prefix="DataApp"
            onChange={(img: string) => handleSetImageBase64(img)}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

export default CreateDataAppInputs;
