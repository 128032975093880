import React, { useEffect, useState } from "react";
import { useGetRecipe } from "src/hooks/api";
import ReadOnlyCodeEditor from "../../../common/ReadOnlyCodeEditor";

interface IProps {
  recipeId: string;
  scenarioId?: string;
}

const ReadOnlyCodeRecipe: React.FC<IProps> = ({ recipeId, scenarioId }) => {
  const { data } = useGetRecipe({
    recipeId,
    scenarioId,
    options: {
      cacheTime: Infinity
    }
  });

  const [code, setCode] = useState<string>();

  useEffect(() => {
    if (data) {
      setCode(data.templates?.map((template: any) => template.code)?.join("\r\n\n"));
    }
  }, [data]);

  return <ReadOnlyCodeEditor key={`code-recipe-code-editor-${recipeId}`} code={code} />;
};

export default ReadOnlyCodeRecipe;
