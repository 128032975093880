import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  useNavigate,
  useParams,
  useLocation,
  generatePath,
  useSearchParams
} from "react-router-dom";
import { Grid, makeStyles } from "@material-ui/core";
import { includes, map } from "lodash";

import {
  useGetEntityFeatures,
  useGetInputEntities,
  useGetScenario,
  useCreateRecipe
} from "src/hooks/api";
import { useQuery } from "src/hooks";
import { useNodeMultiSelection } from "projectsModule/hooks";
import { CodeRecipeHeader } from "./CodeRecipeHeader/CodeRecipeHeader";
import CodeRecipeTabContainer from "./CodeRecipeTabContainer/CodeRecipeTabContainer";
import { useCodeRecipeContext } from "./CodeRecipeContext/useCodeRecipeContext";
import {
  type Scenario,
  type EntityFeaturesResponse,
  EntityTypeEnum,
  ArtifactMini,
  ModelMini
} from "src/types";
import AddEntitiesDialog from "../AddStandardRecipe/AddEntitiesDialog/AddEntitiesDialog";
import { checkIfDefaultScenario, NodeTypes } from "src/pages/private/ProjectsModule/utils";
import { useScenariosStore } from "stores/zustand/stores";
import NewThemeWrapper from "src/styles/NewThemeWrapper";
import { WebPaths } from "src/routing/routes";

import CommonLoader from "src/components/CommonLoader";
import { PredictionServiceDto } from "@rapidcanvas/rc-api-core";
import { CodeRecipeLeftDrawer } from "./CodeRecipeLeftDrawer/CodeRecipeLeftDrawer";

const useStyles = makeStyles({
  gridContainer: {
    gap: "18px",
    padding: "16px 0px 16px 16px",
    display: "flex",
    flexDirection: "column",
    flexShrink: 0,
    flexGrow: 0,
    maxHeight: "100%",
    width: "calc(100% - 22px)",
    height: "inherit"
  },

  codeRecipeContainer: {
    // New UX change
    // The value 94px is the height of both the NavBars (TopNavBar 50px + SubTopNavBar 44px).
    height: "calc(100vh - 94px)",
    backgroundColor: "#f5f5f5"
  },
  codeRecipeBodyContainer: {
    flex: 1,
    // New UX change
    // The value 94px is the height of both the NavBars (TopNavBar 50px + SubTopNavBar 44px).
    height: "calc(100vh - 94px)",
    flexWrap: "nowrap",
    background: "#f5f5f5",
    overflow: "hidden"
  },

  leftSection: {
    height: "100%"
  },
  inputContainer: {
    height: "100%"
  },
  outputContainer: {
    height: "100%",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      "-webkit-appearance": "none",
      width: "6px",
      height: "6px"
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "6px",
      backgroundColor: "#7C7C7C",
      "-webkit-box-shadow": "0 0 1px rgba(255, 255, 255, 0.5)"
    }
  },
  codeContainer: {
    borderLeft: "1px solid #CECECE",
    flexGrow: 1,
    overflow: "auto"
  }
});

const CodeRecipeContainer = (props: $TSFixMe) => {
  const { entityIds, modelsIds, artifactsIds } = props;
  const [isExpanded, setIsExpanded] = React.useState(true);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const isCodeTab = searchParams.get("isCodeTab");
  const location = useLocation();
  const isJobPath = useMemo(() => /jobs/.test(location.pathname), [location.pathname]);
  const [value, setValue] = React.useState<string>(isJobPath || isCodeTab ? "codeEditor" : "askAI");
  const [selectedPredictionService, setSelectedPredictionService] = useState<
    PredictionServiceDto[]
  >([]);

  useEffect(() => {
    if (isCodeTab) {
      setValue("codeEditor");
    }
  }, [isCodeTab]);

  useEffect(() => {
    if (value === "codeEditor") {
      const newParams = new URLSearchParams(searchParams);
      newParams.set("isCodeTab", "true");
      setSearchParams(newParams, { replace: true });
    } else {
      const newParams = new URLSearchParams(searchParams);
      newParams.delete("isCodeTab");
      setSearchParams(newParams, { replace: true });
    }
  }, [value]);

  const classes = useStyles({ isExpanded });

  const { projectId, scenarioId, jobRunId } = useParams();
  const queryParameters = useQuery();

  const { getSelectedNodesByType } = useNodeMultiSelection();

  const [showAddEntitiesDialog, setShowAddEntitiesDialog] = useState<boolean>(false);
  const scenarios = useScenariosStore((state) => state.scenarios);
  const {
    inputDatasets,
    recipe,
    onUpdateRecipe,
    scenarioData,
    setScenarioData,
    setEntityFeaturesMap,
    editorValue,
    setInputDatasets,
    setAllEntities,
    handleInputDatasetsChange,
    isSelectedEntitiesUpdateInProgess,
    selectedArtifacts,
    selectedModels,
    setSelectedArtifacts,
    setSelectedModels
  } = useCodeRecipeContext();

  useEffect(() => {
    setShowAddEntitiesDialog(
      inputDatasets?.length === 0 && selectedArtifacts.length === 0 && selectedModels.length === 0
    );
  }, [inputDatasets]);

  const { isLoading, data: allEntities } = useGetInputEntities({
    id: projectId,
    ...(!!isJobPath ? { scenarioId, jobRunId } : {})
  });

  useEffect(() => {
    if (allEntities) {
      const data = allEntities;
      const selectedEntities = getSelectedNodesByType({ type: NodeTypes.Dataset, getByKey: "id" });
      const filteredInputDatasets = data.filter(
        (dataset) =>
          entityIds?.includes(dataset.id) ||
          queryParameters?.get("entity") === dataset.id ||
          (selectedEntities?.includes(dataset.id) &&
            dataset.entityMeta?.entityViewType?.toLowerCase() !== "chart" &&
            dataset.entityMeta?.entityViewType?.toLowerCase() !== "artifact" &&
            dataset.entityMeta?.entityViewType?.toLowerCase() !== "model")
      );
      const models = map(
        data.filter(
          (dataset) =>
            queryParameters?.get("model") === dataset.id ||
            modelsIds?.includes(dataset.id) ||
            includes(getSelectedNodesByType({ type: NodeTypes.Model, getByKey: "id" }), dataset.id)
        ),
        (model: $TSFixMe) => ({
          name: model.name,
          type: EntityTypeEnum.MODEL
        })
      );

      const artifacts = map(
        data.filter(
          (dataset) =>
            queryParameters?.get("artifact") === dataset.id ||
            artifactsIds?.includes(dataset.id) ||
            includes(
              getSelectedNodesByType({ type: NodeTypes.Artifact, getByKey: "id" }),
              dataset.id
            )
        ),
        (artifact: $TSFixMe) => ({
          name: artifact.name,
          type: EntityTypeEnum.MODEL
        })
      );

      sessionStorage.setItem("selectedEntities", "");
      setAllEntities(data);
      setInputDatasets(filteredInputDatasets);
      setSelectedArtifacts(artifacts as ArtifactMini[]);
      setSelectedModels(models as ModelMini[]);
    }
  }, [allEntities, entityIds, modelsIds, artifactsIds]);

  const { isLoading: isScenarioLoading } = useGetScenario({
    scenarioId,
    onSuccess: (data: Scenario) => {
      setScenarioData(data);
    }
  });

  const { isFetching: isEntitiesLoading } = useGetEntityFeatures({
    datasetsToFetch: inputDatasets,
    ...(!!isJobPath ? { scenarioId, jobRunId } : {}),
    onSuccess: (entityFeatures: EntityFeaturesResponse) => {
      const newFeatures = inputDatasets?.reduce(
        (acc, dataset, index) => ({
          ...acc,
          [dataset?.name]: entityFeatures[index]
        }),
        {}
      );
      setEntityFeaturesMap(newFeatures);
    }
  });

  const { isFetching: isCreatingRecipe } = useCreateRecipe({
    inputEntities: inputDatasets,
    conditions: recipe?.condition?.expression,
    recipeId: recipe?.id,
    projectId,
    code: editorValue,
    selectedArtifacts: selectedArtifacts,
    selectedModels: selectedModels,
    entity: allEntities?.find((item: $TSFixMe) => item.id === queryParameters?.get("entity")),
    newAskAIFlow: true,
    onSuccess: (response) => {
      onUpdateRecipe(response.group);

      if (projectId && scenarioId) {
        navigate(
          generatePath(WebPaths.CodeRecipeContainer, {
            projectId,
            scenarioId,
            groupId: response.group?.id
          }),
          { replace: true }
        );
      }
    }
  });

  const isDefaultScenario = useMemo(
    () => checkIfDefaultScenario(scenarioData, scenarios, scenarioId),
    [scenarioData, scenarios, scenarioId]
  );

  const openAddEntitiesDialog = useCallback(() => setShowAddEntitiesDialog(true), []);
  const onCollapse = useCallback(() => setIsExpanded((expanded) => !expanded), []);

  return isLoading || isCreatingRecipe || isEntitiesLoading || isLoading ? (
    <CommonLoader />
  ) : (
    <Grid className={classes.codeRecipeContainer}>
      {!isScenarioLoading && <CodeRecipeHeader isDefaultScenario={isDefaultScenario} />}
      <Grid container direction="row" className={classes.codeRecipeBodyContainer}>
        <CodeRecipeLeftDrawer
          isExpanded={isExpanded}
          onCollapse={onCollapse}
          isDefaultScenario={isDefaultScenario}
          selectedPredictionService={selectedPredictionService}
          setSelectedPredictionService={setSelectedPredictionService}
          handleTabChange={setValue}
          openAddEntitiesDialog={openAddEntitiesDialog}
        />
        <Grid item className={classes.codeContainer}>
          <NewThemeWrapper>
            <CodeRecipeTabContainer
              isDefaultScenario={isDefaultScenario}
              value={value}
              setValue={setValue}
              selectedPredictionService={selectedPredictionService}
              setSelectedPredictionService={setSelectedPredictionService}
            />
          </NewThemeWrapper>
        </Grid>
      </Grid>
      {showAddEntitiesDialog && (
        <AddEntitiesDialog
          showArtifacts
          showModels
          selectedArtifacts={selectedArtifacts}
          selectedModels={selectedModels}
          recipeId={recipe?.id}
          selectedInputDatasets={inputDatasets}
          allEntities={allEntities || []}
          onClose={() => {
            setShowAddEntitiesDialog(false);
          }}
          onSubmit={({ updatedInputDatasets, updatedArtifacts, updatedModels }) =>
            handleInputDatasetsChange(updatedInputDatasets, updatedArtifacts, updatedModels)
          }
          isSubmitInProgress={isSelectedEntitiesUpdateInProgess}
          noEntitiesText="If you cannot see datasets, artifacts or models here, please add it to the canvas first"
        />
      )}
    </Grid>
  );
};

export default React.memo(CodeRecipeContainer);
