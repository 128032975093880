import React from "react";

// Packages
import { FormProvider } from "react-hook-form";

// MUI
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

// Components
import Header from "./components/Header";
import ScenarioSegments from "./components/ScenarioSegments/ScenarioSegments";
import ScenarioVariables from "./components/ScenarioVariables/ScenarioVariables";

// Contexts
import ScenarioContextProvider from "./context/ScenarioContextProvider";
import { useScenarioContext } from "./context/useScenarioContext";
import CommonLoader from "src/components/CommonLoader";

const Scenario = () => {
  const { isLoading, formMethods } = useScenarioContext();

  return isLoading ? (
    <CommonLoader />
  ) : (
    <FormProvider {...formMethods}>
      <Header />

      <Box p={2}>
        <Grid container direction="column" style={{ rowGap: 16 }}>
          <ScenarioSegments />
          <ScenarioVariables />
        </Grid>
      </Box>
    </FormProvider>
  );
};

const ScenarioWrapper = () => (
  <ScenarioContextProvider>
    <Scenario />
  </ScenarioContextProvider>
);

export default ScenarioWrapper;
