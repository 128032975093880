import * as React from "react";
import { Button, Card, CardContent, CardHeader, Grid, IconButton } from "@material-ui/core";
import MuiLink from "@material-ui/core/Link";
import { useNavigate, generatePath } from "react-router-dom";

import { dateFormat } from "utils/helpers/date.helpers";
import { WebPaths } from "src/routing/routes";
import { Section } from "src/pages/ViewData/ViewDataRoutes";
import { IEntityDetails } from "hooks/api/entities/useEntityDetails";
import { useStyles } from "./styling";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";

interface IProps {
  entityDetails: IEntityDetails;
  isSourceFileImport: boolean;
  scenarioId: string;
  projectId: string;
  isTextInput?: boolean;
  fileTypeLabel?: string;
  isOutputDataset?: boolean;
  entityId: string;
  navigateToSource: () => void;
  cardMargin: string;
  isOpen?: boolean;
}
export const EntityDetails = ({
  entityDetails,
  isSourceFileImport,
  projectId,
  isTextInput = false,
  fileTypeLabel,
  isOutputDataset,
  scenarioId,
  entityId,
  navigateToSource,
  cardMargin,
  isOpen = true
}: IProps) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [expanded, setExpanded] = React.useState(isOpen);

  const handleHistoryClick = () => {
    navigate(
      generatePath(WebPaths.ViewData, {
        section: Section.source,
        scenarioId,
        projectId,
        entityId
      }),
      {
        state: {
          showAuditHistory: true
        }
      }
    );
  };

  return (
    <Card className={cardMargin}>
      <CardHeader
        title={
          <Grid container justifyContent="space-between" alignItems="center">
            <span style={{ fontSize: "14px", fontWeight: 500 }}>Details</span>
            <Grid>
              <IconButton size="small">
                {expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </IconButton>
            </Grid>
          </Grid>
        }
        onClick={() => setExpanded((prev) => !prev)}
        style={{ borderBottom: "1px solid #d3d3d3", padding: "5px 16px", cursor: "pointer" }}
      />
      {expanded && (
        <CardContent>
          <div className={classes.detailsWrapper}>
            {entityDetails?.parentTransform && (
              <div className={classes.detailItem}>
                <p className={classes.detailName} data-testid="entity-detail-build-label">
                  Build:
                </p>
                <p data-testid="entity-detail-build-value">{dateFormat(entityDetails?.built)}</p>
              </div>
            )}
            <div className={classes.detailItem}>
              <p data-testid="entity-detail-created-label" className={classes.detailName}>
                Created:
              </p>
              <p data-testid="entity-detail-created-value">{dateFormat(entityDetails?.created)}</p>
            </div>
            <div className={classes.detailItem}>
              <p data-testid="entity-detail-updated-label" className={classes.detailName}>
                Updated:
              </p>
              <p data-testid="entity-detail-updated-value">{dateFormat(entityDetails?.updated)}</p>
            </div>
            {entityDetails?.parentTransform && (
              <div className={classes.detailItem}>
                <p
                  data-testid="entity-detail-parent-transform-label"
                  className={classes.detailName}>
                  Parent Transform:
                </p>
                <p data-testid="entity-detail-parent-transform-value">
                  {entityDetails?.parentTransform ?? "-"}
                </p>
              </div>
            )}
            {!isTextInput && (
              <>
                <div className={classes.detailItem}>
                  <p data-testid="entity-detail-size-label" className={classes.detailName}>
                    Total size:
                  </p>
                  <p data-testid="entity-detail-size-value">
                    {entityDetails?.totalSize?.size === 0 && entityDetails?.totalSize?.unit === "KB"
                      ? "1 KB"
                      : `${entityDetails?.totalSize?.size ?? "-"} ${entityDetails?.totalSize?.unit ?? "-"}`}
                  </p>
                </div>
                <div className={classes.detailItem}>
                  <p data-testid="entity-detail-rows-label" className={classes.detailName}>
                    Rows:
                  </p>
                  <p data-testid="entity-detail-rows-value">{entityDetails?.rows ?? "-"}</p>
                </div>
                <div className={classes.detailItem}>
                  <p data-testid="entity-detail-columns-label" className={classes.detailName}>
                    Columns:
                  </p>
                  <p data-testid="entity-detail-columns-value">{entityDetails?.columns ?? "-"}</p>
                </div>
              </>
            )}
            {!!isTextInput && !!fileTypeLabel && (
              <div className={classes.detailItem}>
                <p data-testid="entity-detail-size-label" className={classes.detailName}>
                  {!!isOutputDataset ? "Text Output Type:" : "Text Input Type:"}
                </p>
                <p data-testid="entity-detail-size-value">{fileTypeLabel}</p>
              </div>
            )}
            {isSourceFileImport && (
              <div className={classes.detailItem}>
                <p data-testid="entity-detail-source-label" className={classes.detailName}>
                  Source:
                </p>
                <MuiLink style={{ cursor: "pointer" }} onClick={navigateToSource}>
                  <p data-testid="entity-detail-source-value">File Import</p>
                </MuiLink>
              </div>
            )}
            {!isTextInput && !isOutputDataset && (
              <div className={classes.detailItem}>
                <Button
                  size="small"
                  color="primary"
                  variant="outlined"
                  onClick={handleHistoryClick}>
                  Audit History
                </Button>
              </div>
            )}
          </div>
        </CardContent>
      )}
    </Card>
  );
};

export default EntityDetails;
