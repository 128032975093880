import React, { useMemo, useState } from "react";
import { CircularProgress, Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import { keyBy, get, filter, includes } from "lodash";
import { generatePath, Link, useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import clsx from "clsx";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import PauseIcon from "@material-ui/icons/Pause";
import EjectIcon from "@material-ui/icons/Eject";
import PlayArrow from "@material-ui/icons/PlayArrow";

import { ProjectRunDto, ProjectRunEntryDtoStatusEnum } from "@rapidcanvas/rc-api-core";

import DeleteProjectRunModal from "./DeletePredictionRunModal";
import DownloadPredictions from "./DownloadPredictions";
import AddOutputToCanvas from "./AddOutputToCanvas";
import {
  IProjectRun,
  QUERY_KEY_ALL_PROJECT_RUNS
} from "src/hooks/api/projects/useGetAllProjectRuns";
import { JobRunStatuses, JobRunTriggerTypes, JobsStatuses } from "../../Jobs/utils/Jobs.constants";
import { LinkCellWithMenu } from "src/components/Table/Cells/LinkCellWithMenu/LinkCellWithMenu.component";
import { MODELS } from "./SelectModel";
import { Model } from "hooks/api/projects/useGetAllModels";
import { IProjectReturn } from "hooks/api/projects/useRunDetails";
import { TrashIcon } from "src/assets/icons/NewUX/TrashIcon";
import { dateFormat } from "utils/helpers/date.helpers";
import {
  getFailedRecipeName,
  getPredictionJobOutputpathAndName
} from "../utils/PredictionJob.helpers";
import {
  getJobRunStatusBadge,
  getJobRunStatusTemplate,
  getJobStatusTemplate
} from "../../Jobs/utils/Jobs.helpers";
import { useStyles } from "../../Jobs/components/JobsTable/JobsTable.styles";
import { WebPaths } from "src/routing/routes";
import { PencilUnderlined } from "src/assets/icons/PencilUnderlined";
import { CellContext, ColumnDef } from "@tanstack/react-table";
import Table from "src/components/custom/TableV8/Table";
import { TData } from "src/components/custom/TableV8/typing";
import useJobActions from "projectsModule/pages/Jobs/hooks/useJobActions";

interface IProps {
  data: IProjectRun[];
  projectId: string;
  models?: Model[];
  isManual: boolean;
  onJobRunParametersViewOpen: (lastRunEntry: Record<string, any>) => void;
  onLogsOpen: ({
    runId,
    runEntryId,
    recipeId,
    recipeName,
    errorMsg,
    status
  }: {
    runId: string;
    runEntryId: string;
    recipeId: string;
    recipeName?: string;
    errorMsg: string;
    jobName: string;
    status: string;
  }) => void;
  onRunJob: (job?: ProjectRunDto | IProjectReturn) => void;
}

export interface IDeleteRecord {
  id: string;
  name: string;
}

const PredictionRunsTable: React.FC<IProps> = (props) => {
  const { data, projectId, isManual, models, onLogsOpen, onJobRunParametersViewOpen, onRunJob } =
    props;

  const [deleteRecord, setDeleteRecord] = useState<IDeleteRecord | null>(null);
  const [updatingJobIds, setUpdatingJobIds] = useState<string[]>([]);

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const classes = useStyles();

  const onJobPaused = (jobId?: string) => {
    setUpdatingJobIds((prev) => filter(prev, (id) => id !== jobId));
  };

  const onJobResumed = (jobId?: string) => {
    setUpdatingJobIds((prev) => filter(prev, (id) => id !== jobId));
  };

  const { pauseJob, resumeJob } = useJobActions({ onJobPaused, onJobResumed });

  const handleDelete = (id: string, name: string) => {
    setDeleteRecord({ id, name });
  };

  const handleSuccess = (id: string) => {
    queryClient.setQueryData(
      [QUERY_KEY_ALL_PROJECT_RUNS, projectId, isManual],
      filter(data, ({ dto }) => dto.id !== id)
    );
    setDeleteRecord(null);
  };

  const handleLogsClick = ({
    runId,
    runEntryId,
    recipeId,
    recipeName,
    jobName,
    errorMsg,
    status
  }: {
    runId: string;
    runEntryId: string;
    recipeId: string;
    recipeName?: string;
    errorMsg: string;
    jobName: string;
    status: string;
  }) => {
    onLogsOpen({ runId, runEntryId, recipeId, recipeName, errorMsg, jobName, status });
  };

  const handleEdit = (jobId: string) => {
    if (isManual) {
      navigate(generatePath(WebPaths.EditPredictionJob, { projectId, projectRunId: jobId }));
    } else {
      navigate(generatePath(`${WebPaths.JobRoutes}${WebPaths.JobId}`, { projectId, jobId }));
    }
  };

  const columns: ColumnDef<IProjectRun, any>[] = useMemo(() => {
    const keyByModels = keyBy(models, "id");

    return [
      {
        id: isManual ? "Name" : "Scheduler Name",
        accessorKey: "dto.name",
        header: isManual ? "Name" : "Scheduler Name",
        meta: { isTooltip: false },
        cell: ({ row }: CellContext<IProjectRun, any>) => {
          const { dto } = row.original;
          const { name, id, status } = dto;
          return (
            <LinkCellWithMenu
              linkText={name}
              title={name}
              menuButtons={[
                {
                  label: "EDIT",
                  icon: <PencilUnderlined />,
                  action: () => projectId && id && handleEdit(id)
                },
                ...(isManual
                  ? []
                  : [
                      ...(status === JobsStatuses.Active
                        ? [
                            {
                              label: "PAUSE",
                              icon: !!includes(updatingJobIds, id) ? (
                                <CircularProgress size={20} />
                              ) : (
                                <PauseIcon />
                              ),
                              disabled: !!includes(updatingJobIds, id),
                              tooltip: includes(updatingJobIds, id)
                                ? "Prediction scheduler pause action is in progress. Please wait."
                                : "",
                              action: () => {
                                setUpdatingJobIds((prev) => [...prev, id]);
                                pauseJob(row.original?.dto);
                              }
                            }
                          ]
                        : []),
                      ...(status === JobsStatuses.Inactive
                        ? [
                            {
                              label: "RESUME",
                              icon: !!includes(updatingJobIds, id) ? (
                                <CircularProgress size={20} />
                              ) : (
                                <EjectIcon style={{ transform: "rotate(90deg)" }} />
                              ),
                              disabled: !!includes(updatingJobIds, id),
                              tooltip: includes(updatingJobIds, id)
                                ? "Prediction scheduler resume action is in progress. Please wait."
                                : "",
                              action: () => {
                                setUpdatingJobIds((prev) => [...prev, id]);
                                resumeJob(row.original?.dto);
                              }
                            }
                          ]
                        : []),
                      {
                        label: "RUN",
                        icon: <PlayArrow />,
                        action: () => onRunJob(row?.original?.dto)
                      },
                      {
                        label: "RUN HISTORY",
                        icon: <AccessTimeIcon />,
                        action: () =>
                          navigate(
                            generatePath(`${WebPaths.JobRoutes}${WebPaths.JobRuns}`, {
                              projectId,
                              jobId: id
                            })
                          )
                      }
                    ]),
                {
                  label: "DELETE",
                  icon: <TrashIcon viewBox="0 0 20 20" />,
                  action: () => handleDelete(dto.id, dto.name)
                }
              ]}
              onClick={() => {
                if (isManual) {
                  navigate(
                    generatePath(WebPaths.EditPredictionJob, {
                      projectId,
                      projectRunId: id
                    }),
                    {
                      state: { [MODELS]: models }
                    }
                  );
                } else {
                  navigate(
                    `${generatePath(`${WebPaths.JobRoutes}${WebPaths.JobId}`, {
                      projectId,
                      jobId: id
                    })}`
                  );
                }
              }}
            />
          );
        }
      },
      {
        id: "Input Model",
        accessorKey: "dto.modelEntityId",
        header: "Input Model",

        cell: ({ row }: CellContext<IProjectRun, any>) => (
          <span>
            {row.original.dto.modelEntityName ??
              get(keyByModels, [row.original.dto.modelEntityId, "displayName"]) ??
              "-"}
          </span>
        )
      },
      {
        id: "Status",
        accessorKey: "lastRunEntry.status",
        header: "Status",
        meta: { isTooltip: false },
        cell: ({ row }: CellContext<IProjectRun, any>) => {
          if (isManual) {
            const lastRunEntry = row.original.lastRunEntry;
            if (!lastRunEntry) {
              return getJobRunStatusTemplate(JobRunStatuses.Created);
            }
            return (
              <>
                {lastRunEntry?.status
                  ? getJobRunStatusTemplate(lastRunEntry?.status ?? JobRunStatuses.Failure)
                  : ""}
              </>
            );
          } else {
            const { status } = row.original?.dto;

            return <>{status ? getJobStatusTemplate({ job: row?.original?.dto }) : ""}</>;
          }
        }
      },

      ...(!isManual
        ? [
            {
              id: "Last Run by",
              accessorKey: "lastRunBy",
              header: "Last Run by",
              size: 100,
              cell: ({ row }: CellContext<IProjectRun, any>) => {
                const { trigger, updater, creator } = row.original?.lastRunEntry || {};

                return trigger === JobRunTriggerTypes.Scheduler
                  ? "Scheduler"
                  : updater || creator || "N/A";
              }
            },
            {
              id: "Last Run",
              accessorKey: "lastRun",
              header: "Last Run",
              size: 100,
              cell: ({ row }: CellContext<IProjectRun, any>) => {
                const { updated, created } = row.original?.lastRunEntry || {};

                return <span>{(dateFormat(updated) || dateFormat(created)) ?? ""}</span>;
              }
            },
            {
              id: "Last Run Statuses",
              accessorKey: "lastRunEntries",
              header: "Last 5 Run Status",
              // minSize: 135,
              size: 135,
              // maxSize: 135,
              cell: ({ row }: CellContext<IProjectRun, any>) => {
                const { id: jobId } = row?.original?.dto || {};
                const { lastRunEntries } = row?.original || {};

                return (lastRunEntries || [])?.length === 0 ? (
                  "N/A"
                ) : (
                  <Grid container>
                    {lastRunEntries
                      ?.slice(0, 5)
                      ?.sort((a, b) => a.created - b.created)
                      ?.map((eachLastEntry, index: number) => {
                        const tooltipMoreInfo = !!eachLastEntry?.endTime ? (
                          <>
                            <br />
                            On: {dateFormat(eachLastEntry?.endTime)}
                          </>
                        ) : null;

                        const jobRunStatusBadge = getJobRunStatusBadge(
                          eachLastEntry?.status,
                          tooltipMoreInfo
                        );

                        const disabled = !includes(
                          [
                            ProjectRunEntryDtoStatusEnum.SuccessWithWarn,
                            ProjectRunEntryDtoStatusEnum.Success,
                            ProjectRunEntryDtoStatusEnum.TimedOut,
                            ProjectRunEntryDtoStatusEnum.RecipeTimedOut,
                            ProjectRunEntryDtoStatusEnum.Failure
                          ],
                          eachLastEntry?.status
                        );

                        return !!jobRunStatusBadge &&
                          !!projectId &&
                          !!jobId &&
                          !!eachLastEntry?.id ? (
                          <Tooltip
                            title={
                              !!disabled
                                ? "Kindly wait for the run to complete before visiting the canvas."
                                : ""
                            }>
                            <span>
                              <IconButton
                                size="small"
                                key={`lastRunStatus_${index}`}
                                className={clsx(classes.jobRunStatusBadgeButton, {
                                  [classes.disabled]: !!disabled
                                })}
                                disabled={!!disabled}
                                onClick={() =>
                                  navigate(
                                    generatePath(`${WebPaths.JobRoutes}${WebPaths.JobCanvas}`, {
                                      projectId,
                                      jobId,
                                      jobRunId: eachLastEntry?.id
                                    })
                                  )
                                }>
                                {jobRunStatusBadge}
                              </IconButton>
                            </span>
                          </Tooltip>
                        ) : (
                          <></>
                        );
                      })}
                  </Grid>
                );
              }
            }
          ]
        : [
            {
              id: "Triggered By",
              accessorKey: "dto.updater",
              header: "Triggered By",
              cell: ({ row }: { row: { original: IProjectRun } }) => {
                const { updater } = row.original.dto || {};
                return <span>{updater}</span>;
              }
            },
            {
              id: "Updated On",
              accessorKey: "dto.updated",
              header: "Updated On",
              cell: ({ row }: { row: { original: IProjectRun } }) => (
                <span>{dateFormat(row.original.dto.updated)}</span>
              )
            }
          ]),
      {
        id: isManual ? "Prediction Output" : "Last Run Prediction Output",
        accessorKey: "updater",
        header: isManual ? "Prediction Output" : "Last Run Prediction Output",
        enableSorting: false,
        meta: { isTooltip: false },
        size: 175,
        cell: ({ row }: CellContext<IProjectRun, any>) => {
          const disabled =
            !row.original.lastRunEntry?.id ||
            (row.original.lastRunEntry?.status !== JobRunStatuses.Success &&
              row.original.lastRunEntry?.status !== JobRunStatuses.SuccessWithWarn);

          const { path } = getPredictionJobOutputpathAndName(row.original.lastRunEntry);

          return (
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <AddOutputToCanvas
                isManual={isManual}
                disabled={!path || disabled}
                projectId={row.original.dto.projectId}
                predictionJobName={row.original.dto.name}
                path={path ?? ""}
                color={undefined}
                variant={undefined}
              />

              <DownloadPredictions
                id={row.original.lastRunEntry?.id}
                style={{ background: "transparent" }}
                disabled={disabled || !path}
              />
            </div>
          );
        }
      },
      {
        id: isManual ? "Log" : "Last Run Log",
        accessorKey: "lastRunLog",
        header: isManual ? "Log" : "Last Run Log",
        enableSorting: false,
        cell: ({ row }: CellContext<IProjectRun, any>) => {
          const { id, status } = row.original.lastRunEntry || {};
          const recipeName = getFailedRecipeName({ lastRunEntry: row.original.lastRunEntry });
          const recipeId: string = recipeName
            ? get(row.original.lastRunEntry, ["recipeNameToId", recipeName])
            : "";

          const errorMsg = get(row.original.lastRunEntry, "error");

          return !!id ? (
            [JobRunStatuses.Created].includes(status) ? (
              <Tooltip title="Kindly wait for the run to start before checking the logs.">
                <span className={classes.linkDisabled}>View</span>
              </Tooltip>
            ) : (
              <button
                className={classes.link}
                onClick={() =>
                  handleLogsClick({
                    runId: row.original?.dto?.id,
                    runEntryId: id,
                    recipeId,
                    recipeName,
                    errorMsg: recipeId ? "" : errorMsg,
                    jobName: row.original?.dto?.name,
                    status
                  })
                }>
                View
              </button>
            )
          ) : (
            "N/A"
          );
        }
      },

      ...(isManual
        ? []
        : [
            {
              id: "Last Run Canvas",
              accessor: "lastRunCanvas",
              header: "Last Run Canvas",
              enableSorting: false,
              cell: ({ row }: CellContext<IProjectRun, any>) => {
                const { id: jobId } = row?.original?.dto || {};
                const { id: lastJobRunEntryId, status: lastJobRunEntryStatus } =
                  row.original?.lastRunEntry || {};

                return !!projectId && !!jobId && !!lastJobRunEntryId ? (
                  ![
                    JobRunStatuses.Created,
                    JobRunStatuses.Started,
                    JobRunStatuses.EntityLoading,
                    JobRunStatuses.Running
                  ].includes(lastJobRunEntryStatus) ? (
                    <Link
                      className={classes.link}
                      to={generatePath(
                        `${WebPaths.JobRoutes}${WebPaths.JobCanvas}?isManual=${isManual}`,
                        {
                          projectId,
                          jobId,
                          jobRunId: lastJobRunEntryId
                        }
                      )}>
                      <Typography variant="subtitle2">Canvas</Typography>
                    </Link>
                  ) : (
                    <Tooltip title="Kindly wait for the run to complete before visiting the canvas.">
                      <span className={classes.linkDisabled}>Canvas</span>
                    </Tooltip>
                  )
                ) : (
                  "N/A"
                );
              }
            }
          ]),
      {
        id: isManual ? "Run Project Variables" : "Last Run Project Variables",
        accessorKey: "lastRunParameters",
        header: isManual ? "Run Project Variables" : "Last Run Project Variables",
        enableSorting: false,
        cell: ({ row }: CellContext<IProjectRun, any>) => {
          const { id, status } = row.original?.lastRunEntry || {};

          return !!id ? (
            ![
              JobRunStatuses.Created,
              JobRunStatuses.Started,
              JobRunStatuses.EntityLoading,
              JobRunStatuses.Running
            ].includes(status) ? (
              <button
                className={classes.link}
                onClick={() => onJobRunParametersViewOpen(row?.original?.lastRunEntry)}>
                View
              </button>
            ) : (
              <Tooltip title="Kindly wait for the run to get completed.">
                <span className={classes.linkDisabled}>View</span>
              </Tooltip>
            )
          ) : (
            "N/A"
          );
        }
      }
    ];
  }, [models, projectId, isManual, updatingJobIds]);

  return (
    <>
      <Table
        data={data}
        columns={columns as ColumnDef<TData, any>[]}
        columnsProps={{ hiddenColumns: isManual ? [] : ["Updated On", "Updated By"] }}
        sortingProps={{ orderByDefault: isManual ? "Updated On" : "Last Run" }}
        stylesProps={{ maxHeight: "calc(100vh - 134px)" }}
      />
      {deleteRecord && (
        <DeleteProjectRunModal
          isManual={isManual}
          id={deleteRecord.id}
          open={!!deleteRecord}
          onCancel={() => setDeleteRecord(null)}
          onSuccess={handleSuccess}
        />
      )}
    </>
  );
};

export default PredictionRunsTable;
