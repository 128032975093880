import React, { useRef } from "react";

// Packages
import { Cell, Row, flexRender } from "@tanstack/react-table";
import { VirtualItem, Virtualizer } from "@tanstack/react-virtual";
import { map, multiply, size, slice } from "lodash";

// MUI
import MuiTableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import MuiTableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";

// Styles
import useStyles from "./TableBody.styles";

// Contexts
import { useTableContext } from "../../context/useTableContext";

// Types
import { TData } from "../../Table.types";

type Props = {
  rows: Row<TData | any>[];
  rowVirtualizer: Virtualizer<HTMLDivElement, Element> | null;
};

// @ts-ignore
const TableCell = ({ cell }: { cell: Cell<TData, string> }) => {
  const classes = useStyles();

  const cellRef = useRef<HTMLTableCellElement>(null);

  return (
    <Tooltip
      title={
        (cellRef?.current?.scrollWidth ?? 0) > (cellRef?.current?.clientWidth ?? 0)
          ? cell?.getValue()
          : ""
      }>
      <MuiTableCell
        ref={cellRef}
        className={classes.tableCell}
        style={{ width: cell?.column?.getSize() }}>
        {flexRender(cell?.column?.columnDef?.cell, cell?.getContext())}
      </MuiTableCell>
    </Tooltip>
  );
};

const TableBody = (props: Props) => {
  const { rows, rowVirtualizer } = props || {};

  const classes = useStyles({ height: rowVirtualizer?.getTotalSize() });

  const { table } = useTableContext();

  const measureElement = React.useCallback(
    (node: HTMLElement | null) => {
      if (node) {
        rowVirtualizer?.measureElement(node);
      }
    },
    [rowVirtualizer]
  );

  return (
    <MuiTableBody className={classes.root}>
      {map(
        slice(rowVirtualizer?.getVirtualItems(), size(table?.getTopRows())),
        (virtualRow: VirtualItem, rowIndex: number) => {
          const row = rows[virtualRow?.index] as Row<TData>;

          return (
            <TableRow
              key={`tbodyRow-${rowIndex}`}
              ref={measureElement} // measure dynamic row height
              data-index={virtualRow?.index} // needed for dynamic row height measurement
              className={classes.tableRow}
              style={{
                transform: `translateY(${virtualRow.start - multiply(32, size(table?.getTopRows()))}px)` // this should always be a `style` as it changes on scroll
              }}>
              {map(row?.getVisibleCells(), (cell: Cell<TData, string>, cellIndex: number) => (
                // <TableCell key={`tbodyCell-${cellIndex}`} cell={cell} />
                <MuiTableCell
                  key={`tbodyCell-${cellIndex}`}
                  className={classes.tableCell}
                  // Enabling native html-tooltip for all cells irrespective of ellipses.
                  // This can be reconsidered while enabling <TableCell /> component above.
                  title={cell?.getValue()}
                  style={{
                    width: cell?.column?.getSize(),
                    background: cell?.column.getIsSorted() ? "#FEF8E0" : "none"
                  }}>
                  {flexRender(cell?.column?.columnDef?.cell, cell?.getContext())}
                </MuiTableCell>
              ))}
            </TableRow>
          );
        }
      )}
    </MuiTableBody>
  );
};

export default TableBody;
