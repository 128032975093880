import React, { ReactNode, useEffect, useState } from "react";

// Packages
import { isEmpty, map, toNumber } from "lodash";

// MUI
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";

// Open API
import { GetDataFilesSubTypeEnum } from "@rapidcanvas/rc-api-core";

// Hooks
import { IEntity } from "src/hooks/api/entities/useEntities";
import { useGetDataSources } from "src/hooks/api";
import useConnectorsMap from "src/hooks/useConnectorsMap";
import useGetDatasetFiles from "src/hooks/api/entities/useGetDatasetFiles";

// Stores
import { useDataSourcesStore } from "stores/zustand/stores";
import { dataSourcesGetter, dataSourcesSetter } from "stores/zustand/stores.selectors";

// Components
import Table from "./Table";
import SqlInterface from "./SqlInterface";

// Styles
import useStyles from "./Source.styles";
import AuditHistory from "./AuditHistory";
import { Typography } from "@material-ui/core";
import FileName from "./FileName";
import { useLocation } from "react-router-dom";

type IProps = {
  isTextInput?: boolean;
  userId: string | null | undefined;
  isJobPath: boolean;
  isDefaultScenario: boolean;
  dataset: IEntity | null | undefined;
  isLoading: boolean;
  dataFilesSubType?: GetDataFilesSubTypeEnum;
};

export enum MODE {
  EDIT = "edit",
  AUDIT_HISTORY = "audit-history"
}

const Source: React.FC<IProps> = (props) => {
  const {
    isTextInput = false,
    userId,
    isJobPath,
    isDefaultScenario,
    dataset,
    isLoading,
    dataFilesSubType
  } = props;

  const classes = useStyles();
  const { state } = useLocation();

  const dataSourcesStore = useDataSourcesStore(dataSourcesGetter);
  const setDataSourcesStore = useDataSourcesStore(dataSourcesSetter);

  const [mode, setMode] = useState<MODE>();
  const [fileNames, setFileNames] = useState<ReactNode>("");

  useEffect(() => {
    const showAuditHistory = state?.showAuditHistory;
    if (showAuditHistory) {
      setMode(MODE.AUDIT_HISTORY);
    }
  }, []);

  const { isLoading: isFetchingGetConnectors } = useGetDataSources({
    cacheTime: Infinity,
    enabled: isEmpty(dataSourcesStore),
    onSuccess: (dataSourcesData) => setDataSourcesStore(dataSourcesData)
  });

  useGetDatasetFiles({
    datasetId: dataset?.id,
    ...(!!dataFilesSubType ? { dataFilesSubType } : {}),
    subType: dataFilesSubType,
    onSuccess: (data) => {
      setFileNames(
        <Grid container style={{ gap: "5px" }}>
          {map(data, (item, index) => (
            <Grid style={{ maxWidth: "calc(100% - 10px)" }}>
              <FileName item={item} />
              {toNumber(index) < data?.length - 1 && ", "}
            </Grid>
          ))}
        </Grid>
      );
    },
    refetchOnMount: true,
    enabled: !!dataset?.id && !dataset?.dataSourceId
  });

  const connectorsMap = useConnectorsMap();

  return isLoading || isFetchingGetConnectors ? (
    <Box p={4}>
      <Grid container>
        <CircularProgress color="secondary" style={{ margin: "auto" }} />
      </Grid>
    </Box>
  ) : (
    <Grid container direction="column" wrap="nowrap" className={classes.root}>
      {!isEmpty(dataset) && (
        <Grid item>
          <Table
            isJobPath={isJobPath}
            isDefaultScenario={isDefaultScenario}
            dataset={dataset}
            fileNames={fileNames}
            connectorsMap={connectorsMap}
            mode={mode}
            setMode={setMode}
          />
        </Grid>
      )}
      {mode == MODE.EDIT && (
        <Grid item>
          <SqlInterface
            userId={userId}
            isDefaultScenario={isDefaultScenario}
            isJobPath={isJobPath}
            dataset={dataset}
          />
        </Grid>
      )}
      {mode == MODE.AUDIT_HISTORY && dataset && (
        <>
          <Grid item>
            <Typography>Audit History</Typography>
          </Grid>
          <Grid item>
            <AuditHistory
              isTextInput={isTextInput}
              datasetId={dataset.id}
              dataSourceId={dataset.dataSourceId}
              connectorsMap={connectorsMap}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default Source;
