import { Grid } from "@material-ui/core";
import React, { ReactElement } from "react";

export interface IBoxProps {
  icon: ReactElement;
  message: string | ReactElement;
  action: ReactElement;
  width?: string;
}

const GetStartedBox: React.FC<IBoxProps> = ({ icon, message, action, width = "450px" }) => {
  return (
    <Grid
      container
      style={{
        flexWrap: "nowrap",
        background: "white",
        border: "1px solid #BABABA",
        width: width,
        padding: "16px",
        borderRadius: "4px",
        gap: "16px",
        height: "fit-content"
      }}>
      <Grid>{icon}</Grid>
      <Grid container direction="column" justifyContent="space-between" style={{ gap: "10px" }}>
        <Grid style={{ fontSize: "15px" }}>{message}</Grid>
        <Grid container>{action}</Grid>
      </Grid>
    </Grid>
  );
};

export default GetStartedBox;
