import { useQuery } from "@tanstack/react-query";
import { filterWithSpecificDataApp } from "pages/DataApps/utils/DataApps.helpers";

import api from "services/AxiosClient/AxiosClient";
import { DataAppType } from "src/pages/DataApps/DataApps.type";
import useAuthStore from "stores/zustand/auth.store";

type Props = {
  dataAppName?: string;
  onSuccess?: (data: DataAppType[]) => void;
};

const QUERY_KEY_DATAAPPS_ACROSS_TENANTS = "query-key-dataapps-across-tenants";

const useGetDataAppAcrossTenants = ({ dataAppName }: Props) => {
  const userEmail = useAuthStore((state) => state.userEmail);

  return useQuery<DataAppType[]>({
    queryKey: [QUERY_KEY_DATAAPPS_ACROSS_TENANTS, dataAppName],
    queryFn: async () => {
      if (!dataAppName) {
        throw "dataAppName is absent";
      }
      const response = (await api.fetchResponse(
        async () => await api.DataAppControllerApi.getDataAppsAcrossTenants(dataAppName)
      )) as DataAppType[];

      if (userEmail) {
        const filtered = filterWithSpecificDataApp(response, userEmail);
        return filtered;
      }

      return response;
    },
    refetchOnMount: true
  });
};

export default useGetDataAppAcrossTenants;
export { QUERY_KEY_DATAAPPS_ACROSS_TENANTS };
