import React, { useMemo } from "react";
import _ from "lodash";
import { Card, Grid, Typography } from "@material-ui/core";
import { useParams } from "react-router-dom";

import CreateDataAppInputs from "./CreateDataAppInputs";
import {
  DataappAskAIConfigInputTypeEnum,
  DataappAskAIConfigLlmTypeEnum,
  CreateDataAppRequestDtoDataAppTypeEnum
} from "@rapidcanvas/rc-api-core";
import { IRecipes } from "./CreateDataApp";
import { askAITypesInfoList } from "pages/DataApps/utils/DataApps.constants";

interface IProps {
  recipes: IRecipes[];
  recipeLoading: boolean;
  dataAppType: "auto-create" | "import-zip";
  disabledAddDataAppActionMessage?: string;
  disabledAskAICreateActionMessage?: string;
  setValues: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  values: Record<string, any>;
  errorMsgs: Record<string, string | undefined>;
}

export const dataAppConfigFields = {
  llmType: "llmType",
  inputType: "inputType",
  dataAppName: "dataAppName",
  description: "description",
  imageBase64: "imageBase64",
  recipeId: "recipeId",
  systemMessage: "systemMessage",
  dataSharingStrategy: "dataSharingStrategy",
  enableCache: "enableCache",
  customEnvId: "customEnvId",
  isPrivate: "isPrivate",
  projectRunId: "projectRunId",
  zipEnv: "zipEnv",
  zipFile: "zipFile",
  appType: "appType",
  metadata: "metadata",
  allowColumnHyperLink: "allowColumnHyperLink",
  columnsHyperlinksMapping: "columnsHyperlinksMapping",
  pythonVersion: "pythonVersion",
  suggestionPrompts: "suggestionPrompts",
  dataAppPermissions: "dataAppPermissions"
};

const CreateDataAppForm: React.FC<IProps> = (props) => {
  const { recipes, recipeLoading, dataAppType, values, errorMsgs, setValues, handleInputChange } =
    props;

  const inputType = _.get(values, dataAppConfigFields.inputType);
  const { projectId } = useParams();

  const { label, content } = useMemo(() => {
    const type = _.find(askAITypesInfoList, (val) => val.value === inputType);

    if (inputType === CreateDataAppRequestDtoDataAppTypeEnum.RapidModel) {
      return {
        label: "Model DataApp",
        content: (
          <>
            <span
              data-testid="dataAppTypeDescription"
              style={{ color: "#64748B", fontSize: "16px", marginBottom: 0 }}>
              A model dataApp helps in sharing model metrics and interactive model usage
              capabilities to other users
            </span>
            <ul style={{ margin: "8px 16px 0px 16px" }}>
              {_.map(
                [
                  "Uses model from the Canvas",
                  "Contains model performance metrics",
                  "What-If analysis",
                  "Prediction Service",
                  "AskAI on prediction data",
                  "Save additional charts"
                ],
                (item) => (
                  <li key={item} style={{ marginBottom: "4px" }}>
                    {item}
                  </li>
                )
              )}
            </ul>
          </>
        )
      };
    } else {
      return { label: type?.label, content: type?.tooltip };
    }
  }, [inputType]);

  const type = useMemo(() => {
    if (
      _.get(values, dataAppConfigFields.inputType) ===
      CreateDataAppRequestDtoDataAppTypeEnum.RapidModel
    ) {
      return CreateDataAppRequestDtoDataAppTypeEnum.RapidModel;
    } else {
      return CreateDataAppRequestDtoDataAppTypeEnum.Askai;
    }
  }, [values]);

  const handleInputValChange = (
    e: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>
  ) => {
    if (!e.target.name) {
      return;
    }

    setValues({
      ..._.omit(values, dataAppConfigFields.projectRunId),
      [e.target.name]: e.target.value,
      [dataAppConfigFields.llmType]:
        DataappAskAIConfigInputTypeEnum.RagFiles === e.target.value &&
        _.includes(
          [
            DataappAskAIConfigLlmTypeEnum.AzureOpenaiGpt4O,
            DataappAskAIConfigLlmTypeEnum.AnthropicClaude35Sonnet
          ],
          _.get(values, dataAppConfigFields.llmType)
        )
          ? DataappAskAIConfigLlmTypeEnum.OpenaiGpt4O
          : _.get(values, dataAppConfigFields.llmType)
    });
  };

  return (
    <Grid container spacing={2} style={{ minHeight: "calc(100vh - 110px)" }}>
      <Grid item xs={dataAppType === "auto-create" ? 8 : 12}>
        <CreateDataAppInputs
          type={type}
          dataAppType={dataAppType}
          projectId={projectId}
          disabledAddDataAppActionMessage={props.disabledAddDataAppActionMessage}
          values={values}
          errorMsgs={errorMsgs}
          recipes={recipes}
          recipeLoading={recipeLoading}
          onChange={handleInputChange}
          onInputValChange={handleInputValChange}
        />
      </Grid>
      {dataAppType === "auto-create" && (
        <Grid item xs={4}>
          <Card
            style={{
              padding: "16px",
              minHeight: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "16px"
            }}>
            <Typography component="h4" style={{ fontWeight: 500 }}>
              {label}
            </Typography>
            <div>{content}</div>
          </Card>
        </Grid>
      )}
    </Grid>
  );
};

export default CreateDataAppForm;
