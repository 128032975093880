import React from "react";
import { Box, Grid, Tab, Tabs, makeStyles } from "@material-ui/core";

import { OverflowTooltip } from "src/components";
import Schema from "pages/ViewData/ViewDataData/Schema";
import { get } from "lodash";

const useStyles = makeStyles({
  tabs: {
    height: "44px",
    maxWidth: "fit-content",
    background: "#fff"
  },
  flexContainer: {
    height: "100%"
  },
  tab: {
    textTransform: "none",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    padding: "0px 12px"
  },
  tabWrap: {
    borderBottom: "1px solid #0003"
  },
  chartsContainerWrap: {
    width: "100%",
    overflow: "auto",
    background: "#fff",
    borderRadius: "8px"
  },
  wrapper: {
    alignItems: "flex-start"
  }
});

const AIGuideOutputWrap = ({
  tabNames,
  children,
  handleChange,
  value,
  isOutputDataset = false,
  selectedDataset
}: {
  tabNames: { name: string }[];
  children: React.ReactNode;
  handleChange: React.Dispatch<React.SetStateAction<number>>;
  value: number;
  isOutputDataset?: boolean;
  selectedDataset?: any;
}) => {
  const classes = useStyles();

  return (
    <Grid container direction="column" wrap="nowrap" className={classes.chartsContainerWrap}>
      <Grid
        container
        direction="column"
        wrap="nowrap"
        className={classes.tabWrap}
        data-testid="ai-output-tab-wrap">
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid style={{ width: !isOutputDataset ? "100%" : "calc(100% - 78px)" }}>
            <Tabs
              value={value}
              className={classes.tabs}
              classes={{
                flexContainer: classes.flexContainer
              }}
              variant="scrollable"
              indicatorColor="primary"
              textColor="primary"
              onChange={(_, newValue) => handleChange(newValue)}>
              {tabNames?.map(({ name }, index) => (
                <Tab
                  classes={{
                    wrapper: classes.wrapper
                  }}
                  label={
                    <Box maxWidth="100%" data-testid="ai-output-tab-name">
                      <OverflowTooltip value={name} />
                    </Box>
                  }
                  key={index}
                  className={classes.tab}
                />
              ))}
            </Tabs>
          </Grid>
          {isOutputDataset && get(selectedDataset, "featureDtos") && (
            <Grid style={{ paddingRight: "5px" }}>
              <Schema
                dataset={{ name: selectedDataset?.name }}
                entityFeaturesMap={get(selectedDataset, "featureDtos") ?? []}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      {children}
    </Grid>
  );
};

export default React.memo(AIGuideOutputWrap);
