import React, { useMemo, useState } from "react";
import _ from "lodash";
import { FormControl, MenuItem, Select, makeStyles } from "@material-ui/core";
import TableChartOutlinedIcon from "@material-ui/icons/TableChartOutlined";

import HeatMap from "components/Charts/HeatMap";
import HeatMapIcon from "src/assets/icons/HeatMapIcon";
import { IEdaCardProps } from "./EdaOverview";
import { TabPanel } from "src/components";
import { getNumberUptoNDecimal } from "../utils/Eda.helpers";
import Table from "src/components/custom/TableV8/Table";

const useStyles = makeStyles({
  wrapper: {
    width: "100%",

    "& tr td:first-child": {
      fontWeight: 600
    },

    "& .MuiTabs-root": {
      marginBottom: "10px"
    }
  },
  heatMap: {
    maxWidth: "650px",
    margin: "15px auto",

    "& .apexcharts-legend-series": {
      alignItems: "baseline"
    }
  },
  select: {
    "& .MuiSelect-root": {
      background: "#F2F9FF",
      color: "#008FE4",

      "& svg path": {
        fill: "#008FE4"
      }
    }
  },
  flex: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "12px"
  }
});
const variables = "variables";

const EdaCorrelations: React.FC<IEdaCardProps> = (props) => {
  const { edaJson } = props;

  const [tab, setTab] = useState(0);
  const { wrapper, heatMap, select, flex } = useStyles();

  const { data, columns, series } = useMemo(() => {
    const column = [{ id: variables, accessorKey: variables, header: "" }];
    const dataset: Record<string, string | number>[] = [];
    const seriesObject: Record<string, (string | number)[]> = {};

    const keys = _.keys(_.head(edaJson.correlations?.auto));

    _.forEach(edaJson.correlations?.auto, (value, index) => {
      const key = keys[index];
      column.push({ id: key, accessorKey: key, header: key });
      dataset.push({
        ..._.mapValues(value, (val) => getNumberUptoNDecimal(val, 3)),
        [variables]: key
      });

      _.forEach(value, (val, k) => {
        const output =
          _.isNaN(val) || !_.isNumber(val) ? 0 : _.toNumber(getNumberUptoNDecimal(val, 3));
        if (_.has(seriesObject, k)) {
          seriesObject[k] = [..._.get(seriesObject, k), output];
        } else {
          seriesObject[k] = [output];
        }
      });
    });

    return {
      data: dataset,
      columns: column,
      series: _.map(seriesObject, (val, key) => ({
        name: key,
        data: _.map(val, (v, i) => ({ x: keys[i], y: v }))
      })).reverse()
    };
  }, [edaJson.correlations?.auto]);

  const handleChange = (event: any) => {
    setTab(event.target.value);
  };

  return (
    <div className={wrapper}>
      <div className={flex}>
        <FormControl>
          <Select
            disableUnderline
            className={select}
            value={tab}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            onChange={handleChange}>
            <MenuItem value={0}>
              <HeatMapIcon /> Heatmap
            </MenuItem>
            <MenuItem value={1}>
              <TableChartOutlinedIcon /> Table
            </MenuItem>
          </Select>
        </FormControl>
      </div>
      <TabPanel index={0} value={tab} className={heatMap}>
        <HeatMap height={700} series={series} />
      </TabPanel>
      <TabPanel index={1} value={tab}>
        <Table
          data={data}
          columns={columns}
          sortingProps={{ unsorted: true }}
          stylesProps={{ maxHeight: "calc(100vh - 350px)" }}
        />
      </TabPanel>
    </div>
  );
};

export default EdaCorrelations;
