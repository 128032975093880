import Close from "@material-ui/icons/Close";
import React, { useState } from "react";
import clsx from "clsx";
import dayjs from "dayjs";
import {
  Badge,
  CircularProgress,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip
} from "@material-ui/core";

import NotificationLabel from "./NotificationLabel";
import { Notification, NotificationResponse } from "src/types/Notifications.types";
import { toastWrapper } from "services/ToastClient/toastWrapper";
import { useStyles } from "./TopNavBarNotifications.style";
import { useUpdateNotification, useDeleteNotification } from "src/hooks/api";

interface IProps {
  notification: Notification;
}

const NotificationItem: React.FC<IProps> = (props) => {
  const { notification } = props;
  const [hovered, setHovered] = useState(false);

  const classes = useStyles();
  const updateNotificationMutation = useUpdateNotification();
  const deleteNotificationMutation = useDeleteNotification();

  const handleDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    const notificationIds = [
      ...new Set([
        ...(!!notification?.id ? [notification?.id] : []),
        ...(notification?.matching || [])?.map(
          (eachNotification: NotificationResponse) => eachNotification?.id
        )
      ])
    ];

    if (notificationIds?.length === 0) {
      return;
    }

    deleteNotificationMutation.mutate(
      { notificationIds },
      {
        onError: () => {
          toastWrapper({
            type: "error",
            content: "Uh-oh! Notification couldn't be cleared. Please try again."
          });
        }
      }
    );
  };

  const updateNotification = () => {
    if (!notification?.id || notification?.read) {
      return;
    }
    updateNotificationMutation.mutate({ notificationId: notification?.id });
  };

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  return (
    <Tooltip title={notification.tooltip}>
      <ListItem
        className={clsx(
          classes.listItem,
          notification.read ? "read" : "",
          hovered ? "hovered" : ""
        )}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={updateNotification}>
        <ListItemAvatar style={{ minWidth: "unset" }}>
          <Tooltip
            title={notification.frequency > 0 ? `Occurred ${notification.frequency} times` : ""}>
            <Badge
              overlap="rectangular"
              badgeContent={notification.frequency}
              classes={{ anchorOriginTopRightRectangular: classes.badgeTop }}>
              {notification.icon}
            </Badge>
          </Tooltip>
        </ListItemAvatar>
        <ListItemText>
          <div className={classes.contentWrap}>
            <NotificationLabel>{notification.label}</NotificationLabel>
            {hovered || deleteNotificationMutation.isLoading ? (
              <div className={classes.margin}>
                {notification.extra}
                {deleteNotificationMutation.isLoading ? (
                  <CircularProgress size={12} style={{ marginRight: "6px" }} />
                ) : (
                  <IconButton className={classes.btn} onClick={handleDelete}>
                    <Close className={classes.closeIcon} />
                  </IconButton>
                )}
              </div>
            ) : (
              <div className={classes.margin}>
                {notification.loader}
                <span className={classes.created}>{dayjs(notification.created).fromNow()}</span>
              </div>
            )}
          </div>
          <div className={classes.text}>{notification.text}</div>
        </ListItemText>
      </ListItem>
    </Tooltip>
  );
};

export default NotificationItem;
