import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult
} from "@tanstack/react-query";
import { pick } from "lodash";

import api from "services/AxiosClient/AxiosClient";
import useAuthStore from "stores/zustand/auth.store";
import { getAPIWithRethrow, postAPIWithRethrow } from "services/Apis/Apis.service";
import { getColumnsFromStorage } from "src/components/custom/Table/utils/Table.helpers";

const QUERY_KEY_ENTITY_AND_STATS = "query-key-entity-data-and-stats";

export const enum UseGetDatasetDataInfiniteQueryKeys {
  InfiniteDatasetData = "infinite-dataset-data",
  CustomColumnsDatasetData = "custom-columns-dataset-data"
}

interface IReturn {
  viewData: any;
  entityFeatures: any;
}

type Payload = {
  scenarioId?: string;
  rowsStart?: number;
  rowsEnd?: number;
  cols?: string[];
  columnLimit?: number;
};

interface Props extends UseQueryOptions<$TSFixMe> {
  projectId?: string;
  entityId?: string;
  scenarioId?: string;
  jobRunId?: string;
  payload?: Payload;
  shouldDispatchEvent?: boolean;
}

interface InfiniteQueryProps extends UseInfiniteQueryOptions<$TSFixMe> {
  projectId?: string;
  entityId?: string;
  scenarioId?: string;
  jobRunId?: string;
  payload?: Payload;
}

const useEntityDataAndStats = (props: Props): UseQueryResult<IReturn> => {
  const {
    projectId,
    entityId,
    scenarioId,
    jobRunId,
    payload,
    shouldDispatchEvent = true,
    ...options
  } = props || {};

  return useQuery({
    queryKey: [QUERY_KEY_ENTITY_AND_STATS, projectId, entityId, scenarioId, jobRunId],
    enabled: !!entityId,
    queryFn: async () => {
      const response = await api.fetchResponse(
        async () =>
          await api.EntityControllerApi.findEntities(
            entityId,
            undefined,
            projectId,
            scenarioId,
            jobRunId
          ),
        shouldDispatchEvent
      );
      const entityResponse = response[0];

      let dataBody: any = {
        scenarioId,
        rowsStart: 0,
        rowsEnd: 500,
        cols: [],
        ...payload
      };

      if (jobRunId) {
        dataBody = { ...dataBody, projectRunEntryId: jobRunId };
      }

      const entityFeaturesUrl = entityResponse?.scenarioStepId
        ? `/v2/features?entityId=${entityId}&scenarioStepId=${entityResponse?.scenarioStepId}${
            !!jobRunId ? `&projectRunEntryId=${jobRunId}` : ``
          }`
        : `/v2/features?entityId=${entityId}${!!jobRunId ? `&projectRunEntryId=${jobRunId}` : ``}`;

      const [entityDataResponse, entityDataFeaturesResponse] = await Promise.all([
        postAPIWithRethrow(
          `/v2/entities/${entityId}/data${scenarioId ? `?scenarioId=${scenarioId}` : ""}`,
          dataBody
        ),
        getAPIWithRethrow(entityFeaturesUrl, undefined, shouldDispatchEvent)
      ]);

      return {
        viewData: pick(entityDataResponse?.data, "columns", "rows"),
        entityFeatures: entityDataFeaturesResponse
      };
    },
    ...options,
    refetchOnMount: true
  });
};

export const useEntityDataInfinite = (
  props: InfiniteQueryProps
): UseInfiniteQueryResult<IReturn> => {
  const { projectId, entityId, scenarioId, jobRunId, payload, ...options } = props || {};

  const userId = useAuthStore((state) => state.userId);

  const fetchSize = 100;

  return useInfiniteQuery({
    queryKey: [UseGetDatasetDataInfiniteQueryKeys.InfiniteDatasetData],
    queryFn: async ({ pageParam = 0 }) => {
      const start = (pageParam as number) * fetchSize;

      const columnsFromStorage = !!jobRunId
        ? []
        : getColumnsFromStorage({ userId, datasetId: entityId }) || [];

      let dataBody: any = {
        scenarioId,
        rowsStart: start,
        rowsEnd: start + fetchSize,
        cols: columnsFromStorage,
        ...payload
      };

      if (jobRunId) {
        dataBody = { ...dataBody, projectRunEntryId: jobRunId };
      }

      let datasetData: { [key: string]: $TSFixMe[] } = {};
      let datasetSchema: $TSFixMe[] = [];

      if (pageParam === 0) {
        const [entityResponse] = await getAPIWithRethrow(
          `/v2/entities?id=${entityId}&projectId=${projectId}&scenarioId=${scenarioId}${
            !!jobRunId ? `&projectRunEntryId=${jobRunId}` : ``
          }&limit=500`
        );

        const entityFeaturesUrl = entityResponse?.scenarioStepId
          ? `/v2/features?entityId=${entityId}&scenarioStepId=${entityResponse?.scenarioStepId}${
              !!jobRunId ? `&projectRunEntryId=${jobRunId}` : ``
            }`
          : `/v2/features?entityId=${entityId}${
              !!jobRunId ? `&projectRunEntryId=${jobRunId}` : ``
            }`;

        const responseData = await Promise.all([
          postAPIWithRethrow(
            `/v2/entities/${entityId}/data${scenarioId ? `?scenarioId=${scenarioId}` : ""}`,
            dataBody
          ),
          getAPIWithRethrow(entityFeaturesUrl)
        ]);

        datasetData = responseData[0];
        datasetSchema = responseData[1];
      } else {
        datasetData = await postAPIWithRethrow(
          `/v2/entities/${entityId}/data${scenarioId ? `?scenarioId=${scenarioId}` : ""}`,
          dataBody
        );
      }

      return {
        viewData: pick(datasetData?.data, "columns", "rows"),
        messages: datasetData?.messages,
        entityFeatures: datasetSchema
      };
    },
    getNextPageParam: (_lastGroup, groups) => groups.length,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    cacheTime: 0,
    ...options
  });
};

export const useEntityData = ({
  ...options
}: UseMutationOptions<$TSFixMe, Error, $TSFixMe>): UseMutationResult =>
  useMutation({
    mutationFn: async (payload: $TSFixMe) =>
      await postAPIWithRethrow(
        `/v2/entities/${payload?.entityId}/data?${!!payload?.scenarioId ? `&scenarioId=${payload?.scenarioId}` : ""}${
          !!payload?.jobRunId ? `&projectRunEntryId=${payload?.jobRunId}` : ``
        }`,
        {
          ...payload?.payload
        }
      ),
    ...options
  });

export const useEntityDataCustomColumns = ({
  ...options
}: UseMutationOptions<$TSFixMe, Error, $TSFixMe>): UseMutationResult =>
  useMutation({
    mutationFn: async (payload: $TSFixMe) =>
      await postAPIWithRethrow(
        `/v2/entities/${payload?.entityId}/data?${!!payload?.scenarioId ? `&scenarioId=${payload?.scenarioId}` : ""}${
          !!payload?.jobRunId ? `&projectRunEntryId=${payload?.jobRunId}` : ``
        }`,
        {
          ...payload?.payload
        }
      ),
    ...options
  });

export default useEntityDataAndStats;

export { QUERY_KEY_ENTITY_AND_STATS };
