import { makeStyles, Theme } from "@material-ui/core/styles";

interface IStyleProps {
  disabled: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    width: "40%",
    flexShrink: 0
  },
  drawerPaper: {
    width: "40%",
    backgroundColor: "#f5f5f5"
  },
  container: {
    backgroundColor: theme.palette.common.white,
    cursor: ({ disabled }: IStyleProps) => (disabled ? "not-allowed" : "default"),
    opacity: ({ disabled }: IStyleProps) => (disabled ? 0.5 : 1)
  },

  inputField: {
    backgroundColor: theme.palette.common.white
  },
  inputLabel: {
    opacity: 0.625
  },
  inputLabelShrink: {
    shrink: true,
    opacity: 1
  }
}));

export default useStyles;
