import { useMutation } from "@tanstack/react-query";

import { putAPIWithRethrow } from "services/Apis/Apis.service";

export const useLicenseUpdateMutation = () => {
  return useMutation({
    mutationFn: ({ licenseKey }: { licenseKey: string }) => {
      return putAPIWithRethrow(`/license/attach-license`, { licenseKey });
    }
  });
};
