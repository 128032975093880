import React, { useMemo } from "react";

// Packages
import { isEmpty } from "lodash";

// MUI
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useTheme } from "@material-ui/core/styles";

// Open API
import { EnvDto } from "@rapidcanvas/rc-api-core";

// Hooks
import { usePollEnvironment } from "src/hooks/api/environments/usePollEnvironments";

// Components
import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import StatusBar from "../StatusBar/StatusBar";

interface IProps {
  open: boolean;
  environment?: EnvDto;
  onClose: () => void;
}

const Header: React.FC<IProps> = (props) => {
  const { open = false, environment, onClose } = props;

  const theme = useTheme();

  const { data: pollingEnvResponse, isFetchedAfterMount: isPollingEnvFetchedAfterMount } =
    usePollEnvironment({
      id: environment?.id,
      status: environment?.launchStatus,
      isPollEnvEnabled: !!open
    });

  const env = useMemo(() => {
    if (!!isPollingEnvFetchedAfterMount && !isEmpty(pollingEnvResponse?.data?.[0])) {
      return pollingEnvResponse?.data?.[0];
    }

    return environment;
  }, [isPollingEnvFetchedAfterMount, pollingEnvResponse?.data?.[0], environment]);

  return (
    <SubTopNavBarWrapper
      variant="drawer"
      onDrawerClose={onClose}
      subTopNavBarLeftSection={{
        component: (
          <Typography variant="body2" color="textSecondary" data-testid="envConfigTitle">
            Configuration & Consumption
          </Typography>
        )
      }}
      subTopNavBarRightSection={{
        component: (
          <Grid container wrap="nowrap" style={{ columnGap: theme.spacing(1) }}>
            <StatusBar
              environment={env}
              styleProps={{
                style: { width: "auto", margin: "auto", borderRadius: 4 }
              }}
            />
          </Grid>
        )
      }}
    />
  );
};

export default Header;
