import React from "react";

// MUI
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

// Icons
import ReorderRoundedIcon from "@material-ui/icons/ReorderRounded";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "sticky",
    top: 0,
    zIndex: theme.zIndex.appBar, // Ensuring it stays on top of other items
    columnGap: theme.spacing(2),
    fontSize: "small",
    fontWeight: "bold",
    backgroundColor: "#f1f1f1" // "#d9d9d920"
  }
}));

const RecipeListHeader = () => {
  const classes = useStyles();

  return (
    <ListItem dense className={classes.root}>
      <ListItemText
        style={{ width: 20 }}
        primary={<ReorderRoundedIcon color="disabled" style={{ fontSize: "large" }} />}
        primaryTypographyProps={{ align: "center" }}
        data-testid="recipeRunsListHeaderReorderRoundedIcon"
      />
      <ListItemText
        style={{ width: "28%" }}
        primary="Recipe"
        primaryTypographyProps={{
          variant: "subtitle2"
        }}
        data-testid="recipeRunsListHeaderRecipe"
      />
      <ListItemText
        style={{ width: "20%" }}
        primary="Scenario"
        primaryTypographyProps={{
          variant: "subtitle2"
        }}
        data-testid="recipeRunsListHeaderScenario"
      />
      <ListItemText
        style={{ width: "15%" }}
        primary="Status"
        primaryTypographyProps={{
          variant: "subtitle2",
          align: "center"
        }}
        data-testid="recipeRunsListHeaderStatus"
      />
      <ListItemText
        style={{ width: "25%" }}
        primary="Run Triggered At"
        primaryTypographyProps={{
          variant: "subtitle2"
        }}
        data-testid="recipeRunsListHeaderRunTriggeredAt"
      />
      <ListItemText
        style={{ width: "12%" }}
        primary="Action"
        primaryTypographyProps={{
          variant: "subtitle2"
        }}
        data-testid="recipeRunsListHeaderAction"
      />
    </ListItem>
  );
};

export default RecipeListHeader;
