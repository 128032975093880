import React, { useEffect, useState, useMemo, useRef } from "react";
import { pick } from "lodash";
import {
  Drawer,
  Grid,
  Paper,
  Box,
  Button,
  Tooltip,
  Typography,
  Toolbar,
  CircularProgress
} from "@material-ui/core";

import CloudQueueIcon from "@material-ui/icons/CloudQueue";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import JobDestination from "../JobDestination/JobDestination";
import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import { RenderText } from "components/custom";
import { useJobContext } from "../Job/context/useJobContext";
import { useStyles } from "./JobDestinations.styles";
import CommonLoader from "src/components/CommonLoader";
import { JobDestinationsHelperText } from "../../utils/Jobs.constants";

export interface IDestination {
  dataSourceId: string;
  enteredDataSourceId: string;
  datasetId: string;
  id: string;
  entityId?: string;
  dataSourceName: string;
  isValid: boolean;
  isEditing: boolean;
  isSubmitted: boolean;
  dataSourceType: string;
  selectedDataSourceType: string;
  searchedDatasets: any[];
  selectedDatasets: any[];
  options?: Record<string, string>;
  enteredOptions: Record<string, string>;
}

const JobDestinations = (props: $TSFixMe) => {
  const { close, isDestinationSaving, setIsDestinationSaving, isDestinationsOpen } = props || {};

  const classes = useStyles();

  const destinationsContainerRef = useRef<$TSFixMe>();

  // Job context
  const {
    setJobDestinationsContextState,

    dataSourcesData,
    jobData,
    datasetsData,

    destinationsData,
    refetchDestinations,
    destinationLoading,
    setIsSaved
  } = useJobContext() || {};

  // States - STARTS >>
  const [destinations, setDestinations] = useState<IDestination[]>([]);

  const [deletingDestinationId, setDeletingDestinationId] = useState<string>("");

  const [addDestinationWatcher, setAddDestinationWatcher] = useState<number | boolean>(false);
  // << ENDS - States

  // Sync jobDestinationsContextState - STARTS >>
  const isAllDestinationsValid = useMemo(
    () =>
      destinations?.every(
        (eachDestination: IDestination) => eachDestination?.isSubmitted && eachDestination?.isValid
      ),
    [destinations]
  );

  useEffect(() => {
    const thisDestinations: Partial<IDestination>[] = [];

    (destinations || [])
      ?.filter(
        (eachDestination: IDestination) =>
          !!eachDestination?.datasetId &&
          !!eachDestination?.dataSourceId &&
          !!eachDestination?.options
      )
      ?.forEach((eachDestination: IDestination) => {
        thisDestinations.push(
          pick(eachDestination, ["id", "datasetId", "dataSourceId", "options"])
        );
      });

    setJobDestinationsContextState(() => [...thisDestinations]);
  }, [destinations]);
  // << ENDS - Sync jobDestinationsContextState

  useEffect(() => {
    if (addDestinationWatcher) {
      destinationsContainerRef?.current?.scrollTo(
        0,
        destinationsContainerRef?.current?.scrollHeight
      );

      setAddDestinationWatcher(false);
    }
  }, [destinationsContainerRef?.current?.scrollHeight, addDestinationWatcher]);

  const getDataset = (datasetId: string = "") =>
    (datasetsData || [])?.find((eachDataset: any) => eachDataset?.id === datasetId) || {};

  const formDestinationMap = (destination?: IDestination): IDestination => {
    const selectedDataset = getDataset(destination?.datasetId);
    const selectedDatasets =
      Object.keys(selectedDataset || {})?.length > 0 ? [selectedDataset] : [];

    const destinationMap: IDestination = {
      id: destination?.id || "",
      searchedDatasets: [],
      selectedDatasets,
      datasetId: destination?.entityId || "",
      dataSourceId: destination?.dataSourceId || "",
      dataSourceName: destination?.dataSourceName || "",
      dataSourceType: destination?.dataSourceType || "",
      selectedDataSourceType: destination?.dataSourceType || "",
      enteredDataSourceId: destination?.dataSourceId || "",
      options: destination?.options,
      enteredOptions: destination?.options || {},
      isEditing: Object.keys(destination || {})?.length > 0 ? false : true,
      isSubmitted: Object.keys(destination || {})?.length > 0 ? true : false,
      isValid: Object.keys(destination || {})?.length > 0 ? true : false
    };

    return destinationMap;
  };

  const addDestination = () => {
    const thisDestinations = [...destinations]?.map((eachDestination: IDestination) => {
      if (Object.keys(eachDestination || {})?.length > 0) {
        eachDestination["isEditing"] = false;
      }

      return eachDestination;
    });

    setDestinations(() => [...thisDestinations, formDestinationMap() || {}]);

    setAddDestinationWatcher(() => Date.now());
  };

  useEffect(() => {
    if ((dataSourcesData || [])?.length > 0 && (datasetsData || [])?.length > 0) {
      if ((destinationsData || [])?.length > 0) {
        const thisDestinations: IDestination[] = [];

        (destinationsData || [])?.forEach((eachDestination: IDestination) => {
          thisDestinations.push(formDestinationMap(eachDestination));
        });

        setDestinations(() => thisDestinations);
      }
    }
  }, [dataSourcesData, datasetsData, destinationsData]);

  const disabledAddDestinationActionMessage = useMemo(() => {
    if ((datasetsData || [])?.length === 0 || (dataSourcesData || [])?.length === 0) {
      return "To add the destination, it is necessary to have datasets and active data-connectors.";
    } else if (!isAllDestinationsValid) {
      return "Save the destination to enable this action.";
    } else {
      return "";
    }
  }, [datasetsData, dataSourcesData, isAllDestinationsValid]);

  return (
    <Drawer
      open={isDestinationsOpen}
      anchor="right"
      variant="temporary"
      onClose={close}
      keepMounted
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper
      }}
      disableEscapeKeyDown={isDestinationSaving || !!deletingDestinationId}>
      <SubTopNavBarWrapper
        variant="drawer"
        onDrawerClose={close}
        subTopNavBarLeftSection={{
          component: (
            <>
              <RenderText color="textSecondary" isOverflowTooltip>
                Destinations
              </RenderText>
              <Tooltip title={JobDestinationsHelperText.ConnectorsInfo}>
                <InfoOutlinedIcon fontSize="small" style={{ cursor: "help", opacity: 0.5 }} />
              </Tooltip>
            </>
          )
        }}
      />

      <Toolbar />
      <Box m={2} className={classes.container}>
        {destinationLoading ? (
          <Grid style={{ height: "100%" }}>
            <CommonLoader />
          </Grid>
        ) : (
          <>
            <Grid container>
              <Grid item xs={8} container alignItems="center" style={{ display: "flex" }}>
                <CloudQueueIcon />
                <div style={{ paddingLeft: 10 }}>
                  <Typography variant="body2">{JobDestinationsHelperText.InfoText}</Typography>
                  <Typography variant="caption">{JobDestinationsHelperText.InfoSubText}</Typography>
                </div>
              </Grid>
              {(destinations || [])?.length > 0 && (
                <Grid item xs={4} style={{ display: "flex" }}>
                  <Tooltip title={disabledAddDestinationActionMessage}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "auto",
                        marginLeft: "auto",
                        gap: "5px"
                      }}>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => addDestination()}
                        disabled={!!disabledAddDestinationActionMessage}>
                        + Destination
                      </Button>
                      <div>{isDestinationSaving && <CircularProgress size={20} />}</div>
                    </div>
                  </Tooltip>
                </Grid>
              )}
            </Grid>
            {destinations?.length === 0 ? (
              <Paper
                style={{
                  display: "flex",
                  width: "auto",
                  height: 455,
                  marginTop: 20,
                  padding: "20px 40px",
                  border: "1px dashed #A6A6A6",
                  borderRadius: 8
                }}>
                <Tooltip title={disabledAddDestinationActionMessage}>
                  <div style={{ margin: "auto" }}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => addDestination()}
                      disabled={!!disabledAddDestinationActionMessage}>
                      + Destination
                    </Button>
                  </div>
                </Tooltip>
              </Paper>
            ) : (
              <div ref={destinationsContainerRef} style={{ overflow: "hidden" }}>
                {destinations?.map((destination: IDestination, index: number) => (
                  <JobDestination
                    key={index}
                    jobData={jobData}
                    datasetsData={datasetsData}
                    dataSourcesData={dataSourcesData}
                    destinations={destinations}
                    setDestinations={setDestinations}
                    destination={destination}
                    index={index}
                    isAllDestinationsValid={isAllDestinationsValid}
                    setIsSaved={setIsSaved}
                    isDestinationSaving={isDestinationSaving}
                    setIsDestinationSaving={setIsDestinationSaving}
                    deletingDestinationId={deletingDestinationId}
                    setDeletingDestinationId={setDeletingDestinationId}
                    refetchDestinations={refetchDestinations}
                  />
                ))}
              </div>
            )}
          </>
        )}
      </Box>
    </Drawer>
  );
};

export default JobDestinations;
