import React, { useState, ChangeEvent, useMemo, useEffect } from "react";
import _ from "lodash";
import { Grid, makeStyles } from "@material-ui/core";
import { useQueryClient } from "@tanstack/react-query";

import DataSourceCard from "./DataSourceCard";
import useCreateConnector from "src/hooks/api/dataSources/useCreateConnector";
import { DataSourcesHelperText, IConnector, dataSourcesTypes } from "./utils/DataSources.constants";
import { Field } from "src/components";
import { Modal, Search } from "src/components/custom";
import { dataSourcesGetter, dataSourcesSetter } from "stores/zustand/stores.selectors";
import { getEnsuredDataConnectorsData } from "src/hooks/api";
import { useDataSourcesStore } from "stores/zustand/stores";
import { getDataSources } from "services/Apis/wrappers/dataSources";

interface IProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (id: string) => void;
}

const useStyles = makeStyles(() => ({
  container: {
    "& > div": {
      marginBottom: "25px"
    },
    "& > .MuiGrid-item": {
      padding: "8px 8px 20px 8px",
      background: "#f0f0f0",
      borderRadius: "6px"
    },

    "& .MuiTextField-root": {
      width: "50%",
      marginBottom: 0,
      marginLeft: "10px"
    },

    "& #subTopbarSearch": {
      paddingLeft: 0
    }
  },
  card: {
    display: "inline-block",
    margin: 10,
    width: 204,
    borderRadius: 12,
    boxShadow: "none",
    border: "1px solid #E0E0E0",
    cursor: "pointer",
    "&.active": {
      background: "#ECEFF2",
      border: "1px solid #415F80"
    },
    "& img": {
      width: "32px",
      height: "32px"
    }
  },
  connectorContainer: {
    overflow: "auto",
    marginTop: "10px"
  }
}));

const defaultConnectors = _.map(dataSourcesTypes, ({ name, displayName, image }) => ({
  name,
  displayName,
  image,
  type: "default" as const,
  isSelected: false
}));

const SelectDataSourceModal = (props: IProps) => {
  const { open, onClose, onSubmit } = props;

  const queryClient = useQueryClient();
  const dataSourcesStore = useDataSourcesStore(dataSourcesGetter);
  const setDataSourcesStore = useDataSourcesStore(dataSourcesSetter);

  const [searchText, setSearchText] = useState("");
  const [connectorName, setConnectorName] = useState("");

  const [connectors, setConnectors] = useState<$TSFixMe>([...defaultConnectors]);

  useEffect(() => {
    const _ = async () => {
      const dataSourcesResponse = await getDataSources();
      setDataSourcesStore(dataSourcesResponse);
    };

    dataSourcesStore?.length === 0 && _();
  }, []);

  useEffect(() => {
    const _ = async () => {
      const dataConnectorsData: $TSFixMe = await getEnsuredDataConnectorsData({ queryClient });

      if ((dataConnectorsData || [])?.length > 0) {
        setConnectors(() => [...dataConnectorsData]);
      }
    };

    _();
  }, []);

  const classes = useStyles();
  const createConnector = useCreateConnector();

  const checkIsDuplicate = (name: string) => {
    if (dataSourcesStore?.length === 0) return false;

    return dataSourcesStore?.some((dataSource: $TSFixMe) => {
      return dataSource?.name?.toLowerCase() === name?.toLowerCase()?.trim();
    });
  };

  const checkError = () => {
    if (_.size(connectorName) < 3 || _.size(connectorName) > 64) {
      return "Connector name needs to be between 3 and 64 characters.";
    }
    if (checkIsDuplicate(connectorName)) {
      return DataSourcesHelperText.DataSourceNameExists;
    }
    if (
      (!/^(?:[a-zA-Z0-9 _-]*)?$/.test(connectorName) ||
        /^(?:[_ -]*)?$/.test(connectorName) ||
        /^(?:[_-]*)?$/.test(connectorName.substring(0, 1))) &&
      _.size(connectorName) !== 0
    ) {
      return "The connector name contains invalid characters. It needs to start with alphanumerics and can only contain alphanumerics, spaces, underscores and dashes.";
    }
    return null;
  };

  const { selectedConnector, disableSubmit, error } = useMemo(() => {
    const selectedConnector = _.find(connectors, { isSelected: true });

    const errorVal = checkError();

    const disableSubmit =
      _.isEmpty(selectedConnector) || (selectedConnector.type === "fivetran" && !!errorVal);
    return { selectedConnector, disableSubmit, error: errorVal };
  }, [connectors, connectorName, dataSourcesStore]);

  const filteredConnectors = useMemo(() => {
    if (searchText) {
      const str = _.toLower(searchText);
      return _.filter(connectors, (val) => _.includes(_.toLower(val.displayName), str));
    }
    return connectors;
  }, [connectors, searchText]);

  const handleSubmit = () => {
    if (selectedConnector && selectedConnector?.name) {
      if (selectedConnector.type === "default") {
        onSubmit(selectedConnector.name);
      } else {
        createConnector.mutate({
          id: selectedConnector?.name,
          name: connectorName,
          iconUrl: selectedConnector.url
        });
      }
    }
  };

  const handleClick = (id?: string) => {
    if (selectedConnector?.name !== id) {
      setConnectors(
        _.map(connectors, (val) => ({
          ...val,
          isSelected: val.name === id
        }))
      );
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setConnectorName(e.target.value);
  };

  return (
    <Modal
      open={open}
      size="md"
      title="New Connection"
      cancelLabel="Close"
      submitLabel="Create Connection"
      isSubmitDisabled={disableSubmit}
      isSubmitting={createConnector.isLoading}
      onClose={onClose}
      onSubmit={handleSubmit}>
      <Grid container className={classes.container}>
        <Grid container item xs={12}>
          <Grid item xs={12}>
            {searchText ? (
              <div style={{ margin: "10px", fontSize: "14px" }}>{`${_.size(
                filteredConnectors
              )} Matching results found out of total ${_.size(
                connectors
              )} Connectors available`}</div>
            ) : (
              <div style={{ margin: "10px", fontSize: "14px" }}>{`${_.size(
                connectors
              )} Connectors available`}</div>
            )}
            <Search
              test-id="data-connectors-search-field"
              onSearch={handleChange}
              placeholder="Search connector name"
              InputProps={{ style: { border: "1px solid #e0e0e0", width: 205 } }}
            />
            <div
              className={classes.connectorContainer}
              style={{ maxHeight: "calc(100vh - 425px)" }}>
              {_.map(
                filteredConnectors,
                ({ name, displayName, image, url, type }: IConnector, index: number) => (
                  <DataSourceCard
                    key={`${name}_${index}`}
                    id={`${name}_${index}`}
                    classNames={`${classes.card} ${selectedConnector?.name === name && "active"}`}
                    onClick={() => handleClick(name)}
                    image={
                      type === "default" && image ? image : <img src={url} alt={displayName} />
                    }
                    displayName={displayName}
                  />
                )
              )}
            </div>
          </Grid>
        </Grid>
        {selectedConnector?.type === "fivetran" && (
          <Field
            id="connector-name"
            label="Name"
            style={{ marginLeft: 0, marginBottom: 0 }}
            value={connectorName}
            error={!!error}
            helperText={error}
            disabled={createConnector.isLoading}
            required
            variant="outlined"
            size="small"
            onChange={handleInputChange}
          />
        )}
      </Grid>
    </Modal>
  );
};

export default SelectDataSourceModal;
