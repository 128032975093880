import React from "react";

import { PlayArrow, Stop, Refresh } from "@material-ui/icons";
import { EnvDtoLaunchStatusEnum } from "@rapidcanvas/rc-api-core";

export enum EnvironmentDeleteMessage {
  title = "Delete Environment",
  messageLine1 = "Do you really want to delete this environment?",
  messageLine2 = "If you confirm you will delete the environment completely",
  deleteSuccessMsg = "Environment deleted successfully!"
}

export enum ConfirmCleanCustomPackagesPromptDetails {
  title = "Confirm Clean Custom Libraries",

  messageLine1 = "This action removes custom libraries installed manually or through recipes in this environment and restarts it.",
  messageLine2 = "Are you sure?"
}

export enum EnvironmentsHelperText {
  NewEnvironment = "New Environment",
  NameInfo = "The name of the workspace-level default environment is non-editable",
  DeleteInfo = "Cannot delete a launching environment",
  UsageInfo = "Resource usage data can be accessed when the environment is in a running state",

  DiskSpaceInfo = "Disk space cannot be modified once the environment is created.",
  DescriptionHelperText = "Provide additional information about this environment. The information can be up to 1500 characters long.",
  Save = "Save"
}

export enum EnvironmentHelperText {
  ClearingCacheInfo = "Please wait. Clearing cache.",
  CleaningCustomPackagesInfo = "Please wait. Cleaning custom packages.",
  CleanActionsDisabledInfo = "This action is enabled when the environment is in running state. Please run the environment to enable this action."
}

export const PythonLibrariesInfoLines = [
  "To install the latest available version of a specific library, simply state the library's name.",
  "To install a specific version of a library, use the format library_name==version, as shown in the example numpy==1.19.5"
];

export const PythonLibrariesAdditionalInfo =
  "Libraries specified at the recipe's template level take precedence over those at the environment level. If a library is specified at the template level, then the libraries specified here will not be considered.";

export const LinuxLibrariesInfoLines = [
  "To install the latest available version of a specific library, simply state the library's name.",
  "To install a specific version of a library, use the format library_name=version, as shown in the example tesseract-ocr=4.1.1-2.1"
];

export const statusValues: Record<
  string,
  {
    text: string;
    buttonText?: string;
  }
> = {
  shutdown: { text: "Shutdown", buttonText: "START" },
  launching: { text: "Launching...", buttonText: "CHECK LOG" },
  stopping: { text: "Stopping..." },
  success: { text: "Running", buttonText: "STOP" },
  failed: { text: "Failed", buttonText: "RELAUNCH" }
};

export const EnvironmentStatusValuesMapping = {
  [EnvDtoLaunchStatusEnum.Shutdown]: {
    displayName: "Shutdown",
    icon: <PlayArrow fontSize="small" />,
    buttonText: "Start",
    cssClassName: "shutdown"
  },
  [EnvDtoLaunchStatusEnum.Launching]: { displayName: "Launching...", cssClassName: "launching" },
  [EnvDtoLaunchStatusEnum.Stopping]: { displayName: "Stopping...", cssClassName: "stopping" },
  [EnvDtoLaunchStatusEnum.Success]: {
    displayName: "Running",
    icon: <Stop fontSize="small" />,
    buttonText: "Stop",
    cssClassName: "success"
  },
  [EnvDtoLaunchStatusEnum.Failed]: {
    displayName: "Failed",
    icon: <Refresh fontSize="small" />,
    buttonText: "Relaunch",
    cssClassName: "failed"
  }
};

export type EnvironmentType = {
  cores: number;
  diskInGbs: number;
  gpus: $TSFixMe;
  memInGbs: number;
  memInMbs: number;
  name: string;
};

export const DEFAULT_NAME = "DEFAULT";

export enum EnvironmentsTypes {
  ExtraSmall = "EXTRA_SMALL",
  Small = "SMALL",
  Medium = "MEDIUM",
  Large = "LARGE",
  CpuLarge = "CPU_LARGE",
  MaxLarge = "MAX_LARGE",
  ExtraMaxLarge = "EXTRA_MAX_LARGE",
  Custom = "CUSTOM"
}

export const environmentsTypes: $TSFixMe = {
  [EnvironmentsTypes.ExtraSmall]: "Extra Small",
  [EnvironmentsTypes.Small]: "Small",
  [EnvironmentsTypes.Medium]: "Medium",
  [EnvironmentsTypes.Large]: "Large",
  [EnvironmentsTypes.CpuLarge]: "CPU Large",
  [EnvironmentsTypes.MaxLarge]: "Max Large",
  [EnvironmentsTypes.ExtraMaxLarge]: "Extra Max Large",
  [EnvironmentsTypes.Custom]: "Custom"
};
