import React from "react";
import { isEmpty } from "lodash";
import { Badge, Button, makeStyles } from "@material-ui/core";
import { useThreadsStore } from "stores/zustand/stores";
import AIGuidePlainIcon from "icons/NewUX/AIGuidePlainIcon";

type StyleProps = { badgeStyleProps?: any; badgeDotStyleProps?: any };

const useStyles = makeStyles({
  aiGuideIcon: {
    height: "28px",
    scale: 1.4
  },
  aiGuideBtn: {
    height: "28px",
    width: "28px"
  },

  badgeRoot: (props: StyleProps) => ({
    marginTop: 2,
    marginRight: 2,
    ...(!isEmpty(props?.badgeStyleProps) ? props?.badgeStyleProps : {})
  }),

  badge: (props: StyleProps) => ({
    backgroundColor: "#FF0000",
    border: "1px solid #fff",
    ...(!isEmpty(props?.badgeDotStyleProps) ? props?.badgeDotStyleProps : {})
  })
});

const AIGuideMiniButton = ({
  onClick,
  projectId,
  targetId,
  chartId,
  style,
  width = 24,
  height = 16,
  viewBox = "4 -2 48 48",
  disabled,
  className,
  badgeStyleProps = {},
  badgeDotStyleProps = {}
}: {
  onClick: (event: any) => void;
  projectId: string;
  targetId: string;
  style?: any;
  width?: number;
  height?: number;
  viewBox?: string;
  className?: string;
  disabled?: boolean;
  tooltip?: string;
  chartId?: string;
  badgeStyleProps?: { [key: string]: any };
  badgeDotStyleProps?: { [key: string]: any };
}) => {
  const { aiGuideBtn, badge } = useStyles({
    badgeStyleProps,
    badgeDotStyleProps
  });

  const { projectTargetMap, chartIdMessagesCountMap } = useThreadsStore();
  const messageCount = chartId
    ? chartIdMessagesCountMap.get(projectId)?.get(targetId)?.get(chartId)
    : projectTargetMap.get(projectId)?.get(targetId);

  return (
    <Button
      size="small"
      style={style}
      startIcon={
        <Badge
          variant="dot"
          style={{ width: "100%" }}
          color="error"
          classes={{ colorError: badge }}
          invisible={!messageCount || messageCount === 0}
          overlap="circular">
          <AIGuidePlainIcon viewBox={viewBox} width={width} height={height} fill="white" />
        </Badge>
      }
      onClick={onClick}
      disabled={disabled}
      data-testid="sidebar-entity-ai-guide-icon"
      className={`${aiGuideBtn} ${className ?? ""}`}>
      AI Guide
    </Button>
  );
};

export default AIGuideMiniButton;
