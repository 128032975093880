import { useMutation, UseMutationResult } from "@tanstack/react-query";

import { postAPIWithRethrow } from "services/Apis/Apis.service";

interface IVariables {
  id: string;
  redirectUrl: string;
}

interface IData {
  connectorCardUrl: string;
  connectorId: string;
  status: string;
}

const useCreateConnectCardUrl = (): UseMutationResult<IData, unknown, IVariables, unknown> => {
  return useMutation({
    mutationFn: async ({ id, redirectUrl }) => {
      return await postAPIWithRethrow(`/v2/data-source/${id}/create-connector-card`, {
        redirectUrl
      });
    }
  });
};

export default useCreateConnectCardUrl;
