import React, { useEffect, useMemo, useState } from "react";

import { SourceContext } from "./SourceContext";
import { SourceType } from "../../utils/Dataset.constants";
import { useDataSourcesStore } from "stores/zustand/stores";
import { dataSourcesGetter } from "stores/zustand/stores.selectors";
import {
  DataConnectorNames,
  dataSourcesTypes
} from "src/pages/DataSources/utils/DataSources.constants";
import { thirdPartyTypeName } from "src/pages/DataSources/utils/DataSources.constants";
import { find, forEach, get, has, includes, map, size } from "lodash";
import useStoreSelectors from "../../hooks/useStoreSelectors";
import { toastWrapper } from "services/ToastClient/toastWrapper";
import { useGetConnectorFiles } from "src/hooks/api";
import useProbableDataSets from "src/hooks/api/dataSources/useProbableDataSets";
import useAddFileQueryParam from "../../hooks/useAddFileQueryParam";

type Props = {
  children: React.ReactNode;
};

const SourceContextProvider = (props: Props) => {
  const { children } = props || {};

  const isAddFile = useAddFileQueryParam();

  const probableDataSets = useProbableDataSets();

  const dataSourcesStore = useDataSourcesStore(dataSourcesGetter);
  const { datasetSourceStore } = useStoreSelectors();

  const [sources, setSources] = useState<$TSFixMe>([]);
  const [areDataSourceFilesFetching, setAreDataSourceFilesFetching] = useState<$TSFixMe>(false);
  const [dataSourceFiles, setDataSourceFiles] = useState<$TSFixMe>([]);

  const isAddFileSupported = (dataSourceType?: string) =>
    !!isAddFile
      ? includes(
          [
            DataConnectorNames.S3_STORAGE,
            DataConnectorNames.AZURE_BLOB,
            DataConnectorNames.GCP_STORAGE
          ],
          dataSourceType
        )
      : true;

  useEffect(() => {
    if ((dataSourcesStore || [])?.length > 0) {
      const dataSourcesTypesMap: $TSFixMe = {};

      forEach(dataSourcesTypes, (eachDataSourceType: $TSFixMe) => {
        if (isAddFileSupported(eachDataSourceType.name)) {
          dataSourcesTypesMap[eachDataSourceType.name] = {
            image: eachDataSourceType.image,
            isSql: eachDataSourceType.isSql || false
          };
        }
      });

      let apiSources: $TSFixMe = [];

      forEach(dataSourcesStore, (dataSource: $TSFixMe) => {
        if (!isAddFile && dataSource.dataSourceType === thirdPartyTypeName) {
          apiSources.push({
            ...dataSource,
            isSql: false,
            image: <></>,
            dataSourceType: dataSource.tpConnectorType,
            isFivetran: true
          });
        }

        if (has(dataSourcesTypesMap, dataSource.dataSourceType)) {
          apiSources.push({
            ...dataSource,
            image: get(dataSourcesTypesMap, [dataSource.dataSourceType, "image"]) || <></>,
            isSql: get(dataSourcesTypesMap, [dataSource.dataSourceType, "isSql"]) || false
          });
        }
      });

      setSources([...sources, ...apiSources]);
    }
  }, [dataSourcesStore, isAddFile]);

  const getFivetranDataSourceFiles = async () => {
    probableDataSets.mutate(
      { id: datasetSourceStore?.value },
      {
        onSuccess: (response: $TSFixMe) => {
          setAreDataSourceFilesFetching(false);
          setDataSourceFiles(
            map(response?.probableDatasetsInternal, (path) => ({
              path,
              payloadPath:
                size(response.probableDatasetsInternal) === 1
                  ? response.probableDatasetOriginalName
                  : path
            }))
          );
        },
        onError: () => {
          toastWrapper({
            type: "error",
            content: "An error occurred while fetching files. Please try again."
          });
          setAreDataSourceFilesFetching(false);
        }
      }
    );
  };

  const {
    isLoading: isFetchedConnectorFiles,
    mutateAsync: connectorFilesMutation,
    reset: resetConnectorFilesMutation
  } = useGetConnectorFiles();

  useEffect(() => {
    setAreDataSourceFilesFetching(isFetchedConnectorFiles);
  }, [isFetchedConnectorFiles]);

  const onConnectorFilesError = (filesResponse: any) => {
    toastWrapper({
      type: "error",
      content:
        Object.keys(filesResponse?.messages[0] || {})?.[0] ??
        "An error occurred while fetching files from the cloud. Please try again."
    });
  };

  const getDataSourceFiles = async (filePath = "/", isFivetran?: boolean) => {
    setDataSourceFiles([]);

    if (isFivetran) {
      setAreDataSourceFilesFetching(true);
      await getFivetranDataSourceFiles();
      return;
    }

    resetConnectorFilesMutation();

    const payload = {
      id: datasetSourceStore?.value,
      filePath
    };

    await connectorFilesMutation(payload, {
      onSuccess: (data: $TSFixMe) => {
        if (data?.status === "SUCCESS") {
          setDataSourceFiles(data?.files);
        } else {
          onConnectorFilesError(data);
        }
      },
      onError: onConnectorFilesError
    });
  };

  const onSourceOrCriterionChange = () => {
    const isFiveTran = get(find(sources, { id: datasetSourceStore?.value }), "isFivetran") ?? false;

    if (datasetSourceStore?.value !== SourceType.FileUpload) {
      getDataSourceFiles("/", isFiveTran);
    }
  };

  const value = useMemo(
    () => ({
      areDataSourceFilesFetching,
      sources,
      dataSourceFiles,
      onSourceOrCriterionChange,
      getDataSourceFiles
    }),
    [
      areDataSourceFilesFetching,
      sources,
      dataSourceFiles,
      onSourceOrCriterionChange,
      getDataSourceFiles
    ]
  );

  return <SourceContext.Provider value={value}>{children}</SourceContext.Provider>;
};

export default SourceContextProvider;
