import { UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query";

import { deleteAPIWithRethrow } from "services/Apis/Apis.service";
import { QUERY_KEY_NOTIFICATIONS } from "./useGetNotifications";

const useClearAllNotifications = (): UseMutationResult<unknown, unknown, {}, unknown> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () => {
      const data = await deleteAPIWithRethrow(`/v2/env-alerts/deleteAll`, {});
      return data;
    },
    onSuccess: () => {
      queryClient.setQueryData([QUERY_KEY_NOTIFICATIONS], []);
    }
  });
};

export default useClearAllNotifications;
