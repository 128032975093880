import React, { useState, useMemo } from "react";
import { Grid, makeStyles } from "@material-ui/core";

import Drawer from "components/Drawer/CustomDrawer";
import useDataAppStatus from "hooks/api/dataapps/useDataAppStatus";
import { DataAppType } from "../DataApps.type";
import { LogsContent } from "pages/Projects/common/ShowLogsModal/LogsContent";
import {
  ShowLogsDrawerTitle,
  getStatusEquivalence
} from "pages/Projects/common/ShowLogsModal/ShowLogsDrawerTitle";
import { TEMP_MSG, useStyles } from "pages/Projects/common/ShowLogsModal/RecipeLogsDrawer";
import { isInProgress } from "hooks/api/dataapps/useDataAppStatusById";
import { useGetDataAppLogs } from "hooks/api/dataapps/useGetDataAppLogs";
import useExportDataappLogs from "src/pages/Projects/common/useExportDataappLogs";

const useDrawerStyles = makeStyles({
  drawer: {
    width: 600,
    flexShrink: 0
  },
  drawerPaper: {
    width: 600
  }
});

interface IProps {
  open: boolean;
  dataApp: DataAppType;
  onClose: () => void;
}

const DataAppLogsDrawer: React.FC<IProps> = (props) => {
  const { open, dataApp, onClose } = props || {};
  const exportDataappLogs = useExportDataappLogs();

  const { id, name, launchStatus } = dataApp;
  const classes = useStyles();
  const { drawer, drawerPaper } = useDrawerStyles();

  // These logs will be stale in live mode when healthz status api call or dataApp logs call is in progress
  const [searchValue, setSearchValue] = useState("");

  const { currentStatusInfo, status } = useDataAppStatus(id, launchStatus);

  const { isInitialLoading, data: displayedLogs } = useGetDataAppLogs({
    dataAppId: id,
    mode: status && isInProgress(status) ? "live" : "offline",
    enabled: open && !!status
  });

  const handleSearchValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const filteredLogs = useMemo(() => {
    return (displayedLogs || [])?.filter((eachField: $TSFixMe) => {
      return searchValue ? eachField?.toLowerCase()?.includes(searchValue?.toLowerCase()) : true;
    });
  }, [searchValue, displayedLogs]);

  const backgroundColor = useMemo(() => {
    switch (currentStatusInfo?.alertType) {
      case "info":
        return "rgb(229, 246, 253)";
      case "success":
        return "rgb(237, 247, 237)";
      case "error":
        return "rgb(253, 237, 237)";
      default:
        return "";
    }
  }, [currentStatusInfo]);

  const exportLogs = async () => {
    await exportDataappLogs.mutateAsync({ dataaapId: id, name });
  };

  const title = (
    <ShowLogsDrawerTitle
      exportDisabledMessage="Please wait until DataApp launch is complete"
      exportDisabled={status === "LAUNCHING"}
      title={name}
      url={`/dataapps-ui/${id}/${name}/logs`}
      {...(currentStatusInfo && status
        ? {
            status: {
              color: getStatusEquivalence(status),
              text: currentStatusInfo?.text
            }
          }
        : {})}
      searchValue={searchValue}
      exportLogs={exportLogs}
      exportLogLoading={exportDataappLogs.isLoading}
      statusComponent={
        <div
          style={{
            backgroundColor,
            borderRadius: "6px",
            fontWeight: 400,
            padding: "9px 8px",
            textTransform: "capitalize",
            fontSize: 14,
            width: "max-content"
          }}>
          {currentStatusInfo?.text}
        </div>
      }
      onSearch={handleSearchValueChange}
      onClose={onClose}
    />
  );

  return (
    <Drawer
      anchor="right"
      variant="temporary"
      open={open}
      titleStyle={{ padding: 0, borderBottom: "1px solid #E0E0E0", lineHeight: 1 }}
      hideCloseButton
      classes={{
        paper: drawerPaper
      }}
      className={drawer}
      title={title}
      onClose={onClose}>
      <Grid container direction="column" justifyContent="center" className={classes.autoMlLogs}>
        {isInitialLoading || !currentStatusInfo ? (
          <LogsContent logs={TEMP_MSG} />
        ) : (
          <LogsContent logs={filteredLogs} />
        )}
      </Grid>
    </Drawer>
  );
};

export default DataAppLogsDrawer;
