import React from "react";

import SubTopNavBarWrapper from "layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import SubTopNavBarBreadcrumbs from "./SubTopNavBarBreadcrumbs";
import { useProjectContext } from "projectsModule/context/useProjectContext";

interface IProps {
  dataAppName: string;
  onSave: (val: string) => void;
  onShowDataApps: () => void;
  shouldResetDataAppName?: boolean;
  extra?: React.JSX.Element;
}

const CreateDataAppHeader: React.FC<IProps> = (props) => {
  const { dataAppName, shouldResetDataAppName, extra, onSave, onShowDataApps } = props;
  const { project } = useProjectContext() || {};

  return (
    <SubTopNavBarWrapper
      subTopNavBarLeftSection={{
        component: (
          <SubTopNavBarBreadcrumbs
            project={project}
            dataAppName={dataAppName}
            shouldResetDataAppName={shouldResetDataAppName}
            onSave={onSave}
            onShowDataApps={onShowDataApps}
          />
        )
      }}
      subTopNavBarRightSection={{
        component: extra
      }}
    />
  );
};

export default CreateDataAppHeader;
