import React, { useMemo } from "react";
import _, { map, sumBy, trim, without } from "lodash";
import { Typography, makeStyles } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";

import CommonLoader from "src/components/CommonLoader";
import NoDataFoundDefault from "src/pages/common/NoDataFoundDefault";
import RecentSearches from "./RecentSearches";
import SearchResponseComponent from "./SearchResponseComponent";
import useDebounce from "src/hooks/useDebounce";
import useGlobalSearch from "src/hooks/api/globalSearch/useGlobalSearch";
import { SearchRequestDtoSearchComponentsEnum } from "@rapidcanvas/rc-api-core";
import { allKey } from "../../../utils/GlobalSearch.constants";

interface IProps {
  searchText: string;
  searchComponents: string[];
  onNavigate: () => void;
  onSearchTextChange: (text: string) => void;
}

const useStyles = makeStyles({
  popover: {
    maxHeight: "400px",
    height: "calc(100vh - 60px)",
    overflow: "auto",
    width: "100%"
  },
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingRight: "12px"
  },
  italics: {
    fontSize: "12px",
    display: "flex",
    alignItems: "center",
    gap: "10px"
  },
  typography: {
    fontSize: "12px",
    marginBottom: "16px",
    marginTop: "16px",
    color: "#B2B2B2",
    marginLeft: "24px"
  },
  flexStart: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%"
  },
  ellipsis: {
    wordBreak: "break-word"
  }
});

const GlobalSearchPopoverContent: React.FC<IProps> = (props) => {
  const { searchText, searchComponents, onNavigate, onSearchTextChange } = props;

  const { popover, flexCenter, italics, typography, flexStart, ellipsis } = useStyles();

  const values = useMemo(
    () => without(searchComponents, allKey) as SearchRequestDtoSearchComponentsEnum[],
    [searchComponents, allKey]
  );

  const trimmed = useMemo(() => trim(searchText), [searchText]);

  const [debounceSearchQuery] = useDebounce(trimmed);

  const { data, isFetching, isLoading } = useGlobalSearch(debounceSearchQuery, values);

  const count = useMemo(
    () => sumBy(_.values(data?.searchResults), "length"),
    [data?.searchResults]
  );

  const handleClear = () => {
    onSearchTextChange("");
  };

  if (trimmed.length < 3 && trimmed.length > 0) {
    return (
      <div className={flexStart} data-testid="globalSearchRecentSearches">
        <i className={italics}>
          <InfoOutlined style={{ fontSize: "1rem" }} /> A minimum of 3 characters is required to
          perform a search!
        </i>
        <RecentSearches onSearchTextChange={onSearchTextChange} />
      </div>
    );
  }

  if (isLoading || isFetching) {
    return <CommonLoader />;
  }

  if (count === 0) {
    return (
      <div className={`${popover} ${flexCenter}`}>
        <NoDataFoundDefault
          style={{ marginBottom: 0 }}
          wrapperStyle={{ alignItems: "flex-start", gap: "8px" }}
          btnStyle={{ flexDirection: "column" }}
          minHeight="300px"
          title={<span className={ellipsis}>No matching results found</span>}
          onClear={handleClear}
        />
      </div>
    );
  }

  return (
    <div className={popover}>
      <Typography
        className={typography}
        data-testid="globalSearchRecentText"
        variant="body2">{`Found ${count} Result${count > 1 ? "s" : ""}`}</Typography>
      {map(data?.searchResults, (val, key) => (
        <SearchResponseComponent
          searchText={trimmed}
          key={key}
          type={key as SearchRequestDtoSearchComponentsEnum}
          records={val}
          onNavigate={onNavigate}
        />
      ))}
    </div>
  );
};

export default GlobalSearchPopoverContent;
