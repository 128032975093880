import React, { useCallback, useMemo } from "react";

import { isEmpty, isNil, uniq } from "lodash";

import { Tooltip } from "@material-ui/core";

import { DATA_TYPES_ICONS } from "src/assets/icons";

import styles from "./ViewDataDataTable.module.scss";
import Table from "src/components/custom/TableV8/Table";
import { CellContext } from "@tanstack/react-table";

const getElementWithTooltip = ({ value = "", customStyles }: $TSFixMe) => (
  <Tooltip title={value.length >= 15 ? value : ""}>
    <span className={`${styles.cell} ${customStyles}`}>{value}</span>
  </Tooltip>
);

const ViewDataDataTable = ({
  responseData,
  isLoadingData,
  entityFeatures,
  columnOptionsCustomRender,
  onColumnChange,
  hideCount = true,
  maxHeight = "calc(100vh - 179px)",
  globalFilter,
  showSample
}: $TSFixMe) => {
  const iconSelection = useCallback(
    (column: $TSFixMe) => {
      const fieldSchema = entityFeatures?.find(
        (entityFeatureInternal: $TSFixMe) => entityFeatureInternal?.name === column
      )?.fieldSchema;
      return (
        fieldSchema && (
          <span className={styles.iconContainer}>
            {
              (DATA_TYPES_ICONS as $TSFixMe)[
                fieldSchema?.fieldProperties?.idLikeColumn ? "ID" : fieldSchema?.rcDataType
              ]
            }
          </span>
        )
      );
    },
    [entityFeatures]
  );

  const fullListOfColumns = useMemo(() => {
    return uniq([
      ...(responseData?.columns || []),
      ...(entityFeatures?.map((item: $TSFixMe) => item?.name) || [])
    ]);
  }, [entityFeatures, responseData?.columns]);

  const tableColumns = useMemo(() => {
    return (fullListOfColumns || []).map((column: $TSFixMe, index: number) => {
      const dataType = entityFeatures?.find(
        (entityFeatureInternal: $TSFixMe) => entityFeatureInternal?.name === column
      )?.fieldSchema?.rcDataType;
      return {
        id: column || `column_${index}`,
        header: column || "Unknown",
        accessorKey: column,
        meta: {
          icon: iconSelection(column),
          data: {
            dataType
          },
          name: column
        },

        cell: ({ row, column }: CellContext<any, unknown>) => {
          const data = column.columnDef.meta?.data;
          const name = column.columnDef.meta?.name as string;
          if (data.dataType === "DOUBLE" || data.dataType === "FLOAT") {
            const value = row.original[name];
            const formattedValue = value ? Number(value).toFixed(2) : value;
            return getElementWithTooltip({ value: formattedValue });
          }
          return getElementWithTooltip({ value: row.original[name] });
        },
        ...(!!columnOptionsCustomRender && { width: 250 })
      };
    });
  }, [fullListOfColumns, entityFeatures, iconSelection, columnOptionsCustomRender]);

  const tableData =
    responseData?.tableData ||
    (!responseData?.rows || !responseData?.columns
      ? []
      : responseData?.rows?.reduce((responseDataAcc: $TSFixMe, responseDataCurr: $TSFixMe) => {
          !isNil(responseDataCurr?.cells) &&
            responseDataAcc.push(
              responseDataCurr?.cells?.reduce(
                (cellAcc: $TSFixMe, cellCurr: $TSFixMe, cellIndex: $TSFixMe) => {
                  cellAcc[responseData?.columns[cellIndex]] =
                    !!cellCurr &&
                    typeof cellCurr === "string" &&
                    ["nan"].includes(cellCurr?.trim()?.toLowerCase())
                      ? ""
                      : cellCurr;

                  return cellAcc;
                },
                {}
              )
            );

          return responseDataAcc;
        }, []));
  return (
    <Table
      columns={tableColumns ?? []}
      infoProps={{ hideCount, showSample }}
      stylesProps={{ fixedLayout: true, maxHeight, isTheadSticky: true }}
      columnsProps={{
        columnOptionsCustomRender,
        onSelectedColumnChange: onColumnChange,
        hiddenColumns: fullListOfColumns.slice(50)
      }}
      sortingProps={{ unsorted: true }}
      filterProps={{ globalFilter }}
      data={tableData ?? []}
      isLoading={isLoadingData}
      showTypes={!isEmpty(entityFeatures)}
    />
  );
};

export default React.memo(ViewDataDataTable);
