import { FindProjectRunsJobTypeEnum } from "@rapidcanvas/rc-api-core";
import { useMutation, UseMutationResult } from "@tanstack/react-query";

import api from "services/AxiosClient/AxiosClient";

export const enum UsePublishJobsQueryKeys {
  PublishJobs = "publishJobs"
}

interface IPublishJobProps {
  projectId?: string;
  type: FindProjectRunsJobTypeEnum;
}

const usePublishJob = (props?: IPublishJobProps): UseMutationResult<any, unknown, any, unknown> => {
  const { projectId, type } = props || {};

  return useMutation({
    mutationKey: [UsePublishJobsQueryKeys.PublishJobs, projectId, type],
    mutationFn: async (jobId: string) =>
      await api.fetchResponse(
        async () => await api.ProjectRunControllerApi.publishProjectRunMeta(jobId)
      )
  });
};

export default usePublishJob;
