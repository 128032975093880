import React, { useState } from "react";

// Packages
import { Header } from "@tanstack/react-table";
import clsx from "clsx";

// MUI
import Grid from "@material-ui/core/Grid";
import MuiTableCell from "@material-ui/core/TableCell";
import Box from "@material-ui/core/Box";

// Icons
import MoreVertRoundedIcon from "@material-ui/icons/MoreVertRounded";

// Components
import { OverflowTooltip } from "src/components";
import MoreOptions from "./MoreOptions";
import HeaderName from "./HeaderName";

// Contexts
import { useTableContext } from "../../context/useTableContext";

// Types
import { TData } from "../../Table.types";

// Styles
import useStyles from "./TableCell.styles";
import { get, isEmpty } from "lodash";
import DataSummaryCharts from "pages/ViewData/TableData/DataSummaryCharts";

type Props = {
  header: Header<TData, string>;
  showSummary: boolean;
  isSummaryAvailable: boolean;
  isChartAvailable: boolean;
};

const TableCell = (props: Props) => {
  const { header, showSummary, isSummaryAvailable, isChartAvailable } = props || {};

  const classes = useStyles();

  const { resizeColumns } = useTableContext();

  const [anchorMoreOptionsEl, setMoreOptionsAnchorEl] = useState<SVGElement | null>(null);

  const onMoreOptionsClick = (event: React.MouseEvent<SVGElement, MouseEvent>) => {
    setMoreOptionsAnchorEl(() => event?.currentTarget);
  };
  let chartData = undefined;
  try {
    chartData = JSON.parse(header?.column?.columnDef?.meta?.meta.chartData ?? "{}");
  } catch (e) {
    console.error(e);
  }

  return (
    <>
      {Boolean(anchorMoreOptionsEl) && (
        <MoreOptions
          anchorMoreOptionsEl={anchorMoreOptionsEl}
          setMoreOptionsAnchorEl={setMoreOptionsAnchorEl}
          column={header?.column}
        />
      )}

      <MuiTableCell className={classes.root} style={{ width: header?.getSize() }}>
        <Grid container>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            wrap="nowrap"
            className={clsx("headerContainer", {
              sortable: header?.column?.getCanSort()
            })}>
            <HeaderName header={header} />
            {header?.column?.id !== "metaDataColumn" && (
              <MoreVertRoundedIcon
                className="preventHeaderClick"
                fontSize="small"
                onClick={onMoreOptionsClick}
              />
            )}
          </Grid>
          {showSummary && isChartAvailable && (
            <Grid
              container
              className="schemaContainer"
              style={{
                background: header?.column?.id === "metaDataColumn" ? "#E7E7FF" : undefined,
                height: "150px",
                padding: !isEmpty(chartData) ? 0 : undefined,
                alignItems: "center"
              }}>
              {!isEmpty(chartData) ? (
                <DataSummaryCharts chartData={chartData} />
              ) : (
                <OverflowTooltip
                  style={{ flexGrow: 1, maxWidth: 250, whiteSpace: "nowrap" }}
                  value={"Charts"}
                />
              )}
            </Grid>
          )}
          <Grid
            container
            className="schemaContainer"
            style={{
              background: header?.column?.id === "metaDataColumn" ? "#E7E7FF" : undefined
            }}>
            <OverflowTooltip
              style={{ flexGrow: 1, maxWidth: 250, whiteSpace: "nowrap" }}
              value={header?.column?.columnDef?.meta?.meta.rcDataType ?? "Data Type"}
            />
          </Grid>
          {showSummary && isSummaryAvailable && (
            <Grid
              container
              className="schemaContainer"
              style={{
                background: header?.column?.id === "metaDataColumn" ? "#E7E7FF" : undefined
              }}>
              <OverflowTooltip
                style={{ flexGrow: 1, maxWidth: 250, whiteSpace: "nowrap" }}
                value={header?.column?.columnDef?.meta?.meta.uniqueValues ?? "Unique Values"}
              />
            </Grid>
          )}

          {showSummary && isSummaryAvailable && (
            <Grid
              container
              className="schemaContainer"
              style={{
                background: header?.column?.id === "metaDataColumn" ? "#E7E7FF" : undefined
              }}>
              <OverflowTooltip
                style={{ flexGrow: 1, maxWidth: 250, whiteSpace: "nowrap" }}
                value={
                  get(header?.column?.columnDef?.meta?.meta, "nullPercentage") ??
                  (header?.column?.id === "metaDataColumn" ? "Null Percentage" : 0)
                }
              />
            </Grid>
          )}
        </Grid>
        <Box
          {...{
            onDoubleClick: () => resizeColumns(),
            onMouseDown: header?.getResizeHandler(),
            onTouchStart: header?.getResizeHandler(),
            className: `reSizer ${header?.column?.getIsResizing() ? "isResizing" : ""}`
          }}
        />
      </MuiTableCell>
    </>
  );
};

export default TableCell;
