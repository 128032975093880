import React, { useMemo } from "react";

import makeStyles from "@material-ui/core/styles/makeStyles";
import { Grid, IconButton, Paper, Typography } from "@material-ui/core";

import TuneRoundedIcon from "@material-ui/icons/TuneRounded";
import { ExpandIcon, CollapseIcon } from "icons/NewUX";
import ProjectDropdown from "./ProjectDropdown";
import SourceDropdown from "./SourceDropdown";
import CriterionDropdown from "./CriterionDropdown";
import Info from "./Info";
import useHelpers from "../../hooks/useHelpers";
import { useSourceContext } from "../../contexts/Source/useSourceContext";
import { isEmpty } from "lodash";
import useStoreSelectors from "../../hooks/useStoreSelectors";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "inherit",
    borderRadius: theme.spacing(1.5),
    padding: theme.spacing(2),
    overflow: "auto"
  },
  header: {
    marginBottom: theme.spacing(2)
  },
  container: {
    rowGap: theme.spacing(2)
  }
}));

const SelectionSection = () => {
  const { sources } = useSourceContext();
  const { source, isDataSourcesFilesUpload } = useHelpers();

  const {
    datasetIsSelectionSectionOpenStore,
    setDatasetIsSelectionSectionOpenStore,
    datasetSourceStore
  } = useStoreSelectors();

  const classes = useStyles();

  const isCriterionDropdown = useMemo(() => {
    if (isEmpty(sources) || !datasetSourceStore?.value || !isDataSourcesFilesUpload) {
      return true;
    }

    return !sources?.find((eachSource: $TSFixMe) => eachSource?.id === datasetSourceStore?.value)
      ?.isSql;
  }, [datasetSourceStore, sources]);

  return (
    <Paper className={classes.root}>
      {!!datasetIsSelectionSectionOpenStore ? (
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          className={classes.header}>
          <TuneRoundedIcon />
          <IconButton
            onClick={() => setDatasetIsSelectionSectionOpenStore(false)}
            size="small"
            data-testid="selectionSectionCloseAction">
            <Typography color="textSecondary">
              <CollapseIcon />
            </Typography>
          </IconButton>
        </Grid>
      ) : (
        <IconButton
          onClick={() => setDatasetIsSelectionSectionOpenStore(true)}
          size="small"
          data-testid="selectionSectionOpenAction">
          <Typography color="textSecondary">
            <ExpandIcon />
          </Typography>
        </IconButton>
      )}
      {!!datasetIsSelectionSectionOpenStore && (
        <Grid container direction="column" className={classes.container}>
          <Grid item style={{ width: "100%" }}>
            <ProjectDropdown />
          </Grid>
          <Grid item>
            <SourceDropdown />
          </Grid>
          {isCriterionDropdown && (
            <Grid item>
              <CriterionDropdown isDataSourcesFilesUpload={isDataSourcesFilesUpload} />
            </Grid>
          )}
          {!source?.isSql && (
            <Grid item>
              <Info isDataSourcesFilesUpload={isDataSourcesFilesUpload} />
            </Grid>
          )}
        </Grid>
      )}
    </Paper>
  );
};

export default SelectionSection;
