import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import _, { isEqual } from "lodash";
import { Grid, Tooltip } from "@material-ui/core";

import NewModal from "components/Modal/NewModal";
import NewThemeWrapper from "src/styles/NewThemeWrapper";

import MonacoEditor from "react-monaco-editor";
import { InfoOutlined } from "@material-ui/icons";

interface IProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  requirement: string;
  handleSave: (requirements: string) => void;
  isLoading: boolean;
  isSaving: boolean;
}

const RequirementModal: React.FC<IProps> = (props) => {
  const { open, setOpen, requirement, handleSave, isLoading } = props;
  const [value, setValue] = useState("");

  useEffect(() => {
    if (open) {
      const formattedRequirements = requirement.split(/\s+/).filter(Boolean).join("\r\n");
      setValue(formattedRequirements);
    }
  }, [open, requirement]);

  const disabled = useMemo(() => {
    const formattedRequirements = requirement.split(/\s+/).filter(Boolean);
    const formattedValue = value.split(/\s+/).filter(Boolean);
    return isEqual(formattedRequirements, formattedValue);
  }, [requirement, value]);

  return (
    <NewThemeWrapper>
      <NewModal
        header={
          <Grid container alignItems="center" style={{ gap: "5px" }}>
            Requirements
            <Tooltip title={"Libraries should be separated by either spaces or new lines."}>
              <InfoOutlined style={{ fontSize: "18px", color: "rgba(0, 0, 0, 0.54)" }} />
            </Tooltip>
          </Grid>
        }
        open={open}
        data-testid="api-connector-requirement"
        width={600}
        background="#fff"
        keepMounted={false}
        cancelButtonLabel="Close"
        submitDisabled={disabled}
        submitButtonLabel={"Save"}
        loading={isLoading}
        onClose={() => setOpen(false)}
        headerStyle={{ borderBottom: "1px solid #e0e0e0" }}
        onFinish={async () => {
          await handleSave(value);
          setOpen(false);
        }}>
        <Grid container>
          <Grid
            item
            xs
            style={{ height: "calc(100vh - 400px)", overflowX: "hidden", overflowY: "hidden" }}>
            <MonacoEditor
              value={value}
              height={"100%"}
              onChange={setValue}
              language="system"
              options={{
                wordWrap: "bounded",
                wordWrapColumn: 79,
                theme: "vs",
                tabSize: 4,
                minimap: { enabled: false },
                renderLineHighlight: "none",
                scrollbar: {
                  vertical: "hidden",
                  horizontal: "hidden"
                },
                autoDetectHighContrast: false,
                overviewRulerLanes: 0,
                padding: {
                  top: 16
                }
              }}
            />
          </Grid>
        </Grid>
      </NewModal>
    </NewThemeWrapper>
  );
};

export default RequirementModal;
