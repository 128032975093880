import React from "react";

import Box from "@material-ui/core/Box";

import { ArtifactIcon } from "icons/NewUX/ArtifactIcon";
import { ChartIcon } from "icons/NewUX";
import { DatasetIcon } from "icons/NewUX/DatasetIcon";
import { ModelIcon } from "icons/NewUX/ModelIcon";
import { VectorDBIcon } from "icons/NewUX/VectorDBIcon";

export const images = [
  "preview_image1.png",
  "preview_image2.png",
  "preview_image3.png",
  "preview_image4.png",
  "preview_image5.png",
  "preview_image6.png",
  "preview_image7.png",
  "preview_image8.png",
  "preview_image9.png",
  "preview_image10.png",
  "preview_image11.png",
  "preview_image12.png",
  "preview_image13.png",
  "preview_image14.png",
  "preview_image15.png",
  "preview_image16.png",
  "preview_image17.png",
  "preview_image18.png",
  "preview_image19.png",
  "preview_image20.png",
  "preview_image21.png",
  "preview_image22.png",
  "preview_image23.png",
  "preview_image24.png",
  "preview_image25.png",
  "preview_image26.png",
  "preview_image27.png",
  "preview_image29.png",
  "preview_image30.jpg",
  "preview_image31.jpg",
  "preview_image32.jpg",
  "preview_image33.jpg",
  "preview_image34.jpg",
  "preview_image35.jpg",
  "preview_image36.jpg",
  "preview_image37.jpg",
  "preview_image38.jpg",
  "preview_image39.jpg",
  "preview_image40.jpg",
  "preview_image41.jpg",
  "preview_image42.jpg",
  "preview_image43.jpg",
  "preview_image44.jpg",
  "preview_image45.jpg",
  "preview_image46.jpg",
  "preview_image47.jpg",
  "preview_image48.jpg",
  "preview_image49.jpg",
  "preview_image50.jpg",
  "preview_image51.jpg",
  "preview_image52.jpg",
  "preview_image53.jpg",
  "preview_image54.jpg",
  "preview_image55.jpg",
  "preview_image56.jpg",
  "preview_image57.jpg",
  "preview_image58.jpg",
  "preview_image59.jpg",
  "preview_image60.jpg",
  "preview_image61.jpg",
  "preview_image62.jpg",
  "preview_image63.jpg",
  "preview_image64.jpg",
  "preview_image65.jpg",
  "preview_image66.jpg"
];

const publicPath = process.env.PUBLIC_PATH;
const environment = process.env.ENVIRONMENT;
export const cdnImagePath =
  !!publicPath && !!environment ? `${publicPath}/${environment}/images/projectImages` : "";

export const projectRegExpLiteralNotations = {
  jobs: /jobs/,
  jobCanvasCompare: /job-compare/,
  jobCanvas: /job-canvas/
};

export const mimeTypes = {
  html: "text/html",
  css: "text/css",
  json: "application/json",
  png: "image/png",
  jpg: "image/jpeg",
  jpeg: "image/jpeg",
  gif: "image/gif",
  log: "text/plain",
  svg: "image/svg+xml",
  pdf: "application/pdf",
  txt: "text/plain",
  csv: "text/csv",
  mp4: "video/mp4",
  webm: "video/webm"
};

export enum NodeTypes {
  Dataset = "ENTITY",
  File = "FILE",
  Recipe = "DFSGROUP",
  Chart = "CHART",
  Artifact = "ARTIFACT",
  Model = "MODEL",
  Destination = "DESTINATION",
  VectorDB = "VECTOR_STORE"
}

export enum NodeTypeNames {
  Dataset = "Dataset",
  File = "Text",
  Recipe = "Recipe",
  Chart = "Chart",
  Artifact = "Artifact",
  Model = "Model",
  Destination = "Destination",
  VectorDB = "Vector DB"
}

export const NodeTypesNameMapping: $TSFixMe = {
  [NodeTypes.Dataset]: NodeTypeNames.Dataset,
  [NodeTypes.File]: NodeTypeNames.File,
  [NodeTypes.Recipe]: NodeTypeNames.Recipe,
  [NodeTypes.Chart]: NodeTypeNames.Chart,
  [NodeTypes.Artifact]: NodeTypeNames.Artifact,
  [NodeTypes.Model]: NodeTypeNames.Model,
  [NodeTypes.Destination]: NodeTypeNames.Destination,
  [NodeTypes.VectorDB]: NodeTypeNames.VectorDB
};

export const NodeTypesSessionKeyMapping: $TSFixMe = {
  [NodeTypes.Dataset]: "selectedEntities",
  [NodeTypes.Artifact]: "selectedArtifacts",
  [NodeTypes.Model]: "selectedModels"
};

export const NodeTypesIconMapping: $TSFixMe = {
  [NodeTypes.Dataset]: <DatasetIcon width={18} height={18} viewBox="-1 0 20 20" />,
  [NodeTypes.Chart]: <ChartIcon width={18} height={20} viewBox="-2 -4 40 40" />,
  [NodeTypes.Artifact]: <ArtifactIcon width={18} height={18} viewBox="-2 0 17 17" />,
  [NodeTypes.Model]: <ModelIcon width={22} height={22} viewBox="-2 -2 20 20" />,
  [NodeTypes.VectorDB]: <VectorDBIcon width={22} height={22} viewBox="0 0 16 16" />
};

export enum RecipeTypes {
  AiAssisted = "CODE",
  RapidModel = "AUTO_ML",
  Template = "STANDARD",
  ApiConnector = "API_CONNECTOR"
}

export const DescriptionInfo = "The description can be up to 1500 characters long.";
export const DescriptionCharacterLimit = 1500;
export const DescriptionCharacterLimitMessage =
  "The description is exceeding the maximum limit of 1500 characters";

export enum RecipeTypeNames {
  AiAssisted = "AI-assisted",
  RapidModel = "Rapid Model",
  Template = "Template",
  ApiConnector = "Code"
}

export const RecipeTypesNameMapping = {
  [RecipeTypes.AiAssisted]: RecipeTypeNames.AiAssisted,
  [RecipeTypes.RapidModel]: RecipeTypeNames.RapidModel,
  [RecipeTypes.Template]: RecipeTypeNames.Template,
  [RecipeTypes.ApiConnector]: RecipeTypeNames.ApiConnector
};

export const RecipeTypesPathMapping = {
  [RecipeTypes.AiAssisted]: "add-code-recipe" as const,
  [RecipeTypes.Template]: "add-recipe" as const,
  [RecipeTypes.RapidModel]: "add-auto-ml-recipe" as const,
  [RecipeTypes.ApiConnector]: "api-connector-recipe" as const
};

export enum DatasetStatuses {
  UnBuilt = "UNBUILT",
  Pending = "PENDING",
  Empty = "EMPTY",
  Skipped = "SKIPPED",
  Built = "BUILT"
}

export enum FileStatuses {
  UnBuilt = "UNBUILT",
  Pending = "PENDING",
  Empty = "EMPTY",
  Skipped = "SKIPPED",
  Built = "BUILT"
}

export enum RecipeStatuses {
  UnBuilt = "UNBUILT",
  Pending = "PENDING",
  Empty = "EMPTY",
  Running = "RUNNING",
  Success = "SUCCESS",
  Error = "ERROR",
  Skipped = "SKIPPED"
}

export enum ChartStatuses {
  UnBuilt = "UNBUILT",
  Pending = "PENDING",
  Empty = "EMPTY",
  Skipped = "SKIPPED",
  Built = "BUILT"
}

export enum ArtifactStatuses {
  UnBuilt = "UNBUILT",
  Empty = "EMPTY",
  Skipped = "SKIPPED",
  Built = "BUILT"
}

export enum ModelStatuses {
  UnBuilt = "UNBUILT",
  Empty = "EMPTY",
  Skipped = "SKIPPED",
  Built = "BUILT"
}

export const ConfirmRunWithReloadDatasetsPromptDetails = {
  title: "Confirm Run with Fresh Data",

  messageLine1: (
    <>
      If you proceed with this action, it will render the current runs of the associated recipe(s)
      with this input dataset invalid, marking them as{" "}
      <pre
        style={{
          display: "inline",
          margin: 0
        }}>
        UNBUILT
      </pre>{" "}
      and then re-run the recipe(s). Are you sure you want to proceed with this?"
    </>
  ),
  messageLine2: (
    <Box fontStyle="italic">
      Note: This action will overwrite the current dataset with latest data and schema from remote
      storage. Ensure that the schema in the remote storage matches that of the dataset; otherwise,
      linked recipes may encounter failures. Also the associated segment(if any) will be deleted,
      and any custom scenarios using it will default to the entire dataset instead of the segment.
    </Box>
  )
};

export enum ConfirmRunWithReloadDatasetsHelperText {
  CancelLabel = "No"
}

export const ConfirmUpdateTextInputPromptDetails = {
  title: "Update File",
  messageLine1: "This action will replace the current file with the new contents.",
  messageLine2: "Are you sure you want to proceed with this?"
};

export enum ConfirmUpdateTextInputHelperText {
  CancelLabel = "No",
  SubmitLabel = "Yes, Proceed"
}

export enum DatasetWebPathQueryParameters {
  IsAddFile = "isAddFile"
}

export enum ViewFileWebPathQueryParameters {
  Tab = "tab",
  Data = "data",
  Source = "source"
}

export const DISABLED_RECIPE_LOGS = "To check logs, please save and run the recipe";

export const READONLY_ENTITY = "This action is only supported in default scenario.";

export enum CloneRecipePromptDetails {
  title = "Duplicate Recipe",

  messageLine1 = "This action will create an exact copy of the recipe, including its inputs and outputs.",
  messageLine2 = "To avoid name conflicts within the project, you may need to rename the outputs to unique names.",
  messageLine3 = "Are you sure you want to proceed with this?"
}

export enum CloneRecipeHelperText {
  CloneRecipeActionInfo = "Please wait. The duplicate action is in progress.",
  CancelActionLabel = "No",
  SubmitActionLabel = "Yes, Proceed"
}
