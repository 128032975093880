import React, { useMemo } from "react";
import { Box, Typography, CardContent, Card, Button, Grid } from "@material-ui/core";
import { useNavigate, useLocation, generatePath } from "react-router-dom";

import { TrashIcon } from "icons/NewUX/TrashIcon";

import { RenderText } from "components/custom";

import DeleteNodeModal from "src/pages/ViewData/DeleteNodeModal/DeleteNodeModal";
import DrawerAbout from "./components/DrawerAbout";
import useArtifactByName from "hooks/api/artifacts/useArtifactByName";
import useEntities from "hooks/api/entities/useEntities";
import { checkIfDefaultScenario } from "src/pages/private/ProjectsModule/utils";
import { dateFormat } from "utils/helpers/date.helpers";
import { getErrorMessage, handleResponse } from "services/Apis/Apis.service";
import { sideComponentSetter } from "stores/zustand/stores.selectors";
import { useScenariosStore, useDrawerStore } from "stores/zustand/stores";
import { useStyles } from "./components/styling";

import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import NewThemeWrapper from "src/styles/NewThemeWrapper";
import ArtifactReadonly from "src/pages/private/ProjectsModule/pages/Dag/components/Nodes/Artifact/ArtifactReadonly";
import { WebPaths } from "src/routing/routes";
import { EyeIcon } from "icons/NewUX";
import MoreOptions from "src/layout/NavBars/components/SubTopNavBar/MoreOptions";
import CommonLoader from "src/components/CommonLoader";
import ArtifactAddMenu from "./ArtifactAddMenu";
import _ from "lodash";

export const ArtifactModalV2 = ({ data, canAddStandardRecipe }: $TSFixMe) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const setSideComponent = useDrawerStore(sideComponentSetter);

  const [scenarios] = useScenariosStore((state) => [state.scenarios]);

  const isDefaultScenario = useMemo(
    () => checkIfDefaultScenario(null, scenarios, data?.scenarioId),
    [data?.scenarioId, scenarios]
  );

  const { data: artifact, isLoading: isArtifactLoading } = useArtifactByName(
    data.name,
    data?.isJobCanvas ? data?.scenarioId : undefined,
    data?.isJobCanvas ? data?.jobProps?.jobRunId : undefined,
    { cacheTime: Infinity, refetchOnMount: true }
  );

  const {
    data: artifactEntity,
    isLoading,
    refetch
  } = useEntities({
    id: data.itemId,
    scenarioId: data?.isJobCanvas ? data?.scenarioId : undefined,
    jobRunId: data?.isJobCanvas ? data?.jobProps?.jobRunId : undefined,
    options: {
      cacheTime: Infinity,
      refetchOnMount: true,
      onError: (e) => {
        handleResponse({
          errorMessage: getErrorMessage(e) || `Error in fetching artifact - ${data.name}`
        });
      }
    }
  });

  const handleAddTransformClick = () => {
    const url = _.replace(
      WebPaths.StandardRecipeDataContainer,
      "/:groupId?",
      `?artifact=${data.itemId}`
    );
    const path = generatePath(url, {
      projectId: data.projectId,
      scenarioId: data?.scenarioId
    });

    navigate(path);
  };

  const handleAddCodeRecipeClick = () => {
    const url = _.replace(WebPaths.CodeRecipeContainer, "/:groupId?", `?artifact=${data.itemId}`);
    const path = generatePath(url, {
      projectId: data.projectId,
      scenarioId: data?.scenarioId
    });

    navigate(path);
  };

  const handleAddApiConnectorRecipe = () => {
    const url = `${WebPaths.APIConnectorRecipeContainer}?artifact=${data.itemId}`;
    navigate(
      generatePath(url, {
        projectId: data.projectId,
        scenarioId: data?.scenarioId
      })
    );
  };

  const isUnbuilt = useMemo(() => {
    return !artifactEntity?.status || !["BUILT"].includes(artifactEntity?.status);
  }, [artifactEntity?.status]);

  const getNavigatePath = () => {
    if (!!data?.jobProps) {
      return generatePath(`${WebPaths.JobRoutes}${WebPaths.JobArtifacts}`, {
        projectId: data.projectId,
        jobId: data?.jobProps?.jobId,
        scenarioId: data?.scenarioId,
        jobRunId: data?.jobProps?.jobRunId,
        artifactName: data.name
      });
    } else {
      return generatePath(WebPaths.ProjectArtifactsDetails, {
        projectId: data.projectId,
        artifactName: data.name
      });
    }
  };

  const handleViewArtifactClick = () =>
    navigate({ pathname: getNavigatePath() }, { state: { prevPath: location.pathname } });

  const onDelete = () => {
    setIsDeleteModalOpen(true);
  };

  const onClose = () => {
    setSideComponent({
      sideComponent: null,
      sideComponentProps: null
    });
  };

  return (
    <NewThemeWrapper>
      <Box className={classes.drawerContent}>
        {artifactEntity && (
          <DeleteNodeModal
            open={isDeleteModalOpen}
            deleteNote="Deleting this might impact associated dataapps(if any)"
            onClose={() => {
              setIsDeleteModalOpen(false);
            }}
            projectId={data?.projectId}
            nodeId={data.itemId}
            nodeName={data.label}
            nodeType="artifact"
            onAfterSubmit={() =>
              setSideComponent({ sideComponent: null, sideComponentProps: null })
            }
          />
        )}

        <SubTopNavBarWrapper
          variant="drawer"
          onDrawerClose={onClose}
          subTopNavBarLeftSection={{
            component: (
              <>
                <ArtifactReadonly data={data} />
                <RenderText
                  color="textSecondary"
                  isOverflowTooltip
                  linkTo={getNavigatePath()}
                  data-testid="sidebar-artifact-name">
                  {data?.label}
                </RenderText>
              </>
            )
          }}
        />

        <div className={classes.content} style={{ marginTop: 44 }}>
          {isLoading || isArtifactLoading ? (
            <CommonLoader />
          ) : (
            <>
              <Card className={classes.cardMargin}>
                <CardContent>
                  <Grid container direction="row" wrap="nowrap">
                    <Grid
                      container
                      item
                      className={classes.datasetItemGap}
                      wrap="nowrap"
                      style={{ width: "calc(100% - 80px)" }}>
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        startIcon={<EyeIcon viewBox="0 0 20 20" />}
                        onClick={handleViewArtifactClick}>
                        Preview
                      </Button>
                      {!data?.isJobCanvas && isDefaultScenario && (
                        <ArtifactAddMenu
                          isUnbuilt={isUnbuilt}
                          onAddApiConnectorRecipe={handleAddApiConnectorRecipe}
                          addTemplateRecipe={handleAddTransformClick}
                          addAiAssistedRecipe={handleAddCodeRecipeClick}
                          canAddStandardRecipe={canAddStandardRecipe}
                        />
                      )}
                    </Grid>
                    {!data?.isJobCanvas && isDefaultScenario && (
                      <Grid
                        container
                        item
                        className="moreIcon"
                        alignItems="center"
                        style={{ width: "80px" }}>
                        <MoreOptions
                          showTitle
                          options={[
                            {
                              label: "Delete",
                              icon: <TrashIcon viewBox="0 0 20 22" />,
                              action: onDelete
                            }
                          ]}
                        />
                      </Grid>
                    )}
                  </Grid>
                </CardContent>
              </Card>
              {artifactEntity?.markdown && (
                <Card className={classes.cardMargin}>
                  <DrawerAbout
                    markdownString={artifactEntity?.markdown}
                    projectId={data.projectId}
                    elementId={data.itemId}
                    hiddenUpdateContentAction={!!data?.isJobCanvas}
                    type="ARTIFACT"
                    onSuccess={refetch}
                  />
                </Card>
              )}
              <Card className={classes.cardMargin}>
                <CardContent>
                  <Box className={classes.detailsWrapper}>
                    <Box className={classes.detailItem}>
                      <Typography className={classes.detailName}>Created:</Typography>
                      <Typography>{dateFormat(artifactEntity?.created)}</Typography>
                    </Box>
                    <Box className={classes.detailItem}>
                      <Typography className={classes.detailName}>Updated:</Typography>
                      <Typography>{dateFormat(artifactEntity?.updated)}</Typography>
                    </Box>
                    <Box className={classes.detailItem}>
                      <Typography className={classes.detailName}>Build:</Typography>
                      <Typography>{dateFormat(artifactEntity?.built)}</Typography>
                    </Box>
                    {artifact?.producer && (
                      <Box className={classes.detailItem}>
                        <Typography className={classes.detailName}>Parent Transform:</Typography>
                        <Typography> {artifact?.producer.recipeName}</Typography>
                      </Box>
                    )}
                  </Box>
                </CardContent>
              </Card>
            </>
          )}
        </div>
      </Box>
    </NewThemeWrapper>
  );
};
