import React from "react";
import { Typography } from "@material-ui/core";

import { CellContext, ColumnDef, Row } from "@tanstack/react-table";
import _ from "lodash";
import { dateFormat } from "utils/helpers/date.helpers";
import { TData } from "src/components/custom/TableV8/typing";

export enum Status {
  ACTIVE = "ACTIVE",
  PENDING = "PENDING",
  EXPIRED = "EXPIRED",
  CANCELLED = "CANCELLED"
}
const statusColorMapping = {
  [Status.ACTIVE]: "#00BC4B",
  [Status.PENDING]: "#FF9900",
  [Status.EXPIRED]: "#DB2D02",
  [Status.CANCELLED]: "#DB2D02"
};

const statusCellValueMapping = {
  [Status.ACTIVE]: "Active",
  [Status.PENDING]: "Pending",
  [Status.EXPIRED]: "Link Expired",
  [Status.CANCELLED]: "Cancelled"
};

const roleSortType = (rowA: Row<any>, rowB: Row<any>) => {
  const valA = _.lowerCase(_.get(rowA.original, "role", ""));
  const valB = _.lowerCase(_.get(rowB.original, "role", ""));

  return valA.localeCompare(valB);
};

export const getUserColumns = (roles: any): ColumnDef<TData, any>[] => [
  {
    id: "Username",
    accessorKey: "email",

    header: "Username",
    meta: { type: "string" },

    size: 336
  },
  {
    id: "Status",
    accessorKey: "status",
    header: "Status",

    meta: { type: "string" },
    cell: (cell: CellContext<any, unknown>) => {
      const status = cell.row.original.status as Status;
      return (
        <Typography style={{ color: statusColorMapping[status], fontSize: "14px" }}>
          {statusCellValueMapping[status]}
        </Typography>
      );
    },
    size: 144
  },
  {
    id: "Role",
    accessorKey: "roleId",
    sortingFn: roleSortType,

    header: "Role",
    cell: (cell: CellContext<any, unknown>) => cell?.row?.original?.role || "Unknown",
    meta: {
      values: roles,
      isEditable: true,
      hideSelectInputLabel: true,
      selectVariant: "standard",
      type: "select"
    }
  },
  {
    id: "Invited On",
    accessorKey: "invitedOn",
    header: "Invited On",

    cell: (cell: CellContext<any, unknown>) => dateFormat(cell?.row?.original?.invitedOn)
  },
  {
    id: "Created On",
    accessorKey: "createdOn",
    header: "Created On",

    cell: (cell: CellContext<any, unknown>) => dateFormat(cell?.row?.original?.createdOn)
  },
  {
    id: "Last Login",
    accessorKey: "lastLoginTs",
    header: "Last Login",

    cell: (cell: CellContext<any, unknown>) => dateFormat(cell?.row?.original?.lastLoginTs)
  }
];
