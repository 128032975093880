import { useRef } from "react";
import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

import api from "services/AxiosClient/AxiosClient";
import envRelaunchNotification from "environmentsModule/utils/environmentRelaunch.helpers";
import { GroupLogDtoEnvStatusEnum, GroupLogDtoStatusEnum } from "@rapidcanvas/rc-api-core";
import { getRecipeLogs } from "src/pages/Projects/common/ShowLogsModal/utils/recipeLogs.helpers";

export const CACHE_QUERY_KEY_RECIPE_LOGS = "fetch-prediction-recipe-logs";

interface IReturn {
  status?: GroupLogDtoStatusEnum;
  recipeName?: string;
  recipeLogs: string[];
  show: boolean;
}

interface IProps {
  scenarioId?: string;
  groupId: string;
  isRunRecipeLogs: boolean;
  jobRunId?: string;
  showAllLogs: boolean;
  isTesting?: boolean;
}

const POLLING_INTERVAL = 5; // 5 sec
const POLLING_DURATION_FOR_TEST = 3 * 60; // 3 mins
const POLLING_DURATION_FOR_RUN = 20; // 20sec

const useGetRecipeLogs = (
  { scenarioId, groupId, isRunRecipeLogs = true, jobRunId, showAllLogs = false, isTesting }: IProps,
  options?: UseQueryOptions<IReturn>
): UseQueryResult<IReturn> => {
  const envRelaunchRef = useRef(false);
  const count = useRef(0);
  const paginationRef = useRef({ start: 0, limit: 100 });

  const updatePagination = (currentLogsLength: number) => {
    const prev = paginationRef.current;

    if (currentLogsLength < prev.limit) {
      return;
    }

    paginationRef.current = {
      start: prev.start + prev.limit,
      limit: 2 * prev.limit
    };
  };

  return useQuery<IReturn>({
    queryKey: [
      CACHE_QUERY_KEY_RECIPE_LOGS,
      scenarioId,
      groupId,
      isRunRecipeLogs,
      jobRunId,
      showAllLogs
    ],
    queryFn: async () => {
      const response = await api.fetchResponse(
        async () =>
          await api.DfsRunConfigGroupControllerV2Api.getRunTransformProgress(
            groupId,
            scenarioId,
            undefined,
            undefined,
            jobRunId,
            paginationRef.current?.limit || (showAllLogs ? 10000 : 50),
            paginationRef.current?.start,
            undefined,
            !isRunRecipeLogs
          )
      );

      const envStatus = response.envStatus;
      const id = response?.envId;
      if (
        id &&
        envStatus === GroupLogDtoEnvStatusEnum.Launching &&
        envRelaunchRef.current === false
      ) {
        const envResponse = await api.fetchResponse(
          async () => await api.EnvControllerApi.findEnvById(id)
        );

        const shutdownTimeInHrs = envResponse?.[0].shutdownStrategy?.inactivityInHours ?? 0;
        envRelaunchRef.current = true;
        envRelaunchNotification(shutdownTimeInHrs);
      }
      const recipeLogs = getRecipeLogs(
        isRunRecipeLogs,
        response,
        showAllLogs,
        envRelaunchRef.current,
        isTesting
      );
      count.current++;

      return {
        status: response.status,
        recipeName: response.displayName,
        recipeLogs,
        show: count.current > POLLING_DURATION_FOR_TEST / POLLING_INTERVAL && !isRunRecipeLogs
      };
    },
    refetchInterval: (data) => {
      if (isRunRecipeLogs) {
        const isRecipeRunning = data?.status === GroupLogDtoStatusEnum.Running;
        const withinRunDuration = count.current < POLLING_DURATION_FOR_RUN / POLLING_INTERVAL;

        if (isRecipeRunning || withinRunDuration) {
          updatePagination(data?.recipeLogs?.length || 0);
          return POLLING_INTERVAL;
        }
        return false;
      } else {
        if (isTesting) {
          return POLLING_INTERVAL;
        }
        const withinTestDuration = count.current <= POLLING_DURATION_FOR_TEST / POLLING_INTERVAL;
        return withinTestDuration ? POLLING_INTERVAL : false;
      }
    },
    refetchOnMount: true,
    ...options
  });
};

export default useGetRecipeLogs;
