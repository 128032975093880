import React, { useMemo, useState } from "react";
import { Grid, IconButton, InputProps, Typography, makeStyles } from "@material-ui/core";

import { OverflowTooltip, SearchField, Spinner } from "src/components";
import { useGetGlobalVariables } from "src/hooks/api";

import _, { filter, includes, isEmpty, toLower } from "lodash";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import CopyNameButton from "./CodeRecipeCanvasEntities/CopyNameButton";
import NoData from "./NoData";
import Table from "src/components/custom/TableV8/Table";
import { CellContext } from "@tanstack/react-table";

const useStyles = makeStyles({
  codeRecipeHeader: {
    padding: "0px 16px",
    height: "44px",
    flexDirection: "row",
    flexWrap: "nowrap",
    background: "#d9d9d933",
    borderRadius: "11px 11px 0px 0px"
  },

  btnContainer: {
    flexWrap: "nowrap",
    gap: "8px",
    flex: 0
  },
  customScrollbar: {
    "&::-webkit-scrollbar": {
      "-webkit-appearance": "none",
      width: "6px",
      height: "6px"
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "6px",
      backgroundColor: "#003656",
      "-webkit-box-shadow": "0 0 1px rgba(255, 255, 255, 0.5)"
    }
  }
});

const tableColumns = [
  {
    id: "Name",
    accessorKey: "name",
    header: "Name",
    size: 130,
    cell: ({ getValue }: CellContext<any, unknown>) => {
      const [hovered, setHovered] = useState(false);
      const value = getValue() as string;
      return (
        <div
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            position: "relative",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            width: "100%"
          }}>
          <OverflowTooltip style={{ flex: 1 }} value={value}></OverflowTooltip>
          {/* <span
            style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", flex: 1 }}>
            {value}
          </span> */}
          {hovered && (
            <CopyNameButton
              style={{
                marginLeft: "8px",
                position: "absolute",
                right: "0px",
                background: "white",
                zIndex: 1
              }}
              name={value}
            />
          )}
        </div>
      );
    }
  },
  {
    id: "Value",
    accessorKey: "value",
    header: "Value",
    size: 130
  }
];

const GlobalVariables = ({
  projectId,
  scenarioId,
  jobId,
  jobRunId
}: {
  projectId: string | undefined;
  scenarioId: string | undefined;
  jobId: string | undefined;
  jobRunId: string | undefined;
}) => {
  const [expanded, setExpanded] = useState(false);
  const { isLoading, data: globalVariablesData } = useGetGlobalVariables({
    projectId: projectId!,
    scenarioId,
    jobId,
    jobRunId
  });
  const [searchValue, setSearchValue] = useState("");

  const onCollapse = () => {
    setExpanded((prev) => !prev);
  };

  const handleSearch: InputProps["onChange"] = (event) => {
    setSearchValue(event?.target.value);
  };

  const filteredEntities = useMemo(() => {
    if (globalVariablesData) {
      return filter(globalVariablesData, (item) =>
        !!searchValue ? includes(toLower(item.name), toLower(searchValue)) : true
      );
    } else {
      return [];
    }
  }, [globalVariablesData, searchValue]);

  const classes = useStyles();

  return (
    <div
      style={{
        height: "100%",
        border: "1px solid #BDBDBD",
        borderRadius: "11px",
        background: "#FFFFFF"
      }}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        onClick={onCollapse}
        className={classes.codeRecipeHeader}>
        <Typography variant="subtitle1">Project Variables</Typography>
        <Grid container direction="row" className={classes.btnContainer}>
          <IconButton data-testid="entities-on-canvas-collapse" size="small">
            {expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </Grid>
      </Grid>
      {expanded && (
        <>
          {isLoading ? (
            <Spinner size={20} />
          ) : isEmpty(globalVariablesData) ? (
            <NoData content="No Project Variables" />
          ) : (
            <>
              <SearchField
                test-id="entities-on-canvas-search-field"
                onChange={handleSearch}
                InputProps={{
                  style: { height: "34px" }
                }}
                style={{ borderRadius: "0px", width: "100%" }}
                value={searchValue}
                placeholder="Search"
              />
              {isEmpty(filteredEntities) ? (
                <NoData content="No search result" />
              ) : (
                <Grid style={{ height: "calc(100% - 26px)" }} className={classes.customScrollbar}>
                  <Table
                    data={filteredEntities ?? []}
                    columns={tableColumns}
                    infoProps={{ hideCount: true }}
                    stylesProps={{ hideSettings: true, inheritHeight: true, size: "small" }}
                    sortingProps={{ unsorted: true }}
                  />
                </Grid>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default React.memo(GlobalVariables);
