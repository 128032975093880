import React, { useEffect, useMemo, useState } from "react";

import { useParams, useNavigate, generatePath } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import InfoOutlined from "@material-ui/icons/InfoOutlined";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import makeStyles from "@material-ui/core/styles/makeStyles";

import Alert from "@material-ui/lab/Alert";

import { toastWrapper } from "services/ToastClient/toastWrapper";

import { UseGetProjectCanvasQueryKeys } from "src/hooks/api/projects/useGetProjectCanvas";

import { DagFlow } from "../../Dag/components";

import { PublishJobModal } from "./PublishJobModal";

import { useJobContext } from "./Job/context/useJobContext";

import { JobsHelperText } from "../utils/Jobs.constants";
import { WebPaths } from "src/routing/routes";
import CommonLoader from "src/components/CommonLoader";
import { FindProjectRunsJobTypeEnum } from "@rapidcanvas/rc-api-core";

const useStyles = makeStyles(() => ({
  canvasActionsContainer: {
    display: "inline-flex",
    width: "auto",
    columnGap: 12
  },
  flex: {
    fontSize: 14,
    display: "flex",
    alignItems: "center",
    gap: "6px",
    padding: "8px 0px 0px 8px"
  },
  wrap: {
    height: "100%"
  }
}));

interface IProps {
  isValidNodes: boolean;
  isValidatingNodes: boolean;
  isNodesValidated: boolean;
  hideCanvasActions: boolean;
  hideCanvasCompareAction: boolean;
  isPublishingJob: boolean;
  isJobPublished: boolean;
  handleSubmit: () => void;
  isJobGlobalVariablesUpdated: boolean;
}

const JobCanvas: React.FC<IProps> = (props) => {
  const {
    isValidNodes,
    isValidatingNodes,
    isNodesValidated,
    hideCanvasActions = false,
    hideCanvasCompareAction = false,
    isPublishingJob,
    isJobPublished,
    isJobGlobalVariablesUpdated,
    handleSubmit
  } = props || {};

  const { projectId } = useParams() || {};

  const classes = useStyles();

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  // Job context
  const {
    project,
    jobRunConfigContextState,
    refetchJob,
    refetchScenarios,
    projectCanvasData,
    currentJobId: jobId,
    currentScenarioId,
    model,
    jobType,
    refetchDestinations,
    refetchDatasets
  } = useJobContext() || {};

  const scenarioId = useMemo(
    () => (!!jobId ? currentScenarioId : jobRunConfigContextState?.values?.scenario),
    [jobId, currentScenarioId, jobRunConfigContextState]
  );

  const [showPublishJobModal, setShowPublishJobModal] = useState<$TSFixMe>(false);

  useEffect(() => {
    //@TODO should be inside onsuccess of updateJobGlobalVariablesMutation
    if (isJobGlobalVariablesUpdated) {
      refetchJob();
      refetchScenarios();
      refetchDatasets();
      refetchDestinations();
      queryClient.invalidateQueries([UseGetProjectCanvasQueryKeys.ProjectCanvas]);
      setShowPublishJobModal(() => false);
      toastWrapper({
        type: "success",
        content:
          jobType === FindProjectRunsJobTypeEnum.PredictionJob
            ? "Latest Model flow is republished to the Prediction Scheduler!"
            : "Project Canvas is republished to the Scheduler!"
      });
    }
  }, [isJobGlobalVariablesUpdated]);

  const compareCanvas = () => {
    if (projectId) {
      navigate(
        generatePath(`${WebPaths.JobRoutes}${WebPaths.JobCompare}`, {
          projectId,
          scenarioId,
          jobId,
          type: jobType
        })
      );
    }
  };

  return (
    <>
      {showPublishJobModal && (
        <PublishJobModal
          jobType={jobType}
          handleClose={() => setShowPublishJobModal(() => false)}
          handleSubmit={handleSubmit}
          isSubmitLoading={isPublishingJob}
        />
      )}

      {!isNodesValidated || isValidatingNodes || Object.keys(project || {})?.length === 0 ? (
        <CommonLoader />
      ) : !isValidNodes ? (
        <Alert severity="info" style={{ margin: "auto" }}>
          No recipe found! Canvas cannot be loaded.
        </Alert>
      ) : jobType === FindProjectRunsJobTypeEnum.PredictionJob && !model?.id ? (
        <Alert severity="info" style={{ margin: "auto" }}>
          Please select a model to load Canvas
        </Alert>
      ) : (
        <div className={classes.wrap}>
          {jobType === FindProjectRunsJobTypeEnum.PredictionJob && (
            <div className={classes.flex} style={{ backgroundColor: jobId ? "#fffee3" : "#fff" }}>
              <InfoOutlined fontSize="small" />
              <span>
                This illustrates the flow through which data will be processed to generate
                predictions using the chosen model.
              </span>
            </div>
          )}
          <DagFlow
            jobProps={{
              scenarioId,
              entityId: model?.id,
              ...(!!jobId
                ? {
                    ...(!isJobPublished
                      ? // Passing projectCanvasData to get it compared with jobCanvasData and enable canvas-actions
                        // if there they are different.
                        { jobId, canvasData: projectCanvasData, canvasBackgroundColor: "#fffee3" }
                      : { jobId, canvasBackgroundColor: "#fffee3" }),
                    ...(!hideCanvasActions
                      ? {
                          renderContent: (
                            <Grid
                              container
                              direction="row"
                              className={classes.canvasActionsContainer}>
                              <Tooltip title={JobsHelperText.RepublishActionInfo}>
                                <Button
                                  variant="contained"
                                  size="small"
                                  color="primary"
                                  onClick={() => setShowPublishJobModal(() => true)}>
                                  Republish
                                </Button>
                              </Tooltip>
                              {!hideCanvasCompareAction && (
                                <Tooltip title={JobsHelperText.CompareActionInfo}>
                                  <Button
                                    variant="contained"
                                    size="small"
                                    color="primary"
                                    onClick={() => compareCanvas()}>
                                    Compare
                                  </Button>
                                </Tooltip>
                              )}
                            </Grid>
                          )
                        }
                      : {})
                  }
                : { canvasBackgroundColor: "#fffee3" })
            }}
          />
        </div>
      )}
    </>
  );
};

export default JobCanvas;
