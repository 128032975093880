import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import _ from "lodash";

import { UseGetProjectQueryKeys } from "src/hooks/api/projects/useGetProject";
import { handleResponse } from "services/Apis/Apis.service";
import { useProjectsStore } from "src/stores/zustand/stores";
import api from "services/AxiosClient/AxiosClient";
import { EnvAlertDtoStatusEnum, GenerateMarkdownTypeEnum } from "@rapidcanvas/rc-api-core";

interface IVariables {
  projectId?: string;
  projectName?: string;
  type: GenerateMarkdownTypeEnum;
  outputElementId?: string;
  isUpdated: boolean;
  invalidateAboutPage?: boolean;
  isForSnippets?: boolean;
  isAsync?: boolean;
}

const CONTENT_GENERATION_POLLING_INTERVAL = 30;
const POLLING_TIMEOUT = 15 * 60;

const useGenerateAboutContent = (): UseMutationResult<null, unknown, IVariables, unknown> => {
  const [
    generatingSnippetsProjects,
    generatingAboutProjects,
    setGeneratingSnippetsProjects,
    setGeneratingAboutProjects
  ] = useProjectsStore((state) => [
    state.generatingSnippetsProjects,
    state.generatingAboutProjects,
    state.setGeneratingSnippetsProjects,
    state.setGeneratingAboutProjects
  ]);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ projectId, type, outputElementId, isAsync }) => {
      if (!projectId) {
        throw "Error";
      }
      const eventId = await api.fetchResponse(
        async () =>
          await api.MarkdownControllerApi.generateMarkdown(
            projectId,
            type,
            outputElementId,
            isAsync
          )
      );

      if (!eventId) {
        throw "EventId is absent";
      }

      if (!isAsync) {
        const response = await api.fetchResponse(
          async () => await api.EnvAlertControllerApi.findAll(eventId),
          false
        );

        if (response) {
          const error = _.get(response, ["0", "extraInfo", "error"]);
          if (error && response[0].status === EnvAlertDtoStatusEnum.Error) {
            handleResponse({ errorMessage: error });
            throw "Error";
          }
        }

        return null;
      }
      let count = 1;
      return new Promise((resolve, reject) => {
        const interval = setInterval(async () => {
          const eventData = await api.fetchResponse(
            async () => await api.EnvAlertControllerApi.findAll(eventId),
            false
          );
          const status = _.get(eventData, [0, "status"]);
          count = count + 1;
          if (status === EnvAlertDtoStatusEnum.Handled) {
            clearInterval(interval);
            resolve(null);
          }
          if (
            status === EnvAlertDtoStatusEnum.Error ||
            count * CONTENT_GENERATION_POLLING_INTERVAL > POLLING_TIMEOUT
          ) {
            clearInterval(interval);
            reject(eventId);
          }
        }, CONTENT_GENERATION_POLLING_INTERVAL * 1000);
      });
    },
    onSuccess: (
      data,
      { projectId, isUpdated, projectName, invalidateAboutPage, isForSnippets }
    ) => {
      if (invalidateAboutPage) {
        if (_.includes(generatingAboutProjects, projectId)) {
          setGeneratingAboutProjects(
            !!projectId ? _.without(generatingAboutProjects, projectId) : []
          );
        }
        queryClient.invalidateQueries([UseGetProjectQueryKeys.Project]);
        handleResponse({
          successMessage: `About Page content for the project ${projectName} has been ${
            isUpdated ? "updated" : "generated"
          } successfully `
        });
      }

      if (isForSnippets && _.includes(generatingSnippetsProjects, projectId)) {
        setGeneratingSnippetsProjects(
          !!projectId ? _.without(generatingSnippetsProjects, projectId) : []
        );
        const nonGenerated = _.get(data, ["0", "extraInfo", "non-generated"]);
        let successMessage = `Snippets for the project ${projectName} has been generated successfully`;
        if (nonGenerated) {
          successMessage = `Snippets for the project ${projectName} has been generated successfully except for ${nonGenerated}. Please try again`;
        }
        handleResponse({
          successMessage
        });
      }
    },
    onError: (__, { invalidateAboutPage, projectId, isForSnippets }) => {
      if (invalidateAboutPage) {
        if (_.includes(generatingAboutProjects, projectId)) {
          setGeneratingAboutProjects(
            !!projectId ? _.without(generatingAboutProjects, projectId) : []
          );
        }
        queryClient.invalidateQueries([UseGetProjectQueryKeys.Project]);
      }

      if (isForSnippets && _.includes(generatingSnippetsProjects, projectId)) {
        setGeneratingSnippetsProjects(
          !!projectId ? _.without(generatingSnippetsProjects, projectId) : []
        );
      }
    }
  });
};

export default useGenerateAboutContent;
