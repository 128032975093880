import { Button } from "@material-ui/core";
import React, { useState } from "react";
import { Spinner } from "../../../components";
import styles from "./TenantActionButtons.module.scss";

export default function TenantActionButtons({
  enableButtonSwitch,
  enableButtonLeave,
  handleClickSwitch,
  handleClickLeave,
  tenantId
}: $TSFixMe) {
  const [isLoading, setisLoading] = useState(false);

  const handleActionSwitch = async () => {
    setisLoading(true);
    await handleClickSwitch(tenantId);
    setisLoading(false);
  };
  const handleActionLeave = async () => {
    setisLoading(true);
    await handleClickLeave(tenantId);
    setisLoading(false);
  };

  return isLoading ? (
    <Spinner size={40} noPadding />
  ) : (
    <div className={styles.actionButtonsContianer}>
      {enableButtonSwitch && (
        <Button onClick={handleActionSwitch} variant="outlined" color="primary">
          Switch
        </Button>
      )}
      {enableButtonLeave && (
        <Button onClick={handleActionLeave} variant="outlined" color="secondary">
          Leave workspace
        </Button>
      )}
    </div>
  );
}

TenantActionButtons.defaultProps = {
  enableButtonSwitch: true,
  enableButtonLeave: true
};
