import { Box, Button, CircularProgress, Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { DownloadEntityDtoFileTypeEnum } from "@rapidcanvas/rc-api-core";
import { get, isEmpty, startsWith, toLower, toUpper } from "lodash";
import React, { useMemo, useState } from "react";

import { useParams } from "react-router-dom";
import { CommonLoader } from "src/components";
import MonacoEditor from "src/components/custom/ResponsiveMonacoEditor/MonacoEditor";
import Result from "src/components/Errors/Result";
import SomethingWentWrong from "src/components/Errors/SomethingWentWrong";
import MarkdownDisplay from "src/components/MarkdownDisplay";
import { usePreviewDatasetFile } from "src/hooks/api";
import useEntityDetails from "src/hooks/api/entities/useEntityDetails";
import PrettyJsonEditor from "src/pages/private/ProjectsModule/pages/PredictionService/components/PrettyJsonEditor";
import { ViewFileConfig } from "src/pages/private/ProjectsModule/pages/ViewFile/utils/ViewFile.constants";
import { mimeTypes } from "src/pages/private/ProjectsModule/utils";
import { Entity } from "src/types";
import fileTypes from "constants/fileTypes.constants";

interface IProps {
  dataset: Entity;
  isPopover?: boolean;
}

const FileEntity: React.FC<IProps> = ({ dataset, isPopover = false }) => {
  const { scenarioId, jobRunId } = useParams();

  const [iframeLoading, setIframeLoading] = useState(true);
  const { isLoading: isLoadingFileDetails, data: fileDetails } = useEntityDetails(
    dataset.id,
    scenarioId,
    jobRunId,
    {
      refetchOnMount: true
    }
  );

  const fileName = dataset?.displayName || dataset?.name;
  const fileExtension = toLower(fileDetails?.fileExtension);
  const contentType = useMemo(() => get(mimeTypes, fileExtension, ""), [fileExtension]);

  const {
    isLoading: isPreviewingDatasetFile,
    data: previewData,
    isError,
    refetch,
    isFetching
  } = usePreviewDatasetFile({
    fileId: dataset.id,
    fileName: fileName,
    fileType: fileExtension,
    isPopover: isPopover,
    payload: {
      scenarioId,
      projectRunEntryId: jobRunId,
      fileType: toUpper(fileExtension) as DownloadEntityDtoFileTypeEnum,
      downloadRaw: true
    },
    enabled: !!fileDetails
  });

  const handleIframeLoad = () => {
    setIframeLoading(false);
  };

  if (isLoadingFileDetails || isPreviewingDatasetFile) {
    return <CommonLoader />;
  }

  if (isError) {
    return (
      <SomethingWentWrong
        hideMargin
        heading="Something went wrong"
        subtitle1="Error in downloading the file"
      />
    );
  }

  if (isEmpty(previewData)) {
    return (
      <Box p={4}>
        <Grid container alignItems="center" justifyContent="center">
          <Alert severity="info" style={{ justifyContent: "center" }}>
            No data found!
          </Alert>
        </Grid>
      </Box>
    );
  } else {
    if (previewData === ViewFileConfig.MaxFileSizeExceededError) {
      return (
        <Result
          heading={`Preview is unavailable for files larger than ${ViewFileConfig.MaxFileSize}MB`}
          subtitle1="Kindly open the downloaded file to view its contents"
          icon={<></>}
          headerLevel="h6"
          style={{ paddingTop: "10px", paddingLeft: "10px" }}
          extra={
            <Button
              size="small"
              variant="contained"
              disabled={isFetching}
              startIcon={
                isFetching ? <CircularProgress style={{ color: "#fff" }} size={16} /> : undefined
              }
              onClick={() => refetch()}>
              Download
            </Button>
          }
        />
      );
    }

    if (fileExtension === "json") {
      return <PrettyJsonEditor jsonString={previewData} height="400px !important" />;
    }

    if (fileExtension === "md") {
      return <MarkdownDisplay style={{ height: "100%" }} string={previewData || ""} />;
    }

    if (startsWith(contentType, "text/html")) {
      if (isPopover) {
        return (
          <Result
            heading={`Preview is available in new Tab`}
            subtitle1="If not, click below to open."
            icon={<></>}
            style={{ paddingTop: "10px", paddingLeft: "20px" }}
            extra={
              <Button size="small" variant="contained" onClick={() => window.open(previewData)}>
                Open
              </Button>
            }
          />
        );
      }
      return (
        <>
          {iframeLoading && <CommonLoader />}
          <iframe
            src={previewData}
            onLoad={handleIframeLoad}
            style={{
              width: "100%",
              height: "calc(100% - 6px)",
              border: "none",
              boxShadow: "none",
              display: iframeLoading ? "none" : "block"
            }}
            title="Text Entity Preview"
          />
        </>
      );
    }

    if (startsWith(contentType, "text/")) {
      let language = "plaintext";
      if (fileExtension) {
        language = get(fileTypes, [fileExtension, "monacoEditorLanguageValue"], "");
      }

      return (
        <MonacoEditor
          language={language}
          height={"100%"}
          width={"100%"}
          theme="vs"
          value={previewData}
          options={{
            readOnly: true,
            fontSize: 12,
            minimap: { enabled: false },
            renderLineHighlight: "none",
            lineNumbers: "off",
            scrollBeyondLastLine: false,
            wordWrap: "on"
          }}
        />
      );
    }
  }

  return <p>Unsupported file type: {fileName}</p>;
};

export default FileEntity;
