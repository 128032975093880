import React from "react";
import { Box, Divider, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import markdownToTxt from "markdown-to-txt";

import { PersonIconV3 } from "icons/NewUX/PersonIconV3";
import CopilotMarkdown from "./CopilotMarkdown";
import { RcLogoIcon } from "icons/NewUX";
import { CodeSuggestion } from "src/types";
import { LoaderAnimationBox } from "src/components/Loader/GenerateLoader";

import data from "src/components/Loader/askai-loader.json";
import { CopyTextIcon } from "../../CopyButton/CopyTextIcon";

interface StyleProps {
  isError?: boolean;
}
const useStyles = makeStyles<undefined, StyleProps>({
  question: {
    background: "#E7E7FF",
    borderRadius: "3px",
    width: "100%"
  },
  questionHeader: {
    padding: "9px 0px 9px 9px",
    gap: "6px",
    maxHeight: "113px",
    overflow: "auto"
  },
  text: {
    fontSize: "14px",
    lineHeight: "20px",
    whiteSpace: "pre-line",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start"
  },
  code: {
    background: "#E7E7FF",
    fontSize: "12px",
    lineHeight: "18px",
    width: "100%",
    borderRadius: "3px",
    padding: "9px",
    marginBottom: "6px",
    maxHeight: "120px",
    overflowY: "scroll",
    fontFamily: 'Menlo, Monaco, "Courier New", monospace',
    whiteSpace: "break-spaces",
    wordBreak: "break-word",
    wordWrap: "break-word"
  },
  historyRow: {
    gap: "6px"
  },
  answerWrap: {
    background: ({ isError }) => (isError ? "#ffdbdd" : "#F6F6F6"),
    fontSize: "14px",
    lineHeight: "20px",
    borderRadius: "3px",
    width: "100%"
  },
  personIcon: {
    flexShrink: 0
  },
  logo: {
    height: "24px",
    width: "24px",
    background: "#fff",
    display: "flex",
    alignItems: "center",
    borderRadius: "50%",
    justifyContent: "center"
  },
  answerHeader: {
    padding: "6px 9px"
  },
  outputLeftContainer: {
    flex: 1
  }
});

interface IProps {
  suggestion: CodeSuggestion;
  shouldShowCode: boolean;
}
const CopilotMessage: React.FC<IProps> = ({ shouldShowCode, suggestion }) => {
  const classes = useStyles({ isError: suggestion.isError });
  return (
    <Grid
      container
      key={suggestion.messageId}
      direction="column"
      wrap="nowrap"
      className={classes.historyRow}>
      <Grid container direction="column" wrap="nowrap" className={classes.question}>
        {suggestion.code && shouldShowCode && (
          <>
            <Grid container direction="row" wrap="nowrap" className={classes.code}>
              {suggestion.code}
            </Grid>
            <Divider light />
          </>
        )}
        <Grid container direction="row" wrap="nowrap" className={classes.questionHeader}>
          <Grid item className={classes.personIcon}>
            <PersonIconV3 size="small" />
          </Grid>
          <Typography align={"left"} className={classes.text}>
            {suggestion.query}
          </Typography>
        </Grid>
      </Grid>

      {suggestion.isLoading && !suggestion.response ? (
        <Grid
          container
          direction="column"
          wrap="nowrap"
          style={{ paddingBottom: "60px", height: "240px" }}>
          <LoaderAnimationBox
            mt="5px"
            testId="generate-output-loader"
            title={"Generating Output..."}
            data={data}
          />
        </Grid>
      ) : (
        <Grid container direction="column" wrap="nowrap" className={classes.answerWrap}>
          <Grid container direction="row" wrap="nowrap" className={classes.answerHeader}>
            <Grid container direction="row" wrap="nowrap" className={classes.outputLeftContainer}>
              <Box className={classes.logo} mr="4px">
                <RcLogoIcon height={16} width={16} />
              </Box>
              <Typography color="textSecondary" className={classes.text}>
                Output
              </Typography>
            </Grid>
            <Grid item>
              <CopyTextIcon text={markdownToTxt(suggestion.response! || "")} title="Copy Output" />
            </Grid>
          </Grid>
          <Divider light />
          <Box p="9px">
            <CopilotMarkdown string={suggestion.response!} isLoading={!!suggestion.isLoading} />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default React.memo(CopilotMessage);
