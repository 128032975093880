import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  wrapperContainer: {
    // For Project Sub-Navbar
    marginLeft: 60,
    padding: 12
  },
  gridContainer: {
    height: "calc(100vh - 64px - 254px)",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.grey[700]
  },
  gridIcon: {
    height: 215,
    width: 215,
    marginRight: 140
  },
  flex: {
    display: "flex",
    justifyContent: "flex-end",
    gap: theme.spacing(1)
  }
}));

export { useStyles };
