export enum ConfirmDeselectNodesPromptDetails {
  title = "Deselect Highlighted Nodes",

  cancelLabel = "No",
  submitLabel = "Yes, Proceed",

  messageLine1 = "This will remove the current selection from the following nodes:",
  messageLine2 = "Do you want to continue?"
}

export enum ConfirmDeleteNodesPromptDetails {
  title = "Bulk Delete",

  cancelLabel = "No",
  submitLabel = "Yes",

  messageLine1 = "The following nodes will be deleted:",
  messageLine2 = "Note: Deleting this might impact associated DataApps (if any). This will also delete the associated prediction service(s) linked to the selected model(s)."
}

export enum DeleteNodesHelperText {
  Delete = "Bulk Delete",
  CloseActionLabel = "Close",
  NodesDeleted = "Selected canvas node(s) deleted successfully!",
  NoDataFound = "No data found!"
}
