export enum FileTypeKeys {
  Csv = "csv",
  Css = "css",
  Gif = "gif",
  Html = "html",
  Jpeg = "jpeg",
  Jpg = "jpg",
  Json = "json",
  Txt = "txt",
  Log = "log",
  Parquet = "parquet",
  Md = "md",
  Mp4 = "mp4",
  // The "application/octet-stream" is a generic binary MIME type for unknown files, including executables (.exe, .bin),
  // binary data (.dat), compressed files (.zip, .tar, .gz), and ISO images (.iso).
  // The object can be updated accordingly in the future to include more specific cases.
  OctetStream = "octetStream",
  Pdf = "pdf",
  Png = "png",
  Svg = "svg",
  Xlsx = "xlsx",
  Xls = "xls",
  Xml = "xml",
  Webm = "webm"
}

const fileTypes = {
  [FileTypeKeys.Csv]: {
    label: "CSV",
    mimeTypes: ["text/csv"],
    monacoEditorLanguageValue: "csv"
  },
  [FileTypeKeys.Css]: {
    label: "CSS",
    mimeTypes: ["text/css"],
    monacoEditorLanguageValue: "css"
  },
  [FileTypeKeys.Gif]: {
    label: "GIF",
    mimeTypes: ["image/gif"],
    monacoEditorLanguageValue: ""
  },
  [FileTypeKeys.Html]: {
    label: "HTML",
    mimeTypes: ["text/html"],
    monacoEditorLanguageValue: "html"
  },
  [FileTypeKeys.Jpeg]: {
    label: "JPEG",
    mimeTypes: ["image/jpeg"],
    monacoEditorLanguageValue: ""
  },
  [FileTypeKeys.Jpg]: {
    label: "JPG",
    mimeTypes: ["image/jpeg"],
    monacoEditorLanguageValue: ""
  },
  [FileTypeKeys.Json]: {
    label: "JSON",
    mimeTypes: ["application/json"],
    monacoEditorLanguageValue: "json"
  },
  [FileTypeKeys.Txt]: {
    label: "Text",
    mimeTypes: ["text/plain"],
    monacoEditorLanguageValue: "plaintext"
  },
  [FileTypeKeys.Log]: {
    label: "Log",
    mimeTypes: ["text/plain"],
    monacoEditorLanguageValue: "plaintext"
  },
  [FileTypeKeys.Parquet]: {
    label: "Parquet",
    mimeTypes: ["application/vnd.apache.parquet"],
    monacoEditorLanguageValue: ""
  },
  [FileTypeKeys.Md]: {
    label: "Markdown",
    mimeTypes: [
      "text/markdown",
      "text/x-markdown",
      "application/markdown",
      "application/x-markdown"
    ],
    monacoEditorLanguageValue: "markdown"
  },
  [FileTypeKeys.Mp4]: {
    label: "MP4",
    mimeTypes: ["video/mp4"],
    monacoEditorLanguageValue: ""
  },
  [FileTypeKeys.OctetStream]: {
    label: "Octet Stream",
    mimeTypes: ["application/octet-stream"],
    monacoEditorLanguageValue: ""
  },
  [FileTypeKeys.Pdf]: {
    label: "PDF",
    mimeTypes: ["application/pdf"],
    monacoEditorLanguageValue: ""
  },
  [FileTypeKeys.Png]: {
    label: "PNG",
    mimeTypes: ["image/png"],
    monacoEditorLanguageValue: ""
  },
  [FileTypeKeys.Svg]: {
    label: "SVG",
    mimeTypes: ["image/svg+xml"],
    monacoEditorLanguageValue: "xml"
  },
  [FileTypeKeys.Xlsx]: {
    label: "XLSX",
    mimeTypes: ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"],
    monacoEditorLanguageValue: ""
  },
  [FileTypeKeys.Xls]: {
    label: "XLS",
    mimeTypes: ["application/vnd.ms-excel"],
    monacoEditorLanguageValue: ""
  },
  [FileTypeKeys.Xml]: {
    label: "XML",
    mimeTypes: ["text/xml"],
    monacoEditorLanguageValue: "xml"
  },
  [FileTypeKeys.Webm]: {
    label: "WebM",
    mimeTypes: ["video/webm"],
    monacoEditorLanguageValue: ""
  }
};

export default fileTypes;
