import React from "react";

export const ArrowDownIcon = ({
  width = 7,
  height = 4,
  fill = "none",
  color = "currentColor",
  opacity = 1,
  style: styleProps = {}
} = {}) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    style={{ ...styleProps }}>
    <g opacity={opacity}>
      <path
        d="M1.31525 0C0.438757 0 -0.0136555 1.04745 0.58723 1.68555L2.47198 3.68706C2.86678 4.10632 3.53322 4.10632 3.92802 3.68706L5.81277 1.68555C6.41366 1.04745 5.96124 0 5.08475 0H1.31525Z"
        fill={color}
      />
    </g>
  </svg>
);
