import { Row } from "@tanstack/react-table";
import { isString, keys, pickBy, toUpper } from "lodash";

import { getLocalStorageItem } from "src/services/LocalStorage/LocalStorage.service";
import { TableSessionConfig } from "constants/table.constants";

type ColumnsFromStorageProps = {
  userId: string | null | undefined;
  datasetId: string | null | undefined;
};

export const getColumnsFromStorage = (props: ColumnsFromStorageProps) => {
  const { userId, datasetId } = props || {};

  if (!userId || !datasetId) {
    return;
  }

  const tablePreferences = getLocalStorageItem({
    key: TableSessionConfig.TablePreferencesSessionKey
  });

  return keys(
    pickBy(
      // @ts-ignore
      tablePreferences?.[userId]?.[datasetId]?.columnVisibility,
      (value) => value === true
    )
  );
};

// Custom alphanumeric sort function
export const customAlphanumericSort = (
  rowA: Row<{ [key: string]: any }>,
  rowB: Row<{ [key: string]: any }>,
  columnId: string
) => {
  const valueA: any = rowA?.getValue(columnId);
  const valueB: any = rowB?.getValue(columnId);

  // Check if both are numbers (string or actual number)
  const isNumberA = !isNaN(parseFloat(valueA)) && isFinite(valueA);
  const isNumberB = !isNaN(parseFloat(valueB)) && isFinite(valueB);

  if (isNumberA && isNumberB) {
    // Compare as numbers
    return parseFloat(valueA) - parseFloat(valueB);
  }

  // Check if both are strings
  if (!!isString(valueA) && !!isString(valueB)) {
    return toUpper(valueA) > toUpper(valueB) ? 1 : -1;
  }

  // Fallback to string comparison if not numbers
  return valueA > valueB ? 1 : -1;
};
