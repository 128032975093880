import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { DescriptionFieldProps } from "@rjsf/utils";

const useStyles = makeStyles({
  description: {
    fontWeight: 400
  }
});

const DescriptionFieldTemplate: React.FC<DescriptionFieldProps> = ({ id, description }) => {
  const classes = useStyles();
  if (!description) {
    return null;
  }

  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item xs={12}>
        <Typography
          id={id}
          variant="subtitle2"
          color="textSecondary"
          className={classes.description}>
          {description}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default DescriptionFieldTemplate;
