import React, { Dispatch, SetStateAction } from "react";

import { Edge, Node, ProjectDashboardDto, ScenarioDto } from "@rapidcanvas/rc-api-core";
import { EdgeType, NodeType } from "../../../Dag.types";

export type NodeActions = {
  autoLayout: boolean;
  nodeDrag: boolean;
};

type ContextTypes = {
  isFromDatasetPage: boolean;

  project: ProjectDashboardDto;
  scenario?: ScenarioDto;

  nodesExpanded: { [key: string]: boolean };
  setNodesExpanded: Dispatch<SetStateAction<{ [key: string]: boolean }>>;

  updateNodesExpanded: (nodeId?: string) => void;

  nodeActions: NodeActions;
  setNodeActions: Dispatch<SetStateAction<NodeActions>>;

  isJobPath: boolean;
  isJobCanvasComparePath: boolean;

  isFetchingProjectCanvas: boolean;

  nodesStore: Node[];
  setNodesStore: (nodes: Node[]) => void;
  edgesStore: Edge[];
  setEdgesStore: (edges: Edge[]) => void;
  setNodeToFocusStore: (nodeToFocus: string) => void;
  setReloadTriggerStore: () => void;

  initialNodes: NodeType[];
  setInitialNodes: Dispatch<SetStateAction<NodeType[]>>;

  initialEdges: EdgeType[];

  edgeType: string;
  changeEdgeType: () => void;

  isRecipesRunning: boolean;
  isNodeHighlighted: (name: string, nodeToFocus: string) => boolean;

  contextMenuNodeId: string | null;
  setContextMenuNodeId: Dispatch<SetStateAction<string | null>>;

  resetNodesAndEdgesTimer: number | null;
  setResetNodesAndEdgesTimer: Dispatch<SetStateAction<number | null>>;
  isDefaultScenario: boolean;

  resetSelectedNodes: () => void;
};

// @ts-ignore
export const DagFlowContext = React.createContext<ContextTypes>();
