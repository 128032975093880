import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { isEmpty, some, toNumber, find, omit } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useForm, FormProvider } from "react-hook-form";
import { EnvDto, UpdateEnvDto } from "@rapidcanvas/rc-api-core/openapi/Models";

import { EnvironmentsTypes } from "environmentsModule/utils/Environments.constants";
import { DEFAULT_NAME } from "environmentsModule/utils/Environments.constants";
import { ITypes, extractEnvUsageTypes } from "../../utils/Environments.helpers";
import { EnvironmentInputs, EnvConfig } from "./containers/EnvironmentInputs/EnvironmentInputs";
import { EnvironmentTabs } from "./containers/EnvironmentTabs/EnvironmentTabs";

import { useUpdateEnvironment } from "src/hooks/api";
import { handleResponse } from "services/Apis/Apis.service";
import { updateEnvironmentsQueryData } from "src/utils/helpers";
import useGetEnvironmentUsage from "src/hooks/api/environments/useGetEnvironmentUsage";
import { Modal } from "src/components/custom";
import { formatStringsWithAnd } from "utils/helpers";
import { ModalVariants } from "src/components/custom/Modal/Modal";
import { EnvironmentActionsStatusEnum, setEnvironmentStatus } from "src/stores/environmentsSlice";
import { environmentActionStatusSelector } from "../../Environment.selectors";
import EnvironmentHeader from "src/pages/private/EnvironmentsModule/pages/Environment/containers/EnvironmentHeader/EnvironmentHeader";
import { Spinner } from "src/components";
import { Environment } from "monaco-editor";

export interface IPredictionService {
  id: string;
  pdCreated: number;
  pdCreator: string;
  pdId: string;
  pdName: string;
  pdUpdated: number;
  pdUpdater: string;
  pdModelName: string;
}

export interface EnvAction {
  types: ITypes;
  action: "stopping" | "modifying" | "changing";
  env?: EnvDto;
}

const useStyles = makeStyles(() => ({
  root: {
    // New UX change
    // The value 94px is the height of both the NavBars (TopNavBar 50px + SubTopNavBar 44px).
    minHeight: "calc(100vh - 94px)"
  },
  container: { padding: 24, width: "calc(100vw)" },
  tab: {
    padding: "0 !important",
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    "&.Mui-selected": {
      backgroundColor: "rgba(63, 81, 181, 0.08)"
    }
  }
}));

const getDefaultEnvValues = (env: EnvDto) => ({
  ...env,
  memory: env?.memInMbs ? (env?.memInMbs / 1024).toFixed() : "",
  cores: toNumber(env?.cores)
});

const Environment = ({
  environment,
  environmentsTypes,
  fetchEnvironmentData,
  environments,
  isEnvironmentsLoading
}: {
  environmentsTypes: any[];
  environment: EnvDto;
  fetchEnvironmentData: () => Promise<EnvDto | undefined>;
  environments: EnvDto[] | undefined;
  isEnvironmentsLoading: boolean;
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { envId } = useParams();
  const queryClient = useQueryClient();

  const [confirmEnvAction, setConfirmEnvAction] = useState<EnvAction | null>(null);

  const formMethods = useForm<EnvConfig>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: getDefaultEnvValues(environment) || {}
  });
  const { isLoading: isSaving, mutateAsync: updateEnvMutation } = useUpdateEnvironment();
  const {
    data: envUsage,
    isLoading: isLoadingEnvUsage,
    isFetching: isFetchingEnvUsage,
    refetch: refetchEnvUsage
  } = useGetEnvironmentUsage(envId!);

  const handleUpdateEnvironment = (updatedEnv: EnvDto) => {
    formMethods.reset(getDefaultEnvValues(updatedEnv));
  };
  useEffect(() => {
    handleUpdateEnvironment(environment);
  }, [environment]);

  const environmentConfig = formMethods.getValues();
  const environmentError = Object.values(formMethods.formState.errors)?.[0]?.message;
  const environmentStatus = useSelector(environmentActionStatusSelector(envId!));
  const isIdle = useMemo(
    () => environmentStatus === EnvironmentActionsStatusEnum.Idle,
    [environmentStatus]
  );
  const isDirtyFields = formMethods.formState.isDirty;

  const isSaveDisabled = useMemo(() => {
    return !isIdle || !isDirtyFields || !!environmentError || isSaving || isFetchingEnvUsage;
  }, [isIdle, isDirtyFields, environmentError, isSaving, isFetchingEnvUsage]);

  const saveTitle = useMemo(() => {
    if (!isIdle) {
      return "Wait until existing action completes";
    }
    if (isFetchingEnvUsage) {
      return "Wait until environment details are fetched";
    }
    return environmentError ?? "";
  }, [isIdle, environmentError]);

  const isEnvironmentNameInUse = useCallback(
    (name: string) => some(environments, (env) => env.name === name),
    [environment]
  );

  const isDefaultEnvironment = !!(environment?.defaultFlag && environment?.name === DEFAULT_NAME);

  const environmentActionWarning = `Please note that there are active processes within this environment, such as ${
    !!confirmEnvAction ? formatStringsWithAnd(confirmEnvAction.types) : ""
  }. Continuing with ${
    !!confirmEnvAction ? confirmEnvAction?.action : ""
  } the environment may affect these ongoing operations`;

  const handleSave = () => {
    if (isEmpty(environmentsTypes)) {
      return;
    }
    const environmentConfig = formMethods.getValues();
    const envToSend = omit(environmentConfig, ["diskInGbs"]);

    dispatch(setEnvironmentStatus({ id: envId!, status: EnvironmentActionsStatusEnum.Updating }));

    const selectedEnvType = find(environmentsTypes, { name: environmentConfig?.envType });
    const valuesToSend: UpdateEnvDto = {
      ...envToSend,
      description: environmentConfig?.description?.trim(),
      ...(environmentConfig?.envType === EnvironmentsTypes.Custom
        ? {
            cores: toNumber(environmentConfig?.cores),
            memInMbs: toNumber(environmentConfig?.memory ?? 0) * 1024
          }
        : {
            cores: selectedEnvType?.cores,
            memInMbs: selectedEnvType?.memInMbs
          })
    };
    updateEnvMutation(
      { id: envId!, payload: valuesToSend, async: true },
      {
        onSuccess: (environmentResponse) => {
          if (!isEmpty(environmentResponse)) {
            updateEnvironmentsQueryData({ queryClient, data: environmentResponse });
          }
          handleUpdateEnvironment(environmentResponse);
          handleResponse({ successMessage: "Environment updated successfully!" });
        },
        onSettled: () => {
          setConfirmEnvAction(null);
          dispatch(setEnvironmentStatus({ id: envId!, status: EnvironmentActionsStatusEnum.Idle }));
        }
      }
    );
  };

  const validateAndHandleSave = async () => {
    const usageResponse = await refetchEnvUsage();
    const types = extractEnvUsageTypes(usageResponse.data);
    if (!isEmpty(types)) {
      // Display a warning dialog indicating that the environment is currently in use.
      setConfirmEnvAction({ types, action: "modifying" });
      return;
    }
    handleSave();
  };

  return (
    <FormProvider {...formMethods}>
      {confirmEnvAction && (
        <Modal
          open={true}
          variant={ModalVariants.Delete}
          title="Are you sure?"
          content={[environmentActionWarning]}
          onClose={() => setConfirmEnvAction(null)}
          onSubmit={handleSave}
          isSubmitting={isSaving}
          isSubmitDisabled={isSaving}
        />
      )}
      <Grid container direction="column" alignItems="stretch" className={classes.root}>
        <EnvironmentHeader
          isEnvironmentNameInUse={isEnvironmentNameInUse}
          environment={environment}
          isSaving={isSaving}
          isSaveActionDisabled={isSaveDisabled}
          saveTitle={saveTitle}
          onSaveAction={validateAndHandleSave}
          readOnly={isDefaultEnvironment}
          fetchEnvironmentData={fetchEnvironmentData}
        />
        <Grid item xs={12} className={`${classes.container} container-height`}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              {isEnvironmentsLoading ? (
                <Spinner size={24} noPadding />
              ) : (
                <EnvironmentInputs
                  isEnvTypeDisabled={isEnvironmentsLoading}
                  environmentsTypes={environmentsTypes || []}
                  environmentConfig={environmentConfig}
                />
              )}
            </Grid>
            <Grid item xs={9}>
              <EnvironmentTabs
                envUsage={envUsage}
                isLoadingEnvUsage={isLoadingEnvUsage}
                isDefault={isDefaultEnvironment}
                requirements={environmentConfig.requirements || ""}
                linuxPkgs={environmentConfig.linuxPkgs || ""}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  );
};

export default Environment;
