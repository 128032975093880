import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";

import api from "services/AxiosClient/AxiosClient";

import { resetEnvironmentStatus } from "src/stores/environmentsSlice";

import { UseGetPollEnvironmentQueryKeys } from "./usePollEnvironments";
import { QUERY_KEY_ENV_BY_ID } from "./useGetEnvironmentById";

export const enum UseCleanCacheQueryKeys {
  CleanCache = "cleanCache"
}

interface IProps {
  envId?: string;
}

export type EnvCleanCachePayload = {
  envId: string;
  isDeleteRecent?: boolean;
  isCleanCustomPackages?: boolean;
};

export const useCleanCache = (
  props?: IProps
): UseMutationResult<void, unknown, EnvCleanCachePayload, unknown> => {
  const { envId } = props || {};

  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation({
    mutationKey: [UseCleanCacheQueryKeys.CleanCache, envId],
    mutationFn: async (payload: EnvCleanCachePayload) => {
      if (!payload?.envId) {
        throw "Invalid payload!";
      }

      return await api.fetchResponse(
        async () =>
          await api.EnvControllerApi.cleanupCache(
            payload.envId,
            !!payload?.isDeleteRecent,
            !!payload?.isCleanCustomPackages
          )
      );
    },
    onSettled: async () => {
      if (!!envId) {
        dispatch(resetEnvironmentStatus({ id: envId }));

        // Refetching poll-env query in Edit-env page.
        queryClient.refetchQueries([UseGetPollEnvironmentQueryKeys.PollEnvironment, envId]);

        // Refetching environment.
        // It updates query data of environments.
        // It refreshes form, tabs, status in Edit-env page.
        queryClient.refetchQueries([QUERY_KEY_ENV_BY_ID, envId]);
      }
    }
  });
};

export default useCleanCache;
