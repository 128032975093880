import React, { useEffect, useState, useMemo } from "react";

import {
  delay,
  filter,
  forEach,
  includes,
  isEmpty,
  join,
  map,
  reduce,
  size,
  startCase,
  toLower,
  values
} from "lodash";

import { OverflowTooltip } from "src/components";

import { Column as ColumnType, SampleData as SampleDataType } from "../Source.types";

import useStyles from "../Source.styles";
import Table from "src/components/custom/TableV8/Table";
import { CellContext, sortingFns } from "@tanstack/react-table";

type Props = {
  schemaData: any;
  sampleData: SampleDataType;
  searchTerm: string;
  tableMaxHeight: number;
};

const SampleData = (props: Props) => {
  const { schemaData, sampleData, searchTerm, tableMaxHeight } = props || {};

  const classes = useStyles();

  const [data, setData] = useState<any[]>([]);
  const [columns, setColumns] = useState<ColumnType[]>([]);
  const [dataTypeMap, setDataTypeMap] = useState<{ [key: string]: string }>({});

  const getElementWithTooltip = ({ value = "" }: { value: string }) => (
    <OverflowTooltip style={{ flexGrow: 1, width: 150, whiteSpace: "nowrap" }} value={value} />
  );

  useEffect(() => {
    setDataTypeMap(() => ({}));

    const _ = () => {
      let thisSchema: any[] = [];
      if (size(schemaData) > 0) {
        thisSchema = map(schemaData, (eachField: { [fieldSchema: string]: any }) => ({
          name: eachField?.fieldSchema?.fieldName || "",
          idLikeColumn: eachField?.fieldSchema?.fieldProperties?.idLikeColumn,
          detectedDataType: startCase(eachField?.fieldSchema?.rcDataType || "Unknown"),
          newDataType: eachField?.fieldSchema?.rcDataType || "Unknown".toUpperCase()
        }));
      }

      const dataTypesRow: { [key: string]: string } = {};

      forEach(thisSchema, (eachColumn: { [key: string]: any }) => {
        // Replacing ".", "[", "]" with "_".
        const columnKey = eachColumn?.name?.replace(/[.[\]]/gi, "_");
        dataTypesRow[columnKey] = eachColumn?.newDataType;
      });

      setDataTypeMap(() => dataTypesRow);
    };

    // Need to reset data-types on update-dataset API failure.
    // Hence, keeping set-data-type-map logic within a callback.
    delay(_, 0);
  }, [schemaData, sampleData]);

  useEffect(() => {
    setColumns(() => []);

    const thisColumns = map(sampleData?.columns, (column: string, index: number) => {
      // @ts-ignore
      const columnKey = column ? ("" + column).replaceAll(".", "_") : "";

      return {
        id: column || `column_${index + 1}`,
        accessorKey: columnKey,
        header: column || "Unknown",

        sortingFn: sortingFns.alphanumericCaseSensitive,
        cell: ({ getValue }: CellContext<any, any>) =>
          getElementWithTooltip({ value: getValue() as string }),
        meta: {
          meta: dataTypeMap[columnKey],
          isEditable: false,
          selectStyles: classes.sampleDataSchema
        }
      };
    });

    setColumns(() => thisColumns);
  }, [sampleData, dataTypeMap]);

  useEffect(() => {
    const thisColumns = sampleData?.columns || [];
    const thisRows = sampleData?.rows || [];

    setData(() => []);

    const _ = () => {
      const outputRows = reduce(
        thisRows,
        (rowAcc: any[], row: { [key: string]: any }) => {
          rowAcc.push(
            reduce(
              row?.cells,
              (cellAcc: { [key: string]: any }, cell: any, cellIndex: number) => {
                // @ts-ignore
                const columnKey = ("" + thisColumns[cellIndex]).replaceAll(".", "_");

                cellAcc["id"] = `row${cellIndex + 1}`;
                cellAcc[columnKey] =
                  !!cell &&
                  typeof cell === "string" &&
                  includes(["nan"], cell?.trim()?.toLowerCase())
                    ? ""
                    : cell;
                return cellAcc;
              },
              {}
            )
          );

          return rowAcc;
        },
        []
      );

      setData(() => [...outputRows]);
    };

    if (!isEmpty(thisColumns) && !isEmpty(thisRows)) {
      // Need to reset data-types on update-dataset API failure.
      // Hence, keeping set-data logic within a callback.
      delay(_, 0);
    }
  }, [schemaData, sampleData]);

  const filteredValues = useMemo(
    () =>
      filter(data, (eachField: { [key: string]: any }) =>
        includes(toLower(join(values(eachField), " ")), toLower(searchTerm))
      ),
    [searchTerm, data]
  );

  return !isEmpty(columns) && !isEmpty(data) ? (
    <Table
      columns={columns}
      data={filteredValues}
      stylesProps={{
        size: "small",
        maxHeight: `calc(100vh - ${tableMaxHeight ?? 705}px)`,
        isTheadSticky: true,
        fixedLayout: false
      }}
      sortingProps={{ unsorted: true }}
    />
  ) : (
    <></>
  );
};

export default SampleData;
