import React, { useMemo } from "react";
import _ from "lodash";
import shallow from "zustand/shallow";
import { Grid, Box, Typography, makeStyles } from "@material-ui/core";

import ModalTenantSwitch from "../MyTenantsSection/ModalTenantSwitch";
import SearchField from "src/components/Inputs/SearchField";
import useAuthStore from "stores/zustand/auth.store";
import useNotificationStore from "stores/zustand/notification.store";
import useTenantManagementStore from "stores/zustand/tenant-management.store";

import { getTenantColumns } from "./TenantsColumns";
import { useFetchTenantActions } from "./useFetchTenantActions";
import { postAPI } from "services/Apis/Apis.service";
import { useProjectsStore } from "stores/zustand/stores";
import { shouldRefreshProjectsSetter } from "stores/zustand/stores.selectors";
import { useAuthSetAuthResponse } from "src/hooks/useAuthSetAuthResponse";
import { logNotebooksOut } from "services/Notebook/Notebook.service";
import { WebPaths } from "src/routing/routes";
import CommonLoader from "src/components/CommonLoader";
import Table from "src/components/custom/TableV8/Table";
import { formTableCells } from "src/components/custom/TableV8/TableUtils";

const TABLE_SIZE = "small";

const useStyles = makeStyles({
  // @REFACTOR
  // The below styles are added only to support New UX. It shall be removed soon.
  searchField: {
    "& [class^='MuiInputBase-root']": {
      background: "none",
      border: "1px solid #ccc",
      borderRadius: 4
    },
    "& input": {
      paddingTop: 10,
      paddingBottom: 10,
      fontSize: "small"
    }
  },
  noData: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    border: "1px solid #d3d3d3",
    borderRadius: "4px",
    padding: "24px",
    gap: "8px",
    color: "#4c4c4c",
    fontSize: 14
  }
});

const TenantsData = () => {
  const classes = useStyles();

  const [openSwitchTenantModal, setOpenSwitchTenantModal] = React.useState<Boolean>(false);
  const [value, setValue] = React.useState("");
  const [currentTenantId] = useAuthStore((state) => [state.tenantId], shallow);
  const tenants = useTenantManagementStore((state) => state.userTenants, shallow);
  const { switchingTenantId, switchTenant, leaveTenantAndSwitch } = useFetchTenantActions();
  const [setNotification] = useNotificationStore(
    (state: any) => [state.setNotification, state.notifications],
    shallow
  );
  const setShouldRefreshProjects = useProjectsStore(shouldRefreshProjectsSetter);

  const { setAuthResponse } = useAuthSetAuthResponse();

  const handleTenantsSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const joinNewTenant = (tenantId: string) => {
    postAPI("/invitation/accept", { invitationDetails: "", tenantId }).then((data) => {
      if (data) {
        setNotification({
          type: "Dashboard",
          message: `You are added to "${data.tenantName}" workspace successfully`
        });
        setAuthResponse({
          data
        });
        setShouldRefreshProjects(true);
        logNotebooksOut();
        window.location.href = WebPaths.Dashboard;
        window.history.pushState(null, "", WebPaths.Dashboard);
      }
    });
  };

  const tenantColumns = getTenantColumns({
    currentTenantId,
    switchingTenantId,
    switchTenant,
    showLeaveAndSwitchTenantModal: () => setOpenSwitchTenantModal(true),
    joinNewTenant,
    // No other workspace to switch to
    canLeaveAndSwitchTenant:
      tenants?.filter((tenant: $TSFixMe) => tenant?.invitationStatus === "ACTIVE")?.length !== 1
  });
  const columns = formTableCells({ inputColumns: tenantColumns, size: TABLE_SIZE });

  const validTenants = useMemo(() => _.filter(tenants, { hasValidLicense: true }), [tenants]);

  const data = useMemo(() => {
    const sorted = tenants?.sort(
      (tenant1: $TSFixMe, tenant2: $TSFixMe) => tenant1?.hasValidLicense - tenant2?.hasValidLicense
    );

    return _.filter(sorted, ({ name }) => _.includes(_.toLower(name), _.toLower(value)));
  }, [value, tenants]);

  if (tenants.length === 0) {
    return <CommonLoader />;
  }

  return (
    <Grid container direction="column">
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Typography component={"div"} color="textPrimary">
          <Box fontWeight="500">Workspace</Box>
        </Typography>
        <SearchField
          placeholder="Search Workspaces"
          value={value}
          onChange={handleTenantsSearch}
          className={classes.searchField}
        />
      </Grid>
      <Box component="div" pt="20px" width="inherit">
        <Table
          data={data}
          columns={columns}
          stylesProps={{ size: TABLE_SIZE, maxHeight: "calc(100vh - 218px)" }}
        />
        {_.isEmpty(data) && (
          <div className={classes.noData}>
            <span>No Search Results found for "{value}"</span>
            <span>Please refine your search</span>
          </div>
        )}
      </Box>
      <ModalTenantSwitch
        visible={openSwitchTenantModal}
        tenants={validTenants}
        handleSwitchAndDelete={leaveTenantAndSwitch}
        onClose={setOpenSwitchTenantModal}
      />
    </Grid>
  );
};

export default TenantsData;
