import { useEffect, useState } from "react";
import useAuthStore from "../stores/zustand/auth.store";

export const useFetchWithZustand = ({
  fetchCallback,
  fetchingParams,
  setApiData,
  shouldFetchData = true,
  shouldRefreshGetter,
  shouldRefreshUseStore,
  forceFetchData,
  shouldRefreshSetter
}: $TSFixMe) => {
  const [isLoading, setIsLoading] = useState(false);
  const isUserLoggedIn = useAuthStore((state) => state.isUserLoggedIn);

  let shouldRefresh: $TSFixMe = null;
  if (shouldRefreshGetter && shouldRefreshUseStore) {
    shouldRefresh = shouldRefreshUseStore(shouldRefreshGetter);
  }

  const setShouldRefresh =
    shouldRefreshSetter && shouldRefreshUseStore
      ? shouldRefreshUseStore(shouldRefreshSetter)
      : null;

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const data = await fetchCallback?.(fetchingParams || null);
        setApiData(data);
      } catch (error) {
        console.error({ error });
      } finally {
        setIsLoading(false);
      }
    };

    if (shouldFetchData || forceFetchData) {
      fetchData();
    } else if (Boolean(shouldRefresh) && isUserLoggedIn) {
      setShouldRefresh?.(false);
      fetchData();
    }
  }, [
    fetchCallback,
    setApiData,
    shouldFetchData,
    shouldRefreshGetter,
    forceFetchData,
    shouldRefresh
  ]);

  return { isLoading };
};
