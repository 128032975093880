import { FindProjectRunsJobTypeEnum } from "@rapidcanvas/rc-api-core";
import React from "react";
import { Model } from "src/hooks/api/projects/useGetAllProjectModels";

type ContextTypes = {
  project: $TSFixMe;

  dataSourcesData: $TSFixMe[];

  variablesData?: $TSFixMe[];
  refetchVariables: $TSFixMeFunction;

  jobContextState: $TSFixMe;
  setJobContextState: $TSFixMeFunction;

  jobRunConfigContextState: $TSFixMe;
  setJobRunConfigContextState: $TSFixMeFunction;

  jobParametersContextState: $TSFixMe[];
  setJobParametersContextState: $TSFixMeFunction;

  jobDestinationsContextState: $TSFixMe[];
  setJobDestinationsContextState: $TSFixMeFunction;

  isFetchingDefaultJobNames: boolean;
  refetchJobNames: $TSFixMeFunction;
  defaultJobNames: string[];

  isFetchingJob: boolean;
  isJobFetched: boolean;
  jobData: $TSFixMe;
  refetchJob: $TSFixMeFunction;

  scenariosData: $TSFixMe[];
  refetchScenarios: $TSFixMeFunction;
  defaultScenario: $TSFixMe;

  jobType: FindProjectRunsJobTypeEnum;
  model: Pick<Model, "id" | "name"> | null;
  setModel: (model: Pick<Model, "id" | "name">) => void;

  datasetsData: $TSFixMe[];
  getProjectCanvasData: $TSFixMeFunction;
  projectCanvasData: $TSFixMe;
  validateNodes: $TSFixMeFunction;

  currentJobId: string;
  currentScenarioId: string;

  destinationsData: $TSFixMe[];

  refetchDestinations: $TSFixMeFunction;
  destinationLoading: boolean;

  refetchDatasets: $TSFixMeFunction;

  isSaved: boolean;
  setIsSaved: $TSFixMeFunction;
};

// @ts-ignore
export const JobContext = React.createContext<ContextTypes>();
