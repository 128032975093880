import { Button, CircularProgress } from "@material-ui/core";
import { get, isEmpty, startsWith } from "lodash";
import React, { useMemo } from "react";
import { CommonLoader } from "src/components";
import MonacoEditor from "src/components/custom/ResponsiveMonacoEditor/MonacoEditor";
import Result from "src/components/Errors/Result";
import SomethingWentWrong from "src/components/Errors/SomethingWentWrong";
import MarkdownDisplay from "src/components/MarkdownDisplay";
import useTestResultFileDownload, {
  getFileExtension
} from "src/hooks/api/recipes/useTestResultFileDownload";
import PrettyJsonEditor from "src/pages/private/ProjectsModule/pages/PredictionService/components/PrettyJsonEditor";
import { ViewFileConfig } from "src/pages/private/ProjectsModule/pages/ViewFile/utils/ViewFile.constants";
import { mimeTypes } from "src/pages/private/ProjectsModule/utils";
import fileTypes from "constants/fileTypes.constants";

interface IProps {
  fileName: string;
  filePath: string;
}

const FileEntityFromUrl: React.FC<IProps> = ({ fileName, filePath }) => {
  const {
    isLoading: isPreviewingDatasetFile,
    data: previewData,
    isError,
    refetch,
    isFetching
  } = useTestResultFileDownload(filePath, fileName);

  const fileExtension = getFileExtension(filePath);
  const contentType = useMemo(() => get(mimeTypes, fileExtension, ""), [fileExtension]);
  if (isPreviewingDatasetFile) {
    return <CommonLoader />;
  }

  if (isError) {
    return (
      <SomethingWentWrong
        hideMargin
        heading="Something went wrong"
        subtitle1="Error in downloading the file"
      />
    );
  }

  if (isEmpty(previewData)) {
    return <SomethingWentWrong hideMargin heading="Something went wrong" />;
  } else {
    if (previewData === ViewFileConfig.MaxFileSizeExceededError) {
      return (
        <Result
          heading={`Preview is unavailable for files larger than ${ViewFileConfig.MaxFileSize}MB`}
          subtitle1="Kindly open the downloaded file to view its contents"
          icon={<></>}
          extra={
            <Button
              size="small"
              variant="contained"
              disabled={isFetching}
              startIcon={
                isFetching ? <CircularProgress style={{ color: "#fff" }} size={16} /> : undefined
              }
              onClick={() => refetch()}>
              Download Again
            </Button>
          }
        />
      );
    }

    if (fileExtension === "json") {
      return <PrettyJsonEditor jsonString={previewData} height="100%" />;
    }

    if (fileExtension === "md") {
      return <MarkdownDisplay style={{ height: "100%" }} string={previewData || ""} />;
    }

    if (startsWith(contentType, "text/html")) {
      return (
        <iframe
          src={previewData}
          style={{
            width: "100%",
            height: "100%",
            border: "none",
            boxShadow: "none"
          }}
          title="Text Entity Preview"
        />
      );
    }

    if (startsWith(contentType, "text/")) {
      let language = "plaintext";
      if (fileExtension) {
        language = get(fileTypes, [fileExtension, "monacoEditorLanguageValue"], "");
      }

      return (
        <MonacoEditor
          language={language}
          height={"100%"}
          width={"100%"}
          theme="vs"
          value={previewData}
          options={{
            readOnly: true,
            fontSize: 12,
            minimap: { enabled: false },
            renderLineHighlight: "none",
            lineNumbers: "off",
            scrollBeyondLastLine: false,
            wordWrap: "on"
          }}
        />
      );
    }
  }

  return <p>Unsupported file type: {fileName}</p>;
};

export default FileEntityFromUrl;
