import React, { useMemo } from "react";

// MUI
import CircularProgress from "@material-ui/core/CircularProgress";

// Icons
import BlockIcon from "@material-ui/icons/Block";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import WarningIcon from "@material-ui/icons/Warning";
import { DatasetIcon } from "icons/NewUX/DatasetIcon";

// Utils
import { DatasetStatuses } from "src/pages/private/ProjectsModule/utils";

// Open API
import { DataSourceDto } from "@rapidcanvas/rc-api-core";

// Types
import { NodeData } from "src/types";

type Props = {
  data: NodeData;
  connector: DataSourceDto | any;
};

const statusIconMap: { [key: string]: React.ReactNode } = {
  [DatasetStatuses.UnBuilt]: <BlockIcon />,
  [DatasetStatuses.Pending]: <DatasetIcon width={20} height={20} />,
  [DatasetStatuses.Empty]: <WarningIcon />,
  [DatasetStatuses.Skipped]: <SkipNextIcon />,
  [DatasetStatuses.Built]: <DatasetIcon width={20} height={20} />
};

const statusNameMap: { [key: string]: string } = {
  [DatasetStatuses.UnBuilt]: "Unbuilt",
  [DatasetStatuses.Pending]: "Pending...",
  [DatasetStatuses.Empty]: "Empty",
  [DatasetStatuses.Skipped]: "Skipped",
  [DatasetStatuses.Built]: "Built"
};

const useAttributes = (props: Props) => {
  const { data, connector } = props || {};

  const icon = useMemo(() => {
    if (data?.image) {
      return <img src={data?.image} alt="dataset-icon" />;
    }

    if (!!data?.entityDSDetails?.id) {
      let thisIcon = <DatasetIcon width={20} height={20} />;

      if (data?.entityDSDetails?.purpose === "IMPORT") {
        thisIcon = !connector ? (
          <CircularProgress size={20} />
        ) : connector?.type === "default" && connector?.image ? (
          connector?.image
        ) : connector?.url ? (
          <img src={connector?.url} alt={connector?.displayName} />
        ) : (
          <DatasetIcon width={20} height={20} />
        );
      }

      return thisIcon;
    }

    return statusIconMap[data?.status] || <DatasetIcon width={20} height={20} />;
  }, [data, connector]);

  const status = useMemo(() => {
    return statusNameMap[data?.status] || "";
  }, [data?.status]);

  return { icon, status };
};

export default useAttributes;
