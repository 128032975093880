import React from "react";
import _ from "lodash";
import { InfoOutlined } from "@material-ui/icons";
import { Typography, Box, Tooltip } from "@material-ui/core";

import { DataappAskAIConfigInputTypeEnum } from "@rapidcanvas/rc-api-core";

interface IProps {
  inputType: DataappAskAIConfigInputTypeEnum;
}

const HideChatInfo: React.FC<IProps> = ({ inputType }) => {
  return (
    <Box display="flex" flexDirection="column" mt={1}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography variant="body1">
          <b>Hide Chat Side Panel?</b>
        </Typography>
        <Tooltip
          title="To hide the chat side panel for consumer users in this DataApp and center the chat interface on the page, please disable the following permissions."
          arrow>
          <InfoOutlined
            style={{
              fontSize: "1rem",
              marginLeft: "4px",
              color: "#8a8989"
            }}
          />
        </Tooltip>
      </div>
      <Box mt={1} component="ul" padding="0px 24px">
        <Typography component="li">Allow New Chat Creation disabled</Typography>
        {!_.includes(
          [
            DataappAskAIConfigInputTypeEnum.PredictionService,
            DataappAskAIConfigInputTypeEnum.RagFiles
          ],
          inputType
        ) && <Typography component="li">Show User Charts Tab disabled</Typography>}
        <Typography component="li">Allow Input Selection disabled</Typography>
        <Typography component="li">Allow Chat Deletion disabled</Typography>
      </Box>
    </Box>
  );
};

export default HideChatInfo;
