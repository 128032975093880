import React from "react";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import RemoveRoundedIcon from "@material-ui/icons/RemoveRounded";
import { Button } from "@material-ui/core";

import { useDagFlowContext } from "../DagFlow/context/useDagFlowContext";

type Props = {
  nodeId: string;
  disabled?: boolean;
};

const ExpandCollapseIconButton = (props: Props) => {
  const { nodeId, disabled } = props || {};

  const { nodesExpanded, updateNodesExpanded } = useDagFlowContext();

  return (
    <Button
      size="small"
      disabled={disabled}
      startIcon={
        nodesExpanded[nodeId] ?? true ? (
          <RemoveRoundedIcon
            style={{
              background: "#F5C726",
              borderRadius: "10px",
              width: "15px",
              height: "15px",
              border: "1px solid #fff"
            }}
          />
        ) : (
          <AddRoundedIcon
            style={{
              background: "#F5C726",
              borderRadius: "10px",
              width: "15px",
              height: "15px",
              border: "1px solid #fff"
            }}
          />
        )
      }
      onClick={() => updateNodesExpanded(nodeId)}>
      {nodesExpanded[nodeId] ?? true ? "Collapse" : "Expand"}
    </Button>
  );
};

export default ExpandCollapseIconButton;
