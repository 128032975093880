import React, { useMemo } from "react";

import Text from "src/components/Widget/Text";
import { dateFormat } from "utils/helpers/date.helpers";
import { ArtifactDto, ArtifactProducer } from "@rapidcanvas/rc-api-core";
import {
  getDestinationValList,
  getSourceVal
} from "src/pages/Library/ArtifactsAndModels/Artifacts/ArtifactsTable";

import { Tooltip } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import _ from "lodash";
import Table from "src/components/custom/TableV8/Table";
import { CellContext, RowSelectionState, Updater } from "@tanstack/react-table";

const TABLE_SIZE = "small";

export const AddArtifactTable = ({
  data,
  globalFilter,
  selectedRowIds,
  onSelectedRowsChange,
  shouldDisableInitialSelectedRows
}: {
  data: ArtifactDto[];
  selectedRowIds: $TSFixMe;
  globalFilter?: string | undefined;
  // eslint-disable-next-line no-unused-vars
  onSelectedRowsChange: (rows: Array<any>) => void;
  shouldDisableInitialSelectedRows?: boolean;
}) => {
  const getUpdateValue = (artifact: ArtifactDto) =>
    artifact?.fileObjects?.reduce(
      (acc, item) => ((item?.updated as number) > acc! ? item?.updated : acc),
      artifact.updated ?? artifact.created
    );
  const artifacts = useMemo(() => {
    return _.map(data, (artifact) => ({
      ...artifact,
      updated: getUpdateValue(artifact)
    }));
  }, [data]);

  const artifactColumns = React.useMemo(() => {
    return [
      {
        id: "Name",
        accessorKey: "name",
        header: "Name",

        cell: ({ getValue }: CellContext<any, unknown>) => <Text value={getValue() as string} />
      },
      {
        id: "Updated on",
        accessorKey: "updated",
        header: "Updated on",

        cell: ({ getValue }: CellContext<any, unknown>) => (
          <span>{dateFormat(getValue(), false)}</span>
        )
      },
      {
        id: "Source",
        accessorKey: "producer",
        header: () => (
          <span style={{ fontSize: "14px", gap: "5px", display: "flex", alignItems: "center" }}>
            Source
            <Tooltip arrow title="Recipe where artifact is the output">
              <InfoOutlined color="disabled" fontSize="small" />
            </Tooltip>
          </span>
        ),

        cell: ({ getValue }: CellContext<any, unknown>) =>
          getSourceVal(getValue() as ArtifactProducer)
      },
      {
        id: "Destinations",
        accessorKey: "consumers",
        header: () => (
          <span style={{ fontSize: "14px", gap: "5px", display: "flex", alignItems: "center" }}>
            Destinations
            <Tooltip arrow title="Recipes where artifact is the input.">
              <InfoOutlined color="disabled" fontSize="small" />
            </Tooltip>
          </span>
        ),

        cell: ({ getValue }: CellContext<any, unknown>) =>
          getDestinationValList(getValue() as ArtifactProducer[])
      }
    ];
  }, []);

  const handleSelectedRowsChange = (rows: Updater<RowSelectionState>) => {
    onSelectedRowsChange(Object.keys(rows));
  };

  return (
    <Table
      data={artifacts}
      columns={artifactColumns}
      getRowId={(row: $TSFixMe) => {
        return row.name;
      }}
      stylesProps={{ size: TABLE_SIZE, maxHeight: "calc(100vh - 337px)" }}
      rowSelectionProps={{
        isSelectable: true,
        onSelectedRowsChange: handleSelectedRowsChange,
        selectedRowIds: selectedRowIds,
        shouldDisableInitialSelectedRows: shouldDisableInitialSelectedRows
      }}
      sortingProps={{ orderByDefault: "Updated on", sortInverted: true }}
      infoProps={{ emptyTableMessage: "No Artifacts" }}
      filterProps={{ globalFilter }}
    />
  );
};
