// Packages
import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { isEmpty } from "lodash";

// Open API
import { BulkDeleteNodesDto, PipelineStep } from "@rapidcanvas/rc-api-core";
import api from "services/AxiosClient/AxiosClient";

interface IPayload {
  deletingNodes?: BulkDeleteNodesDto[];
}

type Response = { [key: string]: Array<PipelineStep> };

const useDeletingNodes = (): UseMutationResult<Response, unknown, IPayload, unknown> =>
  useMutation({
    mutationFn: async ({ deletingNodes }: IPayload) => {
      if (isEmpty(deletingNodes)) {
        throw "Invalid payload!";
      }

      return await api.fetchResponse(
        async () =>
          await api.ProjectsControllerV2Api.getBulkDeleteCandidates(deletingNodes!, true, true)
      );
    }
  });

export default useDeletingNodes;
