import React from "react";
import {
  makeStyles,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogTitle,
  IconButton
} from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import { useStyles as useSubtopBarStyles } from "src/components/SubtopBar/styling";
import { Close } from "@material-ui/icons";

import { useToastsStore } from "stores/zustand/stores";
import { toastContentGetter, toastContentSetter } from "stores/zustand/stores.selectors";

const useStyles = makeStyles({
  paper: {
    width: "100%",
    padding: "20px",
    backgroundColor: "#f5f5f5",
    color: "#000",
    fontSize: 14,
    height: "296px",
    maxHeight: "296px",
    overflow: "auto",
    borderRadius: "0px",
    maxWidth: "800px"
  },
  textContainer: {
    background: "#fff",
    borderRadius: "4px",
    padding: "18px 21px",
    height: "100%",
    whiteSpace: "pre-line",
    overflow: "auto"
  },
  warningIcon: {
    fill: "#DA1D27",
    marginRight: "10px",
    verticalAlign: "bottom"
  },
  dialog: {
    minWidth: "600px"
  }
});

export const ErrorMessageModal = () => {
  const classes = useStyles();
  const { barMainButton } = useSubtopBarStyles();

  // Stores - STARTS >>
  const toastContentStore = useToastsStore(toastContentGetter);
  const setToastContentStore = useToastsStore(toastContentSetter);
  // << ENDS - Stores

  const resetToastContent = () => {
    setToastContentStore(null);
  };

  return (
    <Dialog
      maxWidth="md"
      open={true}
      onClose={resetToastContent}
      aria-labelledby="error-dialog-title"
      classes={{
        paper: classes.dialog
      }}>
      <DialogTitle id="error-dialog-title">
        <Typography>
          <WarningIcon className={classes.warningIcon} />
          Error
        </Typography>
        <IconButton
          aria-label="close"
          onClick={resetToastContent}
          color="primary"
          style={{
            position: "absolute",
            right: 8,
            top: 8
          }}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.paper}>
        <div className={classes.textContainer}>{toastContentStore}</div>
      </DialogContent>
      <DialogActions>
        <Button onClick={resetToastContent} className={barMainButton}>
          CLOSE
        </Button>
      </DialogActions>
    </Dialog>
  );
};
