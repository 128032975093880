import React, { useState, useEffect, useMemo } from "react";

import {
  Grid,
  Typography,
  makeStyles,
  Box,
  Button,
  CircularProgress,
  Tooltip
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { Field } from "../../../components";
import clsx from "clsx";
import useSecrets, { QUERY_KEY_SECRETS } from "src/hooks/api/secrets/useSecrets";
import _, { get, keyBy } from "lodash";
import useAddAndUpdateSecret from "src/hooks/api/secrets/useAddAndUpdateSecret";
import { useQueryClient } from "@tanstack/react-query";
import { toastWrapper } from "services/ToastClient/toastWrapper";
import { PlusFilledIcon } from "src/assets/icons/PlusFilledIcon";
import PasswordField from "src/components/Inputs/PasswordField";
import { Delete } from "src/assets/icons/Delete";
import Modal, { ModalVariants } from "src/components/custom/Modal/Modal";
import useDeleteSecret from "src/hooks/api/secrets/useDeleteSecret";
import EditSecretField from "./EditSecretField";
import EventBus from "services/EventBus/EventBus";

export const useStyles = makeStyles({
  secrets: {
    backgroundColor: "#ffffff",
    padding: "16px",
    gap: "16px",
    borderRadius: "4px"
  },

  inputsContainer: {
    "& textarea": {
      height: "100% !important"
    },
    "& div[class^='MuiFormControl-root']": {
      width: "100%"
    }
  },

  variablesInputs: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    "& > p": {
      marginBottom: 24
    }
  },

  nameInput: {
    "& p": {
      margin: "4px 0px 8px 0px"
    }
  },

  variableMargin: {
    marginBottom: 24
  },
  variableButton: {
    height: 40
  },
  actionContainer: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    columnGap: 5,
    padding: "10px 20px",
    zIndex: 1
  },
  secretGrid: {
    maxHeight: "calc(100vh - 355px)",
    overflow: "auto",
    padding: "10px"
  }
});

export enum SecretDeletePromptDetails {
  title = "Do you really want to delete this secret?",
  message = "If you agree, the secret will be deleted entirely, which could affect recipes that currently utilize it.",
  confirmLabel = "Delete"
}

const Secrets: React.FC = () => {
  const queryClient = useQueryClient();
  const classes = useStyles();
  const secretsResult = useSecrets();
  const [secretsNameErrors, setSecretsNameErrors] = useState<{ [index: number]: string }>({});
  const [secretValueErrors, setSecretValueErrors] = useState<{ [index: number]: string }>({});
  const [values, setValues] = useState<any[]>();
  const addUpdateSecrets = useAddAndUpdateSecret();
  const [showConfirmScreen, setShowConfirmScreen] = useState(false);
  const [selectedSecret, setSelectedSecret] = useState<string>();
  const deleteSecret = useDeleteSecret();

  useEffect(() => {
    if (_.isEmpty(secretsResult.data)) {
      setValues([{ name: "", value: "" }]);
    } else {
      EventBus.publish("RESET-SECRETS");
      setValues([
        ..._.map(secretsResult.data, (item) => _.pick(item, ["id", "name", "value"])),
        { name: "", value: "" }
      ]);
    }
  }, [secretsResult.data]);

  const savedValues = useMemo(
    () =>
      keyBy(
        _.map(secretsResult.data, (item) => _.pick(item, ["id", "name", "value"])),
        "id"
      ),
    [secretsResult.data]
  );

  const isDirty = useMemo(() => {
    if (!_.isUndefined(secretsResult.data)) {
      if (_.isEmpty(secretsResult.data)) {
        const nonEmptySecrets = _.filter(values, (item) => item.name !== "" || item.value !== "");
        return !_.isEmpty(nonEmptySecrets);
      }
      return !_.isEqual(
        _.filter(values, (item) => item.name !== "" && item.value !== ""),
        _.map(secretsResult.data, (item) => _.pick(item, ["id", "name", "value"]))
      );
    }
    return false;
  }, [secretsResult.data, values]);

  const validateName = (inputString: string) => {
    // Check length
    if (!inputString) {
      return "The Secret name cannot be blank.";
    }
    const counts = _.countBy(values, "name");
    if (_.get(counts, inputString) > 1) {
      return "The Secret name has a duplicate name.";
    }
    if (inputString.length < 3 || inputString.length > 64) {
      return "The Secret name needs to be between 3 and 64 characters.";
    }

    // Check if it starts with an alphanumeric character
    if (!/^[a-zA-Z0-9]/.test(inputString)) {
      return "The Secret name contains invalid characters. It needs to start with alphanumerics.";
    }

    // Check for valid characters
    if (!/^[a-zA-Z0-9 _-]+$/.test(inputString)) {
      return "The Secret name contains invalid characters. It can only contain alphanumerics, spaces, underscores and dashes.";
    }

    return "";
  };

  const valiateField = (inputString: string, index: number, type: "name" | "value") => {
    let valid = true;
    if (type === "name") {
      const nameError = validateName(inputString);
      const nameErrors = secretsNameErrors;
      if (nameError) {
        valid = false;
        nameErrors[index] = nameError;
        setSecretsNameErrors(nameErrors);
      } else {
        setSecretsNameErrors(_.omit(nameErrors, index));
      }
    } else {
      const keyErrors = secretValueErrors;
      if (!inputString) {
        valid = false;
        const keyErrors = secretValueErrors;
        keyErrors[index] = "Value cannot be blank";
        setSecretValueErrors(keyErrors);
      } else {
        setSecretValueErrors(_.omit(keyErrors, index));
      }
    }
    return valid;
  };

  const handleVariableChange = (event: $TSFixMe, index: number, type: "name" | "value") => {
    const newVariables = values;
    if (newVariables) {
      newVariables[index] = { ...newVariables[index], [type]: event?.target?.value };
      setValues([...newVariables]);
    }
    valiateField(event.target.value, index, type);
  };

  const onAdd = () => {
    if (values) {
      setValues([...values, { name: "", value: "" }]);
    }
  };

  const removeErrors = (index: number) => {
    const nameErrors = secretsNameErrors;
    const keyErrors = secretValueErrors;
    setSecretsNameErrors(_.omit(nameErrors, index));
    setSecretValueErrors(_.omit(keyErrors, index));
  };

  const onDelete = (index: number) => {
    if (values) {
      const newValues = values.filter((_, i) => i !== index);
      setValues(newValues);
    }
    removeErrors(index);
  };

  const onCancel = () => {
    setSecretsNameErrors({});
    setSecretValueErrors({});
    if (_.isEmpty(secretsResult.data)) {
      setValues([{ name: "", value: "" }]);
    } else {
      setValues([
        ..._.map(secretsResult.data, (item) => _.pick(item, ["id", "name", "value"])),
        { name: "", value: "" }
      ]);
    }
    EventBus.publish("RESET-SECRETS");
  };

  const handleSave = () => {
    let isValid = true;
    _.forEach(values, (value: any, index: number) => {
      if (value.name !== "" || value.value !== "") {
        const validNameField = valiateField(value.name, index, "name");
        const validValueField = valiateField(value.value, index, "value");
        isValid = validNameField && validValueField;
      }
    });
    if (!isValid) {
      return;
    }

    const newSecrets = _.filter(values, (item) => !_.has(item, "id"));
    const updatedSecrets: Record<string, string>[] = [];
    const oldSecrets = _.filter(values, (item) => _.has(item, "id"));
    const keyedSecrets = _.keyBy(secretsResult.data, "id");
    _.forEach(oldSecrets, (item) => {
      if (item.value !== _.get(keyedSecrets, [item.id, "value"])) {
        updatedSecrets.push(item);
      }
    });
    addUpdateSecrets.mutate(
      {
        add: _.filter(newSecrets, (item: any) => item.name !== "" && item.value !== ""),
        update: updatedSecrets
      },
      {
        onSuccess: () => {
          toastWrapper({
            type: "success",
            content: " Secret(s) saved successfully!"
          });

          queryClient.invalidateQueries([QUERY_KEY_SECRETS]);
        },
        onError: () => {
          queryClient.invalidateQueries([QUERY_KEY_SECRETS]);
        }
      }
    );
  };

  const cancelDeleteSecret = () => {
    setShowConfirmScreen(false);
  };

  const openDeleteModal = (id: string) => {
    setShowConfirmScreen(true);
    setSelectedSecret(id);
  };

  const onDeleteSecret = () => {
    if (selectedSecret) {
      deleteSecret.mutate(
        { id: selectedSecret },
        {
          onSuccess: () => {
            setShowConfirmScreen(false);
            toastWrapper({
              type: "success",
              content: "Secret is deleted successfully!"
            });
            queryClient.invalidateQueries([QUERY_KEY_SECRETS]);
          },
          onError: () => {
            setShowConfirmScreen(false);
          }
        }
      );
    }
  };

  return (
    <Grid container direction="column" className={classes.secrets}>
      <Grid container item justifyContent="space-between">
        <Typography variant="h6" color="textPrimary">
          Secrets
        </Typography>
        <Grid item className={classes.actionContainer}>
          <Tooltip title={!isDirty ? "No unsaved changes found." : ""}>
            <span>
              <Button
                id={"cancel-button"}
                size="small"
                variant="outlined"
                disabled={!isDirty}
                onClick={onCancel}>
                Cancel
              </Button>
            </span>
          </Tooltip>
          <Tooltip
            title={
              !isDirty || !_.isEmpty(secretValueErrors) || !_.isEmpty(secretsNameErrors)
                ? "Please fill all mandatory fields to enable this"
                : ""
            }>
            <span>
              <Button
                id={"save-button"}
                variant="contained"
                color="primary"
                size="small"
                disabled={
                  !isDirty || !_.isEmpty(secretValueErrors) || !_.isEmpty(secretsNameErrors)
                }
                disableElevation
                onClick={handleSave}>
                {addUpdateSecrets.isLoading ? (
                  <CircularProgress size={16} style={{ color: "#fff" }} />
                ) : (
                  "Save"
                )}
              </Button>
            </span>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid item className={classes.secretGrid}>
        <Box className={clsx(classes.variablesInputs, classes.inputsContainer)}>
          {secretsResult.isFetching && _.isEmpty(secretsResult.data) ? (
            <Box pb="16px">
              <Skeleton variant="rect" height={65} />
            </Box>
          ) : (
            values?.map((secret: any, index: number) => {
              const isLastSecret = index === values?.length - 1;
              return (
                <Grid container justifyContent="space-between">
                  <Box
                    key={`variable-inputs-${index}`}
                    style={{ width: "calc(100% - 50px)" }}
                    display="flex"
                    gridGap="24px">
                    <Field
                      id={`variable-name-${index}`}
                      label="Name"
                      className={clsx(classes.nameInput, {
                        [classes.variableMargin]: !secretsNameErrors?.[index]
                      })}
                      disabled={!!secret.id}
                      value={secret?.name || ""}
                      onChange={(event: $TSFixMe) => {
                        handleVariableChange(event, index, "name");
                      }}
                      required
                      variant="outlined"
                      size="small"
                      error={!!secretsNameErrors?.[index]}
                      helperText={secretsNameErrors?.[index]}
                    />
                    {secret.id ? (
                      <EditSecretField
                        id={`variable-key-value-${index}`}
                        label="Value"
                        name={"pass-" + Math.random().toString(36).substring(2)}
                        className={clsx(classes.nameInput, {
                          [classes.variableMargin]: !secretsNameErrors?.[index]
                        })}
                        value={secret?.value || ""}
                        onChange={(event: $TSFixMe) => {
                          handleVariableChange(event, index, "value");
                        }}
                        autoComplete="off"
                        savedValue={get(savedValues, [secret.id, "value"])}
                        required
                        variant="outlined"
                        size="small"
                        error={!!secretValueErrors?.[index]}
                        helperText={secretValueErrors?.[index]}
                      />
                    ) : (
                      <PasswordField
                        id={`variable-value-${index}`}
                        label="Value"
                        name={"pass-" + Math.random().toString(36).substring(2)}
                        className={clsx(classes.nameInput, {
                          [classes.variableMargin]: !secretsNameErrors?.[index]
                        })}
                        value={secret?.value || ""}
                        onChange={(event: $TSFixMe) => {
                          handleVariableChange(event, index, "value");
                        }}
                        autoComplete="new-password"
                        required
                        variant="outlined"
                        size="small"
                        error={!!secretValueErrors?.[index]}
                        helperText={secretValueErrors?.[index]}
                      />
                    )}
                  </Box>
                  <Grid>
                    {isLastSecret ? (
                      <Button
                        id={`add-variable-button-${index}`}
                        className={classes.variableButton}
                        color="primary"
                        size="small"
                        onClick={onAdd}
                        disabled={secret?.value === "" && secret?.name === ""}>
                        <PlusFilledIcon />
                      </Button>
                    ) : (
                      <Button
                        id={`delete-variable-button-${index}`}
                        className={classes.variableButton}
                        color="primary"
                        size="small"
                        onClick={() =>
                          _.has(secret, "id") ? openDeleteModal(secret?.id) : onDelete(index)
                        }>
                        <Delete />
                      </Button>
                    )}
                  </Grid>
                </Grid>
              );
            })
          )}
        </Box>
      </Grid>
      <Modal
        open={showConfirmScreen}
        variant={ModalVariants.Delete}
        title="Delete Secret"
        content={[SecretDeletePromptDetails.message, SecretDeletePromptDetails.title]}
        onClose={cancelDeleteSecret}
        onSubmit={onDeleteSecret}
        isSubmitting={deleteSecret.isLoading}
      />
    </Grid>
  );
};

export default Secrets;
