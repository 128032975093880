import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

import { getAPIWithRethrow } from "services/Apis/Apis.service";

const QUERY_KEY_PREDICTION_SERVICE_BY_MODEL = "query-key-prediction-service-by-Model";

export interface IModelReturn {
  name: string;
  files: string[];
  status: string;
  predictionServiceDetails: {
    id: string;
    name: string;
    description: string;
    modelName: string;
    displayName: string;
    envId: string;
    envDetails: {
      id: string;
      name: string;
      cores: number;
      gpus: null;
      memInMbs: number;
      diskInGbs: number;
      launchStatus: null;
      envType: string;
    };
    timeoutInMins: number;
    logCalls: boolean;
    concurrency: number;
  };
  producer: {
    projectId: string;
    projectName: string;
    recipeId: string;
    recipeType: string;
    recipeName: string;
    scenarioId: string;
    scenarioName: string;
  };
}

const usePredictionServiceByModel = (
  modelName?: string,
  scenarioId?: string,
  jobRunId?: string,
  options?: UseQueryOptions<IModelReturn>
): UseQueryResult<IModelReturn> => {
  return useQuery<any>({
    queryKey: [QUERY_KEY_PREDICTION_SERVICE_BY_MODEL, modelName],
    queryFn: async () => {
      let jobQueryParams: $TSFixMe = ``;
      if (!!jobRunId) {
        jobQueryParams = `${!!scenarioId ? `&scenarioId=${scenarioId}` : ``}`;
        jobQueryParams = `${jobQueryParams}${!!jobRunId ? `&projectRunEntryId=${jobRunId}` : ``}`;
      }

      return await getAPIWithRethrow(
        `/v2/prediction-services/model-details/${modelName}?${jobQueryParams}`
      );
    },
    enabled: !!modelName,
    ...options
  });
};

export default usePredictionServiceByModel;
export { QUERY_KEY_PREDICTION_SERVICE_BY_MODEL };
