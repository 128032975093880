/* eslint-disable no-unused-vars */
import React, { useMemo } from "react";
import {
  Button,
  Grid,
  IconButton,
  Typography,
  makeStyles,
  Tooltip,
  CircularProgress
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { useLocation } from "react-router";

import { ArtifactMini, Entities, EntityFeaturesResponse, ModelMini } from "src/types";
import { InputDatasetsView } from "../../common/InputDatasetsView";
import { ArtifactIcon } from "src/assets/icons/ArtifactIcon";
import { ModelsIcon } from "src/assets/icons/ModelsIcon";
import { CollapseIcon } from "src/assets/icons/CollapseIcon";
import { READONLY_ENTITY } from "projectsModule/utils/Projects.constants";
import { filter, isEmpty, orderBy, toLower } from "lodash";
import NoData from "../../AddCodeRecipe/NoData";
import { OverflowTooltip } from "src/components";

const useStyles = makeStyles({
  codeRecipeHeader: {
    padding: "16px"
  },

  inputDatasets: {
    maxHeight: "calc(100% - 63px)",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      "-webkit-appearance": "none",
      width: "6px",
      height: "6px"
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "6px",
      backgroundColor: "#7C7C7C",
      "-webkit-box-shadow": "0 0 1px rgba(255, 255, 255, 0.5)"
    }
  },
  inputWrap: {
    height: "100%",
    maxHeight: "100%",
    flexWrap: "nowrap",
    overflow: "auto"
  },
  entityLeftContent: {
    flexWrap: "nowrap",
    gap: "8px",
    alignItems: "center",
    minHeight: "48px",
    padding: "0px 6px",
    border: "0.5px #D1D1D1",
    borderStyle: "solid none;"
  },
  entityText: {
    fontWeight: 400
  },
  entityTitle: {
    color: "#000000",
    opacity: "0.5",
    fontSize: "12px",
    lineHeight: "16px",
    padding: "8px 16px"
  },
  btnContainer: {
    flexWrap: "nowrap",
    gap: "16px",
    flex: 0
  }
});

type Props = {
  inputDatasets: Entities;
  entityFeaturesMap: {
    [id: string]: EntityFeaturesResponse;
  } | null;
  onDeleteDataset: (datasetId: string) => void;
  openAddEntitiesDialog: () => void;
  selectedArtifacts: Array<ArtifactMini>;
  selectedModels: Array<ModelMini>;
  recipeId: string | undefined;
  onCollapse: () => void;
  isDefaultScenario: boolean;
  isSelectedEntitiesUpdateInProgress: boolean;
};

export const StandardRecipeInputsView = ({
  inputDatasets,
  entityFeaturesMap,
  openAddEntitiesDialog,
  onDeleteDataset,
  selectedArtifacts,
  selectedModels,
  recipeId,
  onCollapse,
  isDefaultScenario,
  isSelectedEntitiesUpdateInProgress
}: Props) => {
  const classes = useStyles();

  const location = useLocation();
  const isJobPath = useMemo(() => /jobs/.test(location.pathname), [location.pathname]);

  const filteredInputDatasets = useMemo(
    () => filter(inputDatasets, (item) => item.entityMeta.entityViewType !== "FILE"),
    [inputDatasets]
  );

  const textFileInputDataset = useMemo(
    () => filter(inputDatasets, (item) => item.entityMeta.entityViewType === "FILE"),
    [inputDatasets]
  );

  return (
    <Grid container direction="column" className={classes.inputWrap}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={classes.codeRecipeHeader}>
        <Typography variant="subtitle1">Inputs</Typography>
        <Grid container direction="row" className={classes.btnContainer}>
          {!isJobPath && (
            <Tooltip title={!isDefaultScenario ? READONLY_ENTITY : ""}>
              <div>
                <Button
                  color="primary"
                  test-id="standardRecipeHeaderAddEntitiesBtn"
                  variant="contained"
                  startIcon={
                    isSelectedEntitiesUpdateInProgress ? (
                      <CircularProgress size={20} />
                    ) : (
                      <AddIcon htmlColor="#ffffff" />
                    )
                  }
                  onClick={openAddEntitiesDialog}
                  disabled={!isDefaultScenario || isSelectedEntitiesUpdateInProgress}
                  size="small">
                  Add
                </Button>
              </div>
            </Tooltip>
          )}
          <IconButton onClick={onCollapse} size="small">
            <CollapseIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid className={classes.inputDatasets}>
        {isEmpty(inputDatasets) && isEmpty(selectedArtifacts) && isEmpty(selectedModels) && (
          <NoData content="No Inputs selected" />
        )}

        {filteredInputDatasets.length > 0 && (
          <InputDatasetsView
            isDefaultScenario={isDefaultScenario}
            inputDatasets={filteredInputDatasets}
            recipeId={recipeId}
            onDeleteDataset={onDeleteDataset}
            entityFeaturesMap={entityFeaturesMap}
          />
        )}

        {textFileInputDataset.length > 0 && (
          <InputDatasetsView
            isDefaultScenario={isDefaultScenario}
            recipeId={recipeId}
            inputDatasets={textFileInputDataset}
            onDeleteDataset={onDeleteDataset}
            datasetType="FILE"
            entityFeaturesMap={entityFeaturesMap}
          />
        )}

        {selectedArtifacts?.length > 0 && (
          <Grid container direction="column">
            <Grid>
              <Typography variant="subtitle2" className={classes.entityTitle}>
                ARTIFACTS ({selectedArtifacts?.length || 0})
              </Typography>
            </Grid>
            {orderBy(selectedArtifacts, (item) => toLower(item.name)).map((artifact) => (
              <Grid
                container
                key={artifact.name}
                direction="row"
                className={classes.entityLeftContent}>
                <ArtifactIcon />
                <Grid style={{ width: "200px" }}>
                  <OverflowTooltip
                    className={classes.entityText}
                    style={{ whiteSpace: "nowrap", fontSize: "14px", fontWeight: 400 }}
                    test-id="code-recipe-input-dataset-name"
                    value={artifact.name}
                  />
                </Grid>
              </Grid>
            ))}
          </Grid>
        )}
        {selectedModels?.length > 0 && (
          <Grid container direction="column">
            <Grid>
              <Typography variant="subtitle2" className={classes.entityTitle}>
                MODELS ({selectedModels?.length || 0})
              </Typography>
            </Grid>
            {orderBy(selectedModels, (item) => toLower(item.name)).map((model) => (
              <Grid
                container
                key={model.name}
                direction="row"
                className={classes.entityLeftContent}>
                <ModelsIcon />
                <Grid style={{ width: "200px" }}>
                  <OverflowTooltip
                    style={{ whiteSpace: "nowrap", fontSize: "14px", fontWeight: 400 }}
                    className={classes.entityText}
                    test-id="code-recipe-input-dataset-name"
                    value={model.name}
                  />
                </Grid>
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
