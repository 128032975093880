import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import React from "react";
import _ from "lodash";
import { Tooltip } from "@material-ui/core";

import TextField from "./TextFieldWithValidation";
import { CONNECTOR_KEYS } from "../utils/OutputDataset.constants";
import { IConnectorDetailsProps } from "./SnowflakeConnectorDetails";
import { useStyles } from "components/Canvas/CanvasDrawerItems/components/styling";

const FileBasedConnectorDetails: React.FC<IConnectorDetailsProps> = (props) => {
  const { isTextInput = false, values, onChange, readonly = false } = props;
  const { flexWidth } = useStyles();

  return (
    <>
      <TextField
        fullWidth
        id="destination-folder"
        label={CONNECTOR_KEYS.fileCategory.destinationFolder.label}
        name={CONNECTOR_KEYS.fileCategory.destinationFolder.key}
        required
        size="small"
        data-testid="destination-folder"
        value={_.get(values, CONNECTOR_KEYS.fileCategory.destinationFolder.key, "")}
        variant="outlined"
        onChange={onChange}
        disabled={!!readonly}
      />
      <div className={flexWidth} style={{ width: !!readonly ? "100%" : "110%" }}>
        <TextField
          fullWidth
          id="destination-filename"
          label={CONNECTOR_KEYS.fileCategory.destinationFileName.label}
          name={CONNECTOR_KEYS.fileCategory.destinationFileName.key}
          required
          size="small"
          data-testid="destination-filemane"
          value={_.get(values, CONNECTOR_KEYS.fileCategory.destinationFileName.key, "")}
          variant="outlined"
          onChange={onChange}
          disabled={!!readonly}
        />
        {!readonly && (
          <Tooltip
            title={
              <span>
                Export will use this filename to export the{" "}
                {!!isTextInput ? "text output" : "dataset"} into a parquet/csv file. <br /> Please
                note that this file will be overwritten with every export. To create new file, you
                can provide a different name before every export action.
              </span>
            }>
            <InfoOutlinedIcon />
          </Tooltip>
        )}
      </div>
    </>
  );
};

export default FileBasedConnectorDetails;
