import React, { useMemo, useState } from "react";
import {
  Grid,
  IconButton,
  InputProps,
  List,
  ListItem,
  Typography,
  makeStyles
} from "@material-ui/core";

import { OverflowTooltip, SearchField, Spinner } from "src/components";

import _, { filter, includes, isEmpty, toLower } from "lodash";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import useSecrets from "src/hooks/api/secrets/useSecrets";
import CopyNameButton from "./CodeRecipeCanvasEntities/CopyNameButton";
import NoData from "./NoData";

const useStyles = makeStyles({
  codeRecipeHeader: {
    padding: "0px 16px",
    height: "44px",
    flexDirection: "row",
    flexWrap: "nowrap",
    background: "#d9d9d933",
    borderRadius: "11px 11px 0px 0px"
  },

  inputWrap: {
    height: "calc(100% - 79px)",
    flexWrap: "nowrap",
    overflow: "auto",
    borderRadius: "0px 0px 11px 11px",
    "&::-webkit-scrollbar": {
      "-webkit-appearance": "none",
      width: "6px",
      height: "6px"
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "6px",
      backgroundColor: "#003656",
      "-webkit-box-shadow": "0 0 1px rgba(255, 255, 255, 0.5)"
    }
  },

  btnContainer: {
    flexWrap: "nowrap",
    gap: "8px",
    flex: 0
  },
  secret: {
    padding: "4px 8px 4px 16px",
    height: "35px",
    fontSize: "14px",
    borderBottom: "0.5px solid #D1D1D1",
    "&:last-child": {
      border: "none"
    },
    "&:hover $actionButton": {
      visibility: "visible"
    }
  },
  actionButton: {
    visibility: "hidden"
  },
  customScrollbar: {
    "&::-webkit-scrollbar": {
      "-webkit-appearance": "none",
      width: "6px",
      height: "6px"
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "6px",
      backgroundColor: "#003656",
      "-webkit-box-shadow": "0 0 1px rgba(255, 255, 255, 0.5)"
    }
  }
});

const Secrets = () => {
  const [expanded, setExpanded] = useState(false);

  const { isLoading, data: secretsData } = useSecrets({ refetchOnMount: true });
  const [searchValue, setSearchValue] = useState("");

  const onCollapse = () => {
    setExpanded((prev) => !prev);
  };
  const handleSearch: InputProps["onChange"] = (event) => {
    setSearchValue(event?.target.value);
  };

  const filteredEntities = useMemo(() => {
    if (secretsData) {
      return filter(secretsData, (item) =>
        !!searchValue ? includes(toLower(item.name), toLower(searchValue)) : true
      );
    } else {
      return [];
    }
  }, [secretsData, searchValue]);

  const classes = useStyles();

  return (
    <div
      style={{
        height: "100%",
        border: "1px solid #BDBDBD",
        borderRadius: "11px",
        background: "#FFFFFF"
      }}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        onClick={onCollapse}
        className={classes.codeRecipeHeader}>
        <Typography variant="subtitle1">Secrets</Typography>

        <Grid container direction="row" className={classes.btnContainer}>
          <IconButton data-testid="entities-on-canvas-collapse" size="small">
            {expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </Grid>
      </Grid>
      {expanded && (
        <>
          {isLoading ? (
            <Spinner size={20} />
          ) : isEmpty(secretsData) ? (
            <NoData content="No Secrets" />
          ) : (
            <>
              <SearchField
                test-id="entities-on-canvas-search-field"
                onChange={handleSearch}
                InputProps={{
                  style: { height: "34px" }
                }}
                style={{ borderRadius: "0px", width: "100%" }}
                value={searchValue}
                placeholder="Search"
              />
              {isEmpty(filteredEntities) ? (
                <NoData content="No search result" />
              ) : (
                <List
                  style={{
                    padding: 0,
                    height: "calc(100% - 78px)",
                    overflow: "auto"
                  }}
                  className={classes.customScrollbar}>
                  {_.map(filteredEntities, (item) => (
                    <ListItem className={classes.secret}>
                      <Grid container alignItems="center">
                        <Grid style={{ width: "calc(100% - 30px)" }}>
                          <OverflowTooltip style={{ whiteSpace: "nowrap" }} value={item.name} />
                        </Grid>

                        <CopyNameButton
                          name={item.name}
                          tooltip="Copy Secret Name"
                          className={classes.actionButton}
                        />
                      </Grid>
                    </ListItem>
                  ))}
                </List>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default React.memo(Secrets);
