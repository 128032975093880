import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Button, Tooltip } from "@material-ui/core";
import { isEmpty, map, uniq, get, size, filter } from "lodash";

import CommonLoader from "src/components/CommonLoader";
import CreateDataApp from "./CreateDataApp/CreateDataApp";
import ReactException from "components/Errors/ReactException";
import useGetAppTemplates from "src/hooks/api/dataapps/useGetAppTemplates";
import useGetDataApps from "../../../../hooks/api/dataapps/useGetDataApps";
import useProjectDataAppsDetails from "src/hooks/api/dataapps/useProjectDataAppsDetails";
import { DataAppType } from "pages/DataApps/DataApps.type";
import { DataAppsHeader } from "pages/DataApps/DataAppsDashboard/DataAppsHeader/DataAppsHeader";
import { getDocsUrl } from "utils/helpers";
import {
  ADD_DATAAPP_TOOLTIP,
  dataAppGettingStarted,
  DataAppsSplashSection,
  IMPORT_TOOLTIP
} from "pages/DataApps/utils/DataApps.constants";
import { DataAppsView } from "pages/DataApps/DataAppsDashboard/DataAppsView";
import { DeleteDataAppModal } from "pages/DataApps/DataAppsDashboard/DeleteDataAppModal";

import { listViewPages, PagesViewConfiguration } from "constants/index";

import _ from "lodash";
import { useGetRole } from "src/hooks/useGetRole";
import GettingStarted from "components/custom/GetttingStarted/GettingStarted";
import CustomDataApp from "icons/NewUX/GettingStartedIllustrations/CustomDataApp";
import ImportDataApp from "icons/NewUX/GettingStartedIllustrations/ImportDataApp";

const ProjectDataApps: React.FC = () => {
  const { data, isLoading, isError, refetch } = useGetDataApps();

  const { projectId } = useParams();
  const location = useLocation();

  const dataAppDetailsResponse = useProjectDataAppsDetails(projectId);
  const templateResponse = useGetAppTemplates(undefined, undefined, undefined, {
    enabled: !!projectId
  });

  const dataApps = useMemo(
    () => filter(data, (app) => app.projectId === projectId),
    [data, projectId]
  );

  const pagesViewConfiguration = JSON.parse(localStorage.getItem(PagesViewConfiguration) || "{}");
  const isTilesView = get(pagesViewConfiguration, listViewPages.DATAAPPS, true);
  const [tilesView, setTilesView] = useState(isTilesView);
  const { isRoleYieldsDataAppView } = useGetRole();

  const [dataAppType, setDataAppType] = useState<"auto-create" | "import-zip" | null>(null);
  const [searchValue, setSearchValue] = useState("");
  const [dataAppToDelete, setDataAppToDelete] = useState<DataAppType | null>(null);
  const [selectedProjects, setSelectedProjects] = useState<string[]>(
    uniq(dataApps?.map((dataApp) => dataApp.projectId)) || []
  );

  useEffect(() => {
    if (location?.state?.type !== undefined) {
      setDataAppType(location?.state?.type);
    }
  }, [location?.state?.type]);

  useEffect(() => {
    if (isEmpty(selectedProjects)) {
      setSelectedProjects(uniq(map(dataApps, (dataApp) => dataApp.projectId)));
    }
  }, [dataApps]);

  const searchedDataApps = React.useMemo(
    () =>
      dataApps?.filter((dataApp: DataAppType) =>
        (dataApp.displayName || dataApp.name)?.toLowerCase?.().includes?.(searchValue.toLowerCase())
      ),
    [dataApps, searchValue]
  );

  const filteredDataApps = React.useMemo(
    () =>
      selectedProjects.length !== 0
        ? _.orderBy(
            searchedDataApps,
            [(item) => (item.launchStatus === "RUNNING" ? 1 : 0), "updatedOn"],
            ["desc", "desc"]
          )?.filter((dataApp: DataAppType) => selectedProjects?.includes(dataApp.projectId))
        : [],
    [searchedDataApps, selectedProjects]
  );

  const handleSearch = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleDelete = (dataApp: DataAppType) => {
    setDataAppToDelete(dataApp);
  };

  const changeDataAppType = (type: "auto-create" | "import-zip" | null) => {
    setDataAppType(type);
  };

  const handleCreateClick = (type: "auto-create" | "import-zip") => {
    changeDataAppType(type);
    setSearchValue("");
  };

  if (isLoading) {
    return <CommonLoader />;
  }

  if (isError) {
    return <ReactException onClick={refetch} />;
  }

  if (dataAppType) {
    return (
      <CreateDataApp
        allDataApps={data}
        dataAppType={dataAppType}
        dataApps={dataApps}
        binaryClassRecipes={dataAppDetailsResponse.data}
        recipeLoading={dataAppDetailsResponse.isLoading}
        appTemplates={templateResponse.data}
        isLoading={dataAppDetailsResponse.isLoading || templateResponse.isLoading}
        onCancel={() => changeDataAppType(null)}
      />
    );
  }

  return (
    <>
      <DataAppsHeader
        searchValue={searchValue}
        isTilesView={tilesView}
        openCreateDataApp={!!dataAppType}
        onSearch={handleSearch}
        onViewChange={setTilesView}
        onClick={handleCreateClick}
      />

      {size(dataApps) === 0 ? (
        <GettingStarted
          outerBoxWidth="860px"
          boxes={[
            {
              message: dataAppGettingStarted.topMessage1,
              icon: <CustomDataApp />,
              action: !isRoleYieldsDataAppView ? (
                <Tooltip title={ADD_DATAAPP_TOOLTIP}>
                  <Button
                    color="primary"
                    variant="outlined"
                    data-testid="createDataApp"
                    size="medium"
                    onClick={() => handleCreateClick("auto-create")}>
                    Create DataApp
                  </Button>
                </Tooltip>
              ) : (
                <></>
              )
            },
            {
              message: dataAppGettingStarted.topMessage2,
              icon: <ImportDataApp />,
              action: !isRoleYieldsDataAppView ? (
                <Tooltip title={IMPORT_TOOLTIP}>
                  <Button
                    color="primary"
                    variant="outlined"
                    data-testid="importDataApp"
                    size="medium"
                    onClick={() => handleCreateClick("import-zip")}>
                    {DataAppsSplashSection.importLabel}
                  </Button>
                </Tooltip>
              ) : (
                <></>
              )
            }
          ]}
          introduction={{
            question: dataAppGettingStarted.introduction.question,
            answer: dataAppGettingStarted.introduction.answer
          }}
          functionality={{
            question: dataAppGettingStarted.functionality.question,
            answers: dataAppGettingStarted.functionality.answers,
            docsLink: `${getDocsUrl()}/basic/projects/dataapps`
          }}
        />
      ) : (
        <DataAppsView
          allDataApps={dataApps}
          searchValue={searchValue}
          dataApps={filteredDataApps}
          onDelete={handleDelete}
          setIsTilesView={setTilesView}
          isTilesView={tilesView}
          onSearch={handleSearch}
          isProjectDataApps={true}
          onClearSearch={() => setSearchValue("")}
          searchedDataApps={searchedDataApps}
          selectedProjects={selectedProjects}
          setSelectedProjects={setSelectedProjects}
        />
      )}
      {dataAppToDelete && (
        <DeleteDataAppModal
          projectId={dataAppToDelete?.projectId}
          dataAppId={dataAppToDelete?.id}
          dataAppName={dataAppToDelete?.displayName || dataAppToDelete?.name}
          open={!!dataAppToDelete?.id}
          onClose={() => setDataAppToDelete(null)}
        />
      )}
    </>
  );
};

export default ProjectDataApps;
