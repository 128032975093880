import React from "react";

// Packages
import clsx from "clsx";
import { get } from "lodash";

// MUI
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

// Open API
import { EnvDto } from "@rapidcanvas/rc-api-core";

// Components
import StopEnvironment from "../../Environments/components/StopEnvironment";

// Constants
import { EnvironmentStatusValuesMapping } from "src/pages/private/EnvironmentsModule/utils/Environments.constants";

//Hooks
import { useStatusWithStopEnvState } from "../../Environments/hooks/useStatusWithStopEnvState";

// Styles
import useStyles from "./StatusBar.styles";

interface IProps {
  environment: EnvDto;
  isActions?: boolean;
  subtopBarStyles?: boolean;
  styleProps?: any;
}

const StatusBar: React.FC<IProps> = (props) => {
  const { environment, isActions = false, subtopBarStyles, styleProps } = props;
  const { launchStatus: status } = environment;

  const classes: any = useStyles();

  const {
    confirmStopEnvironment,
    resetStopEnvironment,
    onClick,
    showConfirmStopEnvModal,
    statusText,
    showProgress,
    statusClass
  } = useStatusWithStopEnvState({ environment });

  return (
    <>
      {!!showConfirmStopEnvModal && (
        <StopEnvironment
          data={showConfirmStopEnvModal}
          confirmStopEnvironment={confirmStopEnvironment}
          resetStopEnvironment={resetStopEnvironment}
        />
      )}

      <Grid
        container
        className={clsx(classes.statusContainer, classes[statusClass], {
          [classes.subtopBarStyles]: !!subtopBarStyles
        })}
        alignItems="center"
        justifyContent="space-between"
        spacing={1}
        {...styleProps}>
        <Grid item>
          <Typography variant="body2" data-testid="envStatusBarText">
            {statusText}
          </Typography>
        </Grid>
        {!!isActions && (
          <Grid item>
            {!!showProgress ? (
              <CircularProgress size={14} />
            ) : (
              <Button
                size="small"
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  event.stopPropagation();
                  onClick();
                }}
                data-testid="envStatusBarStatusAction">
                {!!status && get(EnvironmentStatusValuesMapping, [status, "buttonText"])}
              </Button>
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default StatusBar;
