import useQuery from "src/hooks/useQuery";
import { checkAndFetchRelativeUrl, updatedSearchParams } from "utils/helpers/url.helpers";

export const useAuthNotebookRedirect = () => {
  const queryParameters = useQuery();
  const redirectUrl = queryParameters.get("redirectUrl");
  const includeAuth = Boolean(queryParameters.get("includeAuth"));
  const shouldRedirect = redirectUrl?.includes("/notebooks") && includeAuth;

  const redirectToNotebook = (token: string) => {
    if (shouldRedirect && redirectUrl) {
      const cleanedUrl = checkAndFetchRelativeUrl(redirectUrl);
      const [pathname, searchParams] = cleanedUrl.split("?");
      const domain = window.location.origin;
      const updatedParams = updatedSearchParams(searchParams, { token }, includeAuth);
      window.location.replace(
        `${domain}${pathname.startsWith("/") ? pathname : `/${pathname}`}?${updatedParams}`
      );
    }
  };
  return { shouldRedirect, redirectToNotebook };
};
