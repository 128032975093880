import { forEach, get, includes, isNumber, map, some } from "lodash";
import { useMutation, UseMutationResult } from "@tanstack/react-query";

import api from "services/AxiosClient/AxiosClient";
import { PositionDto } from "@rapidcanvas/rc-api-core";

interface IProps {
  projectId: string;
  scenarioId: string;
  projectRunId: string;
}

const useRearrangeAndSaveNodes = (): UseMutationResult<null, unknown, IProps, unknown> => {
  return useMutation({
    mutationFn: async ({ projectId, scenarioId, projectRunId }) => {
      const response = await api.fetchResponse(
        async () =>
          await api.ProjectsControllerV2Api.getProjectCanvas(
            projectId,
            true,
            scenarioId,
            projectRunId
          )
      );

      const nodes = response.nodes;
      const hasPredictionNode = some(nodes, (node) => {
        const name = get(node, "displayName") || get(node, "name");
        return name && includes(name, "prediction_output");
      });

      if (hasPredictionNode) {
        const minimalNodes = map(response.nodes, (node) => ({
          id: node.id,
          column: node.column,
          row: node.row
        }));

        const minimalEdges = response.edges?.map((edge) => ({
          id: edge.id,
          source: edge.source,
          target: edge.target
        }));

        const rearrangedResponse = await api.fetchResponse(
          async () =>
            await api.ProjectsControllerV2Api.rearrangeGraph(projectId, {
              nodes: minimalNodes,
              edges: minimalEdges
            })
        );

        const nodesToUpdate: PositionDto[] = [];

        forEach(rearrangedResponse.nodes, (node) => {
          if (node.id && isNumber(node.column) && isNumber(node.row)) {
            nodesToUpdate.push({
              id: node.id,
              positionX: node.column,
              positionY: node.row
            });
          }
        });

        await api.fetchResponse(
          async () =>
            await api.ProjectsControllerV2Api.updatePositions(
              projectId,
              nodesToUpdate,
              projectRunId
            )
        );
      }
      return null;
    }
  });
};

export default useRearrangeAndSaveNodes;
