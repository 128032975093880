import { Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React from "react";

interface IProps {
  content: string;
}
const NoData: React.FC<IProps> = ({ content }) => {
  return (
    <Grid container alignItems="center" justifyContent="center">
      <Alert
        severity="info"
        style={{
          width: "100%",
          borderBottomLeftRadius: "12px",
          borderBottomRightRadius: "12px"
        }}>
        {content}
      </Alert>
    </Grid>
  );
};

export default NoData;
