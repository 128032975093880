import React from "react";

import PredictRecipeIcon from "icons/NewUX/PredictRecipeIcon";
import { ArtifactIcon } from "icons/NewUX/ArtifactIcon";
import { ChartIcon, FileIcon } from "icons/NewUX";
import { ClockIcon } from "icons/NewUX/ClockIcon";
import { ConnectorsIcon } from "icons/NewUX";
import { DataAppIcon } from "icons/NewUX/DataAppIcon";
import { DatasetIcon } from "icons/NewUX/DatasetIcon";
import { EnvironmentsIcon } from "icons/NewUX";
import { ModelIcon } from "icons/NewUX/ModelIcon";
import { ProjectsIcon } from "icons/NewUX/ProjectsIcon";
import { RecipeIcon } from "src/assets/icons/RecipeIcon";
import { SearchRequestDtoSearchComponentsEnum } from "@rapidcanvas/rc-api-core";
import { InfoOutlined } from "@material-ui/icons";
import { Tooltip } from "@material-ui/core";
import { VectorDBIcon } from "icons/NewUX/VectorDBIcon";

export const allKey = "All";
export const RECENT_SEARCH_KEY = "recentSearches";

export const SEARCH_COMPONENTS = {
  [allKey]: {
    icon: <></>,
    label: allKey
  },
  [SearchRequestDtoSearchComponentsEnum.Project]: {
    icon: <ProjectsIcon />,
    label: "Projects"
  },
  [SearchRequestDtoSearchComponentsEnum.Recipe]: {
    icon: <RecipeIcon width={20} height={20} />,
    label: "Recipes"
  },
  [SearchRequestDtoSearchComponentsEnum.Dataset]: {
    icon: <DatasetIcon width={20} height={16} viewBox="0 0 20 20" />,
    label: "Datasets"
  },
  [SearchRequestDtoSearchComponentsEnum.Chart]: {
    icon: <ChartIcon width={20} height={20} viewBox="2 -4 40 40" />,
    label: "Charts"
  },
  [SearchRequestDtoSearchComponentsEnum.Model]: {
    icon: <ModelIcon />,
    label: "Project Models",
    extra: (
      <Tooltip title="Searches only models that are associated to project(s)">
        <InfoOutlined style={{ fontSize: "1rem" }} />
      </Tooltip>
    )
  },
  [SearchRequestDtoSearchComponentsEnum.Artifact]: {
    icon: <ArtifactIcon />,
    label: "Project Artifacts",
    extra: (
      <Tooltip title="Searches only artifacts that are associated to project(s)">
        <InfoOutlined style={{ fontSize: "1rem" }} />
      </Tooltip>
    )
  },
  [SearchRequestDtoSearchComponentsEnum.Dataapp]: {
    icon: <DataAppIcon />,
    label: "DataApps"
  },

  [SearchRequestDtoSearchComponentsEnum.Environment]: {
    icon: <EnvironmentsIcon />,
    label: "Environments"
  },
  [SearchRequestDtoSearchComponentsEnum.Connector]: {
    icon: <ConnectorsIcon />,
    label: "Connectors"
  },
  [SearchRequestDtoSearchComponentsEnum.PredictionScheduler]: {
    icon: <PredictRecipeIcon />,
    label: "Predictions"
  },

  [SearchRequestDtoSearchComponentsEnum.Scheduler]: {
    icon: <ClockIcon />,
    label: "Schedulers"
  },
  [SearchRequestDtoSearchComponentsEnum.VectorStore]: {
    icon: <VectorDBIcon />,
    label: "Vector DB"
  },
  [SearchRequestDtoSearchComponentsEnum.File]: {
    icon: <FileIcon />,
    label: "Text Inputs"
  }
};
