import React from "react";

interface IProps {
  width?: number;
  height?: number;
  viewBox?: string;
  fill?: string;
  color?: string;
}

const ManualPrediction: React.FC<IProps> = (props) => {
  const { width = 155, height = 155, viewBox = "0 0 155 155" } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_28238_1255)">
        <path d="M0 127.731V153.565H27.2685V127.731H0Z" fill="#D9D9D9" />
        <path d="M0 127.731V153.565H7.89352V127.731H0Z" fill="#BEBEBE" />
        <path d="M27.2686 118.403V153.565H54.5371V118.403H27.2686Z" fill="#D9D9D9" />
        <path d="M27.2686 118.403V153.565H35.1621V118.403H27.2686Z" fill="#BEBEBE" />
        <path d="M54.5371 109.358V153.565H81.8056V109.358H54.5371Z" fill="#D9D9D9" />
        <path d="M54.5371 109.358V153.565H62.4306V109.358H54.5371Z" fill="#BEBEBE" />
        <path d="M81.0879 80.3704V153.565H108.356V80.3704H81.0879Z" fill="#D9D9D9" />
        <path d="M81.0879 80.3704V153.565H88.9814V80.3704H81.0879Z" fill="#BEBEBE" />
        <path
          d="M16.8634 22.2453C19.8358 22.2453 22.2453 19.8358 22.2453 16.8634C22.2453 13.891 19.8358 11.4814 16.8634 11.4814C13.891 11.4814 11.4814 13.891 11.4814 16.8634C11.4814 19.8358 13.891 22.2453 16.8634 22.2453Z"
          fill="#D9D9D9"
        />
        <path
          d="M22.2454 11.1227L24.3981 8.96997"
          stroke="#C7C7C7"
          stroke-width="1.43519"
          stroke-linecap="round"
        />
        <path
          d="M8.96997 24.7569L10.764 22.9629"
          stroke="#C7C7C7"
          stroke-width="1.43519"
          stroke-linecap="round"
        />
        <path
          d="M8.61108 8.77661L10.5631 10.3973"
          stroke="#C7C7C7"
          stroke-width="1.43519"
          stroke-linecap="round"
        />
        <path
          d="M4.30566 16.5046H8.25242"
          stroke="#C7C7C7"
          stroke-width="1.43519"
          stroke-linecap="round"
        />
        <path
          d="M16.5046 29.0625V25.1157"
          stroke="#C7C7C7"
          stroke-width="1.43519"
          stroke-linecap="round"
        />
        <path
          d="M147.465 15.0694C151.627 15.0694 155 11.696 155 7.53472C155 3.37341 151.627 0 147.465 0C143.304 0 139.931 3.37341 139.931 7.53472C139.931 11.696 143.304 15.0694 147.465 15.0694Z"
          fill="#2B88FF"
        />
        <path
          d="M81.7905 116.968V116.851C81.7927 116.85 81.7948 116.85 81.7977 116.849V116.966C81.7948 116.966 81.7927 116.967 81.7905 116.968ZM83.7991 79.7964H83.6857V79.7261H83.7991V79.7964Z"
          fill="#BDBCBA"
        />
        <path d="M89.1445 79.7968H89.2601V79.7253H89.1445V79.7968Z" fill="#BDBCBA" />
        <path
          d="M81.7977 101.101C81.7948 101.101 81.7927 101.101 81.7905 101.101V82.7275C81.7927 82.7268 81.7948 82.7261 81.7977 82.7261V101.101Z"
          fill="#C1C0BE"
        />
        <path
          d="M74.1869 23.7173C72.9118 25.2577 71.5998 26.7678 70.2857 28.2751L69.2927 29.3992L68.4023 30.3926C68.0358 30.8254 67.7945 31.3675 67.7087 31.959L67.4098 34.8875C67.3091 35.8804 67.2129 36.8738 67.0964 37.8663C66.8768 39.8518 66.6491 41.8368 66.3824 43.82C66.289 44.5149 65.6498 45.0026 64.9544 44.9091C64.3411 44.827 63.8898 44.3189 63.8562 43.7233C63.741 41.7275 63.6648 39.7339 63.5972 37.7403C63.5564 36.7432 63.5364 35.7471 63.5114 34.7509L63.4438 31.7108C63.4625 30.3123 63.9243 28.9061 64.724 27.7254L65.7115 26.3976L66.6069 25.2079C67.8099 23.6297 69.0147 22.0548 70.2571 20.5102C71.1353 19.4193 72.7316 19.246 73.8231 20.1242C74.9146 21.0024 75.0875 22.5991 74.2092 23.6901L74.1869 23.7173Z"
          fill="#4870C0"
        />
        <path
          d="M63.5415 43.9527L61.8835 46.7674L65.7003 47.1547L66.3716 44.297C66.4514 43.9581 66.2337 43.6166 65.8912 43.558C65.2585 43.4496 64.2687 43.4047 63.5415 43.9527Z"
          fill="#E46554"
        />
        <path
          d="M61.8835 46.7673L62.0578 47.7631C62.1435 48.2552 62.5712 48.6136 63.0711 48.6126L64.314 48.609C64.6928 48.6081 65.0398 48.3986 65.2176 48.0642L65.7003 47.1548L61.8835 46.7673Z"
          fill="#E46554"
        />
        <path
          d="M63.2715 41.8425L66.9453 41.9682L66.7085 44.1632L63.6054 43.9233L63.2715 41.8425Z"
          fill="#4870C0"
        />
        <path
          d="M66.7085 44.1632L66.3878 44.1383L63.6054 43.9233L63.6031 43.9078L63.2715 41.8425L66.9453 41.9682L66.7085 44.1632Z"
          fill="#799BD7"
        />
        <path
          d="M66.9767 64.8841L66.3729 65.7914L65.9111 64.8542L67.0111 50.6117C67.1858 48.3477 66.4219 46.487 63.6054 46.4185C60.9709 46.355 59.3718 48.1545 59.1881 50.4149L58.0346 64.632L57.4036 65.5364L56.9731 64.602L58.1339 50.3885C58.3485 47.7608 59.5147 45.6524 63.658 45.7522C67.8086 45.8524 68.2713 48.0048 68.0694 50.6385L66.9767 64.8841Z"
          fill="#1D2A30"
        />
        <path
          d="M55.3019 55.2779L54.5735 55.2742C53.0565 55.2674 51.771 56.3901 51.5741 57.8943L50.2314 68.1581L54.9572 68.3264L55.3019 55.2779Z"
          fill="#1D2A30"
        />
        <path
          d="M52.6487 68.2445L50.2314 68.1583L51.5741 57.8945C51.7705 56.3948 53.0484 55.2744 54.5594 55.2744C54.5639 55.2744 54.5689 55.2744 54.5735 55.2744L55.1976 55.2776C53.9116 55.2993 52.871 56.3485 52.8515 57.6699L52.6832 65.8971C52.6819 65.9706 52.6841 66.0431 52.6896 66.1152L52.6487 68.2445Z"
          fill="#45555C"
        />
        <path
          d="M68.6448 65.0053L67.5775 64.9748L67.6814 50.7328C67.6977 48.4684 66.8041 46.6081 63.9826 46.5397C61.3435 46.4757 59.8702 48.2756 59.8447 50.5359L59.6855 64.7526L58.6218 64.7227L58.7887 50.5092C58.8196 47.8818 59.8384 45.7731 63.9885 45.8733C68.1463 45.9736 68.7591 48.1259 68.7415 50.7596L68.6448 65.0053Z"
          fill="#1D2A30"
        />
        <path
          d="M73.6528 68.8815L52.6487 68.2583L52.8514 57.6701C52.8714 56.3133 53.9678 55.2432 55.3018 55.2782L70.5945 55.6782C72.3217 55.7237 73.7167 57.1793 73.7095 58.9324L73.6528 68.8815Z"
          fill="#1D2A30"
        />
        <path
          d="M52.6896 66.1153C52.6842 66.0432 52.6819 65.9705 52.6832 65.8971L52.8516 57.6699L52.6896 66.1153Z"
          fill="#929C9E"
        />
        <path
          d="M70.113 68.7762L54.9574 68.3266C53.7539 68.2908 52.78 67.3196 52.6897 66.1153L52.8517 57.6699C52.8712 56.3345 53.9335 55.2771 55.2386 55.2771C55.2599 55.2771 55.2808 55.2771 55.3021 55.278L58.7318 55.3678L58.622 64.7227L59.1178 65.6576L59.6858 64.7526L59.7906 55.3955L67.6458 55.601L67.5777 64.9749L68.1048 65.9125L68.6451 65.0053L68.7086 55.6287L69.3232 55.645C69.3541 60.0309 69.9179 64.7077 70.113 68.7762Z"
          fill="#758288"
        />
        <path
          d="M68.7084 55.6286L67.6456 55.6009L67.6814 50.7328C67.6814 50.7105 67.6814 50.6888 67.6814 50.6665C67.6801 48.7645 67.0251 47.1588 65.1063 46.6816C64.7711 46.5986 64.3977 46.5496 63.9827 46.5396C63.9373 46.5387 63.892 46.5378 63.847 46.5378C63.2573 46.5378 62.728 46.6326 62.2622 46.8059C62.1424 46.8503 62.0272 46.8997 61.916 46.9542C60.5847 47.6065 59.8625 48.9592 59.8448 50.5359L59.7904 55.3955L58.7317 55.3678L58.7888 50.5092C58.8188 47.9417 59.7922 45.8696 63.7091 45.8696C63.8008 45.8696 63.8938 45.871 63.9886 45.8732C68.0897 45.9721 68.742 48.0673 68.7415 50.6516C68.7415 50.6874 68.7415 50.7233 68.7415 50.7595L68.7084 55.6286Z"
          fill="#5D6C72"
        />
        <path
          d="M68.1046 65.9126L67.5775 64.9749L67.6456 55.6011L68.7083 55.6287L68.6448 65.0053L68.1046 65.9126ZM59.1176 65.6576L58.6218 64.7227L58.7316 55.3679L59.7904 55.3955L59.6855 64.7527L59.1176 65.6576Z"
          fill="#A1A8AA"
        />
        <path
          d="M60.0464 59.2585L58.2959 59.2113L58.3037 58.5522L60.0536 58.599L60.0464 59.2585Z"
          fill="#1D2A30"
        />
        <path
          d="M59.999 63.5325L58.2444 63.4836L58.2526 62.8213L60.0062 62.8702L59.999 63.5325Z"
          fill="#1D2A30"
        />
        <path
          d="M69.0424 59.5011L67.2847 59.4539L67.2896 58.7935L69.0465 58.8406L69.0424 59.5011Z"
          fill="#1D2A30"
        />
        <path
          d="M69.0141 63.7841L67.2527 63.7347L67.2577 63.0715L69.0182 63.1205L69.0141 63.7841Z"
          fill="#1D2A30"
        />
        <path
          d="M65.6927 59.7873L61.9346 59.6852L61.9486 58.2754L65.7046 58.3756L65.6927 59.7873Z"
          fill="white"
        />
        <path
          d="M81.909 7.80064C82.0532 7.77294 82.2075 7.92357 82.2542 8.14222C82.3015 8.3608 82.2255 8.56588 82.0812 8.59351C81.9363 8.62121 81.7827 8.47059 81.7361 8.25194C81.6772 8.02604 81.7648 7.82784 81.909 7.80064Z"
          fill="#1D2A30"
        />
        <path
          d="M81.9348 7.98282C82.0826 7.91386 82.2182 7.75965 82.2771 7.60767C82.1515 7.72607 81.9771 7.79187 81.8042 7.78778C81.8702 7.82546 81.9312 7.90662 81.9348 7.98282Z"
          fill="#1D2A30"
        />
        <path
          d="M82.2247 10.203C82.6058 10.2189 82.9739 10.0651 83.2308 9.78389C82.6747 9.30124 82.1882 8.7451 81.7827 8.13135L82.2247 10.203Z"
          fill="#D33B32"
        />
        <path
          d="M80.6941 7.44243C80.9043 7.17893 81.2287 7.03241 81.5659 7.05009L81.609 7.05645C81.6829 7.05509 81.7417 6.99431 81.7403 6.92037C81.7382 6.84688 81.6779 6.78791 81.604 6.78926C81.1835 6.75479 80.7716 6.9267 80.5003 7.25012L80.468 7.3368C80.468 7.41071 80.5276 7.47056 80.6022 7.47056L80.6941 7.44243Z"
          fill="#1D2A30"
        />
        <path
          d="M79.3127 20.5519C75.5396 20.5923 74.9612 18.5379 74.9612 18.5379C76.0821 17.4374 76.138 15.4238 75.8998 13.6284C76.189 14.0339 76.5492 14.3891 77.0207 14.7035C77.9234 15.2973 79.8817 14.3434 79.8817 14.3434C79.886 14.3434 79.8817 14.6781 79.9061 15.1603C79.9678 16.337 80.2262 18.4013 81.2968 18.7207C81.2968 18.7207 83.4725 20.5061 79.3127 20.5519Z"
          fill="#E46554"
        />
        <path
          d="M79.9371 14.6494C79.8969 14.8231 79.8567 14.9964 79.8323 15.1805L79.7856 15.1964C78.9016 15.416 77.9249 15.2953 77.0222 14.702C76.5472 14.3876 76.1862 14.0348 75.8984 13.6287C75.841 13.1729 75.7578 12.7283 75.6746 12.3201L79.7842 14.5673L79.9371 14.6494Z"
          fill="#E46554"
        />
        <path
          d="M82.6409 11.5683C82.6137 12.9636 81.8509 14.1412 80.7831 14.7817L80.7774 14.7822L80.7616 14.7872C80.4752 14.9686 80.1617 15.0979 79.8323 15.1804L79.7856 15.1963C78.9016 15.4159 77.9249 15.2952 77.0222 14.7019C76.5471 14.3875 76.1862 14.0346 75.8984 13.6286C75.1586 12.6139 74.8508 11.2594 74.3212 9.35607C74.1583 8.79678 74.1253 8.20477 74.2293 7.63184C74.6003 5.58291 76.5629 4.22435 78.6066 4.59585C81.4749 5.10481 82.6976 9.01945 82.6409 11.5683Z"
          fill="#E46554"
        />
        <path
          d="M79.9492 15.1495C80.0174 15.1295 80.0856 15.1078 80.153 15.0837C80.0913 15.1073 80.026 15.1282 79.9492 15.1495Z"
          fill="#CECDCC"
        />
        <path
          d="M79.9062 15.1601C79.8818 14.6779 79.8861 14.3431 79.8818 14.3431C79.9062 13.9222 79.9371 13.5012 79.9371 13.5012C79.9371 13.5166 80.3784 14.8557 80.5758 14.8557C80.4042 14.9645 80.2887 15.0308 80.1531 15.0829C80.0856 15.107 80.0175 15.1287 79.9493 15.1487C79.9357 15.1523 79.9213 15.156 79.9062 15.1601Z"
          fill="#B55144"
        />
        <path
          d="M81.6497 6.42174C81.3425 6.90982 80.3752 7.3226 80.4075 7.20922C80.574 6.80413 80.5431 6.02664 80.0415 6.20854C80.18 6.51835 80.5962 8.84763 80.4075 9.13029C80.3049 9.2845 80.2066 11.1479 79.4136 12.7909C78.7807 14.1018 78.7807 14.5083 78.7807 14.5083L76.2046 15.0059C75.2401 14.8852 74.3424 13.6528 74.2628 13.5166C73.9262 12.9374 72.8972 12.1772 72.3619 11.7743C72.1301 11.6002 71.9492 11.3434 71.8517 11.069C71.6749 10.5746 72.0354 8.79273 72.1143 7.95494C72.2277 6.7497 72.8599 5.63472 74.6079 4.91893C75.6886 4.4762 76.5985 3.50502 77.7015 3.12081C78.8044 2.73615 80.1169 2.75384 81.0476 3.45829C81.5062 3.80486 82.4189 5.19789 81.6497 6.42174Z"
          fill="#1D2A30"
        />
        <path
          d="M80.3037 8.11786C81.0973 8.35108 81.5114 9.52047 81.5451 10.263C81.5846 11.0836 81.0363 11.6021 80.3202 11.0527C79.782 10.6295 79.3966 10.0412 79.2251 9.3776C79.0307 8.57375 79.51 7.88522 80.3037 8.11786Z"
          fill="#E46554"
        />
        <path
          d="M81.2574 10.8515C81.2574 11.0429 81.1031 11.1976 80.9115 11.1976C80.7199 11.1976 80.5649 11.0429 80.5649 10.8515C80.5649 10.66 80.7199 10.5049 80.9115 10.5049C81.1031 10.5049 81.2574 10.66 81.2574 10.8515Z"
          fill="#4870C0"
        />
        <path
          d="M80.9115 11.1976C80.7199 11.1976 80.5649 11.0429 80.5649 10.8515C80.5649 10.66 80.7199 10.5049 80.9115 10.5049C81.1031 10.5049 81.2574 10.66 81.2574 10.8515C81.2574 11.0429 81.1031 11.1976 80.9115 11.1976Z"
          fill="#799BD7"
        />
        <path
          d="M73.8774 19.8982C73.8774 19.8982 74.4637 18.0887 75.3485 17.5172C76.2326 16.9457 80.1155 17.5386 80.724 17.7767C81.3325 18.0148 81.946 19.4169 81.946 19.4169L73.8774 19.8982Z"
          fill="#4870C0"
        />
        <path
          d="M74.2256 19.0385C74.4839 18.4891 74.8729 17.8246 75.3486 17.517C75.6199 17.3419 76.1739 17.2761 76.8283 17.2761C78.3073 17.2761 80.3022 17.6114 80.7241 17.7765C81.0937 17.9212 81.4647 18.495 81.7 18.9236C81.5816 18.9064 81.446 18.891 81.281 18.8779C80.4485 18.8393 79.6161 18.8202 78.7837 18.8202C78.6675 18.8202 78.552 18.8207 78.4364 18.8212C77.2998 18.8275 76.1638 18.8697 75.03 18.9477C74.7588 18.9672 74.4904 18.9985 74.2256 19.0385Z"
          fill="#799BD7"
        />
        <path
          d="M82.7434 19.1255C82.1392 19.0266 81.9727 18.9349 81.281 18.8805C79.1978 18.7825 77.111 18.8052 75.03 18.9481C74.2708 19.0025 73.5396 19.1491 72.8141 19.3237C71.3239 19.6812 70.3197 21.0696 70.4299 22.5874C70.7392 26.8337 71.9946 29.3258 72.1797 32.1214C72.5486 37.6909 71.6601 41.596 71.5086 42.8961L74.3648 43.3297C79.4647 43.2091 82.8511 42.1127 85.6583 40.9183C85.6583 40.9183 84.922 34.1845 85.6339 31.7585C87.5771 25.1295 86.3156 24.0807 85.9324 22.1823C85.6123 20.6051 84.3436 19.3881 82.7434 19.1255Z"
          fill="#4870C0"
        />
        <path
          d="M71.5087 42.8952L71.5073 42.8947C71.617 41.9571 72.1102 39.6609 72.2387 36.3609C72.1117 39.6596 71.6184 41.9557 71.5087 42.8952ZM85.6584 40.917C85.6498 40.8376 85.3226 37.8092 85.324 35.1035C85.324 35.1066 85.324 35.1103 85.324 35.1139C85.324 37.8528 85.6584 40.917 85.6584 40.917ZM82.3015 19.0411C82.0999 18.998 81.9326 18.9585 81.7009 18.9254C81.7001 18.9249 81.7001 18.9245 81.7001 18.9241C81.9319 18.9577 82.0999 18.9976 82.3015 19.0411Z"
          fill="white"
        />
        <path
          d="M81.7008 18.9256C81.5817 18.9088 81.4468 18.8939 81.2811 18.8807C80.435 18.8407 79.5875 18.8208 78.7408 18.8208C78.6389 18.8208 78.5377 18.8212 78.4365 18.8217C78.5521 18.8212 78.6676 18.8208 78.7838 18.8208C79.6162 18.8208 80.4487 18.8399 81.2811 18.8784C81.4461 18.8916 81.5817 18.907 81.7001 18.9242L81.7008 18.9256Z"
          fill="#89A8DD"
        />
        <path
          d="M71.5685 42.9037L71.5086 42.8946C71.6183 41.9552 72.1116 39.659 72.2386 36.3603C72.2881 35.0893 72.2831 33.6695 72.1819 32.1209C71.9946 29.3262 70.7411 26.8359 70.4317 22.5855C70.3206 21.0691 71.3244 19.6787 72.8156 19.324C73.5411 19.1462 74.2716 19.0043 75.0322 18.9484C76.1653 18.8713 77.3005 18.8282 78.4365 18.821C78.5377 18.8205 78.6388 18.8201 78.7407 18.8201C79.5875 18.8201 80.435 18.84 81.281 18.88C81.4468 18.8931 81.5817 18.908 81.7008 18.9248C81.9326 18.958 82.0998 18.9975 82.3014 19.0405C82.3151 19.0437 82.3287 19.0464 82.3423 19.0496C81.5221 19.3499 80.8777 20.0652 80.7134 20.9866C80.6868 21.1376 80.6739 21.2877 80.6739 21.4356C80.6739 22.6413 81.5372 23.7095 82.7657 23.9287C83.2565 24.0157 83.7481 24.0892 84.2411 24.1591L86.4549 28.3373C86.2927 29.2808 86.0286 30.4017 85.6332 31.7558C85.4007 32.5445 85.3239 33.7879 85.3239 35.1029C85.3225 37.8087 85.6497 40.837 85.6583 40.9164C85.6569 40.9169 85.6547 40.9178 85.6547 40.9178L85.654 40.9169L74.1374 42.4809L71.6084 42.807C71.6084 42.807 71.5943 42.8402 71.5685 42.9037Z"
          fill="#537BC6"
        />
        <path
          d="M83.5642 18.9199C84.6069 19.0668 85.6395 19.275 86.6779 19.4501C87.7119 19.6497 88.7453 19.8548 89.7757 20.0761L89.4019 20.0471C89.8625 20.067 90.2673 20.1029 90.5227 20.1192C90.6454 20.1283 90.7323 20.1256 90.7 20.1446C90.6935 20.1551 90.6332 20.1741 90.5629 20.2194C90.4747 20.2667 90.3685 20.3433 90.2529 20.4608C90.0161 20.6989 89.9207 21.0178 89.9271 21.1126C89.925 21.2156 89.958 21.1916 89.963 21.1113C89.9809 20.9479 89.9458 20.6345 89.8783 20.3129L89.9257 20.4463C89.4026 18.9367 88.906 17.4166 88.451 15.8802C88.0097 14.3384 87.5856 12.7898 87.2842 11.1931C87.1687 10.5802 87.572 9.98915 88.1848 9.87347C88.6534 9.78549 89.1105 10.0028 89.3509 10.3829C90.2149 11.7592 90.9562 13.1835 91.6795 14.6151C92.3899 16.0512 93.058 17.5041 93.7002 18.9675L93.7469 19.1013C93.922 19.564 94.0942 20.0507 94.1975 20.7052C94.2442 21.0337 94.2779 21.4247 94.2025 21.9323C94.1329 22.429 93.861 23.1407 93.3242 23.6637C93.0623 23.9264 92.7788 24.111 92.509 24.2525C92.2514 24.3818 91.9974 24.4735 91.7907 24.5297C91.3587 24.649 91.0344 24.6834 90.7481 24.7134C90.1812 24.7669 89.7642 24.7692 89.4019 24.7909L89.3444 24.7942L89.0287 24.761C87.9803 24.6512 86.9348 24.5251 85.89 24.3927C84.848 24.2367 83.8025 24.1132 82.7656 23.9291C81.3863 23.6833 80.4678 22.366 80.714 20.987C80.9529 19.6398 82.2202 18.7325 83.5642 18.9199Z"
          fill="#4870C0"
        />
        <path
          d="M89.3447 24.7941L89.2579 24.785L89.1445 24.7732L89.029 24.7609C88.2023 24.6748 87.3778 24.5782 86.554 24.4761C86.5361 24.3808 86.516 24.2883 86.4959 24.1985L83.797 23.3212L84.2412 24.1595C83.7482 24.0896 83.2567 24.0162 82.7659 23.929C81.5373 23.71 80.6748 22.6417 80.6741 21.436C80.6741 21.2881 80.687 21.138 80.7143 20.9869C80.9324 19.7581 82.0059 18.8953 83.2129 18.8953C83.3292 18.8953 83.4461 18.9035 83.5645 18.9198C84.6072 19.0668 85.6398 19.275 86.6782 19.45C87.6914 19.6456 88.7039 19.8465 89.7143 20.0634C89.7416 20.0647 89.7681 20.0665 89.7954 20.0679C89.6131 19.5354 89.433 19.0015 89.2579 18.4657C89.2199 18.3496 89.1826 18.2335 89.1445 18.1173C88.9049 17.3748 88.6731 16.6295 88.4513 15.8802C88.216 15.06 87.9863 14.2381 87.7775 13.408L90.5446 12.435C90.9371 13.1562 91.311 13.8843 91.6798 14.615C92.3902 16.0512 93.0583 17.5041 93.7005 18.9675L93.7472 19.1012C93.9223 19.5639 94.0945 20.0507 94.1978 20.7052C94.2251 20.8953 94.2473 21.1057 94.2466 21.348C94.2466 21.5245 94.2344 21.7181 94.2028 21.9323C94.1332 22.4289 93.8613 23.1406 93.3245 23.6637C93.0626 23.9263 92.7791 24.111 92.5093 24.2525C92.2517 24.3818 91.9977 24.4734 91.791 24.5296C91.359 24.6489 91.0347 24.6834 90.7484 24.7134C90.1815 24.7668 89.7645 24.7691 89.4022 24.7909L89.3447 24.7941ZM89.9274 21.1212C89.9274 21.1629 89.9339 21.1811 89.9411 21.1811C89.9497 21.1811 89.9604 21.1543 89.9633 21.1112C89.9669 21.0831 89.9683 21.0504 89.9683 21.0141C89.9683 20.9869 89.9669 20.957 89.9655 20.9257C89.9389 21.0019 89.9267 21.0654 89.9267 21.103C89.9267 21.1062 89.9274 21.1094 89.9274 21.1126C89.9274 21.1153 89.9274 21.1184 89.9274 21.1212ZM87.2975 11.261C87.2931 11.2383 87.2888 11.2157 87.2845 11.193C87.2766 11.1517 87.2716 11.1109 87.268 11.0701L87.3003 11.193C87.3068 11.2139 87.3147 11.2338 87.3233 11.2533L87.2975 11.261Z"
          fill="#537BC6"
        />
        <path
          d="M102.322 9.15422L115.922 8.73737L115.713 4.47705L102.141 5.37066L102.322 9.15422Z"
          fill="#4870C0"
        />
        <path
          d="M92.0436 9.31828L103.041 8.964L102.873 5.4707L91.8958 6.17879L92.0436 9.31828Z"
          fill="#4870C0"
        />
        <path
          d="M101.588 9.05331L102.309 9.12995L102.131 5.39722L101.42 5.54282L101.588 9.05331Z"
          fill="#1D2A30"
        />
        <path
          d="M102.309 9.12995L101.588 9.05331L101.587 9.01069L101.422 5.5646L101.42 5.54282L102.13 5.39722L102.196 6.77075L102.309 9.12995Z"
          fill="#182124"
        />
        <path
          d="M102.311 9.15463L103.048 9.13196L102.866 5.323L102.129 5.37154L102.311 9.15463Z"
          fill="#1D2A30"
        />
        <path
          d="M90.3355 8.80182L91.427 9.30808L91.2827 6.24756L90.2444 6.85676L90.3355 8.80182Z"
          fill="#1D2A30"
        />
        <path
          d="M84.7469 8.76389L87.9811 8.59562L87.9137 7.16309L84.6816 7.35633L84.7469 8.76389Z"
          fill="#1D2A30"
        />
        <path
          d="M84.7469 8.76403L84.6816 7.35647L86.97 7.21948L86.6873 8.51093C86.6758 8.56124 86.6701 8.61255 86.6687 8.66378L84.7469 8.76403Z"
          fill="#182124"
        />
        <path
          d="M87.5391 8.88455L91.1372 8.7462L91.0424 6.74121L87.4487 6.95396L87.5391 8.88455Z"
          fill="#1D2A30"
        />
        <path
          d="M91.4271 9.30808L90.3357 8.80182L90.3342 8.77692L91.1372 8.74607L91.0425 6.74108L90.3744 6.78056L91.2829 6.24756L91.4271 9.30808Z"
          fill="#182124"
        />
        <path
          d="M90.3341 8.77706L90.2444 6.85689L90.3743 6.78068L91.0423 6.74121L91.1371 8.7462L90.3341 8.77706Z"
          fill="#182124"
        />
        <path
          d="M91.4464 9.33694L92.0435 9.31793L91.8949 6.17847L91.2993 6.21702L91.4464 9.33694Z"
          fill="#1D2A30"
        />
        <path
          d="M115.459 8.752L117.592 8.67579L117.383 4.39868L115.251 4.50755L115.459 8.752Z"
          fill="#1D2A30"
        />
        <path
          d="M116.405 9.05439L119.127 8.97818L119.195 8.78995C119.776 7.17645 119.661 5.39373 118.876 3.86914L116.16 4.05648L116.22 4.19891C116.864 5.74255 116.93 7.46628 116.405 9.05439Z"
          fill="#4870C0"
        />
        <path
          d="M113.511 5.41598C113.484 5.26311 113.494 5.10571 113.506 4.95012L114.607 4.88208L114.63 5.35157L113.511 5.41598Z"
          fill="#8DAEE0"
        />
        <path
          d="M103.299 6.00555L103.276 5.58143L112.84 5.00171C112.828 5.15685 112.821 5.31243 112.845 5.4653L103.299 6.00555Z"
          fill="#8DAEE0"
        />
        <path
          d="M92.1483 6.80732L92.1282 6.42629L101.098 5.85791L101.117 6.27433L92.1483 6.80732Z"
          fill="#8DAEE0"
        />
        <path
          d="M116.427 4.67924L116.401 4.2034L118.531 4.05371L118.554 4.53863L116.427 4.67924Z"
          fill="#8DAEE0"
        />
        <path
          d="M116.653 8.9112L116.627 8.43536L118.853 8.34961C118.838 8.512 118.83 8.67532 118.856 8.83542L116.653 8.9112Z"
          fill="#2E53AC"
        />
        <path
          d="M103.235 8.97245L103.211 8.54878L114.907 8.08472C114.895 8.24173 114.887 8.40003 114.912 8.5551L103.235 8.97245Z"
          fill="#2E53AC"
        />
        <path
          d="M92.3389 9.21588L92.3188 8.83441L101.01 8.49463C101 8.63341 100.995 8.77313 101.015 8.91062L92.3389 9.21588Z"
          fill="#2E53AC"
        />
        <path
          d="M84.0201 7.41035L84.0481 8.78074C84.0531 9.01345 84.2641 9.19852 84.5203 9.19443L85.6325 9.17542L85.5859 6.93091L84.4743 6.97173C84.2182 6.98125 84.0151 7.17764 84.0201 7.41035Z"
          fill="#4870C0"
        />
        <path
          d="M89.7672 11.2107L90.1899 8.6578C90.2071 8.55167 90.2107 8.44418 90.2014 8.33661L90.0801 6.98397C90.0607 6.80887 89.9373 6.63695 89.7471 6.57844L87.7077 6.55439C87.4386 6.46958 87.143 6.59885 87.0533 6.83836L86.6873 8.51084C86.6593 8.63649 86.6622 8.76573 86.6952 8.89001L87.3001 11.1931C87.359 11.3849 87.5362 11.5351 87.7558 11.5773L89.0884 11.5895C89.402 11.6494 89.7034 11.4825 89.7672 11.2107Z"
          fill="#E76454"
        />
        <path
          d="M90.9662 12.286L87.4988 13.5057L86.9211 11.3756L89.8984 10.4692L90.9662 12.286Z"
          fill="#4870C0"
        />
        <path
          d="M87.4988 13.5057L86.9211 11.3756L89.8984 10.4692L90.9662 12.286L90.5443 12.4347L87.7772 13.4078L87.4988 13.5057Z"
          fill="#799BD7"
        />
        <path
          d="M86.455 28.3378L84.2412 24.1597C84.7909 24.2377 85.3413 24.3107 85.8902 24.3928C86.1113 24.4214 86.333 24.4491 86.554 24.4763C86.6351 24.8968 86.6889 25.3781 86.6889 25.9618C86.6896 26.6092 86.6236 27.3821 86.455 28.3378Z"
          fill="#4067BA"
        />
        <path
          d="M86.5539 24.4757C86.3328 24.4485 86.1111 24.4208 85.8901 24.3922C85.3411 24.3101 84.7907 24.2371 84.2411 24.1591L83.7969 23.3208L86.4957 24.1981C86.5158 24.2879 86.5359 24.3805 86.5539 24.4757Z"
          fill="#4067BA"
        />
        <path
          d="M100.156 69.9831L99.6069 76.7961L102.708 76.8557L103.559 69.8362L100.156 69.9831Z"
          fill="#E76454"
        />
        <path
          d="M103.199 75.9243L99.6612 75.9961C99.5278 75.9989 99.4129 76.1001 99.385 76.2401L98.7757 79.3429C98.7205 79.6178 98.8819 79.8876 99.1374 79.945L99.2371 79.9557C100.512 79.9062 101.645 79.8086 103.233 79.7785C105.107 79.7412 104.634 79.9916 106.837 79.9809C108.164 79.973 108.391 78.3483 107.827 78.2802C105.248 77.9838 104.973 77.1543 103.97 76.1848C103.752 75.9989 103.477 75.9064 103.199 75.9243Z"
          fill="#1D2A30"
        />
        <path
          d="M66.2903 99.1821L65.0361 105.44L68.3706 106.459L69.9769 100.067L66.2903 99.1821Z"
          fill="#E76454"
        />
        <path
          d="M84.1312 41.1914C84.1312 41.1914 79.385 68.8907 76.8067 77.0423C74.9711 82.8469 70.0479 101.837 70.0479 101.837L65.5821 100.847C65.5821 100.847 65.0827 87.9203 67.5667 81.2481C68.414 78.9719 69.5194 76.9189 69.8193 75.5074C71.098 69.4781 68.4621 58.5406 69.6274 50.3829C70.2593 45.9611 71.6084 42.8076 71.6084 42.8076L84.1312 41.1914Z"
          fill="#1D2A30"
        />
        <path
          d="M76.8067 77.0421C74.9711 82.8467 70.0479 101.837 70.0479 101.837L65.5821 100.847C65.5821 100.847 65.0827 87.9201 67.5667 81.2479C68.414 78.9717 69.5194 76.9187 69.8193 75.5072C71.098 69.4779 68.4621 58.5404 69.6274 50.3827C70.2593 45.9609 71.6084 42.8074 71.6084 42.8074"
          fill="#1D2A30"
        />
        <path
          d="M80.701 59.1353L78.6982 51.2715C80.3372 52.0254 81.4624 52.1588 82.0551 52.1805L80.701 59.1353Z"
          fill="#182124"
        />
        <path
          d="M103.776 73.251L99.4123 73.391C99.4123 73.391 99.7338 67.6803 99.7338 59.3524C99.7338 58.0683 99.9333 56.8027 100.215 55.6686C100.837 53.1243 101.844 51.2277 101.844 51.2277C101.844 51.2277 101.376 51.255 100.578 51.2918C98.1099 51.4183 82.8137 52.2085 82.5138 52.1858C82.4851 52.1831 82.4363 52.1836 82.3681 52.184C81.9103 52.1872 80.5755 52.1908 78.4055 51.1325C73.7749 48.8753 73.7283 42.5366 73.7283 42.5366L85.6539 40.9172C85.6539 40.9263 85.8491 40.9539 88.7209 41.0033C97.4253 41.1531 108.122 41.4901 110 44.4789C112.641 48.6826 103.776 73.251 103.776 73.251Z"
          fill="#1D2A30"
        />
        <path
          d="M68.5374 104.838L64.8782 104.888C64.7403 104.89 64.6273 104.991 64.6065 105.133L64.1511 108.26C64.1089 108.538 64.2917 108.811 64.5593 108.871L64.6632 108.882C65.9809 108.841 67.149 108.75 68.7915 108.73C70.7293 108.704 70.2539 108.954 72.535 108.956C73.9085 108.957 74.052 107.318 73.4643 107.246C70.777 106.931 70.4444 106.092 69.3508 105.106C69.115 104.917 68.8255 104.822 68.5374 104.838Z"
          fill="#1D2A30"
        />
        <path
          d="M100.215 55.6685C100.837 53.1241 101.844 51.2276 101.844 51.2276C101.844 51.2276 101.376 51.2548 100.578 51.2915C100.542 51.2774 100.506 51.2684 100.46 51.2684L103.641 51.0007L100.215 55.6685Z"
          fill="#182124"
        />
        <path d="M145.06 4.02734H146.336V7.77641" stroke="white" stroke-width="0.382716" />
        <path d="M150.588 4.02734H149.312V7.77641" stroke="white" stroke-width="0.382716" />
        <path d="M148.036 2.85571V7.7764" stroke="white" stroke-width="0.382716" />
        <path
          d="M148.037 3.0901C148.271 3.0901 148.462 2.88028 148.462 2.62146C148.462 2.36265 148.271 2.15283 148.037 2.15283C147.802 2.15283 147.611 2.36265 147.611 2.62146C147.611 2.88028 147.802 3.0901 148.037 3.0901Z"
          fill="white"
        />
        <path
          d="M150.588 4.49586C150.823 4.49586 151.013 4.28604 151.013 4.02723C151.013 3.76841 150.823 3.55859 150.588 3.55859C150.353 3.55859 150.163 3.76841 150.163 4.02723C150.163 4.28604 150.353 4.49586 150.588 4.49586Z"
          fill="white"
        />
        <path
          d="M145.06 4.49586C145.295 4.49586 145.485 4.28604 145.485 4.02723C145.485 3.76841 145.295 3.55859 145.06 3.55859C144.825 3.55859 144.635 3.76841 144.635 4.02723C144.635 4.28604 144.825 4.49586 145.06 4.49586Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M142.083 7.30762V8.04918C142.083 8.21932 142.188 8.36772 142.338 8.40862L143.655 8.76555C143.735 9.04469 143.839 9.3158 143.965 9.57607L143.378 10.6549C143.298 10.8008 143.319 10.9876 143.428 11.1077L144.38 12.1561C144.488 12.2759 144.658 12.2988 144.791 12.2119C144.791 12.2119 145.449 11.7767 145.772 11.5639C146.005 11.7011 146.25 11.8144 146.501 11.9024L146.825 13.3533C146.862 13.5185 146.997 13.6343 147.151 13.6343H148.497C148.651 13.6343 148.786 13.5185 148.823 13.3534L149.147 11.9024C149.406 11.8119 149.655 11.6949 149.893 11.553C150.224 11.772 150.89 12.2119 150.89 12.2119C151.022 12.2991 151.192 12.2767 151.3 12.1561L152.252 11.1077C152.361 10.9876 152.381 10.8008 152.302 10.6549L151.698 9.54457C151.817 9.29513 151.916 9.03414 151.992 8.76555L153.31 8.40862C153.46 8.36736 153.565 8.21932 153.565 8.04918V7.30762H150.178C150.178 7.30755 150.178 7.30769 150.178 7.30762C150.178 8.73828 149.122 9.90265 147.824 9.90265C146.526 9.90265 145.47 8.73842 145.47 7.30776C145.47 7.30769 145.47 7.30783 145.47 7.30776L142.083 7.30762Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M145.923 7.30737C146.028 8.4444 146.839 9.32883 147.824 9.32883C148.809 9.32883 149.62 8.4444 149.726 7.30737H145.923Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_28238_1255">
          <rect width="155" height="153.565" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ManualPrediction;
