import React, { useEffect, useState, useMemo } from "react";

import { toLower } from "lodash";

import {
  Grid,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  makeStyles
} from "@material-ui/core";

import { validateNameField } from "utils/helpers/form.helpers";

import { DatasetKeys, DatasetHelperText } from "../../utils/Dataset.constants";

import useStoreSelectors from "../../hooks/useStoreSelectors";

import useHelpers from "../../hooks/useHelpers";
import useAddFileQueryParam from "../../hooks/useAddFileQueryParam";

import { DatasetControlPropsType } from "../../types/Props.type";

const useStyles = makeStyles(() => ({
  root: {
    position: "relative"
  },
  errorMessageContainer: {
    marginRight: 0,
    marginLeft: 0
  }
}));

const DatasetControl = (props: DatasetControlPropsType) => {
  const { index, datasetName, updateDatasetFormControlRef, updateDatasetFieldRef } = props || {};

  const classes: $TSFixMe = useStyles();

  const isAddFile = useAddFileQueryParam();

  // Stores - STARTS >>
  const {
    datasetDefaultDatasetStore,

    datasetExistingDatasetsStore,
    datasetDatasetsStore
  } = useStoreSelectors();
  // << ENDS - Stores

  const { isCriterionAppend, isUploadControlProcessing, updateDatasetsSession } = useHelpers();

  // States - STARTS >>
  const [value, setValue] = useState<$TSFixMe>("");
  const [error, setError] = useState<$TSFixMe>("");

  const [datasetFormControlRef, setDatasetFormControlRef] = useState<$TSFixMe>();
  const [datasetFieldRef, setDatasetFieldRef] = useState<$TSFixMe>();
  // << ENDS - States

  useEffect(() => {
    if (!isCriterionAppend) {
      setValue(() => datasetName);
      validateDatasetName(datasetName);
    }
  }, [isCriterionAppend, datasetName]);

  useEffect(() => {
    if (isCriterionAppend) {
      const thisValue = datasetDefaultDatasetStore?.name || DatasetHelperText.Unknown;

      setValue(() => thisValue);
    }
  }, [isCriterionAppend, datasetDefaultDatasetStore?.id]);

  const datasetExistingDatasetsNames = useMemo(
    () =>
      (datasetExistingDatasetsStore || [])?.map((eachDataset: $TSFixMe) => eachDataset?.name) || [],
    [datasetExistingDatasetsStore]
  );

  const validateDatasetName = (value: $TSFixMe) => {
    const { isValid, error: errorMessage } = validateNameField({
      fieldName: value,
      fieldNameLabel: !!isAddFile
        ? toLower(DatasetHelperText.TextInputName)
        : toLower(DatasetHelperText.DatasetName),
      maxFieldLength: 64
    });

    setError(() => (isValid ? "" : errorMessage));

    const fields: $TSFixMe = {};
    fields[DatasetKeys.IsValid] = isValid;

    if (isValid) {
      const isExistingDatasetName = datasetExistingDatasetsNames.includes(value);
      fields[DatasetKeys.IsExisting] = isExistingDatasetName;

      if (isExistingDatasetName) {
        setError(() =>
          !!isAddFile
            ? DatasetHelperText.TextInputNameErrorMessage
            : DatasetHelperText.DatasetNameErrorMessage
        );
        fields[DatasetKeys.IsValid] = false;
      }
    }

    updateDatasetsSession({ index, fields });
  };

  const onDatasetNameChange = (event: $TSFixMe) => {
    const thisValue = event?.target?.value;

    setValue(thisValue);
    validateDatasetName(thisValue);
  };

  const save = () => {
    if (datasetDatasetsStore[index]?.isValid) {
      // Update datasets in session >>
      const fields: $TSFixMe = {};
      fields[DatasetKeys.Name] = value;
      updateDatasetsSession({ index, fields });
      // << Update datasets in session
    }
  };

  // Update datasets refs - STARTS >>
  useEffect(() => {
    updateDatasetFormControlRef(datasetFormControlRef);
  }, [datasetFormControlRef, datasetDatasetsStore[index]]);

  useEffect(() => {
    updateDatasetFieldRef(datasetFieldRef);
  }, [datasetFieldRef, datasetDatasetsStore[index]]);
  // << ENDS - Update datasets refs

  const isDatasetDisabled = useMemo(() => {
    if (isUploadControlProcessing) {
      return true;
    }

    return !!datasetDatasetsStore[index]?.[DatasetKeys.Id];
  }, [isUploadControlProcessing, datasetDatasetsStore]);

  return (
    <>
      <Grid container alignItems="center" className={classes.root}>
        {isCriterionAppend ? (
          <FormControl
            ref={setDatasetFormControlRef}
            fullWidth
            variant="outlined"
            size="small"
            color="primary">
            <InputLabel id={`datasetLabel_${index}`} htmlFor={`dataset_${index}`}>
              {!!isAddFile ? DatasetHelperText.TextInputName : DatasetHelperText.DatasetName}
            </InputLabel>
            <OutlinedInput
              id={`dataset_${index}`}
              type="text"
              ref={setDatasetFieldRef}
              value={value}
              label={!!isAddFile ? DatasetHelperText.TextInputName : DatasetHelperText.DatasetName}
              readOnly={true}
              labelWidth={90}
            />
          </FormControl>
        ) : (
          <FormControl
            ref={setDatasetFormControlRef}
            fullWidth
            variant="outlined"
            size="small"
            color="primary"
            error={!datasetDatasetsStore[index]?.isValid}>
            <InputLabel id={`datasetLabel_${index}`} htmlFor={`dataset_${index}`}>
              Edit {!!isAddFile ? DatasetHelperText.TextInputName : DatasetHelperText.DatasetName}
            </InputLabel>
            <OutlinedInput
              id={`dataset_${index}`}
              type="text"
              ref={setDatasetFieldRef}
              value={value}
              label={`Edit ${!!isAddFile ? DatasetHelperText.TextInputName : DatasetHelperText.DatasetName}`}
              onChange={onDatasetNameChange}
              onBlur={save}
              readOnly={isDatasetDisabled}
              labelWidth={90}
            />
            {!datasetDatasetsStore[index]?.isValid && (
              <FormHelperText className={classes.errorMessageContainer}>{error}</FormHelperText>
            )}
          </FormControl>
        )}
      </Grid>
    </>
  );
};

export default DatasetControl;
