import React from "react";
import { Navigate, useLocation } from "react-router-dom";

import AppLoadingScreen from "src/components/Screens/AppLoadingScreen";
import useQuery from "src/hooks/useQuery";
import { RequestState } from "src/types/RequestState";
import { loginPath } from "../routes";
import { fetchRedirectUrlPostSignIn } from "utils/helpers/url.helpers";
import { useAuthAcceptJoinTenantRequest } from "src/hooks/useAuthAcceptJoinTenantRequest";
import { useAuthAwaitStreamlit } from "src/hooks/useAuthAwaitStreamlit";

const RedirectRoute = ({ token }: any) => {
  const location = useLocation();
  const queryParameters = useQuery();
  const invitationDetails = queryParameters.get("invitation_details");

  const { hasInviteParams, data, requestState } = useAuthAcceptJoinTenantRequest({
    invitationDetails
  });
  const { shouldFetchStreamlitToken, requestState: streamlitRequestState } =
    useAuthAwaitStreamlit();

  /* If URL has includeStreamlit Auth Params, fetch streamlitToken and redirect to 
  streamlit app. If URL has invitation_details parameters, accept new tenant invite */
  if (
    (shouldFetchStreamlitToken &&
      (streamlitRequestState === RequestState.Idle ||
        streamlitRequestState === RequestState.Loading)) ||
    (hasInviteParams &&
      (requestState === RequestState.Idle || requestState === RequestState.Loading))
  ) {
    return <AppLoadingScreen requestPending />;
  }

  /* If any request fails, Redirect back to sign in page while retaining URL params 
  to allow join tenant or streamlit redirect requests post sign in  */
  if (requestState === RequestState.Failure || streamlitRequestState === RequestState.Failure) {
    return <Navigate to={{ pathname: loginPath, search: location.search }} replace />;
  }

  if (!hasInviteParams) {
    const { pathname, search } = fetchRedirectUrlPostSignIn(location, token);
    // If an unauthenticated request originates from a certain route, Redirect to that route saved in the state variable
    const shouldRedirectToOriginalRoute = location?.state?.from?.pathname?.includes("/apps/");
    const updatePathName = shouldRedirectToOriginalRoute
      ? location?.state?.from?.pathname
      : pathname;
    const searchParams = shouldRedirectToOriginalRoute ? location?.state?.from?.search : search;
    return <Navigate to={{ pathname: updatePathName, search: searchParams }} replace />;
  }

  /* If the URL has accept new tenant invitation request(URL params named `invitation_details`), 
  Join Tenant, Get updated Auth Creds(Token) and Redirect to that specific tenant dashboard */
  const { token: newToken } = data;
  const { pathname, search } = fetchRedirectUrlPostSignIn(location, newToken);
  return <Navigate to={{ pathname, search }} replace />;
};

export default RedirectRoute;
