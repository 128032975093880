import { Grid, Box, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import { CodeIcon } from "icons/NewUX/CodeIcon";

const useStyles = makeStyles((theme) => ({
  dashedBox: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    alignItems: "center",
    justifyContent: "center",
    border: "2px dashed #ccc",
    borderRadius: "15px",
    padding: theme.spacing(4),
    textAlign: "center",
    backgroundColor: "#FBFBFB",
    width: "100%",
    height: "210px",
    color: "#414141"
  },
  emptyContainer: {
    padding: "16px",
    height: "100%",
    width: "100%"
  }
}));

export const CodeSelectionHint = () => {
  const classes = useStyles();
  return (
    <Grid container alignItems="center" className={classes.emptyContainer}>
      <Box className={classes.dashedBox}>
        <CodeIcon />
        <Typography variant="body1">Select code and press Command+I to copy here</Typography>
      </Box>
    </Grid>
  );
};
