import React from "react";

// Packages
import { includes, isEmpty } from "lodash";

// MUI
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import { useTheme } from "@material-ui/core/styles";

// Icons
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";

// Components
import { Modal } from "src/components/custom";
import DeletingNodesList from "./NodesList";

// Types
import { NodeData } from "src/types";

// Constants
import { ConfirmDeselectNodesPromptDetails, DeleteNodesHelperText } from "./DeleteNodes.constants";

type Props = {
  selectedNodes: NodeData[];
  onDeselectNodes: () => void;
  resetConfirmDeselectNodes: () => void;
};

const DeselectNodesConfirmModal = (props: Props) => {
  const { selectedNodes, onDeselectNodes, resetConfirmDeselectNodes } = props || {};

  const theme = useTheme();

  return (
    <Modal
      open
      title={ConfirmDeselectNodesPromptDetails.title}
      onClose={(_, reason: string) => {
        if (!includes(["escapeKeyDown", "backdropClick"], reason)) {
          resetConfirmDeselectNodes();
        }
      }}
      onSubmit={onDeselectNodes}
      cancelLabel={ConfirmDeselectNodesPromptDetails.cancelLabel}
      submitLabel={ConfirmDeselectNodesPromptDetails.submitLabel}
      hideCloseIcon>
      {isEmpty(selectedNodes) ? (
        <Alert severity="info" style={{ justifyContent: "center" }}>
          {DeleteNodesHelperText.NoDataFound}
        </Alert>
      ) : (
        <Grid container direction="column" style={{ rowGap: theme.spacing(1) }}>
          <Grid item>
            <Grid
              container
              wrap="nowrap"
              alignItems="flex-start"
              style={{ columnGap: theme.spacing(1) }}>
              <Grid item>
                <ReportProblemOutlinedIcon
                  fontSize="small"
                  style={{ marginBottom: theme.spacing(0.75) }}
                  data-testid="deleteNodesConfirmModalWarnIcon"
                />
              </Grid>
              <Grid item>
                <Typography
                  style={{ overflowWrap: "anywhere" }}
                  component="div"
                  variant="body2"
                  data-testid="deleteNodesConfirmModalMessageLine1">
                  {ConfirmDeselectNodesPromptDetails.messageLine1}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <DeletingNodesList selectedNodes={selectedNodes} />
          </Grid>

          <Grid item>
            <Typography
              style={{ overflowWrap: "anywhere" }}
              component="div"
              variant="body2"
              data-testid="deleteNodesConfirmModalMessageLine2">
              {ConfirmDeselectNodesPromptDetails.messageLine2}
            </Typography>
          </Grid>
        </Grid>
      )}
    </Modal>
  );
};

export default DeselectNodesConfirmModal;
