import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";

import useInvalidateCache from "./useInvalidateCache";
import { PS_SUCCESS_SAVE_MESSAGE } from "../pages/private/ProjectsModule/pages/PredictionService/utils/PredictionService.constants";
import { QUERY_KEY_DOWNLOAD_SIGNED_URL } from "./useDownloadSignedUrl";
import { QUERY_KEY_PAGINATED_MODELS } from "./api/models/useGetPaginatedModels";
import { QUERY_KEY_PREDICTION_SERVICE_BY_MODEL } from "./usePredictionServiceByModel";
import { handleResponse } from "services/Apis/Apis.service";
import {
  postAPIWithRethrow,
  getAPIWithRethrow,
  putAPIWithRethrow
} from "services/Apis/Apis.service";

interface IVariables {
  name?: string;
  displayName?: string;
  description?: string;
  modelName?: string;
  envId?: string;
  file?: File;
  shouldDispatchEvent?: boolean;
  logCalls?: boolean;
  timeoutInMins?: number;
  concurrency?: number;
  isAutoSaving?: boolean;
}

const useAddPredictionService = (): UseMutationResult<unknown, unknown, IVariables, unknown> => {
  const queryClient = useQueryClient();
  const invalidateCache = useInvalidateCache();

  return useMutation({
    mutationFn: async ({
      name,
      displayName,
      description,
      modelName,
      envId,
      file,
      logCalls,
      timeoutInMins,
      concurrency,
      shouldDispatchEvent
    }) => {
      const results = await postAPIWithRethrow(
        "/v2/prediction-services",
        {
          name,
          displayName,
          description,
          modelName,
          envId,
          logCalls,
          timeoutInMins,
          concurrency,
          dataSourceIds: []
        },
        undefined,
        shouldDispatchEvent
      );

      const { id } = results;

      const { signedUrl, headers } = await getAPIWithRethrow(
        `/v2/prediction-services/${id}/signed-url?path=${name}.py`
      );

      await putAPIWithRethrow(signedUrl, file, {
        headers,
        signedUrl: true
      });

      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_PAGINATED_MODELS],
        refetchType: "all"
      });

      return results;
    },
    onSuccess: async (__, { shouldDispatchEvent, name }) => {
      if (shouldDispatchEvent) {
        handleResponse({ successMessage: PS_SUCCESS_SAVE_MESSAGE });
      }
      if (name) {
        invalidateCache.mutate({ name, showMessage: false });
      }
      await queryClient.invalidateQueries([QUERY_KEY_PREDICTION_SERVICE_BY_MODEL]);
      await queryClient.invalidateQueries([QUERY_KEY_DOWNLOAD_SIGNED_URL, name]);
    }
  });
};

export default useAddPredictionService;
