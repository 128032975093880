import React, { useCallback, useMemo, useState } from "react";

// Packages
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { includes, join, map, reduce, size, uniq } from "lodash";

// MUI
import Grid from "@material-ui/core/Grid";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

// Utils
import { ToastTypes, toastWrapper } from "services/ToastClient/toastWrapper";
import { getDateSegments, getTimeFormatsByFieldNames } from "./DataFormatModal.helpers";

// Hooks
import { useUpdateDataset } from "src/hooks/api/entities/useUpdateEntities";

// Components
import { Modal, PreviewPlaceholder } from "src/components/custom";
import { OverflowTooltip } from "src/components";
import ConfirmClose from "./ConfirmClose";

// Constants
import {
  DateFormatFormFields,
  DateFormatFormFieldsNameMapping,
  DateFormatModalHelperText,
  segmentOptions
} from "./DateFormatModal.constants";

// Styles
import useStyles from "./DateFormatModal.styles";

type DateFormatFormFieldType = {
  fieldName: string;
  segments: string[];
};

type DateFormatFormFieldsType = {
  [DateFormatFormFields.Fields]: DateFormatFormFieldType[];
};

interface IProps {
  onClose: () => void;
  isLoading?: boolean;
  data?: any;
  datasetData?: any;
  fetchDatasetResources: () => void;
}

const DateFormatModal: React.FC<IProps> = (props) => {
  const { onClose, isLoading, data, datasetData, fetchDatasetResources } = props;

  const classes = useStyles();

  const [showConfirmCloseModal, setShowConfirmCloseModal] = useState(false);

  // Query hooks - STARTS >>
  // Mutations
  const {
    isLoading: isUpdatingDataset,
    mutateAsync: updateDatasetMutation,
    reset: resetUpdateDatasetMutation
  } = useUpdateDataset({ datasetId: datasetData?.id });
  // << ENDS - Query hooks

  // Form - STARTS >>
  const {
    control,
    watch,
    formState: { isDirty, isValid, errors }
  } = useForm<DateFormatFormFieldsType>({
    mode: "onChange", // Validate onChange
    reValidateMode: "onChange", // Re-validate onChange
    defaultValues: {
      fields: map(data, (item) => ({
        fieldName: item?.name,
        segments: getDateSegments(item?.format)
      }))
    }
  });

  const { fields } = useFieldArray({
    control,
    name: DateFormatFormFields.Fields
  });

  // Watch the segments
  const watchFields = watch(DateFormatFormFields.Fields);
  // << ENDS - Form

  // Confirm close modal - STARTS >>
  const setShowConfirmCloseModalHandler = () => setShowConfirmCloseModal(() => true);
  const resetShowConfirmCloseModalHandler = () => setShowConfirmCloseModal(() => false);

  const isAttemptedClose = useCallback(() => {
    if (!!isDirty && !isUpdatingDataset) {
      setShowConfirmCloseModalHandler();
    } else {
      onClose();
    }
  }, [isDirty, isUpdatingDataset]);
  // << ENDS - Confirm close modal

  const updateDataset = async () => {
    const timeFormatMap = getTimeFormatsByFieldNames(data);
    const dateTimeFormatMap = reduce(
      watchFields,
      (result, field) => {
        const formattedString = join(field.segments, "_");
        result[field.fieldName] = `${formattedString} ${timeFormatMap[field.fieldName]}`;
        return result;
      },
      {} as { [key: string]: string }
    );

    const payload = {
      id: datasetData?.id,
      entityMeta: {
        dateTimeFormatMap
      }
    };

    resetUpdateDatasetMutation();
    await updateDatasetMutation(payload, {
      onSuccess: () => {
        fetchDatasetResources();

        toastWrapper({
          type: ToastTypes.Success,
          content: "Updated date formats!"
        });

        onClose();
      }
    });
  };

  const disabledCancelActionMessage = useMemo(() => {
    if (!!isUpdatingDataset) {
      return "Please wait. The update action is in progress.";
    }

    return "";
  }, [isUpdatingDataset]);

  const disabledUpdateActionMessage = useMemo(() => {
    if (!!isLoading) {
      return "Please wait. Fetching required data.";
    }

    if (!!isUpdatingDataset) {
      return "Please wait. The update action is in progress.";
    }

    if (!isDirty) {
      return "Change fields to enable this action.";
    }

    if (!isValid) {
      return "Invalid fields.";
    }

    return "";
  }, [isLoading, isUpdatingDataset, isDirty, isValid]);

  return (
    <>
      {!!showConfirmCloseModal && (
        <ConfirmClose onConfirm={onClose} onCancel={resetShowConfirmCloseModalHandler} />
      )}

      <Modal
        open
        title={DateFormatModalHelperText.Title}
        onClose={isAttemptedClose}
        // Cancel
        isCancelDisabled={!!disabledCancelActionMessage}
        cancelActionInfo={disabledCancelActionMessage}
        // Submit
        submitLabel={DateFormatModalHelperText.SubmitLabel}
        isSubmitDisabled={!!disabledUpdateActionMessage}
        isSubmitting={!!isUpdatingDataset}
        submitActionInfo={disabledUpdateActionMessage}
        onSubmit={updateDataset}>
        <Grid container direction="column" className={classes.root}>
          <Grid item>
            {!!isLoading ? (
              <FormControl component="fieldset" size="small" margin="dense" fullWidth>
                <FormLabel component="legend">
                  <Typography variant="body2" data-testid="datasetFormatModalFieldsPreviewLabel">
                    {DateFormatFormFieldsNameMapping[DateFormatFormFields.Fields]}
                  </Typography>
                </FormLabel>
                <FormGroup>
                  <PreviewPlaceholder
                    label={DateFormatFormFieldsNameMapping[DateFormatFormFields.Fields]}
                    simulateFormControl={false}
                    height={38}
                    data-testid="datasetFormatModalFieldsPreview"
                  />
                </FormGroup>
              </FormControl>
            ) : (
              map(fields, (item, index) => {
                const segments = (watchFields?.[index]?.segments || []) as string[];
                return (
                  <Grid container spacing={2} key={item.id} alignItems="center">
                    <Grid item xs={4}>
                      <Typography
                        variant="body2"
                        align="right"
                        data-testid={`datasetFormatModalField${index}`}>
                        <OverflowTooltip
                          style={{ whiteSpace: "nowrap", maxWidth: 170 }}
                          value={item.fieldName}
                          data-testid={`datasetFormatModalField${index}Info`}
                        />
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Grid container direction="column">
                        <Grid item>
                          <ButtonGroup size="small" className={classes.fieldsButtonGroup}>
                            {map(segments, (_, segmentIndex: number) => (
                              <Controller
                                key={segmentIndex}
                                name={`fields.${index}.segments.${segmentIndex}`}
                                control={control}
                                rules={{
                                  validate: () => {
                                    const uniqueSegments = uniq(segments);

                                    // Check if both YY and YYYY exist
                                    if (includes(segments, "YY") && includes(segments, "YYYY")) {
                                      return false; // Invalid when both are present
                                    }

                                    return size(uniqueSegments) === size(segments) || false;
                                  }
                                }}
                                render={({ field }) => (
                                  <Button
                                    disableElevation
                                    disableFocusRipple
                                    size="small"
                                    className="fieldButton">
                                    <FormControl
                                      variant="outlined"
                                      size="small"
                                      fullWidth
                                      error={!isValid && !!errors?.fields?.[index]?.segments}>
                                      <Select
                                        {...field}
                                        value={field.value || ""}
                                        data-testid={`datasetFormatModalField${index}Segment${segmentIndex}Select`}>
                                        {map(segmentOptions, (option) => (
                                          <MenuItem key={option} value={option}>
                                            {option}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                  </Button>
                                )}
                                data-testid={`datasetFormatModalField${index}Segment${segmentIndex}`}
                              />
                            ))}
                          </ButtonGroup>
                        </Grid>
                        {!isValid && !!errors?.fields?.[index]?.segments && (
                          <Grid item>
                            <Typography
                              variant="caption"
                              color="error"
                              data-testid={`datasetFormatModalField${index}Error`}>
                              Fields must be distinct.
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })
            )}
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default DateFormatModal;
