import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";

// Packages
import {
  ColumnDefResolved,
  ColumnDefTemplate,
  HeaderContext,
  VisibilityState
} from "@tanstack/react-table";
import {
  filter,
  includes,
  indexOf,
  intersectionBy,
  isEmpty,
  isEqual,
  map,
  reverse,
  size,
  sortBy,
  toLower
} from "lodash";
import clsx from "clsx";

// MUI
import Menu from "@material-ui/core/Menu";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";

// Icons
import { TableCog } from "src/assets/icons/TableCog";

// Utils
import { TableSettingsColumnsDirection } from "../../utils/Table.constants";

// Components
import Columns from "./Columns";
import FooterActions from "./FooterActions";
import SearchColumns from "./SearchColumns";

// Contexts
import { useTableContext } from "../../context/useTableContext";

// Types
import { TData } from "../../Table.types";

const MAX_COLUMN_AMOUNT_VIEWDATA = 200;

// Styles
import { useSettingsIconsStyles, useSettingsStyles } from "./Settings.styles";
import { Box, Checkbox, Grid, Typography, useTheme } from "@material-ui/core";
import { DataSourcesHelperText } from "src/pages/DataSources/utils/DataSources.constants";
import EventBus from "services/EventBus/EventBus";
import { EVENTBUS_EVENTS } from "constants/eventBus.constants";

type TableSettingsProps = {
  maxColumnsCount?: number;
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
};

type SettingsIconProps = {
  columns: ColumnDefResolved<TData, any>[];
  columnVisibility?: VisibilityState;
  toggleColumns: (columns: (ColumnDefTemplate<HeaderContext<any, unknown>> | undefined)[]) => void;
  tableSettingsProps?: TableSettingsProps;
  disabledApplyActionMessage?: string;
};

type SettingsProps = {
  maxColumnsCount?: number;
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
  columns: ColumnDefResolved<TData, any>[];
  columnVisibility?: VisibilityState;
  toggleColumns: (columns: (ColumnDefTemplate<HeaderContext<any, unknown>> | undefined)[]) => void;
  disabledApplyActionMessage?: string;
};

interface IColumnDef extends ColumnDefResolved<TData, any> {
  isSelected?: boolean;
}

const Settings = (props: SettingsProps) => {
  const {
    maxColumnsCount,
    anchorEl,
    setAnchorEl,
    columns: inputColumns,
    columnVisibility,
    toggleColumns,
    disabledApplyActionMessage
  } = props || {};

  const theme = useTheme();

  const classes = useSettingsStyles();

  const { table, storeUserPreferences } = useTableContext();
  const [value, setValue] = useState("");

  const [columns, setColumns] = useState<IColumnDef[]>([]);
  const [filteredColumns, setFilteredColumns] = useState<IColumnDef[]>([]);

  const selectedColumns = useMemo(() => filter(columns, { isSelected: true }), [columns]);

  useEffect(() => {
    if (Boolean(anchorEl)) {
      setValue("");
      const columns = sortBy(inputColumns, (column: ColumnDefResolved<TData, any>) =>
        // @ts-ignore
        table?.getState()?.columnOrder?.indexOf(column?.accessorKey)
      );

      const defaultColumns = map(columns, (column: ColumnDefResolved<TData, any>) => ({
        ...column,
        // @ts-ignore
        isSelected: !!columnVisibility?.[column?.accessorKey]
      }));

      const orderedColumn = map(defaultColumns, (item) => ({
        accessorKey: item.accessorKey,
        header: item.header,
        isSelected: item.isSelected ?? false
      }));

      const isColumnsFilterApplied = !isEqual(orderedColumn, originalInputColumns);
      EventBus.publish(EVENTBUS_EVENTS.TableColumnFilterChanged, {
        isColumnsFilterApplied,
        selectionCount: size(selectedColumns),
        totalColumns: size(defaultColumns)
      });
      setColumns(defaultColumns);
      setFilteredColumns(defaultColumns);
    }
  }, [anchorEl, inputColumns, table?.getState()?.columnOrder, columnVisibility]);

  const onClose = () => {
    setAnchorEl(() => null);
  };

  const { originalInputColumns, inputColumnsNames } = useMemo(() => {
    const originalInputColumns = map(inputColumns, (item, index) => ({
      accessorKey: item.accessorKey,
      header: item.header,
      isSelected: index < MAX_COLUMN_AMOUNT_VIEWDATA
    }));
    const inputColumnsNames = map(inputColumns, "header");
    return { originalInputColumns, inputColumnsNames };
  }, [inputColumns]);

  useEffect(() => {}, []);

  const sort = (direction?: TableSettingsColumnsDirection) => {
    switch (direction) {
      case TableSettingsColumnsDirection.Asc:
        const sortedColumns = sortBy(columns, [
          (column) => column?.header?.toLowerCase(),
          (column) => indexOf(inputColumnsNames, column?.header)
        ]);

        setColumns(sortedColumns);

        setFilteredColumns(intersectionBy(sortedColumns, filteredColumns, "accessorKey"));
        break;

      case TableSettingsColumnsDirection.Desc:
        const reverseSortedColumns = reverse(
          sortBy(columns, [
            (column) => column?.header?.toLowerCase(),
            (column) => indexOf(inputColumnsNames, column?.header)
          ])
        );
        setColumns(reverseSortedColumns);

        setFilteredColumns(intersectionBy(reverseSortedColumns, filteredColumns, "accessorKey"));
        break;

      default:
        const thisColumns = map(inputColumns, (column: ColumnDefResolved<TData, any>, index) => ({
          ...column,
          isSelected: index < MAX_COLUMN_AMOUNT_VIEWDATA
        }));
        setColumns(() => thisColumns);
        setFilteredColumns(() => intersectionBy(thisColumns, filteredColumns, "accessorKey"));
        break;
    }
  };

  const handleCheckAll = (event: any) => {
    if (event.target.checked) {
      let noOfSelectedColumn = 0;

      for (let i = 0; i < filteredColumns.length; i++) {
        if (filteredColumns[i].isSelected) {
          noOfSelectedColumn++;
        }
      }
      let numberOfColumntobeSelected = MAX_COLUMN_AMOUNT_VIEWDATA - noOfSelectedColumn;
      const updatedFilteredColumns = [...filteredColumns];

      for (let i = 0; i < updatedFilteredColumns.length && numberOfColumntobeSelected > 0; i++) {
        if (!updatedFilteredColumns[i].isSelected) {
          updatedFilteredColumns[i].isSelected = true;
          numberOfColumntobeSelected--;
        }
      }

      const updatedColumns = map(columns, (column) => {
        const updatedFilteredColumn = updatedFilteredColumns.find(
          (fc) => fc.accessorKey === column.accessorKey
        );
        return updatedFilteredColumn ? updatedFilteredColumn : column;
      });

      setColumns(updatedColumns);
      setFilteredColumns(updatedFilteredColumns);
    } else {
      const updatedFilteredColumns = map(filteredColumns, (column) => ({
        ...column,
        isSelected: false
      }));

      const updatedColumns = map(columns, (column) => {
        const updatedFilteredColumn = updatedFilteredColumns.find(
          (fc) => fc.accessorKey === column.accessorKey
        );
        return updatedFilteredColumn ? updatedFilteredColumn : column;
      });

      setColumns(updatedColumns);
      setFilteredColumns(updatedFilteredColumns);
    }
  };

  const isCheckedAll = useMemo(
    () =>
      size(filter(filteredColumns, (item: any) => item.isSelected)) >=
      Math.min(MAX_COLUMN_AMOUNT_VIEWDATA, size(filteredColumns)),
    [filteredColumns]
  );

  const handleApply = () => {
    const columnOrder = map(
      columns,
      (column: ColumnDefResolved<TData, any>) => column?.accessorKey
    );

    // @ts-ignore
    table?.setColumnOrder(["metaDataColumn", ...columnOrder]);
    storeUserPreferences?.({ columnOrder });

    toggleColumns(
      map(
        filter(columns, (column: IColumnDef) => !!column?.isSelected),
        (column: ColumnDefResolved<TData, any>) => column?.header
      )
    );
    onClose();
    const orderedColumn = map(columns, (item) => ({
      accessorKey: item.accessorKey,
      header: item.header,
      isSelected: item.isSelected ?? false
    }));

    const isColumnsFilterApplied = !isEqual(orderedColumn, originalInputColumns);
    EventBus.publish(EVENTBUS_EVENTS.TableColumnFilterChanged, {
      isColumnsFilterApplied,
      selectionCount: size(selectedColumns),
      totalColumns: size(columns)
    });
  };

  const onColumnsReorder = (filteredReorderedColumns: ColumnDefResolved<TData, any>[]) => {
    if (size(filteredReorderedColumns) > 0) {
      const filteredColumnNames = map(
        filteredReorderedColumns,
        (column: ColumnDefResolved<TData, any>) => column?.accessorKey
      );
      const columnNames = map(
        columns,
        (column: ColumnDefResolved<TData, any>) => column?.accessorKey
      );
      const startIndex = indexOf(columnNames, filteredColumnNames?.[0]);

      if (startIndex !== -1) {
        const reorderedColumns = filter(
          columns,
          (column: ColumnDefResolved<TData, any>) =>
            !includes(filteredColumnNames, column?.accessorKey)
        );

        // @ts-ignore
        reorderedColumns?.splice(startIndex, 0, ...filteredReorderedColumns);

        setColumns(() => reorderedColumns);
      }
    }
  };

  const handleChange = (newVal: string) => {
    setFilteredColumns(
      filter(columns, (column: ColumnDefResolved<TData, any>) =>
        includes(toLower(column?.header), toLower(newVal))
      ) || []
    );
  };

  const menuItemComponents = useMemo(() => {
    let items: React.ReactNode[] = [];

    items.push(
      <li
        key="search"
        className={clsx(["borderRight", "borderLeft", "borderTop", classes.padding])}>
        <SearchColumns value={value} setValue={setValue} onValueChange={handleChange} />
      </li>
    );

    items.push(<Divider key="divider" />);
    if (isEmpty(filteredColumns)) {
      items.push(
        <Box
          py={1}
          px={2}
          key="box"
          fontSize="small"
          fontStyle="italic"
          color={theme.palette.text.secondary}
          className={clsx(["borderRight", "borderLeft"])}>
          {DataSourcesHelperText.NoSearchedColumnsFoundInfo}
        </Box>
      );
      items.push(<Divider key="divider2" />);
    } else {
      items.push(
        <li
          key="grid"
          className={clsx(["borderRight", "borderLeft"])}
          style={{ paddingLeft: theme.spacing(1.5) }}>
          <Grid container alignItems="center">
            <Checkbox
              data-testid="tableFilterSelectAll"
              size={"small"}
              style={{ padding: "4px" }}
              color="primary"
              checked={isCheckedAll}
              onClick={handleCheckAll}
            />
            <Typography variant="body2">{DataSourcesHelperText.SelectAll}</Typography>
          </Grid>
        </li>
      );
      items.push(
        <li key="columns" className={clsx(["borderRight", "borderLeft", "bgColor"])}>
          <Columns
            maxColumnsCount={maxColumnsCount}
            columns={columns}
            setColumns={setColumns}
            filteredColumns={filteredColumns}
            setFilteredColumns={setFilteredColumns}
            selectedColumns={selectedColumns}
            onColumnsReorder={onColumnsReorder}
          />
        </li>
      );
    }
    items.push(
      <li key="footer" className={clsx(["borderRight", "borderLeft", "borderBottom"])}>
        <FooterActions
          inputColumnsNames={inputColumnsNames}
          maxColumnsCount={maxColumnsCount}
          inputColumns={originalInputColumns}
          columns={columns}
          selectedColumns={selectedColumns}
          sort={sort}
          onApply={handleApply}
          disabledApplyActionMessage={disabledApplyActionMessage}
          disableFooterActions={isEmpty(filteredColumns)}
        />
      </li>
    );

    return items;
  }, [
    value,
    filteredColumns,
    isCheckedAll,
    maxColumnsCount,
    columns,
    selectedColumns,
    inputColumnsNames,
    originalInputColumns,
    disabledApplyActionMessage
  ]);

  return (
    <Menu
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      keepMounted
      onClose={onClose}
      PopoverClasses={{ paper: classes.container }}
      MenuListProps={{
        className: classes.list
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      getContentAnchorEl={null}>
      {isEmpty(menuItemComponents) ? <></> : menuItemComponents}
    </Menu>
  );
};

const SettingsIcon = (props: SettingsIconProps) => {
  const {
    columns,
    columnVisibility,
    toggleColumns,
    tableSettingsProps,
    disabledApplyActionMessage
  } = props || {};

  const classes = useSettingsIconsStyles();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const onClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
    setAnchorEl(() => event?.currentTarget);

  return (
    <>
      <Settings
        maxColumnsCount={tableSettingsProps?.maxColumnsCount}
        anchorEl={tableSettingsProps?.anchorEl || anchorEl}
        setAnchorEl={tableSettingsProps?.setAnchorEl || setAnchorEl}
        columns={columns}
        columnVisibility={columnVisibility}
        toggleColumns={toggleColumns}
        disabledApplyActionMessage={disabledApplyActionMessage}
      />

      {!tableSettingsProps && (
        <IconButton className={classes.root} onClick={onClick} color="primary">
          <TableCog />
        </IconButton>
      )}
    </>
  );
};

export default SettingsIcon;
