import { useMutation, UseMutationResult } from "@tanstack/react-query";

// Open API
import api from "services/AxiosClient/AxiosClient";

export interface IReorderRecipeInQueuePayload {
  projectId: string;
  payload: {
    groupId: string;
    scenarioId: string;
    index: number;
  };
}

const useReorderRecipeInQueue = (): UseMutationResult<
  unknown,
  unknown,
  IReorderRecipeInQueuePayload,
  unknown
> =>
  useMutation({
    mutationFn: async (payload: IReorderRecipeInQueuePayload) => {
      if (!payload?.projectId || !payload?.payload) {
        throw "Invalid payload!";
      }

      return await api.fetchResponse(
        async () =>
          await api.DfsRunConfigGroupControllerV2Api.reOrderRecipeQueue(payload?.projectId, {
            ...payload?.payload
          })
      );
    }
  });

export default useReorderRecipeInQueue;
