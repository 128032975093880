import React, { useEffect, useMemo, useState } from "react";

// Packages
import { useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { find, isEmpty, isNil, reduce, size } from "lodash";

// Utils
import { projectRegExpLiteralNotations } from "src/pages/private/ProjectsModule/utils";
import { composeSegmentName, getRulesFromExpression } from "../utils/Segment.helpers";

// Open API
import { FeatureDto, ProjectDashboardDto, ScenarioDto, SegmentDto } from "@rapidcanvas/rc-api-core";

// Hooks
import { useGetEntityFeaturesV3, useGetJob, useGetJobRun, useGetScenario } from "src/hooks/api";
import useEntities from "src/hooks/api/entities/useEntities";
import useSaveSegment from "../hooks/useSaveSegment";
import { useGetSegments } from "src/hooks/api/segments";

// Stores
import { useProjectsStore, useScenariosStore } from "stores/zustand/stores";
import { projectsGetter } from "stores/zustand/stores.selectors";

// Contexts
import { SegmentContext } from "./SegmentContext";

// Types
import { Segment, Segment as SegmentType } from "../Segment.type";

// Constants
import { defaultValues, SegmentFormFields } from "../utils/Segment.constants";

type Props = {
  children: React.ReactNode;
};

const SegmentContextProvider = (props: Props) => {
  const { children } = props || {};

  const location = useLocation();
  const {
    projectId,
    scenarioId,
    entityId: datasetId,
    segmentId,
    jobId,
    jobRunId
  } = useParams() || {};

  const isJobPath = useMemo(
    () => projectRegExpLiteralNotations.jobs.test(location.pathname),
    [location.pathname]
  );

  // Stores - STARTS >>
  const projectsStore = useProjectsStore(projectsGetter);
  const scenariosStore = useScenariosStore((state) => state.scenarios);
  // << ENDS - Stores

  // States - STARTS >>
  const [isDefaultScenario, setIsDefaultScenario] = useState(false);
  const [isSegmentNameSaving, setIsSegmentNameSaving] = useState(false);
  // << ENDS - States

  const project = useMemo(
    () => find(projectsStore, (eachProject: ProjectDashboardDto) => eachProject?.id === projectId),
    [projectsStore, projectId]
  );

  // Query hooks - STARTS >>
  // Queries
  const { data: jobData } = useGetJob({ projectId, jobId, enabled: !!isJobPath });
  const { data: jobRunData } = useGetJobRun({
    jobRunId,
    isApiWithRethrow: false,
    enabled: !!isJobPath
  });

  const { refetch: refetchScenario } = useGetScenario({
    scenarioId,
    onSuccess: (data) => setIsDefaultScenario(() => data?.name === "DEFAULT")
  });

  const { isLoading: isDatasetLoading, data: datasetData } = useEntities({
    // @ts-ignore
    id: datasetId,
    projectId,
    scenarioId,
    jobRunId
  });

  const { isLoading: isDatasetFeaturesLoading, data: datasetFeaturesData } = useGetEntityFeaturesV3(
    {
      entityId: datasetId,
      scenarioId,
      jobRunId,
      refetchOnMount: true
    }
  );

  const datasetFeaturesDataTypeMapping = useMemo(() => {
    if (isEmpty(datasetFeaturesData)) {
      return {};
    }

    return reduce(
      datasetFeaturesData,
      (acc: { [key: string]: string }, item: FeatureDto) => {
        // @ts-ignore
        acc[item.name] = item?.fieldSchema?.rcDataType;
        return acc;
      },
      {}
    );
  }, [datasetFeaturesData]);

  const { isLoading: isSegmentLoading, data: segmentsDataResponse } = useGetSegments({
    datasetId,
    jobRunId
  });

  const segmentData = useMemo(
    () => find(segmentsDataResponse, { id: segmentId }) || {},
    [segmentId, segmentsDataResponse]
  );
  // << ENDS - Query hooks

  useEffect(() => {
    if (size(scenariosStore) > 0) {
      const filteredScenario = find(
        scenariosStore,
        (scenario: ScenarioDto) => scenario?.id === scenarioId
      );

      setIsDefaultScenario(filteredScenario?.name === "DEFAULT");
    } else {
      refetchScenario();
    }
  }, [scenariosStore]);

  // Form - STARTS >>
  const formMethods = useForm<SegmentType>({
    mode: "onChange", // Validate onChange
    reValidateMode: "onChange", // Re-validate onChange
    defaultValues
  });

  const { reset, getValues } = formMethods || {};

  const resetForm = (data?: SegmentDto) => {
    const name = !!segmentId ? data?.name : composeSegmentName(segmentsDataResponse);

    if (!name) {
      return;
    }

    let values: Segment = {
      ...getValues(),
      [SegmentFormFields.Name]: name
    };

    if (!!segmentId && !isNil(data)) {
      // @ts-ignore
      const groups = getRulesFromExpression(data?.condition?.expression);
      if (!isEmpty(data) && !isEmpty(groups)) {
        values = {
          ...values,
          [SegmentFormFields.RowLimit]: data?.rowLimit ?? undefined,
          [SegmentFormFields.Description]: data?.description,
          [SegmentFormFields.Groups]: groups
        };
      }
    }

    reset(values);
  };

  useEffect(() => resetForm(segmentData), [segmentId, segmentsDataResponse, segmentData]);
  // << ENDS - Form

  const { isSegmentSaving, saveSegment } = useSaveSegment({
    getValues,
    datasetFeaturesDataTypeMapping
  });

  const isReadOnly = useMemo(
    () => !isDefaultScenario || !!isJobPath || !!isSegmentSaving || !!isSegmentNameSaving,
    [isDefaultScenario, isJobPath, isSegmentSaving, isSegmentNameSaving]
  );

  const value = useMemo(
    () => ({
      isDefaultScenario,

      isJobPath,
      jobData,
      jobRunData,

      project,

      isDatasetLoading,
      datasetData,

      isDatasetFeaturesLoading,
      datasetFeaturesData,
      datasetFeaturesDataTypeMapping,

      isSegmentLoading,

      isReadOnly,

      formMethods,
      resetForm,

      saveSegment,
      isSegmentSaving,
      setIsSegmentNameSaving
    }),
    [
      isDefaultScenario,

      isJobPath,
      jobData,
      jobRunData,

      project,

      isDatasetLoading,
      datasetData,

      isDatasetFeaturesLoading,
      datasetFeaturesData,
      datasetFeaturesDataTypeMapping,

      isSegmentLoading,

      isReadOnly,

      formMethods,
      resetForm,

      saveSegment,
      isSegmentSaving,
      setIsSegmentNameSaving
    ]
  );

  return <SegmentContext.Provider value={value}>{children}</SegmentContext.Provider>;
};

export default SegmentContextProvider;
