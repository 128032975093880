class AppEnvironment {
  static LOCALHOST = "localhost";

  isLocalhost = () => {
    const hostname = window.location.hostname;
    return hostname === AppEnvironment.LOCALHOST;
  };

  isProduction = () => {
    return !this.isLocalhost();
  };

  getEnvironment = () => {
    const hostname = window.location.hostname;
    return this.isLocalhost() ? AppEnvironment.LOCALHOST : hostname.split(".")[0];
  };
}

const env = new AppEnvironment();
export default env;
