import React, { useMemo } from "react";

// Packages
import { includes } from "lodash";

// Components
import { Modal } from "src/components/custom";
import { ModalVariants } from "src/components/custom/Modal/Modal";

// Constants
import { EnvironmentConfigUpdatePromptDetails } from "./EnvironmentConfig.constants";

interface IProps {
  isSaving?: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmUpdate: React.FC<IProps> = (props) => {
  const { isSaving, onConfirm, onCancel } = props;

  const disabledSaveActionMessage = useMemo(() => {
    if (!!isSaving) {
      return "Please wait. The save action is in progress.";
    }

    return "";
  }, [isSaving]);

  return (
    <Modal
      open
      variant={ModalVariants.Delete}
      title={EnvironmentConfigUpdatePromptDetails.title}
      content={[EnvironmentConfigUpdatePromptDetails.messageLine1]}
      onClose={(_, reason: string) => {
        if (!includes(["escapeKeyDown", "backdropClick"], reason)) {
          onCancel();
        }
      }}
      cancelLabel={EnvironmentConfigUpdatePromptDetails.cancelLabel}
      isCancelDisabled={!!disabledSaveActionMessage}
      isSubmitDisabled={!!disabledSaveActionMessage}
      isSubmitting={!!disabledSaveActionMessage}
      submitActionInfo={disabledSaveActionMessage}
      onSubmit={onConfirm}
      hideCloseIcon
    />
  );
};

export default ConfirmUpdate;
