import { useMutation, UseMutationResult } from "@tanstack/react-query";

import api from "services/AxiosClient/AxiosClient";
import { JwtRequestDto } from "@rapidcanvas/rc-api-core";

const useRefreshToken = (): UseMutationResult<string, unknown, JwtRequestDto, unknown> =>
  useMutation({
    mutationFn: async (payload: JwtRequestDto) =>
      await api.fetchResponse(
        async () => await api.SecurityControllerApi.refreshToken({ ...payload })
      )
  });

export default useRefreshToken;
