// Jobs page
import JobsTable from "./JobsTable/JobsTable";

// Job page
import JobWrapper from "./Job/JobWrapper";
import JobHeader from "./Job/JobHeader";
import JobRunConfig from "./JobRunConfig/JobRunConfig";
import JobGlobalVariables from "./JobGlobalVariables/JobGlobalVariables";
import JobCanvas from "./JobCanvas";
import JobDestinations from "./JobDestinations/JobDestinations";
import JobDestination from "./JobDestination/JobDestination";
import ManualJobRun from "./ManualJobRun/ManualJobRun";

// Job Canvas Compare page
import JobCanvasCompare from "./JobCanvasCompare/JobCanvasCompare";

// Job Runs page
import JobRuns from "./JobRuns/JobRuns";
import JobRunsTable from "./JobRunsTable/JobRunsTable";
import JobRunOutputModal from "./JobRunOutputModal/JobRunOutputModal";

// Job Logs page
import JobRunLogsPage from "./JobRunLogsPage/JobRunLogsPage";

// Job Run Canvas page
import JobRunCanvas from "./JobRunCanvas/JobRunCanvas";

export {
  // Jobs page
  JobsTable,

  // Job page
  JobWrapper,
  JobHeader,
  JobRunConfig,
  JobGlobalVariables,
  JobCanvas,
  JobDestinations,
  JobDestination,
  ManualJobRun,

  // Job Canvas Compare page
  JobCanvasCompare,

  // Job Runs page
  JobRuns,
  JobRunsTable,
  JobRunOutputModal,

  // Job Logs page
  JobRunLogsPage,

  // Job Run Canvas page
  JobRunCanvas
};
