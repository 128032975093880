import React, { useRef, useState } from "react";
import { ArrowDropDown } from "@material-ui/icons";
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Tooltip
} from "@material-ui/core";

import { DISABLED_RECIPE_LOGS } from "projectsModule/utils/Projects.constants";
import { LogsNewIconV2 } from "src/assets/icons/LogsNewIconV2";

interface IProps {
  isTesting: boolean;
  isRunning: boolean;
  id?: string;
  hideTest?: boolean;
  onLogsClick: (isRunLogs: boolean) => void;
}

const options = ["Log For Test", "Log For Run"];
const onlyRun = ["Log For Run"];

const RecipeLogsButton: React.FC<IProps> = (props) => {
  const { id, isRunning, isTesting, hideTest, onLogsClick } = props;

  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement | null>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleMenuItemClick = (__: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
    onLogsClick(index === 1);
    setOpen(false);
  };

  const handleClose = (event: React.MouseEvent<Document>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as Node)) {
      return;
    }

    setOpen(false);
  };

  const handleLogBtnClick = () => {
    if (isTesting) {
      onLogsClick(false);
    } else if (isRunning) {
      onLogsClick(true);
    } else {
      setOpen(true);
    }
  };

  return (
    <Tooltip title={!id ? DISABLED_RECIPE_LOGS : ""}>
      <span>
        <ButtonGroup variant="outlined" color="primary" ref={anchorRef} aria-label="split button">
          <Button
            size="small"
            color="primary"
            data-testid="viewRecipeLogs"
            onClick={handleLogBtnClick}
            disabled={!id}>
            <LogsNewIconV2 width="22" height="22" color={!id ? "#4646b550" : "#4646b5"} />
          </Button>
          <Button
            color="primary"
            size="small"
            style={{ padding: "3px 0px" }}
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="recipe logs"
            aria-haspopup="menu"
            onClick={handleToggle}>
            <ArrowDropDown />
          </Button>
        </ButtonGroup>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === "bottom" ? "center top" : "center bottom"
              }}>
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    {(hideTest ? onlyRun : options).map((option, index) => (
                      <MenuItem key={option} onClick={(event) => handleMenuItemClick(event, index)}>
                        {option}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </span>
    </Tooltip>
  );
};

export default RecipeLogsButton;
