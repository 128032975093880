import {
  DataappAskAIConfigDataAppPermissionsEnum,
  DataappAskAIConfigInputTypeEnum
} from "@rapidcanvas/rc-api-core";
import React from "react";

export enum DataAppsConfig {
  ExemptingWidth = 0.15, // 15%
  CardWidth = 575,
  CardHeight = 288
}

export enum DataAppsSplashSection {
  title = "Get Started with a New DataApp",
  subTitleLine1 = "Welcome to the DataApp page.",
  subTitleLine2 = "Start a new one by clicking on the button below.",
  actionLabel = "Custom DataApp",
  importLabel = "Import DataApp"
}

export const dataAppGettingStarted = {
  topMessage1:
    "Set up a DataApp using simple configurations and connect it to different data sources",
  topMessage2: (
    <>
      Upload a zip file containing <span style={{ whiteSpace: "nowrap" }}>pre-built</span> Streamlit
      or ReactJS code to integrate an existing DataApp.
    </>
  ),
  introduction: {
    question: "What is a DataApp?",
    answer:
      "A DataApp is an interactive tool that helps you explore and visualize data from sources like databases, documents, datasets, and other inputs."
  },
  functionality: {
    question: "How it works?",
    answers: [
      "Create a DataApp or Import a DataApp.",
      "Configure your data source and environment.",
      "Customize and analyze data using AskAI.",
      "Generate insights, visualizations, and predictions. "
    ]
  }
};

export const IMPORT_TOOLTIP = "Provide a zip file containing code to automatically build a DataApp";
export const ADD_DATAAPP_TOOLTIP =
  "Choose simple configuration options to effortlessly build a DataApp";
export const STOPPING_DATAAPP_MSG = "Please wait while the DataApp stops";
export const LAUNCHING_DATAAPP_MSG = "Please wait while the dataApp is launching";
export const DELETING_DATAAPP_MSG = "Dataapp deletion is already in progress";
export const DEFAULT_DATAAPP_NAME = "Untitled DataApp";

export const askAITypesInfoList = [
  {
    title: "Project Canvas Datasets",
    tooltip:
      "This option supports using AskAI on all the datasets available on the project canvas along with user uploaded datasets",
    label: "Project Canvas Datasets",
    value: DataappAskAIConfigInputTypeEnum.ProjectCanvas
  },
  {
    title: "Custom Uploaded Datasets",
    tooltip: "This option supports using AskAI on user uploaded datasets",
    label: "Custom Uploaded Datasets",
    value: DataappAskAIConfigInputTypeEnum.Dataset
  },
  {
    title: "SQL DB as Inputs",
    tooltip:
      "This option supports using AskAI on MySQL based database. The connection needs to be created under Connectors menu via main navigation",
    label: "SQL Sources",
    value: DataappAskAIConfigInputTypeEnum.SqlDb
  },
  {
    title: "Documents and PDFs",
    tooltip:
      "This option supports using AskAI on user uploaded Document, PDF, Text and Markdown files",
    label: "Documents and PDFs",
    value: DataappAskAIConfigInputTypeEnum.RagFiles
  },
  {
    title: "Prediction Service",
    tooltip: "This option supports using AskAI on Prediction Service",
    label: "Prediction Service",
    value: DataappAskAIConfigInputTypeEnum.PredictionService
  },
  {
    title: "Scheduler",
    tooltip: "This option supports using AskAI on Scheduler",
    label: "Scheduler",
    value: DataappAskAIConfigInputTypeEnum.JobCanvas
  }
];

export const DATAAPP_PERMISSIONS = {
  [DataappAskAIConfigDataAppPermissionsEnum.ChatCreation]: {
    label: "Allow New Chat Creation",
    tooltip: "Disabling this will prevent consumer users from creating new chats in this DataApp."
  },
  [DataappAskAIConfigDataAppPermissionsEnum.ChatDeletion]: {
    label: "Allow Chat Deletion",
    tooltip: "Disabling this will prevent consumer users from deleting chats in this DataApp"
  },
  [DataappAskAIConfigDataAppPermissionsEnum.ChatInputSelection]: {
    label: "Allow Input Selection",
    tooltip:
      "Disabling this will prevent consumer users from selecting inputs for chats in this DataApp. Please ensure the input is associated at the chat level before enabling this option"
  },
  [DataappAskAIConfigDataAppPermissionsEnum.InputNameVisibility]: {
    label: "Show Input Name and Details",
    tooltip:
      "Disabling this will prevent consumer users from viewing input data and details in this DataApp"
  },
  [DataappAskAIConfigDataAppPermissionsEnum.InputViewPdfApp]: {
    label: "Allow Documents Download and Preview",
    tooltip:
      "Disabling this will prevent consumer users from previewing and downloading input documents in this DataApp"
  },

  [DataappAskAIConfigDataAppPermissionsEnum.UserCharts]: {
    label: "Show User Charts Tab",
    tooltip: "Disabling this will hide the User Charts tab for consumer users"
  },
  [DataappAskAIConfigDataAppPermissionsEnum.SlashOption]: {
    label: "Allow Slash Options",
    tooltip:
      "Disabling this will prevent consumer users from using slash options such as /Dataset, /Text, /Charts, and /Prompt Suggestions (where applicable) in input queries within this DataApp"
  },
  [DataappAskAIConfigDataAppPermissionsEnum.LlmGeneratedCode]: {
    label: "Show Model Response Code",
    tooltip:
      "Disabling this will prevent consumer users from viewing the code generated by the model in response to their queries"
  }
};
