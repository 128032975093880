import React, { useMemo, useState } from "react";
import {
  VisibilityOutlined,
  OpenInNewOutlined,
  LibraryAddCheckOutlined,
  FileCopyOutlined,
  OpenInNew
} from "@material-ui/icons";
import { Box } from "@material-ui/core";
import { useNavigate } from "react-router-dom";

import SubTopNavBarWrapper from "layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import SubTopNavBarBreadcrumbs from "./SubTopNavBarBreadcrumbs";
import {
  DataAppDto,
  DataAppDtoDataAppTypeEnum,
  DataAppDtoLaunchStatusEnum
} from "@rapidcanvas/rc-api-core";
import DataAppLogsDrawer from "pages/DataApps/DataAppLogs/DataAppLogsDrawer";
import api from "services/AxiosClient/AxiosClient";
import useAuthStore from "stores/zustand/auth.store";
import useCopyClipboard from "hooks/useCopyClipboard";
import { DataAppType } from "pages/DataApps/DataApps.type";
import { DeleteDataAppModal } from "../../DeleteDataAppModal";
import { LogsNewIconV2 } from "icons/LogsNewIconV2";
import { TrashIcon } from "icons/NewUX/TrashIcon";
import { deleteDisabled, deleteTooltip } from "pages/DataApps/utils/DataApps.helpers";
import { useGetRole } from "hooks/useGetRole";

interface IProps {
  projectId: string;
  dataApp?: DataAppDto;
  status?: DataAppDtoLaunchStatusEnum;
  dataAppType?: DataAppDtoDataAppTypeEnum;
  dataAppName: string;
  dataAppDisplayName: string;
  onSave: (newName: string) => void;
  shouldResetDataAppName?: boolean;
  extra?: React.JSX.Element;
  onViewClick: () => void;
}

const EditDataAppHeader: React.FC<IProps> = (props) => {
  const {
    dataAppDisplayName,
    dataApp,
    dataAppType,
    projectId,
    shouldResetDataAppName,
    status,
    extra,
    onSave,
    onViewClick
  } = props;

  const navigate = useNavigate();
  const [openLogs, setOpenLogs] = useState(false);
  const [isDeleteDataAppModalOpen, setIsDeleteDataAppModalOpen] = useState(false);
  const [tenantName] = useAuthStore((state) => [state.tenantName]);
  const { isRoleYieldsDataAppView, isConsumer } = useGetRole();

  const dataAppDeepLink = useMemo(
    () =>
      dataApp?.accessUrl && dataApp?.name
        ? `${window.location.origin}/apps/${encodeURIComponent(dataApp.name)}${`/${encodeURIComponent(tenantName || "")}`}`
        : null,
    [dataApp, tenantName]
  );
  const { handleCopyClick, isCopied } = useCopyClipboard(dataAppDeepLink);

  const isCopyDisabled = useMemo(
    () => !dataAppDeepLink || !dataApp?.accessUrl || status !== DataAppDtoLaunchStatusEnum.Running,
    [dataApp?.accessUrl, dataAppDeepLink, status]
  );

  const isOpenInNewTabDisabled = useMemo(
    () => !dataApp?.accessUrl || status !== DataAppDtoLaunchStatusEnum.Running,
    [dataApp?.accessUrl, status]
  );

  const openInNewTab = (addConsumerView?: boolean) => {
    const accessUrl = dataApp?.accessUrl ? `${window.location.origin}${dataApp.accessUrl}` : null;
    const splitAccessUrl = dataApp?.accessUrl && accessUrl ? accessUrl?.split("?dlId=") : null;
    const token = api.getToken();

    const accessUrlWithToken =
      dataApp?.accessUrl && splitAccessUrl
        ? `${splitAccessUrl[0]}?dlId=${splitAccessUrl[1]}&token=${token}${addConsumerView ? "&isConsumer=true" : ""}`
        : null;

    accessUrlWithToken && window.open(accessUrlWithToken, "_blank");
  };

  const handleDeleteSuccess = () => {
    navigate(-1);
  };

  return (
    <>
      <SubTopNavBarWrapper
        subTopNavBarLeftSection={{
          component: (
            <SubTopNavBarBreadcrumbs
              projectId={projectId}
              dataAppName={dataAppDisplayName}
              shouldResetDataAppName={shouldResetDataAppName}
              onSave={onSave}
            />
          )
        }}
        subTopNavBarRightSection={{
          component: extra,
          moreOptions: [
            {
              label: "View DataApp",
              icon: <OpenInNewOutlined fontSize="small" />,
              testId: "dataAppViewIcon",
              action: onViewClick
            },
            {
              label: "Copy Share Link",
              testId: "dataAppCopyIcon",
              icon: isCopied ? (
                <LibraryAddCheckOutlined fontSize="small" />
              ) : (
                <FileCopyOutlined fontSize="small" />
              ),
              tooltip:
                status !== DataAppDtoLaunchStatusEnum.Running
                  ? "Ensure DataApp is running to enable this action"
                  : isCopyDisabled
                    ? ""
                    : "Click to copy URL to share",
              isDisabled: isCopyDisabled,
              action: handleCopyClick
            },
            {
              label: "Open in New Tab",
              testId: "dataAppOpenInNewTabBtn",
              icon: <OpenInNew fontSize="small" />,
              tooltip:
                status !== DataAppDtoLaunchStatusEnum.Running
                  ? "Ensure DataApp is running to enable this action"
                  : "",
              isDisabled: isOpenInNewTabDisabled,
              action: () => openInNewTab()
            },

            {
              label: "Logs",
              testId: "dataAppLogsBtn",
              icon: <LogsNewIconV2 width="19px" height="19px" />,
              action: () => setOpenLogs(true)
            },
            ...(!isRoleYieldsDataAppView && !isConsumer
              ? [
                  {
                    label: "Delete",
                    testId: "dataAppDeleteBtn",
                    icon: (
                      <Box style={{ marginRight: 5 }}>
                        <TrashIcon viewBox="-3 0 20 20" />
                      </Box>
                    ),
                    tooltip: deleteTooltip(status),
                    isDisabled: deleteDisabled(status),
                    action: () => setIsDeleteDataAppModalOpen(true)
                  }
                ]
              : []),
            ...(dataAppType === DataAppDtoDataAppTypeEnum.Askai
              ? [
                  {
                    label: "Consumer Preview",
                    icon: <VisibilityOutlined fontSize="small" />,
                    testId: "dataAppConsumerPreviewIcon",
                    tooltip:
                      status !== DataAppDtoLaunchStatusEnum.Running
                        ? "Ensure DataApp is running to enable this action"
                        : "",
                    isDisabled: status !== DataAppDtoLaunchStatusEnum.Running,
                    action: () => openInNewTab(true)
                  }
                ]
              : [])
          ]
        }}
      />
      {dataApp && openLogs && (
        <DataAppLogsDrawer
          open={openLogs}
          onClose={() => {
            setOpenLogs(false);
          }}
          dataApp={dataApp as unknown as DataAppType}
        />
      )}
      {dataApp && (
        <DeleteDataAppModal
          projectId={dataApp?.projectId}
          dataAppId={dataApp.id!}
          dataAppName={dataApp.displayName || dataApp?.name || ""}
          open={isDeleteDataAppModalOpen}
          onClose={() => setIsDeleteDataAppModalOpen(false)}
          onDeleteSuccess={handleDeleteSuccess}
        />
      )}
    </>
  );
};

export default EditDataAppHeader;
