import api from "services/AxiosClient/AxiosClient";
import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { UpdateDFSTemplateDto } from "@rapidcanvas/rc-api-core";
import { Recipe } from "src/types";
import {
  createTemplateWithRethrow,
  deleteTransformByIdWithRethrow,
  getTransformGroupWithRethrow,
  saveTransformByIdWithRethrow
} from "services/Apis/wrappers";
import { v4 as uuidv4 } from "uuid";

interface IVariable {
  payload?: UpdateDFSTemplateDto;
  isNew?: boolean;
  recipe?: Recipe | null;
  codeString?: string;
  projectId?: string;
  requirements?: string;
}

const useSaveTemplate = (): UseMutationResult<any, unknown, IVariable, unknown> => {
  return useMutation({
    mutationFn: async ({ payload, recipe, projectId, isNew = false, codeString, requirements }) => {
      if (isNew) {
        const configIdsToDelete = recipe?.runConfigs?.map((config) => config.id) || [];
        await Promise.all(
          configIdsToDelete.map(async (configId) => await deleteTransformByIdWithRethrow(configId))
        );

        const newTemplateResponses = await createTemplateWithRethrow({
          name: uuidv4(),
          code: codeString,
          projectId,
          baseTransforms: [{ condition: null, type: "python", params: {}, runConfigId: null }],
          type: "CODE",
          tags: ["code-template"],
          requirements
        });

        await saveTransformByIdWithRethrow({
          name: uuidv4(),
          groupId: recipe?.id,
          templateId: newTemplateResponses.id
        });

        const transformGroupResponse = await getTransformGroupWithRethrow(recipe?.id);

        return {
          templatesResponse: [newTemplateResponses],
          transformGroupResponse: transformGroupResponse?.[0],
          hasNewTemplates: true
        };
      }
      if (payload)
        return await api.fetchResponse(() =>
          api.DfsTemplateControllerV2Api.updateTemplate(payload)
        );
    }
  });
};

export default useSaveTemplate;
