import { RefObject, useCallback, useMemo } from "react";

import { Row } from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";
import { TData } from "../typing";
import { isEmpty } from "lodash";

type Props = {
  rows: Row<TData | any>[];
  tableContainerRef: RefObject<HTMLDivElement>;
  disableVirtualization?: boolean;
};

const useHook = (props: Props) => {
  const { rows, tableContainerRef, disableVirtualization } = props || {};
  if (!!disableVirtualization) {
    return {
      rowVirtualizer: null,
      paddingBottom: 0,
      paddingTop: 0,
      scrollToTop: () => {}
    };
  }
  const rowVirtualizer = useVirtualizer({
    count: rows?.length,
    estimateSize: () => 32, // estimate row height for accurate scrollbar dragging
    getScrollElement: () => tableContainerRef?.current,
    // measure dynamic row height, except in firefox because it measures table border height incorrectly
    measureElement:
      typeof window !== "undefined" && navigator?.userAgent?.indexOf("Firefox") === -1
        ? (element) => element?.getBoundingClientRect()?.height
        : undefined,
    overscan: 5
  });

  const virtualItems = rowVirtualizer?.getVirtualItems() ?? [];
  const totalSize = rowVirtualizer?.getTotalSize() ?? 0;

  const paddingTop = useMemo(() => {
    return virtualItems?.length > 0 ? virtualItems?.[0]?.start || 0 : 0;
  }, [virtualItems]);

  const paddingBottom = useMemo(() => {
    return virtualItems.length > 0
      ? totalSize - (virtualItems?.[virtualItems.length - 1]?.end || 0)
      : 0;
  }, [virtualItems, totalSize]);

  const scrollToTop = useCallback(() => {
    if (rowVirtualizer && tableContainerRef.current && !isEmpty(virtualItems)) {
      rowVirtualizer.scrollToIndex(0);
    }
  }, [rowVirtualizer, tableContainerRef]);

  return { rowVirtualizer, paddingBottom, paddingTop, scrollToTop };
};

export default useHook;
