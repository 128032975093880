import React, { useMemo, useState } from "react";
import _, { has, isEmpty, isUndefined, size } from "lodash";
import {
  Box,
  Paper,
  makeStyles,
  Typography,
  TextField,
  IconButton,
  Button,
  CircularProgress,
  Tooltip,
  Grid
} from "@material-ui/core";
import clsx from "clsx";
import { Switch } from "src/components";
import { ExpandLess, ExpandMore, InfoOutlined } from "@material-ui/icons";

import PrePostProcess from "./PrePostProcess";
import SelectEnvironment from "./SelectEnvironment";
import useInvalidateCache from "../../../../../../hooks/useInvalidateCache";
import {
  INCORRECT_LENGTH_MSG,
  INCORRECT_PATTERN_MSG,
  NOT_BLANK_MESSAGE,
  PSFields
} from "../utils/PredictionService.constants";
import { MachineLearningTask } from "src/pages/Projects/AddAutoMLRecipe/hooks/useGetAutoMLProblemTypes";
import {
  DescriptionCharacterLimit,
  DescriptionCharacterLimitMessage,
  DescriptionInfo
} from "../../../utils";
import { EnvDto } from "@rapidcanvas/rc-api-core";

interface IProps {
  values: Record<string, string>;
  id?: string;
  disabled: boolean;
  isDeleteInProgress: boolean;
  recipeType?: string;
  loading: boolean;
  isAutoSaving?: boolean;
  onInputChange: (e: any) => void;
  onDelete: () => void;
  onValuesChange: (newValues: any) => void;
  onSave: (code?: string) => void;
  onEnvChange: (environment: EnvDto) => void;
  shouldValidate: boolean;
}

interface IStyleProps {
  id?: string;
}

const useStyles = makeStyles<undefined, IStyleProps>({
  paper: ({ id }) => ({
    padding: "16px",
    minHeight: id ? undefined : "calc(100vh - 165px)",
    display: "flex",
    flexDirection: "column",
    gap: "20px",

    "& form": {
      display: "flex",
      flexDirection: "column",
      gap: "20px"
    }
  }),

  footer: {
    display: "flex",
    justifyContent: "flex-end",
    borderTop: "1px solid rgba(0, 0, 0, 0.2)",
    padding: "10px 0 0 0",
    margin: "10px 0 0 0",
    alignItems: "flex-end"
  },
  saveBtn: {
    height: "30px"
  },
  deleteBtn: {
    padding: 0
  },
  flex: {
    display: "flex",
    alignItems: "center",
    gap: "10px"
  },
  horizontalGrid: {
    gap: "10px"
  },
  header: {
    padding: "0px 16px",
    height: "44px",
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #c2c2c2"
  },
  collapsableHeader: {
    cursor: "pointer",
    justifyContent: "space-between",
    background: "#F0F0FF"
  },

  collapsableBox: {
    border: "1px solid #c2c2c2",
    boxShadow: "0px 1px 3px 0px #0000001F",
    borderRadius: "4px"
  },
  collapsableContent: {
    padding: "12px",
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "column",
    gap: "20px"
  }
});

const REGEX_PATTERN = new RegExp("^[A-Za-z][A-Za-z0-9_]*$");
const IN_PROGRESS_MSG = "Please wait while the current action is in progress";

const PredictionServiceDetails: React.FC<IProps> = (props) => {
  const {
    id,
    values,
    disabled,
    loading,
    recipeType,
    isDeleteInProgress,
    isAutoSaving,
    onValuesChange,
    onInputChange,
    onDelete,
    onSave,
    onEnvChange,
    shouldValidate
  } = props;
  const classes = useStyles({ id });
  const invalidateCache = useInvalidateCache();
  const name = _.get(values, PSFields.name.id, "");

  const [detailsExpanded, setDetailsExpanded] = useState(true);
  const [configurationExpanded, setConfigurationExpanded] = useState(true);

  const { error, helperText } = useMemo(() => {
    if (shouldValidate) {
      const size = _.size(name);
      const isNotBlank = !isUndefined(name) ? size === 0 : false;
      const isInCorrectLength = size > 64 || (size < 3 && size !== 0);
      const isInCorrectPattern = !!name && !REGEX_PATTERN.test(name);
      const isError = isNotBlank || isInCorrectLength || isInCorrectPattern;

      return {
        error: isError,
        helperText: isNotBlank
          ? NOT_BLANK_MESSAGE
          : isInCorrectLength
            ? INCORRECT_LENGTH_MSG
            : isInCorrectPattern
              ? INCORRECT_PATTERN_MSG
              : ""
      };
    }
    return {
      error: false,
      helperText: ""
    };
  }, [name, shouldValidate]);

  const validationError = useMemo(() => {
    const timeoutInMins = _.get(values, PSFields.timeoutInMins.id);
    const concurrency = _.get(values, PSFields.concurrency.id);
    const description = _.get(values, PSFields.description.id);
    let errors = {};
    if (timeoutInMins) {
      if (
        isFinite(_.toNumber(timeoutInMins)) &&
        !(_.toNumber(timeoutInMins) > 0 && _.toNumber(timeoutInMins) < 61)
      ) {
        errors = {
          ...errors,
          [PSFields.timeoutInMins.id]: "The Timeout needs to be between 1 to 60 minutes."
        };
      } else {
        errors = _.omit(errors, PSFields.timeoutInMins.id);
      }
    }
    if (description) {
      if (size(description) > DescriptionCharacterLimit) {
        errors = {
          ...errors,
          [PSFields.description.id]: DescriptionCharacterLimitMessage
        };
      } else {
        errors = _.omit(errors, PSFields.description.id);
      }
    }
    if (concurrency) {
      if (
        isFinite(_.toNumber(concurrency)) &&
        !(_.toNumber(concurrency) > 4 && _.toNumber(concurrency) < 101)
      ) {
        errors = {
          ...errors,
          [PSFields.concurrency.id]: "The Concurrency needs to be between 5 to 100."
        };
      } else {
        errors = _.omit(errors, PSFields.concurrency.id);
      }
    }
    return errors;
  }, [values]);

  const disableSave = useMemo(
    () =>
      disabled ||
      loading ||
      error ||
      !isEmpty(validationError) ||
      invalidateCache.isLoading ||
      isDeleteInProgress,
    [disabled, loading, error, validationError, invalidateCache.isLoading, isDeleteInProgress]
  );

  const handleAddCode = (code: string) => {
    onValuesChange({
      ...values,
      prePostProcess: code
    });
    if (
      loading ||
      error ||
      !isEmpty(validationError) ||
      invalidateCache.isLoading ||
      isDeleteInProgress
    ) {
      return;
    }
    onSave(code);
  };

  const handleSwitchChange = (event: any) => {
    onValuesChange({
      ...values,
      logCalls: event.target.checked
    });
  };

  const handleDetailsToggle = () => {
    setDetailsExpanded(!detailsExpanded);
  };
  const handleConfiguratiionsToggle = () => {
    setConfigurationExpanded(!configurationExpanded);
  };

  const handleRefresh = () => {
    invalidateCache.mutate({ name, showMessage: true });
  };

  return (
    <Paper className={classes.paper}>
      <Box className={classes.collapsableBox} component="form" sx={{ mt: 1 }}>
        <Box
          className={clsx(classes.header, classes.collapsableHeader)}
          onClick={handleDetailsToggle}>
          <Typography variant="subtitle1">Details</Typography>
          <IconButton size="small">{detailsExpanded ? <ExpandLess /> : <ExpandMore />}</IconButton>
        </Box>
        {detailsExpanded && (
          <Grid className={classes.collapsableContent}>
            <TextField
              fullWidth
              id={PSFields.name.id}
              name={PSFields.name.id}
              label={PSFields.name.label}
              value={name}
              required
              error={error}
              helperText={helperText}
              size="small"
              test-id="prediction-service-name"
              variant="outlined"
              onChange={onInputChange}
            />
            <Grid container direction="column">
              <TextField
                fullWidth
                id={PSFields.description.id}
                label={PSFields.description.label}
                maxRows={4}
                minRows={4}
                value={_.get(values, PSFields.description.id, "")}
                multiline
                name={PSFields.description.id}
                size="small"
                error={has(validationError, PSFields.description.id)}
                helperText={_.get(validationError, PSFields.description.id)}
                test-id="prediction-service-description"
                variant="outlined"
                onChange={onInputChange}
              />
              <span
                style={{
                  fontSize: "12px",
                  fontWeight: 400,
                  fontStyle: "italic",
                  opacity: 0.7
                }}>
                {DescriptionInfo}
              </span>
            </Grid>

            <SelectEnvironment
              name={PSFields.environment.id}
              label={PSFields.environment.label}
              value={_.get(values, PSFields.environment.id, "")}
              tooltipInfoMsg={
                recipeType === MachineLearningTask.TIMESERIES_FORECASTING
                  ? "The packages prophet==1.1.5, statsforecast==1.7.5, mlforecast==0.13.1, and xgboost==2.0.3 must be manually installed in the selected environment for time series prediction if they are not already available"
                  : recipeType === MachineLearningTask.CLUSTERING
                    ? "The Hdbscan package must be manually installed on the chosen environment for clustering prediction, if it is not already present"
                    : undefined
              }
              onChange={onInputChange}
              onEnvChange={onEnvChange}
            />
            <PrePostProcess
              value={_.get(values, PSFields.prePostProcess.id, "")}
              onSubmit={handleAddCode}
            />
          </Grid>
        )}
      </Box>
      <Box className={classes.collapsableBox} component="form" sx={{ mt: 1 }}>
        <Box
          className={clsx(classes.header, classes.collapsableHeader)}
          onClick={handleConfiguratiionsToggle}>
          <Typography variant="subtitle1">Configuration Options</Typography>
          <IconButton size="small">
            {configurationExpanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Box>
        {configurationExpanded && (
          <Grid className={classes.collapsableContent}>
            <Grid container xs={12} alignItems="center" className={classes.horizontalGrid}>
              <Grid xs={6}>
                <TextField
                  fullWidth
                  type="number"
                  id={PSFields.timeoutInMins.id}
                  name={PSFields.timeoutInMins.id}
                  label={PSFields.timeoutInMins.label}
                  value={_.get(values, PSFields.timeoutInMins.id, "")}
                  size="small"
                  test-id="prediction-service-timeout-min"
                  variant="outlined"
                  error={has(validationError, PSFields.timeoutInMins.id)}
                  helperText={_.get(validationError, PSFields.timeoutInMins.id)}
                  onChange={onInputChange}
                />
              </Grid>
              <span>Min</span>
              <Tooltip
                title={
                  "This setting will cause incoming requests to time out if they exceed the specified duration. The valid range for this  is between 1 to 60 minutes."
                }>
                <InfoOutlined color="disabled" />
              </Tooltip>
            </Grid>
            <Grid container alignItems="center" xs={12} className={classes.horizontalGrid}>
              <Grid xs={6}>
                <TextField
                  fullWidth
                  type="number"
                  id={PSFields.concurrency.id}
                  label={PSFields.concurrency.label}
                  value={_.get(values, PSFields.concurrency.id, "")}
                  name={PSFields.concurrency.id}
                  error={has(validationError, PSFields.concurrency.id)}
                  helperText={_.get(validationError, PSFields.concurrency.id)}
                  size="small"
                  test-id="prediction-service-concurrency"
                  variant="outlined"
                  onChange={onInputChange}
                />
              </Grid>
              <Tooltip
                title={
                  "This setting will manage incoming parallel requests by queuing them if they exceed the configured limit. The acceptable range for this setting is between 5 to 100."
                }>
                <InfoOutlined color="disabled" />
              </Tooltip>
            </Grid>
            <Grid container alignItems="center" xs={12} className={classes.horizontalGrid}>
              <Typography>Save History</Typography>
              <Tooltip
                title={
                  "Enabling this flag will log the history of calls made to the prediction service endpoint, which will be displayed in the History Tab"
                }>
                <InfoOutlined style={{ width: "20px", height: "20px" }} />
              </Tooltip>
              <Switch
                checked={_.get(values, PSFields.logCalls.id, true) as boolean}
                onChange={handleSwitchChange}
              />
            </Grid>
          </Grid>
        )}
      </Box>
      <div className={classes.footer}>
        <div className={classes.flex}>
          {id ? (
            <Tooltip
              title={
                invalidateCache.isLoading || loading || isDeleteInProgress ? IN_PROGRESS_MSG : ""
              }>
              <span>
                <Button
                  variant="outlined"
                  size="small"
                  disabled={invalidateCache.isLoading || isDeleteInProgress || loading}
                  className={classes.saveBtn}
                  test-id="prediction-service-delete-btn"
                  onClick={onDelete}>
                  Delete
                </Button>
              </span>
            </Tooltip>
          ) : (
            <span />
          )}
          {id && (
            <Tooltip
              title={
                invalidateCache.isLoading || loading || isDeleteInProgress
                  ? IN_PROGRESS_MSG
                  : "Refreshes the underlying model and ensures the utilisation of the latest model files"
              }>
              <span>
                <Button
                  variant="outlined"
                  size="small"
                  disabled={invalidateCache.isLoading || isDeleteInProgress || loading}
                  startIcon={
                    invalidateCache.isLoading ? (
                      <CircularProgress style={{ color: "grey" }} size={16} />
                    ) : undefined
                  }
                  className={classes.saveBtn}
                  test-id="prediction-service-refresh-btn"
                  onClick={handleRefresh}>
                  Refresh
                </Button>
              </span>
            </Tooltip>
          )}
          <Tooltip
            title={
              invalidateCache.isLoading || loading || isDeleteInProgress ? IN_PROGRESS_MSG : ""
            }>
            <span>
              <Button
                variant="contained"
                color="primary"
                size="small"
                type="submit"
                startIcon={
                  loading ? <CircularProgress style={{ color: "white" }} size={16} /> : undefined
                }
                disabled={disableSave}
                className={classes.saveBtn}
                test-id="prediction-service-save-btn"
                onClick={() => onSave()}>
                {isAutoSaving ? "AutoSaving..." : id ? "Update" : "Save"}
              </Button>
            </span>
          </Tooltip>
        </div>
      </div>
    </Paper>
  );
};

export default PredictionServiceDetails;
