import { Button, ButtonProps, CircularProgress } from "@material-ui/core";
import { startCase } from "lodash";
import React from "react";

import { PublicScreenLabels } from "constants/index";
import { useStyles } from "./SignIn.styles";

interface ISubmitBtnProps extends ButtonProps {
  disabled?: boolean;
  loading?: boolean;
  text?: string;
  onClick?: () => void;
}
export const SignInButton = ({
  disabled,
  loading,
  onClick,
  type = "submit",
  text = startCase(PublicScreenLabels.SignIn)
}: ISubmitBtnProps) => {
  const classes = useStyles();
  return (
    <Button
      data-testid="signInAction"
      type={type}
      variant="contained"
      color="primary"
      size="small"
      fullWidth
      onClick={onClick}
      className={classes.action}
      disabled={disabled}>
      {loading ? (
        <>
          <CircularProgress size={16} style={{ marginRight: 5 }} />
          {text || PublicScreenLabels.SignInLoading}
        </>
      ) : (
        text
      )}
    </Button>
  );
};
