import React, { useMemo, useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import _ from "lodash";
import {
  Grid,
  IconButton,
  Tooltip,
  Popover,
  CircularProgress,
  makeStyles,
  TextField,
  InputAdornment
} from "@material-ui/core";

import ArrowExpandIcon from "icons/NewUX/ArrowExpandIcon";
import { DeleteNew } from "src/assets/icons/DeleteNew";
import { EntityFeaturesResponse } from "src/types/Entity.type";
import { EntityIcon } from "src/assets/icons/EntityIcon";
import { OverflowTooltip } from "src/components";
import { READONLY_ENTITY } from "projectsModule/utils/Projects.constants";
import { FileIcon } from "src/assets/icons/NewUX";
import FilePreviewPopover from "./FilePreviewPopover";
import { VectorDBIcon } from "src/assets/icons/NewUX/VectorDBIcon";
import Table from "src/components/custom/TableV8/Table";

interface IProps {
  dataset: any;
  isDefaultScenario: boolean;
  isJobPath: boolean;
  entityFeaturesMap: {
    [id: string]: EntityFeaturesResponse;
  } | null;
  onDelete: (id: string) => void;
}

export const useStyles = makeStyles({
  entity: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    padding: "4px 6px",
    width: "inherit",
    justifyContent: "space-between",
    borderBottom: "0.5px solid #D1D1D1",
    height: "35px"
  },
  entityText: {
    fontWeight: 400,
    fontSize: "14px",
    width: "180px",
    whiteSpace: "nowrap"
  },
  tableWrap: {
    padding: "1px",
    overflow: "auto",
    maxWidth: "500px"
  },
  flex: {
    display: "flex",
    alignItems: "center",
    gap: "6px"
  },
  header: {
    padding: "10px 26px",
    borderBottom: "0.5px solid #D1D1D1",
    fontWeight: 600,
    maxWidth: "350px"
  },
  search: {
    color: "#7c7c7c",
    minHeight: "34px",
    height: "100%",
    margin: "0px",
    width: "100%",
    borderTop: "1px solid #e1e1e1",
    borderBottom: "1px solid #e1e1e1",
    "& fieldset": {
      border: "none"
    },
    "& div[class*='MuiInputBase-root']": {
      height: "34px",
      width: "100%",
      color: "#7c7c7c"
    },
    "& input[class*='MuiInputBase-input']": {
      height: "34px",
      fontSize: "14px",
      width: "100%"
    }
  }
});

const tableColumns = [
  {
    id: "Column",
    accessorKey: "name",
    header: "Column"
  },
  {
    id: "Datatype",
    accessorKey: "fieldSchema.rcDataType",
    header: "Datatype"
  }
];

const InputDataset: React.FC<IProps> = (props) => {
  const { dataset, entityFeaturesMap, isJobPath, isDefaultScenario, onDelete } = props;
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [value, setValue] = useState("");
  const [hovered, setHovered] = useState(false);

  const data = useMemo(() => {
    if (!entityFeaturesMap?.[dataset.name]) {
      return [];
    }

    return _.filter(entityFeaturesMap?.[dataset.name], ({ name }) =>
      _.includes(_.lowerCase(name), _.lowerCase(value))
    );
  }, [value, entityFeaturesMap]);

  const handleExpand = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDelete = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    onDelete(dataset.id);
    setIsDeleting(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setValue("");
  };

  const handleSearch = (val: string) => {
    setValue(val);
  };

  return (
    <>
      <Grid
        container
        className={classes.entity}
        onMouseEnter={() => {
          setHovered(true);
        }}
        onMouseLeave={() => {
          setHovered(false);
        }}
        onClick={(event) => {
          if (dataset.entityMeta.entityViewType !== "VECTOR_STORE") handleExpand(event);
        }}>
        <div className={classes.flex}>
          {dataset.entityMeta.entityViewType === "FILE" ? (
            <FileIcon width={16} height={16} />
          ) : dataset.entityMeta.entityViewType === "VECTOR_STORE" ? (
            <VectorDBIcon />
          ) : (
            <EntityIcon />
          )}
          <div className={classes.entityText} data-testid="code-recipe-input-dataset-name">
            <OverflowTooltip value={dataset.name} />
          </div>
        </div>
        {hovered && (
          <div style={{ display: "flex" }}>
            {!isJobPath && (
              <Tooltip title={!isDefaultScenario ? READONLY_ENTITY : ""}>
                <div>
                  <IconButton
                    style={{ padding: "8px" }}
                    disabled={!isDefaultScenario || isDeleting}
                    onClick={handleDelete}
                    data-testid="code-recipe-delete-input-dataset-btn">
                    {isDeleting ? <CircularProgress size={16} /> : <DeleteNew size="tiny" />}
                  </IconButton>
                </div>
              </Tooltip>
            )}
            {dataset.entityMeta.entityViewType !== "VECTOR_STORE" && (
              <IconButton style={{ padding: "8px" }} data-testid="expandEntityColumnsIcon">
                <ArrowExpandIcon width={16} height={16} />
              </IconButton>
            )}
          </div>
        )}
      </Grid>
      {dataset.entityMeta.entityViewType === "FILE" ? (
        <FilePreviewPopover anchorEl={anchorEl} handleClose={handleClose} dataset={dataset} />
      ) : (
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left"
          }}
          onClose={handleClose}>
          <div className={classes.header}>
            <OverflowTooltip
              value={dataset.name}
              style={{ whiteSpace: "nowrap" }}
              testId="recipeDatasetName"
            />
          </div>
          <TextField
            onChange={(e) => handleSearch(e.target.value)}
            InputProps={{
              onKeyDown: (e) => e.stopPropagation(),
              startAdornment: (
                <InputAdornment position="start" data-testid="searchEntityIcon">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
              type: "search"
            }}
            data-testid="searchEntityColumn"
            placeholder="Search column"
            className={classes.search}
            id="column-search-input"
            name="column-search-input"
            variant="outlined"
          />
          <Grid container className={classes.tableWrap} data-testid="entityColumnsTable">
            <Table
              data={data}
              columns={tableColumns}
              infoProps={{ hideCount: true }}
              stylesProps={{ hideSettings: true, maxHeight: "calc(100vh - 130px)" }}
              sortingProps={{ unsorted: true }}
            />
          </Grid>
        </Popover>
      )}
    </>
  );
};

export default React.memo(InputDataset);
