import React, { useState, useEffect, useRef } from "react";
import { Grid, Typography, IconButton, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Close } from "@material-ui/icons";
import { isEmpty, delay, last } from "lodash";
import InfoIcon from "@material-ui/icons/Info";

import { CodeSelectionHint } from "./CodeSelectionHint";
import CopilotFooter from "./CopilotFooter";
import CopilotMessages from "./CopilotMessages";

import { useFetchCodeSuggestions } from "src/hooks/api/common/useFetchCodeSuggestions";
import { useDispatch, useSelector } from "react-redux";
import { resetState } from "src/stores/codeRecipeSlice";
import { RootState } from "src/stores/store";
import { ClearHistoryIcon } from "icons/NewUX/ClearHistoryIcon";
import { CommonLoader } from "src/components";
import { CodeSuggestion } from "src/types";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
    height: "100%",
    textAlign: "center"
  },
  dashedBox: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    alignItems: "center",
    justifyContent: "center",
    border: "2px dashed #ccc",
    borderRadius: "15px",
    padding: theme.spacing(4),
    textAlign: "center",
    backgroundColor: "#FBFBFB",
    width: "100%",
    height: "210px",
    color: "#414141"
  },
  inputField: {
    width: "100%"
  },
  header: {
    borderBottom: "1px solid #D3D3D3",
    padding: "12px 16px",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  footer: {
    marginTop: theme.spacing(2),
    width: "100%",
    textAlign: "left",
    padding: `0px 16px 16px 16px`
  },
  chatContainer: {
    flexGrow: 1,
    textAlign: "left",
    minHeight: "calc(100% - 290px)",
    maxHeight: "calc(100% - 125px)"
  },
  emptyContainer: {
    padding: "16px",
    height: "100%",
    width: "100%"
  },
  editorGrid: {
    height: "130px",
    padding: "4px",
    borderColor: "1px",
    borderStyle: "solid",
    borderWidth: "1px 1px 0px 1px",
    borderRadius: "4px 4px 0px 0px"
  },
  closeIcon: {
    height: 21
  },
  actions: {
    gap: "12px",
    width: "fit-content"
  },
  headerTitle: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    textAlign: "center",
    gap: "8px"
  }
}));

interface IProps {
  onClose: () => void;
  selectedCode: string;
  editorValue: string;
  recipeId: string;
  onClearCode: () => void;
  isFetchingThreadMessages: boolean;
  suggestions: CodeSuggestion[];
}

export const CopilotRightBar: React.FC<IProps> = ({
  recipeId,
  selectedCode,
  onClearCode,
  editorValue,
  onClose,
  isFetchingThreadMessages,
  suggestions
}) => {
  const classes = useStyles();

  const [query, setQuery] = useState<string>("");
  const [useFullCode, setUseFullCode] = useState<boolean>(true);
  const [highlightedCode, setHighlightedCode] = useState(selectedCode);
  const [isUserAtBottom, setIsUserAtBottom] = useState(true);

  const inputRef = useRef<HTMLDivElement | null>(null);
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const chatContainerRef = useRef<HTMLDivElement | null>(null);
  const [gptModel, setGptModel] = useState<string>("gpt-4o");

  const fetchCodeSuggestions = useFetchCodeSuggestions();

  const dispatch = useDispatch();
  const threadId = useSelector((state: RootState) => {
    return state.codeRecipe.recipeThreadMap[recipeId];
  });

  const isGenerateBtnDisabled = !query;
  const isSuccess = fetchCodeSuggestions.isSuccess;
  const lastResponse = last(suggestions)?.response;
  const isGenerating = last(suggestions)?.isLoading || false;

  const handleSend = (): void => {
    setHighlightedCode("");
    const queryToSend = query.trim();
    const codeToSend = selectedCode ? selectedCode : useFullCode ? editorValue : "";
    if (!queryToSend) {
      return;
    }

    setQuery("");

    fetchCodeSuggestions.mutate({
      code: codeToSend,
      query: queryToSend,
      threadId,
      recipeId,
      model: gptModel
    });
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "auto" });
  };
  const clearHistory = () => {
    dispatch(resetState({ recipeId }));
  };

  useEffect(() => {
    delay(() => scrollToBottom(), 1000);
  }, [isGenerating]);

  useEffect(() => {
    if (isUserAtBottom && chatContainerRef.current) {
      scrollToBottom();
    }
  }, [lastResponse, isUserAtBottom, isSuccess]);

  useEffect(() => {
    setHighlightedCode(selectedCode);
  }, [selectedCode]);

  useEffect(() => {
    // Focus input field when the component is mounted
    inputRef.current?.focus();
    delay(() => {
      scrollToBottom();
    }, 1000);
  }, []);

  const handleScroll = () => {
    if (!chatContainerRef.current) return;
    const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;
    const isAtBottom = scrollHeight - scrollTop <= clientHeight + 5;
    setIsUserAtBottom(isAtBottom);
  };

  const hasNoSuggestions = isEmpty(suggestions);

  if (isFetchingThreadMessages) {
    return (
      <Grid container direction="column" wrap="nowrap" className={classes.root}>
        <CommonLoader />
      </Grid>
    );
  }
  return (
    <Grid container direction="column" wrap="nowrap" className={classes.root}>
      <Grid container item direction="column" wrap="nowrap" xs className={classes.chatContainer}>
        <Grid container item className={classes.header} wrap="nowrap" direction="row">
          <Grid item className={classes.headerTitle}>
            <Typography variant="body1">Code Assist</Typography>
            {!hasNoSuggestions && (
              <Tooltip arrow title="Select code and use Command+I to copy the code here">
                <InfoIcon fontSize="small" style={{ color: "#9e9e9e" }} />
              </Tooltip>
            )}
          </Grid>
          <Grid item container direction="row" className={classes.actions}>
            <Tooltip
              title={
                isGenerating
                  ? "Please wait until existing query is complete"
                  : hasNoSuggestions
                    ? "No history to clear"
                    : "Clear History"
              }>
              <div>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="primary"
                  disabled={hasNoSuggestions || isGenerating}
                  onClick={() => clearHistory()}>
                  <ClearHistoryIcon />
                </IconButton>
              </div>
            </Tooltip>
            <IconButton size="small" aria-label="close" color="primary" onClick={() => onClose()}>
              <Close className={classes.closeIcon} />
            </IconButton>
          </Grid>
        </Grid>
        {hasNoSuggestions ? (
          <Grid item xs>
            <CodeSelectionHint />
          </Grid>
        ) : (
          <CopilotMessages
            suggestions={suggestions}
            messagesEndRef={messagesEndRef}
            containerRef={chatContainerRef}
            onScroll={handleScroll}
          />
        )}
      </Grid>
      <Grid item className={classes.footer}>
        <CopilotFooter
          inputRef={inputRef}
          selectedCode={selectedCode}
          highlightedCode={highlightedCode}
          isGenerating={isGenerating}
          isDisabled={isGenerateBtnDisabled || isGenerating}
          query={query}
          setQuery={setQuery}
          handleSend={handleSend}
          useFullCode={useFullCode}
          setUseFullCode={setUseFullCode}
          onClearCode={onClearCode}
          setGptModel={setGptModel}
          gptModel={gptModel}
        />
      </Grid>
    </Grid>
  );
};

export default CopilotRightBar;
