import React from "react";
import { isEmpty } from "lodash";
import { Badge, IconButton, makeStyles, Tooltip } from "@material-ui/core";
import { useThreadsStore } from "stores/zustand/stores";
import AIGuidePlainIcon from "icons/NewUX/AIGuidePlainIcon";

type StyleProps = { badgeStyleProps?: any; badgeDotStyleProps?: any };

const useStyles = makeStyles({
  aiGuideIcon: {
    height: "30px",
    width: "30px",
    border: "1px solid #4646b5"
  },
  aiGuideBtn: {
    height: "30.75px",
    width: "30.75px",
    border: "1px solid #4646b5",
    borderRadius: "4px"
  },

  badgeRoot: (props: StyleProps) => ({
    marginTop: 0,
    marginRight: 0,
    ...(!isEmpty(props?.badgeStyleProps) ? props?.badgeStyleProps : {})
  }),

  badge: (props: StyleProps) => ({
    backgroundColor: "#FF0000",
    border: "1px solid #fff",
    ...(!isEmpty(props?.badgeDotStyleProps) ? props?.badgeDotStyleProps : {})
  })
});

export const AIGuideMiniIconButton = ({
  onClick,
  projectId,
  targetId,
  chartId,
  style,
  disabled,
  tooltip,
  badgeStyleProps = {},
  badgeDotStyleProps = {}
}: {
  onClick: (event: any) => void;
  projectId: string;
  targetId: string;
  style?: any;
  width?: number;
  height?: number;
  viewBox?: string;
  disabled?: boolean;
  tooltip?: string;
  chartId?: string;
  badgeStyleProps?: { [key: string]: any };
  badgeDotStyleProps?: { [key: string]: any };
}) => {
  const { aiGuideBtn, badgeRoot, badge } = useStyles({
    badgeStyleProps,
    badgeDotStyleProps
  });

  const { projectTargetMap, chartIdMessagesCountMap } = useThreadsStore();
  const messageCount = chartId
    ? chartIdMessagesCountMap.get(projectId)?.get(targetId)?.get(chartId)
    : projectTargetMap.get(projectId)?.get(targetId);

  return (
    <Tooltip title={tooltip || "AI Guide"}>
      <Badge
        variant="dot"
        color="error"
        classes={{ root: badgeRoot, colorError: badge }}
        invisible={!messageCount || messageCount === 0}
        overlap="rectangular">
        <IconButton
          size="small"
          style={style}
          onClick={onClick}
          disabled={disabled}
          data-testid="sidebar-entity-ai-guide-icon"
          className={aiGuideBtn}>
          <AIGuidePlainIcon />
        </IconButton>
      </Badge>
    </Tooltip>
  );
};
