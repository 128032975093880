import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import React, { useEffect, useMemo, useState } from "react";
import _, { find, isEmpty } from "lodash";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  IconButton,
  Tooltip
} from "@material-ui/core";
import { DeleteOutline } from "@material-ui/icons";
import { useIsMutating, useQueryClient } from "@tanstack/react-query";

import ConnectorDetailsForm from "./ConnectorDetailsForm";
import DeleteSyncedConnectorModal from "./DeleteSyncedConnectorModal";
import NoAvailableConnector from "./NoAvailableConnector";
import useExportEntityConnectors, {
  EXPORT_ENTITY_MUTATION_KEY
} from "hooks/api/entities/useExportEntityConnectors";
import useUpdateEntities from "hooks/api/entities/useUpdateEntities";
import {
  CONNECTOR_KEYS,
  DATASET_DESTINATION_DETAILS_INFO,
  TEXT_INPUT_DESTINATION_DETAILS_INFO,
  EXPORT_IN_PROGRESS_MSG,
  EXPORT_DATASET_MSG,
  EXPORT_TEXT_INPUT_MSG,
  INCORRECT_DATASET_STATUS_MSG,
  INCORRECT_TEXT_INPUT_STATUS_MSG,
  MANDATORY_FIELDS_SAVE_MSG,
  MANDATORY_FIELDS_UPDATE_MSG,
  MAX_LONG_LENGTH,
  NO_CHANGE_MSG,
  UPDATE_DATASET_UNSAVED_CHANGES_MSG,
  UPDATE_TEXT_INPUT_UNSAVED_CHANGES_MSG
} from "../utils/OutputDataset.constants";
import { QUERY_KEY_ENTITY_DETAILS } from "src/hooks/api/entities/useEntityDetails";
import {
  DataConnectorNames,
  dataSourcesTypes
} from "pages/DataSources/utils/DataSources.constants";
import { IEntity } from "hooks/api/entities/useEntities";
import { IThirdPartyDataSourceStatus } from "connectorsModule/utils/fivetran.helpers";
import { useForm } from "hooks/useForm";
import { useGetDataSources } from "hooks/api/dataSources/useGetDataSources";
import { useStyles } from "components/Canvas/CanvasDrawerItems/components/styling";
import { isInvalidConnectionInputs } from "../utils/OutputDataset.helpers";
import CommonLoader from "src/components/CommonLoader";
import { useCanvasStore, useDrawerStore } from "stores/zustand/stores";
import { sideComponentSetter } from "stores/zustand/stores.selectors";
import { JobProps } from "projectsModule/pages/Dag/Dag.types";

export interface IData {
  dataSourceType: string;
  iconUrl: string | null;
  id: string;
  name: string;
  thirdPartyDataSourceStatus: IThirdPartyDataSourceStatus;
  tpConnectorType: string;
}

interface IProps {
  entity: IEntity;
  loading: boolean;
  scenarioId: string;
  disableExport: boolean;
  isDestinationModal?: boolean;
  isTextInput?: boolean;
  jobProps?: JobProps;
}

export interface IConnectorType extends IData {
  image: JSX.Element;
}

const DestinationDetails: React.FC<IProps> = (props) => {
  const {
    entity,
    loading,
    scenarioId,
    disableExport,
    isDestinationModal = false,
    isTextInput = false,
    jobProps
  } = props;
  const setSideComponent = useDrawerStore(sideComponentSetter);

  const [open, setOpen] = useState(false);
  const { destinationCard, flexSpaceBetween, flexContainer, bluebtn } = useStyles();
  const { values, resetForm, setValues, handleInputChange } = useForm({
    [CONNECTOR_KEYS.connectorType]: ""
  });
  const setReloadTrigger = useCanvasStore((state) => state.setReloadTrigger);

  const queryClient = useQueryClient();
  const { data, isLoading } = useGetDataSources({});
  const updateSyncDetails = useUpdateEntities();
  const exportEntityConnectors = useExportEntityConnectors(entity.id, scenarioId, !!isTextInput);

  const isMutating = useIsMutating({
    mutationKey: [EXPORT_ENTITY_MUTATION_KEY, entity.id, scenarioId]
  });

  const initialValues = useMemo(() => {
    let initialValue = null;
    if (entity.syncDataSourceId) {
      initialValue = { [CONNECTOR_KEYS.connectorType]: entity.syncDataSourceId };
    }
    if (entity.syncDataSourceOptions) {
      initialValue = { ...initialValue, ...entity.syncDataSourceOptions };
    }

    return initialValue;
  }, [entity.syncDataSourceId, entity.syncDataSourceOptions]);

  const jobDestinationData = useMemo(
    () =>
      isEmpty(jobProps?.destinationsData)
        ? null
        : find(jobProps?.destinationsData, { entityId: entity?.id }),

    [jobProps?.destinationsData]
  );

  useEffect(() => {
    if (!isEmpty(jobDestinationData)) {
      setValues({
        connector: jobDestinationData?.dataSourceId,
        ...jobDestinationData?.options
      });
    } else {
      if (initialValues) {
        setValues(initialValues);
      }
    }
  }, [jobDestinationData, entity.syncDataSourceId, entity.syncDataSourceOptions]);

  const connectors = useMemo(() => {
    const keyBy = _.keyBy(dataSourcesTypes, "name");
    const connectorList: IConnectorType[] = [];

    const allowedConnectors = !!isTextInput
      ? [
          DataConnectorNames.S3_STORAGE,
          DataConnectorNames.AZURE_BLOB,
          DataConnectorNames.GCP_STORAGE
        ]
      : [
          DataConnectorNames.S3_STORAGE,
          DataConnectorNames.AZURE_BLOB,
          DataConnectorNames.GCP_STORAGE,
          DataConnectorNames.MYSQL,
          DataConnectorNames.REDSHIFT,
          DataConnectorNames.SNOWFLAKE,
          DataConnectorNames.MONGO,
          DataConnectorNames.POSTGRES
        ];

    _.forEach(data, (item: IData) => {
      const dataSourceType = _.get(keyBy, item.dataSourceType);
      if (dataSourceType && _.includes(allowedConnectors, dataSourceType.name)) {
        connectorList.push({ ...item, image: _.get(dataSourceType, "image") });
      }
    });

    return connectorList;
  }, [data, isTextInput]);

  const selectedConnectorType = useMemo(() => {
    const connector = _.get(values, CONNECTOR_KEYS.connectorType);

    return _.find(connectors, { id: connector })?.dataSourceType;
  }, [connectors, values]);

  const disableUpdate = useMemo(() => {
    if (!entity.syncDataSourceId) {
      return false;
    }

    return _.isEqual(initialValues, values);
  }, [initialValues, values]);

  const disableSave = useMemo(() => {
    if (_.isEmpty(_.get(values, CONNECTOR_KEYS.connectorType))) {
      return true;
    }

    switch (selectedConnectorType) {
      case DataConnectorNames.AZURE_BLOB:
      case DataConnectorNames.GCP_STORAGE:
      case DataConnectorNames.S3_STORAGE:
        return (
          isInvalidConnectionInputs(
            _.get(values, CONNECTOR_KEYS.fileCategory.destinationFolder.key)
          ) ||
          isInvalidConnectionInputs(
            _.get(values, CONNECTOR_KEYS.fileCategory.destinationFileName.key)
          )
        );

      case DataConnectorNames.MYSQL:
      case DataConnectorNames.REDSHIFT:
      case DataConnectorNames.POSTGRES:
        return (
          isInvalidConnectionInputs(
            _.get(values, CONNECTOR_KEYS.sqlRedshiftCategory.tableName.key),
            CONNECTOR_KEYS.sqlRedshiftCategory.tableName.regex
          ) || isInvalidConnectionInputs(_.get(values, CONNECTOR_KEYS.sqlRedshiftCategory.type.key))
        );

      case DataConnectorNames.MONGO:
        return (
          isInvalidConnectionInputs(
            _.get(values, CONNECTOR_KEYS.mongoCateogry.collection.key),
            CONNECTOR_KEYS.mongoCateogry.collection.regex
          ) ||
          isInvalidConnectionInputs(
            _.get(values, CONNECTOR_KEYS.mongoCateogry.database.key),
            CONNECTOR_KEYS.mongoCateogry.database.regex
          )
        );

      case DataConnectorNames.SNOWFLAKE:
        return (
          isInvalidConnectionInputs(
            _.get(values, CONNECTOR_KEYS.snowFlakeCategory.databaseName.key),
            CONNECTOR_KEYS.snowFlakeCategory.databaseName.regex
          ) ||
          isInvalidConnectionInputs(
            _.get(values, CONNECTOR_KEYS.snowFlakeCategory.schema.key),
            CONNECTOR_KEYS.snowFlakeCategory.schema.regex
          ) ||
          isInvalidConnectionInputs(
            _.get(values, CONNECTOR_KEYS.snowFlakeCategory.tableName.key),
            CONNECTOR_KEYS.snowFlakeCategory.tableName.regex
          ) ||
          isInvalidConnectionInputs(
            _.get(values, CONNECTOR_KEYS.snowFlakeCategory.warehouse.key),
            undefined,
            MAX_LONG_LENGTH
          ) ||
          isInvalidConnectionInputs(
            _.get(values, CONNECTOR_KEYS.snowFlakeCategory.role.key),
            undefined,
            undefined,
            true
          ) ||
          isInvalidConnectionInputs(_.get(values, CONNECTOR_KEYS.snowFlakeCategory.type.key))
        );

      default:
        return false;
    }
  }, [values, selectedConnectorType]);

  const handleConnectorChange = (
    e: React.ChangeEvent<{
      name: string;
      value: string;
    }>
  ) => {
    const { name, value } = e.target;
    if (name) {
      setValues({ [name]: value });
    }
  };

  const handleDelete = () => {
    setOpen(true);
  };

  const handleSave = () => {
    const syncDataSourceId = _.get(values, CONNECTOR_KEYS.connectorType);
    const syncDataSourceOptions = _.mapValues(
      _.omit(values, CONNECTOR_KEYS.connectorType),
      (value) => _.trim(value)
    );

    if (syncDataSourceId && !_.isEmpty(syncDataSourceOptions)) {
      updateSyncDetails.mutate(
        {
          id: entity.id,
          syncDataSourceId,
          syncDataSourceOptions
        },
        {
          onSuccess: () => {
            setReloadTrigger();
            queryClient.invalidateQueries([QUERY_KEY_ENTITY_DETAILS]);
          }
        }
      );
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleRemove = () => {
    updateSyncDetails.mutate(
      {
        id: entity.id,
        syncDataSourceId: ""
      },
      {
        onSuccess: () => {
          setOpen(false);
          setReloadTrigger();

          resetForm();
          queryClient.invalidateQueries([QUERY_KEY_ENTITY_DETAILS]);
          if (isDestinationModal) {
            setSideComponent({
              sideComponent: null,
              sideComponentProps: null
            });
          }
        }
      }
    );
  };

  const handleExport = () => {
    exportEntityConnectors.mutate({
      id: entity.id,
      scenarioId
    });
  };

  const exportTooltipInfo = useMemo(() => {
    if (!!disableExport) {
      return !!isTextInput ? INCORRECT_TEXT_INPUT_STATUS_MSG : INCORRECT_DATASET_STATUS_MSG;
    }

    if (!!disableUpdate) {
      return !!isTextInput ? EXPORT_TEXT_INPUT_MSG : EXPORT_DATASET_MSG;
    }

    return !!isTextInput
      ? UPDATE_TEXT_INPUT_UNSAVED_CHANGES_MSG
      : UPDATE_DATASET_UNSAVED_CHANGES_MSG;
  }, [disableExport, disableUpdate, isTextInput]);

  const subheader = (
    <div className={flexSpaceBetween}>
      <div className={flexContainer}>
        <span>Destination Details</span>
        {!jobProps && (
          <Tooltip
            title={
              !!isTextInput ? TEXT_INPUT_DESTINATION_DETAILS_INFO : DATASET_DESTINATION_DETAILS_INFO
            }>
            <InfoOutlinedIcon />
          </Tooltip>
        )}
      </div>
      {!jobProps &&
        (entity.syncDataSourceId ? (
          <div className={flexContainer}>
            <Tooltip
              title={
                exportEntityConnectors.isLoading
                  ? EXPORT_IN_PROGRESS_MSG
                  : disableUpdate
                    ? NO_CHANGE_MSG
                    : disableSave
                      ? MANDATORY_FIELDS_UPDATE_MSG
                      : ""
              }>
              <div>
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  className={bluebtn}
                  disabled={
                    disableUpdate ||
                    updateSyncDetails.isLoading ||
                    exportEntityConnectors.isLoading ||
                    disableSave
                  }
                  startIcon={
                    updateSyncDetails.isLoading && !open ? (
                      <CircularProgress size={16} />
                    ) : undefined
                  }
                  onClick={handleSave}>
                  Update
                </Button>
              </div>
            </Tooltip>
            <Tooltip title={exportTooltipInfo}>
              <div>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  className={bluebtn}
                  disabled={
                    !!isMutating ||
                    updateSyncDetails.isLoading ||
                    disableExport ||
                    exportEntityConnectors.isLoading ||
                    !disableUpdate
                  }
                  startIcon={
                    exportEntityConnectors.isLoading || !!isMutating ? (
                      <CircularProgress size={16} />
                    ) : undefined
                  }
                  onClick={handleExport}>
                  Export
                </Button>
              </div>
            </Tooltip>
            <Tooltip title={exportEntityConnectors.isLoading ? EXPORT_IN_PROGRESS_MSG : ""}>
              <div>
                <IconButton
                  disabled={updateSyncDetails.isLoading || exportEntityConnectors.isLoading}
                  onClick={handleDelete}>
                  <DeleteOutline />
                </IconButton>
              </div>
            </Tooltip>
          </div>
        ) : (
          <Tooltip title={disableSave ? MANDATORY_FIELDS_SAVE_MSG : ""}>
            <div>
              <Button
                variant="outlined"
                size="small"
                color="primary"
                className={bluebtn}
                startIcon={updateSyncDetails.isLoading ? <CircularProgress size={16} /> : undefined}
                disabled={disableSave || updateSyncDetails.isLoading}
                onClick={handleSave}>
                Save
              </Button>
            </div>
          </Tooltip>
        ))}
    </div>
  );

  return (
    <>
      <Card className={destinationCard}>
        <CardHeader subheader={subheader} subheaderTypographyProps={{ color: "initial" }} />
        <CardContent>
          {isLoading || loading ? (
            <CommonLoader />
          ) : _.isEmpty(connectors) ? (
            <NoAvailableConnector />
          ) : (
            <ConnectorDetailsForm
              selectedConnectorType={selectedConnectorType}
              connectors={connectors}
              values={values}
              isTextInput={!!isTextInput}
              onChange={handleInputChange}
              onConnectorChange={handleConnectorChange}
              readonly={!!jobProps}
            />
          )}
        </CardContent>
      </Card>
      <DeleteSyncedConnectorModal
        open={open}
        loading={updateSyncDetails.isLoading}
        isTextInput={!!isTextInput}
        onCancel={handleCancel}
        onDelete={handleRemove}
      />
    </>
  );
};

export default DestinationDetails;
