import React from "react";
import { generatePath, Link } from "react-router-dom";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";

import EditName from "layout/NavBars/components/SubTopNavBar/EditName";
import { RenderText } from "components/custom";
import { WebPaths } from "routing/routes";
import { useGetRole } from "hooks/useGetRole";

interface IProps {
  project: { id: string; name: string };
  dataAppName: string;
  onSave: (val: string) => void;
  onShowDataApps: () => void;
  shouldResetDataAppName?: boolean;
}

const FONT_SIZE = 14;

const SubTopNavBarBreadcrumbs: React.FC<IProps> = (props) => {
  const { project, dataAppName, shouldResetDataAppName, onSave, onShowDataApps } = props;
  const { isConsumer, isDataAppPowerUser } = useGetRole();

  const breadcrumbs: React.ReactNode[] = [];

  if (!!project?.id) {
    if (isConsumer || isDataAppPowerUser) {
      breadcrumbs.push(<RenderText key="projects">{project?.name || "Project"}</RenderText>);

      breadcrumbs.push(<RenderText key="dataApps">DataApps</RenderText>);
    } else {
      breadcrumbs.push(
        <Link key="projects" to={`/projects/${project?.id}/canvas`} data-testid="projectBreadcrumb">
          <RenderText>{project?.name || "Project"}</RenderText>
        </Link>
      );

      breadcrumbs.push(
        <Link
          key="dataApps"
          to={generatePath(WebPaths.ProjectDataApp, {
            projectId: project.id,
            dataAppName: ""
          })}
          state={{ type: null }}
          data-testid="dataappBreadcrumb"
          onClick={onShowDataApps}>
          <RenderText>DataApps</RenderText>
        </Link>
      );
    }
  }

  breadcrumbs.push(
    <EditName
      key="edit-name"
      data-testid="dataAppCreateNameBreadCrumb"
      fieldNameLabel="DataApp name"
      inputValue={dataAppName}
      onSave={onSave}
      isResetName={shouldResetDataAppName}
      containerProps={{
        style: {
          width: ((dataAppName || "")?.length + 1) * (FONT_SIZE - 4) + 16
        }
      }}
    />
  );

  return (
    <Breadcrumbs>
      {breadcrumbs?.map((eachBreadcrumb: React.ReactNode) => eachBreadcrumb)}
    </Breadcrumbs>
  );
};

export default SubTopNavBarBreadcrumbs;
