import React from "react";

// Packages
import { includes } from "lodash";

// Components
import { Modal } from "src/components/custom";
import { ModalVariants } from "src/components/custom/Modal/Modal";

// Constants
import {
  ConfirmRunWithReloadDatasetsHelperText,
  ConfirmRunWithReloadDatasetsPromptDetails
} from "../utils";

interface IProps {
  resetConfirmRunWithReloadDatasets: () => void;
  runWithReloadDatasets: () => void;
}

const ConfirmRunWithReloadDatasets: React.FC<IProps> = (props) => {
  const { resetConfirmRunWithReloadDatasets, runWithReloadDatasets } = props;

  return (
    <Modal
      open
      variant={ModalVariants.Delete}
      title={ConfirmRunWithReloadDatasetsPromptDetails.title}
      content={[
        ConfirmRunWithReloadDatasetsPromptDetails.messageLine1,
        ConfirmRunWithReloadDatasetsPromptDetails.messageLine2
      ]}
      onClose={(_, reason: string) => {
        if (!includes(["escapeKeyDown", "backdropClick"], reason)) {
          resetConfirmRunWithReloadDatasets();
        }
      }}
      cancelLabel={ConfirmRunWithReloadDatasetsHelperText.CancelLabel}
      onSubmit={runWithReloadDatasets}
      hideCloseIcon
    />
  );
};

export default ConfirmRunWithReloadDatasets;
