import { Button, Grid, makeStyles } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import CommonLoader from "src/components/CommonLoader";
import SomethingWentWrong from "src/components/Errors/SomethingWentWrong";
import usePredictionServiceByModel from "src/hooks/usePredictionServiceByModel";
import { LogsNewIconV2 } from "src/assets/icons/LogsNewIconV2";
import PredictionService from "src/pages/private/ProjectsModule/pages/PredictionService/components/PredictionService";

interface IProps {
  modelName: string;
}

const useStyles = makeStyles({
  selectedButton: {
    background: "#e7e7ff !important",
    color: "#4646b5 !important"
  }
});

const PredictionServiceTab: React.FC<IProps> = ({ modelName }) => {
  const { scenarioId, jobRunId } = useParams<$TSFixMe>();
  const predictionServiceResults = usePredictionServiceByModel(modelName, scenarioId, jobRunId);
  const [open, setOpen] = useState(false);
  const [openLogsDrawer, setOpenLogsDrawer] = useState(false);
  const classes = useStyles();

  if (predictionServiceResults.isLoading) {
    return <CommonLoader />;
  }
  if (predictionServiceResults.isError) {
    return <SomethingWentWrong heading="Something went wrong!!" />;
  }

  const handleViewChange = (__: React.MouseEvent<HTMLElement>, nextView: boolean) => {
    setOpen(nextView);
  };

  return (
    <Grid container direction="column" style={{ padding: "10px" }}>
      <Grid container alignItems="center" style={{ gap: "5px", padding: "10px 5px" }}>
        <span style={{ marginLeft: "auto" }}>
          <ToggleButtonGroup
            size="small"
            orientation="horizontal"
            value={open}
            exclusive
            onChange={handleViewChange}>
            <ToggleButton
              data-testid="predictoinServiceTestButton"
              classes={{ selected: classes.selectedButton }}
              disabled={!open}
              style={{ padding: "5px", textTransform: "none" }}
              value={false}>
              <span> Test</span>
            </ToggleButton>
            <ToggleButton
              data-testid="predictoinServiceLogButton"
              disabled={open}
              classes={{ selected: classes.selectedButton }}
              style={{ padding: "5px", textTransform: "none" }}
              value={true}>
              <span>History</span>
            </ToggleButton>
          </ToggleButtonGroup>
        </span>
        {predictionServiceResults.data?.predictionServiceDetails?.id && (
          <Button
            size="small"
            style={{ minWidth: "32px", padding: "5px 4px" }}
            variant="outlined"
            onClick={() => setOpenLogsDrawer(true)}>
            <LogsNewIconV2 width="22" height="22" color={"#4646b5"} />
          </Button>
        )}
      </Grid>
      <PredictionService
        modelName={modelName}
        data={predictionServiceResults.data}
        open={open}
        setOpen={setOpen}
        openLogsDrawer={openLogsDrawer}
        setOpenLogsDrawer={setOpenLogsDrawer}
        isRecipeView
      />
    </Grid>
  );
};

export default PredictionServiceTab;
