export enum PredictionJobConstants {
  UntitledName = "Prediction Scheduler",
  ManualName = "Manual Prediction"
}

export enum PredictionJobHelperText {
  PredictionJobNameUpdated = "Prediction Run name updated successfully!"
}

export const UNSUCCESSFUL_PREDICTION_OUTPUT_MSG =
  "Please generate a successful prediction output by running the prediction run";

export const FAILED_LAST_RUN_MSG_ISMANUAL =
  "Please ensure this manual run is successful to enable this action";

export const FAILED_LAST_RUN_MSG = "Please ensure the run is successful to enable this action";
