import React from "react";
import { get, isEmpty, map } from "lodash";
import { makeStyles } from "@material-ui/core";

import SearchWidget from "./Widgets/SearchWidget";
import { SearchRequestDtoSearchComponentsEnum, SearchResultDto } from "@rapidcanvas/rc-api-core";
import { SEARCH_COMPONENTS } from "../../../utils/GlobalSearch.constants";

const useStyles = makeStyles({
  wrapper: { marginBottom: "32px" },
  title: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    fontWeight: 700,
    fontSize: "14px",
    marginBottom: "8px"
  },
  flex: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    marginLeft: "24px"
  },
  icon: {
    color: "#7c7c7c",
    width: "16px"
  }
});

interface IProps {
  searchText: string;
  type: SearchRequestDtoSearchComponentsEnum;
  records: SearchResultDto[];
  onNavigate: () => void;
}

const SearchResponseComponent: React.FC<IProps> = (props) => {
  const { type, records, searchText, onNavigate } = props;
  const classes = useStyles();

  if (isEmpty(records)) {
    return null;
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.title} data-testid="globalSearchRecentTitle">
        <span className={classes.icon}>{get(SEARCH_COMPONENTS, [type, "icon"])}</span>
        <span>{get(SEARCH_COMPONENTS, [type, "label"]) ?? type}</span>
      </div>
      <div className={classes.flex}>
        {map(records, (record) => (
          <SearchWidget
            key={record.id}
            type={type}
            record={record}
            searchText={searchText}
            onNavigate={onNavigate}
          />
        ))}
      </div>
    </div>
  );
};

export default SearchResponseComponent;
