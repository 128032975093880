import _ from "lodash";

import { ContentProps, addToast } from "services/ToastClient/toastWrapper";

class ToastClient {
  activeContents: string[] = [];

  setActiveContent = (content: string): void => {
    this.activeContents = [...this.activeContents, content];
  };

  removeContent = (content: string): void => {
    this.activeContents = _.filter(this.activeContents, content);
  };

  emit = (props: ContentProps) => {
    if (typeof props.content === "string" && _.includes(this.activeContents, props.content)) {
      return;
    }

    if (typeof props.content === "string") {
      this.setActiveContent(props.content);
    }

    return addToast({
      ...props,
      onClose: () => {
        props.onClose?.();
        if (typeof props.content === "string") {
          this.removeContent(props.content);
        }
      }
    });
  };
}

const toastClient = new ToastClient();

export default toastClient;
