import _ from "lodash";
import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

import api from "services/AxiosClient/AxiosClient";
import { DataAppDto } from "@rapidcanvas/rc-api-core";

const QUERY_KEY_DATAAPP_BY_ID = "query-key-dataapp-by-id";

const useDataAppById = (
  dataAppId?: string,
  options?: UseQueryOptions<DataAppDto>
): UseQueryResult<DataAppDto> => {
  return useQuery({
    queryKey: [QUERY_KEY_DATAAPP_BY_ID, dataAppId],
    queryFn: async () => {
      if (!dataAppId) {
        throw "DataAppId is absent";
      }
      const response = await api.fetchResponse(
        async () => await api.DataAppControllerApi.getDataAppsById(dataAppId)
      );

      return response;
    },
    ...options,
    refetchOnMount: true
  });
};

export default useDataAppById;
export { QUERY_KEY_DATAAPP_BY_ID };
