import { makeStyles } from "@material-ui/core";
import React from "react";

import GeneratingAboutContentIcon from "src/assets/icons/GeneratingAboutContentIcon";

export const useStyles = makeStyles({
  flexContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "calc(100vh - 160px)",

    "& div": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "20px",
      color: "#515151",

      "& button": {
        textTransform: "capitalize"
      },

      "& div": {
        fontSize: 20
      },

      "& span": {
        fontSize: 14
      }
    }
  }
});

const GeneratingAboutContent: React.FC = () => {
  const { flexContainer } = useStyles();

  return (
    <div className={flexContainer}>
      <div>
        <GeneratingAboutContentIcon />
        <div>About Page Content is getting Generated</div>
        <span>It might take few minutes, please give us some time to update the content here</span>
      </div>
    </div>
  );
};

export default GeneratingAboutContent;
