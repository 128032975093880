import React from "react";
import { Typography } from "@material-ui/core";
import { isNumber } from "lodash";

type OwnProps = {
  title: string;
  subtitleLines?: string[];
  size?: string | number;
  shouldUseGutterBottom?: boolean;
};

// @ts-expect-error TS(2456) FIXME: Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof TitleSection.defaultProps;

// @ts-expect-error TS(7022) FIXME: 'TitleSection' implicitly has type 'any' because i... Remove this comment to see the full error message
const TitleSection = ({
  title,
  subtitleLines,
  size = "default",
  shouldUseGutterBottom,
  ...rest
}: Props) => (
  <div {...rest}>
    <Typography
      {...(isNumber(size)
        ? { style: { fontWeight: 500, fontSize: size } }
        : size === "small"
          ? { style: { fontWeight: 500 }, variant: "h5" }
          : { variant: "h4" })}
      gutterBottom={shouldUseGutterBottom && !!subtitleLines}>
      {title}
    </Typography>
    <Typography variant="body1">
      {subtitleLines?.reduce((prev: $TSFixMe, next: $TSFixMe) => (
        <>
          {prev}
          <br />
          {next}
        </>
      ))}
    </Typography>
  </div>
);

TitleSection.defaultProps = {
  shouldUseGutterBottom: true
};

export default TitleSection;
