import api from "services/AxiosClient/AxiosClient";
import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { Condition } from "@rapidcanvas/rc-api-core";

interface IVariable {
  groupId: string;
  condition?: Condition;
}

const useUpdateRecipeCondition = (): UseMutationResult<any, unknown, IVariable, unknown> => {
  return useMutation({
    mutationFn: async ({ groupId, condition }) => {
      return await api.fetchResponse(() =>
        api.DfsRunConfigGroupControllerV2Api.updateGroup(groupId, { condition })
      );
    }
  });
};

export default useUpdateRecipeCondition;
