import React from "react";

import { Breadcrumbs, Button, Tooltip, makeStyles } from "@material-ui/core";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";

import { capitalize } from "utils/helpers/string.helpers";
import { ellipses } from "utils/helpers/string.helpers";
import { trimLeadingSlash } from "utils/helpers/string.helpers";

import { DataSourcesHelperText } from "../../utils/DataSources.constants";
import CommonLoader from "src/components/CommonLoader";
import { DataSourceFileTypes } from "src/pages/private/ProjectsModule/pages/Dataset/utils/Dataset.constants";
import { formatBytes } from "utils/helpers";
import { dateFormat } from "utils/helpers/date.helpers";
import Table from "src/components/custom/TableV8/Table";
import { CellContext } from "@tanstack/react-table";

const useStyles = makeStyles((theme) => ({
  breadcrumb: { marginBottom: "0.5rem" },
  breadcrumbPath: { fontSize: "0.875rem", fontWeight: 500 },
  breadcrumbLink: {
    display: "flex",
    minWidth: 0,
    padding: "6px 8px",
    textTransform: "none"
  },
  breadcrumbIcon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20
  },
  filePath: { padding: "6px 8px", "& .icon": { marginRight: 4 } },
  disabled: {
    opacity: 0.5
  }
}));

const Files = (props: $TSFixMe) => {
  const { getFiles, isFilesLoading, files, breadcrumbs, setBreadcrumbs, isObjectContentDisabled } =
    props || {};

  const classes = useStyles();

  const columns = [
    {
      id: "Name",
      accessorKey: "path",
      header: "Name",
      cell: ({ row }: CellContext<any, unknown>) => {
        const { path, type } = row?.original;

        return type === "directory" ? (
          <Button
            color="secondary"
            style={{ background: "transparent" }}
            className={classes.breadcrumbLink}
            onClick={async () => {
              await getFiles({ filePath: path });

              const newPaths = breadcrumbs?.length === 0 ? ["/", path] : [...breadcrumbs, path];
              setBreadcrumbs(newPaths);
            }}
            disabled={isObjectContentDisabled}>
            <FolderOpenIcon className={classes.breadcrumbIcon} />
            {path ?? null}
          </Button>
        ) : (
          <div className={classes.filePath}>
            {isObjectContentDisabled ? (
              <span className={classes.disabled}>
                <DescriptionOutlinedIcon fontSize="small" className="icon" />
                {path ?? null}
              </span>
            ) : (
              <>
                <DescriptionOutlinedIcon fontSize="small" className="icon" />
                {path ?? null}
              </>
            )}
          </div>
        );
      }
    },
    {
      id: "Type",
      accessorKey: "type",
      header: "Type",

      cell: ({ getValue }: CellContext<any, unknown>) => {
        const value = getValue() as string;
        return value ? (
          isObjectContentDisabled ? (
            <span className={classes.disabled}>{capitalize(value)}</span>
          ) : (
            capitalize(value)
          )
        ) : null;
      }
    },
    {
      id: "Size",
      accessorKey: "sizeInBytes",
      header: "Size",

      width: "15%",
      cell: ({ row }: CellContext<any, unknown>) =>
        row?.original?.sizeInBytes !== DataSourceFileTypes.Directory && row?.original?.sizeInBytes
          ? formatBytes(row?.original?.sizeInBytes)
          : "-"
    },
    {
      id: "Last Modified",
      accessorKey: "updatedAt",
      header: "Last Modified",

      width: "20%",
      cell: ({ row }: CellContext<any, unknown>) =>
        row?.original?.updatedAt !== DataSourceFileTypes.Directory && row?.original?.updatedAt
          ? dateFormat(row?.original?.updatedAt, false) ?? ""
          : "-"
    }
  ];

  const updateBreadcrumbs = (index: $TSFixMe) => {
    breadcrumbs?.splice(index + 1);
    setBreadcrumbs(breadcrumbs);
  };

  return (
    <>
      {isFilesLoading ? (
        <CommonLoader />
      ) : (
        <>
          {breadcrumbs?.length > 1 && (
            <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
              {breadcrumbs.map((breadcrumb: $TSFixMe, breadcrumbIndex: $TSFixMe) => {
                const formattedBreadcrumbText = trimLeadingSlash({ text: breadcrumb });

                return breadcrumbIndex === breadcrumbs?.length - 1 ? (
                  <Tooltip
                    key={`breadcrumb_${breadcrumbIndex}`}
                    title={formattedBreadcrumbText?.length > 20 ? formattedBreadcrumbText : ""}>
                    <span className={classes.breadcrumbPath}>
                      {ellipses({
                        text: formattedBreadcrumbText,
                        length: 20
                      })}
                    </span>
                  </Tooltip>
                ) : (
                  <Tooltip
                    key={`breadcrumb_${breadcrumbIndex}`}
                    title={formattedBreadcrumbText?.length > 20 ? formattedBreadcrumbText : ""}>
                    <Button
                      key={`breadcrumb_${breadcrumbIndex}`}
                      color="secondary"
                      className={classes.breadcrumbLink}
                      onClick={() => {
                        updateBreadcrumbs(breadcrumbIndex);
                        getFiles({ filePath: breadcrumb });
                      }}
                      disabled={isObjectContentDisabled}>
                      <FolderOpenIcon
                        className={classes.breadcrumbIcon}
                        style={
                          !formattedBreadcrumbText
                            ? {
                                marginRight: 0
                              }
                            : {}
                        }
                      />
                      {ellipses({
                        text: formattedBreadcrumbText,
                        length: 20
                      })}
                    </Button>
                  </Tooltip>
                );
              })}
            </Breadcrumbs>
          )}

          <Table
            data={files}
            columns={columns}
            sortingProps={{ orderByDefault: "Name" }}
            infoProps={{ emptyTableMessage: DataSourcesHelperText.NoFileFound }}
            stylesProps={{
              size: "small",
              isTheadSticky: true,
              maxHeight: `calc(100vh - ${breadcrumbs?.length > 1 ? 230 + 44 : 230}px)`
            }}
          />
        </>
      )}
    </>
  );
};

export default Files;
