import React from "react";

interface IProps {
  width?: number;
  height?: number;
  viewBox?: string;
  fill?: string;
  color?: string;
}

const CopyIcon: React.FC<IProps> = (props) => {
  const { width = 16, height = 16, viewBox = "0 0 16 16", fill = "none", color = "white" } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.33334 11.3333H2.66667C2.31305 11.3333 1.97391 11.1928 1.72386 10.9428C1.47381 10.6927 1.33334 10.3536 1.33334 9.99999V1.99999C1.33334 1.64637 1.47381 1.30723 1.72386 1.05718C1.97391 0.807132 2.31305 0.666656 2.66667 0.666656H10.6667V1.99999H2.66667V9.99999H7.33334V8.66666L10 10.6667L7.33334 12.6667V11.3333ZM12.6667 14V4.66666H5.33334V8.66666H4V4.66666C4 4.31303 4.14048 3.9739 4.39053 3.72385C4.64058 3.4738 4.97971 3.33332 5.33334 3.33332H12.6667C13.0203 3.33332 13.3594 3.4738 13.6095 3.72385C13.8595 3.9739 14 4.31303 14 4.66666V14C14 14.3536 13.8595 14.6928 13.6095 14.9428C13.3594 15.1928 13.0203 15.3333 12.6667 15.3333H5.33334C4.97971 15.3333 4.64058 15.1928 4.39053 14.9428C4.14048 14.6928 4 14.3536 4 14V12.6667H5.33334V14H12.6667Z"
        fill={color}
      />
    </svg>
  );
};

export default CopyIcon;
