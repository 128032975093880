import React from "react";

import Modal, { ModalVariants } from "src/components/custom/Modal/Modal";

interface IProps {
  open: boolean;
  loading: boolean;
  isTextInput?: boolean;
  onDelete: () => void;
  onCancel: () => void;
}

const DeleteSyncedConnectorModal: React.FC<IProps> = (props) => {
  const { open, loading, isTextInput = false, onCancel, onDelete } = props;

  return (
    <Modal
      open={open}
      variant={ModalVariants.Delete}
      title="Remove"
      content={[
        `This will remove the Destination Details from the ${!!isTextInput ? "Text Output" : "Output Dataset"}`,
        "Are you sure?"
      ]}
      submitLabel="Yes, Remove"
      onClose={onCancel}
      onSubmit={onDelete}
      isCancelDisabled={loading}
      isSubmitDisabled={loading}
      isSubmitting={loading}
    />
  );
};

export default DeleteSyncedConnectorModal;
