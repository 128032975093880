import _ from "lodash";
import React from "react";

interface IChatbotPreviewProps {
  formData: { string: string };
}
const getNestedProperties = (obj: any) => {
  return _.merge({}, ...Object.values(obj).map((value) => (_.isObject(value) ? value : {})));
};

const ChatbotPreview: React.FC<IChatbotPreviewProps> = ({ formData }) => {
  const flattenedObject = getNestedProperties(formData);
  const scriptAttributes = Object.entries(flattenedObject)
    .map(([key, value]) => `${key}="${value}"`)
    .join(" ");

  const chatbotHtml = `
    <!DOCTYPE html>
    <html lang="en">
      <head>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta name="description" content="Web site created using create-react-app" />
        <title>Chatbot Plugin Test</title>
        <script id="rc-chatbot" src="https://storage.googleapis.com/rc-public-assets/fe/chatbot/testing/chatbot-plugin.js" ${scriptAttributes}>
        </script>
      </head>
      <body>
        <noscript>You need to enable JavaScript to run this app.</noscript>
        <h2>Chatbot Live Preview</h2>
        <div id="root"></div>
      </body>
    </html>
  `;

  return (
    <iframe
      title="Chatbot Preview"
      srcDoc={chatbotHtml}
      width="100%"
      height={window.innerHeight - 94}
      style={{ border: "solid #9c9d9e", borderWidth: "0px 0px 0px 1px" }}></iframe>
  );
};

export default ChatbotPreview;
