import React, { useState, useMemo, useCallback, useEffect } from "react";
import { Button, CircularProgress, Grid, Tooltip, Typography } from "@material-ui/core";
import { useNavigate, useParams, useLocation, useSearchParams } from "react-router-dom";

// Icons
import { TimeoutIcon } from "icons/NewUX/TimeoutIcon";
import { TrashIcon } from "icons/NewUX/TrashIcon";
import { AddRunIcon } from "icons/NewUX";

import { useCanvasStore } from "stores/zustand/stores";
import { handleResponse } from "services/Apis/Apis.service";
import { validateNameField } from "utils/helpers/form.helpers";
import { useDrawerStore } from "stores/zustand/stores";
import { sideComponentSetter } from "stores/zustand/stores.selectors";
import DeleteNodeModal, { psMessage } from "src/pages/ViewData/DeleteNodeModal/DeleteNodeModal";
import { RecipeConditions } from "../../common/RecipeConditions/RecipeConditions";
import { useCodeRecipeContext } from "../CodeRecipeContext/useCodeRecipeContext";
import { TimeoutTextField } from "../../common/TimeoutTextField/TimeoutTextField";
import { READONLY_ENTITY } from "projectsModule/utils/Projects.constants";
import { useAccessControlContext } from "src/routing/PrivateRoute/accessControlContext/useAccessControlContext";
import { openLogsModal } from "src/layout/NavBars/components/TopNavBar/TopNavBarNotifications/TopNavBarNotifications.constants";

// Components
import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import SubTopNavBarBreadcrumbs from "./SubTopNavBarBreadcrumbs";

// Context
import { useProjectContext } from "src/pages/private/ProjectsModule/context/useProjectContext";
import RecipeLogsDrawer from "../../common/ShowLogsModal/RecipeLogsDrawer";
import { useDeleteRecipe } from "src/hooks/api/transforms/useDeleteRecipe";
import { toastWrapper } from "services/ToastClient/toastWrapper";
import { useQueryClient } from "@tanstack/react-query";
import { QUERY_KEY_RECIPE, UseGetProjectCanvasQueryKeys } from "src/hooks/api";
import { Spinner } from "src/components";
import StopCircleOutlined from "src/assets/icons/StopCircleOutlined";
import useStopRecipe from "src/pages/private/ProjectsModule/pages/Dag/hooks/useStopRecipe";
import Modal, { ModalVariants } from "src/components/custom/Modal/Modal";
import NewThemeWrapper from "src/styles/NewThemeWrapper";
import CodeRecipeLogsButton from "./RecipeLogsButton";
import { TestRecipeDropdown } from "../CodeRecipeTabContainer/TestRecipeDropdown/TestRecipeDropdown";
import { InfoOutlined } from "@material-ui/icons";
import { RecipeStatus } from "pages/Projects/common/RecipeStatus/RecipeStatus";
import { PlayIcon } from "icons/NewUX/PlayIcon";

export const CodeRecipeHeader = ({ isDefaultScenario }: { isDefaultScenario: boolean }) => {
  const navigate = useNavigate();
  const { projectId, scenarioId, jobId, jobRunId } = useParams<$TSFixMe>();
  const [isDeleteRecipeSuccess, setIsDeleteRecipeSuccess] = useState(false);

  const location = useLocation();
  const isJobPath = useMemo(() => /jobs/.test(location.pathname), [location.pathname]);

  // Project context
  const { project } = useProjectContext() || {};

  const deleteRecipeMutation = useDeleteRecipe();

  const [setReloadTrigger] = useCanvasStore((state) => [state.setReloadTrigger]);

  const [isRecipeUpdateInProgress, setIsRecipeUpdateInProgress] = React.useState(false);
  const {
    jobData,
    jobRunData,
    isAddRecipeToQueue,
    isAddingRecipeToQueue,
    isRunDisabled,
    runTooltip,
    handleUpdateRecipe,
    openLogsType,
    setOpenLogsType,
    handleUpdateRecipeName,
    handleTest,
    isTestDisabled,
    isTestInProgress,
    testTooltip,
    handleUpdateRecipeTimeout,
    isRunInProgress,
    recipe,
    scenarioData,
    handleRun,
    isDeletableRecipe,
    handleUpdateRecipeCondition,
    handleStopTesting,
    isTestSaving,
    recipeRunInQueue
  } = useCodeRecipeContext();

  const [shouldResetName, setShouldResetName] = useState<number | boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const { canAccessRecipeTimeout, canViewCodeRecipeConditions } = useAccessControlContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const isOpenLogs = searchParams.get(openLogsModal);
  const [stopRecipeModalOpen, setStopRecipeModalOpen] = useState(false);
  const stopRecipe = useStopRecipe();
  const setReloadTriggerWithDelay = useCanvasStore((state) => state.setReloadTriggerWithDelay);

  const setSideComponent = useDrawerStore(sideComponentSetter);
  const nodes = useCanvasStore((state) => state.nodes);

  const dfsGroupNodes = useMemo(() => {
    return nodes?.filter(
      (node: $TSFixMe) => (node as $TSFixMe)?.type?.toLowerCase() === "dfsgroup"
    );
  }, [nodes]);

  const handleLogsClick = (isRunRecipeLogs: boolean) => {
    setOpenLogsType({ isRunRecipeLogs });
  };

  const handleCancel = () => {
    setStopRecipeModalOpen(false);
  };

  useEffect(() => {
    if (isOpenLogs) {
      handleLogsClick(true);
    }
  }, [isOpenLogs]);

  const validateName = useCallback(
    (name: string) => {
      const nameExist = dfsGroupNodes.some(
        (item: $TSFixMe) => item.name === name || item.displayName === name
      );
      const { error } = validateNameField({
        fieldName: name,
        fieldNameLabel: `recipe name`
      });
      return { nameExist, error };
    },
    [dfsGroupNodes]
  );

  const updateTransformName = useCallback(
    async (name: string) => {
      const cleanedName = name?.trim();
      const { error, nameExist } = validateName(cleanedName);

      if (error) {
        handleResponse({ errorMessage: error });
        setShouldResetName(Date.now());
      } else if (nameExist) {
        handleResponse({ errorMessage: "Recipe name already exists" });
        setShouldResetName(Date.now());
      } else if (recipe?.id) {
        handleUpdateRecipeName({
          payload: { displayName: cleanedName },
          onError: () => setShouldResetName(Date.now()),
          onSuccess: () =>
            toastWrapper({ type: "success", content: "Recipe Name updated Successfully" })
        });
      }
    },
    [handleUpdateRecipe, recipe?.id, validateName]
  );

  const handleSaveConditions = async (updatedConditions: $TSFixMe) => {
    await handleUpdateRecipeCondition({
      payload: {
        condition: {
          expression: updatedConditions
        }
      }
    });
  };

  const openConditionsSideBar = () => {
    const onClose = () => {
      setSideComponent({
        sideComponent: null,
        sideComponentProps: null
      });
    };
    setSideComponent({
      sideComponent: RecipeConditions,
      sideComponentProps: {
        isJobPath,
        jobId,
        jobRunId,
        recipe,
        conditions: recipe?.condition?.expression,
        projectId,
        scenarioId,
        setConditions: handleSaveConditions,
        title: "Conditions",
        onClose,
        onSubmitSuccess: () => {
          handleResponse({
            successMessage: `Conditions updated successfully.`
          });
          onClose();
        },
        isDefaultScenario
      }
    });
  };

  const handleOpenDeleteModal = () => setIsDeleteModalOpen(true);
  const handleCloseDeleteModal = () => setIsDeleteModalOpen(false);

  const handleBack = useCallback(() => {
    navigate(-1);
  }, [projectId, scenarioId]);

  const onUpdateRecipeRunTimeout = (timeout: number) => {
    setIsRecipeUpdateInProgress(true);
    handleUpdateRecipeTimeout({
      payload: { timeout },
      onSuccess: () => {
        setIsRecipeUpdateInProgress(false);
        handleResponse({ successMessage: "Recipe Run Timeout updated successfully." });
      },
      onError: () => setIsRecipeUpdateInProgress(false)
    });
  };

  const handleCloseLogs = () => {
    setOpenLogsType(null);
    setSearchParams({});
  };

  const queryClient = useQueryClient();
  const handleRecipeDelete = ({ onSuccess }: { onSuccess?: () => void } = {}) => {
    isDeletableRecipe &&
      projectId &&
      recipe?.id &&
      deleteRecipeMutation.mutate(
        { projectId, recipeId: recipe?.id },
        {
          onSuccess: () => {
            setReloadTrigger();
            queryClient.invalidateQueries({
              queryKey: [UseGetProjectCanvasQueryKeys.ProjectCanvas, projectId, scenarioId],
              refetchType: "all"
            });
            onSuccess?.();
          }
        }
      );
  };

  const stopRunningRecipe = () => {
    if (recipe?.id) {
      stopRecipe.mutate(
        { groupId: recipe?.id, scenarioId },
        {
          onSuccess: () => {
            setStopRecipeModalOpen(false);
            toastWrapper({ type: "info", content: "Recipe stop action is completed" });
            setReloadTriggerWithDelay();
            queryClient.invalidateQueries([QUERY_KEY_RECIPE]);
          }
        }
      );
    }
  };

  const onSaveSuccess = () => {
    navigate(`/projects/${projectId}/canvas`);
  };

  const onBack = () => {
    if (isDeletableRecipe && !isDeleteRecipeSuccess) {
      handleRecipeDelete();
    }
    window.onpopstate = null;
  };

  useEffect(() => {
    window.onpopstate = (e) => {
      onProjectClick(e);
    };
  });

  const onProjectClick = (event: any) => {
    if (isDeletableRecipe && !isDeleteRecipeSuccess) {
      event.preventDefault();
      handleRecipeDelete({
        onSuccess: onSaveSuccess
      });
    }
    window.onpopstate = null;
  };

  const onDeleteSuccess = () => {
    setIsDeleteRecipeSuccess(true);
    handleBack();
  };

  return (
    <>
      <DeleteNodeModal
        open={isDeleteModalOpen}
        onClose={handleCloseDeleteModal}
        onDeleteSuccess={onDeleteSuccess}
        nodeId={recipe?.id}
        nodeName={recipe?.displayName}
        nodeType="group"
        deleteNote={
          <div>
            <span>Note: </span>
            {psMessage}
          </div>
        }
      />
      {!!stopRecipeModalOpen && (
        <NewThemeWrapper>
          <Modal
            open
            variant={ModalVariants.Delete}
            title={
              <Grid direction="column" container>
                <Typography>Stop Recipe Run</Typography>
                <span style={{ fontSize: "12px", fontStyle: "italic", color: "grey" }}>
                  This process may take some time to get completed.
                </span>
              </Grid>
            }
            content={[
              "This action updates the recipe status to Unbuilt, shuts down the related project environment, and restarts it. This may cause any other recipes depending on this environment to fail.",
              <>
                <b>Note:</b> If recipe execution gets completed before associated environment
                restart then the status of the recipe will be based on the run result.
              </>
            ]}
            submitLabel="Yes, Proceed"
            onClose={handleCancel}
            isSubmitting={stopRecipe.isLoading}
            onSubmit={stopRunningRecipe}
          />
        </NewThemeWrapper>
      )}
      {!!openLogsType && (
        <RecipeLogsDrawer
          isRecipeRunning={isRunInProgress}
          name={recipe?.displayName ?? ""}
          isTesting={isTestInProgress}
          open={!!openLogsType}
          projectId={projectId}
          isRunRecipeLogs={openLogsType.isRunRecipeLogs}
          scenarioId={scenarioId}
          jobRunId={jobRunId}
          groupId={recipe?.id!}
          isJobPath={isJobPath}
          onClose={handleCloseLogs}
        />
      )}

      <SubTopNavBarWrapper
        subTopNavBarLeftSection={{
          backNavAction: onBack,
          component: (
            <SubTopNavBarBreadcrumbs
              project={project}
              isJobPath={isJobPath}
              onProjectClick={onProjectClick}
              jobData={jobData}
              jobRunId={jobRunId}
              jobRunName={jobRunData?.entryDto?.runId}
              isDefaultScenario={isDefaultScenario}
              scenarioName={scenarioData?.name}
              recipeName={recipe?.displayName || "Recipe"}
              updateRecipeName={updateTransformName}
              shouldResetRecipeName={shouldResetName}
            />
          )
        }}
        subTopNavBarRightSection={{
          ...(!isJobPath
            ? {
                moreOptions: [
                  {
                    label: "Delete",
                    icon: <TrashIcon viewBox="0 0 20 20" />,
                    testId: "deleteRecipeBtn",
                    action: () => (recipe?.id ? handleOpenDeleteModal() : handleBack()),
                    isDisabled: !recipe?.id || !isDefaultScenario,
                    ...(!isDefaultScenario ? { tooltip: READONLY_ENTITY } : {})
                  },
                  ...(canAccessRecipeTimeout
                    ? [
                        {
                          label: `Timeout ${recipe?.timeout || 0}hr`,
                          icon: <TimeoutIcon viewBox="0 0 20 22" />,
                          disabled: !recipe?.id,
                          testId: "timeoutIconBtn",
                          nestedComponent: (
                            <TimeoutTextField
                              value={recipe?.timeout || ""}
                              minValue={1}
                              onChange={onUpdateRecipeRunTimeout}
                              disabled={isRecipeUpdateInProgress}
                            />
                          )
                        }
                      ]
                    : [])
                ]
              }
            : {}),
          component: (
            <>
              {canViewCodeRecipeConditions && (
                <Button
                  size="small"
                  color="primary"
                  variant="outlined"
                  onClick={openConditionsSideBar}
                  data-testid="recipeConditions">
                  Conditions
                </Button>
              )}
              {!isJobPath && (
                <Tooltip title={testTooltip || ""}>
                  <span>
                    <TestRecipeDropdown
                      onTest={() => {
                        handleTest();
                        handleLogsClick(false);
                      }}
                      isTestDisabled={isTestDisabled}
                      isTestInProgress={isTestInProgress}
                    />
                  </span>
                </Tooltip>
              )}
              {!isTestSaving && isTestInProgress && (
                <Tooltip title="Stop the ongoing recipe testing process">
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    onClick={handleStopTesting}
                    style={{ textTransform: "none" }}
                    startIcon={<StopCircleOutlined />}>
                    Stop
                  </Button>
                </Tooltip>
              )}
              {!isJobPath && (
                <>
                  <Tooltip
                    title={
                      runTooltip ||
                      "Please remember to save your changes. Unsaved modifications won't be considered in the recipe run"
                    }>
                    <span>
                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={handleRun}
                        data-testid="runRecipe"
                        disabled={isRunDisabled}
                        style={{ textTransform: "none" }}
                        startIcon={isAddRecipeToQueue ? <AddRunIcon /> : <PlayIcon />}>
                        {isAddRecipeToQueue ? (
                          <Typography noWrap variant="inherit">
                            Add to Queue
                          </Typography>
                        ) : (
                          "Run"
                        )}
                        {isAddingRecipeToQueue || isRunInProgress ? (
                          <Spinner size={16} padding="0px 8px" />
                        ) : (
                          <Tooltip title={"Shortcut:  Cmd + R / Ctrl + R to Run"}>
                            <InfoOutlined
                              style={{
                                margin: "0px 8px",
                                color: isRunDisabled ? undefined : "#fff",
                                fontSize: "16px"
                              }}
                            />
                          </Tooltip>
                        )}
                      </Button>
                    </span>
                  </Tooltip>
                  {isRunInProgress && (
                    <Tooltip
                      title={
                        stopRecipe.isLoading
                          ? "Stop Recipe Run is in Progress..."
                          : "Stop Recipe Run"
                      }>
                      <span>
                        <Button
                          variant="outlined"
                          size="small"
                          color="primary"
                          data-testid="stopRecipeRun"
                          onClick={() => setStopRecipeModalOpen(true)}
                          style={{ textTransform: "none" }}
                          disabled={stopRecipe.isLoading}
                          startIcon={
                            stopRecipe.isLoading ? (
                              <CircularProgress size={18} />
                            ) : (
                              <StopCircleOutlined />
                            )
                          }>
                          Stop
                        </Button>
                      </span>
                    </Tooltip>
                  )}
                </>
              )}
              <CodeRecipeLogsButton
                id={recipe?.id}
                hideTest={isJobPath}
                isRunning={isRunInProgress}
                isTesting={isTestInProgress}
                onLogsClick={handleLogsClick}
              />
            </>
          ),
          rightComponent: recipe ? (
            <RecipeStatus
              status={recipe?.status}
              isRunInProgress={isRunInProgress}
              recipeRunInQueue={recipeRunInQueue}
            />
          ) : (
            <React.Fragment />
          )
        }}
      />
    </>
  );
};
