import { get } from "lodash";
import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

import api from "services/AxiosClient/AxiosClient";
import { JobRunStatuses } from "pages/private/ProjectsModule/pages/Jobs/utils/Jobs.constants";
import {
  getFailedRecipeName,
  getPredictionJobOutputpathAndName
} from "pages/private/ProjectsModule/pages/PredictionJob/utils/PredictionJob.helpers";
import { handleResponse } from "services/Apis/Apis.service";
import { FindProjectRunsJobTypeEnum, Row } from "@rapidcanvas/rc-api-core";

const QUERY_KEY_RUN_DETAILS = "query-key-run-details";

export interface IProjectReturn {
  id: string;
  name: string;
  projectId: string;
  scenarioId: string;
  scheduleInfo?: object;
  schedule: string;
  modelEntityId: string;
  modelEntityName?: string;
  status: string;
  jobType: FindProjectRunsJobTypeEnum;
  created: number;
  updated: number;
  creator: string;
  updater: string;
  variables: Record<string, string>;
  computedVariables: Record<string, string>;
  timeoutInHours: number;
}
export interface IReturn {
  predictionJobName: string;
  modelEntityId: string;
  modelEntityName?: string;
  data?: IData;
  lastRunEntryId: string;
  runDetails: IProjectReturn;
  path?: string;
  status: JobRunStatuses;
  recipeId?: string;
  recipeName?: string;
  outputEntityName?: string;
  errorMsg?: string;
}

export interface IData {
  columns: string[];
  rows: Row[];
}

const useRunDetails = (
  projectRunId?: string,
  options?: UseQueryOptions<IReturn>
): UseQueryResult<IReturn> => {
  return useQuery<any>({
    queryKey: [QUERY_KEY_RUN_DETAILS, projectRunId],
    queryFn: async () => {
      if (!projectRunId) {
        throw "project Run Id is absent";
      }

      const runDetails = await api.fetchResponse(
        async () => await api.ProjectRunControllerApi.findProjectRunById(projectRunId)
      );
      const detailsResponse = await api.fetchResponse(
        async () => await api.ProjectRunControllerApi.findProjectRunDetailsById(projectRunId)
      );

      const lastRunEntryId = get(detailsResponse, ["lastRunEntry", "id"]);
      const status = get(detailsResponse, ["lastRunEntry", "status"]);
      const recipeName = getFailedRecipeName(detailsResponse);
      const recipeId = recipeName
        ? get(detailsResponse, ["lastRunEntry", "recipeNameToId", recipeName])
        : "";

      const errorMsg = get(detailsResponse, ["lastRunEntry", "error"]);

      if (
        !(status === JobRunStatuses.Success || status === JobRunStatuses.SuccessWithWarn) ||
        !lastRunEntryId
      ) {
        return {
          predictionJobName: runDetails?.name,
          modelEntityId: runDetails?.modelEntityId,
          modelEntityName: runDetails?.modelEntityName,
          lastRunEntryId,
          runDetails,
          data: { columns: [], rows: [] },
          path: "",
          status,
          recipeId,
          recipeName,
          outputEntityName: "",
          errorMsg: recipeId ? "" : errorMsg
        };
      }

      const { path, name } = getPredictionJobOutputpathAndName(detailsResponse?.lastRunEntry);

      if (!path) {
        handleResponse({ errorMessage: "Unable to generate prediction output" });

        return {
          predictionJobName: runDetails?.name,
          modelEntityId: runDetails?.modelEntityId,
          modelEntityName: runDetails?.modelEntityName,
          lastRunEntryId,
          runDetails,
          path,
          status: JobRunStatuses.Failure,
          recipeId,
          recipeName,
          outputEntityName: name,
          errorMsg: recipeId ? "" : errorMsg
        };
      }

      const outputResponse = await api.fetchResponse(
        async () =>
          await api.EntityControllerApi.getEntityData(lastRunEntryId, {
            projectRunEntryId: lastRunEntryId,
            runEntityName: name,
            rowsEnd: 500,
            rowsStart: 0
          })
      );

      return {
        data: {
          columns: outputResponse?.data?.columns ?? [],
          rows: outputResponse?.data?.rows ?? []
        },
        predictionJobName: runDetails?.name,
        modelEntityId: runDetails?.modelEntityId,
        modelEntityName: runDetails?.modelEntityName,
        lastRunEntryId,
        runDetails,
        path,
        status,
        recipeId,
        recipeName,
        outputEntityName: name
      };
    },
    ...options,
    refetchOnMount: true
  });
};

export default useRunDetails;
export { QUERY_KEY_RUN_DETAILS };
