import React from "react";
import { CircularProgress, Chip, makeStyles } from "@material-ui/core";
import {
  DFSRunConfigGroupDtoStatusEnum,
  RecipeRunData,
  RecipeRunDataStatusEnum
} from "@rapidcanvas/rc-api-core";
import { capitalize } from "utils/helpers/string.helpers";

const useStyles = makeStyles({
  built: { background: "#E8F5E9", color: "#1B5E20" },
  running: { background: "#E3F2FD", color: "#0D47A1" },
  stopped: { background: "#FFEBEE", color: "#4E0F0F" },
  error: { background: "#FFCDD2", color: "#B71C1C" },
  pending: { background: "#FFF3E0", color: "#E65100" },
  success: { background: "#E8F5E9", color: "#1B5E20" },
  skipped: { background: "#F3E5F5", color: "#4A148C" },
  building: { background: "#E3F2FD", color: "#0D47A1" },
  unbuilt: { background: "#E0E0E0", color: "#424242" },
  default: { background: "#F5F5F5", color: "#616161" }
});

export const RecipeStatus = ({
  status,
  isRunInProgress,
  recipeRunInQueue
}: {
  status: DFSRunConfigGroupDtoStatusEnum;
  isRunInProgress: boolean;
  recipeRunInQueue?: RecipeRunData | undefined;
}) => {
  const classes = useStyles();

  const isRunningRecipe = recipeRunInQueue
    ? recipeRunInQueue?.status === RecipeRunDataStatusEnum.Running
    : status === DFSRunConfigGroupDtoStatusEnum.Running || isRunInProgress;
  const updatedStatus = isRunningRecipe ? DFSRunConfigGroupDtoStatusEnum.Running : status;
  const statusLabel = capitalize(updatedStatus?.replace("_", " "));

  const statusClassMap: Record<string, string> = {
    [DFSRunConfigGroupDtoStatusEnum.Built]: classes.built,
    [DFSRunConfigGroupDtoStatusEnum.Running]: classes.running,
    [DFSRunConfigGroupDtoStatusEnum.Stopped]: classes.stopped,
    [DFSRunConfigGroupDtoStatusEnum.Error]: classes.error,
    [DFSRunConfigGroupDtoStatusEnum.Pending]: classes.pending,
    [DFSRunConfigGroupDtoStatusEnum.Success]: classes.success,
    [DFSRunConfigGroupDtoStatusEnum.Skipped]: classes.skipped,
    [DFSRunConfigGroupDtoStatusEnum.Building]: classes.building,
    [DFSRunConfigGroupDtoStatusEnum.Unbuilt]: classes.unbuilt
  };

  return status ? (
    <Chip
      icon={isRunningRecipe ? <CircularProgress size={16} color="inherit" /> : undefined}
      label={`Status: ${statusLabel}`}
      className={statusClassMap[updatedStatus] || classes.default}
    />
  ) : (
    <React.Fragment />
  );
};
