/* eslint-disable no-unused-vars */
import { useQuery } from "@tanstack/react-query";
import { getEntitiesByProjectIdWithRethrow } from "services/Apis/wrappers";
import { Entities } from "src/types";

export const INPUT_ENTITIES_QUERY_KEY = "input-entities-query-key";
export const useGetInputEntities = ({
  id,
  scenarioId,
  jobRunId,
  onSuccess,
  datasetId
}: {
  id?: string;
  scenarioId?: string;
  jobRunId?: string;
  onSuccess?: (data: Entities) => void;
  datasetId?: string;
}) => {
  return useQuery<Entities>({
    queryKey: [INPUT_ENTITIES_QUERY_KEY, id, scenarioId, jobRunId, datasetId],
    queryFn: () => {
      if (!id) {
        throw "id is absent";
      }

      return getEntitiesByProjectIdWithRethrow(id, scenarioId, jobRunId, datasetId);
    },
    onSuccess,
    cacheTime: 0,
    staleTime: 0
  });
};
