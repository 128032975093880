import { useDatasetStore } from "stores/zustand/stores";

const useStoreSelectors = () => {
  // Stores - STARTS >>
  const [
    // Default dataset ID
    datasetDefaultDatasetStore,
    setDatasetDefaultDatasetStore,

    // Existing datasets
    datasetExistingDatasetsStore,
    setDatasetExistingDatasetsStore,

    // Criteria
    datasetCriteriaStore,
    setDatasetCriteriaStore,

    // Is selection section open?
    datasetIsSelectionSectionOpenStore,
    setDatasetIsSelectionSectionOpenStore,

    // Is selection overlay open?
    datasetIsSelectionOverlayOpenStore,
    setDatasetIsSelectionOverlayOpenStore,

    // Project
    datasetProjectStore,
    setDatasetProjectStore,

    // Source
    datasetSourceStore,
    setDatasetSourceStore,

    // Criterion
    datasetCriterionStore,
    setDatasetCriterionStore,

    shouldResetDataset,
    setShouldResetDataset,

    // Files
    datasetFilesStore,
    setDatasetFilesStore,

    // Selected data source files
    datasetSelectedDataSourceFilesStore,
    setDatasetSelectedDataSourceFilesStore,

    //datasource file breadcrumb
    dataSourceFileBreadCrumb,
    setDataSourceFileBreadCrumb,

    // Datasets
    datasetDatasetsStore,
    setDatasetDatasetsStore,

    // Is deleting entities?
    datasetIsDeletingEntitiesStore,
    setDatasetIsDeletingEntitiesStore,

    // Is ontology?
    datasetIsOntologyStore,
    setDatasetIsOntologyStore,

    // Is fetching ontology data?
    datasetIsFetchingOntologyDataStore,
    setDatasetIsFetchingOntologyDataStore,

    // Is fetching ontology schema data?
    datasetIsFetchingOntologySchemaData,
    setDatasetIsFetchingOntologySchemaData,

    // Watch ontology schema set
    datasetWatchOntologySchemaSetStore,
    setDatasetWatchOntologySchemaSetStore,

    // Footer actions >>
    datasetFooterActionsStore,
    setDatasetFooterActionsStore,
    // << Footer actions

    // Reset >>
    resetDatasetStore,
    resetDatasetModalStore,

    datasetIsResettingStore,
    setDatasetIsResettingStore
    // << Reset
  ] = useDatasetStore((state: $TSFixMe) => [
    // Default dataset ID
    state.defaultDataset,
    state.setDefaultDataset,

    // Existing datasets
    state.existingDatasets,
    state.setExistingDatasets,

    // Criteria
    state.criteria,
    state.setCriteria,

    // Is selection section open?
    state.isSelectionSectionOpen,
    state.setIsSelectionSectionOpen,

    // Is selection overlay open?
    state.isSelectionOverlayOpen,
    state.setIsSelectionOverlayOpen,

    // Project
    state.project,
    state.setProject,

    // Source
    state.source,
    state.setSource,

    // Criterion
    state.criterion,
    state.setCriterion,

    state.shouldResetDataset,
    state.setShouldResetDataset,

    // Files
    state.files,
    state.setFiles,

    // Selected data source files
    state.selectedDataSourceFiles,
    state.setSelectedDataSourceFiles,

    // datasource files breadcrumb
    state.dataSourceFileBreadCrumb,
    state.setDataSourceFileBreadCrumb,

    // Datasets
    state.datasets,
    state.setDatasets,

    // Is deleting entities?
    state.isDeletingEntities,
    state.setIsDeletingEntities,

    // Is ontology?
    state.isOntology,
    state.setIsOntology,

    // Is fetching ontology data?
    state.isFetchingOntologyData,
    state.setIsFetchingOntologyData,

    // Is fetching ontology schema data?
    state.datasetIsFetchingOntologySchemaData,
    state.setDatasetIsFetchingOntologySchemaData,

    // Watch ontology schema set
    state.watchOntologySchemaSet,
    state.setWatchOntologySchemaSet,

    // Footer actions >>
    state.footerActions,
    state.setFooterActions,
    // << Footer actions

    // Reset >>
    state.resetDataset,
    state.resetDatasetPage,

    state.isResetting,
    state.setIsResetting
    // << Reset
  ]);
  // << ENDS - Stores

  return {
    // Default dataset ID
    datasetDefaultDatasetStore,
    setDatasetDefaultDatasetStore,

    // Existing datasets
    datasetExistingDatasetsStore,
    setDatasetExistingDatasetsStore,

    // Criteria
    datasetCriteriaStore,
    setDatasetCriteriaStore,

    // Is selection section open?
    datasetIsSelectionSectionOpenStore,
    setDatasetIsSelectionSectionOpenStore,

    // Is selection overlay open?
    datasetIsSelectionOverlayOpenStore,
    setDatasetIsSelectionOverlayOpenStore,

    // Project
    datasetProjectStore,
    setDatasetProjectStore,

    // Source
    datasetSourceStore,
    setDatasetSourceStore,

    // Criterion
    datasetCriterionStore,
    setDatasetCriterionStore,

    //shouldresetDataset
    shouldResetDataset,
    setShouldResetDataset,

    // Files
    datasetFilesStore,
    setDatasetFilesStore,

    // Selected data source files
    datasetSelectedDataSourceFilesStore,
    setDatasetSelectedDataSourceFilesStore,

    //datasource file breadcrumb
    dataSourceFileBreadCrumb,
    setDataSourceFileBreadCrumb,

    // Datasets
    datasetDatasetsStore,
    setDatasetDatasetsStore,

    // Is deleting entities?
    datasetIsDeletingEntitiesStore,
    setDatasetIsDeletingEntitiesStore,

    // Is ontology?
    datasetIsOntologyStore,
    setDatasetIsOntologyStore,

    // Is fetching ontology data?
    datasetIsFetchingOntologyDataStore,
    setDatasetIsFetchingOntologyDataStore,

    // Is fetching ontology schema data?
    datasetIsFetchingOntologySchemaData,
    setDatasetIsFetchingOntologySchemaData,

    // Watch ontology schema set
    datasetWatchOntologySchemaSetStore,
    setDatasetWatchOntologySchemaSetStore,

    // Footer actions >>
    datasetFooterActionsStore,
    setDatasetFooterActionsStore,
    // << Footer actions

    // Reset >>
    resetDatasetStore,
    resetDatasetModalStore,

    datasetIsResettingStore,
    setDatasetIsResettingStore
    // << Reset
  };
};

export default useStoreSelectors;
