const CONNECTOR_KEYS = {
  connectorType: "connector",
  fileCategory: {
    destinationFolder: { key: "outputFileDirectory", label: "Destination Folder" },
    destinationFileName: { key: "outputFileName", label: "Destination file name" }
  },
  sqlRedshiftCategory: {
    tableName: {
      key: "table",
      label: "Table name",
      regex: /^[a-zA-Z][a-zA-Z0-9_]*$/,
      errorMessage:
        "The table name contains invalid characters. It needs to start with alphabets and can only contain letters, numbers and underscores."
    },
    type: { key: "ifTableExists", label: "type", values: { append: "append", replace: "replace" } }
  },
  mongoCateogry: {
    collection: {
      key: "collection",
      label: "Collection",
      regex: /^[a-zA-Z0-9_]+$/,
      errorMessage:
        "The collection name contains invalid characters. It needs to start with alphabets and can only contain letters, numbers and underscores."
    },
    database: {
      key: "database",
      label: "Database",
      regex: /^[a-zA-Z0-9_\-]+$/,
      errorMessage:
        "The database name contains invalid characters. It needs to start with alphabets and can only contain letters, numbers,underscores and hyphens."
    }
  },
  snowFlakeCategory: {
    tableName: {
      key: "table",
      label: "Table name",
      regex: /^[a-zA-Z][a-zA-Z0-9_]*$/,
      errorMessage:
        "The table name contains invalid characters. It needs to start with alphabets and can only contain letters, numbers and underscores."
    },
    databaseName: {
      key: "database",
      label: "Database name",
      regex: /^[a-zA-Z][a-zA-Z0-9_]*$/,
      errorMessage:
        "The database name contains invalid characters. It needs to start with alphabets and can only contain letters, numbers and underscores."
    },
    warehouse: {
      key: "warehouse",
      label: "Warehouse"
    },
    role: {
      key: "role",
      label: "Role"
    },
    schema: {
      key: "schema",
      label: "Schema",
      regex: /^[a-zA-Z][a-zA-Z0-9_]*$/,
      errorMessage:
        "The schema name contains invalid characters. It needs to start with alphabets and can only contain letters, numbers and underscores."
    },
    type: { key: "ifTableExists", label: "type", values: { append: "append", replace: "replace" } }
  }
};

const MAX_LENGTH = 64;
const MAX_LONG_LENGTH = 255;
const MIN_LENGTH = 3;
const EXPORT_CONNECTOR_WITH_OUTPUT_ENTITY_SUCCESS_MSG = "Destination Details Saved Successfully";
const EXPORT_DATASET_SUCCESS_MSG = "Dataset exported successfully";
const EXPORT_TEXT_INPUT_SUCCESS_MSG = "Text output exported successfully";
const DATASET_DESTINATION_DETAILS_INFO = "Fivetran connectors are not supported for this action";
const TEXT_INPUT_DESTINATION_DETAILS_INFO =
  "Only file based connectors (Google Cloud Platform, Amazon S3 and Azure Blob Storage) are supported for text outputs";
const EXPORT_IN_PROGRESS_MSG = "Please wait for the export action to get completed";
const NO_CHANGE_MSG = "No changes are available to update";
const MANDATORY_FIELDS_UPDATE_MSG =
  "Please add all the mandatory information before updating the destination details";
const MANDATORY_FIELDS_SAVE_MSG =
  "Please add all the mandatory information before saving the destination details";
const INCORRECT_DATASET_STATUS_MSG = "Dataset should be in BUILT status for export action";
const INCORRECT_TEXT_INPUT_STATUS_MSG = "Text output should be in BUILT status for export action";
const EXPORT_DATASET_MSG = "Exports dataset to the Data Connector";
const EXPORT_TEXT_INPUT_MSG = "Exports text output to the Data Connector";
const UPDATE_DATASET_UNSAVED_CHANGES_MSG =
  "Please update the unsaved changes to export the dataset";
const UPDATE_TEXT_INPUT_UNSAVED_CHANGES_MSG =
  "Please update the unsaved changes to export the text output";
const LENGTH_ERROR_MESSAGE = `Please enter between ${MIN_LENGTH} and ${MAX_LENGTH} characters`;
export {
  CONNECTOR_KEYS,
  EXPORT_CONNECTOR_WITH_OUTPUT_ENTITY_SUCCESS_MSG,
  EXPORT_DATASET_SUCCESS_MSG,
  EXPORT_TEXT_INPUT_SUCCESS_MSG,
  DATASET_DESTINATION_DETAILS_INFO,
  TEXT_INPUT_DESTINATION_DETAILS_INFO,
  EXPORT_IN_PROGRESS_MSG,
  MANDATORY_FIELDS_UPDATE_MSG,
  NO_CHANGE_MSG,
  INCORRECT_DATASET_STATUS_MSG,
  INCORRECT_TEXT_INPUT_STATUS_MSG,
  EXPORT_DATASET_MSG,
  EXPORT_TEXT_INPUT_MSG,
  MANDATORY_FIELDS_SAVE_MSG,
  UPDATE_DATASET_UNSAVED_CHANGES_MSG,
  UPDATE_TEXT_INPUT_UNSAVED_CHANGES_MSG,
  MAX_LENGTH,
  LENGTH_ERROR_MESSAGE,
  MIN_LENGTH,
  MAX_LONG_LENGTH
};
