import React, { useEffect, useMemo, useRef, useState } from "react";
import _, { filter, includes, isEmpty, isNil, keyBy, keys, map, mapValues, omit } from "lodash";
import {
  TableContainer,
  TableHead,
  TableBody,
  Paper,
  Grid,
  Typography,
  Checkbox,
  Size
} from "@material-ui/core";

import clsx from "clsx";

import { DefaultTableCell } from "./Cells/DefaultTableCell/DefaultTableCell";
import { StyledTableRow, StyledMaUTable, useStyles } from "./styling";
import TableHeader from "./TableHeader/TableHeader";
import { TableSettingsMenu } from "./TableSettingsMenu/TableSettingsMenu";
import OverflowTooltip from "src/components/OverflowTooltip";
import {
  CellContext,
  ColumnDef,
  ColumnOrderState,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  RowSelectionState,
  useReactTable,
  VisibilityState
} from "@tanstack/react-table";
import { customAlphanumericSort } from "src/components/custom/Table/utils/Table.helpers";

import LoadingMore from "./LoadingMore";
import { IProps, TData } from "./typing";
import useVirtualizer from "./hooks/useVirtualizer";

export const Table = ({
  columns,
  data,
  stylesProps = {},
  sortingProps = {},
  filterProps = {},
  columnsProps = {},
  infoProps = {},
  rowSelectionProps = {},
  children,
  noHeaders,
  customHeader,
  actionHeader,
  updateMyData,
  getRowId,
  isLoading,
  disableVirtualization = false,
  showTypes = false
}: IProps) => {
  const { emptyTableMessage = "", hideCount, showSample, hideTotal, countMessage } = infoProps;
  const {
    flatStyle,
    isTheadSticky = false,
    captionStyles = {},
    hideSettings = false,
    inheritHeight = false,
    fixedLayout = true,
    maxHeight,
    size: initialSize,
    showBorder = false
  } = stylesProps;
  const { onSelectedColumnChange, columnOptionsCustomRender, hiddenColumns } = columnsProps;
  const { orderByDefault = "Created", sortInverted, unsorted = false } = sortingProps;
  const {
    isSelectable,
    rowToBeEdited,
    shouldDisableInitialSelectedRows = false,
    onRowClicked,
    selectedRowIds,
    onSelectedRowsChange
  } = rowSelectionProps;
  const { globalFilter, onGlobalFilterChange } = filterProps;
  const size = initialSize ?? "small";
  const classes = useStyles({ size, fixedLayout });
  const tableContainerRef = useRef(null);
  const [rowSelection, setRowSelection] = React.useState<RowSelectionState>(selectedRowIds ?? {});

  const modifiedColumns = useMemo(() => {
    return isSelectable
      ? [
          {
            id: "selection",
            header: ({ table }: any) => (
              <Checkbox
                size="small"
                color="primary"
                data-testid={`table-row-selection-all-check-box`}
                indeterminate={table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
                checked={table.getIsAllRowsSelected()}
                onChange={table.getToggleAllRowsSelectedHandler()}
              />
            ),
            cell: ({ row }: CellContext<any, unknown>) => {
              const rowIds = keys(selectedRowIds);
              const isDisabled = shouldDisableInitialSelectedRows && rowIds?.includes(row.id);

              return (
                <Checkbox
                  data-testid={`table-row-selection-check-box-${row.id}`}
                  size="small"
                  color="primary"
                  disabled={isDisabled}
                  checked={row.getIsSelected()}
                  onChange={row.getToggleSelectedHandler()}
                />
              );
            },
            enableHiding: false,
            size: 75,
            minSize: 75,
            maxSize: 75,
            meta: { isTooltip: false },
            enableSorting: false
          } as any,
          ...columns
        ]
      : columns;
  }, [columns, isSelectable]);

  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(
    mapValues(keyBy(modifiedColumns, "id"), (item) =>
      includes(hiddenColumns, item.id) ? false : true
    )
  );

  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>(map(modifiedColumns, "id"));

  const tableColumns = React.useMemo(() => {
    if (isLoading) {
      const initialColArray: any[] = [];
      initialColArray.length = 7;
      initialColArray.fill({});
      return initialColArray.map((__: {}, index: number) => {
        return {
          id: index.toString(),
          accessorKey: `init_col_${index}`,
          header: ""
        };
      });
    }
    return modifiedColumns;
  }, [modifiedColumns, isLoading]);

  const tableData = React.useMemo(() => {
    if (isLoading) {
      const placeholderArray: any[] = [];
      placeholderArray.length = 10;
      placeholderArray.fill({});
      return placeholderArray.map(() =>
        modifiedColumns.reduce((acc, curr) => {
          acc[curr.accessor] = "";
          return acc;
        }, {})
      );
    }
    return data;
  }, [isLoading, data, modifiedColumns]);

  const onColumnOrderChange = (newOrder: ColumnOrderState) => {
    const reorderedColumns = ["selection", ...filter(newOrder, (id) => id !== "selection")];
    setColumnOrder(reorderedColumns);
  };

  const { getState, setGlobalFilter, getRowModel, getVisibleFlatColumns, getHeaderGroups } =
    useReactTable({
      getRowId,
      columns: tableColumns as ColumnDef<TData, any>[],
      data: tableData,
      columnResizeMode: "onChange",
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      getFacetedRowModel: getFacetedRowModel(),
      getFacetedUniqueValues: getFacetedUniqueValues(),
      onRowSelectionChange: (rows) => {
        setRowSelection(rows);
        if (onSelectedRowsChange) {
          if (typeof rows === "function") {
            onSelectedRowsChange(rows(rowSelection));
          } else {
            onSelectedRowsChange(rows);
          }
        }
      },
      state: {
        columnVisibility: columnVisibility,
        rowSelection: rowSelection,
        globalFilter,
        columnOrder: columnOrder
      },
      onColumnVisibilityChange: setColumnVisibility,
      onColumnOrderChange: (newOrder) => {
        const reorderedColumns = ["selection", ...filter(newOrder, (id) => id !== "selection")];
        setColumnOrder(reorderedColumns);
      },

      onGlobalFilterChange: onGlobalFilterChange,
      meta: {
        updateMyData
      },
      initialState: {
        ...(unsorted
          ? {}
          : !!orderByDefault
            ? { sorting: [{ id: orderByDefault, desc: !sortInverted }] }
            : {})
      },
      sortingFns: { alphanumeric: customAlphanumericSort }
    });

  useEffect(() => {
    setGlobalFilter?.(globalFilter && globalFilter !== "" ? globalFilter : undefined);
  }, [globalFilter]);

  const { rows } = getRowModel();

  const visibleColumns = getVisibleFlatColumns();

  const { rowVirtualizer, paddingBottom, paddingTop, scrollToTop } = useVirtualizer({
    rows,
    tableContainerRef,
    disableVirtualization
  });

  const tableState = getState();

  const renderTableHeader = useMemo(() => {
    return (
      !noHeaders && (
        <TableHead>
          {getHeaderGroups()?.map((headerGroup) => {
            return (
              <StyledTableRow key={headerGroup.id}>
                {headerGroup.headers?.map((header, index: number) => {
                  const customClassName = clsx("headerCell", {
                    headerSticky: isTheadSticky,
                    customHeader: customHeader
                  });
                  return (
                    <TableHeader
                      showTypes={showTypes}
                      key={header.id}
                      index={index}
                      size={size as Size}
                      header={header}
                      className={customClassName}
                      isLoading={isLoading}
                      columnOptionsCustomRender={columnOptionsCustomRender}
                      updateMyData={updateMyData}
                      scrollToTop={scrollToTop}
                      state={tableState}
                    />
                  );
                })}
              </StyledTableRow>
            );
          })}
        </TableHead>
      )
    );
  }, [
    noHeaders,
    size,
    columnOptionsCustomRender,
    updateMyData,
    isLoading,
    scrollToTop,
    tableState
  ]);
  return (
    <Grid className={clsx(inheritHeight && classes.inheritHeightContainer)}>
      {!hideCount && (
        <Typography
          data-test-id={"table-total-count-message"}
          className={
            classes.recordCount
          }>{`${showSample ? "Sample" : hideTotal ? "" : "Total"} ${_.size(data) ?? 0} records${countMessage ?? ""}`}</Typography>
      )}
      <TableContainer
        component={Paper}
        className={clsx(
          classes.relativeContainer,
          flatStyle ? classes.tableFlatContainer : classes.tableContainer,
          inheritHeight && classes.inheritHeightContainer
        )}
        style={{ border: showBorder ? "1px solid #d6d6d6" : undefined }}>
        {!isLoading &&
          !hideSettings &&
          !actionHeader &&
          !noHeaders &&
          !isEmpty(columnVisibility) && (
            <TableSettingsMenu
              originalColumns={filter(map(tableColumns, "id"), (item) => item !== "selection")}
              columnVisibility={omit(columnVisibility, "selection")}
              handleVisibilityChange={setColumnVisibility}
              columnOrder={filter(columnOrder, (item) => item !== "selection")}
              setColumnOrder={onColumnOrderChange}
              onColumnChange={onSelectedColumnChange}
            />
          )}
        <Grid
          ref={tableContainerRef}
          className={clsx(classes.tableDivContainer)}
          style={{
            maxHeight: maxHeight ? maxHeight : inheritHeight ? "inherit" : "100%",
            maxWidth: "100vw"
          }}>
          <StyledMaUTable
            style={{ borderSpacing: "revert" }}
            stickyHeader
            data-testid="oldCustomTable">
            {children && <caption style={captionStyles}>{children}</caption>}
            {actionHeader && (
              <Grid className={classes.actionHeader}>
                <Grid container direction="row" alignItems="center">
                  <Typography
                    data-testid="table-action-header-title"
                    component="div"
                    variant={size === "medium" ? "h6" : "body1"}
                    className={classes.maxWidth}>
                    {actionHeader.title}
                  </Typography>
                  <Grid>{actionHeader.actions}</Grid>
                </Grid>
              </Grid>
            )}
            {renderTableHeader}
            <TableBody>
              <LoadingMore
                colSpan={visibleColumns?.length}
                shouldDisplay={
                  visibleColumns?.length > 0 &&
                  rowVirtualizer?.getVirtualItems()?.length !== rows?.length
                }
                padding={paddingTop}
                isTop
                classes={classes}
              />

              {(!!disableVirtualization ? rows : rowVirtualizer?.getVirtualItems())?.map(
                (virtualRow, rowIndex: number) => {
                  const row = !!disableVirtualization ? rows?.[rowIndex] : rows?.[virtualRow.index];

                  return (
                    <StyledTableRow
                      id={`${rowIndex}`}
                      key={row.id}
                      onClick={() => {
                        if (onRowClicked) {
                          onRowClicked(row);
                        }
                      }}
                      {...{
                        className: `${includes(keys(selectedRowIds), row.id) ? classes.selectedRow : ""}`
                      }}>
                      {isLoading && (
                        <td
                          data-testid="table-body-shimmer-cell"
                          className={classes.shimmerTdCell}
                          key={row?.getVisibleCells()?.[0]?.id}
                        />
                      )}
                      {row?.getVisibleCells()?.map((cell) => {
                        if (isLoading) {
                          return <td className={classes.shimmerTdCell} key={cell.id}></td>;
                        }
                        const className = clsx("bodyCell", {
                          [classes.shimmerTdCell]:
                            // Below condition only applies to ViewDataDataTable data, since it's specially parsed
                            cell?.column?.columnDef.meta?.name === cell?.column?.id &&
                            cell?.row?.original?.[cell.column.columnDef.meta?.name] === undefined
                        });
                        const isTooltip = isNil(cell.column.columnDef.meta?.isTooltip)
                          ? true
                          : cell.column.columnDef.meta?.isTooltip;

                        const shouldSkipOverflow = cell?.column?.id === "selection";
                        return (
                          <DefaultTableCell
                            key={cell.id}
                            cell={cell}
                            className={className}
                            rowToBeEdited={rowToBeEdited}
                            updateMyData={updateMyData}
                            size={size as Size}>
                            {shouldSkipOverflow ? (
                              flexRender(cell?.column?.columnDef?.cell, cell?.getContext())
                            ) : isTooltip ? (
                              <OverflowTooltip
                                value={flexRender(
                                  cell?.column?.columnDef?.cell,
                                  cell?.getContext()
                                )}
                              />
                            ) : (
                              <Grid
                                style={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis"
                                }}>
                                {flexRender(cell?.column?.columnDef?.cell, cell?.getContext())}
                              </Grid>
                            )}
                          </DefaultTableCell>
                        );
                      })}
                    </StyledTableRow>
                  );
                }
              )}
              <LoadingMore
                colSpan={visibleColumns?.length}
                shouldDisplay={
                  visibleColumns?.length > 0 &&
                  rowVirtualizer?.getVirtualItems()?.length !== rows?.length
                }
                padding={paddingBottom}
                isTop={false}
                classes={classes}
              />
            </TableBody>
          </StyledMaUTable>
        </Grid>
        {rows?.length === 0 && emptyTableMessage && (
          <span data-testid="table-empty-message" className={classes.emptyMessage}>
            {emptyTableMessage}
          </span>
        )}

        {visibleColumns?.length === 0 && (
          <span data-testid="no-column-selected-message" className={classes.emptyMessage}>
            No columns selected
          </span>
        )}
      </TableContainer>
    </Grid>
  );
};
export default React.memo(Table);
