import React from "react";
import _ from "lodash";
import { makeStyles } from "@material-ui/core";

import FileBasedConnectorDetails from "./FileBasedConnectorDetails";
import MongoConnectorDetails from "./MongoConnectorDetails";
import SQLRedshiftConnectorDetails from "./SQLRedshiftConnectorDetails";
import SelectConnectorType from "./SelectConnectorType";
import SnowflakeConnectorDetails from "./SnowflakeConnectorDetails";
import { CONNECTOR_KEYS } from "../utils/OutputDataset.constants";
import { DataConnectorNames } from "pages/DataSources/utils/DataSources.constants";
import { IConnectorType } from "./DestinationDetails";

interface IProps {
  readonly?: boolean;
  isTextInput?: boolean;
  selectedConnectorType?: string;
  connectors: IConnectorType[];
  values: any;
  onChange: (
    e: React.ChangeEvent<{
      name: string;
      value: string;
    }>
  ) => void;
  onConnectorChange: (e: any) => void;
}

const useStyles = makeStyles({
  flex: {
    display: "flex",
    width: "50%",
    flexDirection: "column",
    gap: "15px"
  }
});

const ConnectorDetailsForm: React.FC<IProps> = (props) => {
  const {
    readonly = false,
    isTextInput = false,
    values,
    connectors,
    selectedConnectorType,
    onChange,
    onConnectorChange
  } = props;

  const { flex } = useStyles();

  const getConnectorDetails = () => {
    const id = _.get(values, CONNECTOR_KEYS.connectorType);
    switch (selectedConnectorType) {
      case DataConnectorNames.AZURE_BLOB:
      case DataConnectorNames.GCP_STORAGE:
      case DataConnectorNames.S3_STORAGE:
        return (
          <FileBasedConnectorDetails
            key={id}
            isTextInput={!!isTextInput}
            values={values}
            onChange={onChange}
            readonly={readonly}
          />
        );

      case DataConnectorNames.MYSQL:
      case DataConnectorNames.REDSHIFT:
      case DataConnectorNames.POSTGRES:
        return (
          <SQLRedshiftConnectorDetails
            key={id}
            values={values}
            onChange={onChange}
            readonly={readonly}
          />
        );

      case DataConnectorNames.MONGO:
        return (
          <MongoConnectorDetails key={id} values={values} onChange={onChange} readonly={readonly} />
        );

      case DataConnectorNames.SNOWFLAKE:
        return (
          <SnowflakeConnectorDetails
            key={id}
            values={values}
            onChange={onChange}
            readonly={readonly}
          />
        );

      default:
        return <>Please select a connector</>;
    }
  };

  return (
    <div className={flex}>
      <SelectConnectorType
        value={_.get(values, CONNECTOR_KEYS.connectorType)}
        connectors={connectors}
        onChange={onConnectorChange}
        readonly={readonly}
      />
      {getConnectorDetails()}
    </div>
  );
};

export default ConnectorDetailsForm;
