import React from "react";

// Packages
import { compact, filter, includes, isEmpty, map } from "lodash";

// Utils
import { ToastTypes, toastWrapper } from "services/ToastClient/toastWrapper";

// Components
import { Modal } from "src/components/custom";
import { ModalVariants } from "src/components/custom/Modal/Modal";

// Constants
import { ProjectSettingsHelperText } from "../utils/ProjectSettings.constants";
import useDeleteMultipleProject from "src/hooks/api/projects/useDeleteMultipleProject";

type Props = {
  projectIds: string[];
  resetDeleteProject: () => void;
  onDelete: () => void;
};

const DeleteMultipleProjects = (props: Props) => {
  const { projectIds, resetDeleteProject, onDelete } = props || {};

  const {
    isLoading: isProjectDeleting,
    mutateAsync: deleteProjectMutation,
    reset: resetDeleteProjectMutation
  } = useDeleteMultipleProject();

  const deleteProject = async () => {
    resetDeleteProjectMutation();
    await deleteProjectMutation(
      { projectIds },
      {
        onSuccess: (response: any) => {
          if (isEmpty(compact(map(response, "error")))) {
            toastWrapper({
              type: ToastTypes.Success,
              content: ProjectSettingsHelperText.ProjectsDeleted
            });
            onDelete();
          } else {
            toastWrapper({
              type: ToastTypes.Error,
              content: (
                <>
                  <span>
                    An error occurred while deleting the selected projects.
                    <br />
                  </span>
                  {map(
                    filter(response, (item) => !isEmpty(item.error)),
                    (item) => (
                      <>
                        <>{item.name && <span style={{ fontWeight: 600 }}>{item.name} : </span>}</>
                        <span>{item.error}</span> <br />
                        <br />
                      </>
                    )
                  )}
                </>
              )
            });
          }
        },
        onSettled: () => resetDeleteProject()
      }
    );
  };

  const projectDeletePromptDetails = {
    title: "Delete Projects",

    messageLine1:
      "Deleting the selected projects removes them permanently from the Projects list and will also delete the associated prediction service(s) linked to the model(s) within those projects (if any)",
    messageLine2: "Are you sure you want to continue?"
  };
  return (
    <Modal
      open
      variant={ModalVariants.Delete}
      title={projectDeletePromptDetails.title}
      content={[projectDeletePromptDetails.messageLine1, projectDeletePromptDetails.messageLine2]}
      onClose={(_, reason: string) => {
        if (!includes(["escapeKeyDown", "backdropClick"], reason)) {
          resetDeleteProject();
        }
      }}
      cancelLabel="No"
      onSubmit={deleteProject}
      isCancelDisabled={!!isProjectDeleting}
      isSubmitDisabled={!!isProjectDeleting}
      isSubmitting={!!isProjectDeleting}
      hideCloseIcon
    />
  );
};

export default DeleteMultipleProjects;
