import { Button, makeStyles, Tooltip } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import React from "react";
import { Spinner } from "src/components";
import { SaveIcon } from "icons/NewUX";

const useStyles = makeStyles({
  btn: {
    textTransform: "none",
    whiteSpace: "pre"
  },
  infoIcon: {
    margin: "0px 8px",
    color: "#fff",
    fontSize: "16px"
  }
});

export const SaveButton = ({
  disabled,
  loading,
  tooltip = "",
  onClick,
  isAutoSaving
}: {
  disabled: boolean;
  loading: boolean;
  tooltip?: string;
  onClick: () => void;
  isAutoSaving?: boolean;
}) => {
  const classes = useStyles();
  return (
    <Tooltip title={tooltip}>
      <span>
        <Button
          size="small"
          variant="contained"
          data-testid="saveRecipeBtn"
          color="primary"
          onClick={onClick}
          startIcon={<SaveIcon width={21} height={21} viewBox="-2 -2 16 16" />}
          className={classes.btn}
          disabled={disabled}>
          {isAutoSaving ? "Autosaving..." : "Save Code"}
          {loading && <Spinner size={16} padding="0px 8px" />}
          <Tooltip title={"Shortcut: Cmd + S / Ctrl + S to Save"}>
            <InfoOutlined className={classes.infoIcon} />
          </Tooltip>
        </Button>
      </span>
    </Tooltip>
  );
};
