/* eslint-disable no-unused-vars */
import React from "react";
import { TextField, Grid, makeStyles, Typography } from "@material-ui/core";

import { handleResponse } from "services/Apis/Apis.service";

const useStyles = makeStyles({
  timeoutContainer: {
    flexWrap: "nowrap",
    alignItems: "flex-end",
    gap: "8px",
    padding: ({ disablePadding, padding }: { disablePadding: boolean; padding?: string }) =>
      disablePadding ? "0px" : padding ?? "9px 14px"
  },
  subHeading: {
    fontSize: "14px",
    color: "#000000",
    opacity: 0.5
  }
});

export const TimeoutTextField = ({
  value: initialValue,
  onChange,
  onClose,
  disabled,
  fullWidth,
  padding,
  label = "Timeout",
  labelText = "Timeout",
  disablePadding = false,
  minValue = 0,
  maxValue,
  callOnChange
}: {
  value: number | "";
  onChange: (timeout: number) => void;
  onClose?: () => void;
  disabled?: boolean;
  fullWidth?: boolean;
  labelText?: string;
  label?: string;
  padding?: string;
  disablePadding?: boolean;
  maxValue?: number;
  minValue?: number;
  callOnChange?: boolean; // supporting onchange for now
}) => {
  const classes = useStyles({ disablePadding, padding });
  const [value, setValue] = React.useState(initialValue);

  const handleChange = (event: $TSFixMe) => {
    const newValue = event.target.value;

    if (
      Number(newValue) &&
      ((minValue && Number(newValue) < minValue) || (maxValue && Number(newValue) > maxValue))
    ) {
      handleResponse({
        errorMessage: maxValue
          ? `${labelText} needs to be between ${minValue} and ${maxValue} hrs.`
          : `${labelText} needs to be greater than ${minValue - 1}`
      });
    } else if (!newValue || (/^[0-9]*$/.test(newValue) && Number(newValue) >= minValue)) {
      setValue(newValue ? Number(newValue) : newValue);
      if (callOnChange) {
        onChange(newValue ? Number(newValue) : newValue);
      }
    }
  };

  return (
    <Grid container direction="row" className={classes.timeoutContainer}>
      <TextField
        inputProps={{ type: "number", min: minValue, step: 1 }}
        value={value}
        label={label}
        onChange={handleChange}
        onBlur={() => {
          if (callOnChange) {
            return;
          }
          if (minValue !== 0) {
            value ? onChange(value) : setValue(initialValue);
          } else {
            value !== "" && onChange(value);
          }
          onClose?.();
        }}
        disabled={disabled}
        variant="outlined"
        size="small"
        fullWidth={fullWidth}
        data-testid="timeoutTextFieldBtn"
      />
      <Typography className={classes.subHeading}>hr</Typography>
    </Grid>
  );
};
