import { makeStyles, Theme } from "@material-ui/core/styles";

interface IProps {
  marginLeft: string;
}

export const useStyles = makeStyles<Theme, IProps>((theme) => ({
  root: {
    position: "fixed",
    top: 110,
    left: 15,
    zIndex: 10,

    border: `2.25px solid ${theme.palette.secondary.main}`,
    borderRadius: "6px",
    transition: "width 0.3s ease-in-out",
    overflow: "hidden"
  },
  menuItem: {
    padding: 0,
    marginBottom: "6px",

    "&:last-child": {
      marginBottom: 0
    },

    "& svg": {
      marginLeft: ({ marginLeft }) => marginLeft ?? "2px"
    }
  },
  menuItemButton: {
    width: "100%",
    justifyContent: "flex-start",

    "&  span": {
      marginRight: 0
    },
    "&.size": {
      marginBottom: 0,
      paddingBottom: 0
    },
    "&.active": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
      paddingBottom: 4
    }
  },
  loader: {
    margin: 4
  }
}));
