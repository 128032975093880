import React from "react";
import { Button } from "@material-ui/core";

import ReactExceptionIcon from "src/assets/icons/ReactExceptionIcon";
import Result, { ResultProps } from "./Result";

interface IProps extends Partial<ResultProps> {
  onClick?: () => void;
}

const ReactException: React.FC<IProps> = (props) => {
  return (
    <Result
      heading="Oops! Something went wrong..."
      icon={<ReactExceptionIcon />}
      subtitle1="We're sorry, but the page you are trying to access is currently experiencing issues."
      subtitle2="This could be due to a temporary problem or corruption in the page data."
      extra={
        props.onClick ? (
          <Button variant="contained" onClick={props.onClick}>
            Reload
          </Button>
        ) : undefined
      }
    />
  );
};

export default ReactException;
