import React, { useEffect, useMemo, useState } from "react";

// Packages
import shallow from "zustand/shallow";
import { filter, forEach, includes, isEmpty } from "lodash";

// Icons
import { NotebookIcon } from "src/assets/icons/NotebookIcon";
import { NotebookIcon as NewNotebookIcon } from "icons/NewUX";

// Utils
import api from "services/AxiosClient/AxiosClient";
import { NAVBAR_SECTION, IItem } from "../../utils/NavBars.constants";
import { getHostedNotebookPath } from "../../utils/NavBars.helpers";

// Stores
import useAuthStore from "stores/zustand/auth.store";

// Constants
import { MenuItemTypes, NotebookTypes } from "constants/index";

// Types
import { Roles } from "src/types/Role.type";
import { useGetRole } from "hooks/useGetRole";

type Props = {
  mainMenuRef: React.RefObject<HTMLButtonElement>;
};

const useMainMenu = (props: Props) => {
  const { mainMenuRef } = props || {};

  const token = api.getToken();
  const { isRoleYieldsDataAppView } = useGetRole();

  // Stores - STARTS >>
  const [roleName, notebookType, isNotebookEnabled] = useAuthStore(
    (state) => [state.roleName, state.notebookType, state.isNotebookEnabled],
    shallow
  );
  // << ENDS - Stores

  // States - STARTS >>
  const [notebookMenuItems, setNotebookMenuItems] = useState<IItem[]>([]);
  // << ENDS - States

  // Drop-down code - STARTS >>
  const [mainMenuButtonAnchorEl, setMainMenuButtonAnchorEl] =
    React.useState<HTMLButtonElement | null>(null);

  const onMainMenuOpen = (__: React.MouseEvent<HTMLButtonElement>) => {
    setMainMenuButtonAnchorEl(mainMenuRef.current);
  };

  const onMainMenuClose = () => {
    setMainMenuButtonAnchorEl(null);
  };
  // << ENDS - Drop-down code

  // Validating menu-items against the user-role.
  const { menuItems } = useMemo(() => {
    const validatedMenuItems: IItem[][] = [];

    forEach(NAVBAR_SECTION, (values) => {
      const filteredMenuItems = filter(
        values,
        (item: IItem) =>
          includes(item.allowedRoles, roleName) ||
          // Below expression gets through, only if allowed-roles contains data-app view role and the role yields data-app-view.
          (includes(item.allowedRoles, Roles.DataAppView.name) && isRoleYieldsDataAppView)
      );
      if (!isEmpty(filteredMenuItems)) {
        // @ts-ignore
        validatedMenuItems.push(filteredMenuItems);
      }
    });

    return { menuItems: validatedMenuItems };
  }, [isRoleYieldsDataAppView, roleName]);

  useEffect(() => {
    if (includes([Roles.Admin.name, Roles.User.name], roleName) && !!isNotebookEnabled) {
      const hostedNotebookUserPath = getHostedNotebookPath(
        String(NotebookTypes.User)?.toLowerCase(),
        token as unknown as string
      );
      const hostedNotebookTenantPath = getHostedNotebookPath(
        String(NotebookTypes.Tenant)?.toLowerCase(),
        token as unknown as string
      );

      let hostedNotebookUser = {
        path: hostedNotebookUserPath,
        label: NotebookTypes.LaunchNotebookUser,
        icon: <NotebookIcon />,
        menuIcon: NewNotebookIcon,
        type: MenuItemTypes.Notebook
      };

      let hostedNotebookTenant = {
        path: hostedNotebookTenantPath,
        label: NotebookTypes.LaunchNotebookTenant,
        icon: <NotebookIcon />,
        menuIcon: NewNotebookIcon,
        type: MenuItemTypes.Notebook
      };

      let launchNotebookTypes: IItem[] = [];
      if (notebookType === NotebookTypes.Both) {
        launchNotebookTypes = [hostedNotebookUser, hostedNotebookTenant];
      } else if (notebookType === NotebookTypes.User) {
        launchNotebookTypes = [{ ...hostedNotebookUser, label: NotebookTypes.LaunchNotebook }];
      } else if (notebookType === NotebookTypes.Tenant) {
        launchNotebookTypes = [{ ...hostedNotebookTenant, label: NotebookTypes.LaunchNotebook }];
      }

      setNotebookMenuItems(launchNotebookTypes);
    }
  }, [roleName, isNotebookEnabled, notebookType]);

  return {
    mainMenuButtonAnchorEl,
    onMainMenuOpen,
    onMainMenuClose,
    menuItems,
    notebookMenuItems
  };
};

export default useMainMenu;
