import React, { Dispatch, SetStateAction } from "react";

// Packages
import { generatePath, Link, useParams } from "react-router-dom";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";

// Utils
import { RenderText } from "components/custom";
import { WebPaths } from "src/routing/routes";

// Components
import EditName from "src/layout/NavBars/components/SubTopNavBar/EditName";

// Constants
import { JobConfig } from "../../utils/Jobs.constants";
import { FindProjectRunsJobTypeEnum, ProjectRunDtoJobTypeEnum } from "@rapidcanvas/rc-api-core";
import {
  SCHEDULER_TABS,
  TAB
} from "../../../PredictionJob/components/PredictionRunScheduler/Schedulers";

type Props = {
  project: $TSFixMe;
  jobName: string;
  setJobName: Dispatch<SetStateAction<string>>;
  jobType: FindProjectRunsJobTypeEnum;
  updateJobName: $TSFixMeFunction;
  isReadonlyJobName?: boolean;
  shouldResetJobName?: boolean | number;
};

const SubTopNavBarBreadcrumbs = (props: Props) => {
  const {
    project,
    jobName,
    jobType,
    setJobName,
    updateJobName,
    isReadonlyJobName,
    shouldResetJobName
  } = props || {};

  const { jobId } = useParams() || {};

  const onJobNameChange = (newName: string) => {
    !!jobId ? updateJobName(newName) : setJobName(newName);
  };

  const breadcrumbs: React.ReactNode[] = [];

  breadcrumbs.push(
    !!project?.id ? (
      <Link key="projects" to={generatePath(`${WebPaths.Dag}/canvas`, { projectId: project?.id })}>
        <RenderText>{project?.name || "Project"}</RenderText>
      </Link>
    ) : (
      <RenderText key="projects" color="textSecondary">
        {project?.name || "Project"}
      </RenderText>
    )
  );

  if (jobType === ProjectRunDtoJobTypeEnum.PredictionJob) {
    breadcrumbs.push(
      <RenderText key="predictions" color="textSecondary">
        <RenderText>Predictions</RenderText>
      </RenderText>
    );
    breadcrumbs.push(
      !!project?.id ? (
        <Link
          key="jobs"
          state={{ [TAB]: SCHEDULER_TABS.PredictionScheduler }}
          to={generatePath(WebPaths.PredictionJob, { projectId: project?.id })}>
          <RenderText>Prediction Schedulers</RenderText>
        </Link>
      ) : (
        <RenderText key="jobs" color="textSecondary">
          <RenderText>Prediction Schedulers</RenderText>
        </RenderText>
      )
    );
  } else {
    breadcrumbs.push(
      !!project?.id ? (
        <Link key="jobs" to={generatePath(WebPaths.JobRoutes, { projectId: project?.id })}>
          <RenderText>Schedulers</RenderText>
        </Link>
      ) : (
        <RenderText key="jobs" color="textSecondary">
          <RenderText>Schedulers</RenderText>
        </RenderText>
      )
    );
  }

  breadcrumbs.push(
    <EditName
      key="editJobName"
      fieldNameLabel="scheduler name"
      inputValue={jobName}
      onSave={onJobNameChange}
      isResetName={shouldResetJobName}
      isLoading={!!isReadonlyJobName}
      containerProps={{
        style: {
          width: ((jobName || "")?.length + 1) * (JobConfig.JobNameFontSize - 4) + 16
        }
      }}
      data-testid="jobBreadcrumbJobName"
    />
  );

  return (
    <Breadcrumbs>
      {breadcrumbs?.map((eachBreadcrumb: React.ReactNode) => eachBreadcrumb)}
    </Breadcrumbs>
  );
};

export default SubTopNavBarBreadcrumbs;
