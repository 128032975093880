import React, { useState, useRef, ChangeEvent, useEffect } from "react";
import { Tooltip, IconButton, TextField, makeStyles, InputAdornment } from "@material-ui/core";
import QuerySpinner from "../../AddCodeRecipe/CodeRecipeTabContainer/AskAIContainer/GenerateCodeBar/QuerySpinner";
import { SendSharp } from "@material-ui/icons";
import { UserIcon } from "icons/NewUX/UserIcon";
import { filter, includes, isEmpty, toLower, uniq } from "lodash";

import ColumnSuggestionsPopper from "../../AddCodeRecipe/CodeRecipeTabContainer/AskAIContainer/GenerateCodeBar/ColumnSuggestionsPopper";
import useAuthStore from "stores/zustand/auth.store";

export const STOPPED_QUERY_ID = "stopped_query";

interface IProps {
  isGenerating: boolean;
  query: string;
  setQuery: (query: string) => void;
  onGenerateCode: (query: string) => void;
  onGenerateStop: () => void;
  disabled?: boolean;
  columns: string[];
}

const useStyles = makeStyles({
  inputProps: {
    background: "#fff",
    "&:hover $notchedOutline": {
      borderLeftWidth: "1px !important"
    },
    "&$focused $notchedOutline": {
      borderLeftWidth: "1px !important"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderLeftWidth: "0px"
    },
    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#4646B5"
    }
  },
  textField: {
    minHeight: "48px",
    "& .MuiOutlinedInput-notchedOutline": {
      borderLeftWidth: "0px"
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderLeftWidth: "1px !important"
    }
  },
  inputAdornment: {
    gap: "8px"
  }
});

const GenerateCodeBar = ({
  query,
  setQuery,
  isGenerating,
  onGenerateCode,
  onGenerateStop,
  disabled = false,
  columns
}: IProps) => {
  const classes = useStyles();
  const textFieldRef = useRef<HTMLTextAreaElement>(null);
  const isStopped = useRef<boolean>(false);
  const menuItemRefs = useRef<(HTMLLIElement | null)[]>([]);
  const [highlightedIndex, setHighlightedIndex] = useState<number | null>(0);
  const [anchorElColumn, setAnchorElColumn] = useState<HTMLTextAreaElement | null>(null);
  const [atIndex, setAtIndex] = useState<number | null>(null);
  const [filteredSuggestions, setFilteredSuggestion] = useState<string[]>([]);
  const askAiEnabled = useAuthStore((state) => state.askAiEnabled);

  const isGenerateBtnInvalid = !query?.trim();
  const isGenerateBtnDisabled = isGenerating || isGenerateBtnInvalid;

  useEffect(() => {
    if (atIndex !== null) {
      const updatedQuery = query.slice(atIndex + 1, textFieldRef.current?.selectionStart);
      if (query.length >= 0) {
        const newSuggestion = filter(
          columns,
          (item) => updatedQuery.length === 0 || includes(toLower(item), toLower(updatedQuery))
        );
        setFilteredSuggestion(uniq(newSuggestion));
        if (!isEmpty(newSuggestion) && textFieldRef.current) {
          setAnchorElColumn(textFieldRef.current);
        } else {
          setAnchorElColumn(null);
        }
      } else {
        setAnchorElColumn(null);
      }
    } else {
      setAnchorElColumn(null);
    }
  }, [query, atIndex]);

  const generateCode = () => {
    setQuery("");
    onGenerateCode(query?.trim());
  };

  const handleStop = () => {
    isStopped.current = true;
    onGenerateStop();
  };

  const handleSuggestionClick = (suggestion: string) => {
    if (atIndex === null || isEmpty(suggestion)) return;
    const beforeMention = query.slice(0, atIndex);
    const afterMention = query.slice(textFieldRef.current?.selectionStart);
    const newValue = `${beforeMention}${suggestion} ${afterMention}`;

    setQuery(newValue);
    setAnchorElColumn(null);
    setAtIndex(null);
    textFieldRef.current?.focus();
    const newCursorPosition = beforeMention.length + suggestion.length + 1;
    setTimeout(() => {
      textFieldRef.current?.setSelectionRange(newCursorPosition, newCursorPosition);
    }, 0);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (anchorElColumn) {
      if (event.key === "ArrowDown") {
        event.preventDefault();
        setHighlightedIndex((prevIndex) =>
          prevIndex === null || prevIndex === columns.length - 1 ? prevIndex : prevIndex + 1
        );
      } else if (event.key === "ArrowUp") {
        event.preventDefault();
        setHighlightedIndex((prevIndex) =>
          prevIndex === null || prevIndex === 0 ? prevIndex : prevIndex - 1
        );
      } else if (event.key === "Enter" && highlightedIndex !== null) {
        event.preventDefault();
        handleSuggestionClick(filteredSuggestions[highlightedIndex]);
      }
    } else if ((event.metaKey || event.ctrlKey) && event.key === "Enter") {
      // For Command + Enter
      event.preventDefault();
      setQuery(query + "\n");
    } else if (isGenerateBtnDisabled || isGenerating) {
      return;
    } else if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      setAtIndex(null);
      setAnchorElColumn(null);
      generateCode();
    }
  };

  useEffect(() => {
    if (highlightedIndex !== null && menuItemRefs.current[highlightedIndex]) {
      menuItemRefs.current[highlightedIndex]!.scrollIntoView({
        behavior: "smooth",
        block: "nearest"
      });
    }
  }, [highlightedIndex]);

  return (
    <>
      <TextField
        size="small"
        fullWidth
        value={query}
        className={classes.textField}
        variant="outlined"
        placeholder={`Enter your query here.`}
        test-id="ask-ai-modal-text-field"
        inputProps={{
          "test-id": { inputTestId: "ask-ai-modal-input" }
        }}
        multiline
        minRows={1}
        maxRows={3}
        inputRef={textFieldRef}
        onKeyDown={handleKeyDown}
        data-testid="ai-generate-code-bar"
        InputProps={{
          className: classes.inputProps,
          startAdornment: (
            <InputAdornment
              position="start"
              className={classes.inputAdornment}
              data-test-id="ai-input-user-icon">
              <UserIcon color={"#FF653D"} fill="#fff" />
            </InputAdornment>
          ),
          endAdornment: isGenerating ? (
            <QuerySpinner onStop={handleStop} />
          ) : (
            <Tooltip
              arrow
              title={!disabled && isGenerateBtnInvalid ? "Add Query to enable" : ""}
              placement="top">
              {/* div has to enclose button to display tooltip when disabled */}
              <div>
                <IconButton
                  color="primary"
                  size="small"
                  data-testid="ask-ai-modal-generate-code-btn"
                  disabled={!!disabled || isGenerateBtnDisabled}
                  onClick={generateCode}>
                  <SendSharp
                    style={{
                      color: !!disabled || isGenerateBtnDisabled ? "rgba(0, 0, 0, 0.25)" : "#4646B5"
                    }}
                    fontSize="small"
                  />
                </IconButton>
              </div>
            </Tooltip>
          )
        }}
        disabled={!askAiEnabled}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          const val = event.target.value;
          setQuery(val);
          if (val) {
            const cursorPosition = event.target.selectionStart;
            if (cursorPosition) {
              const lastAtSymbolIndex = val.lastIndexOf("@", cursorPosition - 1);
              setAtIndex(lastAtSymbolIndex !== -1 ? lastAtSymbolIndex : null);
            }
          } else {
            setAtIndex(null);
          }
        }}
      />
      <ColumnSuggestionsPopper
        highlightedIndex={highlightedIndex}
        menuItemRefs={menuItemRefs}
        anchorElColumn={anchorElColumn}
        filteredSuggestions={filteredSuggestions}
        onClose={() => setAnchorElColumn(null)}
        onSuggestionClick={handleSuggestionClick}
      />
    </>
  );
};

export default React.memo(GenerateCodeBar);
