// Packages
import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { isArray } from "lodash";

// Utils
import api from "services/AxiosClient/AxiosClient";

// Open API
import { GlobalVariableDto } from "@rapidcanvas/rc-api-core";

export const enum UseGetVariablesQueryKeys {
  Variables = "variables"
}

type PrefetchingVariablesProps = { queryClient: $TSFixMe; projectId?: string };

interface IVariablesProps extends UseQueryOptions<unknown, unknown, GlobalVariableDto[]> {
  projectId?: string;
  projectRunId?: string;
  projectRunEntryId?: string;
}

export const prefetchVariables = (props: PrefetchingVariablesProps) => {
  const { queryClient, projectId } = props;

  queryClient.prefetchQuery({
    queryKey: [UseGetVariablesQueryKeys.Variables, projectId],
    queryFn: async () => {
      if (!projectId) {
        throw "Invalid projectId!";
      }

      return await api.fetchResponse(
        async () => await api.GlobalVariableControllerApi.findByProject(projectId)
      );
    }
  });
};

const useGetVariables = (props: IVariablesProps): UseQueryResult<GlobalVariableDto[]> => {
  const { projectId, projectRunId, projectRunEntryId, ...options } = props;

  return useQuery({
    queryKey: [UseGetVariablesQueryKeys.Variables, projectId, projectRunId, projectRunEntryId],
    queryFn: async () => {
      if (!projectId) {
        throw "Invalid projectId!";
      }

      return await api.fetchResponse(
        async () =>
          await api.GlobalVariableControllerApi.findByProject(
            projectId,
            projectRunId,
            projectRunEntryId
          )
      );
    },
    select: (data) => (isArray(data) ? data : []),
    enabled: !!projectId,
    ...options
  });
};

export default useGetVariables;
