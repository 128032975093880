import React from "react";
import { Grid, IconButton } from "@material-ui/core";

import useDataAppStatus from "hooks/api/dataapps/useDataAppStatus";
import { DataAppDtoLaunchStatusEnum } from "@rapidcanvas/rc-api-core";
import { Spinner } from "src/components";

interface IProps {
  id: string;
  launchStatus: DataAppDtoLaunchStatusEnum;
}

const DataAppTableStatus: React.FC<IProps> = (props) => {
  const { id, launchStatus } = props;

  const { currentStatusInfo, loading } = useDataAppStatus(id, launchStatus);

  return (
    <Grid container direction="row" justifyContent="space-between" alignItems="center">
      <Grid item className={currentStatusInfo?.className} data-testid="dataAppCurrentStatus">
        {currentStatusInfo?.text}
      </Grid>
      {currentStatusInfo?.icon && (
        <Grid item>
          <IconButton
            color="inherit"
            test-id="dataAppCurrentStatusUpdateBtn"
            className={currentStatusInfo?.iconClassName}
            size="small"
            disabled={loading}
            onClick={currentStatusInfo?.onClick}>
            {loading ? <Spinner size={18} noPadding /> : currentStatusInfo?.icon}
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};

export default DataAppTableStatus;
