import React, { useEffect, useState, useMemo } from "react";

// Packages
import { Link, useNavigate, useLocation } from "react-router-dom";
import shallow from "zustand/shallow";
import axios from "axios";
import { isEmpty, lowerCase, map, startCase } from "lodash";

// MUI
import {
  useTheme,
  Grid,
  List,
  ListItem,
  ListItemText,
  Button,
  TextField,
  CircularProgress,
  Typography
} from "@material-ui/core";

// Stores
import useAuthStore from "stores/zustand/auth.store";

// APIs
import { handleResponse } from "services/Apis/Apis.service";

// Components
import Wrapper from "./Wrapper";

// Constants
import { PatternRexExps, PublicScreenLabels, ResetPasswordPathStatuses } from "constants/index";

// Styles
import { useStyles } from "./ResetPassword.styles";
import { WebPaths } from "src/routing/routes";

type Props = {
  status?: number;
};

const ResetPasswordLink = (props: Props) => {
  const { status } = props || {};

  const theme = useTheme();
  const classes = useStyles();

  // @REFACTOR
  // Old code - STARTS >>
  const [resetPasswordEmail, setResetPasswordEmail] = useAuthStore(
    (state) => [state.resetPasswordEmail, state.setResetPasswordEmail],
    shallow
  );

  const [isSending, setIsSending] = useState(false);
  const [email, setEmail] = useState("");
  const [isEmailNotExisting, setIsEmailNotExisting] = useState(false);
  const [emailError, setEmailError] = useState("");
  const navigate = useNavigate();
  const search = useLocation().search;
  const details = new URLSearchParams(search).get("details");

  const handleEmailChange = (e: $TSFixMe) => {
    setIsEmailNotExisting(false);
    setEmail(e.target.value);
    setEmailError("");
  };

  const handleLinkSend = async (event: $TSFixMe) => {
    setIsSending(() => true);
    event.preventDefault();
    const isValid = PatternRexExps.Email.test(email);
    if (!isValid) {
      setIsSending(() => false);
      setEmailError(`${startCase(PublicScreenLabels.Email)} format is invalid!`);
      return;
    }
    try {
      await axios.post("/api/password/reset-link", {
        email: email,
        host: window.location.origin
      });
      setResetPasswordEmail(email);
      setIsSending(() => false);

      navigate(`${WebPaths.AuthPath}${WebPaths.ResetPasswordLinkSent}`);
    } catch (err: $TSFixMe) {
      const errorMessage = err.response.data.msg || err.message;
      if (errorMessage === "User does not exist.") {
        setIsEmailNotExisting(true);
        setIsSending(() => false);
        return;
      }
      handleResponse({ errorMessage });
    } finally {
      setIsSending(() => false);
    }
  };

  useEffect(() => {
    async function callToAction(details: $TSFixMe) {
      try {
        const serverResponse = await axios.get(
          `/api/password/reset-link/consent?details=${details}`
        );
        setEmail(serverResponse.data.email);
      } catch (err: $TSFixMe) {}
    }

    if (details) {
      callToAction(details);
    }
  }, [details]);
  // << ENDS - Old code

  const boldText = (text: string | null | undefined) => {
    if (!!text) {
      return (
        <Typography variant="inherit" style={{ fontWeight: 500 }}>
          {text}
        </Typography>
      );
    }

    return <></>;
  };

  const emailIdError = useMemo(() => {
    const errorsList = [];

    !!isEmailNotExisting &&
      errorsList.push(`User with provided ${lowerCase(PublicScreenLabels.Email)} does not exist!`);

    !!emailError && errorsList.push(emailError);

    if (!isEmpty(errorsList)) {
      return (
        <List dense disablePadding>
          {map(errorsList, (error: string | $TSFixMe, index: number) => (
            <ListItem key={`emailError-${index}`} disableGutters>
              <ListItemText primary={error} disableTypography style={{ marginTop: 0 }} />
            </ListItem>
          ))}
        </List>
      );
    }

    return "";
  }, [isEmailNotExisting, emailError]);

  if (status === ResetPasswordPathStatuses.Send) {
    return (
      <Wrapper>
        <Grid item className={classes.titleContainer}>
          <Typography
            variant="h5"
            component="div"
            align="center"
            data-testid="resetPasswordLinkTitle">
            Reset your {lowerCase(PublicScreenLabels.Password)}
          </Typography>
        </Grid>

        <Grid
          container
          component="form"
          className={classes.formContainer}
          onSubmit={handleLinkSend}>
          <Typography
            variant="body2"
            align="center"
            className={classes.message}
            data-testid="resetPasswordLinkMessageLine1">
            To reset {lowerCase(PublicScreenLabels.Password)}, enter the{" "}
            {lowerCase(PublicScreenLabels.Email)} you use to {lowerCase(PublicScreenLabels.SignIn)}.
          </Typography>

          <TextField
            id="resetPasswordLinkEmail"
            data-testid="resetPasswordLinkEmail"
            label={startCase(PublicScreenLabels.Email)}
            variant="outlined"
            size="small"
            color="primary"
            fullWidth
            // eslint-disable-next-line
            autoFocus
            autoComplete="off"
            onChange={handleEmailChange}
            {...(!!emailIdError
              ? {
                  error: true,
                  helperText: emailIdError
                }
              : {})}
          />

          <Button
            id="resetPasswordLinkAction"
            data-testid="resetPasswordLinkAction"
            type="submit"
            variant="contained"
            color="primary"
            size="small"
            fullWidth
            className={classes.action}
            disabled={!email || !!emailError || !!isEmailNotExisting}>
            {!!isSending ? (
              <>
                <CircularProgress size={16} style={{ marginRight: 5 }} />
                Sending...
              </>
            ) : (
              "Get a Reset Link"
            )}
          </Button>
        </Grid>
      </Wrapper>
    );
  } else if (status === ResetPasswordPathStatuses.Sent) {
    return (
      <Wrapper>
        <Grid item className={classes.titleContainer}>
          <Typography
            variant="h5"
            component="div"
            align="center"
            data-testid="resetPasswordLinkTitle">
            Reset link sent
          </Typography>
        </Grid>

        <Grid container justifyContent="center" style={{ rowGap: theme.spacing(2) }}>
          <Typography variant="body2" align="center" data-testid="resetPasswordLinkMessageLine1">
            Check{" "}
            {!!resetPasswordEmail
              ? boldText(resetPasswordEmail)
              : `your ${lowerCase(PublicScreenLabels.Email)}`}
            , for instructions on how to reset your {lowerCase(PublicScreenLabels.Password)}.
          </Typography>
          <Typography variant="body2" data-testid="resetPasswordLinkMessageLine2">
            Wrong {lowerCase(PublicScreenLabels.Email)}?{" "}
            <Link
              id="resetPasswordLinkForgotPasswordLink"
              data-testid="resetPasswordLinkForgotPasswordLink"
              to="/auth/reset-password-link-send"
              className={classes.link}>
              Re-enter {lowerCase(PublicScreenLabels.Email)}
            </Link>
          </Typography>
        </Grid>
      </Wrapper>
    );
  } else {
    return <></>;
  }
};

export default ResetPasswordLink;
