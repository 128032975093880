import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

import api from "services/AxiosClient/AxiosClient";
import {
  GlobalSearchResultDto,
  SearchRequestDtoSearchComponentsEnum
} from "@rapidcanvas/rc-api-core";
import { getErrorMessage, handleErrorWithRedirectToLogs } from "services/Apis/Apis.service";

const QUERY_KEY_GLOBAL_SEARCH = "query-key-global-search";
const useGlobalSearch = (
  name: string,
  searchComponents: SearchRequestDtoSearchComponentsEnum[],
  options?: UseQueryOptions<GlobalSearchResultDto>
): UseQueryResult<GlobalSearchResultDto> =>
  useQuery({
    queryKey: [QUERY_KEY_GLOBAL_SEARCH, name, searchComponents],
    queryFn: async () => {
      try {
        const response = await api.fetchResponse(
          async () =>
            await api.GlobalSearchControllerApi.search({
              name,
              searchComponents
            }),
          false
        );

        return response;
      } catch (e: any) {
        if (e?.response?.status >= 400 && e?.response?.status < 500) {
          return {};
        } else {
          handleErrorWithRedirectToLogs(getErrorMessage(e));
          throw "Error";
        }
      }
    },
    staleTime: 0, // necessary
    enabled: name?.length > 2,
    refetchOnMount: true,
    ...options
  });

export default useGlobalSearch;
