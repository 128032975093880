import React, { useMemo } from "react";
import { Link } from "react-router-dom";

import Text from "src/components/Widget/Text";
import styles from "../../../common/Environments.module.scss";
import { dateFormat } from "utils/helpers/date.helpers";
import { WebPaths } from "src/routing/routes";
import { generatePath } from "react-router";
import { Project } from "./EnvironmentTabs";
import { CellContext } from "@tanstack/react-table";
import Table from "components/custom/TableV8/Table";

export const EnvironmentProjectsTable = ({ data }: { data: Project[] }) => {
  const columns = useMemo(
    () => [
      {
        id: "Project Name",
        accessorKey: "name",
        header: "Project Name",

        cell: ({ row, getValue }: CellContext<any, string>) => (
          <Link
            className={styles.nameLinkStyles}
            to={generatePath(`${WebPaths.ProjectCanvas}`, {
              projectId: row?.original?.id!
            })}>
            <Text value={getValue()} />
          </Link>
        )
      },
      {
        id: "Description",
        accessorKey: "description",
        header: "Description"
      },
      {
        id: "Creator",
        accessorKey: "creator",
        header: "Creator"
      },
      {
        id: "Creation Date",
        accessorKey: "createAt",
        header: "Creation Date",
        cell: ({ getValue }: CellContext<any, number>) => <span>{dateFormat(getValue())}</span>
      },
      {
        id: "Scheduler Count",
        accessorKey: "jobCount",
        header: "Scheduler Count",
        cell: ({ row, getValue }: CellContext<any, any>) => {
          const value = getValue();
          return value ? (
            <Link className={styles.nameLinkStyles} to={`/projects/${row?.original?.id}/jobs`}>
              <Text value={value.toString()} />
            </Link>
          ) : (
            <React.Fragment>0</React.Fragment>
          );
        }
      },
      {
        id: "Prediction Run Count",
        accessorKey: "predictionJobCount",
        header: "Prediction Run Count",
        cell: ({ row, getValue }: CellContext<any, any>) => {
          const value = getValue();

          return value ? (
            <Link
              className={styles.nameLinkStyles}
              to={`/projects/${row?.original?.id}/prediction-job`}>
              <Text value={value.toString()} />
            </Link>
          ) : (
            <React.Fragment>0</React.Fragment>
          );
        }
      }
    ],
    []
  );

  return (
    <section style={{ overflow: "auto", paddingRight: 24 }}>
      <Table
        columns={columns}
        data={data}
        sortingProps={{ orderByDefault: "Creation Date", sortInverted: false }}
        stylesProps={{ showBorder: true }}
      />
    </section>
  );
};
