import { useCallback } from "react";
import useAuthStore from "stores/zustand/auth.store";
import shallow from "zustand/shallow";

import api from "services/AxiosClient/AxiosClient";
import { Roles } from "src/types/Role.type";

export const useAuthSetAuthResponse = () => {
  const [
    logoutTimer,
    setIsUserLoggedIn,
    setExpiresAt,
    setTenantId,
    setTenantIdPermanent,
    setUserId,
    setTenantNameUserEmail,
    setNotebookType,
    setCpuGuarantee,
    setMemGuarantee,
    setRoleName,
    setPrivileges,
    setDataappsTtlInHrs
  ] = useAuthStore(
    useCallback(
      (state) => [
        state.logoutTimer,
        state.setIsUserLoggedIn,
        state.setExpiresAt,
        state.setTenantId,
        state.setTenantIdPermanent,
        state.setUserId,
        state.setTenantNameUserEmail,
        state.setNotebookType,
        state.setCpuGuarantee,
        state.setMemGuarantee,
        state.setRoleName,
        state.setPrivileges,
        state.setDataappsTtlInHrs
      ],
      []
    ),
    shallow
  );
  const setAuthResponse = ({ data }: { data: any }) => {
    const {
      token,
      userId,
      userEmail,
      tenantId,
      tenantName,
      expiresAt,
      notebookType,
      cpuGuarantee,
      memGuarantee,
      roleName,
      dataappsTtlInHrs
    } = data;

    setRoleName(roleName);
    setPrivileges([Roles.Admin.name, Roles.Demo.name]?.includes(roleName));
    setUserId(userId);
    setTenantId(tenantId);
    setTenantIdPermanent(tenantId);
    setTenantNameUserEmail(tenantName, userEmail);
    setNotebookType(notebookType);
    setExpiresAt(expiresAt, logoutTimer);
    setCpuGuarantee(cpuGuarantee);
    setMemGuarantee(memGuarantee);
    setDataappsTtlInHrs(dataappsTtlInHrs);
    setIsUserLoggedIn(true);

    api.setToken(token);
  };
  return { setAuthResponse };
};
