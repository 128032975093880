import React, { useMemo } from "react";
import _ from "lodash";
import { FormControl, InputLabel, MenuItem, OutlinedInput, Select } from "@material-ui/core";
import shallow from "zustand/shallow";

import Text from "components/Widget/Text";
import useTenantsStore from "stores/zustand/tenant-management.store";
import { projectMetadata } from "../utils/CopyDownloadProject.constants";

interface IProps {
  value: string;
  onChange: (key: string, value: string) => void;
}

interface ITenant {
  id: string;
  name: string;
  invitationStatus: string;
  hasValidLicense: boolean;
}

const CopyTenant: React.FC<IProps> = (props) => {
  const [userTenants] = useTenantsStore((state) => [state.userTenants], shallow);

  const validLicenseTenants = useMemo(() => {
    const filtered = _.filter(
      userTenants,
      (tenant: ITenant) =>
        _.get(tenant, "invitationStatus") === "ACTIVE" && _.get(tenant, "hasValidLicense")
    );
    return _.sortBy(filtered, (tenant: ITenant) => _.toUpper(tenant.name));
  }, [userTenants]);

  const handleChange = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    props.onChange(projectMetadata.tenant.id, e.target.value as string);
  };

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel shrink htmlFor={projectMetadata.tenant.id}>
        Workspace *
      </InputLabel>
      <Select
        label={projectMetadata.tenant.label}
        required
        fullWidth
        id={projectMetadata.tenant.id}
        name={projectMetadata.tenant.id}
        value={props.value}
        error={_.isEmpty(props.value)}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          },
          getContentAnchorEl: null
        }}
        input={<OutlinedInput notched label="Workspace *" />}
        displayEmpty
        onChange={handleChange}>
        {_.map(validLicenseTenants, (tenant: ITenant) => (
          <MenuItem key={tenant.id} value={tenant.id}>
            <Text value={_.capitalize(tenant.name)} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CopyTenant;
