import React, { useEffect, useMemo } from "react";
import _ from "lodash";
import { FormControl, FormControlLabel, Radio, RadioGroup, Tooltip } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";

import TextField from "./TextFieldWithValidation";
import {
  CONNECTOR_KEYS,
  LENGTH_ERROR_MESSAGE,
  MAX_LONG_LENGTH,
  MIN_LENGTH
} from "../utils/OutputDataset.constants";
import { hasLengthError } from "./SQLRedshiftConnectorDetails";

export interface IConnectorDetailsProps {
  isTextInput?: boolean;
  values: Record<string, string>;
  onChange: (e: any) => void;
  readonly?: boolean;
}

const SnowflakeConnectorDetails: React.FC<IConnectorDetailsProps> = (props) => {
  const { values, onChange, readonly = false } = props;

  useEffect(() => {
    if (!_.has(values, CONNECTOR_KEYS.snowFlakeCategory.type.key)) {
      const e = {
        target: {
          name: CONNECTOR_KEYS.snowFlakeCategory.type.key,
          value: CONNECTOR_KEYS.snowFlakeCategory.type.values.append
        }
      };
      onChange(e);
    }
  }, []);

  const tableValue = _.get(values, CONNECTOR_KEYS.snowFlakeCategory.tableName.key, "");
  const { error, helperText } = useMemo(() => {
    const trimmed = _.trim(tableValue);
    if (_.size(trimmed) === 0) {
      return { error: false, helperText: "" };
    }

    const regex = CONNECTOR_KEYS.snowFlakeCategory.tableName.regex;
    const lengthError = hasLengthError(tableValue);

    const isValid = regex.test(tableValue);

    return {
      error: lengthError || !isValid,
      helperText: lengthError
        ? LENGTH_ERROR_MESSAGE
        : isValid
          ? ""
          : CONNECTOR_KEYS.snowFlakeCategory.tableName.errorMessage
    };
  }, [tableValue]);

  const dbValue = _.get(values, CONNECTOR_KEYS.snowFlakeCategory.databaseName.key, "");
  const { dError, dHelperText } = useMemo(() => {
    const trimmed = _.trim(dbValue);
    if (_.size(trimmed) === 0) {
      return { error: false, helperText: "" };
    }
    const regex = CONNECTOR_KEYS.snowFlakeCategory.databaseName.regex;
    const lengthError = hasLengthError(dbValue);
    const isValid = regex.test(dbValue);

    return {
      dError: lengthError || !isValid,
      dHelperText: lengthError
        ? LENGTH_ERROR_MESSAGE
        : isValid
          ? ""
          : CONNECTOR_KEYS.snowFlakeCategory.databaseName.errorMessage
    };
  }, [dbValue]);

  const schemaValue = _.get(values, CONNECTOR_KEYS.snowFlakeCategory.schema.key, "");
  const { schemaError, schemaHelperText } = useMemo(() => {
    const trimmed = _.trim(schemaValue);
    if (_.size(trimmed) === 0) {
      return { error: false, helperText: "" };
    }
    const regex = CONNECTOR_KEYS.snowFlakeCategory.schema.regex;
    const lengthError = hasLengthError(schemaValue);
    const isValid = regex.test(schemaValue);

    return {
      schemaError: lengthError || !isValid,
      schemaHelperText: lengthError
        ? LENGTH_ERROR_MESSAGE
        : isValid
          ? ""
          : CONNECTOR_KEYS.snowFlakeCategory.schema.errorMessage
    };
  }, [schemaValue]);

  const whValue = _.get(values, CONNECTOR_KEYS.snowFlakeCategory.warehouse.key, "");
  const { whError, whHelperText } = useMemo(() => {
    const trimmed = _.trim(whValue);
    if (_.size(trimmed) === 0) {
      return { error: false, helperText: "" };
    }
    const lengthError = hasLengthError(whValue, MAX_LONG_LENGTH);

    return {
      whError: lengthError,
      whHelperText: lengthError
        ? `Please enter between ${MIN_LENGTH} and ${MAX_LONG_LENGTH} characters`
        : ""
    };
  }, [whValue]);

  const roleValue = _.get(values, CONNECTOR_KEYS.snowFlakeCategory.role.key, "");
  const { roleError, roleHelperText } = useMemo(() => {
    const trimmed = _.trim(roleValue);
    if (_.size(trimmed) === 0) {
      return { error: false, helperText: "" };
    }
    const lengthError = hasLengthError(roleValue);

    return {
      roleError: lengthError,
      roleHelperText: lengthError ? LENGTH_ERROR_MESSAGE : ""
    };
  }, [roleValue]);

  return (
    <>
      <TextField
        fullWidth
        id="table"
        label={CONNECTOR_KEYS.snowFlakeCategory.tableName.label}
        name={CONNECTOR_KEYS.snowFlakeCategory.tableName.key}
        required
        size="small"
        data-testid="table"
        value={tableValue}
        error={error}
        helperText={helperText}
        variant="outlined"
        onChange={onChange}
        disabled={!!readonly}
      />
      <TextField
        fullWidth
        id="database"
        label={CONNECTOR_KEYS.snowFlakeCategory.databaseName.label}
        name={CONNECTOR_KEYS.snowFlakeCategory.databaseName.key}
        required
        size="small"
        data-testid="database"
        value={dbValue}
        error={dError}
        helperText={dHelperText}
        variant="outlined"
        onChange={onChange}
        disabled={!!readonly}
      />
      <TextField
        fullWidth
        id="schema"
        label={CONNECTOR_KEYS.snowFlakeCategory.schema.label}
        name={CONNECTOR_KEYS.snowFlakeCategory.schema.key}
        required
        size="small"
        data-testid="schema"
        value={schemaValue}
        error={schemaError}
        helperText={schemaHelperText}
        variant="outlined"
        onChange={onChange}
        disabled={!!readonly}
      />
      <TextField
        fullWidth
        id="warehouse"
        label={CONNECTOR_KEYS.snowFlakeCategory.warehouse.label}
        name={CONNECTOR_KEYS.snowFlakeCategory.warehouse.key}
        size="small"
        required
        data-testid="warehouse"
        value={whValue}
        error={whError}
        helperText={whHelperText}
        variant="outlined"
        onChange={onChange}
        disabled={!!readonly}
      />
      <TextField
        fullWidth
        id="role"
        label={CONNECTOR_KEYS.snowFlakeCategory.role.label}
        name={CONNECTOR_KEYS.snowFlakeCategory.role.key}
        size="small"
        data-testid="role"
        value={roleValue}
        error={roleError}
        helperText={roleHelperText}
        variant="outlined"
        onChange={onChange}
        disabled={!!readonly}
      />
      {!readonly && (
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="demo-controlled-radio-buttons-group"
            name={CONNECTOR_KEYS.snowFlakeCategory.type.key}
            value={_.get(
              values,
              CONNECTOR_KEYS.snowFlakeCategory.type.key,
              CONNECTOR_KEYS.snowFlakeCategory.type.values.append
            )}
            onChange={onChange}>
            <FormControlLabel
              value={CONNECTOR_KEYS.snowFlakeCategory.type.values.append}
              control={<Radio />}
              label={_.capitalize(CONNECTOR_KEYS.snowFlakeCategory.type.values.append)}
            />
            <FormControlLabel
              value={CONNECTOR_KEYS.snowFlakeCategory.type.values.replace}
              control={<Radio />}
              label={_.capitalize(CONNECTOR_KEYS.snowFlakeCategory.type.values.replace)}
            />
            <Tooltip
              title={
                <>
                  <div>
                    Append action is applicable only for same schema. It will fail in case of schema
                    mismatch.
                  </div>
                  <div>
                    Replace will completely overwrite your existing data and schema with the new
                    one.
                  </div>
                </>
              }>
              <InfoOutlined style={{ marginTop: "9px" }} />
            </Tooltip>
          </RadioGroup>
        </FormControl>
      )}
    </>
  );
};

export default SnowflakeConnectorDetails;
