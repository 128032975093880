import React, { useMemo, useState } from "react";

// Packages
import { useLocation, useNavigate, useParams, matchPath } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import clsx from "clsx";
import { map, camelCase, size } from "lodash";

// MUI
import {
  Paper,
  List,
  ListItem,
  CircularProgress,
  Typography,
  Grid,
  Button,
  useMediaQuery
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

// Icons
import { CanvasIcon } from "icons/NewUX/CanvasIcon";
import { ClockIcon } from "icons/NewUX/ClockIcon";
import { DataappsIcon } from "icons/NewUX/DataappsIcon";
import { InfoIcon } from "icons/NewUX/InfoIcon";
import { SettingsIcon } from "icons/NewUX/SettingsIcon";
import { WorkflowIcon } from "icons/NewUX/WorkflowIcon";
import PredictRecipeIcon from "icons/NewUX/PredictRecipeIcon";

// Utils
import Analytics from "services/Analytics/Analytics";
import { toastWrapper } from "services/ToastClient/toastWrapper";
import { IProjectMenuItem, ProjectMeuItemLabels } from "../../utils/NavBars.constants";

// Stores
import { useProjectsStore } from "stores/zustand/stores";
import { shouldRefreshProjectsToggler } from "stores/zustand/stores.selectors";

// Hooks
import { useDeleteProject } from "src/hooks/api";
import { useGetRole } from "src/hooks/useGetRole";

// Helpers
import { deleteProjectMessageLines } from "src/pages/private/ProjectsModule/utils/Projects.helpers";

// Components
import Modal, { ModalVariants } from "src/components/custom/Modal/Modal";
import ProjectSettings from "src/pages/private/ProjectsModule/pages/ProjectSettings/ProjectSettings";

// Constants
import { EVENTS } from "constants/analyticsEvents.constants";

// Styles
import { useStyles } from "./ProjectNavBar.styles";
import { Roles } from "src/types";

// Context
import { useProjectContext } from "src/pages/private/ProjectsModule/context/useProjectContext";
import { WebPaths } from "src/routing/routes";
import useProjectDetails from "src/hooks/api/projects/useProjectDetails";
import { ArtifactsAndModelsIcon } from "icons/NewUX";

const ProjectNavBar = () => {
  const { projectId: projectIdFromUrl } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [hovered, setHovered] = useState(false);
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const isLg = useMediaQuery(theme.breakpoints.down("lg"));

  const { btnSize, width, height, btnHeight } = useMemo(() => {
    if (isSm) {
      return { btnSize: "small" as const, width: 14, height: 14, btnHeight: 24 };
    }

    if (isMd) {
      return { btnSize: "medium" as const, width: 16, height: 16, btnHeight: 32 };
    }

    return { btnSize: "large" as const, width: 20, height: 20, btnHeight: 36 };
  }, [isLg, isMd, isSm]);

  const classes = useStyles({ marginLeft: height === 20 ? "4px" : "2px" });

  // Project context
  const { project } = useProjectContext() || {};
  const projectId = useMemo(() => project?.id, [project]);
  const { data } = useProjectDetails(projectIdFromUrl, {
    refetchOnMount: true,
    enabled: !!projectIdFromUrl
  });

  // Stores - STARTS >>
  const toggleShouldProjectsRefresh = useProjectsStore(shouldRefreshProjectsToggler);
  // << ENDS - Stores

  // States - STARTS >>
  const [isDeleteProjectConfirmModalOpen, setIsDeleteProjectConfirmModalOpen] = useState(false);
  const [projectSettingsOpen, setProjectSettingsOpen] = useState(false);
  // << ENDS - States

  // Query hooks - STARTS >>
  // Mutations
  const { canAccessWithRole } = useGetRole();
  const {
    isLoading: isProjectDeleting,
    mutateAsync: deleteProjectMutation,
    reset: resetDeleteProjectMutation
  } = useDeleteProject();
  // << ENDS - Query hooks

  const projectRoutePaths: IProjectMenuItem[] = useMemo(
    () => [
      {
        pathName: "/canvas",
        path: `/projects/${projectId}/canvas`,
        label: ProjectMeuItemLabels.Canvas,
        allowedRoles: [
          Roles.Admin.name,
          Roles.Demo.name,
          Roles.User.name,
          Roles["Business User"].name
        ],
        icon: () => <CanvasIcon width={width} height={height} viewBox="0 0 16 12" />
      },
      {
        pathName: "/scenarios",
        path: `/projects/${projectId}/scenarios`,
        label: ProjectMeuItemLabels.Scenarios,
        allowedRoles: [Roles.Admin.name, Roles.Demo.name, Roles.User.name],
        icon: () => (
          <WorkflowIcon
            width={width}
            height={height}
            viewBox={width === 20 ? `0 0 ${width} ${height}` : undefined}
          />
        )
      },
      {
        pathName: "/jobs",
        path: `/projects/${projectId}/jobs`,
        label: ProjectMeuItemLabels.Job,
        allowedRoles: [
          Roles.Admin.name,
          Roles.Demo.name,
          Roles.User.name,
          Roles["Business User"].name
        ],
        icon: () => <ClockIcon width={width} height={height} viewBox="0 0 14 14" />,
        size: size(data?.projectJobs)
      },
      {
        pathName: "/prediction-job",
        path: `/projects/${projectId}/prediction-job`,
        label: ProjectMeuItemLabels.PredictionJob,
        allowedRoles: [
          Roles.Admin.name,
          Roles.Demo.name,
          Roles.User.name,
          Roles["Business User"].name
        ],
        icon: () => <PredictRecipeIcon width={width} height={height} />,
        size: size(data?.predictionJobs)
      },
      {
        pathName: "/project-dataapps",
        path: `/projects/${projectId}/project-dataapps`,
        label: ProjectMeuItemLabels.DataApps,
        allowedRoles: [
          Roles.Admin.name,
          Roles.Demo.name,
          Roles.User.name,
          Roles["Business User"].name
        ],
        icon: () => (
          <DataappsIcon
            width={width}
            height={height}
            viewBox={width === 20 ? `0 0 ${width - 2} ${height - 2}` : undefined}
          />
        ),
        size: size(data?.dataApps)
      },
      {
        pathName: "/artifacts-and-models",
        path: `/projects/${projectId}/artifacts-and-models/artifacts`,
        label: ProjectMeuItemLabels.ArtifactsAndModels,
        allowedRoles: [
          Roles.Admin.name,
          Roles.Demo.name,
          Roles.User.name,
          Roles["Business User"].name
        ],
        icon: () => (
          <ArtifactsAndModelsIcon
            width={width}
            height={height}
            viewBox={width === 20 ? `0 0 ${width - 2} ${height - 2}` : undefined}
          />
        )
      },
      {
        pathName: "",
        path: "",
        label: ProjectMeuItemLabels.Settings,
        allowedRoles: [
          Roles.Admin.name,
          Roles.Demo.name,
          Roles.User.name,
          Roles["Business User"].name
        ],
        icon: () => (
          <SettingsIcon
            width={width}
            height={height}
            viewBox={width === 20 ? `0 0 ${width + 2} ${height + 2}` : undefined}
          />
        )
      },
      {
        pathName: "/about",
        path: `/projects/${projectId}/about`,
        label: ProjectMeuItemLabels.About,
        allowedRoles: [
          Roles.Admin.name,
          Roles.Demo.name,
          Roles.User.name,
          Roles["Business User"].name
        ],
        icon: () => <InfoIcon width={width} height={height} />
      }
    ],
    [projectId, data, width, height]
  );

  const filteredProjectRoutePaths = useMemo(
    () =>
      projectRoutePaths
        ?.filter((path: IProjectMenuItem) => !!path?.pathName)
        ?.map((path: IProjectMenuItem) => path?.path),
    [projectRoutePaths]
  );

  const allowedProjectRoutes = useMemo(
    () => projectRoutePaths.filter((path) => canAccessWithRole(path.allowedRoles)),
    [projectRoutePaths]
  );

  if (
    !filteredProjectRoutePaths?.includes(location.pathname) &&
    // @REFACTOR
    // The below condition must be removed and handled with routes.
    !matchPath("/projects/:projectId/scenario/:scenarioId/canvas", location.pathname) &&
    !matchPath(`/projects/:projectId/artifacts-and-models/artifacts`, location.pathname) &&
    !matchPath(`/projects/:projectId/artifacts-and-models/models`, location.pathname)
  ) {
    return <></>;
  }

  const onProjectMenuItemClick = (item: IProjectMenuItem) => {
    Analytics.track({
      category: EVENTS.internalNavigation.category,
      action: EVENTS.internalNavigation.action,
      label: item?.label
    });

    if (item?.label === ProjectMeuItemLabels.Settings) {
      setProjectSettingsOpen(() => true);
      setHovered(false);
    } else {
      navigate(item?.path);
    }
  };

  const isActive = (item: IProjectMenuItem) => {
    return !!item?.pathName && !!location.pathname?.includes(item?.pathName);
  };

  const onDeleteProjectConfirmModalClose = () => {
    setIsDeleteProjectConfirmModalOpen(() => false);
  };

  const onDeleteProject = async () => {
    resetDeleteProjectMutation();

    await deleteProjectMutation(
      { projectId },
      {
        onSuccess: () => {
          toastWrapper({ type: "success", content: "Project deleted successfully!" });
          navigate(WebPaths.Projects);
          toggleShouldProjectsRefresh();
        },
        onSettled: () => {
          onDeleteProjectConfirmModalClose();
        }
      }
    );

    onDeleteProjectConfirmModalClose();
  };

  return (
    <>
      {!!projectId && !!projectSettingsOpen && (
        <ProjectSettings
          projectId={projectId}
          onClose={() => {
            setProjectSettingsOpen(() => false);
          }}
        />
      )}

      {isDeleteProjectConfirmModalOpen && (
        <Modal
          open={true}
          variant={ModalVariants.Delete}
          title="Delete Project"
          content={deleteProjectMessageLines(project?.name)}
          isSubmitting={isProjectDeleting}
          onClose={onDeleteProjectConfirmModalClose}
          onSubmit={onDeleteProject}
        />
      )}

      {allowedProjectRoutes.length > 0 && (
        <Paper elevation={4} style={{}} className={classes.root}>
          <List
            style={{ padding: "4px" }}
            component="nav"
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}>
            {map(allowedProjectRoutes, (item) => (
              <ListItem key={uuidv4()} disableGutters dense className={classes.menuItem}>
                <Typography component={"div"} style={{ width: "100%" }} color="textPrimary">
                  <Grid container direction="column">
                    <Button
                      style={{
                        height: btnHeight,
                        padding: btnSize === "large" ? "2px 8px" : undefined
                      }}
                      size={btnSize}
                      data-testid={camelCase(`project${item.label}`)}
                      className={clsx(
                        classes.menuItemButton,
                        { ["active"]: isActive(item) },
                        { ["size"]: item.size && item.size > 0 }
                      )}
                      startIcon={
                        !item?.pathName && isProjectDeleting ? (
                          <CircularProgress
                            size={16}
                            color="secondary"
                            className={classes.loader}
                          />
                        ) : (
                          item.icon(isActive(item))
                        )
                      }
                      disabled={!projectId}
                      color="inherit"
                      onClick={() => onProjectMenuItemClick(item)}>
                      <span style={{ marginLeft: hovered ? "8px" : undefined }}>
                        {hovered ? item.label : null}
                      </span>
                    </Button>
                    {!!item.size && item.size > 0 && (
                      <span
                        style={{
                          fontSize: "9px",
                          marginLeft: btnSize === "large" ? "14px" : "10px"
                        }}>
                        {item.size}
                      </span>
                    )}
                  </Grid>
                </Typography>
              </ListItem>
            ))}
          </List>
        </Paper>
      )}
    </>
  );
};

export default ProjectNavBar;
