import { useMutation } from "@tanstack/react-query";

import { putAPIWithRethrow } from "services/Apis/Apis.service";
import { UserSettings } from "src/types/User.type";

export const useSaveUserSettingsMutation = () => {
  return useMutation({
    mutationFn: (settings: UserSettings) => {
      return putAPIWithRethrow(`/user-settings`, settings);
    }
  });
};
