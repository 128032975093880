import React from "react";
import _ from "lodash";
import { Box, Grid, makeStyles } from "@material-ui/core";

import AIGuideTextRow from "src/pages/Projects/AIGuide/common/AIGuideTextRow";
import ChatBox from "src/pages/Projects/AIGuide/common/ChatBox";
import { TextIconV2 } from "icons/NewUX/TextIconV2";
import { PromptsHeader } from "./PromptsHeader";
import { PromptsList } from "./PromptsList";

const useStyles = makeStyles({
  promptsRow: {
    padding: "12px 32px 0px 16px",
    width: "inherit",
    gap: "12px",
    flexWrap: "nowrap"
  },
  promptsList: {
    flexWrap: "nowrap",
    padding: ({ isExpanded }: { isExpanded: boolean }) => (isExpanded ? "12px" : "4px 8px"),
    gap: "8px"
  },
  textIcon: {
    height: "32px",
    width: "32px",
    marginRight: "4px"
  }
});

export const PromptSuggestions = ({
  isExpanded,
  inputNames,
  promts,
  handleToggle,
  setShowDeleteModal,
  userInput = "Prompt Suggestions",
  tags,
  autoGenerateCode,
  isGenerating
}: {
  inputNames: string[];
  isExpanded: boolean;
  promts: Array<{ title: string; description: string }>;
  handleToggle: () => void;
  setShowDeleteModal?: (show: boolean) => void;
  userInput?: string;
  tags?: string[];
  autoGenerateCode: (text: string, inputNames: string[]) => void;
  isGenerating: boolean;
}) => {
  const classes = useStyles({ isExpanded });
  return (
    <Grid container direction="column">
      <AIGuideTextRow
        userInput={userInput}
        queryInputs={_.map(inputNames, (name) => ({ name }))}
        setShowDeleteModal={setShowDeleteModal}
        canDelete={true}
        canCopy={true}
        maxHeight="132px"
        color="#fff"
        width="750px"
        hoverBgColor="#fff"
        tags={tags}
      />
      <Grid container direction="row" className={classes.promptsRow}>
        <Box className={classes.textIcon}>
          <TextIconV2 />
        </Box>
        <ChatBox
          noPadding
          color={"#FFF"}
          border={isExpanded ? "#4646B5" : "#fff"}
          width={isExpanded ? "calc(100% - 160px)" : "auto"}
          childWidth="100%">
          <Grid container direction="column" className={classes.promptsList}>
            <PromptsHeader onToggle={handleToggle} isExpanded={isExpanded} />
            {isExpanded && (
              <PromptsList
                prompts={promts}
                isGenerating={isGenerating}
                autoGenerateCode={(text) => autoGenerateCode(text, inputNames)}
              />
            )}
          </Grid>
        </ChatBox>
      </Grid>
    </Grid>
  );
};
