import React from "react";
import shallow from "zustand/shallow";

import useJoinTenant from "hooks/api/user/useJoinTenant";
import useNotificationStore from "stores/zustand/notification.store";
import { RequestState } from "src/types/RequestState";
import { logNotebooksOut } from "services/Notebook/Notebook.service";
import { shouldRefreshProjectsSetter } from "stores/zustand/stores.selectors";
import { useProjectsStore } from "stores/zustand/stores";
import { useAuthSetAuthResponse } from "./useAuthSetAuthResponse";

export const useAuthAcceptJoinTenantRequest = ({ invitationDetails }: $TSFixMe) => {
  const [requestState, setRequestState] = React.useState<RequestState>(RequestState.Idle);
  const setNotification = useNotificationStore(
    React.useCallback((state) => state.setNotification, []),
    shallow
  );
  const setShouldRefreshProjects = useProjectsStore(shouldRefreshProjectsSetter);

  const { setAuthResponse } = useAuthSetAuthResponse();

  const { data } = useJoinTenant(invitationDetails, undefined, {
    enabled: !!invitationDetails,
    onSuccess: (results: any) => {
      setAuthResponse({
        data: results
      });
      setShouldRefreshProjects(true);
      setRequestState(RequestState.Success);
      logNotebooksOut();
      setNotification({
        type: "Dashboard",
        message: `You are added to "${results.tenantName}" tenant successfully`
      });
    },
    onError: (error: any) => {
      console.error(error);
      setRequestState(RequestState.Failure);
    }
  });

  return {
    hasInviteParams: !!invitationDetails,
    requestState,
    data
  };
};
