import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

import { getAPIWithRethrow } from "services/Apis/Apis.service";

const QUERY_KEY_ENTITY_DETAILS = "query-key-entity-details";

export interface IEntityDetails {
  built: number;
  columns: number;
  created: number;
  rootEntity: boolean;
  markdown: string;
  parentTransform: string;
  rows: number;
  totalSize: { unit: string; size: number };
  updated: number;
  dataSourceId?: string;
  fileExtension: string;
  vectorStoreCollectionName: string;
}

const useEntityDetails = (
  entityId?: string,
  scenarioId?: string,
  jobRunId?: string,
  options?: UseQueryOptions<IEntityDetails>
): UseQueryResult<IEntityDetails> => {
  return useQuery({
    queryKey: [QUERY_KEY_ENTITY_DETAILS, entityId, scenarioId, jobRunId],
    queryFn: async () => {
      return await getAPIWithRethrow(
        `/v2/entities/${entityId}/details?scenarioId=${scenarioId}${
          jobRunId ? `&projectRunEntryId=${jobRunId}` : ""
        }`,
        undefined,
        !!jobRunId
      );
    },
    ...options
  });
};

export default useEntityDetails;
export { QUERY_KEY_ENTITY_DETAILS };
