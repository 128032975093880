import React, { useMemo, useState } from "react";

// Packages
import clsx from "clsx";
import shallow from "zustand/shallow";
import { get, has, includes, isEmpty, isFunction, size } from "lodash";

// MUI
import { Typography } from "@material-ui/core";

// Utils
import { addEllipsis, handleClickClosure } from "utils/helpers";
import { ChartStatuses, NodeChartTheme } from "src/pages/private/ProjectsModule/utils";

// Stores
import { useCanvasStore } from "stores/zustand/stores";

// Hooks
import { useNodeMultiSelection } from "projectsModule/hooks";
import { useCtrlKeyPress } from "src/hooks/useCtrlKeyPress";
import useAttributes from "./useAttributes";
import useActions from "./useActions";
import useContextMenu from "../useContextMenu";

// Components
import NodesWrapper from "../NodesWrapper";
import ContextMenuWrapper from "../ContextMenuWrapper";
import ContextMenu from "./ContextMenu";
import NodeActionWrapper from "../NodeActionWrapper";
import ExpandCollapseIconButton from "../ExpandCollapseIconButton";
import NodeCaptionOverflowTooltip from "../NodeCaptionOverflowTooltip";
import DeselectNodesConfirmModal from "../../DeleteNodes/DeselectNodesConfirmModal";

// Context
import { useDagFlowContext } from "../../DagFlow/context/useDagFlowContext";

// Types
import { NodeData } from "src/types";

// Styles
import useStyles from "./Chart.styles";
import useNodeStyle from "../../../hooks/useNodeStyle";

type Props = {
  data: NodeData;
};

const Chart = (props: Props) => {
  const { data } = props || {};

  const { classes, statusKey } = useNodeStyle({
    classes: useStyles({ status: data?.status }),
    status: data?.status
  });

  // Contexts
  const { scenario, isNodeHighlighted, nodesExpanded } = useDagFlowContext();

  const isChartViewAction = useMemo(() => data?.status === ChartStatuses.Built, [data?.status]);
  const isChartDeleteAction = useMemo(
    () => !data?.isJobCanvas && !!scenario?.default,
    [data?.isJobCanvas, scenario?.default]
  );

  // Stores - STARTS >>
  const [nodeToFocusStore, shouldBlockClickHandlerTriggerStore] = useCanvasStore(
    (state) => [state.nodeToFocus, state.shouldBlockClickHandlerTrigger],
    shallow
  );
  // << ENDS - Stores

  // States - STARTS >>
  const [isHovered, setIsHovered] = useState(false);
  const [showDeselectNodesConfirmModal, setShowDeselectNodesConfirmModal] = useState<
    (() => void) | null
  >(null);
  // << ENDS - States

  const disabledMessage = useMemo(() => {
    if (
      !!data?.isJobCanvas &&
      includes([ChartStatuses.UnBuilt, ChartStatuses.Empty, ChartStatuses.Skipped], data?.status)
    ) {
      return `Certain click actions are restricted as supplementary details for the specified chart ${data?.label} are not accessible in the context of this job run entry.`;
    }

    return "";
  }, [data?.isJobCanvas, data?.status, data?.label]);

  // Keeping hooks at the bottom so as to pass the above defined props to it.
  // Hooks - STARTS >>
  const { isCtrlPressed } = useCtrlKeyPress();

  const { onClick, onDoubleClick } = useActions({ data, disabledMessage });

  const { icon, status } = useAttributes({ data, isHovered });

  const {
    contextMenuAnchorEl,
    // Mouse-leave action is not fully supported. Hence, deferring mouse-enter action.
    // actionsOnMouseEnter,
    closeContextMenu,
    contextMenuProp
  } = useContextMenu({
    data,
    disabledMessage
  });

  const { onMultiSelection, selectedNodes, isNodeSelected, resetSelectedNodes } =
    useNodeMultiSelection({ data });
  // << ENDS - Hooks

  // Mouse-click events - STARTS >>
  const handleSingleClick = () => {
    if (isEmpty(selectedNodes)) {
      onClick();
      return;
    }

    setShowDeselectNodesConfirmModal(() => onClick);
  };

  const handleDoubleClick = () => {
    if (isEmpty(selectedNodes)) {
      onDoubleClick();
      return;
    }

    setShowDeselectNodesConfirmModal(() => onDoubleClick);
  };
  // << ENDS - Mouse-click events

  // Confirm deselect nodes - STARTS >>
  const resetConfirmDeselectNodes = () => {
    setShowDeselectNodesConfirmModal(() => null);
  };

  const onConfirmDeselectNodes = () => {
    resetSelectedNodes();
    resetConfirmDeselectNodes();
    showDeselectNodesConfirmModal?.();
  };
  // << ENDS - Confirm deselect nodes

  const showContextMenu = useMemo(() => {
    if (!isEmpty(selectedNodes)) {
      if (!isNodeSelected()) {
        return false;
      }
    }

    return true;
  }, [selectedNodes]);

  return (
    <>
      {isFunction(showDeselectNodesConfirmModal) && (
        <DeselectNodesConfirmModal
          selectedNodes={selectedNodes}
          onDeselectNodes={onConfirmDeselectNodes}
          resetConfirmDeselectNodes={resetConfirmDeselectNodes}
        />
      )}

      {!!showContextMenu && (
        <ContextMenuWrapper
          id={`chartContextMenu-${data?.id}`}
          contextMenuAnchorEl={contextMenuAnchorEl}
          closeContextMenu={closeContextMenu}
          keepMounted>
          <ContextMenu
            closeContextMenu={closeContextMenu}
            isChartViewAction={isChartViewAction}
            isChartDeleteAction={isChartDeleteAction}
            data={data}
            selectedNodes={selectedNodes}
            resetSelectedNodes={resetSelectedNodes}>
            {!!data?.id && !!data?.isSourceNode && size(selectedNodes) <= 1 && (
              <NodeActionWrapper title={nodesExpanded[data?.id] ?? true ? "Collapse" : "Expand"}>
                <ExpandCollapseIconButton nodeId={data?.id} />
              </NodeActionWrapper>
            )}
          </ContextMenu>
        </ContextMenuWrapper>
      )}

      <NodesWrapper
        nodeId={data?.id}
        disabledMessage={disabledMessage}
        isSourceNode={data?.isSourceNode}
        isTargetNode={data?.isTargetNode}
        backgroundColor={
          has(NodeChartTheme, data?.status)
            ? get(NodeChartTheme, [data?.status, "backgroundColor"])
            : NodeChartTheme.backgroundColor
        }
        isHovered={isHovered}
        setIsHovered={setIsHovered}>
        <div
          role="button"
          data-testid="canvasNode"
          tabIndex={0}
          onMouseEnter={() => setIsHovered(() => true)}
          onMouseLeave={() => setIsHovered(() => false)}
          onClick={handleClickClosure({
            shouldBlockClickHandlerTrigger:
              shouldBlockClickHandlerTriggerStore || data?.shouldDisableBlockInteraction,
            isCtrlPressed,
            handleSingleClick,
            handleDoubleClick,
            handleMultiSelection: onMultiSelection
          })}
          onKeyPress={handleClickClosure({
            shouldBlockClickHandlerTrigger:
              shouldBlockClickHandlerTriggerStore || data?.shouldDisableBlockInteraction,
            isCtrlPressed,
            handleSingleClick,
            handleDoubleClick,
            handleMultiSelection: onMultiSelection
          })}
          {...(isChartViewAction || isChartDeleteAction ? contextMenuProp : {})}
          className={clsx(classes.root, classes[statusKey], {
            highlighted: isNodeHighlighted(data?.label, nodeToFocusStore),
            active: isNodeSelected(),
            isContextMenuHighlight: !!showContextMenu && Boolean(contextMenuAnchorEl)
          })}>
          <div className={classes.container}>
            <Typography
              variant="caption"
              className={clsx(classes.status, "status", {
                active: isNodeSelected()
              })}>
              {status}
            </Typography>
            <div className={classes.icon}>{icon}</div>
            {data?.label && (
              <NodeCaptionOverflowTooltip label={data?.label}>
                <Typography
                  variant="caption"
                  className={clsx(classes.label, {
                    active: isNodeSelected()
                  })}>
                  {addEllipsis(data?.label)}
                </Typography>
              </NodeCaptionOverflowTooltip>
            )}
          </div>
        </div>
      </NodesWrapper>
    </>
  );
};

export default Chart;
