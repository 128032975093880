import { QUERY_KEY_MODELS } from "src/hooks/api";
import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";

import api from "services/AxiosClient/AxiosClient";
import { handleResponse } from "services/Apis/Apis.service";

interface IVariables {
  name: string;
  showMessage: boolean;
}

const useInvalidateCache = (): UseMutationResult<unknown, unknown, IVariables, unknown> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ name }) => {
      const response = await api.fetchResponse(
        async () => await api.PredictControllerApi.invalidateCache(name)
      );
      return response;
    },
    onSuccess: async (__, { name, showMessage }) => {
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEY_MODELS], refetchType: "all" });
      if (showMessage) {
        handleResponse({ successMessage: `Prediction Service ${name} refreshed successfully` });
      }
    }
  });
};

export default useInvalidateCache;
