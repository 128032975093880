export enum JobConfig {
  JobNameFontSize = 14
}

export enum JobsStatuses {
  Started = "STARTED",
  Active = "ACTIVE",
  Inactive = "INACTIVE",
  Success = "SUCCESS",
  Failure = "FAILURE",
  Failed = "FAILED",
  TimedOut = "TIMED_OUT"
}

export const jobStatusesNames: $TSFixMe = {
  [JobsStatuses.Started]: "Started",
  [JobsStatuses.Active]: "Scheduler Active",
  [JobsStatuses.Inactive]: "Scheduler Paused",
  [JobsStatuses.Success]: "Success",
  [JobsStatuses.Failure]: "Failed",
  [JobsStatuses.Failed]: "Failed",
  [JobsStatuses.TimedOut]: "Timed Out"
};

export enum JobsStatusColors {
  Success = "#00BC4B",
  Fail = "#E80E00"
}

export enum JobRunTriggerTypes {
  Scheduler = "CRON",
  Manual = "MANUAL"
}

export enum JobRunStatuses {
  // Running
  Created = "CREATED",
  Started = "STARTED",
  EntityLoading = "ENTITY_LOADING",
  Running = "RECIPE_RUNNING",

  // Success
  SuccessWithWarn = "SUCCESS_WITH_WARN",
  Success = "SUCCESS",

  // Failed
  TimedOut = "TIMED_OUT",
  RecipeTimedOut = "RECIPE_TIMED_OUT",
  Failure = "FAILURE"
}

export const jobRunStatusesNames: $TSFixMe = {
  [JobRunStatuses.Created]: "Created",
  [JobRunStatuses.Started]: "Started",
  [JobRunStatuses.EntityLoading]: "Entity Loading",
  [JobRunStatuses.Running]: "Running",
  [JobRunStatuses.SuccessWithWarn]: "Success",
  [JobRunStatuses.Success]: "Success",
  [JobRunStatuses.TimedOut]: "Timed Out",
  [JobRunStatuses.RecipeTimedOut]: "Recipe Timed Out",
  [JobRunStatuses.Failure]: "Failed"
};

export enum JobRunStatusColors {
  Info = "#003656",
  Success = "#83c784",
  Fail = "#e56169"
}

export const jobFrequency = [
  {
    id: "daily",
    displayName: "Daily"
  },
  {
    id: "weekly",
    displayName: "Weekly"
  },
  {
    id: "cron",
    displayName: "Cron"
  }
];

export const jobWeekDays = [
  {
    id: "sunday",
    displayName: "S"
  },
  {
    id: "monday",
    displayName: "M"
  },
  {
    id: "tuesday",
    displayName: "T"
  },
  {
    id: "wednesday",
    displayName: "W"
  },
  {
    id: "thursday",
    displayName: "Th"
  },
  {
    id: "friday",
    displayName: "F"
  },
  {
    id: "saturday",
    displayName: "Sa"
  }
];

export enum JobDeletePromptDetails {
  title = "Do you want to delete this scheduler?",
  message = "If you delete, your scheduler will be deleted permanently!",
  confirmLabel = "Delete",

  messageLine1 = "Do you want to delete this scheduler?",
  messageLine2 = "If you delete, your scheduler will be deleted permanently!"
}

export enum JobRunDeletePromptDetails {
  messageLine1 = "Do you really want to delete this scheduler run entry?",
  messageLine2 = "If you confirm you will delete the scheduler run entry completely!"
}

export enum JobsSplashSection {
  title = "Get Started with a New Scheduler",
  subTitleLine1 = "Welcome to the Scheduler page.",
  subTitleLine2 = "Start a new one by clicking on the button below.",
  actionLabel = "+ New Scheduler"
}

export const JobsGettingStarted = {
  topMessage: "Set up a scheduler to execute workflows and generate updated outputs automatically.",
  introduction: {
    question: "What is Scheduler?",
    answer:
      "A Scheduler automates project flows by running them at set intervals—daily, weekly, or on a custom schedule."
  },
  functionality: {
    question: "How it works?",
    answers: [
      "Select a project flow to automate.",
      "Define the schedule frequency (daily, weekly, or custom).",
      "Configure output storage for datasets and text files."
    ]
  }
};

export const ManualPredictionGettingStarted = {
  topMessage: "Upload a dataset and run predictions instantly using a trained model.",
  functionality: {
    question: "How it works?",
    answers: [
      "Upload a dataset you want to analyze.",
      "Select a trained model for prediction.",
      "The system preprocesses your data through multiple steps to improve accuracy, similar to model training.",
      "Generate predictions instantly.",
      "Download results or add them to the project canvas."
    ]
  }
};
export const PredictionSchedulerGettingStarted = {
  topMessage:
    "Automate predictions by scheduling runs with updated datasets, ensuring continuous insights without manual uploads.",
  functionality: {
    question: "How it works?",
    answers: [
      "Choose a trained model for scheduled predictions.",
      "Set a schedule (daily, weekly, or custom).",
      "The system automatically runs predictions using the latest dataset.",
      "View execution logs and track errors, if any.",
      "Download results or update the project canvas."
    ]
  }
};

export enum JobsHelperText {
  Scheduler = "Scheduler",

  SearchJobsPlaceholder = "Search schedulers",

  JobCanvasCannotCreateJob = "No recipe found for the project. Cannot create scheduler!",
  ScenarioUpdateInfo = "Scenario updation will only consider scenarios available at the project level during this scheduler creation. If you want to associate scenarios created after scheduler creation, please republish the scheduler first.",
  JobRun = "A scheduler when created will take the current state of the Project Canvas. Updating the project canvas does not update an existing scheduler. To take the latest Project Canvas state into consideration you will have to create a new scheduler",
  CronRefLink = "https://crontab.guru",
  JobRunsInfo = "The history page does not contain records of more than 300 and/or older than 30 days.",

  JobCreated = "Scheduler created successfully!",
  JobUpdated = "Scheduler updated successfully!",

  InvisibleOutputDatasetsInfoPart1 = "Certain outputs",
  InvisibleOutputDatasetsInfoPart2 = "may not be currently visible on this canvas. This could be due to the use of Global/Scenario/Recipe variables in their generation, or these outputs may not align with the project canvas from which this scheduler originated or was republished.",
  InvisibleOutputDatasetsInfoPart3 = "Following datasets though can still be downloaded from output dataset list in scheduler history page for this run entry.",

  UntitledJob = "Untitled Scheduler",
  PredictionScheduler = "Prediction Scheduler",

  RepublishActionInfo = "Updates the canvas of this scheduler with the current canvas of the project.",
  CompareActionInfo = "Compares the canvas of this scheduler with the current canvas of the project.",

  DestinationSaved = "Destination saved successfully!",
  DestinationUpdated = "Destination updated successfully!",
  DestinationSaveFailed = "Destination save failed!",

  DestinationDeleted = "Destination deleted successfully!",
  DestinationDeleteFailed = "Destination delete failed!",

  Save = "Save",
  Edit = "Edit",
  Update = "Update",
  Delete = "Delete",

  JobNameUpdated = "Scheduler name updated successfully!",
  ManualJobRunStartedMessage = "Manual scheduler run started!",
  ManualPredictionSchedulerRunStartedMsg = "Manual run started for prediction scheduler",
  JobDeletedMessage = "Scheduler deleted successfully!",
  PredictionSchedulerDeletedMessage = "Prediction scheduler deleted successfully!",

  JobRunReRunInfo = "Performing a re-run will execute this action against this run entry's scheduler canvas and configuration.",

  JobRunOutputModalTitleDescription = "Download is applicable to datasets only",
  JobRunOutputModalUnnavigableOutputInfo = "This output cannot be accessed in this run entry's canvas context. This could be due to the use of Global/Scenario/Recipe variables in their generation, or this output may not align with the project canvas from which this scheduler was originated or was republished."
}

export enum JobDestinationsHelperText {
  ConnectorsInfo = "Fivetran connectors are not supported while adding destinations. In case of text inputs / outputs only file based connectors (Google Cloud Platform, Amazon S3 and Azure Blob Storage) are supported.",
  InfoText = "Save inputs / outputs to External Storage",
  InfoSubText = "All inputs / outputs are also saved into RapidCanvas by default"
}

export const DEFAULT_FILENAME = "_${RUN_ID}";
