import React, { useState } from "react";
import { Button, Typography } from "@material-ui/core";

import DrawerAccordion from "./DrawerAccordion";
import MarkdownDisplay from "components/MarkdownDisplay";
import Modal, { ModalVariants } from "src/components/custom/Modal/Modal";
import useGenerateAboutContent from "pages/private/ProjectsModule/pages/About/hooks/useGenerateAboutContent";
import { handleResponse } from "services/Apis/Apis.service";
import { useStyles } from "components/Canvas/CanvasDrawerItems/components/styling";

interface IProps {
  markdownString: string;
  projectId: string;
  hiddenUpdateContentAction?: boolean;
  type?: "ENTITY" | "RECIPE" | "MODEL" | "ARTIFACT";
  elementId: string;
  onSuccess?: () => void;
}

const DrawerAbout: React.FC<IProps> = (props) => {
  const {
    markdownString,
    projectId,
    hiddenUpdateContentAction = false,
    type,
    elementId,
    onSuccess
  } = props;

  const [open, setOpen] = useState(false);
  const { flexSpaceBetween } = useStyles();
  const generateContent = useGenerateAboutContent();

  const handleUpdate = () => {
    if (type) {
      generateContent.mutate(
        {
          projectId,
          type,
          outputElementId: elementId,
          isUpdated: true
        },
        {
          onSuccess: () => {
            onSuccess?.();
            setOpen(false);
            handleResponse({
              successMessage: "Summary content has been updated successfully "
            });
          }
        }
      );
    }
  };

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setOpen(true);
    event.stopPropagation();
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const title = (
    <div className={flexSpaceBetween}>
      <Typography variant="caption" color="textPrimary">
        ABOUT
      </Typography>
      {!hiddenUpdateContentAction && type && (
        <Button
          color="primary"
          size="small"
          aria-label="update-content"
          style={{ marginLeft: "330px" }}
          onFocus={(event) => event.stopPropagation()}
          onClick={handleOpen}>
          Update Content
        </Button>
      )}
    </div>
  );

  return (
    <>
      <DrawerAccordion
        title={title}
        label="ABOUT"
        content={<MarkdownDisplay string={markdownString} />}
      />
      {open && (
        <Modal
          open={open}
          variant={ModalVariants.Delete}
          title="Update Content"
          content={[
            "This will trigger summary generation using Generative AI and update the existing content",
            "Are you sure?"
          ]}
          submitLabel="Update"
          isSubmitting={generateContent.isLoading}
          onClose={handleCancel}
          onSubmit={handleUpdate}
        />
      )}
    </>
  );
};

export default DrawerAbout;
