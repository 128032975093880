import React from "react";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import { Box, CircularProgress, Typography, makeStyles } from "@material-ui/core";

import Modal from "src/components/custom/Modal/Modal";

interface IProps {
  open: boolean;
  loading: boolean;
  onSubmit: () => void;
  onCancel: () => void;
}

const useStyles = makeStyles({
  flex: {
    display: "flex",
    flexDirection: "column",
    gap: "10px"
  }
});

const UpdateDataConnectorWarningModal: React.FC<IProps> = (props) => {
  const { open, loading, onCancel, onSubmit } = props;
  const classes = useStyles();

  return (
    <Modal
      title="Update Data Connector"
      open={open}
      onClose={onCancel}
      onSubmit={onSubmit}
      isSubmitting={loading}
      submitLabel={
        loading ? <CircularProgress style={{ color: "white" }} size={16} /> : "Yes, Update"
      }>
      <Box display="flex" gridGap={16}>
        <ReportProblemOutlinedIcon fontSize="large" />
        <div className={classes.flex}>
          <Typography variant="body2">
            This connector is being used by dataset(s) and/or project scheduler run(s) as an export
            destination. Updating the configuration of this connector might impact the export
            operation.
          </Typography>
          <Typography variant="body2">Do you still want to continue?</Typography>
        </div>
      </Box>
    </Modal>
  );
};

export default UpdateDataConnectorWarningModal;
