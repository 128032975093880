import { DFSRunConfigGroupDtoStatusEnum } from "@rapidcanvas/rc-api-core";
import { OUTPUT_TYPE } from "src/pages/Projects/AddCodeRecipe/CodeRecipeContext/CodeRecipeContextProvider";
export enum EntityTypeEnum {
  ENTITY = "ENTITY",
  DFSGROUP = "DFSGROUP",
  CHART = "CHART",
  MODEL = "MODEL",
  ARTIFACT = "ARTIFACT",
  DESTINATION = "DESTINATION",
  NONE = "NONE",
  FILE = "FILE",
  VECTOR_STORE = "VECTOR_STORE"
}

type RecipeNode = {
  id: string;
  name: string;
  displayName: string;
  type: keyof typeof EntityTypeEnum;
};
export type Recipe = {
  id: string;
  name: string;
  isCopied: boolean;
  displayName: string;
  defaultCode?: string;
  projectId: string;
  runConfigs: Array<{
    id: string;
    templateId: string;
    displayName: string;
    variables: $TSFixMe;
    name: string;
  }>;
  newApiConnectorRecipe: boolean;
  parents: Array<RecipeNode>;
  children: Array<RecipeNode>;
  condition?: {
    expression: $TSFixMe;
  };
  timeout: number | null;
  metadata?: {
    problemType?: string;
    vertical?: string;
  };
  codeChanged: boolean;
  status: DFSRunConfigGroupDtoStatusEnum;
  newAskAIFlow: boolean;
  runErrDetails: {
    lineNo: number;
    line: string;
    errorExplanation?: string;
  } | null;
};

type DfsTemplateInputMetadataType = {
  input_name: string;
  tooltip: string | null;
  default_value: string | null;
  is_required: boolean;
  multiple: boolean;
  input_dataset: boolean;
  input_column: boolean;
};
type DfsTemplateInputType = {
  name: string;
  metadata: DfsTemplateInputMetadataType;
};
export type DfsTemplate = {
  id: string;
  projectId: string;
  name: string;
  display_name: string;
  inputs: Array<DfsTemplateInputType>;
  inputValues: $TSFixMe;
  description: string;
  tags: Array<string>;
  secondaryTags: null | Array<string>;
};

export type AskAIResponse = {
  code: string;
  userInput: string;
  transformId?: string;
  pipelineTestResult?: $TSFixMe;
  outputType: OUTPUT_TYPE;
  queryOutputs?: $TSFixMe;
  promptSuggestions?: string[];
};

export type DatasetCustomColumnsProps = {
  columnNames: string[];
  entityId?: string;
};

export type CodeSuggestion = {
  query: string;
  isLoading?: boolean;
  response?: string;
  code: string;
  messageId?: string;
  isError?: boolean;
};
