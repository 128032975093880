import React, { useCallback, useMemo, useState } from "react";

import { useIsMutating } from "@tanstack/react-query";
import { chain, get, isEmpty } from "lodash";

import { Button, Grid, Paper, Tooltip, Typography, makeStyles, useTheme } from "@material-ui/core";

import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { EditDateIcon } from "icons/NewUX";

import { OntologyDatasetIcon } from "src/assets/icons/Dataset/OntologyDatasetIcon";
import { FileConfigurationIcon } from "src/assets/icons/Dataset/FileConfigurationIcon";

import { ellipses } from "utils/helpers/string.helpers";

import DateFormatModal from "./DateFormatModal/DateFormatModal";

import { DatasetHelperText } from "../../utils/Dataset.constants";

import { UseUpdateDatasetQueryKeys } from "src/hooks/api/entities/useUpdateEntities";
import useStoreSelectors from "../../hooks/useStoreSelectors";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    borderRadius: theme.spacing(1.5),
    padding: theme.spacing(2),
    "& .fileDatasetContainer": {
      columnGap: theme.spacing(2)
    },
    "& .fileIconContainer": {
      display: "flex",
      "& svg": {
        margin: "auto",
        width: 43,
        height: 45,
        color: "#003656"
      }
    },
    "& .datasetIconContainer": {
      display: "flex",
      border: "2px solid",
      borderRadius: 10,
      padding: 7
    },
    "& .attributesWrapper": {
      height: "inherit",
      flexGrow: 1
    },
    "& .attributesContainer": {
      height: "inherit"
    },
    "& .fileConfigurationAction": {
      marginLeft: "auto"
    }
  }
}));

const ViewUploadedContainer = (props: $TSFixMe) => {
  const {
    isAddFile,
    ontologyDatasetIndex,
    isConfigContainerExpanded,
    setIsConfigContainerExpanded,
    isSchemaOptionsSupportedFileType,
    isFetchingOntologySchema,
    ontologySchemaData,
    isFetchingDataset,
    datasetData,
    fetchDatasetResources,
    children
  } = props || {};

  const theme = useTheme();
  const classes: $TSFixMe = useStyles();

  // Stores - STARTS >>
  const { datasetFilesStore, datasetDatasetsStore } = useStoreSelectors();
  // << ENDS - Stores

  const [showDateFormatModal, setShowDateFormatModal] = useState(false);

  const filesNames = useMemo(
    () =>
      datasetDatasetsStore?.length === 1
        ? datasetFilesStore?.map((eachFile: $TSFixMe) => eachFile?.name)?.join(", ")
        : datasetFilesStore[ontologyDatasetIndex]?.name,
    [ontologyDatasetIndex, datasetFilesStore]
  );

  const datasetsNames = useMemo(
    () => datasetDatasetsStore[ontologyDatasetIndex]?.name,
    [ontologyDatasetIndex, datasetDatasetsStore]
  );

  const pendingUpdateDatasetMutations = useIsMutating({
    mutationKey: [UseUpdateDatasetQueryKeys.UpdateDataset, datasetData?.id]
  });

  const isUpdatingDataset = useMemo(
    () => pendingUpdateDatasetMutations > 0,
    [pendingUpdateDatasetMutations]
  );

  const setDateFormatModalHandler = useCallback(() => setShowDateFormatModal(() => true), []);
  const resetDateFormatModalHandler = useCallback(() => setShowDateFormatModal(() => false), []);

  const isLoadingUpdateActionData = useMemo(
    () => !!isFetchingDataset || !!isFetchingOntologySchema,
    [isFetchingDataset, isFetchingOntologySchema]
  );

  const dateFormatData = useMemo(
    () =>
      chain(ontologySchemaData)
        .filter((item) => get(item, "fieldSchema.rcDataType") === "TIMESTAMP")
        .map((item) => {
          const name = item.name;
          const format = get(item, "fieldSchema.rcDateTimeFormatEnum");
          return !!name && !!format ? { name, format } : null;
        })
        .compact() // Removes any `null` values
        .value(),
    [ontologySchemaData]
  );

  const disabledUpdateActionMessage = useMemo(() => {
    if (!!isLoadingUpdateActionData) {
      return "Please wait. Fetching required data.";
    }

    if (!!isUpdatingDataset) {
      return "Please wait. The update action is in progress.";
    }

    return "";
  }, [isLoadingUpdateActionData, isUpdatingDataset]);

  return (
    <>
      {!!showDateFormatModal && (
        <DateFormatModal
          onClose={resetDateFormatModalHandler}
          isLoading={isLoadingUpdateActionData}
          data={dateFormatData}
          datasetData={datasetData}
          fetchDatasetResources={fetchDatasetResources}
        />
      )}

      <Paper className={classes.root}>
        <Grid container alignItems="center">
          <Grid item xs={8}>
            <Grid container alignItems="center">
              <Grid item xs={6}>
                <Grid container alignItems="center" className="fileDatasetContainer">
                  <Grid item className="fileIconContainer">
                    {<DescriptionOutlinedIcon />}
                  </Grid>
                  <Grid item className="attributesWrapper">
                    <Grid
                      container
                      direction="column"
                      justifyContent="space-between"
                      className="attributesContainer">
                      <Typography
                        id="viewUploadedFileLabel"
                        variant="subtitle2"
                        color="textPrimary">
                        {DatasetHelperText.UploadedFile}
                      </Typography>
                      {filesNames ? (
                        <Typography variant="body2" color="textPrimary">
                          <Tooltip
                            id="viewUploadedFileNames"
                            title={filesNames?.length > 40 ? filesNames : ""}>
                            <span>{ellipses({ text: filesNames, length: 40 })}</span>
                          </Tooltip>
                        </Typography>
                      ) : (
                        <Typography
                          id="viewUploadedFileUnknown"
                          variant="body2"
                          color="textPrimary">
                          {DatasetHelperText.Unknown}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container alignItems="center" className="fileDatasetContainer">
                  <Grid item className="datasetIconContainer">
                    <OntologyDatasetIcon />
                  </Grid>
                  <Grid item className="attributesWrapper">
                    <Grid
                      container
                      direction="column"
                      justifyContent="space-between"
                      className="attributesContainer">
                      <Typography
                        id="viewUploadedDatasetLabel"
                        variant="subtitle2"
                        color="textPrimary">
                        {!!isAddFile ? DatasetHelperText.TextInput : DatasetHelperText.Dataset}
                      </Typography>
                      {datasetsNames ? (
                        <Typography variant="body2" color="textPrimary">
                          <Tooltip
                            id="viewUploadedDatasetNames"
                            title={datasetsNames?.length > 40 ? datasetsNames : ""}>
                            <span>{ellipses({ text: datasetsNames, length: 40 })}</span>
                          </Tooltip>
                        </Typography>
                      ) : (
                        <Typography
                          id="viewUploadedDatasetUnknown"
                          variant="body2"
                          color="textPrimary">
                          {DatasetHelperText.Unknown}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {!isAddFile && (
            <Grid item xs={4} style={{ textAlign: "right" }} className="fileConfigurationAction">
              <Grid container justifyContent="flex-end" style={{ columnGap: theme.spacing(2) }}>
                {!!isSchemaOptionsSupportedFileType && (
                  <Grid item>
                    <Typography color="textPrimary">
                      <Button
                        variant="outlined"
                        color="inherit"
                        startIcon={<FileConfigurationIcon fill="#003656" />}
                        onClick={() =>
                          setIsConfigContainerExpanded(() => !isConfigContainerExpanded)
                        }
                        endIcon={
                          isConfigContainerExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />
                        }>
                        File Configuration
                      </Button>
                    </Typography>
                  </Grid>
                )}
                {/* Enforcing the below date-format action is hidden until it is part of the release. */}
                {false && !isEmpty(dateFormatData) && (
                  <Grid item>
                    <Tooltip title={disabledUpdateActionMessage}>
                      <Typography color="textPrimary">
                        <Button
                          variant="outlined"
                          color="inherit"
                          disabled={!!disabledUpdateActionMessage}
                          startIcon={<EditDateIcon />}
                          onClick={setDateFormatModalHandler}>
                          Date Format
                        </Button>
                      </Typography>
                    </Tooltip>
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
        {children}
      </Paper>
    </>
  );
};

export default ViewUploadedContainer;
