import api from "services/AxiosClient/AxiosClient";
import { useMutation, UseMutationResult } from "@tanstack/react-query";

interface IVariable {
  groupId: string;
}

const useUnbuiltRecipe = (): UseMutationResult<any, unknown, IVariable, unknown> => {
  return useMutation({
    mutationFn: async ({ groupId }) => {
      return await api.fetchResponse(
        () => api.DfsRunConfigGroupControllerV2Api.changeRecipeStatusToUnbuiltSafely(groupId),
        false
      );
    }
  });
};

export default useUnbuiltRecipe;
