import { ReactNode } from "react";

// Packages
import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

// Open API
import api from "services/AxiosClient/AxiosClient";
import { ProjectCanvasDto } from "@rapidcanvas/rc-api-core";
import { get, includes, keyBy, map, some } from "lodash";

export const enum UseGetProjectCanvasQueryKeys {
  ProjectCanvas = "query-key-project-canvas",
  ProjectCanvasLite = "query-key-project-canvas-lite"
}

type ProjectJobProps = {
  scenarioId?: string;
  jobId?: string;
  jobRunId?: string;
  entityId?: string;
  renderContent?: ReactNode;
  skipJobCanvas?: boolean;
};
interface IProps extends UseQueryOptions<$TSFixMe> {
  projectId?: string;
  scenarioId?: string;
  jobProps?: ProjectJobProps;

  // useQuery options
  cacheTime?: number;
  addDestinations?: boolean;
}

export const reArrange = async (projectId: string, response: ProjectCanvasDto) => {
  const nodes = response.nodes;

  const hasPredictionNode = some(nodes, (node) => {
    const name = get(node, "displayName") || get(node, "name");
    return name && includes(name, "prediction_output");
  });

  if (hasPredictionNode) {
    const minimalNodes = map(response.nodes, (node) => ({
      id: node.id,
      column: node.column,
      row: node.row
    }));

    const minimalEdges = response.edges?.map((edge) => ({
      id: edge.id,
      source: edge.source,
      target: edge.target
    }));

    const rearrangedResponse = await api.fetchResponse(
      async () =>
        await api.ProjectsControllerV2Api.rearrangeGraph(projectId, {
          nodes: minimalNodes,
          edges: minimalEdges
        })
    );

    const updatedNodeIdNodeMap = keyBy(rearrangedResponse.nodes, "id");
    const val = {
      ...response,
      nodes: map(nodes, (node) => {
        const updatedNode = updatedNodeIdNodeMap[node.id];

        return {
          ...node,
          position: {
            x: updatedNode.column * 180,
            y: updatedNode.row * 180
          }
        };
      }),
      edges: rearrangedResponse.edges
    };

    return val;
  } else {
    return response;
  }
};

export const useGetProjectCanvas = (props: IProps): UseQueryResult<ProjectCanvasDto> => {
  const {
    projectId,
    scenarioId,
    jobProps,

    // useQuery options
    cacheTime = 0,
    enabled,
    addDestinations = false,
    ...options
  } = props;

  return useQuery({
    queryKey: [
      UseGetProjectCanvasQueryKeys.ProjectCanvas,
      projectId,
      scenarioId || jobProps?.scenarioId,
      jobProps?.jobId,
      jobProps?.jobRunId,
      jobProps?.entityId
    ],
    queryFn: async () => {
      if (!projectId) {
        throw "Invalid payload!";
      }

      const entityId = jobProps?.entityId;
      if (entityId && !jobProps.jobId) {
        const response = await api.fetchResponse(
          async () =>
            await api.ProjectsControllerV2Api.getAllModelsWithInputDataSets1(
              projectId,
              entityId,
              scenarioId || jobProps?.scenarioId
            )
        );

        if (jobProps.jobId) {
          return response;
        } else {
          const val = await reArrange(projectId, response);
          return val;
        }
      } else {
        const response = await api.fetchResponse(
          async () =>
            await api.ProjectsControllerV2Api.getProjectCanvas(
              projectId,
              true,
              scenarioId || jobProps?.scenarioId,
              jobProps?.jobId,
              jobProps?.jobRunId
            )
        );

        if (jobProps?.jobId) {
          return response;
        } else {
          const val = await reArrange(projectId, response);
          return val;
        }
      }
    },
    select: (data) => (typeof data === "object" ? data : {}),
    cacheTime,
    enabled: enabled ?? (!!projectId && (!!scenarioId || !!jobProps?.scenarioId)),
    ...options
  });
};

export const useGetProjectCanvasLite = (props: IProps): UseQueryResult<ProjectCanvasDto> => {
  const { projectId, scenarioId, ...options } = props;

  return useQuery({
    queryKey: [UseGetProjectCanvasQueryKeys.ProjectCanvasLite, projectId, scenarioId],
    queryFn: async () => {
      if (!projectId) {
        throw "Invalid payload!";
      }

      return await api.fetchResponse(
        async () => await api.ProjectsControllerV2Api.getProjectCanvas(projectId, true, scenarioId)
      );
    },
    select: (data: $TSFixMe) => (typeof data === "object" ? data : {}),
    staleTime: 0, // Ensures data is always considered stale
    cacheTime: Infinity, // Keeps the data in memory indefinitely
    ...options
  });
};
