import _ from "lodash";
import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";

import api from "services/AxiosClient/AxiosClient";
import { UseGetProjectsQueryKeys } from "./api/projects/useGetProjects";

interface IVariables {
  projectId: string;
  isFavorite: boolean;
}

const useMarkProjectAsFavorite = (): UseMutationResult<unknown, unknown, IVariables, unknown> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ projectId, isFavorite }) => {
      queryClient.setQueryData([UseGetProjectsQueryKeys.Projects], (prevData: any) => {
        const val = _.map(prevData, (val) => (val.id === projectId ? { ...val, isFavorite } : val));
        return val;
      });

      const response = await api.fetchResponse(
        async () => await api.ProjectControllerApi.addToFavorite(projectId, isFavorite)
      );

      return response;
    },
    onSuccess: (__, { projectId, isFavorite }) => {
      queryClient.setQueryData([UseGetProjectsQueryKeys.Projects], (prevData: any) => {
        const val = _.map(prevData, (val) => (val.id === projectId ? { ...val, isFavorite } : val));
        return val;
      });
    },
    onError: async (__, { projectId, isFavorite }) => {
      queryClient.setQueryData([UseGetProjectsQueryKeys.Projects], (prevData: any) => {
        const val = _.map(prevData, (val) =>
          val.id === projectId ? { ...val, isFavorite: !isFavorite } : val
        );
        return val;
      });
    }
  });
};

export default useMarkProjectAsFavorite;
