import axios from "axios";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useMemo } from "react";
import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

import api, { TOKEN } from "services/AxiosClient/AxiosClient";
import useAuthStore from "stores/zustand/auth.store";
import { PathRexExps } from "constants/common.constants";
import { logNotebooksOut } from "services/Notebook/Notebook.service";
import { loginPath } from "src/routing/routes";

export const QUERY_KEY_TOKEN_VALIDATION = "query-key-token-validation";

interface IAuthResponse {
  cpuGuarantee: number;
  dataappsTtlInHrs: number;
  eulaAccepted: boolean;
  expiresAt: number;
  ghEmail: string;
  ghFullName: string;
  memGuarantee: number;
  notebookId: string;
  notebookType: string;
  roleId: string;
  roleName: string;
  tenantId: string;
  tenantName: string;
  token: string;
  userEmail: string;
  userId: string;
}

const useCheckTokenValidation = (
  options?: UseQueryOptions<IAuthResponse>
): UseQueryResult<IAuthResponse> => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = useMemo(() => location.pathname, [location.pathname]);

  const [logout, logoutTimer] = useAuthStore((state) => [state.logout, state.logoutTimer]);

  const navigateToLogin = () => {
    navigate(
      {
        pathname: PathRexExps.auth.test(currentPath) ? currentPath : loginPath,
        search: location.search
      },
      { state: { from: location } }
    );
  };

  const isAppsRoute = currentPath.includes("/apps/");

  return useQuery({
    queryKey: [QUERY_KEY_TOKEN_VALIDATION],
    queryFn: async () => {
      const tokenFromSearchParams = searchParams.get(TOKEN);
      const tokenFromStorage = api.getToken();
      const token = isAppsRoute ? tokenFromSearchParams ?? tokenFromStorage : tokenFromStorage;

      if (!token) {
        navigateToLogin();
        logNotebooksOut();
        throw "Token is absent";
      }

      try {
        const { data } = await axios.post("/api/token/validation", { token });
        api.init(token);
        return data;
      } catch {
        logout(logoutTimer);
        navigateToLogin();
        api.removeToken();
        throw "token validation failed";
      }
    },
    cacheTime: Infinity,
    refetchOnMount: false,
    ...options
  });
};

export default useCheckTokenValidation;
