/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Box, Grid, Typography } from "@material-ui/core";
import React, { useMemo, useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";

// Icons
import IconButton from "@material-ui/core/IconButton";
import { PlusIcon } from "icons/NewUX/PlusIcon";

import AddFilesToArtifact from "../AddFilesToArtifact";
import { ArtifactTable } from "./ArtifactTable";
import type { Artifact } from "src/types/Artifact.type";
import { useUpdateEffect } from "src/hooks/useUpdateEffect";
import { useGetJob, useGetJobRun } from "src/hooks/api";

// Components
import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import SubTopNavBarBreadcrumbs from "./SubTopNavBarBreadcrumbs";

// Context
import { useProjectContext } from "src/pages/private/ProjectsModule/context/useProjectContext";

import useArtifactByName from "src/hooks/api/artifacts/useArtifactByName";
import CommonLoader from "src/components/CommonLoader";

const ArtifactDetails = () => {
  const { artifactName, projectId, scenarioId, jobId, jobRunId } = useParams<$TSFixMe>();
  const { pathname } = useLocation();
  const [showAddFilesModal, setShowAddFilesModal] = useState(false);
  const [selectedArtifact, setSelectedArtifact] = useState<Artifact | undefined>(undefined);

  const [shouldArtifactsRefresh, setShouldArtifactsRefresh] = useState(false);
  const isProjectNav = location.pathname.includes("artifacts-and-model") && !!projectId;

  const {
    data,
    isSuccess,
    isLoading: isArtifactsLoading,
    refetch
  } = useArtifactByName(artifactName!, scenarioId, jobRunId);

  useEffect(() => {
    if (isSuccess && data) {
      setSelectedArtifact(data);
    }
  }, [isSuccess, data]);

  // Project context
  const { project } = useProjectContext() || {};

  const isJobPath = useMemo(() => /jobs/.test(pathname), [pathname]);

  // Query hooks - STARTS >>
  const { data: jobData } = useGetJob({ projectId, jobId });
  const { data: jobRunData } = useGetJobRun({ jobRunId, isApiWithRethrow: false });
  // << ENDS - Query hooks

  const toggleShouldArtifactsRefresh = () => {
    setShouldArtifactsRefresh((refresh) => !refresh);
  };

  const openAddFilesModal = () => {
    setShowAddFilesModal(true);
  };

  const handleClose = () => {
    setShowAddFilesModal(false);
  };

  useUpdateEffect(() => {
    refetch();
  }, [refetch, shouldArtifactsRefresh]);

  return isArtifactsLoading ? (
    <CommonLoader />
  ) : (
    <>
      {showAddFilesModal && (
        <AddFilesToArtifact
          //@ts-expect-error
          artifactName={selectedArtifact?.name}
          onClose={handleClose}
          toggleShouldArtifactsRefresh={toggleShouldArtifactsRefresh}
        />
      )}

      <SubTopNavBarWrapper
        subTopNavBarLeftSection={{
          component: (
            <SubTopNavBarBreadcrumbs
              project={project}
              isJobPath={isJobPath}
              jobData={jobData}
              jobRunId={jobRunId}
              jobRunName={jobRunData?.entryDto?.runId}
              artifactName={artifactName}
              isProjectNav={isProjectNav}
            />
          )
        }}
        {...(!isJobPath
          ? {
              subTopNavBarRightSection: {
                component: (
                  <IconButton color="primary" size="small" onClick={openAddFilesModal}>
                    <PlusIcon width={28} height={28} />
                  </IconButton>
                )
              }
            }
          : {})}
      />

      <Box m={2}>
        <Grid container direction="column" style={{ rowGap: 16 }}>
          <Grid item>
            <Typography variant="h6" color="textPrimary">
              Files
            </Typography>
          </Grid>
          {selectedArtifact && (
            <Grid item>
              <ArtifactTable
                artifactName={artifactName!}
                artifact={selectedArtifact}
                onArtifactDelete={toggleShouldArtifactsRefresh}
                maxHeight="calc(100vh - 220px)"
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
};

export default ArtifactDetails;
