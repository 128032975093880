import { useMutation, UseMutationResult } from "@tanstack/react-query";

import { getAPIWithRethrow } from "services/Apis/Apis.service";

interface IVariables {
  id: string;
}

interface IData {
  responseEntity: string;
  status: string;
  probableDatasetOriginalName: string;
  probableDatasetsInternal: string[];
}

const useProbableDataSets = (): UseMutationResult<IData, unknown, IVariables, unknown> => {
  return useMutation({
    mutationFn: async ({ id }) => {
      return await getAPIWithRethrow(`/v2/data-source/probable-data-sets/${id}`);
    }
  });
};

export default useProbableDataSets;
