import React, { useMemo, useState } from "react";

// Packages
import clsx from "clsx";
import shallow from "zustand/shallow";
import { find, get, has, includes, isEmpty, isFunction, size } from "lodash";

// MUI
import { Typography } from "@material-ui/core";

// Utils
import { addEllipsis, handleClickClosure } from "utils/helpers";
import { DatasetStatuses, NodeDatasetTheme } from "src/pages/private/ProjectsModule/utils";

// Stores
import { useCanvasStore, useDataSourcesStore } from "stores/zustand/stores";
import { connectorsGetter } from "stores/zustand/stores.selectors";

// Components
import { thirdPartyTypeName } from "src/pages/DataSources/utils/DataSources.constants";
import NodesWrapper from "../NodesWrapper";
import ContextMenuWrapper from "../ContextMenuWrapper";
import ContextMenu from "./ContextMenu";
import NodeActionWrapper from "../NodeActionWrapper";
import ExpandCollapseIconButton from "../ExpandCollapseIconButton";
import NodeCaptionOverflowTooltip from "../NodeCaptionOverflowTooltip";
import DeselectNodesConfirmModal from "../../DeleteNodes/DeselectNodesConfirmModal";

// Hooks
import { useNodeMultiSelection } from "projectsModule/hooks";
import { useCtrlKeyPress } from "src/hooks/useCtrlKeyPress";
import useAttributes from "./useAttributes";
import useActions from "./useActions";
import useContextMenu from "../useContextMenu";

// Contexts
import { useAccessControlContext } from "src/routing/PrivateRoute/accessControlContext/useAccessControlContext";
import { useDagFlowContext } from "../../DagFlow/context/useDagFlowContext";

// Types
import { NodeData } from "src/types";

// Styles
import useStyles from "./Dataset.styles";
import useNodeStyle from "../../../hooks/useNodeStyle";

type Props = {
  data: NodeData;
};

const VectorDB = (props: Props) => {
  const { data } = props || {};

  const { classes, statusKey } = useNodeStyle({
    classes: useStyles({ status: data?.status }),
    status: data?.status
  });

  // Contexts
  const { canAddStandardRecipe } = useAccessControlContext();
  const { scenario, isNodeHighlighted, nodesExpanded } = useDagFlowContext();

  // Stores - STARTS >>
  const connectorsStore = useDataSourcesStore(connectorsGetter);
  const [nodeToFocusStore, shouldBlockClickHandlerTriggerStore] = useCanvasStore(
    (state) => [state.nodeToFocus, state.shouldBlockClickHandlerTrigger],
    shallow
  );
  // << ENDS - Stores

  // States - STARTS >>
  const [isHovered, setIsHovered] = useState(false);
  const [showDeselectNodesConfirmModal, setShowDeselectNodesConfirmModal] = useState<
    (() => void) | null
  >(null);
  // << ENDS - States

  const connector = useMemo(() => {
    const connectors = isFunction(connectorsStore) ? connectorsStore() : connectorsStore;
    return (
      find(connectors, {
        name:
          data?.entityDSDetails?.type === thirdPartyTypeName
            ? data?.entityDSDetails?.tpConnectorType
            : data?.entityDSDetails?.type
      }) || {}
    );
  }, [connectorsStore, data?.entityDSDetails?.type]);

  const disabledMessage = useMemo(() => {
    if (
      !!data?.isJobCanvas &&
      includes(
        [DatasetStatuses.UnBuilt, DatasetStatuses.Empty, DatasetStatuses.Skipped],
        data?.status
      )
    ) {
      return `Certain click actions are restricted as supplementary details for the specified entity ${data?.label} are not accessible in the context of this scheduler run entry.`;
    }

    return "";
  }, [data?.isJobCanvas, data?.status, data?.label]);

  // Keeping hooks at the bottom so as to pass the above defined props to it.
  // Hooks - STARTS >>
  const { isCtrlPressed } = useCtrlKeyPress();

  const { onClick } = useActions({
    canAddStandardRecipe,
    data,
    connector,
    disabledMessage
  });
  const { icon, status } = useAttributes({ data, connector });

  const {
    contextMenuAnchorEl,
    // Mouse-leave action is not fully supported. Hence, deferring mouse-enter action.
    // actionsOnMouseEnter,
    closeContextMenu,
    contextMenuProp
  } = useContextMenu({
    data,
    disabledMessage
  });

  const { onMultiSelection, selectedNodes, isNodeSelected, resetSelectedNodes } =
    useNodeMultiSelection({ data });
  // << ENDS - Hooks

  // Mouse-click events - STARTS >>
  const handleSingleClick = () => {
    if (isEmpty(selectedNodes)) {
      onClick();
      return;
    }

    setShowDeselectNodesConfirmModal(() => onClick);
  };
  // << ENDS - Mouse-click events

  // Confirm deselect nodes - STARTS >>
  const resetConfirmDeselectNodes = () => {
    setShowDeselectNodesConfirmModal(() => null);
  };

  const onConfirmDeselectNodes = () => {
    resetSelectedNodes();
    resetConfirmDeselectNodes();
    showDeselectNodesConfirmModal?.();
  };
  // << ENDS - Confirm deselect nodes

  const showContextMenu = useMemo(() => {
    if (!isEmpty(selectedNodes)) {
      if (!isNodeSelected()) {
        return false;
      }
    }

    return true;
  }, [selectedNodes]);

  const additionalStyles = useMemo(() => {
    let thisStyles = classes.defaultStyles;

    if ((data?.entityDSDetails?.id && data?.entityDSDetails?.purpose === "IMPORT") || data?.image) {
      thisStyles = classes.connectorNodeStyles;
    }

    return thisStyles;
  }, [data?.entityDSDetails, data?.image]);

  return (
    <>
      {isFunction(showDeselectNodesConfirmModal) && (
        <DeselectNodesConfirmModal
          selectedNodes={selectedNodes}
          onDeselectNodes={onConfirmDeselectNodes}
          resetConfirmDeselectNodes={resetConfirmDeselectNodes}
        />
      )}

      {!!showContextMenu && (
        <ContextMenuWrapper
          id={`datasetContextMenu-${data?.id}`}
          contextMenuAnchorEl={contextMenuAnchorEl}
          closeContextMenu={closeContextMenu}
          keepMounted>
          <ContextMenu
            open={!!contextMenuAnchorEl}
            closeContextMenu={closeContextMenu}
            isDefaultScenario={!!scenario?.default}
            data={data}
            selectedNodes={selectedNodes}
            resetSelectedNodes={resetSelectedNodes}>
            {!!data?.id && !!data?.isSourceNode && size(selectedNodes) <= 1 && (
              <NodeActionWrapper title={nodesExpanded[data?.id] ?? true ? "Collapse" : "Expand"}>
                <ExpandCollapseIconButton nodeId={data?.id} />
              </NodeActionWrapper>
            )}
          </ContextMenu>
        </ContextMenuWrapper>
      )}

      <NodesWrapper
        nodeId={data?.id}
        disabledMessage={disabledMessage}
        isSourceNode={data?.isSourceNode}
        isTargetNode={data?.isTargetNode}
        backgroundColor={
          has(NodeDatasetTheme, data?.status)
            ? get(NodeDatasetTheme, [data?.status, "backgroundColor"])
            : NodeDatasetTheme.backgroundColor
        }
        isHovered={isHovered}
        setIsHovered={setIsHovered}>
        <div
          role="button"
          data-testid="canvasNode"
          tabIndex={0}
          onMouseEnter={() => setIsHovered(() => true)}
          onMouseLeave={() => setIsHovered(() => false)}
          onClick={handleClickClosure({
            shouldBlockClickHandlerTrigger:
              shouldBlockClickHandlerTriggerStore || data?.shouldDisableBlockInteraction,
            isCtrlPressed,
            handleSingleClick,
            handleMultiSelection: onMultiSelection
          })}
          onKeyPress={handleClickClosure({
            shouldBlockClickHandlerTrigger:
              shouldBlockClickHandlerTriggerStore || data?.shouldDisableBlockInteraction,
            isCtrlPressed,
            handleSingleClick,
            handleMultiSelection: onMultiSelection
          })}
          {...contextMenuProp}
          className={clsx(classes.root, additionalStyles, classes[statusKey], {
            highlighted:
              isNodeHighlighted(data?.label, nodeToFocusStore) ||
              (!!showContextMenu && Boolean(contextMenuAnchorEl)),
            active: isNodeSelected()
          })}>
          <div className={classes.container}>
            <Typography
              variant="caption"
              className={clsx(classes.status, {
                active: isNodeSelected()
              })}>
              {status}
            </Typography>
            <div className={classes.icon}>{icon}</div>
            {data?.label && (
              <NodeCaptionOverflowTooltip label={data?.label}>
                <Typography
                  variant="caption"
                  className={clsx(classes.label, {
                    active: isNodeSelected()
                  })}>
                  {addEllipsis(data?.label)}
                </Typography>
              </NodeCaptionOverflowTooltip>
            )}
          </div>
        </div>
      </NodesWrapper>
    </>
  );
};

export default VectorDB;
