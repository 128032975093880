import React, { useMemo, useState } from "react";

// Packages
import { useNavigate, useParams } from "react-router-dom";
import { useIsMutating, useQueryClient } from "@tanstack/react-query";
import shallow from "zustand/shallow";
import clsx from "clsx";
import { toast } from "react-toastify";
import { delay, get, isEmpty, size } from "lodash";

// MUI
import Grid from "@material-ui/core/Grid";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Typography from "@material-ui/core/Typography";
import { Button, CircularProgress } from "@material-ui/core";

// Icons
import { AddRunIcon, EyeIcon, LogsIcon } from "icons/NewUX";
import { RunIcon } from "icons/NewUX/RunIcon";
import { TrashIcon } from "icons/NewUX/TrashIcon";
import CopyIcon from "icons/NewUX/CopyIcon";
import StopCircleOutlined from "src/assets/icons/StopCircleOutlined";

// Utils
import { areAllKeysPresentAndNotNil } from "src/utils/helpers";
import { checkEnvRelaunch } from "environmentsModule/utils/environmentRelaunch.helpers";
import { ToastTypes, toastWrapper } from "services/ToastClient/toastWrapper";
import {
  CloneRecipeHelperText,
  isRecipeRunInQueue as isRecipeRunInQueueHelper
} from "src/pages/private/ProjectsModule/utils";

// Hooks
import {
  clearRunTransformGroupCache,
  useCloneRecipe,
  UseGetProjectCanvasQueryKeys
} from "src/hooks/api";
import { useAddRecipeToQueue, UseGetRecipeRunsQueueQueryKeys } from "src/hooks/api/recipes";
import { UseCloneRecipeQueryKeys } from "hooks/api/projects/useCloneRecipe";
import useTransformGroup from "hooks/api/transforms/useTransformGroup";
import useStopRecipe from "../../../hooks/useStopRecipe";

// APIs
import { runTransformGroupWithRethrow } from "services/Apis/wrappers";

// Stores
import { useCanvasStore } from "stores/zustand/stores";

// Components
import Modal, { ModalVariants } from "src/components/custom/Modal/Modal";
import RecipeLogsDrawer from "pages/Projects/common/ShowLogsModal/RecipeLogsDrawer";
import { useTransformErrorUtils } from "src/pages/Projects/common/hooks/useTransformErrorUtils";
import DeleteNodeModal, { psMessage } from "src/pages/ViewData/DeleteNodeModal/DeleteNodeModal";
import { CloneRecipeConfirmModal } from "projectsModule/components";
import DeleteNodesModal from "../../DeleteNodes/DeleteNodesModal";

// Constants
import {
  RecipeTypes,
  RecipeStatuses,
  RecipeTypesPathMapping
} from "src/pages/private/ProjectsModule/utils";

// Types
import { NodeData } from "src/types";
import { useContextStyles } from "../useContextMenuStyles";
import NodeActionWrapper from "../NodeActionWrapper";

type Props = {
  open: boolean;
  closeContextMenu: () => void;
  isDefaultScenario: boolean;
  setIsRunRequestPending: (isRunRequestPending: boolean) => void;
  setReloadTriggerWithDelay: () => void;
  data: NodeData;
  selectedNodes: NodeData[];
  resetSelectedNodes: () => void;
  children?: React.ReactNode;
};

const ContextMenu = (props: Props) => {
  const {
    open = false,
    closeContextMenu,
    isDefaultScenario,
    setIsRunRequestPending,
    setReloadTriggerWithDelay,
    data,
    selectedNodes,
    resetSelectedNodes,
    children
  } = props || {};

  const navigate = useNavigate();
  const { projectId, scenarioId } = useParams();

  const queryClient = useQueryClient();
  const { fetchAndRenderFullErrorLogs } = useTransformErrorUtils();
  const stopRecipe = useStopRecipe();
  const [stopRecipeModalOpen, setStopRecipeModalOpen] = useState(false);
  const [showConfirmDeleteNodesModal, setShowConfirmDeleteNodesModal] = useState(false);

  const classes = useContextStyles();

  const [
    isRecipesRunningAcrossScenariosStore,
    isPendingRecipeRunsInQueueStore,
    pendingRecipeRunsInQueueStore,
    setNodeToFocusStore
  ] = useCanvasStore(
    (state) => [
      state.isRecipesRunningAcrossScenarios,
      state.isPendingRecipeRunsInQueue,
      state.pendingRecipeRunsInQueue,
      state.setNodeToFocus
    ],
    shallow
  );

  // States - STARTS >>
  const [isRunning, setIsRunning] = useState(false);
  const [cloneRecipeConfirmModal, showCloneRecipeConfirmModal] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isLogs, setIsLogs] = useState(false);
  // << ENDS - States

  // Query hooks - STARTS >>
  // Queries
  const { isLoading, data: recipe } = useTransformGroup(
    data?.id,
    data?.scenarioId,
    data?.jobProps?.jobRunId,
    {
      cacheTime: Infinity,
      refetchOnMount: true,
      enabled: !!open && areAllKeysPresentAndNotNil(data, ["scenarioId", "id"])
    }
  );

  // Mutations
  const {
    isLoading: isAddingRecipeToQueue,
    mutateAsync: addRecipeToQueueMutation,
    reset: resetAddRecipeToQueueMutation
  } = useAddRecipeToQueue();

  const {
    isLoading: isCloningRecipe,
    mutateAsync: cloningRecipeMutation,
    reset: resetCloningRecipeMutation
  } = useCloneRecipe({ id: data?.id });

  const pendingCloningRecipe = useIsMutating({
    mutationKey: [UseCloneRecipeQueryKeys.CloneRecipe, data?.id],
    exact: true,
    fetching: true
  });

  const isPendingCloningRecipe = useMemo(() => pendingCloningRecipe > 0, [pendingCloningRecipe]);
  // << ENDS - Query hooks

  const handleCancel = () => {
    setStopRecipeModalOpen(false);
  };

  const onView = () => {
    if (!areAllKeysPresentAndNotNil(data, ["projectId", "scenarioId", "id"])) {
      return;
    }

    const recipeType = get(RecipeTypesPathMapping, data?.recipeType || RecipeTypes.Template);
    let path = `/projects/${data?.projectId}/scenario/${data?.scenarioId}/${recipeType}/${data?.id}`;

    if (!!data?.jobProps) {
      if (
        !data?.isJobCanvasPath ||
        !areAllKeysPresentAndNotNil(data?.jobProps, ["jobId", "jobRunId"])
      ) {
        return;
      }

      path = `/projects/${data?.projectId}`;
      path = `${path}/jobs/${data?.jobProps?.jobId}`;
      path = `${path}/scenario/${data?.scenarioId}`;
      path = `${path}/job-runs/${data?.jobProps?.jobRunId}`;
      path = `${path}/recipes/${recipeType}/${data?.id}`;
    }

    !!path && navigate(path);
  };

  const addRecipeToQueue = async () => {
    await resetAddRecipeToQueueMutation();
    await addRecipeToQueueMutation(
      { scenarioId: data?.scenarioId, id: data?.id },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries([UseGetRecipeRunsQueueQueryKeys.RecipeRunsQueue]);
          await queryClient.invalidateQueries([UseGetProjectCanvasQueryKeys.ProjectCanvas]);

          toastWrapper({
            type: ToastTypes.Info,
            content: `Recipe ${data?.label} added to queue successfully!`
          });
        },
        onError: () => {
          toastWrapper({
            type: ToastTypes.Error,
            content: `Error while adding Recipe ${data?.label} to queue!`
          });
        }
      }
    );
  };

  const runRecipe = async () => {
    if (!areAllKeysPresentAndNotNil(data, ["scenarioId", "id"])) {
      return;
    }

    !!data?.projectId && checkEnvRelaunch(data?.projectId);

    if (!!isRecipesRunningAcrossScenariosStore || !!isPendingRecipeRunsInQueueStore) {
      await addRecipeToQueue();
      return;
    }

    setIsRunning(() => true);
    setIsRunRequestPending(true);

    const toastId = toastWrapper({
      content: "View the recipe run log",
      actions: [
        {
          action: () => {
            setIsLogs(true);
          },
          label: "View Log"
        }
      ],
      type: ToastTypes.Info
    });

    try {
      setReloadTriggerWithDelay();

      delay(
        () => queryClient.invalidateQueries([UseGetRecipeRunsQueueQueryKeys.RecipeRunsQueue]),
        1000
      );

      await runTransformGroupWithRethrow(data?.id, data?.scenarioId, undefined, false);
      await clearRunTransformGroupCache(queryClient);
      queryClient.invalidateQueries({
        queryKey: [UseGetProjectCanvasQueryKeys.ProjectCanvas]
      });
    } catch (e) {
      toast.dismiss(toastId);

      fetchAndRenderFullErrorLogs(e, {
        projectId: data?.projectId,
        scenarioId: data?.scenarioId,
        groupId: data?.id
      });
    } finally {
      setIsRunning(() => false);
      setIsRunRequestPending(false);
    }
  };

  const stopRunningRecipe = () => {
    stopRecipe.mutate(
      { groupId: data?.id, scenarioId: data?.scenarioId },
      {
        onSuccess: () => {
          setStopRecipeModalOpen(false);
          toastWrapper({ type: "info", content: "Recipe stop action is completed" });
          clearRunTransformGroupCache(queryClient);
          queryClient.invalidateQueries({
            queryKey: [UseGetProjectCanvasQueryKeys.ProjectCanvas]
          });
        }
      }
    );
  };

  const cloneRecipe = async () => {
    await resetCloningRecipeMutation();
    await cloningRecipeMutation(
      { id: data?.id },
      {
        onSuccess: (cloneRecipeResponse) => {
          toastWrapper({
            type: ToastTypes.Success,
            content: !!data?.label
              ? `Recipe ${data?.label} duplicated successfully!`
              : "Recipe duplicated successfully!"
          });

          const newRecipeLabel = cloneRecipeResponse?.displayName || cloneRecipeResponse?.name;
          !!newRecipeLabel && setNodeToFocusStore(newRecipeLabel);

          resetConfirmCloneRecipe();
          closeContextMenu();
        }
      }
    );
  };

  const isRecipeRunInQueue = useMemo(
    () =>
      isRecipeRunInQueueHelper({
        pendingRecipeRunsInQueue: pendingRecipeRunsInQueueStore,
        recipeId: data?.id,
        scenarioId: data?.scenarioId
      }),
    [pendingRecipeRunsInQueueStore, data?.id, data?.scenarioId]
  );

  const isRecipeRunDisabled = useMemo(
    () =>
      isRunning ||
      isAddingRecipeToQueue ||
      data?.status === RecipeStatuses.Running ||
      isRecipeRunInQueue ||
      size(recipe?.runConfigs) === 0,
    [isRunning, isAddingRecipeToQueue, data?.status, isRecipeRunInQueue, recipe?.runConfigs]
  );

  const onDelete = () => {
    setIsDeleteModalOpen(true);
  };

  // const handleDuplicate = () => {
  //   return;
  // };

  const isAddRecipeToQueue = useMemo(
    () =>
      data?.status !== RecipeStatuses.Running &&
      (!!isRecipesRunningAcrossScenariosStore || !!isPendingRecipeRunsInQueueStore),
    [data?.status, isRecipesRunningAcrossScenariosStore, isPendingRecipeRunsInQueueStore]
  );

  // Clone recipe - STARTS >>
  const promptConfirmCloneRecipe = () => {
    showCloneRecipeConfirmModal(() => true);
  };

  const resetConfirmCloneRecipe = () => {
    showCloneRecipeConfirmModal(() => false);
  };
  // << ENDS - Clone recipe

  // Delete nodes - STARTS >>
  const promptConfirmDeleteNodes = () => {
    setShowConfirmDeleteNodesModal(() => true);
  };

  const resetConfirmDeleteNodes = () => {
    setShowConfirmDeleteNodesModal(() => false);
  };
  // << ENDS - Delete nodes

  const disabledCloneActionMessage = useMemo(() => {
    if (!!isCloningRecipe || !!isPendingCloningRecipe) {
      return CloneRecipeHelperText.CloneRecipeActionInfo;
    }

    return "";
  }, [isCloningRecipe, isPendingCloningRecipe]);

  const buttonComponents = useMemo(() => {
    let items: React.ReactNode[] = [];

    if (!!data?.isJobCanvas) {
      if (!data?.isJobCanvasPath) {
        return [];
      }
    }

    if (size(selectedNodes) > 1) {
      items.push(
        <Button
          size="small"
          key="delete"
          data-testid="recipeContextMenuDeleteSelected"
          startIcon={<TrashIcon viewBox="0 0 20 22" />}
          onClick={promptConfirmDeleteNodes}>
          Delete Selected
        </Button>
      );

      return items;
    }

    items.push(
      <Button
        key="view"
        className="context-menu-border"
        data-testid="Preview"
        startIcon={<EyeIcon viewBox="0 0 20 20" />}
        size="small"
        onClick={onView}>
        Preview
      </Button>
    );

    if (!data?.isJobCanvas) {
      items.push(
        <NodeActionWrapper
          key="run-recipe"
          title={
            size(recipe?.runConfigs) === 0
              ? "No transformations in Recipe!"
              : data?.status === RecipeStatuses.Running
                ? "Recipe Run is in progress..."
                : isRecipeRunInQueue
                  ? "Recipe is already in queue!"
                  : ""
          }>
          <Button
            key="run-recipe"
            size="small"
            style={{ minWidth: "24px" }}
            disabled={isRecipeRunDisabled}
            data-testid="Run Recipe"
            startIcon={
              isRunning ||
              isAddingRecipeToQueue ||
              data?.status === RecipeStatuses.Running ||
              isLoading ? (
                <CircularProgress size={16} />
              ) : isAddRecipeToQueue || isRecipeRunInQueue ? (
                <AddRunIcon width={18} height={16} viewBox="1 2 24 20" />
              ) : (
                <RunIcon width={14} viewBox="0 1 16 16" />
              )
            }
            onClick={runRecipe}>
            {data?.status === RecipeStatuses.Running || isRunning
              ? "Recipe Running"
              : isRecipeRunInQueue
                ? "Added to Queue"
                : !!isRecipesRunningAcrossScenariosStore || !!isPendingRecipeRunsInQueueStore
                  ? "Add to Queue"
                  : "Run Recipe"}
          </Button>
        </NodeActionWrapper>
      );

      if (!isLoading && data?.status === RecipeStatuses.Running) {
        items.push(
          <Button
            key="stop-recipe"
            size="small"
            data-testid="Stop Recipe Run"
            style={{ minWidth: "24px" }}
            className={clsx({ disabled: data?.status !== RecipeStatuses.Running })}
            disabled={stopRecipe.isLoading}
            startIcon={<StopCircleOutlined fill={stopRecipe.isLoading ? "grey" : undefined} />}
            onClick={() => {
              setStopRecipeModalOpen(true);
              closeContextMenu();
            }}>
            Stop Recipe Run
          </Button>
        );
      }

      if (!!isDefaultScenario) {
        items.push(
          <NodeActionWrapper key="clone-recipe" title={disabledCloneActionMessage}>
            <Button
              size="small"
              data-testid="cloneRecipe"
              disabled={!!disabledCloneActionMessage}
              onClick={promptConfirmCloneRecipe}
              startIcon={
                !!isPendingCloningRecipe || !!isCloningRecipe ? (
                  <CircularProgress size={16} />
                ) : (
                  <CopyIcon />
                )
              }>
              Duplicate
            </Button>
          </NodeActionWrapper>
        );
      }
    }

    items.push(
      <Button
        key="view-logs"
        size="small"
        data-testid="Logs"
        className="context-menu-border"
        startIcon={<LogsIcon viewBox="0 -2 24 24" />}
        onClick={() => {
          setIsLogs(true);
        }}>
        Logs
      </Button>
    );

    if (!!isDefaultScenario && !data?.isJobCanvas) {
      items.push(
        <Button
          key="delete"
          data-testid="Delete"
          size="small"
          startIcon={<TrashIcon viewBox="0 0 20 22" />}
          onClick={onDelete}>
          Delete
        </Button>
      );
    }

    return items;
  }, [
    selectedNodes,
    data?.isJobCanvas,
    data?.isJobCanvasPath,
    data?.status,
    isDefaultScenario,
    isLoading,
    isRecipeRunDisabled,
    recipe?.runConfigs,
    isRecipesRunningAcrossScenariosStore,
    isAddRecipeToQueue,
    isPendingRecipeRunsInQueueStore,
    stopRecipe.isLoading,
    isPendingCloningRecipe,
    isCloningRecipe,
    disabledCloneActionMessage
  ]);

  return (
    <>
      {!!cloneRecipeConfirmModal && (
        <CloneRecipeConfirmModal
          isCloningRecipe={isCloningRecipe}
          cloneRecipe={cloneRecipe}
          resetConfirmCloneRecipe={resetConfirmCloneRecipe}
        />
      )}

      {!!showConfirmDeleteNodesModal && (
        <DeleteNodesModal
          projectId={projectId}
          scenarioId={scenarioId}
          selectedNodes={selectedNodes}
          resetSelectedNodes={resetSelectedNodes}
          resetConfirmDeleteNodes={resetConfirmDeleteNodes}
        />
      )}

      {!!isLogs && (
        <RecipeLogsDrawer
          open
          isRunRecipeLogs={true}
          isRecipeRunning={data?.status === RecipeStatuses.Running}
          name={data?.displayName || data?.name || ""}
          projectId={data?.projectId}
          scenarioId={data?.scenarioId}
          jobRunId={data?.jobProps?.jobRunId}
          groupId={data?.id}
          isJobPath={!!data?.jobProps}
          onClose={() => {
            setIsLogs(false);
            closeContextMenu();
          }}
        />
      )}

      {!!stopRecipeModalOpen && (
        <Modal
          open
          variant={ModalVariants.Delete}
          title={
            <Grid direction="column" container>
              <Typography>Stop Recipe Run</Typography>
              <span style={{ fontSize: "12px", fontStyle: "italic", color: "grey" }}>
                This process may take some time to get completed.
              </span>
            </Grid>
          }
          content={[
            "This action updates the recipe status to Unbuilt, shuts down the related project environment, and restarts it. This may cause any other recipes depending on this environment to fail.",
            <>
              <b>Note:</b> If recipe execution gets completed before associated environment restart
              then the status of the recipe will be based on the run result.
            </>,
            "Are you sure you want to proceed with this?"
          ]}
          submitLabel="Yes, Proceed"
          onClose={handleCancel}
          isSubmitting={stopRecipe.isLoading}
          onSubmit={stopRunningRecipe}
        />
      )}

      {!!isDeleteModalOpen && (
        <DeleteNodeModal
          open
          nodeId={data?.id}
          nodeName={data?.label}
          nodeType="group"
          deleteNote={
            <div>
              {data?.recipeType === RecipeTypes.RapidModel ? (
                <span>Note: Deleting this might impact associated DataApps (if any).</span>
              ) : (
                <span>Note: </span>
              )}
              {psMessage}
            </div>
          }
          onDeleteSuccess={resetSelectedNodes}
          onClose={() => {
            setIsDeleteModalOpen(false);
          }}
          onAfterSubmit={closeContextMenu}
        />
      )}

      {(React.isValidElement(children) || !isEmpty(buttonComponents)) && (
        <ButtonGroup variant="text" size="small" orientation="vertical" className={classes.root}>
          {!isEmpty(buttonComponents) && buttonComponents}
          {children}
        </ButtonGroup>
      )}
    </>
  );
};

export default ContextMenu;
