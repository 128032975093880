// Packages
import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { isArray } from "lodash";

// Open API
import api from "services/AxiosClient/AxiosClient";
import { EnvDto } from "@rapidcanvas/rc-api-core";

export const enum UseGetEnvironmentQueryKeys {
  Environment = "environment"
}

interface IEnvironmentProps extends UseQueryOptions<unknown, unknown, EnvDto> {
  id?: string;
}

const useGetEnvironment = (props: IEnvironmentProps): UseQueryResult<EnvDto> => {
  const { id, ...options } = props;

  return useQuery({
    queryKey: [UseGetEnvironmentQueryKeys.Environment, id],
    queryFn: async () => {
      if (!id) {
        throw "Invalid payload!";
      }

      return await api.fetchResponse(async () => await api.EnvControllerApi.findEnvById(id));
    },
    select: (data: any) => (isArray(data) ? data?.[0] : []),
    enabled: !!id,
    ...options
  });
};

export default useGetEnvironment;
