import { useMutation } from "@tanstack/react-query";

import { postAPIWithRethrow } from "services/Apis/Apis.service";

export const useLicenseSyncMutation = () => {
  return useMutation({
    mutationFn: () => {
      return postAPIWithRethrow(`/license/sync/current`, {});
    }
  });
};
