import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { ArtifactDto } from "@rapidcanvas/rc-api-core";
import api from "services/AxiosClient/AxiosClient";

export const QUERY_KEY_ARTIFACTS = "artifacts";

export const useGetArtifacts = (options?: UseQueryOptions<ArtifactDto[]>) => {
  return useQuery<ArtifactDto[]>({
    queryKey: [QUERY_KEY_ARTIFACTS],
    queryFn: async () => {
      const response = await api.fetchResponse(
        async () => await api.ArtifactsControllerApi.getArtifacts()
      );

      return response.artifacts ?? [];
    },
    ...options
  });
};
