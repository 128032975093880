import { makeStyles, Theme } from "@material-ui/core";

export const useContextStyles = makeStyles<Theme>((theme) => ({
  root: {
    "& button": {
      minWidth: theme.spacing(4),
      color: "#fff",
      justifyContent: "flex-start",
      background: "transparent",
      width: "100%",
      padding: "4px 12px",
      fontWeight: 400,

      "&.context-menu-border": {
        borderBottom: "1px solid #595959"
      },

      "&:hover": {
        background: "#545454"
      },

      "&:disabled": {
        color: "grey",
        cursor: "not-allowed",

        "& svg": {
          color: "grey"
        }
      },

      "& svg": {
        color: theme.palette.common.white
      }
    }
  }
}));
