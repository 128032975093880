import React from "react";

import { ProjectDto } from "@rapidcanvas/rc-api-core";

import { IEntity } from "src/hooks/api/entities/useEntities";

type ContextTypes = {
  scenarioId?: string;

  isLoadingProject?: boolean;
  project?: ProjectDto;

  isLoadingDataset?: boolean;
  dataset?: IEntity;

  reset: $TSFixMeFunction;
  navigateBack: $TSFixMeFunction;
  navigateBackWithoutRedirect: $TSFixMeFunction;
};

// @ts-ignore
export const DatasetContext = React.createContext<ContextTypes>();
