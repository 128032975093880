import _ from "lodash";
import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import api from "services/AxiosClient/AxiosClient";
import { ArtifactDto } from "@rapidcanvas/rc-api-core";

export const QUERY_KEY_ARTIFACTS_PAGINATED = "artifacts-paginated";

interface IReturn {
  data: ArtifactDto[];
  total: number;
}

// const PAGE_SIZE = 50;

const useGetPaginatedArtifacts = (projectId?: string, options?: UseQueryOptions<IReturn>) => {
  // const currentRef = useRef(0);
  // const queryClient = useQueryClient();

  return useQuery<IReturn>({
    queryKey: [QUERY_KEY_ARTIFACTS_PAGINATED, projectId],
    queryFn: async () => {
      // const start = currentRef.current * PAGE_SIZE;
      // const end = (currentRef.current + 1) * PAGE_SIZE;
      const response = await api.fetchResponse(
        async () => await api.ArtifactsControllerApi.getArtifacts1(undefined, undefined, projectId)
      );
      // const total = response.totalElements ?? 0;
      // const oldData = queryClient.getQueryData([QUERY_KEY_ARTIFACTS_PAGINATED]) as IReturn;

      // const newData = {
      //   data: _.uniqBy([...(oldData?.data ?? []), ...(response.content ?? [])], "name"),
      //   start,
      //   end,
      //   total
      // };

      // if (total > end) {
      //   currentRef.current += 1;
      //   queryClient.setQueryData([QUERY_KEY_ARTIFACTS_PAGINATED], newData);
      //   queryClient.invalidateQueries([QUERY_KEY_ARTIFACTS_PAGINATED]);
      // }

      return { data: response.content ?? [], total: response.totalElements ?? 0 };
    },
    refetchOnMount: true,
    ...options
  });
};

export default useGetPaginatedArtifacts;
