import React, { useEffect, useMemo, useState } from "react";

// Packages
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { capitalize, map } from "lodash";

// MUI
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles, useTheme } from "@material-ui/core/styles";

// Open API
import { GetDataFilesSubTypeEnum } from "@rapidcanvas/rc-api-core";

// Utils
import { ViewFileWebPathQueryParameters } from "../../utils";

// Stores
import useAuthStore from "stores/zustand/auth.store";

// Hooks
import useEntities from "src/hooks/api/entities/useEntities";
import useEntityDetails from "src/hooks/api/entities/useEntityDetails";
import useIsDefaultScenario from "src/pages/private/ProjectsModule/hooks/useIsDefaultScenario";

// Components
import { Tabs, Tab, TabPanel } from "src/components";
import CommonLoader from "src/components/CommonLoader";
import Source from "src/pages/ViewData/Source/Source";
import Header from "./components/Header";
import { Data } from "../../components";

export const useStyles = makeStyles((theme) => ({
  dataContainer: {
    height: "calc(100vh - 190px)",
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1.5),
    overflow: "auto"
  }
}));

const ViewFile = () => {
  const classes = useStyles();
  const theme = useTheme();

  const { projectId, scenarioId, fileId, jobRunId } = useParams();

  const location = useLocation();
  const isJobPath = useMemo(() => /jobs/.test(location.pathname), [location.pathname]);

  const [searchParams] = useSearchParams();
  const tabQueryParam = searchParams.get(ViewFileWebPathQueryParameters.Tab);

  const userId = useAuthStore((state) => state.userId);

  const isDefaultScenario = useIsDefaultScenario({ projectId, scenarioId });

  // Query hooks - STARTS >>
  const { isLoading, data } = useEntities({
    id: fileId!,
    projectId,
    scenarioId,
    jobRunId
  });

  const { isLoading: isLoadingFileDetails, data: fileDetails } = useEntityDetails(
    fileId,
    scenarioId,
    jobRunId,
    {
      refetchOnMount: true
    }
  );
  // << ENDS - Query hooks

  const [tabValue, setTabValue] = useState(ViewFileWebPathQueryParameters.Data as string);

  useEffect(() => {
    if (!!tabQueryParam) {
      setTabValue(() => tabQueryParam);
    }
  }, [tabQueryParam]);

  return !!isLoading ? (
    <CommonLoader />
  ) : (
    <>
      <Header
        isJobPath={!!isJobPath}
        isDefaultScenario={!!isDefaultScenario}
        data={data}
        fileDetails={fileDetails}
        isLoading={isLoadingFileDetails}
      />

      <Box p={2}>
        <Grid container direction="column" style={{ rowGap: theme.spacing(1.5) }}>
          <Grid item>
            <Tabs
              value={tabValue}
              onChange={setTabValue}
              textColor="primary"
              indicatorColor="primary"
              variant="scrollable">
              {map(
                !!fileDetails?.rootEntity
                  ? [ViewFileWebPathQueryParameters.Data, ViewFileWebPathQueryParameters.Source]
                  : [ViewFileWebPathQueryParameters.Data],
                (tab) => (
                  <Tab key={`${tab}Key`} value={tab} label={capitalize(tab)} />
                )
              )}
            </Tabs>
          </Grid>
          <Grid item>
            <TabPanel value={tabValue} index={ViewFileWebPathQueryParameters.Data}>
              <Paper elevation={0} className={classes.dataContainer}>
                <Data
                  projectId={projectId}
                  scenarioId={scenarioId}
                  fileId={fileId}
                  jobRunId={jobRunId}
                  data={data}
                  fileDetails={fileDetails}
                  isLoadingFileDetails={isLoadingFileDetails}
                  editorProps={{
                    height: "calc(100vh - 230px)"
                  }}
                />
              </Paper>
            </TabPanel>
            {!!fileDetails?.rootEntity && (
              <TabPanel value={tabValue} index={ViewFileWebPathQueryParameters.Source}>
                <Source
                  isTextInput
                  userId={userId}
                  isJobPath={isJobPath}
                  isDefaultScenario={!!isDefaultScenario}
                  dataset={data}
                  isLoading={isLoading}
                  dataFilesSubType={GetDataFilesSubTypeEnum.Merged}
                />
              </TabPanel>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ViewFile;
