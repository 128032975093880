import React, { useMemo } from "react";
import { useLocation, useParams } from "react-router";
import { Grid, IconButton, makeStyles } from "@material-ui/core";

import CodeRecipeInputContainer from "../CodeRecipeInputContainer/CodeRecipeInputContainer";

import GlobalVariables from "../GlobalVariables";
import Secrets from "../Secrets";
import PredictionServices from "../PredictionServices";
import { useCodeRecipeContext } from "../CodeRecipeContext/useCodeRecipeContext";

import useAuthStore from "stores/zustand/auth.store";

import { PredictionServiceDto } from "@rapidcanvas/rc-api-core";
import { CollapseIcon } from "src/assets/icons/CollapseIcon";
import { ExpandIcon } from "src/assets/icons/ExpandIcon";
import CodeRecipeCanvasEntitiesContainer from "../CodeRecipeCanvasEntities/CodeRecipeCanvasEntitiesContainer";

const useStyles = makeStyles({
  gridContainer: {
    gap: "18px",
    padding: "16px 0px 16px 16px",
    display: "flex",
    flexDirection: "column",
    flexShrink: 0,
    flexGrow: 0,
    maxHeight: "100%",
    width: "calc(100% - 22px)",
    height: "inherit"
  },
  leftSection: {
    height: "100%"
  }
});

export const CodeRecipeLeftDrawer = ({
  isExpanded,
  onCollapse,
  isDefaultScenario,
  selectedPredictionService,
  setSelectedPredictionService,
  handleTabChange,
  openAddEntitiesDialog
}: {
  isExpanded: boolean;
  onCollapse: () => void;
  isDefaultScenario: boolean;
  selectedPredictionService: PredictionServiceDto[];
  setSelectedPredictionService: React.Dispatch<React.SetStateAction<PredictionServiceDto[]>>;
  handleTabChange: React.Dispatch<React.SetStateAction<string>>;
  openAddEntitiesDialog: () => void;
}) => {
  const classes = useStyles();
  const { projectId, scenarioId, jobRunId, jobId } = useParams();
  const location = useLocation();
  const isJobPath = useMemo(() => /jobs/.test(location.pathname), [location.pathname]);

  const [hasPrivileges] = useAuthStore((state) => [state.hasPrivileges]);
  const {
    inputDatasets,
    recipe,
    entityFeaturesMap,
    handleInputDatasetsChange,
    isSelectedEntitiesUpdateInProgess,
    selectedArtifacts,
    selectedModels,
    selectedCodeRecipe,
    setSelectedCodeRecipe,
    setCurrentSelectedCodeRecipe
  } = useCodeRecipeContext();

  return (
    <Grid
      container
      item
      direction="column"
      style={{
        width: isExpanded ? "350px" : "15px",
        minWidth: isExpanded ? "350px" : "15px",
        maxWidth: isExpanded ? "350px" : "15px"
      }}
      className={classes.leftSection}>
      <Grid
        container
        style={{
          flexWrap: "nowrap",
          height: "100%",
          overflow: "auto",
          gap: "10px",
          width: "inherit"
        }}>
        {isExpanded && (
          <Grid item className={classes.gridContainer}>
            <CodeRecipeInputContainer
              selectedArtifacts={selectedArtifacts}
              selectedModels={selectedModels}
              inputDatasets={inputDatasets}
              entityFeaturesMap={entityFeaturesMap}
              handleInputDatasetsChange={handleInputDatasetsChange}
              openAddEntitiesDialog={openAddEntitiesDialog}
              isDefaultScenario={isDefaultScenario}
              isSelectedEntitiesUpdateInProgress={isSelectedEntitiesUpdateInProgess}
            />
            <Grid style={{ maxHeight: "50%", width: "100%", padding: "10px 5px" }}>
              <CodeRecipeCanvasEntitiesContainer
                projectId={projectId}
                scenarioId={scenarioId}
                isJobpath={isJobPath}
                jobId={jobId}
                jobRunId={jobRunId}
                recipeId={recipe?.id}
                setValue={handleTabChange}
                selectedCodeRecipe={selectedCodeRecipe}
                setSelectedCodeRecipe={setSelectedCodeRecipe}
                setCurrentSelectedCodeRecipe={setCurrentSelectedCodeRecipe}
              />
            </Grid>
            <Grid style={{ maxHeight: "50%", width: "100%", padding: "10px 5px" }}>
              <PredictionServices
                projectId={projectId}
                selectedPredictionService={selectedPredictionService}
                setSelectedPredictionService={setSelectedPredictionService}
                setValue={handleTabChange}
              />
            </Grid>
            <Grid style={{ maxHeight: "50%", width: "100%", padding: "10px 5px" }}>
              <GlobalVariables
                projectId={projectId}
                scenarioId={scenarioId}
                jobId={jobId}
                jobRunId={jobRunId}
              />
            </Grid>
            {hasPrivileges && (
              <Grid style={{ maxHeight: "50%", width: "100%", padding: "10px 5px" }}>
                <Secrets />
              </Grid>
            )}
          </Grid>
        )}
        <Grid container style={{ height: "100%" }} alignItems="center">
          <div
            onClick={onCollapse}
            style={{
              background: "#D9D9D9",
              height: "66px",
              display: "flex",
              alignItems: "center",
              cursor: "pointer"
            }}>
            <IconButton style={{ padding: 0, width: "12px", color: "black" }}>
              {isExpanded ? <CollapseIcon /> : <ExpandIcon />}
            </IconButton>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};
