import React from "react";
import { Link } from "react-router-dom";
import { makeStyles, Tooltip } from "@material-ui/core";

import Text from "src/components/Widget/Text";

const useStyles = makeStyles(() => ({
  detailsWrapper: {
    fontSize: "14px",
    display: "flex",
    flexDirection: "column",
    gap: "8px"
  },
  detailItem: {
    display: "flex",
    alignItems: "center",

    "& a": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      width: "68%",
      marginBottom: 0
    }
  },
  detailName: {
    color: "#515151",
    fontWeight: 500,
    paddingRight: "6px"
  },
  sourceImage: {
    display: "inline-block",
    width: 20,
    height: 20,
    marginRight: 5,
    "& img": {
      width: "100%",
      height: "100%"
    }
  },
  connector: {
    width: "85%",
    overflow: "hidden",
    whiteSpace: "nowrap",
    marginBottom: 0,
    textOverflow: "ellipsis"
  }
}));

const DataSourceEntitySourceDetails = (props: $TSFixMe) => {
  const { data } = props || {};

  const classes = useStyles();

  return (
    <div className={classes.detailsWrapper}>
      <div className={classes.detailItem}>
        <span className={classes.detailName}>Data Connector Name:</span>
        {!!data?.entityDSDetails?.id && !!data?.entityDSDetails?.name ? (
          <Tooltip placement="top-start" title={data?.entityDSDetails?.name}>
            <Link to={`/data-connectors/${data?.entityDSDetails?.id}`}>
              {data?.entityDSDetails?.name}
            </Link>
          </Tooltip>
        ) : (
          "Unknown"
        )}
      </div>
      <div className={classes.detailItem}>
        <span className={classes.detailName}>Type:</span>
        <span className={classes.sourceImage}>
          {data?.connector?.type === "default" && data?.connector?.image ? (
            data?.connector?.image
          ) : (
            <img src={data?.connector?.url} alt={data?.connector?.displayName} />
          )}
        </span>
        <Tooltip
          placement="bottom-start"
          title={<Text value={data?.connector?.displayName || "Unknown"} />}>
          <span className={classes.connector}>
            {
              <Text
                style={{ whiteSpace: "nowrap" }}
                value={data?.connector?.displayName || "Unknown"}
              />
            }
          </span>
        </Tooltip>
      </div>
    </div>
  );
};

export default DataSourceEntitySourceDetails;
