import api from "services/AxiosClient/AxiosClient";
import { NotebookTypes } from "constants/index";

let notebookTabsRefs: $TSFixMe = [];

const setIsLoading = ({
  setIsLoggingNotebooksTypeUserOut,
  setIsLoggingNotebooksTypeTenantOut,
  notebookType,
  isLoading = false
}: $TSFixMe) => {
  if (notebookType) {
    switch (notebookType) {
      case String(NotebookTypes.User)?.toLowerCase():
        setIsLoggingNotebooksTypeUserOut(isLoading);
        break;
      case String(NotebookTypes.Tenant)?.toLowerCase():
        setIsLoggingNotebooksTypeTenantOut(isLoading);
        break;
    }
  }
};

export const isOpenNotebookTab = async ({
  path,
  setIsLoggingNotebooksTypeUserOut,
  setIsLoggingNotebooksTypeTenantOut
}: $TSFixMe) =>
  await new Promise<void>((resolve) => {
    if (notebookTabsRefs?.length === 0) {
      resolve();
    }

    const isIdenticalPaths = notebookTabsRefs.every(
      (notebookTabRef: $TSFixMe) => notebookTabRef.path === path
    );

    if (!isIdenticalPaths) {
      const params = new URL(path).searchParams;
      const notebookType = params.get("notebookType");

      setIsLoading({
        setIsLoggingNotebooksTypeUserOut,
        setIsLoggingNotebooksTypeTenantOut,
        notebookType,
        isLoading: true
      });

      logNotebooksOut({ closeTabs: true })
        .then(() => {
          resolve();
        })
        .finally(() => {
          setIsLoading({
            setIsLoggingNotebooksTypeUserOut,
            setIsLoggingNotebooksTypeTenantOut,
            notebookType,
            isLoading: false
          });
        });
    } else {
      resolve();
    }
  });

export const openNotebookTab = ({
  path,
  setIsLoggingNotebooksTypeUserOut,
  setIsLoggingNotebooksTypeTenantOut
}: $TSFixMe) => {
  isOpenNotebookTab({
    path,
    setIsLoggingNotebooksTypeUserOut,
    setIsLoggingNotebooksTypeTenantOut
  }).then(() => {
    // Storing window object reference of new tab.
    notebookTabsRefs.push({
      path,
      windowRef: window.open(path, "_blank")
    });
  });
};

const logNotebookOut = async (domain: $TSFixMe) => {
  try {
    const url = `${domain}/notebooks/hub/logout`;

    await api.client(url);
  } catch (e) {
    console.error(e);
  }
};

export const logNotebooksOut = async ({ closeTabs = false } = {}) =>
  await new Promise<void>((resolve) => {
    const domain = window.location.origin;

    domain && logNotebookOut(domain);

    setTimeout(() => {
      notebookTabsRefs.length > 0 &&
        notebookTabsRefs.map((notebookTabRef: $TSFixMe) => {
          if (closeTabs) {
            notebookTabRef.windowRef?.close();
          } else {
            notebookTabRef.windowRef?.location?.reload();
            // notebookTabRef.windowRef?.close();
          }
        });

      if (closeTabs) {
        notebookTabsRefs = [];
      }

      resolve();
    }, 3000);
  });
