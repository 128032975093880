import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  IconButton,
  makeStyles,
  Typography,
  CircularProgress,
  Tooltip,
  Theme
} from "@material-ui/core";
import { Close } from "@material-ui/icons";

import ModalTitle from "./ModalTitle";
import clsx from "clsx";
import { useStyles as useSubtopBarStyles } from "../SubtopBar/styling";
import NewThemeWrapper from "src/styles/NewThemeWrapper";

interface IStyleProps {
  width?: string;
  minHeight?: string;
  height?: string | number;
  fullWidth?: boolean;
  align?: "top" | "center";
  onlyContent?: boolean;
  actionStyles?: React.CSSProperties;
}

const useStyles = makeStyles<Theme, IStyleProps>((theme) => ({
  paper: ({ align, width, height, minHeight }) => {
    const positionProps =
      align === "top"
        ? {
            height: "60vh",
            width: "60vw"
          }
        : {
            maxWidth: width ? width : 979,
            maxHeight: height ? height : 532,
            ...(minHeight && { minHeight })
          };
    return {
      ...positionProps,
      background: "#FFFFFF",
      borderRadius: 8,
      padding: "32px 24px",
      border: "1px solid #D2DAE6",
      boxShadow: "0px 0.8px 1.5px rgba(0, 0, 0, 0.1), 0px 6px 12px rgba(0, 0, 0, 0.2)"
    };
  },
  root: {
    position: "relative",

    "& .MuiTooltip-popper": {
      zIndex: 9999999
    }
  },
  content: {
    position: "relative",
    overflowY: "auto",
    overflowX: "hidden",
    padding: "0px !important",
    minHeight: ({ height }) => (height ? "default" : 250),
    marginTop: ({ onlyContent }) => (onlyContent ? 0 : 40)
  },
  button: {
    backgroundColor: "#4646b5",
    color: "#fff",
    "&:disabled": { opacity: 0.5 }
  },
  actions: ({ actionStyles }: any) => {
    return (
      actionStyles ?? {
        padding: 0,
        paddingTop: 20,
        borderTop: "1px solid #E0E0E0",
        marginTop: 24
      }
    );
  },
  tabs: {
    "& .MuiTab-root": {
      minWidth: 0,
      color: theme.palette.primary.main,
      textTransform: "none"
    },
    "& .Mui-selected": {
      backgroundColor: "#edeff3",
      borderRadius: "4px 4px 0px 0px"
    },
    "& .MuiTabs-flexContainer": {
      float: "right"
    },
    "& .MuiTabs-indicator": {
      backgroundColor: theme.palette.primary.main // "#425e7f"
    }
  },
  subtitle: {
    color: theme.palette.primary.light,
    paddingBottom: 20
  },
  titleDescription: {
    fontStyle: "italic",
    color: "#000000",
    opacity: 0.5
  }
}));

interface Props {
  onClose: () => void;
  open: boolean;
  hideCloseButton?: boolean;
  title?: string;
  withForm?: boolean;
  className?: string;
  isNewTheme?: boolean;
  subtitle?: string;
  titleDescription?: string;
  children?: React.ReactNode;
  onCancel?: () => void;
  hideCancel?: boolean;
  actionStyles?: React.CSSProperties;
  onSubmit?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  submitDataTestId?: string;
  cancelDataTestId?: string;
  cancelButtonLabel?: string;
  submitButtonLabel?: string;
  maxWidth?: DialogProps["maxWidth"];
  style?: React.CSSProperties;
  keepMounted?: boolean;
  align?: "top" | "center";
  isSubmitLoading?: boolean;
  submitButtonClassName?: string;
  submitButtonId?: string;
  tooltipTitle?: string;
  submitDisabled?: boolean;
  width?: string;
  minHeight?: string;
  height?: string | number;
  fullWidth?: boolean;
  titleIcon?: React.JSX.Element;
  paperStyle?: Record<string, string | number>;
}

const Modal = ({
  fullWidth = true,
  open,
  hideCloseButton,
  title,
  titleIcon,
  subtitle,
  titleDescription,
  actionStyles,
  submitDataTestId,
  cancelDataTestId,
  children,
  maxWidth,
  onClose,
  onCancel,
  hideCancel = false,
  onSubmit,
  withForm,
  cancelButtonLabel = "Cancel",
  submitButtonLabel = "Save",
  submitDisabled = false,
  width,
  minHeight,
  height,
  align = "center",
  isSubmitLoading = false,
  submitButtonClassName,
  submitButtonId,
  keepMounted,
  tooltipTitle,
  isNewTheme = false,
  paperStyle
}: Props) => {
  const classes = useStyles({
    width,
    minHeight,
    height,
    align,
    onlyContent: hideCloseButton && !(title && title?.length > 0),
    actionStyles
  });
  const { barMainButton } = useSubtopBarStyles();

  return (
    <Dialog
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={open}
      classes={{ paper: classes.paper }}
      PaperProps={{ style: paperStyle }}
      keepMounted={keepMounted}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      className={classes.root}>
      {!hideCloseButton && (
        <IconButton
          aria-label="close"
          onClick={onClose}
          color="primary"
          style={{
            position: "absolute",
            right: 8,
            top: 8
          }}>
          <Close />
        </IconButton>
      )}

      <>
        {title && (
          <ModalTitle title={title} titleIcon={titleIcon} noMargin={Boolean(titleIcon)}>
            {subtitle && (
              <Typography className={classes.subtitle}>
                {subtitle}
                {!!titleDescription && (
                  <Typography
                    variant="caption"
                    component="div"
                    className={classes.titleDescription}>
                    {titleDescription}
                  </Typography>
                )}
              </Typography>
            )}
          </ModalTitle>
        )}

        <DialogContent className={classes.content}>{children}</DialogContent>
        {!withForm &&
          (isNewTheme ? (
            <DialogActions className={classes.actions}>
              <NewThemeWrapper>
                {!hideCancel && (
                  <Button
                    data-testid={cancelDataTestId}
                    variant="outlined"
                    color="primary"
                    onClick={onCancel}
                    disabled={isSubmitLoading}>
                    {cancelButtonLabel}
                  </Button>
                )}
                <Tooltip title={tooltipTitle ?? ""} placement="top">
                  <span>
                    <Button
                      data-testid={submitDataTestId}
                      id={submitButtonId}
                      variant="contained"
                      className={clsx(
                        classes.button,
                        submitButtonClassName
                          ? {
                              [submitButtonClassName]: !!submitButtonClassName
                            }
                          : {}
                      )}
                      startIcon={
                        isSubmitLoading ? (
                          <CircularProgress size={16} style={{ color: "#fff" }} />
                        ) : undefined
                      }
                      color="primary"
                      onClick={onSubmit}
                      disabled={submitDisabled || isSubmitLoading}>
                      {submitButtonLabel}
                    </Button>
                  </span>
                </Tooltip>
              </NewThemeWrapper>
            </DialogActions>
          ) : (
            <DialogActions className={classes.actions}>
              {!hideCancel && (
                <Button
                  data-testid={cancelDataTestId}
                  className={barMainButton}
                  disabled={isSubmitLoading}
                  onClick={onCancel}>
                  {cancelButtonLabel}
                </Button>
              )}
              <Tooltip title={tooltipTitle ?? ""} placement="top">
                <span>
                  <Button
                    data-testid={submitDataTestId}
                    id={submitButtonId}
                    variant="contained"
                    color="primary"
                    onClick={onSubmit}
                    startIcon={
                      isSubmitLoading ? (
                        <CircularProgress size={16} style={{ color: "#fff" }} />
                      ) : undefined
                    }
                    disabled={submitDisabled || isSubmitLoading}
                    className={clsx(
                      barMainButton,
                      submitButtonClassName
                        ? {
                            [submitButtonClassName]: !!submitButtonClassName
                          }
                        : {}
                    )}>
                    {submitButtonLabel}
                  </Button>
                </span>
              </Tooltip>
            </DialogActions>
          ))}
      </>
    </Dialog>
  );
};

Modal.defaultProps = {
  subtitle: "",
  maxWidth: "md",
  setTabValue: null
};

export default Modal;
