import React, { useEffect } from "react";

// Packages
import { assign, get, includes, isEmpty, set } from "lodash";

// Icons
import CafexLogo from "src/assets/images/cafex-logo.svg";

// Stores
import { useToastsStore } from "stores/zustand/stores";
import { toastContentSetter } from "stores/zustand/stores.selectors";
import useAuthStore from "./stores/zustand/auth.store";

// Styles
import "react-toastify/dist/ReactToastify.css";

import AppProviders from "src/AppProviders";
import AppRoutes from "src/AppRoutes";

// Components
import { CafexConfig } from "./utils/constants";

const App: React.FC = () => {
  // Stores - STARTS >>
  const setToastContentStore = useToastsStore(toastContentSetter);
  const [tenantIdStore] = useAuthStore((state) => [state.tenantId]);
  // << ENDS - Stores

  // @TODO: The below logic is yet to be reconsidered.
  useEffect(() => {
    document.getElementById("splash-screen")?.remove();

    return () => {
      setToastContentStore(null);
    };
  }, []);

  // @HACK: Applied a temporary workaround to display the favicon specifically for the Cafex client. This code should be removed once its no longer needed.
  const updateFavicon = () => {
    const linkElement = document.querySelector("link[rel='icon']") as HTMLLinkElement;
    const currentHref = get(linkElement, "href", "");

    if (includes(currentHref, "rc-icon.svg")) {
      set(linkElement, "href", CafexLogo);
      document.title = CafexConfig.FaviconName;
    } else {
      if (isEmpty(linkElement)) {
        const newLink = document.createElement("link");
        assign(newLink, { rel: "icon", href: CafexLogo });
        document.head.appendChild(newLink);
        document.title = CafexConfig.FaviconName;
      }
    }
  };

  useEffect(() => {
    if (includes([CafexConfig.TenantId, CafexConfig.TestTenantId], tenantIdStore)) {
      updateFavicon();
    }
  }, [tenantIdStore]);

  return (
    <AppProviders>
      <AppRoutes />
    </AppProviders>
  );
};

export default App;
