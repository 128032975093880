import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  Tooltip,
  makeStyles
} from "@material-ui/core";
import { ArrowDropDown, Edit } from "@material-ui/icons";
import React, { useCallback, useMemo, useState } from "react";
import { CodeCheckpointDto } from "@rapidcanvas/rc-api-core";

import Modal, { ModalVariants } from "src/components/custom/Modal/Modal";

import { DeleteNew } from "src/assets/icons/DeleteNew";
import { SaveCheckpointIcon } from "icons/NewUX/SaveCheckpointIcon";

import { useDeleteCodeCheckpoint } from "src/hooks/api/codeCheckpoints/useDeleteCodeCheckpoint";
import { handleResponse } from "services/Apis/Apis.service";
import { EditCheckpointNameModal } from "./EditCheckpointNameModal";
import { find, get, orderBy } from "lodash";
import { OverflowTooltip } from "src/components";
import Text from "src/components/Widget/Text";

const useStyles = makeStyles({
  menuPaper: {
    border: "1px solid #C7C7C7",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "8px",
    width: "320px"
  },
  menuList: {
    padding: "8px"
  },
  menuItemTitle: {
    color: "#4646B5",
    fontSize: "14px",
    lineHeight: "28px"
  },
  menuItemDesc: {
    color: "#000",
    opacity: 0.5,
    fontSize: "12px",
    fontStyle: "italic",
    whiteSpace: "normal"
  },
  menuItem: {
    display: "flex",
    flexDirection: "column",
    gap: "6px",
    alignItems: "flex-start",
    "&:hover": {
      background: "#F2F9FF",
      borderRadius: "4px"
    }
  },
  btn: {
    borderStyle: "solid",
    borderColor: " #4646B5",
    borderWidth: ({ showBorder }: { showBorder?: boolean }) =>
      showBorder ? "1px !important" : "1px 0px 1px 1px !important",

    color: "#4646B5 !important",
    borderRadius: ({ showBorder }: { showBorder?: boolean }) =>
      showBorder ? "4px" : "4px 0px 0px 4px",
    height: "100%",
    "&:disabled": {
      opacity: 0.5
    },
    background: "#fff"
  },
  iconBtn: {
    background: "#fff !important",
    border: "1px solid #4646B5",
    borderRadius: "0px 4px 4px 0px",
    "&:disabled": {
      opacity: 0.5
    }
  },
  arrowIcon: {
    color: "#4646B5"
  },
  loader: {
    marginLeft: "8px"
  }
});

const CHECKPOINTS_SAVE_LIMIT = 5;
export const CodeCheckpointsButton = ({
  onSave,
  isSaving,
  isDisabled,
  data,
  tooltip,
  recipeId,
  onCheckpointSelect,
  onDeleteSuccess
}: {
  onSave: () => void;
  isSaving: boolean;
  isDisabled: boolean;
  data: CodeCheckpointDto[];
  recipeId: string;
  tooltip: string;
  onCheckpointSelect: React.Dispatch<React.SetStateAction<string>>;
  onDeleteSuccess: (checkpointId: string) => void;
}) => {
  const hideMenu = data.length === 0;
  const classes = useStyles({ showBorder: hideMenu });

  const [anchorEl, setAnchorEl] = useState(null);
  const [showSaveLimitReachedModal, setShowSaveLimitReachedModal] = useState<boolean>(false);
  const [isConfirmDeleteCheckpointModalVisible, setIsConfirmDeleteCheckpointModalVisible] =
    useState<boolean>(false);
  const [checkpointToDelete, setCheckpointToDelete] = useState<null | string>(null);
  const [checkpointToEdit, setCheckpointToEdit] = useState<null | string>(null);

  const deleteCodeCheckpoint = useDeleteCodeCheckpoint();

  const handleClick = useCallback((e: $TSFixMe) => {
    setAnchorEl(e.currentTarget);
  }, []);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const checkpoints = useMemo(() => orderBy(data, "updated", "desc"), [data]);
  const options = checkpoints.map((option) => ({
    label: option.name!,
    value: option.id!,
    testId: option.id!
  }));

  const isCheckpointsLimitReached = checkpoints.length >= CHECKPOINTS_SAVE_LIMIT;

  const handleSave = () => {
    if (isCheckpointsLimitReached) {
      setShowSaveLimitReachedModal(true);
      return;
    }
    onSave();
  };

  const handleDeleteCheckpoint = () => {
    const checkpointIdToDelete = get(find(checkpoints, { name: checkpointToDelete }), "id");
    checkpointIdToDelete &&
      deleteCodeCheckpoint.mutate(
        { id: checkpointIdToDelete, recipeId },
        {
          onSuccess: () => {
            handleResponse({
              successMessage: `Checkpoint ${checkpointToDelete} deleted successfully`
            });
            onDeleteSuccess(checkpointIdToDelete);
          },
          onSettled: () => {
            setCheckpointToDelete("");
            setAnchorEl(null);
            setIsConfirmDeleteCheckpointModalVisible(false);
          }
        }
      );
  };

  const handleCloseModal = () => {
    setShowSaveLimitReachedModal(false);
  };
  return (
    <Grid container direction="row" wrap="nowrap">
      {isConfirmDeleteCheckpointModalVisible && (
        <Modal
          open
          variant={ModalVariants.Delete}
          title="Delete Checkpoint"
          content={[`Are you sure you want to delete “${checkpointToDelete}”`]}
          submitLabel="Yes"
          cancelLabel="No"
          onClose={() => {
            setIsConfirmDeleteCheckpointModalVisible(false);
            setCheckpointToDelete(null);
          }}
          hideCloseIcon
          onSubmit={handleDeleteCheckpoint}
          isSubmitting={deleteCodeCheckpoint.isLoading}
        />
      )}
      {!!checkpointToEdit && (
        <EditCheckpointNameModal
          open={!!checkpointToEdit}
          data={checkpoints}
          onClose={() => {
            setCheckpointToEdit(null);
            setAnchorEl(null);
          }}
          value={checkpointToEdit!}
          recipeId={recipeId}
        />
      )}
      {showSaveLimitReachedModal && (
        <Modal
          open
          variant={ModalVariants.Delete}
          title="Alert"
          content={[`A maximum of 5 checkpoints can be saved.`]}
          cancelLabel="Close"
          hideSubmitAction
          onClose={handleCloseModal}
          onSubmit={handleCloseModal}
          hideCloseIcon
        />
      )}
      <Tooltip title={isSaving ? "Checkpoints save is in progress" : tooltip || ""}>
        <div>
          <Button
            className={classes.btn}
            size="small"
            variant="outlined"
            disabled={isDisabled}
            data-testid="save-checkpoints-btn"
            startIcon={<SaveCheckpointIcon />}
            onClick={handleSave}>
            Save Code Checkpoint
            {isSaving && <CircularProgress size={16} className={classes.loader} />}
          </Button>
        </div>
      </Tooltip>
      {!hideMenu && (
        <>
          <Tooltip title={isSaving ? "Checkpoints save is in progress" : ""}>
            <div>
              <IconButton
                onClick={handleClick}
                className={classes.iconBtn}
                disabled={isSaving}
                data-testid="code-checkpoints-dropdown-btn"
                size="small">
                <ArrowDropDown className={classes.arrowIcon} />
              </IconButton>
            </div>
          </Tooltip>
          <Menu
            classes={{
              paper: classes.menuPaper,
              list: classes.menuList
            }}
            transitionDuration={{ exit: 0 }}
            onClose={handleClose}
            keepMounted
            getContentAnchorEl={null}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left"
            }}>
            <MenuList disablePadding>
              {options.map((option, index) => (
                <MenuItem
                  key={index}
                  disabled={isSaving}
                  className={classes.menuItem}
                  test-id="code-checkpoints-menu-item"
                  onClick={() => {
                    onCheckpointSelect?.(option.value!);
                    handleClose();
                  }}>
                  <Grid container direction="row" wrap="nowrap" justifyContent="space-between">
                    <OverflowTooltip
                      title={option.label}
                      style={{ flexGrow: 1, maxWidth: "65%", whiteSpace: "nowrap" }}
                      value={<Text value={option.label} style={{ whiteSpace: "nowrap" }} />}
                    />

                    <Grid container item direction="row" wrap="nowrap" style={{ width: "auto" }}>
                      <IconButton
                        style={{ padding: "4px" }}
                        onClick={(event) => {
                          event.stopPropagation();
                          setCheckpointToEdit(option.label);
                        }}
                        data-testid="code-checkpoints-edit-btn">
                        <Edit fontSize="small" />
                      </IconButton>
                      <IconButton
                        style={{ padding: "4px" }}
                        onClick={(event) => {
                          event.stopPropagation();
                          setCheckpointToDelete(option.label);
                          setIsConfirmDeleteCheckpointModalVisible(true);
                        }}
                        data-testid="code-checkpoints-delete-btn">
                        <DeleteNew size="tiny" />
                      </IconButton>
                    </Grid>
                  </Grid>
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </>
      )}
    </Grid>
  );
};
