// Retaining commented redux logic as a reference for implementing future features.
// import { useSelector } from "react-redux";

// Hooks
import { useGetEnvironments, usePollEnvironments } from "src/hooks/api";

// Stores
// import { RootState } from "src/stores/store";

type Props = {
  poll?: boolean;
};

const useEnvironments = (props?: Props) => {
  const { poll = true } = props || {};

  const useQueryResults = useGetEnvironments({ refetchOnMount: true });

  // const data = useSelector((state: RootState) => state.environments.environments);

  usePollEnvironments({ poll, environments: useQueryResults?.data });

  return useQueryResults;
};

export default useEnvironments;
