import { get, has } from "lodash";

import makeStyles from "@material-ui/core/styles/makeStyles";

import { NodeDestinationTheme } from "src/pages/private/ProjectsModule/utils";

type StyleProps = {
  status: string;
};

const useStyles = makeStyles({
  root: {
    width: 40,
    height: 40,
    borderRadius: 40 / 3,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: ({ status }: StyleProps) =>
        has(NodeDestinationTheme, status)
          ? get(NodeDestinationTheme, [status, "onHover", "backgroundColor"])
          : NodeDestinationTheme.onHover.backgroundColor,
      border: "1px solid",
      borderColor: ({ status }: StyleProps) =>
        has(NodeDestinationTheme, status)
          ? get(NodeDestinationTheme, [status, "onHover", "borderColor"])
          : NodeDestinationTheme.onHover.borderColor,
      color: ({ status }: StyleProps) =>
        has(NodeDestinationTheme, status)
          ? get(NodeDestinationTheme, [status, "onHover", "iconColor"])
          : NodeDestinationTheme.onHover.iconColor
    },
    "&.highlighted": {
      boxShadow: `0px 0px 10px 5px ${NodeDestinationTheme.highlightColor}`
    },
    "&.active": {
      boxShadow: `0px 0px 5px 5px  ${NodeDestinationTheme.activeColor}`
    }
  },
  defaultStyles: {
    backgroundColor: ({ status }: StyleProps) =>
      has(NodeDestinationTheme, status)
        ? get(NodeDestinationTheme, [status, "backgroundColor"])
        : NodeDestinationTheme.backgroundColor,
    border: "1px solid",
    borderColor: ({ status }: StyleProps) =>
      has(NodeDestinationTheme, status)
        ? get(NodeDestinationTheme, [status, "borderColor"])
        : NodeDestinationTheme.borderColor,
    color: ({ status }: StyleProps) =>
      has(NodeDestinationTheme, status)
        ? get(NodeDestinationTheme, [status, "iconColor"])
        : NodeDestinationTheme.iconColor
  },
  connectorNodeStyles: {
    backgroundColor: "#fff", // White color
    border: "1px solid",
    borderColor: ({ status }: StyleProps) =>
      has(NodeDestinationTheme, status)
        ? get(NodeDestinationTheme, [status, "borderColor"])
        : NodeDestinationTheme.borderColor
  },
  container: {
    height: "100%",
    position: "relative",
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "center",
    alignItems: "center"
  },
  status: {
    top: -20,
    position: "absolute",
    color: ({ status }: StyleProps) =>
      has(NodeDestinationTheme, status)
        ? get(NodeDestinationTheme, [status, "statusColor"])
        : NodeDestinationTheme.statusColor,
    "&:hover": {
      color: ({ status }: StyleProps) =>
        has(NodeDestinationTheme, status)
          ? get(NodeDestinationTheme, [status, "onHover", "statusColor"])
          : NodeDestinationTheme.onHover.statusColor
    },
    "&.active": {
      fontWeight: "bold"
    }
  },
  icon: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& img": {
      width: "90%",
      height: "90%"
    }
  },
  label: {
    top: 2,
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 5px",
    borderRadius: 5,
    color: "#000",
    whiteSpace: "nowrap",
    "&.active": {
      fontWeight: "bold",
      backgroundColor: NodeDestinationTheme.activeColor
    }
  }
});

export default useStyles;
