import { UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import { DataAppType } from "pages/DataApps/DataApps.type";
import { DATAAPP_INTERVAL_IN_SEC } from "./useDataAppStatusById";
import {
  getAPIWithRethrow,
  getErrorMessage,
  handleErrorWithRedirectToLogs,
  handleResponse,
  postAPIWithRethrow
} from "services/Apis/Apis.service";
import { invalidateDataAppQueries } from "./useLaunchDataAppMutation";
import { DataAppDtoLaunchStatusEnum } from "@rapidcanvas/rc-api-core";
import { useRef } from "react";

type Props = { id: string };

export const useStopDataAppMutation = (): UseMutationResult<
  DataAppDtoLaunchStatusEnum,
  unknown,
  Props,
  unknown
> => {
  const queryClient = useQueryClient();
  const { dataAppName } = useParams();
  const fetchingRef = useRef<boolean>(false);

  return useMutation({
    mutationFn: async ({ id }) => {
      const response: DataAppType = await postAPIWithRethrow(`/dataapps/${id}/stop`, {});
      const launchStatus = response?.launchStatus;

      if (launchStatus !== DataAppDtoLaunchStatusEnum.Stopped) {
        return await new Promise((resolve, reject) => {
          const interval = setInterval(async () => {
            if (!fetchingRef.current) {
              fetchingRef.current = true;
              try {
                const newResponse: DataAppType = await getAPIWithRethrow(
                  `/dataapps/by-id/${id}`,
                  undefined,
                  false
                );
                fetchingRef.current = false;
                const newStatus = newResponse.launchStatus;
                if (newStatus === DataAppDtoLaunchStatusEnum.Stopped) {
                  clearInterval(interval);
                  resolve(newStatus);
                }
              } catch (e: any) {
                if (e?.response?.status !== 404) {
                  handleErrorWithRedirectToLogs(getErrorMessage(e));
                }
                clearInterval(interval);
                reject(e);
              }
            }
          }, DATAAPP_INTERVAL_IN_SEC * 1000);
        });
      } else {
        return launchStatus;
      }
    },
    onSuccess: (__, { id }) => {
      invalidateDataAppQueries(queryClient, id, DataAppDtoLaunchStatusEnum.Stopped, dataAppName);
      handleResponse({ successMessage: `DataApp stopped successfully.` });
    }
  });
};
