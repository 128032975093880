import React from "react";
import { get, includes, isEmpty, map, pick, without } from "lodash";
import {
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  FormControl,
  makeStyles,
  SelectProps
} from "@material-ui/core";

import OverflowTooltip from "src/components/OverflowTooltip";
import { allKey, SEARCH_COMPONENTS } from "../../../utils/GlobalSearch.constants";
import { useGetRole } from "hooks/useGetRole";
import { SearchRequestDtoSearchComponentsEnum } from "@rapidcanvas/rc-api-core";

const useStyles = makeStyles(() => ({
  select: {
    borderTopLeftRadius: "20px",
    borderBottomLeftRadius: "20px",
    flex: 1,
    padding: "5px 10px",
    background: "#fff",
    borderRight: "1px solid #ccc",

    "& .MuiOutlinedInput-notchedOutline": {
      border: "none"
    },

    "&.Mui-focused": {
      backgroundColor: "transparent"
    }
  },

  root: {
    display: "flex",
    justifyContent: "center",

    "&:focus": {
      backgroundColor: "transparent"
    }
  },
  menuItem: {
    padding: "4px",

    "&.Mui-selected": {
      backgroundColor: "transparent !important"
    },
    "&.Mui-selected:hover": {
      backgroundColor: "transparent !important"
    }
  },
  menuList: {
    padding: "0px",
    minWidth: "300px"
  },
  items: {
    display: "flex",
    gap: "12px",
    alignItems: "center"
  },
  paper: {
    borderRadius: "10px",
    border: "1px solid #6B6B6B"
  },
  icon: {
    color: "#7c7c7c",
    width: "16px"
  }
}));

interface IProps {
  values: string[];
  onChange: (values: string[]) => void;
}

const GlobalSearchSelect: React.FC<IProps> = (props) => {
  const { values, onChange } = props;

  const classes = useStyles();
  const { isDataAppPowerUser, isConsumer } = useGetRole();

  const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedValues = event.target.value as string[];

    const allKeys = Object.keys(SEARCH_COMPONENTS);
    const optionKeys = allKeys.filter((key) => key !== allKey);

    if (selectedValues.length === allKeys.length) {
      onChange(allKeys);
    } else {
      if (values.includes(allKey)) {
        if (selectedValues.includes(allKey)) {
          if (selectedValues.length === allKeys.length) {
            onChange(selectedValues);
          } else {
            onChange(without(selectedValues, allKey));
          }
        } else {
          onChange([]);
        }
      } else {
        if (selectedValues.includes(allKey)) {
          onChange(allKeys);
        } else {
          if (selectedValues.length === optionKeys.length) {
            onChange(allKeys);
          } else {
            onChange(selectedValues);
          }
        }
      }
    }
  };

  const renderValue = (selected: SelectProps["value"]) => {
    if (isEmpty(selected)) {
      return "Select";
    }

    if (includes(selected as string[], allKey)) {
      return allKey;
    }
    const values = map(selected as string[], (item) => get(SEARCH_COMPONENTS, [item, "label"]));

    return <OverflowTooltip style={{ whiteSpace: "nowrap" }} value={values.join(", ")} />;
  };

  return (
    <FormControl className={classes.select}>
      <Select
        multiple
        data-testid="globalSearchSelect"
        classes={{ root: classes.root }}
        value={values}
        MenuProps={{
          getContentAnchorEl: null,

          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "center"
          },
          classes: { list: classes.menuList, paper: classes.paper }
        }}
        onChange={handleSelectChange}
        displayEmpty
        disableUnderline
        renderValue={renderValue}>
        {map(
          isDataAppPowerUser || isConsumer
            ? pick(SEARCH_COMPONENTS, SearchRequestDtoSearchComponentsEnum.Dataapp)
            : SEARCH_COMPONENTS,
          (option, key) => (
            <MenuItem
              key={option.label}
              value={key}
              data-testid={option.label}
              classes={{ root: classes.menuItem }}>
              <Checkbox color="primary" style={{ padding: "6px" }} checked={values.includes(key)} />
              <ListItemText
                primary={
                  <div className={classes.items}>
                    <span className={classes.icon}>{option.icon}</span>
                    <span>{option.label}</span>
                    {get(option, "extra")}
                  </div>
                }
              />
            </MenuItem>
          )
        )}
      </Select>
    </FormControl>
  );
};

export default GlobalSearchSelect;
