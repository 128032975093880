import React, { useMemo, useState } from "react";

import { useParams } from "react-router-dom";
import { includes, isEmpty, isNil } from "lodash";
import { Box, Divider, Grid, Paper, Typography, makeStyles, useTheme } from "@material-ui/core";

import AnalyzingFileAnimation from "../../AnalyzingFileAnimation/AnalyzingFileAnimation";
import PreviewIconNew from "icons/NewUX/Dataset/PreviewIconNew";

import useStoreSelectors from "../../../hooks/useStoreSelectors";

import {
  DatasetKeys,
  OntologyDatasetStatuses,
  DatasetHelperText
} from "../../../utils/Dataset.constants";

import OntologyHeader from "./OntologyHeader";
import OntologySampleData from "./OntologySampleData";
import OntologyLoaderWrapper from "../../OntologyLoaderWrapper";
import { Data } from "src/pages/private/ProjectsModule/components";

import { useUploadContext } from "../../../contexts/Upload/useUploadContext";
import CommonLoader from "src/components/CommonLoader";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    flex: 1,
    overflow: "auto",
    borderRadius: theme.spacing(1.5),
    padding: theme.spacing(2)
  },
  cell: {
    display: "inline-block",
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    verticalAlign: "middle"
  },
  marginLeft: {
    marginLeft: theme.spacing(1)
  }
}));

const OntologyContainer = (props: $TSFixMe) => {
  const {
    isAddFile,
    isDataSourcesFilesUpload,
    ontologyDatasetIndex,
    dataset,
    datasetId,
    isConfigContainerExpanded,
    ontologySampleData,
    isProcessingFiles,
    isFetchingOntologySchema,
    isFetchingOntologySampleData,
    ontologySchemaOptionsData,
    ontologySchemaData,
    getDatasetCustomColumnsData,
    visibleColumns,
    totalRowCount
  } = props || {};

  const { projectId, scenarioId } = useParams();

  const theme = useTheme();
  const classes: $TSFixMe = useStyles();

  // Stores - STARTS >>
  const {
    datasetFilesStore,
    datasetDefaultDatasetStore,
    datasetIsFetchingOntologyDataStore,
    datasetWatchOntologySchemaSetStore
  } = useStoreSelectors();
  // << ENDS - Stores

  // States - STARTS >>
  const [searchTerm, setSearchTerm] = useState<$TSFixMe>("");
  // << ENDS - States

  const { updateDatasetLite } = useUploadContext();

  const loader = useMemo(() => {
    // Note: Do not change the order of return, as it follows the file-types flow.
    if (
      includes(
        [OntologyDatasetStatuses.Failed],
        dataset?.[DatasetKeys.OntologyConfig][DatasetKeys.Status]
      )
    ) {
      return;
    }

    if (!!isProcessingFiles) {
      return (
        <OntologyLoaderWrapper>
          <Typography color="primary">
            <AnalyzingFileAnimation />
          </Typography>
          <Typography variant="body2" color="primary">
            {isDataSourcesFilesUpload
              ? "Analyzing the data..."
              : "Analyzing & Configuring your file..."}
          </Typography>
        </OntologyLoaderWrapper>
      );
    }

    if (!isAddFile) {
      if (!!isFetchingOntologySampleData) {
        return (
          <OntologyLoaderWrapper>
            <Typography color="primary">
              <PreviewIconNew />
            </Typography>
            <Typography variant="body2" color="primary">
              Fetching sample data...
            </Typography>
          </OntologyLoaderWrapper>
        );
      }

      if (!datasetDefaultDatasetStore?.id) {
        if (!!isFetchingOntologySchema) {
          return (
            <OntologyLoaderWrapper>
              <Typography color="primary">
                <PreviewIconNew />
              </Typography>
              <Typography variant="body2" color="primary">
                Fetching schema...
              </Typography>
            </OntologyLoaderWrapper>
          );
        }
      }
    }

    if (!!datasetIsFetchingOntologyDataStore) {
      return <CommonLoader />;
    }

    if (!!isAddFile) {
      if (isNil(isProcessingFiles)) {
        return <CommonLoader />;
      }
    }

    return null;
  }, [
    isDataSourcesFilesUpload,
    dataset,
    ontologyDatasetIndex,
    datasetIsFetchingOntologyDataStore,
    isProcessingFiles,
    isFetchingOntologySchema,
    isFetchingOntologySampleData,
    isAddFile
  ]);

  const noDataFoundMessage = (message: string) => (
    <OntologyLoaderWrapper>
      <Typography variant="body2" color="primary">
        {message}
      </Typography>
    </OntologyLoaderWrapper>
  );

  return (
    <Paper className={classes.root}>
      {!!isAddFile ? (
        <Typography variant="subtitle2" color="textPrimary">
          {DatasetHelperText.Preview}
        </Typography>
      ) : (
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          style={{ marginBottom: theme.spacing(2) }}>
          <Grid item>
            <Typography variant="subtitle2" color="textPrimary">
              {DatasetHelperText.SampleData}
            </Typography>
          </Grid>

          <Grid item>
            <OntologyHeader
              datasetDefaultDatasetStore={datasetDefaultDatasetStore}
              ontologyDatasetIndex={ontologyDatasetIndex}
              dataset={dataset}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              updateDatasetLite={updateDatasetLite}
            />
          </Grid>
        </Grid>
      )}

      <Box mx={-2} my={2}>
        <Divider />
      </Box>

      {loader ||
        (!!isAddFile ? (
          <Box style={{ height: "calc(100vh - 370px)", overflow: "auto" }}>
            <Data
              projectId={projectId}
              scenarioId={scenarioId}
              fileId={datasetId}
              editorProps={{
                height: `${datasetFilesStore[ontologyDatasetIndex]?.type === "text/html" ? "calc(100vh - 380px)" : "calc(100vh - 370px)"}`
              }}
            />
          </Box>
        ) : isEmpty(ontologySampleData?.columns) || isEmpty(ontologySampleData?.rows) ? (
          noDataFoundMessage(DatasetHelperText.NoSampleDataFound)
        ) : (
          <OntologySampleData
            totalRowCount={totalRowCount}
            datasetDefaultDatasetStore={datasetDefaultDatasetStore}
            isConfigContainerExpanded={isConfigContainerExpanded}
            ontologyDatasetIndex={ontologyDatasetIndex}
            ontologySampleData={ontologySampleData}
            searchTerm={searchTerm}
            ontologySchemaOptionsData={ontologySchemaOptionsData}
            ontologySchemaData={ontologySchemaData}
            datasetWatchOntologySchemaSetStore={datasetWatchOntologySchemaSetStore}
            getDatasetCustomColumnsData={getDatasetCustomColumnsData}
            visibleColumns={visibleColumns}
          />
        ))}
    </Paper>
  );
};

export default OntologyContainer;
