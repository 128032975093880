import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  IconButton,
  Button,
  Chip,
  Typography,
  Menu,
  MenuItem,
  Tooltip
} from "@material-ui/core";
import SearchField from "../Inputs/SearchField";
import { BarButton } from "./BarButton";
import { CardView } from "../../assets/icons/CardView";
import { ArrowLeft } from "../../assets/icons/ArrowLeft";
import { List } from "../../assets/icons/List";
import SUBTOPBAR_ICONS from "../../assets/icons/SubtopBarIcons";

import { capitalize } from "../../utils/helpers/string.helpers";

const {
  CHARTS,
  GROUP,
  DATASET,
  RECIPE,
  ARTIFACT,
  GENERIC,
  S3_STORAGE,
  AZURE_BLOB,
  GCP_STORAGE,
  MONGO,
  MYSQL,
  REDSHIFT,
  // REDIS_STORAGE,
  SNOWFLAKE,
  MODEL
} = SUBTOPBAR_ICONS;

import EditName from "./EditName";
import InfoOutlined from "@material-ui/icons/InfoOutlined";
import Text from "../Widget/Text";
import clsx from "clsx";
import { CheckmarkSquare } from "src/assets/icons/CheckmarkSquare";
import { ChevronLeft, ChevronRight, MoreVert } from "@material-ui/icons";
import { useStyles } from "./styling";

export type ButtonType =
  | {
      disabled?: boolean;
      key?: string;
      label?: string;
      action?: $TSFixMeFunction;
      icon?: $TSFixMe;
      tooltip?: string;
      id?: string;
      loading?: boolean;
      variant?: "contained" | "text" | "outlined";
      "test-id"?: string;
      loadingComponent?: React.ReactNode;
    }
  | {
      component: React.ReactNode;
    };
type Props = {
  children?: $TSFixMe; // TODO: PropTypes.oneOf([PropTypes.node, PropTypes.string, PropTypes.number])
  customLeftSideContent?: $TSFixMe; // TODO: PropTypes.oneOf([PropTypes.node, PropTypes.string, PropTypes.number])
  customRightSideContent?: $TSFixMe;
  backButton?: {
    label?: string;
    path?: string;
    onBackBtnClick?: $TSFixMeFunction;
  };
  searchPlaceholder?: string;
  onSearch?: $TSFixMeFunction;
  chips?: $TSFixMe;
  onSave?: $TSFixMeFunction;
  onCancel?: $TSFixMeFunction;
  buttons?: ButtonType[];
  onToggleView?: $TSFixMeFunction;
  viewList?: boolean;
  moreOptions?: {
    testId?: string;
    id?: string;
    disabled?: boolean;
    label?: string;
    action?: $TSFixMeFunction;
    icon?: $TSFixMe;
    tooltip?: string;
    loading?: boolean;
    nestedComponent?: React.ReactNode;
  }[];
  section?: string;
  isSectionIcon?: boolean;
  sectionGap?: number;
  isSaveDisabled?: boolean;
  isEditHidden?: boolean;
  shouldReset?: $TSFixMe;
  infoTooltip?: string;
  isEditDisable?: boolean;
  inputValue?: string;
  subTextValue?: string;
  onUpdateName?: (name: string) => void;
};

export const SubtopBar = ({
  children,
  customLeftSideContent,
  customRightSideContent,
  backButton,
  searchPlaceholder,
  onSearch,
  chips,
  onSave,
  onCancel,
  buttons,
  isEditHidden = false,
  isEditDisable,
  onToggleView,
  inputValue,
  onUpdateName,
  subTextValue,
  section,
  isSectionIcon = true,
  sectionGap = 16,
  viewList,
  moreOptions,
  isSaveDisabled,
  shouldReset = false,
  infoTooltip
}: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [onLoadingNameChange, setOnLoadingNameChange] = useState(false);
  const [inputEditName, setInputEditName] = useState(inputValue);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isNestedOpen, setIsNestedOpen] = useState(false);

  const [nested, setNested] = useState<$TSFixMe>(null);

  useEffect(() => {
    inputValue && setInputEditName(inputValue);
  }, [inputValue]);

  useEffect(() => {
    shouldReset && setInputEditName(inputValue);
  }, [inputValue, shouldReset]);

  const handleBack = () => {
    if (backButton?.onBackBtnClick) {
      backButton.onBackBtnClick();
    } else if (backButton?.path) {
      navigate(backButton.path);
    }
  };

  const handleSubmitNameChange = async () => {
    if (inputEditName?.trim() === inputValue) {
      return;
    }
    setOnLoadingNameChange(true);
    if (inputEditName) {
      await onUpdateName?.(inputEditName);
    }
    setOnLoadingNameChange(false);
  };

  const handleChangeName = (value: $TSFixMe) => {
    setInputEditName(value);
  };

  const iconSectionSelection = useMemo(() => {
    const icons = {
      charts: CHARTS,
      dataset: DATASET,
      group: GROUP,
      recipe: RECIPE,
      artifact: ARTIFACT,
      model: MODEL,
      S3_STORAGE,
      AZURE_BLOB,
      GCP_STORAGE,
      MONGO,
      MYSQL,
      // Deferring the below types for time-being.
      // REDIS_STORAGE,
      REDSHIFT,
      SNOWFLAKE
    };
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return icons[section] ? icons[section] : GENERIC;
  }, [section]);

  const gridListView = (visual: $TSFixMe) =>
    clsx({
      [classes.viewButtonsIcons]: true,
      [classes.opacityIcon]: visual
    });

  const ViewButtons = () => {
    return (
      <div className={classes.viewButtonsContainer}>
        <IconButton
          aria-label="View cards"
          test-id="viewCardMode"
          className={gridListView(!viewList)}
          // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
          onClick={() => onToggleView(true)}>
          <CardView />
        </IconButton>
        <IconButton
          aria-label="View list"
          test-id="viewListMode"
          className={gridListView(viewList)}
          // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
          onClick={() => onToggleView(false)}>
          <List />
        </IconButton>
      </div>
    );
  };

  const getChipStyle = (status: string) => {
    // @FIXME: The color codes below should be constants & centralized.
    let style: $TSFixMe = {
      marginLeft: 10
    };

    switch (status.toLowerCase()) {
      case "success":
        style = {
          ...style,
          backgroundColor: "rgba(111, 207, 151, 0.05)",
          border: "1px solid #6FCF97"
        };
        break;
    }

    return style;
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleClick = useCallback((e: $TSFixMe) => {
    setAnchorEl(e.currentTarget);
  }, []);

  return (
    <Grid item xs={12} className={classes.subtopBarContainer}>
      {/* Children prop replaces all subtop bar content  */}
      {children ? (
        <div className={classes.subtopBar}>{children}</div>
      ) : (
        <div className={classes.subtopBar}>
          {/* Left Side content */}
          {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
          <Box display="flex" alignItems="center" width="100%" sx={{ gap: sectionGap }}>
            {(backButton || inputValue) && (
              <Box display="flex" width="100%">
                {backButton && (
                  <Button
                    onClick={handleBack}
                    className={classes.backButton}
                    color="primary"
                    test-id="sub-top-bar-back-btn">
                    <ArrowLeft />
                  </Button>
                )}

                {inputValue && (
                  <Box
                    className={classes.inputValueContainer}
                    display="flex"
                    alignItems="center"
                    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
                    sx={{ gap: !isEditHidden && !isEditDisable ? 4 : 12 }}>
                    {isSectionIcon && iconSectionSelection}
                    {(isEditHidden || isEditDisable) && (
                      <Typography variant="body2" test-id="sub-top-bar-input-value">
                        <Text value={inputValue} />
                        {infoTooltip && (
                          <Tooltip title={infoTooltip}>
                            <InfoOutlined className={classes.infoTooltipStyles} />
                          </Tooltip>
                        )}
                      </Typography>
                    )}
                    {subTextValue ? (
                      <Typography
                        variant="body2"
                        test-id="sub-top-bar-sub-text-value">{`(${subTextValue})`}</Typography>
                    ) : (
                      !isEditHidden &&
                      !isEditDisable && (
                        <EditName
                          inputValue={inputEditName}
                          isLoading={onLoadingNameChange}
                          onSave={handleSubmitNameChange}
                          onChange={handleChangeName}
                          // @ts-expect-error TS(2322) FIXME: Type '{ label: string; inputValue: any; isLoading:... Remove this comment to see the full error message
                          shouldHavePadding
                          test-id="sub-top-bar-edit-name"
                          infoTooltip={infoTooltip}
                        />
                      )
                    )}
                  </Box>
                )}
              </Box>
            )}
            {searchPlaceholder && (
              <SearchField
                placeholder={searchPlaceholder}
                test-id="sub-top-bar-search-field"
                onChange={onSearch}
              />
            )}
            {customLeftSideContent}
          </Box>
          {customRightSideContent && (
            //@ts-expect-error TS(2769) FIXME: No overload matches this call.
            <Box display="flex" marginLeft="auto" alignItems="center" sx={{ gap: sectionGap }}>
              {customRightSideContent}
            </Box>
          )}
          {/* Right Side actions (buttons and toggle view) */}
          {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
          <Box display="flex" sx={{ gap: 16, alignItems: "center" }}>
            {chips?.map(({ label, icon, value, status }: $TSFixMe, index: number) => {
              return (
                <div key={`chip_${label}_${index}`}>
                  {label}
                  <Chip
                    // @ts-ignore
                    variant="soft"
                    style={getChipStyle(status)}
                    label={
                      <Typography variant="caption">
                        {icon}
                        {capitalize(value)}
                      </Typography>
                    }
                  />
                </div>
              );
            })}
            <Box display="flex" sx={{ gridGap: 16 }}>
              {buttons?.map(
                (
                  /* @ts-expect-error TS(2339) FIXME: Property 'loading' does not exist on type '{ disab... Remove this comment to see the full error message */
                  { id, label, action, loading, component, loadingComponent, ...restProps },
                  index
                ) => {
                  if (component) {
                    return <React.Fragment key={index}>{component}</React.Fragment>;
                  }
                  if (loading && loadingComponent) {
                    return <React.Fragment key={index}>{loadingComponent}</React.Fragment>;
                  }
                  return (
                    <BarButton
                      loading={loading}
                      id={id}
                      key={label ?? index}
                      main
                      label={label}
                      onClick={action}
                      {...restProps}
                    />
                  );
                }
              )}
            </Box>
            {onToggleView && <ViewButtons />}
            {onSave ? (
              <div className={classes.actionsButtonContainer}>
                {onCancel && (
                  <Button
                    onClick={onCancel}
                    className={`${classes.barMainButton} ${classes.actionButton}`}>
                    Cancel
                  </Button>
                )}
                <Button
                  disabled={isSaveDisabled}
                  onClick={onSave}
                  className={`${classes.barMainButton} ${classes.actionButton}`}
                  color="primary"
                  variant="contained"
                  disableElevation
                  startIcon={<CheckmarkSquare />}>
                  Save
                </Button>
              </div>
            ) : null}
            {moreOptions && moreOptions?.length > 0 && (
              <>
                <button
                  onClick={handleClick}
                  className={classes.moreOptionsButton}
                  // eslint-disable-next-line react/no-unknown-property
                  test-id="more-options-btn">
                  <MoreVert color="primary" />
                </button>
                <Menu
                  className={classes.barMenu}
                  anchorEl={anchorEl}
                  anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                  getContentAnchorEl={undefined}
                  transitionDuration={{ exit: 0 }}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}>
                  {isNestedOpen && nested ? (
                    <Grid container alignItems="center" direction="column">
                      <MenuItem
                        className={classes.fullWidth}
                        onClick={(event: $TSFixMe) => {
                          event.preventDefault();
                          event.stopPropagation();
                          setIsNestedOpen(false);
                          setNested(null);
                        }}>
                        <Box pr="6px" display="flex">
                          <ChevronLeft fontSize="small" />
                        </Box>
                      </MenuItem>
                      {React.cloneElement(nested, {
                        ...nested.props,
                        onClose: () => {
                          setIsNestedOpen(false);
                          setNested(null);
                          handleClose();
                        }
                      })}
                    </Grid>
                  ) : (
                    moreOptions?.map(
                      ({
                        id,
                        icon,
                        label,
                        action,
                        disabled,
                        tooltip,
                        loading,
                        testId,
                        nestedComponent
                      }) => {
                        const handleClickItem = () => {
                          if (nestedComponent) {
                            setNested(nestedComponent);
                            setIsNestedOpen(true);
                            return;
                          }
                          action?.();
                          setAnchorEl(null);
                        };
                        return (
                          <MenuItem
                            id={id}
                            key={label}
                            onClick={() => !disabled && handleClickItem()}
                            {...(disabled && { className: "disabled" })}>
                            <BarButton
                              icon={icon}
                              label={label}
                              tooltip={tooltip}
                              testId={testId}
                              loading={loading}
                              endIcon={nestedComponent ? <ChevronRight /> : null}
                              disabled={disabled}
                            />
                          </MenuItem>
                        );
                      }
                    )
                  )}
                </Menu>
              </>
            )}
          </Box>
        </div>
      )}
    </Grid>
  );
};

export default SubtopBar;
