import { useMutation } from "@tanstack/react-query";

import api from "services/AxiosClient/AxiosClient";

import { MessageFeedbackRequestDto } from "@rapidcanvas/rc-api-core";

export const useSaveFeedbackMutation = (threadId: string) => {
  return useMutation({
    mutationFn: async ({
      request,
      messageId
    }: {
      request: MessageFeedbackRequestDto;
      messageId: string;
    }) => {
      return await api.fetchResponse(() =>
        api.AiControllerApi.addFeedback(threadId, messageId, request)
      );
    }
  });
};
