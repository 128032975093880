import React, { useCallback, useMemo } from "react";
import axios from "axios";
import _ from "lodash";
import { Box, Grid, IconButton, Tooltip, Typography, CircularProgress } from "@material-ui/core";
import { useParams, useLocation } from "react-router-dom";

import ArtifactPreview from "./ArtifactPreview";
import CommonLoader from "src/components/CommonLoader";
import type { Artifact, File } from "src/types/Artifact.type";
import useArtifactByName from "src/hooks/api/artifacts/useArtifactByName";
import { DeleteEntityFileConfirmationModal } from "../DeleteEntityFileConfirmationModal";
import { DeleteOutline } from "@material-ui/icons";
import { Download } from "src/assets/icons/Download";
import { download } from "utils/helpers/file.helpers";
import { downloadArtifactSignedUrl } from "services/Apis/wrappers";
import { formatBytes } from "utils/helpers";
import { deleteAPIWithRethrow, handleResponse } from "services/Apis/Apis.service";
import { toastWrapper } from "services/ToastClient/toastWrapper";
import Table from "src/components/custom/TableV8/Table";

type Props = {
  artifactName: string;
  artifact?: Artifact;
  onArtifactDelete?: () => void;
  onArtifactDownload?: () => void;
  disableDelete?: boolean;
  maxHeight?: string;
};

export const ArtifactTable = ({
  artifactName,
  artifact: initialData,
  onArtifactDelete,
  onArtifactDownload,
  disableDelete = false,
  maxHeight
}: Props) => {
  const { scenarioId, jobRunId } = useParams<$TSFixMe>();
  const location = useLocation();
  const isJobPath = useMemo(() => /jobs/.test(location.pathname), [location.pathname]);

  const [filesDownloading, setFilesDownloading] = React.useState<Array<string>>([]);

  const [isDeleteFileModalOpen, setIsDeleteFileModalOpen] = React.useState<boolean>(false);
  const [fileToBeDeleted, setFileToBeDeleted] = React.useState<File | null>(null);
  const [isDeleteInProgress, setIsDeleteInProgress] = React.useState(false);

  const { data, isLoading } = useArtifactByName(artifactName, scenarioId, jobRunId, {
    initialData
  });

  const onDownload = useCallback(
    async (path: string) => {
      setFilesDownloading((files) => [...files, path]);
      try {
        const signedUrl = await downloadArtifactSignedUrl(`${artifactName}/${path}`);
        await axios
          .get(signedUrl?.signedUrl, {
            responseType: "arraybuffer",
            headers: { ...signedUrl.headers, "Access-Control-Allow-Origin": "*" }
          })
          .then((response) => {
            download(response.data, path, response.headers["content-type"]);
          });

        onArtifactDownload?.();
      } catch (error: $TSFixMe) {
        handleResponse({
          errorMessage:
            error.response?.data?.msg ||
            error.message ||
            `Error in downloading artifact - ${artifactName}`
        });
      } finally {
        setFilesDownloading((files) => files?.filter((file) => file !== path));
      }
    },
    [artifactName, onArtifactDownload]
  );

  const onDelete = useCallback(
    async (path: string) => {
      setIsDeleteInProgress(true);
      try {
        await deleteAPIWithRethrow(
          `/v2/artifacts/contents/${artifactName}`,
          {},
          { params: { path } }
        );

        onArtifactDelete?.();
        toastWrapper({
          type: "success",
          content: `Artifact file - ${path} deleted successfully.`
        });
      } catch (error: $TSFixMe) {
        handleResponse({
          errorMessage:
            error.response?.data?.msg ||
            error.message ||
            `Error in deleting artifact - ${artifactName}`
        });
      } finally {
        setFileToBeDeleted(null);
        setIsDeleteInProgress(false);
        setIsDeleteFileModalOpen(false);
      }
    },
    [artifactName, onArtifactDelete]
  );

  const columns = useMemo(() => {
    return [
      {
        id: "File",
        accessorKey: "path",
        header: "File",

        cell: ({ getValue }: $TSFixMe) => <Typography variant="body2">{getValue()}</Typography>
      },
      {
        id: "File Size",
        accessorKey: "sizeInBytes",
        header: "File Size",

        cell: ({ getValue }: $TSFixMe) => formatBytes(getValue())
      },
      {
        id: "File Type",
        accessorKey: "type",
        header: "File Type"
      },
      {
        id: "Actions",
        accessorKey: "id",
        header: "Actions",
        enableSorting: false,
        cell: ({ row }: $TSFixMe) => {
          const filePath = row?.original?.path;
          const isDownloadInProgress = !!filesDownloading.find((file) => file === filePath);

          return (
            <Grid container alignItems="center">
              <ArtifactPreview
                name={row?.original?.path}
                artifactName={artifactName}
                type={row?.original?.type}
              />
              <Tooltip title="Download File">
                {isDownloadInProgress ? (
                  <Box display="flex" px="12px">
                    <CircularProgress size="20px" />
                  </Box>
                ) : (
                  <IconButton
                    aria-label="download"
                    component="div"
                    onClick={() => onDownload(filePath)}>
                    <Download />
                  </IconButton>
                )}
              </Tooltip>
              {!isJobPath && (
                <Tooltip title={data?.producer ? "This file cannot be deleted." : "Delete File"}>
                  <IconButton
                    disabled={disableDelete || !!data?.producer}
                    aria-label="delete"
                    component="div"
                    onClick={() => {
                      setFileToBeDeleted(row?.original);
                      setIsDeleteFileModalOpen(true);
                    }}>
                    <DeleteOutline />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
          );
        }
      }
    ];
  }, [data?.producer, disableDelete, filesDownloading, onDownload]);

  const handleConfirm = () => {
    fileToBeDeleted && onDelete(fileToBeDeleted.path);
  };

  return !isLoading ? (
    <>
      <Table
        data={_.get(data, "fileObjects") || []}
        columns={columns}
        stylesProps={{ size: "small", maxHeight: !!maxHeight ? maxHeight : undefined }}
        infoProps={{ emptyTableMessage: "No files" }}
      />
      {isDeleteFileModalOpen && (
        <DeleteEntityFileConfirmationModal
          fileName={fileToBeDeleted?.path || ""}
          entityLabel="Artifact"
          entityName={artifactName}
          isDeleteInProgress={isDeleteInProgress}
          onConfirm={handleConfirm}
          onClose={() => setIsDeleteFileModalOpen(false)}
        />
      )}
    </>
  ) : (
    <CommonLoader />
  );
};
