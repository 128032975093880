import React, { useMemo } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { isEmpty } from "lodash";

import Drawer from "components/Drawer/CustomDrawer";
import useGetJobRunLogs from "./useGetJobRunLogs";
import { LogsContent } from "src/pages/Projects/common/ShowLogsModal/LogsContent";
import { ToastifyAlert } from "src/components/ToastifyAlert/ToastifyAlert";
import {
  getStatusEquivalence,
  ShowLogsDrawerTitle
} from "src/pages/Projects/common/ShowLogsModal/ShowLogsDrawerTitle";
import { JobRunStatuses, jobRunStatusesNames } from "../../utils/Jobs.constants";
import useExportJobRunLogs from "src/pages/Projects/common/useExportJobRunLogs";
import { checkEnvRelaunch } from "environmentsModule/utils/environmentRelaunch.helpers";

const TEMP_MSG = ["Fetching logs. Please wait..."];

const useStyles = makeStyles({
  autoMlLogs: {
    flexWrap: "nowrap",
    width: "600px"
  },
  logStatuses: {
    gap: "16px",
    background: "#1e1e1e",
    padding: "8px 8px 0px 8px"
  },
  logStatusWithoutBckg: {
    gap: "16px",
    padding: "8px 8px 0px 8px"
  },
  drawer: {
    width: 600,
    flexShrink: 0
  },
  drawerPaper: {
    width: 600
  }
});

interface IProps {
  projectId: string;
  jobId: string;
  name: string;
  jobRunId: string;
  open: boolean;
  onClose: () => void;
  isJobRunning: boolean;
}

const JobRunLogsDrawer: React.FC<IProps> = (props) => {
  const { projectId, name, jobId, jobRunId, open, onClose, isJobRunning } = props;
  const exportJobRunLogs = useExportJobRunLogs();

  const classes = useStyles();
  const [searchValue, setSearchValue] = React.useState("");
  const { jobRunInfo, isFetching, jobRunLogs, jobRunStatus } = useGetJobRunLogs({
    jobRunId
  });

  const url = `/projects/${projectId}/jobs/${jobId}/job-runs/${jobRunId}/logs`;

  const renderInfo = ({ message, type }: $TSFixMe) => {
    return (
      <Grid
        container
        item
        xs={12}
        style={{
          padding: 16
        }}>
        <ToastifyAlert type={type} message={message} />
      </Grid>
    );
  };

  const onSearchValueChange = (event: $TSFixMe) => {
    setSearchValue(event.target.value);
  };

  const filteredLogs = useMemo(() => {
    return (jobRunLogs || [])?.filter((eachField: $TSFixMe) => {
      return searchValue ? eachField?.toLowerCase()?.includes(searchValue?.toLowerCase()) : true;
    });
  }, [searchValue, jobRunLogs]);

  const exportLogs = async () => {
    if (jobRunId) {
      !!projectId && checkEnvRelaunch(projectId);
      await exportJobRunLogs.mutateAsync({ jobRunId, name });
    }
  };

  const title = (
    <ShowLogsDrawerTitle
      exportDisabledMessage="Please wait until job run is complete"
      title={name}
      exportDisabled={isJobRunning}
      url={url}
      onClose={onClose}
      onSearch={onSearchValueChange}
      searchValue={searchValue}
      exportLogLoading={exportJobRunLogs.isLoading}
      exportLogs={exportLogs}
      {...(jobRunStatus
        ? {
            status: {
              color: getStatusEquivalence(jobRunStatus as JobRunStatuses),
              text: jobRunStatusesNames[jobRunStatus]
            }
          }
        : {})}
    />
  );
  return (
    <Drawer
      anchor="right"
      variant="temporary"
      open={open}
      titleStyle={{ padding: 0, borderBottom: "1px solid #E0E0E0", lineHeight: 1 }}
      hideCloseButton
      classes={{
        paper: classes.drawerPaper
      }}
      className={classes.drawer}
      title={title}
      onClose={onClose}>
      <Grid container direction="column" justifyContent="center" className={classes.autoMlLogs}>
        {!isFetching ? (
          <LogsContent logs={TEMP_MSG} />
        ) : (filteredLogs && filteredLogs?.length !== 0) || isEmpty(jobRunInfo) ? (
          <LogsContent logs={filteredLogs} />
        ) : (
          renderInfo(jobRunInfo)
        )}
      </Grid>
    </Drawer>
  );
};

export default JobRunLogsDrawer;
