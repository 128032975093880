import api from "services/AxiosClient/AxiosClient";
import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { Condition } from "@rapidcanvas/rc-api-core";

interface IVariable {
  groupId: string;
  timeout: number;
  condition?: Condition;
}

const useUpdateRecipeTimeout = (): UseMutationResult<any, unknown, IVariable, unknown> => {
  return useMutation({
    mutationFn: async ({ groupId, timeout, condition }) => {
      const data = await api.fetchResponse(() =>
        api.DfsRunConfigGroupControllerV2Api.updateGroup(groupId, { timeout, condition })
      );
      return data;
    }
  });
};

export default useUpdateRecipeTimeout;
