import React from "react";
import { Box } from "@material-ui/core";

function a11yProps(index: $TSFixMe) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

type Props = {
  children?: React.ReactNode;
  index: number | string;
  value: number | string;
  boxProps?: $TSFixMe;
  className?: string;
  style?: any;
  keepMounted?: boolean;
};

function TabPanel(props: Props) {
  const { children, value, index, boxProps, keepMounted = false, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      aria-labelledby={`simple-tab-${index}`}
      {...a11yProps(index)}
      {...other}>
      {keepMounted
        ? true
        : value === index && (
            <Box height="100%" {...boxProps}>
              {children}
            </Box>
          )}
    </div>
  );
}

export default TabPanel;
