import { useSearchParams } from "react-router-dom";

import {
  checkIfAddFile,
  DatasetWebPathQueryParameters
} from "src/pages/private/ProjectsModule/utils";

const useAddFileQueryParam = () => {
  const [searchParams] = useSearchParams();
  return checkIfAddFile(searchParams.get(DatasetWebPathQueryParameters.IsAddFile));
};

export default useAddFileQueryParam;
