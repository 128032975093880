export enum EnvironmentsConfig {
  ExemptingWidth = 0.15, // 15%
  CardWidth = 375,
  CardHeight = 370
}

export enum EnvironmentLogModes {
  Live = "live",
  Offline = "offline"
}

export enum NewEnvironmentFormFields {
  Name = "name",
  Description = "description",
  EnvironmentType = "environmentType",
  Cores = "cores",
  Memory = "memInGbs",
  DiskSpace = "diskInGbs",
  Packages = "packages",
  LinuxLibs = "linuxPkgs",
  Requirements = "requirements"
}

export const NewEnvironmentFormFieldsNameMapping = {
  [NewEnvironmentFormFields.Name]: "Environment Name",
  [NewEnvironmentFormFields.Description]: "Environment Description",
  [NewEnvironmentFormFields.EnvironmentType]: "Environment Type",
  [NewEnvironmentFormFields.Cores]: "Cores",
  [NewEnvironmentFormFields.Memory]: "Memory",
  [NewEnvironmentFormFields.DiskSpace]: "Disk Space",
  [NewEnvironmentFormFields.Packages]: "Python Libraries",
  [NewEnvironmentFormFields.LinuxLibs]: "Linux Libraries"
};

export enum SplashSectionHelperText {
  Title = "Get Started with a New Environment",
  SubLine1 = "Welcome to the Environments page.",
  SubLine2 = "Start a new one by clicking on the button below."
}

export enum NewEnvCloseModalPromptDetails {
  title = "Confirm",

  messageLine1 = "If you leave you will lose any unsaved changes.",
  messageLine2 = "Do you really want to close this window?",

  cancelLabel = "No"
}

export enum NewEnvironmentModalHelperText {
  title = "Create a new environment",

  EnvironmentNameInfo = "The name shown in the list of environments.",
  EnvironmentDescriptionInfo = "Provide additional information about this environment.The information can be up to 1500 characters long.",
  EnvironmentTypeInfo = "Disk space cannot be modified once the environment is created.",

  EnvironmentSaved = "Environment created successfully!",

  submitLabel = "Create Environment"
}

export enum EnvironmentDeletePromptDetails {
  title = "Delete Environment",

  messageLine1 = "Do you really want to delete this environment?",
  messageLine2 = "If you confirm you will delete the environment completely!",

  cancelLabel = "No"
}

export enum EnvironmentsHelperText {
  NewEnvironment = "New Environment",

  SelectAll = "Select All",

  Edit = "Edit",
  Config = "Config",
  Logs = "Logs",
  Delete = "Delete",

  Projects = "Projects",
  NoProjectFound = "No project found!",
  NoProjects = "No projects",

  EnvironmentDeleted = "Environment deleted successfully!",
  EnvironmentLaunchFailed = "Environment launch failed! Please check environment logs for more details.",

  SomethingWentWrong = "Something went wrong!",
  LogsFetchingInfo = "Fetching logs. Please wait...",
  NoLogsFound = "No logs found!",

  Export = "Export",
  OpenInNewTab = "Open in New Tab"
}

export enum EnvironmentCardHelperText {
  EnvironmentType = "Environment Type",
  Projects = "Projects",
  UpdatedOn = "Updated on"
}
