import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import { Chip, IconButton, TextField, Tooltip, makeStyles } from "@material-ui/core";
import {
  ThumbUpAltOutlined,
  ThumbDownAltOutlined,
  ThumbUpAltRounded,
  ThumbDownAltRounded
} from "@material-ui/icons";

import Button from "components/Buttons/DefaultButton";
import { useSaveFeedbackMutation } from "src/hooks/api/aiguide/useSaveFeedbackMutation";
import {
  MessageFeedbackRequestDto,
  MessageFeedbackRequestDtoFeedbackEnum
} from "@rapidcanvas/rc-api-core";

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    gap: "12px"
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    marginTop: "10px",

    "& button": {
      padding: 0
    }
  },
  iconButtonRoot: {
    "& svg": {
      width: 20,
      height: 20
    },

    "&:hover": {
      backgroundColor: "transparent"
    },

    "&:active": {
      backgroundColor: "transparent"
    }
  },
  feedBackSentMsg: {
    display: "flex",
    flexDirection: "column",
    fontSize: "12px"
  },
  inputRoot: {
    background: "#fff",
    paddingTop: "6.5px !important",
    paddingBottom: "6.5px !important"
  }
});

enum DISLIKED_RESPONSE_TYPES {
  InCorrectResponse = "Incorrect Response",
  Error = "Error",
  Gibberish = "Gibberish",
  IncompleteResponse = "Incomplete Response",
  Other = "Other"
}

enum REACTIONS {
  Liked = "liked",
  DisLiked = "DisLiked"
}

export interface ILikes {
  liked: boolean;
  feedback: string;
}

interface IProps {
  threadId: string;
  messageId: string;
  feedback: MessageFeedbackRequestDtoFeedbackEnum | undefined;
  onSuccess: (request: MessageFeedbackRequestDto) => void;
  rightComponent?: React.ReactNode;
  hideButtons?: boolean;
}

const MAX_CHAR_LIMIT = 1500;

const AIGuideFeedback: React.FC<IProps> = (props) => {
  const { threadId, messageId, feedback, rightComponent, onSuccess, hideButtons = false } = props;

  const saveFeedbackMutation = useSaveFeedbackMutation(threadId);
  const { root, iconButtonRoot, wrapper, feedBackSentMsg, inputRoot } = useStyles();
  const ref = useRef<HTMLDivElement | null>(null);

  const [reaction, setReaction] = useState<REACTIONS | null>(null);
  const [disLikeResponse, setDislikeResponse] = useState<DISLIKED_RESPONSE_TYPES | null>(null);
  const [value, setValue] = React.useState("");
  const [isHistoric, setIsHistoric] = useState(false);

  useEffect(() => {
    if (feedback && feedback !== MessageFeedbackRequestDtoFeedbackEnum.NotProvided) {
      setReaction(
        feedback === MessageFeedbackRequestDtoFeedbackEnum.Liked
          ? REACTIONS.Liked
          : REACTIONS.DisLiked
      );
      setIsHistoric(true);
    }
  }, [feedback]);

  useEffect(() => {
    if (
      saveFeedbackMutation.isSuccess ||
      reaction === REACTIONS.DisLiked ||
      disLikeResponse === DISLIKED_RESPONSE_TYPES.Other
    ) {
      ref.current?.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, [reaction, disLikeResponse, saveFeedbackMutation.isSuccess]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleReaction = (type: REACTIONS) => {
    if (reaction !== type) {
      setReaction(type);

      setIsHistoric(false);
      saveFeedbackMutation.reset();
      if (type === REACTIONS.Liked) {
        setDislikeResponse(null);
        setValue("");
        const request = {
          feedback: MessageFeedbackRequestDtoFeedbackEnum.Liked
        };
        saveFeedbackMutation.mutate(
          { messageId, request },
          {
            onError: () => setReaction(reaction),
            onSuccess: () => onSuccess(request)
          }
        );
      }
    }
  };

  const handleClick = (val: DISLIKED_RESPONSE_TYPES) => {
    if (disLikeResponse !== val) {
      setDislikeResponse(val);
      setValue("");
      saveFeedbackMutation.reset();
      if (val !== DISLIKED_RESPONSE_TYPES.Other) {
        const request = {
          feedback: MessageFeedbackRequestDtoFeedbackEnum.Disliked,
          comment: val
        };
        saveFeedbackMutation.mutate(
          {
            messageId,
            request
          },
          {
            onError: () => setDislikeResponse(disLikeResponse),
            onSuccess: () => {
              onSuccess(request);
              setValue(val);
            }
          }
        );
      }
    }
  };

  const handleSubmit = () => {
    const request = { feedback: MessageFeedbackRequestDtoFeedbackEnum.Disliked, comment: value };
    saveFeedbackMutation.mutate(
      {
        messageId,
        request
      },
      { onSuccess: () => onSuccess(request) }
    );
  };

  const isLoading = saveFeedbackMutation.isLoading;
  return (
    <div className={wrapper} ref={ref}>
      <div className={root} style={{ marginLeft: "13px" }}>
        {!hideButtons && (
          <>
            <Tooltip title={isLoading ? "" : "Good response"}>
              <span>
                <IconButton
                  data-testid="ai-feedback-reaction-btn"
                  classes={{ root: iconButtonRoot }}
                  disabled={isLoading}
                  onClick={() => handleReaction(REACTIONS.Liked)}>
                  {reaction === REACTIONS.Liked ? (
                    <ThumbUpAltRounded color="primary" />
                  ) : (
                    <ThumbUpAltOutlined />
                  )}
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title={isLoading ? "" : "Bad response"}>
              <span>
                <IconButton
                  data-testid="ai-feedback-reaction-btn"
                  classes={{ root: iconButtonRoot }}
                  disabled={isLoading}
                  onClick={() => handleReaction(REACTIONS.DisLiked)}>
                  {reaction === REACTIONS.DisLiked ? (
                    <ThumbDownAltRounded color="primary" />
                  ) : (
                    <ThumbDownAltOutlined />
                  )}
                </IconButton>
              </span>
            </Tooltip>
          </>
        )}
        {rightComponent}
      </div>
      {reaction === REACTIONS.DisLiked && !isHistoric && (
        <div className={root}>
          {_.map(DISLIKED_RESPONSE_TYPES, (val) => (
            <Chip
              key={val}
              variant={disLikeResponse === val ? "default" : "outlined"}
              color={disLikeResponse === val ? "primary" : "default"}
              label={val}
              disabled={isLoading}
              onClick={() => handleClick(val)}
              data-testid="ai-feedback-dislike-chips"
            />
          ))}
        </div>
      )}
      {disLikeResponse === DISLIKED_RESPONSE_TYPES.Other && !saveFeedbackMutation.isSuccess && (
        <TextField
          multiline
          value={value}
          hiddenLabel
          style={{ maxWidth: "87%" }}
          placeholder="Please specify the issue here"
          minRows={1}
          maxRows={2}
          size="small"
          variant="outlined"
          data-testid="ai-feedback-text-field"
          InputProps={{
            className: inputRoot,
            endAdornment: (
              <Button
                size="small"
                variant="outlined"
                isCustomTheming={false}
                component="span"
                color="primary"
                data-testid="ai-feedback-submit-icon"
                disabled={!value || isLoading || value?.length > MAX_CHAR_LIMIT}
                onClick={handleSubmit}>
                Submit
              </Button>
            )
          }}
          error={value.length > MAX_CHAR_LIMIT}
          helperText={
            value.length > MAX_CHAR_LIMIT
              ? `Maximum allowed length is ${MAX_CHAR_LIMIT} characters.`
              : undefined
          }
          onChange={handleChange}
        />
      )}
      {saveFeedbackMutation.isSuccess && (
        <div className={feedBackSentMsg}>
          <div style={{ marginLeft: "13px", overflowWrap: "break-word" }}>{value}</div>
          <span style={{ color: "green", marginLeft: "13px" }} data-testid="ai-feedback-sent-text">
            Feedback sent
          </span>
        </div>
      )}
    </div>
  );
};

export default AIGuideFeedback;
