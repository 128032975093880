import React, { useState } from "react";
import {
  makeStyles,
  TextField,
  TextFieldProps,
  InputAdornment,
  IconButton
} from "@material-ui/core";
import clsx from "clsx";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  textField: {
    "& .MuiFilledInput-root": {
      borderRadius: 4,
      backgroundColor: "transparent",
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      "&:hover": {
        backgroundColor: "transparent"
      },
      "&.Mui-focused>div": {
        backgroundColor: "transparent"
      }
    }
  }
}));

type Props = TextFieldProps & {
  InputProps?: $TSFixMe;
  hideShowPassword?: boolean;
};

const PasswordField = ({
  id,
  variant = "filled",
  className,
  InputProps,
  hideShowPassword = false,
  ...rest
}: Props) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  return (
    <TextField
      id={id}
      name={id}
      variant={"outlined"}
      type={showPassword ? "text" : "password"}
      className={clsx(classes.textField, className)}
      InputProps={{
        disableUnderline: true,
        endAdornment: hideShowPassword ? undefined : (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end">
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
        ...InputProps
      }}
      {...rest}
    />
  );
};

export default PasswordField;
