export enum DateFormatFormFields {
  Fields = "fields"
}

export const DateFormatFormFieldsNameMapping = {
  [DateFormatFormFields.Fields]: "Fields"
};

export const segmentOptions = ["DD", "MM", "YY", "YYYY"];

export enum DateFormatCloseModalPromptDetails {
  title = "Confirm",

  messageLine1 = "If you leave you will lose any unsaved changes.",
  messageLine2 = "Do you really want to close this modal?",

  cancelLabel = "No"
}

export enum DateFormatModalHelperText {
  Title = "Set Date formats for fields",

  SubmitLabel = "Apply"
}
