import React from "react";

// Packages
import { Link } from "react-router-dom";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";

// Utils
import { RenderText } from "components/custom";

// Components
import EditName from "src/layout/NavBars/components/SubTopNavBar/EditName";

const FONT_SIZE = 14;

type Props = {
  project: $TSFixMe;
  dataAppName?: string;
  updateDataAppName: $TSFixMeFunction;
  shouldResetDataAppName: number | boolean;
};

const SubTopNavBarBreadcrumbs = (props: Props) => {
  const { project, dataAppName, updateDataAppName, shouldResetDataAppName } = props || {};

  const breadcrumbs: React.ReactNode[] = [];

  if (!!project?.id) {
    breadcrumbs.push(
      <Link key="projects" to={`/projects/${project?.id}/canvas`} data-testid="projectBreadcrumb">
        <RenderText>{project?.name || "Project"}</RenderText>
      </Link>
    );

    breadcrumbs.push(
      <Link
        key="project-dataapp"
        to={`/projects/${project?.id}/project-dataapps`}
        data-testid="dataAppTitleBreadcrumb">
        <RenderText>DataApps</RenderText>
      </Link>
    );
  }

  breadcrumbs.push(
    <EditName
      key="edit-name"
      data-testid="dataAppEditNameBreadCrumb"
      fieldNameLabel="DataApp name"
      inputValue={dataAppName}
      onSave={updateDataAppName}
      isResetName={shouldResetDataAppName}
      containerProps={{
        style: {
          width: ((dataAppName || "")?.length + 1) * (FONT_SIZE - 4) + 16
        }
      }}
    />
  );

  return (
    <Breadcrumbs>
      {breadcrumbs?.map((eachBreadcrumb: React.ReactNode) => eachBreadcrumb)}
    </Breadcrumbs>
  );
};

export default SubTopNavBarBreadcrumbs;
