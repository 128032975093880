import { Tooltip } from "@material-ui/core";
import React from "react";

interface IProps {
  label: string;
  maxLength?: number;
  placement?: "bottom-start" | "bottom";
  children: React.ReactElement<any, any>;
}

const NodeCaptionOverflowTooltip: React.FC<IProps> = ({
  label,
  maxLength = 25,
  placement = "bottom",
  children
}) => {
  return (
    <Tooltip title={label.length > maxLength ? label : ""} placement={placement}>
      {children}
    </Tooltip>
  );
};

export default NodeCaptionOverflowTooltip;
