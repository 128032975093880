import React, { useMemo, useState } from "react";
import _ from "lodash";
import { IconButton, Tooltip, Typography } from "@material-ui/core";

import Modal from "src/components/custom/Modal/Modal";
import { SnippetGeneratorIcon } from "icons/NewUX";
import Spinner from "src/components/Spinner";
import useGenerateAboutContent from "pages/private/ProjectsModule/pages/About/hooks/useGenerateAboutContent";
import useAuthStore from "stores/zustand/auth.store";
import { useProjectsStore } from "stores/zustand/stores";

interface IProps {
  projectId?: string;
  projectName?: string;
}

const SnippetGenerator: React.FC<IProps> = (props) => {
  const { projectId, projectName } = props;

  const [open, setOpen] = useState(false);
  const generateContent = useGenerateAboutContent();

  const askAiEnabled = useAuthStore((state) => state.askAiEnabled);
  const [generatingSnippetsProjects, setGeneratingSnippetsProjects] = useProjectsStore((state) => [
    state.generatingSnippetsProjects,
    state.setGeneratingSnippetsProjects
  ]);

  const isAlreadyGenerating = useMemo(() => {
    return _.includes(generatingSnippetsProjects, projectId);
  }, [generatingSnippetsProjects, projectId]);

  const handleSnippetGeneratorClick = () => {
    setOpen(true);
  };

  const handleSubmit = () => {
    handleCancel();
    // @ts-ignore
    setGeneratingSnippetsProjects(_.uniq([...generatingSnippetsProjects, projectId]));
    generateContent.mutate({
      projectId,
      type: "OBJECTS",
      isUpdated: true,
      isForSnippets: true,
      isAsync: true,
      projectName
    });
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const disabled = generateContent.isLoading || isAlreadyGenerating;

  return (
    <>
      <Tooltip title={disabled ? "Snippet generation is in progress..." : "Snippet Generator"}>
        <div>
          <IconButton
            size="small"
            color="primary"
            disabled={disabled}
            onClick={handleSnippetGeneratorClick}>
            {disabled ? <Spinner size={12} noPadding /> : <SnippetGeneratorIcon />}
          </IconButton>
        </div>
      </Tooltip>
      {open && (
        <Modal
          open
          askAiA11yBackdrop={!askAiEnabled}
          title="AI Content"
          content={
            <>
              <Typography variant="body2" color="textPrimary" gutterBottom>
                This will trigger summary generation for all elements on the canvas using Generative
                AI.
              </Typography>
              <Typography variant="body2" color="textPrimary">
                This is an asynchronous process and can take some time depending on the number of
                elements on the canvas.
              </Typography>
            </>
          }
          onClose={handleCancel}
          onSubmit={handleSubmit}
          submitLabel="Generate"
        />
      )}
    </>
  );
};

export default SnippetGenerator;
