import React, { useState } from "react";

// MUI
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";

// Icons
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MoreVertRoundedIcon from "@material-ui/icons/MoreVertRounded";

// Types
import { MoreOption } from "./SubTopNavBar.types";
import { Button, useTheme } from "@material-ui/core";
import { ArrowDownIcon } from "icons/NewUX/ArrowDownIcon";

type Props = {
  options: MoreOption[];
  showTitle?: boolean;
};

const useStyles = makeStyles((theme) => ({
  menuItemIcon: {
    minWidth: "auto",
    marginRight: theme.spacing(1)
  },
  loader: { color: theme.palette.text.secondary }
}));

const MoreOptions = (props: Props) => {
  const { options, showTitle = false } = props || {};
  const theme = useTheme();

  const classes = useStyles();

  const [nestedComponent, setNestedComponent] = useState<React.ReactNode | null>(null);

  // Drop-down - STARTS >>
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const onOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
    setNestedComponent(null);
  };
  // ENDS - Drop-down

  return (
    <>
      {showTitle ? (
        <Button
          color="primary"
          variant="outlined"
          onClick={onOpen}
          style={{ fontWeight: 400, height: 30.75 }}
          size="small"
          data-testid="subTopNavBarMoreAction"
          endIcon={<ArrowDownIcon color={theme.palette.primary.main} />}>
          Actions
        </Button>
      ) : (
        <IconButton size="small" color="primary" onClick={onOpen}>
          <MoreVertRoundedIcon opacity={0.5} />
        </IconButton>
      )}

      {Boolean(anchorEl) && (
        <Menu
          open
          getContentAnchorEl={null}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          onClose={onClose}
          PaperProps={{
            style: {
              width: 225,
              borderRadius: 12
            }
          }}>
          {Boolean(nestedComponent) && (
            <MenuItem onClick={() => setNestedComponent(null)}>
              <ListItemIcon className={classes.menuItemIcon}>
                <ChevronLeftIcon />
              </ListItemIcon>
            </MenuItem>
          )}

          {Boolean(nestedComponent) ? (
            <>{nestedComponent}</>
          ) : (
            options?.map((moreOption: MoreOption, index: number) => (
              <Tooltip key={index} title={!!moreOption?.tooltip ? moreOption?.tooltip : ""}>
                <span>
                  <MenuItem
                    onClick={() => {
                      if (!!moreOption?.nestedComponent) {
                        setNestedComponent(moreOption?.nestedComponent);
                      } else {
                        moreOption?.action();
                        !!moreOption?.isCloseMoreAction && onClose();
                      }
                    }}
                    disabled={!!moreOption?.isDisabled || !!moreOption?.isLoading}>
                    <ListItemIcon className={classes.menuItemIcon}>{moreOption?.icon}</ListItemIcon>
                    <ListItemText
                      primary={moreOption?.label}
                      primaryTypographyProps={{ variant: "body2" }}
                    />
                    {!!moreOption?.isLoading && (
                      <ListItemSecondaryAction>
                        <CircularProgress size={18} color="inherit" className={classes.loader} />
                      </ListItemSecondaryAction>
                    )}
                    {!!moreOption?.nestedComponent && (
                      <ListItemSecondaryAction>
                        <ChevronRightIcon fontSize="small" />
                      </ListItemSecondaryAction>
                    )}
                  </MenuItem>
                </span>
              </Tooltip>
            ))
          )}
        </Menu>
      )}
    </>
  );
};

export default MoreOptions;
