import React from "react";
import { Tabs as MuiTabs, makeStyles, TabsProps as MuiTabsProps } from "@material-ui/core";

import NewThemeWrapper from "src/styles/NewThemeWrapper";

interface TabsProps extends MuiTabsProps {
  children: React.ReactNode;
  value: any;
  onChange: (value: any) => void;
}

const useStyles = makeStyles({
  tabs: {
    height: "44px",
    borderRadius: "32px",
    maxWidth: "fit-content",
    background: "#fff"
  },
  indicator: {
    height: "0px"
  },
  scroller: {
    padding: "4px",
    height: "100%"
  }
});

export const Tabs = ({ children, value, onChange, ...restProps }: TabsProps) => {
  const classes = useStyles();

  const handleChange = (_: any, newValue: any) => {
    onChange(newValue);
  };

  return (
    <NewThemeWrapper>
      <MuiTabs
        value={value}
        onChange={handleChange}
        classes={{
          indicator: classes.indicator,
          scroller: classes.scroller
        }}
        variant="scrollable"
        scrollButtons="auto"
        className={classes.tabs}
        textColor="primary"
        {...restProps}>
        {children}
      </MuiTabs>
    </NewThemeWrapper>
  );
};
