import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { filter } from "lodash";

import api from "services/AxiosClient/AxiosClient";
import useAuthStore from "stores/zustand/auth.store";
import { DataAppType } from "pages/DataApps/DataApps.type";
import { filterWithSpecificDataApp } from "pages/DataApps/utils/DataApps.helpers";

const QUERY_KEY_DATA_APPS = "query-key-data-apps";

const useGetDataApps = (
  options?: UseQueryOptions<DataAppType[]>
): UseQueryResult<DataAppType[]> => {
  const userEmail = useAuthStore((state) => state.userEmail);

  return useQuery({
    queryKey: [QUERY_KEY_DATA_APPS],
    queryFn: async () => {
      const response = (await api.fetchResponse(
        async () => await api.DataAppControllerApi.getDataApps()
      )) as DataAppType[];

      const dataWithAppTemplates = filter(response, (dataApp) => !!dataApp.appTemplateId);
      if (userEmail) {
        const filtered = filterWithSpecificDataApp(dataWithAppTemplates, userEmail);
        return filtered;
      }
      return dataWithAppTemplates;
    },
    refetchOnMount: true,
    ...options
  });
};

export default useGetDataApps;
export { QUERY_KEY_DATA_APPS };
