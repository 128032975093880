import React from "react";

interface IProps {
  width?: number;
  height?: number;
  viewBox?: string;
  fill?: string;
  color?: string;
}

const EditDateIcon: React.FC<IProps> = (props) => {
  const {
    width = 16,
    height = 16,
    viewBox = "0 0 16 16",
    fill = "none",
    color = "currentColor"
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.3998 0.800049C5.73117 0.800049 5.9998 1.06868 5.9998 1.40005V2.40005H9.9998V1.40005C9.9998 1.06868 10.2684 0.800049 10.5998 0.800049C10.9312 0.800049 11.1998 1.06868 11.1998 1.40005V2.40005H12.9998C14.2148 2.40005 15.1998 3.38502 15.1998 4.60005V7.00005C15.1998 7.33142 14.9312 7.60005 14.5998 7.60005H1.9998V13C1.9998 13.5524 2.44752 14 2.9998 14H6.9998C7.33117 14 7.5998 14.2687 7.5998 14.6C7.5998 14.9314 7.33117 15.2 6.9998 15.2H2.9998C1.78478 15.2 0.799805 14.2151 0.799805 13V4.60005C0.799805 3.38502 1.78478 2.40005 2.9998 2.40005H4.7998V1.40005C4.7998 1.06868 5.06844 0.800049 5.3998 0.800049ZM4.7998 3.60005H2.9998C2.44752 3.60005 1.9998 4.04776 1.9998 4.60005V6.40005H13.9998V4.60005C13.9998 4.04776 13.5521 3.60005 12.9998 3.60005H11.1998V4.20005C11.1998 4.53142 10.9312 4.80005 10.5998 4.80005C10.2684 4.80005 9.9998 4.53142 9.9998 4.20005V3.60005H5.9998V4.20005C5.9998 4.53142 5.73117 4.80005 5.3998 4.80005C5.06844 4.80005 4.7998 4.53142 4.7998 4.20005V3.60005ZM14.6286 10.9712C14.4004 10.743 14.0304 10.743 13.8022 10.9712L10.7998 13.9736V14.8H11.6263L14.6286 11.7976C14.8568 11.5695 14.8568 11.1994 14.6286 10.9712ZM12.9536 10.1227C13.6505 9.42581 14.7803 9.42581 15.4772 10.1227C16.174 10.8196 16.174 11.9493 15.4772 12.6462L12.2991 15.8243C12.1865 15.9368 12.034 16 11.8748 16H10.1998C9.86844 16 9.5998 15.7314 9.5998 15.4V13.7251C9.5998 13.5659 9.663 13.4133 9.77557 13.3008L12.9536 10.1227Z"
        fill={color}
      />
    </svg>
  );
};

export default EditDateIcon;
