import { Box, Grid, Button, IconButton, makeStyles, Tab, Tabs, Tooltip } from "@material-ui/core";
import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";

import _, { filter, find, get, map, orderBy } from "lodash";
import { OverflowTooltip, TabPanel } from "src/components";

import { useLocation, useParams } from "react-router";
import { CloseOutlined, InfoOutlined } from "@material-ui/icons";
import { AiAssistedIcon } from "icons/NewUX/AiAssistedRecipeIcon";
import NewThemeWrapper from "src/styles/NewThemeWrapper";
import { useApiConnectorRecipeContext } from "../ApiConnectorRecipeContext/useApiConnectorRecipeContext";
import { CodeRecipeCodeEditor } from "../../AddCodeRecipe/CodeRecipeTabContainer/CodeRecipeCodeEditor/CodeRecipeCodeEditor";
import ReadOnlyCodeRecipe from "../../AddCodeRecipe/CodeRecipeTabContainer/ReadOnlyCodeRecipe/ReadOnlyCodeRecipe";
import { RecipeTypes } from "src/pages/private/ProjectsModule/utils";
import ApiConnectorIcon from "icons/NewUX/ApiConnectorIcon";
import { CodeCheckpointsButton } from "../ApiConnectorRecipeHeader/CodeCheckpointsButton";
import ReadOnlyCodeEditor from "../../common/ReadOnlyCodeEditor";
import { Modal } from "src/components/custom";
import { ModalVariants } from "src/components/custom/Modal/Modal";
import { useDeleteCodeCheckpoint } from "src/hooks/api/codeCheckpoints/useDeleteCodeCheckpoint";
import { SaveCodeToCurrentBtn } from "./SaveCodeToCurrentBtn";
import { handleResponse } from "services/Apis/Apis.service";
import { useUpdateEffect } from "src/hooks/useUpdateEffect";
import CopilotRightBar from "../../AddCodeRecipe/CodeRecipeTabContainer/CopilotRightBar/CopilotRightBar";
import { SaveButton } from "./SaveButton";
import { READONLY_ENTITY } from "projectsModule/utils/Projects.constants";

const useStyles = makeStyles({
  tabDetails: {
    padding: "0 16px",
    height: "100%"
  },
  codeEditorWrap: {
    border: "1px solid #C5C5C5",
    height: "100%"
  },
  btn: {
    height: "auto"
  },
  codeTab: {
    textTransform: "none",
    background: "white",
    margin: "10px 10px 0 0",
    padding: "6px 2px 6px 12px",
    borderTopLeftRadius: "6px",
    borderTopRightRadius: "6px"
  },
  currentCodeTab: {
    textTransform: "none",
    background: "white",
    margin: "10px 10px 0 0",
    borderTopLeftRadius: "6px",
    borderTopRightRadius: "6px"
  },
  codeTabList: {
    marginLeft: "16px",
    marginRight: "16px",
    minHeight: "30px",
    gap: "5px",
    alignItems: "flex-end",
    "& .Mui-selected": {
      background: "#E7E7FF"
    },
    minWidth: 0,
    flexShrink: 1,
    overflow: "hidden"
  },
  scrollButtons: {
    background: "#fff",
    height: "48px"
  },
  tabHeader: {
    flexWrap: "nowrap",
    justifyContent: "space-between",
    paddingRight: "16px",
    width: "100%",
    overflow: "hidden",
    display: "flex"
  },
  headerButtons: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "16px",
    flexShrink: 0
  },
  buttons: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0px 16px",
    gap: "16px",
    flexShrink: 0
  },
  flexContainer: {
    maxWidth: "100%"
  },
  infoIcon: {
    margin: "0px 8px",
    color: "#4646b5",
    fontSize: "16px"
  }
});

interface IProps {
  isDefaultScenario: boolean;
  insertedCode: string | null;
  setInsertedCode: Dispatch<SetStateAction<string | null>>;
}

const ApiConnectorRecipeCodeTabs: React.FC<IProps> = (props) => {
  const { isDefaultScenario, setInsertedCode, insertedCode } = props;
  const [value, setValue] = useState<string>("current");
  const [isConfirmDeleteCheckpointModalVisible, setIsConfirmDeleteCheckpointModalVisible] =
    useState<boolean>(false);
  const [checkpointToDelete, setCheckpointToDelete] = useState<null | string>(null);
  const { scenarioId } = useParams();
  const classes = useStyles();
  const codeEditorRef: $TSFixMe = React.useRef(null);
  const [isCopilotVisible, setIsCopilotVisible] = React.useState<boolean>(false);
  const [copilotSelectedCode, setCopilotSelectedCode] = React.useState<string>("");

  const {
    editorValue,
    codeErrorDetails,
    selectedApiRecipe,
    currentSelectedApiRecipe,
    setSelectedApiRecipe,
    setEditorValue,
    handleTest,
    isSaveDisabled,
    isSaveInProgress,
    handleSave,
    codeCheckpointsData,
    recipeId,
    setCurrentSelectedApiRecipe,
    isAutoSaving,
    saveToolTip,
    isFetchingThreadMessages,
    suggestions
  } = useApiConnectorRecipeContext();

  const [checkpoints, setCheckpoints] = useState(
    codeCheckpointsData.codeCheckpoints.map((checkpoint) => ({ ...checkpoint, isVisible: false }))
  );

  const location = useLocation();
  const isJobPath = useMemo(() => /jobs/.test(location.pathname), [location.pathname]);

  useUpdateEffect(() => {
    setCheckpoints((currCheckpoints) =>
      codeCheckpointsData.codeCheckpoints.map((checkpoint) => ({
        ...checkpoint,
        isVisible: currCheckpoints.find((c) => c.id === checkpoint.id)?.isVisible ?? true
      }))
    );
  }, [codeCheckpointsData.codeCheckpoints]);

  const deleteCodeCheckpoint = useDeleteCodeCheckpoint();
  const handleChange = (__: any, newValue: string) => {
    if (value === newValue) return;
    setValue(newValue);
  };

  useEffect(() => {
    if (currentSelectedApiRecipe) {
      setValue(currentSelectedApiRecipe);
    } else {
      setValue("current");
    }
  }, [currentSelectedApiRecipe]);

  const handleClose = (index: string) => {
    setSelectedApiRecipe(filter(selectedApiRecipe, (item) => item.id !== index));
    if (currentSelectedApiRecipe === index) {
      setCurrentSelectedApiRecipe(undefined);
    }
  };

  const triggerSaveCode = useCallback(
    (code: string) => {
      const canSaveRecipe = !isSaveDisabled && isDefaultScenario;
      canSaveRecipe && handleSave({ isAutoSaving: true, code });
    },
    [isSaveDisabled, isDefaultScenario, handleSave]
  );

  const handleDeleteCheckpoint = () => {
    const checkpointIdToDelete = get(
      find(codeCheckpointsData.codeCheckpoints, { name: checkpointToDelete! }),
      "id"
    );
    recipeId &&
      checkpointIdToDelete &&
      deleteCodeCheckpoint.mutate(
        { id: checkpointIdToDelete, recipeId },
        {
          onSuccess: () => {
            handleResponse({
              successMessage: `Checkpoint ${checkpointToDelete} deleted successfully`
            });
            value === checkpointIdToDelete && setValue("current");
          },
          onSettled: () => {
            setIsConfirmDeleteCheckpointModalVisible(false);
          }
        }
      );
  };

  const checkpointTab = _.find(codeCheckpointsData.codeCheckpoints, { id: value! });

  const handleSaveCodeToCurrent = () => {
    checkpointTab &&
      handleSave({
        code: checkpointTab?.codeContent,
        onSuccess: () => {
          setValue("current");
        }
      });
  };

  const visibleCheckpoints = useMemo(
    () =>
      orderBy(
        checkpoints.filter((checkpoint) =>
          "isVisible" in checkpoint ? checkpoint.isVisible : true
        ),
        "updated",
        "desc"
      ),
    [checkpoints]
  );

  const handleShowCopilot = () => {
    setIsCopilotVisible(true);
  };
  const handleHideCopilot = () => {
    setIsCopilotVisible(false);
    setCopilotSelectedCode("");
  };

  useUpdateEffect(() => {
    // Re-layout code editor when Copilot visibility changes to update width
    codeEditorRef.current?.layout();
  }, [isCopilotVisible]);

  const handleCommandI = (selectedText: string) => {
    setCopilotSelectedCode(selectedText);
    handleShowCopilot();
  };

  const onClearCode = () => {
    setCopilotSelectedCode("");
  };

  return (
    <NewThemeWrapper>
      <Grid container direction="row" wrap="nowrap" style={{ height: "100%", width: "100%" }}>
        <Grid
          item
          xs={isCopilotVisible ? 8 : 12}
          container
          direction="column"
          wrap="nowrap"
          style={{ width: "100%" }}>
          {isConfirmDeleteCheckpointModalVisible && (
            <Modal
              open
              variant={ModalVariants.Delete}
              hideCloseIcon
              title="Delete Checkpoint"
              content={[`Are you sure you want to delete “${checkpointToDelete}”`]}
              submitLabel="Yes"
              cancelLabel="No"
              onClose={() => {
                setIsConfirmDeleteCheckpointModalVisible(false);
                setCheckpointToDelete(null);
              }}
              onSubmit={handleDeleteCheckpoint}
              isSubmitting={deleteCodeCheckpoint.isLoading}
            />
          )}
          <Grid container direction="row" wrap="nowrap" className={classes.tabHeader}>
            <Grid item style={{ flexGrow: 1, overflow: "auto" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                className={classes.codeTabList}
                classes={{
                  flexContainer: classes.flexContainer,
                  scrollButtons: classes.scrollButtons
                }}
                variant="scrollable"
                TabIndicatorProps={{
                  style: { background: "#4646b5", marginRight: "16px" }
                }}>
                <Tab
                  test-id="code-recipe-ask-ai-editor-tab"
                  key="current"
                  value="current"
                  label={
                    <Grid
                      container
                      style={{ gap: "5px" }}
                      alignItems="center"
                      justifyContent="center">
                      <span>Current</span>
                      <Tooltip title="Type entity names to get auto suggestions">
                        <InfoOutlined style={{ fontSize: "14px" }} />
                      </Tooltip>
                    </Grid>
                  }
                  className={classes.currentCodeTab}
                />
                {map(visibleCheckpoints, (checkpoint) => (
                  <Tab
                    key={checkpoint.id}
                    value={checkpoint.id}
                    style={{ maxWidth: "150px" }}
                    test-id="code-recipe-code-checkpoint-tab"
                    className={classes.codeTab}
                    label={
                      <Grid container justifyContent="space-between" alignItems="center">
                        <Grid
                          container
                          item
                          alignItems="center"
                          style={{
                            gap: "5px",
                            width: "calc(100% - 50px)",
                            flexWrap: "nowrap"
                          }}>
                          <Grid item xs>
                            <OverflowTooltip
                              value={checkpoint.name}
                              title={checkpoint.name}
                              style={{ whiteSpace: "nowrap" }}
                            />
                          </Grid>
                        </Grid>
                        <IconButton
                          test-id={`code-checkpoint-tab-${checkpoint.name}`}
                          onClick={(event) => {
                            event.stopPropagation();
                            setCheckpoints((prevCheckpoints) =>
                              prevCheckpoints.map((curr) =>
                                curr.id === checkpoint.id ? { ...curr, isVisible: false } : curr
                              )
                            );
                            setValue("current");
                          }}
                          style={{
                            padding: "2px"
                          }}>
                          <CloseOutlined fontSize="small" />
                        </IconButton>
                      </Grid>
                    }
                  />
                ))}
                {map(selectedApiRecipe, (recipe: any) => {
                  return (
                    <Tab
                      test-id={`code-recipe-code-editor-tab-${recipe.displayName}`}
                      key={recipe.id}
                      style={{ maxWidth: "150px" }}
                      label={
                        <Grid container justifyContent="space-between" alignItems="center">
                          <Grid
                            container
                            item
                            alignItems="center"
                            style={{
                              gap: "5px",
                              width: "calc(100% - 50px)",
                              flexWrap: "nowrap"
                            }}>
                            <Grid item style={{ width: "16px" }}>
                              {recipe.recipeType === RecipeTypes.ApiConnector ? (
                                <ApiConnectorIcon viewBox="0 0 16 16" width={16} />
                              ) : (
                                <AiAssistedIcon viewBox="0 0 20 20" />
                              )}
                            </Grid>
                            <Grid item xs>
                              <OverflowTooltip
                                value={recipe.displayName}
                                title={recipe.displayName}
                                style={{ whiteSpace: "nowrap" }}
                              />
                            </Grid>
                          </Grid>
                          <IconButton
                            test-id={`code-recipe-code-editor-close-tab-btn-${recipe.displayName}`}
                            onClick={(event) => {
                              event.stopPropagation();
                              handleClose(recipe.id);
                            }}
                            style={{
                              padding: "2px"
                            }}>
                            <CloseOutlined fontSize="small" />
                          </IconButton>
                        </Grid>
                      }
                      value={recipe.id}
                      className={classes.codeTab}
                    />
                  );
                })}
              </Tabs>
            </Grid>
            <Grid item className={classes.buttons}>
              {!isCopilotVisible && !isJobPath && (
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  className={classes.btn}
                  onClick={handleShowCopilot}>
                  Code Assist
                  <Tooltip title={"Select code and use Command+I to copy the code here"}>
                    <InfoOutlined className={classes.infoIcon} />
                  </Tooltip>
                </Button>
              )}
              {!codeCheckpointsData.hideSave && !isJobPath && (
                <Grid className={classes.headerButtons}>
                  {!!checkpointTab ? (
                    <Box display="flex" flexDirection="row" flexWrap="nowrap">
                      <SaveCodeToCurrentBtn
                        isSaveInProgress={isSaveInProgress}
                        isDisabled={isSaveDisabled}
                        onSave={handleSaveCodeToCurrent}
                      />
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        style={{ marginLeft: "8px" }}
                        onClick={() => {
                          setCheckpointToDelete(checkpointTab.name!);
                          setIsConfirmDeleteCheckpointModalVisible(true);
                        }}
                        data-testid="code-recipe-delete-input-dataset-btn">
                        Delete Checkpoint
                      </Button>
                    </Box>
                  ) : (
                    <CodeCheckpointsButton
                      isDisabled={
                        codeCheckpointsData.isLoading ||
                        !editorValue ||
                        codeCheckpointsData.isSaveInProgress
                      }
                      recipeId={recipeId!}
                      onDeleteSuccess={(deletedCheckpointId) => {
                        value === deletedCheckpointId && setValue("current");
                      }}
                      data={checkpoints}
                      isSaving={codeCheckpointsData.isSaveInProgress}
                      onSave={codeCheckpointsData.handleSave}
                      tooltip={
                        !editorValue
                          ? "Add code to enable save"
                          : isSaveInProgress || codeCheckpointsData.isSaveInProgress
                            ? "Wait until existing save action is complete"
                            : ""
                      }
                      onCheckpointSelect={(checkpointId) => {
                        setCheckpoints((prevCheckpoints) =>
                          prevCheckpoints.map((curr) =>
                            curr.id === checkpointId ? { ...curr, isVisible: true } : curr
                          )
                        );
                        setValue(checkpointId);
                      }}
                    />
                  )}
                </Grid>
              )}
              {!isJobPath && (
                <SaveButton
                  onClick={handleSave}
                  tooltip={isDefaultScenario ? saveToolTip || "" : READONLY_ENTITY}
                  disabled={isSaveDisabled || !isDefaultScenario}
                  loading={isSaveInProgress}
                  isAutoSaving={isAutoSaving}
                />
              )}
            </Grid>
          </Grid>
          <TabPanel
            key="current"
            value={value}
            index="current"
            boxProps={{ height: "100%" }}
            style={{ height: "calc(100% - 68px)" }}>
            <Grid className={classes.tabDetails}>
              <Grid className={classes.codeEditorWrap}>
                <Box height="calc(100% - 2px)" mt="1px" mb="4px">
                  <CodeRecipeCodeEditor
                    disabled={!isDefaultScenario || isJobPath}
                    editorValue={editorValue}
                    editorRef={codeEditorRef}
                    setEditorValue={setEditorValue}
                    insertedCode={insertedCode}
                    resetInsertedCode={() => setInsertedCode(null)}
                    errDetails={codeErrorDetails}
                    onControlT={(val) => handleTest(undefined, val)}
                    onEditorBlur={triggerSaveCode}
                    onCommandI={(val) => handleCommandI(val)}
                  />
                </Box>
              </Grid>
            </Grid>
          </TabPanel>
          {map(visibleCheckpoints, (checkpoint) => {
            return (
              <TabPanel
                key={checkpoint.id}
                value={value}
                index={checkpoint.id!}
                boxProps={{ height: "100%" }}
                style={{ height: "calc(100% - 68px)" }}>
                <Grid className={classes.tabDetails}>
                  <Grid className={classes.codeEditorWrap}>
                    <Box height="calc(100% - 2px)" mt="1px" mb="4px">
                      <ReadOnlyCodeEditor
                        key={`code-checkpoint-${checkpoint.id}`}
                        code={checkpoint.codeContent}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </TabPanel>
            );
          })}
          {map(selectedApiRecipe, (recipe) => {
            return (
              <TabPanel
                key={recipe.id}
                value={value}
                index={recipe.id}
                boxProps={{ height: "100%" }}
                style={{ height: "calc(100% - 68px)" }}>
                <Grid className={classes.tabDetails}>
                  <Grid className={classes.codeEditorWrap}>
                    <Box height="calc(100% - 2px)" mt="1px" mb="4px">
                      <ReadOnlyCodeRecipe recipeId={recipe.id} scenarioId={scenarioId} />
                    </Box>
                  </Grid>
                </Grid>
              </TabPanel>
            );
          })}
        </Grid>
        {isCopilotVisible && (
          <Grid item xs={4}>
            <CopilotRightBar
              onClose={handleHideCopilot}
              selectedCode={copilotSelectedCode}
              onClearCode={onClearCode}
              editorValue={editorValue}
              recipeId={recipeId!}
              isFetchingThreadMessages={isFetchingThreadMessages}
              suggestions={suggestions}
            />
          </Grid>
        )}
      </Grid>
    </NewThemeWrapper>
  );
};

export default ApiConnectorRecipeCodeTabs;
