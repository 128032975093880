import { useMutation, UseMutationResult } from "@tanstack/react-query";

import { postAPI } from "services/Apis/Apis.service";

interface IVariables {
  dataSourceIds: string[];
}

const useRefreshDataConnectorStatus = (): UseMutationResult<
  any[],
  unknown,
  IVariables,
  unknown
> => {
  return useMutation({
    mutationFn: async ({ dataSourceIds }) => {
      return await postAPI(`/v2/data-source/refresh`, { dataSourceIds });
    }
  });
};

export default useRefreshDataConnectorStatus;
