/* eslint-disable no-unused-vars */
import { useMutation } from "@tanstack/react-query";

import {
  updateTransformGroupWithRethrow,
  addTransformGroupWithRethrow
} from "services/Apis/wrappers";
import { Recipe, EntityTypeEnum } from "src/types";

export const useSaveStandardRecipeMutation = (onSuccess?: $TSFixMe) => {
  return useMutation({
    mutationFn: async ({
      recipeName,
      projectId,
      selectedArtifacts,
      selectedModels,
      selectedInputDatasets,
      recipe,
      conditions = null,
      payload = {}
    }: {
      recipeName?: string;
      projectId: string;
      recipe: Recipe | null;
      selectedArtifacts?: any;
      selectedModels?: any;
      selectedInputDatasets?: any;
      conditions?: $TSFixMe;
      payload?: $TSFixMe;
      onSuccess?: $TSFixMe;
    }) => {
      const runConfigs = recipe?.runConfigs?.length
        ? recipe?.runConfigs?.map((item) => item.id)
        : [];
      const updatedDatasets =
        recipe?.parents?.filter(
          (parent: $TSFixMe) =>
            parent.type !== EntityTypeEnum.ENTITY &&
            parent.type !== EntityTypeEnum.ARTIFACT &&
            parent.type !== EntityTypeEnum.MODEL
        ) || [];
      const newEntities =
        selectedInputDatasets?.map((item: $TSFixMe) => ({
          id: item.id,
          type: EntityTypeEnum.ENTITY
        })) || [];
      const newArtifacts =
        selectedArtifacts?.map((artifact: $TSFixMe) => ({
          name: artifact.name,
          type: EntityTypeEnum.ARTIFACT
        })) || [];
      const newModels =
        selectedModels?.map((model: $TSFixMe) => ({
          name: model.name,
          type: EntityTypeEnum.MODEL
        })) || [];
      const updatedParents = [...updatedDatasets, ...newEntities, ...newArtifacts, ...newModels];
      const updatedPayload = {
        ...recipe,
        runConfigs,
        projectId,
        name: recipe?.name || recipeName,
        displayName: recipeName || recipe?.displayName || recipe?.name,
        parents: updatedParents || recipe?.parents,
        condition: {
          expression: conditions
        },
        ...payload
      };

      const createdGroup = await (recipe?.id
        ? updateTransformGroupWithRethrow(updatedPayload, recipe?.id)
        : addTransformGroupWithRethrow(updatedPayload, projectId));
      return {
        transformGroupResponse: Array.isArray(createdGroup) ? createdGroup[0] : createdGroup
      };
    },
    onSuccess,
    cacheTime: 0
  });
};
