import { useMutation } from "@tanstack/react-query";

import { postAPIWithRethrow } from "services/Apis/Apis.service";

export const useLicenseTestMutation = () => {
  return useMutation({
    mutationFn: ({ licenseKey }: { licenseKey: string }) => {
      return postAPIWithRethrow(`/license/test`, { licenseKey }, {}, false);
    }
  });
};
