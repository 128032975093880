import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { getScenariosWithRethrow, getScenarios } from "services/Apis/wrappers";

interface ProjectProps extends UseQueryOptions<$TSFixMe> {
  projectId?: string;
  isApiWithRethrow?: boolean;

  // useQuery options
  cacheTime?: number;
  refetchOnMount?: boolean;
  refetchOnWindowFocus?: boolean;
}

export const useGetProjectScenarios = (props: ProjectProps) => {
  const {
    projectId,
    isApiWithRethrow = false,

    // useQuery options
    cacheTime = 0,
    refetchOnMount = false,
    refetchOnWindowFocus = false,
    ...options
  } = props;
  return useQuery({
    queryKey: ["scenarios", projectId],
    queryFn: () => {
      return isApiWithRethrow
        ? getScenariosWithRethrow({ projectId })
        : getScenarios({ projectId });
    },
    select: (data: $TSFixMe) => (Array.isArray(data) ? data : []),
    cacheTime,
    refetchOnMount,
    refetchOnWindowFocus,
    enabled: !!projectId,
    ...options
  });
};
