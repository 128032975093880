import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";

import StandardRecipeContainer from "./StandardRecipeContainer";
import { useGetRecipe } from "src/hooks/api";
import { EntityTypeEnum } from "src/types";
import CommonLoader from "src/components/CommonLoader";

export const StandardRecipeDataContainer = () => {
  const { groupId, scenarioId, jobRunId } = useParams<$TSFixMe>();
  const { isLoading, data, isSuccess, refetch } = useGetRecipe({
    recipeId: groupId,
    scenarioId,
    jobRunId,
    options: { refetchOnMount: true }
  });
  const [key, setKey] = useState("");

  const { entityIds, artifactNames, modelNames } = useMemo(() => {
    return {
      entityIds: data?.recipe?.parents
        ?.filter(
          (parent) => parent.type === EntityTypeEnum.ENTITY || parent.type === EntityTypeEnum.FILE
        )
        ?.map((parent) => parent.id),
      artifactNames: data?.recipe?.parents
        ?.filter((parent) => parent.type === EntityTypeEnum.ARTIFACT)
        ?.map((parent) => parent.name),
      modelNames: data?.recipe?.parents
        ?.filter((parent) => parent.type === EntityTypeEnum.MODEL)
        ?.map((parent) => parent.name)
    };
  }, [data?.recipe?.parents]);

  useEffect(() => {
    if (isSuccess) {
      setKey(Date.now().toString());
    }
  }, [isSuccess]);

  if (!groupId) {
    return <StandardRecipeContainer refetchRecipe={refetch} />;
  }
  return isLoading ? (
    <CommonLoader />
  ) : (
    <div key={key}>
      <StandardRecipeContainer
        refetchRecipe={refetch}
        entityIds={entityIds}
        artifactNames={artifactNames}
        modelNames={modelNames}
        recipeId={groupId}
        recipe={data?.recipe}
      />
    </div>
  );
};

export default StandardRecipeDataContainer;
