import { WebPaths } from "src/routing/routes";
import { LogsIcon } from "../../../../../assets/icons/LogsIcon";
import { TemplatesIcon } from "../../../../../assets/icons/TemplatesIcon";
import { UsageAndPolicyIcon } from "../../../../../assets/icons/UsageAndPolicyIcon";

import {
  ProfileIcon,
  ArtifactsAndModelsIcon,
  ConnectorsIcon,
  DashboardIcon,
  DataappsIcon,
  EnvironmentsIcon,
  ProjectsIcon
} from "icons/NewUX";

export const VIEW_DATA = "view-data";

export const getMenus = ({ section = "dashboard", shouldHideProjectNavigation = false }) => {
  const topbarConfig: $TSFixMe = {
    dashboard: {
      sectionName: "dashboard",
      backButtonPath: WebPaths.Dashboard,
      getMenuIcon: DashboardIcon
    },
    projects: {
      sectionName: "projects",
      backButtonPath: shouldHideProjectNavigation ? null : "/projects",
      getMenuIcon: ProjectsIcon
    },
    "data-connectors": {
      sectionName: "Connectors",
      backButtonPath: "/data-connectors",
      getMenuIcon: ConnectorsIcon
    },
    "data-connector": {
      sectionName: "Connectors",
      backButtonPath: "/data-connectors",
      getMenuIcon: ConnectorsIcon
    },
    transforms: {
      sectionName: "Templates",
      backButtonPath: "/transforms",
      getMenuIcon: TemplatesIcon
    },
    environments: {
      sectionName: "environments",
      backButtonPath: "/environments",
      getMenuIcon: EnvironmentsIcon
    },
    "environment-logs": {
      sectionName: "environment logs",
      backButtonPath: null,
      getMenuIcon: LogsIcon
    },
    "usage-and-policy": {
      sectionName: "Usage & Policy",
      backButtonPath: null,
      getMenuIcon: UsageAndPolicyIcon
    },
    variables: {
      sectionName: "Variables",
      backButtonPath: null,
      getMenuIcon: ProjectsIcon
    },
    "user-profile": {
      sectionName: "My Profile",
      backButtonPath: null,
      getMenuIcon: ProfileIcon,
      iconProps: {
        viewBox: "0 0 22 22"
      }
    },
    "tenant-management": {
      sectionName: "Workspace Management",
      backButtonPath: null,
      getMenuIcon: ProfileIcon,
      iconProps: {
        viewBox: "0 0 22 22"
      }
    },
    "view-data": {
      sectionName: "",
      backButtonPath: "/projects",
      getMenuIcon: ProjectsIcon
    },
    logs: {
      sectionName: "Logs",
      backButtonPath: null,
      getMenuIcon: LogsIcon
    },
    "recipe-run-logs": {
      sectionName: "Projects",
      backButtonPath: "/projects",
      getMenuIcon: ProjectsIcon
    },
    scenarios: {
      sectionName: "Projects",
      backButtonPath: "/projects",
      getMenuIcon: ProjectsIcon
    },
    "dataapps-ui": {
      sectionName: "DataApps",
      backButtonPath: "/dataapps-ui",
      getMenuIcon: DataappsIcon
    },
    dataapp: {
      sectionName: "DataApps",
      backButtonPath: "/dataapps-ui",
      getMenuIcon: DataappsIcon,
      shouldIgnoreSlashIcon: true
    },
    "artifacts-and-models": {
      sectionName: "Artifacts & Models",
      backButtonPath: "/artifacts-and-models/artifacts",
      getMenuIcon: ArtifactsAndModelsIcon
    }
  };

  // @TODO: Below approach to render nested screen name need to be revamped with better approach.
  const getSection = (section: $TSFixMe): $TSFixMe => {
    let filteredSection = topbarConfig[section];

    Object.keys(topbarConfig).filter((e: $TSFixMe) => {
      const index = topbarConfig[e]?.children
        ?.map((c: $TSFixMe) => c.name)
        ?.findIndex((eachChild: $TSFixMe) => eachChild === section);

      if (index > -1) {
        filteredSection = topbarConfig[e];
        filteredSection["sectionName"] = topbarConfig[e]?.children?.[index]?.displayName;
      }
    });

    return filteredSection;
  };

  return getSection(section);
};
