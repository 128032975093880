import { Button, Tooltip, Grid, CircularProgress } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import React, { useState } from "react";
import { Modal } from "src/components/custom";
import { ModalVariants } from "src/components/custom/Modal/Modal";

export const SaveCodeToCurrentBtn = ({
  isSaveInProgress,
  onSave,
  isDisabled
}: {
  isSaveInProgress: boolean;
  onSave: () => void;
  isDisabled: boolean;
}) => {
  const [showSaveConfirmModal, setShowSaveConfirmModal] = useState<boolean>(false);
  return (
    <>
      {showSaveConfirmModal && (
        <Modal
          open
          variant={ModalVariants.Delete}
          title="Confirmation"
          content={[
            `This action will overwrite the current recipe code with the checkpoint code and save the changes`,
            "Are you sure you want to continue?"
          ]}
          submitLabel="Yes"
          cancelLabel="No"
          hideCloseIcon
          onClose={() => {
            setShowSaveConfirmModal(false);
          }}
          onSubmit={onSave}
          isSubmitting={isSaveInProgress}
        />
      )}

      <Tooltip title={"Replaces the current recipe code with this checkpoint code and saves it"}>
        <span>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            disabled={isDisabled}
            data-testid="SaveCodeToCurrentBtn"
            style={{
              padding: "3px 9px",
              border: "1px solid #4646B5",
              display: "flex",
              alignItems: "center",
              gap: "5px",
              flexWrap: "nowrap"
            }}
            onClick={() => setShowSaveConfirmModal(true)}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              style={{
                gap: "5px",
                flexWrap: "nowrap"
              }}>
              <span>Save Code to Current</span>

              {isSaveInProgress ? (
                <CircularProgress color="secondary" size={16} />
              ) : (
                <InfoOutlined style={{ fontSize: "16px", color: "rgba(0, 0, 0, 0.54)" }} />
              )}
            </Grid>
          </Button>
        </span>
      </Tooltip>
    </>
  );
};
