import React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import {
  Grid,
  Paper,
  makeStyles,
  Button,
  Tooltip,
  CircularProgress,
  GridSize
} from "@material-ui/core";

import SubTopNavBarBreadcrumbs from "./SubTopNavBarBreadcrumbs";
import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";

import CommonLoader from "src/components/CommonLoader";
import usePredictionServiceLiveLogs from "../hooks/usePredictionServiceLiveLogs";
import { ToastifyAlert } from "src/components/ToastifyAlert/ToastifyAlert";
import { IS_PS_TEST_LOGS } from "./PredictionServiceLiveLogsDrawer";

export const useStyles = makeStyles({
  logsContainer: {
    // New UX change
    // The value 94px is the height of both the NavBars (TopNavBar 50px + SubTopNavBar 44px).
    height: "calc(100vh - 94px)",
    padding: 16
  }
});

interface IInfo {
  message: string;
  type: "info" | "success" | "warning" | "error" | null;
  size?: GridSize;
}

const PredictionServiceLiveLogs = () => {
  const { id, modelName } = useParams();
  const [searchParams] = useSearchParams();
  const isTesting = searchParams.get(IS_PS_TEST_LOGS) === "true";
  const classes = useStyles();

  const { data, isFetching, isLoading, refetch } = usePredictionServiceLiveLogs(
    id,
    isTesting,
    120000
  );

  const recipeLogs = data?.logs?.logData ?? [];

  const renderInfo = ({ message, type, size = 12 }: IInfo) => {
    return (
      <Grid
        container
        item
        xs={size}
        style={{
          marginTop: 25,
          display: "inline-block"
        }}>
        <ToastifyAlert type={type} message={message} />
      </Grid>
    );
  };

  return (
    <Grid container justifyContent="center">
      {!id ? (
        renderInfo({ message: "Invalid inputs.", type: "warning", size: 8 })
      ) : isLoading ? (
        <CommonLoader size={24} />
      ) : (
        <>
          <SubTopNavBarWrapper
            subTopNavBarLeftSection={{
              component: (
                <SubTopNavBarBreadcrumbs
                  predictionServiceId={id}
                  modelName={modelName}
                  predictionServiceName={data?.logs?.predictionServiceName}
                />
              )
            }}
            subTopNavBarRightSection={{
              component: (
                <>
                  {(data?.show || !isTesting) && (
                    <Tooltip title="Retrieves the most recent logs">
                      <Button
                        variant="outlined"
                        size="small"
                        disabled={isFetching}
                        startIcon={isFetching ? <CircularProgress size={16} /> : undefined}
                        onClick={() => refetch()}>
                        Refresh
                      </Button>
                    </Tooltip>
                  )}
                </>
              )
            }}
          />

          <Grid container className={classes.logsContainer}>
            {recipeLogs && recipeLogs?.length !== 0 ? (
              <Grid container item xs={12}>
                <Paper
                  style={{
                    width: "100%",
                    padding: "20px 25px",
                    backgroundColor: "#1e1e1e",
                    fontFamily: `Menlo, Monaco, "Courier New", monospace`,
                    color: "#d4d4d4",
                    fontSize: 12,
                    overflow: "auto"
                  }}>
                  <ul style={{ listStyle: "none" }}>
                    {recipeLogs.map((log, index) => {
                      return <li key={`log_${index}`}>{log}</li>;
                    })}
                  </ul>
                </Paper>
              </Grid>
            ) : (
              renderInfo({ type: "info", message: "No logs found!" })
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default PredictionServiceLiveLogs;
