import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { Button, CircularProgress, Tooltip } from "@material-ui/core";
import { generatePath, useNavigate, useParams } from "react-router-dom";

import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import SubTopNavBarBreadcrumbs from "./SubTopNavBarBreadcrumbs";

import { ReplayOutlined } from "@material-ui/icons";

import { useProjectContext } from "src/pages/private/ProjectsModule/context/useProjectContext";
import { Recipe } from "src/types";
import usePublishTemplate from "src/hooks/api/transforms/usePublishTemplate";
import { handleResponse } from "services/Apis/Apis.service";
import { WebPaths } from "src/routing/routes";
import useResetNotebook from "src/hooks/api/transforms/useResetNotebook";
import { RevertChangesConfirmModal } from "../AutoMLNotebookContainer/RevertChangesConfirmModal";
import { useQueryClient, UseQueryResult } from "@tanstack/react-query";
import { QUERY_KEY_RECIPE } from "src/hooks/api/transforms/useGetRecipe";
import { ChangeTemplateResponseDto } from "@rapidcanvas/rc-api-core";
import useUnbuiltRecipe from "src/hooks/api/transforms/useUnbuiltRecipe";

interface IProps {
  recipe: Recipe | undefined;
  templateUrlResult: UseQueryResult<ChangeTemplateResponseDto, Error>;
  iframeLoading: boolean;
  setKey: Dispatch<SetStateAction<string>>;
  setIframeLoading: Dispatch<SetStateAction<boolean>>;
  recipeType: "CODE" | "AUTO-ML" | "STANDARD" | "API-CONNECTOR";
  recipeRunconfigId?: string;
}

export const AutoMLNotebookHeader: React.FC<IProps> = (props) => {
  const queryClient = useQueryClient();
  const {
    recipe,
    templateUrlResult: { isFetching, isLoading },
    iframeLoading,
    setKey,
    setIframeLoading,
    recipeType,
    recipeRunconfigId
  } = props;
  const { scenarioId, projectId } = useParams();
  const publishTemplate = usePublishTemplate();
  const resetNotebook = useResetNotebook();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const unbuiltRecipe = useUnbuiltRecipe();

  useEffect(() => {
    if (recipeType === "API-CONNECTOR" || recipeType === "CODE") {
      window.onpopstate = null;
    }
  }, []);

  const canGoback = useMemo(() => history.state && history.state.idx !== 0, [location]);

  const handlePublish = () => {
    if (recipeRunconfigId) {
      publishTemplate.mutate(
        { runConfigId: recipeRunconfigId },
        {
          onSuccess: (data) => {
            if (recipe?.id) {
              unbuiltRecipe.mutate({ groupId: recipe.id });
            }
            queryClient.removeQueries([QUERY_KEY_RECIPE]);
            if (data.success) {
              if (canGoback) {
                navigate(-1);
              } else {
                if (!!project?.id && !!scenarioId && !!recipe?.id)
                  navigate(
                    generatePath(
                      recipeType === "AUTO-ML"
                        ? WebPaths.AutoMLRecipeContainer
                        : recipeType === "CODE"
                          ? WebPaths.CodeRecipeContainer
                          : recipeType === "API-CONNECTOR"
                            ? `${WebPaths.APIConnectorRecipeContainer}/${recipe.id}}`
                            : WebPaths.StandardRecipeDataContainer,
                      {
                        projectId: project?.id,
                        scenarioId,
                        groupId: recipe?.id
                      }
                    ),
                    { replace: true }
                  );
              }
              handleResponse({ successMessage: "Recipe Code is Edited in Notebook" });
            } else {
              handleResponse({ errorMessage: data.error.message ?? "something went wrong!!" });
            }
          }
        }
      );
    }
  };

  const handleCancel = () => {
    if (canGoback) {
      navigate(-1);
    } else {
      if (!!project?.id && !!scenarioId && !!recipe?.id)
        navigate(
          generatePath(
            recipeType === "AUTO-ML"
              ? WebPaths.AutoMLRecipeContainer
              : recipeType === "CODE"
                ? WebPaths.CodeRecipeContainer
                : recipeType === "API-CONNECTOR"
                  ? `${WebPaths.APIConnectorRecipeContainer}/${recipe.id}}`
                  : WebPaths.StandardRecipeDataContainer,
            {
              projectId: project?.id,
              scenarioId,
              groupId: recipe?.id
            }
          ),
          { replace: true }
        );
    }
  };

  const handleReset = () => {
    if (recipeRunconfigId) {
      resetNotebook.mutate(
        { runConfigId: recipeRunconfigId },
        {
          onSuccess: (data) => {
            if (data.success) {
              setOpen(false);
              setKey(Date.now().toString());
              setIframeLoading(true);
            } else {
              handleResponse({ errorMessage: data.error.message ?? "something went wrong!!" });
            }
          }
        }
      );
    }
  };

  const { project } = useProjectContext() || {};

  return (
    <>
      <SubTopNavBarWrapper
        subTopNavBarLeftSection={{
          backNavPath: `/projects/${projectId}/scenario/${scenarioId}/add-auto-ml-recipe/${recipe?.id}`,
          component: (
            <SubTopNavBarBreadcrumbs
              project={project}
              recipeName={recipe?.displayName ?? recipe?.name}
              scenarioId={scenarioId}
              groupId={recipe?.id}
              recipeType={recipeType}
            />
          )
        }}
        subTopNavBarRightSection={{
          component: (
            <>
              {!(isFetching || isLoading || iframeLoading) && (
                <Tooltip title="Reverts changes made to the notebook that haven't been saved back to the recipe yet.">
                  <Button
                    size="small"
                    color="primary"
                    data-testid="revertNotebookChangesButton"
                    startIcon={<ReplayOutlined />}
                    onClick={() => setOpen(true)}>
                    Revert
                  </Button>
                </Tooltip>
              )}
              <Button size="small" color="primary" onClick={handleCancel}>
                Cancel
              </Button>

              {!(isFetching || isLoading || iframeLoading) && (
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  data-testid="saveToRecipeNotebookChangesButton"
                  onClick={handlePublish}
                  disabled={publishTemplate.isLoading || !recipeRunconfigId}
                  startIcon={publishTemplate.isLoading ? <CircularProgress size={20} /> : null}>
                  Save back to Recipe
                </Button>
              )}
              <RevertChangesConfirmModal
                loading={resetNotebook.isLoading}
                open={open}
                handleClose={() => {
                  setOpen(false);
                }}
                handleSubmit={handleReset}
              />
            </>
          )
        }}
      />
    </>
  );
};
