import React from "react";
import { Grid } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import ReactMonacoEditor, { MonacoEditorProps } from "react-monaco-editor";

const MonacoEditor: React.FC<MonacoEditorProps> = (props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    if (!containerRef.current) return;

    const resizeObserver = new ResizeObserver(() => {
      setDimensions({
        width: containerRef.current!.clientWidth,
        height: containerRef.current!.clientHeight
      });
    });

    resizeObserver.observe(containerRef.current);

    return () => resizeObserver.disconnect();
  }, [containerRef.current]);

  return (
    <Grid ref={containerRef} style={{ height: props.height, width: props.width }}>
      <ReactMonacoEditor
        {...props}
        height={dimensions.height > 0 ? dimensions.height : "100%"}
        width={dimensions.width > 0 ? dimensions.width : "100%"}
        theme="vs"
      />
    </Grid>
  );
};

export default MonacoEditor;
