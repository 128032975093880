/* eslint-disable no-unused-vars */
import { getAPIWithRethrow } from "services/Apis/Apis.service";
import { useQuery } from "@tanstack/react-query";

export enum AutoDetectedProblemType {
  BINARY_CLASSIFICATION = "Binary Classification",
  REGRESSION = "Regression",
  MULTICLASS_CLASSIFICATION = "Multiclass Classification",
  UNKNOWN = "Unknown",
  UNRECOGNIZED = "Unrecognized"
}

export const useGetProblemType = ({
  entityId,
  targetCol,
  enabled
}: {
  entityId?: string;
  targetCol: string | null;
  problemType: string | null;
  enabled?: boolean;
}) => {
  return useQuery<keyof typeof AutoDetectedProblemType>({
    queryKey: ["entityId", entityId, "targetCol", targetCol, "get-problem-type"],
    cacheTime: 0,
    queryFn: async () => {
      const response = await getAPIWithRethrow(`/v2/dfs-templates/get-problem-type`, {
        params: { id: entityId, targetColumn: targetCol ? encodeURIComponent(targetCol) : "" }
      });
      return response.problemType;
    },
    enabled: !!entityId && !!targetCol && enabled
  });
};
