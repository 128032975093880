import React from "react";

const DEFAULT_PYTHON_CLASS = "RandomForestModelService";
const DEFAULT_PRE_POST_PROCESS_CODE = `#-----------------Do not edit/delete this section-----------------\n\nfrom utils.dtos.rc_prediction_service import RCPredictionService \nclass ${DEFAULT_PYTHON_CLASS}(RCPredictionService):             \n    def load(self, context):                                     \n        pass    \n\n    def pre_process(self, context, service_input):              \n        import pandas as pd \n#-----------------Do not edit/delete this section-----------------\n\n        #Write your pre-process logic here\n        input_df = pd.DataFrame(service_input)                   \n        return input_df\n#-----------------Do not edit/delete this section----------------- \n\n    def post_process(self, context, output_df):                  \n#-----------------Do not edit/delete this section-----------------  \n\n        #Write your post-process logic here\n        return output_df.to_json()`;
const PS_SUCCESS_SAVE_MESSAGE = "Prediction service saved successfully";
const PS_TEST_SUCCESSFUL_MESSAGE = "Test is successful";
const PS_DELETE_SUCCESSFUL_MESSAGE = "Prediction Service deleted successfully";
const PS_SUCCESS_UPDATE_MESSAGE = "Prediction service updated successfully";
const INCORRECT_PATTERN_MSG =
  "The Name contains invalid characters. It needs to start with alphabet and can only contain alphanumerics and underscores.";
const INCORRECT_LENGTH_MSG = "Name should contain 3 to 64 characters.";
const NOT_BLANK_MESSAGE = "Name cannot be blank.";
const INCORRECT_PRETTIFY_JSON_MSG = "Unable to prettify the JSON. Please provide correct JSON";
const CURL_COMMAND_INFO =
  "This is a sample CURL command. When running this command, ensure you use the authentication bearer token from the current workspace's settings.";

const PSFields = {
  name: {
    id: "name",
    label: "Name"
  },
  description: {
    id: "description",
    label: "Description"
  },
  timeoutInMins: {
    id: "timeout",
    label: "Timeout"
  },
  concurrency: {
    id: "concurrency",
    label: "Concurrency"
  },

  environment: {
    id: "environmentId",
    label: "Environment"
  },
  prePostProcess: {
    id: "prePostProcess",
    label: "Pre-process & Post-process"
  },
  logCalls: {
    id: "logCalls",
    label: " Show Logs"
  },
  endpoint: {
    id: "endpoint",
    label: "Unique Endpoint"
  },
  curlCommand: {
    id: "command",
    label: "Command"
  }
};

const PSInfo = [
  <>
    <b>Prediction Service Overview: </b>
    <div>
      {
        "A Prediction Service allows you to send real-time data to a model and receive immediate results. In RapidCanvas, setting up a Prediction Service will create a unique web address (or endpoint) for you. This endpoint processes the data you send, uses the model to make predictions, and then promptly returns those predictions."
      }
    </div>
  </>,
  <>
    <b>Setting Up Your Prediction Service: </b>
    <div>
      <div>
        <b>Name: </b>
        <span>Begin by giving your service a unique name.</span>
      </div>
      <div>
        <b>Environment: </b>
        <span>Choose a custom environment tailored to your needs.</span>
      </div>
      <div>
        <b>Code Customization: </b>
        <span>
          If needed, you can add pre-processing and post-processing steps with the integrated code
          editor.
        </span>
      </div>
    </div>
  </>,
  <>
    <b>Accessing Your Service: </b>
    <div>
      <div style={{ marginBottom: "8px" }}>{"After you’ve saved your Prediction Service:"}</div>
      <div>{"An exclusive web address (endpoint) is created."}</div>
      <div>
        {
          "A sample command (called a curl command) is provided for you to easily send data and receive predictions."
        }
      </div>
    </div>
  </>,
  <>
    <b>Testing Your Service: </b>
    <div>
      <div style={{ marginBottom: "8px" }}>{"With your Prediction Service ready:"}</div>
      <div>{"Use the panel on the right to try out its prediction features."}</div>
      <div>
        {"You can send data in CSV or JSON format and see the model’s predictions in real-time."}
      </div>
    </div>
  </>
];

export {
  DEFAULT_PRE_POST_PROCESS_CODE,
  DEFAULT_PYTHON_CLASS,
  PSFields,
  PSInfo,
  PS_SUCCESS_SAVE_MESSAGE,
  PS_TEST_SUCCESSFUL_MESSAGE,
  PS_SUCCESS_UPDATE_MESSAGE,
  PS_DELETE_SUCCESSFUL_MESSAGE,
  INCORRECT_LENGTH_MSG,
  INCORRECT_PATTERN_MSG,
  CURL_COMMAND_INFO,
  INCORRECT_PRETTIFY_JSON_MSG,
  NOT_BLANK_MESSAGE
};

export enum PredictionServiceHelperText {
  CanvasDatasetFieldLabel = "Select Dataset",
  PredictionResults = "Prediction Results",
  DownloadAsCSV = "Download as CSV",
  AddToCanvas = "Add to Canvas",
  PredictionResultsOutputDatasetName = "Prediction Results Output Dataset Name"
}
