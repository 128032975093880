import React from "react";

// Packages
import { toLower, indexOf, get, map } from "lodash";

// Open API
import { EnvDto, EnvDtoLaunchStatusEnum } from "@rapidcanvas/rc-api-core";

// Utils
import { snakeCaseToStartCase } from "src/utils/helpers";

import { getEnvironmentUsage } from "services/Apis/wrappers/environments";

export type ITypes = ("running job(s)" | "prediction job(s)" | "active recipe(s)")[];

interface EnvUsage {
  pdCreated: number;
  pdUpdated: number;
  projectCreated: number;
  projectCreator: string;
  projectId: string;
  projectName: string;
  projectRunList: any[];
  projectUpdated: number;
  projectUpdater: string;
  recipeCreated: number;
  recipeUpdated: number;
  runningJobCount: number;
  runningPredictionJobCount: number;
  runningRecipeCount: number;
  type: string;
}
interface IProps {
  environmentType?: string;
  cores: number;
  memInGbs: number;
  diskInGbs: number;
}

export const EnvironmentTypeConfig: React.FC<IProps> = (props) => {
  const { environmentType, cores, memInGbs, diskInGbs } = props;

  return (
    <>
      {!!environmentType
        ? `${snakeCaseToStartCase(environmentType, { toUpperCase: ["cpu"] })} - `
        : ""}
      {`Core: ${cores} | Memory: ${memInGbs} GB | Disk Space: ${diskInGbs} GB`}
    </>
  );
};

export const sortEnvironmentsByDateAndStatus = (environments?: EnvDto[]) => {
  if (!environments) return environments;

  const statusOrder = map(
    [
      EnvDtoLaunchStatusEnum.Launching,
      EnvDtoLaunchStatusEnum.Stopping,
      EnvDtoLaunchStatusEnum.Success
    ],
    toLower
  );

  return [...environments].sort((a, b) => {
    const aStatusIndex = indexOf(statusOrder, toLower(get(a, "launchStatus", "")));
    const bStatusIndex = indexOf(statusOrder, toLower(get(b, "launchStatus", "")));

    // If both have a status in the predefined order, compare by status order
    if (aStatusIndex !== -1 && bStatusIndex !== -1) {
      if (aStatusIndex !== bStatusIndex) return aStatusIndex - bStatusIndex;
    }

    // If only one has a matching status, prioritize it
    else if (aStatusIndex !== -1) return -1;
    else if (bStatusIndex !== -1) return 1;

    // If both are outside the status list or have the same status, sort by updated (latest first)
    return (get(b, "updated", 0) || 0) - (get(a, "updated", 0) || 0);
  });
};

export const getEnvironmentUsageDetails = async (id?: string): Promise<ITypes> => {
  if (!id) {
    return [];
  }

  const usageResponse: EnvUsage[] = await getEnvironmentUsage(id);
  return extractEnvUsageTypes(usageResponse);
};

export const extractEnvUsageTypes = (usageResponse: EnvUsage[]) => {
  const projectType = (usageResponse || [])?.filter(
    (eachEntry: EnvUsage) => eachEntry?.type === "PROJECT"
  );
  const recipeType = (usageResponse || [])?.filter(
    (eachEntry: EnvUsage) => eachEntry?.type === "RECIPE"
  );

  const areRunningJobs = projectType?.some((eachEntry: EnvUsage) => eachEntry?.runningJobCount > 0);
  const areRunningPredictionJobs = projectType?.some(
    (eachEntry: EnvUsage) => eachEntry?.runningPredictionJobCount > 0
  );
  const areRunningRecipes = recipeType?.some(
    (eachEntry: EnvUsage) => eachEntry?.runningRecipeCount > 0
  );

  const types: ITypes = [];

  if (areRunningJobs) {
    types.push("running job(s)");
  }
  if (areRunningPredictionJobs) {
    types.push("prediction job(s)");
  }
  if (areRunningRecipes) {
    types.push("active recipe(s)");
  }
  return types;
};
