import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { useSearchParams } from "react-router-dom";
import { filter, get, map, uniq } from "lodash";

import CommonLoader from "src/components/CommonLoader";
import CreateDataAppButton from "./CreateDataApp/CreateDataAppButton";
import ReactException from "src/components/Errors/ReactException";
import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import useGetDataApps from "../../../../hooks/api/dataapps/useGetDataApps";
import { DataAppType } from "../../DataApps.type";
import { DataAppsView } from "../DataAppsView";
import { DeleteDataAppModal } from "../DeleteDataAppModal";
import { listViewPages, PagesViewConfiguration } from "constants/common.constants";
import { useDebouncedSearchHandler } from "src/pages/Dashboard/Dashboard";
import { useGetRole } from "src/hooks/useGetRole";
import { DataAppDtoLaunchStatusEnum } from "@rapidcanvas/rc-api-core";

const PlatformDataApps = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const querySearchParams = searchParams.get("search");
  const pagesViewConfiguration = JSON.parse(localStorage.getItem(PagesViewConfiguration) || "{}");
  const isTilesView = get(pagesViewConfiguration, listViewPages.DATAAPPS, true);

  const [tilesView, setTilesView] = useState(isTilesView);
  const [dataAppToDelete, setDataAppToDelete] = useState<DataAppType | null>(null);
  const [searchValue, setSearchValue] = useState(querySearchParams ?? "");
  const [selectedProjects, setSelectedProjects] = useState<string[]>([]);

  const { isRoleYieldsDataAppView, isConsumer } = useGetRole();
  const { data, isLoading, isError, refetch } = useGetDataApps();
  const handleSearchParams = useDebouncedSearchHandler(searchParams, setSearchParams);

  useEffect(() => {
    setSelectedProjects(uniq(map(data, (dataApp) => dataApp.projectId)));
  }, [data]);

  const searchedDataApps = useMemo(
    () =>
      filter(data, (dataApp: DataAppType) =>
        (dataApp.displayName || dataApp.name)?.toLowerCase?.().includes?.(searchValue.toLowerCase())
      ),
    [data, searchValue]
  );

  const filteredDataApps = React.useMemo(
    () =>
      selectedProjects.length !== 0
        ? _.orderBy(
            searchedDataApps,
            [
              (item) => (item.launchStatus === DataAppDtoLaunchStatusEnum.Running ? 1 : 0),
              "updatedOn"
            ],
            ["desc", "desc"]
          )?.filter((dataApp: DataAppType) => selectedProjects?.includes(dataApp.projectId))
        : [],
    [searchedDataApps, selectedProjects]
  );

  const handleSearch = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearchValue(e.target.value);
    handleSearchParams(e.target.value);
  };

  const handleDelete = (dataApp: DataAppType) => {
    setDataAppToDelete(dataApp);
  };

  if (isLoading) {
    return <CommonLoader size={40} />;
  }

  if (isError) {
    return <ReactException onClick={refetch} />;
  }

  return (
    <>
      <SubTopNavBarWrapper
        // subTopNavBarLeftSection={{
        //   backNavAction: null
        // }}
        subTopNavBarRightSection={{
          component: !isRoleYieldsDataAppView && !isConsumer && <CreateDataAppButton />
        }}
      />
      <DataAppsView
        allDataApps={data}
        searchValue={searchValue}
        dataApps={filteredDataApps}
        onDelete={handleDelete}
        setIsTilesView={setTilesView}
        isTilesView={tilesView}
        onSearch={handleSearch}
        isProjectDataApps={false}
        searchedDataApps={searchedDataApps}
        selectedProjects={selectedProjects}
        onClearSearch={() => setSearchValue("")}
        setSelectedProjects={setSelectedProjects}
      />
      {dataAppToDelete && (
        <DeleteDataAppModal
          projectId={dataAppToDelete?.projectId}
          dataAppId={dataAppToDelete?.id}
          dataAppName={dataAppToDelete?.displayName || dataAppToDelete?.name}
          open={!!dataAppToDelete?.id}
          onClose={() => setDataAppToDelete(null)}
        />
      )}
    </>
  );
};

export default PlatformDataApps;
