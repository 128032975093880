import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Button, Grid, IconButton, Typography } from "@material-ui/core";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { Dropdown } from "../../components";

import styles from "./VariablesList.module.scss";

import AddIcon from "@material-ui/icons/Add";

import { VariablesTable } from "./components/VariablesTable";

import { useDrawerStore, useProjectsStore, useVariablesStore } from "../../stores/zustand/stores";
import shallow from "zustand/shallow";
import { sideComponentSetter } from "../../stores/zustand/stores.selectors";
import { getVariables } from "../../services/Apis/wrappers";
import VariablesForm from "./components/VariablesForm";
import { WebPaths } from "src/routing/routes";

const VariablesList = () => {
  const navigate = useNavigate();
  const { projectId } = useParams<$TSFixMe>();
  const [variables, setVariables] = useState([]);
  const [areVariablesLoading, setAreVariablesLoading] = useVariablesStore(
    useCallback((state) => [state.areVariablesLoading, state.setAreVariablesLoading], []),
    shallow
  );
  const projects = useProjectsStore((state) => state.projectList);
  const variablesReloadTrigger = useVariablesStore((state) => state.variablesReloadTrigger);

  const setSideComponent = useDrawerStore(sideComponentSetter);

  useEffect(() => {
    const fetchVariables = async () => {
      setAreVariablesLoading(true);
      const responseData = await getVariables(projectId);
      setVariables(responseData);
      setAreVariablesLoading(false);
    };
    fetchVariables();
  }, [projectId, variablesReloadTrigger, setAreVariablesLoading]);

  const navigateToProjects = () => navigate(WebPaths.Projects);

  const handleAddVariable = useCallback(() => {
    setSideComponent({
      sideComponent: () => <VariablesForm projectId={projectId} selectedVariable={{}} />,
      sideComponentProps: {
        title: "New Variable",
        anchor: "left",
        drawerPanelClassName: styles.drawerPanel
      }
    });
  }, [setSideComponent, projectId]);

  const project = useMemo(() => {
    return projects.find((project) => (project as $TSFixMe).id === projectId);
  }, [projects, projectId]);

  return (
    <>
      <Grid
        container
        item
        xs={12}
        style={{
          flexWrap: "nowrap",
          padding: "0 20px",
          height: "80px",
          backgroundColor: "#fff",
          borderBottom: "1px solid #eee"
        }}>
        <Grid
          item
          container
          xs={4}
          style={{
            display: "flex",
            flexFlow: "row nowrap",
            alignItems: "center",
            justifyContent: "flex-start"
          }}>
          <div>
            <IconButton
              color="primary"
              onClick={navigateToProjects}
              aria-label="Navigate to projects">
              <NavigateBeforeIcon />
            </IconButton>
          </div>
          <Grid container direction="column">
            <Typography className={styles.contentHeaderLabel} variant="button">
              Project
            </Typography>
            <Typography className={styles.contentHeaderName} variant="h5">
              {(project as $TSFixMe)?.name || ""}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={8} container style={{ justifyContent: "flex-end", alignItems: "center" }}>
          <Button onClick={handleAddVariable} className={styles.headerButton} color="primary">
            <AddIcon />
            ADD VARIABLES
          </Button>
          <Dropdown
            onChange={(event) => {
              navigate(event.target.value);
            }}
            // @ts-expect-error TS(2322) FIXME: Type '{ onChange: (event: any) => void; required: ... Remove this comment to see the full error message
            required={false}
            value={`/variables/${projectId}`}
            options={[
              { label: "View: Project Variables", value: `/variables/${projectId}` },
              { label: "View: Scenarios", value: `/scenarios/${projectId}` },
              { label: "View: Canvas", value: `/projects/${projectId}` }
            ]}
            style={{ width: "30%", margin: "0" }}
          />
        </Grid>
      </Grid>
      <div className={styles.variablesTableContainer}>
        <VariablesTable isLoading={areVariablesLoading} data={variables} projectId={projectId} />
      </div>
    </>
  );
};

export default VariablesList;
