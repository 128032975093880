import React from "react";
import _ from "lodash";
import { FormControl } from "@material-ui/core";
import {
  CreateDataAppRequestDtoDataAppTypeEnum,
  DataappAskAIConfigInputTypeEnum
} from "@rapidcanvas/rc-api-core";
import { Select } from "src/components";
import { askAITypesInfoList } from "src/pages/DataApps/utils/DataApps.constants";
import { dataAppConfigFields } from "./CreateDataAppForm";

interface IProps {
  readOnly?: boolean;
  disabledAddDataAppActionMessage?: string;
  value: DataappAskAIConfigInputTypeEnum;
  onChange?: (
    e: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>
  ) => void;
}

const SelectAskAIInputType: React.FC<IProps> = (props) => {
  return (
    <FormControl style={{ minWidth: "400px" }} fullWidth>
      <Select
        required
        values={[
          ..._.map(askAITypesInfoList, ({ label, value }) => ({ label, value })),
          {
            label: "Model",
            value: CreateDataAppRequestDtoDataAppTypeEnum.RapidModel,
            disabled: !!props.disabledAddDataAppActionMessage || props.readOnly,
            helpText: props.disabledAddDataAppActionMessage
          }
        ]}
        disabled={props.readOnly}
        data-testid="dataAppaskAITypesInfoList"
        name={dataAppConfigFields.inputType}
        variant="outlined"
        label="Input Type"
        value={props.value}
        onChange={props.onChange}
        inputProps={{
          "aria-label": dataAppConfigFields.inputType,
          name: dataAppConfigFields.inputType
        }}
      />
    </FormControl>
  );
};

export default SelectAskAIInputType;
