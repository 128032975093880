import { useCallback, useRef } from "react";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import api from "services/AxiosClient/AxiosClient";

type OnMessageCallback<T> = (newMessage: T) => void;
type OnErrorCallback = (error: AxiosError) => void;
type OnSuccessCallback<T> = (lastMessage: T) => void;
type onMessageCondition<T> = (lastMessage: T) => boolean;

type UseSSEOptions<T> = {
  url: string;
  body: Record<string, unknown> | string;
  headers?: Record<string, string>;
  onMessage?: OnMessageCallback<T>;
  onSuccess?: OnSuccessCallback<T>;
  onError?: OnErrorCallback;
  onSuccessCondition?: onMessageCondition<T>;
};

export const useSSEMutation = <T>() => {
  const dataRef = useRef<T | null>(null);
  const abortControllerRef = useRef<AbortController | null>(null);
  const token = api.getToken();

  const mutationFn = useCallback(
    async ({
      url,
      body,
      headers = {},
      onMessage,
      onSuccessCondition,
      onSuccess,
      onError
    }: UseSSEOptions<T>) => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      const controller = new AbortController();
      abortControllerRef.current = controller;
      const { signal } = controller;
      try {
        await fetchEventSource(url, {
          method: "POST",
          signal,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            ...headers
          },
          body: JSON.stringify(body),
          onmessage: (event) => {
            if (event.data === "[DONE]") {
              dataRef.current && onSuccess?.(dataRef.current);
              return;
            }
            const newMessage: T = JSON.parse(event.data);
            if (onSuccessCondition?.(newMessage)) {
              dataRef.current = newMessage;
            }
            onMessage?.(newMessage);
          },
          onclose: () => {
            // Do nothing to avoid retries on close
          },
          onopen: async (response) => {
            if (!response.ok) {
              throw new Error(response.statusText);
            }
          },
          openWhenHidden: true,
          onerror: (error: any) => {
            if (error) {
              const wrapperError = error.error || error;
              wrapperError && onError?.(wrapperError);
              // Throw error to avoid retries
              throw error;
            } else {
              throw new Error("Something went wrong");
            }
          }
        });
      } finally {
        abortControllerRef.current = null;
      }

      return dataRef.current;
    },
    []
  );
  return useMutation({
    mutationFn
  });
};
