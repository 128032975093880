/* eslint-disable no-unused-vars */
import { useCallback, useEffect } from "react";

import { useRunTransformGroupMutation } from "src/hooks/api";
import { handleResponse } from "services/Apis/Apis.service";
import { Recipe } from "src/types";
import { checkEnvRelaunch } from "environmentsModule/utils/environmentRelaunch.helpers";
import { useParams } from "react-router";
import { useTransformErrorUtils } from "./hooks/useTransformErrorUtils";
import { useCanvasStore } from "stores/zustand/stores";
import { usePollRecipe } from "src/hooks/api/transforms/usePollRecipe";
import { RecipeStatuses } from "projectsModule/utils/Projects.constants";

type RecipeRunProps = {
  recipe: Recipe | null;
  onSuccess?: () => void;
  onError?: () => void;
};

export const useRecipeRun = (
  isRecipeRunning: boolean,
  recipeId: string | undefined,
  onSuccess?: () => void,
  onError?: () => void
) => {
  const { projectId, scenarioId } = useParams<$TSFixMe>();
  const runTransformGroupMutation = useRunTransformGroupMutation();
  const { fetchAndRenderFullErrorLogs } = useTransformErrorUtils();
  const { data, isFetched, isSuccess, isError, error } = usePollRecipe({
    enabled: !!(recipeId && isRecipeRunning),
    recipeId,
    scenarioId
  });

  const handleSuccess = () => {
    onSuccess ? onSuccess() : handleResponse({ successMessage: `Run successful` });
    setReloadTriggerWithDelay();
  };

  const handleError = (err: any) => {
    onError?.();
    fetchAndRenderFullErrorLogs(err, { projectId, scenarioId, groupId: recipeId });
  };

  useEffect(() => {
    if (isSuccess && data?.status === RecipeStatuses.Success) {
      handleSuccess();
    } else if (isSuccess && data?.status === RecipeStatuses.Error) {
      handleError(error);
    }
  }, [data, isSuccess, error]);

  useEffect(() => {
    isError && handleError(error);
  }, [error]);

  const setReloadTriggerWithDelay = useCanvasStore((state) => state.setReloadTriggerWithDelay);

  const onRecipeRun = useCallback(
    async ({ recipe }: RecipeRunProps) => {
      if (!recipe?.id || !scenarioId) {
        return;
      }
      projectId && checkEnvRelaunch(projectId);

      let timeoutId: $TSFixMe;

      runTransformGroupMutation.mutate(
        { recipeId: recipe?.id, scenarioId },
        {
          onSuccess: handleSuccess,
          onError: handleError
        }
      );

      return () => clearTimeout(timeoutId);
    },
    [
      fetchAndRenderFullErrorLogs,
      projectId,
      runTransformGroupMutation,
      scenarioId,
      setReloadTriggerWithDelay
    ]
  );

  return {
    onRecipeRun,
    isRunInProgress: isFetched
      ? data?.status === RecipeStatuses.Running
      : isRecipeRunning || runTransformGroupMutation.isLoading
  };
};
