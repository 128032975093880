import React, { useState } from "react";

import CreateProject from "src/pages/Projects/CreateProject/CreateProject";
import DataAppProjectsModal from "./DataAppProjectsModal";
import NewThemeWrapper from "src/styles/NewThemeWrapper";
import AddDataAppButton from "../../DataAppsHeader/AddDataAppButton";

const CreateDataAppButton: React.FC = () => {
  const [type, setType] = useState<"auto-create" | "import-zip" | null>(null);
  const [openCreateProject, setOpenCreateProject] = useState(false);

  const handleCreateProject = () => {
    setType(null);
    setOpenCreateProject(true);
  };

  return (
    <>
      <AddDataAppButton onClick={setType} />
      {!!type && (
        <DataAppProjectsModal
          open={!!type}
          type={type}
          onClose={() => setType(null)}
          onOpenCreateProjects={handleCreateProject}
        />
      )}
      {openCreateProject && (
        <NewThemeWrapper>
          <CreateProject open={openCreateProject} onClose={() => setOpenCreateProject(false)} />
        </NewThemeWrapper>
      )}
    </>
  );
};

export default CreateDataAppButton;
