import _ from "lodash";
import { toast } from "react-toastify";

import {
  PublishAppTemplateRequestDtoAppTypeEnum,
  PublishAppTemplateRequestDtoSourceEnum,
  SignedUrlRequestDtoSignedUrlObjectTypeEnum
} from "@rapidcanvas/rc-api-core";
import { useMutation, UseMutationResult } from "@tanstack/react-query";

import api from "services/AxiosClient/AxiosClient";

interface IVariable {
  name: string;
  source: PublishAppTemplateRequestDtoSourceEnum;
  appType: PublishAppTemplateRequestDtoAppTypeEnum;
}
const useDownloadAppTemplate = (): UseMutationResult<null, unknown, IVariable, unknown> => {
  return useMutation({
    mutationFn: async ({ appType, name, source }) => {
      const toastId = toast.info("Downloading...");

      const data = await api.fetchResponse(
        async () =>
          await api.SignedUrlControllerApi.downloadFileUrl({
            fileName: `${name}.zip`,
            signedUrlObjectType:
              appType === PublishAppTemplateRequestDtoAppTypeEnum.Streamlit
                ? SignedUrlRequestDtoSignedUrlObjectTypeEnum.AppTemplateStreamlit
                : SignedUrlRequestDtoSignedUrlObjectTypeEnum.AppTemplateReactjs,
            metadata: {
              appType: _.lowerCase(appType),
              SOURCE: source
            }
          })
      );
      if (data.signedUrl) {
        const a = document.createElement("a");
        a.setAttribute("href", data.signedUrl);
        a.setAttribute("download", name);
        a.click();
      }

      toast.update(toastId, {
        render: "Downloaded Successfully",
        type: "success",
        isLoading: false,
        autoClose: 5000
      });

      return null;
    }
  });
};

export default useDownloadAppTemplate;
