import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

import { putAPI } from "services/Apis/Apis.service";

const QUERY_KEY_DATAAPPS_RESOURCE_USAGE = "query-key-dataapp-resource-usage";

const useDataAppResourceUsage = (
  dataappId?: string,
  options?: UseQueryOptions<null>
): UseQueryResult<null> => {
  return useQuery({
    queryKey: [QUERY_KEY_DATAAPPS_RESOURCE_USAGE, dataappId],
    queryFn: async () => {
      return await putAPI(`/dataapps/${dataappId}/record-usage`, { dataappId }, true);
    },
    refetchInterval: 60000,
    refetchIntervalInBackground: true,
    ...options
  });
};

export default useDataAppResourceUsage;
