/* eslint-disable no-unused-vars */
import { CodeCheckpointDto } from "@rapidcanvas/rc-api-core";
import { useQuery } from "@tanstack/react-query";
import api from "services/AxiosClient/AxiosClient";

export const CODE_CHECKPOINTS_KEY = "codeCheckpoints";
export const useGetCodeCheckpoints = ({
  recipeId,
  enabled = true
}: {
  enabled?: boolean;
  recipeId: string | undefined;
}) => {
  return useQuery<CodeCheckpointDto[]>({
    queryKey: ["recipe", recipeId, CODE_CHECKPOINTS_KEY],
    queryFn: async () => {
      return await api.fetchResponse(() =>
        api.CodeCheckpointControllerApi.allCurrentUserCheckpoints(recipeId!)
      );
    },
    enabled: !!recipeId && enabled,
    cacheTime: 0
  });
};
