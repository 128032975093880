export enum ViewFileConfig {
  MaxFileSize = 50,
  MaxFileSizeExceededError = "maxFileSizeExceededError"
}

export enum ViewFileHelperText {
  Delete = "Delete",

  Project = "Project",
  File = "File",

  Download = "Download",
  Downloading = "Downloading..."
}
