import React, { lazy, Suspense } from "react";

// Packages
import { Route, Routes } from "react-router-dom";

// Contexts
import PrivateRouteContextProvider from "src/routing/PrivateRoute/context/PrivateRouteContextProvider";

// Components
const PrivateRouteWrapper = lazy(() => import("src/routing/PrivateRoute/PrivateRouteWrapper"));
const PublicRouteWrapper = lazy(() => import("src/routing/PublicRoute/PublicRouteWrapper"));
import { SentryProvider } from "src/AppProviders";
import { WebPaths } from "./routing/routes";
import CommonLoader from "./components/CommonLoader";

const AppRoutes = () => {
  return (
    <Suspense fallback={<CommonLoader />}>
      <Routes>
        <Route
          path={`${WebPaths.AuthPath}/*`}
          element={
            <SentryProvider>
              <PublicRouteWrapper />
            </SentryProvider>
          }
        />
        <Route
          path="*"
          element={
            <PrivateRouteContextProvider>
              <PrivateRouteWrapper />
            </PrivateRouteContextProvider>
          }
        />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
