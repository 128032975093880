import React from "react";
import { makeStyles, Tooltip } from "@material-ui/core";

import JsonEditor from "src/components/editor/JsonEditor";
import { dataAppConfigFields } from "./CreateDataAppForm";
import { InfoOutlined } from "@material-ui/icons";

interface IProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const useStyles = makeStyles({
  editor: {
    width: "100% !important",
    borderRadius: "6px",
    border: "1px solid rgba(0, 0, 0, 0.2)",
    height: "300px !important",

    "& .ace_print-margin": {
      visibility: "hidden !important"
    }
  }
});

const DataAppMetaData: React.FC<IProps> = (props) => {
  const { value, onChange } = props;
  const classes = useStyles();

  const handleChange = (value: string) => {
    onChange({
      target: { value, name: dataAppConfigFields.metadata }
    } as React.ChangeEvent<HTMLInputElement>);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
      <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
        <span>Metadata</span>
        <Tooltip title="Certain default keys in this data app's metadata(like name and display_name ) are non-editable and cannot be deleted">
          <InfoOutlined color="disabled" fontSize="small" />
        </Tooltip>
      </div>
      <JsonEditor
        placeholder="Copy paste your metadata JSON here"
        jsonString={value}
        className={classes.editor}
        onChange={handleChange}
      />
    </div>
  );
};

export default DataAppMetaData;
