import React, { useCallback, useMemo, useState } from "react";
import _, { get } from "lodash";
import { Button, CircularProgress, makeStyles } from "@material-ui/core";
import {
  unstable_BlockerFunction as BlockerFunction,
  useNavigate,
  useParams
} from "react-router-dom";

import EditDataAppTabs from "./EditDataAppTabs";
import {
  CreateDataAppRequestDtoDataAppTypeEnum,
  DataappAskAIConfigInputTypeEnum,
  DataAppDto,
  DataAppDtoDataAppTypeEnum,
  PublishAppTemplateRequestDtoSourceEnum,
  UpdateDataAppRequestDtoPythonVersionEnum
} from "@rapidcanvas/rc-api-core";
import { dataAppConfigFields } from "../CreateDataApp/CreateDataAppForm";
import { useForm } from "hooks/useForm";
import { handleResponse } from "services/Apis/Apis.service";
import { useUpdateDataAppMutation } from "src/hooks/api";
import useUpdateDataAppWithZip from "hooks/api/dataapps/UseUpdateDataAppWithZip";
import { validateDataAppEnvConfig } from "../CreateDataApp/DataAppEnvironment";
import {
  DescriptionCharacterLimit,
  DescriptionCharacterLimitMessage
} from "projectsModule/utils/Projects.constants";
import Modal, { ModalVariants } from "components/custom/Modal/Modal";
import RouteBlocker from "components/RouteBlocker/RouteBlocker";
import useDataAppStatus from "hooks/api/dataapps/useDataAppStatus";
import { EditDataAppHeaderWrap } from "./EditDataAppHeaderWrap";

const useStyles = makeStyles({
  wrapper: {
    padding: "16px 16px 0px 16px"
  }
});

interface IProps {
  dataApp: DataAppDto;
  onSuccess: () => void;
}

const EditDataApp: React.FC<IProps> = (props) => {
  const { dataApp, onSuccess } = props;
  const { askAIConfig, dataAppType } = dataApp;

  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();
  const [dirty, setDirty] = useState(false);

  const isProjectDataApps = !!get(params, "projectId");

  const updateDataAppMutation = useUpdateDataAppMutation();
  const updateDataAppWithZip = useUpdateDataAppWithZip();
  const [showOnlyChatPopup, setShowOnlyChatPopup] = useState(false);
  const { currentStatusInfo, status, loading } = useDataAppStatus(
    dataApp.id!,
    dataApp.launchStatus
  );

  const { values, handleInputChange, setValues } = useForm({
    [dataAppConfigFields.dataAppName]: dataApp.displayName || dataApp.name,
    [dataAppConfigFields.description]: dataApp.description,
    [dataAppConfigFields.customEnvId]: dataApp.askAIConfig?.customEnvId,
    [dataAppConfigFields.llmType]: askAIConfig?.llmType,
    [dataAppConfigFields.inputType]: askAIConfig?.inputType,
    [dataAppConfigFields.dataSharingStrategy]: askAIConfig?.dataSharingStrategy,
    [dataAppConfigFields.enableCache]: askAIConfig?.enableCache,
    [dataAppConfigFields.systemMessage]: askAIConfig?.systemMessage,
    [dataAppConfigFields.isPrivate]: dataApp.isPrivate,
    [dataAppConfigFields.projectRunId]: askAIConfig?.projectRunId,
    [dataAppConfigFields.appType]: dataApp.appType,
    [dataAppConfigFields.dataAppPermissions]: dataApp.askAIConfig?.dataAppPermissions ?? [],
    [dataAppConfigFields.pythonVersion]:
      dataApp.pythonVersion ?? UpdateDataAppRequestDtoPythonVersionEnum._38,
    [dataAppConfigFields.zipEnv]: {
      name: dataApp.envType,
      cores: dataApp.cores,
      diskInGbs: dataApp.diskInGbs,
      memInGbs: dataApp.memInMbs ? dataApp.memInMbs / 1024 : undefined
    },
    [dataAppConfigFields.imageBase64]: dataApp.iconUrl,
    [dataAppConfigFields.metadata]: dataApp.metadata ? JSON.stringify(dataApp.metadata) : "",
    [dataAppConfigFields.allowColumnHyperLink]: askAIConfig?.allowColumnHyperLink || false,
    [dataAppConfigFields.suggestionPrompts]: askAIConfig?.suggestionPrompts || [],
    [dataAppConfigFields.columnsHyperlinksMapping]:
      dataApp.metadata?.columns_hyperlinks_mapping || ""
  });

  const shouldBlock = useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) =>
      currentLocation.pathname !== nextLocation.pathname && dirty,
    [dirty]
  );

  const [hyperlinkError, setHyperlinkError] = useState<string>("");

  const isInvalid = useMemo(() => {
    return dataAppType === CreateDataAppRequestDtoDataAppTypeEnum.Custom
      ? !!validateDataAppEnvConfig(
          "Cores",
          _.get(values, [dataAppConfigFields.zipEnv, "cores"]),
          12
        ) ||
          !!validateDataAppEnvConfig(
            "Memory",
            _.get(values, [dataAppConfigFields.zipEnv, "memInGbs"]),
            48
          ) ||
          !!validateDataAppEnvConfig(
            "Disk Space",
            _.get(values, [dataAppConfigFields.zipEnv, "diskInGbs"]),
            100
          )
      : false;
  }, [dataAppType, values]);

  const errorMsgs = useMemo(() => {
    return {
      [dataAppConfigFields.description]:
        _.size(_.get(values, dataAppConfigFields.description)) > DescriptionCharacterLimit
          ? DescriptionCharacterLimitMessage
          : ""
    };
  }, [values]);

  const getAskAIConfigValues = () => {
    const withoutPrivate = _.omit(values, dataAppConfigFields.isPrivate);

    switch (_.get(values, dataAppConfigFields.inputType)) {
      case DataappAskAIConfigInputTypeEnum.PredictionService:
        return _.pick(withoutPrivate, [
          dataAppConfigFields.inputType,
          dataAppConfigFields.systemMessage,
          dataAppConfigFields.suggestionPrompts,
          dataAppConfigFields.dataAppPermissions
        ]);

      case DataappAskAIConfigInputTypeEnum.RagFiles:
        return _.omit(withoutPrivate, dataAppConfigFields.dataSharingStrategy);

      default:
        return withoutPrivate;
    }
  };

  const handleUpdate = (newVal?: string, onlyChat?: boolean) => {
    if (!dataApp.id) {
      return;
    }

    let md = _.get(values, dataAppConfigFields.metadata);
    let metadata: any;
    const hyperlinksStr = dataAppConfigFields.allowColumnHyperLink
      ? _.get(values, dataAppConfigFields.columnsHyperlinksMapping)
      : "";
    const payload = {
      ...dataApp,
      displayName: newVal ?? _.get(values, dataAppConfigFields.dataAppName),
      description: _.get(values, dataAppConfigFields.description),
      iconUrl: _.get(values, dataAppConfigFields.imageBase64),
      onlyChat,
      askAIConfig: {
        ...dataApp.askAIConfig,
        ...getAskAIConfigValues(),
        customEnvId: _.get(values, dataAppConfigFields.customEnvId),
        allowColumnHyperLink: _.get(values, dataAppConfigFields.allowColumnHyperLink)
      },
      metadata: _.merge(
        {},
        metadata,
        hyperlinksStr ? { columns_hyperlinks_mapping: hyperlinksStr } : {}
      ),
      cores: _.toNumber(_.get(values, [dataAppConfigFields.zipEnv, "cores"])),
      diskInGbs: _.toNumber(_.get(values, [dataAppConfigFields.zipEnv, "diskInGbs"])),
      memInMbs: _.toNumber(_.get(values, [dataAppConfigFields.zipEnv, "memInGbs"])) * 1024,
      envType: _.get(values, [dataAppConfigFields.zipEnv, "name"]),
      pythonVersion: _.get(values, dataAppConfigFields.pythonVersion),
      isPrivate: _.includes([true, "true"], _.get(values, dataAppConfigFields.isPrivate))
    };

    if (dataAppType === CreateDataAppRequestDtoDataAppTypeEnum.Custom) {
      if (md) {
        try {
          metadata = JSON.parse(md);
        } catch {
          handleResponse({ errorMessage: "Unable to parse JSON metadata. Please use valid JSON" });
          return;
        }
      }
      metadata = _.merge(
        {},
        metadata,
        hyperlinksStr ? { columns_hyperlinks_mapping: hyperlinksStr } : {}
      );
      const newZipFile = _.get(values, dataAppConfigFields.zipFile);
      if (newZipFile) {
        updateDataAppWithZip.mutate(
          {
            payload: {
              ...payload,
              metadata
            },
            templateId: dataApp.appTemplateId!,
            id: dataApp.id,
            source: PublishAppTemplateRequestDtoSourceEnum.Tenant,
            appType: _.get(values, dataAppConfigFields.appType),
            file: _.get(values, [dataAppConfigFields.zipFile, "file"])
          },
          {
            onSuccess: (data) => {
              setValues((prev) => ({
                ...prev,
                [dataAppConfigFields.dataAppName]: data.displayName
              }));
              handleResponse({ successMessage: "DataApp updated successfully." });
              onSuccess();
              setDirty(false);
            }
          }
        );
        return;
      }
    }

    updateDataAppMutation.mutate(
      {
        id: dataApp.id,
        payload: {
          ...payload,
          metadata: _.merge(
            {},
            metadata,
            hyperlinksStr ? { columns_hyperlinks_mapping: hyperlinksStr } : {}
          )
        }
      },
      {
        onSuccess: () => {
          if (showOnlyChatPopup) {
            setShowOnlyChatPopup(false);
          }
          handleResponse({ successMessage: "DataApp updated successfully." });
          onSuccess();
          setDirty(false);
        }
      }
    );
  };

  const handleClick = () => {
    if (dataAppType === DataAppDtoDataAppTypeEnum.Askai) {
      if (
        dataApp.onlyChat &&
        !_.isEqual(
          dataApp.askAIConfig?.dataAppPermissions,
          _.get(values, dataAppConfigFields.dataAppPermissions)
        )
      ) {
        setShowOnlyChatPopup(true);
      } else {
        handleUpdate();
      }
    } else {
      handleUpdate();
    }
  };

  const handlChange = (e: any) => {
    handleInputChange(e);
    setDirty(true);
  };

  const setFormValues = (newValues: Record<string, any>, name: string) => {
    setValues(newValues);

    if (!_.isEqual(_.get(values, name), _.get(newValues, name))) {
      setDirty(true);
    }
  };

  const handleSave = (newVal: string) => {
    setFormValues(
      { ...values, [dataAppConfigFields.dataAppName]: newVal },
      dataAppConfigFields.dataAppName
    );
    handleUpdate(newVal);
  };

  return (
    <div className={classes.wrapper}>
      <EditDataAppHeaderWrap
        dataApp={dataApp}
        isProjectDataApps={isProjectDataApps}
        currentStatusInfo={currentStatusInfo}
        status={status}
        loading={loading}
        onSave={handleSave}
        extra={
          <>
            <Button variant="outlined" color="primary" size="small" onClick={() => navigate(-1)}>
              Cancel
            </Button>
            <Button
              size="small"
              variant="contained"
              disabled={
                _.size(_.get(values, dataAppConfigFields.description)) >
                  DescriptionCharacterLimit ||
                isInvalid ||
                !!hyperlinkError ||
                updateDataAppMutation.isLoading ||
                updateDataAppWithZip.isLoading
              }
              startIcon={
                updateDataAppMutation.isLoading || updateDataAppWithZip.isLoading ? (
                  <CircularProgress size={16} />
                ) : undefined
              }
              data-testid="createDatAppBtn"
              color="primary"
              onClick={handleClick}>
              Update
            </Button>
          </>
        }
      />
      <EditDataAppTabs
        dataApp={dataApp}
        values={values}
        errorMsgs={errorMsgs}
        onInputChange={handlChange}
        setValues={setFormValues}
        onHyperLinkError={setHyperlinkError}
      />
      {showOnlyChatPopup && (
        <Modal
          open={showOnlyChatPopup}
          variant={ModalVariants.Delete}
          title="Consumer Permission"
          content={[
            "This DataApp has an additional chat-only interface configuration. Proceeding with changes to default consumer permissions will revert this configuration and might impact the existing behaviour of this DataApp",
            "Do you want to continue?"
          ]}
          isSubmitting={updateDataAppMutation.isLoading}
          cancelLabel="No"
          submitLabel="Yes"
          onSubmit={() => handleUpdate(undefined, false)}
          onClose={() => setShowOnlyChatPopup(false)}
        />
      )}
      <RouteBlocker
        shouldReset={!dirty}
        title="Warning"
        content={[
          "You have unsaved changes. If you leave, they will be lost.",
          "Are you sure you want to navigate away?"
        ]}
        shouldBlock={shouldBlock}
      />
    </div>
  );
};

export default EditDataApp;
