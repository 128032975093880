import React, { useMemo, useState } from "react";
import { Link, generatePath, useNavigate } from "react-router-dom";
import clsx from "clsx";

import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

import AccessTimeIcon from "@material-ui/icons/AccessTime";
import PauseIcon from "@material-ui/icons/Pause";
import EjectIcon from "@material-ui/icons/Eject";
import PlayArrow from "@material-ui/icons/PlayArrow";

import { PencilUnderlined } from "src/assets/icons/PencilUnderlined";
import { Delete } from "src/assets/icons/Delete";

import { dateFormat } from "utils/helpers/date.helpers";

import { LinkCellWithMenu } from "src/components/Table/Cells/LinkCellWithMenu/LinkCellWithMenu.component";

import { getJobStatusTemplate, getJobRunStatusBadge } from "../../utils/Jobs.helpers";

import JobRunLogsDrawer from "../JobRunLogs/JobRunLogsDrawer";

import { JobRunStatuses, JobRunTriggerTypes, JobsStatuses } from "../../utils/Jobs.constants";

import useJobActions from "../../hooks/useJobActions";

import { useStyles } from "./JobsTable.styles";
import { WebPaths } from "src/routing/routes";
import { ProjectRunEntryDtoStatusEnum } from "@rapidcanvas/rc-api-core";
import { filter, includes } from "lodash";
import { CellContext, ColumnDef } from "@tanstack/react-table";
import Table from "src/components/custom/TableV8/Table";
import { TData } from "src/components/custom/TableV8/typing";

interface IData {
  jobRunId: string;
  jobId: string;
  name: string;
}

const JobsTable = (props: $TSFixMe) => {
  const {
    projectId,
    jobs,
    onViewOutputOpen,
    onJobRunParametersViewOpen,
    onEditJob,
    onDeleteJob,
    onRunJob,
    prefetchJobQueries,
    hideJobRunCanvas = false,
    hideJobRunParameters = false
  } = props || {};

  const classes = useStyles();
  const [logsData, setLogsData] = useState<IData | null>(null);
  const [isJobRunning, setIsJobRunning] = useState(false);
  const [updatingJobIds, setUpdatingJobIds] = useState<string[]>([]);

  const navigate = useNavigate();

  const onJobPaused = (jobId?: string) => {
    setUpdatingJobIds((prev) => filter(prev, (id) => id !== jobId));
  };

  const onJobResumed = (jobId?: string) => {
    setUpdatingJobIds((prev) => filter(prev, (id) => id !== jobId));
  };

  const { pauseJob, resumeJob } = useJobActions({ onJobPaused, onJobResumed });

  const handleLogsClick = (jobId: string, jobRunId: string, name: string, status: string) => {
    setLogsData({ jobId, jobRunId, name });

    setIsJobRunning(
      includes(
        [
          ProjectRunEntryDtoStatusEnum.ComputeVariables,
          ProjectRunEntryDtoStatusEnum.Created,
          ProjectRunEntryDtoStatusEnum.EntityLoading,
          ProjectRunEntryDtoStatusEnum.RecipeRunning,
          ProjectRunEntryDtoStatusEnum.Started
        ],
        status
      )
    );
  };

  const columns: ColumnDef<TData, any>[] = useMemo(
    () => [
      {
        id: "Scheduler Name",
        accessorKey: "name",
        header: "Scheduler Name",
        maxSize: 180,
        meta: { isTooltip: false },

        cell: ({ row }) => {
          const { id, name, status } = row.original?.dto || {};

          return (
            <LinkCellWithMenu
              linkText={name}
              onClick={() => {
                navigate(
                  generatePath(`${WebPaths.JobRoutes}${WebPaths.JobId}`, {
                    projectId,
                    jobId: id
                  })
                );
                prefetchJobQueries({ jobId: id });
              }}
              menuButtons={[
                {
                  label: "EDIT",
                  icon: <PencilUnderlined />,
                  action: () => projectId && id && onEditJob(id)
                },
                ...(status === JobsStatuses.Active
                  ? [
                      {
                        label: "PAUSE",
                        icon: !!includes(updatingJobIds, id) ? (
                          <CircularProgress size={20} />
                        ) : (
                          <PauseIcon />
                        ),
                        disabled: !!includes(updatingJobIds, id),
                        tooltip: includes(updatingJobIds, id)
                          ? "Scheduler pause action is in progress. Please wait."
                          : "",
                        action: () => {
                          setUpdatingJobIds((prev) => [...prev, id]);
                          pauseJob(row?.original?.dto);
                        }
                      }
                    ]
                  : []),
                ...(status === JobsStatuses.Inactive
                  ? [
                      {
                        label: "RESUME",
                        icon: !!includes(updatingJobIds, id) ? (
                          <CircularProgress size={20} />
                        ) : (
                          <EjectIcon style={{ transform: "rotate(90deg)" }} />
                        ),
                        disabled: !!includes(updatingJobIds, id),
                        tooltip: includes(updatingJobIds, id)
                          ? "Scheduler resume action is in progress. Please wait."
                          : "",
                        action: () => {
                          setUpdatingJobIds((prev) => [...prev, id]);
                          resumeJob(row?.original?.dto);
                        }
                      }
                    ]
                  : []),
                {
                  label: "RUN",
                  icon: <PlayArrow />,
                  action: () => onRunJob(row?.original?.dto)
                },
                {
                  label: "RUN HISTORY",
                  icon: <AccessTimeIcon />,
                  action: () =>
                    navigate(
                      generatePath(`${WebPaths.JobRoutes}${WebPaths.JobRuns}`, {
                        projectId,
                        jobId: id
                      })
                    )
                },
                {
                  label: "DELETE",
                  icon: <Delete />,
                  action: () => id && onDeleteJob(id)
                }
              ]}
            />
          );
        }
      },
      {
        id: "Status",
        accessorKey: "status",
        header: "Status",

        size: 100,
        cell: ({ row }) => {
          const { status } = row.original?.dto || {};

          return <>{status ? getJobStatusTemplate({ job: row?.original?.dto }) : ""}</>;
        }
      },
      {
        id: "Updated on",
        accessorKey: "updated",
        header: "Updated on",

        size: 100,
        cell: ({ row }) => {
          const { updated, created } = row.original?.dto || {};

          return <span>{(dateFormat(updated) || dateFormat(created)) ?? ""}</span>;
        }
      },
      {
        id: "Updated by",
        accessorKey: "updater",
        header: "Updated by",

        cell: ({ row }) => {
          const { updater, creator } = row.original?.dto || {};

          return updater || creator;
        }
      },
      {
        id: "Last Run by",
        accessorKey: "lastRunBy",
        header: "Last Run by",

        size: 100,
        cell: ({ row }) => {
          const { trigger, updater, creator } = row.original?.lastRunEntry || {};

          return trigger === JobRunTriggerTypes.Scheduler
            ? "Scheduler"
            : updater || creator || "N/A";
        }
      },
      {
        id: "Last Run",
        accessorKey: "lastRun",
        header: "Last Run",

        size: 100,
        cell: ({ row }) => {
          const { updated, created } = row.original?.lastRunEntry || {};

          return <span>{(dateFormat(updated) || dateFormat(created)) ?? ""}</span>;
        }
      },
      {
        id: "Last 5 Run Status",
        accessorKey: "lastRunEntries",
        header: "Last 5 Run Status",
        size: 135,
        cell: ({ row }) => {
          const { id: jobId } = row?.original?.dto || {};
          const { lastRunEntries } = row?.original || {};

          return (lastRunEntries || [])?.length === 0 ? (
            "N/A"
          ) : (
            <Grid container>
              {lastRunEntries
                ?.slice(0, 5)
                ?.sort(
                  (a: $TSFixMe, b: $TSFixMe) => (a.updated || a.created) - (b.updated || b.created)
                )
                ?.map((eachLastEntry: $TSFixMe, index: number) => {
                  const tooltipMoreInfo = !!eachLastEntry?.endTime ? (
                    <>
                      <br />
                      On: {dateFormat(eachLastEntry?.endTime)}
                    </>
                  ) : null;

                  const jobRunStatusBadge = getJobRunStatusBadge(
                    eachLastEntry?.status,
                    tooltipMoreInfo
                  );

                  const disabled = !includes(
                    [
                      JobRunStatuses.SuccessWithWarn,
                      JobRunStatuses.Success,
                      JobRunStatuses.TimedOut,
                      JobRunStatuses.RecipeTimedOut,
                      JobRunStatuses.Failure
                    ],
                    eachLastEntry?.status
                  );

                  return !!jobRunStatusBadge && !!projectId && !!jobId && !!eachLastEntry?.id ? (
                    <Tooltip
                      title={
                        !!disabled
                          ? "Kindly wait for the run to complete before visiting the canvas."
                          : ""
                      }>
                      <span>
                        <IconButton
                          size="small"
                          key={`lastRunStatus_${index}`}
                          className={clsx(classes.jobRunStatusBadgeButton, {
                            [classes.disabled]: !!disabled
                          })}
                          disabled={!!disabled}
                          onClick={() =>
                            navigate(
                              generatePath(`${WebPaths.JobRoutes}${WebPaths.JobCanvas}`, {
                                projectId,
                                jobId,
                                jobRunId: eachLastEntry?.id
                              })
                            )
                          }>
                          {jobRunStatusBadge}
                        </IconButton>
                      </span>
                    </Tooltip>
                  ) : (
                    <></>
                  );
                })}
            </Grid>
          );
        }
      },
      {
        id: "Last Run Output",
        accessorKey: "lastRunOutput",
        header: "Last Run Output",
        enableSorting: false,
        size: 85,
        cell: ({ row }) => {
          const { id, status } = row.original?.lastRunEntry || {};

          return !!id ? (
            [
              JobRunStatuses.Success,
              JobRunStatuses.SuccessWithWarn,
              JobRunStatuses.Failure,
              JobRunStatuses.TimedOut,
              JobRunStatuses.RecipeTimedOut
            ].includes(status) ? (
              <button
                className={classes.link}
                onClick={() => onViewOutputOpen(row.original?.dto, row.original?.lastRunEntry)}>
                Output
              </button>
            ) : (
              <Tooltip title="The run is currently in progress. Please check the output once the execution is complete.">
                <span className={classes.linkDisabled}>View</span>
              </Tooltip>
            )
          ) : (
            "N/A"
          );
        }
      },
      {
        id: "Last Run Log",
        accessorKey: "lastRunLog",
        header: "Last Run Log",
        enableSorting: false,
        size: 85,
        cell: ({ row }) => {
          const { id, status } = row.original?.lastRunEntry || {};
          return !!id ? (
            ![JobRunStatuses.Created].includes(status) ? (
              <button
                className={classes.link}
                onClick={() =>
                  handleLogsClick(row.original?.dto?.id, id, row.original?.dto?.name, status)
                }>
                View
              </button>
            ) : (
              <Tooltip title="Kindly wait for the run to start before checking the logs.">
                <span className={classes.linkDisabled}>View</span>
              </Tooltip>
            )
          ) : (
            "N/A"
          );
        }
      },
      ...(!hideJobRunCanvas
        ? [
            {
              id: "Last Run Canvas",
              accessorKey: "lastRunCanvas",
              header: "Last Run Canvas",
              enableSorting: false,
              size: 85,
              cell: ({ row }: CellContext<TData, any>) => {
                const { id: jobId } = row?.original?.dto || {};
                const { id: lastJobRunEntryId, status: lastJobRunEntryStatus } =
                  row.original?.lastRunEntry || {};

                // eslint-disable-next-line no-extra-boolean-cast
                return !!projectId && !!jobId && !!lastJobRunEntryId ? (
                  ![
                    JobRunStatuses.Created,
                    JobRunStatuses.Started,
                    JobRunStatuses.EntityLoading,
                    JobRunStatuses.Running
                  ].includes(lastJobRunEntryStatus) ? (
                    <Link
                      className={classes.link}
                      to={generatePath(`${WebPaths.JobRoutes}${WebPaths.JobCanvas}`, {
                        projectId,
                        jobId,
                        jobRunId: lastJobRunEntryId
                      })}>
                      <Typography variant="subtitle2">Canvas</Typography>
                    </Link>
                  ) : (
                    <Tooltip title="Kindly wait for the run to complete before visiting the canvas.">
                      <span className={classes.linkDisabled}>Canvas</span>
                    </Tooltip>
                  )
                ) : (
                  "N/A"
                );
              }
            }
          ]
        : []),
      ...(!hideJobRunParameters
        ? [
            {
              id: "Last Run Project Variables",
              accessorKey: "lastRunParameters",
              header: "Last Run Project Variables",
              enableSorting: false,
              size: 95,
              cell: ({ row }: CellContext<TData, any>) => {
                const { id, status } = row.original?.lastRunEntry || {};

                return !!id ? (
                  ![
                    JobRunStatuses.Created,
                    JobRunStatuses.Started,
                    JobRunStatuses.EntityLoading,
                    JobRunStatuses.Running
                  ].includes(status) ? (
                    <button
                      className={classes.link}
                      onClick={() => onJobRunParametersViewOpen(row?.original?.lastRunEntry)}>
                      View
                    </button>
                  ) : (
                    <Tooltip title="Kindly wait for the run to get completed.">
                      <span className={classes.linkDisabled}>View</span>
                    </Tooltip>
                  )
                ) : (
                  "N/A"
                );
              }
            }
          ]
        : [])
    ],
    [projectId, updatingJobIds]
  );

  const handleClose = () => {
    setLogsData(null);
  };

  return (
    <>
      <Table
        data={jobs}
        columns={columns}
        disableVirtualization
        stylesProps={{ size: "small" }}
        sortingProps={{ orderByDefault: "Scheduler Name" }}
        columnsProps={{ hiddenColumns: ["Updated on", "Updated by"] }}
      />
      {logsData && (
        <JobRunLogsDrawer
          isJobRunning={isJobRunning}
          name={logsData?.name}
          open={!!logsData}
          projectId={projectId}
          jobId={logsData.jobId}
          jobRunId={logsData?.jobRunId}
          onClose={handleClose}
        />
      )}
    </>
  );
};

export default JobsTable;
