/* eslint-disable no-unused-vars */
import { useQuery } from "@tanstack/react-query";

import { getAPIWithRethrow } from "services/Apis/Apis.service";
import { addTransformGroupWithRethrow } from "services/Apis/wrappers";
import { ArtifactMini, Entity, EntityTypeEnum, ModelMini, Recipe } from "src/types";
import { extractNewRecipeName } from "src/pages/Projects/common/Recipe.utils";

type Output = {
  group: Recipe;
};
export const useCreateRecipe = ({
  inputEntities,
  projectId,
  recipeId,
  onSuccess,
  entity,
  conditions,
  code,
  selectedArtifacts,
  selectedModels,
  newAskAIFlow = false
}: {
  inputEntities: Array<Entity>;

  projectId?: string;
  recipeId: string | undefined;
  entity: Entity | undefined;
  conditions: $TSFixMe;
  code: string;
  selectedArtifacts: ArtifactMini[];
  selectedModels: ModelMini[];
  onSuccess: ({ group }: Output) => void;
  newAskAIFlow?: boolean;
}) => {
  return useQuery<Output>({
    queryKey: ["project", projectId, "allRecipes"],
    queryFn: async () => {
      if (!projectId) {
        throw "Project id is absent";
      }
      const groups: Array<Recipe> = await getAPIWithRethrow(
        `/v2/dfs-run-config-groups?projectId=${projectId}`
      );
      const updatedRecipeName = extractNewRecipeName({ name: entity?.name as string, groups });

      const newArtifacts =
        selectedArtifacts?.map((artifact: $TSFixMe) => ({
          name: artifact.name,
          type: EntityTypeEnum.ARTIFACT
        })) || [];
      const newModels =
        selectedModels?.map((model: $TSFixMe) => ({
          name: model.name,
          type: EntityTypeEnum.MODEL
        })) || [];

      const transformGroupPayload = {
        name: updatedRecipeName,
        displayName: updatedRecipeName,
        parents: [
          ...inputEntities?.map((entity) => ({ id: entity.id, type: "ENTITY" })),
          ...newArtifacts,
          ...newModels
        ],
        condition: {
          expression: conditions
        },
        recipeType: "CODE",
        newAskAIFlow
      };
      const createdGroup = await addTransformGroupWithRethrow(transformGroupPayload, projectId);
      const group = Array.isArray(createdGroup) ? createdGroup[0] : createdGroup;

      return { group };
    },
    onSuccess,
    enabled:
      !recipeId &&
      (inputEntities.length > 0 || selectedArtifacts.length > 0 || selectedModels.length > 0) &&
      !code,
    cacheTime: 0
  });
};
