import React from "react";
import { List } from "@material-ui/core";
import { Language, Code, CallMerge, Assignment } from "@material-ui/icons";

import AddTransformModal from "../AddTransformModal";
import TransformItem from "./transform-list-item";
import TransformModal from "../TransformModal";
import { sideComponentSetter } from "../../../../stores/zustand/stores.selectors";
import { useDrawerStore } from "../../../../stores/zustand/stores";

export default function TransformItemsList({ pipeline, history }: $TSFixMe) {
  const setSideComponent = useDrawerStore(sideComponentSetter);

  const handleCancelAddTransform = () => {
    setSideComponent({
      sideComponent: TransformModal,
      sideComponentProps: { title: "Choose Recipe" }
    });
  };

  const handleAddTransformClick = () => {
    setSideComponent({
      sideComponent: AddTransformModal,
      sideComponentProps: {
        title: "New Transform",
        handleCancelAction: () => handleCancelAddTransform(),
        handleCreateAction: () => null,
        history,
        disabled: true,
        projectId: pipeline?.id
      }
    });
  };

  const transformItems = [
    {
      Icon: Assignment,
      title: "Data preparation",
      handleClick: () => handleAddTransformClick(),
      subTitle: "Manipulate, clean and transform the raw dataset ",
      isActive: true
    },
    {
      Icon: CallMerge,
      title: "Join",
      subTitle: "Merge two datasets into one ",
      isActive: false
    },
    {
      Icon: CallMerge,
      title: "Split",
      subTitle: "Split one dataset into multiple",
      isActive: false
    },
    {
      Icon: Code,
      title: "Code",
      subTitle: "Use your favorite language to manipulate the data",
      isActive: false
    },
    {
      Icon: Language,
      title: "ML Builder",
      subTitle: "Machine Learning lab",
      isActive: false
    }
  ];

  return (
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
      {transformItems.map((item, index) => (
        <TransformItem key={index} {...item} />
      ))}
    </List>
  );
}
