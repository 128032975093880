import React, { useEffect } from "react";
import { unstable_BlockerFunction as BlockerFunction, useBlocker } from "react-router-dom";

import Modal, { ModalVariants } from "components/custom/Modal/Modal";

interface IProps {
  content: React.ReactNode | React.ReactNode[];
  title: string;
  shouldReset: boolean;
  shouldBlock: BlockerFunction;
}

const RouteBlocker: React.FC<IProps> = (props) => {
  const { shouldReset, content, title, shouldBlock } = props;

  const blocker = useBlocker(shouldBlock);

  useEffect(() => {
    if (blocker.state === "blocked" && shouldReset) {
      blocker.reset();
    }
  }, [blocker, shouldReset]);

  if (blocker.state === "blocked") {
    return (
      <Modal
        open={true}
        variant={ModalVariants.Delete}
        title={title}
        content={content}
        onClose={() => blocker.reset?.()}
        onSubmit={() => blocker.proceed?.()}
      />
    );
  }
  return null;
};

export default RouteBlocker;
