import { filter } from "lodash";

export const getDateSegments = (text: string): string[] => {
  // Split the input string by space to isolate the date part
  const [datePart] = text.split(" ");

  // Split the date part by hyphen and filter valid segments
  return filter(datePart.split("_"), Boolean);
};

export const getTimeFormatsByFieldNames = (
  dateFormatMap: Record<string, string>[]
): Record<string, string> =>
  dateFormatMap.reduce((acc, { name, format }) => {
    if (!!name && !!format) {
      const timePart = format.split(" ")[1] || "H:M:S"; // Extract the time portion after the space
      if (timePart) {
        acc[name] = timePart;
      }
    }
    return acc;
  }, {});
