import React from "react";

export const FilterIcon = ({ width = 20, height = 20, color = "#4646b5", fill = "white" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 22"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.93002 1.09998L4.00002 8.99998M3.40002 1.09998H16.6C17.7 1.09998 18.6 1.99998 18.6 3.09998V5.29998C18.6 6.09998 18.1 7.09998 17.6 7.59998L13.3 11.4C12.7 11.9 12.3 12.9 12.3 13.7V18C12.3 18.6 11.9 19.4 11.4 19.7L10 20.6C8.70002 21.4 6.90002 20.5 6.90002 18.9V13.6C6.90002 12.9 6.50002 12 6.10002 11.5L2.30002 7.49998C1.80002 6.99998 1.40002 6.09998 1.40002 5.49998V3.19998C1.40002 1.99998 2.30002 1.09998 3.40002 1.09998Z"
      stroke={`url(#filterGradient_${color.replace("#", "")})`} // Use dynamic gradient ID
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id={`filterGradient_${color.replace("#", "")}`} // Unique gradient ID based on color
        x1="18.6"
        y1="1.09998"
        x2="18.4737"
        y2="21.0053"
        gradientUnits="userSpaceOnUse">
        <stop stopColor={color} />
        <stop offset="1" stopColor={color} />
      </linearGradient>
    </defs>
  </svg>
);
