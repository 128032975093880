import React, { useState, useEffect } from "react";

// MUI
import Container from "@material-ui/core/Container/Container";
import makeStyles from "@material-ui/core/styles/makeStyles";

// Global components
import { Drawer } from "src/components";
import TopNavBar from "src/layout/NavBars/components/TopNavBar/TopNavBar";

// Local components
import PrivateRoute from "./PrivateRoute";

// Styles
import NewThemeWrapper from "src/styles/NewThemeWrapper";

// Context
import { usePrivateRouteContext } from "./context/usePrivateRouteContext";

import { SentryProvider } from "src/AppProviders";
import { AccessControlContextProvider } from "./accessControlContext/AccessControlContextProvider";
import EventBus from "services/EventBus/EventBus";
import { EVENTBUS_EVENTS } from "constants/eventBus.constants";
import AccessDenied from "src/components/Errors/AccessDenied";
import { Button } from "@material-ui/core";
import { WebPaths } from "../routes";

const useStyles = makeStyles(() => ({
  mainContainer: {
    position: "absolute",
    top: 94, // This is to render the main-content below the fixed-positioned TopNavBar & Sub-TopNavBar.
    overflow: "auto"
  }
}));

const PrivateRouteWrapper = () => {
  const { isNavBars } = usePrivateRouteContext() || {};
  const [accessDenied, setAccessDenied] = useState(false);

  useEffect(() => {
    EventBus.subscribe(EVENTBUS_EVENTS.AccessDenied, () => {
      setAccessDenied(true);
    });

    return () => {
      EventBus.unsubscribe(EVENTBUS_EVENTS.AccessDenied);
    };
  });

  const classes = useStyles({ isNavBars });

  const handleReload = () => {
    window.location.href = WebPaths.Dashboard;
  };

  if (accessDenied) {
    return (
      <AccessDenied
        subtitle1="Sorry, you do not have access to this resource."
        extra={
          <Button variant="contained" onClick={handleReload}>
            Reload
          </Button>
        }
      />
    );
  }

  return (
    <>
      {/* Global components - STARTS >> */}
      <Drawer />
      {/* << ENDS - Global components */}

      {isNavBars && (
        <NewThemeWrapper>
          <TopNavBar />
        </NewThemeWrapper>
      )}
      <SentryProvider>
        {/* The <main /> section */}
        <Container
          component="main"
          maxWidth={false}
          disableGutters
          className={classes.mainContainer}>
          <AccessControlContextProvider>
            <PrivateRoute />
          </AccessControlContextProvider>
        </Container>
      </SentryProvider>
    </>
  );
};

export default PrivateRouteWrapper;
