import { useMutation } from "@tanstack/react-query";

import { putAPIWithRethrow } from "services/Apis/Apis.service";

type UpdateCodeHistoryRequest = {
  historyId: string;
  outputNameChanges: { [key: string]: string };
};
export const useUpdateCodeHistoryMutation = () => {
  return useMutation({
    mutationFn: ({
      recipeId,
      request
    }: {
      recipeId: string;
      request: UpdateCodeHistoryRequest;
    }) => {
      return putAPIWithRethrow(`/v2/dfs-run-config-groups/${recipeId}/code-history`, request);
    }
  });
};
