import React, { useMemo, useState } from "react";

// Packages
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { useIsMutating } from "@tanstack/react-query";
import { includes, replace, toLower, toUpper } from "lodash";

// Icons
import { ExportIcon } from "icons/NewUX/ExportIcon";
import { TrashIcon } from "icons/NewUX/TrashIcon";
import CachedIcon from "@material-ui/icons/Cached";

// Open API
import { EntityDetailsDto, EntityDto, EntityDtoStatusEnum } from "@rapidcanvas/rc-api-core";

// Utils
import { WebPaths } from "src/routing/routes";
import { useAccessControlContext } from "src/routing/PrivateRoute/accessControlContext/useAccessControlContext";
import { DatasetWebPathQueryParameters, NodeTypes } from "../../../utils";
import { UseReloadDatasetsQueryKeys } from "src/hooks/api/entities/useReloadDatasets";

// Hooks
import { useGetJob, useGetJobRun, useGetProject, useGetScenario } from "src/hooks/api";
import { useDownloadTextInput } from "src/pages/private/ProjectsModule/hooks";

// Components
import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import SubTopNavBarBreadcrumbs from "../components/SubTopNavBarBreadcrumbs";
import AddMenu from "src/pages/ViewData/AddMenu";
import { ConfirmUpdateTextInput } from "src/pages/private/ProjectsModule/components";
import DeleteNodeModal, { psMessage } from "src/pages/ViewData/DeleteNodeModal/DeleteNodeModal";
import ReloadDatasetModal from "src/pages/ViewData/ViewDataData/ReloadDatasetModal";

// Constants
import fileTypes from "constants/fileTypes.constants";
import { ViewFileHelperText } from "../utils/ViewFile.constants";
import { thirdPartyTypeName } from "src/pages/DataSources/utils/DataSources.constants";

interface IProps {
  isJobPath?: boolean;
  isDefaultScenario?: boolean;
  // @TODO: Type any below should be removed after IEntity in useEntities hook is replaced with EntityDto.
  data?: EntityDto | any;
  // @TODO: Type any below should be removed after IEntityDetails in useEntityDetails hook is replaced with EntityDetailsDto.
  fileDetails?: EntityDetailsDto | any;
  isLoading?: boolean;
}

const Header: React.FC<IProps> = (props) => {
  const { isJobPath = false, isDefaultScenario, data, fileDetails, isLoading = false } = props;

  const navigate = useNavigate();
  const { projectId, scenarioId, fileId, jobId, jobRunId } = useParams() || {};

  const { canAddStandardRecipe } = useAccessControlContext();

  // States - STARTS >>
  const [showConfirmUpdateTextInputModal, setShowConfirmUpdateTextInputModal] = useState(false);
  const [showConfirmReloadTextInputModal, setShowConfirmReloadTextInputModal] = useState(false);
  const [showDeleteConfirmScreen, setShowDeleteConfirmScreen] = useState(false);
  // << ENDS - States

  // Query hooks - STARTS >>
  // Queries
  const { data: project } = useGetProject({
    projectId,
    refetchOnMount: true
  });

  const { data: scenarioData } = useGetScenario({
    scenarioId
  });

  const { data: jobData } = useGetJob({ projectId, jobId });
  const { data: jobRunData } = useGetJobRun({ jobRunId, isApiWithRethrow: false });

  const pendingReloadTextInputsMutations = useIsMutating({
    mutationKey: [UseReloadDatasetsQueryKeys.ReloadDatasets, fileId]
  });

  const isReloadingTextInputs = useMemo(
    () => pendingReloadTextInputsMutations > 0,
    [pendingReloadTextInputsMutations]
  );
  // << ENDS - Query hooks

  const fileTypeLabel = useMemo(() => {
    const fileTypeKey = toLower(fileDetails?.fileExtension) as keyof typeof fileTypes;
    return fileTypes?.[fileTypeKey]?.label;
  }, [fileDetails?.fileExtension]);

  const { download, isDownloading } = useDownloadTextInput({
    projectId,
    isJobCanvas: !!jobRunId,
    jobProps: { jobRunId },
    data: {
      scenarioId,
      datasetId: fileId,
      fileType: toUpper(fileDetails?.fileExtension || ""),
      fileName: data?.displayName || data?.name
    }
  });

  const isBuilt = useMemo(
    () => includes([EntityDtoStatusEnum.Built], data?.status),
    [data?.status]
  );

  const addRapidModelRecipe = () => {
    if (!projectId || !scenarioId || !fileId) {
      return;
    }

    const url = replace(WebPaths.AutoMLRecipeContainer, "/:groupId?", `?entity=${fileId}`);
    const path = generatePath(url, {
      projectId: projectId,
      scenarioId: scenarioId
    });

    navigate(path);
  };

  const addStandardRecipe = () => {
    if (!projectId || !scenarioId || !fileId) {
      return;
    }

    const url = replace(WebPaths.StandardRecipeDataContainer, "/:groupId?", `?entity=${fileId}`);
    const path = generatePath(url, {
      projectId: projectId,
      scenarioId: scenarioId
    });

    navigate(path);
  };

  const addApiConnectorRecipe = () => {
    if (!projectId || !scenarioId || !fileId) {
      return;
    }

    const url = `${WebPaths.APIConnectorRecipeContainer}?entity=${fileId}`;
    navigate(
      generatePath(url, {
        projectId: projectId,
        scenarioId: scenarioId
      })
    );
  };

  // Confirm update text input - STARTS >>
  const updateTextInput = () => {
    if (!projectId || !scenarioId || !fileId) {
      return;
    }

    const url = `${WebPaths.Dataset}/${fileId}?${DatasetWebPathQueryParameters.IsAddFile}=true`;
    navigate(
      generatePath(url, {
        projectId,
        scenarioId
      })
    );
  };

  const promptConfirmUpdateTextInput = () => {
    setShowConfirmUpdateTextInputModal(() => true);
  };

  const resetConfirmUpdateTextInput = () => {
    setShowConfirmUpdateTextInputModal(() => false);
  };

  const confirmUpdateTextInput = () => {
    resetConfirmUpdateTextInput();
    updateTextInput();
  };
  // << ENDS - Confirm update text input

  // Confirm reload text input - STARTS >>
  const handleReload = () => {
    setShowConfirmReloadTextInputModal(true);
  };

  const resetConfirmReloadTextInput = () => {
    setShowConfirmReloadTextInputModal(() => false);
  };
  // << ENDS - Confirm reload text input

  // Delete file - STARTS >>
  const promptConfirmDeleteFile = () => {
    setShowDeleteConfirmScreen(() => true);
  };

  const resetDeleteFile = () => {
    setShowDeleteConfirmScreen(() => false);
  };

  const afterDeleteFile = () => {
    navigate(`/projects/${projectId}/scenario/${scenarioId}/canvas`);
  };
  // << ENDS - Delete file

  const disabledExportActionMessage = useMemo(() => {
    if (!!isLoading) {
      return "Please wait. Fetching required data.";
    }

    return "";
  }, [isLoading]);

  return (
    <>
      {!!showConfirmUpdateTextInputModal && (
        <ConfirmUpdateTextInput
          resetConfirmUpdateTextInput={resetConfirmUpdateTextInput}
          updateTextInput={confirmUpdateTextInput}
        />
      )}

      {showDeleteConfirmScreen && !!scenarioId && (
        <DeleteNodeModal
          open
          nodeId={fileId}
          nodeName={data?.displayName || data?.name}
          nodeType="file"
          deleteNote={
            <div>
              <span>Note: </span>
              {psMessage}
            </div>
          }
          onClose={resetDeleteFile}
          onAfterSubmit={afterDeleteFile}
        />
      )}

      {!!showConfirmReloadTextInputModal && (
        <ReloadDatasetModal
          open
          isTextInput
          id={fileId}
          name={data?.name}
          isFiveTran={data?.dataSourceType === thirdPartyTypeName}
          projectId={projectId}
          onCancel={resetConfirmReloadTextInput}
          onSuccess={resetConfirmReloadTextInput}
        />
      )}

      <SubTopNavBarWrapper
        subTopNavBarLeftSection={{
          // @HACK
          // Passing empty method as there is some limitation at SubTopNavBarWrapper component.
          backNavAction: () => {},
          component: (
            <SubTopNavBarBreadcrumbs
              isJobPath={isJobPath}
              scenarioId={scenarioId}
              projectId={projectId}
              project={project}
              data={data}
              isDefaultScenario={isDefaultScenario}
              scenarioName={scenarioData?.name}
              jobData={jobData}
              jobRunId={jobRunId}
              jobRunName={jobRunData?.entryDto?.runId}
            />
          )
        }}
        subTopNavBarRightSection={{
          moreOptions: [
            {
              label: !!fileTypeLabel ? `Export as ${fileTypeLabel}` : "Export",
              icon: <ExportIcon viewBox="0 0 22 24" />,
              disabled: !!disabledExportActionMessage,
              tooltip: disabledExportActionMessage,
              isLoading: isDownloading,
              action: () => {
                download();
              }
            },
            ...(!isJobPath
              ? [
                  {
                    label: ViewFileHelperText.Delete,
                    icon: <TrashIcon viewBox="0 0 20 20" />,
                    action: () => {
                      promptConfirmDeleteFile();
                    }
                  }
                ]
              : []),
            ...(!!data?.dataSourceId && !isJobPath && isDefaultScenario
              ? [
                  {
                    label: "Reload",
                    icon: <CachedIcon style={{ width: "16px", height: "16px" }} />,
                    isLoading: !!isReloadingTextInputs,
                    isDisabled: !!isReloadingTextInputs,
                    tooltip: !!isReloadingTextInputs
                      ? "Please wait. The reload text input action is in progress."
                      : data?.dataSourceType === thirdPartyTypeName
                        ? "Synchronizes the current text input with latest data in corresponding data connector"
                        : "Synchronizes the current text input with remote storage and retrieves the latest data",
                    action: handleReload
                  }
                ]
              : [])
          ],
          component: isJobPath ? (
            <></>
          ) : (
            <AddMenu
              // Files
              isTextInput={toLower(data?.entityMeta?.entityViewType) === toLower(NodeTypes.File)}
              isAddDatasetHidden={!fileDetails?.rootEntity || !!data?.dataSourceType}
              addDataset={() => {
                if (data?.status === EntityDtoStatusEnum.Unbuilt) {
                  updateTextInput();
                } else {
                  promptConfirmUpdateTextInput();
                }
              }}
              // Recipes
              isUnbuilt={!isBuilt}
              canAddAiAssistedRecipe={false}
              canAddRapidModelRecipe={false}
              canAddStandardRecipe={!!isDefaultScenario && !!canAddStandardRecipe}
              addTemplateRecipe={addStandardRecipe}
              addRapidModelRecipe={addRapidModelRecipe}
              onAddApiConnectorRecipe={addApiConnectorRecipe}
            />
          )
        }}
      />
    </>
  );
};

export default Header;
