import React, { useRef, useState } from "react";
import { Box, Button, Grid, makeStyles } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { v4 as uuidv4 } from "uuid";

import { DfsTemplate } from "src/types";
import { TransformationsList } from "./TransformationsList/TransformationsList";
import TransformItems from "./TransformationsList/TransformItems/TransformItems";
import NewThemeWrapper from "src/styles/NewThemeWrapper";
import { filter } from "lodash";

type Props = {
  isJobPath?: boolean;
  groupId?: string;
  dfsTemplates: Array<DfsTemplate>;
  configGroup?: $TSFixMe;
  appliedTransforms: Array<$TSFixMe>;
  onAppliedTransformChange: $TSFixMeFunction;
  projectId: string | number;
  selectedInputDatasets?: $TSFixMe;
  onDeleteAppliedTransform: $TSFixMe;
  isDefaultScenario: boolean;
  scenarioId?: string;
};

const useStyles = makeStyles({
  gridWrap: {
    flexWrap: "nowrap",
    padding: "20px",
    gap: "18px",
    width: "700px"
  }
});

export const TransformationsView = ({
  isJobPath,
  groupId,
  dfsTemplates,
  configGroup,
  appliedTransforms,
  onAppliedTransformChange,
  projectId,
  scenarioId,
  selectedInputDatasets,
  onDeleteAppliedTransform,
  isDefaultScenario
}: Props) => {
  const classes = useStyles();
  const autocompleteRef = useRef();
  const textFieldRef = useRef(null);

  const [allTransforms, setAllTransforms] = useState<$TSFixMe>(appliedTransforms || []);
  const [isRecipeUpdating, setIsRecipeUpdating] = useState<boolean>(false);
  const [addedTransformIds, setAddedTransformIds] = useState<$TSFixMe>(
    appliedTransforms?.map((transform) => transform.id) || []
  );
  const allowedDfsTemplates = filter(dfsTemplates, (dfsTemplate) => dfsTemplate?.tags?.length > 0);

  const nameIsRepeated = (name: $TSFixMe) => {
    return allTransforms.some(
      (transform: $TSFixMe) => transform.displayName === name || transform.name === name
    );
  };

  const handleSelectedTransformChange = (updatedTransform: $TSFixMe) => {
    let transformName = updatedTransform.name;
    if (nameIsRepeated(transformName)) {
      let i = 2;
      while (nameIsRepeated(`${transformName} ${i}`)) i++;

      transformName = `${transformName} ${i}`;
    }
    setAllTransforms((transforms: $TSFixMe) => [
      ...transforms,
      {
        ...updatedTransform,
        id: uuidv4(),
        templateId: updatedTransform.id,
        name: transformName,
        expanded: true
      }
    ]);
  };

  const onHighlightTransform = () => {
    if (textFieldRef.current && autocompleteRef.current) {
      (textFieldRef.current as $TSFixMe)?.focus?.();
      (autocompleteRef.current as $TSFixMe)?.open?.();
    }
  };

  const onDeleteTransform = async (transformId: string, transformName: string) => {
    setIsRecipeUpdating(true);
    const isValidTransform = addedTransformIds?.some(
      (currTransformId: any) => currTransformId === transformId
    );

    const onSuccess = () => {
      setIsRecipeUpdating(false);
      setAddedTransformIds((acc: any) =>
        acc.filter((currTransformId: any) => currTransformId !== transformId)
      );
      setAllTransforms((acc: any) =>
        acc.filter((currTransform: any) => currTransform.id !== transformId)
      );
    };
    isValidTransform
      ? await onDeleteAppliedTransform({ transformId, transformName, onSuccess })
      : onSuccess();
  };

  const handleTransformNameChange = async (
    updatedTransform: $TSFixMe,
    onSuccess: $TSFixMeFunction
  ) => {
    if (!addedTransformIds?.includes(updatedTransform.id)) {
      setAllTransforms((prevTransforms: $TSFixMe) =>
        prevTransforms.map((currTransform: $TSFixMe) =>
          currTransform.id === updatedTransform.id ? { ...updatedTransform } : currTransform
        )
      );
      onSuccess?.();
      return;
    }
    onAppliedTransformChange({
      transformData: updatedTransform,
      onSuccess: ({ transformResponse }: { transformResponse: $TSFixMe }) => {
        setAllTransforms((allTransforms: $TSFixMe) =>
          allTransforms?.map((currTransform: $TSFixMe) =>
            currTransform?.id === updatedTransform.id
              ? {
                  ...currTransform,
                  displayName: transformResponse.name,
                  id: transformResponse.id,
                  inputValues: transformResponse.variables || {}
                }
              : currTransform
          )
        );
        setAddedTransformIds((transformIds: Array<string>) => [
          ...transformIds,
          transformResponse.id
        ]);
        onSuccess?.();
      }
    });
  };

  const onAddTransform = async ({
    payload,
    onSettled,
    onSuccess
  }: {
    payload: $TSFixMe;
    onSettled: () => void;
    onSuccess: () => void;
  }) => {
    onAppliedTransformChange({
      transformData: payload,
      onSettled: () => {
        onSettled?.();
      },
      onSuccess: ({ transformResponse }: { transformResponse: $TSFixMe }) => {
        setAllTransforms((allTransforms: $TSFixMe) =>
          allTransforms?.map((currTransform: $TSFixMe) =>
            currTransform?.id === payload.id
              ? {
                  ...currTransform,
                  id: transformResponse.id,
                  inputValues: transformResponse.variables || {},
                  expanded: false
                }
              : currTransform
          )
        );
        setAddedTransformIds((transformIds: Array<string>) => [
          ...transformIds,
          transformResponse.id
        ]);
        onSuccess?.();
      }
    });
  };

  return (
    <NewThemeWrapper>
      <Grid container direction="column" className={classes.gridWrap}>
        {!isJobPath && (
          <TransformationsList
            autocompleteRef={autocompleteRef}
            textFieldRef={textFieldRef}
            dfsTemplates={allowedDfsTemplates}
            setSelectedTransform={handleSelectedTransformChange}
            isDefaultScenario={isDefaultScenario}
          />
        )}
        {allTransforms?.map((currentTransform: any) => {
          return (
            <TransformItems
              key={currentTransform.id}
              isJobPath={isJobPath}
              scenarioId={scenarioId}
              appliedTransforms={appliedTransforms}
              groupId={groupId}
              selectedTransform={currentTransform}
              configGroup={configGroup}
              selectedEntities={selectedInputDatasets}
              projectId={projectId}
              isEditing={addedTransformIds?.includes(currentTransform.id)}
              onAddTransform={onAddTransform}
              onDeleteTransform={onDeleteTransform}
              allTransforms={allTransforms}
              onTransformNameChange={handleTransformNameChange}
              setIsRecipeUpdating={setIsRecipeUpdating}
              isRecipeUpdating={isRecipeUpdating}
              isDefaultScenario={isDefaultScenario}
            />
          );
        })}
        {!isJobPath && (
          <Box py="4px">
            <Button
              variant="outlined"
              color="primary"
              startIcon={<Add />}
              onClick={onHighlightTransform}
              disabled={!isDefaultScenario}
              fullWidth
              test-id="standardRecipeAddNewTransformBtn">
              New Transform
            </Button>
          </Box>
        )}
      </Grid>
    </NewThemeWrapper>
  );
};
