/* eslint-disable react/no-unknown-property */
import React, { useCallback, useState } from "react";
import {
  Button,
  ListItemIcon,
  Menu,
  MenuItem,
  MenuProps,
  Tooltip,
  makeStyles
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import styles from "./MoreOptions.module.scss";

export interface IOption {
  id?: string;
  testId?: string;
  tooltip?: string;
  dataTestId?: string;
  icon?: React.JSX.Element;
  label: string | React.JSX.Element;
  click: () => void;
  disabled?: boolean;
  doNotclose?: boolean;
}

interface MoreOptionsProps {
  options?: IOption[];
  customRender?: React.ReactNode;
  menuProps?: Partial<MenuProps>;
  customMenuRender?: React.JSX.Element;
  testId?: string;
  btnProps?: any;
}

const useStyles = makeStyles({
  listItemIcon: {
    color: "#003656 !important",
    minWidth: "28px",
    "& svg": {
      height: "19px"
    }
  },
  button: {
    padding: 0,
    minWidth: 0
  }
});
export const MoreOptions = ({
  options = [],
  testId,
  customRender = null,
  customMenuRender,
  menuProps = {},
  btnProps = {}
}: MoreOptionsProps) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const handleClose = useCallback((e: $TSFixMe) => {
    e?.stopPropagation();
    e?.preventDefault();
    setAnchorEl(null);
  }, []);

  const handleClick = useCallback((e: $TSFixMe) => {
    e.stopPropagation();
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  }, []);

  const getLMenuItem = (option: IOption, index: number) => {
    const handleClickItem = async (e: any) => {
      e.stopPropagation();
      e.preventDefault();
      if (option.doNotclose) {
        await option.click();
      }
      option.click();

      setAnchorEl(null);
    };

    return (
      <MenuItem
        data-testid={option.dataTestId}
        key={index}
        test-id={option?.id || option?.testId || `dropdownMenu${index}`}
        onClick={handleClickItem}
        disabled={option?.disabled}>
        {option.icon && <ListItemIcon className={classes.listItemIcon}>{option.icon}</ListItemIcon>}
        {option.label}
      </MenuItem>
    );
  };

  return (
    <>
      <Button
        onClick={handleClick}
        className={styles.tableHeaderOptionsButton}
        classes={{ root: classes.button }}
        data-testid={testId}
        {...btnProps}>
        <MoreVertIcon />
      </Button>
      {customRender || (
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          {...menuProps}>
          {customMenuRender
            ? React.cloneElement(customMenuRender, {
                onClose: handleClose,
                ...customMenuRender.props
              })
            : options.map((option, index: number) => {
                return option.tooltip ? (
                  <Tooltip key={index} arrow title={option.tooltip}>
                    <span>{getLMenuItem(option, index)}</span>
                  </Tooltip>
                ) : (
                  getLMenuItem(option, index)
                );
              })}
        </Menu>
      )}
    </>
  );
};
