import React from "react";
import { Box, makeStyles, Theme } from "@material-ui/core";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

import CopilotMessageCodeBlock from "./CopilotMessageCodeBlock";

interface StyleProps {
  isLoading: boolean;
}
const useStyles = makeStyles<Theme, StyleProps>(() => ({
  markdownStyles: {
    fontSize: "14px",
    lineHeight: "20px",
    overflow: "auto",
    width: "100%",
    "& ul": {
      display: "block",
      marginBlockStart: "1em",
      marginBlockEnd: "1em",
      marginInlineStart: "0px",
      marginInlineEnd: "0px",
      paddingInlineStart: "40px",
      marginTop: 0,
      marginBottom: "10px"
    },
    "& ol": {
      display: "block",
      listStyleType: "decimal",
      marginBlockStart: "1em",
      marginBlockEnd: "1em",
      marginInlineStart: "0px",
      marginInlineEnd: "0px",
      paddingInlineStart: "40px",
      marginTop: "0",
      marginBottom: " 10px"
    },
    "& ul li": {
      listStylePosition: "inside",

      "& p": {
        display: "inline",
        margin: 0
      }
    },
    "& ol li": {
      listStylePosition: "inside",

      "& p": {
        display: "inline",
        margin: 0
      }
    },
    "& code": {
      padding: "2px 4px",
      fontSize: "12px",
      fontFamily: 'Menlo, Monaco, "Courier New", monospace',
      color: "#c7254e",
      backgroundColor: "#F6F6F6",
      borderRadius: "4px",
      cursor: "pointer"
    },
    "& pre": {
      display: "block",
      margin: "0 0 10px",
      fontSize: "12px",
      lineHeight: "1.42857143",
      wordBreak: "break-word",
      wordWrap: "break-word",
      width: "95%",
      backgroundColor: ({ isLoading }) => (isLoading ? "#fff" : "#f5f5f5"),
      border: "1px solid #ccc",
      padding: ({ isLoading }) => (isLoading ? "4px" : "0px"),
      borderRadius: "4px",
      position: "relative",
      cursor: "pointer"
    },
    "& pre code": {
      padding: "0",
      fontSize: "inherit",
      color: "inherit",
      whiteSpace: "pre-wrap",
      backgroundColor: "transparent",
      borderRadius: "0"
    },
    "& table > thead > tr > th": {
      verticalAlign: "bottom",
      borderBottom: "2px solid #ddd",
      padding: "8px"
    },
    "& table > tbody > tr:nth-child(odd) > td": {
      backgroundColor: "#f9f9f9"
    },
    "& table, th, td": {
      border: "1px solid #a3a9ac",
      borderCollapse: "collapse"
    },

    "& table > thead > tr > td": {
      padding: "8px",
      verticalAlign: "middle",
      borderTop: "1px solid #ddd",
      textAlign: "center"
    },
    "& table > tbody > tr > td": {
      padding: "8px",
      verticalAlign: "middle",
      borderTop: "1px solid #ddd",
      textAlign: "center"
    },
    "& table > tfoot > tr > td": {
      padding: "8px",
      verticalAlign: "middle",
      borderTop: "1px solid #ddd",
      textAlign: "center"
    },
    "& img": {
      maxWidth: "35%"
    },
    "& h1, h2, h3, h4, h5, h6": {
      fontSize: "1.125rem",
      lineHeight: 1.6,
      marginBottom: "8px",
      marginTop: "8px"
    }
  },
  copyCode: {
    position: "absolute",
    top: "4px",
    right: "4px",
    zIndex: 1
  }
}));

const CopilotMarkdown = ({ string, isLoading }: { string: string; isLoading: boolean }) => {
  const classes = useStyles({ isLoading });

  return (
    <Box className={classes.markdownStyles}>
      <Markdown
        remarkPlugins={[remarkGfm]}
        components={{
          code({ inline, children }) {
            return inline ? (
              <code>{String(children)}</code>
            ) : (
              <CopilotMessageCodeBlock isLoading={isLoading}>
                {String(children)}
              </CopilotMessageCodeBlock>
            );
          }
        }}>
        {string}
      </Markdown>
    </Box>
  );
};

export default React.memo(CopilotMarkdown);
