import React, { useMemo, useState } from "react";
import { Button, Grid, makeStyles } from "@material-ui/core";
import _ from "lodash";

import { Tab, TabPanel, Tabs } from "src/components";
import NewModal from "components/Modal/NewModal";
import NoDataFoundDefault from "src/pages/common/NoDataFoundDefault";
import SomethingWentWrong from "src/components/Errors/SomethingWentWrong";
import useGetAppTemplates from "src/hooks/api/dataapps/useGetAppTemplates";
import {
  AppTemplateDtoAppTypeEnum,
  PublishAppTemplateRequestDtoSourceEnum
} from "@rapidcanvas/rc-api-core";
import { Spinner } from "src/components";
import useDownloadAppTemplate from "src/hooks/api/dataapps/useDownloadAppTemplate";
import { CodeRecipeCodeEditor } from "src/pages/Projects/AddCodeRecipe/CodeRecipeTabContainer/CodeRecipeCodeEditor/CodeRecipeCodeEditor";
import SyntaxList from "src/pages/Projects/AddApiConnectorRecipe/ApiConnectorRecipeTabContainer/SyntaxList";

const STREAMLIT_SYNTAX = {
  Syntax: [
    {
      title: "Pull entity data from canvas",
      tooltip: "Pull entity data from canvas",
      code: `from utils.notebookhelpers.helpers import da_helper

df = da_helper.get_entity_data(name=entity_name)`
    },
    {
      title: "Pull artifact data",
      tooltip: "Pull artifact data",
      code: `from utils.notebookhelpers.helpers import da_helper

Option 1
# Let's say file is a csv file
filepath = da_helper.get_artifact(artifact_name, filename_in_artifact)
df = pd.read_csv(filepath)

Option 2
# Let's say file is a csv file
Artifact.download_file(artifact_name, file_name, folder=folder_name)
df = pd.read_csv(folder_name + "/" + file_name)`
    },
    {
      title: "Pull canvas chart in dataApp",
      tooltip: "Pull canvas chart in dataApp",
      code: `from utils.notebookhelpers.helpers import da_helper

da_helper.display_chart(project_id, chart_name, st, job_id, job_entry_id)`
    },
    {
      title: "Embed AskAI DataApp as an Iframe",
      tooltip: "Embed AskAI DataApp as an Iframe",
      code: `# "Example AskAI DataApp"
ask_ai_app = Dataapp.find_by_name(ask_ai_app_name)

st.header("Setup")
st.markdown("""
1. From Project > DataApps > +
2. Create Data App with name Example AskAI DataApp
3. Use Project Datasets as inputs
4. Create and launch app
5. Add titanic dataset as input and ask one dummy question (for e.g. count of records)
""")

app_url = "https://staging.dev.rapidcanvas.net/dataapps/" + ask_ai_app.slug + "/?dlId=" + quote(ask_ai_app.dl_id) + "&token=" + Requests.getToken()

components.iframe(
    src=app_url,
    height=800,
    scrolling=True
)`
    },
    {
      title: "Pull data from job",
      tooltip: "Pull data from job",
      code: `project = Project.getAllProjects()["project_name"]
dataset = project.getAllDatasets()["entity_name"]
job = ProjectRun.find_project_run_by_name(project.id, "job_name")
project_run_entries = job.find_all_project_run_entries()

job_entry = get_last_successful_project_run_entry(project_run_entries)

data = dataset.getData(project_run_entry_id=job_entry['id'])`
    },
    {
      title: "Invoke Prediction Service from DataApp",
      tooltip: "Invoke Prediction Service from DataApp",
      code: `request = "{\"query\": \"test\"}"
response = PredictionService.predict_by_service(
    "ps_name",
    request
)`
    },
    {
      title: "Download RCModel and execute it locally",
      tooltip: "Download RCModel and execute it locally",
      code: `model = da_helper.get_rc_ml_model("model_name")
request = "{\"query\": \"test\"}"
response = model.predict(request)`
    },

    {
      title: "Access App Metadata and Project variables",
      tooltip: "Access App Metadata and Project variables",
      code: `project_name = da_helper.get_metadata()['project_nane']
project = Project.getAllProjects()[project_name]
gvs = GlobalVariable.get_all(project.id)`
    }
  ]
};
const useStyles = makeStyles({
  flex: {
    marginLeft: "24px",
    marginTop: "10px",
    minHeight: "300px",
    display: "flex",
    flexDirection: "column",
    gap: "10px"
  },
  flexDiv: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column"
  },
  gridItem: {
    width: "300px",
    maxHeight: "calc(100vh - 200px)",
    overflow: "auto",
    paddingRight: "8px",
    borderRight: "1px solid #dedada",
    scrollbarWidth: "none",
    "-ms-overflow-style": "none",

    "&::-webkit-scrollbar": {
      display: "none"
    }
  }
});

interface IProps {
  open: boolean;
  onClose: () => void;
}

interface ISelected {
  title: string;
  key: string;
  code: string;
  tooltip: string;
}

enum TABS {
  SampleStreamlit = "Sample Streamlit",
  SampleReact = "Sample React JS",
  SampleSyntaxStreamLit = "Sample Syntax Streamlit"
}

const SampleDataAppZipModal: React.FC<IProps> = (props) => {
  const { open, onClose } = props;
  const [tab, setTab] = useState(TABS.SampleStreamlit);
  const [selected, setSelected] = useState<ISelected>({
    ..._.get(STREAMLIT_SYNTAX, "Syntax")[0],
    key: "Pull entity data from canvas"
  });

  const codeEditorRef: $TSFixMe = React.useRef(null);
  const download = useDownloadAppTemplate();
  const classes = useStyles();

  const { data, isLoading, isError } = useGetAppTemplates();

  const { streamlitTemplates, reactTemplates } = useMemo(() => {
    const sampleTemplates = _.filter(data, { sample: true });

    return {
      streamlitTemplates: _.filter(
        sampleTemplates,
        (val) =>
          val.appType === AppTemplateDtoAppTypeEnum.Streamlit &&
          val.name !== "sample_sales_dashboard"
      ),
      reactTemplates: _.filter(sampleTemplates, {
        appType: AppTemplateDtoAppTypeEnum.Reactjs
      })
    };
  }, [data]);

  const handleClose = () => {
    onClose();
  };

  const handleClick = (appType: AppTemplateDtoAppTypeEnum, name?: string) => {
    if (name) {
      download.mutate({
        name,
        source: PublishAppTemplateRequestDtoSourceEnum.System,
        appType
      });
    }
  };

  const handleChange = (newVal: ISelected) => {
    setSelected(newVal);
  };

  const getComponent = () => {
    if (isError) {
      return <SomethingWentWrong heading="Error in fetching sample zip files" />;
    }

    if (isLoading) {
      return <Spinner />;
    }

    return (
      <>
        <Tabs value={tab} onChange={setTab} aria-label="basic tabs example">
          {_.map(TABS, (tab) => (
            <Tab key={tab} value={tab} label={tab} />
          ))}
        </Tabs>
        <TabPanel key={TABS.SampleReact} value={tab} index={TABS.SampleReact}>
          {_.isEmpty(reactTemplates) ? (
            <NoDataFoundDefault title={"No sample React JS Zip Files available"} subTitle={""} />
          ) : (
            <div className={classes.flex}>
              {_.map(reactTemplates, (temp) => (
                <div key={temp.id} className={classes.flexDiv}>
                  <li>
                    <Button
                      style={{ padding: 0 }}
                      color="primary"
                      onClick={() => handleClick(AppTemplateDtoAppTypeEnum.Reactjs, temp.name)}>
                      {temp.displayName ?? temp.name}
                    </Button>
                  </li>
                </div>
              ))}
            </div>
          )}
        </TabPanel>
        <TabPanel key={TABS.SampleStreamlit} value={tab} index={TABS.SampleStreamlit}>
          {_.isEmpty(streamlitTemplates) ? (
            <NoDataFoundDefault title={"No sample Streamlit Zip Files available"} subTitle={""} />
          ) : (
            <div className={classes.flex}>
              {_.map(streamlitTemplates, (temp) => (
                <div key={temp.id} className={classes.flexDiv}>
                  <li>
                    <Button
                      style={{ padding: 0 }}
                      color="primary"
                      onClick={() => handleClick(AppTemplateDtoAppTypeEnum.Streamlit, temp.name)}>
                      {temp.displayName ?? temp.name}
                    </Button>
                  </li>
                </div>
              ))}
            </div>
          )}
        </TabPanel>
        <TabPanel key={TABS.SampleSyntaxStreamLit} value={tab} index={TABS.SampleSyntaxStreamLit}>
          <Grid container>
            <Grid item xs="auto" className={classes.gridItem}>
              <SyntaxList
                value={selected.title}
                onChange={handleChange}
                syntax={STREAMLIT_SYNTAX}
              />
            </Grid>
            <Grid
              item
              xs
              style={{ height: "calc(100vh - 200px)", overflowX: "hidden", overflowY: "auto" }}>
              <CodeRecipeCodeEditor
                showConstant
                editorRef={codeEditorRef}
                editorValue={selected.code}
                setEditorValue={(val: string) => setSelected((prev) => ({ ...prev, code: val }))}
              />
            </Grid>
          </Grid>
        </TabPanel>
      </>
    );
  };

  return (
    <NewModal
      header="Sample"
      open={open}
      data-testid="dataAppSampleZips"
      keepMounted={false}
      width="55%"
      background="#fff"
      hideFooter
      onClose={handleClose}>
      <div>{getComponent()}</div>
    </NewModal>
  );
};

export default SampleDataAppZipModal;
