import dayjs from "dayjs";
import { get, isEmpty, join, toNumber } from "lodash";
import parser from "cron-parser";

import { ProjectRunDto } from "@rapidcanvas/rc-api-core";

import { IProjectReturn } from "hooks/api/projects/useRunDetails";

export const dateFormat = (
  date: $TSFixMe,
  isEpochMilliSec = true,
  dateFormat = "YYYY-MM-DD HH:mm:ss"
) => {
  if (!isEpochMilliSec) {
    if (date && date !== 0) {
      // Mechanism to detect if timestamp is already in seconds
      return date < 631152000000
        ? dayjs.unix(date).format(dateFormat)
        : dayjs.unix(date / 1000).format(dateFormat);
    }
    return "N/A";
  }
  if (typeof date === "string") {
    return date?.length !== 0 ? dayjs(date).format(dateFormat) : "N/A";
  }
  return date && date !== 0 ? dayjs.unix(date / 1000).format(dateFormat) : "N/A";
};

export const dateDiffFromNow = (date1: number, isEpochMilliSec = true, unit?: $TSFixMe) => {
  const dayjs1 = date1 ? (isEpochMilliSec ? dayjs.unix(date1 / 1000) : dayjs.unix(date1)) : null;
  if (!dayjs1) {
    return null;
  }
  return dayjs(dayjs1).diff(Date.now(), unit);
};

export const timestampsDiff = (fromTimestamp: number, toTimestamp: number) => {
  let delta = Math.abs(toTimestamp - fromTimestamp) / 1000; // delta

  // Uncommented config properties will be considered.
  // Add a config if require.
  const config: $TSFixMe = {
    // year: 31536000,
    // month: 2592000,
    // week: 604800,
    day: 86400,
    hour: 3600,
    minute: 60,
    second: 1
  };

  const diffMap: $TSFixMe = {}; // result
  Object.keys(config)?.forEach((key: $TSFixMe) => {
    diffMap[key] = Math.floor(delta / config[key]);
    delta -= diffMap[key] * config[key];
  });

  // Example: { year: 0, month: 0, week: 1, day: 2, hour: 34, minute: 56, second: 7 };
  return diffMap;
};

export const nextRunTimestamp = (job?: ProjectRunDto | IProjectReturn) => {
  if (isEmpty(job)) return;

  const scheduleInfo = job?.scheduleInfo;
  const schedule = job?.schedule;

  try {
    let cronExpression = schedule;

    if (isEmpty(schedule) && !isEmpty(scheduleInfo)) {
      // Construct cron expression from scheduleInfo
      const frequency = get(scheduleInfo, "cronConfig.frequency");
      const hr = toNumber(get(scheduleInfo, "cronConfig.hr", "0"));
      const min = toNumber(get(scheduleInfo, "cronConfig.min", "0"));
      const days = get(scheduleInfo, "cronConfig.days", []);

      switch (frequency) {
        case "daily":
          cronExpression = `${min} ${hr} * * *`;
          break;
        case "weekly":
          cronExpression = days.length
            ? `${min} ${hr} * * ${join(days, ",")}`
            : `${min} ${hr} * * 0`; // Default to Sunday if no days are provided
          break;
        default:
          return "-";
      }
    }

    if (!cronExpression) return "-";

    // Parse cron expression and compute next run time
    const interval = parser.parse(cronExpression);
    return interval.next().getTime();
  } catch (error) {
    console.error("Error computing next run time:", error);
    return "-";
  }
};
