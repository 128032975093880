import React, { useEffect, useMemo, useRef, useState } from "react";

import NewModal from "src/components/Modal/NewModal";
import {
  Box,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Tooltip,
  Typography
} from "@material-ui/core";
import _, { delay, filter, isEmpty } from "lodash";
import { SendSharp } from "@material-ui/icons";
import QuerySpinner from "../../AddCodeRecipe/CodeRecipeTabContainer/AskAIContainer/GenerateCodeBar/QuerySpinner";
import useCreateChatThreadForChartEdit from "src/hooks/api/aiguide/useCreateChatThreadForChartEdit";
import useEditChartChatMutation from "src/hooks/api/codeRecipe/useEditChartChatMutation";
import { useParams } from "react-router";
import { AIChatRequestDtoOutputTypeEnum, AIChatResponseDto } from "@rapidcanvas/rc-api-core";
import { useDeleteAIGuideThread } from "src/hooks/api/aiguide/useDeleteAIGuideThread";
import EditChartConversation from "../charts/EditChart/EditChartConversation";
import AskAILoaderItem from "../../AddCodeRecipe/CodeRecipeTabContainer/AskAIContainer/AskAILoaderItem";
import OriginalChart from "../charts/EditChart/OriginalChart";
import Modal, { ModalVariants } from "src/components/custom/Modal/Modal";
import { STOPPED_QUERY_ID } from "./GenerateCodeBar";
import { SuggestionPrompts } from "./AIGuidePromptSuggestions/SuggestionPrompts";
import CommonLoader from "src/components/CommonLoader";

interface IProps {
  open: boolean;
  onClose: () => void;
  chartJson: any;
  name: string;
  askAIMessageId: string | undefined;
  codeName?: string;
  parentThreadId: string | undefined;
}

const suggestion = ["Change color theme", "Update chart title", "Change this to a line chart"];

const useStyles = makeStyles({
  input: {
    height: "100%",
    borderRadius: "24px",
    background: "#fff"
  },
  notchedOutline: {
    borderLeftWidth: "0px"
  },
  root: {
    height: "51px",
    "& .MuiOutlinedInput-notchedOutline": {
      borderLeftWidth: "0px"
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderLeftWidth: "1px !important"
    }
  },
  helpText: {
    color: "rgba(0,0,0,0.6)",
    fontSize: "12px",
    lineHeight: 1.54,
    padding: "4px 16px"
  }
});

const EditChartModal: React.FC<IProps> = ({
  open,
  onClose,
  chartJson,
  name,
  askAIMessageId,
  codeName,
  parentThreadId
}) => {
  const classes = useStyles();
  const [query, setQuery] = useState("");
  const textFieldRef = useRef<HTMLTextAreaElement>(null);
  const [userInput, setUserInput] = useState("");
  const { scenarioId, projectId } = useParams();
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const [isExpanded, setIsExpanded] = useState(true);
  const [confirm, setConfirm] = useState(false);
  const [conversation, setConversation] = useState<AIChatResponseDto[]>([]);
  const deleteThread = useDeleteAIGuideThread();
  const isStopped = useRef<boolean>(false);
  const controllerRef = useRef<AbortController | null>(null);

  const threadResult = useCreateChatThreadForChartEdit(
    {
      flowType: "RECIPE",
      context: {
        projectId,
        scenarioId
      },
      target: {
        targetType: "CHART",
        targetId: askAIMessageId,
        chartName: codeName,
        chartId: name
      },
      threadName: `${name}chatThread`
    },
    { refetchOnMount: true }
  );

  const chat = useEditChartChatMutation();

  const handleClose = () => {
    if (_.isEmpty(conversation)) {
      if (threadResult.data?.threadId && projectId) {
        if (chat.isLoading) {
          controllerRef.current?.abort();
        }
        deleteThread.mutate({ threadId: threadResult.data?.threadId ?? "", projectId });
      }
      onClose();
    } else {
      setConfirm(true);
    }
  };
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "auto" });
  };

  const disabled = useMemo(
    () => chat.isLoading || isEmpty(query.trim()) || threadResult.isFetching,
    [chat.isLoading, query, threadResult.isFetching]
  );

  useEffect(() => {
    delay(() => scrollToBottom(), 1000);
  }, [chat.isSuccess, chat.isLoading]);

  const handleGenerateResponse = (suggestion?: string) => {
    if (threadResult.data?.threadId && askAIMessageId) {
      setQuery("");
      const newController = new AbortController();
      controllerRef.current = newController;

      setUserInput(suggestion ? suggestion : query);
      chat.mutate(
        {
          threadId: threadResult.data?.threadId,
          aitChatRequest: {
            query: suggestion ? suggestion : query,
            useCase: "CHART_EDIT_USE_CASE",
            outputType: "CHART",
            inputIds: [{ type: "ASKAI_MESSAGE_ID", id: askAIMessageId, name: codeName }]
          },
          controller: newController
        },
        {
          onSuccess: (data) => {
            if (isStopped.current || !data) {
              isStopped.current = false;
              return;
            }
            if (isEmpty(conversation)) {
              setIsExpanded(false);
            }
            setConversation((prev) => [...prev, { ...data, isExpanded: true }]);
          },
          onError: (error) => {
            if (isStopped.current || !error) {
              isStopped.current = false;
              return;
            }
          }
        }
      );
    }
  };

  const removeQuery = (askAIMessageId: string) => {
    setConversation((prev) =>
      filter(prev, (item: AIChatResponseDto) => item.askAIMessageId !== askAIMessageId)
    );
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if ((event.metaKey || event.ctrlKey) && event.key === "Enter") {
      // For Command + Enter or Ctrl + Enter on Windows
      event.preventDefault();
      setQuery(query + "\n");
    } else if (disabled) {
      return;
    } else if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();

      handleGenerateResponse();
    }
  };

  const handleConfirm = () => {
    if (threadResult.data?.threadId && projectId) {
      if (chat.isLoading) {
        controllerRef.current?.abort();
      }
      deleteThread.mutate({ threadId: threadResult.data?.threadId ?? "", projectId });
    }
    onClose();
  };

  const handleStop = () => {
    isStopped.current = true;
    controllerRef.current?.abort();
    const baseResponse = {
      threadId: threadResult.data?.threadId!,
      query: userInput,
      askAIMessageId: STOPPED_QUERY_ID,
      isExpanded: true,
      outputType: AIChatRequestDtoOutputTypeEnum.Chart,
      error: {
        explanation: "Query run stopped",
        errorType: "errorType"
      } as any
    } as AIChatResponseDto;
    setConversation((prev) => [...prev, baseResponse]);
  };

  return (
    <NewModal
      header="Chart Edit"
      width="85%"
      contentWidth="100%"
      open={open}
      hideFooter
      onClose={handleClose}>
      <Grid container direction="column" style={{ gap: "10px" }}>
        <Grid
          direction="column"
          style={{
            maxHeight: "calc(95vh - 200px)",
            minHeight: "300px",
            overflow: "auto",
            gap: "10px",
            display: "flex"
          }}>
          <Grid
            style={{
              borderRadius: "5px",
              paddingLeft: "16px"
            }}>
            <Box style={{ overflowY: "auto", borderRadius: "2px" }}>
              <OriginalChart
                askAIMessageId={askAIMessageId}
                chartJson={chartJson}
                name={name}
                isExpanded={isExpanded}
                setIsExpanded={setIsExpanded}
              />
            </Box>
          </Grid>
          {threadResult.isLoading || threadResult.isFetching ? (
            <CommonLoader />
          ) : (
            <EditChartConversation
              subThreadId={threadResult.data?.threadId}
              responses={conversation}
              onClose={onClose}
              removeQuery={removeQuery}
              askAIMessageId={askAIMessageId}
              parentThreadId={parentThreadId}
              isSaveDisabled={chat.isLoading}
            />
          )}
          {chat.isLoading && (
            <Grid container direction="column">
              <AskAILoaderItem
                isFetchingSuggestions={false}
                inputNames={[]}
                userInput={userInput}
                testId="generate-output-loader"
                style={{ paddingBottom: "calc(10vh)" }}
                tags={[]}
                queryAsTitle
              />
            </Grid>
          )}
          <div id="messagesEndRef" ref={messagesEndRef}></div>
        </Grid>

        <Grid container direction="column" wrap="nowrap">
          <SuggestionPrompts
            suggestions={suggestion}
            isDisabled={chat.isLoading || threadResult.isFetching}
            disabledTitle={chat.isLoading ? "Wait until existing query run is complete" : ""}
            onClick={(suggestion) => {
              handleGenerateResponse(suggestion);
            }}
          />
        </Grid>
        <TextField
          size="small"
          fullWidth
          value={query}
          className={classes.root}
          variant="outlined"
          placeholder="Enter your Custom Query to modify the chart"
          test-id="ask-ai-modal-text-field"
          inputProps={{
            "test-id": { inputTestId: "ask-ai-modal-input" }
          }}
          multiline
          minRows={1}
          maxRows={2}
          inputRef={textFieldRef}
          onKeyDown={handleKeyDown}
          InputProps={{
            className: classes.input,
            classes: {
              notchedOutline: classes.notchedOutline
            },
            endAdornment: chat.isLoading ? (
              <QuerySpinner onStop={() => handleStop()} />
            ) : (
              <Tooltip
                title={
                  chat.isLoading
                    ? "Please wait until existing query is executed"
                    : isEmpty(query.trim())
                      ? "Add Query to enable"
                      : ""
                }>
                <span>
                  <IconButton
                    color="primary"
                    size="small"
                    test-id="ask-ai-modal-generate-code-btn"
                    disabled={disabled}
                    onClick={() => handleGenerateResponse()}>
                    <SendSharp
                      fontSize="small"
                      style={{ opacity: 1, color: disabled ? "rgba(0, 0, 0, 0.6)" : "#4646b5" }}
                    />
                  </IconButton>
                </span>
              </Tooltip>
            )
          }}
          onChange={(event) => setQuery(event.target.value)}
        />

        <Grid item container direction="row" justifyContent="flex-end">
          <Typography className={classes.helpText}>
            <b>Shift/Ctrl + Return</b> to add a new line
          </Typography>
        </Grid>
        {confirm && (
          <Modal
            open
            variant={ModalVariants.Delete}
            title="Are you sure?"
            content={["History is not stored for chart edits. Are you sure you want to exit?"]}
            onClose={() => {
              setConfirm(false);
            }}
            onSubmit={handleConfirm}
            cancelLabel="No"
            submitLabel="Yes"
            hideCloseIcon
          />
        )}
      </Grid>
    </NewModal>
  );
};

export default EditChartModal;
