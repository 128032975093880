import InfoOutlined from "@material-ui/icons/InfoOutlined";
import React from "react";
import _ from "lodash";
import { makeStyles } from "@material-ui/core";
import { useQueryClient } from "@tanstack/react-query";

import AddOutputToCanvas from "./AddOutputToCanvas";
import DownloadPredictions from "./DownloadPredictions";
import ViewDataData from "pages/ViewData/ViewDataData/ViewDataData";
import useEntityFeatures from "hooks/api/projects/useEntityFeatures";
import { IReturn, QUERY_KEY_RUN_DETAILS } from "hooks/api/projects/useRunDetails";
import { getEntityDataByColumn } from "services/Apis/wrappers/projects";

const useStyles = makeStyles({
  flex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderTopLeftRadius: "6px",
    borderBottom: "1px solid #e0e4e6",
    borderTopRightRadius: "6px",
    padding: "10px",
    background: "white"
  },
  flexEnd: {
    display: "flex",
    gap: "16px"
  },
  outputInfo: {
    display: "flex",
    gap: "8px",
    marginTop: "10px",
    fontSize: 14,
    alignItems: "center"
  }
});

interface IProps {
  predictionJobName: string;
  disabled: boolean;
  projectRunId: string;
  projectId: string;
  data: IReturn;
}

const PredictionJobOutput: React.FC<IProps> = (props) => {
  const { predictionJobName, disabled, projectId, data, projectRunId } = props;

  const featureResponse = useEntityFeatures(
    projectId,
    data?.lastRunEntryId,
    data.lastRunEntryId,
    data?.outputEntityName
  );
  const { flex, outputInfo, flexEnd } = useStyles();
  const queryClient = useQueryClient();

  const handleColumnChange = async (columnNames: string[]) => {
    const nonExistingColumns = _.filter(
      columnNames,
      (col) => !_.includes(data?.data?.columns, col)
    );

    if (nonExistingColumns.length > 0) {
      const body = {
        rowsStart: 0,
        rowsEnd: 500,
        projectRunEntryId: data?.lastRunEntryId,
        runEntityName: data?.outputEntityName,
        cols: nonExistingColumns
      };
      try {
        const newColumn = await getEntityDataByColumn(data?.lastRunEntryId, body as any);
        queryClient.setQueryData([QUERY_KEY_RUN_DETAILS, projectRunId], (prevData: any) => {
          return {
            ...prevData,
            data: {
              columns: [...(prevData.data?.columns || []), ...(newColumn?.data?.columns ?? [])],
              rows: prevData?.data?.rows?.map((row: any, index: number) => ({
                cells: [...row?.cells, ...newColumn?.data?.rows?.[index]?.cells]
              }))
            }
          };
        });
      } catch (e) {
        console.error(e);
      }
    }
  };

  return (
    <div>
      <div className={flex}>
        <span>{`${predictionJobName} - Prediction Output`}</span>
        <div className={flexEnd}>
          <AddOutputToCanvas
            isManual
            path={data?.path!}
            projectId={projectId}
            disabled={disabled || !data?.path}
            predictionJobName={predictionJobName}
          />
          <DownloadPredictions
            id={data?.lastRunEntryId}
            size="small"
            data-testid="predict-job-=download"
            variant="contained"
            disabled={disabled || !data.path}
            style={{ background: "#F8F8FF", boxShadow: "none" }}>
            Download
          </DownloadPredictions>
        </div>
      </div>
      <ViewDataData
        isLoading={featureResponse.isLoading}
        responseData={data?.data}
        hideCount={false}
        entityFeatures={featureResponse.data ?? []}
        maxHeight="calc(100vh - 215px)"
        onColumnChange={handleColumnChange}
      />
      <div className={outputInfo}>
        <InfoOutlined style={{ width: "16px", height: "16px" }} />
        <i>Output columns might not always match with the uploaded data</i>
      </div>
    </div>
  );
};

export default PredictionJobOutput;
