import React, { useCallback, useMemo, useState } from "react";

// Packages
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import { includes, isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { EnvDto, EnvDtoLaunchStatusEnum } from "@rapidcanvas/rc-api-core/openapi/Models";

// MUI
import { Button, CircularProgress, Tooltip } from "@material-ui/core";

// Icons
import { ConfigIcon } from "src/assets/icons/ConfigIcon";
import { LogsNewIconV2 } from "src/assets/icons/LogsNewIconV2";
import { Clear } from "src/assets/icons/Clear";
import { TrashIcon } from "icons/NewUX/TrashIcon";

// Hooks
import {
  useCleanCache,
  useUpdateEnvironment,
  usePollEnvironment,
  EnvCleanCachePayload,
  updateEnvsQueryDataById
} from "src/hooks/api";

// Components
import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import { Modal } from "src/components/custom";
import { ModalVariants } from "src/components/custom/Modal/Modal";

// Constants
import {
  ConfirmCleanCustomPackagesPromptDetails,
  EnvironmentHelperText,
  EnvironmentsHelperText
} from "../../../../utils/Environments.constants";

//Utils
import { WebPaths } from "src/routing/routes";
import { handleResponse } from "services/Apis/Apis.service";
import { ToastTypes, toastWrapper } from "services/ToastClient/toastWrapper";
import { validateNameField } from "utils/helpers/form.helpers";
import { updateEnvironmentsQueryData } from "src/utils/helpers";

//State
import { EnvironmentActionsStatusEnum, setEnvironmentStatus } from "src/stores/environmentsSlice";
import { environmentActionStatusSelector } from "../../../../Environment.selectors";

import EnvironmentLogs from "src/pages/private/EnvironmentsModule/pages/common/EnvironmentLogs/EnvironmentLogs";
import DeleteEnvironment from "src/pages/private/EnvironmentsModule/pages/common/DeleteEnvironment/DeleteEnvironment";
import EnvironmentConfig from "src/pages/private/EnvironmentsModule/pages/common/EnvironmentConfig/EnvironmentConfig";
import EnvironmentHeaderBreadcrumb from "./EnvironmentHeaderBreadcrumb";
import { StatusBarNew } from "../StatusBar/StatusBar";

type Props = {
  environment: EnvDto;
  isSaving: boolean;
  isSaveActionDisabled: boolean;
  isEnvironmentNameInUse: (name: string) => boolean;
  onSaveAction: () => void;
  readOnly: boolean;
  fetchEnvironmentData: () => void;
  saveTitle: string;
};

const EnvironmentHeader = (props: Props) => {
  const {
    environment,
    isSaving,
    isSaveActionDisabled,
    isEnvironmentNameInUse,
    onSaveAction,
    readOnly,
    fetchEnvironmentData,
    saveTitle
  } = props || {};

  const navigate = useNavigate();
  const { envId } = useParams();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const envActionStatus = useSelector(environmentActionStatusSelector(envId!));

  const [shouldResetName, setShouldResetName] = useState<number | boolean>(false);
  const [showCleanCustomPackagesConfirmScreen, setShowCleanCustomPackagesConfirmScreen] =
    useState(false);
  const [showDeleteScreen, setShowDeleteScreen] = useState(false);
  const [showLogsScreen, setShowLogsScreen] = useState<boolean>(false);
  const [showEnvConfigScreen, setShowEnvConfigScreen] = useState<boolean>(false);

  // Mutations
  const updateEnv = useUpdateEnvironment();

  // Query hooks - STARTS >>
  const {
    isLoading: isClearingCache,
    mutateAsync: clearCacheMutation,
    reset: resetClearCacheMutation
  } = useCleanCache();

  const {
    data: pollingEnvResponse,
    isFetchedAfterMount: isPollingEnvFetchedAfterMount,
    refetch: refetchPollingEnv
  } = usePollEnvironment({
    id: envId!,
    status: environment?.launchStatus
  });

  const {
    isLoading: isCleaningCustomPackages,
    mutateAsync: cleanCustomPackagesMutation,
    reset: resetCleanCustomPackagesMutation
  } = useCleanCache({ envId });
  // << ENDS - Query hooks

  const env = useMemo(() => {
    if (!!isPollingEnvFetchedAfterMount && !isEmpty(pollingEnvResponse?.data?.[0])) {
      return pollingEnvResponse?.data?.[0];
    }

    return environment;
  }, [isPollingEnvFetchedAfterMount, pollingEnvResponse?.data?.[0], environment]);

  const isUsageDisabled = useMemo(
    () =>
      environment?.launchStatus !== EnvDtoLaunchStatusEnum.Success ||
      envActionStatus === EnvironmentActionsStatusEnum.Stopping,
    [environment?.launchStatus, envActionStatus]
  );

  const isLaunching = useMemo(
    () =>
      environment?.launchStatus === EnvDtoLaunchStatusEnum.Launching ||
      envActionStatus === EnvironmentActionsStatusEnum.Launching,
    [environment?.launchStatus, envActionStatus]
  );

  const disabledClearCacheActionMessage = useMemo(() => {
    if (!!isClearingCache) {
      return EnvironmentHelperText.ClearingCacheInfo;
    }

    if (!!isCleaningCustomPackages) {
      return EnvironmentHelperText.CleaningCustomPackagesInfo;
    }

    if (
      environment?.launchStatus?.trim()?.toLowerCase() !==
      EnvDtoLaunchStatusEnum.Success.toLowerCase()
    ) {
      return EnvironmentHelperText.CleanActionsDisabledInfo;
    }

    return "";
  }, [isClearingCache, isCleaningCustomPackages, environment]);

  const disabledCleanCustomPackagesActionMessage = useMemo(() => {
    if (!!isClearingCache) {
      return EnvironmentHelperText.ClearingCacheInfo;
    }

    if (!!isCleaningCustomPackages) {
      return EnvironmentHelperText.CleaningCustomPackagesInfo;
    }

    if (
      environment?.launchStatus?.trim()?.toLowerCase() !==
      EnvDtoLaunchStatusEnum.Success.toLowerCase()
    ) {
      return EnvironmentHelperText.CleanActionsDisabledInfo;
    }

    return "";
  }, [isClearingCache, isCleaningCustomPackages, environment]);

  const validateEnvironmentName = (value: string) => {
    const { isValid, error: errorMessage } = validateNameField({
      fieldName: value,
      fieldNameLabel: `environment name`
    });

    if (!isValid) {
      return errorMessage;
    }

    if (isEnvironmentNameInUse(value)) {
      return "The Environment name already exist.";
    }

    return "";
  };

  const updateEnvironmentName = (name: string) => {
    const errorMessage = validateEnvironmentName(name);

    if (errorMessage) {
      handleResponse({ errorMessage });
      setShouldResetName(Date.now());
      return;
    }
    updateEnv.mutate(
      {
        id: environment?.id!,
        payload: {
          ...environment,
          name
        }
      },
      {
        onSuccess: (data: EnvDto) => {
          if (!isEmpty(data)) {
            updateEnvironmentsQueryData({ queryClient, data });
          }

          toastWrapper({
            type: ToastTypes.Success,
            content: "Environment Name updated successfully."
          });
        },
        onError: () => {
          setShouldResetName(Date.now());
        }
      }
    );
  };

  const onClearCache = async () => {
    if (!envId) {
      return;
    }

    const toastId = toastWrapper({
      type: ToastTypes.Info,
      content: "Clearing cache is in progress.",
      ...{ closeOnClick: false }
    });

    const payload: EnvCleanCachePayload = {
      envId,
      isDeleteRecent: true
    };

    await resetClearCacheMutation();
    await clearCacheMutation(payload, {
      onSuccess: () =>
        toastWrapper({
          type: ToastTypes.Success,
          content: "Cache cleared successfully!"
        }),
      onSettled: () => toast.dismiss(toastId)
    });
  };

  const onCleanCustomPackages = async () => {
    resetCleanCustomPackages();

    if (!envId) {
      return;
    }

    dispatch(setEnvironmentStatus({ id: envId!, status: EnvironmentActionsStatusEnum.Updating }));
    updateEnvsQueryDataById(queryClient, envId, EnvDtoLaunchStatusEnum.Launching);

    const toastId = toastWrapper({
      type: ToastTypes.Info,
      content: "Cleaning custom packages is in progress.",
      ...{ closeOnClick: false }
    });

    const payload: EnvCleanCachePayload = {
      envId,
      isCleanCustomPackages: true
    };

    await resetCleanCustomPackagesMutation();
    await cleanCustomPackagesMutation(payload, {
      onSuccess: async () => {
        refetchPollingEnv();
        await fetchEnvironmentData();

        toastWrapper({
          type: ToastTypes.Success,
          content: "Custom packages cleaned successfully!"
        });
      },
      onSettled: () => toast.dismiss(toastId)
    });
  };

  // Confirm clean custom packages - STARTS >>
  const promptConfirmCleanCustomPackages = () => {
    setShowCleanCustomPackagesConfirmScreen(() => true);
  };

  const resetCleanCustomPackages = () => {
    setShowCleanCustomPackagesConfirmScreen(() => false);
  };
  // << ENDS - Confirm clean custom packages

  const resetDeleteEnvironment = useCallback(() => setShowDeleteScreen(false), []);

  const handleDelete = useCallback(() => {
    navigate(WebPaths.Environments);
    resetDeleteEnvironment();
  }, []);

  const moreOptions = useMemo(
    () => [
      {
        label: "Configs",
        icon: <ConfigIcon />,
        action: () => setShowEnvConfigScreen(true),
        isCloseMoreAction: true
      },
      {
        label: "Logs",
        icon: <LogsNewIconV2 />,
        action: () => setShowLogsScreen(true),
        isCloseMoreAction: true
      },
      {
        label: "Clear Cache",
        icon: <Clear width={20} height={20} />,
        isDisabled: !!disabledClearCacheActionMessage,
        isLoading: !!isClearingCache,
        tooltip: disabledClearCacheActionMessage,
        action: onClearCache
      },
      {
        label: "Clean Custom Libraries",
        icon: <Clear width={20} height={20} />,
        isDisabled: !!disabledCleanCustomPackagesActionMessage,
        isLoading: !!isCleaningCustomPackages,
        tooltip: disabledCleanCustomPackagesActionMessage,
        action: promptConfirmCleanCustomPackages
      },
      {
        label: "Delete",
        icon: <TrashIcon width={18} height={18} viewBox="0 0 20 20" />,
        isDisabled: isLaunching,
        tooltip: isLaunching ? EnvironmentsHelperText.DeleteInfo : "",
        action: () => setShowDeleteScreen(true),
        isCloseMoreAction: true
      }
    ],
    [
      isUsageDisabled,
      isLaunching,
      disabledClearCacheActionMessage,
      isClearingCache,
      disabledCleanCustomPackagesActionMessage,
      isCleaningCustomPackages
    ]
  );

  return (
    <>
      {showEnvConfigScreen && (
        <EnvironmentConfig
          open={!!showEnvConfigScreen}
          environment={environment}
          onUpdate={fetchEnvironmentData}
          onClose={() => setShowEnvConfigScreen(false)}
        />
      )}

      <EnvironmentLogs
        open={!!showLogsScreen}
        environment={environment}
        onClose={() => {
          setShowLogsScreen(false);
        }}
      />

      {showDeleteScreen && (
        <DeleteEnvironment
          environment={environment}
          onDelete={handleDelete}
          resetDeleteEnvironment={resetDeleteEnvironment}
        />
      )}

      {showCleanCustomPackagesConfirmScreen && (
        <Modal
          open
          variant={ModalVariants.Delete}
          title={ConfirmCleanCustomPackagesPromptDetails.title}
          content={[
            ConfirmCleanCustomPackagesPromptDetails.messageLine1,
            ConfirmCleanCustomPackagesPromptDetails.messageLine2
          ]}
          onClose={(_, reason: string) => {
            if (!includes(["escapeKeyDown", "backdropClick"], reason)) {
              resetCleanCustomPackages();
            }
          }}
          onSubmit={onCleanCustomPackages}
          hideCloseIcon
        />
      )}

      <SubTopNavBarWrapper
        subTopNavBarLeftSection={{
          component: (
            <EnvironmentHeaderBreadcrumb
              environmentName={environment.name!}
              onEditEnvironmentName={updateEnvironmentName}
              shouldResetName={shouldResetName}
              readonly={readOnly}
            />
          )
        }}
        subTopNavBarRightSection={{
          moreOptions,
          moreActionWidth: 250,
          component: (
            <>
              {env && <StatusBarNew environment={env} />}

              <Tooltip title={saveTitle}>
                <div>
                  <Button
                    color="primary"
                    size="small"
                    startIcon={isSaving ? <CircularProgress size={16} /> : undefined}
                    onClick={onSaveAction}
                    disabled={isSaveActionDisabled}
                    data-testid="saveEnvironment">
                    {EnvironmentsHelperText.Save}
                  </Button>
                </div>
              </Tooltip>
            </>
          )
        }}
      />
    </>
  );
};

export default EnvironmentHeader;
