import React, { useMemo } from "react";

// Packages
import { isEmpty, isNil, last, map, reduce } from "lodash";

// MUI
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import makeStyles from "@material-ui/core/styles/makeStyles";

// Open API
import { DeploymentPropertyDto } from "@rapidcanvas/rc-api-core";

// Components
import MarkdownDisplay from "src/components/MarkdownDisplay";

const useStyles = makeStyles(() => ({
  loader: {
    position: "relative",
    left: "50%"
  }
}));

type Props = {
  isLoading: boolean;
  data?: DeploymentPropertyDto;
};

const ReleaseNotesContent = (props: Props) => {
  const { isLoading, data } = props || {};

  const classes = useStyles();

  // @REFACTOR
  // Didn't find suitable plugin of react-markdown which can render each \n as a new line.
  // Hence, below workaround.
  const lines = useMemo(() => {
    if (!isNil(data?.value) && !isEmpty(data?.value)) {
      const parts = data?.value?.split("\n");

      const result = reduce(
        parts,
        (acc: any[], part: string) => {
          if (acc.length > 0 && last(acc).startsWith("- ")) {
            acc[acc.length - 1] = last(acc) + "\n" + part;
          } else {
            acc.push(part);
          }

          return acc;
        },
        []
      );

      return result;
    }

    return [];
  }, [data?.value]);

  return (
    <Box width="100%" height="100%">
      {isLoading ? (
        <CircularProgress color="secondary" className={classes.loader} />
      ) : !isEmpty(lines) ? (
        <Box p={2} bgcolor="#fff" height="100%" overflow="auto">
          {map(lines, (line, index) => (
            <React.Fragment key={index}>
              <MarkdownDisplay string={line || ""} />
              <br />
            </React.Fragment>
          ))}
        </Box>
      ) : (
        <Alert severity="info" style={{ justifyContent: "center" }}>
          No data found!
        </Alert>
      )}
    </Box>
  );
};

export default ReleaseNotesContent;
