import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import api from "services/AxiosClient/AxiosClient";
import { AI_GUIDE_MESSAGES_KEY } from "./useGetAIGuideMessages";
import { AIChatResponseDto, ThreadResponseDto } from "@rapidcanvas/rc-api-core";
import { map } from "lodash";
import { AI_GUIDE_THREADS_KEY } from "./useGetAIGuideThreads";

interface IVariables {
  threadId: string;
  messageIds: string[];
  projectId?: string;
}

export const useDeleteAIGuideMessages = (): UseMutationResult<
  unknown,
  unknown,
  IVariables,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ projectId, threadId, messageIds }) => {
      const promises = messageIds.map((messageId) =>
        api.fetchResponse(() => api.AiControllerApi.deleteMessage2(threadId, messageId))
      );

      const responses = await Promise.all(promises);

      queryClient.setQueryData(
        [AI_GUIDE_MESSAGES_KEY, threadId],
        (prevData: AIChatResponseDto[] | undefined) =>
          (prevData || []).filter((resp) => !messageIds.includes(resp.askAIMessageId!))
      );
      projectId &&
        queryClient.setQueryData(
          ["projects", projectId, AI_GUIDE_THREADS_KEY],
          (prevData: ThreadResponseDto[] | undefined) => {
            if (!prevData) return [];

            return map(prevData, (thread) =>
              thread.threadId === threadId
                ? {
                    ...thread,
                    messagesCount:
                      thread.messagesCount && thread.messagesCount > messageIds?.length
                        ? thread.messagesCount - messageIds?.length
                        : 0
                  }
                : thread
            );
          }
        );

      return responses;
    }
  });
};
