import React from "react";
import { Button } from "@material-ui/core";
import { useNavigate } from "react-router-dom";

import NotFoundIcon from "src/assets/icons/NotFoundIcon";
import Result from "./Result";

const ComponentNotFound: React.FC = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Result
      icon={<NotFoundIcon />}
      heading="Page not found"
      subtitle1="Oops! It looks like the page you are trying to access does not exist or has been moved."
      extra={
        <Button variant="contained" onClick={handleGoBack}>
          Go Back
        </Button>
      }
    />
  );
};

export default ComponentNotFound;
