import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  useTheme
} from "@material-ui/core";
import React, { useState } from "react";
import shallow from "zustand/shallow";
import useApiKeys, { QUERY_KEY_API_KEYS } from "src/hooks/api/api-keys/useApiKeys";
import { PlusFilledIcon } from "src/assets/icons/PlusFilledIcon";
import ApiKeyModal from "./ApiKeyModal";
import EventBus from "services/EventBus/EventBus";
import { filter, find, isEmpty, size } from "lodash";
import SyntaxButton from "./SyntaxButton";
import { ApiAccessKeyDto } from "@rapidcanvas/rc-api-core";
import { Field } from "src/components";
import useTenantsStore from "stores/zustand/tenant-management.store";
import PasswordField from "src/components/Inputs/PasswordField";
import MoreVertRoundedIcon from "@material-ui/icons/MoreVertRounded";
import useDeleteApiKey from "src/hooks/api/api-keys/useDeleteApiKey";
import { handleResponse } from "services/Apis/Apis.service";
import { useQueryClient } from "@tanstack/react-query";
import ApiKeySuccessModal from "./ApiKeySuccessModal";
import ReactException from "src/components/Errors/ReactException";
import { Warning } from "@material-ui/icons";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { dateFormat } from "utils/helpers/date.helpers";
import Modal, { ModalVariants } from "src/components/custom/Modal/Modal";
import CommonLoader from "src/components/CommonLoader";
import { QUERY_KEY_CURRENT_TENANT } from "src/hooks/api/tenant/useFetchCurrentTenant";

export const addApiKey = "ADD-API-KEY";
export const editApiKey = "EDIT-API-KEY";
dayjs.extend(isSameOrBefore);

const useStyles = makeStyles(() => ({
  apiKeysBox: {
    "& .Mui-disabled": {
      color: "#003656 !important"
    }
  }
}));

export const isUpForRenual = (expiry: number) => {
  const inputDate = dayjs(expiry);

  const oneMonthFromNow = dayjs().add(1, "M").endOf("day");

  return inputDate.isSameOrBefore(dayjs().endOf("day"))
    ? "Expired"
    : inputDate.isSameOrBefore(oneMonthFromNow)
      ? "Expiring Soon"
      : "";
};

const ApiKeys: React.FC = () => {
  const [confirm, setConfirm] = useState(false);
  const apiKeyResult = useApiKeys();
  const deleteApiKey = useDeleteApiKey();
  const classes = useStyles();
  const queryClient = useQueryClient();

  const theme = useTheme();

  const [roles] = useTenantsStore((state) => [state.roles], shallow);

  const [menuState, setMenuState] = useState<{
    anchorEl: HTMLButtonElement | null;
    selectedKey: ApiAccessKeyDto | null;
  }>({ anchorEl: null, selectedKey: null });

  const onOpen = (event: React.MouseEvent<HTMLButtonElement>, key: ApiAccessKeyDto) => {
    setMenuState({ anchorEl: event.currentTarget, selectedKey: key });
  };

  const onDropDownClose = () => {
    setMenuState({ anchorEl: null, selectedKey: null });
  };

  const handleEdit = () => {
    if (menuState.selectedKey) {
      EventBus.publish(editApiKey, { record: menuState.selectedKey });
    }
    onDropDownClose();
  };

  const handleAdd = (isFirst?: boolean) => {
    EventBus.publish(addApiKey, isFirst);
  };
  const handleDelete = () => {
    setConfirm(true);
  };

  const handleConfirm = () => {
    if (menuState.selectedKey) {
      deleteApiKey.mutate(
        { id: menuState.selectedKey.id ?? "" },
        {
          onSuccess: () => {
            if (size(apiKeyResult.data) === 1) {
              queryClient.invalidateQueries([QUERY_KEY_CURRENT_TENANT]);
            }
            queryClient.setQueryData([QUERY_KEY_API_KEYS], (prevData: any) => {
              return filter(prevData, (item) => item.id !== (menuState.selectedKey?.id ?? ""));
            });
            onDropDownClose();
            setConfirm(false);
            handleResponse({ successMessage: `API Key ${name} deleted successfully.` });
          }
        }
      );
    }
  };

  const handleRenew = (key: ApiAccessKeyDto) => {
    EventBus.publish(editApiKey, { record: key });
  };

  if (apiKeyResult.isError) {
    return <ReactException onClick={apiKeyResult.refetch} />;
  }
  if (apiKeyResult.isLoading) {
    return <CommonLoader />;
  }

  const getRole = (id: string) => (!!id ? find(roles, { id }) : null);

  return (
    <Grid
      container
      direction="column"
      style={{
        backgroundColor: "#ffffff",
        padding: "16px",
        gap: "16px",
        borderRadius: "4px"
      }}>
      <Grid
        container
        item
        justifyContent="space-between"
        alignItems="center"
        style={{ borderBottom: "1px solid rgb(224, 224, 224)", paddingBottom: "5px" }}>
        <Typography variant="h6" color="textPrimary">
          API Keys
        </Typography>
        <Grid
          item
          style={{
            display: "flex",
            columnGap: 5,
            zIndex: 1
          }}>
          <SyntaxButton tooltip="Refer to this Syntax for API Key usage" />

          <Tooltip
            title={
              size(apiKeyResult.data) > 4
                ? "You can only create a maximum of 5 API Keys at a time"
                : ""
            }>
            <span>
              <Button
                id={"add-api-key-button}"}
                color="primary"
                size="small"
                disabled={apiKeyResult.isLoading || size(apiKeyResult.data) > 4}
                onClick={() => handleAdd(isEmpty(apiKeyResult.data))}>
                <PlusFilledIcon />
              </Button>
            </span>
          </Tooltip>
        </Grid>
      </Grid>
      <span style={{ paddingBottom: "10px" }}>
        Your API Keys needs to be treated as securely as any other password. You can only create a
        maximum of 5 Keys at a time.
      </span>
      {isEmpty(apiKeyResult.data) ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: "calc(100vh - 400px)" }}>
          <Button color="primary" variant="contained" onClick={() => handleAdd(true)}>
            + API Key
          </Button>
        </Grid>
      ) : (
        <Grid
          container
          direction="column"
          style={{ gap: "16px", maxHeight: " calc(100vh - 364px)" }}>
          {apiKeyResult.data?.map((key, index: number) => (
            <>
              <Grid container justifyContent="space-between">
                <Box
                  key={`variable-inputs-${index}`}
                  style={{ width: "100%", alignItems: "center" }}
                  className={classes.apiKeysBox}
                  display="flex"
                  gridGap="24px">
                  <Grid item xs={3}>
                    <Field
                      id={`variable-name-${index}`}
                      fullWidth
                      label="Name"
                      disabled={!!key.name}
                      value={key?.name || ""}
                      required
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Field
                      id={`variable-role-${index}`}
                      fullWidth
                      label="Role"
                      disabled={!!key.roleId}
                      value={getRole(key.roleId!)?.name || ""}
                      required
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <PasswordField
                      hideShowPassword
                      id={`variable-key-${index}`}
                      label="API Key"
                      fullWidth
                      disabled={!!key.name}
                      name={"pass-" + Math.random().toString(36).substring(2)}
                      value={key?.apiKey || ""}
                      autoComplete="new-password"
                      required
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Field
                      fullWidth
                      id={`variable-expiry-${index}`}
                      label="Expiry Date"
                      disabled={!!key.name}
                      value={dateFormat(key.keyExpiryTs, true, "YYYY-MM-DD") || ""}
                      required
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                  <Grid container style={{ flexWrap: "nowrap", gap: "5px" }} item xs={2}>
                    <Tooltip title="Actions">
                      <IconButton
                        color="primary"
                        size="small"
                        onClick={(event) => onOpen(event, key)}
                        data-testid="subTopNavBarMoreAction">
                        <MoreVertRoundedIcon />
                      </IconButton>
                    </Tooltip>
                    {!!isUpForRenual(key.keyExpiryTs ?? dayjs().valueOf()) && (
                      <Grid container alignItems="center" style={{ gap: "5px" }}>
                        <Warning fontSize={"small"} style={{ color: "#FF0000" }} />
                        <span style={{ fontSize: "12px" }}>
                          {isUpForRenual(key.keyExpiryTs ?? dayjs().valueOf())}
                        </span>
                        <Button
                          onClick={() => handleRenew(key)}
                          size="small"
                          color="primary"
                          variant="outlined">
                          Renew
                        </Button>
                      </Grid>
                    )}
                  </Grid>

                  {Boolean(menuState.anchorEl) && (
                    <Menu
                      open
                      anchorEl={menuState.anchorEl}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left"
                      }}
                      onClose={onDropDownClose}
                      PaperProps={{
                        style: {
                          marginTop: 6,
                          width: 150,
                          borderRadius: theme.spacing(1.5)
                        }
                      }}>
                      <MenuItem
                        onClick={() => {
                          handleEdit();
                        }}
                        data-testid={"exportLogs"}>
                        <ListItemText
                          primary={"Edit"}
                          primaryTypographyProps={{ variant: "body2" }}
                        />
                      </MenuItem>

                      <MenuItem
                        onClick={() => {
                          handleDelete();
                        }}
                        data-testid={"openInNewTab"}>
                        <ListItemText
                          primary={"Delete"}
                          primaryTypographyProps={{ variant: "body2" }}
                        />
                        {deleteApiKey.isLoading && (
                          <ListItemSecondaryAction>
                            <CircularProgress
                              size={18}
                              color="inherit"
                              style={{ color: theme.palette.text.secondary }}
                            />
                          </ListItemSecondaryAction>
                        )}
                      </MenuItem>
                    </Menu>
                  )}
                </Box>
              </Grid>
            </>
          ))}
        </Grid>
      )}
      {confirm && (
        <Modal
          open
          variant={ModalVariants.Delete}
          title="Delete API Key"
          content={[
            `Are you sure you want to delete API Key with name ${menuState.selectedKey?.name}?`
          ]}
          onClose={() => {
            setConfirm(false);
            onDropDownClose();
          }}
          isSubmitting={deleteApiKey.isLoading}
          onSubmit={handleConfirm}
          cancelLabel="No"
          submitLabel="Yes"
          hideCloseIcon
        />
      )}

      <ApiKeyModal />
      <ApiKeySuccessModal />
    </Grid>
  );
};
export default ApiKeys;
