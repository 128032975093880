import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { CodeSuggestion } from "src/types";
import CopilotMessage from "./CopilotMessage";

const useStyles = makeStyles({
  chatHistory: {
    flexGrow: 1,
    textAlign: "left",
    padding: "16px",
    height: "calc(100% - 56px)",
    overflow: "auto",
    gap: "16px"
  }
});

interface IProps {
  suggestions: CodeSuggestion[];
  messagesEndRef: React.MutableRefObject<HTMLDivElement | null>;
  containerRef: React.MutableRefObject<HTMLDivElement | null>;
  onScroll: () => void;
}
export const CopilotMessages: React.FC<IProps> = ({
  messagesEndRef,
  containerRef,
  suggestions,
  onScroll
}) => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction="column"
      item
      wrap="nowrap"
      onScroll={onScroll}
      className={classes.chatHistory}
      ref={containerRef}>
      {suggestions.map((suggestion, index) => {
        const shouldShowCode = index === 0 ? true : suggestion.code !== suggestions[index - 1].code;
        return (
          <CopilotMessage key={index} suggestion={suggestion} shouldShowCode={shouldShowCode} />
        );
      })}

      <div ref={messagesEndRef}></div>
    </Grid>
  );
};

export default React.memo(CopilotMessages);
