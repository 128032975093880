import React, { useMemo, useState, useEffect } from "react";
import { Button, CircularProgress, Grid, makeStyles, Tooltip } from "@material-ui/core";
import { isEmpty } from "lodash";

import Drawer from "components/Drawer/CustomDrawer";
import useExportRecipeLogs from "../useExportRecipeLogs";
import useGetRecipeLogs from "src/hooks/useGetRecipeLogs";
import { LogsContent } from "./LogsContent";
import { RecipeStatuses } from "projectsModule/utils/Projects.constants";
import { ToastifyAlert } from "src/components/ToastifyAlert/ToastifyAlert";
import { checkEnvRelaunch } from "environmentsModule/utils/environmentRelaunch.helpers";
import { getStatusEquivalence, ShowLogsDrawerTitle } from "./ShowLogsDrawerTitle";
import { GroupLogDtoStatusEnum } from "@rapidcanvas/rc-api-core";

export const TEMP_MSG = ["Fetching logs. Please wait..."];
export const UNBUILT_MSG = "Logs are not available because the recipe hasn't been executed yet.";
export const IS_TEST_LOGS = "isTestLogs";

export const useStyles = makeStyles({
  autoMlLogs: {
    flexWrap: "nowrap",
    width: "600px"
  },
  logStatuses: {
    gap: "16px",
    background: "#1e1e1e",
    padding: "8px 8px 0px 8px"
  },
  logStatusWithoutBckg: {
    gap: "16px",
    padding: "8px 8px 0px 8px"
  },
  drawer: {
    width: 600,
    flexShrink: 0
  },
  drawerPaper: {
    width: 600
  }
});

type Props = {
  type: any;
  message: string;
};

export const renderInfo = ({ message, type }: Props) => {
  return (
    <Grid
      container
      item
      xs={12}
      style={{
        padding: 16,
        display: "inline-block"
      }}>
      <ToastifyAlert type={type} message={message} />
    </Grid>
  );
};

interface IProps {
  projectId?: string;
  scenarioId?: string;
  name: string;
  isRunRecipeLogs: boolean;
  open: boolean;
  groupId: string;
  isJobPath: boolean;
  jobRunId?: string;
  onClose: () => void;
  isRecipeRunning: boolean;
  isTesting?: boolean;
}

const RecipeLogsDrawer: React.FC<IProps> = (props) => {
  const {
    projectId,
    scenarioId,
    groupId,
    name,
    isJobPath,
    jobRunId,
    open,
    isRunRecipeLogs,
    onClose,
    isRecipeRunning,
    isTesting
  } = props;
  const classes = useStyles();
  const exportRecipeLogs = useExportRecipeLogs();

  const [searchValue, setSearchValue] = useState("");
  const { data, isLoading, isFetching, refetch } = useGetRecipeLogs({
    scenarioId,
    groupId,
    showAllLogs: true,
    isRunRecipeLogs,
    jobRunId: isJobPath ? jobRunId : undefined,
    isTesting
  });

  const recipeLogs = data?.recipeLogs ?? [];
  const recipeStatus = data?.status ?? ("" as unknown as any);

  const url = `/recipe-run-logs/${projectId}/${scenarioId}/${groupId}?${IS_TEST_LOGS}=${!isRunRecipeLogs}`;

  const statusToShow = isRunRecipeLogs ? data?.status : undefined;

  useEffect(() => {
    if (projectId) {
      checkEnvRelaunch(projectId);
    }
  }, [projectId]);

  const exportLogs = async () => {
    if (groupId) {
      !!projectId && checkEnvRelaunch(projectId);
      await exportRecipeLogs.mutateAsync({
        groupId,
        scenarioId,
        name,
        isTestEnabled: !isRunRecipeLogs,
        projectRunEntryId: jobRunId
      });
    }
  };

  const filteredLogs = useMemo(() => {
    return (data?.recipeLogs || [])?.filter((eachField) => {
      return searchValue ? eachField?.toLowerCase()?.includes(searchValue?.toLowerCase()) : true;
    });
  }, [searchValue, data?.recipeLogs]);

  const handleSearchValueChange = (event: any) => {
    setSearchValue(event.target.value);
  };

  const recipeRunInfo = useMemo(() => {
    switch (recipeStatus) {
      case GroupLogDtoStatusEnum.Success:
        return {
          type: "success",
          message: isRunRecipeLogs ? "Recipe run successful" : "Recipe test successful"
        };

      case GroupLogDtoStatusEnum.Running:
        return { type: "info", message: "Recipe run in progress..." };

      case GroupLogDtoStatusEnum.Unbuilt:
        return {
          type: "info",
          message: "Logs are not available because the recipe hasn't been executed yet."
        };

      default:
        return { type: "error", message: "No logs found!" };
    }
  }, [recipeStatus]);

  const title = (
    <ShowLogsDrawerTitle
      title={name}
      prefix={isRunRecipeLogs ? "Run" : "Test"}
      url={isJobPath ? undefined : url}
      onClose={onClose}
      searchValue={searchValue}
      onSearch={handleSearchValueChange}
      exportLogs={exportLogs}
      exportLogLoading={exportRecipeLogs.isLoading}
      exportDisabled={isRunRecipeLogs ? isRecipeRunning : !!isTesting}
      exportDisabledMessage={
        isRunRecipeLogs
          ? "Please wait until recipe run is complete"
          : "Please wait until recipe test is complete"
      }
      extra={
        data?.show ? (
          <Tooltip title="Retrieves the most recent logs">
            <Button
              variant="outlined"
              size="small"
              disabled={isFetching}
              startIcon={isFetching ? <CircularProgress size={16} /> : undefined}
              onClick={() => refetch()}>
              Refresh
            </Button>
          </Tooltip>
        ) : undefined
      }
      {...(statusToShow && {
        status: { color: getStatusEquivalence(recipeStatus), text: recipeStatus }
      })}
    />
  );

  return (
    <Drawer
      anchor="right"
      variant="temporary"
      open={open}
      titleStyle={{ padding: 0, borderBottom: "1px solid #E0E0E0", lineHeight: 1 }}
      hideCloseButton
      classes={{
        paper: classes.drawerPaper
      }}
      className={classes.drawer}
      title={title}
      onClose={onClose}>
      <Grid container direction="column" justifyContent="center" className={classes.autoMlLogs}>
        {isLoading ? (
          <LogsContent logs={TEMP_MSG} />
        ) : isEmpty(recipeLogs) && !isRunRecipeLogs ? (
          <LogsContent info="No logs found!" logs={[]} />
        ) : !isEmpty(recipeLogs) ? (
          <LogsContent
            logs={filteredLogs}
            {...(RecipeStatuses.UnBuilt === recipeStatus ? { info: UNBUILT_MSG } : {})}
          />
        ) : (
          renderInfo(recipeRunInfo)
        )}
      </Grid>
    </Drawer>
  );
};

export default RecipeLogsDrawer;
