import React from "react";
import _, { includes, isEmpty, pickBy } from "lodash";
import { Card, Grid, makeStyles } from "@material-ui/core";

import DataAppModelControls from "../CreateDataApp/DataAppModelControls";
import SelectAskAILLMModel from "../CreateDataApp/SelectAskAILLMModel";
import { dataAppConfigFields } from "../CreateDataApp/CreateDataAppForm";
import DataAppAccess from "../CreateDataApp/DataAppAccess";
import SelectDataAppSecurity from "../CreateDataApp/SelectDataAppSecurity";
import { DataAppAppTypeEnum } from "pages/DataApps/DataApps.type";
import ColumnHyperlinks from "../CreateDataApp/ColumnHyperlinks";
import DataAppPermissions from "./DataAppPermissions";
import {
  CreateDataAppRequestDtoDataAppTypeEnum,
  DataappAskAIConfigInputTypeEnum,
  DataAppDtoAppTemplateTypeEnum,
  DataAppDtoDataAppTypeEnum
} from "@rapidcanvas/rc-api-core";
import EnableResponseCache from "../CreateDataApp/EnableResponseCache";

interface IProps {
  values: Record<string, any>;
  dataAppType?: DataAppDtoDataAppTypeEnum;
  appTemplateType?: DataAppDtoAppTemplateTypeEnum;
  onInputChange: (e: any) => void;
  setValues: (values: Record<string, any>, name: string) => void;
  onHyperLinkError: (error: string) => void;
}

const useStyles = makeStyles({
  card: { padding: "16px", display: "flex", flexDirection: "column", gap: "32px", height: "100%" },
  sqDiv: { border: "1px solid #c7c7c7", borderRadius: "4px", padding: "16px" }
});

const EditDataAppSettings: React.FC<IProps> = (props) => {
  const { values, dataAppType, appTemplateType, onInputChange, setValues, onHyperLinkError } =
    props;

  const { card, sqDiv } = useStyles();

  const updateHyperLinks = (parsedMapping: { [key: string]: string }) => {
    const newval = {
      [dataAppConfigFields.columnsHyperlinksMapping]: !isEmpty(
        pickBy(parsedMapping, (value, key) => key && value)
      )
        ? JSON.stringify(parsedMapping)
        : ""
    };
    setValues(
      {
        ...values,
        ...newval
      },
      dataAppConfigFields.columnsHyperlinksMapping
    );
  };

  return (
    <Grid container spacing={2} direction="column">
      <Grid container item spacing={2}>
        <Grid item xs={6} sm={6} lg={6}>
          <Card className={card}>
            {_.get(values, dataAppConfigFields.inputType) !==
              DataappAskAIConfigInputTypeEnum.PredictionService && (
              <SelectAskAILLMModel
                value={_.get(values, dataAppConfigFields.llmType)}
                inputType={_.get(values, dataAppConfigFields.inputType)}
                onChange={onInputChange}
              />
            )}
            {dataAppType === DataAppDtoDataAppTypeEnum.Askai && (
              <DataAppModelControls
                value={_.get(values, dataAppConfigFields.systemMessage) ?? ""}
                onChange={onInputChange}
              />
            )}
          </Card>
        </Grid>
        <Grid item xs={6} sm={6} lg={6}>
          <Card className={card}>
            {dataAppType === CreateDataAppRequestDtoDataAppTypeEnum.Askai &&
              appTemplateType === DataAppAppTypeEnum.REACTJS && (
                <>
                  <DataAppAccess
                    value={_.get(values, dataAppConfigFields.isPrivate)}
                    onChange={onInputChange}
                  />
                </>
              )}
            {!_.includes(
              [
                DataappAskAIConfigInputTypeEnum.RagFiles,
                DataappAskAIConfigInputTypeEnum.PredictionService
              ],
              _.get(values, dataAppConfigFields.inputType)
            ) && (
              <SelectDataAppSecurity
                value={_.get(values, dataAppConfigFields.dataSharingStrategy)}
                onChange={onInputChange}
              />
            )}
            {!_.includes(
              [DataappAskAIConfigInputTypeEnum.PredictionService],
              _.get(values, dataAppConfigFields.inputType)
            ) && (
              <EnableResponseCache
                value={_.get(values, dataAppConfigFields.enableCache)}
                onChange={(e) =>
                  setValues(
                    {
                      ...values,
                      [e.target.name]: e.target.checked
                    },
                    e.target.name
                  )
                }
              />
            )}
          </Card>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Card className={card}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <div className={sqDiv}>
                <DataAppPermissions
                  inputType={_.get(values, dataAppConfigFields.inputType)}
                  permissions={_.get(values, dataAppConfigFields.dataAppPermissions)}
                  onChange={(permissions) =>
                    setValues(
                      {
                        ...values,
                        [dataAppConfigFields.dataAppPermissions]: permissions
                      },
                      dataAppConfigFields.dataAppPermissions
                    )
                  }
                />
              </div>
            </Grid>
            <Grid item xs={8}>
              {!includes(
                [
                  DataappAskAIConfigInputTypeEnum.RagFiles,
                  DataappAskAIConfigInputTypeEnum.PredictionService
                ],
                _.get(values, dataAppConfigFields.inputType)
              ) &&
                dataAppType === CreateDataAppRequestDtoDataAppTypeEnum.Askai && (
                  <div className={sqDiv}>
                    <ColumnHyperlinks
                      value={_.get(values, dataAppConfigFields.allowColumnHyperLink)}
                      onChange={(e) =>
                        setValues(
                          {
                            ...values,
                            [e.target.name]: e.target.checked
                          },
                          e.target.name
                        )
                      }
                      mapping={_.get(values, dataAppConfigFields.columnsHyperlinksMapping)}
                      updateHyperLinks={updateHyperLinks}
                      updateHyperLinkError={onHyperLinkError}
                    />
                  </div>
                )}
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default EditDataAppSettings;
