import React from "react";
import { Box, Tab as MuiTab, TabProps as MuiTabProps, makeStyles } from "@material-ui/core";
import OverflowTooltip from "../OverflowTooltip";

const useStyles = makeStyles((theme) => ({
  tab: {
    padding: "2px",
    textTransform: "none",
    color: "#003656",
    fontWeight: 400,
    minHeight: "40px",
    height: "40px"
  },
  selected: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    borderRadius: "20px",
    backgroundColor: "#E7E7FF"
  },
  disabled: {
    color: "#003656 !important",
    opacity: 0.3
  },
  wrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "8px",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    margin: "0px 12px"
  },
  labelIcon: {
    "& svg": {
      marginBottom: "0px !important",
      flexShrink: 0
    }
  },
  clearIcon: {
    visibility: ({ isHovered }: any) => (isHovered ? "visible" : "hidden")
  },
  label: {
    "& svg": {
      flexShrink: 0
    }
  }
}));

interface TabProps extends MuiTabProps {
  clearIcon?: React.ReactNode;
  testId?: string;
}
export const Tab = (props: TabProps) => {
  const { label, testId, clearIcon, icon, ...restProps } = props;
  const [isHovered, setIsHovered] = React.useState(false);

  const classes = useStyles({ isHovered });

  return (
    <MuiTab
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={classes.tab}
      label={
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          maxWidth="100%"
          width="100%"
          gridGap={clearIcon ? "6px" : "0px"}>
          <Box
            display="flex"
            flexDirection="row"
            className={classes.label}
            style={{ width: clearIcon ? "80%" : "100%", gap: "6px" }}
            flexWrap="nowrap"
            alignItems="center"
            justifyContent="center">
            {icon}
            {typeof label === "string" ? <OverflowTooltip value={label} /> : label}
          </Box>
          {clearIcon && <div className={classes.clearIcon}>{clearIcon}</div>}
        </Box>
      }
      data-testid={testId}
      {...restProps}
      classes={{
        selected: classes.selected,
        disabled: classes.disabled,
        wrapper: classes.wrapper,
        labelIcon: classes.labelIcon,
        ...restProps.classes
      }}
    />
  );
};
