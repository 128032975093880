import React, { useMemo, useState } from "react";
import _ from "lodash";
import { Button, Grid, Radio } from "@material-ui/core";
import { useNavigate, generatePath } from "react-router-dom";

import NewModal from "src/components/Modal/NewModal";
import NoDataFoundDefault from "src/pages/common/NoDataFoundDefault";
import SelectSearch from "src/components/Inputs/SelectSearch";
import SomethingWentWrong from "src/components/Errors/SomethingWentWrong";
import useGetDashboardProjects from "src/hooks/api/projects/useGetProjects";
import { OverflowTooltip } from "src/components";
import { WebPaths } from "src/routing/routes";
import CommonLoader from "src/components/CommonLoader";

export const MODELS = "models";

interface IProps {
  open: boolean;
  type: "auto-create" | "import-zip";
  onClose: () => void;
  onOpenCreateProjects: () => void;
}

const DataAppProjectsModal: React.FC<IProps> = (props) => {
  const { open, type, onClose, onOpenCreateProjects } = props;
  const [selectedProjectId, setSelectedProjectId] = useState<string | null>(null);
  const [searchValue, setSearchValue] = useState("");

  const { data, isLoading, isError, refetch } = useGetDashboardProjects({ refetchOnMount: true });
  const navigate = useNavigate();

  const filtered = useMemo(() => {
    if (!searchValue) {
      return data;
    }

    return _.filter(data, ({ name }) => _.includes(_.toLower(name), _.toLower(searchValue)));
  }, [data, searchValue]);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedProjectId(event.target.value);
  };

  const handleNavigate = () => {
    navigate(generatePath(WebPaths.ProjectDashboard, { projectId: selectedProjectId }), {
      state: { type }
    });
  };

  const getChild = () => {
    if (isError) {
      return (
        <SomethingWentWrong
          heading="Error in fetching the project details"
          extra={<Button onClick={() => refetch()}>Refetch</Button>}
        />
      );
    }

    if (isLoading) {
      return <CommonLoader />;
    }

    return (
      <>
        <Grid style={{ display: "flex", gap: "10px" }}>
          <SelectSearch
            style={{ width: "45%", padding: 0, position: "relative" }}
            value={searchValue}
            onChange={setSearchValue}
          />
        </Grid>

        {_.size(filtered) === 0 ? (
          <NoDataFoundDefault
            title={
              _.size(data) === 0
                ? "No Projects found"
                : `No project found with keyword "${searchValue}"`
            }
            subTitle={
              _.size(data) === 0 ? (
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={onOpenCreateProjects}>
                  Create project
                </Button>
              ) : undefined
            }
            onClear={() => setSearchValue("")}
          />
        ) : (
          _.map(filtered, (project) => {
            return (
              <Grid
                key={project.id}
                style={{ display: "flex", alignItems: "center", gap: "8px", padding: "8px 0px" }}>
                <Radio
                  style={{ padding: 0 }}
                  checked={selectedProjectId === project.id}
                  value={project.id}
                  inputProps={{ "aria-label": project.id }}
                  onChange={handleChange}
                />
                <OverflowTooltip value={project.name} style={{ whiteSpace: "nowrap" }} />
              </Grid>
            );
          })
        )}
      </>
    );
  };

  return (
    <NewModal
      open={open}
      header="Select a project"
      width="600px"
      maxHeight="calc(100vh - 200px)"
      submitDisabled={!selectedProjectId}
      submitTooltip={selectedProjectId ? "" : "Please select a project to continue"}
      height={350}
      background="#fff"
      isDefaultButtonCustomTheming
      submitButtonLabel="Next"
      onFinish={handleNavigate}
      onClose={onClose}>
      {getChild()}
    </NewModal>
  );
};

export default DataAppProjectsModal;
