import { head, includes } from "lodash";
import { useDispatch } from "react-redux";
import { useMutation, UseMutationResult } from "@tanstack/react-query";

import { getAPIWithRethrow } from "services/Apis/Apis.service";
import { relaunchEnvironment } from "services/Apis/wrappers/environments";

import {
  EnvironmentActionsStatusEnum,
  setEnvironmentStatus,
  resetEnvironmentStatus
} from "src/stores/environmentsSlice";
import { EnvDto, EnvDtoLaunchStatusEnum } from "@rapidcanvas/rc-api-core";

interface IVariable {
  envId: string;
}

const useRelaunchEnvironment = (): UseMutationResult<unknown, unknown, IVariable, unknown> => {
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: async ({ envId }) => {
      dispatch(
        setEnvironmentStatus({ id: envId!, status: EnvironmentActionsStatusEnum.Launching })
      );

      const response = await getAPIWithRethrow(`/v2/envs/${envId}`);
      const envDetails: EnvDto | undefined = head(response);
      if (
        !includes(
          [EnvDtoLaunchStatusEnum.Success, EnvDtoLaunchStatusEnum.Launching],
          envDetails?.launchStatus
        )
      ) {
        await relaunchEnvironment(envId, false, envDetails, false);
      }

      return null;
    },
    onSettled: (__1, __2, payload) => {
      dispatch(resetEnvironmentStatus({ id: payload.envId! }));
    }
  });
};

export default useRelaunchEnvironment;
