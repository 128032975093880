import React, { Dispatch, SetStateAction } from "react";

type ContextTypes = {
  isFromDatasetPage: boolean;
  isFromArtifactsModelModal: boolean;
  setIsFromArtifactsModelModal: Dispatch<SetStateAction<boolean>>;

  scenarioId: string;

  isFetchingProjectScenarios: boolean;
};

// @ts-ignore
export const DagContext = React.createContext<ContextTypes>();
