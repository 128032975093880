import React, { useEffect, useMemo, useState } from "react";

import { useQueryClient } from "@tanstack/react-query";

import { Button, CircularProgress, Tooltip } from "@material-ui/core";

// Icons
import { TestIcon } from "icons/NewUX";
import { TrashIcon } from "icons/NewUX/TrashIcon";

import DataConnectorStatus from "./DataConnectorStatus";
import useCreateConnectCardUrl from "hooks/api/dataSources/useCreateConnectCardUrl";
import { updateConnectorsQueryData } from "src/utils/helpers";
import { DataSourcesHelperText } from "../utils/DataSources.constants";
import { getRedirectUrl } from "connectorsModule/utils/fivetran.helpers";
import { thirdPartyTypeName } from "src/pages/DataSources/utils/DataSources.constants";

import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import ConnectorAutoSync from "./ConnectorAutoSync";
import { get } from "lodash";
import DataSourceHeaderBreadcrumb from "./DataSourceHeaderBreadcrumb";
import useUpdateDataSource from "src/hooks/api/dataSources/useUpdateDataSource";
import { ToastTypes, toastWrapper } from "services/ToastClient/toastWrapper";
import { validateNameField } from "utils/helpers/form.helpers";
import { handleResponse } from "services/Apis/Apis.service";

const DataSourceHeader = (props: $TSFixMe) => {
  const {
    dataSourceTypeMetaData,
    id,
    status,
    dataSourceDetails,
    test,
    name,
    isSaving,
    isTesting,
    isTestDisabled,
    isSaveDisabled,
    isDeleteDisabled,
    saveDataSource,
    deleteDataSource,
    onManualSync,
    syncData,
    checkIsDuplicate,
    setValues,
    setDataSourceDetails,
    setNameChanged
  } = props || {};

  const queryClient = useQueryClient();

  const [shouldResetName, setShouldResetName] = useState<number | boolean>(false);

  const createConnectorCardUrl = useCreateConnectCardUrl();
  const updateDatasource = useUpdateDataSource();

  const handleEdit = () => {
    createConnectorCardUrl.mutate(
      {
        id,
        redirectUrl: getRedirectUrl(dataSourceDetails?.name)
      },
      {
        onSuccess: (result) => {
          window.open(result?.connectorCardUrl, "_self");
        }
      }
    );
  };

  useEffect(() => {
    let updatedConnectorName = "Connector";

    let i = 2;
    while (checkIsDuplicate(updatedConnectorName)) {
      updatedConnectorName = `Connector ${i}`;
      i++;
    }
    if (!dataSourceDetails?.name) {
      setValues((prev: any) => ({ ...prev, name: { ...prev.name, value: updatedConnectorName } }));
    }
  }, [dataSourceDetails]);

  const validateDatasourcetName = (value: $TSFixMe) => {
    const { isValid, error: errorMessage } = validateNameField({
      fieldName: value,
      fieldNameLabel: `data-connector name`
    });

    if (!isValid) {
      return errorMessage;
    }

    if (checkIsDuplicate(value)) {
      return DataSourcesHelperText.DataSourceNameExists;
    }

    return "";
  };

  const handleEditDataSourceName = (name?: string) => {
    const errorMessage = validateDatasourcetName(name);

    if (errorMessage) {
      handleResponse({ errorMessage });
      setShouldResetName(Date.now());
      return;
    }
    if (dataSourceDetails?.id) {
      updateDatasource.mutate(
        { id: dataSourceDetails?.id, payload: { ...dataSourceDetails, name } },
        {
          onSuccess: (dataSourceDetailsResponse) => {
            setNameChanged(true);
            setDataSourceDetails(() => dataSourceDetailsResponse);
            toastWrapper({
              type: ToastTypes.Success,
              content: "Connector Name updated successfully."
            });

            updateConnectorsQueryData({ queryClient, data: dataSourceDetailsResponse });

            setValues((prev: any) => {
              return { ...prev, name: { ...prev.name, value: name } };
            });
          },
          onError: () => {
            setShouldResetName(Date.now());
          }
        }
      );
    } else {
      setValues((prev: any) => {
        return { ...prev, name: { ...prev.name, value: name } };
      });
    }
  };

  const hideSaveAction = useMemo(() => {
    if (dataSourceTypeMetaData?.name === thirdPartyTypeName) {
      if (!!id || !!dataSourceDetails?.id) {
        return true;
      }
    }

    return false;
  }, []);

  return (
    <SubTopNavBarWrapper
      subTopNavBarLeftSection={{
        component: (
          <DataSourceHeaderBreadcrumb
            shouldResetName={shouldResetName}
            connectorName={dataSourceDetails?.name || name}
            onEditDataSourceName={handleEditDataSourceName}
          />
        )
      }}
      subTopNavBarRightSection={{
        moreOptions: [
          {
            label: "Delete",
            icon: <TrashIcon viewBox="0 0 20 20" />,
            action: deleteDataSource,
            isDisabled: isDeleteDisabled
          }
        ],
        component: (
          <>
            <DataConnectorStatus
              isWrapped={false}
              id={id}
              status={status}
              onManualSync={onManualSync}
              showManualSync
            />
            {dataSourceTypeMetaData?.name === thirdPartyTypeName && (
              <ConnectorAutoSync
                connectorId={get(dataSourceDetails, ["options", "connectorId"])}
                onManualSync={onManualSync}
                syncFrequency={syncData?.syncFrequency}
                id={id}
              />
            )}

            {dataSourceTypeMetaData?.name !== thirdPartyTypeName && (
              <Button
                size="small"
                color="primary"
                variant="outlined"
                onClick={test}
                disabled={isTestDisabled}
                data-testid="testConnector"
                startIcon={
                  isTesting ? (
                    <CircularProgress size={22} />
                  ) : (
                    <TestIcon height={24} viewBox="0 0 16 16" />
                  )
                }>
                Test
              </Button>
            )}

            {dataSourceTypeMetaData?.name === thirdPartyTypeName && (
              <Button
                size="small"
                color="primary"
                variant="outlined"
                onClick={handleEdit}
                data-testid="editConnector">
                {DataSourcesHelperText.EditConfiguration}
              </Button>
            )}

            {!hideSaveAction && (
              <Tooltip
                title={
                  !(id || dataSourceDetails?.id) && isSaveDisabled
                    ? DataSourcesHelperText.SaveActionDisabledInfo
                    : ""
                }>
                <span>
                  <Button
                    size="small"
                    color="primary"
                    variant="outlined"
                    onClick={saveDataSource}
                    startIcon={isSaving ? <CircularProgress size={16} /> : undefined}
                    disabled={isSaveDisabled}
                    data-testid="saveConnector">
                    {DataSourcesHelperText.Save}
                  </Button>
                </span>
              </Tooltip>
            )}
          </>
        )
      }}
    />
  );
};

export default DataSourceHeader;
