import React from "react";
import { makeStyles, Grid, Theme } from "@material-ui/core";

import { CopyTextIcon } from "../../CopyButton/CopyTextIcon";
import ReadOnlyCodeEditor from "src/pages/Projects/common/ReadOnlyCodeEditor";
interface StyleProps {
  isLoading: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  gridWrap: {
    position: "relative",
    display: "block"
  },
  editorGrid: {
    height: "auto"
  },
  copyCode: {
    position: "absolute",
    top: "4px",
    right: "4px",
    zIndex: 1,
    background: "#fff"
  }
}));

const CopilotMessageCodeBlock = ({
  children,
  isLoading
}: {
  children: string;
  isLoading: boolean;
}) => {
  const classes = useStyles({ isLoading });

  return (
    <Grid className={classes.gridWrap}>
      <Grid container className={classes.editorGrid}>
        {isLoading ? <code>{children}</code> : <ReadOnlyCodeEditor code={children} dynamicHeight />}
      </Grid>
      {!isLoading && (
        <Grid item className={classes.copyCode}>
          <CopyTextIcon text={children || ""} title="Copy Code" />
        </Grid>
      )}
    </Grid>
  );
};

export default React.memo(CopilotMessageCodeBlock);
