import Cancel from "@material-ui/icons/Cancel";
import React, { useState } from "react";
import { Button, Chip, IconButton, makeStyles, TextField } from "@material-ui/core";

import DefaultButton from "src/components/Buttons/DefaultButton";
import SampleDataAppZipModal from "./SampleDataAppZipsModal";
import { dataAppConfigFields } from "./CreateDataAppForm";
interface IFile {
  name: string;
  fileSize: number;
  fileType: string;
  file: File;
}

interface IProps {
  placeholder?: string;
  value: IFile | null;
  onChange: (
    e: React.ChangeEvent<{
      name?: string;
      value: IFile | null;
    }>
  ) => void;
}

const useStyles = makeStyles(() => ({
  input: {
    display: "none"
  },
  uploadText: {
    width: "100%",
    flexGrow: 1,
    "& label": {
      marginBottom: 0,
      "&.MuiInputLabel-root.MuiInputLabel-outlined": {
        width: "calc(100% - 32px) !important"
      }
    },
    " & .MuiFormHelperText-root": {
      color: "rgba(76, 175, 80, 1)"
    }
  }
}));

const DataAppUploadZip: React.FC<IProps> = (props) => {
  const { value, placeholder, onChange } = props;

  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const fileName = value?.name ?? "";

  const handleFilesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.[0]) {
      const file = e.target.files[0];
      const newValue: IFile = {
        name: file.name,
        fileSize: file.size,
        fileType: file.type,
        file
      };

      onChange({
        target: {
          name: dataAppConfigFields.zipFile,
          value: newValue
        }
      } as any);
      e.target.value = "";
    }
  };

  const handleShowSample = () => {
    setOpen(true);
  };

  const handleClick = () => {
    onChange({
      target: {
        name: dataAppConfigFields.zipFile,
        value: null
      }
    } as any);
  };

  return (
    <div>
      <input
        accept=".zip"
        className={classes.input}
        id="dataApp-zip-file-upload"
        type="file"
        onChange={handleFilesChange}
      />
      <TextField
        id="upload"
        name="upload"
        label={placeholder ?? "Upload Zip File *"}
        value={fileName}
        className={classes.uploadText}
        disabled
        size="small"
        helperText={fileName ? "Upload Successful" : ""}
        data-testid="dataapp-zip-upload-file-input"
        variant="outlined"
        InputLabelProps={{
          shrink: !!fileName
        }}
        InputProps={{
          endAdornment: (
            <>
              {fileName ? (
                <>
                  <Chip style={{ color: "#008F1D", background: "#C4FFD0" }} label="Uploaded" />
                  <IconButton size="small" onClick={handleClick}>
                    <Cancel />
                  </IconButton>
                </>
              ) : (
                <label htmlFor="dataApp-zip-file-upload">
                  <DefaultButton
                    variant="outlined"
                    color="primary"
                    component="span"
                    size="small"
                    isCustomTheming={false}>
                    Upload
                  </DefaultButton>
                </label>
              )}
            </>
          )
        }}
      />
      <Button color="primary" onClick={handleShowSample}>
        Sample Zip Files
      </Button>
      {open && <SampleDataAppZipModal open={open} onClose={() => setOpen(false)} />}
    </div>
  );
};

export default DataAppUploadZip;
