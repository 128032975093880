import React, { useMemo, useState } from "react";
import { filter, find, forEach, size, some } from "lodash";
import { useQueryClient } from "@tanstack/react-query";
import { useParams, useNavigate } from "react-router-dom";
import { Button, CircularProgress, makeStyles, Tooltip } from "@material-ui/core";
import _ from "lodash";

import {
  DescriptionCharacterLimit,
  DescriptionCharacterLimitMessage
} from "src/pages/private/ProjectsModule/utils";
import CommonLoader from "src/components/CommonLoader";
import { dataAppConfigFields } from "./CreateDataAppForm";
import CreateDataAppHeader from "./CreateDataAppHeader";
import {
  AppTemplateDto,
  CreateDataAppRequestDtoDataAppTypeEnum,
  DataappAskAIConfig,
  DataappAskAIConfigDataAppPermissionsEnum,
  DataappAskAIConfigDataSharingStrategyEnum,
  DataappAskAIConfigInputTypeEnum,
  DataappAskAIConfigLlmTypeEnum,
  DFSRunConfigGroupDto,
  PublishAppTemplateRequestDtoAppTypeEnum,
  PublishAppTemplateRequestDtoSourceEnum,
  UpdateDataAppRequestDtoPythonVersionEnum
} from "@rapidcanvas/rc-api-core";
import { DataAppType } from "src/pages/DataApps/DataApps.type";
import { QUERY_KEY_PROJECT_DETAILS } from "src/hooks/api/projects/useProjectDetails";
import { useForm } from "hooks/useForm";
import { EnvironmentsTypes } from "environmentsModule/utils/Environments.constants";
import { useGetRole } from "hooks/useGetRole";
import { handleResponse } from "services/Apis/Apis.service";
import { validateNameField } from "utils/helpers/form.helpers";
import { useCreateDataAppMutation } from "hooks/api/dataapps/useCreateDataAppMutation";
import useUploadDataAppZip from "hooks/api/dataapps/useUploadDataAppZip";
import { validateDataAppEnvConfig } from "./DataAppEnvironment";
import { DEFAULT_DATAAPP_NAME } from "pages/DataApps/utils/DataApps.constants";
import CreateDataAppTabs from "./CreateDataAppTabs";
import { WebPaths } from "routing/routes";

export interface IRecipes extends DFSRunConfigGroupDto {
  allowed: boolean;
  helpText: string;
}

interface IProps {
  allDataApps: DataAppType[];
  isLoading: boolean;
  dataAppType: "auto-create" | "import-zip";
  binaryClassRecipes?: DFSRunConfigGroupDto[];
  appTemplates?: AppTemplateDto[];
  dataApps: DataAppType[];
  onCancel: () => void;
  recipeLoading: boolean;
}

const useStyles = makeStyles({
  wrapper: {
    padding: "16px 16px 0px 16px",
    marginLeft: 60
  }
});

const CreateDataApp: React.FC<IProps> = (props) => {
  const {
    allDataApps,
    dataApps,
    binaryClassRecipes,
    appTemplates,
    isLoading,
    recipeLoading,
    dataAppType,
    onCancel
  } = props;

  const queryClient = useQueryClient();
  const createDataAppMutation = useCreateDataAppMutation();
  const uploadZip = useUploadDataAppZip();
  const { projectId } = useParams();
  const classes = useStyles();
  const { isDataAppPowerUser } = useGetRole();
  const navigate = useNavigate();
  const [hyperlinkError, setHyperlinkError] = useState<string>("");

  const dataAppRecipeIds = useMemo(
    () => dataApps?.map((dataApp) => dataApp.groupId).filter((id) => !!id),
    [dataApps]
  );

  const askAIDataAppTemplateId = useMemo(
    () => find(appTemplates, { name: "askai" })?.id,
    [appTemplates]
  );

  const name = useMemo(() => {
    const title = DEFAULT_DATAAPP_NAME;

    const isNameTaken = (candidate: string) =>
      some(allDataApps, ({ displayName, name }) => displayName === candidate || name === candidate);

    if (!isNameTaken(title)) {
      return title;
    }

    let count = 1;
    let newName = `${title} ${count}`;

    while (isNameTaken(newName)) {
      count += 1;
      newName = `${title} ${count}`;
    }

    return newName;
  }, [allDataApps]);

  const { recipes, allowedRecipes } = useMemo(() => {
    const final: (DFSRunConfigGroupDto & { allowed: boolean; helpText: string })[] = [];
    const all: (DFSRunConfigGroupDto & { allowed: boolean; helpText: string })[] = [];

    forEach(binaryClassRecipes, (recipe) => {
      const isAllowed = !dataAppRecipeIds?.includes(recipe.id);
      const val = {
        ...recipe,
        allowed: isAllowed,
        helpText: !isAllowed ? "Recipe already has dataApp associated with it" : ""
      };
      all.push(val);
      if (isAllowed) {
        final.push(val);
      }
    });

    return { recipes: all, allowedRecipes: final };
  }, [binaryClassRecipes, dataAppRecipeIds]);

  const { values, handleInputChange, setValues } = useForm({
    [dataAppConfigFields.dataAppName]: name,
    [dataAppConfigFields.description]: "",
    [dataAppConfigFields.recipeId]: allowedRecipes?.length === 1 ? allowedRecipes?.[0]?.id : "",
    [dataAppConfigFields.llmType]: DataappAskAIConfigLlmTypeEnum.OpenaiGpt4O,
    [dataAppConfigFields.inputType]: DataappAskAIConfigInputTypeEnum.ProjectCanvas,
    [dataAppConfigFields.dataSharingStrategy]: DataappAskAIConfigDataSharingStrategyEnum.SampleData,
    [dataAppConfigFields.enableCache]: false,
    [dataAppConfigFields.isPrivate]: false,
    [dataAppConfigFields.metadata]: "",
    [dataAppConfigFields.customEnvId]: "",
    [dataAppConfigFields.zipEnv]: {
      name: EnvironmentsTypes.Small,
      cores: "",
      diskInGbs: "",
      memInGbs: ""
    },
    [dataAppConfigFields.dataAppPermissions]: _.map(DataappAskAIConfigDataAppPermissionsEnum),
    [dataAppConfigFields.appType]: PublishAppTemplateRequestDtoAppTypeEnum.Streamlit,
    [dataAppConfigFields.allowColumnHyperLink]: false,
    [dataAppConfigFields.columnsHyperlinksMapping]: "",
    [dataAppConfigFields.pythonVersion]: UpdateDataAppRequestDtoPythonVersionEnum._310,
    [dataAppConfigFields.suggestionPrompts]: []
  });

  const type = useMemo(() => {
    if (
      _.get(values, dataAppConfigFields.inputType) ===
      CreateDataAppRequestDtoDataAppTypeEnum.RapidModel
    ) {
      return CreateDataAppRequestDtoDataAppTypeEnum.RapidModel;
    } else {
      return CreateDataAppRequestDtoDataAppTypeEnum.Askai;
    }
  }, [values]);

  const { askAIDataApps } = useMemo(() => {
    const filtered = filter(dataApps, { appTemplateId: askAIDataAppTemplateId });

    return {
      askAIDataApps: filtered
    };
  }, [dataApps, askAIDataAppTemplateId]);

  const disabledAskAICreateActionMessage = useMemo(() => {
    if (!askAIDataAppTemplateId) {
      return "It seems that the associated appTemplate for this data app does not exist. Please contact the administrator for assistance";
    }

    return "";
  }, [askAIDataAppTemplateId, askAIDataApps]);

  const disabledAddDataAppActionMessage = useMemo(() => {
    if (size(binaryClassRecipes) === 0) {
      return "This is applicable only for successfully built Binary Classification/Regression/Binary Experimental/Multiclass Classification types of Rapid Model Recipes.";
    }

    return "";
  }, [binaryClassRecipes]);

  const handleSuccess = () => {
    queryClient.invalidateQueries([QUERY_KEY_PROJECT_DETAILS]);
    if (isDataAppPowerUser) {
      navigate(WebPaths.Dataapps);
    } else {
      onCancel();
    }
  };

  const errorMsgs = useMemo(() => {
    const name = _.trim(_.get(values, dataAppConfigFields.dataAppName));

    const { error } = validateNameField({
      fieldName: name,
      fieldNameLabel: "dataApp name"
    });

    return {
      [dataAppConfigFields.dataAppName]: name ? error : "",
      [dataAppConfigFields.description]:
        _.size(_.get(values, dataAppConfigFields.description)) > DescriptionCharacterLimit
          ? DescriptionCharacterLimitMessage
          : ""
    };
  }, [values]);

  const disabledTooltipMsg = useMemo(() => {
    const name = _.trim(_.get(values, dataAppConfigFields.dataAppName));

    if (disabledAskAICreateActionMessage) {
      return disabledAskAICreateActionMessage;
    }

    if (dataAppType === "import-zip") {
      if (!name) {
        return "Please input DataApp Name to enable";
      }

      if (!!_.get(errorMsgs, dataAppConfigFields.dataAppName)) {
        return "Please use valid DataApp Name to enable";
      }

      if (!!_.get(errorMsgs, dataAppConfigFields.description)) {
        return "Description charater limit exceeded";
      }

      if (!_.get(values, dataAppConfigFields.zipFile)) {
        return "Please upload a zip to enable";
      }

      const zipEnv = _.get(values, dataAppConfigFields.zipEnv);
      const { cores, diskInGbs, memInGbs } = zipEnv;
      if (zipEnv?.name === EnvironmentsTypes.Custom) {
        if (
          !cores ||
          !diskInGbs ||
          !memInGbs ||
          validateDataAppEnvConfig("Cores", cores, 12) ||
          validateDataAppEnvConfig("Memory", memInGbs, 48) ||
          validateDataAppEnvConfig("Disk Space", diskInGbs, 100)
        ) {
          return "Please select valid environment configuration to enable";
        }
      }

      return "";
    }

    if (type === CreateDataAppRequestDtoDataAppTypeEnum.Askai) {
      if (!name) {
        return "Please input DataApp Name to enable";
      }

      if (!!_.get(errorMsgs, dataAppConfigFields.dataAppName)) {
        return "Please use valid DataApp Name to enable";
      }
      if (!!_.get(errorMsgs, dataAppConfigFields.description)) {
        return "Description charater limit exceeded";
      }

      if (!_.get(values, dataAppConfigFields.inputType)) {
        return "Please select an input to enable";
      }

      if (
        !_.get(values, dataAppConfigFields.customEnvId) &&
        _.get(values, dataAppConfigFields.inputType) !==
          DataappAskAIConfigInputTypeEnum.PredictionService
      ) {
        return "Please wait until the environment is loaded for this DataApp's AskAI";
      }

      if (
        _.get(values, dataAppConfigFields.inputType) ===
          DataappAskAIConfigInputTypeEnum.JobCanvas &&
        !_.get(values, dataAppConfigFields.projectRunId)
      ) {
        return "Please select a scheduler to enable";
      }

      if (!!hyperlinkError) {
        return hyperlinkError;
      }

      return "";
    } else {
      const recipeId = _.get(values, dataAppConfigFields.recipeId);
      if (!name || !recipeId) {
        return "Please input DataApp Name and select Recipe to enable";
      }

      if (!!_.get(errorMsgs, dataAppConfigFields.dataAppName)) {
        return "Please use valid DataApp Name to enable";
      }

      if (!_.get(values, dataAppConfigFields.customEnvId)) {
        return "Please wait until the environment is loaded for this DataApp's AskAI";
      }

      if (!!hyperlinkError) {
        return hyperlinkError;
      }

      return "";
    }
  }, [values, type, errorMsgs, disabledAskAICreateActionMessage, dataAppType, hyperlinkError]);

  const handleSubmit = () => {
    if (projectId) {
      const hyperlinksStr = dataAppConfigFields.allowColumnHyperLink
        ? _.get(values, dataAppConfigFields.columnsHyperlinksMapping)
        : "";
      if (dataAppType === "import-zip") {
        let md = _.get(values, dataAppConfigFields.metadata);
        let metadata: any;
        if (md) {
          try {
            metadata = JSON.parse(md);
          } catch {
            handleResponse({
              errorMessage: "Unable to parse JSON metadata. Please use valid JSON"
            });
            return;
          }
        }

        uploadZip.mutate(
          {
            payload: {
              name: _.replace(
                `${_.get(values, dataAppConfigFields.dataAppName)}_template`,
                / /g,
                "_"
              ),
              appType: _.get(values, dataAppConfigFields.appType)
            },
            file: _.get(values, [dataAppConfigFields.zipFile, "file"]),
            source: PublishAppTemplateRequestDtoSourceEnum.Tenant,
            appType: _.get(values, dataAppConfigFields.appType)
          },
          {
            onSuccess: (id: string | null) => {
              if (!id) {
                return;
              }

              createDataAppMutation.mutate({
                dataAppName: _.get(values, dataAppConfigFields.dataAppName),
                description: _.get(values, dataAppConfigFields.description),
                appTemplateId: id,
                projectId,
                metadata: _.merge(
                  {},
                  metadata,
                  hyperlinksStr ? { columns_hyperlinks_mapping: hyperlinksStr } : {}
                ),
                pythonVersion: _.get(values, dataAppConfigFields.pythonVersion),
                envType: _.get(values, [dataAppConfigFields.zipEnv, "name"]),
                iconUrl: _.get(values, dataAppConfigFields.imageBase64),
                cores: _.get(values, [dataAppConfigFields.zipEnv, "cores"])
                  ? _.toNumber(_.get(values, [dataAppConfigFields.zipEnv, "cores"]))
                  : undefined,
                diskInGbs: _.get(values, [dataAppConfigFields.zipEnv, "diskInGbs"])
                  ? _.toNumber(_.get(values, [dataAppConfigFields.zipEnv, "diskInGbs"]))
                  : undefined,
                memInMbs: _.get(values, [dataAppConfigFields.zipEnv, "memInGbs"])
                  ? _.toNumber(_.get(values, [dataAppConfigFields.zipEnv, "memInGbs"])) * 1024
                  : undefined,
                dataAppType: CreateDataAppRequestDtoDataAppTypeEnum.Custom,
                onSuccess: handleSuccess
              });
            }
          }
        );
        return;
      }

      if (type === CreateDataAppRequestDtoDataAppTypeEnum.Askai) {
        let askAIConfig: DataappAskAIConfig;
        const hyperlinksStr = dataAppConfigFields.allowColumnHyperLink
          ? _.get(values, dataAppConfigFields.columnsHyperlinksMapping)
          : "";
        if (askAIDataAppTemplateId) {
          if (
            _.get(values, dataAppConfigFields.inputType) ===
            DataappAskAIConfigInputTypeEnum.PredictionService
          ) {
            askAIConfig = {
              inputType: _.get(values, dataAppConfigFields.inputType),
              allowColumnHyperLink: _.get(values, dataAppConfigFields.allowColumnHyperLink),
              systemMessage: _.get(values, dataAppConfigFields.systemMessage),
              suggestionPrompts: _.get(values, dataAppConfigFields.suggestionPrompts),
              dataAppPermissions: _.get(values, dataAppConfigFields.dataAppPermissions)
            };
          } else {
            askAIConfig = {
              llmType: _.get(values, dataAppConfigFields.llmType),
              inputType: _.get(values, dataAppConfigFields.inputType),
              systemMessage: _.get(values, dataAppConfigFields.systemMessage),
              enableCache: _.get(values, dataAppConfigFields.enableCache),
              allowColumnHyperLink: _.get(values, dataAppConfigFields.allowColumnHyperLink),
              dataAppPermissions: _.get(values, dataAppConfigFields.dataAppPermissions),
              dataSharingStrategy:
                _.get(values, dataAppConfigFields.inputType) !==
                DataappAskAIConfigInputTypeEnum.RagFiles
                  ? _.get(values, dataAppConfigFields.dataSharingStrategy)
                  : undefined,
              customEnvId: _.get(values, dataAppConfigFields.customEnvId),
              projectRunId: _.get(values, dataAppConfigFields.projectRunId),
              suggestionPrompts: _.get(values, dataAppConfigFields.suggestionPrompts)
            };
          }

          createDataAppMutation.mutate({
            dataAppName: _.get(values, dataAppConfigFields.dataAppName),
            description: _.get(values, dataAppConfigFields.description),
            appTemplateId: askAIDataAppTemplateId,
            projectId,
            metadata: hyperlinksStr
              ? {
                  columns_hyperlinks_mapping: hyperlinksStr
                }
              : {},
            envType: EnvironmentsTypes.Small,
            iconUrl: _.get(values, dataAppConfigFields.imageBase64),
            dataAppType: type,
            askAIConfig,
            isPrivate: _.includes([true, "true"], _.get(values, dataAppConfigFields.isPrivate)),
            onSuccess: handleSuccess
          });
        } else {
          handleResponse({
            errorMessage: "Please make sure to publish ask AI template before creating the app"
          });
        }
        return;
      }

      if (type === CreateDataAppRequestDtoDataAppTypeEnum.RapidModel) {
        const currRecipe = recipes.find(
          (recipe) => recipe.id === _.get(values, dataAppConfigFields.recipeId)
        );
        const currentAppTemplate = appTemplates?.find(
          (appTemplate: any) =>
            appTemplate.autoMlProblemType?.toLowerCase() === currRecipe?.metadata?.problemType &&
            appTemplate.buildStatus
        );
        if (!currentAppTemplate) {
          handleResponse({ errorMessage: `App Template not found for the specified recipe` });
        }

        projectId &&
          currentAppTemplate &&
          currRecipe &&
          currentAppTemplate.id &&
          createDataAppMutation.mutate({
            dataAppName: _.get(values, dataAppConfigFields.dataAppName),
            description: _.get(values, dataAppConfigFields.description),
            projectId,
            envType: EnvironmentsTypes.Small,
            iconUrl: _.get(values, dataAppConfigFields.imageBase64),
            appTemplateId: currentAppTemplate.id,
            recipeId: currRecipe.id,
            metadata: _.merge(
              {},
              currRecipe?.runConfigs?.[0]?.variables,
              hyperlinksStr ? { columns_hyperlinks_mapping: hyperlinksStr } : {}
            ),
            dataAppType: type,
            askAIConfig: {
              systemMessage: _.get(values, dataAppConfigFields.systemMessage),
              customEnvId: _.get(values, dataAppConfigFields.customEnvId)
            },
            onSuccess: handleSuccess
          });
        return;
      }
    }
  };

  if (isLoading) {
    return <CommonLoader />;
  }

  return (
    <div className={classes.wrapper}>
      <CreateDataAppHeader
        extra={
          <div style={{ display: "flex", gap: "8px" }}>
            <Button variant="outlined" color="primary" size="small" onClick={onCancel}>
              Cancel
            </Button>
            <Tooltip title={disabledTooltipMsg ?? ""}>
              <span>
                <Button
                  size="small"
                  disabled={
                    !!disabledTooltipMsg || createDataAppMutation.isLoading || uploadZip.isLoading
                  }
                  variant="contained"
                  startIcon={
                    createDataAppMutation.isLoading || uploadZip.isLoading ? (
                      <CircularProgress size={16} />
                    ) : undefined
                  }
                  data-testid="createDatAppBtn"
                  color="primary"
                  onClick={handleSubmit}>
                  Create
                </Button>
              </span>
            </Tooltip>
          </div>
        }
        dataAppName={_.get(values, dataAppConfigFields.dataAppName) ?? ""}
        onSave={(newVal: string) =>
          setValues((prev) => ({ ...prev, [dataAppConfigFields.dataAppName]: newVal }))
        }
        onShowDataApps={onCancel}
      />
      <CreateDataAppTabs
        recipes={recipes}
        errorMsgs={errorMsgs}
        values={values}
        type={dataAppType}
        disabledAddDataAppActionMessage={disabledAddDataAppActionMessage}
        disabledAskAICreateActionMessage={disabledAskAICreateActionMessage}
        recipeLoading={recipeLoading}
        setValues={setValues}
        onInputChange={handleInputChange}
        onHyperLinkError={setHyperlinkError}
      />
    </div>
  );
};

export default CreateDataApp;
