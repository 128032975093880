// Packages
import axios from "axios";
import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { get, head, toLower } from "lodash";

// Utils
import api from "services/AxiosClient/AxiosClient";
import fileTypes from "constants/fileTypes.constants";
import { download as downloadUtil } from "utils/helpers/file.helpers";

// Open API
import { DownloadEntityDto } from "@rapidcanvas/rc-api-core";

export const enum UseDownloadDatasetFileQueryKeys {
  DownloadDatasetFile = "downloadDatasetFile"
}

interface IDownloadDatasetFileProps extends UseQueryOptions<unknown, unknown, any> {
  fileId?: string;
  fileName?: string;
  fileType?: string;
  payload?: DownloadEntityDto;
}

const useDownloadDatasetFile = (props: IDownloadDatasetFileProps): UseQueryResult<any> => {
  const { fileId, fileName, fileType, payload, ...options } = props;

  const isInvalidProps = !fileId || !payload || !fileName || !fileType;

  return useQuery({
    queryKey: [
      UseDownloadDatasetFileQueryKeys.DownloadDatasetFile,
      fileId,
      payload?.projectRunEntryId
    ],
    queryFn: async () => {
      if (isInvalidProps) {
        throw new Error("Invalid payload!");
      }

      const { data } = await api.EntityControllerApi.getDownloadUrl(fileId, payload);
      const response = await axios.get(data, { responseType: "arraybuffer" });

      downloadUtil(
        response?.data,
        fileName,
        head(get(fileTypes, [toLower(fileType), "mimeTypes"], []))
      );
    },
    ...options
  });
};

export default useDownloadDatasetFile;
