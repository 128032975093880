import { RecipeStatuses } from "projectsModule/utils/Projects.constants";
import { useQuery } from "@tanstack/react-query";

import { getTransformGroupWithRethrow } from "services/Apis/wrappers";
import { Recipe } from "src/types";

export const usePollRecipe = ({
  recipeId,
  scenarioId,
  enabled
}: {
  recipeId?: string | null;
  scenarioId?: string;
  enabled: boolean;
}) => {
  return useQuery<Recipe>({
    queryKey: ["recipe", recipeId, "polling"],
    queryFn: async () => {
      const createdGroup = await getTransformGroupWithRethrow(recipeId, scenarioId);
      const recipe: Recipe = Array.isArray(createdGroup) ? createdGroup[0] : createdGroup;
      return recipe;
    },
    refetchInterval: (data) => (data?.status === RecipeStatuses.Running ? 5000 : false),
    enabled,
    cacheTime: 0
  });
};
