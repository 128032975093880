export const capitalize = (term = "") => {
  if (term === "" || term === null) return term;

  const lowerString = term.toLowerCase();
  return lowerString?.charAt(0)?.toUpperCase() + lowerString?.slice(1);
};

export const capitalizeFirstLetter = (string: string) => {
  if (!string) return string;
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
};

export const ellipses = ({ text = "", position = "center", length = 25 }) => {
  if (!text || text.length <= length) return text;

  let output = "";
  const left = Math.ceil((length - 3) / 2);
  const right = Math.floor((length - 3) / 2);

  switch (position) {
    case "left":
      output = `...${text.substring(text.length - (length - 3))}`;
      break;

    case "right":
      output = `${text.substring(0, length - 3)}...`;
      break;

    default:
      output = `${text.substring(0, left)}...${text.substring(text.length - right)}`;
      break;
  }
  return output;
};

export const trimLeadingSlash = ({ text = "" }) => {
  if (!text) return text;
  return text.replace(/\/+$/g, "");
};

export const leadingTextInPath = ({ text = "", delimiter = "." }): $TSFixMe => {
  if (!text) return text;

  return text
    .split(delimiter)
    .filter((part: any) => !!part)
    .at(-1);
};
