import Img1 from "src/assets/images/dataAppImages/dataApp-thumbnail1.jpg";
import Img2 from "src/assets/images/dataAppImages/dataApp-thumbnail2.jpg";
import Img3 from "src/assets/images/dataAppImages/dataApp-thumbnail3.jpg";
import Img4 from "src/assets/images/dataAppImages/dataApp-thumbnail4.jpg";
import Img5 from "src/assets/images/dataAppImages/dataApp-thumbnail5.jpg";
import Img6 from "src/assets/images/dataAppImages/dataApp-thumbnail6.jpg";
import Img7 from "src/assets/images/dataAppImages/dataApp-thumbnail7.jpg";
import Img8 from "src/assets/images/dataAppImages/dataApp-thumbnail8.jpg";
import Img9 from "src/assets/images/dataAppImages/dataApp-thumbnail9.jpg";
import Img10 from "src/assets/images/dataAppImages/dataApp-thumbnail10.jpg";

export const offlineImages = [Img1, Img2, Img3, Img4, Img5, Img6, Img7, Img8, Img9, Img10];

export const cdnImages = [
  "dataApp-thumbnail1.jpg",
  "dataApp-thumbnail2.jpg",
  "dataApp-thumbnail3.jpg",
  "dataApp-thumbnail4.jpg",
  "dataApp-thumbnail5.jpg",
  "dataApp-thumbnail6.jpg",
  "dataApp-thumbnail7.jpg",
  "dataApp-thumbnail8.jpg",
  "dataApp-thumbnail9.jpg",
  "dataApp-thumbnail10.jpg"
];

const publicPath = process.env.PUBLIC_PATH;
const environment = process.env.ENVIRONMENT;
export const cdnImagePath =
  !!publicPath && !!environment ? `${publicPath}/${environment}/images/dataAppImages` : "";
