import React, { useMemo } from "react";

// Packages
import { generatePath, Link } from "react-router-dom";
import { size } from "lodash";

// MUI
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

// Open API
import { EnvDto } from "@rapidcanvas/rc-api-core";

// Utils
import { WebPaths } from "src/routing/routes";

// Components
import { OverflowTooltip } from "src/components";
import Chip from "./Chip";

interface IProps {
  environment: EnvDto;
}

const useStyles = makeStyles((theme) => ({
  projectsChipContainer: { position: "relative", minHeight: theme.spacing(3) },
  link: {
    display: "inline-flex",
    color: "#003656",
    "&:hover": {
      color: "#003656",
      textDecoration: "underline"
    }
  }
}));

const ProjectsField: React.FC<IProps> = (props) => {
  const { environment } = props;

  const classes = useStyles();

  const isMultipleProjects = useMemo(
    () => size(environment?.projectDtos) - 1 > 0,
    [environment?.projectDtos]
  );

  const value = useMemo(() => {
    const firstProject = (
      <OverflowTooltip
        style={{ whiteSpace: "nowrap" }}
        value={environment?.projectDtos?.[0]?.name}
        data-testid="envCardFirstProjectNameOverflowTooltip"
      />
    );

    if (!!environment?.projectDtos?.[0]?.id) {
      return (
        <Link
          className={classes.link}
          to={generatePath(WebPaths.Dag, { projectId: environment?.projectDtos?.[0]?.id })}
          onClick={(event) => event?.stopPropagation()}
          style={{ width: !!isMultipleProjects ? "calc(100% - 45px)" : "100%" }}
          data-testid="envCardFirstProjectNameAction">
          {firstProject}
        </Link>
      );
    }

    return firstProject;
  }, [environment?.projectDtos, isMultipleProjects]);

  return (
    <Grid
      container
      wrap="nowrap"
      alignItems="center"
      justifyContent="space-between"
      className={classes.projectsChipContainer}>
      {value}

      {!!isMultipleProjects && <Chip environment={environment} />}
    </Grid>
  );
};

export default ProjectsField;
