import React, { useCallback, useState } from "react";

// Packages
import { size } from "lodash";

// MUI
import MuiChip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";

// Open API
import { EnvDto } from "@rapidcanvas/rc-api-core";

// Components
import Popover from "./Popover";

interface IProps {
  environment: EnvDto;
}

const useStyles = makeStyles({
  projectsChip: {
    position: "absolute",
    right: 0,
    cursor: "pointer",
    backgroundColor: "white",
    "&:hover, &:active, &:focus": {
      backgroundColor: "white !important"
    }
  }
});

const Chip: React.FC<IProps> = (props) => {
  const { environment } = props;

  const classes = useStyles();

  const [projectsAnchorEl, setProjectsAnchorEl] = useState<HTMLElement | null>(null);

  const handleProjectsOpen = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event?.stopPropagation();
    event?.preventDefault();
    setProjectsAnchorEl(event.currentTarget);
  }, []);

  const handleProjectsClose = useCallback(() => {
    setProjectsAnchorEl(null);
  }, []);

  return (
    <>
      {Boolean(projectsAnchorEl) && (
        <Popover
          environment={environment}
          anchorEl={projectsAnchorEl}
          onClose={handleProjectsClose}
        />
      )}

      <MuiChip
        variant="outlined"
        size="small"
        color="primary"
        label={`+${size(environment?.projectDtos) - 1}`}
        onClick={handleProjectsOpen}
        className={classes.projectsChip}
        data-testid="envCardProjectsAction"
      />
    </>
  );
};

export default Chip;
