import React, { useEffect, useState } from "react";
import {
  Button,
  Menu,
  MenuItem,
  Typography,
  CircularProgress,
  Grid,
  makeStyles
} from "@material-ui/core";
import _, { map } from "lodash";
import useSetSynchFrequency from "src/hooks/api/dataSources/useSetSynchFrequency";
import clsx from "clsx";
import { toastWrapper } from "services/ToastClient/toastWrapper";
import { syncDataSource } from "services/Apis/wrappers/dataSources";

interface IProps {
  onManualSync: any;
  syncFrequency: number;
  connectorId: string;
  id: string;
}

const useStyles = makeStyles({
  menuTitle: {
    fontSize: "14px"
  },
  selectedMenu: {
    backgroundColor: "#E6F5FF !important"
  }
});

const menuItems = [
  {
    title: "Manual Sync",
    key: "manual",
    value: 0
  },
  {
    title: " Every 1 Hour",
    key: "1-hr",
    value: 60
  },
  {
    title: " Every 2 Hour",
    key: "2-hr",
    value: 120
  },
  {
    title: " Every 6 Hour",
    key: "6-hr",
    value: 360
  },
  {
    title: " Every 12 Hour",
    key: "12-hr",
    value: 720
  },
  {
    title: " Every 24 Hour",
    key: "24-hr",
    value: 1440
  }
];

const ConnectorAutoSync: React.FC<IProps> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [syncFrequency, setSyncFrequency] = useState<number>(props.syncFrequency);
  const changeSyncFrequency = useSetSynchFrequency();
  const classes = useStyles();

  useEffect(() => {
    if (props.syncFrequency) {
      setSyncFrequency(props.syncFrequency);
    }
  }, [props.syncFrequency]);

  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (item: any) => {
    const oldValue = syncFrequency;
    setSyncFrequency(item.value);
    changeSyncFrequency.mutate(
      { connectorId: props.connectorId, syncFrequency: item.value },
      {
        onSuccess: () => {
          if (item.value === 0) {
            syncDataSource(props.id);
            props.onManualSync();
          }
          setAnchorEl(null);

          toastWrapper({
            type: "success",
            content:
              item.value === 0
                ? "Manual sync is successfully triggered"
                : "Auto sync frequency has been successfully configured."
          });
        },
        onError: () => {
          setSyncFrequency(oldValue);
        }
      }
    );
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        size="small"
        color="primary"
        variant="outlined"
        data-testid="connectorSync"
        disabled={_.isUndefined(props.syncFrequency)}
        onClick={handleClick}>
        Sync
      </Button>
      <Menu
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        getContentAnchorEl={null}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        PaperProps={{ style: { width: "200px" } }}
        onClose={handleClose}>
        {map(menuItems, (item) => (
          <MenuItem
            key={item.key}
            disabled={changeSyncFrequency.isLoading}
            selected={item.value === syncFrequency}
            onClick={() => handleMenuItemClick(item)}
            className={clsx({ [classes.selectedMenu]: item.value === syncFrequency })}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Typography className={classes.menuTitle}>{item.title}</Typography>
              {changeSyncFrequency.isLoading && item.value === syncFrequency && (
                <CircularProgress size={22} />
              )}
            </Grid>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ConnectorAutoSync;
