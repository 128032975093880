/* eslint-disable no-unused-vars */
import { useMutation } from "@tanstack/react-query";
import { v4 as uuidv4 } from "uuid";

import {
  createTemplateWithRethrow,
  saveTransformByIdWithRethrow,
  getTransformGroupWithRethrow
} from "services/Apis/wrappers";
import { Recipe } from "src/types";
import api from "services/AxiosClient/AxiosClient";
import { DFSRunConfigGroupDtoStatusEnum } from "@rapidcanvas/rc-api-core";
import { get } from "lodash";

export const useSaveCodeRecipeMutationV2 = () => {
  return useMutation({
    mutationFn: async ({
      projectId,
      code,
      recipe,
      codeHistoryId,
      markUnbuit
    }: {
      code: string;
      projectId: string;
      recipe: Recipe | null;
      codeHistoryId: string | null;
      markUnbuit: boolean;
    }) => {
      const templateGroupPayload = {
        name: uuidv4(),
        code,
        projectId,
        baseTransforms: [{ condition: null, type: "python", params: {}, runConfigId: null }],
        type: "CODE"
      };

      const templateResponse: $TSFixMe = await createTemplateWithRethrow(templateGroupPayload);

      const payload = {
        name: uuidv4(),
        groupId: recipe?.id,
        templateId: templateResponse.id,
        codeHistoryId
      };
      const saveTransformResponse = await saveTransformByIdWithRethrow(payload);

      const transformGroupsResponse = await getTransformGroupWithRethrow(recipe?.id);
      const transformGroupResponse = get(transformGroupsResponse, "[0]", {});
      if (!!recipe?.id && markUnbuit && transformGroupResponse) {
        await api.DfsRunConfigGroupControllerV2Api.changeRecipeStatusToUnbuiltSafely(recipe?.id);
        transformGroupResponse.status = DFSRunConfigGroupDtoStatusEnum.Unbuilt;
      }
      return {
        templateResponse,
        saveTransformResponse,
        transformGroupResponse
      };
    },
    cacheTime: 0
  });
};
