import { useMutation } from "@tanstack/react-query";

import { NotebookTypes } from "constants/index";
import { RECENT_SEARCH_KEY } from "src/layout/NavBars/utils/GlobalSearch.constants";
import { UserTenantType } from "src/types/Tenant.type";
import { WebPaths } from "src/routing/routes";
import { logNotebooksOut } from "services/Notebook/Notebook.service";
import { postAPIWithRethrow } from "services/Apis/Apis.service";
import { useAuthSetAuthResponse } from "src/hooks/useAuthSetAuthResponse";

type Props = {
  onSuccess?: (data: UserTenantType) => void;
};

export const useTenantSwitchMutation = ({ onSuccess }: Props = {}) => {
  const { setAuthResponse } = useAuthSetAuthResponse();

  return useMutation({
    mutationFn: ({ tenantId }: { tenantId: string }) => {
      return postAPIWithRethrow(`/switch`, { tenantId }).then(async (data: UserTenantType) => {
        // @TODO: This needs to be centralized.
        if (data.notebookType !== NotebookTypes.Tenant) {
          await logNotebooksOut();
        }
        setAuthResponse({
          data
        });
        await onSuccess?.(data);
        return data;
      });
    },
    onSuccess: () => {
      window.location.href = WebPaths.Dashboard;
      window.history.pushState(null, "", WebPaths.Dashboard);
      sessionStorage.removeItem(RECENT_SEARCH_KEY);
    }
  });
};
