// Packages
import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import { isEmpty } from "lodash";

// Utils
import { updateProjectsQueryData } from "utils/helpers";

// Open API
import { BulkDeleteNodesDto } from "@rapidcanvas/rc-api-core";
import api from "services/AxiosClient/AxiosClient";

// Hooks
import { UseGetConnectorsQueryKeys } from "../dataSources/useGetConnectors";

interface IPayload {
  deletingNodes?: BulkDeleteNodesDto[];
}

type Props = {
  projectId?: string;
  connectorDatasetsExists?: boolean;
};

const useDeleteNodes = (props?: Props): UseMutationResult<void, unknown, IPayload, unknown> => {
  const { projectId, connectorDatasetsExists } = props || {};

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ deletingNodes }: IPayload) => {
      if (isEmpty(deletingNodes)) {
        throw "Invalid payload!";
      }

      return await api.fetchResponse(
        async () => await api.ProjectsControllerV2Api.bulkDeleteStep(deletingNodes!, true, true)
      );
    },
    onSuccess: () => {
      if (!!projectId) {
        updateProjectsQueryData({ queryClient, data: { id: projectId }, fetchData: true });
      }

      if (!!connectorDatasetsExists) {
        queryClient.invalidateQueries([UseGetConnectorsQueryKeys.Connectors]);
      }
    }
  });
};

export default useDeleteNodes;
