import React from "react";

const useCopyClipboard = (copyText: string | null, time?: number) => {
  const [isCopied, setIsCopied] = React.useState(false);

  const [error, setError] = React.useState();

  const handleCopyClick = () => {
    if (copyText) {
      navigator.clipboard
        .writeText(copyText)
        .then(() => {
          // If successful, update the isCopied state value
          setIsCopied(true);
          setTimeout(() => {
            setIsCopied(false);
          }, time ?? 1500);
        })
        .catch((err) => {
          setError(err);
        });
    }
  };

  return { handleCopyClick, isCopied, error };
};

export default useCopyClipboard;
