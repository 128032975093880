import { Grid } from "@material-ui/core";
import _ from "lodash";
import { get, map, orderBy } from "lodash";
import React, { useMemo } from "react";
import useEntityAuditHistory from "src/hooks/api/entities/useEntityAuditHistory";
import { dateFormat } from "utils/helpers/date.helpers";
import NoAuditHistory from "./NoAuditHistory";
import { EntityAuditHistoryDtoActionEnum } from "@rapidcanvas/rc-api-core";
import CommonLoader from "src/components/CommonLoader";
import Table from "src/components/custom/TableV8/Table";
import { CellContext } from "@tanstack/react-table";

interface IProps {
  isTextInput?: boolean;
  datasetId: string;
  dataSourceId?: string;
  connectorsMap: { [key: string]: any };
}

const userActionDatasetMessageMap = {
  [EntityAuditHistoryDtoActionEnum.Edit]: "Dataset Updated",
  [EntityAuditHistoryDtoActionEnum.Create]: "Dataset Created",
  [EntityAuditHistoryDtoActionEnum.Reload]: "Dataset Reloaded",
  [EntityAuditHistoryDtoActionEnum.Append]: "File Appended to Dataset"
};

const userActionTextInputMessageMap = {
  [EntityAuditHistoryDtoActionEnum.Edit]: "Text Input Updated",
  [EntityAuditHistoryDtoActionEnum.Create]: "Text Input Created",
  [EntityAuditHistoryDtoActionEnum.Reload]: "Text Input Reloaded",
  [EntityAuditHistoryDtoActionEnum.Append]: "File Updated for Text Input"
};

const AuditHistory: React.FC<IProps> = ({
  isTextInput = false,
  datasetId,
  connectorsMap,
  dataSourceId
}) => {
  const auditHistoryResult = useEntityAuditHistory(datasetId, { refetchOnMount: true });

  const columns = useMemo(
    () => [
      {
        id: "User Action",
        accessorKey: "dataSourceOptions",
        header: "User Action",
        cell: ({ row }: CellContext<any, unknown>) => {
          const { dataSourceOptions, action } = row.original || {};
          return (
            <Grid container direction="column">
              <Grid item>
                {get(
                  !!isTextInput ? userActionTextInputMessageMap : userActionDatasetMessageMap,
                  action
                )}
              </Grid>
              {(!dataSourceId ||
                (dataSourceId && !!connectorsMap[dataSourceId]?.dataSourceType?.isSql)) &&
                dataSourceOptions &&
                map(dataSourceOptions, (value, key) => (
                  <Grid key={key} item>
                    <strong>{key}:</strong> {JSON.stringify(value)}
                  </Grid>
                ))}
            </Grid>
          );
        }
      },
      {
        id: "Changed by",
        accessorKey: "creator",
        header: "Changed by"
      },
      {
        id: "Updated at",
        accessorKey: "created",
        header: "Updated at",
        cell: ({ row }: CellContext<any, unknown>) => {
          const { created } = row.original || {};

          return <span>{dateFormat(created) ?? ""}</span>;
        }
      }
    ],
    []
  );
  if (auditHistoryResult.isLoading) {
    return <CommonLoader />;
  }

  return _.isEmpty(auditHistoryResult.data) ? (
    <NoAuditHistory />
  ) : (
    <Table
      data={orderBy(auditHistoryResult.data, (item) => Number(item.created), ["desc"])}
      columns={columns}
      stylesProps={{ maxHeight: "calc(100vh - 370px)" }}
    />
  );
};

export default AuditHistory;
