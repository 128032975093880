import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

import { getAPIWithRethrow } from "services/Apis/Apis.service";

export const QUERY_KEY_SECRETS = "query-key-secrets";

const useSecrets = (options?: UseQueryOptions<any, any>): UseQueryResult<any> => {
  return useQuery({
    queryKey: [QUERY_KEY_SECRETS],
    queryFn: async () => {
      const secretResponse = await getAPIWithRethrow("/secret");

      return secretResponse;
    },
    refetchOnMount: true,
    keepPreviousData: true,
    ...options
  });
};

export default useSecrets;
