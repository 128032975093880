// inputEntities store selectors
export const getInputEntities = (state: $TSFixMe) => state.inputEntities;
export const inputEntitiesSetter = (state: $TSFixMe) => state.setInputEntities;
export const getSelectedInputEntity = (state: $TSFixMe) => state.selectedInputEntity;
export const selectedInputEntitySetter = (state: $TSFixMe) => state.setSelectedInputEntity;

// outputEntities store selectors
export const getSideComponent = (state: $TSFixMe) => state.sideComponent;
export const getSideComponentProps = (state: $TSFixMe) => state.sideComponentProps;
export const sideComponentSetter = (state: $TSFixMe) => state.setSideComponent;

// run-config selectors
export const configGroupSetter = (state: $TSFixMe) => state.setConfigGroup;
export const entitiesFeaturesGetter = (state: $TSFixMe) => state.entitiesFeatures;

// projects selectors
export const projectsSetter = (state: $TSFixMe) => state.setProjectList;
export const projectsGetter = (state: $TSFixMe) => state.projectList;
export const shouldRefreshProjectsGetter = (state: $TSFixMe) => state.shouldProjectsRefresh;
export const shouldRefreshProjectsToggler = (state: $TSFixMe) => state.toggleShouldRefresh;
export const shouldRefreshProjectsSetter = (state: $TSFixMe) => state.setShouldProjectsRefresh;

// jobs selectors
export const shouldRefreshJobsGetter = (state: $TSFixMe) => state.shouldJobsRefresh;
export const shouldRefreshJobsToggler = (state: $TSFixMe) => state.toggleShouldRefresh;

export const watchingJobsGetter = (state: $TSFixMe) => state.watchingJobs;
export const watchingJobsSetter = (state: $TSFixMe) => state.setWatchingJobs;

export const watchingJobsIntervalIdGetter = (state: $TSFixMe) => state.watchingJobsIntervalId;
export const watchingJobsIntervalIdSetter = (state: $TSFixMe) => state.setWatchingJobsIntervalId;

// data sources selectors
export const dataSourcesSetter = (state: $TSFixMe) => state.setDataSources;
export const dataSourcesGetter = (state: $TSFixMe) => state.dataSources;
export const connectorsSetter = (state: $TSFixMe) => state.setConnectors;
export const connectorsGetter = (state: $TSFixMe) => state.connectors;
export const dataSourceTypeSetter = (state: $TSFixMe) => state.setDataSourceType;
export const dataSourceTypeGetter = (state: $TSFixMe) => state.dataSourceType;
export const isCreateDataSourceSetter = (state: $TSFixMe) => state.setIsCreateDataSource;
export const isCreateDataSourceGetter = (state: $TSFixMe) => state.isCreateDataSource;

// canvas selectors
export const getNodes = (state: $TSFixMe) => state.nodes;

// Toasts - STARTS >>
export const toastContentGetter = (state: $TSFixMe) => state.toastContent;
export const toastContentSetter = (state: $TSFixMe) => state.setToastContent;
// << ENDS - Toasts
