import { configureStore } from "@reduxjs/toolkit";
import { persistStore } from "redux-persist";
import dagReducer from "./dagSlice";
import environmentsReducer from "./environmentsSlice";
import codeRecipeReducer from "./codeRecipeSlice"; // This is already wrapped with persistReducer

export const store = configureStore({
  reducer: {
    dag: dagReducer,
    environments: environmentsReducer,
    codeRecipe: codeRecipeReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
