import React, { useCallback, useMemo, useState } from "react";

// Packages
import { generatePath, Link } from "react-router-dom";
import { filter, includes, isEmpty, map, sortBy, tail, toLower } from "lodash";

// MUI
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import MuiPopover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles, useTheme } from "@material-ui/core/styles";

// Utils
import { WebPaths } from "src/routing/routes";

// Open API
import { EnvDto } from "@rapidcanvas/rc-api-core";

// Components
import { OverflowTooltip, SearchField } from "src/components";

// Constants
import { EnvironmentsHelperText } from "../../utils/Environments.constants";

const useStyles = makeStyles((theme) => ({
  link: {
    display: "inline-block",
    color: "#003656",
    "&:hover": {
      color: "#003656",
      textDecoration: "underline"
    }
  },
  searchStyles: {
    width: 200,
    "& > div": { height: theme.spacing(3.5), borderRadius: theme.spacing(2) }
  }
}));

interface IProps {
  environment: EnvDto | null;
  anchorEl: HTMLElement | null;
  onClose: () => void;
}

const Popover: React.FC<IProps> = (props) => {
  const { environment, anchorEl, onClose } = props;

  const theme = useTheme();
  const classes = useStyles();

  const sortedProjects = useMemo(
    () => sortBy(tail(environment?.projectDtos), (project) => toLower(project?.name)),
    [environment?.projectDtos]
  );

  const onPopoverClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event?.stopPropagation();
  }, []);

  const [searchValue, setSearchValue] = useState("");

  const onSearch = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    event?.stopPropagation();
    event?.preventDefault();
    const value = event?.target?.value || "";
    setSearchValue(() => value);
  }, []);

  const filteredProjects = useMemo(
    () =>
      filter(sortedProjects, (project) =>
        !!searchValue ? includes(toLower(project?.name), toLower(searchValue)) : true
      ),
    [sortedProjects, searchValue]
  );

  return (
    <MuiPopover
      open
      anchorEl={anchorEl}
      onClick={onPopoverClick}
      onClose={onClose}
      anchorOrigin={{
        vertical: "center",
        horizontal: "right"
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "left"
      }}
      PaperProps={{
        style: {
          width: 350,
          borderRadius: theme.spacing(1.5)
        }
      }}>
      <List disablePadding>
        <ListItem divider>
          <ListItemText
            style={{ margin: 0 }}
            primaryTypographyProps={{ variant: "subtitle2" }}
            data-testid="envCardProjectsTitle">
            <Grid container justifyContent="space-between" alignItems="center">
              {EnvironmentsHelperText.Projects}
              <SearchField
                placeholder="Search"
                value={searchValue}
                onChange={onSearch}
                size="small"
                className={classes.searchStyles}
                onClick={(event) => {
                  event?.stopPropagation();
                }}
              />
            </Grid>
          </ListItemText>
        </ListItem>
        <Box
          style={{
            maxHeight: 400,
            overflowY: "auto"
          }}>
          {isEmpty(filteredProjects) ? (
            <ListItem dense key={`envCardProjectsPopoverNoProjectFound`}>
              <ListItemText
                primaryTypographyProps={{
                  color: "textSecondary"
                }}>
                <Box fontStyle="italic" data-testid="envCardProjectsNoProjectFound">
                  {EnvironmentsHelperText.NoProjectFound}
                </Box>
              </ListItemText>
            </ListItem>
          ) : (
            map(filteredProjects, (project, index) => {
              const overflowTooltip = (
                <OverflowTooltip
                  style={{ whiteSpace: "nowrap", maxWidth: 300 }}
                  value={project?.name}
                  data-testid="envCardProjectNameOverflowTooltip"
                />
              );

              return (
                <ListItem dense key={`envCardProjectName_${index}`}>
                  <ListItemText>
                    {!!project?.id ? (
                      <Link
                        className={classes.link}
                        to={generatePath(WebPaths.Dag, { projectId: project?.id })}
                        onClick={(event) => event?.stopPropagation()}
                        data-testid={`envCardProjectName_${index}`}>
                        {overflowTooltip}
                      </Link>
                    ) : (
                      overflowTooltip
                    )}
                  </ListItemText>
                </ListItem>
              );
            })
          )}
        </Box>
      </List>
    </MuiPopover>
  );
};

export default Popover;
