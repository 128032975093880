import React, { useMemo } from "react";

// Packages
import { includes, isEmpty } from "lodash";

// Utils
import { formatStringsWithAnd } from "utils/helpers";

// Components
import { Modal } from "src/components/custom";
import { ModalVariants } from "src/components/custom/Modal/Modal";
import { EnvAction } from "../../Environment/Environment";

interface IProps {
  data: EnvAction;
  confirmStopEnvironment?: () => void;
  resetStopEnvironment?: () => void;
}

const StopEnvironment: React.FC<IProps> = (props) => {
  const { data, confirmStopEnvironment, resetStopEnvironment } = props;

  const content = useMemo(
    () => [
      `Please note that there are active processes within this environment, such as ${
        !isEmpty(data?.types) ? formatStringsWithAnd(data?.types) : ""
      }. Continuing with ${
        !!data?.action ? data?.action : ""
      } the environment may affect these ongoing operations.`
    ],
    [data]
  );

  return (
    <Modal
      open
      variant={ModalVariants.Delete}
      title="Are you sure?"
      content={content}
      onClose={(event?: React.MouseEvent<HTMLButtonElement>, reason?: string) => {
        event?.stopPropagation();

        if (!includes(["escapeKeyDown", "backdropClick"], reason)) {
          resetStopEnvironment?.();
        }
      }}
      onSubmit={(event?: React.MouseEvent<HTMLButtonElement>) => {
        event?.stopPropagation();

        confirmStopEnvironment?.();
        resetStopEnvironment?.();
      }}
      onClick={(event: React.MouseEvent<HTMLDivElement>) => {
        if (!(event.target as HTMLElement).closest("button")) {
          event.stopPropagation(); // Stop propagation except for buttons
        }
      }}
      hideCloseIcon
    />
  );
};

export default StopEnvironment;
