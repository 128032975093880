import { useEffect } from "react";

import { map, pick, toUpper } from "lodash";

import { BulkDeleteNodesDto } from "@rapidcanvas/rc-api-core";

import { useDeletingNodes } from "hooks/api";

import { NodeData } from "src/types";

interface IProps {
  selectedNodes: NodeData[];
}

const useGetDeletingNodes = (props: IProps) => {
  const { selectedNodes } = props;

  const {
    data: deletingNodesData,
    isLoading: isLoadingDeletingNodes,
    isError: isErrorGettingDeletingNodes,
    mutateAsync: deletingNodesMutation,
    reset: resetDeletingNodesMutation
  } = useDeletingNodes();

  useEffect(() => {
    const _ = async () => {
      resetDeletingNodesMutation();

      const deletingNodes: BulkDeleteNodesDto[] = map(selectedNodes, (item) => ({
        ...pick(item, ["id"]), // Get 'id'
        stepType: toUpper(item.type) // Convert 'type' to uppercase
      }));

      await deletingNodesMutation({ deletingNodes });
    };

    _();
  }, []);

  return { isLoadingDeletingNodes, isErrorGettingDeletingNodes, deletingNodesData };
};

export default useGetDeletingNodes;
