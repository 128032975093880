import React from "react";

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  makeStyles
} from "@material-ui/core";

import { EntityDtoStatusEnum } from "@rapidcanvas/rc-api-core";

import { Criteria, DatasetHelperText } from "../../utils/Dataset.constants";

import useStoreSelectors from "../../hooks/useStoreSelectors";
import useAddFileQueryParam from "../../hooks/useAddFileQueryParam";

// import { useSourceContext } from "../../contexts/Source/useSourceContext";
import { CriterionDropdownPropsType } from "../../types/Props.type";
import { map } from "lodash";

const useStyles = makeStyles(() => ({
  root: {
    "& [class^='MuiInputLabel-root']": { maxWidth: "100%" }
  }
}));

const CriterionDropdown = (props: CriterionDropdownPropsType) => {
  const { isDataSourcesFilesUpload } = props || {};
  // const { onSourceOrCriterionChange } = useSourceContext();

  const classes: $TSFixMe = useStyles();

  const isAddFile = useAddFileQueryParam();

  // Stores - STARTS >>
  const {
    datasetDefaultDatasetStore,
    datasetCriteriaStore,
    datasetCriterionStore,
    setDatasetCriterionStore
  } = useStoreSelectors();
  // << ENDS - Stores

  const onChange = (event: $TSFixMe) => {
    const thisCriterionStore = datasetCriterionStore;
    thisCriterionStore.value = event?.target?.value;

    setDatasetCriterionStore({ ...thisCriterionStore });

    // Preventing reloading the data sources files.
    // onSourceOrCriterionChange();
  };

  const isDisabled = (criterion: $TSFixMe) => {
    if (isDataSourcesFilesUpload) {
      return [Criteria.Merge, Criteria.Append]?.includes(criterion);
    }

    return false;
  };

  return (
    <FormControl fullWidth variant="outlined" className={classes.root}>
      <InputLabel id="criterionLabel">{DatasetHelperText.Criterion}</InputLabel>
      <Select
        labelId="criterionLabel"
        id="criterion"
        value={datasetCriterionStore?.value}
        disabled={datasetCriterionStore?.isDisabled}
        onChange={onChange}
        label={DatasetHelperText.Criterion}>
        {map(datasetCriteriaStore, (eachCriterion: $TSFixMe) => {
          return (
            <MenuItem
              id={`criterionOption_${eachCriterion?.id}`}
              key={`criterionOption_${eachCriterion?.id}`}
              value={eachCriterion?.id}
              disabled={isDisabled(eachCriterion?.id)}>
              <Typography id={`criterionOptionName_${eachCriterion?.id}`} color="textPrimary">
                {!!isAddFile
                  ? eachCriterion.id === Criteria.Append &&
                    datasetDefaultDatasetStore?.status === EntityDtoStatusEnum.Unbuilt
                    ? "Add File"
                    : eachCriterion?.textInputName
                  : eachCriterion?.datasetName}
              </Typography>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default CriterionDropdown;
