import React from "react";

// Packages
import { generatePath, Link } from "react-router-dom";

// MUI
import Breadcrumbs from "@material-ui/core/Breadcrumbs";

// Open API
import { EntityDto, ProjectDto, ProjectRunDto } from "@rapidcanvas/rc-api-core";

// Utils
import { RenderText } from "components/custom";
import { WebPaths } from "src/routing/routes";

// Constants
import { ViewFileHelperText } from "../utils/ViewFile.constants";

interface IProps {
  isJobPath?: boolean;
  projectId?: string;
  scenarioId?: string;
  project?: ProjectDto;
  // @TODO: Type any below should be removed after IEntity in useEntities hook is replaced with EntityDto.
  data?: EntityDto | any;
  isDefaultScenario?: boolean;
  scenarioName?: string;
  jobData?: ProjectRunDto;
  jobRunId?: string;
  jobRunName?: string;
}

const SubTopNavBarBreadcrumbs: React.FC<IProps> = (props) => {
  const {
    isJobPath = false,
    projectId,
    scenarioId,
    project,
    data,
    isDefaultScenario = true,
    scenarioName,
    jobData,
    jobRunId,
    jobRunName
  } = props;

  const breadcrumbs: React.ReactNode[] = [];

  if (!!projectId && !!project?.name && !!scenarioId) {
    breadcrumbs.push(
      <Link
        key="projectName"
        to={`/projects/${projectId}/scenario/${scenarioId}/canvas`}
        data-testid="viewFileBreadcrumbProjectName">
        <RenderText>{project?.name}</RenderText>
      </Link>
    );
  } else {
    breadcrumbs.push(
      <RenderText key="projectName" color="textSecondary">
        {ViewFileHelperText.Project}
      </RenderText>
    );
  }

  if (!!isJobPath && !!project?.id) {
    breadcrumbs.push(
      !!project?.id && (
        <Link key="jobs" to={generatePath(WebPaths.JobRoutes, { projectId: project?.id })}>
          <RenderText>Schedulers</RenderText>
        </Link>
      )
    );

    if (!!jobData?.id) {
      breadcrumbs.push(
        <Link key="jobs-name" to={`/projects/${project?.id}/jobs/${jobData?.id}`}>
          <RenderText>{jobData?.name || "Scheduler"}</RenderText>
        </Link>
      );
    } else {
      breadcrumbs.push(
        <RenderText key="jobs-name" color="textSecondary">
          Scheduler
        </RenderText>
      );
    }

    if (!!jobData?.id && !!jobRunId) {
      breadcrumbs.push(
        <Link
          key="job-run-name"
          to={`/projects/${project?.id}/jobs/${jobData?.id}/job-runs/${jobRunId}/job-canvas`}>
          <RenderText>{`Run Name ${jobRunName || jobRunId || "Unknown"}`}</RenderText>
        </Link>
      );
    } else {
      breadcrumbs.push(
        <RenderText key="job-run-name" color="textSecondary">{`Run Name ${
          jobRunName || jobRunId || "Unknown"
        }`}</RenderText>
      );
    }
  }

  breadcrumbs.push(
    <RenderText key="fileName" color="textSecondary">
      {data?.displayName || data?.name || ViewFileHelperText.File}
      {!isDefaultScenario && !!scenarioName && ` (${scenarioName})`}
    </RenderText>
  );

  return (
    <Breadcrumbs data-testid="viewFileBreadcrumbsContainer">
      {breadcrumbs?.map((eachBreadcrumb: React.ReactNode) => eachBreadcrumb)}
    </Breadcrumbs>
  );
};

export default SubTopNavBarBreadcrumbs;
