import React, { useMemo } from "react";
import { useParams } from "react-router";

import { useGetRecipe } from "src/hooks/api";
import ApiConnectorRecipeContainer from "./ApiConnectorRecipeContainer";
import { ApiConnectorRecipeContextProvider } from "./ApiConnectorRecipeContext/ApiConnectorRecipeContextProvider";
import CommonLoader from "src/components/CommonLoader";

const ApiConnectorRecipeDataContainer = () => {
  const { groupId, scenarioId, jobRunId } = useParams();
  const { isLoading, data } = useGetRecipe({
    recipeId: groupId,
    scenarioId,
    jobRunId,
    options: { cacheTime: undefined, refetchOnMount: true }
  });

  const { entityIds, artifactsIds, modelsIds } = useMemo(() => {
    return {
      entityIds: data?.recipe?.parents
        ?.filter(
          (parent) =>
            parent.type === "ENTITY" || parent.type === "FILE" || parent.type === "VECTOR_STORE"
        )
        ?.map((parent) => parent.id),
      artifactsIds: data?.recipe?.parents
        ?.filter((parent) => parent.type === "ARTIFACT")
        ?.map((parent) => parent.id),
      modelsIds: data?.recipe?.parents
        ?.filter((parent) => parent.type === "MODEL")
        ?.map((parent) => parent.id)
    };
  }, [data?.recipe?.parents]);

  if (!groupId) {
    return (
      <ApiConnectorRecipeContextProvider entityIds={entityIds}>
        <ApiConnectorRecipeContainer />
      </ApiConnectorRecipeContextProvider>
    );
  }

  return isLoading ? (
    <CommonLoader />
  ) : (
    <ApiConnectorRecipeContextProvider
      templates={data?.templates}
      recipe={data?.recipe}
      entityIds={entityIds}
      modelsIds={modelsIds}
      artifactsIds={artifactsIds}>
      <ApiConnectorRecipeContainer />
    </ApiConnectorRecipeContextProvider>
  );
};

export default ApiConnectorRecipeDataContainer;
