import React from "react";
import {
  FileCopyOutlined,
  LibraryAddCheckOutlined as LibraryAddCheckOutlinedIcon
} from "@material-ui/icons";
import { IconButton, Tooltip } from "@material-ui/core";

import useCopyClipboard from "src/hooks/useCopyClipboard";

export const CopyTextIcon = ({
  text,
  title = "Copy Query",
  color,
  ...restProps
}: {
  text: string;
  title?: string;
  color?: string;
}) => {
  const { handleCopyClick, isCopied } = useCopyClipboard(text);
  const onCopyClick = (event: any) => {
    event?.stopPropagation();
    handleCopyClick();
  };
  return (
    <Tooltip title={isCopied ? "Copied" : title} placement="top" arrow>
      <IconButton size="small" color="primary" onClick={onCopyClick} {...restProps}>
        {isCopied ? (
          <LibraryAddCheckOutlinedIcon fontSize="small" style={{ color }} />
        ) : (
          <FileCopyOutlined style={{ fontSize: "16px", color }} />
        )}
      </IconButton>
    </Tooltip>
  );
};
