import React, { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react";
import { DatasetMenuKeys, RecipeMenuKeys } from "src/pages/Projects/CanvasFlow/AddMenu";
import {
  DatasetWebPathQueryParameters,
  NodeTypeNames,
  NodeTypes,
  RecipeTypeNames
} from "../../../utils";
import { filter, isFunction, last, map, values } from "lodash";
import ApiConnectorIcon from "icons/NewUX/ApiConnectorIcon";
import { TemplateRecipeIcon } from "icons/NewUX/TemplateRecipeIcon";
import { RapidModelRecipeIcon } from "icons/NewUX/RapidModelRecipeIcon";
import { AiAssistedIcon } from "src/assets/icons/AiAssistedIcon";
import { FileIcon } from "icons/NewUX";
import { ModelIcon } from "icons/NewUX/ModelIcon";
import { ArtifactIcon } from "icons/NewUX/ArtifactIcon";
import { DatasetIcon } from "icons/NewUX/DatasetIcon";
import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Grid,
  makeStyles,
  Tooltip
} from "@material-ui/core";
import { useAccessControlContext } from "src/routing/PrivateRoute/accessControlContext/useAccessControlContext";
import { useCanvasStore } from "stores/zustand/stores";
import _ from "lodash";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { WebPaths } from "src/routing/routes";
import { useNodeMultiSelection } from "projectsModule/hooks";
import { AddArtifactsModal } from "src/pages/Projects/common/AddArtifactsModal";
import { AddModelsModal } from "src/pages/Projects/common/AddModelsModal";
import { createEntityWithRethrow } from "services/Apis/wrappers";
import { handleResponse } from "services/Apis/Apis.service";
import shallow from "zustand/shallow";
import { useDagContext } from "../context/useDagContext";

interface IProps {
  contextMenu: { x: number; y: number; width: number };
  setContextMenu: Dispatch<SetStateAction<{ x: number; y: number; width: number } | null>>;
}

const useDrawerStyles = makeStyles({
  title: {
    fontSize: "14px",
    color: "#809AAB"
  },
  header: {
    borderBottom: "1px solid #d3d3d3",
    padding: "4px 20px"
  },
  btnLabel: {
    justifyContent: "flex-start",
    fontWeight: 400
  }
});

const DagContextMenu: React.FC<IProps> = ({ contextMenu, setContextMenu }) => {
  const { projectId } = useParams();
  const { header, title, btnLabel } = useDrawerStyles();
  const { canAddStandardRecipe, canAddArtifacts, canAddModels } = useAccessControlContext();
  const { scenarioId, setIsFromArtifactsModelModal } = useDagContext() || {};

  const { getSelectedNodesByType } = useNodeMultiSelection();

  const [setNodeToFocus, setReloadTrigger] = useCanvasStore(
    (state) => [
      state.setNodeToFocus,
      state.setReloadTrigger,
      state.openRecipeRunsQueue,
      state.isPendingRecipeRunsInQueue
    ],
    shallow
  );

  const [isAddArtifactModalOpen, setIsAddArtifactModalOpen] = useState(false);
  const [isAddModelsModalOpen, setIsAddModelsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modelLoading, setModelLoading] = useState(false);

  const selectedEntities = getSelectedNodesByType({ type: NodeTypes.Dataset, getByKey: "id" });

  const nodes = useCanvasStore((state) => state.nodes);
  const navigate = useNavigate();
  const { entityNodes, artifactNodes, modelNodes, textNodes } = useMemo(() => {
    const groupBy = _.groupBy(nodes, (node) => (node as $TSFixMe)?.type?.toLowerCase());

    return {
      dfsGroupNodes: _.get(groupBy, "dfsgroup", []),
      entityNodes: filter(_.get(groupBy, "entity"), (item: any) => item.status !== "UNBUILT") ?? [],
      artifactNodes:
        filter(_.get(groupBy, "artifact"), (item: any) => item.status !== "UNBUILT") ?? [],
      modelNodes: filter(_.get(groupBy, "model"), (item: any) => item.status !== "UNBUILT") ?? [],
      textNodes: filter(_.get(groupBy, "file"), (item: any) => item.status !== "UNBUILT") ?? []
    };
  }, [nodes]);

  const isAddTemplateRecipeDisabled =
    entityNodes.length < 1 &&
    artifactNodes.length < 1 &&
    modelNodes.length < 1 &&
    textNodes.length < 1;
  const isAddAiAssistedRecipeDisabled =
    entityNodes.length < 1 && artifactNodes.length < 1 && modelNodes.length < 1;

  const disabledAddRapidModelRecipeActionMessage = useMemo(
    () =>
      entityNodes.length < 1
        ? "No inputs to add recipe"
        : selectedEntities?.length > 1
          ? "Multiple datasets are selected as input. Please choose a single dataset to proceed."
          : "",
    [entityNodes, selectedEntities]
  );

  const onAddDatasetAction = useCallback(() => {
    setContextMenu(null);
    if (projectId && scenarioId) {
      navigate(generatePath(WebPaths.Dataset, { projectId, scenarioId }));
    }
  }, [scenarioId, projectId]);

  const onAddFileAction = useCallback(() => {
    setContextMenu(null);
    if (projectId && scenarioId) {
      navigate(
        generatePath(`${WebPaths.Dataset}?${DatasetWebPathQueryParameters.IsAddFile}=true`, {
          projectId,
          scenarioId
        })
      );
    }
  }, [scenarioId, projectId]);

  const addTemplateRecipe = useCallback(() => {
    setContextMenu(null);
    sessionStorage.removeItem("configGroupId");
    navigate(`/projects/${projectId}/scenario/${scenarioId}/add-recipe`);
  }, [scenarioId, projectId]);

  const addAiAssistedRecipe = useCallback(() => {
    setContextMenu(null);
    sessionStorage.removeItem("configGroupId");
    navigate(`/projects/${projectId}/scenario/${scenarioId}/add-code-recipe`);
  }, [scenarioId, projectId]);

  const addRapidModelRecipe = useCallback(() => {
    setContextMenu(null);
    sessionStorage.removeItem("configGroupId");
    navigate(`/projects/${projectId}/scenario/${scenarioId}/add-auto-ml-recipe`);
  }, [scenarioId, projectId]);

  const onAddApiConnectorRecipe = () => {
    setContextMenu(null);
    if (projectId && scenarioId) {
      navigate(generatePath(WebPaths.APIConnectorRecipeContainer, { projectId, scenarioId }));
    }
  };

  // Meta-data of menu-items - STARTS >>
  const datasetMenuDetails = useMemo(
    () => ({
      [DatasetMenuKeys.Dataset]: {
        key: DatasetMenuKeys.Dataset,
        label: NodeTypeNames.Dataset,
        icon: <DatasetIcon width={20} height={16} viewBox="2 0 20 20" />,
        action: onAddDatasetAction
      },
      [DatasetMenuKeys.Artifact]: {
        key: DatasetMenuKeys.Artifact,
        label: NodeTypeNames.Artifact,
        hide: !canAddArtifacts,
        icon: <ArtifactIcon width={20} height={20} />,
        action: () => setIsAddArtifactModalOpen(true)
      },
      [DatasetMenuKeys.Model]: {
        key: DatasetMenuKeys.Model,
        hide: !canAddModels,
        label: NodeTypeNames.Model,
        icon: <ModelIcon width={20} height={20} />,
        action: () => setIsAddModelsModalOpen(true)
      },
      [DatasetMenuKeys.File]: {
        key: DatasetMenuKeys.File,
        label: NodeTypeNames.File,
        icon: <FileIcon width={20} height={20} viewBox="0 0 20 20" />,
        action: onAddFileAction
      }
    }),
    []
  );

  const recipeMenuDetails = useMemo(
    () => ({
      [RecipeMenuKeys.AiAssistedRecipe]: {
        key: RecipeMenuKeys.AiAssistedRecipe,
        label: RecipeTypeNames.AiAssisted,
        icon: <AiAssistedIcon width={20} height={20} />,
        action: addAiAssistedRecipe,
        isDisabled: isAddAiAssistedRecipeDisabled,
        hide: false,
        tooltip: isAddAiAssistedRecipeDisabled ? "No inputs to add recipe" : ""
      },
      [RecipeMenuKeys.RapidModelRecipe]: {
        key: RecipeMenuKeys.RapidModelRecipe,
        label: RecipeTypeNames.RapidModel,
        icon: <RapidModelRecipeIcon width={20} height={20} viewBox="2 0 20 20" />,
        action: addRapidModelRecipe,
        isDisabled: !!disabledAddRapidModelRecipeActionMessage,
        hide: false,
        tooltip: disabledAddRapidModelRecipeActionMessage
      },
      [RecipeMenuKeys.TemplateRecipe]: {
        key: RecipeMenuKeys.TemplateRecipe,
        label: RecipeTypeNames.Template,
        icon: <TemplateRecipeIcon width={20} height={20} />,
        action: addTemplateRecipe,
        hide: !canAddStandardRecipe,
        isDisabled: isAddTemplateRecipeDisabled,
        tooltip: isAddTemplateRecipeDisabled ? "No inputs to add recipe" : ""
      },
      [RecipeMenuKeys.ApiConnectorRecipe]: {
        key: RecipeMenuKeys.ApiConnectorRecipe,
        label: "Code",
        icon: <ApiConnectorIcon style={{ marginRight: "3px" }} />,
        action: onAddApiConnectorRecipe,
        isDisabled: false,
        hide: !isFunction(onAddApiConnectorRecipe),
        tooltip: ""
      }
    }),
    [
      isAddTemplateRecipeDisabled,
      isAddAiAssistedRecipeDisabled,
      disabledAddRapidModelRecipeActionMessage,
      onAddApiConnectorRecipe
    ]
  );
  // ENDS - Meta-data of menu-items

  // Formatting to support MUI menu - STARTS >>
  const visibleDatasetMenuItems = values(datasetMenuDetails).filter(
    (menuItem: { [key: string]: any }) => !menuItem.hide
  );
  const datasetMenuItems = useMemo(
    () =>
      map(visibleDatasetMenuItems, (menuItem) => ({
        key: menuItem?.key,
        label: menuItem?.label,
        icon: menuItem?.icon,
        action: menuItem.action
      })),
    [datasetMenuDetails]
  );

  const visibleRecipeMenuItems = values(recipeMenuDetails).filter((recipe) => !recipe.hide);
  const recipeMenuItems = useMemo(
    () =>
      map(visibleRecipeMenuItems, (menuItem) => ({
        key: menuItem?.key,
        label: menuItem?.label,
        icon: menuItem?.icon,
        isDisabled: menuItem?.isDisabled,
        tooltip: menuItem?.tooltip,
        action: menuItem.action
      })),
    [recipeMenuDetails]
  );

  const createEntities = React.useCallback(
    async (entityNames: Array<string>, entityViewType: string) => {
      const entityBody = {
        entityMeta: {
          entityViewType,
          entityType: "EVENT"
        },
        projectId
      };
      try {
        const response = await Promise.all(
          entityNames.map(async (artifactName: string) => {
            return await createEntityWithRethrow({
              ...entityBody,
              name: artifactName
            });
          })
        );

        setReloadTrigger();

        const lastCreatedEntityName = last(response)?.name;
        if (!!lastCreatedEntityName) {
          setNodeToFocus(lastCreatedEntityName);
          setIsFromArtifactsModelModal(() => true);
        }
      } catch (error: $TSFixMe) {
        handleResponse({
          errorMessage:
            error.response?.data?.msg ||
            error.message ||
            `Error in adding ${entityViewType === "ARTIFACT" ? "artifacts" : "models"}`
        });
      }
    },
    [projectId, setReloadTrigger]
  );
  const onAddArtifacts = React.useCallback(
    async (artifactNames: Array<string>) => {
      const existingArtifactNames = artifactNodes?.map((artifact: $TSFixMe) => artifact.name);
      const newArtifacts = artifactNames.filter((name) => !existingArtifactNames.includes(name));
      setLoading(true);
      await createEntities(newArtifacts, "ARTIFACT");
      setIsAddArtifactModalOpen(false);
      setLoading(false);
    },
    [artifactNodes, createEntities]
  );

  const onAddModels = React.useCallback(
    async (modelNames: Array<string>) => {
      const existingModelNames = modelNodes?.map((model: $TSFixMe) => model.name);
      const newModels = modelNames.filter((name) => !existingModelNames.includes(name));
      setModelLoading(true);
      await createEntities(newModels, "MODEL");
      setIsAddModelsModalOpen(false);
      setModelLoading(false);
    },
    [createEntities, modelNodes]
  );

  return (
    <Grid
      style={{
        position: "absolute",
        top: `${contextMenu.y}px`,
        left: `${contextMenu.x}px`,
        zIndex: 12,
        width: contextMenu.width,
        borderRadius: "4px",
        boxShadow:
          "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)"
      }}>
      <AddArtifactsModal
        loading={loading}
        open={isAddArtifactModalOpen}
        selectedArtifacts={artifactNodes}
        shouldDisableInitialSelectedRows
        onAddArtifacts={onAddArtifacts}
        onClose={() => setIsAddArtifactModalOpen(false)}
      />

      <AddModelsModal
        loading={modelLoading}
        open={isAddModelsModalOpen}
        onAddModels={onAddModels}
        selectedModels={modelNodes}
        shouldDisableInitialSelectedRows
        onClose={() => setIsAddModelsModalOpen(false)}
      />
      <CardHeader
        style={{
          padding: "0 16px",
          background: "white",
          borderBottom: "1px solid #d3d3d3",
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px"
        }}
        title={<span style={{ fontWeight: 500, fontSize: "16px" }}>Add</span>}
      />
      <Card style={{ boxShadow: "none" }}>
        <CardHeader className={header} title={<span className={title}>Elements</span>} />
        <CardContent style={{ padding: "12px 12px 0 12px", borderBottom: "1px solid #d3d3d3" }}>
          <ButtonGroup style={{ width: "100%" }} variant="text" orientation="vertical">
            {map(datasetMenuItems, (item) => (
              <Button
                key={item.key}
                data-testid={`canvas-context-menu-${item.label}`}
                classes={{ label: btnLabel }}
                style={{ borderBottom: "none" }}
                startIcon={item.icon}
                onClick={(event) => {
                  event.stopPropagation();
                  item.action();
                }}>
                {item.label}
              </Button>
            ))}
          </ButtonGroup>
        </CardContent>
      </Card>
      <Card
        style={{
          boxShadow: "none",
          borderBottomLeftRadius: "4px",
          borderBottomRightRadius: "4px"
        }}>
        <CardHeader className={header} title={<span className={title}>Recipes</span>} />
        <CardContent style={{ padding: "12px" }}>
          <ButtonGroup style={{ width: "100%" }} variant="text" orientation="vertical">
            {map(recipeMenuItems, (item) => (
              <Tooltip key={item.key} title={item.tooltip ?? ""} style={{ borderBottom: "none" }}>
                <span>
                  <Button
                    disabled={item.isDisabled}
                    data-testid={`canvas-context-menu-${item.label}`}
                    style={{ width: "100%" }}
                    classes={{ label: btnLabel }}
                    startIcon={item.icon}
                    onClick={(event) => {
                      event.stopPropagation();
                      item.action?.();
                    }}>
                    {item.label}
                  </Button>
                </span>
              </Tooltip>
            ))}
          </ButtonGroup>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default DagContextMenu;
