import React from "react";

import { MuiPickersUtilsProvider, DatePickerProps, DatePicker } from "@material-ui/pickers";
import DayjsUtils from "@date-io/dayjs";
import { createTheme, ThemeProvider } from "@material-ui/core";

const theme = createTheme({
  overrides: {
    MuiButton: {
      text: {
        background: "none"
      }
    }
  }
});

const CustomDatePicker: React.FC<DatePickerProps> = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DayjsUtils}>
        <DatePicker {...props} />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default CustomDatePicker;
