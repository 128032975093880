import { Grid } from "@material-ui/core";
import OverflowTooltip from "components/OverflowTooltip";
import { map } from "lodash";
import { PlotType } from "plotly.js";
import React from "react";
import Plot from "react-plotly.js";

interface IProps {
  chartData: any;
}

const DataSummaryCharts: React.FC<IProps> = ({ chartData }) => {
  if (!chartData) return null;

  let plotData = [];
  let layout: any = {
    showlegend: false,
    xaxis: {
      tickmode: "array",
      tickvals: [chartData.values[0]?.start, chartData.values[chartData.values.length - 1]?.start],
      ticktext: [chartData.values[0]?.count, chartData.values[chartData.values.length - 1]?.count],
      showgrid: false
    },
    yaxis: {
      showticklabels: false,
      showgrid: false
    },
    bargap: 0,
    height: 140,
    width: 140
  };

  switch (chartData.type) {
    case "pie":
      plotData = [
        {
          labels: chartData.values.map((v: any) => v.title),
          values: chartData.values.map((v: any) => v.value),
          type: "pie" as PlotType,
          textinfo: "label",
          hoverinfo: "label+value"
        }
      ];
      layout = { ...layout, margin: { t: 0, b: 0, l: 0, r: 0 }, height: 80, width: 80 };
      const plotParams: any = {
        data: plotData as any,
        layout,
        useResizeHandler: false,
        style: { width: "100%", height: "100%" },
        config: { displaylogo: false, displayModeBar: false }
      };
      return (
        <Grid
          container
          alignItems="center"
          style={{
            width: "150px",
            height: "150px",
            flexWrap: "nowrap",
            gap: "5px",
            overflow: "hidden"
          }}>
          <Grid style={{ width: "90px", height: "90px" }}>
            <Plot {...plotParams} />
          </Grid>
          <Grid container alignItems="center" direction="column">
            {map(chartData.values, (item) => (
              <>
                <OverflowTooltip
                  value={item.title}
                  style={{
                    fontSize: "14px",
                    fontWeight: 400,
                    fontStyle: "normal",
                    color: "#000000"
                  }}></OverflowTooltip>
                <OverflowTooltip
                  value={`${item.value}%`}
                  style={{
                    fontSize: "14px",
                    fontWeight: 400,
                    fontStyle: "normal",
                    color: "#000000"
                  }}
                />
              </>
            ))}
          </Grid>
        </Grid>
      );

    case "histogram":
      plotData = [
        {
          x: chartData.values.map((v: any) => v.start),
          y: chartData.values.map((v: any) => v.count),
          type: "bar" as PlotType
        }
      ];
      layout = { ...layout, margin: { l: 10, r: 10, t: 30, b: 30 } };
      break;

    case "table":
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          style={{ height: "140px", padding: "2px 10px 2px 10px" }}>
          {map(chartData.values, (item) => (
            <Grid
              container
              justifyContent="space-between"
              style={{ gap: "5px", flexWrap: "nowrap" }}>
              <OverflowTooltip
                style={{
                  fontSize: "14px",
                  width: "calc(100% - 40px)",
                  whiteSpace: "nowrap",
                  fontStyle: "normal",
                  color: "#000000",
                  fontWeight: 400
                }}
                value={item.title?.toString()}></OverflowTooltip>

              <OverflowTooltip
                style={{
                  width: "40px",
                  fontSize: "14px",
                  whiteSpace: "nowrap",
                  fontStyle: "normal",
                  color: "#000000",
                  textAlign: "end",
                  fontWeight: 400
                }}
                value={item.value?.toString()}></OverflowTooltip>
            </Grid>
          ))}
        </Grid>
      );

    case "single":
      return (
        <Grid container alignItems="center" style={{ height: "150px" }}>
          <Grid container direction="column" alignItems="center">
            <OverflowTooltip
              value={chartData.values[0].value}
              style={{ fontSize: "20px", fontWeight: 500, fontStyle: "normal", color: "#000000" }}
            />

            <OverflowTooltip
              value={chartData.values[0].title}
              style={{ fontSize: "14px", fontStyle: "normal", fontWeight: 400, color: "#000000" }}
            />
          </Grid>
        </Grid>
      );
    case "empty":
      return (
        <Grid container alignItems="center" style={{ height: "150px" }}>
          <Grid container direction="column" alignItems="center">
            <OverflowTooltip
              value="NA"
              style={{ fontSize: "14px", fontStyle: "normal", fontWeight: 400, color: "#000000" }}
            />
          </Grid>
        </Grid>
      );

    default:
      return null;
  }

  const plotParams: any = {
    data: plotData as any,
    layout,
    useResizeHandler: false,
    style: { width: "100%", height: "100%" },
    config: { displaylogo: false, displayModeBar: false }
  };

  return (
    <Grid container style={{ height: "150px" }}>
      <Plot {...plotParams} />
    </Grid>
  );
};

export default DataSummaryCharts;
