import { EnvUsageDto } from "@rapidcanvas/rc-api-core";
import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

import api from "services/AxiosClient/AxiosClient";

const QUERY_KEY_ENV_USAGE = "query-env-usage";

const useGetEnvironmentUsage = (
  envId: string,
  options?: UseQueryOptions<any>
): UseQueryResult<any> => {
  return useQuery<Array<EnvUsageDto>>({
    queryKey: [QUERY_KEY_ENV_USAGE, envId],
    queryFn: async () => {
      return await api.fetchResponse(async () => await api.EnvControllerApi.getUsage(envId));
    },
    cacheTime: 0,
    staleTime: 0,
    ...options
  });
};

export default useGetEnvironmentUsage;
export { QUERY_KEY_ENV_USAGE };
