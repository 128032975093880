import React, { useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import {
  Typography,
  Grid,
  Paper,
  Chip,
  makeStyles,
  Button,
  Tooltip,
  CircularProgress,
  GridSize
} from "@material-ui/core";
import { isEmpty } from "lodash";

import SubTopNavBarBreadcrumbs from "./SubTopNavBarBreadcrumbs";
import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import useGetRecipeLogs from "src/hooks/useGetRecipeLogs";
import useTransformGroup from "src/hooks/api/transforms/useTransformGroup";
import { GroupLogDtoStatusEnum } from "@rapidcanvas/rc-api-core";
import { IS_TEST_LOGS } from "../common/ShowLogsModal/RecipeLogsDrawer";
import { RecipeStatuses } from "projectsModule/utils/Projects.constants";
import { ToastifyAlert } from "../../../components/ToastifyAlert/ToastifyAlert";
import { useProjectContext } from "src/pages/private/ProjectsModule/context/useProjectContext";
import CommonLoader from "src/components/CommonLoader";

export const useStyles = makeStyles({
  logsContainer: {
    // New UX change
    // The value 94px is the height of both the NavBars (TopNavBar 50px + SubTopNavBar 44px).
    height: "calc(100vh - 94px)",
    padding: 16
  }
});

interface IInfo {
  message: string;
  type: "info" | "success" | "warning" | "error" | null;
  size?: GridSize;
}

const RecipeRunLogs = () => {
  const { scenarioId, groupId } = useParams();
  const [searchParams] = useSearchParams();

  const isTestLogs = searchParams.get(IS_TEST_LOGS) === "true";

  const classes = useStyles();
  const { project } = useProjectContext() || {};

  const { data: recipe } = useTransformGroup(groupId, scenarioId);

  const { data, isFetching, isLoading, refetch } = useGetRecipeLogs({
    scenarioId,
    groupId: groupId!,
    showAllLogs: true,
    isRunRecipeLogs: !isTestLogs
  });

  const recipeLogs = data?.recipeLogs ?? [];
  const recipeStatus = data?.status ?? ("" as unknown as any);

  const getStatus = useMemo(() => {
    let style = {
      marginLeft: 10,
      backgroundColor: "#ecf5ff",
      borderColor: "#0844cf",
      color: "#0844cf"
    };

    switch (recipeStatus) {
      case RecipeStatuses.Success:
        style = {
          ...style,
          backgroundColor: "#ddfbe5",
          borderColor: "#1fa045",
          color: "#1fa045"
        };
        break;
      case RecipeStatuses.Error:
        style = {
          ...style,
          backgroundColor: "#fff2f1",
          borderColor: "#f74c54",
          color: "#f74c54"
        };
        break;
    }

    if (isTestLogs) {
      return null;
    }

    return <Chip style={style} label={<Typography variant="caption">{recipeStatus}</Typography>} />;
  }, [recipeStatus, isTestLogs]);

  const renderInfo = ({ message, type, size = 12 }: IInfo) => {
    return (
      <Grid
        container
        item
        xs={size}
        style={{
          marginTop: 25,
          display: "inline-block"
        }}>
        <ToastifyAlert type={type} message={message} />
      </Grid>
    );
  };

  const recipeRunInfo = useMemo(() => {
    switch (recipeStatus) {
      case GroupLogDtoStatusEnum.Success:
        return {
          type: "success" as const,
          message: !isTestLogs ? "Recipe run successful" : "Recipe test successful"
        };

      case GroupLogDtoStatusEnum.Running:
        return { type: "info" as const, message: "Recipe run in progress..." };

      case GroupLogDtoStatusEnum.Unbuilt:
        return {
          type: "info" as const,
          message: "Logs are not available because the recipe hasn't been executed yet."
        };

      default:
        return { type: "error" as const, message: "No logs found!" };
    }
  }, [recipeStatus]);

  return (
    <Grid container justifyContent="center">
      {!groupId ? (
        renderInfo({ message: "Invalid inputs.", type: "warning", size: 8 })
      ) : isLoading ? (
        <CommonLoader size={24} />
      ) : (
        <>
          <SubTopNavBarWrapper
            subTopNavBarLeftSection={{
              component: (
                <SubTopNavBarBreadcrumbs
                  project={project}
                  scenarioId={scenarioId}
                  recipe={recipe}
                />
              )
            }}
            subTopNavBarRightSection={{
              component: (
                <>
                  {getStatus}{" "}
                  {data?.show && (
                    <Tooltip title="Retrieves the most recent logs">
                      <Button
                        variant="outlined"
                        size="small"
                        disabled={isFetching}
                        startIcon={isFetching ? <CircularProgress size={16} /> : undefined}
                        onClick={() => refetch()}>
                        Refresh
                      </Button>
                    </Tooltip>
                  )}
                </>
              )
            }}
          />

          <Grid container className={classes.logsContainer}>
            {recipeLogs && recipeLogs?.length !== 0 ? (
              <Grid container item xs={12}>
                <Paper
                  style={{
                    width: "100%",
                    padding: "20px 25px",
                    backgroundColor: "#1e1e1e",
                    fontFamily: `Menlo, Monaco, "Courier New", monospace`,
                    color: "#d4d4d4",
                    fontSize: 12,
                    overflow: "auto"
                  }}>
                  <ul style={{ listStyle: "none" }}>
                    {recipeLogs.map((log, index) => {
                      return <li key={`log_${index}`}>{log}</li>;
                    })}
                  </ul>
                </Paper>
              </Grid>
            ) : isEmpty(recipeLogs) && isTestLogs ? (
              renderInfo({ type: "info", message: "No logs found!" })
            ) : (
              renderInfo(recipeRunInfo)
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default RecipeRunLogs;
