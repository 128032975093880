import React, { useMemo } from "react";
import clsx from "clsx";
import { useParams, useNavigate, useLocation, generatePath } from "react-router-dom";
import { Handle, Position } from "react-flow-renderer";

import { Grid, Tooltip, makeStyles } from "@material-ui/core";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";

import { addEllipsis, handleClickClosure } from "../../../utils/helpers/common.helpers";
import { useDrawerStore, useCanvasStore } from "stores/zustand/stores";
import { sideComponentSetter } from "stores/zustand/stores.selectors";
import { ArtifactModalV2 } from "../CanvasDrawerItems/ArtifactModalV2";
import { useSaveEntityStorageHelper } from "./useSaveEntityStorageHelper";
import { useCtrlKeyPress } from "src/hooks/useCtrlKeyPress";
import { useStyles as useCommonBlockStyles } from "./commonBlockStyles";
import { shouldHighlight } from "../helpers/CanvasFlow.helpers";
import { WebPaths } from "src/routing/routes";

const useStyles = makeStyles(() => ({
  artifactBlockStyles: {
    backgroundColor: ({ isBuilt }: { isBuilt: boolean }) => (isBuilt ? "#379DBD" : "#b6b7b9"),
    border: "1px solid rgba(0, 0, 0, 0.38)"
  }
}));

const ArtifactBlockV2 = ({ data }: $TSFixMe) => {
  const classes = useStyles({ isBuilt: data?.status === "BUILT" });
  const commonClasses = useCommonBlockStyles();
  const location = useLocation();
  const { scenarioId } = useParams<$TSFixMe>();

  const isJobCanvasPath = useMemo(() => /job-canvas/.test(location.pathname), [location.pathname]);

  const setSideComponent = useDrawerStore(sideComponentSetter);
  const shouldBlockClickHandlerTrigger = useCanvasStore(
    (state) => state.shouldBlockClickHandlerTrigger
  );
  const nodeToFocus = useCanvasStore((state) => state.nodeToFocus);
  const { isEntitySelected, handleMultiSelection } = useSaveEntityStorageHelper({
    data,
    sessionKey: "selectedArtifacts"
  });
  const { isCtrlPressed } = useCtrlKeyPress();
  const navigate = useNavigate();

  const disabledBlockMessage = useMemo(() => {
    if (!!data?.isJobCanvas && ["UNBUILT", "EMPTY", "SKIPPED"].includes(data?.status)) {
      return `Certain click actions are restricted as supplementary details for the specified artifact ${data?.label} are not accessible in the context of this scheduler run entry.`;
    }

    return "";
  }, [data?.isJobCanvas, data?.status]);

  const handleSingleClick = () => {
    if (!!disabledBlockMessage) {
      return;
    }

    if (!!data?.jobProps) {
      if (!isJobCanvasPath) {
        return;
      }
    }

    setSideComponent({
      sideComponent: ArtifactModalV2,
      sideComponentProps: {
        noHeader: true,
        data: { ...data, scenarioId: scenarioId || data?.scenarioId }
      }
    });
  };

  const handleDoubleClick = () => {
    if (!!disabledBlockMessage) {
      return;
    }

    let path = generatePath(WebPaths.ArtifactsDetails, { artifactName: data.name });

    if (!!data?.jobProps) {
      if (!isJobCanvasPath) {
        return;
      }

      path = generatePath(`${WebPaths.JobRoutes}${WebPaths.JobArtifacts}`, {
        projectId: data.projectId,
        jobId: data?.jobProps?.jobId,
        scenarioId: data?.scenarioId,
        jobRunId: data?.jobProps?.jobRunId,
        artifactName: data.name
      });
    }

    navigate({ pathname: path }, { state: { prevPath: location.pathname } });
  };

  const isSelected = isEntitySelected();

  return (
    <>
      <Handle type="target" position={Position.Left} />
      <Tooltip title={disabledBlockMessage} placement="top">
        <Grid
          className={clsx(commonClasses.blockContainer, classes.artifactBlockStyles, {
            [commonClasses.highlighted]: shouldHighlight(data?.label, nodeToFocus)
          })}
          onClick={handleClickClosure({
            shouldBlockClickHandlerTrigger:
              shouldBlockClickHandlerTrigger || data.shouldDisableBlockInteraction,
            isCtrlPressed,
            handleDoubleClick,
            handleSingleClick,
            handleMultiSelection,
            shouldClearEntities: false
          })}>
          <div className={commonClasses.labelContainer}>
            <div className={commonClasses.iconContainer}>
              <AccountBalanceIcon />
            </div>
            <div
              className={clsx(commonClasses.label, {
                [commonClasses.labelActive]: isSelected
              })}>
              {addEllipsis(data.label)}
            </div>
          </div>
        </Grid>
      </Tooltip>
      <Handle type="source" position={Position.Right} />
    </>
  );
};

export default ArtifactBlockV2;
