import { useMutation, UseMutationResult } from "@tanstack/react-query";

import { deleteAPI } from "services/Apis/Apis.service";

interface IVariables {
  name: string;
  filePath: string;
}
const useDeleteArtifactFile = (): UseMutationResult<unknown, unknown, IVariables, unknown> => {
  return useMutation({
    mutationFn: async ({ name, filePath }) => {
      return await deleteAPI(`/v2/artifacts/contents/${name}?path=${filePath}`, {});
    }
  });
};

export default useDeleteArtifactFile;
