import React from "react";

// Packages
import { generatePath, useNavigate } from "react-router-dom";

// MUI
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import MuiLink from "@material-ui/core/Link";

// Open API
import { EntityDtoStatusEnum } from "@rapidcanvas/rc-api-core";

// Utils
import { RenderText } from "components/custom";
import { WebPaths } from "src/routing/routes";
import { ViewFileWebPathQueryParameters } from "src/pages/private/ProjectsModule/utils";

// Hooks
import useAddFileQueryParam from "../../hooks/useAddFileQueryParam";

// Types
import { IEntity } from "src/hooks/api/entities/useEntities";

// Constants
import { DatasetHelperText } from "../../utils/Dataset.constants";

type Props = {
  scenarioId?: string;
  project: $TSFixMe;
  dataset?: IEntity;
  navigateBack: $TSFixMeFunction;
};

const SubTopNavBarBreadcrumbs = (props: Props) => {
  const { scenarioId, project, dataset, navigateBack } = props || {};
  const navigate = useNavigate();

  const isAddFile = useAddFileQueryParam();
  const featureName = !!isAddFile ? "File" : "Dataset";

  const navigatBackWithRedirect = (path: string) => {
    navigateBack().then(() => {
      navigate(path);
    });
  };

  const breadcrumbs: React.ReactNode[] = [];

  breadcrumbs.push(
    !!project?.id ? (
      <MuiLink
        key="projectName"
        onClick={() => navigatBackWithRedirect(`/projects/${project?.id}/canvas`)}>
        <RenderText>{project?.name || "Project"}</RenderText>
      </MuiLink>
    ) : (
      <RenderText key="projects" color="textSecondary">
        {project?.name || "Project"}
      </RenderText>
    )
  );

  if (!!dataset?.id) {
    if (!!project?.id && !!scenarioId) {
      let path: string;
      if (!!isAddFile) {
        path = generatePath(
          `${WebPaths.ViewFile}/?${ViewFileWebPathQueryParameters.Tab}=${ViewFileWebPathQueryParameters.Data}`,
          {
            projectId: project?.id,
            scenarioId,
            fileId: dataset?.id
          }
        );
      } else {
        path = generatePath(WebPaths.ViewData, {
          projectId: project?.id,
          entityId: dataset?.id,
          scenarioId,
          section: "data"
        });
      }

      breadcrumbs.push(
        <MuiLink
          key="link"
          onClick={() => navigatBackWithRedirect(path)}
          style={{ cursor: "pointer" }}>
          <RenderText>{dataset?.name || featureName}</RenderText>
        </MuiLink>
      );
    }

    breadcrumbs.push(
      <RenderText key="dataset" color="textSecondary">
        {!!isAddFile
          ? dataset?.status === EntityDtoStatusEnum.Unbuilt
            ? DatasetHelperText.AddFile
            : DatasetHelperText.UpdateFile
          : DatasetHelperText.AppendFile}
      </RenderText>
    );
  } else {
    breadcrumbs.push(
      <RenderText key="dataset" color="textSecondary">
        Create New {featureName}
      </RenderText>
    );
  }

  return (
    <Breadcrumbs>
      {breadcrumbs?.map((eachBreadcrumb: React.ReactNode) => eachBreadcrumb)}
    </Breadcrumbs>
  );
};

export default SubTopNavBarBreadcrumbs;
