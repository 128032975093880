// @TODO: The below will be converted to enum in a refactoring task.
export const Roles = {
  Admin: {
    name: "Admin"
  },
  User: {
    name: "User"
  },
  DataAppView: {
    name: "DataAppView"
  },
  Demo: {
    name: "Demo"
  },
  "Business User": {
    name: "Business User"
  },
  "DataApp Consumer": {
    name: "DataApp Consumer"
  },
  "DataApp Power User": {
    name: "DataApp Power User"
  }
};

export type RoleName = keyof typeof Roles;
