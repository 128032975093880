import React, { useMemo } from "react";

import { forEach, has, startCase, toLower } from "lodash";

import { dataSourcesTypes } from "src/pages/DataSources/utils/DataSources.constants";

import { useDataSourcesStore } from "stores/zustand/stores";
import { dataSourcesGetter } from "stores/zustand/stores.selectors";

import { thirdPartyTypeName } from "src/pages/DataSources/utils/DataSources.constants";

const useConnectorsMap = () => {
  const dataSourcesStore = useDataSourcesStore(dataSourcesGetter);

  return useMemo(() => {
    let apiSourcesMap: $TSFixMe = {};

    if ((dataSourcesStore || [])?.length > 0) {
      const dataSourcesTypesMap: $TSFixMe = {};

      forEach(dataSourcesTypes, (eachDataSourceType: $TSFixMe) => {
        dataSourcesTypesMap[eachDataSourceType.name] = {
          ...eachDataSourceType,
          isSql: eachDataSourceType.isSql ?? false
        };
      });

      forEach(dataSourcesStore, (dataSource: $TSFixMe) => {
        if (dataSource.dataSourceType === thirdPartyTypeName) {
          apiSourcesMap[dataSource?.id] = {
            ...dataSource,
            dataSourceType: {
              isSql: false,
              name: dataSource.tpConnectorType,
              displayName: startCase(toLower(dataSource?.tpConnectorType)),
              image: (
                <img
                  src={dataSource?.iconUrl}
                  alt={dataSource?.dataSourceType}
                  width="20px"
                  height="20px"
                />
              )
            }
          };
        }

        if (has(dataSourcesTypesMap, dataSource.dataSourceType)) {
          apiSourcesMap[dataSource?.id] = {
            ...dataSource,
            dataSourceType: {
              ...dataSourcesTypesMap[dataSource.dataSourceType]
            }
          };
        }
      });
    }

    return apiSourcesMap;
  }, [dataSourcesTypes, dataSourcesStore]);
};

export default useConnectorsMap;
