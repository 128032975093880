import React, { useMemo } from "react";
import _ from "lodash";

import DataConnectorStatus from "./DataSource/DataConnectorStatus";
import { Delete } from "src/assets/icons/Delete";
import { LinkCellWithMenu } from "src/components/Table/Cells/LinkCellWithMenu/LinkCellWithMenu.component";
import { PencilUnderlined } from "src/assets/icons/PencilUnderlined";

import OverflowTooltip from "components/OverflowTooltip";
import { dateFormat } from "utils/helpers/date.helpers";
import { getFivetranConnectorStatus } from "connectorsModule/utils/fivetran.helpers";
import { thirdPartyTypeName } from "src/pages/DataSources/utils/DataSources.constants";
import { useDataSourcesStore } from "stores/zustand/stores";
import Table from "src/components/custom/TableV8/Table";
import { CellContext, Row } from "@tanstack/react-table";

const DataSourcesTable = (props: $TSFixMe) => {
  const { dataSources, editDataSource, deleteDataSource, onManualSync } = props || {};
  const newConnectorToSync = useDataSourcesStore((state) => state.newConnectorToSync);

  const dataSourceSortType = (rowA: Row<any>, rowB: Row<any>) => {
    const valA =
      _.get(rowA.original, "dataSourceType") === thirdPartyTypeName
        ? _.get(rowA.original, "tpConnectorType")
        : _.get(rowA.original, "dataSourceType");
    const valB =
      _.get(rowB.original, "dataSourceType") === thirdPartyTypeName
        ? _.get(rowB.original, "tpConnectorType")
        : _.get(rowB.original, "dataSourceType");

    return valA.localeCompare(valB, undefined, { sensitivity: "base" });
  };

  const statusSortType = useMemo(
    () => (rowA: Row<any>, rowB: Row<any>) => {
      const valA = getFivetranConnectorStatus(
        _.get(rowA.original, "thirdPartyDataSourceStatus"),
        rowA?.original?.id === newConnectorToSync?.id,
        newConnectorToSync?.created
      );
      const valB = getFivetranConnectorStatus(
        _.get(rowB.original, "thirdPartyDataSourceStatus"),
        rowB?.original?.id === newConnectorToSync?.id,
        newConnectorToSync?.created
      );

      return valA.localeCompare(valB, undefined, { sensitivity: "base" });
    },
    [newConnectorToSync?.id, newConnectorToSync?.created]
  );

  const columns = [
    {
      id: "Connection Name",
      accessorKey: "name",
      header: "Connection Name",
      size: 180,
      meta: { isTooltip: false },
      cell: ({ row, getValue }: $TSFixMe) => {
        const handleClick = () => {
          editDataSource({
            ...row?.original,
            connectCardUrl: row?.original?.options?.connectCardUrl
          });
        };

        return (
          <LinkCellWithMenu
            style={{ cursor: "pointer" }}
            url={
              row?.original?.dataSourceType === thirdPartyTypeName
                ? ""
                : `/data-connectors/${row?.original?.id}`
            }
            linkText={<OverflowTooltip style={{ whiteSpace: "nowrap" }} value={getValue()} />}
            menuButtons={[
              {
                id: "editDataSource",
                label: "EDIT",
                icon: <PencilUnderlined />,
                action: handleClick
              },
              {
                id: "deleteDataSource",
                label: "DELETE",
                icon: <Delete />,
                action: () => deleteDataSource(row?.original?.id)
              }
            ]}
            onClick={handleClick}
          />
        );
      }
    },
    {
      id: "Type",
      accessorKey: "dataSourceType",
      header: "Type",
      sortingFn: dataSourceSortType,
      cell: ({ row, getValue }: $TSFixMe) =>
        row?.original?.dataSourceType === thirdPartyTypeName
          ? row.original.tpConnectorType
          : getValue() ?? null
    },
    {
      id: "Status",
      accessorKey: "thirdPartyDataSourceStatus",
      header: "Status",
      sortingFn: statusSortType,
      cell: ({ row }: $TSFixMe) => {
        const tpStatus = row?.original?.thirdPartyDataSourceStatus;
        const isSameConnector = row?.original?.id === newConnectorToSync?.id;
        const status = getFivetranConnectorStatus(
          tpStatus,
          isSameConnector,
          newConnectorToSync?.created
        );

        return (
          <DataConnectorStatus id={row?.original?.id} status={status} onManualSync={onManualSync} />
        );
      }
    },
    {
      id: "Projects",
      accessorKey: "projects",
      header: "Projects"
    },
    {
      id: "Updated by",
      accessorKey: "updater",
      header: "Updated by"
    },
    {
      id: "Updated on",
      accessorKey: "updated",
      header: "Updated on",
      cell: ({ getValue }: CellContext<any, any>) => <span>{dateFormat(getValue())}</span>
    }
  ];

  return (
    <Table
      data={dataSources}
      columns={columns}
      stylesProps={{ isTheadSticky: true, fixedLayout: true, maxHeight: "calc(100vh - 339px)" }}
      infoProps={{ hideCount: true }}
      sortingProps={{ orderByDefault: "Updated on" }}
    />
  );
};

export default DataSourcesTable;
