import { useDrawerStore } from "stores/zustand/stores";
import { sideComponentSetter } from "stores/zustand/stores.selectors";
import { StatusType } from "./ShowLogsDrawerTitle";
import { StandardLogs } from "./StandardLogs";
import { ReactNode } from "react";

export const useShowLogsModal = () => {
  const setSideComponent = useDrawerStore(sideComponentSetter);

  const openLogsSideBar = (logs: string[] | ReactNode, status?: StatusType, url?: string) => {
    const onClose = () => {
      setSideComponent({
        sideComponent: null,
        sideComponentProps: null
      });
    };

    setSideComponent({
      sideComponent: StandardLogs,
      sideComponentProps: {
        logs,
        url,
        status,
        noHeader: true,
        onClose
      }
    });
  };
  return { openLogsSideBar };
};
