import React from "react";

import NewThemeWrapper from "src/styles/NewThemeWrapper";

import SubTopNavBar from "./SubTopNavBar";

// @REFACTOR
// Using this wrapper only to apply NewThemeWrapper. It will be removed once new-theme is applied at platform-level.
const SubTopNavBarWrapper = ({ ...props }) => {
  return (
    <NewThemeWrapper>
      <SubTopNavBar {...props} />
    </NewThemeWrapper>
  );
};

export default SubTopNavBarWrapper;
