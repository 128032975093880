import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { Data } from "@rapidcanvas/rc-api-core";

import api from "services/AxiosClient/AxiosClient";

export const CACHE_QUERY_KEY_FETCH_PREDICTION_LOGS = "fetch-prediction-logs";

const usePredictionServiceLogs = (
  id: string,
  options?: UseQueryOptions<Data | undefined, any>
): UseQueryResult<Data | undefined, any> => {
  return useQuery<Data | undefined, any>({
    queryKey: [CACHE_QUERY_KEY_FETCH_PREDICTION_LOGS, id],
    queryFn: async () => {
      const data = await api.fetchResponse(
        async () => await api.PredictionServiceControllerApi.getLogs1(id)
      );

      return data.data;
    },
    ...options
  });
};

export default usePredictionServiceLogs;
