import { has, get } from "lodash";

import makeStyles from "@material-ui/core/styles/makeStyles";

import { NodeRecipeTheme } from "src/pages/private/ProjectsModule/utils";

type StyleProps = {
  status: string;
};

const useStyles = makeStyles({
  root: {
    width: 40,
    height: 40,
    borderRadius: "50%",
    backgroundColor: ({ status }: StyleProps) =>
      has(NodeRecipeTheme, status)
        ? get(NodeRecipeTheme, [status, "backgroundColor"])
        : NodeRecipeTheme.backgroundColor,
    border: "1px solid",
    borderColor: ({ status }: StyleProps) =>
      has(NodeRecipeTheme, status)
        ? get(NodeRecipeTheme, [status, "borderColor"])
        : NodeRecipeTheme.borderColor,
    color: ({ status }: StyleProps) =>
      has(NodeRecipeTheme, status)
        ? get(NodeRecipeTheme, [status, "iconColor"])
        : NodeRecipeTheme.iconColor,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: ({ status }: StyleProps) =>
        has(NodeRecipeTheme, status)
          ? get(NodeRecipeTheme, [status, "onHover", "backgroundColor"])
          : NodeRecipeTheme.onHover.backgroundColor,
      border: "1px solid",
      borderColor: ({ status }: StyleProps) =>
        has(NodeRecipeTheme, status)
          ? get(NodeRecipeTheme, [status, "onHover", "borderColor"])
          : NodeRecipeTheme.onHover.borderColor,
      color: ({ status }: StyleProps) =>
        has(NodeRecipeTheme, status)
          ? get(NodeRecipeTheme, [status, "onHover", "iconColor"])
          : NodeRecipeTheme.onHover.iconColor
    },
    "&.highlighted": {
      boxShadow: `0px 0px 10px 5px ${NodeRecipeTheme.highlightColor}`
    },
    "&.active": {
      boxShadow: `0px 0px 5px 5px  ${NodeRecipeTheme.activeColor}`
    }
  },
  container: {
    height: "100%",
    position: "relative",
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "center",
    alignItems: "center"
  },
  status: {
    top: -20,
    position: "absolute",
    color: ({ status }: StyleProps) =>
      has(NodeRecipeTheme, status)
        ? get(NodeRecipeTheme, [status, "statusColor"])
        : NodeRecipeTheme.statusColor,
    "&:hover": {
      color: ({ status }: StyleProps) =>
        has(NodeRecipeTheme, status)
          ? get(NodeRecipeTheme, [status, "onHover", "statusColor"])
          : NodeRecipeTheme.onHover.statusColor
    },
    "&.active": {
      fontWeight: "bold"
    }
  },
  icon: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  label: {
    top: 2,
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 5px",
    color: "#000",
    whiteSpace: "nowrap",
    "&.active": {
      fontWeight: "bold",
      backgroundColor: NodeRecipeTheme.activeColor
    }
  }
});

export default useStyles;
