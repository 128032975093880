import React from "react";

// Packages
import clsx from "clsx";
import { get } from "lodash";

// MUI
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";

// Open API
import { EnvDto } from "@rapidcanvas/rc-api-core";

// Components
import StopEnvironment from "../StopEnvironment";

// Constants
import { EnvironmentStatusValuesMapping } from "src/pages/private/EnvironmentsModule/utils/Environments.constants";

//Hooks
import { useStatusWithStopEnvState } from "../../hooks/useStatusWithStopEnvState";

// Styles
import useStyles from "./EnvironmentsTable.styles";

interface IProps {
  environment: EnvDto;
}

const Status: React.FC<IProps> = (props) => {
  const { environment } = props;
  const { launchStatus: status } = environment;

  const classes: any = useStyles();

  const {
    confirmStopEnvironment,
    resetStopEnvironment,
    onClick,
    showConfirmStopEnvModal,
    statusClass,
    statusText,
    showProgress
  } = useStatusWithStopEnvState({ environment });

  return (
    <>
      {!!showConfirmStopEnvModal && (
        <StopEnvironment
          data={showConfirmStopEnvModal}
          confirmStopEnvironment={confirmStopEnvironment}
          resetStopEnvironment={resetStopEnvironment}
        />
      )}
      <Grid
        container
        className={clsx(classes.statusContainer, classes[statusClass])}
        alignItems="center"
        justifyContent="space-between"
        spacing={1}>
        <Grid item>
          <Box fontWeight="bold" data-testid="envListStatusText">
            {statusText}
          </Box>
        </Grid>
        <Grid item>
          {showProgress ? (
            <CircularProgress size={12} style={{ marginRight: 4 }} />
          ) : (
            !!status &&
            !!get(EnvironmentStatusValuesMapping, [status, "icon"]) && (
              <Tooltip
                title={get(EnvironmentStatusValuesMapping, [status, "buttonText"], "")}
                data-testid="envListStatusTooltip">
                <IconButton
                  size="small"
                  style={{ padding: 0 }}
                  onClick={onClick}
                  data-testid="envListStatusAction">
                  {get(EnvironmentStatusValuesMapping, [status, "icon"])}
                </IconButton>
              </Tooltip>
            )
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default Status;
