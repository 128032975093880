import { useRef } from "react";
import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { PredictLiveLogDto } from "@rapidcanvas/rc-api-core";

import api from "services/AxiosClient/AxiosClient";

export const CACHE_QUERY_KEY_FETCH_PREDICTION_SERVICE_LIVE_LOGS =
  "fetch-dataset-prediction-service-live-logs";

const TESTING_REFETCH_INTERVAL = 2000; // 1sec
const REFETCH_COUNT_POST_TESTING = 2;

interface IReturn {
  show: boolean;
  logs: PredictLiveLogDto;
}
const usePredictionServiceLiveLogs = (
  id?: string,
  isTesting?: boolean,
  maxLimit?: number,
  options?: UseQueryOptions<IReturn, Error>
): UseQueryResult<IReturn, Error> => {
  const count = useRef(0);

  return useQuery<IReturn, Error>({
    queryKey: [CACHE_QUERY_KEY_FETCH_PREDICTION_SERVICE_LIVE_LOGS, id, isTesting, maxLimit],
    queryFn: async () => {
      if (!id) {
        throw "id is required!!";
      }
      const data = await api.fetchResponse(
        async () => await api.PredictionServiceControllerApi.getPredictionLogs(id)
      );

      if (!isTesting || maxLimit) {
        count.current++;
      }

      return {
        show: !!maxLimit && count.current >= maxLimit / TESTING_REFETCH_INTERVAL,
        logs: data
      };
    },
    refetchInterval: () => {
      if (isTesting) {
        if (maxLimit) {
          if (count.current < maxLimit / TESTING_REFETCH_INTERVAL) {
            return TESTING_REFETCH_INTERVAL;
          } else {
            return false;
          }
        } else {
          return TESTING_REFETCH_INTERVAL;
        }
      }

      if (count.current > REFETCH_COUNT_POST_TESTING) {
        return false;
      }

      return false;
    },
    ...options
  });
};

export default usePredictionServiceLiveLogs;
