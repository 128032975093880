import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  gridGap: {
    gap: "6px"
  },
  statName: {
    fontSize: "14px"
  },
  subHeading: {
    fontSize: "14px",
    color: "#000000",
    opacity: 0.5
  },
  statValue: {
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "30px"
  },
  singleStat: {
    display: "flex",
    gap: "4px",
    flexDirection: "column",
    alignItems: "center"
  }
});

const SingleStat = ({ name, value }: { name: string; value: number }) => {
  const classes = useStyles();
  return (
    <Grid className={classes.singleStat}>
      <Typography color="secondary" className={classes.statValue}>
        {value}
      </Typography>
      <Typography color="textPrimary" className={classes.statName}>
        {name}
      </Typography>
    </Grid>
  );
};

const TenantStats = ({ projectCount, userCount }: { projectCount: number; userCount: number }) => {
  const classes = useStyles();
  return (
    <Grid container direction="column" className={classes.gridGap}>
      <Typography className={classes.subHeading}>Workspace Stats</Typography>
      <Grid container direction="row" justifyContent="space-around">
        <SingleStat name="No. of Projects" value={projectCount} />
        <SingleStat name="No. of Users" value={userCount} />
      </Grid>
    </Grid>
  );
};

export default TenantStats;
