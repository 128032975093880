import React from "react";

interface IProps {
  width?: number;
  height?: number;
  viewBox?: string;
  fill?: string;
  color?: string;
}

const AboutPage: React.FC<IProps> = (props) => {
  const { width = 155, height = 155, viewBox = "0 0 155 155" } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M125.892 103.291L75.5208 80.295L101.458 23.48L151.83 46.4756L125.892 103.291Z"
        fill="#F2F2F2"
      />
      <path
        d="M133.566 70.2841L95.4329 52.8757C95.2834 52.8074 95.2175 52.6308 95.2857 52.4813C95.354 52.3317 95.5306 52.2658 95.6802 52.3341L133.813 69.7424C133.963 69.8107 134.028 69.9873 133.96 70.1369C133.892 70.2865 133.715 70.3524 133.566 70.2841Z"
        fill="white"
      />
      <path
        d="M135.686 65.6395L97.5533 48.2312C97.4037 48.1629 97.3378 47.9863 97.4061 47.8367C97.4744 47.6872 97.651 47.6213 97.8006 47.6896L135.933 65.0979C136.083 65.1662 136.149 65.3428 136.081 65.4924C136.012 65.6419 135.836 65.7078 135.686 65.6395Z"
        fill="white"
      />
      <path
        d="M137.807 60.9945L99.6739 43.5862C99.5243 43.5179 99.4584 43.3413 99.5267 43.1917C99.595 43.0421 99.7716 42.9763 99.9212 43.0445L138.054 60.4529C138.203 60.5214 138.269 60.6981 138.2 60.8476C138.132 60.9967 137.956 61.0624 137.807 60.9945Z"
        fill="white"
      />
      <path
        d="M139.927 56.35L101.794 38.9416C101.645 38.8734 101.579 38.6967 101.647 38.5472C101.715 38.3976 101.892 38.3317 102.042 38.4L140.174 55.8083C140.324 55.8766 140.39 56.0532 140.322 56.2028C140.253 56.3524 140.077 56.4183 139.927 56.35Z"
        fill="white"
      />
      <path
        d="M131.649 74.4818L93.5167 57.0735C93.3671 57.0052 93.3012 56.8286 93.3695 56.679C93.4378 56.5294 93.6144 56.4636 93.7639 56.5318L131.897 73.9402C132.046 74.0085 132.112 74.1851 132.044 74.3346C131.976 74.4842 131.799 74.5501 131.649 74.4818Z"
        fill="white"
      />
      <path
        d="M138.017 49.5366L108.23 35.9384C107.884 35.789 107.704 35.4033 107.812 35.0414C107.938 34.6665 108.345 34.4651 108.72 34.5914C108.743 34.5993 108.766 34.6084 108.788 34.6186L138.566 48.2125C138.919 48.3628 139.102 48.7556 138.99 49.123C138.859 49.4961 138.449 49.692 138.076 49.5605C138.056 49.5534 138.036 49.5454 138.017 49.5366Z"
        fill="white"
      />
      <path
        d="M23.406 46.4756L73.7777 23.48L99.7149 80.295L49.3431 103.291L23.406 46.4756Z"
        fill="#F2F2F2"
      />
      <path
        d="M41.6699 70.2841L79.8027 52.8757C79.9523 52.8074 80.0182 52.6308 79.9499 52.4813C79.8816 52.3317 79.705 52.2658 79.5555 52.3341L41.4227 69.7424C41.2731 69.8107 41.2072 69.9873 41.2755 70.1369C41.3438 70.2865 41.5204 70.3524 41.6699 70.2841Z"
        fill="white"
      />
      <path
        d="M39.5496 65.6395L77.6824 48.2312C77.832 48.1629 77.8978 47.9863 77.8296 47.8367C77.7613 47.6872 77.5847 47.6213 77.4351 47.6896L39.3023 65.0979C39.1528 65.1662 39.0869 65.3428 39.1551 65.4924C39.2234 65.6419 39.4 65.7078 39.5496 65.6395Z"
        fill="white"
      />
      <path
        d="M37.429 60.9945L75.5618 43.5862C75.7114 43.5179 75.7773 43.3413 75.709 43.1917C75.6407 43.0421 75.4641 42.9763 75.3145 43.0445L37.1817 60.4529C37.0323 60.5214 36.9667 60.6981 37.0352 60.8476C37.1036 60.9967 37.2797 61.0624 37.429 60.9945Z"
        fill="white"
      />
      <path
        d="M35.3086 56.35L73.4414 38.9416C73.591 38.8734 73.6569 38.6967 73.5886 38.5472C73.5203 38.3976 73.3437 38.3317 73.1942 38.4L35.0613 55.8083C34.9118 55.8766 34.8459 56.0532 34.9142 56.2028C34.9825 56.3524 35.1591 56.4183 35.3086 56.35Z"
        fill="white"
      />
      <path
        d="M43.5862 74.4818L81.719 57.0735C81.8686 57.0052 81.9344 56.8286 81.8662 56.679C81.7979 56.5294 81.6213 56.4636 81.4717 56.5318L43.3389 73.9402C43.1893 74.0085 43.1235 74.1851 43.1918 74.3346C43.26 74.4842 43.4366 74.5501 43.5862 74.4818Z"
        fill="white"
      />
      <path
        d="M37.2185 49.5366L67.005 35.9384C67.352 35.789 67.532 35.4033 67.4236 35.0414C67.2972 34.6665 66.8909 34.4651 66.516 34.5914C66.4927 34.5993 66.4698 34.6084 66.4474 34.6186L36.6699 48.2125C36.3165 48.3628 36.1333 48.7556 36.2455 49.123C36.377 49.4961 36.786 49.692 37.1591 49.5605C37.1793 49.5534 37.1991 49.5454 37.2185 49.5366Z"
        fill="white"
      />
      <path
        d="M5.03551 113.562C5.35353 108.636 8.09841 103.638 12.6679 101.77C11.1272 106.912 11.4552 112.434 13.5938 117.358C14.4255 119.254 15.5479 121.276 14.9897 123.27C14.6424 124.511 13.6716 125.502 12.5591 126.151C11.4466 126.801 10.1901 127.153 8.94924 127.5L8.714 127.719C6.45119 123.331 4.71749 118.489 5.03551 113.562Z"
        fill="#F0F0F0"
      />
      <path
        d="M12.7195 101.859C10.184 105.063 8.72268 108.985 8.54302 113.067C8.48873 113.945 8.57321 114.826 8.79332 115.678C9.03455 116.501 9.49173 117.244 10.1173 117.831C10.6897 118.389 11.3398 118.894 11.7664 119.582C12.1975 120.301 12.3014 121.169 12.052 121.969C11.7703 122.963 11.1484 123.796 10.5118 124.59C9.80509 125.471 9.05708 126.375 8.80134 127.503C8.77035 127.64 8.56011 127.584 8.59105 127.448C9.03595 125.485 10.8436 124.278 11.6336 122.488C12.0022 121.652 12.1213 120.701 11.6799 119.868C11.2939 119.14 10.6239 118.619 10.0395 118.058C9.42078 117.495 8.94938 116.789 8.66646 116.002C8.4037 115.164 8.28541 114.287 8.31668 113.41C8.35558 111.433 8.70506 109.475 9.35229 107.606C10.0792 105.47 11.1661 103.474 12.5661 101.704C12.6528 101.595 12.8056 101.75 12.7195 101.859Z"
        fill="white"
      />
      <path
        d="M8.52366 111.749C7.11755 111.495 6.1027 110.259 6.12638 108.831C6.1279 108.771 6.17789 108.723 6.238 108.725C6.29518 108.726 6.34147 108.772 6.34401 108.829C6.31947 110.16 7.26829 111.31 8.57952 111.539C8.71738 111.563 8.66075 111.773 8.52366 111.749Z"
        fill="white"
      />
      <path
        d="M9.78981 117.586C10.959 116.79 11.7854 115.583 12.1041 114.205C12.1356 114.069 12.3458 114.125 12.3144 114.261C11.9801 115.694 11.1176 116.949 9.89903 117.774C9.78281 117.853 9.67426 117.664 9.78981 117.586Z"
        fill="white"
      />
      <path
        d="M10.293 105.664C10.8079 105.873 11.3931 105.808 11.8501 105.493C11.9653 105.412 12.0737 105.601 11.9593 105.681C11.4529 106.027 10.8079 106.1 10.2371 105.875C10.1803 105.858 10.1465 105.799 10.1599 105.742C10.1756 105.684 10.235 105.649 10.293 105.664Z"
        fill="white"
      />
      <path
        d="M27.4021 109.661C27.3316 109.713 27.2612 109.765 27.1907 109.819C26.2468 110.523 25.3617 111.302 24.5441 112.15C24.4798 112.215 24.4156 112.281 24.3534 112.347C22.4053 114.41 20.8571 116.816 19.7875 119.444C19.3629 120.491 19.0184 121.568 18.7572 122.666C18.3964 124.183 18.126 125.856 17.3093 127.128C17.2256 127.262 17.1337 127.39 17.0341 127.512L8.99968 127.989C8.98088 127.981 8.9621 127.975 8.94321 127.966L8.62329 128C8.6328 127.943 8.64392 127.883 8.65343 127.825C8.6588 127.792 8.66597 127.759 8.67138 127.725C8.67554 127.703 8.67979 127.68 8.68224 127.66C8.68359 127.653 8.68505 127.645 8.68651 127.64C8.689 127.619 8.69346 127.601 8.69603 127.582C8.75721 127.249 8.82082 126.916 8.88688 126.583C8.88677 126.581 8.88677 126.581 8.88845 126.579C9.39835 124.042 10.1246 121.524 11.2098 119.197C11.2425 119.127 11.275 119.055 11.3113 118.985C11.8063 117.939 12.3859 116.935 13.0445 115.983C13.4068 115.463 13.7951 114.962 14.2081 114.481C15.2775 113.241 16.5354 112.177 17.9355 111.328C20.7269 109.636 24.0284 108.855 27.1632 109.601C27.2434 109.62 27.3218 109.64 27.4021 109.661Z"
        fill="#F0F0F0"
      />
      <path
        d="M27.3901 109.763C23.4364 110.795 19.9084 113.047 17.3072 116.198C16.7352 116.867 16.2721 117.621 15.935 118.434C15.6322 119.236 15.5498 120.105 15.6962 120.949C15.817 121.74 16.032 122.535 15.9585 123.341C15.87 124.174 15.43 124.93 14.7491 125.419C13.9259 126.043 12.9277 126.333 11.9416 126.584C10.8468 126.862 9.70512 127.134 8.8219 127.88C8.71489 127.971 8.58078 127.799 8.68762 127.709C10.2243 126.41 12.3943 126.535 14.103 125.581C14.9004 125.136 15.5684 124.447 15.7173 123.517C15.8474 122.703 15.6264 121.883 15.4972 121.084C15.3422 120.262 15.3909 119.414 15.639 118.615C15.9336 117.788 16.3669 117.017 16.9202 116.335C18.1414 114.781 19.5995 113.427 21.2411 112.325C23.1076 111.057 25.1773 110.118 27.3606 109.548C27.4957 109.513 27.5244 109.728 27.3901 109.763Z"
        fill="white"
      />
      <path
        d="M18.0851 115.134C17.1154 114.085 17.049 112.487 17.9281 111.361C18.0146 111.251 18.1897 111.38 18.1031 111.49C17.2822 112.538 17.3472 114.028 18.2562 115C18.3518 115.102 18.18 115.236 18.0851 115.134Z"
        fill="white"
      />
      <path
        d="M15.5822 120.557C16.9948 120.626 18.3814 120.159 19.4654 119.251C19.5728 119.161 19.7069 119.332 19.5997 119.422C18.4698 120.365 17.0259 120.848 15.556 120.773C15.4159 120.766 15.4428 120.55 15.5822 120.557Z"
        fill="white"
      />
      <path
        d="M23.1615 111.341C23.4473 111.817 23.9533 112.118 24.5083 112.141C24.6484 112.146 24.6214 112.362 24.4822 112.357C23.8694 112.329 23.3106 111.998 22.9903 111.475C22.9552 111.427 22.9633 111.36 23.0088 111.322C23.0561 111.286 23.1242 111.294 23.1615 111.341Z"
        fill="white"
      />
      <path d="M125.095 103.768H49.7742V18.813H125.095V103.768Z" fill="white" />
      <path
        d="M125.095 103.768H49.7742V18.813H125.095V103.768ZM50.1791 103.363H124.69V19.2179H50.1791V103.363Z"
        fill="#CACACA"
      />
      <path
        d="M115.945 58.5912H58.9246C58.701 58.5915 58.5194 58.4105 58.519 58.1868C58.5187 57.9631 58.6998 57.7816 58.9234 57.7812C58.9238 57.7812 58.9242 57.7812 58.9246 57.7812H115.945C116.168 57.7816 116.349 57.9631 116.349 58.1868C116.349 58.41 116.168 58.5908 115.945 58.5912Z"
        fill="#E4E4E4"
      />
      <path
        d="M115.945 51.6458H58.9246C58.701 51.6462 58.5194 51.4652 58.519 51.2415C58.5187 51.0179 58.6998 50.8363 58.9234 50.8359C58.9238 50.8359 58.9242 50.8359 58.9246 50.8359H115.945C116.168 50.8363 116.349 51.0178 116.349 51.2415C116.349 51.4647 116.168 51.6455 115.945 51.6458Z"
        fill="#E4E4E4"
      />
      <path
        d="M115.945 44.7005H58.9246C58.701 44.7009 58.5194 44.5199 58.519 44.2962C58.5187 44.0725 58.6998 43.891 58.9234 43.8906C58.9238 43.8906 58.9242 43.8906 58.9246 43.8906H115.945C116.168 43.891 116.349 44.0725 116.349 44.2962C116.349 44.5194 116.168 44.7002 115.945 44.7005Z"
        fill="#E4E4E4"
      />
      <path
        d="M115.945 37.7552H58.9246C58.701 37.7556 58.5194 37.5745 58.519 37.3509C58.5187 37.1272 58.6998 36.9456 58.9234 36.9453C58.9238 36.9453 58.9242 36.9453 58.9246 36.9453H115.945C116.168 36.9456 116.349 37.1272 116.349 37.3509C116.349 37.5741 116.168 37.7549 115.945 37.7552Z"
        fill="#E4E4E4"
      />
      <path
        d="M115.945 64.868H58.9246C58.701 64.8683 58.5194 64.6873 58.519 64.4637C58.5187 64.24 58.6998 64.0584 58.9234 64.0581C58.9238 64.0581 58.9242 64.0581 58.9246 64.0581H115.945C116.168 64.0584 116.349 64.24 116.349 64.4637C116.349 64.6868 116.168 64.8677 115.945 64.868Z"
        fill="#E4E4E4"
      />
      <path
        d="M115.945 96.0282H58.9246C58.701 96.0285 58.5194 95.8475 58.519 95.6238C58.5187 95.4002 58.6998 95.2186 58.9234 95.2183C58.9238 95.2183 58.9242 95.2183 58.9246 95.2183H115.945C116.168 95.2186 116.349 95.4002 116.349 95.6238C116.349 95.847 116.168 96.0278 115.945 96.0282Z"
        fill="#E4E4E4"
      />
      <path
        d="M102.285 89.5279H72.5835C72.1701 89.5274 71.8352 89.1925 71.8347 88.7791V71.3074C71.8352 70.894 72.1702 70.559 72.5835 70.5586H102.285C102.699 70.5591 103.034 70.894 103.034 71.3074V88.7791C103.034 89.1924 102.699 89.5275 102.285 89.5279Z"
        fill="white"
      />
      <path
        d="M92.3666 79.5464C91.7438 78.8796 90.6983 78.8439 90.0315 79.4667C90.0184 79.4789 90.0056 79.4914 89.9929 79.504L81.634 87.8629C81.1743 88.3216 80.4303 88.3227 79.9692 87.8654L78.7312 86.6299C78.0877 85.9861 77.0442 85.9859 76.4004 86.6294C76.4002 86.6296 76.4001 86.6297 76.3999 86.6299L74.0039 89.0285H100.865V88.6866L92.3666 79.5464Z"
        fill="#6C63FF"
      />
      <path
        d="M102.285 89.5279H72.5835C72.1701 89.5274 71.8352 89.1925 71.8347 88.7791V71.3074C71.8352 70.894 72.1702 70.559 72.5835 70.5586H102.285C102.699 70.5591 103.034 70.894 103.034 71.3074V88.7791C103.034 89.1924 102.699 89.5275 102.285 89.5279ZM72.5835 71.0578C72.4457 71.058 72.3341 71.1696 72.3339 71.3074V88.7791C72.3341 88.9169 72.4457 89.0286 72.5835 89.0287H102.285C102.423 89.0286 102.535 88.9169 102.535 88.7791V71.3074C102.535 71.1696 102.423 71.0579 102.285 71.0578H72.5835Z"
        fill="#3F3D56"
      />
      <path
        d="M30.6793 86.6071C30.9638 85.6042 30.3814 84.5605 29.3785 84.276C29.2715 84.2457 29.1621 84.2249 29.0515 84.2138L27.4952 77.689L25.1099 80.2307L26.9535 86.0371C26.9182 87.0847 27.7388 87.9625 28.7863 87.9978C29.6629 88.0273 30.4454 87.4524 30.6793 86.6071Z"
        fill="#FFB6B6"
      />
      <path
        d="M26.5192 84.2405C26.2915 84.1647 26.1067 83.9957 26.0109 83.7757L22.7111 75.7045C21.8697 73.7695 21.7597 71.5947 22.4017 69.5846L24.5161 62.9616C24.6507 61.4648 25.9732 60.3604 27.47 60.495C28.9668 60.6295 30.0712 61.952 29.9366 63.4489C29.9363 63.4518 29.9361 63.4547 29.9358 63.4575L27.1318 74.1226L29.0099 82.4986C29.0701 82.849 28.9051 83.1998 28.5968 83.3769L27.2047 84.1723C27.0554 84.2576 26.8836 84.2953 26.7123 84.2804C26.6466 84.2748 26.5818 84.2614 26.5192 84.2405Z"
        fill="#E4E4E4"
      />
      <path d="M38.2557 125.595H40.5569L41.6518 116.719H38.2551L38.2557 125.595Z" fill="#FFB6B6" />
      <path
        d="M37.6685 124.844L42.2006 124.844H42.2008C43.7959 124.844 45.0889 126.137 45.089 127.732V127.826L37.6686 127.826L37.6685 124.844Z"
        fill="#2F2E41"
      />
      <path d="M25.3035 125.595H27.6047L28.6996 116.719H25.303L25.3035 125.595Z" fill="#FFB6B6" />
      <path
        d="M24.7163 124.844L29.2484 124.844H29.2486C30.8437 124.844 32.1367 126.137 32.1369 127.732V127.826L24.7164 127.826L24.7163 124.844Z"
        fill="#2F2E41"
      />
      <path
        d="M39.6414 80.8227L26.1455 79.6353C26.1455 79.6353 24.8121 88.1435 24.9998 91.5223C25.1875 94.9012 25.3752 100.157 25.3752 100.157L23.8735 123.058L29.6926 122.307L30.4435 103.348L32.8837 93.024L35.6994 106.164L37.5765 123.058L42.6448 122.495L40.9554 102.034C40.9554 102.034 42.4571 83.6384 39.6414 80.8227Z"
        fill="#2F2E41"
      />
      <path
        d="M36.8513 54.7567C38.3175 53.2906 38.3175 50.9135 36.8513 49.4474C35.3852 47.9813 33.0082 47.9813 31.5421 49.4474C30.076 50.9135 30.076 53.2906 31.5421 54.7567C33.0082 56.2228 35.3852 56.2228 36.8513 54.7567Z"
        fill="#FFB8B8"
      />
      <path
        d="M38.309 60.485L35.6276 58.3506C35.6276 58.3506 32.7239 56.11 31.0205 57.7665L30.2558 58.8599C30.2558 58.8599 25.9199 60.485 25.9199 62.5499L26.1261 73.8769C26.1261 73.8769 24.6244 79.696 26.8769 80.0714L39.9984 81.1334L39.8291 70.3104L38.309 60.485Z"
        fill="#E4E4E4"
      />
      <path
        d="M31.288 55.3876C31.288 55.3876 27.346 52.5719 29.4109 49.1931C30.8433 46.849 33.8116 47.0346 35.4954 47.3681C36.4162 47.5381 37.1945 48.1503 37.5763 49.0054C37.8579 49.6624 37.8579 50.3193 36.7316 50.3193C34.4791 50.3193 35.6054 52.0088 35.6054 52.0088C35.6054 52.0088 34.1037 52.3842 34.4791 53.6982C34.8545 55.0122 31.288 55.3876 31.288 55.3876Z"
        fill="#2F2E41"
      />
      <path
        d="M51.8536 80.2679C51.5894 79.2594 50.5578 78.6561 49.5493 78.9202C49.4418 78.9484 49.3369 78.986 49.236 79.0326L44.5822 74.2017L43.8179 77.6026L48.3544 81.6687C48.8559 82.5891 50.0086 82.9287 50.9289 82.4272C51.6991 82.0075 52.0814 81.1148 51.8536 80.2679Z"
        fill="#FFB6B6"
      />
      <path
        d="M47.068 80.3413C46.8333 80.3916 46.5883 80.3398 46.3941 80.1989L39.4531 74.9211C37.7456 73.6813 36.5466 71.8635 36.0791 69.8059L34.5378 63.0265C33.8932 61.6689 34.4713 60.0458 35.829 59.4013C37.1866 58.7568 38.8097 59.3349 39.4542 60.6925C39.4557 60.6956 39.4572 60.6987 39.4586 60.7019L42.4582 71.3136L48.3292 77.576C48.559 77.8473 48.595 78.2332 48.4194 78.5424L47.624 79.9345C47.5386 80.0837 47.4097 80.2034 47.2546 80.2776C47.1951 80.3061 47.1325 80.3275 47.068 80.3413Z"
        fill="#E4E4E4"
      />
      <path
        d="M142.409 5.66481H33.9948C33.9079 5.66456 33.8376 5.59392 33.8379 5.50701C33.8381 5.42044 33.9083 5.35032 33.9948 5.3501H142.409C142.496 5.35035 142.566 5.42099 142.566 5.50789C142.566 5.59444 142.496 5.66456 142.409 5.66481Z"
        fill="#CACACA"
      />
      <path
        d="M37.5363 3.46171C38.4922 3.46171 39.2671 2.68678 39.2671 1.73085C39.2671 0.77493 38.4922 0 37.5363 0C36.5804 0 35.8054 0.77493 35.8054 1.73085C35.8054 2.68678 36.5804 3.46171 37.5363 3.46171Z"
        fill="#3F3D56"
      />
      <path
        d="M43.5158 3.46171C44.4717 3.46171 45.2466 2.68678 45.2466 1.73085C45.2466 0.77493 44.4717 0 43.5158 0C42.5598 0 41.7849 0.77493 41.7849 1.73085C41.7849 2.68678 42.5598 3.46171 43.5158 3.46171Z"
        fill="#3F3D56"
      />
      <path
        d="M49.495 3.46171C50.4509 3.46171 51.2259 2.68678 51.2259 1.73085C51.2259 0.77493 50.4509 0 49.495 0C48.5391 0 47.7642 0.77493 47.7642 1.73085C47.7642 2.68678 48.5391 3.46171 49.495 3.46171Z"
        fill="#3F3D56"
      />
      <path
        d="M138.827 1.05859H134.578C134.404 1.05796 134.264 0.91654 134.265 0.742723C134.265 0.569817 134.405 0.429833 134.578 0.429199H138.827C139 0.429833 139.141 0.57125 139.14 0.745066C139.14 0.917945 139 1.05796 138.827 1.05859Z"
        fill="#3F3D56"
      />
      <path
        d="M138.827 2.23877H134.578C134.404 2.23814 134.264 2.09672 134.265 1.9229C134.265 1.75 134.405 1.61001 134.578 1.60938H138.827C139 1.61001 139.141 1.75143 139.14 1.92525C139.14 2.09812 139 2.23814 138.827 2.23877Z"
        fill="#3F3D56"
      />
      <path
        d="M138.827 3.41895H134.578C134.404 3.41831 134.264 3.27689 134.265 3.10308C134.265 2.93017 134.405 2.79018 134.578 2.78955H138.827C139 2.79018 139.141 2.9316 139.14 3.10542C139.14 3.2783 139 3.41831 138.827 3.41895Z"
        fill="#3F3D56"
      />
      <path
        d="M71.7062 127.874H0.187712C0.08404 127.874 0 127.79 0 127.686C0 127.583 0.08404 127.499 0.187712 127.499H71.7062C71.8099 127.499 71.8939 127.583 71.8939 127.686C71.8939 127.79 71.8099 127.874 71.7062 127.874Z"
        fill="#CACACA"
      />
      <path
        d="M109.732 30.4032H65.1925C64.6788 30.4143 64.2382 30.0387 64.1679 29.5296C64.1125 28.9943 64.5015 28.5155 65.0368 28.4601C65.0701 28.4567 65.1035 28.455 65.1369 28.4549H109.663C110.185 28.4412 110.634 28.8238 110.703 29.3418C110.751 29.8777 110.355 30.3512 109.819 30.3993C109.79 30.4019 109.761 30.4032 109.732 30.4032Z"
        fill="#6C63FF"
      />
    </svg>
  );
};

export default AboutPage;
