import * as React from "react";

import { dateFormat } from "utils/helpers/date.helpers";

import { IEntityDetails } from "hooks/api/entities/useEntityDetails";
import { useStyles } from "./styling";

interface IProps {
  entityDetails: IEntityDetails;
}
export const VectorDetails = ({ entityDetails }: IProps) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.detailsWrapper}>
        {entityDetails?.parentTransform && (
          <div className={classes.detailItem}>
            <p className={classes.detailName} data-testid="entity-detail-build-label">
              Build:
            </p>
            <p data-testid="entity-detail-build-value">{dateFormat(entityDetails?.built)}</p>
          </div>
        )}
        <div className={classes.detailItem}>
          <p data-testid="entity-detail-created-label" className={classes.detailName}>
            Created:
          </p>
          <p data-testid="entity-detail-created-value">{dateFormat(entityDetails?.created)}</p>
        </div>
        <div className={classes.detailItem}>
          <p data-testid="entity-detail-updated-label" className={classes.detailName}>
            Updated:
          </p>
          <p data-testid="entity-detail-updated-value">{dateFormat(entityDetails?.updated)}</p>
        </div>
        {entityDetails?.parentTransform && (
          <div className={classes.detailItem}>
            <p data-testid="entity-detail-parent-transform-label" className={classes.detailName}>
              Parent Transform:
            </p>
            <p data-testid="entity-detail-parent-transform-value">
              {entityDetails?.parentTransform ?? "-"}
            </p>
          </div>
        )}

        <div className={classes.detailItem}>
          <p data-testid="entity-detail-parent-transform-label" className={classes.detailName}>
            Collection name:
          </p>
          <p data-testid="entity-detail-parent-transform-value">
            {!!entityDetails?.vectorStoreCollectionName
              ? entityDetails?.vectorStoreCollectionName
              : "-"}
          </p>
        </div>
      </div>
    </>
  );
};

export default VectorDetails;
