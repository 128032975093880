import React, { useState } from "react";

// MUI
import Button from "@material-ui/core/Button";

// Icons
import { TrashIcon } from "icons/NewUX/TrashIcon";

// Types
import { NodeData } from "src/types";

// Constants
import { DeleteNodesHelperText } from "./DeleteNodes.constants";
import DeleteNodesModal from "./DeleteNodesModal";

interface IProps {
  projectId?: string;
  scenarioId?: string;
  selectedNodes: NodeData[];
  resetSelectedNodes: () => void;
}

const DeleteNodes: React.FC<IProps> = (props) => {
  const { projectId, scenarioId, selectedNodes, resetSelectedNodes } = props;

  // States - STARTS >>
  const [showConfirmDeleteNodesModal, setShowConfirmDeleteNodesModal] = useState(false);
  // << ENDS - States

  // Delete nodes - STARTS >>
  const promptConfirmDeleteNodes = () => {
    setShowConfirmDeleteNodesModal(() => true);
  };

  const resetConfirmDeleteNodes = () => {
    setShowConfirmDeleteNodesModal(() => false);
  };
  // << ENDS - Delete nodes

  return (
    <>
      {!!showConfirmDeleteNodesModal && (
        <DeleteNodesModal
          projectId={projectId}
          scenarioId={scenarioId}
          selectedNodes={selectedNodes}
          resetSelectedNodes={resetSelectedNodes}
          resetConfirmDeleteNodes={resetConfirmDeleteNodes}
        />
      )}

      <Button
        size="small"
        variant="contained"
        color="primary"
        onClick={promptConfirmDeleteNodes}
        startIcon={<TrashIcon viewBox="0 0 20 20" />}>
        {DeleteNodesHelperText.Delete}
      </Button>
    </>
  );
};

export default DeleteNodes;
