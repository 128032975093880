import React, { useMemo, useState } from "react";
import _, { get } from "lodash";
import { Box, Grid, makeStyles } from "@material-ui/core";

import AIGuideDataset from "./AIGuideDataset";
import { AIChatResponseDto } from "@rapidcanvas/rc-api-core";
import AIGuideChatBox from "./AIGuideChatBox";
import AIGuideOutputWrap from "./AIGuideOutputWrap";
import { AskAICodeEditor } from "../../common/AskAICodeEditor";
import { EntityIconV3 } from "icons/NewUX/EntityIconV3";
import { SampleRows } from "../../AddCodeRecipe/CodeRecipeTabContainer/AskAIContainer/AskAIResponseContainer/SampleRows";
import CopyDataButton from "./CopyDataButton";

const useStyles = makeStyles({
  dataset: {
    padding: "0px 12px 0px 12px",
    width: "100%"
  },
  root: {
    flexDirection: "row",
    alignItems: "flex-start",
    flexWrap: "nowrap",
    padding: "0px 32px 0px 16px"
  },
  icon: {
    height: "32px",
    width: "24px !important"
  },
  datasetWrap: {
    margin: "5px 12px 5px 12px",
    border: "1px solid #D1D1D1",
    overflowY: "auto",
    borderRadius: "2px"
  },
  flex: {
    display: "flex",
    gap: "16px"
  }
});

interface ICopyData {
  data: Record<string, string | number | boolean>[];
  index: number;
}

const AIGuideDatasetResponse = ({
  message,
  isExpanded,
  setIsExpanded,
  rightContainer,
  shouldShowCode
}: {
  message: AIChatResponseDto;
  isExpanded: boolean;
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  rightContainer: React.ReactNode;
  shouldShowCode: boolean;
}) => {
  const classes = useStyles();
  const [value, handleChange] = useState<number>(0);
  const [copyData, setCopyData] = useState<ICopyData[]>([]);
  const { outputEntityList, outputEntityResponseList, transformId, code, error, sampleRows } =
    message;

  const datasetList = outputEntityList || outputEntityResponseList;
  const hasResponse = outputEntityResponseList && outputEntityResponseList?.length !== 0;
  const title = hasResponse
    ? `${outputEntityResponseList?.[0]?.name}${outputEntityResponseList?.length && outputEntityResponseList?.length !== 1 ? ` (+${outputEntityResponseList?.length - 1})` : ""}`
    : `${outputEntityList?.[0]?.name}${outputEntityList?.length && outputEntityList?.length !== 1 ? ` (+${outputEntityList?.length - 1})` : ""}`;

  const tableData = useMemo(() => _.find(copyData, { index: value }), [value, copyData]);

  const handleSuccess = (data: Record<string, any>[], index: number) => {
    setCopyData((prevData) => [...prevData, { data, index }]);
  };

  const rightContainerElements = isExpanded ? (
    <div className={classes.flex}>
      {tableData?.data && <CopyDataButton tableData={tableData.data} />}
      {rightContainer}
    </div>
  ) : undefined;

  return (
    <Grid container className={classes.root}>
      <AIGuideChatBox
        isCollapsed={!isExpanded}
        isExpandDisabled={false}
        onToggle={() => setIsExpanded((expanded) => !expanded)}
        rightContainer={rightContainerElements}
        isAddedToRecipe={!!transformId}
        title={title}
        width={isExpanded ? "calc(100% - 160px)" : "calc(50% - 24px)"}
        icon={
          <Grid
            container
            item
            direction="column"
            alignItems="center"
            justifyContent="center"
            wrap="nowrap"
            data-testid="ai-output-dataset-icon"
            className={classes.icon}>
            <EntityIconV3 />
          </Grid>
        }>
        {shouldShowCode && code && (
          <AskAICodeEditor
            editorValue={code}
            errDetails={error ? ({ ...error, lineOfCode: (error as any)?.lineInCode } as any) : {}}
          />
        )}
        <Grid
          container
          direction="column"
          wrap="nowrap"
          style={{ display: shouldShowCode && code ? "none" : "block" }}>
          {datasetList?.length === 1 ? (
            <Box className={classes.datasetWrap}>
              <AIGuideDataset
                outputEntity={outputEntityList?.[0]}
                outputEntityResponse={outputEntityResponseList?.[0]}
                onSuccess={(data: Record<string, any>[]) => handleSuccess(data, 0)}
              />
            </Box>
          ) : (
            <AIGuideOutputWrap
              tabNames={_.map(datasetList || [], (entity: any) => ({ name: entity.name }))}
              value={value}
              handleChange={handleChange}
              isOutputDataset
              selectedDataset={get(datasetList, value)}>
              {datasetList?.map((entity, index) => {
                return (
                  <Box style={{ display: index === value ? "block" : "none" }} key={entity.name}>
                    <Grid className={classes.dataset}>
                      <AIGuideDataset
                        outputEntity={entity}
                        outputEntityResponse={entity}
                        onSuccess={(data: any) => handleSuccess(data, index)}
                      />
                    </Grid>
                  </Box>
                );
              })}
            </AIGuideOutputWrap>
          )}
          <SampleRows sampleRows={sampleRows} />
        </Grid>
      </AIGuideChatBox>
    </Grid>
  );
};

export default React.memo(AIGuideDatasetResponse);
