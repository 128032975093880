import React, { useCallback, useMemo } from "react";

// Packages
import { isEmpty } from "lodash";

// MUI
import Typography from "@material-ui/core/Typography";

// Icons
import { PencilIcon } from "icons/NewUX";
import { Delete } from "src/assets/icons/Delete";
import { LogsNewIconV2 } from "src/assets/icons/LogsNewIconV2";
import { ConfigIcon } from "src/assets/icons/ConfigIcon";

// Open API
import { EnvDto, EnvDtoLaunchStatusEnum } from "@rapidcanvas/rc-api-core";

// Utils
import { dateFormat } from "utils/helpers/date.helpers";
import { snakeCaseToStartCase } from "src/utils/helpers";

// Components
import { OverflowTooltip } from "src/components";
import { LinkCellWithMenu } from "src/components/Table/Cells/LinkCellWithMenu/LinkCellWithMenu.component";
import Projects from "../ProjectsField/ProjectsField";
import Status from "./Status";

// Constants
import { EnvironmentsHelperText } from "../../utils/Environments.constants";
import Table from "src/components/custom/TableV8/Table";
import { CellContext } from "@tanstack/react-table";

interface IProps {
  environments: EnvDto[];
  searchValue: string;
  onEdit: (envId?: string) => void;
  onConfig: (env: EnvDto) => void;
  onLogs: (env: EnvDto) => void;
  onDelete: (env: EnvDto) => void;
}

const EnvironmentsTable: React.FC<IProps> = (props) => {
  const { environments, searchValue, onEdit, onConfig, onLogs, onDelete } = props || {};

  const disabledDeleteActionMessage = useCallback((environment?: EnvDto) => {
    if (environment?.launchStatus === EnvDtoLaunchStatusEnum.Launching) {
      return "Cannot delete a launching environment";
    }

    return "";
  }, []);

  const columns = useMemo(
    () => [
      {
        header: "Name",
        id: "Name",
        accessorKey: "name",
        meta: { isTooltip: false },

        cell: ({ row, getValue }: CellContext<any, any>) => (
          <LinkCellWithMenu
            linkText={
              <OverflowTooltip
                style={{ whiteSpace: "nowrap" }}
                value={getValue()}
                data-testid="envListNameAction"
              />
            }
            onClick={() => onEdit(row?.original?.id)}
            menuButtons={[
              {
                icon: (
                  <Typography color="textSecondary" data-testid="envListEditIcon">
                    <PencilIcon />
                  </Typography>
                ),
                label: EnvironmentsHelperText.Edit,
                action: () => onEdit(row?.original?.id)
              },
              {
                icon: (
                  <Typography color="textSecondary" data-testid="envListConfigIcon">
                    <ConfigIcon />
                  </Typography>
                ),
                label: EnvironmentsHelperText.Config,
                action: () => onConfig(row?.original)
              },
              {
                icon: (
                  <Typography color="textSecondary" data-testid="envListLogsIcon">
                    <LogsNewIconV2 width="24" />
                  </Typography>
                ),
                label: EnvironmentsHelperText.Logs,
                action: () => onLogs(row?.original)
              },
              {
                icon: (
                  <Typography color="textSecondary" data-testid="envListDeleteIcon">
                    <Delete />
                  </Typography>
                ),
                label: EnvironmentsHelperText.Delete,
                action: () => onDelete(row?.original),
                disabled: !!disabledDeleteActionMessage(row?.original),
                tooltip: disabledDeleteActionMessage(row?.original)
              }
            ]}
          />
        )
      },
      {
        header: "Env Type",
        id: "Env Type",
        accessorKey: "envType",
        cell: ({ getValue }: CellContext<any, any>) => (
          <>{snakeCaseToStartCase(getValue() || "", { toUpperCase: ["cpu"] })}</>
        )
      },
      {
        header: "Description",
        id: "Description",
        accessorKey: "description"
      },
      {
        header: "Updated by",
        id: "Updated by",
        accessorKey: "updater"
      },
      {
        header: "Updated on",
        id: "Updated on",
        accessorKey: "updated",
        cell: ({ getValue }: CellContext<any, any>) => (
          <span data-testid="envListDate">{dateFormat(getValue())}</span>
        )
      },
      {
        id: "Used in",
        accessorFn: (props: any) =>
          props.projects
            ? props.projects?.reduce(
                (acc: string, project: any) => (acc.length ? acc.concat(`, ${project}`) : project),
                ""
              )
            : "",
        header: "Used In",
        meta: { isTooltip: false },

        cell: ({ row }: CellContext<any, any>) =>
          isEmpty(row?.original?.projectDtos) ? <></> : <Projects environment={row?.original} />
      },
      {
        header: "Status",
        id: "Status",
        accessorKey: "launchStatus",
        meta: { isTooltip: false },
        cell: ({ row }: CellContext<any, any>) => <Status environment={row?.original} />
      }
    ],
    []
  );

  return (
    <Table
      data={environments}
      columns={columns as any[]}
      stylesProps={{ isTheadSticky: true, maxHeight: "calc(100vh - 339px)" }}
      infoProps={{ hideCount: true, emptyTableMessage: "No records" }}
      filterProps={{ globalFilter: searchValue }}
      sortingProps={{ orderByDefault: "Updated on", unsorted: true }}
    />
  );
};

export default EnvironmentsTable;
