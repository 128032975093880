/* eslint-disable no-unused-vars */
import React from "react";

import { Grid } from "@material-ui/core";
import { DataAppTile } from "./DataAppTile";
import { type DataAppType } from "../../DataApps.type";
import { DataAppsConfig } from "../../utils/DataApps.constants";

type Props = {
  isProjectDataApps: boolean;
  dataApps: DataAppType[];
  onConfigOpen: (dataApp: DataAppType) => void;
  onEdit: (dataApp: DataAppType) => void;
  onDelete: (dataApp: DataAppType) => void;
  onLogsOpen: (dataApp: DataAppType) => void;
  onDownloadCode: (dataApp: DataAppType) => void;
  onOpenInNewTab: (dataApp: DataAppType) => void;
  onEditCode: (dataApp: DataAppType) => void;
};

export const DataAppsTiles = ({
  dataApps,
  onDelete,
  onConfigOpen,
  onEdit,
  onEditCode,
  onLogsOpen,
  onDownloadCode,
  onOpenInNewTab
}: Props) => {
  const availableWindowSize = window.innerWidth * (1 - DataAppsConfig.ExemptingWidth);
  return (
    <>
      <Grid container style={{ gap: 24, width: availableWindowSize }}>
        {dataApps.map((dataApp) => {
          return (
            <Grid
              key={dataApp.id}
              item
              xs
              style={{
                flex: `0 0 ${DataAppsConfig.CardWidth}px`,
                width: DataAppsConfig.CardWidth,
                height: DataAppsConfig.CardHeight
              }}>
              <DataAppTile
                data={dataApp}
                onEdit={onEdit}
                onDelete={onDelete}
                onConfigOpen={onConfigOpen}
                onLogsOpen={onLogsOpen}
                onDownloadCode={onDownloadCode}
                onOpenInNewTab={onOpenInNewTab}
                onEditCode={onEditCode}
              />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};
