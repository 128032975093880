import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import { UpdateDataAppRequestDto } from "@rapidcanvas/rc-api-core";

import api from "services/AxiosClient/AxiosClient";
import { updateDataAppsAcrossTenantsQueryData, updateDataAppsQueryData } from "src/utils/helpers";
import { QUERY_KEY_DATAAPP_BY_ID } from "./useDataAppById";
import { relaunchEnv } from "./useLaunchDataAppMutation";

interface IVariables {
  payload: UpdateDataAppRequestDto;
  id: string;
}

export const useUpdateDataAppMutation = (): UseMutationResult<
  any,
  unknown,
  IVariables,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ id, payload }) => {
      const response = await api.fetchResponse(
        async () => await api.DataAppControllerApi.updateDataApp(id, payload)
      );

      if (response.askAIConfig?.customEnvId) {
        relaunchEnv(response.askAIConfig?.customEnvId);
      }

      updateDataAppsQueryData({ queryClient, data: response });
      updateDataAppsAcrossTenantsQueryData({ queryClient, data: response });
      queryClient.setQueryData([QUERY_KEY_DATAAPP_BY_ID, id], response);

      return response;
    }
  });
};
