import React from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";

import DataAppTableStatus from "./DataAppTableStatus";
import { DataAppDtoDataAppTypeEnum } from "@rapidcanvas/rc-api-core";
import { DataAppTableLinkCell } from "./DataAppTableLinkCell";
import { askAITypesInfoList } from "../../utils/DataApps.constants";
import { dateFormat } from "utils/helpers/date.helpers";
import { type DataAppType } from "../../DataApps.type";
import Table from "src/components/custom/TableV8/Table";
import { CellContext, ColumnDef } from "@tanstack/react-table";
import { TData } from "src/components/custom/TableV8/typing";
import { useGetRole } from "hooks/useGetRole";

const useStyles = makeStyles({
  link: {
    color: "#003656",
    "&:hover": {
      color: "#003656",
      textDecoration: "underline"
    }
  }
});

type Props = {
  isProjectDataApps: boolean;
  dataApps: DataAppType[];
  onDelete: (data: DataAppType) => void;
  onConfigOpen: (dataApp: DataAppType) => void;
  onEdit: (dataApp: DataAppType) => void;
  onLogsOpen: (dataApp: DataAppType) => void;
  onDownloadCode: (dataApp: DataAppType) => void;
  onOpenInNewTab: (dataApp: DataAppType) => void;
  onEditCode: (appInfo: DataAppType) => void;
};

export const DataAppsTable = ({
  isProjectDataApps,
  dataApps,
  onDelete,
  onEdit,
  onConfigOpen,
  onLogsOpen,
  onDownloadCode,
  onOpenInNewTab,
  onEditCode
}: Props) => {
  const { link } = useStyles();
  const { isConsumer, isRoleYieldsDataAppView, isDataAppPowerUser } = useGetRole();

  const columns = React.useMemo(
    () => [
      {
        id: "DataApp Name",
        accessorFn: (row: DataAppType) => row?.displayName || row?.name,
        header: "DataApp Name",
        cell: ({ row }: CellContext<DataAppType, unknown>) => {
          return (
            <DataAppTableLinkCell
              row={row}
              isRoleYieldsDataAppView={isRoleYieldsDataAppView}
              onEdit={onEdit}
              onDelete={onDelete}
              onConfigOpen={onConfigOpen}
              onLogsOpen={onLogsOpen}
              onDownloadCode={onDownloadCode}
              onOpenInNewTab={onOpenInNewTab}
              onEditCode={onEditCode}
            />
          );
        }
      },
      {
        id: "Project",
        accessorKey: "projectName",
        header: "Project",
        cell: ({ row }: CellContext<any, unknown>) =>
          isRoleYieldsDataAppView || isConsumer || isDataAppPowerUser ? (
            row.original.projectName || row.original.projectId
          ) : (
            <Link className={link} to={`/projects/${row.original.projectId}`}>
              {row.original.projectName || row.original.projectId}
            </Link>
          )
      },
      {
        id: "Input Type",
        accessorFn: (row: DataAppType) => {
          if (row?.dataAppType === DataAppDtoDataAppTypeEnum.RapidModel) {
            return row?.dataAppType;
          }

          return (
            _.find(askAITypesInfoList, { value: row?.askAIConfig?.inputType })?.label ??
            row?.askAIConfig?.inputType
          );
        },
        header: "Input Type",
        cell: ({ row }: CellContext<DataAppType, unknown>) => {
          const askAIConfig = row.original.askAIConfig;
          const dataAppType = row.original.dataAppType;

          switch (dataAppType) {
            case DataAppDtoDataAppTypeEnum.RapidModel: {
              return "Rapid Model";
            }

            case DataAppDtoDataAppTypeEnum.Askai: {
              return _.find(askAITypesInfoList, { value: askAIConfig?.inputType })?.label ?? "-";
            }

            default: {
              return "-";
            }
          }
        }
      },
      {
        id: "Last Shutdown by",
        accessorKey: "stoppedBy",
        header: "Last Shutdown by"
      },
      {
        id: "Updated by",
        accessorKey: "updater",
        header: "Updated by"
      },
      {
        id: "Updated on",
        accessorKey: "updatedOn",
        header: "Updated on",
        cell: ({ getValue }: $TSFixMe) => <span>{dateFormat(getValue())}</span>
      },
      {
        id: "Status",
        accessorKey: "launchStatus",
        header: "Status",

        cell: ({ row }: CellContext<DataAppType, any>) => (
          <DataAppTableStatus id={row?.original?.id} launchStatus={row?.original?.launchStatus} />
        )
      }
    ],
    [onDelete, isRoleYieldsDataAppView, isConsumer, isDataAppPowerUser]
  );

  return (
    <Table
      data={dataApps}
      columns={columns as ColumnDef<TData, any>[]}
      infoProps={{ hideCount: true }}
      sortingProps={{ orderByDefault: "Updated on" }}
      stylesProps={{
        size: "small",
        maxHeight: isProjectDataApps ? "calc(100vh - 136px)" : "calc(100vh - 339px)",
        isTheadSticky: true
      }}
    />
  );
};
