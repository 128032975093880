import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import {
  isEmpty,
  forEach,
  includes,
  toLower,
  some,
  filter,
  map,
  lowerCase,
  isNil,
  uniqBy,
  keyBy,
  get
} from "lodash";
import { generatePath, useNavigate, useParams } from "react-router-dom";

import { ProjectRunDto } from "@rapidcanvas/rc-api-core";
import { WebPaths } from "routing/routes";

import CommonLoader from "src/components/CommonLoader";
import JobGlobalVariables from "../Jobs/components/JobGlobalVariables/JobGlobalVariables";
import ModelFilter, { SelectedModel } from "./components/ModelFilter";
import NewThemeWrapper from "src/styles/NewThemeWrapper";
import NoDataFoundDefault from "src/pages/common/NoDataFoundDefault";
import PredictionButton from "./components/CreatePredictionJobButton";
import PredictionJobLogsDrawer from "./components/PredictionJobLogs/PredictionJobLogsDrawer";
import PredictionRunsTable from "./components/PredictionRunsTable";
import useGetAllModels from "hooks/api/projects/useGetAllModels";
import useGetAllPredictionRuns, { IProjectRun } from "hooks/api/projects/useGetAllProjectRuns";
import { IProjectReturn } from "hooks/api/projects/useRunDetails";
import { getDocsUrl } from "utils/helpers";
import {
  JobRunStatuses,
  ManualPredictionGettingStarted,
  PredictionSchedulerGettingStarted
} from "../Jobs/utils/Jobs.constants";
import { PredictionJobConstants } from "./utils/PredictionJob.constants";
import { Search } from "src/components";
import { getComputedVariables } from "../Jobs/utils/Jobs.helpers";
import GettingStarted from "components/custom/GetttingStarted/GettingStarted";
import ManualPrediction from "icons/NewUX/GettingStartedIllustrations/ManualPrediction";
import { ManualJobRun } from "../Jobs/components";

const useStyles = makeStyles(() => ({
  flex: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center"
  }
}));

interface IProps {
  isManual: boolean;
}

const PredictionsRuns: React.FC<IProps> = (props) => {
  const { isManual } = props;

  const navigate = useNavigate();

  const classes = useStyles();
  const { projectId } = useParams();
  const [logsRunDetails, setLogsRunDetails] = useState<{
    runId: string;
    jobName: string;
    runEntryId: string;
    recipeId?: string;
    recipeName?: string;
    errorMsg: string;
  } | null>(null);
  const [isJobRunning, setIsJobRunning] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [selectedModels, setSelectedModels] = useState<SelectedModel[]>([]);
  const [jobRunParametersData, setJobRunParametersData] = useState<Record<string, any>[] | null>(
    null
  );

  const [currentJob, setCurrentJob] = useState<ProjectRunDto | IProjectReturn | null>(null);
  const [showManualJobRunModal, setShowManualJobRunModal] = useState(false);

  const modelResponse = useGetAllModels(projectId);
  const { data, isLoading } = useGetAllPredictionRuns(projectId, isManual);

  const modelData = useMemo(() => {
    return map(
      modelResponse.data,
      ({
        id,
        name,
        displayName,
        recipeVariables,
        parentRecipeName,
        parentRecipeId,
        parentRecipeDisplayName
      }) => ({
        id,
        name,
        displayName,
        parentRecipeName,
        parentRecipeDisplayName,
        parentRecipeId,
        targetCol: recipeVariables?.targetCol
      })
    );
  }, [modelResponse.data]);

  const allModels = useMemo(() => {
    const ids: SelectedModel[] = [];
    const keyByModels = keyBy(modelData, "id");

    forEach(data, ({ dto }) => {
      if (dto?.modelEntityId && !map(ids, "id").includes(dto?.modelEntityId)) {
        ids.push({
          id: dto?.modelEntityId,
          name:
            get(keyByModels, [dto?.modelEntityId, "displayName"]) ??
            dto?.modelEntityName ??
            get(keyByModels, [dto?.modelEntityId, "name"]) ??
            ""
        });
      }
    });
    return ids;
  }, [data]);

  useEffect(() => {
    if (isEmpty(selectedModels) && allModels.length > 0) {
      setSelectedModels(uniqBy(allModels, "id"));
    }
  }, [allModels]);

  const filteredByModel = useMemo(() => {
    return filter(
      data,
      (record) =>
        !!record.dto?.modelEntityId && includes(map(selectedModels, "id"), record.dto.modelEntityId)
    );
  }, [data, selectedModels]);

  const filteredBySearch = useMemo(() => {
    if (!searchValue) {
      return filteredByModel;
    }

    return filter(filteredByModel, (record) =>
      includes(lowerCase(record.dto?.name), lowerCase(searchValue))
    );
  }, [searchValue, filteredByModel]);

  const name = useMemo(() => {
    let count = 1;
    const title = isManual
      ? PredictionJobConstants.ManualName
      : PredictionJobConstants.UntitledName;

    forEach(data, ({ dto }) => {
      if (includes(toLower(dto?.name), toLower(title)) || dto?.name === `${title} ${count + 1}`) {
        count += 1;
      }
    });

    while (some(data, ({ dto }) => dto?.name === `${title} ${count}`)) {
      count += 1;
    }

    return `${title} ${count}`;
  }, [data]);

  const handleLogsOpen = ({
    runId,
    runEntryId,
    recipeId,
    recipeName,
    errorMsg,
    jobName,
    status
  }: {
    runId: string;
    runEntryId: string;
    recipeId: string;
    recipeName?: string;
    errorMsg: string;
    jobName: string;
    status: string;
  }) => {
    setLogsRunDetails({ runId, runEntryId, recipeId, recipeName, errorMsg, jobName });
    setIsJobRunning(
      includes(
        [
          JobRunStatuses.Created,
          JobRunStatuses.EntityLoading,
          JobRunStatuses.Running,
          JobRunStatuses.Started
        ],
        status
      )
    );
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleJobRunParameters = (lastRunEntry: Record<string, any>) => {
    setJobRunParametersData(
      () =>
        Object.entries(
          getComputedVariables(lastRunEntry?.computedVariables, lastRunEntry?.variables)
        )?.map(([key, value]) => ({
          ["key"]: key,
          ["value"]: value
        })) || []
    );
  };

  // Manual job run - STARTS >>
  const resetManualJobRun = () => {
    setCurrentJob(() => null);
    setShowManualJobRunModal(() => false);
  };

  const promptManualJobRun = useCallback((job?: ProjectRunDto | IProjectReturn) => {
    if (isEmpty(job)) {
      resetManualJobRun();
      return;
    }

    setCurrentJob(() => job || null);
    setShowManualJobRunModal(() => true);
  }, []);
  // << ENDS - Manual job run

  const navigateToJobRunsPage = () => {
    if (!projectId || !currentJob?.id) return;

    navigate(
      generatePath(`${WebPaths.JobRoutes}${WebPaths.JobRuns}`, {
        projectId,
        jobId: currentJob?.id
      })
    );
  };

  return (
    <NewThemeWrapper>
      {isLoading || modelResponse.isLoading ? (
        <CommonLoader />
      ) : (
        <Grid item xs={12}>
          {isEmpty(data) ? (
            isManual ? (
              <GettingStarted
                boxes={[
                  {
                    icon: <ManualPrediction />,
                    message: ManualPredictionGettingStarted.topMessage,
                    action: (
                      <PredictionButton
                        projectId={projectId!}
                        models={modelData}
                        name={name}
                        isManual={isManual}
                      />
                    )
                  }
                ]}
                functionality={{
                  question: ManualPredictionGettingStarted.functionality.question,
                  answers: ManualPredictionGettingStarted.functionality.answers,
                  docsLink: `${getDocsUrl()}/basic/projects/prediction-job/manual-prediction`
                }}
              />
            ) : (
              <GettingStarted
                boxes={[
                  {
                    icon: <ManualPrediction />,
                    message: PredictionSchedulerGettingStarted.topMessage,
                    action: (
                      <PredictionButton
                        projectId={projectId!}
                        models={modelData}
                        name={name}
                        isManual={isManual}
                      />
                    )
                  }
                ]}
                functionality={{
                  question: PredictionSchedulerGettingStarted.functionality.question,
                  answers: PredictionSchedulerGettingStarted.functionality.answers,
                  docsLink: `${getDocsUrl()}/basic/projects/prediction-job/prediction-scheduler`
                }}
              />
            )
          ) : (
            <>
              <div className={classes.flex}>
                <Search
                  value={searchValue}
                  onSearch={handleChange}
                  placeholder="Search Prediction"
                />
                <ModelFilter
                  selectedModels={selectedModels}
                  models={allModels}
                  onSelect={setSelectedModels}
                />
                <PredictionButton
                  showIcon
                  projectId={projectId!}
                  models={modelData}
                  name={name}
                  isManual={isManual}
                />
              </div>
              {isEmpty(filteredBySearch) && searchValue ? (
                <NoDataFoundDefault
                  title={`No prediction found with keyword "${searchValue}"`}
                  minHeight="314.5px"
                  onClear={() => setSearchValue("")}
                />
              ) : isEmpty(filteredByModel) ? (
                <NoDataFoundDefault
                  title="No prediction found with matching filter criteria"
                  subTitle={""}
                  minHeight="314.5px"
                />
              ) : (
                <PredictionRunsTable
                  data={filteredBySearch as IProjectRun[]}
                  projectId={projectId!}
                  models={modelData}
                  isManual={isManual}
                  onLogsOpen={handleLogsOpen}
                  onJobRunParametersViewOpen={handleJobRunParameters}
                  onRunJob={promptManualJobRun}
                />
              )}
            </>
          )}
        </Grid>
      )}
      {!isNil(jobRunParametersData) && (
        <JobGlobalVariables
          isPredictionScheduler
          close={() => setJobRunParametersData(null)}
          jobParametersData={jobRunParametersData}
        />
      )}
      {logsRunDetails && (
        <PredictionJobLogsDrawer
          isJobRunning={isJobRunning}
          open={!!logsRunDetails}
          projectId={projectId!}
          logRunDetails={logsRunDetails}
          onClose={() => setLogsRunDetails(null)}
        />
      )}

      {!!showManualJobRunModal && !isEmpty(currentJob) && (
        <ManualJobRun
          close={resetManualJobRun}
          projectId={projectId}
          jobId={currentJob?.id}
          isPredictionScheduler={true}
          jobParametersData={getComputedVariables(
            currentJob?.computedVariables,
            currentJob?.variables
          )}
          navigateToJobRunsPage={navigateToJobRunsPage}
        />
      )}
    </NewThemeWrapper>
  );
};

export default PredictionsRuns;
