import React, { useMemo, useState } from "react";

// Packages
import clsx from "clsx";
import shallow from "zustand/shallow";
import { get, has, includes, isEmpty, isFunction, size } from "lodash";

// MUI
import { Typography } from "@material-ui/core";

// Icons
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";

// Utils
import { addEllipsis, handleClickClosure } from "utils/helpers";
import { ArtifactStatuses, NodeArtifactTheme } from "src/pages/private/ProjectsModule/utils";

// Stores
import { useCanvasStore } from "stores/zustand/stores";

// Hooks
import { useNodeMultiSelection } from "projectsModule/hooks";
import { useCtrlKeyPress } from "src/hooks/useCtrlKeyPress";
import useActions from "./useActions";
import useContextMenu from "../useContextMenu";

// Components
import NodesWrapper from "../NodesWrapper";
import ContextMenuWrapper from "../ContextMenuWrapper";
import ContextMenu from "./ContextMenu";
import NodeActionWrapper from "../NodeActionWrapper";
import ExpandCollapseIconButton from "../ExpandCollapseIconButton";
import NodeCaptionOverflowTooltip from "../NodeCaptionOverflowTooltip";
import DeselectNodesConfirmModal from "../../DeleteNodes/DeselectNodesConfirmModal";

// Contexts
import { useDagFlowContext } from "../../DagFlow/context/useDagFlowContext";

// Types
import { NodeData } from "src/types";

// Styles
import useStyles from "./Artifact.styles";
import useNodeStyle from "../../../hooks/useNodeStyle";
import { useAccessControlContext } from "src/routing/PrivateRoute/accessControlContext/useAccessControlContext";

type Props = {
  data: NodeData;
};

const Artifact = (props: Props) => {
  const { data } = props || {};
  const { canAddStandardRecipe } = useAccessControlContext();

  const { classes, statusKey } = useNodeStyle({
    classes: useStyles({ status: data?.status }),
    status: data?.status
  });

  // Contexts
  const { scenario, isNodeHighlighted, nodesExpanded } = useDagFlowContext();

  // Stores - STARTS >>
  const [nodeToFocusStore, shouldBlockClickHandlerTriggerStore] = useCanvasStore(
    (state) => [state.nodeToFocus, state.shouldBlockClickHandlerTrigger],
    shallow
  );
  // << ENDS - Stores

  // States - STARTS >>
  const [isHovered, setIsHovered] = useState(false);
  const [showDeselectNodesConfirmModal, setShowDeselectNodesConfirmModal] = useState<
    (() => void) | null
  >(null);
  // << ENDS - States

  const disabledMessage = useMemo(() => {
    if (
      !!data?.isJobCanvas &&
      includes(
        [ArtifactStatuses.UnBuilt, ArtifactStatuses.Empty, ArtifactStatuses.Skipped],
        data?.status
      )
    ) {
      return `Certain click actions are restricted as supplementary details for the specified artifact ${data?.label} are not accessible in the context of this job run entry.`;
    }

    return "";
  }, [data?.isJobCanvas, data?.status, data?.label]);

  // Keeping hooks at the bottom so as to pass the above defined props to it.
  // Hooks - STARTS >>
  const { isCtrlPressed } = useCtrlKeyPress();

  const { onClick, onDoubleClick } = useActions({
    data,
    disabledMessage,
    canAddStandardRecipe
  });

  const {
    contextMenuAnchorEl,
    // Mouse-leave action is not fully supported. Hence, deferring mouse-enter action.
    // actionsOnMouseEnter,
    closeContextMenu,
    contextMenuProp
  } = useContextMenu({
    data,
    disabledMessage
  });

  const { onMultiSelection, selectedNodes, isNodeSelected, resetSelectedNodes } =
    useNodeMultiSelection({ data });
  // << ENDS - Hooks

  // Mouse-click events - STARTS >>
  const handleSingleClick = () => {
    if (isEmpty(selectedNodes)) {
      onClick();
      return;
    }

    setShowDeselectNodesConfirmModal(() => onClick);
  };

  const handleDoubleClick = () => {
    if (isEmpty(selectedNodes)) {
      onDoubleClick();
      return;
    }

    setShowDeselectNodesConfirmModal(() => onDoubleClick);
  };
  // << ENDS - Mouse-click events

  // Confirm deselect nodes - STARTS >>
  const resetConfirmDeselectNodes = () => {
    setShowDeselectNodesConfirmModal(() => null);
  };

  const onConfirmDeselectNodes = () => {
    resetSelectedNodes();
    resetConfirmDeselectNodes();
    showDeselectNodesConfirmModal?.();
  };
  // << ENDS - Confirm deselect nodes

  const showContextMenu = useMemo(() => {
    if (!isEmpty(selectedNodes)) {
      if (!isNodeSelected()) {
        return false;
      }
    }

    return true;
  }, [selectedNodes]);

  return (
    <>
      {isFunction(showDeselectNodesConfirmModal) && (
        <DeselectNodesConfirmModal
          selectedNodes={selectedNodes}
          onDeselectNodes={onConfirmDeselectNodes}
          resetConfirmDeselectNodes={resetConfirmDeselectNodes}
        />
      )}

      {!!showContextMenu && (
        <ContextMenuWrapper
          id={`artifactContextMenu-${data?.id}`}
          contextMenuAnchorEl={contextMenuAnchorEl}
          closeContextMenu={closeContextMenu}
          keepMounted>
          <ContextMenu
            closeContextMenu={closeContextMenu}
            isDefaultScenario={!!scenario?.default}
            data={data}
            selectedNodes={selectedNodes}
            resetSelectedNodes={resetSelectedNodes}>
            {!!data?.id && !!data?.isSourceNode && size(selectedNodes) <= 1 && (
              <NodeActionWrapper title={nodesExpanded[data?.id] ?? true ? "Collapse" : "Expand"}>
                <ExpandCollapseIconButton nodeId={data?.id} />
              </NodeActionWrapper>
            )}
          </ContextMenu>
        </ContextMenuWrapper>
      )}

      <NodesWrapper
        nodeId={data?.id}
        disabledMessage={disabledMessage}
        isSourceNode={data?.isSourceNode}
        isTargetNode={data?.isTargetNode}
        backgroundColor={
          has(NodeArtifactTheme, data?.status)
            ? get(NodeArtifactTheme, [data?.status, "backgroundColor"])
            : NodeArtifactTheme.backgroundColor
        }
        isHovered={isHovered}
        setIsHovered={setIsHovered}>
        <div
          role="button"
          data-testid="canvasNode"
          tabIndex={0}
          onMouseEnter={() => setIsHovered(() => true)}
          onMouseLeave={() => setIsHovered(() => false)}
          onClick={handleClickClosure({
            shouldBlockClickHandlerTrigger:
              shouldBlockClickHandlerTriggerStore || data?.shouldDisableBlockInteraction,
            isCtrlPressed,
            handleSingleClick,
            handleDoubleClick,
            handleMultiSelection: onMultiSelection,
            shouldClearEntities: false
          })}
          onKeyPress={handleClickClosure({
            shouldBlockClickHandlerTrigger:
              shouldBlockClickHandlerTriggerStore || data?.shouldDisableBlockInteraction,
            isCtrlPressed,
            handleSingleClick,
            handleDoubleClick,
            handleMultiSelection: onMultiSelection,
            shouldClearEntities: false
          })}
          {...contextMenuProp}
          className={clsx(classes.root, classes[statusKey], {
            highlighted:
              isNodeHighlighted(data?.label, nodeToFocusStore) ||
              (!!showContextMenu && Boolean(contextMenuAnchorEl)),
            active: isNodeSelected()
          })}>
          <div className={classes.container}>
            <div className={classes.icon}>
              <AccountBalanceIcon />
            </div>
            {data?.label && (
              <NodeCaptionOverflowTooltip label={data?.label}>
                <Typography
                  variant="caption"
                  className={clsx(classes.label, {
                    active: isNodeSelected()
                  })}>
                  {addEllipsis(data?.label)}
                </Typography>
              </NodeCaptionOverflowTooltip>
            )}
          </div>
        </div>
      </NodesWrapper>
    </>
  );
};

export default Artifact;
