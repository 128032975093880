import React from "react";

export const ClearHistoryIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.0601 15.6491C14.1823 15.7713 14.342 15.8322 14.502 15.8322C14.662 15.8322 14.8216 15.7713 14.9438 15.6491L17.1588 13.4341C17.3507 13.2422 17.397 12.9484 17.2729 12.7069L15.7154 9.67032L18.572 6.81375C19.1076 6.27813 19.4026 5.56625 19.4026 4.80875C19.4026 4.05125 19.1076 3.33938 18.572 2.80407C17.4666 1.69875 15.6679 1.69875 14.5626 2.80407L11.706 5.66094L8.66945 4.10344C8.42789 3.97938 8.13383 4.02532 7.94227 4.2175L5.72727 6.4325C5.4832 6.67657 5.4832 7.07219 5.72727 7.31625L6.27102 7.86L4.25227 9.87875C3.53914 10.5922 2.3782 10.5919 1.66445 9.87875C1.42039 9.63469 1.02477 9.63469 0.780703 9.87875C0.536641 10.1228 0.536641 10.5184 0.780703 10.7625L7.14195 17.1238C7.74258 17.7241 8.53102 18.0244 9.31977 18.0244C10.1085 18.0244 10.8973 17.7241 11.4976 17.1238L13.5163 15.105L14.0601 15.6491ZM8.50414 5.42313L11.5407 6.98063C11.7823 7.10469 12.0766 7.05813 12.2679 6.86657L15.4463 3.68782C16.0648 3.07 17.0698 3.07 17.6882 3.68782C17.9876 3.98719 18.1526 4.38532 18.1526 4.80875C18.1526 5.23219 17.9876 5.63032 17.6882 5.93L14.5098 9.10844C14.3179 9.30032 14.2716 9.59407 14.3957 9.83563L15.9532 12.8722L14.502 14.3234L13.9582 13.7797L7.59695 7.41844L7.0532 6.87469L8.50445 5.42344L8.50414 5.42313ZM10.6138 16.2403C9.9007 16.9538 8.73977 16.9541 8.02602 16.2403L7.49695 15.7113L8.58383 14.6244C8.82789 14.3803 8.82789 13.9847 8.58383 13.7406C8.33977 13.4966 7.94414 13.4966 7.70008 13.7406L6.6132 14.8275L3.41539 11.6297C4.0457 11.5359 4.65227 11.2469 5.13633 10.7631L7.15508 8.74438L12.6326 14.2219L10.6138 16.2403Z"
        fill="#515151"
      />
      <path
        d="M18.7502 16.7763H12.9133C12.5683 16.7763 12.2883 17.0563 12.2883 17.4013C12.2883 17.7463 12.5683 18.0263 12.9133 18.0263H18.7502C19.0952 18.0263 19.3752 17.7463 19.3752 17.4013C19.3752 17.0563 19.0952 16.7763 18.7502 16.7763ZM1.89453 7.54407H2.34766V7.99719C2.34766 8.34219 2.62766 8.62219 2.97266 8.62219C3.31766 8.62219 3.59766 8.34219 3.59766 7.99719V7.54407H4.05078C4.39578 7.54407 4.67578 7.26407 4.67578 6.91907C4.67578 6.57407 4.39578 6.29407 4.05078 6.29407H3.59766V5.84094C3.59766 5.49594 3.31766 5.21594 2.97266 5.21594C2.62766 5.21594 2.34766 5.49594 2.34766 5.84094V6.29407H1.89453C1.54953 6.29407 1.26953 6.57407 1.26953 6.91907C1.26953 7.26407 1.54953 7.54407 1.89453 7.54407ZM10.0002 3.11063H10.6152V3.72563C10.6152 4.07063 10.8952 4.35063 11.2402 4.35063C11.5852 4.35063 11.8652 4.07063 11.8652 3.72563V3.11063H12.4805C12.8255 3.11063 13.1055 2.83063 13.1055 2.48563C13.1055 2.14063 12.8255 1.86063 12.4805 1.86063H11.8652V1.24532C11.8652 0.900316 11.5852 0.620316 11.2402 0.620316C10.8952 0.620316 10.6152 0.900316 10.6152 1.24532V1.86063H10.0002C9.65516 1.86063 9.37516 2.14063 9.37516 2.48563C9.37516 2.83063 9.65516 3.11063 10.0002 3.11063Z"
        fill="#515151"
      />
    </svg>
  );
};
