import React, { useMemo } from "react";
import { Grid } from "@material-ui/core";
import { includes, isEmpty, map } from "lodash";

import CopyDownloadIcon from "icons/NewUX/CopyDownloadIcon";
import ProjectInfoCard from "../../components/InfoCards/ProjectInfoCard";
import { ImageIcon } from "icons/NewUX/ImageIcon";
import { SettingsIcon } from "icons/NewUX/SettingsIcon";
import { TrashIcon } from "icons/NewUX/TrashIcon";
import { defaultImages } from "src/pages/common/defaultImages";
import { useProjectsStore } from "stores/zustand/stores";
import CommonLoader from "src/components/CommonLoader";

interface IProps {
  list?: any[];
  isLoading: boolean;
  onEdit: (id: string) => void;
  onImageOpen: (id: string) => void;
  onCopyOpen: (id: string) => void;
  onDelete: (id: string) => void;
}

export const ProjectsCards = ({
  list,
  isLoading,
  onImageOpen,
  onCopyOpen,
  onEdit,
  onDelete
}: IProps) => {
  const transformedList = useMemo(() => {
    let imageIndex = 0;
    return map(list, (data) => {
      const updatedImage = data.image || defaultImages[imageIndex];
      if (!data.image) {
        imageIndex = (imageIndex + 1) % defaultImages.length; // Cycle through images
      }

      const transformData = {
        ...data,
        id: data.id,
        title: data.name,
        image: updatedImage, // Single key for image
        link: "/projects"
      };
      return transformData;
    });
  }, [list, defaultImages]);

  const [updatingProjectIds] = useProjectsStore((state) => [state.updatingProjectIds]);

  const projectsOptions = ({ id }: { id: string }) => [
    {
      label: "Project Image",
      click: () => {
        onImageOpen(id);
      },
      icon: <ImageIcon />
    },
    {
      label: "Project Settings",
      click: () => onEdit(id),
      icon: <SettingsIcon />
    },
    {
      label: "Copy Project",
      click: () => onCopyOpen(id),
      icon: <CopyDownloadIcon />
    },
    {
      label: "Delete Project",
      click: () => onDelete(id),
      icon: <TrashIcon viewBox="0 0 20 20" />,
      disabled: includes(updatingProjectIds, id),
      tooltip: includes(updatingProjectIds, id)
        ? "Please wait until the project is getting updated"
        : ""
    }
  ];

  const availableWindowSize = window.innerWidth * 0.8;
  return (
    <Grid container style={{ gap: "24px", width: availableWindowSize }} justifyContent="flex-start">
      {isLoading ? (
        <CommonLoader />
      ) : (
        !isEmpty(list) &&
        transformedList.map((data: $TSFixMe) => {
          return (
            <Grid
              key={data.id}
              item
              xs
              style={{
                flex: "0 0 356px",
                maxWidth: "356px",
                height: "460px",
                minHeight: "460px",
                maxHeight: "460px"
              }}>
              <ProjectInfoCard data={data} options={projectsOptions(data)} />
            </Grid>
          );
        })
      )}
    </Grid>
  );
};
