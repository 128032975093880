import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Grid,
  Paper,
  makeStyles,
  Typography,
  Badge as MuiBadge,
  withStyles
} from "@material-ui/core";
import { isEmpty, size, toLower, toUpper } from "lodash";
import { useQueryClient } from "@tanstack/react-query";

import UploadFileList, { IFile } from "./Artifacts/UploadFileList";
import Modal, { ModalVariants } from "src/components/custom/Modal/Modal";
import { Field } from "../../../components";
import { areFileTypesValid } from "utils/helpers";
import { toastWrapper } from "services/ToastClient/toastWrapper";
import { useForm } from "../../../hooks/useForm";
import { validateNameField } from "utils/helpers/form.helpers";
import { handleResponse, postAPIWithRethrow } from "services/Apis/Apis.service";
import { leadingTextInPath } from "utils/helpers/string.helpers";
import { fileMimeTypesValues } from "src/pages/private/ProjectsModule/pages/Dataset/utils/Dataset.constants";
import { QUERY_KEY_ARTIFACTS_PAGINATED } from "src/hooks/api/artifacts/useGetPaginatedArtifacts";
import _ from "lodash";
import { useCanvasStore } from "stores/zustand/stores";
import { createEntityWithRethrow } from "services/Apis/wrappers";
import shallow from "zustand/shallow";

const initialFormValues = {
  description: "",
  name: "",
  files: []
};

const errors = {
  nameExist: "The artifact name already exist"
};

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    gap: 24,
    "& > .MuiGrid-item": {
      paddingTop: 8,
      alignContent: "flex-start"
    }
  },
  paper: {
    width: "100%",
    height: 164,
    display: "flex",
    backgroundColor: "#f5f7f9",
    border: "1px dashed #425e7f",
    boxShadow: "none",
    "& input:hover": {
      cursor: "pointer"
    }
  },
  filesCountContainer: {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    fontSize: "small",
    color: theme.palette.common.black
  },
  uploadComponent: {
    marginBottom: 0,
    marginRight: 8,
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    width: "100%",
    height: "100%"
  },
  error: {
    fontSize: ".8rem",
    color: "red",
    margin: "10px 20px 0px 20px"
  },
  uploadButton: {
    width: "108px",
    backgroundColor: theme.palette.primary.main,
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px rgb(0 0 0 / 14%), 0px 1px 5px rgb(0 0 0 / 12%)",
    borderRadius: "4px",
    fontSize: "12px",
    color: "white",
    margin: "0 auto",
    padding: "4px 12px",
    marginTop: "12px",
    opacity: ({ disabled }: { disabled?: boolean }) => (disabled ? 0.5 : 1)
  }
}));

type Props = {
  open: boolean;
  onClose: () => void;
  projectId?: string;
  artifactList: $TSFixMe[];
  nodes?: any;
  onSuccess?: () => void;
};

export const Badge = withStyles((theme) => ({
  badge: {
    right: -10,
    top: 11,
    background: "#bdbdbd",
    border: `2px solid ${theme.palette.background.paper}`
  },
  root: {
    width: "100px"
  }
}))(MuiBadge);

const CreateArtifact = ({ open, onClose, artifactList, projectId, nodes, onSuccess }: Props) => {
  const { values, handleInputChange, resetForm } = useForm(initialFormValues);
  const [showNameError, setShowNameError] = useState<[boolean, string]>([false, ""]);
  const [showConfirmScreen, setShowConfirmScreen] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isUploadOpen, setIsUploadOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const classes = useStyles({ disabled: isLoading });
  const queryClient = useQueryClient();
  const fileRef = useRef<HTMLInputElement>(null);
  const [setReloadTrigger] = useCanvasStore((state) => [state.setReloadTrigger], shallow);

  const { artifactNodes } = useMemo(() => {
    const groupBy = _.groupBy(nodes, (node) => (node as $TSFixMe)?.type?.toLowerCase());

    return {
      artifactNodes: _.get(groupBy, "artifact", [])
    };
  }, [nodes]);

  const handleFilesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || !areFileTypesValid(e.target.files)) {
      setErrorMessage("Invalid File Type(s). Please upload again");
    } else {
      setErrorMessage(null);
      const newFiles = Object.values(e.target.files).map((file, index) => ({
        name: file.name,
        file,
        size: file.size,
        fileType: file.type,
        id: `${file.name}.${file.size}${index}`
      }));
      handleInputChange({
        target: { value: [...((values as $TSFixMe).files || []), ...newFiles], name: "files" }
      });
      if (isUploadOpen) {
        setIsLoading(true);
      }
      e.target.value = "";
    }

    // Below code resets the input type file. It's needed to accept every files selection. Without the below code, the subsequent selections are omitted.
    if (fileRef?.current) {
      fileRef.current.value = "";
    }
  };

  const cleanErrorMessage = () => {
    if (errorMessage) {
      setErrorMessage(null);
    }
  };

  const validFields = () => {
    let isValid = true;
    const { isValid: isNameValid, error } = validateNameField({
      fieldName: (values as $TSFixMe)?.name,
      fieldNameLabel: `artifact name`,
      maxFieldLength: 64
    });
    if (!isNameValid && error) {
      setShowNameError([true, error]);
      isValid = false;
    } else if (nameExist()) {
      setShowNameError([true, errors.nameExist]);
      isValid = false;
    } else {
      setShowNameError([false, ""]);
    }

    return isValid;
  };

  useEffect(() => {
    showNameError[0] && setShowNameError([false, ""]);
  }, [open]);

  const nameExist = () => {
    return Boolean(
      artifactList?.find(
        (artifact) =>
          (artifact as $TSFixMe).name.toLowerCase() ===
          (values as $TSFixMe).name.toLowerCase().trim()
      )
    );
  };

  const handleOnClose = () => {
    resetForm();
    onClose();
  };

  const invalidate = (projectId?: string) => {
    queryClient.setQueryData([QUERY_KEY_ARTIFACTS_PAGINATED, projectId], (old: any) => {
      return {
        ...old,
        data: [
          {
            name: _.get(values, "name"),
            producer: null,
            fileObjects: _.map(_.get(values, "files"), (file: File) => ({
              path: file.name,
              updated: Date.now() / 1000
            })),
            created: Date.now() / 1000
          },
          ...(old?.data ?? [])
        ],
        total: old?.total + 1
      };
    });
  };

  const createEntities = React.useCallback(
    async (entityNames: Array<string>, entityViewType: string) => {
      const entityBody = {
        entityMeta: {
          entityViewType,
          entityType: "EVENT"
        },
        projectId
      };
      try {
        await Promise.all(
          entityNames.map(async (artifactName: string) => {
            return await createEntityWithRethrow({
              ...entityBody,
              name: artifactName
            });
          })
        );
        setReloadTrigger();
      } catch (error: $TSFixMe) {
        handleResponse({
          errorMessage:
            error.response?.data?.msg ||
            error.message ||
            `Error in adding ${entityViewType === "ARTIFACT" ? "artifacts" : "models"}`
        });
      }
    },
    [projectId, setReloadTrigger]
  );

  const handleSubmit = async (e: $TSFixMe) => {
    e?.preventDefault();
    if (validFields()) {
      setIsLoading(true);
      const name = (values as $TSFixMe)?.name?.trim();
      if ((values as $TSFixMe).files.length === 0) {
        try {
          await postAPIWithRethrow(`/v2/artifacts/empty-folder/${name}`, undefined);
          if (!!projectId) {
            const existingArtifactNames = artifactNodes?.map((artifact: $TSFixMe) => artifact.name);
            const newArtifacts = [name].filter((name) => !existingArtifactNames.includes(name));
            await createEntities(newArtifacts, "ARTIFACT");
          }
          invalidate(projectId);
          onSuccess?.();
          handleOnClose();
          toastWrapper({
            content: `Artifact ${name} created successfully`,
            type: "success"
          });
        } finally {
          setIsLoading(false);
        }
      } else {
        setIsUploadOpen(true);
      }
    }
  };

  const handleChangeFiles = async (finished: boolean) => {
    if (finished) {
      const name = (values as $TSFixMe)?.name?.trim();
      if (!!projectId) {
        const existingArtifactNames = artifactNodes?.map((artifact: $TSFixMe) => artifact.name);
        const newArtifacts = [name].filter((name) => !existingArtifactNames.includes(name));
        await createEntities(newArtifacts, "ARTIFACT");
      }
      invalidate(projectId);
      onClose();
      setIsLoading(false);
      setIsUploadOpen(false);
      setIsDirty(false);

      toastWrapper({
        content: `Artifact ${name} created successfully`,
        type: "success"
      });
    }
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    id?: string
  ) => {
    if (id === "name") {
      const { isValid: isNameValid, error } = validateNameField({
        fieldName: event.target.value,
        fieldNameLabel: `artifact name`,
        maxFieldLength: 64
      });
      if (!isNameValid && error) {
        setShowNameError([true, error]);
      } else {
        setShowNameError([false, ""]);
      }
    }

    handleInputChange(event);
    setIsDirty(true);
  };

  const handleCloseModal = () => {
    setIsDirty(false);
    setShowConfirmScreen(false);
    handleOnClose();
  };

  const handleCloseAttempt = () => {
    if (isDirty) {
      return setShowConfirmScreen(true);
    }
    handleOnClose();
  };

  const handleRemoveFile = (newFiles: IFile[]) => {
    handleInputChange({
      target: {
        value: newFiles,
        name: "files"
      }
    });
  };

  const handleCancelClose = () => {
    setShowConfirmScreen(false);
  };

  const handleError = (error: any, finished: boolean, allCanceled: boolean) => {
    if (finished) {
      if (!allCanceled) {
        onClose();
        setIsUploadOpen(false);
      }
      setIsLoading(false);
      setIsDirty(false);
    }
    console.error("error", error);
  };

  const getFileType = (file: IFile) => {
    let type = fileMimeTypesValues?.[file?.fileType]?.displayName;
    if (!!type) {
      return type;
    }

    type = leadingTextInPath({ text: file?.name });
    if (toLower(type) === "parquet") {
      return "Parquet";
    }

    return toUpper(type);
  };

  return (
    <>
      {showConfirmScreen && isDirty && (
        <Modal
          open={true}
          variant={ModalVariants.Delete}
          title="Confirm"
          content={[
            "Do you really want to close this window?",
            "If you leave you will lose any unsaved changes."
          ]}
          onClose={handleCancelClose}
          onSubmit={handleCloseModal}
          cancelLabel="Cancel"
          submitLabel="Close"
        />
      )}

      <Modal
        open
        size="md"
        title="Create Artifact"
        onClose={handleCloseAttempt}
        onSubmit={handleSubmit}
        submitLabel="Create Artifact"
        isSubmitDisabled={(values as $TSFixMe)?.name?.trim() === "" || showNameError[0]}
        submitActionInfo={
          (values as $TSFixMe)?.name?.trim() === ""
            ? "Please enter all mandatory fields to proceed with this action."
            : ""
        }
        submitActionTooltipProps={{
          placement: "top-end"
        }}
        isSubmitting={isLoading}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Field
                  id="name"
                  label="Artifact Name"
                  variant="outlined"
                  size="small"
                  fullWidth
                  required
                  error={showNameError[0]}
                  helperText={showNameError[0] && showNameError[1]}
                  onChange={(event) => {
                    handleChange(event, "name");
                  }}
                />
              </Grid>
              <Grid item>
                <Paper className={classes.paper}>
                  <Grid
                    container
                    alignItems="center"
                    style={{ display: "flex", justifyContent: "center" }}>
                    <input
                      style={{ opacity: 0, height: 164, position: "absolute" }}
                      id="files"
                      multiple
                      name="files"
                      type="file"
                      onChange={handleFilesChange}
                      onClick={cleanErrorMessage}
                      required
                      ref={fileRef}
                      disabled={isLoading}
                    />
                    <label htmlFor="files" className={classes.uploadComponent}>
                      <Grid container direction="column" spacing={1}>
                        <Grid item>
                          <Typography variant="caption" color="textPrimary">
                            Drag & drop the files or click to browse.
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography className={classes.uploadButton}>BROWSE FILE</Typography>
                        </Grid>
                        {errorMessage && (
                          <Typography className={classes.error}>{errorMessage}</Typography>
                        )}
                      </Grid>
                    </label>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container direction="column" style={{ rowGap: 16 }}>
              <Badge overlap="rectangular" badgeContent={size((values as $TSFixMe).files)} showZero>
                <Typography variant="subtitle2" color="textPrimary">
                  Selected Files
                </Typography>
              </Badge>

              {!isEmpty((values as $TSFixMe).files) && (
                <UploadFileList
                  msgHeight={0}
                  files={(values as $TSFixMe).files}
                  name={(values as $TSFixMe).name}
                  getFileType={getFileType}
                  isUploadOpen={isUploadOpen}
                  onError={handleError}
                  onChange={handleChangeFiles}
                  onRemoveFile={handleRemoveFile}
                  onClose={handleOnClose}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default CreateArtifact;
