import React from "react";

export enum ConnectorsConfig {
  ExemptingWidth = 0.15, // 15%
  CardWidth = 575,
  CardHeight = 230
}

import { GcpImage } from "../assets/images/GcpImage";
import { AmazonS3Image } from "../assets/images/AmazonS3Image";
import { AzureImage } from "../assets/images/AzureImage";
import { MySqlImage } from "../assets/images/MySqlImage";
import { SnowflakeImage } from "../assets/images/SnowflakeImage";
import { RedshiftImage } from "../assets/images/RedshiftImage";
import { MongoDbImage } from "../assets/images/MongoDbImage";
import { PostgresImage } from "../assets/images/PostgresImage";

export const thirdPartyTypeName = "THIRD_PARTY";
export const INACTIVE_DATA_SOURCE_MSG =
  "Data connector is currently inactive. Please ensure that the connector configuration is correct to display sample data.";
export const emptyDataCauses = [
  " Sync is still in progress. Recheck after some time",
  " No data available at source. Update your Data Connector"
];
export const activeDataSourceCondition = {
  SCHEMA_STATUS: "ready",
  SETUP_STATE: "connected"
};

export const DEFAULT_SYNCING_MESSAGE = (
  <div>
    <b>Note: </b>Sync is currently in progress. Additional tables/data might get added once the sync
    is successful
  </div>
);

export const DEFAULT_SYNC_ISSUE_MESSAGE = (
  <div>
    <b>Note: </b>There are sync issues and data might not be fully synced
  </div>
);

export enum DataSourcesHttpStatuses {
  Success = "SUCCESS"
}

export enum DataConnectorNames {
  S3_STORAGE = "S3_STORAGE",
  AZURE_BLOB = "AZURE_BLOB",
  GCP_STORAGE = "GCP_STORAGE",
  MONGO = "MONGO",
  MYSQL = "MYSQL",
  REDSHIFT = "REDSHIFT",
  SNOWFLAKE = "SNOWFLAKE",
  POSTGRES = "POSTGRES"
}

export interface IConnector {
  name?: string;
  displayName?: string;
  image?: JSX.Element;
  url?: string;
  type?: "fivetran" | "default";
  isSelected?: boolean;
}

export const dataSourcesTypes = [
  {
    name: DataConnectorNames.S3_STORAGE,
    displayName: "Amazon S3",
    image: <AmazonS3Image />
  },
  {
    name: DataConnectorNames.AZURE_BLOB,
    displayName: "Azure Blob Storage",
    image: <AzureImage />
  },
  {
    name: DataConnectorNames.GCP_STORAGE,
    displayName: "Google Cloud Platform",
    image: <GcpImage />
  },
  {
    name: DataConnectorNames.MONGO,
    displayName: "MongoDB",
    image: <MongoDbImage />,
    isSql: true
  },
  {
    name: DataConnectorNames.MYSQL,
    displayName: "MySQL",
    image: <MySqlImage />,
    isSql: true
  },
  {
    name: DataConnectorNames.POSTGRES,
    displayName: "PostgreSQL",
    image: <PostgresImage />,
    isSql: true
  },
  {
    name: DataConnectorNames.REDSHIFT,
    displayName: "Amazon Redshift",
    image: <RedshiftImage />,
    isSql: true
  },
  {
    name: DataConnectorNames.SNOWFLAKE,
    displayName: "Snowflake",
    image: <SnowflakeImage />,
    isSql: true
  }
];

export enum DataSourceTabKeys {
  Files = "files",
  Data = "data",
  Datasets = "datasets",
  Jobs = "schedulers"
}

export const dataSourceTabKeyNames = {
  [DataSourceTabKeys.Files]: "Files",
  [DataSourceTabKeys.Data]: "Data",
  [DataSourceTabKeys.Datasets]: "Datasets",
  [DataSourceTabKeys.Jobs]: "Schedulers"
};

export const dataSourceTabs = [
  {
    key: DataSourceTabKeys.Datasets,
    displayName: dataSourceTabKeyNames[DataSourceTabKeys.Datasets]
  },
  {
    key: DataSourceTabKeys.Jobs,
    displayName: dataSourceTabKeyNames[DataSourceTabKeys.Jobs]
  }
];

export enum DataSourceUsageDatasetTypes {
  Source = "SOURCE",
  Destination = "DEST",
  JobSource = "JOB_ENTITY",
  JobDestination = "JOB_DEST",
  predictionService = "PREDICTION_SERVICE"
}

export enum SplashSectionHelperText {
  Title = "Get Started with a New Data Connector",
  SubLine1 = "Welcome to the Connectors page.",
  SubLine2 = "Start a new one by clicking on the button below."
}

export enum DataSourceDeletePromptDetails {
  title = "Do you really want to delete this data connector?",
  message = "If you confirm you will delete the data connector completely",
  confirmLabel = "Delete"
}

export enum DataSourcesHelperText {
  SearchDataSourcesPlaceholder = "Search data connectors",
  SelectAll = "Select All",
  UpdatedOn = "Updated on",

  ConfigurationTitle = "Data Connector Configuration",
  ConfigurationInfo = "Provide the details below to configure your connection",

  Unknown = "Unknown",
  Source = "Type",
  Required = "Required!",

  DataSourceNameExists = "The Data Connector name already exist.",

  SqlConfigurationEditorQueryFieldInfo = "Only select queries are supported on the SQL query interface",

  SaveActionDisabledInfo = "The save action is disabled until the connection is tested and successful.",
  SqlConfigurationFieldDisabledInfo = "This field is disabled until the connection is tested successfully and saved.",
  SqlConfigurationActionDisabledInfo = "This action is disabled until the connection is tested and successful.",

  ConnectionSuccess = "Connection success!",
  ConnectionSuccessObjectBased = "Connection success! Save the connection to access files.",
  ConnectionFailed = "Connection failed! Please recheck params and/or credentials provided.",

  SampleData = "Sample Data",

  NewDataSource = "New Data Connector",
  EditConfiguration = "Edit Configuration",
  Save = "Save",
  Delete = "Delete",
  ManualSync = "Manual Sync",
  RunQuery = "Run Query",
  Download = "Download",

  DataSourceUpdateSucceed = "Data Connector updated successfully!",
  DataSourceCreateSucceed = "Data Connector created successfully!",
  DataSourceDeleteSucceed = "Data Connector deleted successfully!",

  SourceDatasetsCardTitle = "Data Connector as Source",
  DestinationDatasetsCardTitle = "Data Connector as Destination",

  NoFileFound = "No file found!",
  NoDatasetFound = "No dataset found!",
  NoJobFound = "No schedulers found!",
  NoSampleDataFound = "No sample data found",
  SomethingWentWrong = "Something went wrong!",

  NoSearchedColumnsFoundInfo = "No matching columns found!"
}
