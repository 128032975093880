import React, { useMemo } from "react";
import _ from "lodash";
import {
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";

import useGetEnvironmentTypes, { IReturn } from "src/hooks/api/environments/useGetEnvironmentTypes";
import { Alert } from "@material-ui/lab";
import { EnvironmentsHelperText } from "src/pages/private/EnvironmentsModule/utils/Environments.constants";
import { EnvironmentsTypes } from "environmentsModule/utils/Environments.constants";
import { Field, Spinner } from "src/components";
import { capitalize } from "utils/helpers/string.helpers";
import { dataAppConfigFields } from "./CreateDataAppForm";
import { useStyles } from "../../../../private/EnvironmentsModule/pages/Environment/containers/EnvironmentInputs/EnvironmentInputs";

interface IProps {
  value: Pick<IReturn, "cores" | "diskInGbs" | "name" | "memInGbs">;
  readOnly?: boolean;
  onChange: (
    e: React.ChangeEvent<{
      name?: string;
      value: Pick<IReturn, "cores" | "diskInGbs" | "name" | "memInGbs">;
    }>
  ) => void;
}

export const validateDataAppEnvConfig = (fieldName: string, value: number | "", limit: number) => {
  if (value === "") {
    return null;
  } else {
    const numericValue = _.toNumber(value);

    if (!Number.isInteger(numericValue)) {
      return `The ${fieldName} must be an integer.`;
    } else if (numericValue <= 0 || numericValue > limit) {
      return `The  ${fieldName} needs to be between 1 and ${limit}.`;
    } else {
      return null;
    }
  }
};

const DataAppEnvironment: React.FC<IProps> = (props) => {
  const { value, onChange } = props;
  const { data, isLoading } = useGetEnvironmentTypes();

  const classes = useStyles();
  const selectedEnv = useMemo(() => {
    return data?.find((val) => val.name === value.name);
  }, [data, value]);

  const { coresHelperText, diskInGbsHelperText, memInGbsHelperText } = useMemo(() => {
    const { cores, diskInGbs, memInGbs } = value;

    if (!selectedEnv) {
      return { coresHelperText: "", diskInGbsHelperText: "", memInGbsHelperText: "" };
    }
    return {
      coresHelperText: validateDataAppEnvConfig("Cores", cores, selectedEnv?.cores),
      diskInGbsHelperText: validateDataAppEnvConfig(
        "Disk Space",
        diskInGbs,
        selectedEnv?.diskInGbs
      ),
      memInGbsHelperText: validateDataAppEnvConfig("Memory", memInGbs, selectedEnv?.memInGbs)
    };
  }, [value]);

  const handleChange = (
    event: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>,
    key: string
  ) => {
    onChange({
      ...event,
      target: {
        name: dataAppConfigFields.zipEnv,
        value: { ...value, [key]: event.target.value }
      }
    } as any);
  };

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
        <FormControl variant="outlined" style={{ width: "100%" }}>
          <InputLabel id="demo-simple-select-outlined-label">Environment Type *</InputLabel>
          <Select
            required
            displayEmpty
            data-testid="dataAppenvType"
            id={dataAppConfigFields.zipEnv}
            name={dataAppConfigFields.zipEnv}
            value={value.name}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left"
              },
              getContentAnchorEl: null
            }}
            onChange={(e) => handleChange(e, "name")}
            label="Environment Type *">
            {isLoading ? (
              <Spinner padding={5} size={24} />
            ) : (
              _.map(data, (item) => {
                const displayName = capitalize(item.name.toLowerCase().split("_").join(" "));

                return (
                  <MenuItem key={item.name} value={item.name}>
                    <Grid
                      container
                      direction="row"
                      style={{ flexWrap: "nowrap", gap: "8px" }}
                      alignItems="center">
                      <Grid component="span" color="primary">
                        {displayName}
                      </Grid>
                      {item.name !== EnvironmentsTypes.Custom && (
                        <Grid
                          component="span"
                          style={{
                            color: "rgba(0, 0, 0, 0.6)",
                            display: "inline-block",
                            fontSize: "12px",
                            lineHeight: "24px"
                          }}>
                          {`Core: ${item.cores} | Memory: ${(
                            item.memInMbs / 1024
                          ).toFixed()} GB | Disk Space: ${item.diskInGbs} GB`}
                        </Grid>
                      )}
                    </Grid>
                  </MenuItem>
                );
              })
            )}
          </Select>
        </FormControl>
        {selectedEnv && selectedEnv.name === EnvironmentsTypes.Custom && (
          <div style={{ display: "flex", gap: "12px" }}>
            <Field
              id="cores"
              label="Cores"
              value={value.cores}
              onChange={(e) => handleChange(e, "cores")}
              error={!!coresHelperText}
              helperText={coresHelperText}
              type="number"
              required
              variant="outlined"
            />
            <Field
              id="memInGbs"
              label="Memory"
              value={value.memInGbs}
              onChange={(e) => handleChange(e, "memInGbs")}
              InputProps={{
                endAdornment: <InputAdornment position="end">GB</InputAdornment>
              }}
              error={!!memInGbsHelperText}
              helperText={memInGbsHelperText}
              type="number"
              required
              variant="outlined"
            />
            <Field
              id="diskInGbs"
              label="Disk Space"
              disabled={props.readOnly}
              value={value.diskInGbs}
              onChange={(e) => handleChange(e, "diskInGbs")}
              InputProps={{
                endAdornment: <InputAdornment position="end">GB</InputAdornment>
              }}
              error={!!diskInGbsHelperText}
              helperText={diskInGbsHelperText}
              type="number"
              required
              variant="outlined"
            />
          </div>
        )}
      </div>
      <Alert
        variant="outlined"
        severity="info"
        className={classes.diskSpaceInfo}
        icon={<InfoOutlined fontSize="small" color="action" />}>
        <Typography variant="caption" color="textSecondary" data-testid="envDiskSpaceInfo">
          {EnvironmentsHelperText.DiskSpaceInfo}
        </Typography>
      </Alert>
    </div>
  );
};

export default DataAppEnvironment;
