import React from "react";

import { Link } from "react-router-dom";

import Typography, { TypographyProps } from "@material-ui/core/Typography";
import { makeStyles, TypographyVariant } from "@material-ui/core/styles";

import { OverflowTooltip } from "src/components";

type TypographyColors = Exclude<TypographyProps["color"], undefined>;

type Props = {
  variant?: TypographyVariant;
  color?: TypographyColors;
  isOverflowTooltip?: boolean;
  children: React.ReactNode;
  linkTo?: string;
  style?: { [key: string]: string | number };
};

const useStyles = makeStyles((theme) => ({
  link: {
    color: "#515151",
    fontSize: "14px",

    "&:hover": {
      color: theme.palette.primary.main,
      textDecoration: "none"
    },
    maxWidth: 200,
    whiteSpace: "nowrap"
  },
  tooltip: {
    maxWidth: 200,
    whiteSpace: "nowrap"
  }
}));

const RenderText = ({
  variant = "body2",
  color = "textPrimary",
  isOverflowTooltip = false,
  children,
  linkTo,
  ...rest
}: Props) => {
  const classes = useStyles();
  const val = isOverflowTooltip ? (
    <OverflowTooltip
      className={classes.tooltip}
      style={{ whiteSpace: "nowrap" }}
      value={children}
    />
  ) : (
    children
  );

  if (linkTo) {
    return (
      <Link to={linkTo} className={classes.link}>
        {val}
      </Link>
    );
  } else {
    return (
      <Typography component="span" variant={variant} color={color} {...rest}>
        {val}
      </Typography>
    );
  }
};

export default RenderText;
