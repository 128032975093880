// @REFACTOR
// The whole component is to be refactored.

import React, { useEffect, useState } from "react";
import { Grid, makeStyles, TextField, Tooltip } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import { validateNameField } from "utils/helpers/form.helpers";
import ErrorIcon from "@material-ui/icons/Error";
import Space from "src/pages/common/Space";

const FONT_SIZE = 14;

export const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    gap: 12,
    // @ts-expect-error
    maxWidth: ({ inputWidth }) => inputWidth
  },
  inputClass: {
    flex: "1 0 auto",
    "& div:not(.Mui-focused):not(:hover) fieldset": {
      border: "none"
    },
    "& input": {
      fontSize: FONT_SIZE,
      padding: "8px"
    }
  },
  infoTooltipStyles: {
    margin: "0px 15px",
    cursor: "help",
    opacity: 0.5,
    verticalAlign: "bottom"
  },
  errorToolTip: {
    background: "#FFF1F1",
    color: "black",
    maxWidth: "500px",
    margin: 0,
    padding: "7px",
    borderRadius: "4px",
    boxShadow: "0px 4px 4px 0px #00000040"
  }
}));

type OwnProps = {
  placeholder?: string;
  inputValue?: string;
  onSave: (val: string) => void;
  isLoading?: boolean;
  infoTooltip?: string;
  isResetName?: number | boolean;
  containerProps?: $TSFixMe;
  inputProps?: any;
  fieldNameLabel: string;
};

// @ts-expect-error TS(2565) FIXME: Property 'defaultProps' is used before being assig... Remove this comment to see the full error message
type Props = OwnProps & typeof EditName.defaultProps;

export default function EditName({
  placeholder,
  inputValue,
  onSave,
  isLoading,
  infoTooltip,
  isResetName,
  containerProps,
  inputProps,
  fieldNameLabel,
  ...restProps
}: Props) {
  const classes = useStyles({ inputWidth: (inputValue?.length + 1) * (FONT_SIZE - 4) + 16 });

  const [inputName, setInputName] = useState(inputValue);
  const [validationError, setValidationError] = useState("");

  const handleChange = (e: $TSFixMe) => {
    const { value } = e.target;
    setInputName(value);
    const { error } = validateNameField({
      fieldName: value,
      fieldNameLabel: fieldNameLabel
    });
    if (error) {
      setValidationError(error);
    } else {
      setValidationError("");
    }
  };
  const handleSubmit = () => {
    if (inputValue.trim() === inputName.trim()) {
      return;
    }
    if (validationError) {
      setInputName(inputValue);
      setValidationError("");
      return;
    }

    onSave(inputName);
  };

  const handleKeyPress = (e: $TSFixMe) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  useEffect(() => {
    setInputName(inputValue);
  }, [inputValue]);

  useEffect(() => {
    !!isResetName && setInputName(inputValue);
  }, [isResetName]);

  return (
    <div className={classes.container} {...containerProps}>
      <Tooltip
        open={!!validationError}
        classes={{ tooltip: classes.errorToolTip }}
        title={
          !!validationError && (
            <Space>
              <Grid>
                <ErrorIcon style={{ color: "#DA1D27" }} />
              </Grid>
              <Grid style={{ flex: 1 }}>
                <p style={{ margin: 0, fontWeight: 400 }}> {validationError}</p>
              </Grid>
            </Space>
          )
        }>
        <TextField
          size="small"
          className={classes.inputClass}
          onBlur={handleSubmit}
          onChange={handleChange}
          inputProps={inputProps}
          placeholder={placeholder}
          data-testid="editNameField"
          value={inputName}
          error={Boolean(validationError)}
          variant="outlined"
          onKeyPress={handleKeyPress}
          disabled={isLoading}
          {...restProps}
        />
      </Tooltip>
      {infoTooltip && (
        <Tooltip title={infoTooltip}>
          <InfoOutlined className={classes.infoTooltipStyles} />
        </Tooltip>
      )}
    </div>
  );
}

EditName.defaultProps = {
  placeholder: "",
  inputValue: "",
  isLoading: false
};
