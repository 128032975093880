import React from "react";

interface IProps {
  width?: number;
  height?: number;
  viewBox?: string;
  fill?: string;
  color?: string;
}

const ImportDataApp: React.FC<IProps> = (props) => {
  const { width = 155, height = 155, viewBox = "0 0 155 155" } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_28238_1908)">
        <path d="M140 107.071H0V107.141H140V107.071Z" fill="#EBEBEB" />
        <path
          d="M66.3637 94.584H12.296C11.4148 94.584 10.6978 93.867 10.6978 92.9858V16.9848C10.6978 16.1037 11.4148 15.3866 12.296 15.3866H66.3637C67.2446 15.3866 67.9617 16.1037 67.9617 16.9848V92.9858C67.9617 93.867 67.2446 94.584 66.3637 94.584ZM12.296 15.4569C11.4532 15.4569 10.7678 16.1423 10.7678 16.9851V92.9861C10.7678 93.8289 11.4532 94.5143 12.296 94.5143H66.3637C67.2062 94.5143 67.8917 93.8289 67.8917 92.9861V16.9848C67.8917 16.1423 67.2062 15.4566 66.3637 15.4566L12.296 15.4569Z"
          fill="#EBEBEB"
        />
        <path
          d="M126.927 94.584H72.8592C71.978 94.584 71.2612 93.867 71.2612 92.9858V16.9848C71.2612 16.1037 71.9783 15.3866 72.8592 15.3866H126.927C127.808 15.3866 128.525 16.1037 128.525 16.9848V92.9858C128.525 93.867 127.808 94.584 126.927 94.584ZM72.8592 15.4569C72.0167 15.4569 71.3312 16.1423 71.3312 16.9851V92.9861C71.3312 93.8289 72.0167 94.5143 72.8592 94.5143H126.927C127.769 94.5143 128.455 93.8289 128.455 92.9861V16.9848C128.455 16.1423 127.769 15.4566 126.927 15.4566L72.8592 15.4569Z"
          fill="#EBEBEB"
        />
        <path d="M56.1219 109.738H48.8428V109.808H56.1219V109.738Z" fill="#EBEBEB" />
        <path d="M29.8368 108.978H14.6885V109.048H29.8368V108.978Z" fill="#EBEBEB" />
        <path d="M48.5621 112.338H41.1934V112.408H48.5621V112.338Z" fill="#EBEBEB" />
        <path d="M83.1767 111.868H72.3037V111.938H83.1767V111.868Z" fill="#EBEBEB" />
        <path d="M88.6638 111.868H85.6694V111.938H88.6638V111.868Z" fill="#EBEBEB" />
        <path d="M125.972 110.686H110.697V110.756H125.972V110.686Z" fill="#EBEBEB" />
        <path d="M63.0861 25.5503H21.5386V69.3932H63.0861V25.5503Z" fill="#E6E6E6" />
        <path
          d="M67.5382 24.1312H18.849C18.6213 24.1312 18.4365 24.316 18.4365 24.5437C18.4365 24.7713 18.6213 24.9561 18.849 24.9561H67.5384C67.7664 24.9561 67.9509 24.7713 67.9509 24.5437C67.9509 24.316 67.7661 24.1312 67.5382 24.1312Z"
          fill="#E6E6E6"
        />
        <path d="M64.8489 25.5503H22.3623V69.3932H64.8489V25.5503Z" fill="#F0F0F0" />
        <path d="M63.0861 69.3929H21.5386V71.0309H63.0861V69.3929Z" fill="#E6E6E6" />
        <path d="M67.786 69.3929H25.2993V71.0309H67.786V69.3929Z" fill="#F0F0F0" />
        <path
          d="M62.3809 66.9249L62.3809 28.0184L24.8303 28.0184L24.8303 66.9249H62.3809Z"
          fill="#FAFAFA"
        />
        <path d="M33.3545 66.925L47.8406 28.0182H54.6787L40.1927 66.925H33.3545Z" fill="white" />
        <path d="M41.6616 66.925L56.1477 28.0182H58.8139L44.3278 66.925H41.6616Z" fill="white" />
        <path
          d="M62.3809 66.9248L62.3809 28.0182H62.0675L62.0675 66.9248H62.3809Z"
          fill="#E6E6E6"
        />
        <g opacity="0.6">
          <path
            d="M23.8115 62.9944H18.5942L21.4063 23.3932H26.6232L23.8115 62.9944Z"
            fill="#F0F0F0"
          />
          <path
            d="M25.8433 62.9944H21.7788L24.5906 23.3931H28.655L25.8433 62.9944Z"
            fill="#E6E6E6"
          />
          <path d="M29.028 62.9944H23.811L26.6228 23.3931H31.8398L29.028 62.9944Z" fill="#F0F0F0" />
          <path
            d="M31.0606 62.9944H26.9961L29.8079 23.3931H33.8723L31.0606 62.9944Z"
            fill="#E6E6E6"
          />
          <path
            d="M34.2453 62.9944H29.0283L31.8401 23.3931H37.0573L34.2453 62.9944Z"
            fill="#F0F0F0"
          />
        </g>
        <g opacity="0.6">
          <path
            d="M63.9892 62.9944H66.9373L65.3483 23.3934H62.3999L63.9892 62.9944Z"
            fill="#F0F0F0"
          />
          <path
            d="M62.8405 62.9944H65.1373L63.5483 23.3934H61.2515L62.8405 62.9944Z"
            fill="#E6E6E6"
          />
          <path
            d="M61.0407 62.9944H63.9891L62.3998 23.3934H59.4517L61.0407 62.9944Z"
            fill="#F0F0F0"
          />
          <path
            d="M59.8922 62.9944H62.1893L60.6001 23.3934H58.3032L59.8922 62.9944Z"
            fill="#E6E6E6"
          />
          <path
            d="M58.0924 62.9944H61.0408L59.4518 23.3934H56.5034L58.0924 62.9944Z"
            fill="#F0F0F0"
          />
        </g>
        <path d="M119.463 23.3932H90.7334V61.591H119.463V23.3932Z" fill="#E6E6E6" />
        <path d="M121.646 23.3931H91.6304V61.591H121.646V23.3931Z" fill="#F5F5F5" />
        <path
          d="M95.5225 24.9626L92.8491 58.0219L117.754 60.0359L120.427 26.9766L95.5225 24.9626Z"
          fill="white"
        />
        <path
          d="M119.813 26.9262L117.14 59.9856L117.753 60.0352L120.426 26.9758L119.813 26.9262Z"
          fill="#E6E6E6"
        />
        <path d="M123.229 75.8593H107.183V107.071H123.229V75.8593Z" fill="#F5F5F5" />
        <path d="M123.229 75.8593H121.906V107.071H123.229V75.8593Z" fill="#F0F0F0" />
        <path d="M71.4712 89.9566H108.419V76.7498H71.4712V89.9566Z" fill="#E6E6E6" />
        <path d="M73.2524 78.2003H123.229V75.8593H73.2524V78.2003Z" fill="#F0F0F0" />
        <path d="M73.2523 75.8593H57.2061V107.071H73.2523V75.8593Z" fill="#F5F5F5" />
        <path
          d="M61.7202 107.071H53.9253V89.6655C53.9253 87.533 55.654 85.8043 57.7865 85.8043H57.859C59.9915 85.8043 61.7202 87.533 61.7202 89.6655V107.071Z"
          fill="#E6E6E6"
        />
        <path d="M73.2525 75.8593H71.9292V107.071H73.2525V75.8593Z" fill="#F0F0F0" />
        <path d="M91.314 75.8593H87.8521L88.6943 69.1214H92.1562L91.314 75.8593Z" fill="#F0F0F0" />
        <path
          d="M92.0796 75.8593H88.6177L89.4599 69.1214H92.9218L92.0796 75.8593Z"
          fill="#F5F5F5"
        />
        <path
          d="M92.5005 72.4903H89.0386L89.4597 69.1214H92.9216L92.5005 72.4903Z"
          fill="#F0F0F0"
        />
        <path
          d="M79.7795 71.6837H104.268C104.472 71.6837 104.631 71.5047 104.605 71.302L102.718 56.2039C102.697 56.0339 102.552 55.9065 102.381 55.9065H77.8923C77.6882 55.9065 77.53 56.0854 77.5554 56.2881L79.4426 71.3863C79.4639 71.5563 79.6084 71.6837 79.7795 71.6837Z"
          fill="#F0F0F0"
        />
        <path
          d="M80.1437 71.6837H104.633C104.837 71.6837 104.995 71.5047 104.969 71.302L103.082 56.2039C103.061 56.0339 102.916 55.9065 102.745 55.9065H78.2565C78.0524 55.9065 77.8942 56.0854 77.9197 56.2881L79.8069 71.3863C79.8279 71.5563 79.9724 71.6837 80.1437 71.6837Z"
          fill="#F5F5F5"
        />
        <path
          d="M104.179 70.8493L102.413 56.7174C102.407 56.6698 102.366 56.6342 102.319 56.6342H78.8034C78.7462 56.6342 78.702 56.6843 78.7093 56.7409L80.4758 70.8728C80.4817 70.9204 80.522 70.9559 80.5699 70.9559H104.085C104.142 70.9559 104.186 70.9061 104.179 70.8493Z"
          fill="#E6E6E6"
        />
        <path d="M101.67 70.9565L95.4541 56.6348H90.0039L96.2199 70.9565H101.67Z" fill="#EBEBEB" />
        <path
          d="M95.1702 70.9565L88.9544 56.6348H86.9902L93.2062 70.9565H95.1702Z"
          fill="#EBEBEB"
        />
        <path
          d="M90.1527 75.2279H94.6685C94.9788 75.2279 95.2299 75.4793 95.2299 75.7896V75.8596H89.5913V75.7896C89.5913 75.4793 89.8427 75.2279 90.1527 75.2279Z"
          fill="#F5F5F5"
        />
        <path
          d="M86.9801 75.2279H89.6821C89.9924 75.2279 90.2435 75.4793 90.2435 75.7896V75.8596H86.4185V75.7896C86.4187 75.4793 86.6699 75.2279 86.9801 75.2279Z"
          fill="#F0F0F0"
        />
        <path
          d="M101.462 75.8596H84.0279L83.5566 74.4604H99.1935L101.462 75.8596Z"
          fill="#F5F5F5"
        />
        <path d="M85.9461 75.8596H83.066L82.6763 74.4604H85.5564L85.9461 75.8596Z" fill="#F0F0F0" />
        <path
          d="M76.6062 74.6889H79.2273C79.8738 74.6889 80.3977 75.2128 80.3977 75.8593H75.4355C75.4358 75.2128 75.96 74.6889 76.6062 74.6889Z"
          fill="#F0F0F0"
        />
        <path
          d="M24.9838 107.071H29.8472L29.8472 84.4665H24.9838L24.9838 107.071Z"
          fill="#E0E0E0"
        />
        <path d="M46.5764 107.071H26.9134L24.4824 80.36H44.1451L46.5764 107.071Z" fill="#E6E6E6" />
        <path d="M47.5952 107.071H27.9322L25.501 80.36H45.164L47.5952 107.071Z" fill="#F0F0F0" />
        <path d="M30.6196 104.115L28.7266 83.3168H42.4754L44.3685 104.115H30.6196Z" fill="white" />
        <path
          d="M69.9999 119.717C99.9828 119.717 124.289 118.298 124.289 116.547C124.289 114.796 99.9828 113.376 69.9999 113.376C40.0169 113.376 15.7109 114.796 15.7109 116.547C15.7109 118.298 40.0169 119.717 69.9999 119.717Z"
          fill="#F5F5F5"
        />
        <path
          d="M27.745 49.5586L27.4648 49.5677L27.5869 53.2988L27.8671 53.2897L27.745 49.5586Z"
          fill="#4646B5"
        />
        <path
          d="M27.6395 46.3404L27.3594 46.3496L27.4174 48.1225L27.6975 48.1133L27.6395 46.3404Z"
          fill="#4646B5"
        />
        <path
          d="M31.4157 73.3234H84.857C86.0658 73.3234 87.0225 72.3344 86.9831 71.1256L85.7933 34.6601C85.7539 33.4514 84.7327 32.4624 83.5239 32.4624H30.0823C28.8735 32.4624 27.9168 33.4514 27.9563 34.6601L29.146 71.1256C29.1855 72.3344 30.2069 73.3234 31.4157 73.3234Z"
          fill="#4646B5"
        />
        <path
          d="M31.587 73.3234H85.0284C86.2372 73.3234 87.1939 72.3344 87.1544 71.1256L85.9644 34.6601C85.925 33.4514 84.9038 32.4624 83.695 32.4624H30.2537C29.0449 32.4624 28.0882 33.4514 28.1276 34.6601L29.3174 71.1256C29.3568 72.3344 30.3783 73.3234 31.587 73.3234Z"
          fill="#4646B5"
        />
        <path
          opacity="0.5"
          d="M31.587 73.3234H85.0284C86.2372 73.3234 87.1939 72.3344 87.1544 71.1256L85.9644 34.6601C85.925 33.4514 84.9038 32.4624 83.695 32.4624H30.2537C29.0449 32.4624 28.0882 33.4514 28.1276 34.6601L29.3174 71.1256C29.3568 72.3344 30.3783 73.3234 31.587 73.3234Z"
          fill="white"
        />
        <path
          opacity="0.5"
          d="M83.5597 33.5612H30.1183C30.0578 33.5612 29.9982 33.5637 29.9391 33.5684C28.5685 33.681 28.7659 35.7589 30.1446 35.7589H83.6773C85.056 35.7589 85.1176 33.681 83.7397 33.5684C83.6801 33.5637 83.6202 33.5612 83.5597 33.5612Z"
          fill="#4646B5"
        />
        <path
          d="M31.4665 34.6599C31.4754 34.9382 31.2573 35.1636 30.979 35.1636C30.7007 35.1636 30.4677 34.9379 30.4588 34.6599C30.4498 34.3816 30.6679 34.1562 30.9462 34.1562C31.2246 34.1562 31.4575 34.3818 31.4665 34.6599Z"
          fill="#FAFAFA"
        />
        <path
          d="M33.1803 34.6599C33.1896 34.9382 32.9712 35.1636 32.6929 35.1636C32.4146 35.1636 32.1816 34.9379 32.1726 34.6599C32.1637 34.3818 32.3818 34.1562 32.6601 34.1562C32.9384 34.1562 33.1714 34.3818 33.1803 34.6599Z"
          fill="#FAFAFA"
        />
        <path
          d="M34.8942 34.6599C34.9032 34.9382 34.6851 35.1636 34.4067 35.1636C34.1284 35.1636 33.8955 34.9379 33.8865 34.6599C33.8775 34.3816 34.0957 34.1562 34.374 34.1562C34.652 34.1562 34.885 34.3818 34.8942 34.6599Z"
          fill="#FAFAFA"
        />
        <path
          d="M32.6232 69.6836H83.7552C84.1953 69.6836 84.5403 69.3269 84.526 68.887L83.5474 38.8987C83.5331 38.4586 83.1646 38.1021 82.7248 38.1021H31.5926C31.1527 38.1021 30.8074 38.4589 30.8217 38.8987L31.8003 68.887C31.8149 69.3269 32.1831 69.6836 32.6232 69.6836Z"
          fill="white"
        />
        <g opacity="0.3">
          <path
            d="M70.1471 65.9725H69.2139V65.7071H69.8733L69.852 65.0482L70.1172 65.0396L70.1471 65.9725Z"
            fill="#4646B5"
          />
          <path
            d="M67.7275 65.9725H66.2407V65.7071H67.7275V65.9725ZM64.7541 65.9725H63.2671V65.7071H64.7541V65.9725ZM61.7805 65.9725H60.2937V65.7071H61.7805V65.9725ZM58.8069 65.9725H57.3201V65.7071H58.8069V65.9725ZM55.8336 65.9725H54.3465V65.7071H55.8336V65.9725ZM52.86 65.9725H51.3732V65.7071H52.86V65.9725ZM49.8867 65.9725H48.3999V65.7071H49.8867V65.9725Z"
            fill="#4646B5"
          />
          <path
            d="M46.9131 65.9725H45.988L45.958 65.0482L46.2232 65.0396L46.245 65.7071H46.9131V65.9725Z"
            fill="#4646B5"
          />
          <path
            d="M45.9099 63.5614L45.8614 62.0746L46.1266 62.066L46.175 63.5528L45.9099 63.5614ZM45.8127 60.5878L45.7643 59.101L46.0294 59.0924L46.0779 60.5792L45.8127 60.5878ZM45.7158 57.6142L45.6674 56.1274L45.9325 56.1188L45.981 57.6056L45.7158 57.6142ZM45.6189 54.6406L45.5702 53.1538L45.8354 53.1452L45.8841 54.632L45.6189 54.6406ZM45.5218 51.667L45.4731 50.1802L45.7382 50.1716L45.7869 51.6584L45.5218 51.667ZM45.4246 48.6934L45.3762 47.2066L45.6413 47.198L45.6898 48.6848L45.4246 48.6934ZM45.3277 45.7198L45.2793 44.233L45.5445 44.2244L45.5929 45.7112L45.3277 45.7198Z"
            fill="#4646B5"
          />
          <path
            d="M45.2302 42.7462L45.1997 41.8133H46.1332V42.0787H45.4741L45.4954 42.7378L45.2302 42.7462Z"
            fill="#4646B5"
          />
          <path
            d="M66.9477 42.0787H65.4609V41.8133H66.9477V42.0787ZM63.9744 42.0787H62.4876V41.8133H63.9744V42.0787ZM61.0008 42.0787H59.5137V41.8133H61.0008V42.0787ZM58.0272 42.0787H56.5404V41.8133H58.0272V42.0787ZM55.0538 42.0787H53.567V41.8133H55.0538V42.0787ZM52.0805 42.0787H50.5934V41.8133H52.0805V42.0787ZM49.1069 42.0787H47.6201V41.8133H49.1069V42.0787Z"
            fill="#4646B5"
          />
          <path
            d="M69.124 42.7462L69.1022 42.0787H68.4341V41.8133H69.3592L69.3892 42.7378L69.124 42.7462Z"
            fill="#4646B5"
          />
          <path
            d="M69.8034 63.5614L69.755 62.0746L70.0201 62.066L70.0686 63.5528L69.8034 63.5614ZM69.7063 60.5878L69.6578 59.101L69.923 59.0924L69.9714 60.5792L69.7063 60.5878ZM69.6094 57.6142L69.5607 56.1274L69.8258 56.1188L69.8745 57.6056L69.6094 57.6142ZM69.5125 54.6406L69.4638 53.1538L69.7289 53.1452L69.7776 54.632L69.5125 54.6406ZM69.4153 51.667L69.3669 50.1802L69.6321 50.1716L69.6805 51.6584L69.4153 51.667ZM69.3182 48.6934L69.2697 47.2066L69.5349 47.198L69.5833 48.6848L69.3182 48.6934ZM69.2213 45.7198L69.1729 44.233L69.438 44.2244L69.4865 45.7112L69.2213 45.7198Z"
            fill="#4646B5"
          />
        </g>
        <path
          d="M62.9503 57.6842H53.1752C51.3071 57.6842 49.7379 56.1647 49.6766 54.2965C49.6212 52.5877 50.8473 51.1499 52.5077 50.9203C52.933 49.2728 54.4341 48.101 56.1673 48.101C57.5695 48.101 58.8564 48.834 59.5897 50.0318C59.8955 49.9378 60.2139 49.8904 60.5395 49.8904C62.257 49.8904 63.7217 51.2328 63.8992 52.9231C64.7641 53.3347 65.3519 54.2125 65.3832 55.1696C65.4051 55.8407 65.1637 56.4721 64.7034 56.9476C64.2433 57.4227 63.6209 57.6842 62.9503 57.6842ZM56.1676 48.7539C54.6645 48.7539 53.3726 49.8171 53.0957 51.282L53.0487 51.5307L52.7961 51.5469C51.3661 51.639 50.2825 52.8374 50.329 54.2755C50.3789 55.7954 51.6554 57.0318 53.1752 57.0318H62.9503C63.4425 57.0318 63.8984 56.8409 64.2341 56.494C64.5704 56.1468 64.7468 55.6839 64.7305 55.1911C64.7053 54.4242 64.1999 53.7248 63.4731 53.4509L63.2709 53.3747L63.2619 53.1589C63.2029 51.7166 61.9815 50.5434 60.5392 50.5434C60.201 50.5434 59.8728 50.6075 59.5645 50.7338L59.2893 50.8466L59.1518 50.5829C58.5635 49.4545 57.42 48.7539 56.1676 48.7539Z"
          fill="#4646B5"
        />
        <path
          d="M60.1333 54.5104L57.4372 51.8994L54.9116 54.5104H56.3895L56.4777 57.2099H58.7434L58.6555 54.5104H60.1333Z"
          fill="#4646B5"
        />
        <path
          d="M102.745 43.9045C103.004 44.2755 103.21 44.5894 103.424 44.9372C103.637 45.2765 103.838 45.6209 104.033 45.9693C104.426 46.6645 104.79 47.376 105.125 48.1093C105.457 48.8423 105.772 49.5902 106.032 50.372C106.304 51.1448 106.525 51.9571 106.691 52.7945L106.799 53.4332L106.812 53.513L106.827 53.6152C106.838 53.7129 106.851 53.7972 106.855 53.9064C106.859 54.0142 106.864 54.1122 106.855 54.2363L106.843 54.4132L106.813 54.6081C106.794 54.7319 106.75 54.8828 106.707 55.0163C106.651 55.1619 106.594 55.3017 106.51 55.4422C106.352 55.7225 106.123 55.972 105.892 56.1489C105.412 56.5146 104.979 56.6193 104.629 56.6857C104.275 56.7453 103.986 56.7439 103.716 56.7316C103.448 56.7168 103.204 56.6877 102.972 56.6437C102.512 56.5572 102.094 56.4427 101.692 56.3153C100.893 56.0532 100.155 55.7401 99.4411 55.3918C98.7246 55.0413 98.0472 54.6714 97.3657 54.2335L98.1707 52.4465C99.5858 52.7943 101.048 53.1759 102.394 53.3599C102.728 53.4077 103.053 53.4371 103.343 53.4402C103.623 53.4458 103.899 53.4156 103.944 53.3733C103.961 53.3601 103.898 53.3403 103.712 53.4637C103.628 53.5234 103.526 53.6278 103.457 53.7409C103.424 53.802 103.395 53.8499 103.377 53.9089C103.355 53.9507 103.345 54.0123 103.334 54.0414L103.318 54.1315C103.317 54.1609 103.311 54.1649 103.311 54.1783C103.312 54.1929 103.305 54.1842 103.305 54.1884C103.306 54.1976 103.299 54.1699 103.294 54.1511L103.278 54.0923L103.152 53.6217C102.777 52.36 102.225 51.0728 101.579 49.8162C101.263 49.1848 100.916 48.5609 100.563 47.9413C100.387 47.6311 100.203 47.3247 100.021 47.0195C99.8415 46.7222 99.6446 46.3968 99.4811 46.1406L102.745 43.9045Z"
          fill="#FFB573"
        />
        <path
          d="M98.4996 52.587L97.2858 51.3766L96.0776 54.0685C96.0776 54.0685 97.22 54.7024 98.239 54.2401L98.4996 52.587Z"
          fill="#FFB573"
        />
        <path
          d="M95.49 50.4123L94.5957 52.6842L96.0777 54.0685L97.2859 51.3763L95.49 50.4123Z"
          fill="#FFB573"
        />
        <path
          d="M99.5866 44.8053L76.2749 40.6983L72.1679 64.01L95.4796 68.117L99.5866 44.8053Z"
          fill="#4646B5"
        />
        <path
          opacity="0.6"
          d="M99.5866 44.8053L76.2749 40.6983L72.1679 64.01L95.4796 68.117L99.5866 44.8053Z"
          fill="white"
        />
        <path
          d="M98.2526 45.7399L77.2095 42.0326L73.5021 63.0757L94.5453 66.783L98.2526 45.7399Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M87.1164 52.7837C88.9008 51.534 89.3341 49.0744 88.0844 47.29C86.8346 45.5057 84.375 45.0723 82.5907 46.3221C80.8063 47.5718 80.373 50.0314 81.6227 51.8158C82.8725 53.6001 85.3321 54.0335 87.1164 52.7837Z"
          fill="#4646B5"
        />
        <path
          opacity="0.2"
          d="M89.7858 55.2946L82.7326 60.2324L79.4888 55.6015L74.1668 59.3294L73.5068 63.077L94.548 66.7836L95.2082 63.0361L89.7858 55.2946Z"
          fill="#4646B5"
        />
        <path
          d="M90.9426 35.4239C90.9465 35.6101 90.851 35.7632 90.7292 35.7658C90.6074 35.7683 90.5055 35.6193 90.5016 35.4331C90.4977 35.2469 90.5931 35.0938 90.7149 35.0912C90.8367 35.0887 90.9387 35.2377 90.9426 35.4239Z"
          fill="#263238"
        />
        <path
          d="M90.8678 35.763C90.8678 35.763 90.4665 36.8466 90.0112 37.3747C90.3472 37.6754 90.9047 37.5231 90.9047 37.5231L90.8678 35.763Z"
          fill="#FF5652"
        />
        <path
          d="M91.5979 34.3761C91.5705 34.3809 91.5413 34.3756 91.5167 34.3585C91.1191 34.0844 90.7512 34.2255 90.7358 34.2322C90.6803 34.2546 90.6162 34.2274 90.5935 34.1717C90.5709 34.1157 90.5975 34.0524 90.6532 34.0298C90.6728 34.0216 91.1423 33.8352 91.6407 34.1784C91.6906 34.2129 91.7032 34.2809 91.6687 34.3308C91.6517 34.3557 91.6256 34.3714 91.5979 34.3761Z"
          fill="#263238"
        />
        <path
          d="M96.3392 36.1345C96.221 37.8526 96.3563 41.3235 97.67 42.2948C97.67 42.2948 97.348 43.6959 94.2325 44.0874C90.8064 44.5177 92.3814 42.5924 92.3814 42.5924C94.1952 41.9109 93.9726 40.5297 93.4832 39.2677L96.3392 36.1345Z"
          fill="#FFB573"
        />
        <path
          d="M104.83 110.48C104.823 110.478 104.815 110.475 104.808 110.471C104.753 110.445 104.688 110.383 104.692 110.239C104.694 110.164 104.726 110.099 104.787 110.048C105.05 109.823 105.817 109.922 105.85 109.926C105.872 109.929 105.889 109.944 105.895 109.965C105.901 109.985 105.895 110.008 105.878 110.022C105.699 110.175 105.106 110.576 104.83 110.48ZM105.704 110.021C105.445 110 105.014 109.997 104.857 110.131C104.821 110.162 104.802 110.198 104.801 110.242C104.798 110.345 104.841 110.366 104.855 110.372C105.003 110.444 105.424 110.23 105.704 110.021Z"
          fill="#4646B5"
        />
        <path
          d="M105.456 109.95C105.187 109.857 104.902 109.699 104.854 109.537C104.84 109.491 104.834 109.404 104.944 109.33C105.014 109.282 105.095 109.267 105.183 109.286C105.521 109.358 105.874 109.927 105.889 109.951C105.9 109.969 105.9 109.99 105.89 110.007C105.88 110.025 105.861 110.035 105.841 110.035C105.745 110.032 105.603 110.001 105.456 109.95ZM105.198 109.404C105.185 109.399 105.173 109.396 105.16 109.393C105.101 109.38 105.05 109.389 105.005 109.42C104.945 109.46 104.955 109.492 104.959 109.506C105.003 109.654 105.439 109.86 105.733 109.913C105.626 109.761 105.405 109.475 105.198 109.404Z"
          fill="#4646B5"
        />
        <path
          d="M87.8268 114.307C87.5305 114.307 87.2441 114.266 87.1483 114.132C87.1147 114.086 87.0882 114.006 87.1528 113.893C87.189 113.83 87.2475 113.787 87.3264 113.765C87.7112 113.661 88.5181 114.121 88.5523 114.141C88.5721 114.152 88.5825 114.174 88.5789 114.197C88.5752 114.219 88.5579 114.237 88.5355 114.242C88.3882 114.271 88.1031 114.307 87.8268 114.307ZM87.4606 113.858C87.4222 113.858 87.3866 113.862 87.3547 113.871C87.3043 113.885 87.269 113.91 87.2475 113.948C87.2088 114.015 87.2228 114.049 87.2368 114.069C87.3443 114.218 87.9284 114.221 88.3579 114.16C88.1437 114.051 87.7305 113.858 87.4606 113.858Z"
          fill="#4646B5"
        />
        <path
          d="M88.5246 114.242C88.5171 114.242 88.5092 114.241 88.5022 114.238C88.2234 114.113 87.6712 113.613 87.7132 113.352C87.7233 113.29 87.7667 113.214 87.917 113.199C88.0285 113.189 88.131 113.219 88.2206 113.293C88.5143 113.534 88.5767 114.157 88.579 114.183C88.5809 114.202 88.5722 114.221 88.5563 114.233C88.547 114.239 88.5358 114.242 88.5246 114.242ZM87.961 113.306C87.9504 113.306 87.9392 113.307 87.9282 113.308C87.83 113.318 87.8235 113.357 87.8216 113.369C87.7961 113.526 88.1716 113.919 88.456 114.09C88.4264 113.908 88.344 113.535 88.1511 113.377C88.0929 113.33 88.0304 113.306 87.961 113.306Z"
          fill="#4646B5"
        />
        <path
          d="M91.0651 114.188H88.7551L88.5249 108.838H90.8349L91.0651 114.188Z"
          fill="#FFB573"
        />
        <path
          d="M108.337 109.31L106.195 109.899L103.453 104.883L105.595 104.294L108.337 109.31Z"
          fill="#FFB573"
        />
        <path
          d="M105.875 109.664L108.186 108.482C108.27 108.439 108.372 108.46 108.433 108.533L109.774 110.153C109.913 110.321 109.856 110.576 109.66 110.672C108.846 111.071 108.43 111.224 107.414 111.744C106.789 112.064 104.93 113.447 103.992 113.691C103.074 113.93 102.689 113.04 103.051 112.856C104.676 112.03 105.332 110.676 105.606 109.961C105.655 109.832 105.752 109.726 105.875 109.664Z"
          fill="#263238"
        />
        <path
          d="M88.7702 113.921H91.293C91.3873 113.921 91.4691 113.986 91.4898 114.078L91.9462 116.132C91.9935 116.344 91.832 116.546 91.6138 116.542C90.7038 116.527 89.389 116.474 88.2452 116.474C86.9079 116.474 85.7526 116.546 84.1843 116.546C83.236 116.546 82.9728 115.588 83.3698 115.501C85.1769 115.105 86.6525 115.063 88.2141 114.1C88.3835 113.996 88.5708 113.921 88.7702 113.921Z"
          fill="#263238"
        />
        <path
          d="M98.696 46.5715C98.9368 47.1923 99.202 47.7974 99.4694 48.3646C99.93 49.346 100.389 50.2034 100.722 50.7959C101.02 51.3293 101.214 51.6451 101.214 51.6451L106.26 49.1425C106.26 49.1425 104.227 43.298 102.104 42.6479C99.9294 41.9871 97.6065 43.7852 98.696 46.5715Z"
          fill="#263238"
        />
        <path
          opacity="0.2"
          d="M100.932 47.1492C100.492 47.4723 100.179 48.342 99.9556 49.3634C100.243 49.9259 100.508 50.414 100.722 50.7953C101.019 51.329 101.213 51.6454 101.213 51.6454L101.792 51.3579C101.753 50.0066 101.536 48.4842 100.932 47.1492Z"
          fill="black"
        />
        <path
          d="M88.0259 50.2983C88.0259 51.0843 88.0446 51.9492 88.0889 52.902C88.202 55.4931 88.4851 58.7224 89.026 62.7348H101.381C101.466 60.8386 100.274 51.5843 102.104 42.6479C102.104 42.6479 99.9847 42.2234 97.9659 42.1259C96.3872 42.0503 94.1013 42.0002 92.7145 42.1259C90.8844 42.2956 89.1613 42.7674 89.1613 42.7674C89.1613 42.7674 88.0228 44.0535 88.0259 50.2983Z"
          fill="#263238"
        />
        <path
          d="M91.2043 46.6306C90.9851 47.0904 90.7972 47.416 90.59 47.7523C90.3862 48.0827 90.1812 48.3999 89.9664 48.7076C89.542 49.3262 89.0956 49.9181 88.6286 50.4966C87.6923 51.651 86.6817 52.7514 85.5111 53.767C84.9278 54.2774 84.2939 54.7579 83.5782 55.1966L83.3027 55.3574L83.2308 55.3982L83.1294 55.4489L82.9255 55.5469C82.652 55.6651 82.3751 55.7578 82.1023 55.8124C81.5569 55.9249 81.0437 55.9319 80.5719 55.8902C79.6272 55.7905 78.8367 55.501 78.1121 55.1544C77.7523 54.9746 77.4087 54.7834 77.0831 54.5672C76.7493 54.3519 76.4584 54.131 76.1294 53.8524L77.1214 52.162C78.3322 52.4792 79.7005 52.7648 80.6399 52.6307C80.8692 52.6027 81.0619 52.5456 81.1946 52.4759C81.261 52.4398 81.313 52.4067 81.3553 52.3723C81.3643 52.3625 81.3738 52.3535 81.3833 52.3451L81.3979 52.3334L81.4379 52.3048L81.6079 52.1853C82.0637 51.8498 82.5201 51.4393 82.9692 50.9995C83.8635 50.1116 84.7133 49.0968 85.5147 48.0482C85.9151 47.5232 86.3032 46.9854 86.6739 46.4472C86.8573 46.177 87.0385 45.9079 87.2065 45.643C87.37 45.3838 87.5413 45.1052 87.6301 44.9338L91.2043 46.6306Z"
          fill="#FFB573"
        />
        <path
          d="M77.6212 52.4101L75.4907 51.3998L75.2983 54.2572C75.2983 54.2572 76.9543 54.544 77.8309 53.8972L77.6212 52.4101Z"
          fill="#FFB573"
        />
        <path
          d="M72.8052 51.8859L73.6261 54.0641L75.2986 54.2575L75.4909 51.4001L72.8052 51.8859Z"
          fill="#FFB573"
        />
        <path
          d="M92.7312 45.1172C92.6584 47.3244 88.1121 52.4106 88.1121 52.4106L83.3081 49.2732C83.3081 49.2732 84.8313 46.4239 86.8089 44.3617C89.7923 41.2512 92.8186 42.4678 92.7312 45.1172Z"
          fill="#263238"
        />
        <path
          opacity="0.2"
          d="M88.5254 108.84L88.6436 111.597H90.9547L90.8365 108.84H88.5254Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M105.596 104.295L103.454 104.884L104.868 107.469L107.011 106.88L105.596 104.295Z"
          fill="black"
        />
        <path
          d="M97.1474 34.8995C97.0547 37.1901 97.1169 38.5355 95.9859 39.7339C94.2847 41.5366 91.4387 40.5479 90.8454 38.2754C90.3112 36.23 90.5525 32.8275 92.7729 31.8704C94.9603 30.9274 97.24 32.6085 97.1474 34.8995Z"
          fill="#FFB573"
        />
        <path
          d="M92.5867 35.9912C91.7624 35.4046 90.9535 34.191 91.0156 33.2889C90.2535 33.2578 89.74 31.9978 90.16 31.08C90.4243 31.671 91.334 31.2356 91.6879 30.7689C91.4975 30.3178 91.5286 29.571 92.8354 29.0421C92.7264 29.4232 92.7836 30.063 94.0954 29.6021C95.6897 29.0421 98.4911 29.4154 97.6698 31.3132C98.7932 31.5154 100.248 33.8315 99.0108 35.0512C97.774 36.2709 97.5175 38.2356 96.4597 38.4378C95.4018 38.64 92.6489 38.7088 92.5867 35.9912Z"
          fill="#263238"
        />
        <path
          d="M96.9258 31.2376C97.6608 30.9013 98.8457 31.0455 99.4841 31.9567C98.6147 31.6011 98.1175 31.3698 96.9258 31.2376Z"
          fill="#263238"
        />
        <path
          d="M93.191 35.8934C93.1736 36.4305 92.9387 36.9418 92.6483 37.28C92.2115 37.7888 91.733 37.4928 91.6367 36.8905C91.5499 36.3484 91.6723 35.4306 92.2155 35.1419C92.7505 34.8572 93.2106 35.2794 93.191 35.8934Z"
          fill="#FFB573"
        />
        <path
          d="M93.4079 62.7351C93.4079 62.7351 92.3179 80.2152 94.384 86.6605C97.6567 96.8699 103.49 107.298 103.49 107.298L107.662 106.044C107.662 106.044 103.074 95.035 101.565 86.4351C100.209 78.7094 101.38 69.0452 101.38 62.7348C98.5025 62.7351 93.4079 62.7351 93.4079 62.7351Z"
          fill="#4646B5"
        />
        <path
          opacity="0.2"
          d="M93.2312 66.6299C94.3972 66.2891 95.306 66.4176 95.5421 67.455C96.1578 70.1635 94.3104 77.3057 93.3894 80.5733C93.0159 75.8917 93.1027 70.2923 93.2312 66.6299Z"
          fill="black"
        />
        <path
          d="M102.312 106.102C102.295 106.108 103.401 107.394 103.401 107.394L107.96 106.023L107.38 104.481L102.312 106.102Z"
          fill="#263238"
        />
        <path
          d="M89.0246 62.7351C89.0246 62.7351 85.545 77.483 85.3 84.5278C85.0253 92.4282 87.7825 111.063 87.7825 111.063H91.6958C91.6958 111.063 92.1776 92.2832 92.3515 85.0869C92.5414 77.2419 97.1462 62.7351 97.1462 62.7351H89.0246Z"
          fill="#4646B5"
        />
        <path
          d="M87.0298 109.505C87.0116 109.505 87.2504 111.129 87.2504 111.129H92.0107L92.1395 109.679L87.0298 109.505Z"
          fill="#263238"
        />
        <path
          d="M90.7548 41.5847C91.2843 41.4013 95.3207 40.8884 98.2501 41.0446C99.9223 41.1339 100.865 41.3162 101.548 41.6466C102.232 41.9773 101.007 43.6419 100.442 43.8452C100.671 44.4836 99.3973 45.2981 98.6707 45.6845C98.3797 46.2851 95.5176 46.8392 93.8359 45.5498C92.5445 44.5603 89.2436 42.1083 90.7548 41.5847Z"
          fill="#4646B5"
        />
      </g>
      <defs>
        <clipPath id="clip0_28238_1908">
          <rect width="140" height="140" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ImportDataApp;
