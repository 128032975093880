import React, { useEffect, useMemo, useState } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { capitalize, concat, filter, find, get, includes, isEmpty, map, size } from "lodash";

import ConfigContainer from "./ConfigContainer";
import OntologyContainer from "./OntologyContainer/OntologyContainer";
import ViewUploadedContainer from "./ViewUploadedContainer";
import WebWorker from "src/workerSetup";
import api from "services/AxiosClient/AxiosClient";
import useDatasetsSession from "../../hooks/useDatasetsSession";
import useEntities from "src/hooks/api/entities/useEntities";
import useFilesSession from "../../hooks/useFilesSession";
import useGetEntityData, { QUERY_KEY_ENTITY_DATA } from "src/hooks/api/entities/useGetEntityData";
import useHelpers from "../../hooks/useHelpers";
import useStoreSelectors from "../../hooks/useStoreSelectors";
import worker from "../../edaWorker";

import {
  Criteria,
  schemaOptionsSupportedFileTypes,
  FileMetaDataKeys,
  FileUploadStatuses,
  DatasetKeys,
  OntologyDatasetStatuses,
  separators,
  encodings,
  DatasetHelperText
} from "../../utils/Dataset.constants";
import { EntityDataDto, EntityMetaDtoEntityViewTypeEnum } from "@rapidcanvas/rc-api-core";
import { ToastTypes, toastWrapper } from "services/ToastClient/toastWrapper";
import { checkEnvRelaunch } from "environmentsModule/utils/environmentRelaunch.helpers";
import { processFilesWithRethrow, applyConfigWithRethrow } from "services/Apis/wrappers/projects";
import { useEntityDataCustomColumns } from "src/hooks/api/projects/useEntityDataAndStats";
import { useGetSchemaData, useGetSchemaOptions } from "src/hooks/api/projects/useEntityFeatures";
import useAddFileQueryParam from "../../hooks/useAddFileQueryParam";
import { useParams } from "react-router-dom";
import { useProjectsStore } from "stores/zustand/stores";
import { useSourceContext } from "../../contexts/Source/useSourceContext";
import { useUploadContext } from "../../contexts/Upload/useUploadContext";

const OntologySection = () => {
  const { projectId, scenarioId } = useParams();

  const isAddFile = useAddFileQueryParam();

  const edaWorker = new WebWorker(worker);
  const queryClient = useQueryClient();

  const { sources } = useSourceContext();

  const {
    cancelApiTokenRef,
    createDataset,
    failFilesInSession,
    ontologyDatasetIndex,
    envRelaunchToastIdRef
  } = useUploadContext();

  const [setModifiedDatasetId] = useProjectsStore((state) => [state.setModifiedDatasetId]);

  // Stores - STARTS >>
  const {
    datasetDefaultDatasetStore,
    datasetSourceStore,
    datasetCriterionStore,
    datasetFilesStore,
    datasetDatasetsStore,
    setDatasetIsFetchingOntologyDataStore,
    datasetWatchOntologySchemaSetStore
  } = useStoreSelectors();

  // << ENDS - Stores

  const { getDatasetDatasetsSession } = useDatasetsSession();
  const token = api.getToken();

  // Files in session - STARTS >>
  const [filesTimer, setFilesTimer] = useState<$TSFixMe>(new Date().getTime());

  const { getDatasetFilesSession, setDatasetFilesSession } = useFilesSession({
    timer: filesTimer
  });
  // << ENDS - Files in session

  const {
    isDataSourcesFilesUpload,
    updateFilesSession,
    updateDatasetsSession,
    removeDatasetOntologySchemaSession
  } = useHelpers();

  // States - STARTS >>
  const [isConfigContainerExpanded, setIsConfigContainerExpanded] = useState<$TSFixMe>(false);
  const [isProcessingFiles, setIsProcessingFiles] = useState<$TSFixMe>();
  // << ENDS - States

  const dataset = useMemo(
    () => datasetDatasetsStore[ontologyDatasetIndex],
    [ontologyDatasetIndex, datasetDatasetsStore]
  );

  const getDatasetSession = () => getDatasetDatasetsSession()?.[ontologyDatasetIndex] || {};

  const isSchemaOptionsSupportedFileType = useMemo(
    () =>
      schemaOptionsSupportedFileTypes.includes(
        datasetFilesStore[ontologyDatasetIndex]?.type?.toLowerCase()
      ),
    [datasetFilesStore, ontologyDatasetIndex]
  );

  // Query hooks - STARTS >>
  const {
    isLoading: isFetchingDataset,
    isSuccess: isDatasetFetched,
    data: datasetData,
    refetch: refetchDataset
  } = useEntities({
    id: dataset?.id || getDatasetSession()?.id,
    projectId,
    cacheTime: Infinity,
    enabled: isSchemaOptionsSupportedFileType && (!!dataset?.id || !!getDatasetSession()?.id)
  });

  const {
    isLoading: isFetchingOntologySchemaOptions,
    data: ontologySchemaOptionsData,
    refetch: refetchOntologySchemaOptions
  } = useGetSchemaOptions({
    entityId: dataset?.id || getDatasetSession()?.id,
    cacheTime: Infinity,
    enabled: false
  });

  const {
    isFetching: isFetchingOntologySchema,
    isSuccess: isOntologySchemaFetched,
    data: ontologySchemaData,
    refetch: refetchOntologySchema
  } = useGetSchemaData({
    entityId: dataset?.id || getDatasetSession()?.id,
    onError: () => {
      failDatasetsInSession();
    },
    cacheTime: Infinity,
    enabled: false
  });

  const {
    isLoading: isFetchingOntologySampleData,
    isSuccess: isOntologySampleDataFetched,
    data: ontologySampleDataResponse,
    refetch: refetchOntologySampleData
  } = useGetEntityData(dataset?.id || getDatasetSession()?.id, scenarioId, "", {
    enabled: false
  });

  const ontologySampleData = useMemo(
    () => ontologySampleDataResponse?.data || { columns: [], rows: [] },
    [ontologySampleDataResponse]
  );
  const totalRowCount = useMemo(
    () => ontologySampleDataResponse?.entityDetails?.rows,
    [ontologySampleDataResponse]
  );

  const { mutateAsync: datasetDataCustomColumnsMutation } = useEntityDataCustomColumns({
    onSuccess: (datasetDataResponse) => {
      const updatedAllEntitiesData: EntityDataDto = {
        ...ontologySampleDataResponse,
        data: {
          ...ontologySampleDataResponse?.data,
          columns: concat(
            ontologySampleDataResponse?.data?.columns,
            datasetDataResponse?.data?.columns
          ),
          rows: map(ontologySampleDataResponse?.data?.rows, (row, index) => {
            if (datasetDataResponse?.data?.rows?.[index]) {
              row.cells = concat(row?.cells, datasetDataResponse?.data?.rows?.[index]?.cells);
            }
            return row;
          })
        }
      };

      queryClient.setQueryData(
        [QUERY_KEY_ENTITY_DATA, dataset?.id || getDatasetSession()?.id, scenarioId, ""],
        updatedAllEntitiesData
      );
    }
  });

  const [visibleColumns, setVisibleColumns] = useState<string[]>([]);
  const getDatasetCustomColumnsData = async (columnNames: string[]) => {
    if (isEmpty(ontologySampleData)) {
      return;
    }

    setVisibleColumns(() => columnNames);

    const newColumns = filter(columnNames, (col) => !includes(ontologySampleData?.columns, col));

    if (isEmpty(newColumns)) {
      return;
    }

    const payload = {
      entityId: dataset?.id || getDatasetSession()?.id,
      payload: {
        scenarioId: scenarioId!,
        rowsStart: 0,
        rowsEnd: size(ontologySampleData?.rows),
        cols: newColumns
      }
    };

    await datasetDataCustomColumnsMutation(payload);
  };

  useEffect(() => {
    if (!isAddFile) {
      let isFetching = false;

      if (!datasetDefaultDatasetStore?.id) {
        isFetching = isFetching || isFetchingOntologySchemaOptions;
        isFetching = isFetching || isFetchingOntologySchema;
      }

      isFetching = isFetching || isFetchingOntologySampleData;

      setDatasetIsFetchingOntologyDataStore(isFetching);
    }
  }, [
    isAddFile,
    datasetDefaultDatasetStore,
    isFetchingOntologySchemaOptions,
    isFetchingOntologySchema,
    isFetchingOntologySampleData
  ]);

  useEffect(() => {
    if (isDatasetFetched) {
      if (datasetData?.id) {
        // Update datasets in session >>
        const fields: $TSFixMe = {};
        fields[DatasetKeys.OntologyConfig] = {};
        fields[DatasetKeys.OntologyConfig][DatasetKeys.Separator] =
          datasetData?.entityMeta?.separator || separators[0].value;
        fields[DatasetKeys.OntologyConfig][DatasetKeys.Encoding] =
          datasetData?.entityMeta?.encoding || encodings[0].value;

        updateDatasetsSession({ index: ontologyDatasetIndex, fields: fields });
        // << Update datasets in session
      }
    }
  }, [isDatasetFetched, datasetData]);
  // << ENDS - Query hooks

  const failDatasetsInSession = () => {
    // Update datasets in session >>
    const fields: $TSFixMe = {};
    fields[DatasetKeys.OntologyConfig] = {};
    fields[DatasetKeys.OntologyConfig][DatasetKeys.Status] = OntologyDatasetStatuses.Failed;

    updateDatasetsSession({ index: ontologyDatasetIndex, fields });
    // << Update datasets in session
  };

  // Process files - STARTS >>
  const processFiles = async () => {
    let thisDatasetFilesSession = getDatasetFilesSession();

    const targetIndices: number[] = [];
    if (datasetCriterionStore?.value === Criteria.Segregate) {
      targetIndices.push(ontologyDatasetIndex);
    } else {
      (thisDatasetFilesSession || [])?.forEach((eachFile: $TSFixMe, index: number) => {
        if (eachFile?.status === FileUploadStatuses.SignedUrl) {
          targetIndices.push(index);
        }
      });
    }

    const fileNamesToProcess = (thisDatasetFilesSession || [])
      ?.filter(
        (eachFile: $TSFixMe, index: number) =>
          targetIndices.includes(index) && eachFile?.status === FileUploadStatuses.SignedUrl
      )
      ?.map((eachFile: $TSFixMe) => eachFile?.name);

    if (fileNamesToProcess?.length === 0) {
      return;
    }

    // Update files in session >>
    thisDatasetFilesSession = (thisDatasetFilesSession || [])?.map(
      (eachFile: $TSFixMe, index: number) => {
        const fields: $TSFixMe = {};

        if (targetIndices.includes(index) && eachFile?.status === FileUploadStatuses.SignedUrl) {
          fields[FileMetaDataKeys.Status] = FileUploadStatuses.Processing;
        }

        return {
          ...eachFile,
          ...fields
        };
      }
    );

    setDatasetFilesSession(thisDatasetFilesSession);
    setFilesTimer(() => new Date().getTime());
    // << Update files in session

    try {
      setIsProcessingFiles(() => true);

      await processFilesWithRethrow({
        params: { entityId: dataset?.id || getDatasetSession()?.id },
        options: {
          params: {
            fileName: `${fileNamesToProcess}`,
            isAppend: datasetCriterionStore?.value === Criteria.Append
          },
          cancelToken: cancelApiTokenRef.current
        }
      });

      toastWrapper({
        type: ToastTypes.Success,
        content: `${!!isAddFile ? capitalize(DatasetHelperText.TextInput) : DatasetHelperText.Dataset} ${dataset?.name} created successfully`
      });

      if (datasetCriterionStore?.value === Criteria.Append) {
        setModifiedDatasetId(dataset?.id || getDatasetSession()?.id);
      }

      // Update files in session >>
      thisDatasetFilesSession = getDatasetFilesSession();

      thisDatasetFilesSession = (thisDatasetFilesSession || [])?.map(
        (eachFile: $TSFixMe, index: number) => {
          const fields: $TSFixMe = {};

          if (targetIndices.includes(index) && eachFile?.status === FileUploadStatuses.Processing) {
            fields[FileMetaDataKeys.Status] = FileUploadStatuses.Success;
            fields[FileMetaDataKeys.UploadProgress] = 100;
          }

          return {
            ...eachFile,
            ...fields
          };
        }
      );

      setDatasetFilesSession(thisDatasetFilesSession);
      setFilesTimer(() => new Date().getTime());
      // << Update files in session

      // setReloadTrigger();
    } catch (error: $TSFixMe) {
      // Update files in session >>
      thisDatasetFilesSession = getDatasetFilesSession();

      thisDatasetFilesSession = (thisDatasetFilesSession || [])?.map(
        (eachFile: $TSFixMe, index: number) => {
          const fields: $TSFixMe = {};

          if (targetIndices.includes(index) && eachFile?.status === FileUploadStatuses.Processing) {
            fields[FileMetaDataKeys.Status] = FileUploadStatuses.SignedUrl;
          }

          return {
            ...eachFile,
            ...fields
          };
        }
      );

      setDatasetFilesSession(thisDatasetFilesSession);
      setFilesTimer(() => new Date().getTime());
      // << Update files in session

      throw error;
    } finally {
      setIsProcessingFiles(() => false);
    }
  };
  // << ENDS - Process files

  const applyConfig = async ({ isAppliedConfig = true } = {}) => {
    // Update datasets in session >>
    const fields: $TSFixMe = {};
    fields[DatasetKeys.OntologyConfig] = {};
    fields[DatasetKeys.OntologyConfig][DatasetKeys.Status] = OntologyDatasetStatuses.Active;

    updateDatasetsSession({ index: ontologyDatasetIndex, fields });
    // << Update datasets in session

    setDatasetIsFetchingOntologyDataStore(true);

    // Resetting ontology schema
    removeDatasetOntologySchemaSession();

    let isCreateDataset = false;
    // Keeping nested ifs for a better readability.
    if (isDataSourcesFilesUpload) {
      if (!(dataset?.id || getDatasetSession()?.id)) {
        isCreateDataset = true;
      }
    } else {
      if (!(dataset?.id || getDatasetSession()?.id)) {
        setDatasetIsFetchingOntologyDataStore(false);
        return;
      }
    }

    try {
      let separator = null;
      let encoding = null;

      if (!isAddFile && !!isSchemaOptionsSupportedFileType) {
        separator = dataset?.ontologyConfig?.separator || separators[0].value;
        encoding = dataset?.ontologyConfig?.encoding || encodings[0].value;
      }

      let config = {};
      if (!!isAddFile) {
        config = { entityMeta: { entityViewType: EntityMetaDtoEntityViewTypeEnum.File } };
      } else {
        if (!!isAppliedConfig) {
          config = { entityMeta: { separator, encoding }, autodetectionDisable: true };
        }
      }

      const requestJson = {
        id: dataset?.id || getDatasetSession()?.id || "",
        ...config
      };

      if (!envRelaunchToastIdRef.current) {
        envRelaunchToastIdRef.current = checkEnvRelaunch(projectId);
      }

      let canApplyConfig = isAppliedConfig;

      if (isCreateDataset) {
        try {
          setIsProcessingFiles(() => true);

          const isFiveTran =
            get(find(sources, { id: datasetSourceStore?.value }), "isFivetran") ?? false;

          const filePath = datasetFilesStore[ontologyDatasetIndex];
          const file = isEmpty(filePath?.payloadPath) ? filePath?.file : filePath?.payloadPath;
          const thisRequestJson = {
            ...requestJson,
            name: datasetDatasetsStore[ontologyDatasetIndex]?.name,
            dataSourceId: datasetSourceStore?.value,
            dataSourceOptions: { [isFiveTran ? "probableDataset" : "filePath"]: file }
          };

          const entityResponse = await createDataset(thisRequestJson);
          if (isFiveTran) {
            toastWrapper({
              type: ToastTypes.Success,
              content: `${DatasetHelperText.Dataset} ${dataset?.name} created successfully`
            });
          }

          if (entityResponse?.id) {
            canApplyConfig = false;

            // Update files in session >>
            const fileFields: $TSFixMe = {};
            fileFields[FileMetaDataKeys.Status] = FileUploadStatuses.Success;
            fileFields[FileMetaDataKeys.UploadProgress] = 100;
            fileFields[FileMetaDataKeys.EntityId] = entityResponse?.id;

            updateFilesSession({ index: ontologyDatasetIndex, fields: fileFields });
            // << Update files in session

            // Update datasets in session >>
            const datasetFields: $TSFixMe = {};
            datasetFields[DatasetKeys.Id] = entityResponse?.id;
            datasetFields[DatasetKeys.IsDirty] = false;

            if (isSchemaOptionsSupportedFileType) {
              datasetFields[DatasetKeys.OntologyConfig] = {};
              datasetFields[DatasetKeys.OntologyConfig][DatasetKeys.Separator] =
                entityResponse?.entityMeta?.separator || separator || separators[0].value;
              datasetFields[DatasetKeys.OntologyConfig][DatasetKeys.Encoding] =
                entityResponse?.entityMeta?.encoding || encoding || encodings[0].value;
            }

            updateDatasetsSession({ index: ontologyDatasetIndex, fields: datasetFields });
            // << Update datasets in session
          } else {
            failFilesInSession({ index: ontologyDatasetIndex });
            failDatasetsInSession();

            setDatasetIsFetchingOntologyDataStore(false);
            setIsProcessingFiles(() => false);

            return;
          }
        } catch {
          failFilesInSession({ index: ontologyDatasetIndex });
          failDatasetsInSession();

          return;
        } finally {
          setDatasetIsFetchingOntologyDataStore(false);
          setIsProcessingFiles(() => false);
        }
      }

      if (canApplyConfig) {
        const entityResponse = await applyConfigWithRethrow(requestJson);

        if (entityResponse?.id) {
          // Update datasets in session >>
          fields[DatasetKeys.IsDirty] = false;

          if (isSchemaOptionsSupportedFileType) {
            fields[DatasetKeys.OntologyConfig][DatasetKeys.Separator] =
              entityResponse?.entityMeta?.separator || separator || separators[0].value;
            fields[DatasetKeys.OntologyConfig][DatasetKeys.Encoding] =
              entityResponse?.entityMeta?.encoding || encoding || encodings[0].value;
          }

          updateDatasetsSession({ index: ontologyDatasetIndex, fields: fields });
          // << Update datasets in session
        }
      }

      await processFiles();

      if (!canApplyConfig) {
        if (!isAddFile && !!isSchemaOptionsSupportedFileType) {
          if (!!dataset?.id || !!getDatasetSession()?.id) {
            refetchDataset();
          }
        }
      }

      if (!isAddFile) {
        const domain = window.location.origin;
        const datasetFilesSession = getDatasetFilesSession();
        datasetFilesSession.map((file: any) => {
          const entityId = file?.entityId;
          entityId &&
            scenarioId &&
            // @ts-ignore
            edaWorker?.postMessage({
              entityId,
              scenarioId,
              authToken: token,
              domain
            });
        });
      }
    } catch {
      failDatasetsInSession();
      setDatasetIsFetchingOntologyDataStore(false);

      return;
    }

    setDatasetIsFetchingOntologyDataStore(false);

    if (!isAddFile) {
      if (datasetDefaultDatasetStore?.id) {
        await refetchOntologySampleData();
      } else {
        await refetchOntologySchemaOptions();
        await refetchOntologySchema();
        await refetchOntologySampleData();
      }
    }
  };

  useEffect(() => {
    const _ = async () => {
      await applyConfig({ isAppliedConfig: false });
    };

    _();
  }, [ontologyDatasetIndex]);

  const fetchDatasetResources = async () => {
    await refetchDataset();
    await refetchOntologySchema();
    await refetchOntologySampleData();
  };

  useEffect(() => {
    const _ = async () => {
      const payload = {
        entityId: dataset?.id || getDatasetSession()?.id,
        payload: {
          scenarioId: scenarioId!,
          rowsStart: 0,
          rowsEnd: size(ontologySampleData?.rows),
          cols: isEmpty(visibleColumns) ? ontologySampleData?.columns : visibleColumns
        }
      };

      await Promise.all([refetchOntologySchema(), datasetDataCustomColumnsMutation(payload)]);
    };

    !!isOntologySchemaFetched && !!isOntologySampleDataFetched && _();
  }, [datasetWatchOntologySchemaSetStore]);

  return (
    <>
      <ViewUploadedContainer
        isAddFile={isAddFile}
        ontologyDatasetIndex={ontologyDatasetIndex}
        isConfigContainerExpanded={isConfigContainerExpanded}
        setIsConfigContainerExpanded={setIsConfigContainerExpanded}
        isSchemaOptionsSupportedFileType={isSchemaOptionsSupportedFileType}
        isFetchingOntologySchema={isFetchingOntologySchema}
        ontologySchemaData={ontologySchemaData}
        isFetchingDataset={isFetchingDataset}
        datasetData={datasetData}
        fetchDatasetResources={fetchDatasetResources}>
        {!isAddFile && isSchemaOptionsSupportedFileType && isConfigContainerExpanded && (
          <ConfigContainer
            datasetDefaultDatasetStore={datasetDefaultDatasetStore}
            ontologyDatasetIndex={ontologyDatasetIndex}
            applyConfig={applyConfig}
          />
        )}
      </ViewUploadedContainer>

      <OntologyContainer
        isAddFile={isAddFile}
        totalRowCount={totalRowCount}
        isDataSourcesFilesUpload={isDataSourcesFilesUpload}
        ontologyDatasetIndex={ontologyDatasetIndex}
        dataset={dataset}
        datasetId={dataset?.id || getDatasetSession()?.id || datasetDefaultDatasetStore?.id}
        isConfigContainerExpanded={isConfigContainerExpanded}
        ontologySampleData={ontologySampleData}
        isProcessingFiles={isProcessingFiles}
        isFetchingOntologySchema={isFetchingOntologySchema}
        isFetchingOntologySampleData={isFetchingOntologySampleData}
        ontologySchemaOptionsData={ontologySchemaOptionsData}
        ontologySchemaData={ontologySchemaData}
        getDatasetCustomColumnsData={getDatasetCustomColumnsData}
        visibleColumns={visibleColumns}
      />
    </>
  );
};

export default OntologySection;
