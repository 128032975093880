import React, { useMemo } from "react";
import _ from "lodash";
import { Rating } from "@material-ui/lab";
import { Star } from "@material-ui/icons";
import { makeStyles, Tooltip } from "@material-ui/core";

import useMarkProjectAsFavorite from "src/hooks/useMarkProjectAsFavorite";

const useStyles = makeStyles({
  rating: {
    height: "25px",
    "& label": {
      marginBottom: 0
    },
    "&:hover span": {
      transform: "scale(1.0) !important"
    },
    "&:active span": {
      transform: "scale(1.0) !important"
    }
  }
});

interface IProps {
  value: boolean;
  projectId: string;
  isHovered?: boolean;
  className?: string;
  style?: React.CSSProperties;
  onChange?: (isFavourite: boolean) => void;
}

const MarkFavorite: React.FC<IProps> = (props) => {
  const { value, style, className, projectId, isHovered, onChange } = props;
  const rating = value ? 1 : null;
  const classes = useStyles();
  const markFavorite = useMarkProjectAsFavorite();

  const handleChange = (__: React.ChangeEvent<{}>, newValue: number | null) => {
    if (markFavorite.isLoading) {
      return;
    }

    markFavorite.mutate(
      {
        projectId,
        isFavorite: !!newValue
      },
      {
        onSuccess: () => {
          onChange?.(!!newValue);
        }
      }
    );
  };

  const isFav = rating === 1;

  const tooltip = useMemo(() => {
    if (markFavorite.isLoading) {
      return "Please wait while the current update operation completes";
    }

    if (isFav) {
      return "Remove from Favorites";
    }

    return "Mark as Favorite";
  }, [isFav, markFavorite.isLoading]);

  return (
    <Tooltip placement="top" title={tooltip}>
      <span>
        <Rating
          data-testid={`favouriteProject-${projectId}`}
          size="small"
          style={{
            ...style,
            display: !!value || isHovered ? "block" : "none",
            cursor: markFavorite.isLoading ? "not-allowed" : undefined
          }}
          className={`${classes.rating} ${!!value ? "" : className}`}
          value={rating}
          name={projectId}
          max={1}
          icon={
            <Star
              style={{
                color: isFav ? "#ff5d00" : "transparent",
                stroke: isFav ? "white" : "#4646b5",
                strokeWidth: 1,
                width: isFav ? undefined : "20px",
                height: isFav ? undefined : "20px",
                marginRight: isFav ? undefined : "2px",
                marginTop: isFav ? undefined : "1px"
              }}
            />
          }
          emptyIcon={
            <Star
              style={{
                color: "transparent",
                stroke: "#4646b5",
                strokeWidth: -1,
                width: "20px",
                height: "20px",
                marginRight: isFav ? undefined : "2px",
                marginTop: isFav ? undefined : "1px"
              }}
            />
          }
          onChange={handleChange}
          onClick={(e) => e.stopPropagation()}
        />
      </span>
    </Tooltip>
  );
};

export default MarkFavorite;
