import React from "react";

// MUI
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    color: theme.palette.text.secondary,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
  }
}));

type Props = {
  buttonLabel: string;
  toggleShowMore: () => void;
};

const Footer = (props: Props) => {
  const { buttonLabel, toggleShowMore } = props || {};

  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Button size="small" onClick={toggleShowMore} data-testid="recipeRunsFooterAction">
        <Typography
          variant="caption"
          color="textSecondary"
          style={{ fontWeight: 400 }}
          data-testid="recipeRunsFooterActionLabel">
          {buttonLabel}
        </Typography>
      </Button>
    </Box>
  );
};

export default Footer;
