import React, { useEffect, useMemo, useState } from "react";

// Packages
import MonacoEditor from "react-monaco-editor";
import { get, isEmpty, toLower, toUpper, startsWith, isNil, some } from "lodash";

// MUI
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

// Open API
import {
  DownloadEntityDtoFileTypeEnum,
  EntityDetailsDto,
  EntityDto
} from "@rapidcanvas/rc-api-core";

// Utils
import { mimeTypes } from "../utils";

// Hooks
import { useDownloadDatasetFile, usePreviewDatasetFile } from "src/hooks/api";
import useEntities from "src/hooks/api/entities/useEntities";
import useEntityDetails from "src/hooks/api/entities/useEntityDetails";

// Components
import CommonLoader from "src/components/CommonLoader";
import MarkdownDisplay from "src/components/MarkdownDisplay";
import JsonEditor from "src/components/editor/JsonEditor";
import Result from "src/components/Errors/Result";
import SomethingWentWrong from "src/components/Errors/SomethingWentWrong";

// Constants
import fileTypes, { FileTypeKeys } from "constants/fileTypes.constants";
import { ViewFileConfig, ViewFileHelperText } from "../pages/ViewFile/utils/ViewFile.constants";

export const useStyles = makeStyles((theme) => ({
  monacoEditorStyles: {
    height: "inherit",
    marginLeft: -theme.spacing(2)
  },
  // @TODO: Refactoring the PrettyJsonEditor component would eliminate the need for the custom styling below.
  jsonEditor: {
    width: "100% !important",
    border: "1px solid rgba(0, 0, 0, 0.2)",
    borderRadius: theme.spacing(1),
    "& .ace_print-margin": {
      visibility: "hidden !important"
    }
  }
}));

interface IEditorProps {
  height?: string;
}
interface IProps {
  projectId?: string;
  scenarioId?: string;
  fileId?: string;
  jobRunId?: string;
  data?: EntityDto | any; // @TODO: Replace any with IEntity after migration.
  fileDetails?: EntityDetailsDto | any; // @TODO: Replace any with IEntityDetails after migration.
  isLoadingFileDetails?: boolean;
  editorProps?: IEditorProps;
}

const Data: React.FC<IProps> = (props: IProps) => {
  const {
    projectId,
    scenarioId,
    fileId,
    jobRunId,
    data: dataProp,
    fileDetails: fileDetailsProp,
    isLoadingFileDetails: isLoadingFileDetailsProp,
    editorProps
  } = props;

  const classes = useStyles();

  const [isPreviewing, setIsPreviewing] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);

  // Query hooks - STARTS >>
  const {
    isLoading: isLoadingFileEnforced,
    data: dataEnforced,
    refetch: refetchFileEnforced
  } = useEntities({
    id: fileId!,
    projectId,
    scenarioId,
    jobRunId,
    enabled: false
  });

  useEffect(() => {
    if (isNil(dataProp)) {
      refetchFileEnforced();
    }
  }, [dataProp]);

  const data = useMemo(() => dataProp ?? dataEnforced, [dataProp, dataEnforced]);

  const {
    isLoading: isLoadingFileDetailsEnforced,
    data: fileDetailsEnforced,
    refetch: refetchFileDetailsEnforced
  } = useEntityDetails(fileId, scenarioId, jobRunId, {
    enabled: false
  });

  useEffect(() => {
    if (isNil(fileDetailsProp)) {
      refetchFileDetailsEnforced();
    } else {
      if (!fileDetailsProp?.fileExtension) {
        setIsPreviewing(() => false);
      }
    }
  }, [fileDetailsProp]);

  const fileDetails = useMemo(
    () => fileDetailsProp ?? fileDetailsEnforced,
    [fileDetailsProp, fileDetailsEnforced]
  );

  const fileName = useMemo(() => data?.displayName || data?.name, [data]);
  const fileExtension = useMemo(
    () => toLower(fileDetails?.fileExtension),
    [fileDetails?.fileExtension]
  );
  const contentType = useMemo(() => get(mimeTypes, fileExtension, ""), [fileExtension]);

  const {
    data: previewData,
    isError,
    refetch
  } = usePreviewDatasetFile({
    fileId,
    fileName,
    fileType: fileExtension,
    payload: {
      scenarioId,
      projectRunEntryId: jobRunId,
      fileType: toUpper(fileExtension) as DownloadEntityDtoFileTypeEnum,
      downloadRaw: true
    },
    onSettled: () => {
      setIsPreviewing(() => false);
    },
    enabled: false
  });

  const { refetch: refetchDownloadDatasetFile } = useDownloadDatasetFile({
    fileId,
    fileName,
    fileType: fileExtension,
    payload: {
      scenarioId,
      projectRunEntryId: jobRunId,
      fileType: toUpper(fileExtension) as DownloadEntityDtoFileTypeEnum,
      downloadRaw: true
    },
    enabled: false
  });

  useEffect(() => {
    if (!!fileId && !!fileName && !!fileExtension) {
      refetch();
    }
  }, [fileId, fileName, fileExtension]);
  // << ENDS - Query hooks

  const isLoading = useMemo(() => {
    let tempIsLoading = false;

    if (isNil(dataProp)) {
      tempIsLoading = tempIsLoading || isLoadingFileEnforced;
    }

    if (isNil(fileDetailsProp)) {
      tempIsLoading = tempIsLoading || isLoadingFileDetailsEnforced;
    }

    return !!isLoadingFileDetailsProp || !!isPreviewing;
  }, [
    dataProp,
    isLoadingFileEnforced,
    fileDetailsProp,
    isLoadingFileDetailsEnforced,
    isLoadingFileDetailsProp,
    isPreviewing
  ]);

  const downloadDatasetFile = async () => {
    setIsDownloading(() => true);

    try {
      await refetchDownloadDatasetFile();
    } finally {
      setIsDownloading(() => false);
    }
  };

  if (isLoading) {
    return <CommonLoader />;
  }

  if (isError) {
    return (
      <SomethingWentWrong
        hideMargin
        heading="Something went wrong"
        subtitle1="Error while loading the data."
      />
    );
  }

  if (isEmpty(previewData)) {
    return (
      <Box p={4}>
        <Grid container alignItems="center" justifyContent="center">
          <Alert severity="info" style={{ width: "25%", justifyContent: "center" }}>
            No data found!
          </Alert>
        </Grid>
      </Box>
    );
  } else {
    if (previewData === ViewFileConfig.MaxFileSizeExceededError) {
      return (
        <Result
          heading={`Preview is unavailable for files larger than ${ViewFileConfig.MaxFileSize}MB`}
          subtitle1="Kindly open the downloaded file to view its contents"
          icon={<></>}
          extra={
            <Button
              size="small"
              variant="contained"
              disabled={!!isDownloading}
              startIcon={
                !!isDownloading ? (
                  <CircularProgress style={{ color: "#fff" }} size={16} />
                ) : undefined
              }
              onClick={() => downloadDatasetFile()}>
              {!!isDownloading ? ViewFileHelperText.Downloading : ViewFileHelperText.Download}
            </Button>
          }
        />
      );
    }

    if (fileExtension === FileTypeKeys.Json) {
      return (
        <JsonEditor
          jsonString={previewData}
          className={classes.jsonEditor}
          height={editorProps?.height || "100%"}
          readOnly
        />
      );
    }

    if (fileExtension === FileTypeKeys.Md) {
      return (
        <MarkdownDisplay
          string={previewData || ""}
          style={{ height: editorProps?.height || "100%" }}
        />
      );
    }

    if (some(fileTypes[FileTypeKeys.Html].mimeTypes, (type) => startsWith(contentType, type))) {
      return (
        <iframe
          src={previewData}
          style={{
            width: "100%",
            height: editorProps?.height || "90%",
            border: "none",
            boxShadow: "none"
          }}
          title="Preview"
        />
      );
    }

    if (startsWith(contentType, "text/")) {
      const language = get(
        fileTypes,
        [fileDetails?.fileExtension, "monacoEditorLanguageValue"],
        ""
      );

      return (
        <MonacoEditor
          language={language ?? fileTypes[FileTypeKeys.Txt].monacoEditorLanguageValue}
          height={editorProps?.height || "100%"}
          width="100%"
          theme="vs"
          value={previewData}
          className={classes.monacoEditorStyles}
          options={{
            readOnly: true,
            fontSize: 12,
            minimap: { enabled: false },
            renderLineHighlight: "none",
            lineNumbers: "off",
            scrollBeyondLastLine: false,
            wordWrap: "on"
          }}
        />
      );
    }
  }

  return <p>Unsupported file type: {fileName}</p>;
};

export default Data;
