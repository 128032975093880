import React, { useMemo, useState } from "react";

// Packages
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { includes, isEmpty, size } from "lodash";

// MUI
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { Button } from "@material-ui/core";

// Icons
import { TrashIcon } from "icons/NewUX/TrashIcon";
import ApiConnectorIcon from "icons/NewUX/ApiConnectorIcon";

// Utils
import { areAllKeysPresentAndNotNil } from "src/utils/helpers";
import { WebPaths } from "src/routing/routes";
import { RecipeTypeNames, DatasetStatuses } from "src/pages/private/ProjectsModule/utils";

// Hooks

// Components
import DeleteNodeModal, { psMessage } from "src/pages/ViewData/DeleteNodeModal/DeleteNodeModal";
import Modal, { ModalVariants } from "src/components/custom/Modal/Modal";
import ReloadDatasetModal from "src/pages/ViewData/ViewDataData/ReloadDatasetModal";
import DeleteNodesModal from "../../DeleteNodes/DeleteNodesModal";

// Constants
import { thirdPartyTypeName } from "src/pages/DataSources/utils/DataSources.constants";

// Types
import { NodeData } from "src/types";
import { useContextStyles } from "../useContextMenuStyles";

type Props = {
  open: boolean;
  closeContextMenu: () => void;
  isDefaultScenario: boolean;
  data: NodeData;
  selectedNodes: NodeData[];
  resetSelectedNodes: () => void;
  children?: React.ReactNode;
};

const ContextMenu = (props: Props) => {
  const { closeContextMenu, isDefaultScenario, data, selectedNodes, resetSelectedNodes, children } =
    props || {};

  const navigate = useNavigate();
  const { projectId, scenarioId } = useParams();

  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [showConfirmDeleteNodesModal, setShowConfirmDeleteNodesModal] = useState(false);

  const classes = useContextStyles();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [reloadOpen, setReloadOpen] = useState(false);

  const isBuilt = useMemo(() => ["BUILT"].includes(data?.status), [data?.status]);

  const isAddRecipeDisabled = useMemo(() => {
    return !data?.status || !includes([DatasetStatuses.Built], data?.status);
  }, [data?.status]);

  const isNavigableToRecipePage = useMemo(
    () => areAllKeysPresentAndNotNil(data, ["projectId", "scenarioId", "id"]),
    [data?.projectId, data?.scenarioId, data?.id]
  );

  const onAddDatasetAction = () => {
    if (!areAllKeysPresentAndNotNil(data, ["projectId", "scenarioId", "id"])) {
      return;
    }

    navigate(`/projects/${data?.projectId}/scenario/${data?.scenarioId}/dataset/${data?.id}`);
  };

  const handleAddApiConnectorRecipe = () => {
    const url = `${WebPaths.APIConnectorRecipeContainer}?entity=${data?.id}`;
    navigate(
      generatePath(url, {
        projectId: data?.projectId,
        scenarioId: data?.scenarioId
      })
    );
  };

  const onDelete = () => {
    setIsDeleteModalOpen(true);
  };

  const handleCancel = () => {
    setOpenConfirmModal(false);
  };

  // Delete nodes - STARTS >>
  const promptConfirmDeleteNodes = () => {
    setShowConfirmDeleteNodesModal(() => true);
  };

  const resetConfirmDeleteNodes = () => {
    setShowConfirmDeleteNodesModal(() => false);
  };
  // << ENDS - Delete nodes

  const buttonComponents = useMemo(() => {
    let items: React.ReactNode[] = [];

    if (!!data?.isJobCanvas) {
      if (!data?.isJobCanvasPath) {
        return [];
      }
    }

    if (size(selectedNodes) > 1) {
      items.push(
        <Button
          size="small"
          key="delete"
          data-testid="vectorDbContextMenuDeleteSelected"
          startIcon={<TrashIcon viewBox="0 0 20 22" />}
          onClick={promptConfirmDeleteNodes}>
          Delete Selected
        </Button>
      );

      return items;
    }

    if (!!isDefaultScenario && isBuilt) {
      items.push(
        <Button
          key={RecipeTypeNames.ApiConnector}
          size="small"
          data-testid={`${RecipeTypeNames.ApiConnector} Recipe`}
          className="context-menu-border"
          startIcon={<ApiConnectorIcon />}
          {...(!!isNavigableToRecipePage
            ? {
                onClick: handleAddApiConnectorRecipe
              }
            : {})}>
          {RecipeTypeNames.ApiConnector} Recipe
        </Button>
      );
    }

    if (!!isDefaultScenario) {
      items.push(
        <Button
          size="small"
          key="delete"
          data-testid="Delete"
          startIcon={<TrashIcon viewBox="0 0 20 22" />}
          onClick={onDelete}>
          Delete
        </Button>
      );
    }

    return items;
  }, [
    selectedNodes,
    data?.isJobCanvas,
    data?.isJobCanvasPath,
    isDefaultScenario,
    isBuilt,
    isAddRecipeDisabled,
    isNavigableToRecipePage
  ]);

  return (
    <>
      {!!showConfirmDeleteNodesModal && (
        <DeleteNodesModal
          projectId={projectId}
          scenarioId={scenarioId}
          selectedNodes={selectedNodes}
          resetSelectedNodes={resetSelectedNodes}
          resetConfirmDeleteNodes={resetConfirmDeleteNodes}
        />
      )}

      {!!isDeleteModalOpen && (
        <DeleteNodeModal
          open
          nodeId={data?.id}
          nodeName={data?.label}
          nodeType="entity"
          deleteNote={
            <div>
              <span>Note: </span>
              {psMessage}
            </div>
          }
          onDeleteSuccess={resetSelectedNodes}
          onClose={() => {
            setIsDeleteModalOpen(false);
          }}
          onAfterSubmit={closeContextMenu}
        />
      )}
      {reloadOpen && (
        <ReloadDatasetModal
          id={data?.itemId}
          name={data?.label}
          projectId={data?.projectId}
          open={reloadOpen}
          isFiveTran={data?.entityDSDetails?.type === thirdPartyTypeName}
          onCancel={() => setReloadOpen(false)}
          onSuccess={() => setReloadOpen(false)}
        />
      )}

      {(React.isValidElement(children) || !isEmpty(buttonComponents)) && (
        <ButtonGroup variant="text" size="small" orientation="vertical" className={classes.root}>
          {!isEmpty(buttonComponents) && buttonComponents}
          {children}
        </ButtonGroup>
      )}

      {openConfirmModal && (
        <Modal
          open
          variant={ModalVariants.Delete}
          title="Add File"
          content={[
            "If you make any changes to the dataset, it will render the current runs of the associated recipe(s) with this input dataset invalid, marking them as UNBUILT. To implement the changes, it is necessary to rerun the linked recipe(s). Also the associated segment(if any) will be deleted, and any custom scenarios using it will default to the entire dataset instead of the segment.",
            "Are you sure you want to proceed with this?"
          ]}
          submitLabel="Yes, Proceed"
          onClose={handleCancel}
          onSubmit={onAddDatasetAction}
        />
      )}
    </>
  );
};

export default ContextMenu;
