import React from "react";

// MUI
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";

// Constants
import { EnvironmentConfigHelperText } from "./EnvironmentConfig.constants";

// Styles
import { useStyles as useSubtopBarStyles } from "src/components/SubtopBar/styling";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    columnGap: theme.spacing(1),
    padding: "10px 20px",
    zIndex: 1,
    backgroundColor: "#d9d9d9"
  }
}));

interface IProps {
  disabledCancelActionMessage: string;
  disabledSaveActionMessage: string;
  updateEnvironment: () => void;
  onClose: () => void;
}

const Footer: React.FC<IProps> = (props) => {
  const { disabledCancelActionMessage, disabledSaveActionMessage, updateEnvironment, onClose } =
    props;

  const { barMainButton, actionButton } = useSubtopBarStyles();
  const classes = useStyles();

  return (
    <Grid container component="footer" justifyContent="flex-end" className={classes.root}>
      <Tooltip title={disabledCancelActionMessage} data-testid="envConfigCancelActionInfo">
        <span>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            className={`${barMainButton} ${actionButton}`}
            disabled={!!disabledCancelActionMessage}
            onClick={() => onClose()}
            data-testid="envConfigCancelAction">
            {EnvironmentConfigHelperText.Cancel}
          </Button>
        </span>
      </Tooltip>
      <Tooltip title={disabledSaveActionMessage} data-testid="envConfigSaveActionInfo">
        <span>
          <Button
            variant="contained"
            color="primary"
            size="small"
            disabled={!!disabledSaveActionMessage}
            onClick={() => updateEnvironment()}
            data-testid="envConfigSaveAction">
            {EnvironmentConfigHelperText.Save}
          </Button>
        </span>
      </Tooltip>
    </Grid>
  );
};

export default Footer;
