import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Grid, makeStyles } from "@material-ui/core";
import { useLocation } from "react-router-dom";

import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import { Tabs, Tab, TabPanel } from "src/components";
import { useProjectContext } from "src/pages/private/ProjectsModule/context/useProjectContext";
import PredictionsRuns from "../../PredictionRuns";
import SubTopNavBarBreadcrumbs from "./SubTopNavBarBreadcrumbs";

const useStyles = makeStyles((theme) => ({
  wrapper: { marginLeft: 60, padding: theme.spacing(2) }
}));

export const TAB = "tab";

export enum SCHEDULER_TABS {
  Scheduler = "Scheduler",
  PredictionScheduler = "Prediction Scheduler",
  ManualPrediction = "Manual Prediction"
}

const Schedulers: React.FC = () => {
  const [value, setValue] = useState(SCHEDULER_TABS.ManualPrediction);

  const { project } = useProjectContext();
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    const tab = _.get(location.state, TAB);
    if (tab) {
      setValue(tab);
    }
  }, []);

  return (
    <>
      <SubTopNavBarWrapper
        subTopNavBarLeftSection={{
          component: <SubTopNavBarBreadcrumbs project={project} tab={value} />
        }}
      />
      <Grid className={classes.wrapper}>
        <Grid>
          <Tabs
            value={value}
            onChange={setValue}
            textColor="primary"
            indicatorColor="primary"
            variant="scrollable">
            {_.map([SCHEDULER_TABS.ManualPrediction, SCHEDULER_TABS.PredictionScheduler], (tab) => (
              <Tab key={tab} value={tab} label={tab} />
            ))}
          </Tabs>
        </Grid>

        <TabPanel value={value} index={SCHEDULER_TABS.ManualPrediction}>
          <PredictionsRuns isManual={true} />
        </TabPanel>
        <TabPanel value={value} index={SCHEDULER_TABS.PredictionScheduler}>
          <PredictionsRuns isManual={false} />
        </TabPanel>
      </Grid>
    </>
  );
};

export default Schedulers;
