import React, { useMemo, useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";

import useGetRecipeLogs from "src/hooks/useGetRecipeLogs";
import { LogsContent } from "./LogsContent";
import { RecipeStatuses } from "projectsModule/utils/Projects.constants";
import { ToastifyAlert } from "src/components/ToastifyAlert/ToastifyAlert";
import { getStatusEquivalence, ShowLogsDrawerTitle } from "./ShowLogsDrawerTitle";
import { sideComponentSetter } from "stores/zustand/stores.selectors";
import { useDrawerStore } from "stores/zustand/stores";
import { GroupLogDtoStatusEnum } from "@rapidcanvas/rc-api-core";
import { isEmpty } from "lodash";
import useExportRecipeLogs from "../useExportRecipeLogs";
import { checkEnvRelaunch } from "environmentsModule/utils/environmentRelaunch.helpers";

export const TEMP_MSG = ["Fetching logs. Please wait..."];
export const UNBUILT_MSG = "Logs are not available because the recipe hasn't been executed yet.";

export const useStyles = makeStyles({
  autoMlLogs: {
    flexWrap: "nowrap",
    width: "600px"
  },
  logStatuses: {
    gap: "16px",
    background: "#1e1e1e",
    padding: "8px 8px 0px 8px"
  },
  logStatusWithoutBckg: {
    gap: "16px",
    padding: "8px 8px 0px 8px"
  }
});

export const renderInfo = ({ message, type }: $TSFixMe) => {
  return (
    <Grid
      container
      item
      xs={12}
      style={{
        padding: 16,
        display: "inline-block"
      }}>
      <ToastifyAlert type={type} message={message} />
    </Grid>
  );
};

const isRunRecipeLogs = true;
// NOTE: Use RecipeLogsDrawer instead of this component

export const RecipeLogs = ({ projectId, scenarioId, groupId, isJobPath, jobRunId }: $TSFixMe) => {
  const classes = useStyles();
  const exportRecipeLogs = useExportRecipeLogs();
  const [searchValue, setSearchValue] = useState("");
  const { data, isLoading } = useGetRecipeLogs({
    scenarioId,
    groupId,
    showAllLogs: true,
    isRunRecipeLogs,
    jobRunId: isJobPath ? jobRunId : undefined
  });

  const recipeLogs = data?.recipeLogs ?? [];
  const recipeStatus = data?.status ?? ("" as unknown as any);

  const setSideComponent = useDrawerStore(sideComponentSetter);

  const url = `/recipe-run-logs/${projectId}/${scenarioId}/${groupId}`;

  const exportLogs = async () => {
    if (groupId) {
      !!projectId && checkEnvRelaunch(projectId);
      await exportRecipeLogs.mutateAsync({ groupId, scenarioId, name: groupId });
    }
  };

  const closeLogs = () => {
    setSideComponent({
      sideComponent: null,
      sideComponentProps: null
    });
  };

  const handleSearchValueChange = (event: $TSFixMe) => {
    setSearchValue(event.target.value);
  };

  const filteredLogs = useMemo(() => {
    return (recipeLogs || [])?.filter((eachField: $TSFixMe) => {
      return searchValue ? eachField?.toLowerCase()?.includes(searchValue?.toLowerCase()) : true;
    });
  }, [searchValue, recipeLogs]);

  const recipeRunInfo = useMemo(() => {
    switch (recipeStatus) {
      case GroupLogDtoStatusEnum.Success:
        return {
          type: "success",
          message: isRunRecipeLogs ? "Recipe run successful" : "Recipe test successful"
        };

      case GroupLogDtoStatusEnum.Running:
        return { type: "info", message: "Recipe run in progress..." };

      case GroupLogDtoStatusEnum.Unbuilt:
        return {
          type: "info",
          message: "Logs are not available because the recipe hasn't been executed yet."
        };

      default:
        return { type: "error", message: "No logs found!" };
    }
  }, [recipeStatus]);

  return (
    <Grid container direction="column" justifyContent="center" className={classes.autoMlLogs}>
      <ShowLogsDrawerTitle
        url={!isJobPath && url}
        onClose={closeLogs}
        {...(recipeStatus && {
          status: { color: getStatusEquivalence(recipeStatus), text: recipeStatus }
        })}
        searchValue={searchValue}
        exportLogs={exportLogs}
        exportLogLoading={exportRecipeLogs.isLoading}
        exportDisabled={false}
        exportDisabledMessage="Please wait until recipe run is complete"
        onSearch={handleSearchValueChange}
      />
      {isLoading ? (
        <LogsContent logs={TEMP_MSG} />
      ) : !isEmpty(recipeLogs) ? (
        <LogsContent
          logs={filteredLogs}
          {...(RecipeStatuses.UnBuilt === recipeStatus ? { info: UNBUILT_MSG } : {})}
        />
      ) : (
        renderInfo(recipeRunInfo)
      )}
    </Grid>
  );
};
