// Packages
import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

// Open API
import api from "services/AxiosClient/AxiosClient";
import { ResourceUsageDto } from "@rapidcanvas/rc-api-core";

export const enum UseGetEnvironmentQueryKeys {
  EnvironmentResourceUsage = "environmentResourceUsage"
}

interface IEnvironmentResourceUsageProps
  extends UseQueryOptions<unknown, unknown, ResourceUsageDto> {
  id?: string;
}

const useGetEnvironmentResourceUsage = (
  props: IEnvironmentResourceUsageProps
): UseQueryResult<ResourceUsageDto> => {
  const { id, ...options } = props;

  return useQuery({
    queryKey: [UseGetEnvironmentQueryKeys.EnvironmentResourceUsage, id],
    queryFn: async () => {
      if (!id) {
        throw "Invalid payload!";
      }

      return await api.fetchResponse(
        async () => await api.EnvControllerApi.getResourceUsage(id),
        false,
        false
      );
    },
    enabled: !!id,
    ...options
  });
};

export default useGetEnvironmentResourceUsage;
