import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

import { getAPIWithRethrow } from "services/Apis/Apis.service";

const QUERY_KEY_TRANSFORM_GROUP_DETAILS = "query-key-transform-group-details";

export interface ITransformGroupData {
  built: number;
  recipeRunTime: number;
  created: number;
  icon: string;
  image: string;
  markdown: string;
  results: {
    msgList: any[];
    name: string;
    status: string;
    transformRuntime: string;
    type: string;
  }[];
  scenarioId: string;
  status: string;
  updated: number;
  updater: string;
}

const useTransformGroupDetails = (
  groupId: string,
  scenarioId: string,
  jobRunId: string,
  options?: UseQueryOptions<ITransformGroupData>
): UseQueryResult<ITransformGroupData> => {
  return useQuery({
    queryKey: [QUERY_KEY_TRANSFORM_GROUP_DETAILS, groupId, scenarioId, jobRunId],
    queryFn: async () => {
      return await getAPIWithRethrow(
        `/v2/dfs-run-config-groups/${groupId}/details?${
          !!scenarioId ? `&scenarioId=${scenarioId}` : ``
        }${!!jobRunId ? `&projectRunEntryId=${jobRunId}` : ``}`
      );
    },
    ...options
  });
};

export default useTransformGroupDetails;
export { QUERY_KEY_TRANSFORM_GROUP_DETAILS };
