import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";

import { Table } from "src/components/custom/TableV8/Table";
import { ColumnDef } from "@tanstack/react-table";
import { TData } from "src/components/custom/TableV8/typing";

const useStyles = makeStyles({
  licenseQuotas: {
    backgroundColor: "#ffffff",
    padding: "16px",
    gap: "16px",
    borderRadius: "4px"
  }
});

export const LicenseQuotas = ({ metadata }: { metadata: $TSFixMe }) => {
  const classes = useStyles();
  const quotas = metadata
    ? Object.keys(metadata).reduce(
        (acc: Array<{ component: string; quota: boolean }>, metadataKey) => {
          const metadataValue: boolean = metadata[metadataKey];
          return typeof metadataValue === "number" || typeof metadataValue === "bigint"
            ? [...acc, { component: metadataKey, quota: metadataValue }]
            : acc;
        },
        []
      )
    : [];

  const inputColumns: ColumnDef<TData, any>[] = [
    {
      id: "Component",
      accessorKey: "component",
      header: "Component",
      meta: { type: "string" }
    },
    {
      id: "Quota",
      accessorKey: "quota",
      header: "Quota",
      meta: { type: "string", fontWeight: 500 }
    }
  ];

  return quotas?.length && quotas?.length !== 0 ? (
    <Grid container direction="column" className={classes.licenseQuotas}>
      <Grid item>
        <Typography variant="h6" color="textPrimary">
          License Quotas
        </Typography>
      </Grid>
      <Grid item>
        <Table stylesProps={{ size: "medium" }} columns={inputColumns} data={quotas} />
      </Grid>
    </Grid>
  ) : (
    <React.Fragment />
  );
};
