import React, { Dispatch, SetStateAction } from "react";

import { UseFormReturn } from "react-hook-form";

import {
  EntityDto,
  FeatureDto,
  ProjectDashboardDto,
  ProjectRunDto,
  ProjectRunEntryDto,
  SegmentDto
} from "@rapidcanvas/rc-api-core";

import { DatasetFeaturesDataTypeMapping, Segment } from "../Segment.type";

type ContextTypes = {
  isDefaultScenario: boolean;

  isJobPath: boolean;
  jobData?: ProjectRunDto;
  jobRunData: ProjectRunEntryDto;

  project: ProjectDashboardDto;

  isDatasetLoading: boolean;
  datasetData: EntityDto | any;

  isDatasetFeaturesLoading: boolean;
  datasetFeaturesData?: FeatureDto[];
  datasetFeaturesDataTypeMapping?: DatasetFeaturesDataTypeMapping;

  isSegmentLoading?: boolean;

  isReadOnly: boolean;

  formMethods: UseFormReturn<Segment, unknown, undefined>;
  resetForm: (data: SegmentDto) => void;

  saveSegment: () => void;
  isSegmentSaving: boolean;
  setIsSegmentNameSaving: Dispatch<SetStateAction<boolean>>;
};

// @ts-ignore
export const SegmentContext = React.createContext<ContextTypes>();
