const projectMetadata = {
  name: { id: "name", label: "Project Name" },
  dataapps: { id: "dataAppIds", label: "All Dataapps" },
  jobs: { id: "projectRunIds", label: "All Schedulers" },
  predictionServices: { id: "predictionServices", label: "Prediction Service" },
  environment: { id: "envId", label: "Environment" },
  tenant: { id: "tenantId", label: "Workspace *" }
};

const SUCCESSFULLY_COPIED_MSG = "Project is copied successfully";
const SUCCESSFULLY_DOWNLOADED_MSG = "Project is downloaded successfully";
export { projectMetadata, SUCCESSFULLY_COPIED_MSG, SUCCESSFULLY_DOWNLOADED_MSG };
