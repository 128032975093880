import { useRef } from "react";

import { v4 as uuidv4 } from "uuid";

import {
  delay,
  every,
  has,
  includes,
  initial,
  isEmpty,
  isNil,
  join,
  last,
  reduce,
  replace,
  size,
  startCase,
  toLower,
  toUpper
} from "lodash";

import { leadingTextInPath } from "./string.helpers";

import { removeLocalStorageItem } from "src/services/LocalStorage/LocalStorage.service";
import { removeSessionStorageItem } from "src/services/SessionStorage/SessionStorage.service";

import { TableSessionConfig } from "constants/table.constants";

export const onLogout = () => {
  removeLocalStorageItem(TableSessionConfig.TablePreferencesSessionKey);
  removeSessionStorageItem(TableSessionConfig.TablePreferencesSessionKey);
};

const LOCALHOST = "localhost";

const isLocalhost = () => {
  const hostname = window.location.hostname;
  return hostname === LOCALHOST;
};
const DEFAULT_SERVER_URL = "https://test.dev.rapidcanvas.net";

export const getBasePath = () => {
  return isLocalhost() ? DEFAULT_SERVER_URL : window.location.origin;
};

export const areAllKeysPresentAndNotNil = (dataMap?: { [key: string]: any }, keys?: string[]) => {
  if (isNil(dataMap) || isNil(keys)) {
    return false;
  }

  if (isEmpty(dataMap) || isEmpty(keys)) {
    return false;
  }

  return every(keys, (key) => has(dataMap, key) && !isNil(dataMap[key]));
};

interface GetReactHookFormTextFieldRulesProps {
  fieldName?: string;
  minLength?: number;
  maxLength?: number;
}

export const getReactHookFormTextFieldRules = (props?: GetReactHookFormTextFieldRulesProps) => {
  const { fieldName, minLength = 3, maxLength = 64 } = props || {};

  return {
    validate: {
      noInvalidPatterns: (value: string) => {
        if (!value) {
          return `${fieldName ? `The ${toLower(fieldName)}` : "This field"} cannot be blank.`;
        }

        const thisValue = value.trim();
        if (size(thisValue) < minLength || size(thisValue) > maxLength) {
          return `${fieldName ? `The ${toLower(fieldName)}` : "This field"} needs to be between ${minLength} and ${maxLength} characters.`;
        }

        if (
          // Spaces, underscores and dashes are allowed. No other special characters
          !/^(?:[a-zA-Z0-9 _-]*)?$/.test(thisValue) ||
          // Should not have only special characters
          /^(?:[_ -]*)?$/.test(thisValue) ||
          // Should not start with special characters
          /^(?:[_-]*)?$/.test(thisValue.substring(0, 1))
        ) {
          return `${fieldName ? `The ${toLower(fieldName)}` : "This field"} contains invalid characters. It needs to start with alphanumerics and can only contain alphanumerics, spaces, underscores and dashes.`;
        }

        return true;
      }
    }
  };
};

const createCanvasContext = () => {
  const canvas = document.createElement("canvas");
  return canvas.getContext("2d");
};

export const getTextWidth = (
  text: string,
  fontSize = 14.5,
  fontFamily: "Roboto" | "Helvetica" | "Arial" | "sans-serif" = "Roboto"
) => {
  const context = createCanvasContext();

  if (context) {
    context.font = `${fontSize}px ${fontFamily}`;
    return context.measureText(text).width;
  }

  return 0; // Fallback in case the context is not available
};

const transformSubstringsCase = (
  input: string,
  substrings: string[],
  transformFn: (text: string) => string
): string =>
  reduce(
    substrings,
    (result, substring) =>
      replace(result, new RegExp(substring, "gi"), (match) => transformFn(match)),
    input
  );

export const snakeCaseToStartCase = (
  text: string,
  options?: Partial<{ toUpperCase: string[]; toLowerCase: string[] }>
): string => {
  const { toUpperCase = [], toLowerCase = [] } = options || {};
  let output = startCase(toLower(text));

  if (!isEmpty(toUpperCase)) {
    output = transformSubstringsCase(output, toUpperCase, toUpper);
  }
  if (!isEmpty(toLowerCase)) {
    output = transformSubstringsCase(output, toLowerCase, toLower);
  }

  return output;
};

export const safeParseJSON = (json: string | undefined, defaultValue = {}) => {
  if (!json) {
    return defaultValue;
  }
  try {
    return JSON.parse(json);
  } catch (error) {
    console.error("Failed to parse JSON:", error);
    return defaultValue;
  }
};

export const preventExponentialInput = (event: React.KeyboardEvent<HTMLInputElement>) => {
  if (includes(["e", "E", "+"], event?.key)) {
    event?.preventDefault();
  }
};

export const invalidFileTypes = [
  "c",
  "cgi",
  "pl",
  "class",
  "cpp",
  "cs",
  "h",
  "java",
  "php",
  "py",
  "sh",
  "swift",
  "vb",
  "asp",
  "aspx",
  "cer",
  "cfm",
  "css",
  "htm",
  "html",
  "js",
  "jsp",
  "part",
  "rss",
  "xhtml",
  "apk",
  "bat",
  "bin",
  "com",
  "exe",
  "gadget",
  "jar",
  "msi",
  "wsf"
];

export function addEllipsis(label = "", maxLength = 25) {
  if (label.length <= maxLength) return label;

  const ellipsis = "...";
  const keepLength = Math.floor((maxLength - ellipsis.length) / 2);

  return `${label.substring(0, keepLength)}${ellipsis}${label.substring(label.length - keepLength)}`;
}

export function handleClickClosure({
  shouldBlockClickHandlerTrigger,
  isCtrlPressed,
  handleSingleClick,
  handleDoubleClick,
  handleMultiSelection,
  shouldClearEntities = true
}: $TSFixMe) {
  const clickCountRef = useRef(0);

  return () => {
    if (isCtrlPressed) {
      handleMultiSelection();
      return;
    } else {
      shouldClearEntities && sessionStorage.setItem("selectedEntities", "");
    }

    if (shouldBlockClickHandlerTrigger) {
      return;
    }

    clickCountRef.current++;

    if (clickCountRef.current === 1) {
      delay(() => {
        if (clickCountRef.current === 1) {
          clickCountRef.current = 0;
          handleSingleClick?.();
          return;
        }
      }, 500);
    } else if (clickCountRef.current === 2) {
      clickCountRef.current = 0;
      handleDoubleClick?.();
      return;
    } else {
      return;
    }
  };
}

export const getDocsUrl = (): string => {
  // Placeholder for future conditions
  return "https://docs.rapidcanvas.ai";
};

// @TODO: Specific to Recipe. Keeping it for a demo. It can be changed later.
export const recipeModelProblemTypesDocsUrl = (): string =>
  `${getDocsUrl()}/basic/projects/recipes/rapid-model-recipe#rapid-model-recipe-problem-types`;

export const validateFiles = (files: FileList, validFileExtensions: Array<string>) => {
  return files
    ? Object.values(files).every((file: File) =>
        validFileExtensions.includes(leadingTextInPath({ text: file.name }))
      )
    : true;
};

export const areFileTypesValid = (
  files: FileList,
  invalidFileExtensions: Array<string> = invalidFileTypes
) => {
  return files
    ? !Object.values(files).some((file: File) =>
        invalidFileExtensions.includes(leadingTextInPath({ text: file.name }))
      )
    : true;
};

export const formatStringsWithAnd = (strings: string[]) => {
  if (isEmpty(strings)) return "";
  return join(initial(strings), ", ") + " and " + last(strings);
};

export const SomethingWentWrongText = "Something went wrong. Please try again after sometime.";

export function formatBytes(bytes: $TSFixMe, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export const setUniqueKeys = (arr: Array<any> | undefined = [], key: string | undefined = "id") => {
  return arr?.map((elem) => {
    return { ...elem, [key]: uuidv4() };
  });
};
