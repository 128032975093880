import { useMutation, UseMutationResult } from "@tanstack/react-query";
import api from "services/AxiosClient/AxiosClient";
import { UpdateDataSourceDto } from "@rapidcanvas/rc-api-core";

interface IVariable {
  id: string;
  payload: UpdateDataSourceDto;
}

const useUpdateDataSource = (): UseMutationResult<any, unknown, IVariable, unknown> => {
  return useMutation({
    mutationFn: async ({ id, payload }) => {
      return await api.fetchResponse(() => api.DataSourceControllerApi.update5(id, payload));
    }
  });
};

export default useUpdateDataSource;
