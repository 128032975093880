// Packages
import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { isArray, orderBy } from "lodash";

// Utils
import api from "services/AxiosClient/AxiosClient";

// Open API
import { ProjectDashboardDto } from "@rapidcanvas/rc-api-core";

export const enum UseGetProjectsQueryKeys {
  Projects = "projects"
}

interface IProjectsProps extends UseQueryOptions<unknown, unknown, ProjectDashboardDto[]> {}

const useGetProjects = (props: IProjectsProps): UseQueryResult<ProjectDashboardDto[]> => {
  const { ...options } = props;

  return useQuery({
    queryKey: [UseGetProjectsQueryKeys.Projects],
    queryFn: async () =>
      await api.fetchResponse(async () => await api.ProjectsControllerV2Api.getDashboard()),
    select: (data) => (isArray(data) ? orderBy(data, "updated", "desc") : []),
    staleTime: 0, // Ensures data is always considered stale
    cacheTime: Infinity, // Keeps the data in memory indefinitely
    ...options
  });
};

export default useGetProjects;
