import React, { useEffect, useState } from "react";
import { Button, ButtonProps, TextField, Tooltip } from "@material-ui/core";

import NewModal from "components/Modal/NewModal";
import useAddPredictionOutputToCanvas from "hooks/api/projects/useAddPredictionOutputToCanvas";
import { validateNameField } from "utils/helpers/form.helpers";
import {
  FAILED_LAST_RUN_MSG,
  FAILED_LAST_RUN_MSG_ISMANUAL,
  UNSUCCESSFUL_PREDICTION_OUTPUT_MSG
} from "../utils/PredictionJob.constants";

interface IProps extends ButtonProps {
  disabled: boolean;
  projectId: string;
  isManual: boolean;
  path: string;
  predictionJobName: string;
}

const AddOutputToCanvas: React.FC<IProps> = (props) => {
  const { predictionJobName, projectId, path, isManual, disabled, ...rest } = props;
  const [value, setValue] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const maxLength = 64;
    const unique = Date.now().toString().slice(-6);
    const baseSuffix = `_output_${unique}`;
    const maxJobNameLength = maxLength - baseSuffix.length;

    const trimmedJobName = predictionJobName?.slice(0, maxJobNameLength);
    const finalString = `${trimmedJobName}${baseSuffix}`;

    setValue(finalString);
  }, [predictionJobName, open]);

  const { isValid, error } = validateNameField({
    fieldName: value.trim(),
    fieldNameLabel: `prediction output`,
    maxFieldLength: 64
  });

  const addToCanvas = useAddPredictionOutputToCanvas();

  const handleClick = () => {
    setOpen(true);
  };

  const handleAddToCanvas = () => {
    addToCanvas.mutate(
      {
        projectId,
        name: value,
        path
      },
      {
        onSuccess: () => setOpen(false)
      }
    );
  };

  return (
    <>
      <Tooltip
        title={
          !path
            ? isManual
              ? FAILED_LAST_RUN_MSG_ISMANUAL
              : FAILED_LAST_RUN_MSG
            : disabled
              ? UNSUCCESSFUL_PREDICTION_OUTPUT_MSG
              : ""
        }>
        <span>
          <Button
            color="primary"
            variant="contained"
            data-testid="predict-job-add-to-canvas"
            disabled={disabled || addToCanvas.isLoading}
            size="small"
            onClick={handleClick}
            {...rest}>
            Add to canvas
          </Button>
        </span>
      </Tooltip>
      <NewModal
        open={open}
        header="Update Name"
        loading={addToCanvas.isLoading}
        width="465px"
        height={350}
        background="#fff"
        isDefaultButtonCustomTheming
        submitButtonLabel="Add to Canvas"
        submitDisabled={disabled || !isValid || addToCanvas.isLoading}
        onFinish={handleAddToCanvas}
        onClose={() => setOpen(false)}>
        <TextField
          fullWidth
          data-testid="output-dataset-name"
          name="dataset"
          label="Prediction Output Dataset Name"
          value={value}
          error={!isValid}
          helperText={error}
          size="small"
          variant="outlined"
          onChange={(e) => setValue(e.target.value)}
        />
      </NewModal>
    </>
  );
};

export default AddOutputToCanvas;
