import React from "react";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { Edit, GetApp, VisibilityOutlined } from "@material-ui/icons";

import DataAppTypeIcon from "../../common/DataAppTypeIcon";
import Text from "components/Widget/Text";
import { ConfigIcon } from "src/assets/icons/ConfigIcon";
import { DataAppDtoDataAppTypeEnum, DataAppDtoLaunchStatusEnum } from "@rapidcanvas/rc-api-core";
import { Delete } from "src/assets/icons/Delete";
import { LinkCellWithMenu } from "src/components/Table/Cells/LinkCellWithMenu/LinkCellWithMenu.component";
import { LogsNewIconV2 } from "src/assets/icons/LogsNewIconV2";
import { deleteDisabled, deleteTooltip } from "../../utils/DataApps.helpers";
import { type DataAppType } from "../../DataApps.type";
import { useGetRole } from "hooks/useGetRole";

export const DataAppTableLinkCell = ({
  row,
  isRoleYieldsDataAppView,
  onDelete,
  onConfigOpen,
  onEdit,
  onLogsOpen,
  onDownloadCode,
  onOpenInNewTab
  // onEditCode
}: {
  row: { original: DataAppType };
  isRoleYieldsDataAppView: boolean;
  onDelete: (data: DataAppType) => void;
  onEdit: (dataApp: DataAppType) => void;
  onConfigOpen: (dataApp: DataAppType) => void;
  onLogsOpen: (dataApp: DataAppType) => void;
  onDownloadCode: (dataApp: DataAppType) => void;
  onOpenInNewTab: (dataApp: DataAppType) => void;
  onEditCode: (appInfo: DataAppType) => void;
}) => {
  const location = useLocation();
  const { isConsumer } = useGetRole();

  const dataAppName = row.original.displayName || row.original.name;
  return (
    <LinkCellWithMenu
      url={`${location.pathname === "/" ? "dataapps-ui" : location.pathname}/${row.original.name}`}
      title={dataAppName}
      extra={
        row.original?.appType && (
          <div className="hiddenDiv">
            <DataAppTypeIcon appType={row.original.appType} />
          </div>
        )
      }
      linkText={<Text value={dataAppName} style={{ whiteSpace: "pre" }} />}
      menuButtons={[
        ...(!isRoleYieldsDataAppView && !isConsumer
          ? [
              {
                label: "Edit",
                icon: (
                  <Typography color="textSecondary">
                    <Edit />
                  </Typography>
                ),
                action: () => onEdit(row.original)
              },
              // {
              //   label: "Edit Code",
              //   icon: (
              //     <Typography color="textSecondary">
              //       <Edit />
              //     </Typography>
              //   ),
              //   action: () => onEditCode(row.original)
              // },
              {
                label: "Delete",
                icon: (
                  <Typography color="textSecondary">
                    <Delete />
                  </Typography>
                ),
                tooltip: deleteTooltip(row.original?.launchStatus),
                disabled: deleteDisabled(row.original?.launchStatus),
                action: () => onDelete(row.original)
              }
            ]
          : []),

        {
          label: "Logs",
          icon: <LogsNewIconV2 fill="#003656" width="24" />,
          action: () => onLogsOpen(row.original)
        },
        ...(isConsumer
          ? []
          : [
              {
                label: "Configs",
                icon: <ConfigIcon />,
                disabled: false,
                action: () => onConfigOpen(row.original)
              }
            ]),
        ...(row?.original?.dataAppType === DataAppDtoDataAppTypeEnum.Askai && !isConsumer
          ? [
              {
                label: "Consumer Preview",
                id: "dataAppConsumerPreview",
                tooltip:
                  row.original?.launchStatus !== DataAppDtoLaunchStatusEnum.Running
                    ? "Ensure DataApp is running to enable this action"
                    : "",
                disabled: row.original?.launchStatus !== DataAppDtoLaunchStatusEnum.Running,
                icon: <VisibilityOutlined fontSize="small" />,
                action: () => onOpenInNewTab(row.original)
              }
            ]
          : []),
        ...(row?.original?.dataAppType === DataAppDtoDataAppTypeEnum.Custom && !isConsumer
          ? [
              {
                label: "Download Code",
                icon: <GetApp />,
                disabled: false,
                action: () => onDownloadCode(row.original)
              }
            ]
          : [])
      ]}
    />
  );
};
