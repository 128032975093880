// Import Sentry configuration
import { initSentry } from "./app-configs/sentry.config";

// React and ReactDOM imports
import React from "react";
import ReactDOM from "react-dom";

// React Router imports
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider
} from "react-router-dom";

// Import styles
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";

// Import Material-UI styles
import { StylesProvider } from "@material-ui/core/styles";

// Import application components
import App from "./App";

// Import React Redux configuration files
import { Provider as ReduxProvider } from "react-redux";
import { store as reduxStore, persistor } from "./stores/store";
import { PersistGate } from "redux-persist/integration/react";

// Import React Query configuration files
import { queryClient } from "./app-configs/react-query.config";
import { QueryClientProvider } from "@tanstack/react-query";

// Import Analytics configuration
import { initAnalytics } from "./app-configs/analytics.config";

// Initialize Sentry
initSentry();

// Initialize Analytics
initAnalytics();

const router = createBrowserRouter(createRoutesFromElements(<Route path="*" element={<App />} />), {
  basename: "/"
});

// Render application
ReactDOM.render(
  <StylesProvider injectFirst>
    <ReduxProvider store={reduxStore}>
      <PersistGate loading={null} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={router} />;
        </QueryClientProvider>
      </PersistGate>
    </ReduxProvider>
  </StylesProvider>,
  document.querySelector("#root")
);
