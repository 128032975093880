import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";

import DatasetAIGuide from "../dataset/AIGuide";
import ChartsAIGuide from "../charts/AIGuide";
import ModelAIGuide from "../model/AIGuide";

import {
  ThreadResponseDto,
  ThreadResponseDtoTargetTypeEnum,
  AIChatResponseDto
} from "@rapidcanvas/rc-api-core";

type IProps = {
  activeThread: ThreadResponseDto;
  threads: ThreadResponseDto[];
  messages: AIChatResponseDto[];
};

const AIGuideRightbar = ({ threads, messages, activeThread }: IProps) => {
  const [visitedThreads, setVisitedThreads] = useState<ThreadResponseDto[]>([]);

  const visitedThreadIds = visitedThreads.map((thread) => thread.threadId);
  useEffect(() => {
    if (!visitedThreadIds.includes(activeThread.threadId)) {
      setVisitedThreads((visitedThreads) => [...visitedThreads, activeThread]);
    }
  }, [activeThread]);

  return (
    <>
      {threads.map((thread) => {
        const { threadId, targetType } = thread;
        const isVisited = visitedThreadIds.includes(threadId);
        const isActive = activeThread.threadId === threadId;
        const commonProps = {
          thread,
          messages
        };

        return isActive || isVisited ? (
          <Grid
            item
            key={threadId!}
            style={{
              maxWidth: isActive ? "100%" : "0%",
              flexBasis: isActive ? "100%" : "0%",
              visibility: isActive ? "visible" : "hidden",
              height: "inherit"
            }}>
            {targetType === ThreadResponseDtoTargetTypeEnum.Model ? (
              <ModelAIGuide {...commonProps} />
            ) : targetType === ThreadResponseDtoTargetTypeEnum.Chart ? (
              <ChartsAIGuide {...commonProps} />
            ) : (
              <DatasetAIGuide {...commonProps} />
            )}
          </Grid>
        ) : null;
      })}
    </>
  );
};

export default React.memo(AIGuideRightbar);
