import { useMemo } from "react";

// Packages
import { useQueryClient } from "@tanstack/react-query";
import { find, isEmpty } from "lodash";

// Open API
import { ScenarioDto } from "@rapidcanvas/rc-api-core";

// Hooks
import { UseGetScenariosQueryKeys } from "src/hooks/api";
import useGetScenarios from "src/hooks/api/scenarios/useGetScenarios";

interface IProps {
  projectId?: string;
  scenarioId?: string;
  jobId?: string;
}

const useIsDefaultScenario = (props: IProps) => {
  const { projectId, scenarioId, jobId } = props;
  const queryClient = useQueryClient();

  const scenariosQueryData = queryClient.getQueryData([
    UseGetScenariosQueryKeys.Scenarios,
    projectId
  ]) as ScenarioDto[] | undefined;

  // @TODO: Below query is always fetched as scenariosQueryData is undefined on mount. This will be addressed soon.
  const { data } = useGetScenarios({
    projectId,
    jobId,
    enabled: isEmpty(scenariosQueryData)
  });

  const isDefaultScenario = useMemo(
    () => find(scenariosQueryData || data || [], { id: scenarioId })?.default,
    [scenariosQueryData, data, scenarioId]
  );

  return !!isDefaultScenario;
};

export default useIsDefaultScenario;
