export enum RunOptionsHelperText {
  RunMenuActionLabel = "Run",

  RunMenuItemActionLabel = "Run",
  RunWithReloadDatasetsMenuItemActionLabel = "Run with Fresh Data",

  RunActionInfo = "Executes the canvas using the currently available data in datasets.",
  RunWithReloadDatasetsActionInfo = "Executes the canvas after fetching updated data for datasets created via connectors.",

  RecipesRunningToasterInfo = "Recipes are running...",
  RecipesRunningInfo = "Please wait. Recipes are running in the project.",

  DataFetchingInfo = "Please wait. Fetching required data.",
  ConnectorDatasetsInfo = "Applicable only when there are datasets created via data-connector."
}
