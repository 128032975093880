import React from "react"; // useState
import {
  IconButton
  // Menu, MenuItem
} from "@material-ui/core";
// import AddIcon from "@material-ui/icons/Add";

// import UploadIcon from "src/icons/NewUX/UploadIcon";
// import UploadProjectModal from "./CopyProject/components/UploadProjectModal";
import { PlusIcon } from "icons/NewUX/PlusIcon";

interface IProps {
  onNewProjectClick: () => void;
}

const CreateProjectButton: React.FC<IProps> = (props) => {
  const { onNewProjectClick } = props;
  // const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  // const [open, setOpen] = useState(false);

  // const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  // const handleUploadProject = () => {
  //   setOpen(true);
  // };

  return (
    <>
      <IconButton color="primary" data-testid="addProject" size="small" onClick={onNewProjectClick}>
        <PlusIcon width={28} height={28} />
      </IconButton>
      {/* <Menu
        data-testid="add-project"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        PaperProps={{
          style: {
            borderRadius: 12
          }
        }}
        onClick={handleClose}
        onClose={handleClose}>
        <MenuItem style={{ display: "flex", gap: "10px" }} onClick={onNewProjectClick}>
          <AddIcon style={{ color: "#7C7C7C", width: "16px", height: "16px" }} /> New Project
        </MenuItem>
        <MenuItem style={{ display: "flex", gap: "10px" }} onClick={handleUploadProject}>
          <UploadIcon />
          <span>Upload Project</span>
        </MenuItem>
      </Menu>
      <UploadProjectModal open={open} onClose={() => setOpen(false)} /> */}
    </>
  );
};

export default CreateProjectButton;
