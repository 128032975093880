import React, { useMemo, useState } from "react";

// Packages
import { useParams } from "react-router-dom";
import { useIsMutating } from "@tanstack/react-query";
import { isEmpty, size } from "lodash";

import ButtonGroup from "@material-ui/core/ButtonGroup";
import { Button } from "@material-ui/core";

// Icons
import { EyeIcon } from "icons/NewUX";

// Hooks
import { UseReloadDatasetsQueryKeys } from "src/hooks/api/entities/useReloadDatasets";

// Types
import { NodeData } from "src/types";

import { useContextStyles } from "../useContextMenuStyles";
import useUpdateEntities from "src/hooks/api/entities/useUpdateEntities";
import { useCanvasStore } from "stores/zustand/stores";
import { TrashIcon } from "icons/NewUX/TrashIcon";

import Modal, { ModalVariants } from "src/components/custom/Modal/Modal";
import DeleteNodesModal from "../../DeleteNodes/DeleteNodesModal";

type Props = {
  closeContextMenu: () => void;
  isDefaultScenario: boolean;
  data: NodeData;
  children?: React.ReactNode;
  onAddDestination: () => void;
  selectedNodes: NodeData[];
  resetSelectedNodes: () => void;
};

const ContextMenu = (props: Props) => {
  const {
    isDefaultScenario,
    data,
    children,
    closeContextMenu,
    onAddDestination,
    selectedNodes,
    resetSelectedNodes
  } = props || {};

  const { projectId, scenarioId } = useParams();

  const updateSyncDetails = useUpdateEntities();
  const [open, setOpen] = useState(false);
  const [showConfirmDeleteNodesModal, setShowConfirmDeleteNodesModal] = useState(false);
  const entity = data.parentDataset;

  const classes = useContextStyles();

  const isBuilt = useMemo(() => ["BUILT"].includes(data?.status), [data?.status]);

  const setReloadTrigger = useCanvasStore((state) => state.setReloadTrigger);

  const pendingReloadDatasetsMutations = useIsMutating({
    mutationKey: [UseReloadDatasetsQueryKeys.ReloadDatasets, data?.id]
  });

  const isReloadingDatasets = useMemo(
    () => pendingReloadDatasetsMutations > 0,
    [pendingReloadDatasetsMutations]
  );

  const handleRemove = async () => {
    await updateSyncDetails.mutateAsync(
      {
        id: entity.id,
        syncDataSourceId: ""
      },
      {
        onSuccess: () => {
          setReloadTrigger();
          setOpen(false);
        }
      }
    );
  };
  const onDelete = () => {
    setOpen(true);
    closeContextMenu();
  };

  // Delete nodes - STARTS >>
  const promptConfirmDeleteNodes = () => {
    setShowConfirmDeleteNodesModal(() => true);
  };

  const resetConfirmDeleteNodes = () => {
    setShowConfirmDeleteNodesModal(() => false);
  };
  // << ENDS - Delete nodes

  const buttonComponents = useMemo(() => {
    let items: React.ReactNode[] = [];

    if (!!data?.isJobCanvas) {
      if (!data?.isJobCanvasPath) {
        return [];
      }
    }

    if (size(selectedNodes) > 1) {
      items.push(
        <Button
          size="small"
          key="delete"
          data-testid="destinationContextMenuDeleteSelected"
          startIcon={<TrashIcon viewBox="0 0 20 22" />}
          onClick={promptConfirmDeleteNodes}>
          Delete Selected
        </Button>
      );

      return items;
    }

    items.push(
      <Button
        key="view"
        data-testid="Preview"
        className="context-menu-border"
        startIcon={<EyeIcon viewBox="0 0 20 20" />}
        size="small"
        onClick={() => {
          onAddDestination();
          closeContextMenu();
        }}>
        Preview
      </Button>
    );

    items.push(
      <Button
        size="small"
        key="delete"
        data-testid="Delete"
        startIcon={<TrashIcon viewBox="0 0 20 22" />}
        onClick={onDelete}>
        Delete
      </Button>
    );

    return items;
  }, [
    selectedNodes,
    data?.isJobCanvas,
    data?.isJobCanvasPath,
    isDefaultScenario,
    isBuilt,
    data?.isOutputDataset,
    data?.entityDSDetails?.type,
    isReloadingDatasets
  ]);

  return (
    <>
      {!!showConfirmDeleteNodesModal && (
        <DeleteNodesModal
          projectId={projectId}
          scenarioId={scenarioId}
          selectedNodes={selectedNodes}
          resetSelectedNodes={resetSelectedNodes}
          resetConfirmDeleteNodes={resetConfirmDeleteNodes}
        />
      )}

      {(React.isValidElement(children) || !isEmpty(buttonComponents)) && (
        <ButtonGroup variant="text" size="small" orientation="vertical" className={classes.root}>
          {!isEmpty(buttonComponents) && buttonComponents}
          {children}
        </ButtonGroup>
      )}
      <Modal
        open={open}
        variant={ModalVariants.Delete}
        title="Remove"
        content={[
          "This will remove the Destination Details from the Output Dataset",
          "Are you sure?"
        ]}
        onClose={() => {
          setOpen(false);
        }}
        isSubmitting={updateSyncDetails.isLoading}
        onSubmit={handleRemove}
        hideCloseIcon
      />
    </>
  );
};

export default ContextMenu;
