import React from "react";

import SettingsIcon from "@material-ui/icons/Settings";
import VisibilityIcon from "@material-ui/icons/Visibility";

export enum DatasetSessionConfig {
  UploadingFilesSessionKey = "uploadingFilesSession",
  DatasetsSessionKey = "datasetsSession",
  OntologySchemaSessionKey = "ontologySchemaSession",
  SqlConfigurationSessionKey = "sqlConfigurationSession"
}

export enum SourceType {
  FileUpload = "fileUpload"
}

export enum Criteria {
  SingleFileUpload = "SINGLE",
  Merge = "MERGE",
  Segregate = "SEGREGATE",
  Append = "APPEND"
}

export const criteria = [
  {
    id: Criteria.SingleFileUpload,
    datasetName: "Single File Import",
    textInputName: "Single File Import",
    datasetDescription: "A single file will be used to create a new dataset.",
    textInputDescription: "A single file will be used to create a new text input."
  },
  {
    id: Criteria.Merge,
    datasetName: "Merge",
    datasetDescription:
      "Multiple files will be merged into a single dataset. This criteria expects schema to be the same in all files."
  },
  {
    id: Criteria.Segregate,
    datasetName: "Segregate",
    textInputName: "Segregate",
    datasetDescription: "Each file will be used to create a new dataset.",
    textInputDescription: "Each file will be used to create a new text input."
  },
  {
    id: Criteria.Append,
    datasetName: "Append",
    textInputName: "Update File",
    datasetDescription:
      "Data in this file will be appended to an existing dataset only if the schema of both files is the same. Please note that the data gets overwritten if the new file has the same name as an existing file.",
    textInputDescription: "This action will completely replace the contents of the existing file."
  }
];

export enum FileMimeTypes {
  Text = "text/plain",
  ApplicationJson = "application/json",
  TextCsv = "text/csv",
  TextXml = "text/xml",
  TextHtml = "text/html",
  ApplicationOctetStream = "application/octet-stream",
  XLSX = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  XLS = "application/vnd.ms-excel",
  Md = "text/markdown"
}

export enum FileTypes {
  Csv = "csv"
}

// fileMimeTypesValues >>
const thisFileMimeTypesValues: $TSFixMe = {};

thisFileMimeTypesValues[FileMimeTypes.Text] = {
  displayName: "Text"
};
thisFileMimeTypesValues[FileMimeTypes.ApplicationJson] = {
  displayName: "JSON"
};
thisFileMimeTypesValues[FileMimeTypes.TextCsv] = {
  displayName: "CSV"
};
thisFileMimeTypesValues[FileMimeTypes.TextHtml] = {
  displayName: "HTML"
};
thisFileMimeTypesValues[FileMimeTypes.TextXml] = {
  displayName: "XML"
};
thisFileMimeTypesValues[FileMimeTypes.ApplicationOctetStream] = {
  displayName: "Parquet"
};
thisFileMimeTypesValues[FileMimeTypes.XLS] = {
  displayName: "XLS"
};
thisFileMimeTypesValues[FileMimeTypes.XLSX] = {
  displayName: "XLSX"
};
thisFileMimeTypesValues[FileMimeTypes.Md] = {
  displayName: "Markdown"
};

export const fileMimeTypesValues = thisFileMimeTypesValues;
// << fileMimeTypesValues

// Update this list of MIME types, which can support change in schema options.
export const schemaOptionsSupportedFileTypes = [FileMimeTypes.TextCsv, FileTypes.Csv];

export enum FileMetaDataKeys {
  Status = "status",
  EntityId = "entityId",
  UploadProgress = "uploadProgress"
}

export const validFileTypes = ["csv", "xls", "xlsx", "parquet"];
export const validFileTypesForFileTypeDataset = ["txt", "json", "html", "md"];
export const maximumNoOfFilesAllowed = 25;
export const maximumTotalFilesSizeAllowedInGb = 5;

export enum DataSourcesNavigationTypes {
  Previous = "PREVIOUS",
  Next = "NEXT"
}

export enum DataSourceFileTypes {
  Directory = "directory",
  File = "file"
}

export enum FileUploadStatuses {
  Stage = "STAGE",
  Uploading = "UPLOADING",
  EntityCreated = "ENTITY_CREATED",
  SignedUrl = "SIGNED_URL",
  Processing = "PROCESSING",
  Success = "SUCCESS",
  Failed = "FAILED",
  Reuploading = "REUPLOADING",
  Deleting = "DELETING"
}

export enum DatasetConfig {
  acceptedDatasetNameLength = 64
}

export enum DatasetKeys {
  Id = "id",
  Name = "name",
  IsValid = "isValid",
  IsDirty = "isDirty",
  IsExisting = "isExisting",
  OntologyConfig = "ontologyConfig",
  Status = "status",
  Separator = "separator",
  CustomSeparator = "customSeparator",
  Encoding = "encoding",
  SqlConfig = "sqlConfig",
  IsSqlFieldDirty = "isSqlFieldDirty",
  IsSqlConfigValid = "isSqlConfigValid",
  IsSchemaOrOntologyDirty = "isSchemaOrOntologyDirty"
}

export enum OntologyNavigationTypes {
  Previous = "PREVIOUS",
  Next = "NEXT"
}

export enum OntologyDatasetStatuses {
  Stage = "STAGE",
  Active = "ACTIVE",
  Updating = "UPDATING",
  Updated = "UPDATED",
  Failed = "FAILED",
  Deleting = "DELETING"
}

export enum Separators {
  Comma = "COMMA",
  Tab = "TAB",
  Pipe = "PIPE",
  Colon = "COLON",
  Semicolon = "SEMICOLON",
  Dot = "DOT",
  Space = "SPACE"
}

export const separators = [
  {
    id: Separators.Comma,
    name: "Comma",
    value: ","
  },
  {
    id: Separators.Tab,
    name: "Tab",
    value: "\t"
  },
  {
    id: Separators.Pipe,
    name: "Pipe",
    value: "|"
  },
  {
    id: Separators.Colon,
    name: "Colon",
    value: ":"
  },
  {
    id: Separators.Semicolon,
    name: "Semicolon",
    value: ";"
  },
  {
    id: Separators.Dot,
    name: "Dot",
    value: "."
  },
  {
    id: Separators.Space,
    name: "Space",
    value: " "
  }
];

export enum Encodings {
  Utf_8 = "UTF_8",
  Utf_16 = "UTF_16",
  Utf_32 = "UTF_32",
  Ascii = "ASCII",

  Big5 = "Big5",
  Gb2312 = "GB2312",
  Gb18030 = "GB18030",
  Euc_Tw = "EUC_TW",
  Hz_Gb_2312 = "HZ_GB_2312",
  Iso_2022_Cn = "ISO_2022_CN",
  Euc_Jp = "EUC_JP",
  Shift_Jis = "SHIFT_JIS",
  Iso_2022_Jp = "ISO_2022_JP",
  Euc_Kr = "EUC_KR",
  Iso_2022_Kr = "ISO_2022_KR",
  Koi8_R = "KOI8_R",
  MacCyrillic = "MacCyrillic",
  Ibm855 = "IBM855",
  Ibm866 = "IBM866",
  Iso_8859_5 = "ISO_8859_5",
  Windows_1251 = "windows_1251",
  Iso_8859_2 = "ISO_8859_2",
  Windows_1250 = "windows_1250",
  Iso_8859_1 = "ISO_8859_1",
  Windows_1252 = "windows_1252",
  Iso_8859_7 = "ISO_8859_7",
  Windows_1253 = "windows_1253",
  Iso_8859_8 = "ISO_8859_8",
  Windows_1255 = "windows_1255",
  Tis_620 = "TIS_620"
}

export const encodings = [
  { id: Encodings.Utf_8, name: "UTF-8", value: "utf-8" },
  { id: Encodings.Utf_16, name: "UTF-16", value: "utf-16" },
  { id: Encodings.Utf_32, name: "UTF-32", value: "utf-32" },
  { id: Encodings.Ascii, name: "ASCII", value: "ascii" },

  { id: Encodings.Big5, name: "Big5", value: "big5" },
  { id: Encodings.Gb2312, name: "GB2312", value: "gb2312" },
  { id: Encodings.Gb18030, name: "GB18030", value: "gb18030" },
  { id: Encodings.Euc_Tw, name: "EUC-TW", value: "euc-tw" },
  { id: Encodings.Hz_Gb_2312, name: "HZ-GB-2312", value: "hz-gb-2312" },
  { id: Encodings.Iso_2022_Cn, name: "ISO-2022-CN", value: "iso-2022-cn" },
  { id: Encodings.Euc_Jp, name: "EUC-JP", value: "euc-jp" },
  { id: Encodings.Shift_Jis, name: "SHIFT_JIS", value: "shift_jis" },
  { id: Encodings.Iso_2022_Jp, name: "ISO-2022-JP", value: "iso-2022-jp" },
  { id: Encodings.Euc_Kr, name: "EUC-KR", value: "euc-kr" },
  { id: Encodings.Iso_2022_Kr, name: "ISO-2022-KR", value: "iso-2022-kr" },
  { id: Encodings.Koi8_R, name: "KOI8-R", value: "koi8-r" },
  { id: Encodings.MacCyrillic, name: "MacCyrillic", value: "maccyrillic" },
  { id: Encodings.Ibm855, name: "IBM855", value: "ibm855" },
  { id: Encodings.Ibm866, name: "IBM866", value: "ibm866" },
  { id: Encodings.Iso_8859_5, name: "ISO-8859-5", value: "iso-8859-5" },
  { id: Encodings.Windows_1251, name: "windows-1251", value: "windows-1251" },
  { id: Encodings.Iso_8859_2, name: "ISO-8859-2", value: "iso-8859-2" },
  { id: Encodings.Windows_1250, name: "windows-1250", value: "windows-1250" },
  { id: Encodings.Iso_8859_1, name: "ISO-8859-1", value: "iso-8859-1" },
  { id: Encodings.Windows_1252, name: "windows-1252", value: "windows-1252" },
  { id: Encodings.Iso_8859_7, name: "ISO-8859-7", value: "iso-8859-7" },
  { id: Encodings.Windows_1253, name: "windows-1253", value: "windows-1253" },
  { id: Encodings.Iso_8859_8, name: "ISO-8859-8", value: "iso-8859-8" },
  { id: Encodings.Windows_1255, name: "windows-1255", value: "windows-1255" },
  { id: Encodings.Tis_620, name: "TIS-620", value: "tis-620" }
];

export enum OntologyConfig {
  ColumnsLimit = 50,
  RowsLimit = 100
}

export enum OntologyViewTypes {
  Schema = "SCHEMA",
  SampleData = "SAMPLE_DATA"
}

export const ontologyViewTypes = [
  {
    id: OntologyViewTypes.Schema,
    name: "Schema",
    icon: <SettingsIcon fontSize="small" />,
    isActive: false
  },
  {
    id: OntologyViewTypes.SampleData,
    name: "Sample Data",
    icon: <VisibilityIcon fontSize="small" />,
    isActive: true
  }
];

export enum DatasetHelperText {
  CreateDatasetModalTitle = "Create New Data Set",
  AddFilesToDatasetModalTitle = "Add File to Dataset",
  Title = "Create New Data Set",
  OpenDataset = "Open Dataset",
  OpenTextInput = "Open Text Input",
  Project = "Project",
  Source = "Source",
  Criterion = "Mode",
  ViewInCanvas = "View in Canvas",

  SelectionPlaceholderDragDropTitle = "Drag & Drop files here",
  Or = "or",

  UploadedDataColumnInfoLine1 = "Please note that the duplicate column names are appended by a dot followed by a sequence number.",
  UploadedDataColumnInfoLine2 = "Spaces, foreign characters, and hyphens in column names are replaced by underscore.",
  AddFileAppendInfo = "Separator and Encoding are disabled during append as they are picked from existing Dataset.",
  CriterionMergeOntologyInfo = "Separator and Encoding will be common and applicable to all files selected.",

  FileTypesSupported = "File types supported",
  Note = "Note",
  TextInputConnectorTypesSupported = "Connector types supported",
  MaximumNoOfFilesAllowed = "Maximum no.of files allowed",
  MaximumFileSizeAllowed = "Maximum file size allowed",
  ExcelMultiSheetInfo = "Only the first tab of data is picked for xls and xlsx files.",

  UploadFilesFromLocal = "Import files from local",

  NewConnection = "New connection",
  NoFileFound = "No file found!",

  FileSize = "Size",
  FileType = "Type",

  UploadingFileActionDisabledText = "There is a task running. Please wait.",
  FileUploadSucceed = "Imported successfully!",
  FileUploadFailed = "Import failed!",

  DatasetNameErrorMessage = "This dataset name already exists.",
  TextInputNameErrorMessage = "This text input name already exists.",

  SqlConfigurationEditorQueryFieldInfo = "Only select queries are supported on the SQL query interface",

  UploadedFile = "Imported File",

  Separator = "Separator",
  Encoding = "Encoding",
  EditQuery = "Edit Query",

  RunQuery = "Run Query",
  Cancel = "Cancel",
  Upload = "Import",
  Previous = "Previous",
  Next = "Next",
  Uploading = "Importing",
  Processing = "Processing",
  Save = "Save",

  Dataset = "Dataset",
  DatasetName = "Dataset Name",

  TextInput = "Text Input",
  TextInputName = "Text Input Name",

  AppendFile = "Append File",
  AddFile = "Add File",
  UpdateFile = "Update File",

  SampleData = "Sample Data",
  Preview = "Preview",
  OntologyTitle = "Data Types",

  Apply = "Apply",
  CreateDataset = "Create Dataset",
  CreateDatasets = "Create Datasets",

  Done = "Done",

  Unknown = "Unknown",

  NoDataFound = "No data found",
  NoSampleDataFound = "No sample data found"
}

export enum FivetranStatus {
  active = "active",
  inActive = "inactive",
  syncing = "syncing",
  syncIssues = "sync issues",
  syncPaused = "sync paused"
}
