import React from "react";

interface IProps {
  width?: number;
  height?: number;
  viewBox?: string;
  fill?: string;
  color?: string;
}

const FileIcon: React.FC<IProps> = (props) => {
  const {
    width = 16,
    height = 16,
    viewBox = "0 0 16 16",
    fill = "none",
    color = "currentColor"
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clipPathId)">
        <path
          d="M9.33331 14.6667C9.7015 14.6667 9.99998 14.9651 9.99998 15.3333C9.99998 15.7015 9.7015 16 9.33331 16H3.99998C2.52722 16 1.33331 14.8061 1.33331 13.3333V2.66667C1.33331 1.19391 2.52722 0 3.99998 0L9.83822 0C10.5455 0 11.2237 0.280952 11.7238 0.781049L13.8856 2.94281C14.3857 3.44291 14.6666 4.12118 14.6666 4.82843V13.3362C14.6666 14.8074 13.474 16 12.0028 16C11.6308 15.9995 11.3328 15.7006 11.3333 15.3324C11.3338 14.9642 11.6327 14.6661 12.0009 14.6667C12.7376 14.6667 13.3333 14.071 13.3333 13.3362V4.82843C13.3333 4.4748 13.1928 4.13567 12.9428 3.88562L10.781 1.72386C10.531 1.47381 10.1918 1.33333 9.83822 1.33333H3.99998C3.2636 1.33333 2.66665 1.93029 2.66665 2.66667V13.3333C2.66665 14.0697 3.2636 14.6667 3.99998 14.6667H9.33331ZM9.99998 4.66667H11.3333C11.7015 4.66667 12 4.96514 12 5.33333C12 5.70152 11.7015 6 11.3333 6H9.33331C8.96512 6 8.66665 5.70152 8.66665 5.33333V3.33333C8.66665 2.96514 8.96512 2.66667 9.33331 2.66667C9.7015 2.66667 9.99998 2.96514 9.99998 3.33333V4.66667ZM4.66665 11.3333C4.29846 11.3333 3.99998 11.0349 3.99998 10.6667C3.99998 10.2985 4.29846 10 4.66665 10H11.3333C11.7015 10 12 10.2985 12 10.6667C12 11.0349 11.7015 11.3333 11.3333 11.3333H4.66665ZM4.66665 9.33333C4.29846 9.33333 3.99998 9.03486 3.99998 8.66667C3.99998 8.29848 4.29846 8 4.66665 8H11.3333C11.7015 8 12 8.29848 12 8.66667C12 9.03486 11.7015 9.33333 11.3333 9.33333H4.66665ZM4.66665 13.3333C4.29846 13.3333 3.99998 13.0349 3.99998 12.6667C3.99998 12.2985 4.29846 12 4.66665 12H9.33331C9.7015 12 9.99998 12.2985 9.99998 12.6667C9.99998 13.0349 9.7015 13.3333 9.33331 13.3333H4.66665Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clipPathId">
          <rect width={width} height={height} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FileIcon;
