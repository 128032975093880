import { Dispatch, SetStateAction } from "react";

// Packages
import Papa from "papaparse";
import {
  forEach,
  initial,
  isArray,
  isPlainObject,
  join,
  keys,
  map,
  now,
  range,
  replace,
  size,
  split,
  values,
  zip
} from "lodash";

// Utils
import { ToastTypes, toastWrapper } from "services/ToastClient/toastWrapper";
import { checkEnvRelaunch } from "environmentsModule/utils/environmentRelaunch.helpers";

// APIs
import {
  createEntityWithRethrow,
  executeSignedUrlWithRethrow,
  getSignedUrlWithRethrow,
  processFilesWithRethrow
} from "services/Apis/wrappers";

type Props = {
  projectId?: string;
  uploadType: { [key: string]: any };
  value?: string;
  fileName?: string;
  datasetId?: string;
  prediction?: string;
  getDatasetName: (id?: string) => string;
  setIsAddingToCanvas: Dispatch<SetStateAction<boolean>>;
  setIsAddedToCanvas: Dispatch<SetStateAction<boolean>>;
  setIsUpdateDatasetNameModalOpen: Dispatch<SetStateAction<boolean>>;
};

const useActions = (props: Props) => {
  const {
    projectId,
    uploadType,
    value,
    fileName,
    datasetId,
    prediction,
    getDatasetName,
    setIsAddingToCanvas,
    setIsAddedToCanvas,
    setIsUpdateDatasetNameModalOpen
  } = props || {};

  const downloadFile = (data: string, fileName: string) => {
    const blob = new Blob([data], { type: "text/csv" });

    const url = window.URL.createObjectURL(blob);

    const anchorElement = document.createElement("a");
    anchorElement.setAttribute("href", url);
    anchorElement.setAttribute("download", fileName);
    anchorElement.click();
  };

  const formattedPredictionResult = () => {
    if (!prediction) {
      return;
    }

    const data = JSON.parse(prediction);

    if (isPlainObject(values(data)?.[0])) {
      // Determine the length of the data based on the first key
      const length = size(values(data)[0]);

      // Map over each index
      const result = map(range(length), (index) => {
        const obj: any = {};

        // Add the dynamic properties for each key
        forEach(keys(data), (key) => {
          obj[key] = data[key][index];
        });

        return obj;
      });

      return result;
    } else if (isArray(values(data)?.[0])) {
      // Dynamically handle input keys
      const inputKeys = keys(data); // Get the keys dynamically

      // Use the first key as the base for iteration length (assuming arrays are of the same length)
      const valuesArray = zip(...inputKeys.map((key) => data[key]));

      // Create the array of objects dynamically
      const result = map(valuesArray, (values) =>
        map(inputKeys, (key, index) => ({ [key]: values[index] })).reduce(
          (acc, obj) => ({ ...acc, ...obj }),
          {}
        )
      );

      return result;
    } else {
      return [data];
    }
  };

  const onDownload = () => {
    const data = formattedPredictionResult();

    if (!data) {
      return;
    }

    const csvData = Papa.unparse(data, {
      delimiter: ",",
      header: true
    });

    const downloadingFileName =
      value === uploadType.canvasDataset.name
        ? replace(getDatasetName(datasetId) || "", / /g, "-")
        : join(initial(split(fileName, ".")), ".");

    downloadFile(
      csvData,
      `${downloadingFileName ? `${downloadingFileName}-` : ""}prediction-results.csv`
    );
  };

  const createDataset = async (datasetName: string) => {
    checkEnvRelaunch(projectId);

    const requestJson = {
      projectId,
      name: datasetName,
      entityMeta: {
        entityOntology: "USER",
        entityType: "EVENT",
        separator: ",",
        encoding: "UTF_8"
      },
      autodetectionDisable: true
    };

    return await createEntityWithRethrow(requestJson);
  };

  const onAddToCanvas = async (datasetName: string) => {
    setIsAddingToCanvas(() => true);

    if (!projectId) {
      return;
    }

    try {
      const data = formattedPredictionResult();

      if (!data) {
        return;
      }

      const entityData = await createDataset(datasetName);

      // Signed-url creation
      const processingFileName = `predictionServiceResults-${now().toString().slice(-6)}.csv`;

      const signedUrlResponse = await getSignedUrlWithRethrow({
        params: { entityId: entityData?.id },
        options: {
          params: {
            fileName: processingFileName
          }
        }
      });

      // Signed-url execution
      const signedUrlData = signedUrlResponse[0];

      const apiOptions = {
        signedUrl: true,
        headers: signedUrlData?.headers
      };

      const csvData = Papa.unparse(data, {
        delimiter: ",",
        header: true
      });

      const blob = new Blob([csvData], { type: "text/csv" });
      const processingFile = new File([blob], processingFileName, { type: "text/csv" });

      await executeSignedUrlWithRethrow({
        url: signedUrlData?.signedUrl,
        data: processingFile,
        options: apiOptions
      });

      // Processing the file
      await processFilesWithRethrow({
        params: { entityId: entityData?.id },
        options: {
          params: {
            fileName: processingFileName
          }
        }
      });

      setIsAddedToCanvas(() => true);
      setIsUpdateDatasetNameModalOpen(() => false);

      toastWrapper({
        type: ToastTypes.Success,
        content: `Dataset ${datasetName} created successfully!`
      });
    } finally {
      setIsAddingToCanvas(() => false);
    }
  };

  return { onDownload, onAddToCanvas };
};

export default useActions;
