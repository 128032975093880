import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  statusContainer: {
    height: theme.spacing(4),
    padding: "0 22px",
    textTransform: "capitalize",
    fontWeight: 400,
    "& button": {
      height: 24,
      padding: 0,
      border: "none",
      background: "none",
      textTransform: "capitalize",
      fontSize: 12,
      fontWeight: 500,
      color: "#003656"
    }
  },
  shutdown: {
    backgroundColor: "#FF980014",
    color: "#C77700",
    "& button": { color: "#663d00" }
  },
  launching: {
    backgroundColor: "rgba(33, 150, 243, 0.08)",
    color: "#2196f3",
    "& svg": { color: "#0e3c61" }
  },
  stopping: {
    backgroundColor: "#FF980014",
    color: "#c77700",
    "& svg": { color: "#663d00" }
  },
  success: {
    backgroundColor: "#00800014",
    color: "#008000",
    "& button": { color: "#1d4620" }
  },
  failed: {
    backgroundColor: "rgba(244, 67, 54, 0.08)",
    color: "#DA1D27",
    "& button": { color: "#621b16" }
  },

  subtopBarStyles: {
    padding: "0 9px",
    borderRadius: "4px",
    "& button": {
      height: "24px",
      border: "none",
      color: "#007eda",
      background: "#ffffff",
      textTransform: "capitalize",
      fontSize: "14px",
      fontWeight: 400,
      borderRadius: "4px",
      padding: "0 9px"
    },
    "&.shutdown,\n  &.launching,\n  &.success,\n  &.failed": {
      "& button": { color: "#007eda" }
    }
  }
}));

export default useStyles;
