import React from "react";
import { IconButton, InputAdornment, InputBase } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Clear } from "@material-ui/icons";

const useStyles = makeStyles(() => ({
  input: {
    borderTopRightRadius: "20px",
    borderBottomRightRadius: "20px",
    flex: 2,
    padding: "5px 10px",
    background: "#fff"
  }
}));

interface IProps {
  disabled: boolean;
  value: string;
  onClick: () => void;
  onChange: (value: string) => void;
}

const GlobalSearchInput: React.FC<IProps> = (props) => {
  const { value, onChange, ...rest } = props;
  const classes = useStyles();

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    onChange("");
  };

  return (
    <InputBase
      className={classes.input}
      placeholder="Search..."
      value={value}
      data-testid="globalSearchInput"
      inputProps={{ "aria-label": "search" }}
      onChange={(e) => onChange(e.target.value)}
      endAdornment={
        value && (
          <InputAdornment position="end">
            <IconButton size="small" onClick={handleClick}>
              <Clear fontSize="small" />
            </IconButton>
          </InputAdornment>
        )
      }
      {...rest}
    />
  );
};

export default GlobalSearchInput;
