import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

import { getAPIWithRethrow } from "services/Apis/Apis.service";

export const QUERY_KEY_DATA_SOURCE_SYNCHED_DATA = "query-key-data-source-synched-data";

const useGetSynchedData = (
  connectorId: string,
  start: number = 0,
  end: number = 100,
  options?: UseQueryOptions<any, any>
): UseQueryResult<any> => {
  return useQuery({
    queryKey: [QUERY_KEY_DATA_SOURCE_SYNCHED_DATA, connectorId, start, end],
    queryFn: async () => {
      const secretResponse = await getAPIWithRethrow(
        `/v2/data-source/${connectorId}/connector/schema?tableCountStart=${start}&tableCountEnd=${end}`
      );

      return secretResponse;
    },
    refetchOnMount: true,
    enabled: !!connectorId,
    keepPreviousData: true,
    ...options
  });
};

export default useGetSynchedData;
