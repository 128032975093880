import { Grid, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import NewModal from "src/components/Modal/NewModal";
import EventBus from "services/EventBus/EventBus";
import useGetLogDetails from "../../../../../../hooks/useGetLogDetails";
import { PredictLogDtoStatusEnum } from "@rapidcanvas/rc-api-core";
import PrettyJsonEditor from "./PrettyJsonEditor";
import { EVENTBUS_EVENTS } from "constants/eventBus.constants";
import CommonLoader from "src/components/CommonLoader";

const useStyles = makeStyles({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    margin: "16px 0"
  },
  editor: {
    width: "100% !important",
    borderRadius: "6px",
    border: "1px solid rgba(0, 0, 0, 0.2)",
    height: "300px !important",

    "& .ace_print-margin": {
      visibility: "hidden !important"
    }
  },
  resultContainer: {
    gap: "5px"
  }
});

const LogDetailsModal: React.FC = () => {
  const [record, setRecord] = useState<any>();
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const logsDetails = useGetLogDetails(record?.serviceId, record?.id, { enabled: !!record });

  useEffect(() => {
    EventBus.subscribe(EVENTBUS_EVENTS.ShowLogDetails, (payload: any) => {
      setRecord(payload);
      setOpen(true);
    });
    return () => {
      EventBus.unsubscribe(EVENTBUS_EVENTS.ShowLogDetails);
    };
  }, []);

  const handleClose = () => {
    setOpen(false);
    setRecord(undefined);
  };

  const content = logsDetails.isLoading ? (
    <CommonLoader />
  ) : (
    <Grid container className={classes.resultContainer} direction="column">
      <PrettyJsonEditor jsonString={logsDetails.data?.request ?? ""} title="Request" />

      <PrettyJsonEditor
        jsonString={
          logsDetails.data?.status === PredictLogDtoStatusEnum.Success
            ? logsDetails.data?.response
            : logsDetails.data?.error
        }
        title={logsDetails.data?.status === PredictLogDtoStatusEnum.Success ? "Response" : "Error"}
      />
    </Grid>
  );

  return (
    <NewModal
      width={"70%"}
      open={open}
      header="Log Details"
      hideCancel
      submitButtonLabel="Close"
      onClose={handleClose}
      onFinish={handleClose}>
      {content}
    </NewModal>
  );
};

export default LogDetailsModal;
