import shallow from "zustand/shallow";
import _ from "lodash";

import useAuthStore from "stores/zustand/auth.store";
import { Roles, type RoleName } from "src/types";

export const useGetRole = (): {
  roleName: RoleName | null | undefined;
  isAdmin: boolean;
  hasAccess: boolean;
  hasDemoRole: boolean;
  isBusinessUser: boolean;
  isDataAppPowerUser: boolean;
  isConsumer: boolean;
  isRoleYieldsDataAppView: boolean;
  canAccessWithRole: (allowedRoles: string[]) => boolean;
} => {
  const roleName = useAuthStore((state) => state.roleName, shallow);

  const isAdmin = roleName === Roles.Admin.name;
  const isConsumer = roleName === Roles["DataApp Consumer"].name;
  const isDataAppPowerUser = roleName === Roles["DataApp Power User"].name;
  const hasAccess = _.includes([Roles.Admin.name, Roles.User.name], roleName);
  const hasDemoRole = roleName === Roles.Demo.name;

  const checkIsRoleYieldsDataAppView = (thisRoleName: RoleName | null | undefined) => {
    return (thisRoleName || "").startsWith(Roles.DataAppView.name);
  };

  const isRoleYieldsDataAppView = checkIsRoleYieldsDataAppView(roleName);
  const isBusinessUser = roleName === Roles["Business User"].name;
  const canAccessWithRole = (allowedRoles: string[]) => {
    return (
      (roleName && allowedRoles.includes(roleName)) ||
      (allowedRoles.includes(Roles.DataAppView.name) && checkIsRoleYieldsDataAppView(roleName))
    );
  };

  return {
    roleName,
    isAdmin,
    hasAccess,
    hasDemoRole,
    isConsumer,
    isDataAppPowerUser,
    canAccessWithRole,
    isRoleYieldsDataAppView,
    isBusinessUser
  };
};
