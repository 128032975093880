import React, { useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";

import { useDeleteAllAIGuideMessages, useGetAIGuideMessages } from "src/hooks/api";
import NewThemeWrapper from "src/styles/NewThemeWrapper";
import { AIGuideHeader } from "./AIGuideHeader";
import { DeleteQueryModal } from "../../AddCodeRecipe/CodeRecipeTabContainer/AskAIContainer/AskAIResponseContainer/DeleteQueryModal";
import AIGuideLeftbar from "./AIGuideLeftbar";
import AIGuideRightbar from "./AIGuideRightbar";
import { Spinner } from "src/components";
import { useAIGuideContext } from "./useAIGuideContext";

import { ThreadResponseDto } from "@rapidcanvas/rc-api-core";
import { handleResponse } from "services/Apis/Apis.service";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    background: "#dadada",
    height: "calc(100vh - 94px)",
    minHeight: "calc(100vh - 94px)",
    maxWidth: "100%",
    overflow: "hidden"
  },
  rightBar: {
    width: "80%",
    height: "calc(100vh - 94px)",
    maxWidth: "80%",
    flexBasis: "80%"
  }
});

export const AIGuideThreadsContainer = ({
  threads,
  activeThread,
  setActiveThread,
  onDeleteSuccess,
  projectId
}: {
  threads: ThreadResponseDto[];
  activeThread: ThreadResponseDto;
  setActiveThread: React.Dispatch<React.SetStateAction<ThreadResponseDto | undefined>>;
  onDeleteSuccess: (threadId: string) => void;
  projectId: string;
}) => {
  const { onClose } = useAIGuideContext();

  const classes = useStyles();

  const [showDeleteMessagesDialog, setShowDeleteMessagesDialog] = useState(false);

  const { isLoading, data: messages = [] } = useGetAIGuideMessages({
    threadId: activeThread.threadId
  });
  const deleteAllQueryMutation = useDeleteAllAIGuideMessages(projectId!);

  const onClearAll = () => {
    deleteAllQueryMutation.mutate(
      {
        threadId: activeThread?.threadId!
      },
      {
        onSuccess: () => {
          setShowDeleteMessagesDialog(false);
          handleResponse({ successMessage: "Chat history is cleared successfully." });
        }
      }
    );
  };

  return (
    <NewThemeWrapper>
      <AIGuideHeader
        onMinimize={onClose}
        onClearAll={() => setShowDeleteMessagesDialog(true)}
        isClearAllDisabled={messages?.length === 0 || isLoading}
      />
      {showDeleteMessagesDialog && (
        <DeleteQueryModal
          header="Clear History"
          deleteMsg={
            <div>This will delete all the queries and corresponding answers permanently.</div>
          }
          handleSubmit={onClearAll}
          onClose={() => setShowDeleteMessagesDialog(false)}
          loading={deleteAllQueryMutation.isLoading}
        />
      )}
      <Grid container direction="row" wrap="nowrap" className={classes.container}>
        <AIGuideLeftbar
          activeId={activeThread.threadId!}
          threads={threads}
          onThreadChange={setActiveThread}
          onDeleteSuccess={onDeleteSuccess}
          projectId={projectId}
        />
        <Grid container className={classes.rightBar}>
          {isLoading ? (
            <Spinner />
          ) : (
            <AIGuideRightbar activeThread={activeThread} threads={threads} messages={messages} />
          )}
        </Grid>
      </Grid>
    </NewThemeWrapper>
  );
};

export default React.memo(AIGuideThreadsContainer);
