import { useEffect } from "react";

// Packages
import { useForm } from "react-hook-form";
import { isEmpty, isNil, map } from "lodash";

// Open API
import { GlobalVariableDto, ProjectDto } from "@rapidcanvas/rc-api-core";

// Types
import { ProjectSettingsFormFieldsType } from "../ProjectSettings.types";

// Constants
import {
  defaultValues,
  defaultVariables,
  ProjectSettingsFormFields
} from "../utils/ProjectSettings.constants";

type Props = {
  isBusinessUser?: boolean;
  project?: ProjectDto;
  variables?: GlobalVariableDto[];
};

const useProjectSettingsForm = (props: Props) => {
  const { project, isBusinessUser, variables } = props || {};

  const formMethods = useForm<
    ProjectSettingsFormFieldsType<typeof isBusinessUser extends true ? false : true>
  >({
    mode: "onChange", // Validate onChange
    reValidateMode: "onChange", // Re-validate onChange
    // @TODO: The fields are initially empty and updated once the API response is received.
    // Resetting them to empty while interacting with the form is not considered a change, so dirtyFields does not detect it.
    // However, touchedFields captures this. To reliably detect changes, especially for optional fields that may be updated with empty values,
    // touchedFields should be used alongside dirtyFields until a better approach is found.
    defaultValues
  });

  const { setValue, clearErrors } = formMethods || {};

  const resetField = (fieldName: keyof ProjectSettingsFormFieldsType, value: any) => {
    // Clear value
    setValue(fieldName, value, {
      shouldDirty: false, // Clear from dirtyFields
      shouldTouch: false // Clear from touchedFields
    });

    // Clear any validation errors
    clearErrors(fieldName);
  };

  useEffect(() => {
    if (!isNil(variables) && !isEmpty(project)) {
      let image = project?.image || "";
      if (!!image && !/(http|https):\/\//.test(image)) {
        image = image?.split(";base64,")?.pop() || "";
        image = `data:image/jpeg;base64,${image}`;
      }

      resetField(ProjectSettingsFormFields.Name, project?.name || project?.display_name || "");
      resetField(ProjectSettingsFormFields.Description, project?.description ?? "");
      resetField(ProjectSettingsFormFields.Image, image);
      resetField(ProjectSettingsFormFields.EnvironmentType, project?.envId ?? "");
      resetField(ProjectSettingsFormFields.AskAiSystemMessage, project?.askAIContext ?? "");
    }
  }, [project]);

  const resetVariables = (paramVariables: GlobalVariableDto[]) =>
    resetField(
      ProjectSettingsFormFields.Variables,
      isEmpty(paramVariables)
        ? defaultVariables
        : map(paramVariables, (variable: GlobalVariableDto) => ({
            key: variable?.name || "",
            value: variable?.value || ""
          }))
    );

  return { formMethods, resetVariables };
};

export default useProjectSettingsForm;
