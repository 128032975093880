import React, { useEffect, useMemo } from "react";
import clsx from "clsx";

import _, { sortBy, toLower } from "lodash";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Tooltip,
  makeStyles
} from "@material-ui/core";
import InfoOutlined from "@material-ui/icons/InfoOutlined";

import EnvironmentTypeConfig from "src/pages/private/EnvironmentsModule/pages/common/EnvironmentTypeConfig";
import Text from "components/Widget/Text";
import styles from "src/pages/private/EnvironmentsModule/pages/common/Environments.module.scss";
import { useEnvironments } from "src/hooks";
import { EnvDto } from "@rapidcanvas/rc-api-core";
import { Spinner } from "src/components";
import { envMetadataFind } from "pages/Projects/helpers/projects.helpers";
import { statusValues } from "src/pages/private/EnvironmentsModule/utils/Environments.constants";
import { useGetEnvironmentTypes, useGetProject } from "src/hooks/api";
import { useStyles as useEnvStyles } from "src/pages/Projects/ProjectSettings/styling";

const useStyles = makeStyles(() => ({
  envTypeSpan: {
    fontSize: 12,
    color: "#838383"
  },
  flexEnd: {
    display: "flex",
    alignItems: "center",
    gap: "10px"
  }
}));

interface IProps {
  value: string;
  projectId?: string;
  tooltipInfoMsg?: string;
  onChange: (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => void;
  shouldSetDefaultValue?: boolean;
  name: string;
  label: string;
  onEnvChange?: (environment: EnvDto) => void;
}

const SelectEnvironment: React.FC<IProps> = (props) => {
  const classes = useStyles();
  const { envMenuItem, roundEnvStatus } = useEnvStyles();

  const projectResults = useGetProject({ projectId: props.projectId, refetchOnMount: true });
  const envId = projectResults.data?.envId;

  const envTypesResult = useGetEnvironmentTypes();
  const { data } = envTypesResult;

  const setDefault = (envs?: EnvDto[]) => {
    if (!props.value && props.shouldSetDefaultValue) {
      const defaultId = _.find(envs, { name: "DEFAULT" })?.id;

      if (envId) {
        props.onChange({
          target: { value: envId, name: props.name }
        } as React.ChangeEvent<HTMLInputElement>);
        return;
      }

      if (defaultId) {
        props.onChange({
          target: { value: defaultId, name: props.name }
        } as React.ChangeEvent<HTMLInputElement>);
      }
    }
  };

  const { isLoading: isFetchingEnvironments, data: environmentsData } = useEnvironments();

  const sorted = useMemo(() => {
    return sortBy(environmentsData, (environment) => toLower(environment?.name));
  }, [environmentsData]);

  useEffect(() => {
    const environment = _.find(environmentsData, { id: props.value });
    setDefault(environmentsData);
    if (environment) {
      props.onEnvChange?.(environment as EnvDto);
    }
  }, [environmentsData]);

  const handleChange = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    props.onChange(e);
    const env = _.find(environmentsData, { id: e.target.value });
    if (env) {
      props.onEnvChange?.(env as EnvDto);
    }
  };

  return (
    <Grid className={classes.flexEnd}>
      <FormControl variant="outlined" fullWidth>
        <InputLabel shrink htmlFor={props.name}>
          Environment *
        </InputLabel>
        <Select
          label={props.label}
          required
          fullWidth
          defaultValue=""
          id={props.name}
          data-testid={props.name}
          name={props.name}
          value={props.value}
          onChange={handleChange}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left"
            },
            getContentAnchorEl: null
          }}
          input={<OutlinedInput notched label="Environment *" />}
          displayEmpty>
          {!!isFetchingEnvironments ? (
            <Spinner padding={5} size={24} />
          ) : (
            sorted?.map((env: any) => {
              const { name: type } = envMetadataFind(data ?? [], env) || {};
              return (
                <MenuItem key={env.id} value={env.id}>
                  <Box className={envMenuItem}>
                    <Grid container direction="column">
                      <Text value={env.name} />
                      <span className={classes.envTypeSpan}>
                        <EnvironmentTypeConfig
                          envType={type}
                          cores={env?.cores}
                          memInMbs={env?.memInMbs}
                          diskInGbs={env?.diskInGbs}
                        />
                      </span>
                    </Grid>
                    <div
                      className={clsx(
                        roundEnvStatus,
                        styles.envStatus,
                        styles[env?.launchStatus?.trim()?.toLowerCase()]
                      )}>
                      {statusValues[env?.launchStatus?.trim()?.toLowerCase()]?.text ||
                        env?.launchStatus ||
                        "Unknown"}
                    </div>
                  </Box>
                </MenuItem>
              );
            })
          )}
        </Select>
      </FormControl>
      {props.tooltipInfoMsg && (
        <Tooltip title={props.tooltipInfoMsg}>
          <InfoOutlined fontSize="small" />
        </Tooltip>
      )}
    </Grid>
  );
};

export default SelectEnvironment;
