import React from "react";
import { Button, CircularProgress } from "@material-ui/core";
import { useParams } from "react-router-dom";

import CommonLoader from "components/CommonLoader";
import SomethingWentWrong from "components/Errors/SomethingWentWrong";
import useDataAppById from "hooks/api/dataapps/useDataAppById";
import EditDataAppCode from "./EditDataAppCode";

const EditDataAppCodeRoute: React.FC = () => {
  const { dataAppId } = useParams();

  const { data, isError, isLoading, refetch, isFetching } = useDataAppById(dataAppId);

  if (isError) {
    return (
      <SomethingWentWrong
        heading="Error in fetching dataApp details"
        extra={
          <Button
            variant="outlined"
            disabled={isFetching}
            startIcon={isFetching ? <CircularProgress size={16} /> : undefined}
            onClick={() => refetch()}>
            Retry
          </Button>
        }
      />
    );
  }

  if (isLoading) {
    return <CommonLoader />;
  }

  return <EditDataAppCode dataApp={data} />;
};

export default EditDataAppCodeRoute;
