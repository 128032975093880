export const getSnippets = (key?: string, extUserId?: string) => {
  const apiUrl = window.location.origin;
  return {
    "API Key Usage": [
      {
        title: "Curl",
        tooltip: "Curl",
        key: "usage-curl",
        code: `curl -X POST '${apiUrl}/api/v2/predict/MyPredictionService' \
--header 'content-type: application/json' \
--header 'X-API-KEY: ${key ?? "<RC_APIKEY>"}' \
--data-raw <JSON_DATA>`
      },
      {
        title: "Python",
        tooltip: "Python",
        key: "usage-python",
        code: `import requests
url = "${apiUrl}/api/v2/predict/MyPredictionService"
headers = {
    "content-type": "application/json",
    "X-API-KEY": "${key ?? "<RC_APIKEY>"}"
}
data = {
    # Your JSON data here
}
response = requests.post(url, headers=headers, json=data)`
      }
    ],
    "Get User Token from API Key": [
      {
        title: "Curl",
        tooltip: "Curl",
        key: "user-token-curl",
        code: `curl --location '${apiUrl}/api/access_key/token' \
--header 'accept: application/json' \
--header 'X-API-KEY: ${key ?? "<RC_APIKEY>"}'`
      },
      {
        title: "Python",
        tooltip: "Python",
        key: "user-token-python",
        code: `import requests
url = "${apiUrl}/api/access_key/token"
headers = {
    "accept": "application/json",
    "X-API-KEY": "${key ?? "<RC_APIKEY>"}"  # Replace with your actual API key
}
response = requests.get(url, headers=headers)
print("Token:", response.json()["token"])`
      }
    ],
    "Get User Token from API Key With Additional Parameters": [
      {
        title: "Curl",
        tooltip: "Curl",
        key: "user-token-curl-with-additional-parameters",
        code: `curl --location --request POST '${apiUrl}/api/access_key/token' \
--header 'Content-Type: application/json' \
--header 'X-API-KEY: ${key ?? "<RC_APIKEY>"}' \
--data '{
    "extUserId": "${extUserId ?? "<EXT_USER_ID>"}",
    "customProps": {
        "rc-ext-ds": "mysql_connector",
        "table-name": "mysql_table"
    }
}'`
      },
      {
        title: "Python",
        tooltip: "Python",
        key: "user-token-python-with-additional-parameters",
        code: `import requests

url = "${apiUrl}"
headers = {
    "Content-Type": "application/json",
    "X-API-KEY": "${key ?? "<RC_APIKEY>"}" # Replace with your actual API key
}
data = {
    "extUserId": "${extUserId ?? "<EXT_USER_ID>"}", # A unique identifier from the platform, used to generate an authentication token for RapidCanvas
    "customProps": {
        "rc-ext-ds": "mysql_connector", #The SQL connector name that must exist in the platform for the tenant you are generating the auth token for. 
        "table-name": "mysql_table"     #The name of the table in the SQL based connector. 
    }
}

response = requests.post(url, headers=headers, json=data)

# Print the response
print(response.status_code)
print(response.json())`
      }
    ]
  };
};

export const downloadMarkdown = (
  snippets: Record<string, any>,
  filename = "keys.md",
  key: string
) => {
  // Generate Markdown content
  const generateMarkdown = (snippets: Record<string, any>) => {
    let markdown = "";
    markdown += `## API Key\n\n`;
    markdown += `${key}\n`; // Snippet Code
    markdown += "\n\n"; // Code block ends

    Object.entries(snippets).forEach(([sectionTitle, items]) => {
      markdown += `## ${sectionTitle}\n\n`; // Section Title

      items.forEach(({ title, code }: any) => {
        markdown += `### ${title}\n\n`; // Snippet Title
        markdown += `${code}\n`; // Snippet Code
        markdown += "\n\n"; // Code block ends
      });
    });
    return markdown;
  };

  const markdownContent = generateMarkdown(snippets);

  // Create a Blob for the Markdown file
  const blob = new Blob([markdownContent], { type: "text/markdown" });
  const url = URL.createObjectURL(blob);

  // Create a temporary link to trigger the download
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);

  // Revoke the URL object to free memory
  URL.revokeObjectURL(url);
};
