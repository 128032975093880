// Packages
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { reject, some, uniqBy } from "lodash";

// Types
import { NodeData } from "src/types";

interface SelectionState {
  selectedNodes: NodeData[];
}

const initialState: SelectionState = {
  selectedNodes: []
};

const dagSlice = createSlice({
  name: "dag",
  initialState,
  reducers: {
    toggleNodeSelection: (state, action: PayloadAction<{ node: NodeData }>) => {
      const { node } = action.payload;
      const exists = some(state.selectedNodes, { id: node.id });

      state.selectedNodes = exists
        ? reject(state.selectedNodes, { id: node.id }) // Deselect
        : uniqBy([...state.selectedNodes, node], "id"); // Select
    },
    resetSelectedNodes: (state) => {
      state.selectedNodes = [];
    }
  }
});

export const { toggleNodeSelection, resetSelectedNodes } = dagSlice.actions;
export default dagSlice.reducer;
