/* eslint-disable no-unused-vars */
import React, { ReactNode } from "react";
import {
  Grid,
  Box,
  CircularProgress,
  Button,
  IconButton,
  TypographyProps,
  Tooltip
} from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

import { DeleteNew } from "src/assets/icons/DeleteNew";

import { CellContext, ColumnDef } from "@tanstack/react-table";
import { TData } from "./typing";

interface BaseColumn {
  id: string;
  accessor: string;
  header: React.ReactNode;
  isSortable?: boolean;
  isEditable?: boolean;
  cell?: (cell: CellContext<any, any>) => ReactNode;
  color?: TypographyProps["color"];
  fontWeight?: string | number;
  width?: number | string;
  sortDescFirst?: boolean;
  sortType?: any;
  filter?: any;
  defaultCanFilter?: boolean;
}
interface NumberColumn extends BaseColumn {
  type?: "number";
}

interface StringColumn extends BaseColumn {
  type?: "string";
}

interface SelectColumn extends BaseColumn {
  type: "select";
  values: { value: string; label: string | number }[];
  hideSelectInputLabel: boolean;
  selectVariant: string;
}

interface DateTimeColumn extends BaseColumn {
  type: "datetime";
  format?: string;
}

interface DateColumn extends BaseColumn {
  type: "date";
  format?: string;
}

export type InputColumn = StringColumn | NumberColumn | SelectColumn | DateTimeColumn | DateColumn;

type EditProps = {
  isEditable?: boolean;
  // Check if particular row can be edited or not
  canEdit?: (cell: any) => boolean;
  isInviteExpired: (cell: any) => boolean;
  isLoggedInUser: (cell: any) => boolean;
  handleEditClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, cell: any) => void;
  handleEditSave?: (cell: any) => void;
  handleEditCancel?: (cell: any) => void;
  isRowInEditMode?: (cell: any) => boolean;
  isSaveDisabled?: (cell: any) => boolean;
  isSaveInProgress?: (cell: any) => boolean;
};
type DeleteProps = {
  isDeletable?: boolean;
  isLoggedInUser: (cell: any) => boolean;
  // Check if particular row can be deleted or not
  canDelete?: (cell: any) => boolean;
  handleDeleteClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, cell: any) => void;
};

type FormTableCellProps = {
  inputColumns: ColumnDef<TData, any>[];
  size: string;
  editProps?: EditProps;
  deleteProps?: DeleteProps;
  renderExtraActions?: (cell: CellContext<any, any>) => React.ReactNode;
  isFilterable?: boolean;
};
const useStyles = makeStyles({
  subHeading: {
    fontSize: "14px"
  },
  gridGap: {
    gap: "8px"
  }
});

export const formTableCells = ({
  inputColumns,
  editProps,
  deleteProps,
  renderExtraActions
}: FormTableCellProps) => {
  const actionsColumn = formActionColumn({
    editProps,
    deleteProps,
    renderExtraActions
  });
  return actionsColumn ? [...inputColumns, actionsColumn] : inputColumns;
};

const formActionColumn = ({
  editProps,
  deleteProps,
  renderExtraActions
}: {
  editProps?: EditProps;
  deleteProps?: DeleteProps;
  renderExtraActions?: (cell: CellContext<any, any>) => React.ReactNode;
}) => {
  if (!editProps?.isEditable && !deleteProps?.isDeletable && !renderExtraActions) {
    return null;
  }
  const baseActionColumnProps = {
    id: "Actions",
    accessorKey: "id",
    header: "Actions",
    enableSorting: false
  };
  const actionCell = formActionCell({ editProps, deleteProps, renderExtraActions });
  return { ...baseActionColumnProps, cell: actionCell };
};

const formActionCell = ({
  editProps,
  deleteProps,
  renderExtraActions
}: {
  editProps?: EditProps;
  deleteProps?: DeleteProps;
  renderExtraActions?: (cell: CellContext<any, any>) => React.ReactNode;
}) => {
  const styles = useStyles();
  return function cell(cell: any) {
    const isEditing = editProps?.isRowInEditMode?.(cell);
    if (isEditing) {
      const isSaveDisabled = editProps?.isSaveDisabled?.(cell);
      const isSaveInProgress = editProps?.isSaveInProgress?.(cell);
      return (
        <Grid container className={styles.gridGap}>
          {isSaveInProgress ? (
            <Box display="flex" px="22px" alignItems="center">
              <CircularProgress size="20px" />
            </Box>
          ) : (
            <>
              <Button
                size="small"
                onClick={() => editProps?.handleEditSave?.(cell)}
                color="primary"
                disabled={isSaveDisabled}>
                Save
              </Button>
            </>
          )}
          <Button size="small" onClick={() => editProps?.handleEditCancel?.(cell)} color="primary">
            Cancel
          </Button>
          {renderExtraActions?.(cell)}
        </Grid>
      );
    }
    const canEdit = editProps?.canEdit?.(cell);
    const canDelete = deleteProps?.canDelete?.(cell);
    const inviteExpired = editProps?.isInviteExpired(cell);
    return (
      <Grid container className={styles.gridGap}>
        <Tooltip
          title={
            editProps?.isLoggedInUser(cell)
              ? "This action is not applicable for logged in user email"
              : inviteExpired
                ? "You cannot change the role of a user whose invitation link has expired"
                : ""
          }>
          <span>
            <IconButton
              aria-label="edit"
              component="div"
              size="small"
              disabled={!canEdit}
              onClick={(event) => editProps?.handleEditClick?.(event, cell)}
              color="primary">
              <Edit fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip
          title={
            deleteProps?.isLoggedInUser(cell)
              ? "This action is not applicable for logged in user email"
              : ""
          }>
          <span>
            <IconButton
              aria-label="delete"
              component="div"
              size="small"
              disabled={!canDelete}
              color="primary"
              onClick={(event) => deleteProps?.handleDeleteClick?.(event, cell)}>
              <DeleteNew disabled={!canDelete} size="small" />
            </IconButton>
          </span>
        </Tooltip>
        {renderExtraActions?.(cell)}
      </Grid>
    );
  };
};
