import React from "react";
import { MenuItem, Select, FormControl, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  label: {
    transform: "translate(14px, 9px) scale(1) !important"
  },
  labelShrink: {
    transform: "translate(10px, -6px) scale(0.75) !important"
  },
  selectRoot: {
    fontSize: "12px",
    padding: "6px 0px",
    height: "20px",
    width: "fit-content",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none"
    }
  },
  select: {
    padding: "0px 24px 0px 0px",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none"
    }
  },
  menuItem: { fontSize: "12px" }
});

const models = [
  { value: "gpt-4o", label: "GPT-4o" },
  { value: "o1", label: "O1" },
  { value: "o3-mini", label: "O3 Mini" }
];

const CopilotModelSelection = ({
  gptModel,
  setGptModel
}: {
  setGptModel: React.Dispatch<React.SetStateAction<string>>;
  gptModel: string;
}) => {
  const classes = useStyles();

  const handleChange = (event: any) => {
    setGptModel(event.target.value);
  };

  return (
    <FormControl variant="standard" size="small">
      <Select
        value={gptModel}
        onChange={handleChange}
        label="Model"
        className={classes.selectRoot}
        classes={{
          select: classes.select
        }}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 150,
              fontSize: "12px"
            }
          }
        }}>
        {models.map((model) => (
          <MenuItem key={model.value} value={model.value} className={classes.menuItem}>
            {model.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CopilotModelSelection;
