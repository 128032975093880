import React from "react";
import {
  Grid,
  Typography,
  TextField,
  makeStyles,
  TextFieldProps as MuiTextFieldProps
} from "@material-ui/core";
import { WidgetProps } from "@rjsf/utils";

const useStyles = makeStyles({
  root: {
    gap: "4px"
  },
  label: {}
});

type TextFieldProps = MuiTextFieldProps & {
  onChange: (value: string | number) => void;
};

const StringField = ({ value, onChange, label, required }: WidgetProps | TextFieldProps) => {
  const classes = useStyles();
  return (
    <Grid container direction="column" wrap="nowrap" item className={classes.root}>
      <Grid item>
        <Typography variant="body2" align="left" className={classes.label}>
          {label}
          {required && " *"}
        </Typography>
      </Grid>
      <Grid item>
        <TextField
          fullWidth
          value={value || ""}
          onChange={(e) => onChange(e.target.value)}
          variant="outlined"
          size="small"
        />
      </Grid>
    </Grid>
  );
};

export default StringField;
