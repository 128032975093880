import { last, map } from "lodash";
import { AIChatResponseDto } from "@rapidcanvas/rc-api-core";
import React from "react";
import EditChartChat from "./EditChartChat";

interface IProps {
  responses: AIChatResponseDto[];
  subThreadId?: string;
  onClose: () => void;
  removeQuery: (askAIMessageId: string) => void;
  askAIMessageId: string | undefined;
  parentThreadId: string | undefined;
  isSaveDisabled: boolean;
}

const EditChartConversation: React.FC<IProps> = (props) => {
  const {
    responses,
    subThreadId,
    onClose,
    removeQuery,
    askAIMessageId,
    parentThreadId,
    isSaveDisabled
  } = props;

  return (
    <>
      {map(responses, (response) => {
        return (
          <EditChartChat
            isSaveDisabled={isSaveDisabled}
            askAIMessageId={askAIMessageId}
            message={response}
            subThreadId={subThreadId}
            onClose={onClose}
            removeQuery={removeQuery}
            disableDelete={response !== last(responses)}
            parentThreadId={parentThreadId}
          />
        );
      })}
    </>
  );
};

export default EditChartConversation;
