import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { FeatureDto } from "@rapidcanvas/rc-api-core";

import api from "services/AxiosClient/AxiosClient";

export const CACHE_QUERY_KEY_FETCH_ALL_ENTITY_COLUMNS = "fetch-all-entity-columns";

const useGetAllEntityColumns = (
  entityId: string,
  scenarioId?: string,
  jobRunId?: string,
  options?: UseQueryOptions<FeatureDto[], Error>
): UseQueryResult<FeatureDto[], Error> => {
  return useQuery<FeatureDto[], Error>({
    queryKey: [CACHE_QUERY_KEY_FETCH_ALL_ENTITY_COLUMNS, entityId, scenarioId, jobRunId],
    queryFn: async () => {
      const data = await api.fetchResponse(
        async () => await api.FeatureControllerApi.findFeatures(entityId, scenarioId, jobRunId)
      );

      return data;
    },
    ...options
  });
};

export default useGetAllEntityColumns;
