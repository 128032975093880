import React from "react";
import { useTheme, makeStyles } from "@material-ui/core";

import OverflowTooltip from "src/components/OverflowTooltip";

interface IProps {
  title?: string;
  searchText: string;
  extra?: React.JSX.Element;
  onClick?: () => void;
}

const useStyles = makeStyles((theme) => ({
  textLink: {
    cursor: "pointer",
    color: "black",
    textDecoration: "none",
    fontSize: "14px",

    "&:hover": {
      textDecoration: "none",
      color: theme.palette.primary.main
    }
  }
}));

const SearchTitle: React.FC<IProps> = (props) => {
  const { title, searchText, extra, onClick } = props;

  const theme = useTheme();
  const { textLink } = useStyles();

  if (!title) {
    return <div>(No title)</div>;
  }

  if (!searchText) {
    return <div>{title}</div>;
  }

  const escapeRegExp = (text: string) => text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");

  const safeSearchText = escapeRegExp(searchText);
  const parts = title.split(new RegExp(`(${safeSearchText})`, "gi"));

  return (
    <OverflowTooltip
      style={{ whiteSpace: "nowrap", maxWidth: "100%" }}
      value={
        <a className={textLink} onClick={onClick}>
          {parts.map((part, index) =>
            part.toLowerCase() === searchText.toLowerCase() ? (
              <span key={index} style={{ color: theme.palette.primary.main }}>
                {part}
              </span>
            ) : (
              <span key={index}>{part}</span>
            )
          )}
          <span style={{ marginLeft: "8px" }}>{extra}</span>
        </a>
      }
    />
  );
};

export default SearchTitle;
