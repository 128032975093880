import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import React from "react";
import { Link, generatePath } from "react-router-dom";
import { isFunction } from "lodash";

import EditName from "src/layout/NavBars/components/SubTopNavBar/EditName";
import { RenderText } from "components/custom";
import { WebPaths } from "src/routing/routes";
import { SCHEDULER_TABS, TAB } from "./PredictionRunScheduler/Schedulers";

interface IProps {
  project: $TSFixMe;
  predictionJobName?: string;
  onUpdate?: (name: string) => void;
}

const FONT_SIZE = 14;

const SubTopNavBarBreadcrumbs: React.FC<IProps> = (props) => {
  const { project, predictionJobName, onUpdate } = props;

  const breadcrumbs: React.ReactNode[] = [];

  breadcrumbs.push(
    !!project?.id && (
      <Link key="projects" to={`/projects/${project?.id}/canvas`}>
        <RenderText>{project?.name || "Project"}</RenderText>
      </Link>
    )
  );

  breadcrumbs.push(!!project?.id && <RenderText key="prediction">Predictions</RenderText>);

  breadcrumbs.push(
    !!project?.id && (
      <Link
        key="prediction-name"
        state={{ [TAB]: SCHEDULER_TABS.ManualPrediction }}
        to={generatePath(WebPaths.PredictionJob, { projectId: project?.id })}>
        <RenderText>Manual Predictions</RenderText>
      </Link>
    )
  );

  if (isFunction(onUpdate) && predictionJobName) {
    breadcrumbs.push(
      <EditName
        fieldNameLabel="prediction-run name"
        key="edit-name"
        inputValue={predictionJobName}
        onSave={onUpdate}
        containerProps={{
          style: {
            width: ((predictionJobName || "")?.length + 1) * (FONT_SIZE - 4) + 16
          }
        }}
      />
    );
  }

  return (
    <Breadcrumbs>
      {breadcrumbs?.map((eachBreadcrumb: React.ReactNode) => eachBreadcrumb)}
    </Breadcrumbs>
  );
};

export default SubTopNavBarBreadcrumbs;
