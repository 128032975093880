import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";

import api from "services/AxiosClient/AxiosClient";
import { ProjectDto, UpdateProjectDto } from "@rapidcanvas/rc-api-core";

import { updateProjectsQueryData } from "src/utils/helpers";

const useUpdateProject = (): UseMutationResult<ProjectDto, unknown, UpdateProjectDto, unknown> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (payload: UpdateProjectDto) =>
      await api.fetchResponse(
        async () => await api.ProjectControllerApi.updateProject({ ...payload })
      ),
    onSuccess: (data) => {
      updateProjectsQueryData({ queryClient, data });
    }
  });
};

export default useUpdateProject;
