import React, { SetStateAction } from "react";

import Popover from "@material-ui/core/Popover";
import makeStyles from "@material-ui/core/styles/makeStyles";

type Props = {
  id?: string;
  contextMenuAnchorEl: SetStateAction<Element | null>;
  closeContextMenu: () => void;
  keepMounted?: boolean;
  children: React.ReactNode;
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: -theme.spacing(2.5),
    boxShadow: "none",
    borderRadius: 0,
    backgroundColor: "transparent"
  },
  container: {
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.common.black,
    overflow: "hidden"
  }
}));

const ContextMenuWrapper = (props: Props) => {
  const {
    id = "nodeContextMenu",
    contextMenuAnchorEl,
    closeContextMenu,
    keepMounted = false,
    children
  } = props || {};

  const classes = useStyles();

  // onContextMenu of the popover background.
  const onContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();

    if (Boolean(contextMenuAnchorEl)) {
      closeContextMenu();
    }
  };

  return (
    <Popover
      id={id}
      open={Boolean(contextMenuAnchorEl)}
      // @ts-ignore
      anchorEl={contextMenuAnchorEl}
      onClose={closeContextMenu}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right"
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "left"
      }}
      PaperProps={{
        className: classes.root
      }}
      onContextMenu={onContextMenu}
      keepMounted={keepMounted}>
      <div className={classes.container}>{children}</div>
    </Popover>
  );
};

export default ContextMenuWrapper;
