// Packages
import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import { isEmpty } from "lodash";

// Open API
import { DFSRunConfigGroupDto } from "@rapidcanvas/rc-api-core";
import api from "services/AxiosClient/AxiosClient";
import { UseGetProjectCanvasQueryKeys } from "./useGetProjectCanvas";

export const enum UseCloneRecipeQueryKeys {
  CloneRecipe = "cloneRecipe"
}

interface IPayload {
  id?: string;
}

interface IProps {
  id?: string;
}

const useCloneRecipe = (
  props?: IProps
): UseMutationResult<DFSRunConfigGroupDto, unknown, IPayload, unknown> => {
  const { id } = props || {};

  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [UseCloneRecipeQueryKeys.CloneRecipe, id],
    mutationFn: async ({ id }: IPayload) => {
      if (!id) {
        throw "Invalid payload!";
      }

      return await api.fetchResponse(
        async () => await api.DfsRunConfigGroupControllerV2Api.cloneRecipe(id)
      );
    },
    onSuccess: async (data) => {
      if (!isEmpty(data)) {
        await queryClient.invalidateQueries([
          UseGetProjectCanvasQueryKeys.ProjectCanvas,
          data?.projectId
        ]);
      }
    }
  });
};

export default useCloneRecipe;
