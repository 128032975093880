import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";

import PredictionJobDefaultIcon from "icons/NewUX/PredictionJobDefaultIcon";
import PredictionJobAnimation from "./prediction-job-animations/PredictionJobAnimation";

const useStyles = makeStyles({
  grid: {
    alignItems: "center",
    minHeight: "calc(100vh - 127px)",
    gap: "10px",
    justifyContent: "center"
  }
});

interface IProps {
  loading?: boolean;
  extra?: React.JSX.Element;
}

const PredictionJobDefault: React.FC<IProps> = (props) => {
  const { grid } = useStyles();

  return (
    <Grid container direction="column" className={grid}>
      {props.loading ? <PredictionJobAnimation /> : <PredictionJobDefaultIcon />}
      {!props.loading && <Typography variant="h6">How Prediction Output is Generated?</Typography>}
      <Typography variant="subtitle1" style={{ color: "#515151" }}>
        {props.loading
          ? "This action might take time. Feel free to navigate to other pages and we shall update you once the output is ready"
          : "Your data is passed through the same set of steps which were used for building the model. At the final step, the model is used to generate predictions"}
      </Typography>
      {props.extra}
    </Grid>
  );
};

export default PredictionJobDefault;
