import React, { useMemo } from "react";
import clsx from "clsx";
import { Handle, Position } from "react-flow-renderer";
import { Grid, Tooltip, makeStyles } from "@material-ui/core";
import { useParams, useLocation } from "react-router-dom";

import { addEllipsis } from "utils/helpers";
import { useDrawerStore, useCanvasStore } from "stores/zustand/stores";
import { sideComponentSetter } from "stores/zustand/stores.selectors";
import { ModelModalV2 } from "../CanvasDrawerItems/ModelModalV2";
import { useSaveEntityStorageHelper } from "./useSaveEntityStorageHelper";
import { useCtrlKeyPress } from "src/hooks/useCtrlKeyPress";
import EmojiEventsIcon from "@material-ui/icons/EmojiEvents";
import { useStyles as useCommonBlockStyles } from "./commonBlockStyles";
import { shouldHighlight } from "../helpers/CanvasFlow.helpers";

const useStyles = makeStyles(() => ({
  modelBlockStyles: {
    backgroundColor: ({ isBuilt }: { isBuilt: boolean }) => (isBuilt ? "#7358DE" : "#b6b7b9"),
    border: "1px solid #003656"
  }
}));

const ModelBlockV2 = ({ data }: $TSFixMe) => {
  const { scenarioId } = useParams<$TSFixMe>();

  const location = useLocation();
  const isJobCanvasPath = useMemo(() => /job-canvas/.test(location.pathname), [location.pathname]);

  const classes = useStyles({ isBuilt: data?.status === "BUILT" });
  const commonClasses = useCommonBlockStyles();

  const setSideComponent = useDrawerStore(sideComponentSetter);
  const shouldBlockClickHandlerTrigger = useCanvasStore(
    (state) => state.shouldBlockClickHandlerTrigger
  );
  const nodeToFocus = useCanvasStore((state) => state.nodeToFocus);
  const { isCtrlPressed } = useCtrlKeyPress();
  const { isEntitySelected, handleMultiSelection } = useSaveEntityStorageHelper({
    data,
    sessionKey: "selectedModels"
  });

  const disabledBlockMessage = useMemo(() => {
    if (!!data?.isJobCanvas && ["UNBUILT", "EMPTY", "SKIPPED"].includes(data?.status)) {
      return `Certain click actions are restricted as supplementary details for the specified model ${data?.label} are not accessible in the context of this scheduler run entry.`;
    }

    return "";
  }, [data?.isJobCanvas, data?.status]);

  const handleModelClick = () => {
    if (!!disabledBlockMessage) {
      return;
    }

    if (!!data?.jobProps) {
      if (!isJobCanvasPath) {
        return;
      }
    }

    if (shouldBlockClickHandlerTrigger || data?.shouldDisableBlockInteraction) {
      return;
    }
    if (isCtrlPressed) {
      handleMultiSelection();
      return;
    }
    setSideComponent({
      sideComponent: ModelModalV2,
      sideComponentProps: {
        noHeader: true,
        data: { ...data, scenarioId: scenarioId || data?.scenarioId }
      }
    });
  };

  const isSelected = isEntitySelected();

  return (
    <>
      <Handle type="target" position={Position.Left} />
      <Tooltip title={disabledBlockMessage} placement="top">
        <Grid
          className={clsx(commonClasses.blockContainer, classes.modelBlockStyles, {
            [commonClasses.highlighted]: shouldHighlight(data?.label, nodeToFocus)
          })}
          onClick={handleModelClick}>
          <div className={commonClasses.labelContainer}>
            <div className={commonClasses.iconContainer}>
              <EmojiEventsIcon />
            </div>
            <div
              className={clsx(commonClasses.label, {
                [commonClasses.labelActive]: isSelected
              })}>
              {addEllipsis(data.label)}
            </div>
          </div>
        </Grid>
      </Tooltip>
      <Handle type="source" position={Position.Right} />
    </>
  );
};

export default ModelBlockV2;
