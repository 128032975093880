import React, { useMemo } from "react";

import { find, get, last, size, some, split, toLower } from "lodash";

import { Grid, Typography, Tooltip, makeStyles } from "@material-ui/core";

import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";

import fileTypes from "constants/fileTypes.constants";
import { ellipses } from "utils/helpers/string.helpers";
import { formatBytes } from "utils/helpers";

import { DatasetHelperText } from "../../../utils/Dataset.constants";

import { FileMetaDataPropsType } from "../../../types/Props.type";

const useStyles = makeStyles(() => ({
  root: {
    height: "100%",
    flexWrap: "nowrap",
    gap: 10,
    "& .iconContainer": {
      display: "flex",
      "& svg": {
        margin: "auto",
        width: 43,
        height: 45,
        color: "#4646b5"
      }
    },
    "& .fileAttributesWrapper": {
      height: "inherit",
      flexGrow: 1
    },
    "& .fileAttributesContainer": {
      height: "inherit"
    }
  },
  fileAttributesSmall: {
    fontSize: 12
  }
}));

const FileMetaData = (props: FileMetaDataPropsType) => {
  const { index, file, isDataSourcesFilesUpload } = props || {};

  const classes: $TSFixMe = useStyles();

  const fileTypeLabel = useMemo(() => {
    // Checking for the file's MIME type matches any predefined file types
    const matchedFileType = find(fileTypes, ({ mimeTypes }) =>
      some(mimeTypes, (mimeType) => mimeType === file?.type)
    );

    // If MIME type is not found, checking file extension instead
    const fileExtension = toLower(last(split(file?.name, ".")));
    const extensionFileType = get(fileTypes, fileExtension, {});

    const label = get(matchedFileType || extensionFileType, "label");

    if (!!label) return label;

    if (!!file?.type) {
      return (
        <Tooltip id={`fileTypeTooltip_${index}`} title={size(file?.type) > 7 ? file?.type : ""}>
          <span id={`fileType_${index}`}>{ellipses({ text: file?.type, length: 7 })}</span>
        </Tooltip>
      );
    }

    return "";
  }, [file?.type]);

  return (
    <>
      <Grid container alignItems="center" className={classes.root}>
        <Grid item className="iconContainer">
          {<DescriptionOutlinedIcon />}
        </Grid>
        <Grid item className="fileAttributesWrapper">
          <Grid
            container
            direction="column"
            justifyContent={
              !!fileTypeLabel && !isDataSourcesFilesUpload ? "space-between" : "center"
            }
            className="fileAttributesContainer">
            {file?.name ? (
              <Typography variant="subtitle2">
                <Tooltip id={`fileName_${index}`} title={file?.name?.length > 25 ? file?.name : ""}>
                  <span>{ellipses({ text: file?.name, length: 25 })}</span>
                </Tooltip>
              </Typography>
            ) : (
              <Typography id={`fileNameUnknown_${index}`} variant="subtitle2">
                {DatasetHelperText.Unknown}
              </Typography>
            )}

            {(!!fileTypeLabel || !isDataSourcesFilesUpload) && (
              <Grid container className={classes.fileAttributesSmall}>
                {!isDataSourcesFilesUpload && (
                  <Grid id={`fileSizeLabel_${index}`} item xs={6}>
                    {`${DatasetHelperText.FileSize}: `}
                    <span id={`fileSize_${index}`}>
                      {file?.size ? formatBytes(file.size) : "0 KB"}
                    </span>
                  </Grid>
                )}
                {!isDataSourcesFilesUpload && <Grid xs={1}></Grid>}
                {!!fileTypeLabel && (
                  <Grid id={`fileTypeLabel_${index}`} item xs={5}>
                    {`${DatasetHelperText.FileType}: `}
                    {fileTypeLabel}
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default FileMetaData;
