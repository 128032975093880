// Packages
import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import { isEmpty } from "lodash";

// Open API
import api from "services/AxiosClient/AxiosClient";
import {
  ProjectRunDto,
  ProjectRunDtoJobTypeEnum,
  UpdateProjectRunDto
} from "@rapidcanvas/rc-api-core";

// Utils
import { updateJobsQueryData, updatePredictionSchedulersQueryData } from "utils/helpers";

const useUpdateJob = (): UseMutationResult<
  ProjectRunDto,
  unknown,
  UpdateProjectRunDto,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (payload: UpdateProjectRunDto) => {
      if (!payload?.id) {
        throw "Invalid payload!";
      }

      return await api.fetchResponse(
        async () => await api.ProjectRunControllerApi.updateProjectRun(payload)
      );
    },
    onSuccess: (data) => {
      if (!isEmpty(data)) {
        if (data?.jobType === ProjectRunDtoJobTypeEnum.ProjectJob) {
          updateJobsQueryData({ queryClient, data, fetchData: true });
        }

        if (data?.jobType === ProjectRunDtoJobTypeEnum.PredictionJob) {
          updatePredictionSchedulersQueryData({ queryClient, data, fetchData: true });
        }
      }
    }
  });
};

export default useUpdateJob;
