export enum EnvironmentConfigConfig {
  EnvironmentMinInactivity = 1,
  EnvironmentMaxInactivity = 24
}

export enum EnvironmentConfigFormFields {
  EnvShutdownTimeout = "envShutdownTimeout",
  EnvShutdownStrategyType = "envShutdownStrategyType"
}

export const EnvironmentConfigFormFieldsNameMapping = {
  [EnvironmentConfigFormFields.EnvShutdownTimeout]: "Shutdown Environment if Inactive for",
  [EnvironmentConfigFormFields.EnvShutdownStrategyType]: "Evergreen"
};

export const defaultValues = {
  [EnvironmentConfigFormFields.EnvShutdownTimeout]: undefined,
  [EnvironmentConfigFormFields.EnvShutdownStrategyType]: false
};

export enum EnvironmentConfigClosePromptDetails {
  title = "Confirm",

  messageLine1 = "If you leave you will lose any unsaved changes.",
  messageLine2 = "Do you really want to close this window?",

  cancelLabel = "No"
}

export enum EnvironmentConfigUpdatePromptDetails {
  title = "Save Configuration",

  messageLine1 = "Modifications to the shutdown time for the running environment will take effect upon relaunching the environment.",

  cancelLabel = "No"
}

export enum EnvironmentConfigHelperText {
  EnvShutdownStrategyTypeInfo = "Enabling this option will always keep this environment up and running",
  Cancel = "Cancel",
  Save = "Save"
}
