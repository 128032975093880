import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: 375,
    height: 370,
    border: `1px solid ${theme.palette.background.paper}`,
    borderRadius: theme.spacing(1.5),
    cursor: "pointer",
    "& .actions": {
      visibility: "hidden",
      position: "absolute",
      right: 0
    },
    "&:hover": {
      backgroundColor: "#f4f4ff",
      borderColor: theme.palette.primary.main,
      "& .actions": {
        visibility: "visible"
      }
    },
    "& [class^='MuiCardHeader-avatar']": {
      marginRight: 0
    }
  },
  cardHeaderContent: {
    width: "90%"
  },
  cardHeaderAction: {
    position: "relative"
  },
  moreOptionListItem: {
    minWidth: 28,
    "& svg": {
      height: 19
    }
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    paddingTop: 0,
    color: theme.palette.text.secondary
  },
  descriptionContainer: { width: "100%", height: 40 },
  updaterContainer: {
    marginTop: theme.spacing(2),
    columnGap: theme.spacing(1)
  },
  updaterAvatar: {
    height: 28,
    width: 28,
    fontSize: 20,
    backgroundColor: theme.palette.text.primary,
    color: theme.palette.getContrastText(theme.palette.text.primary)
  },
  cardActions: {
    padding: 0,
    paddingBottom: theme.spacing(0.5)
  },

  link: {
    display: "inline-block",
    color: "#003656",
    "&:hover": {
      color: "#003656",
      textDecoration: "underline"
    }
  }
}));

export default useStyles;
