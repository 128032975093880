import React from "react";

export const PersonIconV3 = ({ size = "medium" }: { size?: "small" | "medium" }) => {
  if (size === "small") {
    return (
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M23.7238 12C23.7238 5.70396 18.6199 0.600006 12.3238 0.600006C6.02778 0.600006 0.923828 5.70396 0.923828 12C0.923828 18.2961 6.02778 23.4 12.3238 23.4C18.6199 23.4 23.7238 18.2961 23.7238 12Z"
          stroke="#FF5C00"
          stroke-width="1.1"
        />
        <path
          d="M12.3359 13.0805C14.2035 13.0805 15.7109 11.573 15.7109 9.70547C15.7109 7.83797 14.2035 6.33047 12.3359 6.33047C10.4684 6.33047 8.96094 7.83797 8.96094 9.70547C8.96094 11.573 10.4684 13.0805 12.3359 13.0805ZM12.3359 7.34297C13.6409 7.34297 14.6985 8.40047 14.6985 9.70547C14.6985 11.0105 13.6409 12.0679 12.3359 12.0679C11.0309 12.0679 9.97344 11.0105 9.97344 9.70547C9.97344 8.40047 11.0309 7.34297 12.3359 7.34297Z"
          fill="#FF5C00"
        />
        <path
          d="M18.9739 16.77C17.1289 15.2176 14.7889 14.3626 12.3363 14.3626C9.88382 14.3626 7.54382 15.2176 5.69882 16.77C5.49632 16.95 5.45132 17.265 5.63132 17.49C5.81132 17.6926 6.12632 17.7376 6.35132 17.5576C8.01632 16.14 10.1313 15.375 12.3363 15.375C14.5413 15.375 16.6563 16.14 18.2989 17.535C18.3889 17.625 18.5013 17.6475 18.6363 17.6475C18.7713 17.6475 18.9289 17.58 19.0189 17.4675C19.1989 17.265 19.1763 16.95 18.9739 16.77Z"
          fill="#FF5C00"
        />
      </svg>
    );
  }
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="#FFF8F3" />
      <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" stroke="#FF5C00" />
      <path
        d="M15.9999 17.2004C18.0749 17.2004 19.7499 15.5254 19.7499 13.4504C19.7499 11.3754 18.0749 9.70044 15.9999 9.70044C13.9249 9.70044 12.2499 11.3754 12.2499 13.4504C12.2499 15.5254 13.9249 17.2004 15.9999 17.2004ZM15.9999 10.8254C17.4499 10.8254 18.6249 12.0004 18.6249 13.4504C18.6249 14.9004 17.4499 16.0754 15.9999 16.0754C14.5499 16.0754 13.3749 14.9004 13.3749 13.4504C13.3749 12.0004 14.5499 10.8254 15.9999 10.8254Z"
        fill="#FF5C00"
      />
      <path
        d="M23.3747 21.3C21.3247 19.575 18.7247 18.625 15.9997 18.625C13.2747 18.625 10.6747 19.575 8.62473 21.3C8.39973 21.5 8.34973 21.85 8.54973 22.1C8.74973 22.325 9.09973 22.375 9.34973 22.175C11.1997 20.6 13.5497 19.75 15.9997 19.75C18.4497 19.75 20.7997 20.6 22.6247 22.15C22.7247 22.25 22.8497 22.275 22.9997 22.275C23.1497 22.275 23.3247 22.2 23.4247 22.075C23.6247 21.85 23.5997 21.5 23.3747 21.3Z"
        fill="#FF5C00"
      />
    </svg>
  );
};
