import { Grid, Box, Typography, useTheme } from "@material-ui/core";
import { isEmpty } from "lodash";
import React from "react";

import CommonLoader from "src/components/CommonLoader";
import EnvironmentPredictionServiceTable from "./EnvironmentPredictionServiceTable";
import { IPredictionService } from "../../Environment";

interface IProps {
  isLoading: boolean;
  predictionServices: IPredictionService[];
}
export const PredictionServicesTab: React.FC<IProps> = ({ predictionServices, isLoading }) => {
  const theme = useTheme();

  return (
    <Grid
      container
      style={{
        height: isEmpty(predictionServices) ? "inherit" : "initial",
        rowGap: theme.spacing(2)
      }}>
      {isLoading ? (
        <CommonLoader />
      ) : isEmpty(predictionServices) ? (
        <>
          <Box m="auto">
            <Typography variant="body2" color="textSecondary" align="center" gutterBottom>
              The environment is not used in any prediction service yet.
            </Typography>
            <Typography variant="body2" color="textSecondary" align="center">
              After you use it for any prediction service, all of them will be listed here.
            </Typography>
          </Box>
        </>
      ) : (
        <EnvironmentPredictionServiceTable data={predictionServices} />
      )}
    </Grid>
  );
};
