import { useMutation, UseMutationResult } from "@tanstack/react-query";

import api from "services/AxiosClient/AxiosClient";
import { CreateScenarioDto, ScenarioDto } from "@rapidcanvas/rc-api-core";

const useSaveScenario = (): UseMutationResult<ScenarioDto, unknown, CreateScenarioDto, unknown> =>
  useMutation({
    mutationFn: async (payload: CreateScenarioDto) =>
      await api.fetchResponse(async () => await api.ScenarioControllerApi.create2({ ...payload }))
  });

export default useSaveScenario;
