import { map } from "lodash";
import { monaco } from "react-monaco-editor";

const helperFunctionList = [
  "initH2o",
  "get_system_feature_store_redis_host",
  "addParam",
  "getAllParams",
  "getParam",
  "get_global_var",
  "get_secret",
  "getOrCreateArtifactsDir",
  "uploadArtifacts",
  "downloadArtifacts",
  "get_artifact",
  "getTenantId",
  "getOrCreateContext",
  "getAllEntities",
  "getEntityData",
  "get_data_from_source",
  "write_data_to_source",
  "get_datasource",
  "getEntityFilePath",
  "getChildDir",
  "getOutputCollection",
  "generate_warning",
  "save",
  "validate_artifact_added",
  "get_rc_ml_model",
  "getValueFromCacheMap",
  "createTemplateOutput",
  "create_template_output_rc_ml_model",
  "create_template_output_answer",
  "create_template_output_metadata",
  "create_template_output_artifact",
  "createTemplateOutputDataset",
  "create_template_output_chart",
  "createTemplateOutputEChart",
  "createTemplateOutputPlotlibChart",
  "createTemplateOutputPlotlyChart",
  "createTemplateOutputPlotlyChartAsJson",
  "createOutputCollection",
  "get_or_create_input_var",
  "get_or_create_input_column_var",
  "get_or_create_input_data_source_var",
  "get_or_create_chart_app_var",
  "get_or_create_input_dataset",
  "get_or_create_output_dataset",
  "get_or_create_output_chart",
  "save_all_vars",
  "get_metadata_value",
  "get_all_metadata"
];
export const helperSuggestions = map(helperFunctionList, (item) => ({
  label: item,
  kind: monaco.languages.CompletionItemKind.Function,
  insertText: `Helpers.${item}`,
  documentation: `${item} function`,
  command: {
    id: "auto-import",
    title: "Add Helper import",
    arguments: ["from utils.notebookhelpers.helpers import Helpers"]
  }
}));

export const helperFunctionSignatures = {
  initH2o: {
    label: "initH2o(h2o=None, h2oServerUrl=None, init_type=1)",
    documentation:
      "init_type: 0 - connect only, 1-(Default) - init local if cannot connect\nReturns: initialized h2o object",
    parameters: [
      { label: "h2o", documentation: "h2o object or None" },
      { label: "h2oServerUrl", documentation: "URL of the h2o server or None" },
      { label: "init_type", documentation: "Type of initialization" }
    ]
  },
  get_system_feature_store_redis_host: {
    label: "get_system_feature_store_redis_host()",
    documentation: "",
    parameters: []
  },
  addParam: {
    label: "addParam(context, paramKey, paramVal)",
    documentation: "Adds the parameter to the context",
    parameters: [
      { label: "context", documentation: "The context to add the parameter to" },
      { label: "paramKey", documentation: "Key of the parameter" },
      { label: "paramVal", documentation: "Value of the parameter" }
    ]
  },
  getAllParams: {
    label: "getAllParams(context)",
    documentation: "Get all the parameters in the context",
    parameters: [{ label: "context", documentation: "The context to retrieve parameters from" }]
  },
  getParam: {
    label: "getParam(context, param)",
    documentation:
      "Get the parameter from the context given the parameter key, if param doesn’t exist, it returns None",
    parameters: [
      { label: "context", documentation: "The context containing the parameter" },
      { label: "param", documentation: "Key of the parameter" }
    ]
  },
  get_global_var: {
    label: "get_global_var(context, key)",
    documentation:
      "Get the global variable from the context given the key, if key doesn’t exist, it returns None",
    parameters: [
      { label: "context", documentation: "The context containing the global variable" },
      { label: "key", documentation: "Key of the global variable" }
    ]
  },
  get_secret: {
    label: "get_secret(context: dict, key: str)",
    documentation:
      "Get the secret value from the context given the key, if key doesn’t exist, it returns None",
    parameters: [
      { label: "context", documentation: "The context containing the secret" },
      { label: "key", documentation: "Key of the secret" }
    ]
  },
  getOrCreateArtifactsDir: {
    label: "getOrCreateArtifactsDir(context, artifactsId, purgeOld=False)",
    documentation:
      "This is used to create artifacts directory, you can save all the artifacts in this directory",
    parameters: [
      { label: "context", documentation: "The context to create the directory in" },
      { label: "artifactsId", documentation: "ID of the artifact" },
      { label: "purgeOld", documentation: "Whether to purge old artifacts" }
    ]
  },
  uploadArtifacts: {
    label: "uploadArtifacts(context)",
    documentation: "",
    parameters: [{ label: "context", documentation: "The context to upload artifacts from" }]
  },
  list_artifact_files: {
    label: "list_artifact_files(context, artifacts_id)",
    documentation:
      "List the files present in the artifact without downloading the artifact. This will not work when doing prepare for local",
    parameters: [
      { label: "context", documentation: "The context to list artifact files from" },
      { label: "artifacts_id", documentation: "ID of the artifact" }
    ]
  },
  download_artifact_file: {
    label: "download_artifact_file(context, artifacts_id, file_name)",
    documentation:
      "Download a single file in artifact without downloading the whole artifact. This will not work when doing prepare for local",
    parameters: [
      { label: "context", documentation: "The context to download the file from" },
      { label: "artifacts_id", documentation: "ID of the artifact" },
      { label: "file_name", documentation: "Name of the file to download" }
    ]
  },
  downloadArtifacts: {
    label: "downloadArtifacts(context, artifactsId)",
    documentation: "",
    parameters: [
      { label: "context", documentation: "The context to download artifacts from" },
      { label: "artifactsId", documentation: "ID of the artifact" }
    ]
  },
  get_artifact: {
    label: "get_artifact(context, artifact_id, artifact_name)",
    documentation: "",
    parameters: [
      { label: "context", documentation: "The context to retrieve the artifact from" },
      { label: "artifact_id", documentation: "ID of the artifact" },
      { label: "artifact_name", documentation: "Name of the artifact" }
    ]
  },
  getTenantId: {
    label: "getTenantId(context)",
    documentation: "",
    parameters: [{ label: "context", documentation: "The context to get the tenant ID from" }]
  },
  getOrCreateContext: {
    label: "getOrCreateContext(contextId, localVars, entities=None)",
    documentation:
      "This is used to create the context, it should be called at the start of the file",
    parameters: [
      { label: "contextId", documentation: "ID of the context" },
      { label: "localVars", documentation: "Required parameter, pass it as localvars()" },
      { label: "entities", documentation: "Dictionary of local datasets" }
    ]
  },
  getAllEntities: {
    label: "getAllEntities(context)",
    documentation: "Returns all the entity names in the context",
    parameters: [{ label: "context", documentation: "The context to retrieve entity names from" }]
  },
  load_all_entities: {
    label: "load_all_entities(context)",
    documentation: "Load and create dataframe for all the entities in the context",
    parameters: [{ label: "context", documentation: "The context to load entities from" }]
  },
  getEntityData: {
    label:
      "getEntityData(context, entityName, inferDTypesFromSchema=False, numRows=None, pandas_lib=None)",
    documentation: "Returns the data of entity as dataframe",
    parameters: [
      { label: "context", documentation: "The context to get entity data from" },
      { label: "entityName", documentation: "Name of the entity" },
      { label: "inferDTypesFromSchema", documentation: "Infer data types from schema" },
      { label: "numRows", documentation: "Number of rows to read" },
      { label: "pandas_lib", documentation: "Pandas library to use" }
    ]
  },
  get_data_from_source: {
    label:
      "get_data_from_source(source_type: utils.rcclient.enums.DataSourceType, source: str, name: str = None, **options)",
    documentation: "",
    parameters: [
      { label: "source_type", documentation: "Type of data source" },
      { label: "source", documentation: "Source" },
      { label: "name", documentation: "Name of the data" }
    ]
  },
  write_data_to_source: {
    label:
      "write_data_to_source(df, source_type: utils.rcclient.enums.DataSourceType, target: str, name: str = None, **options)",
    documentation: "",
    parameters: [
      { label: "df", documentation: "Dataframe to write" },
      { label: "source_type", documentation: "Type of data source" },
      { label: "target", documentation: "Target to write data to" },
      { label: "name", documentation: "Name of the data" }
    ]
  },
  get_datasource: {
    label: "get_datasource(source: utils.rcclient.enums.DataSourceType)",
    documentation: "",
    parameters: [{ label: "source", documentation: "Source to get data from" }]
  },
  getEntityFilePath: {
    label: "getEntityFilePath(context, entityName)",
    documentation: "Returns file path of the entity",
    parameters: [
      { label: "context", documentation: "The context to get entity file path from" },
      { label: "entityName", documentation: "Name of the entity" }
    ]
  },
  getChildDir: {
    label: "getChildDir(context)",
    documentation: "Returns the transform directory, it can be used to save datasets etc.",
    parameters: [{ label: "context", documentation: "The context to get child directory from" }]
  },
  getOutputCollection: {
    label: "getOutputCollection(context)",
    documentation: "Returns outputCollection, use it to add outputs",
    parameters: [{ label: "context", documentation: "The context to get output collection from" }]
  },
  generate_warning: {
    label: "generate_warning(context, warning)",
    documentation: "",
    parameters: [
      { label: "context", documentation: "The context to generate warning in" },
      { label: "warning", documentation: "Warning message" }
    ]
  },
  save: {
    label: "save(context)",
    documentation: "Saves the context to the disk, remember to call it at end of your notebook",
    parameters: [{ label: "context", documentation: "The context to save" }]
  },
  validate_artifact_added: {
    label: "validate_artifact_added(context)",
    documentation: "",
    parameters: [{ label: "context", documentation: "The context to validate artifact in" }]
  },
  get_rc_ml_model: {
    label: "get_rc_ml_model(context, model_name, model_version='default')",
    documentation: "",
    parameters: [
      { label: "context", documentation: "The context to get ML model from" },
      { label: "model_name", documentation: "Name of the model" },
      { label: "model_version", documentation: "Version of the model" }
    ]
  },
  getValueFromCacheMap: {
    label: "getValueFromCacheMap(context, cacheKey)",
    documentation: "Returns the value from cache map",
    parameters: [
      { label: "context", documentation: "Context containing the cache map" },
      { label: "cacheKey", documentation: "Key in the cache map" }
    ]
  },
  createTemplateOutput: {
    label:
      "createTemplateOutput(context, outputName: str, outputType: utils.dtos.templateOutput.OutputType, data=None, dataType: utils.dtos.templateOutput.FileType = FileType.CSV, outputFileName: str = None, custom_params: dict = {}, metadata: dict = {}, description: str = None, group: str = None)",
    documentation: "Creates an output for the notebook",
    parameters: [
      { label: "context", documentation: "The context for the output" },
      { label: "outputName", documentation: "Name of the output" },
      { label: "outputType", documentation: "Type of the output" },
      { label: "data", documentation: "Dataframe of the output dataset" },
      { label: "dataType", documentation: "Data type of the output" },
      { label: "outputFileName", documentation: "File name of the output" },
      { label: "custom_params", documentation: "Custom parameters" },
      { label: "metadata", documentation: "Metadata" },
      { label: "description", documentation: "Description of the output" },
      { label: "group", documentation: "Group of the output" }
    ]
  },
  create_template_output_rc_ml_model: {
    label:
      "create_template_output_rc_ml_model(context, model_name, model_obj, artifacts, version='default')",
    documentation: "",
    parameters: [
      { label: "context", documentation: "The context for the ML model output" },
      { label: "model_name", documentation: "Name of the ML model" },
      { label: "model_obj", documentation: "ML model object" },
      { label: "artifacts", documentation: "Artifacts associated with the model" },
      { label: "version", documentation: "Version of the model output" }
    ]
  },
  create_template_output_answer: {
    label: "create_template_output_answer(context, answer)",
    documentation: "",
    parameters: [
      { label: "context", documentation: "The context for the answer output" },
      { label: "answer", documentation: "Answer output" }
    ]
  },
  create_template_output_metadata: {
    label: "create_template_output_metadata(context, metadata_list)",
    documentation: "",
    parameters: [
      { label: "context", documentation: "The context for the metadata output" },
      { label: "metadata_list", documentation: "List of metadata" }
    ]
  },
  create_template_output_graph: {
    label: "create_template_output_graph(context, graph)",
    documentation: "",
    parameters: [
      { label: "context", documentation: "The context for the graph output" },
      { label: "graph", documentation: "Graph object" }
    ]
  },

  create_template_output_artifact: {
    signature: "create_template_output_artifact(context, artifact_name)",
    parameters: [
      { name: "context", description: "The execution context." },
      { name: "artifact_name", description: "The name of the artifact to create output for." }
    ]
  },
  createTemplateOutputDataset: {
    signature: "createTemplateOutputDataset(context, outputName, dataFrame)",
    parameters: [
      { name: "context", description: "The execution context." },
      { name: "outputName", description: "The name of the output dataset." },
      { name: "dataFrame", description: "The data frame to be used as output." }
    ]
  },
  create_template_output_chart: {
    signature:
      "create_template_output_chart(context, title, metadata={}, description=None, group=None)",
    parameters: [
      { name: "context", description: "The execution context." },
      { name: "title", description: "The title of the chart." },
      { name: "metadata", description: "Optional metadata for the chart." },
      { name: "description", description: "Optional description of the chart." },
      { name: "group", description: "Optional group information for the chart." }
    ]
  },
  createTemplateOutputEChart: {
    signature:
      "createTemplateOutputEChart(context, chartTitle, dataFrame, chartType=ChartType.TABLE, params={}, description=None, group=None)",
    parameters: [
      { name: "context", description: "The execution context." },
      { name: "chartTitle", description: "The title of the EChart." },
      { name: "dataFrame", description: "The data frame for the EChart." },
      { name: "chartType", description: "The type of the chart (default is ChartType.TABLE)." },
      { name: "params", description: "Optional parameters for the chart." },
      { name: "description", description: "Optional description of the chart." },
      { name: "group", description: "Optional group information for the chart." }
    ]
  },
  createTemplateOutputPlotlibChart: {
    signature:
      "createTemplateOutputPlotlibChart(context, chartTitle: str, plt, description=None, group=None)",
    parameters: [
      { name: "context", description: "The execution context." },
      { name: "chartTitle", description: "The title of the Plotlib chart." },
      { name: "plt", description: "The Plotlib chart object." },
      { name: "description", description: "Optional description of the chart." },
      { name: "group", description: "Optional group information for the chart." }
    ]
  },
  createTemplateOutputPlotlyChart: {
    signature:
      "createTemplateOutputPlotlyChart(context, chartTitle: str, plotly_fig, description=None, group=None)",
    parameters: [
      { name: "context", description: "The execution context." },
      { name: "chartTitle", description: "The title of the Plotly chart." },
      { name: "plotly_fig", description: "The Plotly figure object." },
      { name: "description", description: "Optional description of the chart." },
      { name: "group", description: "Optional group information for the chart." }
    ]
  },
  createTemplateOutputPlotlyChartAsJson: {
    signature:
      "createTemplateOutputPlotlyChartAsJson(context, chartTitle: str, plotly_fig, group=None)",
    parameters: [
      { name: "context", description: "The execution context." },
      { name: "chartTitle", description: "The title of the Plotly chart." },
      { name: "plotly_fig", description: "The Plotly figure object." },
      { name: "group", description: "Optional group information for the chart." }
    ]
  },
  createOutputCollection: {
    signature: "createOutputCollection(context)",
    parameters: [{ name: "context", description: "The execution context." }]
  },
  getPreparedData: {
    signature: "getPreparedData(prepareId)",
    parameters: [{ name: "prepareId", description: "The ID of the prepared data." }]
  },
  get_or_create_input_var: {
    signature: "get_or_create_input_var(name, metadata, local_context)",
    parameters: [
      { name: "name", description: "The name of the input variable." },
      { name: "metadata", description: "Metadata for the input variable." },
      { name: "local_context", description: "The local execution context." }
    ]
  },
  get_or_create_input_column_var: {
    signature: "get_or_create_input_column_var(name, metadata, local_context)",
    parameters: [
      { name: "name", description: "The name of the input column variable." },
      { name: "metadata", description: "Metadata for the input column variable." },
      { name: "local_context", description: "The local execution context." }
    ]
  },
  get_or_create_input_data_source_var: {
    signature: "get_or_create_input_data_source_var(name, metadata, local_context)",
    parameters: [
      { name: "name", description: "The name of the input data source variable." },
      { name: "metadata", description: "Metadata for the input data source variable." },
      { name: "local_context", description: "The local execution context." }
    ]
  },
  get_or_create_chart_app_var: {
    signature: "get_or_create_chart_app_var(name, metadata, local_context)",
    parameters: [
      { name: "name", description: "The name of the chart app variable." },
      { name: "metadata", description: "Metadata for the chart app variable." },
      { name: "local_context", description: "The local execution context." }
    ]
  },
  get_or_create_input_dataset: {
    signature:
      "get_or_create_input_dataset(name, metadata, local_context, template_id=None, nb_name=None)",
    parameters: [
      { name: "name", description: "The name of the input dataset." },
      { name: "metadata", description: "Metadata for the input dataset." },
      { name: "local_context", description: "The local execution context." },
      { name: "template_id", description: "Optional template ID." },
      { name: "nb_name", description: "Optional notebook name." }
    ]
  },
  get_or_create_output_dataset: {
    signature:
      "get_or_create_output_dataset(name, metadata, local_context, template_id=None, nb_name=None)",
    parameters: [
      { name: "name", description: "The name of the output dataset." },
      { name: "metadata", description: "Metadata for the output dataset." },
      { name: "local_context", description: "The local execution context." },
      { name: "template_id", description: "Optional template ID." },
      { name: "nb_name", description: "Optional notebook name." }
    ]
  },
  get_or_create_output_chart: {
    signature:
      "get_or_create_output_chart(name, metadata, local_context, template_id=None, nb_name=None)",
    parameters: [
      { name: "name", description: "The name of the output chart." },
      { name: "metadata", description: "Metadata for the output chart." },
      { name: "local_context", description: "The local execution context." },
      { name: "template_id", description: "Optional template ID." },
      { name: "nb_name", description: "Optional notebook name." }
    ]
  },
  save_all_vars: {
    signature: "save_all_vars()",
    parameters: []
  },
  get_metadata_value: {
    signature:
      "get_metadata_value(context, subject_type: utils.dtos.metadata.MetadataSubjectType, subject_name: str, key: str)",
    parameters: [
      { name: "context", description: "The execution context." },
      { name: "subject_type", description: "The type of the metadata subject." },
      { name: "subject_name", description: "The name of the metadata subject." },
      { name: "key", description: "The key of the metadata value." }
    ]
  },
  get_all_metadata: {
    signature: "get_all_metadata(context)",
    parameters: [{ name: "context", description: "The execution context." }]
  }
};

export const addImportStatement = (
  code: string,
  importStatement: string,
  currentPosition: number
) => {
  const lines = code.split("\n");

  let linesAdded = 0;
  const transformIndices = lines
    .map((line, index) => ({ line: line.trim(), index }))
    .filter(({ line, index }) => line.startsWith("def transform") && index < currentPosition)
    .map(({ index }) => index);

  if (transformIndices.length === 0) {
    if (
      lines.some((line, index) => line.trim() === importStatement.trim() && index < currentPosition)
    ) {
      return { updatedCode: code, linesAdded: 0 };
    }
    const firstNonImportLineIndex = lines.findIndex(
      (line) => !line.startsWith("import ") && !line.startsWith("from ")
    );
    const insertPosition = firstNonImportLineIndex === -1 ? lines.length : firstNonImportLineIndex;
    lines.splice(insertPosition, 0, importStatement);
    linesAdded = 1;
    return { updatedCode: lines.join("\n"), linesAdded };
  }
  const targetTransformIndex = transformIndices[transformIndices.length - 1];

  if (
    lines.some(
      (line, index) =>
        line.trim() === importStatement.trim() &&
        index > targetTransformIndex &&
        index < currentPosition
    )
  ) {
    return { updatedCode: code, linesAdded: 0 };
  }

  const indentLevel = lines[targetTransformIndex].match(/^\s*/)?.[0] || "";

  const insertPosition = targetTransformIndex + 1;
  lines.splice(insertPosition, 0, `${indentLevel}    ${importStatement}`);
  linesAdded = 1;

  return { updatedCode: lines.join("\n"), linesAdded };
};
