import React, { Dispatch, SetStateAction } from "react";

// Packages
import { useParams } from "react-router-dom";
import { ReactFlowProvider } from "react-flow-renderer";
import { isEmpty, isNil } from "lodash";

// Utils
import { getDocsUrl } from "utils/helpers";

// Contexts
import DagFlowContextProvider from "./context/DagFlowContextProvider";
import { useDagFlowContext } from "./context/useDagFlowContext";

import DagFlowContainer from "../DagFlowContainer/DagFlowContainer";
import RecipeRunsPopover from "../RecipeRunsPopover/RecipeRunsPopover";

// Types
import { JobProps } from "../../Dag.types";

// Styles
import NewThemeWrapper from "src/styles/NewThemeWrapper";
import CommonLoader from "src/components/CommonLoader";
import GettingStarted from "components/custom/GetttingStarted/GettingStarted";
import { Button, Grid, Typography } from "@material-ui/core";
import ImportDataApp from "icons/NewUX/GettingStartedIllustrations/ImportDataApp";
import _ from "lodash";
import AddProjectArtifactButton from "projectsModule/components/AddProjectArtifactButton";

export const canvasGettingStarted = {
  topMessage: "Add input data or connect to a data connector to kickstart your project.",

  functionality: {
    question: "How it works?",
    answers: [
      "Import your data – Add an input or connect an external data connector.",
      "Let AI assist you – Use AI to clean, transform, and explore data effortlessly.",
      "Build models with ease – AI can automatically generate ML models based on your data.",
      "Refine and analyze – Modify transformations, visualize trends, and optimize insights.",
      "Deploy and integrate – Use generated models in real-world applications."
    ]
  }
};

type DagFlowProps = {
  jobProps?: JobProps;
  onAddDatasetAction?: () => void;
  recipeRunsPopoverAnchorEl?: HTMLButtonElement | null;
  setRecipeRunsPopoverAnchorEl?: Dispatch<SetStateAction<HTMLButtonElement | null>>;
  onAddFileAction?: () => void;
  navigateToApiConnectorRecipe?: () => void;
};

type DagFlowWrapperProps = {
  jobProps?: JobProps;
  onAddDatasetAction?: () => void;
  recipeRunsPopoverAnchorEl?: HTMLButtonElement | null;
  setRecipeRunsPopoverAnchorEl?: Dispatch<SetStateAction<HTMLButtonElement | null>>;
  onAddFileAction?: () => void;
  navigateToApiConnectorRecipe?: () => void;
};

const DagFlowSubWrapper = (props: DagFlowWrapperProps) => {
  const {
    jobProps,
    onAddDatasetAction,
    recipeRunsPopoverAnchorEl,
    setRecipeRunsPopoverAnchorEl,
    onAddFileAction,
    navigateToApiConnectorRecipe
  } = props || {};

  const { projectId } = useParams() || {};

  const { project, isJobPath, isFetchingProjectCanvas, isJobCanvasComparePath, nodesStore } =
    useDagFlowContext();

  return (
    <>
      {!isNil(recipeRunsPopoverAnchorEl) && !isNil(setRecipeRunsPopoverAnchorEl) && (
        <RecipeRunsPopover
          recipeRunsPopoverAnchorEl={recipeRunsPopoverAnchorEl}
          setRecipeRunsPopoverAnchorEl={setRecipeRunsPopoverAnchorEl}
        />
      )}

      {!!isJobCanvasComparePath ? (
        <ReactFlowProvider>
          <DagFlowContainer
            projectId={projectId}
            project={project}
            jobProps={jobProps}
            nodesDraggable={false}
          />
        </ReactFlowProvider>
      ) : isFetchingProjectCanvas ? (
        <CommonLoader />
      ) : isEmpty(nodesStore) ? (
        <GettingStarted
          outerBoxWidth="608px"
          boxes={[
            {
              message: canvasGettingStarted.topMessage,
              icon: <ImportDataApp />,
              width: "600px",
              action: (
                <Grid container direction="column" style={{ gap: "16px", flexWrap: "nowrap" }}>
                  <Grid container style={{ gap: "10px", flexWrap: "nowrap", paddingTop: "16px" }}>
                    <Grid container direction="column" style={{ gap: "10px" }}>
                      <Button
                        color="primary"
                        variant="outlined"
                        size="medium"
                        style={{ width: "fit-content" }}
                        onClick={onAddDatasetAction}>
                        + Dataset
                      </Button>
                    </Grid>
                    <Grid container direction="column" style={{ gap: "10px" }}>
                      <Button
                        color="primary"
                        variant="outlined"
                        size="medium"
                        style={{ width: "fit-content" }}
                        onClick={onAddFileAction}>
                        + Text file
                      </Button>
                    </Grid>
                    <Grid container direction="column" style={{ gap: "10px" }}>
                      {projectId && <AddProjectArtifactButton projectId={projectId} />}
                    </Grid>
                  </Grid>
                  <span style={{ opacity: 0.33 }}>OR</span>
                  <Grid container direction="column" style={{ gap: "10px" }}>
                    <Typography style={{ fontSize: "15px", fontWeight: 500 }}>
                      Create Custom Code
                    </Typography>
                    <Button
                      color="primary"
                      variant="outlined"
                      size="medium"
                      onClick={navigateToApiConnectorRecipe}
                      style={{ width: "fit-content" }}>
                      + Code Recipe
                    </Button>
                  </Grid>
                </Grid>
              )
            }
          ]}
          functionality={{
            question: canvasGettingStarted.functionality.question,
            answers: canvasGettingStarted.functionality.answers,
            docsLink: `${getDocsUrl()}/basic/projects/canvas-overview`
          }}
        />
      ) : (
        <ReactFlowProvider>
          <DagFlowContainer
            projectId={projectId}
            project={project}
            jobProps={jobProps}
            nodesDraggable={!isJobPath}
          />
        </ReactFlowProvider>
      )}
    </>
  );
};

const DagFlowWrapper = (props: DagFlowProps) => {
  const {
    jobProps,
    onAddDatasetAction,
    recipeRunsPopoverAnchorEl,
    setRecipeRunsPopoverAnchorEl,
    onAddFileAction,
    navigateToApiConnectorRecipe
  } = props || {};

  return (
    <DagFlowContextProvider jobProps={jobProps}>
      <NewThemeWrapper>
        <DagFlowSubWrapper
          jobProps={jobProps}
          onAddDatasetAction={onAddDatasetAction}
          onAddFileAction={onAddFileAction}
          navigateToApiConnectorRecipe={navigateToApiConnectorRecipe}
          recipeRunsPopoverAnchorEl={recipeRunsPopoverAnchorEl}
          setRecipeRunsPopoverAnchorEl={setRecipeRunsPopoverAnchorEl}
        />
      </NewThemeWrapper>
    </DagFlowContextProvider>
  );
};

export default DagFlowWrapper;
