import { filter, includes, map } from "lodash";

import { ScenarioDto } from "@rapidcanvas/rc-api-core";

import { ScenarioHelperText } from "./Scenario.constants";

export const composeScenarioName = (scenarios?: ScenarioDto[]) => {
  const scenarioNames = map(
    filter(scenarios, (scenario) => includes(scenario?.name, ScenarioHelperText.UntitledScenario)),
    (scenario) => scenario?.name
  );

  let name = `${ScenarioHelperText.UntitledScenario}`;
  let i = 2;

  while (includes(scenarioNames, name)) {
    name = `${ScenarioHelperText.UntitledScenario} ${i}`;
    i++;
  }

  return name;
};
