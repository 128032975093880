import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  headerContainer: {
    position: "relative",
    gap: "6px",
    "& svg.MuiTableSortLabel-icon": {
      fill: "#008fe4"
    },
    "&:hover": {
      "& span.MuiTableSortLabel-root": {
        color: "inherit"
      },
      "& svg.MuiTableSortLabel-icon": {
        opacity: 1
      }
    }
  },
  sortLabel: {
    overflow: "auto",
    flex: 1
  },
  highlight: {
    backgroundColor: "#f2f9ff !important"
  },
  resizer: {
    opacity: 0,
    display: "inline-block",
    background: "#008fe4",
    width: "4px",
    height: "100%",
    position: "absolute",
    right: 0,
    top: 0,
    transition: "all ease 0.5s",
    transform: "translateX(50%)",
    zIndex: 3,
    touchAction: "none",
    cursor: "col-resize",
    "&:hover": {
      opacity: 1
    }
  },
  schemaHeader: {
    padding: 0,
    color: "#2c2e2f",
    background: "#ffffff",
    borderTop: "1px solid #e0e0e0",
    borderRight: "1px solid #e0e0e0",
    zIndex: 5
  },
  schemaFirstColumn: {
    zIndex: 6
  },
  schemaHeaderContainer: {
    padding: "12px 24px 11px 16px !important"
  },
  schemaControlContainer: {
    borderTop: "1px solid #e0e0e0",
    padding: "6px 24px 0px 16px !important",
    width: "100%"
  },
  schemaHeaderResizer: {
    height: "50%"
  }
}));
