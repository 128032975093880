import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: theme.spacing(1.5)
  },
  wrapper: {
    maxHeight: "calc(100vh - 430px)",
    overflowY: "auto"
  },
  groupIndentation: {
    position: "absolute",
    top: -76,
    left: -25,
    width: 25,
    height: "190%",
    borderLeft: "2px dotted #515151",
    borderBottom: "2px dotted #515151"
  },
  ruleIndentation: {
    position: "absolute",
    left: -25,
    width: 25,
    borderLeft: "2px dotted #515151",
    borderBottom: "2px dotted #515151"
  },
  ruleContainer: {
    position: "relative",
    marginTop: 20,
    marginLeft: 50,
    columnGap: theme.spacing(2),
    width: "auto"
  },
  textField: {
    width: 250
  },
  inputLabel: {
    opacity: 0.625
  },
  inputLabelShrink: {
    shrink: true,
    opacity: 1
  },
  listBox: {
    padding: 0,
    overflow: "auto"
  },
  toggleButtonRoot: {
    border: "1px solid #4646b4", // Border for the entire ToggleButtonGroup
    borderRadius: "4px"
  },

  toggleButtonGrouped: {
    "&:not(:first-child)": {
      borderLeft: "1px solid #4646b4"
    }
  },
  toggleButton: {
    flex: 1,
    color: "#4646b4",
    fontWeight: 400,
    background: "white",
    "&.Mui-selected": {
      backgroundColor: "#E7E7FF",
      color: "#4646b4",
      "&:hover": {
        backgroundColor: "#E7E7FF"
      }
    }
  }
}));

export default useStyles;
