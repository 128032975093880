// Packages
import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";

// Utils
import api from "services/AxiosClient/AxiosClient";

// Open API
import { EnvDtoLaunchStatusEnum } from "@rapidcanvas/rc-api-core";

// Stores
import { resetEnvironmentStatus } from "src/stores/environmentsSlice";

// Hooks
import { updateEnvsQueryDataById } from "./usePollEnvironments";

export enum UseStopEnvironmentQueryKeys {
  StopEnvironment = "stopEnvironment"
}

interface IPayload {
  id?: string;
}

const useStopEnvironment = ({
  id
}: {
  id?: string;
}): UseMutationResult<unknown, unknown, IPayload, unknown> => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation({
    mutationKey: [UseStopEnvironmentQueryKeys.StopEnvironment, id],
    mutationFn: async ({ id }: IPayload) => {
      if (!id) {
        throw "Invalid payload!";
      }

      return await api.fetchResponse(async () => await api.EnvControllerApi.stopEnv(id, true));
    },
    onSettled: (__1, __2, payload) => {
      if (!!payload?.id) {
        updateEnvsQueryDataById(queryClient, payload.id, EnvDtoLaunchStatusEnum.Stopping);
        dispatch(resetEnvironmentStatus({ id: payload.id! }));
      }
    }
  });
};

export default useStopEnvironment;
