import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import axios from "axios";
import { ViewFileConfig } from "src/pages/private/ProjectsModule/pages/ViewFile/utils/ViewFile.constants";

import api from "services/AxiosClient/AxiosClient";
import { getFileContent } from "../entities/usePreviewDatasetFile";

export const getFileExtension = (filePath: string) => {
  const match = filePath?.match(/\.([^.]+)$/);
  return match ? match[1] : "";
};

export const QUERY_KEY_RECIPE_TEST_FILE_DOWNLOAD = "query-key-recipe-test-file-download";

const useTestResultFileDownload = (
  filePath: string,
  fileName: string,
  options?: UseQueryOptions<any, unknown>
): UseQueryResult<any> => {
  return useQuery({
    queryKey: [QUERY_KEY_RECIPE_TEST_FILE_DOWNLOAD, fileName, filePath],
    queryFn: async () => {
      if (!fileName || !filePath) {
        throw "Invalid payload!";
      }

      const { data } = await api.SignedUrlControllerApi.downloadFileUrl({
        fileName,
        filePath,
        signedUrlObjectType: "GENERAL"
      });

      if (!data?.signedUrl) {
        throw "unable to fetch file";
      } else {
        const response = await axios.get(data?.signedUrl, { responseType: "blob" });

        const fileSize = response?.data?.size ?? 0;
        if (fileSize > ViewFileConfig.MaxFileSize * 1024 * 1024) {
          const blob = new Blob([response.data]);
          const url = window.URL.createObjectURL(blob);

          const downloadLink = document.createElement("a");
          downloadLink.href = url;
          downloadLink.download = fileName;
          downloadLink.click();

          return ViewFileConfig.MaxFileSizeExceededError;
        }
        const fileType = getFileExtension(filePath);
        return getFileContent(response.data, fileType);
      }
    },
    enabled: !!fileName && !!filePath,
    ...options
  });
};

export default useTestResultFileDownload;
