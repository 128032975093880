import React, { useState } from "react";
import clsx from "clsx";
import {
  Box,
  Button,
  Grid,
  Tooltip,
  Typography,
  makeStyles,
  Card as MuiCard
} from "@material-ui/core";
import { CalendarToday, Person } from "@material-ui/icons";
import { generatePath, useNavigate } from "react-router-dom";

import {} from "@material-ui/core";
import { MoreOptions } from "../MoreOptions/MoreOptions";

import MarkFavorite from "src/pages/Projects/MarkFavorite";
import defaultImage from "../../assets/images/projectImages/5Gpredictive_med.jpg";
import styles from "./InfoCards.module.scss";
import { TitleText } from "src/components";
import { DataAppIcon } from "icons/NewUX/DataAppIcon";
import { JobIcon } from "icons/NewUX/JobIcon";
import { WebPaths } from "src/routing/routes";
import { dateFormat } from "utils/helpers/date.helpers";
import { CardTitle } from "../Card/CardTitle";
import { LoadableImage } from "../custom";

export interface IData {
  id: string;
  scenarioId: string;
  image: string;
  description: string;
  title: string;
  defaultScenarioId: string;
  link: string;
  dataSetCount: number;
  singleEntityId: string;
  dataAppCount: number;
  jobCount: number;
  updated: string;
  updater: string;
  isFavorite: boolean;
}

interface IOptions {
  label: string;
  click: () => void;
  icon: React.JSX.Element;
}

interface IProps {
  data: IData;
  options: IOptions[];
}

const useStyles = makeStyles({
  cardImg: {
    position: "relative",

    "& img": {
      width: "100%",
      borderRadius: "8px",
      height: "164px",
      minHeight: "164px"
    }
  },
  actionBtn: {
    borderRadius: "4px",
    borderColor: "#4646b5",
    width: "max-content",
    marginBottom: "74px"
  },
  countText: {
    color: "#00365699",
    fontSize: "12px",
    fontWeight: 400
  },
  gap: {
    gap: "6px"
  },
  card: {
    boxShadow: "0px 4px 4px 0px #00000040",
    borderRadius: "12px",
    background: ({ isHovered }: any) => (isHovered ? "#F4F4FF" : "#fff"),
    border: ({ isHovered }: any) => (isHovered ? "1px solid #4646B5" : "1px solid #fff"),
    minHeight: "120px",
    padding: "16px",
    position: "relative",
    cursor: ({ link }: any) => (link ? "pointer" : "default"),
    height: "100%"
  },
  moreOptions: {
    position: "absolute",
    background: "#fff",
    top: "32px",
    right: "32px",
    width: "25px",
    height: "28px",
    borderRadius: "4px",
    zIndex: 1
  },
  overflowText: {
    maxWidth: "calc(100% - 40px)"
  },
  detailsContainer: {
    overflow: "auto",
    height: "calc(100% - 164px)",
    flexDirection: "column",
    flexWrap: "nowrap"
  }
});

const ProjectInfoCard: React.FC<IProps> = ({ data, options }) => {
  const {
    id,
    scenarioId,
    image,
    description,
    title,
    defaultScenarioId,
    link,
    dataSetCount,
    singleEntityId,
    dataAppCount,
    jobCount,
    updated,
    updater,
    isFavorite
  } = data;

  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const classes = useStyles({ isHovered, link });

  const handleCardBtnClick = (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    if (dataSetCount === 0) {
      navigate(
        generatePath(WebPaths.Dataset, {
          projectId: id,
          scenarioId: defaultScenarioId ?? scenarioId
        })
      );
    } else {
      navigate(
        `/projects/${id}?openAskAI=true${
          dataSetCount === 1 && !!singleEntityId ? `&entity=${singleEntityId}` : ""
        }`
      );
    }
  };

  const handleDataAppClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleJobsClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleLinkClick = () => {
    const navLink = `${link}/${id}`;
    navigate(navLink);
  };

  return (
    <MuiCard
      onClick={handleLinkClick}
      className={classes.card}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      {isHovered && (
        <Grid className={classes.moreOptions}>
          <MoreOptions
            options={options}
            btnProps={{
              style: {
                color: "#4646B5"
              }
            }}
          />
        </Grid>
      )}
      <Grid item xs="auto" className={classes.cardImg} data-testid="projectImage">
        <MarkFavorite
          projectId={id}
          isHovered={isHovered}
          style={{ position: "absolute", left: "6px", top: "6px" }}
          value={isFavorite}
        />

        {/(http|https):\/\//.test(image) ? (
          <img src={image} alt={title} />
        ) : (
          <LoadableImage src={image} fallbackSrc={defaultImage} title={title} />
        )}
      </Grid>
      <Grid container direction="column" item xs className={classes.detailsContainer}>
        <Grid item>
          <Box pt="8px">
            <CardTitle
              isHovered={isHovered}
              dataTestId="projectName"
              link={`${link}/${id}`}
              title={title}
              style={{ fontWeight: 400 }}
            />
          </Box>
        </Grid>
        {description && (
          <Grid item>
            <TitleText
              dataTestId="projectDescription"
              title={description}
              variant="body2"
              color="textSecondary"
              className={styles.description}
            />
          </Grid>
        )}
        <Grid
          container
          direction="column"
          wrap="nowrap"
          justifyContent="flex-end"
          item
          style={{ flexGrow: 1 }}>
          <Button
            variant="outlined"
            color="primary"
            data-testid="projectData"
            className={classes.actionBtn}
            onClick={handleCardBtnClick}>
            {dataSetCount === 0 ? "Connect Your Data" : "Ask AI on your data"}
          </Button>
        </Grid>
        <Grid item container direction="row" wrap="nowrap">
          <Grid
            item
            container
            xs={6}
            direction="row"
            alignItems="center"
            className={classes.gap}
            wrap="nowrap"
            style={{ paddingBottom: "6px" }}>
            <Person style={{ fontSize: "16px", color: "#00365699" }} />
            <Grid className={classes.overflowText}>
              <TitleText
                dataTestId="projectUpdater"
                title={updater?.split("@")?.[0] || "-"}
                className={clsx(classes.countText)}
              />
            </Grid>
          </Grid>
          <Grid item xs={6} container direction="row" className={classes.gap} wrap="nowrap">
            <Tooltip title="Updated On">
              <>
                <CalendarToday style={{ fontSize: "16px", color: "#00365699" }} />
                <Typography data-testid="projectUpdatedOn" className={classes.countText}>
                  {dateFormat(updated, true)}
                </Typography>
              </>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid item container direction="row" wrap="nowrap">
          <Grid
            item
            xs={6}
            container
            direction="row"
            alignItems="center"
            className={classes.gap}
            onClick={handleDataAppClick}>
            <DataAppIcon />
            <TitleText
              dataTestId="createDataAppForProject"
              title={
                dataAppCount === 0
                  ? "Create DataApp"
                  : `${dataAppCount} DataApp${dataAppCount !== 1 ? "s" : ""}`
              }
              className={clsx(classes.countText)}
              link={`/projects/${id}/project-dataapps`}
            />
          </Grid>
          <Grid
            item
            xs={6}
            container
            direction="row"
            className={classes.gap}
            onClick={handleJobsClick}>
            <JobIcon />
            <TitleText
              dataTestId="scheduleSchedulerForProject"
              title={
                jobCount === 0
                  ? "Create Scheduler"
                  : `${jobCount} Scheduler${jobCount > 1 ? "s" : ""}`
              }
              className={classes.countText}
              link={jobCount === 0 ? `/projects/${id}/jobs/create-job` : `/projects/${id}/jobs`}
            />
          </Grid>
        </Grid>
      </Grid>
    </MuiCard>
  );
};

export default ProjectInfoCard;
