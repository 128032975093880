import React, { useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { isEmpty, map } from "lodash";

import GlobalSearchInput from "./GlobalSearchInput";
import GlobalSearchPopover from "./GlobalSearchPopover";
import GlobalSearchSelect from "./GlobalSearchSelect";
import NewThemeWrapper from "src/styles/NewThemeWrapper";
import { SEARCH_COMPONENTS } from "../../../utils/GlobalSearch.constants";
import { useGetRole } from "hooks/useGetRole";
import { SearchRequestDtoSearchComponentsEnum } from "@rapidcanvas/rc-api-core";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #ccc",
    borderRadius: "20px",
    overflow: "hidden",
    minWidth: "300px",
    width: "100%",
    background: "#fff",
    height: "36px",
    marginRight: "90px"
  }
}));

const defaultValues = map(SEARCH_COMPONENTS, (__, key) => key);

const GlobalSearch: React.FC = () => {
  const classes = useStyles();
  const ref = React.useRef<HTMLDivElement | null>(null);
  const { isDataAppPowerUser, isConsumer } = useGetRole();
  const [values, setValues] = useState<string[]>(
    isDataAppPowerUser || isConsumer
      ? [SearchRequestDtoSearchComponentsEnum.Dataapp]
      : defaultValues
  );
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const [searchText, setSearchText] = useState<string>("");

  const handleSearch = (val: string) => {
    setSearchText(val);

    if (val && ref.current && !anchorEl) {
      setAnchorEl(ref.current);
    }

    if (!val) {
      setAnchorEl(null);
    }
  };

  const handleClick = () => {
    if (searchText && ref.current && !anchorEl) {
      setAnchorEl(ref.current);
    }
  };

  return (
    <NewThemeWrapper>
      <Grid className={classes.container} ref={ref} data-testid="globalSearch">
        <GlobalSearchSelect values={values} onChange={setValues} />
        <GlobalSearchInput
          value={searchText}
          disabled={isEmpty(values)}
          onChange={handleSearch}
          onClick={handleClick}
        />
      </Grid>
      <GlobalSearchPopover
        searchText={searchText}
        searchComponents={values}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        onSearchTextChange={handleSearch}
      />
    </NewThemeWrapper>
  );
};

export default GlobalSearch;
