import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

import { getAPIWithRethrow } from "services/Apis/Apis.service";
import { getEntityFeaturesWithRethrow } from "services/Apis/wrappers/projects";

const QUERY_KEY_ENTITY_FEATURES = "query-key-entity-features";

export const enum UseGetSchemaOptionsQueryKeys {
  SchemaOptions = "schema-options"
}

export const enum UseGetSchemaDataQueryKeys {
  SchemaData = "schema-data"
}

interface SchemaOptionsProps extends UseQueryOptions<$TSFixMe> {
  entityId: string | null;
}

interface SchemaDataProps extends UseQueryOptions<$TSFixMe> {
  entityId: string | null | undefined;
}

const useEntityFeatures = (
  projectId?: string,
  entityId?: string,
  projectRunEntryId?: string,
  projectRunEntityName?: string
): UseQueryResult<any> => {
  return useQuery({
    queryKey: [
      QUERY_KEY_ENTITY_FEATURES,
      projectId,
      entityId,
      projectRunEntryId,
      projectRunEntityName
    ],
    enabled: !!entityId && !!projectRunEntryId && !!projectRunEntityName,
    queryFn: async () => {
      const entityDataFeaturesResponse = await getAPIWithRethrow(
        `/v2/features?entityId=${entityId}&projectRunEntryId=${projectRunEntryId}&projectRunEntityName=${projectRunEntityName}`
      );
      return entityDataFeaturesResponse;
    }
  });
};

export const useGetSchemaOptions = (props: SchemaOptionsProps): UseQueryResult<$TSFixMe> => {
  const {
    entityId,

    // useQuery options
    cacheTime = 0,
    enabled = true,
    ...options
  } = props;

  return useQuery({
    queryKey: [UseGetSchemaOptionsQueryKeys.SchemaOptions, entityId],
    queryFn: () => {
      return getAPIWithRethrow(`/v2/features/schema?entityId=${entityId}`);
    },
    cacheTime,
    enabled: enabled,
    ...options
  });
};

export const useGetSchemaData = (props: SchemaDataProps): UseQueryResult<$TSFixMe> => {
  const {
    entityId,

    // useQuery options
    cacheTime = 0,
    enabled = true,
    ...options
  } = props;

  return useQuery({
    queryKey: [UseGetSchemaDataQueryKeys.SchemaData, entityId],
    queryFn: () => {
      return getEntityFeaturesWithRethrow(entityId);
    },
    cacheTime,
    enabled: enabled,
    ...options
  });
};

export default useEntityFeatures;
export { QUERY_KEY_ENTITY_FEATURES };
