import { UseMutationResult, useMutation } from "@tanstack/react-query";

import { postAPIWithRethrow } from "services/Apis/Apis.service";

interface IVariables {
  like: boolean;
  feedback?: string;
  historyId: string;
}
const useLikeDislikeReaction = (): UseMutationResult<unknown, unknown, IVariables, unknown> => {
  return useMutation({
    mutationFn: ({ like, historyId, feedback }) => {
      return postAPIWithRethrow(
        `/v2/dfs-run-config-groups/generated-output/like?historyId=${historyId}`,
        {
          like,
          feedback
        }
      );
    }
  });
};

export default useLikeDislikeReaction;
