import React, { useMemo, useState } from "react";

// Packages
import { generatePath, useNavigate, useParams } from "react-router-dom";

// MUI
import Grid from "@material-ui/core/Grid";

// Utils
import { WebPaths } from "src/routing/routes";
import { projectRegExpLiteralNotations } from "src/pages/private/ProjectsModule/utils";

// Hooks
import { useGetSegments } from "src/hooks/api/segments";
import useIsDefaultScenario from "./hooks/useIsDefaultScenario";

// Components
import SegmentsTable from "./components/SegmentsTable";
import DeleteSegment from "./components/DeleteSegment";
import CommonLoader from "src/components/CommonLoader";

const Segments = () => {
  const navigate = useNavigate();
  const { projectId, scenarioId, entityId: datasetId, jobId, jobRunId } = useParams();

  const { isDefaultScenario } = useIsDefaultScenario();

  const isJobPath = useMemo(
    () => projectRegExpLiteralNotations.jobs.test(location.pathname),
    [location.pathname]
  );

  // States - STARTS >>
  const [deletingSegmentId, setDeletingSegmentId] = useState<undefined | null | string>("");
  // << ENDS - States

  const { isLoading, data, refetch } = useGetSegments({
    datasetId,
    jobRunId,
    refetchOnMount: true
  });

  const onEdit = (segmentId?: string) => {
    if (!projectId || !scenarioId || !datasetId || !segmentId) {
      return;
    }

    if (!isJobPath) {
      navigate(
        generatePath(WebPaths.Segment, {
          projectId,
          scenarioId,
          entityId: datasetId,
          segmentId
        })
      );
    } else {
      if (!jobId || !jobRunId) {
        return;
      }

      navigate(
        generatePath(`${WebPaths.JobRoutes}${WebPaths.JobSegment}`, {
          projectId,
          jobId,
          scenarioId,
          jobRunId,
          entityId: datasetId,
          segmentId
        })
      );
    }
  };

  return (
    <>
      {!!deletingSegmentId && (
        <DeleteSegment
          deletingSegmentId={deletingSegmentId}
          resetDeleteSegment={() => setDeletingSegmentId(() => "")}
          refetch={refetch}
        />
      )}

      <Grid container>
        {isLoading ? (
          <CommonLoader />
        ) : (
          <SegmentsTable
            data={data}
            isReadOnly={!isDefaultScenario || !!isJobPath}
            onEdit={onEdit}
            onDelete={(segmentId?: string) => setDeletingSegmentId(() => segmentId)}
          />
        )}
      </Grid>
    </>
  );
};

export default Segments;
