import { useQuery } from "@tanstack/react-query";

import { getAPI } from "services/Apis/Apis.service";
import { ChatbotPropertyDto } from "@rapidcanvas/rc-api-core";

export const useGetAllChatbotProperties = () => {
  return useQuery<ChatbotPropertyDto[]>({
    queryKey: ["license"],
    queryFn: () => {
      return getAPI(`/chatbot-property/all`, {}, false);
    }
  });
};
