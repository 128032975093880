import React from "react";

export const InputIcon = ({
  width = 16,
  height = 16,
  viewBox = "0 0 14 14",
  fill = "none",
  color = "currentColor",
  opacity = 1
} = {}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    color={color}>
    <g clipPath="url(#clip0_23428_20485)">
      <path
        d="M12.1333 12.1333H0.933333V0.933333H2.33333V0H0.466667C0.342899 0 0.2242 0.0491665 0.136683 0.136684C0.0491665 0.2242 0 0.342899 0 0.466667L0 12.6C0 12.7238 0.0491665 12.8425 0.136683 12.93C0.2242 13.0175 0.342899 13.0667 0.466667 13.0667H12.6C12.7238 13.0667 12.8425 13.0175 12.93 12.93C13.0175 12.8425 13.0667 12.7238 13.0667 12.6V5.13333H12.1333V12.1333Z"
        fill="#003656"
        fillOpacity={opacity}
      />
      <path
        d="M5.60002 0.93335C4.99382 0.93335 4.66668 1.41402 4.66668 1.86668V5.87348L2.66328 3.87008L2.00342 4.52995L4.80342 7.32995C4.84666 7.37344 4.89806 7.40795 4.95469 7.43149C5.01131 7.45504 5.07203 7.46717 5.13335 7.46717C5.19467 7.46717 5.25539 7.45504 5.31202 7.43149C5.36864 7.40795 5.42005 7.37344 5.46328 7.32995L8.26328 4.52995L7.60342 3.87008L5.60002 5.87348V1.86668H14V0.93335H5.60002Z"
        fill="#003656"
        fillOpacity={opacity}
      />
    </g>
    <defs>
      <clipPath id="clip0_23428_20485">
        <rect width="14" height="13.0667" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
