import React, { useMemo } from "react";

// MUI
import CircularProgress from "@material-ui/core/CircularProgress";

// Icons
import BlockIcon from "@material-ui/icons/Block";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import WarningIcon from "@material-ui/icons/Warning";
import { FileIcon } from "icons/NewUX";

// Utils
import { DatasetStatuses } from "src/pages/private/ProjectsModule/utils";

// Open API
import { DataSourceDto } from "@rapidcanvas/rc-api-core";

// Types
import { EntityTypeEnum, NodeData } from "src/types";
import { DatasetIcon } from "icons/NewUX/DatasetIcon";

type Props = {
  data: NodeData;
  connector: DataSourceDto | any;
};

const statusIconTextMap: { [key: string]: React.ReactNode } = {
  [DatasetStatuses.UnBuilt]: <BlockIcon />,
  [DatasetStatuses.Pending]: <DatasetIcon width={20} height={20} />,
  [DatasetStatuses.Empty]: <WarningIcon />,
  [DatasetStatuses.Skipped]: <SkipNextIcon />,
  [DatasetStatuses.Built]: <DatasetIcon width={20} height={20} />
};
const statusIconEntityMap: { [key: string]: React.ReactNode } = {
  [DatasetStatuses.UnBuilt]: <BlockIcon />,
  [DatasetStatuses.Pending]: <FileIcon width={20} height={20} />,
  [DatasetStatuses.Empty]: <WarningIcon />,
  [DatasetStatuses.Skipped]: <SkipNextIcon />,
  [DatasetStatuses.Built]: <FileIcon width={20} height={20} />
};

const statusNameMap: { [key: string]: string } = {
  [DatasetStatuses.UnBuilt]: "Unbuilt",
  [DatasetStatuses.Pending]: "Pending...",
  [DatasetStatuses.Empty]: "Empty",
  [DatasetStatuses.Skipped]: "Skipped",
  [DatasetStatuses.Built]: "Built"
};

const useAttributes = (props: Props) => {
  const { data, connector } = props || {};

  const icon = useMemo(() => {
    if (data?.image) {
      return <img src={data?.image} alt="dataset-icon" />;
    }

    const isText = data.type === EntityTypeEnum.FILE;
    if (!!data?.entityDSDetails?.id) {
      let thisIcon = isText ? (
        <FileIcon width={20} height={20} />
      ) : (
        <DatasetIcon width={20} height={20} />
      );

      if (data?.entityDSDetails?.purpose === "IMPORT") {
        thisIcon =
          connector?.type === "default" && connector?.image ? (
            connector?.image || <CircularProgress size={20} />
          ) : connector?.url ? (
            <img src={connector?.url} alt={connector?.displayName} />
          ) : (
            <CircularProgress size={20} />
          );
      }

      return thisIcon;
    }

    return isText
      ? statusIconTextMap[data?.status]
      : statusIconEntityMap[data?.status] || <FileIcon width={20} height={20} />;
  }, [data, connector]);

  const status = useMemo(() => {
    return statusNameMap[data?.status] || "";
  }, [data?.status]);

  return { icon, status };
};

export default useAttributes;
