import React, { useRef } from "react";
import _ from "lodash";
import { Grid, Typography, makeStyles } from "@material-ui/core";

import NotFoundIcon from "src/assets/icons/NotFoundIcon";
import PlotlyCharts from "components/PlotlyCharts";
import Result from "src/components/Errors/Result";
import useFetchChartData from "./useFetchChartData";
import { TableTypeChart } from "./TableTypeChart";
import CommonLoader from "src/components/CommonLoader";

const useStyles = makeStyles({
  infoItem: {
    color: "rgba(0,0,0,0.6)",
    fontSize: "14px",
    lineHeight: 1.54
  },
  infoIcon: {
    color: "#515151",
    paddingRight: "4px"
  },
  chartDesc: {
    padding: "12px",
    gap: "4px",
    flexWrap: "nowrap",
    borderTop: "1px solid #396083"
  },
  chartContainerWrap: {
    flexWrap: "nowrap",
    justifyContent: "space-between",
    height: "100%"
  },
  chartWrap: {
    flex: 1,
    width: "inherit",
    maxWidth: "100%",
    overflow: "auto"
  },
  explanation: {
    fontWeight: 500
  }
});
export const Chart = ({ data = {}, token, expanded }: $TSFixMe) => {
  const loaderRef = useRef<HTMLDivElement>(null);

  const classes = useStyles();
  const { name, dataFilePath, chartType, description } = data;
  const {
    data: chartData,
    isLoading,
    isError
  } = useFetchChartData({ name, dataFilePath, chartType });

  return (
    <>
      {(!chartData || isLoading) && !isError ? (
        <div ref={loaderRef}>
          <CommonLoader />
        </div>
      ) : (
        <Grid container direction="column" className={classes.chartContainerWrap}>
          <Grid item className={classes.chartWrap}>
            {chartType === "HTML" ? (
              <iframe
                title={name || ""}
                srcDoc={chartData}
                style={{
                  height: "100%",
                  minHeight: expanded ? "920px" : "inherit",
                  width: "100%",
                  background: "white",
                  border: "none",
                  boxShadow: "none"
                }}
              />
            ) : chartType === "TABLE" ? (
              <TableTypeChart data={chartData} />
            ) : chartType === "IMAGE" ? (
              <img
                src={chartData}
                alt={name}
                style={{
                  maxHeight: "100%",
                  maxWidth: "100%"
                }}
              />
            ) : chartType === "JSON" ? (
              isError ? (
                <Result
                  style={{ padding: "20px" }}
                  icon={<NotFoundIcon />}
                  heading="Unable to load the chart"
                  subtitle1="Chart size limit exceeded. Unable to render"
                />
              ) : (
                <PlotlyCharts {...chartData} />
              )
            ) : (
              <iframe
                onLoad={() => (loaderRef?.current as $TSFixMe)?.remove()}
                title={data.name || ""}
                style={{
                  height: "100%",
                  minHeight: expanded ? "900px" : "inherit",
                  width: "100%",
                  background: "white",
                  border: "none",
                  boxShadow: "none"
                }}
                src={`${data.configPath}&token=${token}`}
              />
            )}
          </Grid>
          {description && (
            <Grid container item direction="column" className={classes.chartDesc}>
              <Typography color="primary" className={classes.explanation}>
                Explanation
              </Typography>
              <Typography className={classes.infoItem} test-id="chartDescription">
                {description}
              </Typography>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

export default Chart;
