import React from "react";

import { SearchRequestDtoSearchComponentsEnum, SearchResultDto } from "@rapidcanvas/rc-api-core";
import useNavigateFromSearchComponent from "src/hooks/api/globalSearch/useNavigateFromSearchComponent";
import SearchTitle from "./SearchTitle";
import { CircularProgress } from "@material-ui/core";
import { getRecentSearches } from "../RecentSearches";
import { concat, uniq } from "lodash";
import { RECENT_SEARCH_KEY } from "src/layout/NavBars/utils/GlobalSearch.constants";

interface IProps {
  searchText: string;
  type: SearchRequestDtoSearchComponentsEnum;
  record: SearchResultDto;
  onNavigate: () => void;
}

const SearchWidget: React.FC<IProps> = (props) => {
  const { type, record, searchText, onNavigate } = props;

  const navigate = useNavigateFromSearchComponent();

  const handleClick = () => {
    if (record.id && record.name && !navigate.isLoading) {
      navigate.mutate(
        {
          id: record.id,
          type,
          projectId: record.pid,
          name: record.name
        },
        {
          onSuccess: () => {
            const recentSearches = getRecentSearches();
            const updatedSearches = uniq(concat(searchText, recentSearches)).slice(0, 5);
            sessionStorage.setItem(RECENT_SEARCH_KEY, JSON.stringify(updatedSearches));
            onNavigate();
          }
        }
      );
    }
  };

  return (
    <SearchTitle
      searchText={searchText}
      title={record.pname ? `${record.name} (Project: ${record.pname ?? ""})` : record.name}
      extra={navigate.isLoading ? <CircularProgress size={10} /> : undefined}
      onClick={handleClick}
    />
  );
};

export default SearchWidget;
