import React, { useCallback, useMemo } from "react";

// Packages
import {
  cloneDeep,
  filter,
  flatMap,
  isNil,
  keyBy,
  map,
  mapValues,
  pickBy,
  sortBy,
  toLower,
  toUpper
} from "lodash";

// MUI
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

// Icons
import { DatasetIcon } from "icons/NewUX/DatasetIcon";

// Open API
import { PipelineStep, PipelineStepTypeEnum } from "@rapidcanvas/rc-api-core";

// Utils
import { addEllipsis } from "utils/helpers";

// Components
import DatasetReadonly from "../Nodes/Dataset/DatasetReadonly";
import FileReadonly from "../Nodes/File/FileReadonly";
import DestinationReadonly from "../Nodes/Destination/DestinationReadonly";
import VectorDBReadonly from "../Nodes/VectorDB/VectorDBReadonly";
import ArtifactReadonly from "../Nodes/Artifact/ArtifactReadonly";
import ModelReadonly from "../Nodes/Model/ModelReadonly";
import RecipeReadonly from "../Nodes/Recipe/RecipeReadonly";
import ChartReadonly from "../Nodes/Chart/ChartReadonly";
import NodeCaptionOverflowTooltip from "../Nodes/NodeCaptionOverflowTooltip";

// Contexts
import { useDagFlowContext } from "../DagFlow/context/useDagFlowContext";

// Types
import { NodeData } from "src/types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      maxHeight: 380,
      overflowY: "auto",
      flexWrap: "nowrap",
      backgroundColor: theme.palette.background.paper,
      "& .divider:last-child": {
        display: "none"
      }
    }
  })
);

const NodeIconMapping = {
  [PipelineStepTypeEnum.Entity]: DatasetReadonly,
  [PipelineStepTypeEnum.File]: FileReadonly,
  DESTINATION: DestinationReadonly,
  [PipelineStepTypeEnum.VectorStore]: VectorDBReadonly,
  [PipelineStepTypeEnum.Artifact]: ArtifactReadonly,
  [PipelineStepTypeEnum.Model]: ModelReadonly,
  [PipelineStepTypeEnum.Dfsgroup]: RecipeReadonly,
  [PipelineStepTypeEnum.Chart]: ChartReadonly,
  [PipelineStepTypeEnum.IntermediateEntity]: null
};

interface IProps {
  selectedNodes?: NodeData[];
  deletingNodesData?: { [key: string]: Array<PipelineStep> };
}

interface ISelectedNodesListProps {
  selectedNodes?: NodeData[];
}

interface IDeletingNodesListProps {
  deletingNodesData?: { [key: string]: Array<PipelineStep> };
}

const SelectedNodesList: React.FC<ISelectedNodesListProps> = (props) => {
  const { selectedNodes } = props;

  const classes = useStyles();

  const getNodeLabel = useCallback(
    (node: NodeData) => node?.label || node?.displayName || node?.name,
    []
  );

  const sortedSelectedNodes = useMemo(
    () => sortBy(selectedNodes, (node) => toLower(getNodeLabel(node))),
    [selectedNodes, getNodeLabel]
  );

  return (
    <List className={classes.root} dense disablePadding data-testid="selectedNodesList">
      {map(sortedSelectedNodes, (node: NodeData, index: number) => {
        const NodeReadonly = !node?.type
          ? null
          : NodeIconMapping[toUpper(node?.type) as keyof typeof NodeIconMapping];
        const label = getNodeLabel(node);

        return (
          <ListItem
            key={`selectedNode-${index}`}
            disableGutters
            data-testid={`selectedNodesListItem-${index}`}>
            <ListItemIcon
              style={{ minWidth: "auto", marginRight: 8 }}
              data-testid={`selectedNodesListItemIcon-${index}`}>
              {!isNil(NodeReadonly) ? (
                <NodeReadonly data={node} />
              ) : (
                <DatasetIcon
                  width={20}
                  height={20}
                  data-testid={`selectedNodesListItemDefaultIcon-${index}`}
                />
              )}
            </ListItemIcon>
            <ListItemText data-testid={`selectedNodesListItemNodeLabel-${index}`}>
              <NodeCaptionOverflowTooltip label={label} placement="bottom-start" maxLength={50}>
                <Typography variant="body2">{addEllipsis(label, 50)}</Typography>
              </NodeCaptionOverflowTooltip>
            </ListItemText>
          </ListItem>
        );
      })}
    </List>
  );
};

const removeDuplicateNodes = (input: { [key: string]: Array<PipelineStep> }) => {
  const clonedInput = cloneDeep(input);

  // Collect all referenced IDs
  const idsSet = new Set(
    flatMap(clonedInput, (items, key) => filter(map(items, "id"), (id) => id !== key))
  );

  // Keep only keys that are not in the collected IDs
  return pickBy(clonedInput, (__, key) => !idsSet.has(key));
};

const DeletingNodesList: React.FC<IDeletingNodesListProps> = (props) => {
  const { deletingNodesData } = props;

  const classes = useStyles();

  const { initialNodes } = useDagFlowContext();

  const nodesMap = useMemo(() => mapValues(keyBy(initialNodes, "id"), "data"), [initialNodes]);

  const uniqueSelectedNodes = useMemo(() => {
    const sortedSelectedNodes = mapValues(deletingNodesData, (items, key) =>
      sortBy(items, (item) => item.id !== key)
    );

    return removeDuplicateNodes(sortedSelectedNodes);
  }, [deletingNodesData]);

  const getNodeLabel = useCallback(
    (node: NodeData) => node?.label || node?.displayName || node?.name,
    []
  );

  return (
    <List className={classes.root} dense disablePadding data-testid="deleteNodeModalNodesList">
      {map(uniqueSelectedNodes, (groupNodes: any, _groupKey: string, groupIndex: number) => (
        <React.Fragment key={groupIndex}>
          {map(groupNodes, (item, index) => {
            const node = nodesMap?.[item?.id];
            const NodeReadonly = !node?.type
              ? null
              : NodeIconMapping[toUpper(node?.type) as keyof typeof NodeIconMapping];
            const label = !!node ? getNodeLabel(node) : "Unknown";

            return (
              <ListItem
                key={`deletingNode-${index}`}
                disableGutters
                data-testid={`deleteNodeModalNodeListItem-${index}`}>
                <ListItemIcon
                  style={{ minWidth: "auto", marginRight: 8 }}
                  data-testid={`deleteNodeModalNodeListItemIcon-${index}`}>
                  {!!node && !isNil(NodeReadonly) ? (
                    <NodeReadonly data={node} />
                  ) : (
                    <DatasetIcon
                      width={20}
                      height={20}
                      data-testid={`deleteNodeModalNodeListItemDefaultIcon-${index}`}
                    />
                  )}
                </ListItemIcon>
                <ListItemText data-testid={`deleteNodeModalNodeListItemNodeLabel-${index}`}>
                  <NodeCaptionOverflowTooltip label={label} placement="bottom-start" maxLength={50}>
                    <Typography variant="body2">{addEllipsis(label, 50)}</Typography>
                  </NodeCaptionOverflowTooltip>
                </ListItemText>
              </ListItem>
            );
          })}

          <Box m={0.5} className="divider">
            <Divider />
          </Box>
        </React.Fragment>
      ))}
    </List>
  );
};

const NodesListWrapper: React.FC<IProps> = (props) => {
  const { selectedNodes, deletingNodesData } = props;

  if (!isNil(selectedNodes)) {
    return <SelectedNodesList selectedNodes={selectedNodes} />;
  }

  return <DeletingNodesList deletingNodesData={deletingNodesData} />;
};

export default NodesListWrapper;
