import React, { useMemo } from "react";

import { DATA_TYPES_ICONS } from "src/assets/icons";

import styles from "./ViewDataSchemaTable.module.scss";
import Table from "src/components/custom/TableV8/Table";
import { CellContext } from "@tanstack/react-table";

const PossibleNullCell = ({ getValue }: CellContext<any, unknown>) => {
  const value = getValue() as any;
  return value === null ? <span>NULL</span> : <span>{value}</span>;
};

const ViewDataSchemaTable = ({
  data,
  isLoadingData,
  maxHeight = "calc(100vh - 179px)"
}: $TSFixMe) => {
  const columns = useMemo(() => {
    return [
      {
        header: "Name",
        id: "Name",
        accessorKey: "name",
        cell: ({ getValue, row }: CellContext<any, unknown>) => {
          const { fieldSchema = {} } = row.original;
          return (
            <>
              <span className={styles.dataTypeIcon}>
                {
                  /* @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */
                  DATA_TYPES_ICONS[
                    fieldSchema?.fieldProperties?.idLikeColumn ? "ID" : fieldSchema?.rcDataType
                  ]
                }
              </span>
              <span>{getValue() as string}</span>
            </>
          );
        }
      },
      {
        header: "Type",
        id: "Type",
        accessorKey: "fieldSchema.rcDataType",
        cell: PossibleNullCell
      }
    ];
  }, []);

  return (
    <Table
      columns={columns}
      data={data ?? []}
      isLoading={isLoadingData}
      sortingProps={{ unsorted: true }}
      infoProps={{ emptyTableMessage: "No records" }}
      stylesProps={{ maxHeight }}
    />
  );
};

export default ViewDataSchemaTable;
