import React from "react";

import Text from "src/components/Widget/Text";
import { dateFormat } from "utils/helpers/date.helpers";
import { ModelDto } from "@rapidcanvas/rc-api-core";
import { CellContext, RowSelectionState, Updater } from "@tanstack/react-table";
import Table from "src/components/custom/TableV8/Table";

const TABLE_SIZE = "small";

interface IProps {
  data: ModelDto[];
  selectedRowIds: $TSFixMe;
  globalFilter?: string | undefined;
  onSelectedRowsChange: (rows: Array<any>) => void;
  shouldDisableInitialSelectedRows?: boolean;
}
export const AddModelsTable: React.FC<IProps> = (props) => {
  const {
    data,
    globalFilter,
    selectedRowIds,
    onSelectedRowsChange,
    shouldDisableInitialSelectedRows
  } = props;

  const artifactColumns = React.useMemo(() => {
    return [
      {
        id: "Name",
        header: "Model Name",
        accessorKey: "name",
        cell: ({ getValue }: CellContext<any, unknown>) => <Text value={getValue() as string} />
      },
      {
        id: "Date Created",
        header: "Date Created",
        accessorKey: "created",
        cell: ({ getValue }: CellContext<any, unknown>) => dateFormat(getValue())
      },
      {
        id: "Source Recipe",
        accessorFn: (row: any) => {
          const value = row.original?.producer;
          return value
            ? `${value?.recipeName} ${value?.projectName ? `(${value?.projectName})` : ""}`
            : "Manual addition";
        },
        header: "Source Recipe"
      }
    ];
  }, []);

  const handleSelectedRowsChange = (rows: Updater<RowSelectionState>) => {
    onSelectedRowsChange(Object.keys(rows));
  };

  return (
    <Table
      data={data}
      columns={artifactColumns}
      rowSelectionProps={{
        isSelectable: true,
        onSelectedRowsChange: handleSelectedRowsChange,
        selectedRowIds: selectedRowIds,
        shouldDisableInitialSelectedRows: shouldDisableInitialSelectedRows
      }}
      sortingProps={{ orderByDefault: "Date Created", sortInverted: true }}
      stylesProps={{ maxHeight: "calc(100vh - 337px)", size: TABLE_SIZE }}
      infoProps={{ emptyTableMessage: "No Models" }}
      getRowId={(row: $TSFixMe) => {
        return row.name;
      }}
      filterProps={{ globalFilter }}
    />
  );
};
