import React, { useMemo } from "react";

import { Button, makeStyles } from "@material-ui/core";

import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";

import { DatasetHelperText } from "../../utils/Dataset.constants";

import useStoreSelectors from "../../hooks/useStoreSelectors";
import { ToastTypes, toastWrapper } from "services/ToastClient/toastWrapper";

const useStyles = makeStyles((theme) => ({
  button: {
    minWidth: 150
  },
  buttonMarginLeft: {
    extend: "button",
    marginLeft: theme.spacing(1)
  }
}));

const SqlInterfaceActions = (props: $TSFixMe) => {
  const { ontologyClose } = props || {};

  const classes = useStyles();

  // Stores - STARTS >>
  const { datasetDatasetsStore, setDatasetIsOntologyStore, datasetFooterActionsStore } =
    useStoreSelectors();
  // << ENDS - Stores

  const dataset = useMemo(() => datasetDatasetsStore[0], [datasetDatasetsStore]);

  const onNext = () => {
    setDatasetIsOntologyStore(true);
    toastWrapper({
      type: ToastTypes.Success,
      content: `Dataset ${dataset?.name} created successfully`
    });
  };

  return (
    <>
      {!datasetFooterActionsStore.next.isHidden && (
        <Button
          id="datasetActionNext"
          variant="contained"
          color="primary"
          className={classes.buttonMarginLeft}
          disabled={datasetFooterActionsStore.next.isDisabled}
          onClick={() => onNext()}
          endIcon={<ChevronRightRoundedIcon />}>
          {DatasetHelperText.Next}
        </Button>
      )}
      {ontologyClose}
    </>
  );
};

export default SqlInterfaceActions;
