import { getAPI, putAPIWithRethrow, deleteAPIWithRethrow } from "../Apis.service";

export const getNotifications = async ({ groupMatching }: $TSFixMe) =>
  await getAPI(
    `/v2/env-alerts?${groupMatching !== undefined ? `&groupMatching=${groupMatching}` : ``}`,
    undefined,
    false
  );

export const updateNotificationWithRethrow = async (notificationId: string) =>
  await putAPIWithRethrow(`/v2/env-alerts/${notificationId}/read`, {});

export const deleteNotificationWithRethrow = async (notificationIds: string[]) =>
  await deleteAPIWithRethrow(`/v2/env-alerts?id=${notificationIds}`, {}, {}, false);
