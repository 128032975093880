import { useState, useMemo } from "react";

import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

import { updateProjectsQueryData } from "src/utils/helpers";

import api from "services/AxiosClient/AxiosClient";

import { UseGetConnectorsQueryKeys } from "src/hooks/api/dataSources/useGetConnectors";

import { useDataSourcesStore, useCanvasStore } from "stores/zustand/stores";
import { dataSourcesGetter } from "stores/zustand/stores.selectors";

import { NodeTypes } from "projectsModule/utils";
import { dataSourcesTypes } from "src/pages/DataSources/utils/DataSources.constants";
import {
  SourceType,
  Criteria,
  DatasetSessionConfig,
  FileUploadStatuses,
  DatasetKeys,
  OntologyDatasetStatuses
} from "../utils/Dataset.constants";

import useStoreSelectors from "./useStoreSelectors";
import useFilesObserver from "./useFilesObserver";
import useFilesSession from "./useFilesSession";
import useDatasetsSession from "./useDatasetsSession";

const useHelpers = (props?: $TSFixMe) => {
  const { ontologyDatasetIndex = -1 } = props || {};

  const { projectId } = useParams();
  const queryClient = useQueryClient();

  const [setReloadTrigger] = useCanvasStore((state: $TSFixMe) => [state.setReloadTrigger]);

  // Stores - STARTS >>
  const dataSourcesStore = useDataSourcesStore(dataSourcesGetter);
  const {
    datasetSourceStore,
    datasetCriterionStore,
    datasetFilesStore,
    datasetDatasetsStore,
    datasetIsDeletingEntitiesStore,
    setDatasetIsDeletingEntitiesStore,
    datasetIsOntologyStore,
    datasetIsFetchingOntologyDataStore
  } = useStoreSelectors();
  // << ENDS - Stores

  const {
    isFilesUploadInitiated,
    isFilesUploading,
    isFilesUploadProcessing,
    isFilesUploadDone,
    isFilesDeleting
  } = useFilesObserver();

  // Observable facets - STARTS >>
  const isDataSourcesFilesUpload = useMemo(
    () => !!datasetSourceStore?.value && datasetSourceStore?.value !== SourceType.FileUpload,
    [datasetSourceStore]
  );

  const isCriterionAppend = useMemo(
    () => !!datasetCriterionStore?.value && datasetCriterionStore?.value === Criteria.Append,
    [datasetCriterionStore]
  );
  // << ENDS - Observable facets

  const source = useMemo(() => {
    if (
      (dataSourcesStore || [])?.length === 0 ||
      !datasetSourceStore?.value ||
      datasetSourceStore?.value === SourceType.FileUpload
    ) {
      return {};
    }

    if ((dataSourcesStore || [])?.length > 0) {
      const dataSourcesTypesMap: $TSFixMe = {};

      dataSourcesTypes.forEach((eachDataSourceType: $TSFixMe) => {
        dataSourcesTypesMap[eachDataSourceType.name] = {
          image: eachDataSourceType.image,
          isSql: eachDataSourceType.isSql || false
        };
      });

      const thisSource = (dataSourcesStore || [])?.find(
        (eachDataSource: $TSFixMe) => eachDataSource?.id === datasetSourceStore?.value
      );

      return {
        ...thisSource,
        ...{ isSql: dataSourcesTypesMap[thisSource?.dataSourceType]?.isSql || false }
      };
    }
  }, [dataSourcesStore, datasetSourceStore, datasetSourceStore]);

  const [filesTimer, setFilesTimer] = useState<$TSFixMe>(new Date().getTime());

  const { getDatasetFilesSession, setDatasetFilesSession } = useFilesSession({
    timer: filesTimer
  });

  const [datasetsTimer, setDatasetsTimer] = useState<$TSFixMe>(new Date().getTime());
  const { getDatasetDatasetsSession, setDatasetDatasetsSession } = useDatasetsSession({
    timer: datasetsTimer
  });

  // Observable facets - STARTS >>
  const isUploadControlProcessing = useMemo(
    () => isFilesUploading || isFilesDeleting || datasetIsDeletingEntitiesStore,
    [isFilesUploading, isFilesDeleting, datasetIsDeletingEntitiesStore]
  );

  const isCancelDisabled = useMemo(
    () => isFilesUploadProcessing || isFilesDeleting || datasetIsDeletingEntitiesStore,
    [isFilesUploadProcessing, isFilesDeleting, datasetIsDeletingEntitiesStore]
  );
  // << ENDS - Observable facets

  const updateFilesSession = ({ index = -1, fields = {} }: $TSFixMe = {}) => {
    if (Object.keys(fields)?.length === 0) {
      return;
    }

    // Update files in session >>
    let thisDatasetFilesSession = getDatasetFilesSession();
    if (index === -1) {
      thisDatasetFilesSession = (thisDatasetFilesSession || [])?.map((eachFile: $TSFixMe) => ({
        ...eachFile,
        ...fields
      }));
    } else {
      thisDatasetFilesSession[index] = { ...thisDatasetFilesSession[index], ...fields };
    }

    setDatasetFilesSession(thisDatasetFilesSession);
    setFilesTimer(() => new Date().getTime());
    // << Update files in session
  };

  const updateDatasetsSession = ({ index = -1, fields = {} }: $TSFixMe = {}) => {
    if (Object.keys(fields)?.length === 0) {
      return;
    }

    // Update datasets in session >>
    let thisDatasetDatasetsSession = getDatasetDatasetsSession();

    if ((thisDatasetDatasetsSession || [])?.length > 0) {
      if (index === -1) {
        thisDatasetDatasetsSession = (thisDatasetDatasetsSession || [])?.map(
          (eachDataset: $TSFixMe) => {
            let thisDataset = { ...eachDataset, ...fields };
            thisDataset[DatasetKeys.OntologyConfig] = {
              ...(eachDataset[DatasetKeys.OntologyConfig] || {}),
              ...(fields[DatasetKeys.OntologyConfig] || {})
            };

            return thisDataset;
          }
        );
      } else {
        let thisDataset = { ...thisDatasetDatasetsSession[index], ...fields };
        thisDataset[DatasetKeys.OntologyConfig] = {
          ...(thisDatasetDatasetsSession[index][DatasetKeys.OntologyConfig] || {}),
          ...(fields[DatasetKeys.OntologyConfig] || {})
        };

        thisDatasetDatasetsSession[index] = thisDataset;
      }

      setDatasetDatasetsSession(thisDatasetDatasetsSession);
      setDatasetsTimer(() => new Date().getTime());
    }
    // << Update datasets in session
  };

  const getDatasetOntologySchemaSession = () => {
    let datasetOntologySchemaSession: $TSFixMe = [];

    if (!sessionStorage.getItem(DatasetSessionConfig.OntologySchemaSessionKey)) {
      return datasetOntologySchemaSession;
    }

    try {
      datasetOntologySchemaSession =
        // @ts-ignore
        JSON.parse(sessionStorage.getItem(DatasetSessionConfig.OntologySchemaSessionKey)) || [];
    } catch (error: $TSFixMe) {
      console.error(error);
    }

    return datasetOntologySchemaSession;
  };

  const setDatasetOntologySchemaSession = (datasetOntologySchemaSession: $TSFixMe = []) => {
    sessionStorage.setItem(
      DatasetSessionConfig.OntologySchemaSessionKey,
      JSON.stringify(datasetOntologySchemaSession)
    );
  };

  const removeDatasetOntologySchemaSession = () => {
    sessionStorage.removeItem(DatasetSessionConfig.OntologySchemaSessionKey);
  };

  const isOntologyProcessing = useMemo(() => {
    if (!datasetIsOntologyStore || ontologyDatasetIndex === -1) {
      return false;
    }

    const datasetOntologyConfig =
      datasetDatasetsStore?.[ontologyDatasetIndex]?.[DatasetKeys.OntologyConfig];

    return (
      datasetIsFetchingOntologyDataStore ||
      datasetOntologyConfig?.[DatasetKeys.Status] === OntologyDatasetStatuses.Updating
    );
  }, [
    datasetIsOntologyStore,
    datasetDatasetsStore,
    ontologyDatasetIndex,
    datasetIsFetchingOntologyDataStore
  ]);

  const deleteEntities = async (entityId = "") => {
    if (isCriterionAppend) {
      return;
    }

    let deletingEntities: $TSFixMe = [];
    entityId && deletingEntities.push(entityId);

    if (entityId || (isFilesUploadInitiated && !isFilesUploadDone)) {
      if (!entityId) {
        const deleteEntitiesInStore = (datasetFilesStore || [])
          ?.filter(
            (eachFile: $TSFixMe) =>
              eachFile?.entityId && eachFile?.status !== FileUploadStatuses.Success
          )
          ?.map((eachFile: $TSFixMe) => eachFile?.entityId);

        deletingEntities = [...deletingEntities, ...deleteEntitiesInStore];
      }

      deletingEntities = [...new Set(deletingEntities)];

      if (deletingEntities?.length > 0) {
        setDatasetIsDeletingEntitiesStore(true);
        await Promise.all(
          deletingEntities?.map(async (entityId: $TSFixMe) => {
            return await api.ProjectsControllerV2Api.deleteStep(entityId, NodeTypes.Dataset);
          })
        )
          .then(() => {
            updateProjectsQueryData({ queryClient, data: { id: projectId }, fetchData: true });
            queryClient.refetchQueries([UseGetConnectorsQueryKeys.Connectors]);
          })
          .catch((error: $TSFixMe) => console.error(error));

        setDatasetIsDeletingEntitiesStore(false);
      }
    }
  };

  const deleteEntityDataSourcesFilesUploadSql = async () => {
    const thisDatasetStore = datasetDatasetsStore[0];
    if (
      thisDatasetStore?.[DatasetKeys.Id] &&
      thisDatasetStore?.[DatasetKeys.OntologyConfig]?.[DatasetKeys.Status] ===
        OntologyDatasetStatuses.Stage
    ) {
      // Update datasets in session >>
      const fields: $TSFixMe = {};
      fields[DatasetKeys.OntologyConfig] = {};
      fields[DatasetKeys.OntologyConfig][DatasetKeys.Status] = OntologyDatasetStatuses.Deleting;

      updateDatasetsSession({ index: 0, fields });
      // << Update datasets in session

      await deleteEntities(thisDatasetStore?.[DatasetKeys.Id]);
      setReloadTrigger();
    }
  };

  return {
    isDataSourcesFilesUpload,
    isCriterionAppend,
    source,
    isUploadControlProcessing,
    isCancelDisabled,
    updateFilesSession,
    updateDatasetsSession,
    getDatasetOntologySchemaSession,
    setDatasetOntologySchemaSession,
    removeDatasetOntologySchemaSession,
    isOntologyProcessing,
    deleteEntities,
    deleteEntityDataSourcesFilesUploadSql
  };
};

export default useHelpers;
