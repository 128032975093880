import {
  DatasetStatuses,
  RecipeStatuses,
  ChartStatuses,
  ArtifactStatuses,
  ModelStatuses
} from "./Projects.constants";

export const NodeDatasetTheme = {
  backgroundColor: "#b6b7b9", // Ash color
  borderColor: "#b6b7b9", // Ash color
  statusColor: "#b6b7b9", // Ash color
  iconColor: "#fff", // White color
  highlightColor: "#fff4bc", // Yellow color
  activeColor: "#ffdf80", // Yellow color
  onHover: {
    backgroundColor: "#fff", // White color
    borderColor: "#b6b7b9", // Ash color
    statusColor: "#b6b7b9", // Ash color
    iconColor: "#b6b7b9" // Ash color
  },
  [DatasetStatuses.Pending]: {
    backgroundColor: "#4646b5", // Blue color
    borderColor: "#4646b5", // Blue color
    statusColor: "#ff5c00", // Orange color
    iconColor: "#fff", // White color
    onHover: {
      backgroundColor: "#fff", // White color
      borderColor: "#4646b5", // Blue color
      statusColor: "#ff5c00", // Orange color
      iconColor: "#4646b5" // Blue color
    }
  },
  [DatasetStatuses.Built]: {
    backgroundColor: "#4646b5", // Blue color
    borderColor: "#4646b5", // Blue color
    statusColor: "#4caf50", // Green color
    iconColor: "#fff", // White color
    onHover: {
      backgroundColor: "#fff", // White color
      borderColor: "#4646b5", // Blue color
      statusColor: "#4caf50", // Green color
      iconColor: "#4646b5" // Blue color
    }
  }
};

export const NodeFileTheme = {
  backgroundColor: "#b6b7b9", // Ash color
  borderColor: "#b6b7b9", // Ash color
  statusColor: "#b6b7b9", // Ash color
  iconColor: "#fff", // White color
  highlightColor: "#fff4bc", // Yellow color
  activeColor: "#ffdf80", // Yellow color
  onHover: {
    backgroundColor: "#fff", // White color
    borderColor: "#b6b7b9", // Ash color
    statusColor: "#b6b7b9", // Ash color
    iconColor: "#b6b7b9" // Ash color
  },
  [DatasetStatuses.Pending]: {
    backgroundColor: "#b26ab9", // Purple color
    borderColor: "#b26ab9", // Purple color
    statusColor: "#ff5c00", // Orange color
    iconColor: "#fff", // White color
    onHover: {
      backgroundColor: "#fff", // White color
      borderColor: "#b26ab9", // Purple color
      statusColor: "#ff5c00", // Orange color
      iconColor: "#b26ab9" // Purple color
    }
  },
  [DatasetStatuses.Built]: {
    backgroundColor: "#b26ab9", // Purple color
    borderColor: "#b26ab9", // Purple color
    statusColor: "#4caf50", // Green color
    iconColor: "#fff", // White color
    onHover: {
      backgroundColor: "#fff", // White color
      borderColor: "#b26ab9", // Purple color
      statusColor: "#4caf50", // Green color
      iconColor: "#b26ab9" // Purple color
    }
  }
};

export const NodeDestinationTheme = {
  backgroundColor: "#b6b7b9", // Ash color
  borderColor: "#b6b7b9", // Ash color
  statusColor: "#b6b7b9", // Ash color
  iconColor: "#fff", // White color
  highlightColor: "#fff4bc", // Yellow color
  activeColor: "#ffdf80", // Yellow color
  onHover: {
    backgroundColor: "#fff", // White color
    borderColor: "#b6b7b9", // Ash color
    statusColor: "#b6b7b9", // Ash color
    iconColor: "#b6b7b9" // Ash color
  },
  [DatasetStatuses.Pending]: {
    backgroundColor: "#b26ab9", // Purple color
    borderColor: "#b26ab9", // Purple color
    statusColor: "#ff5c00", // Orange color
    iconColor: "#fff", // White color
    onHover: {
      backgroundColor: "#fff", // White color
      borderColor: "#b26ab9", // Purple color
      statusColor: "#ff5c00", // Orange color
      iconColor: "#b26ab9" // Purple color
    }
  },
  [DatasetStatuses.Built]: {
    backgroundColor: "#b26ab9", // Purple color
    borderColor: "#b26ab9", // Purple color
    statusColor: "#4caf50", // Green color
    iconColor: "#fff", // White color
    onHover: {
      backgroundColor: "#fff", // White color
      borderColor: "#b26ab9", // Purple color
      statusColor: "#4caf50", // Green color
      iconColor: "#b26ab9" // Purple color
    }
  }
};

export const NodeVectorTheme = {
  backgroundColor: "#BF9300", // Ash color
  borderColor: "#BF9300", // Ash color
  statusColor: "#BF9300", // Ash color
  iconColor: "#fff", // White color
  highlightColor: "#fff4bc", // Yellow color
  activeColor: "#ffdf80", // Yellow color
  onHover: {
    backgroundColor: "#fff", // White color
    borderColor: "#BF9300", // Ash color
    statusColor: "#BF9300", // Ash color
    iconColor: "#BF9300" // Ash color
  },
  [DatasetStatuses.Pending]: {
    backgroundColor: "#BF9300", // Purple color
    borderColor: "#BF9300", // Purple color
    statusColor: "#ff5c00", // Orange color
    iconColor: "#fff", // White color
    onHover: {
      backgroundColor: "#fff", // White color
      borderColor: "#BF9300", // Purple color
      statusColor: "#ff5c00", // Orange color
      iconColor: "#BF9300" // Purple color
    }
  },
  [DatasetStatuses.Built]: {
    backgroundColor: "#BF9300", // Purple color
    borderColor: "#BF9300", // Purple color
    statusColor: "#4caf50", // Green color
    iconColor: "#fff", // White color
    onHover: {
      backgroundColor: "#fff", // White color
      borderColor: "#BF9300", // Purple color
      statusColor: "#4caf50", // Green color
      iconColor: "#BF9300" // Purple color
    }
  }
};
export const NodeRecipeTheme = {
  backgroundColor: "#b6b7b9", // Ash color
  borderColor: "#b6b7b9", // Ash color
  statusColor: "#b6b7b9", // Ash color
  iconColor: "#fff", // White color
  highlightColor: "#fff4bc", // Yellow color
  activeColor: "#ffdf80", // Yellow color
  onHover: {
    backgroundColor: "#fff", // White color
    borderColor: "#b6b7b9", // Ash color
    statusColor: "#b6b7b9", // Ash color
    iconColor: "#b6b7b9" // Ash color
  },
  [RecipeStatuses.Pending]: {
    backgroundColor: "#ff5c00", // Orange color
    borderColor: "#ff5c00", // Orange color
    statusColor: "#ff5c00", // Orange color
    iconColor: "#fff", // White color
    onHover: {
      backgroundColor: "#fff", // White color
      borderColor: "#ff5c00", // Orange color
      statusColor: "#ff5c00", // Orange color
      iconColor: "#ff5c00" // Orange color
    }
  },
  [RecipeStatuses.Running]: {
    backgroundColor: "#ffd339", // Dark Yellow color
    borderColor: "#ffd339", // Dark Yellow color
    statusColor: "#008fe4", // Cerulean (Blueish) color
    iconColor: "#fff", // White color
    onHover: {
      backgroundColor: "#fff", // White color
      borderColor: "#ffd339", // Dark Yellow color
      statusColor: "#008fe4", // Cerulean (Blueish) color
      iconColor: "#ffd339" // Dark Yellow color
    }
  },
  [RecipeStatuses.Success]: {
    backgroundColor: "#4caf50", // Green color
    borderColor: "#4caf50", // Green color
    statusColor: "#4caf50", // Green color
    iconColor: "#fff", // White color
    onHover: {
      backgroundColor: "#fff", // White color
      borderColor: "#4caf50", // Green color
      statusColor: "#4caf50", // Green color
      iconColor: "#4caf50" // Green color
    }
  },
  [RecipeStatuses.Error]: {
    backgroundColor: "#da1d27", // Red color
    borderColor: "#da1d27", // Red color
    statusColor: "#da1d27", // Red color
    iconColor: "#fff", // White color
    onHover: {
      backgroundColor: "#fff", // White color
      borderColor: "#da1d27", // Red color
      statusColor: "#da1d27", // Red color
      iconColor: "#da1d27" // Red color
    }
  }
};

export const NodeChartTheme = {
  backgroundColor: "#b6b7b9", // Ash color
  borderColor: "#b6b7b9", // Ash color
  statusColor: "#b6b7b9", // Ash color
  iconColor: "#fff", // White color
  activeColor: "#ffdf80", // Yellow color
  onHover: {
    backgroundColor: "#fff", // White color
    borderColor: "#b6b7b9", // Ash color
    statusColor: "#b6b7b9", // Ash color
    iconColor: "#b6b7b9" // Ash color
  },
  [ChartStatuses.Pending]: {
    backgroundColor: "#b6b7b9", // Ash color
    borderColor: "#b6b7b9", // Ash color
    statusColor: "#ff5c00", // Orange color
    iconColor: "#fff", // White color
    onHover: {
      backgroundColor: "#fff", // White color
      borderColor: "#b6b7b9", // Ash color
      statusColor: "#ff5c00", // Orange color
      iconColor: "#b6b7b9" // Ash color
    }
  },
  [ChartStatuses.Built]: {
    backgroundColor: "#008fe4", // Cerulean (Blueish) color
    borderColor: "#008fe4", // Cerulean (Blueish) color
    statusColor: "#4caf50", // Green color
    iconColor: "#fff", // White color
    onHover: {
      backgroundColor: "#fff", // White color
      borderColor: "#008fe4", // Purple color
      statusColor: "#4caf50", // Cerulean (Blueish) color
      iconColor: "#008fe4" // Cerulean (Blueish) color
    }
  }
};

export const NodeArtifactTheme = {
  backgroundColor: "#b6b7b9", // Ash color
  borderColor: "#b6b7b9", // Ash color
  iconColor: "#fff", // White color
  highlightColor: "#fff4bc", // Yellow color
  activeColor: "#ffdf80", // Yellow color
  onHover: {
    backgroundColor: "#fff", // White color
    borderColor: "#b6b7b9", // Ash color
    iconColor: "#b6b7b9" // Ash color
  },
  [ArtifactStatuses.Built]: {
    backgroundColor: "#379dbd", // Turquoise (Blueish) color
    borderColor: "#379dbd", // Turquoise (Blueish) color
    iconColor: "#fff", // White color
    onHover: {
      backgroundColor: "#fff", // White color
      borderColor: "#379dbd", // Turquoise (Blueish) color
      iconColor: "#379dbd" // Turquoise (Blueish) color
    }
  }
};

export const NodeModelTheme = {
  backgroundColor: "#b6b7b9", // Ash color
  borderColor: "#b6b7b9", // Ash color
  iconColor: "#fff", // White color
  highlightColor: "#fff4bc", // Yellow color
  activeColor: "#ffdf80", // Yellow color
  onHover: {
    backgroundColor: "#fff", // White color
    borderColor: "#b6b7b9", // Ash color
    iconColor: "#b6b7b9" // Ash color
  },
  [ModelStatuses.Built]: {
    backgroundColor: "#ad5fea", // Light Purple color
    borderColor: "#ad5fea", // Light Purple color
    iconColor: "#fff", // White color
    onHover: {
      backgroundColor: "#fff", // White color
      borderColor: "#ad5fea", // Light Purple color
      iconColor: "#ad5fea" // Light Purple color
    }
  }
};
