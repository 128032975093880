import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import React, { useState, useMemo, useEffect, useRef } from "react";
import RelativeTime from "dayjs/plugin/relativeTime";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import dayjs from "dayjs";
import {
  Grid,
  Box,
  IconButton,
  Badge,
  Popover,
  Link as MuiLink,
  Typography,
  CircularProgress,
  Button,
  Tooltip
} from "@material-ui/core";
import { generatePath, Link, useNavigate } from "react-router-dom";
import { forEach, filter, includes, size, slice, get } from "lodash";

import HighCpuUsageIcon from "src/assets/icons/HighCpuUsageIcon";
import LowDiskIcon from "src/assets/icons/LowDiskIcon";
import NotificationMemoryIcon from "src/assets/icons/NotificationMemoryIcon";
import { useCanvasStore } from "stores/zustand/stores";
import NotificationsList from "./NotificationsList";
import useClearAllNotifications from "hooks/api/notifications/useClearAllNotifications";
import useGetNotifications from "hooks/api/notifications/useGetNotifications";
import useReadAllNotifications from "hooks/api/notifications/useReadAllNotifications";
import { WebPaths } from "src/routing/routes";
import {
  NotificationsConfig,
  lastRunEntryId,
  openLogsModal
} from "./TopNavBarNotifications.constants";
import {
  NotificationTypes,
  NotificationParentTypes,
  Notification,
  NotificationResponse,
  NotificationStatus
} from "src/types/Notifications.types";
import { useGetRole } from "hooks/useGetRole";
import { useStyles } from "./TopNavBarNotifications.style";
import { LogsNewIconV2 } from "src/assets/icons/LogsNewIconV2";
import { Roles } from "src/types";
import { RecipeTypesPathMapping } from "src/pages/private/ProjectsModule/utils/Projects.constants";
import { handleResponse } from "services/Apis/Apis.service";

dayjs.extend(RelativeTime);

const TopNavBarNotifications: React.FC = () => {
  const classes = useStyles();
  const { roleName, isRoleYieldsDataAppView, isConsumer, isDataAppPowerUser } = useGetRole();
  const navigate = useNavigate();

  const setOpenRecipeRunsQueueStore = useCanvasStore((state) => state.setOpenRecipeRunsQueue);

  const { data, refetch, isFetching, isError } = useGetNotifications();
  const ref = useRef<boolean>(false);
  const fetchingRef = useRef<boolean>(isFetching);
  const clearAll = useClearAllNotifications();
  const readAll = useReadAllNotifications();

  // Popover code - STARTS >>
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (Boolean(anchorEl) && isError) {
      handleResponse({
        errorMessage: "Failed to fetch notifications. Please wait for sometime."
      });
    }
  }, [anchorEl, isError]);

  useEffect(() => {
    if (isFetching !== fetchingRef.current) {
      fetchingRef.current = isFetching;
    }
  }, [isFetching]);

  useEffect(() => {
    let interval: NodeJS.Timeout;

    const callback = () => {
      if (ref.current === false) {
        ref.current = true;
        refetch();
        let count = 1;
        interval = setInterval(() => {
          if (
            count >
            NotificationsConfig.MaxDurationInSeconds / NotificationsConfig.PollingDurationInSeconds
          ) {
            clearInterval(interval);
            ref.current = false;
          } else {
            count = count + 1;
            if (!fetchingRef.current) {
              refetch();
            }
          }
        }, NotificationsConfig.PollingDurationInSeconds * 1000);
      }
    };

    const handleVisibilityChange = () => {
      // to call the api when user switch between browser tabs (better than refetchOnWindowFocus)
      if (!document.hidden && !fetchingRef.current) {
        refetch();
      }
    };

    window.addEventListener("notificationTimerEvent", callback);
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      clearInterval(interval);
      window.removeEventListener("notificationTimerEvent", callback);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    refetch();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "notification-popover" : undefined;
  // ENDS - Popover code

  const getFrequency = (note: NotificationResponse) => {
    const filtered = filter(note.matching, ({ status }) => status === note.status);
    const length = size(filtered);

    return { frequency: length > 0 ? length + 1 : 0 };
  };

  const getCustomEnvironmentProperties = (eachNote: NotificationResponse, text: string) => {
    const hideNavigation =
      isRoleYieldsDataAppView ||
      isConsumer ||
      isDataAppPowerUser ||
      includes([Roles["Business User"].name, Roles.Demo.name], roleName);

    return {
      label: hideNavigation ? (
        <span className={classes.textLink}>{eachNote.parentName}</span>
      ) : (
        <Link to={`/environments/${eachNote.parentId}`} className={classes.textLink}>
          {eachNote.parentName}
        </Link>
      ),
      tooltip: hideNavigation
        ? "Please ask admin to consider allocating additional resources for an optimal experience"
        : "Please consider allocating additional resources for an optimal experience",
      text,
      ...getFrequency(eachNote)
    };
  };

  const getBYOSProperties = (eachNote: NotificationResponse, text: string) => {
    return {
      label: (
        <Link to={`/dataapps-ui/${eachNote.parentName}`} className={classes.textLink}>
          {eachNote.extraInfo.dataAppDisplayName ?? eachNote.parentName}
        </Link>
      ),
      tooltip:
        isRoleYieldsDataAppView || isConsumer
          ? "To ensure smooth operations, please ask admin consider upgrading the configuration of the associated dataApp environment."
          : "To ensure smooth operations, consider upgrading the configuration of the associated dataApp environment.",
      text,
      ...getFrequency(eachNote)
    };
  };

  const getIcon = (status: NotificationStatus) => {
    switch (status) {
      case NotificationStatus.Error:
      case NotificationStatus.JobTimeout:
      case NotificationStatus.STOPPED:
      case NotificationStatus.RecipeTimeout:
        return <ReportProblemOutlinedIcon color="error" />;
      case NotificationStatus.Success:
      case NotificationStatus.Handled:
      case NotificationStatus.STOPPED:
        return <CheckCircleOutlineIcon style={{ color: "#4CAF50" }} />;
      case NotificationStatus.Triggered:
      default:
        return <ErrorOutlineIcon />;
    }
  };

  const getEdaCommonInfo = (eachNote: NotificationResponse) => {
    const { extraInfo } = eachNote;
    const projectId = extraInfo.projectId;
    const scenarioId = extraInfo.scenarioId;
    const parentId = eachNote.parentId;
    const projectDisplayName = extraInfo.projectName;

    return {
      icon: getIcon(eachNote.status),
      label:
        !!projectId && !!scenarioId && !!parentId ? (
          <Link
            to={`/view-data/projects/${projectId}/entity/${parentId}/scenario/${scenarioId}/analysis`}
            className={classes.textLink}>
            <span>{`${eachNote.parentName} (Project: ${projectDisplayName ?? "-"})`}</span>
          </Link>
        ) : (
          <span className={classes.textLink}>{`${eachNote.parentName} (Project: ${
            projectDisplayName ?? "-"
          })`}</span>
        ),
      tooltip: "",
      frequency: 0,
      loader:
        eachNote.status === NotificationStatus.Triggered ? (
          <CircularProgress size={12} />
        ) : undefined
    };
  };

  const handleNavigate = (to: string) => {
    navigate(to);
    handleClose();
  };

  const onProjectClick = ({
    projectId,
    scenarioId
  }: {
    projectId?: string;
    scenarioId?: string;
  }) => {
    if (!projectId || !scenarioId) {
      return;
    }

    const path = generatePath(`${WebPaths.Dag}${WebPaths.Canvas}`, {
      projectId,
      scenarioId
    });

    setOpenRecipeRunsQueueStore(true);
    navigate(path);
    handleClose();
  };

  const getNotificationsByRoles = (allNotifications: Notification[]) => {
    if (isRoleYieldsDataAppView || isConsumer || isDataAppPowerUser) {
      return filter(allNotifications, ({ parentType }) =>
        includes(
          [
            NotificationParentTypes.BYOS,
            NotificationParentTypes.DataApp,
            NotificationParentTypes.BYOR
          ],
          parentType
        )
      );
    }

    return allNotifications;
  };

  const handleJobsLogsClick = (eachNote: NotificationResponse) => {
    switch (eachNote.status) {
      case NotificationStatus.Error:
      case NotificationStatus.RecipeTimeout: {
        const canNavigate = !!eachNote.extraInfo.projectId && !!eachNote.parentId;
        const recipePath = get(RecipeTypesPathMapping, eachNote.extraInfo.recipeType);
        const hasAllParams =
          !!recipePath &&
          !!eachNote.extraInfo.scenarioId &&
          !!eachNote.extraInfo.lastRunEntryId &&
          !!eachNote.extraInfo.recipeId;
        if (canNavigate && hasAllParams) {
          handleNavigate(
            `/projects/${eachNote.extraInfo.projectId}/jobs/${eachNote.parentId}/scenario/${eachNote.extraInfo.scenarioId}/job-runs/${eachNote.extraInfo.lastRunEntryId}/recipes/${recipePath}/${eachNote.extraInfo.recipeId}?${openLogsModal}=true`
          );
        }
        break;
      }

      case NotificationStatus.Triggered:
      case NotificationStatus.JobTimeout: {
        const canNavigate =
          !!eachNote.extraInfo.projectId &&
          !!eachNote.parentId &&
          !!eachNote.extraInfo.lastRunEntryId;
        if (canNavigate) {
          handleNavigate(
            `/projects/${eachNote.extraInfo.projectId}/jobs/${eachNote.parentId}/job-runs?${lastRunEntryId}=${eachNote.extraInfo.lastRunEntryId}&${openLogsModal}=true`
          );
        }
        break;
      }
    }
  };

  const getJobText = (status: NotificationStatus) => {
    switch (status) {
      case NotificationStatus.Success:
        return "Scheduler run is successful";
      case NotificationStatus.Error:
        return "Scheduler run failed";
      case NotificationStatus.Triggered:
        return "Scheduler run is in progress";
      case NotificationStatus.JobTimeout:
        return "Scheduler run failed due to Scheduler timeout";
      case NotificationStatus.RecipeTimeout:
        return "Scheduler run failed due to recipe timeout";
      default:
        return "";
    }
  };

  const { filtered, unread } = useMemo(() => {
    const filteredNotifications: Notification[] = [];

    forEach(data, (eachNote) => {
      switch (eachNote.parentType) {
        case NotificationParentTypes.CustomEnv: {
          switch (eachNote.type) {
            case NotificationTypes.OOM: {
              filteredNotifications.push({
                ...eachNote,
                icon: <NotificationMemoryIcon />,
                ...getCustomEnvironmentProperties(eachNote, "Memory limit exceeded")
              });
              break;
            }
            case NotificationTypes.HIGH_CPU: {
              filteredNotifications.push({
                ...eachNote,
                icon: <HighCpuUsageIcon />,
                ...getCustomEnvironmentProperties(eachNote, "High CPU Usage")
              });
              break;
            }
            case NotificationTypes.LOW_DISK: {
              filteredNotifications.push({
                ...eachNote,
                icon: <LowDiskIcon />,
                ...getCustomEnvironmentProperties(eachNote, "Low Disk Space")
              });
              break;
            }
            case NotificationTypes.CUSTOM_ENV_LAUNCH: {
              const hideNavigation =
                isRoleYieldsDataAppView ||
                isConsumer ||
                includes([Roles["Business User"].name, Roles.Demo.name], roleName);
              if (hideNavigation) {
                return;
              }

              filteredNotifications.push({
                ...eachNote,
                icon: getIcon(eachNote.status),
                label: (
                  <Link to={`/environments/${eachNote.parentId}`} className={classes.textLink}>
                    {eachNote.parentName}
                  </Link>
                ),
                text:
                  eachNote.status === NotificationStatus.Success
                    ? "Environment relaunch successful"
                    : eachNote.status === NotificationStatus.Triggered
                      ? "Environment relaunch is in progress"
                      : eachNote.status === NotificationStatus.Error
                        ? "Environment relaunch failed"
                        : "",
                tooltip: "",
                frequency: 0,
                loader:
                  eachNote.status === NotificationStatus.Triggered ? (
                    <CircularProgress size={12} />
                  ) : undefined,
                extra:
                  eachNote.status === NotificationStatus.Success ? undefined : (
                    <IconButton
                      className={classes.btn}
                      classes={{ label: classes.label }}
                      onClick={() => {
                        if (!hideNavigation) {
                          handleNavigate(
                            `/environments/${eachNote.parentId}?${openLogsModal}=true`
                          );
                        }
                      }}>
                      <LogsNewIconV2 width="14px" height="14px" color="#515151" />
                    </IconButton>
                  )
              });
              break;
            }
          }
          break;
        }

        case NotificationParentTypes.PROJECT: {
          switch (eachNote.type) {
            case NotificationTypes.PROJECT_MARKDOWN: {
              if (isRoleYieldsDataAppView || isConsumer || isDataAppPowerUser) {
                return;
              }

              filteredNotifications.push({
                ...eachNote,
                icon: getIcon(eachNote.status),
                frequency: 0,
                tooltip: "",
                text:
                  eachNote.status === NotificationStatus.Handled
                    ? "About Page generation is successful"
                    : eachNote.status === NotificationStatus.Triggered
                      ? "About Page generation is in progress"
                      : eachNote.status === NotificationStatus.Error
                        ? "About Page generation failed"
                        : "",
                label: (
                  <Link to={`/projects/${eachNote.parentId}/about`} className={classes.textLink}>
                    {eachNote.parentName}
                  </Link>
                ),
                loader:
                  eachNote.status === NotificationStatus.Triggered ? (
                    <CircularProgress size={12} />
                  ) : undefined
              });
              break;
            }

            case NotificationTypes.PROJECT_SNIPPET: {
              if (isRoleYieldsDataAppView || isConsumer || isDataAppPowerUser) {
                return;
              }

              filteredNotifications.push({
                ...eachNote,
                icon: getIcon(eachNote.status),
                frequency: 0,
                tooltip: "",
                text:
                  eachNote.status === NotificationStatus.Handled
                    ? "Snippet generation is successful"
                    : eachNote.status === NotificationStatus.Triggered
                      ? "Snippet generation is in progress"
                      : eachNote.status === NotificationStatus.Error
                        ? "Snippet generation failed"
                        : "",
                label: (
                  <Link to={`/projects/${eachNote.parentId}`} className={classes.textLink}>
                    {eachNote.parentName}
                  </Link>
                ),
                loader:
                  eachNote.status === NotificationStatus.Triggered ? (
                    <CircularProgress size={12} />
                  ) : undefined
              });
              break;
            }

            case NotificationTypes.QUEUE_RECIPE: {
              if (isRoleYieldsDataAppView || isConsumer || isDataAppPowerUser) {
                return;
              }

              filteredNotifications.push({
                ...eachNote,
                icon: getIcon(eachNote.status),
                frequency: 0,
                tooltip: "",
                text:
                  eachNote.status === NotificationStatus.Handled
                    ? "Recipe Queue run completed"
                    : eachNote.status === NotificationStatus.Triggered
                      ? "Recipe Queue run is in progress"
                      : "",
                label: (
                  <MuiLink
                    className={classes.textLink}
                    onClick={() =>
                      onProjectClick({
                        projectId: eachNote?.parentId,
                        scenarioId: eachNote?.extraInfo?.scenarioId
                      })
                    }>
                    {eachNote.parentName}
                  </MuiLink>
                ),
                loader:
                  eachNote.status === NotificationStatus.Triggered ? (
                    <CircularProgress size={12} />
                  ) : undefined
              });
              break;
            }
          }
          break;
        }

        case NotificationParentTypes.BYOS:
        case NotificationParentTypes.BYOR: {
          switch (eachNote.type) {
            case NotificationTypes.OOM: {
              filteredNotifications.push({
                ...eachNote,
                icon: <NotificationMemoryIcon />,
                ...getBYOSProperties(eachNote, "Memory limit exceeded")
              });
              break;
            }
            case NotificationTypes.HIGH_CPU: {
              filteredNotifications.push({
                ...eachNote,
                icon: <HighCpuUsageIcon />,
                ...getBYOSProperties(eachNote, "High CPU Usage")
              });
              break;
            }
            case NotificationTypes.LOW_DISK: {
              filteredNotifications.push({
                ...eachNote,
                icon: <LowDiskIcon />,
                ...getBYOSProperties(eachNote, "Low Disk Space")
              });
              break;
            }
          }
          break;
        }

        case NotificationParentTypes.PredictionJob: {
          const canNavigate = !!eachNote.extraInfo.projectId && !!eachNote.parentId;
          switch (eachNote.type) {
            case NotificationTypes.PREDICTION_JOB_RUN:
              filteredNotifications.push({
                ...eachNote,
                frequency: 0,
                icon: getIcon(eachNote.status),
                text:
                  eachNote.status === NotificationStatus.Success
                    ? "Prediction Run is successful"
                    : eachNote.status === NotificationStatus.Triggered
                      ? "Prediction Run is in progress"
                      : "Prediction Run has failed",
                tooltip: "",
                label: canNavigate ? (
                  <Link
                    to={`/projects/${eachNote.extraInfo.projectId}/prediction-job/${eachNote.parentId}`}
                    className={classes.textLink}>
                    {`${eachNote.parentName} (Project: ${eachNote.extraInfo.projectName ?? "-"})`}
                  </Link>
                ) : (
                  <span className={classes.textLink}>{`${eachNote.parentName} (Project: ${
                    eachNote.extraInfo.projectName ?? "-"
                  })`}</span>
                ),
                extra:
                  eachNote.status === NotificationStatus.Success ? undefined : (
                    <IconButton
                      className={classes.btn}
                      classes={{ label: classes.label }}
                      onClick={() => {
                        if (canNavigate) {
                          handleNavigate(
                            `/projects/${eachNote.extraInfo.projectId}/prediction-job/${eachNote.parentId}?${openLogsModal}=true`
                          );
                        }
                      }}>
                      <LogsNewIconV2 width="14px" height="14px" color="#515151" />
                    </IconButton>
                  ),
                loader:
                  eachNote.status === NotificationStatus.Triggered ? (
                    <CircularProgress size={12} />
                  ) : undefined
              });
          }
          break;
        }

        case NotificationParentTypes.Job: {
          const canNavigate =
            !!eachNote.extraInfo.projectId &&
            !!eachNote.parentId &&
            !!eachNote.extraInfo.lastRunEntryId;

          switch (eachNote.type) {
            case NotificationTypes.JOB_RUN:
              filteredNotifications.push({
                ...eachNote,
                frequency: 0,
                icon: getIcon(eachNote.status),
                text: getJobText(eachNote.status),
                tooltip: "",
                label: canNavigate ? (
                  <Link
                    to={`/projects/${eachNote.extraInfo.projectId}/jobs/${eachNote.parentId}/job-runs/${eachNote.extraInfo.lastRunEntryId}/job-canvas`}
                    className={classes.textLink}>
                    {`${eachNote.parentName} (Project: ${eachNote.extraInfo.projectName ?? "-"})`}
                  </Link>
                ) : (
                  <span className={classes.textLink}>{`${eachNote.parentName} (Project: ${
                    eachNote.extraInfo.projectName ?? "-"
                  })`}</span>
                ),
                extra:
                  eachNote.status === NotificationStatus.Success ? undefined : (
                    <IconButton
                      className={classes.btn}
                      classes={{ label: classes.label }}
                      onClick={() => handleJobsLogsClick(eachNote)}>
                      <LogsNewIconV2 width="14px" height="14px" color="#515151" />
                    </IconButton>
                  ),
                loader:
                  eachNote.status === NotificationStatus.Triggered ? (
                    <CircularProgress size={12} />
                  ) : undefined
              });
          }
          break;
        }

        case NotificationParentTypes.Recipe: {
          const recipePath = get(RecipeTypesPathMapping, eachNote.extraInfo.recipeType);
          const canNavigate =
            !!eachNote.extraInfo.projectId &&
            !!eachNote.extraInfo.scenarioId &&
            !!eachNote.parentId &&
            recipePath;
          switch (eachNote.type) {
            case NotificationTypes.RECIPE_RUN:
              filteredNotifications.push({
                ...eachNote,
                frequency: 0,
                icon: getIcon(eachNote.status),
                text:
                  eachNote.status === NotificationStatus.Success
                    ? "Recipe run successful"
                    : eachNote.status === NotificationStatus.Triggered
                      ? "Recipe run is in progress"
                      : eachNote.status === NotificationStatus.STOPPED
                        ? "Recipe run has been stopped"
                        : "Recipe run failed",
                tooltip: "",
                label: canNavigate ? (
                  <Link
                    to={`/projects/${eachNote.extraInfo.projectId}/scenario/${eachNote.extraInfo.scenarioId}/${recipePath}/${eachNote.parentId}`}
                    className={classes.textLink}>
                    {`${eachNote.parentName} (Project: ${eachNote.extraInfo.projectName ?? "-"})`}
                  </Link>
                ) : (
                  <span className={classes.textLink}>{`${eachNote.parentName} (Project: ${
                    eachNote.extraInfo.projectName ?? "-"
                  })`}</span>
                ),
                extra:
                  eachNote.status === NotificationStatus.Success ? undefined : (
                    <IconButton
                      className={classes.btn}
                      classes={{ label: classes.label }}
                      onClick={() => {
                        if (canNavigate) {
                          handleNavigate(
                            `/projects/${eachNote.extraInfo.projectId}/scenario/${eachNote.extraInfo.scenarioId}/${recipePath}/${eachNote.parentId}?${openLogsModal}=true`
                          );
                        }
                      }}>
                      <LogsNewIconV2 width="14px" height="14px" color="#515151" />
                    </IconButton>
                  ),
                loader:
                  eachNote.status === NotificationStatus.Triggered ? (
                    <CircularProgress size={12} />
                  ) : undefined
              });
          }
          break;
        }

        case NotificationParentTypes.DataApp: {
          switch (eachNote.type) {
            case NotificationTypes.DATAAPP_LAUNCH: {
              filteredNotifications.push({
                ...eachNote,
                frequency: 0,
                icon: getIcon(eachNote.status),
                text:
                  eachNote.status === NotificationStatus.Success
                    ? "DataApp launched successfully"
                    : eachNote.status === NotificationStatus.Triggered
                      ? "DataApp launch is in progress"
                      : "DataApp launch failed",
                label: (
                  <Link to={`/dataapps-ui/${eachNote.parentName}`} className={classes.textLink}>
                    {eachNote.extraInfo.dataAppDisplayName}
                  </Link>
                ),
                tooltip: "",
                extra:
                  eachNote.status === NotificationStatus.Success ? undefined : (
                    <IconButton
                      className={classes.btn}
                      classes={{ label: classes.label }}
                      onClick={() =>
                        handleNavigate(`/dataapps-ui/${eachNote.parentName}?${openLogsModal}=true`)
                      }>
                      <LogsNewIconV2 width="14px" height="14px" color="#515151" />
                    </IconButton>
                  ),
                loader:
                  eachNote.status === NotificationStatus.Triggered ? (
                    <CircularProgress size={12} />
                  ) : undefined
              });
              break;
            }
          }
          break;
        }

        case NotificationParentTypes.Eda: {
          switch (eachNote.type) {
            case NotificationTypes.EDA_CHARTS: {
              filteredNotifications.push({
                ...eachNote,
                text:
                  eachNote.status === NotificationStatus.Success
                    ? "Data Analysis Charts generation is successful"
                    : eachNote.status === NotificationStatus.Triggered
                      ? "Data Analysis Charts generation is in progress"
                      : "Data Analysis Charts generation failed due to unexpected error. Please try again",
                ...getEdaCommonInfo(eachNote)
              });

              break;
            }
            case NotificationTypes.EDA_ADDITIONAL_CHARTS: {
              filteredNotifications.push({
                ...eachNote,
                text:
                  eachNote.status === NotificationStatus.Success
                    ? "Data Analysis Additional Charts generation is successful"
                    : eachNote.status === NotificationStatus.Triggered
                      ? "Data Analysis Additional Charts generation is in progress"
                      : "Data Analysis Additional Charts generation failed due to unexpected error. Please try again",
                ...getEdaCommonInfo(eachNote)
              });

              break;
            }
            case NotificationTypes.EDA_INFO: {
              filteredNotifications.push({
                ...eachNote,
                text:
                  eachNote.status === NotificationStatus.Success
                    ? "Data Analysis Report generation is successful"
                    : eachNote.status === NotificationStatus.Triggered
                      ? "Data Analysis Report generation is in progress"
                      : "Data Analysis Report generation failed due to unexpected error. Please try again",
                ...getEdaCommonInfo(eachNote)
              });

              break;
            }
          }
          break;
        }
      }
    });

    const filteredVal = slice(
      getNotificationsByRoles(filteredNotifications),
      0,
      NotificationsConfig.MaximumNotifications
    );
    return {
      filtered: filteredVal,
      unread: size(filter(filteredVal, (eachNotification) => !eachNotification?.read))
    };
  }, [data, roleName, isRoleYieldsDataAppView, isConsumer, isDataAppPowerUser]);

  const handleClearAll = () => {
    clearAll.mutate({});
  };

  const handleReadAll = () => {
    readAll.mutate({});
  };
  return (
    <>
      <IconButton color="primary" onClick={handleClick}>
        <Badge
          component="span"
          badgeContent={unread}
          overlap="rectangular"
          color="error"
          style={{ cursor: "pointer" }}>
          <Typography color="primary">
            <NotificationsNoneIcon fontSize="small" style={{ opacity: 1 }} />
          </Typography>
        </Badge>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            borderRadius: 0,
            maxHeight: "unset"
          }
        }}
        onClose={handleClose}>
        <Box className={classes.notificationsWrapperContainer}>
          {filtered?.length === 0 ? (
            <Box className={classes.noAlert}>
              <Typography variant="caption" className={classes.showingCountInfoText}>
                No notifications found!
              </Typography>
            </Box>
          ) : (
            <Grid container direction="column" className={classes.notificationsSubContainer}>
              <Grid
                item
                container
                justifyContent="space-between"
                alignItems="center"
                style={{ display: "flex" }}>
                <Typography variant="caption" className={classes.showingCountInfoText}>
                  Showing {filtered?.length} Notifications
                </Typography>
                <div className={classes.buttons}>
                  <Tooltip title="This action will remove all notifications.">
                    <Button
                      size="small"
                      disabled={clearAll.isLoading}
                      startIcon={clearAll.isLoading ? <CircularProgress size={16} /> : undefined}
                      onClick={handleClearAll}>
                      Clear All
                    </Button>
                  </Tooltip>
                  <Tooltip title="This action will mark all notifications as read">
                    <Button
                      size="small"
                      disabled={readAll.isLoading}
                      startIcon={readAll.isLoading ? <CircularProgress size={16} /> : undefined}
                      onClick={handleReadAll}>
                      Read All
                    </Button>
                  </Tooltip>
                </div>
              </Grid>
              <Grid item>
                <NotificationsList notifications={filtered} />
              </Grid>
            </Grid>
          )}
        </Box>
      </Popover>
    </>
  );
};

export default React.memo(TopNavBarNotifications);
