/* eslint-disable no-unused-vars */
import React, { useMemo, useState } from "react";
import { useParams } from "react-router";
import { Box, Button, Grid, IconButton, Tooltip, makeStyles } from "@material-ui/core";
import { Add, ExpandLess, Remove } from "@material-ui/icons";
import _ from "lodash";

import AskAiLikeDislike, { ILikes } from "./AskAiLikeDislike";
import AskAIOutputDatasetContainer from "./AskAIOutputDatasetContainer";
import TextOutputContainer from "./TextOutputContainer";
import AskAIChartContainer from "./AskAIChartContainer";
import { AskAICodeContainer } from "./AskAICodeContainer";
import { AskAIResponseCollapsedView } from "./AskAIResponseCollapsedView";
import { DeleteQueryErrorModal } from "./DeleteQueryErrorModal";
import { DeleteQueryModal } from "./DeleteQueryModal";
import { OUTPUT_TYPE } from "../../../CodeRecipeContext/CodeRecipeContextProvider";
import { STOPPED_QUERY_ID } from "../GenerateCodeBar/GenerateCodeQueryInput";
import { ShowUpdateNameDialog } from "./ShowUpdateNameDialog";
import { Spinner } from "src/components";
import { ViewCodeIcon } from "icons/NewUX/ViewCodeIcon";
import { ViewOutputIcon } from "icons/NewUX/ViewOutputIcon";
import { checkEnvRelaunch } from "environmentsModule/utils/environmentRelaunch.helpers";
import { isEmpty } from "lodash";
import { useCodeRecipeContext } from "../../../CodeRecipeContext/useCodeRecipeContext";
import {
  useDeleteCodeHistoryMutation,
  useUpdateCodeHistoryMutation,
  useViewOutputMutation
} from "src/hooks/api";
import { handleResponse } from "services/Apis/Apis.service";
import { ModelIcon } from "icons/NewUX/ModelIcon";
import AIGuideTextRow from "src/pages/Projects/AIGuide/common/AIGuideTextRow";
import { TextIconV2 } from "icons/NewUX/TextIconV2";
import { EntityIconV3 } from "icons/NewUX/EntityIconV3";
import { ChartIconV3 } from "icons/NewUX/ChartIconV3";

type StyleProps = {
  isExpanded: boolean;
  isOutputText: boolean;
};
const useStyles = makeStyles<undefined, StyleProps>({
  responseContainer: {
    flexWrap: "nowrap"
  },
  responseWrap: {
    width: "calc(100% - 84px)",
    display: "flex",
    gap: "16px",
    flexDirection: "column",
    flexWrap: "nowrap"
  },
  outputColumn: {
    padding: "0px 32px 16px 16px",
    width: "calc(100% - 28px)",
    gap: ({ isExpanded, isOutputText }) => (isExpanded && !isOutputText ? "24px" : "12px"),
    flexWrap: "nowrap"
  },
  outputIconWrap: {
    width: "20px",
    minWidth: "20px",
    background: "#fff",
    borderRadius: "50%",
    height: "20px",
    flexWrap: "nowrap",
    marginRight: ({ isExpanded }) => (isExpanded ? "0px" : "12px")
  },
  queryBtn: {
    borderRadius: "4px",
    background: "#fff",
    padding: "4px"
  },
  recipeBtn: {
    backgroundColor: "#fff",
    height: "24px"
  },
  textIcon: {
    height: "32px",
    width: "32px",
    marginRight: "4px"
  },
  entityIcon: {
    height: "32px",
    width: "24px !important",
    marginRight: ({ isExpanded }) => (isExpanded ? "0px" : "12px")
  },
  chartIcon: {
    height: "32px",
    width: "24px !important",
    marginRight: ({ isExpanded }) => (isExpanded ? "0px" : "12px")
  }
});

type Props = {
  response: $TSFixMe;
  responseIndex: number;
};

const AskAIResponseContainer = ({ response, responseIndex }: Props) => {
  const { projectId } = useParams();
  const {
    pipelineTestResult,
    userInput,
    transformId,
    codeHistoryId,
    historyId,
    likes,
    answer,
    outputType,
    queryInputs,
    queryOutputs,
    outputStatus,
    isExpanded: isInitialExpanded,
    execErr,
    sampleRows,
    erroneousCode,
    errDetails,
    hideDelete
  } = response;
  const {
    recipeId,
    onAddCodeToRecipe,
    onRemoveCodeFromRecipe,
    isAskAiRecipeUpdateInProgress,
    isAutoGenerateInProgress,
    responses,
    pinnedNames,
    setResponses,
    inputNames,
    setInputNames,
    isQueryAssociatedWithRecipe,
    allRecipeDatasets,
    allRecipeCharts,
    allRecipeModels
  } = useCodeRecipeContext();
  const code = queryOutputs?.[0]?.code || "";
  const dataMap = useMemo(() => pipelineTestResult?.dataMap, [pipelineTestResult]);

  const isOutputDataset = outputType === OUTPUT_TYPE.DATASET;
  const isOutputChart = outputType === OUTPUT_TYPE.CHART;
  const isOutputModel = outputType === OUTPUT_TYPE.MODEL;
  const isOutputText = !isOutputDataset && !isOutputChart && !isOutputModel;
  const [isExpanded, setExpanded] = React.useState<boolean>(isInitialExpanded);
  const [shouldShowCode, setShouldShowCode] = useState<boolean>(false);
  const [showUpdateNameDialog, setShowUpdateNameDialog] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [showDeleteQueryErrorDialog, setShowDeleteQueryErrorDialog] = useState<boolean>(false);
  const [isDeleteInProgress, setIsDeleteInProgress] = React.useState<boolean>(false);

  const classes = useStyles({ isExpanded: isExpanded && !answer, isOutputText });
  const viewOutputMutation = useViewOutputMutation();
  const updateCodeHistoryMutation = useUpdateCodeHistoryMutation();
  const deleteCodeHistoryMutation = useDeleteCodeHistoryMutation();

  const onAddToRecipe = React.useCallback(
    ({
      updatedCode,
      updatedQueryOutputs,
      updatedHistoryId
    }: {
      updatedCode?: string;
      updatedQueryOutputs?: { name: string }[];
      updatedHistoryId?: string[];
    } = {}) => {
      const currHistoryId = updatedHistoryId || codeHistoryId || historyId;
      const onSuccess = (transformId: string) => {
        setResponses((responses: $TSFixMe[]) =>
          responses?.map((res) => {
            const responseHistoryId = res.codeHistoryId || res.historyId;
            return responseHistoryId !== currHistoryId
              ? res
              : {
                  ...res,
                  transformId,
                  queryOutputs: updatedQueryOutputs || res?.queryOutputs || []
                };
          })
        );
        setShowUpdateNameDialog(false);
      };
      onAddCodeToRecipe({
        code: updatedCode || code,
        codeHistoryId: currHistoryId,
        onSuccess
      });
    },
    [code, codeHistoryId, historyId, onAddCodeToRecipe, responseIndex, setResponses]
  );

  const onRemoveFromRecipe = React.useCallback(() => {
    const onSuccess = () => {
      setResponses((responses: $TSFixMe[]) =>
        responses?.map((res, index) =>
          index !== responseIndex ? res : { ...res, transformId: null }
        )
      );
    };
    onRemoveCodeFromRecipe({ transformId, onSuccess });
  }, [onRemoveCodeFromRecipe, responseIndex, setResponses, transformId]);

  const fetchOutput = React.useCallback(() => {
    projectId && checkEnvRelaunch(projectId);

    viewOutputMutation.mutate(
      {
        recipeId,
        projectId,
        code
      },
      {
        onSuccess: (data) => {
          const updatedOutputNames =
            outputType === OUTPUT_TYPE.CHART
              ? data?.entityViewData?.map((entity: any) => entity.name)
              : Object.keys(data?.dataMap);
          setResponses((responses: $TSFixMe[]) =>
            responses?.map((res, index) =>
              index !== responseIndex
                ? res
                : {
                    ...res,
                    pipelineTestResult: data,
                    outputNames: updatedOutputNames,
                    answer: data?.answer
                  }
            )
          );

          setExpanded(true);
          setShouldShowCode(false);
        }
      }
    );
  }, [code, projectId, recipeId, responseIndex, setResponses, viewOutputMutation]);

  const onUpdateOutputName = (updatedNameMapping: { [key: string]: string }) => {
    const currentHistoryId = codeHistoryId || historyId;
    const outputNames = Object.values(updatedNameMapping);

    const allOutputNames = (
      isOutputDataset ? allRecipeDatasets : isOutputChart ? allRecipeCharts : allRecipeModels
    )?.map((entity: any) => entity.name || entity.displayName);
    const duplicateNames = _.intersection(outputNames, allOutputNames);
    if (duplicateNames.length !== 0) {
      handleResponse({
        errorMessage: `The output name(s) - ${duplicateNames.join(", ")} are already in use. Please ensure each output name is unique before saving.`
      });
      return;
    }

    if ((isOutputChart || isOutputModel) && _.uniq(outputNames)?.length !== outputNames?.length) {
      handleResponse({
        errorMessage: `Duplicate output name(s). Please ensure each output name is unique before saving.`
      });
      return;
    }

    recipeId &&
      updateCodeHistoryMutation.mutate(
        {
          recipeId,
          request: {
            historyId: currentHistoryId,
            outputNameChanges: updatedNameMapping
          }
        },
        {
          onSuccess: (response: any) => {
            setResponses((arrResponses: any) =>
              arrResponses.map((arrResponse: any) =>
                arrResponse.historyId === currentHistoryId
                  ? {
                      ...arrResponse,
                      pipelineTestResult: {
                        ...arrResponse.pipelineTestResult,
                        entityViewData: arrResponse?.pipelineTestResult?.entityViewData?.map(
                          (chart: any) => ({
                            ...chart,
                            name: updatedNameMapping[chart.name]
                          })
                        )
                      },
                      queryOutputs: arrResponse.queryOutputs.map((qOutput: any) => ({
                        ...qOutput,
                        name: updatedNameMapping[qOutput.name]
                      }))
                    }
                  : arrResponse
              )
            );
            if (isEmpty(pinnedNames)) {
              const newInputNames = inputNames.map(
                (inputName) => updatedNameMapping[inputName] || inputName
              );
              setInputNames(newInputNames);
            }
            onAddToRecipe({
              updatedCode: response?.queryOutputs?.[response?.queryOutputs?.length - 1].code,
              updatedQueryOutputs: response.queryOutputs,
              updatedHistoryId: response.historyId
            });
          }
        }
      );
  };
  const toggleExpanded = React.useCallback(() => {
    // Fetch Output if not expanded and pipelineTestResult is empty.
    if (!isExpanded && isEmpty(pipelineTestResult) && !execErr) {
      fetchOutput();
    } else {
      setExpanded((expanded) => !expanded);
    }
  }, [isExpanded, isOutputChart, isOutputDataset, fetchOutput]);

  React.useEffect(() => {
    // collapse all responses when auto generate is running
    isAutoGenerateInProgress && setExpanded(false);
  }, [isAutoGenerateInProgress]);

  const isRecipeUpdating = React.useMemo(
    () =>
      (updateCodeHistoryMutation.isLoading &&
        updateCodeHistoryMutation.variables?.request?.historyId === (codeHistoryId || historyId)) ||
      isQueryAssociatedWithRecipe(transformId || codeHistoryId || historyId),
    [updateCodeHistoryMutation.isLoading, isAskAiRecipeUpdateInProgress]
  );

  const buttons = React.useMemo(() => {
    if (!!erroneousCode) {
      return [
        {
          component: (
            <IconButton size="small">
              <ExpandLess />
            </IconButton>
          )
        }
      ];
    }

    return [
      {
        component: (
          <Tooltip title={shouldShowCode ? "View Output" : "View Code"}>
            <div>
              <IconButton
                className={classes.queryBtn}
                disabled={viewOutputMutation.isLoading}
                test-id={`ask-ai-view-${shouldShowCode ? "output" : "code"}-btn`}
                onClick={(event: any) => {
                  event.stopPropagation();
                  if (isOutputDataset || isOutputChart || isOutputModel) {
                    setShouldShowCode((showCode) => !showCode);
                    return;
                  }
                  if (!shouldShowCode) {
                    setShouldShowCode(true);
                  }
                  fetchOutput();
                }}>
                {viewOutputMutation.isLoading ? (
                  <Spinner size={18} noPadding />
                ) : shouldShowCode ? (
                  <ViewOutputIcon />
                ) : (
                  <ViewCodeIcon />
                )}
              </IconButton>
            </div>
          </Tooltip>
        )
      },
      {
        component: (
          <Button
            color="primary"
            size="small"
            className={classes.recipeBtn}
            startIcon={
              !isRecipeUpdating &&
              (transformId ? <Remove color="primary" /> : <Add color="primary" />)
            }
            disabled={updateCodeHistoryMutation.isLoading || isAskAiRecipeUpdateInProgress}
            onClick={(event: $TSFixMe): void => {
              event.stopPropagation();
              transformId ? onRemoveFromRecipe() : setShowUpdateNameDialog(true);
            }}
            test-id={`ask-ai-${transformId ? "remove-from" : "add-to"}-recipe-btn`}>
            {isRecipeUpdating ? <Spinner size={18} noPadding /> : "Recipe"}
          </Button>
        )
      },
      {
        component: (
          <IconButton size="small">
            {viewOutputMutation.isLoading ? <Spinner size={18} noPadding /> : <ExpandLess />}
          </IconButton>
        )
      }
    ];
  }, [
    isAskAiRecipeUpdateInProgress,
    isOutputChart,
    isOutputDataset,
    onAddToRecipe,
    onRemoveFromRecipe,
    shouldShowCode,
    transformId,
    fetchOutput,
    viewOutputMutation.isLoading,
    updateCodeHistoryMutation.isLoading
  ]);

  const onDeleteQuery = () => {
    setIsDeleteInProgress(true);
    if (response.outputType === OUTPUT_TYPE.CHART && !response.transformId) {
      recipeId &&
        deleteCodeHistoryMutation.mutate(
          { recipeId, historyIds: [historyId] },
          {
            onSuccess: () => {
              setResponses((currResponses: any) => {
                const filteredResponses = currResponses.filter(
                  (currResponse: any) =>
                    currResponse.historyId !== historyId ||
                    currResponse.historyId === STOPPED_QUERY_ID
                );
                return filteredResponses.map((currResponse: any) =>
                  currResponse.promptSuggestions
                    ? { ...currResponse, isExpanded: false }
                    : currResponse
                );
              });
              setIsDeleteInProgress(false);
              setShowDeleteModal(false);
            }
          }
        );

      return;
    }
    const responseOutputName = response.queryOutputs?.[0]?.name;
    const responseMapping = responses.reduce(
      (acc: any, currResponse: any) => {
        const queryInputNames = currResponse.queryInputs.map((queryInput: any) => queryInput.name);
        const currOutputName = currResponse.queryOutputs?.[0]?.name;
        if (acc.outputNames.find((outputName: string) => queryInputNames.includes(outputName))) {
          return {
            historyIds: [...acc.historyIds, currResponse.historyId],
            outputNames:
              currResponse.outputType === OUTPUT_TYPE.DATASET
                ? [...acc.outputNames, currOutputName]
                : acc.outputNames
          };
        } else {
          return acc;
        }
      },
      { historyIds: [historyId], outputNames: [responseOutputName] }
    );
    if (
      responses.find(
        (response: any) =>
          responseMapping.historyIds.includes(response.historyId) && response.transformId
      ) ||
      (response.outputType === "CHART" && response.transformId)
    ) {
      setIsDeleteInProgress(false);
      setShowDeleteModal(false);
      setShowDeleteQueryErrorDialog(true);
      return;
    }
    recipeId &&
      deleteCodeHistoryMutation.mutate(
        { recipeId, historyIds: responseMapping.historyIds },
        {
          onSuccess: () => {
            setResponses((currResponses: any) => {
              const filteredResponses = currResponses.filter(
                (currResponse: any) =>
                  !responseMapping.historyIds?.includes(currResponse.historyId) ||
                  currResponse.historyId === STOPPED_QUERY_ID
              );
              return filteredResponses.map((currResponse: any) =>
                currResponse.promptSuggestions
                  ? { ...currResponse, isExpanded: false }
                  : currResponse
              );
            });
            setIsDeleteInProgress(false);
            setShowDeleteModal(false);
          }
        }
      );
  };

  const handleSuccess = (likes: Pick<ILikes, "liked" | "feedback">) => {
    const currentHistoryId = codeHistoryId || historyId;
    setResponses((responses: any[]) =>
      responses?.map((res) => {
        if (res.historyId !== currentHistoryId) {
          return res;
        }
        return {
          ...res,
          likes
        };
      })
    );
  };

  return (
    <Grid container direction="column" className={classes.responseContainer}>
      <AIGuideTextRow
        userInput={userInput}
        queryInputs={queryInputs}
        canDelete={!hideDelete}
        maxHeight="132px"
        setShowDeleteModal={setShowDeleteModal}
        color="#fff"
        width="750px"
        hoverBgColor="#fff"
      />
      <Grid container direction="row" className={classes.outputColumn} data-testid="aiResponseIcon">
        {isOutputDataset ? (
          <Grid
            test-id="ask-ai-modal-entity-icon"
            container
            item
            direction="column"
            alignItems="center"
            justifyContent="center"
            wrap="nowrap"
            className={classes.entityIcon}>
            <EntityIconV3 />
          </Grid>
        ) : isOutputChart ? (
          <Grid
            container
            item
            direction="column"
            alignItems="flex-start"
            justifyContent="center"
            wrap="nowrap"
            className={classes.chartIcon}>
            <ChartIconV3 />
          </Grid>
        ) : isOutputModel ? (
          <Grid
            test-id="ask-ai-modal-rc-icon-container"
            container
            alignItems="center"
            justifyContent="center"
            className={classes.outputIconWrap}>
            <ModelIcon width={14} height={14} viewBox="-3 -3 24 24" color="#7C7C7C" />
          </Grid>
        ) : (
          <Box className={classes.textIcon}>
            <TextIconV2 />
          </Box>
        )}
        <Grid className={classes.responseWrap}>
          {!isExpanded ? (
            <AskAIResponseCollapsedView
              toggleExpanded={toggleExpanded}
              queryOutputs={queryOutputs}
              outputType={outputType}
              isOutputModel={isOutputModel}
              isFetchingResponse={viewOutputMutation.isLoading}
              isAddedToRecipe={!!transformId}
              isOutputError={outputStatus === "FAILED"}
              errorMsg={execErr}
              hasErrorCode={!!erroneousCode}
              errDetails={errDetails}
            />
          ) : shouldShowCode || !!erroneousCode ? (
            <AskAICodeContainer
              title={
                erroneousCode
                  ? errDetails?.errorExplanation || `Could not execute code. ${execErr}`
                  : "Python"
              }
              errorDescription={
                errDetails?.errorExplanation ? `Could not execute code. ${execErr}` : null
              }
              editorValue={erroneousCode ? erroneousCode : code}
              toggleExpanded={toggleExpanded}
              buttons={buttons}
              errDetails={errDetails}
              background={!!transformId ? "#E9FFDF" : !!erroneousCode ? "#FFDBDD" : "#E7E7FF"}
            />
          ) : (
            <Grid container direction="column" wrap="nowrap" style={{ width: "calc(100% - 48px)" }}>
              {isOutputDataset ? (
                <AskAIOutputDatasetContainer
                  buttons={buttons}
                  toggleExpanded={toggleExpanded}
                  dataMap={dataMap}
                  queryOutputs={queryOutputs}
                  isAddedToRecipe={!!transformId}
                  sampleRows={sampleRows}
                />
              ) : isOutputChart || isOutputModel ? (
                <AskAIChartContainer
                  toggleExpanded={toggleExpanded}
                  pipelineTestResult={pipelineTestResult}
                  buttons={buttons}
                  isOutputModel={isOutputModel}
                  queryOutputs={queryOutputs}
                  isAddedToRecipe={!!transformId}
                  sampleRows={sampleRows}
                />
              ) : (
                <TextOutputContainer
                  text={answer}
                  toggleExpanded={toggleExpanded}
                  hasError={!_.isEmpty(errDetails)}
                />
              )}
              <AskAiLikeDislike
                historyId={codeHistoryId || historyId}
                likes={likes}
                onSuccess={handleSuccess}
              />
            </Grid>
          )}
        </Grid>
        {showUpdateNameDialog && (
          <ShowUpdateNameDialog
            queryOutputs={
              isOutputModel
                ? _.concat(
                    _.map(pipelineTestResult.entityModelData, (modelData) =>
                      _.assign({}, modelData, { isModel: true })
                    ),
                    _.filter(queryOutputs, (output) => !output.modelVersion)
                  )
                : queryOutputs
            }
            title={isOutputModel ? "Update Model Name" : "Update Name"}
            onClose={() => setShowUpdateNameDialog(false)}
            label={isOutputDataset ? "Dataset Name" : "Chart Name"}
            onSubmit={onUpdateOutputName}
            isSubmitLoading={isRecipeUpdating}
            isSubmitDisabled={updateCodeHistoryMutation.isLoading || isAskAiRecipeUpdateInProgress}
          />
        )}
        {showDeleteQueryErrorDialog && (
          <DeleteQueryErrorModal onClose={() => setShowDeleteQueryErrorDialog(false)} />
        )}
        {showDeleteModal && (
          <DeleteQueryModal
            userInput={userInput}
            handleSubmit={() => onDeleteQuery?.()}
            onClose={() => setShowDeleteModal?.(false)}
            loading={isDeleteInProgress}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default React.memo(AskAIResponseContainer);
