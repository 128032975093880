import React, { useMemo } from "react";

import { useIsMutating } from "@tanstack/react-query";

import { Grid, Button, makeStyles, CircularProgress, Tooltip } from "@material-ui/core";

import { UseUpdateDatasetQueryKeys } from "src/hooks/api/entities/useUpdateEntities";
import useHelpers from "../../../hooks/useHelpers";

import { SearchField } from "src/components";
import {
  DatasetHelperText,
  DatasetKeys,
  OntologyDatasetStatuses
} from "../../../utils/Dataset.constants";

const useStyles = makeStyles((theme) => ({
  root: {
    columnGap: theme.spacing(2),
    "& [class^='MuiButton-label']": {
      columnGap: 5
    }
  },

  // @REFACTOR
  // The below styles are added only to support New UX. It shall be removed soon.
  searchField: {
    "& [class^='MuiInputBase-root']": {
      background: "none",
      border: "1px solid #ccc",
      borderRadius: 4
    },
    "& input": {
      paddingTop: 10,
      paddingBottom: 10,
      fontSize: "small"
    }
  }
}));

const OntologyHeader = (props: $TSFixMe) => {
  const {
    datasetDefaultDatasetStore,
    ontologyDatasetIndex,
    dataset,
    searchTerm,
    setSearchTerm,
    updateDatasetLite
  } = props || {};

  const classes: $TSFixMe = useStyles();

  const { isOntologyProcessing } = useHelpers({ ontologyDatasetIndex });

  const onSearchChange = (event: $TSFixMe) => {
    setSearchTerm(event?.target?.value);
  };

  const pendingUpdateDatasetMutations = useIsMutating({
    mutationKey: [UseUpdateDatasetQueryKeys.UpdateDataset, dataset?.id]
  });

  const isUpdatingDataset = useMemo(
    () => pendingUpdateDatasetMutations > 0,
    [pendingUpdateDatasetMutations]
  );

  const disabledApplyActionMessage = useMemo(() => {
    if (!!isUpdatingDataset) {
      return "Please wait. The update action is in progress.";
    }

    return "";
  }, [isUpdatingDataset]);

  return (
    <>
      <Grid container className={classes.root}>
        {!datasetDefaultDatasetStore?.id &&
          dataset?.[DatasetKeys.OntologyConfig]?.[DatasetKeys.IsSchemaOrOntologyDirty] && (
            <Tooltip title={disabledApplyActionMessage}>
              <span>
                <Button
                  id="datasetDataTypeAction"
                  variant="contained"
                  color="primary"
                  disabled={
                    !!disabledApplyActionMessage ||
                    dataset?.[DatasetKeys.OntologyConfig]?.[DatasetKeys.Status] ===
                      OntologyDatasetStatuses.Updating
                  }
                  onClick={() => updateDatasetLite()}>
                  {dataset?.[DatasetKeys.OntologyConfig]?.[DatasetKeys.Status] ===
                  OntologyDatasetStatuses.Updating ? (
                    <CircularProgress size={23} color="inherit" />
                  ) : (
                    DatasetHelperText.Apply
                  )}
                </Button>
              </span>
            </Tooltip>
          )}
        <SearchField
          id="ontologyContainerSearch"
          placeholder="Search"
          className={classes.searchField}
          value={searchTerm}
          onChange={onSearchChange}
          disabled={isOntologyProcessing}
        />
      </Grid>
    </>
  );
};

export default OntologyHeader;
