import React, { useCallback } from "react";

// Packages
import {
  Controller,
  ControllerRenderProps,
  useFormContext,
  FieldValues,
  useFieldArray,
  FieldErrors
} from "react-hook-form";
import { difference, every, filter, includes, isEmpty, isNil, map, some, toLower } from "lodash";

// MUI
import {
  Box,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  Divider,
  Paper,
  Typography,
  Grid,
  InputBase,
  useTheme,
  IconButton
} from "@material-ui/core";

// Icons
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";

// Open API
import { EntityDto, SegmentDto } from "@rapidcanvas/rc-api-core";

// Components
import { OverflowTooltip } from "src/components";

// Styles
import useStyles from "./ScenarioSegments.styles";

// Contexts
import { useScenarioContext } from "../../context/useScenarioContext";

// Types
import { Scenario } from "../../Scenario.type";

// Constants
import { ScenarioFormFields, ScenarioHelperText } from "../../utils/Scenario.constants";
import CommonLoader from "src/components/CommonLoader";

const ScenarioSegments = () => {
  const theme = useTheme();
  const classes = useStyles();

  const { isPendingSegments, isReadOnly, isAttemptedSubmit } = useScenarioContext();

  const {
    control,
    formState: { errors }
  } = useFormContext();

  const { fields } = useFieldArray({
    control,
    name: ScenarioFormFields.Datasets
  });

  const filteredSegments = useCallback(
    (dataset: EntityDto, field: ControllerRenderProps<FieldValues, `datasets.${number}`>) =>
      filter(dataset?.segments, (segment: SegmentDto) =>
        includes(toLower(segment?.name), toLower(field?.value?.searchQuery))
      ),
    []
  );

  const onSelectAllSegments = ({ dataset, field, checked }: any) => {
    const filteredSegmentIds = map(
      filteredSegments(dataset as EntityDto, field),
      (segment: SegmentDto) => segment?.id
    );

    const filteredSelectedSegmentIds = filter(field?.value?.selectedSegments, (id) =>
      some(field?.value?.segments, { id })
    );

    const newSegments = !!checked
      ? [...filteredSelectedSegmentIds, ...filteredSegmentIds]
      : difference(filteredSelectedSegmentIds, filteredSegmentIds);

    return newSegments;
  };

  return (
    <Paper className={classes.root}>
      <Box p={2}>
        <Typography variant="subtitle2" color="textSecondary" data-testid="segmentAddSegments">
          {ScenarioHelperText.AddSegments}
        </Typography>
      </Box>
      <Divider />
      {isPendingSegments ? (
        <CommonLoader />
      ) : isEmpty(fields) ? (
        <Box p={2} fontStyle="italic" style={{ opacity: 0.75 }}>
          <InfoOutlinedIcon />{" "}
          <Typography
            variant="body2"
            color="textSecondary"
            component="span"
            data-testid="segmentNoSegmentsInfo">
            {ScenarioHelperText.NoSegmentsInfo}
          </Typography>
        </Box>
      ) : (
        <Grid container wrap="nowrap" className={classes.datasetWrapper}>
          {map(fields, (dataset, datasetIndex) => (
            <Controller
              key={`datasetController-${datasetIndex}`}
              name={`datasets.${datasetIndex}`}
              control={control}
              rules={{
                validate: (value) => value?.fullSegment || !isEmpty(value?.selectedSegments)
              }}
              render={({ field }) => (
                <Grid
                  key={`datasetContainer-${datasetIndex}`}
                  container
                  direction="column"
                  className={classes.datasetSubWrapper}>
                  <Box mx={2} my={1} width="90%">
                    <InputBase
                      fullWidth
                      placeholder="Search"
                      value={field?.value?.searchQuery}
                      disabled={!!isReadOnly}
                      startAdornment={
                        <SearchRoundedIcon
                          fontSize="small"
                          style={{ opacity: 0.5 }}
                          data-testid={`segmentDataset${datasetIndex}SearchSegmentSearchIcon`}
                        />
                      }
                      {...(!!field?.value?.searchQuery
                        ? {
                            endAdornment: (
                              <IconButton
                                size="small"
                                data-testid={`segmentDataset${datasetIndex}SearchSegmentCloseAction`}>
                                <CloseRoundedIcon
                                  fontSize="small"
                                  style={{ opacity: 0.5 }}
                                  onClick={() => {
                                    field?.onChange({ ...field?.value, searchQuery: "" });
                                  }}
                                />
                              </IconButton>
                            )
                          }
                        : {})}
                      inputProps={{ style: { marginLeft: 8 } }}
                      onChange={(event) =>
                        field?.onChange({ ...field?.value, searchQuery: event?.target?.value })
                      }
                      style={{ fontSize: "0.875rem" }}
                      data-testid={`segmentDataset${datasetIndex}SearchSegment`}
                    />
                  </Box>

                  <Divider style={{ width: "100%" }} />

                  <Grid container direction="column" className={classes.datasetContainer}>
                    <Typography
                      component="div"
                      variant="body2"
                      noWrap
                      style={{ width: "90%" }}
                      data-testid={`segmentDataset${datasetIndex}DatasetName`}>
                      <OverflowTooltip
                        value={(dataset as EntityDto)?.displayName || (dataset as EntityDto)?.name}
                      />
                    </Typography>
                    <RadioGroup
                      className={classes.segmentTypeContainer}
                      value={field?.value?.fullSegment ? "full" : "custom"}
                      onChange={(event) => {
                        const isFullSegment = event?.target?.value === "full";

                        let newSegments = [];

                        if (!isFullSegment) {
                          newSegments = onSelectAllSegments({
                            dataset,
                            field,
                            checked: true
                          });
                        }

                        field?.onChange({
                          ...field?.value,
                          fullSegment: isFullSegment,
                          selectedSegments: newSegments
                        });
                      }}>
                      <FormControlLabel
                        label="Full Dataset"
                        value="full"
                        control={
                          <Radio
                            size="small"
                            color="primary"
                            classes={{ root: classes.segmentTypeRadioRoot }}
                            data-testid={`segmentDataset${datasetIndex}FullDatasetRadio`}
                          />
                        }
                        disabled={!!isReadOnly}
                        classes={{
                          root: classes.segmentTypeFormControlLabelRoot,
                          label: classes.segmentTypeFormControlLabelLabel
                        }}
                        data-testid={`segmentDataset${datasetIndex}FullDataset`}
                      />
                      <FormControlLabel
                        label="Custom Segments"
                        value="custom"
                        control={
                          <Radio
                            size="small"
                            color="primary"
                            classes={{ root: classes.segmentTypeRadioRoot }}
                            data-testid={`segmentDataset${datasetIndex}CustomDatasetRadio`}
                          />
                        }
                        disabled={!!isReadOnly}
                        classes={{
                          root: classes.segmentTypeFormControlLabelRoot,
                          label: classes.segmentTypeFormControlLabelLabel
                        }}
                        data-testid={`segmentDataset${datasetIndex}CustomDataset`}
                      />
                    </RadioGroup>

                    {!isEmpty(filteredSegments(dataset as EntityDto, field)) && (
                      <Box ml={3} mb={-3.5}>
                        <FormControlLabel
                          key={`dataset-${datasetIndex}-segment-all`}
                          label={<Typography variant="subtitle2">Select all</Typography>}
                          disabled={!!isReadOnly || !!field?.value?.fullSegment}
                          control={
                            <Checkbox
                              size="small"
                              color="primary"
                              checked={every(
                                filteredSegments(dataset as EntityDto, field),
                                (segment) => includes(field?.value?.selectedSegments, segment?.id)
                              )}
                              onChange={(event) => {
                                const newSegments = onSelectAllSegments({
                                  dataset,
                                  field,
                                  checked: event?.target?.checked
                                });

                                field?.onChange({
                                  ...field?.value,
                                  fullSegment: false,
                                  selectedSegments: newSegments
                                });
                              }}
                              classes={{ root: classes.segmentsCheckboxRoot }}
                              data-testid={`segmentDataset${datasetIndex}SelectAllSegmentsCheckbox`}
                            />
                          }
                          classes={{
                            root: classes.segmentsFormControlLabelRoot,
                            label: classes.segmentsFormControlLabelLabel
                          }}
                          data-testid={`segmentDataset${datasetIndex}SelectAllSegments`}
                        />
                      </Box>
                    )}
                    <Grid container className={classes.segmentsContainer}>
                      {isEmpty(filteredSegments(dataset as EntityDto, field)) ? (
                        <Box
                          ml={1}
                          fontSize="small"
                          fontStyle="italic"
                          color={theme.palette.text.secondary}
                          data-testid={`segmentDataset${datasetIndex}NoSegmentsFoundInfo`}>
                          {ScenarioHelperText.NoSearchedSegmentsFoundInfo}
                        </Box>
                      ) : (
                        map(
                          filteredSegments(dataset as EntityDto, field),
                          (segment, segmentIndex) => (
                            <FormControlLabel
                              key={`dataset-${datasetIndex}-segment-${segmentIndex}`}
                              label={
                                <OverflowTooltip
                                  style={{ width: "100%", whiteSpace: "nowrap" }}
                                  value={segment?.name}
                                />
                              }
                              disabled={!!isReadOnly || !!field?.value?.fullSegment}
                              className={
                                !!isAttemptedSubmit &&
                                !isNil((errors as FieldErrors<Scenario>)?.datasets?.[datasetIndex])
                                  ? "error"
                                  : ""
                              }
                              control={
                                <Checkbox
                                  size="small"
                                  color="primary"
                                  checked={includes(field?.value?.selectedSegments, segment?.id)}
                                  onChange={(event) => {
                                    const filteredSelectedSegmentIds = filter(
                                      field?.value?.selectedSegments,
                                      (id) => some(field?.value?.segments, { id })
                                    );

                                    const newSegments = !!event?.target?.checked
                                      ? [...filteredSelectedSegmentIds, segment?.id]
                                      : filter(
                                          filteredSelectedSegmentIds,
                                          (segmentId) => segmentId !== segment?.id
                                        );

                                    field?.onChange({
                                      ...field?.value,
                                      fullSegment: false,
                                      selectedSegments: newSegments
                                    });
                                  }}
                                  classes={{ root: classes.segmentsCheckboxRoot }}
                                  data-testid={`segmentDataset${datasetIndex}Segment${segmentIndex}Checkbox`}
                                />
                              }
                              classes={{
                                root: classes.segmentsFormControlLabelRoot,
                                label: classes.segmentsFormControlLabelLabel
                              }}
                              data-testid={`segmentDataset${datasetIndex}Segment${segmentIndex}`}
                            />
                          )
                        )
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            />
          ))}
        </Grid>
      )}
    </Paper>
  );
};

export default ScenarioSegments;
