import React from "react";
import _ from "lodash";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Tooltip
} from "@material-ui/core";

import { dataAppConfigFields } from "./CreateDataAppForm";
import { DataappAskAIConfigDataSharingStrategyEnum } from "@rapidcanvas/rc-api-core";

interface IProps {
  readOnly?: boolean;
  value: DataappAskAIConfigDataSharingStrategyEnum;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
const SelectDataAppSecurity: React.FC<IProps> = (props) => {
  return (
    <FormControl component="fieldset" disabled={props.readOnly}>
      <FormLabel component="legend">Security</FormLabel>
      <RadioGroup
        row={props.readOnly}
        aria-label={dataAppConfigFields.dataSharingStrategy}
        name={dataAppConfigFields.dataSharingStrategy}
        value={props.value}
        data-testid="dataAppConfigSecurity"
        onChange={props.onChange}>
        <FormControlLabel
          value={DataappAskAIConfigDataSharingStrategyEnum.SampleData}
          control={<Radio />}
          data-testid="dataAppConfigSampleData"
          label={
            <>
              <span>Sample Data</span>{" "}
              <Tooltip title="A sample of 5 rows of data is shared with the LLM for context">
                <InfoOutlinedIcon
                  style={{ fontSize: "1rem", marginBottom: "2px", color: "#8a8989" }}
                />
              </Tooltip>
            </>
          }
        />
        <FormControlLabel
          value={DataappAskAIConfigDataSharingStrategyEnum.OnlyMetadata}
          control={<Radio />}
          data-testid="dataAppConfigOnlyMetadata"
          label={
            <>
              <span>Only Metadata</span>{" "}
              <Tooltip title="Only column names of the data are shared with the LLM for context">
                <InfoOutlinedIcon
                  style={{ fontSize: "1rem", marginBottom: "2px", color: "#8a8989" }}
                />
              </Tooltip>
            </>
          }
        />
      </RadioGroup>
    </FormControl>
  );
};

export default SelectDataAppSecurity;
