import { Grid, Link, List, ListItem, Typography } from "@material-ui/core";
import React from "react";
import GetStartedBox, { IBoxProps } from "./GetStartedBox";
import { isEmpty, map } from "lodash";

interface IProps {
  boxes: IBoxProps[];
  introduction?: {
    question: string;
    answer: string;
  };
  functionality?: {
    question: string;
    answers: string[];
    docsLink: string;
  };
  outerBoxWidth?: string;
}

const GettingStarted: React.FC<IProps> = ({
  boxes,
  introduction,
  functionality,
  outerBoxWidth = "430px"
}) => {
  return (
    <Grid container justifyContent="center">
      <Grid
        container
        direction="column"
        style={{ gap: "30px", width: outerBoxWidth, paddingTop: "50px" }}>
        <Grid container direction="column" style={{ gap: "10px" }}>
          <Typography style={{ fontSize: "24px" }}>Get started</Typography>
          <Grid
            container
            style={{
              gap: "10px",
              flexWrap: "nowrap"
            }}>
            {map(boxes, (item, index) => (
              <GetStartedBox key={index} {...item} />
            ))}
          </Grid>
        </Grid>
        {!isEmpty(introduction) && (
          <Grid container direction="column" style={{ gap: "10px" }}>
            <Typography style={{ fontSize: "24px" }}>{introduction.question}</Typography>
            <Typography style={{ fontSize: "15px", paddingRight: "10px" }}>
              {introduction.answer}
            </Typography>
          </Grid>
        )}
        {!isEmpty(functionality) && (
          <Grid container direction="column">
            <Typography style={{ fontSize: "24px" }}>{functionality.question}</Typography>
            <List dense>
              {functionality.answers.map((step, index) => (
                <ListItem key={index}>
                  <Typography style={{ fontSize: "15px" }}>{`${index + 1}. ${step}`}</Typography>
                </ListItem>
              ))}
            </List>
            <Link
              style={{ fontSize: "15px", cursor: "pointer" }}
              onClick={() => window.open(functionality.docsLink, "_blank")}>
              Learn more
            </Link>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default GettingStarted;
