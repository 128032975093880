import { Card, CardContent, CardHeader, Grid, IconButton } from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import { FeatureDto } from "@rapidcanvas/rc-api-core";
import { CellContext } from "@tanstack/react-table";
import Table from "components/custom/TableV8/Table";
import _ from "lodash";
import { map } from "lodash";
import React, { useMemo, useState } from "react";

interface IProps {
  features: FeatureDto[];
  cardMargin: string;
}

const DatasetSummary: React.FC<IProps> = ({ features, cardMargin }) => {
  const [expanded, setExpanded] = useState(true);
  const columns = [
    { id: "Column", header: "Column", accessorKey: "name" },
    { id: "Data type", header: "Data type", accessorKey: "rcDataType" },
    {
      id: "Null Percentage",
      header: "Null Percentage",
      accessorKey: "nullPercentage",

      cell: ({ getValue }: CellContext<any, string>) => {
        const value = getValue();
        return value ?? 0;
      }
    },
    { id: "Unique values", header: "Unique values", accessorKey: "uniqueValues" },
    { id: "First row", header: "First row", accessorKey: "firstRow" },
    { id: "Last row", header: "Last row", accessorKey: "lastRow" }
  ];

  const data = useMemo(
    () =>
      _.map(
        map(features, ({ name, fieldSchema }) => {
          return { ...fieldSchema, name };
        }),
        (item) => {
          return _.mapValues(item, (value) => {
            return _.isString(value) && value.toLowerCase() === "nan" ? "" : value;
          });
        }
      ),
    [features]
  );

  return (
    <Card className={cardMargin}>
      <CardHeader
        title={
          <Grid container justifyContent="space-between" alignItems="center">
            <span style={{ fontSize: "14px", fontWeight: 500 }}>Summary</span>
            <Grid>
              <IconButton size="small">
                {expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </IconButton>
            </Grid>
          </Grid>
        }
        onClick={() => setExpanded((prev) => !prev)}
        style={{ borderBottom: "1px solid #d3d3d3", padding: "5px 16px", cursor: "pointer" }}
      />
      {expanded && (
        <CardContent>
          <Table
            columns={columns}
            data={data}
            stylesProps={{ maxHeight: "400px" }}
            sortingProps={{ unsorted: true }}
            infoProps={{ hideCount: true }}
          />
        </CardContent>
      )}
    </Card>
  );
};

export default DatasetSummary;
