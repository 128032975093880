import { startCase } from "lodash";
import React from "react";
import { List, ListItem, ListItemText } from "@material-ui/core";
import { PublicScreenLabels } from "constants/index";

export const EmailIdError = ({ type }: { type?: string }) => {
  if (!type) {
    return <React.Fragment />;
  }
  return (
    <List dense disablePadding>
      {type === "required" && (
        <ListItem disableGutters>
          <ListItemText
            primary={`${startCase(PublicScreenLabels.Email)} is required!`}
            disableTypography
          />
        </ListItem>
      )}
      {type === "pattern" && (
        <ListItem disableGutters>
          <ListItemText
            primary={`${startCase(PublicScreenLabels.Email)} format invalid!`}
            disableTypography
          />
        </ListItem>
      )}
    </List>
  );
};
