/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import clsx from "clsx";
import {
  MenuItem,
  Box,
  Grid,
  Typography,
  makeStyles,
  CircularProgress,
  Select,
  Button,
  FormControl
} from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import _ from "lodash";

const maxCpu = 8;
const maxRam = 16;

const useStyles = makeStyles({
  subTitle: {
    fontSize: "12px"
  },
  selectedMenuItem: {
    backgroundColor: "#E6F5FF",
    opacity: "1 !important"
  },
  menuItem: {
    borderBottom: "1px solid #cccccc",
    "&:hover": {
      background: "rgba(230, 245, 255, .6)"
    },

    "&:last-of-type": {
      borderBottom: "none !important"
    }
  },
  customMenuItem: {
    "&:hover": {
      background: "none"
    }
  },
  menuHeader: {
    paddingLeft: "10px",
    paddingRight: "8px"
  },
  customSelect: {
    width: "40px",
    padding: "6px 32px 6px 12px"
  }
});
export const NotebookSizesListItems = ({
  memGuarantee,
  cpuGuarantee,
  onClose,
  onUpdateConfig,
  isLoading
}: {
  onClose: () => void;
  onUpdateConfig: (config: { memGuarantee: number; cpuGuarantee: number }) => void;
  memGuarantee: number | null;
  cpuGuarantee: number | null;
  memGuaranteeUpdating: number | undefined;
  cpuGuaranteeUpdating: number | undefined;
  isLoading: boolean;
}) => {
  const [customRam, setCustomRam] = useState<number | "">(memGuarantee ?? "");
  const [customCPU, setCustomCPU] = useState<number | "">(cpuGuarantee ?? "");
  const classes = useStyles();

  const handleReset = () => {
    setCustomCPU(cpuGuarantee ?? "");
    setCustomRam(memGuarantee ?? "");
  };

  const handleApply = () => {
    if (customCPU && customRam)
      onUpdateConfig({ memGuarantee: customRam, cpuGuarantee: customCPU });
  };

  return (
    <Box m="0px 10px">
      <MenuItem
        className={classes.menuHeader}
        onClick={onClose}
        test-id="tenant-menu-notebook-sizes-back-btn">
        <Grid container alignItems="center">
          <Box pr="6px" display="flex">
            <ChevronLeftIcon fontSize="small" />
          </Box>
          <Typography className={classes.subTitle}>MY NOTEBOOK SIZE</Typography>
        </Grid>
      </MenuItem>
      <Box test-id="tenant-menu-notebook-sizes-list">
        <MenuItem
          className={clsx([classes.customMenuItem])}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
          }}>
          <Grid direction="column" container style={{ gap: "5px" }}>
            <Grid container style={{ gap: "10px" }} alignItems="center">
              RAM
              <FormControl>
                <Select
                  classes={{ select: classes.customSelect }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  variant="outlined"
                  value={customRam}
                  MenuProps={{
                    style: { height: "300px" },
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  }}
                  onChange={(event) => {
                    setCustomRam(event?.target.value as number);
                  }}>
                  {_.map(_.range(1, maxRam + 1), (item) => (
                    <MenuItem value={item}>{item}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              GB
            </Grid>
            <Grid container style={{ gap: "10px" }} alignItems="center">
              <Typography style={{ width: "34.72px" }}>CPU</Typography>
              <FormControl>
                <Select
                  classes={{ select: classes.customSelect }}
                  variant="outlined"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={customCPU}
                  MenuProps={{
                    style: { height: "300px" },
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  }}
                  onChange={(event) => {
                    setCustomCPU(event?.target.value as number);
                  }}>
                  {_.map(_.range(1, maxCpu + 1), (item) => (
                    <MenuItem value={item}>{item}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              GHz
            </Grid>
            <Grid container style={{ width: "100%", paddingTop: "10px" }}>
              <Grid
                container
                item
                style={{
                  gap: "5px",
                  marginLeft: "auto",
                  justifyContent: "flex-end",
                  alignItems: "center"
                }}>
                <Button
                  color="primary"
                  size="small"
                  variant="outlined"
                  disabled={
                    (!customCPU && !customRam) ||
                    isLoading ||
                    (customRam === memGuarantee && customCPU === cpuGuarantee)
                  }
                  onClick={handleReset}>
                  Reset
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  disabled={
                    !customCPU ||
                    !customRam ||
                    isLoading ||
                    (customRam === memGuarantee && customCPU === cpuGuarantee)
                  }
                  onClick={handleApply}>
                  {isLoading ? <CircularProgress size={20} /> : "Apply"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </MenuItem>
      </Box>
    </Box>
  );
};
