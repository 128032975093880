import AssignmentIcon from "@material-ui/icons/Assignment";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import _, { includes } from "lodash";
import { Grid, makeStyles } from "@material-ui/core";
import { useQueryClient } from "@tanstack/react-query";

import ApiConnectorIcon from "icons/NewUX/ApiConnectorIcon";
import NewThemeWrapper from "src/styles/NewThemeWrapper";
import styles from "./DeleteNodeModal.module.scss";
import useDeleteCandidates from "src/hooks/api/projects/useDeleteCandidates";
import useGetDeleteCandidates from "src/hooks/api/projects/useGetDeleteCandidates";
import { AiAssistedIcon } from "src/assets/icons/AiAssistedIcon";
import { ArtifactIcon } from "icons/NewUX/ArtifactIcon";
import { ChartIcon, FileIcon } from "icons/NewUX";
import { DatasetIcon } from "icons/NewUX/DatasetIcon";
import { Modal } from "src/components/custom";
import { ModelIcon } from "icons/NewUX/ModelIcon";
import { PipelineStepTypeEnum } from "@rapidcanvas/rc-api-core";
import { RapidModelIcon } from "src/assets/icons/RapidModelIcon";
import { RecipeIcon } from "src/assets/icons/RecipeIcon";
import { RecipeTypes } from "src/pages/private/ProjectsModule/utils";
import { clearArtifactCache, clearModelCache, UseGetProjectCanvasQueryKeys } from "src/hooks/api";
import { toastWrapper } from "services/ToastClient/toastWrapper";
import { updateProjectsQueryData } from "src/utils/helpers";
import CommonLoader from "src/components/CommonLoader";
import { VectorDBIcon } from "icons/NewUX/VectorDBIcon";

const keyMap = {
  [PipelineStepTypeEnum.Artifact]: "artifact",
  [PipelineStepTypeEnum.Chart]: "chart",
  [PipelineStepTypeEnum.Dfsgroup]: "group",
  [PipelineStepTypeEnum.Entity]: "entity",
  [PipelineStepTypeEnum.File]: "file",
  [PipelineStepTypeEnum.Model]: "model",
  [PipelineStepTypeEnum.VectorStore]: "vector_db",

  [PipelineStepTypeEnum.IntermediateEntity]: ""
};
const nodeTypes = {
  chart: {
    label: PipelineStepTypeEnum.Chart,
    displayName: "Chart",
    warningText: "Delete chart",
    icon: <ChartIcon width={18} height={20} viewBox="-2 -4 40 40" />
  },
  entity: {
    label: PipelineStepTypeEnum.Entity,
    displayName: "Entity",
    warningText: "Delete entity",
    icon: <DatasetIcon width={18} height={18} viewBox="-1 0 20 20" />
  },
  file: {
    label: PipelineStepTypeEnum.File,
    displayName: "File",
    warningText: "Delete file",
    icon: <FileIcon width={20} height={20} viewBox="0 0 18 18" />
  },
  vector_db: {
    label: PipelineStepTypeEnum.VectorStore,
    displayName: "Vector DB",
    warningText: "Delete Vector DB",
    icon: <VectorDBIcon width={20} height={20} viewBox="0 0 18 18" />
  },
  group: {
    label: PipelineStepTypeEnum.Dfsgroup,
    displayName: "Recipe",
    warningText: "Delete recipe",
    recipeIcons: {
      [RecipeTypes.AiAssisted]: <AiAssistedIcon />,
      [RecipeTypes.RapidModel]: <RapidModelIcon />,
      [RecipeTypes.ApiConnector]: <ApiConnectorIcon />,
      [RecipeTypes.Template]: <RecipeIcon />
    }
  },
  artifact: {
    label: PipelineStepTypeEnum.Artifact,
    displayName: "Artifact",
    warningText: "Delete artifact",
    icon: <ArtifactIcon width={18} height={18} viewBox="-2 0 17 17" />
  },
  model: {
    label: PipelineStepTypeEnum.Model,
    displayName: "Model",
    warningText: "Delete model",
    icon: <ModelIcon width={22} height={22} viewBox="-2 -2 20 20" />
  }
};

const useStyles = makeStyles({
  deleteNote: {
    fontStyle: "italic",
    color: "#000000",
    opacity: 0.5,
    paddingTop: "12px",
    fontSize: "14px"
  }
});

export const psMessage = (
  <span>
    This will also delete the associated prediction service(s) linked to the model(s) of this
    recipe(if any).
  </span>
);

interface IProps {
  open: boolean;
  onClose: () => void;
  projectId?: string;
  nodeId?: string;
  nodeName?: string;
  nodeType: string;
  deleteNote?: React.JSX.Element | string;
  onAfterSubmit?: () => void;
  onDeleteSuccess?: () => void;
}

const DeleteNodeModal: React.FC<IProps> = (props) => {
  const {
    open,
    projectId,
    nodeId = "",
    nodeName = "",
    nodeType = "entity",
    deleteNote,
    onClose,
    onAfterSubmit,
    onDeleteSuccess
  } = props;

  const { projectId: paramProjectId, scenarioId } = useParams();

  const classes = useStyles();
  const queryClient = useQueryClient();
  const deleteCandidates = useDeleteCandidates();

  // This is to wait for all awaited actions to complete after the delete mutation is fulfilled.
  const [isDeleting, setIsDeleting] = useState(false);

  const { data, isLoading, isError } = useGetDeleteCandidates(
    nodeId,
    _.get(nodeTypes, [nodeType, "label"]),
    nodeType,
    {
      enabled: open
    }
  );

  const handleDelete = () => {
    setIsDeleting(() => true);

    deleteCandidates.mutate(
      {
        stepId: nodeId,
        nodeType,
        stepType: _.get(nodeTypes, [nodeType, "label"])
      },
      {
        onSuccess: async () => {
          nodeType === "artifact" && (await clearArtifactCache(queryClient));
          nodeType === "model" && (await clearModelCache(queryClient));

          const projId = projectId ?? paramProjectId;
          if (nodeType === "entity" && !!projId) {
            updateProjectsQueryData({
              queryClient,
              data: { id: projId },
              fetchData: true
            });
          }

          toastWrapper({
            type: "success",
            content: `${_.get(nodeTypes, [nodeType, "displayName"]) || "Node"} ${nodeName} deleted successfully!`
          });

          if (!!projId) {
            await queryClient.invalidateQueries([
              UseGetProjectCanvasQueryKeys.ProjectCanvas,
              projId,
              ...(scenarioId ? [scenarioId] : [])
            ]);
          }

          onDeleteSuccess?.();
          onAfterSubmit?.();
          setIsDeleting(() => false);
          onClose();
        },
        onError: () => {
          setIsDeleting(() => false);
          onClose();
        }
      }
    );
  };

  return open ? (
    <NewThemeWrapper>
      <Modal
        open={open}
        title={_.get(nodeTypes, [nodeType, "warningText"])}
        onClose={(_, reason: string) => {
          if (!includes(["escapeKeyDown", "backdropClick"], reason)) {
            onClose();
          }
        }}
        isSubmitting={!!isDeleting}
        onSubmit={handleDelete}
        isSubmitDisabled={isLoading || isError || !!isDeleting}
        cancelLabel="No"
        submitLabel="Yes"
        hideCloseIcon>
        {isLoading ? (
          <CommonLoader />
        ) : (
          <>
            <p className={styles.deletedItemsTitle}>Following items will be deleted:</p>
            {_.map(
              _.sortBy(data, (obj) => (obj.id === nodeId ? -1 : 1)),
              (item) => {
                const nodeTypeKey = !!item.type ? _.toLower(_.get(keyMap, item.type)) : null;

                return (
                  <div key={item.id} className={styles.toBeDeletedItem}>
                    <div className={styles.toBeDeletedIcon}>
                      {item.type === PipelineStepTypeEnum.Dfsgroup ? (
                        item.recipeType ? (
                          _.get(nodeTypes, ["group", "recipeIcons", item.recipeType])
                        ) : (
                          <AssignmentIcon />
                        )
                      ) : !!nodeTypeKey ? (
                        _.get(nodeTypes, [nodeTypeKey, "icon"])
                      ) : (
                        <AssignmentIcon />
                      )}
                    </div>
                    <div className={styles.toBeDeletedName}>{item.displayName}</div>
                  </div>
                );
              }
            )}
            {deleteNote && <Grid className={classes.deleteNote}>{deleteNote}</Grid>}
          </>
        )}
      </Modal>
    </NewThemeWrapper>
  ) : (
    <></>
  );
};

export default DeleteNodeModal;
