// Packages
import { QueryClient } from "@tanstack/react-query";
import {
  DataAppDto,
  DataSourceDashBoardDto,
  EnvDto,
  ProjectDashboardDto,
  ProjectDto,
  ProjectRunDetailDto,
  ProjectRunDto
} from "@rapidcanvas/rc-api-core";
import { filter, find, get, isEmpty, isNil, map, some } from "lodash";

// Utils
import api from "services/AxiosClient/AxiosClient";

// Open API
import {
  QUERY_KEY_ENV_BY_ID,
  UseGetEnvironmentsQueryKeys,
  UseGetJobQueryKeys,
  UseGetJobsQueryKeys
} from "src/hooks/api";
import { UseGetConnectorsQueryKeys } from "src/hooks/api/dataSources/useGetConnectors";
import { UseGetProjectsQueryKeys } from "src/hooks/api/projects/useGetProjects";
import { UseGetProjectQueryKeys } from "src/hooks/api/projects/useGetProject";
import { QUERY_KEY_DATA_APPS } from "src/hooks/api/dataapps/useGetDataApps";
import { QUERY_KEY_DATAAPPS_ACROSS_TENANTS } from "src/hooks/api/dataapps/useGetDataAppAcrossTenants";
import { QUERY_KEY_ALL_PROJECT_RUNS } from "hooks/api/projects/useGetAllProjectRuns";

export enum UpdateQueriesDataActionEnum {
  Create = "create",
  Update = "update",
  Delete = "delete"
}

// Environments - STARTS >>
interface IUpdateEnvironmentsQueryDataProps {
  queryClient: QueryClient;
  data: EnvDto;
  action?: UpdateQueriesDataActionEnum;
  fetchData?: boolean;
}

export const updateEnvironmentsQueryData = async (props: IUpdateEnvironmentsQueryDataProps) => {
  const { queryClient, data, action = "update", fetchData = false } = props;

  let newEnvironment = data;
  if (action === "update") {
    if (!!newEnvironment?.id) {
      if (!!fetchData) {
        const envResponse = await api.fetchResponse(
          async () => await api.EnvControllerApi.findEnvById(newEnvironment?.id!)
        );

        newEnvironment = {
          ...newEnvironment,
          ...envResponse?.[0]
        };
      }
    }

    queryClient.setQueryData([QUERY_KEY_ENV_BY_ID, newEnvironment?.id], newEnvironment);
  }

  queryClient.setQueryData(
    [UseGetEnvironmentsQueryKeys.Environments],
    (prevEnvironments?: EnvDto[]) => {
      if (isNil(prevEnvironments) || isEmpty(prevEnvironments)) return prevEnvironments;

      switch (action) {
        case "create":
          // Add environment only if it doesn't already exist
          if (!some(prevEnvironments, { id: newEnvironment?.id })) {
            return [...prevEnvironments, newEnvironment];
          }
          return prevEnvironments;

        case "delete":
          // Remove environment with matching id
          return filter(
            prevEnvironments,
            (prevEnvironment) => prevEnvironment?.id !== newEnvironment?.id
          );

        case "update":
        default:
          // Replace environment with matching id or keep the previous environment
          return map(prevEnvironments, (prevEnvironment) =>
            prevEnvironment?.id === newEnvironment?.id
              ? { ...prevEnvironment, ...newEnvironment }
              : prevEnvironment
          );
      }
    }
  );
};
// << ENDS - Environments

// Connectors - STARTS >>
interface IUpdateConnectorsQueryDataProps {
  queryClient: QueryClient;
  data: DataSourceDashBoardDto;
  action?: UpdateQueriesDataActionEnum;
}

export const updateConnectorsQueryData = async (props: IUpdateConnectorsQueryDataProps) => {
  const { queryClient, data: newConnector, action = "update" } = props;

  queryClient.setQueryData(
    [UseGetConnectorsQueryKeys.Connectors],
    (prevConnectors?: DataSourceDashBoardDto[]) => {
      if (isNil(prevConnectors) || isEmpty(prevConnectors)) return prevConnectors;

      switch (action) {
        case "create":
          // Add connector only if it doesn't already exist
          if (!some(prevConnectors, { id: newConnector?.id })) {
            return [...prevConnectors, newConnector];
          }
          return prevConnectors;

        case "delete":
          // Remove connector with matching id
          return filter(prevConnectors, (prevConnector) => prevConnector?.id !== newConnector?.id);

        case "update":
        default:
          // Replace connector with matching id or keep the previous connector
          return map(prevConnectors, (prevConnector) =>
            prevConnector?.id === newConnector?.id
              ? { ...prevConnector, ...newConnector }
              : prevConnector
          );
      }
    }
  );
};
// << ENDS - Connectors

// Projects - STARTS >>
interface IUpdateProjectsQueryDataProps {
  queryClient: QueryClient;
  data: ProjectDashboardDto | ProjectDto;
  action?: UpdateQueriesDataActionEnum;
  fetchData?: boolean;
}

const getProject = async (data: ProjectDashboardDto | ProjectDto) => {
  const response = await api.fetchResponse(
    async () => await api.ProjectsControllerV2Api.getDashboard(undefined, data?.id)
  );
  if (isEmpty(response?.[0])) return {};

  return response?.[0];
};

const updateEnvOnProjectAssociationChange = async (
  queryClient: QueryClient,
  prevProject: ProjectDashboardDto | ProjectDto,
  newProject: ProjectDashboardDto | ProjectDto
) => {
  if (prevProject?.envId !== newProject?.envId) {
    // Updating the environment from which the project was removed.
    updateEnvironmentsQueryData({
      queryClient,
      data: { id: prevProject?.envId },
      fetchData: true
    });

    // @TODO: The get environment is calling for twice for the below envId. Need to fix.
    // Updating the environment to which the project was added.
    updateEnvironmentsQueryData({
      queryClient,
      data: { id: newProject?.envId },
      fetchData: true
    });
  }
};

export const updateProjectsQueryData = async (props: IUpdateProjectsQueryDataProps) => {
  const { queryClient, data, action = "update", fetchData = false } = props;

  let newProject = data;
  if (action === "update") {
    if (!!newProject?.id) {
      if (!!fetchData) {
        newProject = { ...newProject, ...(await getProject(newProject)) };
      }
    }

    // Passing newProject as an array ensures that the structure is transformed appropriately by the select in the query.
    queryClient.setQueryData([UseGetProjectQueryKeys.Project, newProject?.id], [newProject]);
  }

  queryClient.setQueryData(
    [UseGetProjectsQueryKeys.Projects],
    (prevProjects?: ProjectDashboardDto[]) => {
      if (isNil(prevProjects) || isEmpty(prevProjects)) return prevProjects;

      switch (action) {
        case "create":
          // Add project only if it doesn't already exist
          if (!some(prevProjects, { id: newProject?.id })) {
            return [...prevProjects, newProject];
          }
          return prevProjects;

        case "delete":
          // Remove project with matching id
          return filter(prevProjects, (prevProject) => prevProject?.id !== newProject?.id);

        case "update":
        default:
          return map(prevProjects, (prevProject) => {
            if (prevProject?.id === newProject?.id) {
              // Updating the environments whose associated projects are changed.
              updateEnvOnProjectAssociationChange(queryClient, prevProject, newProject);

              // Update project with new data
              return { ...prevProject, ...newProject };
            }

            return prevProject;
          });
      }
    }
  );
};
// << ENDS - Projects

// DataApps - STARTS >>
interface IUpdateDataAppsQueryDataProps {
  queryClient: QueryClient;
  data: DataAppDto;
  action?: UpdateQueriesDataActionEnum;
  fetchData?: boolean;
}

export const updateDataAppsQueryData = async (props: IUpdateDataAppsQueryDataProps) => {
  const { queryClient, data: newDataApp, action = "update" } = props;

  queryClient.setQueryData([QUERY_KEY_DATA_APPS], (prevDataApps?: DataAppDto[]) => {
    if (isNil(prevDataApps) || isEmpty(prevDataApps)) return prevDataApps;

    switch (action) {
      case "create":
        // Add data-app only if it doesn't already exist
        if (!some(prevDataApps, { id: newDataApp?.id })) {
          return [...prevDataApps, newDataApp];
        }
        return prevDataApps;

      case "delete":
        // Remove data-app with matching id
        return filter(prevDataApps, (prevDataApp) => prevDataApp?.id !== newDataApp?.id);

      case "update":
      default:
        // Replace data-app with matching id or keep the previous data-app
        return map(prevDataApps, (prevDataApp) =>
          prevDataApp?.id === newDataApp?.id ? { ...prevDataApp, ...newDataApp } : prevDataApp
        );
    }
  });
};
// << ENDS - DataApps

// DataApps across the tenants - STARTS >>
interface IUpdateDataAppsAcrossTenantsQueryDataProps {
  queryClient: QueryClient;
  data: DataAppDto;
  action?: UpdateQueriesDataActionEnum;
  fetchData?: boolean;
}

const getQueryKeyIfDataExists = (queryClient: QueryClient, name?: string) => {
  if (!name) return null;

  const queryKey = [QUERY_KEY_DATAAPPS_ACROSS_TENANTS, name];
  return !isEmpty(queryClient.getQueryData<DataAppDto[]>(queryKey)) ? queryKey : null;
};

export const updateDataAppsAcrossTenantsQueryData = async (
  props: IUpdateDataAppsAcrossTenantsQueryDataProps
) => {
  const { queryClient, data: newDataApp, action = "update" } = props;

  let queryKey = !!newDataApp?.displayName
    ? getQueryKeyIfDataExists(queryClient, newDataApp?.name)
    : null;

  if (!queryKey) {
    const tenantDataApps = queryClient.getQueryData<DataAppDto[]>([QUERY_KEY_DATA_APPS]);
    if (isEmpty(tenantDataApps)) return;

    const filteredDataApp = find(tenantDataApps, { id: newDataApp?.id });
    if (isEmpty(filteredDataApp)) return;

    if (!!get(filteredDataApp, "displayName")) {
      queryKey = getQueryKeyIfDataExists(queryClient, get(filteredDataApp, "displayName"));
    }

    if (!queryKey) {
      if (!!get(filteredDataApp, "name")) {
        queryKey = getQueryKeyIfDataExists(queryClient, get(filteredDataApp, "name"));
      }
    }
  }

  if (!queryKey) return;

  queryClient.setQueryData(queryKey, (prevDataApps?: DataAppDto[]) => {
    if (isNil(prevDataApps) || isEmpty(prevDataApps)) return prevDataApps;

    switch (action) {
      case "create":
        // Add data-app only if it doesn't already exist
        if (!some(prevDataApps, { id: newDataApp?.id })) {
          return [...prevDataApps, newDataApp];
        }
        return prevDataApps;

      case "delete":
        // Remove data-app with matching id
        return filter(prevDataApps, (prevDataApp) => prevDataApp?.id !== newDataApp?.id);

      case "update":
      default:
        // Replace data-app with matching id or keep the previous data-app
        return map(prevDataApps, (prevDataApp) =>
          prevDataApp?.id === newDataApp?.id ? { ...prevDataApp, ...newDataApp } : prevDataApp
        );
    }
  });
};
// << ENDS - DataApps across the tenants

// Jobs - STARTS >>
interface IUpdateJobsQueryDataProps {
  queryClient: QueryClient;
  data: ProjectRunDto;
  action?: UpdateQueriesDataActionEnum;
  fetchData?: boolean;
}

export const updateJobsQueryData = async (props: IUpdateJobsQueryDataProps) => {
  const { queryClient, data, action = "update", fetchData = false } = props;

  let newJob = data;
  if (action === "update") {
    if (!!newJob?.id) {
      if (!!fetchData) {
        const jobResponse = await api.fetchResponse(
          async () => await api.ProjectRunControllerApi.findProjectRunById(newJob?.id!)
        );

        newJob = {
          ...newJob,
          ...jobResponse
        };
      }
    }

    queryClient.setQueryData([UseGetJobQueryKeys.Job, data?.projectId, data?.id], newJob);
  }

  queryClient.setQueryData(
    [UseGetJobsQueryKeys.Jobs, data?.projectId, data?.jobType, data?.subType],
    (prevJobs?: ProjectRunDetailDto[]) => {
      if (isNil(prevJobs) || isEmpty(prevJobs)) return prevJobs;

      switch (action) {
        case "create":
          // @TODO: append logic here
          return prevJobs;

        case "delete":
          // @TODO: filter logic here
          return prevJobs;

        case "update":
        default:
          // Replace job with matching id or keep the previous job
          return map(prevJobs, (prevJob) =>
            prevJob?.dto?.id === newJob?.id
              ? { ...prevJob, dto: { ...prevJob.dto, ...newJob } }
              : prevJob
          );
      }
    }
  );
};
// << ENDS - Jobs

// Prediction schedulers - STARTS >>
interface IUpdatePredictionSchedulersQueryDataProps {
  queryClient: QueryClient;
  data: ProjectRunDto;
  action?: UpdateQueriesDataActionEnum;
  fetchData?: boolean;
}

export const updatePredictionSchedulersQueryData = async (
  props: IUpdatePredictionSchedulersQueryDataProps
) => {
  const { queryClient, data, action = "update", fetchData = false } = props;

  let newPredictionScheduler = data;
  if (action === "update") {
    if (!!newPredictionScheduler?.id) {
      if (!!fetchData) {
        const predictionSchedulerResponse = await api.fetchResponse(
          async () =>
            await api.ProjectRunControllerApi.findProjectRunById(newPredictionScheduler?.id!)
        );

        newPredictionScheduler = {
          ...newPredictionScheduler,
          ...predictionSchedulerResponse
        };
      }
    }

    // Update prediction-scheduler query data here.
  }

  queryClient.setQueryData(
    // 2rd argument is for isManual
    [QUERY_KEY_ALL_PROJECT_RUNS, data?.projectId, false],
    (prevPredictionSchedulers?: ProjectRunDetailDto[]) => {
      if (isNil(prevPredictionSchedulers) || isEmpty(prevPredictionSchedulers))
        return prevPredictionSchedulers;

      switch (action) {
        case "create":
          // @TODO: append logic here
          return prevPredictionSchedulers;

        case "delete":
          // @TODO: filter logic here
          return prevPredictionSchedulers;

        case "update":
        default:
          // Replace prediction scheduler with matching id or keep the previous prediction scheduler
          return map(prevPredictionSchedulers, (prevPredictionScheduler) =>
            prevPredictionScheduler?.dto?.id === newPredictionScheduler?.id
              ? {
                  ...prevPredictionScheduler,
                  dto: { ...prevPredictionScheduler.dto, ...newPredictionScheduler }
                }
              : prevPredictionScheduler
          );
      }
    }
  );
};
// << ENDS - Prediction schedulers
