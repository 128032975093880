import { useQueryClient, useMutation } from "@tanstack/react-query";

import { deleteJobWithRethrow } from "services/Apis/wrappers/projects";
import { UseGetJobsDashboardQueryKeys } from "./useGetJobs";

type DeleteJobPayloadTypes = { jobId: string };

export const useDeleteJob = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ jobId }: DeleteJobPayloadTypes) => deleteJobWithRethrow(jobId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["jobs"] });
      queryClient.refetchQueries([UseGetJobsDashboardQueryKeys.JobsDashboard]);
    }
  });
};
