/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import {
  AutocompleteProps as MuiAutocompleteProps,
  Autocomplete as MuiAutocomplete,
  AutocompleteRenderInputParams
} from "@material-ui/lab";
import { TextField, makeStyles } from "@material-ui/core";

import Spinner from "../Spinner";
import { orderBy, toLower } from "lodash";
import ListBox from "./AutoCompleteListbox";

interface Option {
  label: string;
  value: string;
}

//@ts-expect-error
interface AutocompleteProps extends MuiAutocompleteProps<Option, false, false, false> {
  options: Option[];
  value: string | null;
  label?: string;
  required?: boolean;
  disableClearable?: boolean;
  onChange: (event: any, selectedOption: string | null) => void;
  renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
  isSorted?: boolean;
  virtualized?: boolean;
}

const useStyles = makeStyles({
  selectOption: {
    whiteSpace: "normal"
  }
});

export const Autocomplete: React.FC<AutocompleteProps> = ({
  options,
  label,
  value,
  onChange,
  required,
  virtualized,
  isSorted,
  ...restProps
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  return (
    <MuiAutocomplete
      classes={{
        option: classes.selectOption
      }}
      autoHighlight
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      loadingText={<Spinner padding={5} size={24} />}
      options={isSorted ? orderBy(options, (item) => toLower(item.label)) : options}
      value={options.find((option) => option.value === value) || null}
      getOptionLabel={(option: Option) => option.label}
      getOptionSelected={(option: Option, value: Option) => option.value === value.value}
      onChange={(event, newValue) => {
        onChange(event, newValue ? newValue.value : null);
      }}
      ListboxComponent={
        virtualized
          ? (ListBox as React.ComponentType<React.HTMLAttributes<HTMLElement>>)
          : undefined
      }
      renderInput={(params) => (
        <TextField
          {...params}
          required={required}
          variant="outlined"
          label={label}
          placeholder=""
        />
      )}
      {...restProps}
    />
  );
};
