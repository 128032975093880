import React, { useState } from "react";
import { map, size } from "lodash";
import { Box, Tooltip, Typography, makeStyles } from "@material-ui/core";
import { EnvUsageDto, ProjectDashboardDto } from "@rapidcanvas/rc-api-core";
import { InfoOutlined } from "@material-ui/icons";
import {
  NewEnvironmentFormFields,
  NewEnvironmentFormFieldsNameMapping
} from "src/pages/private/EnvironmentsModule/pages/Environments/utils/Environments.constants";

import { Tabs, Tab, TabPanel } from "src/components";
import Space from "src/pages/common/Space";
import {
  LinuxLibrariesInfoLines,
  PythonLibrariesAdditionalInfo,
  PythonLibrariesInfoLines
} from "../../../../utils/Environments.constants";

import { PredictionServicesTab } from "./PredictionServicesTab";
import { EnvironmentProjectsTab } from "./EnvironmentProjectsTab";
import { EnvironmentPackagesTab } from "./EnvironmentPackagesTab";
import { useExtractPredictionServices } from "./useExtractPredictionServices";
import { useExtractProjects } from "./useExtractProjects";
import { useFormContext } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
  tabPanelContainer: {
    // 94 (SubTopNavBar height)
    // + 24 (Page padding)
    // + 48 (Tabs height)
    // + 24 (Data section margin-top)
    // + 8 (Data section padding-top)
    // + n (buffer)
    height: "calc(100vh - 214px)",
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
    borderRadius: 12,
    overflowY: "auto"
  },
  tabPanel: {
    height: "100%"
  }
}));

export interface Project extends ProjectDashboardDto {
  jobCount?: number;
  predictionJobCount?: number;
}

type Props = {
  isDefault: boolean;
  isLoadingEnvUsage: boolean;
  envUsage: EnvUsageDto[];
  requirements: string;
  linuxPkgs: string;
};

export const EnvironmentTabs = ({
  isDefault,
  envUsage,
  isLoadingEnvUsage,
  requirements,
  linuxPkgs
}: Props) => {
  const classes = useStyles();
  const [value, setValue] = useState(1);

  const { setValue: setFormValue } = useFormContext();

  const { predictionServices, isLoadingPredictionServices } = useExtractPredictionServices({
    envUsage,
    isLoadingEnvUsage
  });

  const { projects, isLoadingProjects } = useExtractProjects({ isLoadingEnvUsage, envUsage });
  const handleChangeCodeEditor = (_name: string, value: string) => {
    setFormValue(NewEnvironmentFormFields.Requirements, value, { shouldDirty: true });
  };
  const handleChangeLinuxLibs = (_name: string, value: string) => {
    setFormValue(NewEnvironmentFormFields.LinuxLibs, value, { shouldDirty: true });
  };

  return (
    <>
      <Tabs
        value={value}
        onChange={setValue}
        textColor="primary"
        indicatorColor="primary"
        variant="scrollable">
        <Tab
          value={1}
          label={
            <Space justifyContent="center">
              <Typography variant="body2">
                {NewEnvironmentFormFieldsNameMapping[NewEnvironmentFormFields.Packages]}
              </Typography>
              <Tooltip
                arrow
                title={map(PythonLibrariesInfoLines, (line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    {index < size(PythonLibrariesInfoLines) - 1 && <br />}
                  </React.Fragment>
                ))}>
                <InfoOutlined style={{ color: "#9e9e9e", fontSize: "16px" }} />
              </Tooltip>
            </Space>
          }
        />
        <Tab
          value={2}
          label={
            <Space justifyContent="center">
              <Typography variant="body2">
                {NewEnvironmentFormFieldsNameMapping[NewEnvironmentFormFields.LinuxLibs]}
              </Typography>
              <Tooltip
                arrow
                title={map(LinuxLibrariesInfoLines, (line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    {index < size(LinuxLibrariesInfoLines) - 1 && <br />}
                  </React.Fragment>
                ))}>
                <InfoOutlined style={{ color: "#9e9e9e", fontSize: "16px" }} />
              </Tooltip>
            </Space>
          }
        />
        <Tab value={3} label="Projects" />
        <Tab value={4} label="Prediction Service" />
      </Tabs>
      <Box className={classes.tabPanelContainer}>
        <TabPanel value={value} index={1} className={classes.tabPanel}>
          <Box mb={1}>
            <Typography variant="body2" style={{ fontStyle: "italic" }}>
              <Box display="inline" style={{ fontWeight: "bold" }}>
                Note:{" "}
              </Box>
              {PythonLibrariesAdditionalInfo}
            </Typography>
          </Box>
          <Box style={{ height: "calc(100vh - 295px)" }}>
            <EnvironmentPackagesTab
              onEditorChange={handleChangeCodeEditor}
              isDefault={isDefault}
              code={requirements}
              name={NewEnvironmentFormFields.Requirements}
            />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={2} className={classes.tabPanel}>
          <EnvironmentPackagesTab
            onEditorChange={handleChangeLinuxLibs}
            isDefault={isDefault}
            code={linuxPkgs}
            name={NewEnvironmentFormFields.LinuxLibs}
          />
        </TabPanel>
        <TabPanel value={value} index={3} className={classes.tabPanel}>
          <EnvironmentProjectsTab projects={projects} isLoading={isLoadingProjects} />
        </TabPanel>
        <TabPanel value={value} index={4} className={classes.tabPanel}>
          <PredictionServicesTab
            predictionServices={predictionServices}
            isLoading={isLoadingPredictionServices || isLoadingEnvUsage}
          />
        </TabPanel>
      </Box>
    </>
  );
};
