import React from "react";
import _ from "lodash";

const useDebounce = (
  value: string,
  minCharLimit = 3,
  delay = 500
): [string, React.Dispatch<React.SetStateAction<string>>] => {
  const [debouncedValue, setDebouncedValue] = React.useState(value);

  React.useEffect(() => {
    if (_.size(_.toString(value)) < minCharLimit) {
      setDebouncedValue("");
      return () => _.noop();
    }

    const handler: NodeJS.Timeout = setTimeout(() => {
      setDebouncedValue(value);
    }, delay) as unknown as NodeJS.Timeout;

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay, minCharLimit]);

  return [_.toString(debouncedValue), setDebouncedValue];
};

export default useDebounce;
