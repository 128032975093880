import React, { useMemo } from "react";
import { Link } from "react-router-dom";

import Text from "components/Widget/Text";
import styles from "../../../common/Environments.module.scss";
import { IPredictionService } from "../../Environment";
import { TABS } from "pages/Library/ArtifactsAndModels/Models/ModelDetails";
import { dateFormat } from "utils/helpers/date.helpers";
import { CellContext } from "@tanstack/react-table";
import Table from "components/custom/TableV8/Table";

interface IProps {
  data: IPredictionService[];
}

const EnvironmentPredictionServiceTable: React.FC<IProps> = ({ data }) => {
  const columns = useMemo(
    () => [
      {
        id: "Name",
        accessorKey: "pdName",
        header: "Name",

        cell: ({ row, getValue }: CellContext<any, string>) => (
          <Link
            className={styles.nameLinkStyles}
            to={`/artifacts-and-models/model/${row.original?.pdModelName}`}
            state={{ tab: TABS.predictionService.value }}>
            <Text value={getValue()} />
          </Link>
        )
      },
      {
        id: "Model",
        accessorKey: "pdModelName",
        header: "Model",

        cell: ({ getValue }: CellContext<any, string>) => <Text value={getValue()} />
      },
      {
        id: "Creation Date",
        accessorKey: "pdCreated",
        header: "Creation Date",

        cell: ({ getValue }: CellContext<any, number>) => <span>{dateFormat(getValue())}</span>
      },
      {
        id: "Creator",
        accessorKey: "pdCreator",
        header: "Creator"
      }
    ],
    []
  );

  return (
    <section>
      <Table
        data={data}
        columns={columns}
        sortingProps={{ orderByDefault: "Creation Date", sortInverted: false }}
        stylesProps={{ showBorder: true }}
      />
    </section>
  );
};

export default EnvironmentPredictionServiceTable;
