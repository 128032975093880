import React, { useEffect, useState, useMemo } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

import _, { get, isEmpty, orderBy, toLower } from "lodash";
import { Badge, Grid, IconButton, makeStyles, Tooltip, Typography } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import { Search, ToggleView } from "src/components/custom";
import { FilterIcon } from "src/assets/icons/FilterIcon";

import { deleteDataSourceWithRethrow } from "services/Apis/wrappers/dataSources";

import { toastWrapper } from "services/ToastClient/toastWrapper";
import { updateConnectorsQueryData, UpdateQueriesDataActionEnum } from "src/utils/helpers";

import { useDataSourcesStore } from "stores/zustand/stores";
import {
  dataSourcesSetter,
  dataSourceTypeSetter,
  isCreateDataSourceGetter,
  isCreateDataSourceSetter
} from "stores/zustand/stores.selectors";

import {
  ConnectorsConfig,
  DataSourceDeletePromptDetails,
  DataSourcesHelperText
} from "./utils/DataSources.constants";

import DataSourcesTable from "./DataSourcesTable";
import DataSourcesTiles from "./DataSourcesTiles";
import SelectDataSourceModal from "./SelectDataSourceModal";
import useRefreshDataConnectorStatus from "hooks/api/dataSources/useRefreshDataConnectorStatus";
import useSyncConnector from "hooks/api/dataSources/useSyncConnector";
import Modal, { ModalVariants } from "src/components/custom/Modal/Modal";
import {
  getFivetranConnectorStatus,
  SYNCING_LIMIT_IN_SECONDS
} from "connectorsModule/utils/fivetran.helpers";
import { thirdPartyTypeName } from "src/pages/DataSources/utils/DataSources.constants";
import { useGetConnectors } from "src/hooks/api";
import { FivetranStatus } from "src/pages/private/ProjectsModule/pages/Dataset/utils/Dataset.constants";
import SplashSectionWrapper from "./SplashSectionWrapper";
import backgroundTopImg from "src/assets/images/background-top.svg";
import backgroundBottomImg from "src/assets/images/background-bottom.svg";
import { WebPaths } from "src/routing/routes";
import { listViewPages, PagesViewConfiguration } from "constants/index";
import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import { PlusIcon } from "icons/NewUX/PlusIcon";
import NoDataFoundDefault from "../common/NoDataFoundDefault";
import CommonLoader from "src/components/CommonLoader";

const useStyles = makeStyles({
  containerBackground: {
    backgroundImage: `url(${backgroundTopImg}), url(${backgroundBottomImg})`,
    backgroundSize: "100% auto, 100% auto",
    backgroundRepeat: "no-repeat, no-repeat",
    backgroundPosition: "right top, right bottom"
  },
  headerContainer: {
    position: "sticky",
    top: 64,
    margin: "0 10%",
    zIndex: 1
  },
  root: {
    display: "flex",
    marginTop: 64,
    flexDirection: "column",
    padding: "0 10% 32px 10%",
    width: "100%",
    // New UX change
    // The value 96px is the height of both the NavBars (TopNavBar 50px + SubTopNavBar 46px).
    height: "calc(100vh - 228px)",
    overflowY: "auto",
    overflowX: "hidden"
  },
  title: {
    color: "#003656",
    fontSize: "32px",
    lineHeight: "37.5px",
    paddingBottom: "4px"
  },
  dataSourceTypeMenuItemContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center"
  },
  topActions: {
    display: "flex",
    gap: "5px",
    alignItems: "center",
    marginLeft: "auto"
  }
});

interface IDataSourceTypeGroup {
  name: string;
  checked: boolean;
  count: number;
}

export const SYNC_ID = "syncId";
const DataSources = () => {
  const classes = useStyles();

  const queryClient = useQueryClient();

  useSyncConnector();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const pagesViewConfiguration = JSON.parse(localStorage.getItem(PagesViewConfiguration) || "{}");
  const isTilesView = get(pagesViewConfiguration, listViewPages.CONNECTORS, true);

  // Stores - STARTS >>
  const [newConnectorToSync, setNewConnectorToSync] = useDataSourcesStore((state) => [
    state.newConnectorToSync,
    state.setNewConnectorToSync
  ]);
  const setDataSourcesStore = useDataSourcesStore(dataSourcesSetter);
  const setDataSourceTypeStore = useDataSourcesStore(dataSourceTypeSetter);
  const isCreateDataSourceStore = useDataSourcesStore(isCreateDataSourceGetter);
  const setIsCreateDataSourceStore = useDataSourcesStore(isCreateDataSourceSetter);
  // << ENDS - Stores

  // State for Pre-fetching data - STARTS >>
  const [dataSourcesTypesGroup, setDataSourcesTypesGroup] = useState<IDataSourceTypeGroup[]>([]);
  const [searchValue, setSearchValue] = useState("");

  const [dataSourcesTypesGroupAnchorEl, setDataSourcesTypesGroupAnchorEl] = React.useState(null);

  const [showConfirmScreen, setShowConfirmScreen] = useState(false);
  const [deletingDataSourceId, setDeletingDataSourceId] = useState("");
  // << ENDS - State for Pre-fetching data

  const [tilesView, setTilesView] = useState(isTilesView);
  const [data, setData] = useState<any[]>([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isForceLoading, setIsForceLoading] = useState(false);

  const refreshStatus = useRefreshDataConnectorStatus();

  const toggleView = (bool: boolean) => {
    setTilesView(bool);
    localStorage.setItem(
      PagesViewConfiguration,
      JSON.stringify({ ...pagesViewConfiguration, [listViewPages.CONNECTORS]: bool })
    );
  };

  const setStates = (modifiedData: any[]) => {
    setData(modifiedData);
    setDataSourcesStore(modifiedData);

    let allSources: Record<string, number> = {};

    _.forEach(modifiedData, (item) => {
      if (item?.dataSourceType === thirdPartyTypeName) {
        const oldCount = _.get(allSources, item.tpConnectorType) ?? 0;
        _.setWith(allSources, [item.tpConnectorType], oldCount + 1, Object);
      } else {
        const oldCount = _.get(allSources, item.dataSourceType) ?? 0;
        _.setWith(allSources, [item.dataSourceType], oldCount + 1, Object);
      }
    });

    setDataSourcesTypesGroup(
      _.map(allSources, (count: number, key: string) => ({
        name: key,
        checked: true,
        count
      }))
    );
  };

  useEffect(() => {
    if (queryParams.get(SYNC_ID)) {
      navigate(WebPaths.DataConnectors, { replace: true });
    }
  }, [queryParams]);

  const getRefreshedStatus = (syncingIds: string[], previousData: any[]) => {
    refreshStatus.mutate(
      { dataSourceIds: syncingIds },
      {
        onSuccess: (syncedResults) => {
          const keyBySynced = _.keyBy(syncedResults, "id");
          const reSynced = _.map(previousData, (connector) => {
            if (_.has(keyBySynced, connector?.id)) {
              const synced = _.get(keyBySynced, connector?.id);
              return {
                ...connector,
                ...synced,
                tpConnectorType: _.toUpper(synced.tpConnectorType),
                dataSourceType: _.toUpper(synced.dataSourceType)
              };
            } else {
              return connector;
            }
          });
          setStates(reSynced);
        }
      }
    );
  };

  const handleSuccess = (results?: any[]) => {
    let modifiedData: any[] = [];
    let syncingIds: string[] = [];

    _.forEach(results, (connector) => {
      modifiedData.push({
        ...connector,
        tpConnectorType: _.toUpper(connector.tpConnectorType),
        dataSourceType: _.toUpper(connector.dataSourceType)
      });

      if (
        connector?.dataSourceType === thirdPartyTypeName &&
        !_.includes(
          [FivetranStatus.active, FivetranStatus.inActive],
          getFivetranConnectorStatus(connector?.thirdPartyDataSourceStatus)
        )
      ) {
        syncingIds.push(connector.id);
      }
    });

    setStates(modifiedData);

    if (!_.isEmpty(syncingIds)) {
      getRefreshedStatus(syncingIds, modifiedData);
    }

    if (
      newConnectorToSync?.id &&
      newConnectorToSync?.created &&
      (Date.now() - newConnectorToSync?.created) / 1000 < SYNCING_LIMIT_IN_SECONDS
    ) {
      setTimeout(() => {
        getRefreshedStatus([newConnectorToSync.id], modifiedData);
        setNewConnectorToSync(null);
      }, SYNCING_LIMIT_IN_SECONDS * 1000);
    }
  };

  const {
    isFetched,
    isLoading,
    isFetching,
    data: dataSourcesQueryData,
    refetch: refetchDataSources
  } = useGetConnectors({
    refetchOnMount: true
  });

  useEffect(() => {
    isFetched && handleSuccess(dataSourcesQueryData);
  }, [isFetched, dataSourcesQueryData]);

  // << ENDS - Pre-fetching required data

  const dataSources = useMemo(() => {
    let dataFiltered = data?.sort(
      (set1: $TSFixMe, set2: $TSFixMe) => set2?.updated - set1?.updated
    );

    const selectedDataSourcesTypes = dataSourcesTypesGroup
      ?.filter((eachDataSourceType: $TSFixMe) => eachDataSourceType?.checked)
      ?.map((eachDataSourceType: $TSFixMe) => eachDataSourceType?.name);

    if (selectedDataSourcesTypes?.length === 0) {
      dataFiltered = [];
    } else {
      dataFiltered = dataFiltered?.filter((item: $TSFixMe) => {
        return selectedDataSourcesTypes?.includes(
          item?.dataSourceType === thirdPartyTypeName ? item?.tpConnectorType : item?.dataSourceType
        );
      });
    }

    if (dataFiltered?.length === 0) return [];

    if (searchValue) {
      return dataFiltered?.filter((item: $TSFixMe) => {
        return item?.name?.toLowerCase?.().includes?.(searchValue?.toLowerCase());
      });
    }

    return dataFiltered;
  }, [data, searchValue, dataSourcesTypesGroup]);

  const onSearch = ({ target: { value } }: $TSFixMe) => {
    setSearchValue(value);
  };

  // Select data source - STARTS >>
  const onSelectDataSourceModalClose = () => {
    setIsCreateDataSourceStore(false);
  };

  const onSelectDataSourceModalSubmit = (selection: string) => {
    setIsCreateDataSourceStore(false);
    setDataSourceTypeStore(selection);

    navigate(WebPaths.CreateDataConnector);
  };
  // << ENDS - Select data source

  // DataSourcesTypes filter dropdown - STARTS >>
  const toggleDataSourcesTypesGroupFilter = (event: $TSFixMe) => {
    setDataSourcesTypesGroupAnchorEl(event.currentTarget);
  };

  const closeDataSourcesTypesGroupFilter = () => {
    setDataSourcesTypesGroupAnchorEl(null);
  };

  const toggleDataSourcesTypesSelectAll = () => {
    const isSelectedAll = dataSourcesTypesGroup?.every(
      (eachDataSourceType: IDataSourceTypeGroup) => eachDataSourceType?.checked
    );

    setDataSourcesTypesGroup(
      dataSourcesTypesGroup?.map((eachDataSourceType: IDataSourceTypeGroup) => {
        eachDataSourceType.checked = !isSelectedAll;
        return eachDataSourceType;
      })
    );
  };

  const onDataSourcesTypesGroupChange = (dataSourceTypeName: string) => {
    setDataSourcesTypesGroup(
      dataSourcesTypesGroup?.map((eachDataSourceType: IDataSourceTypeGroup) => {
        return eachDataSourceType?.name === dataSourceTypeName
          ? {
              ...eachDataSourceType,
              checked: !eachDataSourceType?.checked
            }
          : eachDataSourceType;
      })
    );
  };
  // << ENDS - DataSourcesTypes filter dropdown

  const editDataSource = (data: $TSFixMe) => navigate(`/data-connectors/${data?.id}`);

  // Delete data source - STARTS >>
  const promptConfirmDeleteDataSource = (id: string) => {
    setDeletingDataSourceId(id);
    setShowConfirmScreen(true);
  };

  const cancelDeleteDataSource = () => {
    setDeletingDataSourceId("");
    setShowConfirmScreen(false);
  };

  const confirmDeleteDataSource = () => {
    deleteDataSource();
    setDeletingDataSourceId("");
  };

  const deleteDataSource = () => {
    const _ = async () => {
      if (deletingDataSourceId) {
        setIsDeleting(() => true);

        try {
          await deleteDataSourceWithRethrow({ id: deletingDataSourceId });

          toastWrapper({
            type: "success",
            content: DataSourcesHelperText.DataSourceDeleteSucceed
          });

          updateConnectorsQueryData({
            queryClient,
            data: { id: deletingDataSourceId },
            action: UpdateQueriesDataActionEnum.Delete
          });

          setIsDeleting(() => false);
          setShowConfirmScreen(() => false);

          refetchDataSources();
        } catch (e: $TSFixMe) {
          console.error(e);
        } finally {
          setIsDeleting(() => false);
          setShowConfirmScreen(() => false);
          setIsForceLoading(() => false);
        }
      }
    };

    _();
  };
  // << ENDS - Delete data source

  const handleManualSync = (connectedId: string) => {
    setNewConnectorToSync({ id: connectedId, created: Date.now() });
    const newData = _.map(data, (connector) => {
      if (connector.id === connectedId) {
        return {
          ...connector,
          thirdPartyDataSourceStatus: {
            ...connector.thirdPartyDataSourceStatus,
            historicalSync: true,
            syncState: FivetranStatus.syncing
          }
        };
      } else {
        return connector;
      }
    });
    setData(newData);
  };

  const isFiltered = useMemo(
    () =>
      dataSourcesTypesGroup.every(
        (eachDataSourceType: IDataSourceTypeGroup) => eachDataSourceType?.checked
      ),
    [dataSourcesTypesGroup]
  );

  const availableWindowSize = window.innerWidth * (1 - ConnectorsConfig.ExemptingWidth);
  const numberOfCards = Math.floor((availableWindowSize + 24) / (ConnectorsConfig.CardWidth + 24));

  const startLoc = tilesView
    ? (availableWindowSize + 24 - numberOfCards * (ConnectorsConfig.CardWidth + 24)) / 2
    : 0;

  const isLoadingConnectors = useMemo(
    () => isLoading || isForceLoading,
    [isLoading, isForceLoading]
  );

  return (
    <>
      {isCreateDataSourceStore && (
        <SelectDataSourceModal
          open={true}
          onClose={onSelectDataSourceModalClose}
          onSubmit={onSelectDataSourceModalSubmit}
        />
      )}
      {showConfirmScreen && (
        <Modal
          open={true}
          variant={ModalVariants.Delete}
          title="Delete Connector"
          content={[DataSourceDeletePromptDetails.title, DataSourceDeletePromptDetails.message]}
          isSubmitting={isDeleting}
          onClose={cancelDeleteDataSource}
          onSubmit={confirmDeleteDataSource}
        />
      )}
      <SubTopNavBarWrapper
        // subTopNavBarLeftSection={{
        //   backNavAction: null
        // }}
        subTopNavBarRightSection={{
          component: (
            <Tooltip
              title={
                isFetching || isLoading ? "Please wait while data connectors are loading" : ""
              }>
              <span>
                <IconButton
                  data-testid="addConnectorButton"
                  color="primary"
                  size="small"
                  disabled={isFetching || isLoading}
                  onClick={() => setIsCreateDataSourceStore(true)}>
                  <PlusIcon width={28} height={28} />
                </IconButton>
              </span>
            </Tooltip>
          )
        }}
      />

      {/* @REFACTOR */}
      {/* The below code should be moved to Header component. */}
      {/* DataSourcesTypes filter dropdown - STARTS >> */}
      <Menu
        id="dataSourcesTypesFilter"
        anchorEl={dataSourcesTypesGroupAnchorEl}
        open={Boolean(dataSourcesTypesGroupAnchorEl)}
        onClose={closeDataSourcesTypesGroupFilter}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}>
        <MenuItem key="data_sources_types_select_all">
          <Checkbox
            data-testid="filterSelectAll"
            color="default"
            checked={dataSourcesTypesGroup.every(
              (eachDataSourceType: IDataSourceTypeGroup) => eachDataSourceType?.checked
            )}
            onClick={toggleDataSourcesTypesSelectAll}
          />
          {DataSourcesHelperText.SelectAll}
        </MenuItem>
        {orderBy(dataSourcesTypesGroup, (item) => toLower(item.name)).map(
          (dataSourceType: IDataSourceTypeGroup, index: number) => (
            <MenuItem key={`data_source_type_${index}`}>
              <Checkbox
                data-testid={`dataConnector${index + 1}`}
                checked={dataSourceType?.checked}
                color="default"
                onClick={() => {
                  onDataSourcesTypesGroupChange(dataSourceType?.name);
                }}
              />
              <div className={classes.dataSourceTypeMenuItemContainer}>
                {dataSourceType?.name}
                <Chip label={dataSourceType?.count} />
              </div>
            </MenuItem>
          )
        )}
      </Menu>
      {/* << ENDS - DataSourcesTypes filter dropdown */}

      {isLoadingConnectors ? (
        <CommonLoader />
      ) : (
        <Grid container className={classes.containerBackground}>
          <Grid
            container
            className={classes.headerContainer}
            {...(isTilesView
              ? {
                  style: {
                    padding: `0 ${startLoc - 92}px 0 ${startLoc}px`,
                    width: availableWindowSize
                  }
                }
              : { style: { margin: "0 5% 0 10%" } })}>
            {dataSources?.length !== 0 && (
              <Grid item>
                <Typography data-testid="connectors" variant="h5" className={classes.title}>
                  Connectors
                </Typography>

                <Typography data-testid="connectorsCount" variant="subtitle1">
                  Showing {dataSources.length || 0} Connectors
                </Typography>
              </Grid>
            )}

            <Grid className={classes.topActions}>
              {data?.length > 0 && (
                <>
                  <Search
                    data-testid="dataConnectorSearchField"
                    value={searchValue}
                    onSearch={onSearch}
                    placeholder={DataSourcesHelperText.SearchDataSourcesPlaceholder}
                    InputProps={{ style: { width: 210 } }}
                  />
                  <IconButton
                    data-testid="dataConnectorFilterIcon"
                    onClick={toggleDataSourcesTypesGroupFilter}
                    disabled={dataSourcesTypesGroup?.length === 0}>
                    <Badge color="error" variant="dot" overlap="rectangular" invisible={isFiltered}>
                      <FilterIcon />
                    </Badge>
                  </IconButton>
                  <ToggleView isPrimaryView={tilesView} setIsPrimaryView={toggleView} />
                </>
              )}
            </Grid>
          </Grid>
          <Grid item container direction="column" wrap="nowrap" xs={12} className={classes.root}>
            {isEmpty(data) ? (
              <SplashSectionWrapper onClick={() => setIsCreateDataSourceStore(true)} />
            ) : isEmpty(dataSources) ? (
              <NoDataFoundDefault
                title={
                  searchValue
                    ? `No connector found with keyword "${searchValue}"`
                    : "No connector found with applied filters"
                }
                subTitle={searchValue ? undefined : " Please change filter criteria to select"}
                onClear={() => setSearchValue("")}
              />
            ) : (
              <Grid item style={{ padding: `16px ${startLoc}px`, width: availableWindowSize }}>
                {tilesView ? (
                  <DataSourcesTiles
                    dataSources={dataSources}
                    editDataSource={editDataSource}
                    deleteDataSource={promptConfirmDeleteDataSource}
                    onManualSync={handleManualSync}
                  />
                ) : (
                  <DataSourcesTable
                    dataSources={dataSources}
                    editDataSource={editDataSource}
                    deleteDataSource={promptConfirmDeleteDataSource}
                    onManualSync={handleManualSync}
                  />
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default DataSources;
