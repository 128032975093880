import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";

import api from "services/AxiosClient/AxiosClient";

import { DataAppType } from "src/pages/DataApps/DataApps.type";
import { updateDataAppsAcrossTenantsQueryData, updateDataAppsQueryData } from "src/utils/helpers";
import { QUERY_KEY_DATAAPP_BY_ID } from "./useDataAppById";
import { DataAppDto } from "@rapidcanvas/rc-api-core";

type Props = { dataAppId: string; dataApp: DataAppType };

const useSaveDataAppMutation = (): UseMutationResult<DataAppDto, unknown, Props, unknown> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ dataAppId, dataApp }: Props) => {
      const response = await api.fetchResponse(
        async () => await api.DataAppControllerApi.updateDataApp(dataAppId, dataApp as any)
      );
      return response;
    },
    onSuccess: (data, { dataAppId }) => {
      updateDataAppsQueryData({ queryClient, data });
      updateDataAppsAcrossTenantsQueryData({ queryClient, data });
      queryClient.setQueryData([QUERY_KEY_DATAAPP_BY_ID, dataAppId], data);
    }
  });
};

export default useSaveDataAppMutation;
