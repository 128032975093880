// Packages
import { useDispatch, useSelector } from "react-redux";
import { filter, map, some, toLower } from "lodash";

// Stores
import {
  resetSelectedNodes as resetSelectedNodesStore,
  toggleNodeSelection
} from "stores/dagSlice";
import { RootState } from "stores/store";

// Types
import { NodeTypes } from "projectsModule/utils";
import { NodeData } from "src/types";

interface IProps {
  data?: NodeData;
}

const useNodeMultiSelection = (props?: IProps) => {
  const { data } = props || {};

  const dispatch = useDispatch();
  const selectedNodes = useSelector((state: RootState) => state.dag.selectedNodes);

  const onMultiSelection = () => {
    if (!data || !data?.isDefaultScenario || !!data?.isJobCanvas) return;

    dispatch(toggleNodeSelection({ node: data }));
  };

  const isNodeSelected = () => {
    if (!data) return;
    return some(selectedNodes, { id: data.id });
  };

  const getSelectedNodesByType = ({
    type,
    getByKey
  }: { type?: NodeTypes; getByKey?: string } = {}) => {
    // Filter nodes by type
    const filteredNodes = filter(selectedNodes, (item) => toLower(item?.type) === toLower(type));

    // If getByKey is provided, map the filtered nodes to that key
    return getByKey ? map(filteredNodes, getByKey) : filteredNodes;
  };

  const resetSelectedNodes = () => dispatch(resetSelectedNodesStore());

  return {
    onMultiSelection,
    selectedNodes,
    getSelectedNodesByType,
    isNodeSelected,
    resetSelectedNodes
  };
};

export default useNodeMultiSelection;
