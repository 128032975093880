import React, { useMemo } from "react";
import { Box } from "@material-ui/core";

import { RenderText } from "components/custom";
import { NodeTypes, projectRegExpLiteralNotations } from "src/pages/private/ProjectsModule/utils";

import { useDrawerStore } from "../../../stores/zustand/stores";
import { sideComponentSetter } from "../../../stores/zustand/stores.selectors";
import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import DestinationReadonly from "src/pages/private/ProjectsModule/pages/Dag/components/Nodes/Destination/DestinationReadonly";
import DestinationDetails from "pages/private/ProjectsModule/pages/OutputDataset/components/DestinationDetails";
import NewThemeWrapper from "src/styles/NewThemeWrapper";
import SourceDetails from "pages/private/ProjectsModule/pages/OutputDataset/components/SourceDetails";
import useEntities from "hooks/api/entities/useEntities";

import { useStyles as useDrawerStyles } from "./components/styling";

import _, { toLower } from "lodash";

import CommonLoader from "src/components/CommonLoader";
import { generatePath } from "react-router-dom";
import { WebPaths } from "src/routing/routes";

const DestinationModal = ({ data }: $TSFixMe) => {
  const { drawerContent, content } = useDrawerStyles();

  const isJobCanvasPath = useMemo(
    () => projectRegExpLiteralNotations.jobCanvas.test(location.pathname),
    [location.pathname]
  );

  const setSideComponent = useDrawerStore(sideComponentSetter);

  const { data: entity, isLoading } = useEntities({
    id: data.id,
    scenarioId: data?.scenarioId,
    ...(!!data?.isJobCanvas ? { jobRunId: data?.jobProps?.jobRunId } : {}),
    options: { refetchOnMount: true }
  });

  const getNavigatePath = () => {
    if (!!data?.jobProps) {
      return generatePath(`${WebPaths.JobRoutes}${WebPaths.JobDataRoutes}`, {
        projectId: data?.projectId,
        jobId: data?.jobProps?.jobId,
        scenarioId: data?.scenarioId,
        jobRunId: data?.jobProps?.jobRunId,
        entityId: data.id,
        section: "data"
      });
    } else {
      return generatePath(WebPaths.ViewData, {
        projectId: data?.projectId,
        scenarioId: data?.scenarioId,
        entityId: data.id,
        section: "data"
      });
    }
  };

  const isUnbuilt = useMemo(() => {
    return !entity?.status || !["BUILT"].includes(entity?.status);
  }, [entity?.status]);

  const onClose = () => {
    setSideComponent({
      sideComponent: null,
      sideComponentProps: null
    });
  };

  return (
    <NewThemeWrapper>
      <Box className={drawerContent}>
        <SubTopNavBarWrapper
          variant="drawer"
          onDrawerClose={onClose}
          subTopNavBarLeftSection={{
            component: (
              <>
                <DestinationReadonly data={data} />
                <RenderText
                  color="textSecondary"
                  isOverflowTooltip
                  linkTo={getNavigatePath()}
                  data-testid="sidebar-entity-name">
                  {data?.label}
                </RenderText>
              </>
            )
          }}
        />

        <div className={content} style={{ marginTop: 44 }}>
          {isLoading ? (
            <CommonLoader />
          ) : (
            <>
              {(!!data?.jobProps ? !!isJobCanvasPath : true) ? (
                <>
                  {entity && (
                    <DestinationDetails
                      entity={entity}
                      scenarioId={data?.scenarioId}
                      loading={isLoading}
                      disableExport={isUnbuilt}
                      isDestinationModal
                      isTextInput={toLower(data?.type) === toLower(NodeTypes.File)}
                      jobProps={data?.jobProps}
                    />
                  )}
                </>
              ) : (
                !!data?.entityDSDetails?.id &&
                data?.entityDSDetails?.purpose === "IMPORT" && (
                  <SourceDetails setSideComponent={setSideComponent} data={data} />
                )
              )}
            </>
          )}
        </div>
      </Box>
    </NewThemeWrapper>
  );
};

export default DestinationModal;
