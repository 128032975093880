import React, { useState } from "react";
import _ from "lodash";

import { Grid, makeStyles } from "@material-ui/core";
import { TextIconV2 } from "icons/NewUX/TextIconV2";
import AIGuideTextRow from "../../common/AIGuideTextRow";

const useStyles = makeStyles({
  textIcon: {
    height: "32px",
    width: "32px"
  },
  collapsedText: {
    "& > *": {
      marginBottom: "0px !important"
    }
  },
  title: {
    textWrap: "wrap !important",
    wordBreak: "break-word",
    fontWeight: 400,
    color: "#515151",
    fontSize: "14px"
  },
  codeRow: {
    padding: "0px 32px 0px 12px",
    width: "inherit",
    gap: "20px",
    flexWrap: "nowrap"
  },
  entityIcon: {
    height: "32px",
    width: "24px !important",
    minWidth: "24px"
  },
  modelIcon: {
    width: "24px",
    minWidth: "24px",
    background: "#fff",
    borderRadius: "50%",
    height: "24px",
    flexWrap: "nowrap",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
  },
  chartIcon: {
    height: "32px",
    width: "24px !important"
  }
});

const GENERIC_ERROR_RESPONSE = "An invalid output is generated. Only chart output is supported";
const EditChartNonChartOutput = () => {
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState(false);

  const icon = (
    <Grid
      container
      item
      direction="column"
      alignItems="flex-start"
      justifyContent="center"
      wrap="nowrap"
      className={classes.textIcon}>
      <TextIconV2 />
    </Grid>
  );

  return (
    <Grid container direction="column" wrap="nowrap">
      {!isExpanded ? (
        <Grid className={classes.collapsedText}>
          <AIGuideTextRow
            userInput={GENERIC_ERROR_RESPONSE}
            color={"#FFDBDD"}
            hoverBgColor={"#FFDBDD"}
            textColor={"#515151"}
            isError={true}
            onExpand={() => setIsExpanded(true)}
            canCopy={false}
            noPadding
            disableMarkdownPadding
            isMarkDown
            icon={icon}
          />
        </Grid>
      ) : (
        <Grid container direction="column" wrap="nowrap">
          <AIGuideTextRow
            width="calc(100% - 134px)"
            color={"#FFDBDD"}
            hoverBgColor="#4646b5"
            userInput={GENERIC_ERROR_RESPONSE}
            isError={true}
            textColor="#003656"
            copyTitle="Copy Answer"
            canCopy={false}
            isMarkDown
            noPadding
            onCollapse={() => setIsExpanded(false)}
            icon={icon}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default React.memo(EditChartNonChartOutput);
