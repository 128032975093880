import { useMutation, UseMutationResult } from "@tanstack/react-query";

import {
  EXPORT_DATASET_SUCCESS_MSG,
  EXPORT_TEXT_INPUT_SUCCESS_MSG
} from "pages/private/ProjectsModule/pages/OutputDataset/utils/OutputDataset.constants";
import { handleResponse, postAPIWithRethrow } from "services/Apis/Apis.service";

interface IVariables {
  id: string;
  scenarioId: string;
}

export const EXPORT_ENTITY_MUTATION_KEY = "export-entity-connectors";

const useExportEntityConnectors = (
  id: string,
  scenarioId: string,
  isTextInput?: boolean
): UseMutationResult<unknown, unknown, IVariables, unknown> => {
  return useMutation({
    mutationKey: [EXPORT_ENTITY_MUTATION_KEY, id, scenarioId],
    mutationFn: async ({ id, scenarioId }) => {
      const results = await postAPIWithRethrow(`/v2/entities/${id}/sync`, {
        id,
        scenarioId
      });

      return results;
    },
    onSuccess: () => {
      handleResponse({
        successMessage: !!isTextInput ? EXPORT_TEXT_INPUT_SUCCESS_MSG : EXPORT_DATASET_SUCCESS_MSG
      });
    }
  });
};

export default useExportEntityConnectors;
