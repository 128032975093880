import React from "react";
import { Tooltip } from "@material-ui/core";

import ReactJsIcon from "icons/NewUX/ReactJsIcon";
import StreamlitIcon from "icons/NewUX/StreamlitIcon";
import { DataAppDtoAppTypeEnum } from "@rapidcanvas/rc-api-core";

interface IProps {
  appType: DataAppDtoAppTypeEnum;
}

const DataAppTypeIcon: React.FC<IProps> = (props) => {
  const { appType } = props;

  const isStreamlit = appType === DataAppDtoAppTypeEnum.Streamlit;

  return (
    <Tooltip title={isStreamlit ? "Streamlit" : "React JS"}>
      <span>{isStreamlit ? <StreamlitIcon /> : <ReactJsIcon />}</span>
    </Tooltip>
  );
};

export default DataAppTypeIcon;
