// Packages
import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { Id, toast } from "react-toastify";

// Utils
import api from "services/AxiosClient/AxiosClient";
import envRelaunchNotification from "environmentsModule/utils/environmentRelaunch.helpers";

// Open API
import { EnvDto } from "@rapidcanvas/rc-api-core";

// Store
import { resetEnvironmentStatus } from "src/stores/environmentsSlice";

interface IPayload {
  id?: string;
  environment?: EnvDto;
}

const useRelaunchEnvironment = (): UseMutationResult<EnvDto, unknown, IPayload, unknown> => {
  let toastId: Id | undefined;

  const dispatch = useDispatch();

  return useMutation({
    mutationFn: async ({ id, environment }: IPayload) => {
      if (!id || !environment) {
        throw "Invalid payload!";
      }

      toastId = envRelaunchNotification(Number(environment?.shutdownStrategy?.inactivityInHours));

      return await api.fetchResponse(
        async () => await api.EnvControllerApi.updateEnv(id, environment, true, true, true)
      );
    },
    onError: () => !!toastId && toast.dismiss(toastId),
    onSettled: (__1, __2, payload) => {
      dispatch(resetEnvironmentStatus({ id: payload.id! }));
    }
  });
};

export default useRelaunchEnvironment;
