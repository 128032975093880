import { useMemo } from "react";

import { schemaOptionsSupportedFileTypes, FileUploadStatuses } from "../utils/Dataset.constants";

import useStoreSelectors from "./useStoreSelectors";

const useFilesObserver = () => {
  // Stores - STARTS >>
  const { datasetFilesStore } = useStoreSelectors();
  // << ENDS - Stores

  // Observable facets - STARTS >>
  const isFilesSelected = useMemo(() => (datasetFilesStore || [])?.length > 0, [datasetFilesStore]);

  const isProcessFilesWithSchemaOptions = useMemo(() => {
    if (!isFilesSelected) {
      return false;
    }

    return (Array.isArray(datasetFilesStore) ? datasetFilesStore : [])?.every(
      (eachFile: $TSFixMe) =>
        schemaOptionsSupportedFileTypes.includes(eachFile?.type?.toLowerCase())
    );
  }, [datasetFilesStore]);

  const isFilesUploadInitiated = useMemo(() => {
    if (!isFilesSelected) {
      return false;
    }

    return (Array.isArray(datasetFilesStore) ? datasetFilesStore : [])?.some(
      (eachFile: $TSFixMe) => eachFile?.status !== FileUploadStatuses.Stage
    );
  }, [datasetFilesStore]);

  const isFilesUploading = useMemo(() => {
    if (!isFilesSelected) {
      return false;
    }

    return (Array.isArray(datasetFilesStore) ? datasetFilesStore : [])?.some(
      (eachFile: $TSFixMe) => {
        let thisIsFilesUploading: boolean = false;

        // if (schemaOptionsSupportedFileTypes.includes(eachFile?.type?.toLowerCase())) {
        thisIsFilesUploading = [
          FileUploadStatuses.Uploading,
          FileUploadStatuses.EntityCreated
        ].includes(eachFile?.status);
        // } else {
        //   thisIsFilesUploading = [
        //     FileUploadStatuses.Uploading,
        //     FileUploadStatuses.EntityCreated,
        //     FileUploadStatuses.SignedUrl,
        //     FileUploadStatuses.Processing
        //   ].includes(eachFile?.status);
        // }

        return thisIsFilesUploading;
      }
    );
  }, [datasetFilesStore]);

  const isFilesUploadProcessing = useMemo(() => {
    if (!isFilesSelected) {
      return false;
    }

    return (Array.isArray(datasetFilesStore) ? datasetFilesStore : [])?.some((eachFile: $TSFixMe) =>
      [FileUploadStatuses.Processing].includes(eachFile?.status)
    );
  }, [datasetFilesStore]);

  // @DEPRECATED
  // const isFilesUploading = useMemo(() => {
  //   if (!isFilesSelected) {
  //     return false;
  //   }

  //   return (Array.isArray(datasetFilesStore) ? datasetFilesStore : [])?.some(
  //     (eachFile: $TSFixMe) =>
  //       [
  //         FileUploadStatuses.Uploading,
  //         FileUploadStatuses.EntityCreated,
  //         FileUploadStatuses.SignedUrl,
  //         FileUploadStatuses.Processing
  //       ].includes(eachFile?.status)
  //   );
  // }, [datasetFilesStore]);

  const isFilesDeleting = useMemo(() => {
    if (!isFilesSelected) {
      return false;
    }

    return (Array.isArray(datasetFilesStore) ? datasetFilesStore : [])?.some(
      (eachFile: $TSFixMe) => eachFile?.status === FileUploadStatuses.Deleting
    );
  }, [datasetFilesStore]);

  const isFilesWithSignedUrl = useMemo(() => {
    if (!isFilesSelected) {
      return false;
    }

    return (Array.isArray(datasetFilesStore) ? datasetFilesStore : [])?.every(
      (eachFile: $TSFixMe) => eachFile?.status === FileUploadStatuses.SignedUrl
    );
  }, [datasetFilesStore]);

  const isFilesUploadDone = useMemo(() => {
    if (!isFilesSelected) {
      return false;
    }

    return (Array.isArray(datasetFilesStore) ? datasetFilesStore : [])?.every(
      (eachFile: $TSFixMe) => eachFile?.status === FileUploadStatuses.Success
    );
  }, [datasetFilesStore]);

  const isFilesUploadFulfilled = useMemo(() => {
    if (!isFilesSelected) {
      return false;
    }

    return (Array.isArray(datasetFilesStore) ? datasetFilesStore : [])?.every(
      (eachFile: $TSFixMe) =>
        [FileUploadStatuses.Success, FileUploadStatuses.Failed].includes(eachFile?.status)
    );
  }, [datasetFilesStore]);
  // << ENDS - Observable facets

  return {
    isFilesSelected,
    isProcessFilesWithSchemaOptions,
    isFilesUploadInitiated,
    isFilesUploading,
    isFilesUploadProcessing,
    isFilesDeleting,
    isFilesWithSignedUrl,
    isFilesUploadDone,
    isFilesUploadFulfilled
  };
};

export default useFilesObserver;
