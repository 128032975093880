import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

import { getAPIWithRethrow } from "services/Apis/Apis.service";

const QUERY_KEY_ENTITIES = "query-key-entities";

export interface IEntity {
  built: number;
  created: number;
  creator: string;
  dataSourceId: string;
  dataSourceName: string;
  dataSourceOptions: string;
  dataSourceType: string;
  description: string;
  displayName: string;
  entityMeta: Record<string, any>;
  id: string;
  name: string;
  numberOfSegments: number;
  positionX: number;
  positionY: number;
  projectId: string;
  scenarioStepId: string;
  segments: string[];
  status: string;
  markdown: string;
  syncDataSourceId: string;
  syncDataSourceName: string;
  syncDataSourceOptions: Record<string, any>;
  updated: number;
}

interface EntitiesProps extends UseQueryOptions<$TSFixMe> {
  id: string;
  projectId?: string;
  scenarioId?: string;
  jobRunId?: string;
  options?: UseQueryOptions<IEntity>;
}

const useEntities = (props: EntitiesProps): UseQueryResult<IEntity> => {
  const {
    id,
    projectId,
    scenarioId,
    jobRunId,

    // useQuery options
    enabled,
    ...options
  } = props;

  return useQuery({
    queryKey: [QUERY_KEY_ENTITIES, projectId, scenarioId, id, jobRunId],
    queryFn: async () => {
      const results = await getAPIWithRethrow(
        `/v2/entities?id=${id}${projectId ? `&projectId=${projectId}` : ""}${
          scenarioId ? `&scenarioId=${scenarioId}` : ""
        }${jobRunId ? `&projectRunEntryId=${jobRunId}` : ""}`
      );

      return results[0];
    },
    refetchOnMount: true,
    enabled: enabled ?? !!id,
    ...options
  });
};

export default useEntities;
export { QUERY_KEY_ENTITIES };
