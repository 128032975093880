import { SearchRequestDtoSearchComponentsEnum } from "@rapidcanvas/rc-api-core";
import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { useNavigate, generatePath } from "react-router-dom";
import { replace } from "lodash";

import api from "services/AxiosClient/AxiosClient";
import { RecipeTypesPathMapping } from "src/pages/private/ProjectsModule/utils";
import { Section } from "src/pages/ViewData/ViewDataRoutes";
import { WebPaths } from "src/routing/routes";
import { useCanvasStore } from "stores/zustand/stores";

interface IPayload {
  id: string;
  name: string;
  projectId?: string;
  type: SearchRequestDtoSearchComponentsEnum;
}

const useNavigateFromSearchComponent = (): UseMutationResult<null, unknown, IPayload, unknown> => {
  const navigate = useNavigate();
  const { setNodeToFocus } = useCanvasStore();

  return useMutation({
    mutationFn: async ({ id, type, name, projectId }) => {
      if (!id) {
        throw "Invalid payload!";
      }

      switch (type) {
        case SearchRequestDtoSearchComponentsEnum.Artifact:
          navigate(
            projectId
              ? generatePath(WebPaths.ProjectArtifactsDetails, {
                  artifactName: name,
                  projectId
                })
              : generatePath(WebPaths.ArtifactsDetails, { artifactName: name })
          );
          return null;

        case SearchRequestDtoSearchComponentsEnum.Chart: {
          const data = await api.fetchResponse(
            async () => await api.EntityControllerApi.findEntities(id)
          );

          const first = data?.[0];
          const scenarioId = first.scenarioId;
          if (first.projectId && scenarioId) {
            navigate(
              generatePath(WebPaths.Charts, {
                projectId: first.projectId,
                chartId: id,
                scenarioId
              })
            );
          }
          return null;
        }

        case SearchRequestDtoSearchComponentsEnum.Connector:
          navigate(generatePath(WebPaths.DataConnector, { id }));
          return null;

        case SearchRequestDtoSearchComponentsEnum.Dataapp:
          navigate(generatePath(WebPaths.DataAppDashboard, { dataAppName: name }));
          return null;

        case SearchRequestDtoSearchComponentsEnum.Dataset:
          const data = await api.fetchResponse(
            async () => await api.EntityControllerApi.findEntities(id)
          );

          const first = data?.[0];
          const scenarioId = first.scenarioId;
          if (first.projectId && scenarioId) {
            navigate(
              generatePath(WebPaths.ViewData, {
                projectId: first.projectId,
                entityId: id,
                scenarioId,
                section: Section.data
              })
            );
          }
          return null;

        case SearchRequestDtoSearchComponentsEnum.Environment:
          navigate(generatePath(WebPaths.EnvironmentConfig, { envId: id }));
          return null;

        case SearchRequestDtoSearchComponentsEnum.Model:
          navigate(
            projectId
              ? generatePath(WebPaths.ProjectModelsDetails, { modelName: name, projectId })
              : generatePath(WebPaths.ModelDetails, { modelName: name })
          );

          return null;

        case SearchRequestDtoSearchComponentsEnum.PredictionScheduler: {
          const data = await api.fetchResponse(
            async () => await api.ProjectRunControllerApi.findProjectRunById(id)
          );

          if (data.projectId) {
            navigate(
              generatePath(`${WebPaths.JobRoutes}${WebPaths.JobId}`, {
                projectId: data.projectId,
                jobId: id
              })
            );
          }

          return null;
        }

        case SearchRequestDtoSearchComponentsEnum.Project:
          navigate(generatePath(WebPaths.Dag, { projectId: id }));
          return null;

        case SearchRequestDtoSearchComponentsEnum.Recipe: {
          const data = await api.fetchResponse(
            async () => await api.DfsRunConfigGroupControllerV2Api.findGroups(id)
          );

          const first = data?.[0];
          const recipeType = first.recipeType;
          const scenarioId = first.scenarioId;

          if (recipeType) {
            const url = replace(
              WebPaths.StandardRecipeDataContainer,
              RecipeTypesPathMapping.STANDARD,
              RecipeTypesPathMapping[recipeType]
            );
            if (url && scenarioId) {
              navigate(
                generatePath(url, {
                  projectId: first.projectId,
                  scenarioId,
                  groupId: id
                })
              );
            }
          }
          return null;
        }

        case SearchRequestDtoSearchComponentsEnum.Scheduler: {
          const data = await api.fetchResponse(
            async () => await api.ProjectRunControllerApi.findProjectRunById(id)
          );

          if (data.projectId) {
            navigate(
              generatePath(`${WebPaths.JobRoutes}${WebPaths.JobId}`, {
                projectId: data.projectId,
                jobId: id
              })
            );
          }

          return null;
        }

        case SearchRequestDtoSearchComponentsEnum.VectorStore: {
          const data = await api.fetchResponse(
            async () => await api.EntityControllerApi.findEntities(id)
          );

          const first = data?.[0];
          const scenarioId = first.scenarioId;
          if (first.projectId && scenarioId) {
            setNodeToFocus(name);
            navigate(
              generatePath(`${WebPaths.Dag}${WebPaths.Canvas}`, {
                projectId: first.projectId,
                scenarioId
              })
            );
          }
          return null;
        }

        case SearchRequestDtoSearchComponentsEnum.File: {
          const data = await api.fetchResponse(
            async () => await api.EntityControllerApi.findEntities(id)
          );

          const first = data?.[0];
          const scenarioId = first.scenarioId;
          if (first.projectId && scenarioId) {
            navigate(
              generatePath(WebPaths.ViewFile, {
                projectId: first.projectId,
                fileId: id,
                scenarioId
              })
            );
          }
          return null;
        }

        default:
          return null;
      }
    }
  });
};

export default useNavigateFromSearchComponent;
