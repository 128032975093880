import React, { useMemo, useState } from "react";

// Packages
import { useQueryClient } from "@tanstack/react-query";
import { includes, isEmpty, map, pick, some, toUpper } from "lodash";

// MUI
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import Typography from "@material-ui/core/Typography";
import { useTheme } from "@material-ui/core/styles";

// Icons
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";

// Open API
import { BulkDeleteNodesDto } from "@rapidcanvas/rc-api-core";

// Utils
import { ToastTypes, toastWrapper } from "services/ToastClient/toastWrapper";

// Hooks
import { useDeleteNodes, UseGetProjectCanvasQueryKeys } from "src/hooks/api";
import useGetDeletingNodes from "./useGetDeletingNodes";

// Components
import { Modal } from "src/components/custom";
import CommonLoader from "components/CommonLoader";
import DeletingNodesList from "./NodesList";

// Types
import { NodeData } from "src/types";

// Constants
import { ConfirmDeleteNodesPromptDetails, DeleteNodesHelperText } from "./DeleteNodes.constants";

interface IProps {
  projectId?: string;
  scenarioId?: string;
  selectedNodes: NodeData[];
  resetSelectedNodes: () => void;
  resetConfirmDeleteNodes: () => void;
}

const DeleteNodesModal: React.FC<IProps> = (props) => {
  const { projectId, scenarioId, selectedNodes, resetSelectedNodes, resetConfirmDeleteNodes } =
    props;

  const theme = useTheme();
  const queryClient = useQueryClient();

  // This is to wait for all awaited actions to complete after the delete mutation is fulfilled.
  const [isDeleting, setIsDeleting] = useState(false);

  const connectorDatasetsExists = useMemo(
    () => some(selectedNodes, (node: NodeData) => !isEmpty(node?.entityDSDetails)),
    [selectedNodes]
  );

  const { isLoadingDeletingNodes, isErrorGettingDeletingNodes, deletingNodesData } =
    useGetDeletingNodes({ selectedNodes });

  const {
    isLoading: isDeleteNodesMutationLoading,
    mutateAsync: deleteNodesMutation,
    reset: resetDeleteNodesMutation
  } = useDeleteNodes({ projectId, connectorDatasetsExists });

  const onNodesDeleted = async () => {
    if (!!projectId && !!scenarioId) {
      await queryClient.invalidateQueries([
        UseGetProjectCanvasQueryKeys.ProjectCanvas,
        projectId,
        scenarioId
      ]);
    }

    toastWrapper({ type: ToastTypes.Success, content: DeleteNodesHelperText.NodesDeleted });
    resetSelectedNodes();

    setIsDeleting(() => false);
    resetConfirmDeleteNodes();
  };

  const deleteNodes = async () => {
    setIsDeleting(() => true);
    resetDeleteNodesMutation();

    const deletingNodes: BulkDeleteNodesDto[] = map(selectedNodes, (item) => ({
      ...pick(item, ["id"]), // Get 'id'
      stepType: toUpper(item.type) // Convert 'type' to uppercase
    }));

    await deleteNodesMutation(
      { deletingNodes },
      {
        onSuccess: onNodesDeleted,
        onError: () => {
          setIsDeleting(() => false);
          resetConfirmDeleteNodes();
        }
      }
    );
  };

  return (
    <Modal
      open
      title={ConfirmDeleteNodesPromptDetails.title}
      onClose={(_, reason: string) => {
        if (!includes(["escapeKeyDown", "backdropClick"], reason)) {
          resetConfirmDeleteNodes();
        }
      }}
      onSubmit={deleteNodes}
      cancelLabel={
        !!isErrorGettingDeletingNodes
          ? DeleteNodesHelperText.CloseActionLabel
          : ConfirmDeleteNodesPromptDetails.cancelLabel
      }
      submitLabel={ConfirmDeleteNodesPromptDetails.submitLabel}
      isCancelDisabled={!!isDeleteNodesMutationLoading || !!isDeleting}
      isSubmitDisabled={!!isLoadingDeletingNodes || !!isDeleteNodesMutationLoading || !!isDeleting}
      isSubmitting={!!isDeleteNodesMutationLoading || !!isDeleting}
      hideSubmitAction={isErrorGettingDeletingNodes}
      hideCloseIcon>
      {!!isLoadingDeletingNodes ? (
        <CommonLoader />
      ) : isEmpty(deletingNodesData) ? (
        <Alert severity="info" style={{ justifyContent: "center" }}>
          {DeleteNodesHelperText.NoDataFound}
        </Alert>
      ) : (
        <Grid container direction="column" style={{ rowGap: theme.spacing(1) }}>
          <Grid item>
            <Grid
              container
              wrap="nowrap"
              alignItems="flex-start"
              style={{ columnGap: theme.spacing(1) }}>
              <Grid item>
                <ReportProblemOutlinedIcon
                  fontSize="small"
                  style={{ marginBottom: theme.spacing(0.75) }}
                  data-testid="deleteNodeModalWarnIcon"
                />
              </Grid>
              <Grid item>
                <Typography
                  style={{ overflowWrap: "anywhere" }}
                  component="div"
                  variant="body2"
                  data-testid="deleteNodeModalMessageLine1">
                  {ConfirmDeleteNodesPromptDetails.messageLine1}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <DeletingNodesList deletingNodesData={deletingNodesData} />
          </Grid>

          <Grid item>
            <Typography
              style={{ overflowWrap: "anywhere", fontStyle: "italic" }}
              component="div"
              variant="caption"
              data-testid="deleteNodeModalMessageLine2">
              {ConfirmDeleteNodesPromptDetails.messageLine2}
            </Typography>
          </Grid>
        </Grid>
      )}
    </Modal>
  );
};

export default DeleteNodesModal;
