import React, { useState } from "react";
import { useNavigate, useParams } from "react-router";

import { useGetRecipe } from "src/hooks/api";

import { AutoMLNotebookHeader } from "./AutoMLNoteBook/AutoMLNotebookHeader/AutoMLNotebookHeader";
import AutoMLNotebookIframeContainer from "./AutoMLNoteBook/AutoMLNotebookContainer/AutoMLNotebookIframeContainer";
import useAutoMLNotebookUrl from "src/hooks/api/transforms/useAutoMLNotebookUrl";
import { first, get } from "lodash";
import CommonLoader from "src/components/CommonLoader";

interface IProps {
  recipeType: "CODE" | "AUTO-ML" | "STANDARD" | "API-CONNECTOR";
}

const AutoMLNotebookContainer: React.FC<IProps> = ({ recipeType }) => {
  const { groupId, scenarioId, jobRunId, transformId } = useParams();
  const [iframeLoading, setIframeLoading] = useState(true);
  const [key, setKey] = useState(Date.now().toString());
  const { isLoading, data, isFetching } = useGetRecipe({
    recipeId: groupId,
    scenarioId,
    jobRunId,
    options: { refetchOnMount: true }
  });

  const recipeRunconfigId =
    recipeType === "STANDARD" ? transformId : get(first(data?.recipe?.runConfigs), "id");

  const templateUrlResult = useAutoMLNotebookUrl(recipeRunconfigId, scenarioId, {
    enabled: !!recipeRunconfigId,
    refetchOnMount: true
  });
  const navigate = useNavigate();

  if (!groupId) {
    navigate(-1);
  }

  return isLoading || isFetching ? (
    <CommonLoader />
  ) : (
    <div>
      <AutoMLNotebookHeader
        recipe={data?.recipe}
        templateUrlResult={templateUrlResult}
        iframeLoading={iframeLoading}
        setIframeLoading={setIframeLoading}
        setKey={setKey}
        recipeType={recipeType}
        recipeRunconfigId={recipeRunconfigId}
      />
      <AutoMLNotebookIframeContainer
        templateUrlResult={templateUrlResult}
        iframeLoading={iframeLoading}
        setIframeLoading={setIframeLoading}
        key={key}
      />
    </div>
  );
};

export default AutoMLNotebookContainer;
