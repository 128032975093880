import _ from "lodash";
import { useRef } from "react";
import { UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import { DataAppType } from "pages/DataApps/DataApps.type";
import { DATAAPP_INTERVAL_IN_SEC } from "./useDataAppStatusById";
import { DataAppDtoLaunchStatusEnum } from "@rapidcanvas/rc-api-core";
import { QUERY_KEY_DATAAPPS_ACROSS_TENANTS } from "./useGetDataAppAcrossTenants";
import { QUERY_KEY_DATA_APPS } from "./useGetDataApps";
import { getAPIWithRethrow, handleResponse, putAPIWithRethrow } from "services/Apis/Apis.service";
import { invalidateDataAppQueries } from "./useLaunchDataAppMutation";
import { useDataAppStore } from "stores/zustand/stores";

type Props = {
  dataAppId: string;
  envType?: string;
  TTLChanged: boolean;
  dataApp: DataAppType;
  envChanged: boolean;
  setStatus: (status: DataAppDtoLaunchStatusEnum) => void;
  refetch: () => Promise<void>;
};

export const useSaveDataAppEnvMutation = (): UseMutationResult<any, unknown, Props, unknown> => {
  const queryClient = useQueryClient();
  const fetchingRef = useRef<boolean>(false);
  const { dataAppName } = useParams();

  const [updatingDataApps, setUpdatingDataApps] = useDataAppStore((state) => [
    state.updatingDataApps,
    state.setUpdatingDataApps
  ]);

  return useMutation({
    mutationFn: async ({ dataAppId, envType, TTLChanged, dataApp, envChanged, setStatus }) => {
      setUpdatingDataApps(
        _.map(updatingDataApps, (dataApp) =>
          dataApp.id === dataAppId ? { ...dataApp, loading: true } : dataApp
        )
      );

      if (TTLChanged) {
        await putAPIWithRethrow(`/dataapps/${dataAppId}`, dataApp);
        await queryClient.invalidateQueries([QUERY_KEY_DATA_APPS]);
        await queryClient.invalidateQueries([QUERY_KEY_DATAAPPS_ACROSS_TENANTS, dataAppName]);
        handleResponse({ successMessage: `Shutdown time for dataApp updated successfully.` });
      }

      if (envChanged) {
        await putAPIWithRethrow(`/dataapps/${dataAppId}/env`, { envType });
        await queryClient.invalidateQueries([QUERY_KEY_DATA_APPS]);
        await queryClient.invalidateQueries([QUERY_KEY_DATAAPPS_ACROSS_TENANTS, dataAppName]);
        handleResponse({ successMessage: `DataApp environment updated successfully.` });
        let interval: NodeJS.Timeout;

        interval = setInterval(async () => {
          if (!fetchingRef.current) {
            try {
              fetchingRef.current = true;
              const response: DataAppType = await getAPIWithRethrow(
                `/dataapps/by-id/${dataAppId}`,
                undefined,
                false
              );
              fetchingRef.current = false;
              const latestStatus = response?.launchStatus;
              setStatus(latestStatus);
              invalidateDataAppQueries(queryClient, dataAppId, latestStatus, dataAppName);
              if (
                _.includes(
                  [DataAppDtoLaunchStatusEnum.Failure, DataAppDtoLaunchStatusEnum.Running],
                  latestStatus
                )
              ) {
                clearInterval(interval);
              }
            } catch {
              clearInterval(interval);
            }
          }
        }, DATAAPP_INTERVAL_IN_SEC * 1000);
      }

      return null;
    },
    onError: async () => {
      await queryClient.invalidateQueries([QUERY_KEY_DATA_APPS]);
      await queryClient.invalidateQueries([QUERY_KEY_DATAAPPS_ACROSS_TENANTS, dataAppName]);
    },
    onSettled: (__, ___, { dataAppId, refetch }) => {
      setUpdatingDataApps(
        _.map(updatingDataApps, (dataApp) =>
          dataApp.id === dataAppId ? { ...dataApp, loading: false } : dataApp
        )
      );
      refetch();
    }
  });
};
