import {
  getAPI,
  getAPIWithRethrow,
  postAPI,
  postAPIWithRethrow,
  putAPIWithRethrow,
  deleteAPIWithRethrow
} from "../Apis.service";

// Data connectors get APIs - STARTS >>
export const getDataConnectors = async (
  shouldDispatchEvent: boolean = true,
  maskErrorMessage: string = ""
) => await getAPI("/v2/data-source/third-party-sources", {}, shouldDispatchEvent, maskErrorMessage);
// << ENDS - Data connectors get APIs

// Data source APIs - STARTS >>
// Get >>
export const getDataSources = async () => await getAPI("/v2/data-source");

export const syncDataSource = async (id: string) =>
  await postAPIWithRethrow(`/v2/data-source/${id}/sync`, { id });

export const getDataSourceWithRethrow = async ({ id }: $TSFixMe = {}) =>
  await getAPIWithRethrow(`/v2/data-source/${id}`);
// << Get

// Create >>
export const createDataSourceWithRethrow = async ({ payload }: $TSFixMe = {}) =>
  await postAPIWithRethrow("/v2/data-source", payload);
// << Create

// Update >>
export const updateDataSourceWithRethrow = async ({ payload = {} }: $TSFixMe = {}) =>
  await putAPIWithRethrow(`/v2/data-source`, payload);
// << Update

// Delete >>
export const deleteDataSourceWithRethrow = async ({ id, payload = {} }: $TSFixMe = {}) =>
  await deleteAPIWithRethrow(`/v2/data-source?id=${id}`, payload);
// << Delete
// << ENDS - Data source APIs

export const getDataSourcesSchema = async () => await getAPI("/v2/data-source/schema");

export const getDataSourceFiles = async ({ payload }: $TSFixMe = {}) =>
  await postAPI("/v2/data-source/list-files", payload, false);

export const getDataSourceUsageWithRethrow = async (id: string) =>
  await getAPIWithRethrow(`/v2/data-source/${id}/usage`);

export const getSqlDataSourceSampleDataWithRethrow = async ({ payload }: $TSFixMe = {}) =>
  await postAPIWithRethrow("/v2/data-source/sample-data", payload);
