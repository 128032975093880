import React from "react";
import { useQuery } from "@tanstack/react-query";

import { getAPIWithRethrow, postAPIWithRethrow } from "services/Apis/Apis.service";
import { DataAppType } from "../DataApps.type";

type Props = {
  dataAppName: string;
  tenantName: string;
  token: string | null;
};

type Response =
  | {
      accessUrl: string;
    }
  | { errorMsg: string | React.ReactNode };

export const useGetDataAppDeepLink = ({ dataAppName, tenantName, token }: Props) => {
  return useQuery<Response>({
    queryFn: async () => {
      let tenantId: string = "";
      const dataApps = await getAPIWithRethrow(
        `/dataapps/across-tenants`,
        {
          params: { name: dataAppName }
        },
        false
      );
      if (!dataApps || dataApps.length === 0) {
        return {
          errorMsg: (
            <>
              You do not have access to the DataApp <b>{dataAppName}</b> or the DataApp does not
              exist
            </>
          )
        };
      }

      if (tenantName) {
        const userDetails = await getAPIWithRethrow(`/user`, {}, false);
        const currentTenant = userDetails.tenants.find(
          (tenant: $TSFixMe) => tenant.name === tenantName
        );
        if (!currentTenant) {
          return {
            errorMsg: (
              <>
                You do not have access to the Workspace <b>{tenantName}</b> or the Workspace does
                not exist
              </>
            )
          };
        }
        tenantId = currentTenant.id;
      } else {
        tenantId = dataApps?.[0].tenantId;
      }

      let authToken = token;

      if (!authToken) {
        const switchTokenResponse = await postAPIWithRethrow(
          `/switch`,
          {
            tenantId
          },
          {},
          false
        );
        authToken = switchTokenResponse.token;
      }

      const dataApp =
        dataApps.length === 0
          ? dataApps[0]
          : dataApps.find((dataApp: DataAppType) => dataApp.tenantId === tenantId);

      const accessUrl = `${dataApp.accessUrl}&token=${authToken}`;
      return { accessUrl };
    }
  });
};
