import React, { useEffect, useMemo, useState } from "react";
import _, { get, includes, keyBy, map, some, toLower } from "lodash";
import { useParams, useLocation, useSearchParams } from "react-router-dom";

import { getProjectCanvas } from "services/Apis/wrappers";
import {
  useGetDataSources,
  useGetScenarios,
  useGetVariables,
  useGetJobs,
  useGetJobDestinations,
  useGetJob,
  useGetProjectCanvas
} from "src/hooks/api";
import api from "services/AxiosClient/AxiosClient";
import { useProjectsStore } from "stores/zustand/stores";
import { projectsGetter } from "stores/zustand/stores.selectors";
import { JobContext } from "./JobContext";
import { dataSourcesTypes } from "src/pages/DataSources/utils/DataSources.constants";
import { JobsHelperText } from "../../../utils/Jobs.constants";
import { Model } from "src/hooks/api/projects/useGetAllProjectModels";
import {
  EntityViewDTOViewTypeEnum,
  FindProjectRunsJobTypeEnum,
  FindProjectRunsSubTypeEnum,
  ProjectRunDetailDto
} from "@rapidcanvas/rc-api-core";
import { getComputedVariables } from "../../../utils/Jobs.helpers";

export const PREDICTION_SCHEDULER_MODEL = "predictionSchedulerModel";
export const JOB_TYPE = "type";

const JobContextProvider = (props: $TSFixMe) => {
  const { children } = props || {};

  const { projectId, jobId } = useParams();
  const [searchParams] = useSearchParams();
  const type = searchParams.get(JOB_TYPE) as FindProjectRunsJobTypeEnum | null;

  const location = useLocation();
  useEffect(() => {
    if (type === FindProjectRunsJobTypeEnum.PredictionJob) {
      const model = _.get(location.state, PREDICTION_SCHEDULER_MODEL);
      if (model) {
        setModel(model);
      }
    }
  }, []);

  // Stores - STARTS >>
  const projectsStore = useProjectsStore(projectsGetter);
  const reloadTrigger = useProjectsStore((state) => state.reloadTrigger);
  // << ENDS - Stores

  // States - STARTS >>
  const [dataSourcesData, setDataSourcesData] = useState<$TSFixMe>([]);

  const [jobContextState, setJobContextState] = useState<$TSFixMe>({
    jobName: ""
  });

  const [model, setModel] = useState<Pick<Model, "id" | "name"> | null>(null);

  const [jobRunConfigContextState, setJobRunConfigContextState] = useState<$TSFixMe>({
    isValid: false,
    values: {}
  });

  const [jobParametersContextState, setJobParametersContextState] = useState<$TSFixMe>([]);
  const [jobDestinationsContextState, setJobDestinationsContextState] = useState<$TSFixMe>([]);

  const [defaultJobNames, setDefaultJobNames] = useState<$TSFixMe>([]);

  const [datasetsData, setDatasetsData] = useState<$TSFixMe>([]);
  const [projectCanvasData, setProjectCanvasData] = useState<$TSFixMe>({});

  const [isSaved, setIsSaved] = useState<$TSFixMe>(false);
  // << ENDS - States

  // Query hooks - STARTS >>
  // Queries
  const { isFetched: isDataSourcesFetched, data: dataSourcesQueryData } = useGetDataSources();
  const { data: variablesData, refetch: refetchVariables } = useGetVariables({ projectId });

  useEffect(() => {
    !!reloadTrigger && refetchVariables();
  }, [reloadTrigger]);

  const onGetJobsSucceed = (data: ProjectRunDetailDto[]) => {
    let defaultNames: $TSFixMe = [];
    if ((Array.isArray(data) ? data : [])?.length > 0) {
      defaultNames = data?.reduce((acc: $TSFixMe, item: $TSFixMe) => {
        if (
          item?.dto?.name?.includes(
            jobType === FindProjectRunsJobTypeEnum.PredictionJob
              ? JobsHelperText.PredictionScheduler
              : JobsHelperText?.UntitledJob
          )
        ) {
          acc.push(item?.dto?.name);
        }

        return acc;
      }, []);
    }

    setDefaultJobNames(defaultNames);
  };

  const {
    isLoading: isFetchingJob,
    isSuccess: isJobFetched,
    data: jobData,
    refetch: refetchJob
  } = useGetJob({
    projectId,
    jobId,
    refetchOnMount: true
  });

  const jobType = get(jobData, "jobType") ?? type ?? FindProjectRunsJobTypeEnum.ProjectJob;
  useEffect(() => {
    if (jobData?.modelEntityId && jobType === FindProjectRunsJobTypeEnum.PredictionJob) {
      setModel({
        id: jobData?.modelEntityId,
        name: jobData.modelEntityName ?? ""
      });
    }
  }, [jobData]);

  const { isFetching: isFetchingDefaultJobNames, refetch: refetchJobNames } = useGetJobs({
    projectId,
    jobType,
    subType:
      jobType === FindProjectRunsJobTypeEnum.PredictionJob
        ? FindProjectRunsSubTypeEnum.Scheduler
        : undefined,
    onSuccess: onGetJobsSucceed
  });

  const currentJobId = useMemo(() => {
    return jobId || (jobData?.id as string);
  }, [jobId, jobData?.id]);

  const { data: scenariosData, refetch: refetchScenarios } = useGetScenarios({
    projectId,
    ...(!!currentJobId ? { jobId: currentJobId } : {}),
    keepPreviousData: false,
    refetchOnMount: true
  });

  const defaultScenario = useMemo(
    () => (scenariosData ? scenariosData?.find((scenario: any) => scenario?.default) : undefined),
    [scenariosData]
  );

  const currentScenarioId = useMemo(
    () => jobData?.scenarioId || defaultScenario?.id,
    [jobData?.scenarioId, defaultScenario?.id]
  );

  const { data: jobCanvasData, refetch: refetchDatasets } = useGetProjectCanvas({
    projectId,
    scenarioId: jobRunConfigContextState?.values?.scenario,
    jobProps: { jobId: currentJobId }
  });

  useEffect(() => {
    if ((jobCanvasData?.nodes || [])?.length > 0) {
      setDatasetsData(() => [
        ...(jobCanvasData?.nodes
          ?.filter((node: $TSFixMe) =>
            includes(
              [toLower(EntityViewDTOViewTypeEnum.Entity), toLower(EntityViewDTOViewTypeEnum.File)],
              toLower(node?.type)
            )
          )
          ?.map((eachDataset: $TSFixMe) => ({
            id: eachDataset?.id,
            name: eachDataset?.name,
            type: eachDataset?.type
          })) || [])
      ]);
    }
  }, [jobCanvasData]);

  const {
    data: destinationsData,
    refetch: refetchDestinations,
    isLoading: destinationLoading
  } = useGetJobDestinations({
    jobId: currentJobId!
  });
  // << ENDS - Query hooks

  const project = useMemo(
    () =>
      (projectsStore || [])?.find((eachProject: $TSFixMe) => eachProject?.id === projectId) || {},
    [projectId, projectsStore]
  );

  useEffect(() => {
    if (isDataSourcesFetched) {
      if (dataSourcesQueryData?.length > 0) {
        const dataSourcesTypesImages: $TSFixMe = {};
        dataSourcesTypes?.forEach((eachDataSourceType: $TSFixMe) => {
          dataSourcesTypesImages[eachDataSourceType?.name] = eachDataSourceType?.image;
        });
        const supportedConnectors = _.map(dataSourcesTypes, "name");
        const inHouseConnectors = _.filter(dataSourcesQueryData, ({ dataSourceType }) =>
          _.includes(supportedConnectors, dataSourceType)
        );

        setDataSourcesData(() => [
          ...(inHouseConnectors?.map((eachDataSource: $TSFixMe) => {
            eachDataSource.image = dataSourcesTypesImages[eachDataSource?.dataSourceType];
            return eachDataSource;
          }) || [])
        ]);
      }
    }
  }, [isDataSourcesFetched]);

  useEffect(() => {
    let jobParameters: $TSFixMe = [];
    if (isJobFetched && jobData?.id) {
      setJobContextState(() => ({ ...jobContextState, jobName: jobData?.name }));
      const computedVariables = getComputedVariables(
        jobData?.computedVariables,
        jobData?.variables
      );

      jobParameters =
        Object.keys(computedVariables)?.map((key: $TSFixMe) => ({
          key: key,
          value: computedVariables?.[key]
        })) || [];
    } else {
      jobParameters =
        (variablesData || [])?.map((eachVariable: $TSFixMe) => ({
          key: eachVariable?.name,
          value: eachVariable?.value
        })) || [];
    }

    setJobParametersContextState(() => [...jobParameters]);
  }, [isJobFetched, jobData, variablesData]);

  const getProjectCanvasData = async ({ scenarioId: thisScenarioId }: $TSFixMe = {}) =>
    await getProjectCanvas({
      projectId,
      scenarioId: thisScenarioId || jobRunConfigContextState?.values?.scenario
    });

  useEffect(() => {
    const _ = async () => {
      if (jobType === FindProjectRunsJobTypeEnum.PredictionJob) {
        if (projectId && model?.id && !currentJobId) {
          const response = await api.fetchResponse(
            async () =>
              await api.ProjectsControllerV2Api.getAllModelsWithInputDataSets1(
                projectId,
                model?.id,
                currentScenarioId ?? jobRunConfigContextState?.values?.scenario
              )
          );

          const nodes = response.nodes;
          const hasPredictionNode = some(nodes, (node) => {
            const name = get(node, "displayName") || get(node, "name");
            return name && includes(name, "prediction_output");
          });

          if (hasPredictionNode) {
            const minimalNodes = map(response.nodes, (node) => ({
              id: node.id,
              column: node.column,
              row: node.row
            }));

            const minimalEdges = response.edges?.map((edge) => ({
              id: edge.id,
              source: edge.source,
              target: edge.target
            }));

            const rearrangedResponse = await api.fetchResponse(
              async () =>
                await api.ProjectsControllerV2Api.rearrangeGraph(projectId, {
                  nodes: minimalNodes,
                  edges: minimalEdges
                })
            );

            const updatedNodeIdNodeMap = keyBy(rearrangedResponse.nodes, "id");
            const val = {
              ...response,
              nodes: map(nodes, (node) => {
                const updatedNode = updatedNodeIdNodeMap[node.id];

                return {
                  ...node,
                  position: {
                    x: updatedNode.column * 180,
                    y: updatedNode.row * 180
                  }
                };
              }),
              edges: rearrangedResponse.edges
            };

            setProjectCanvasData(val);
          } else {
            setProjectCanvasData(() => response || {});
          }
        }
      } else {
        const response = await getProjectCanvasData({
          scenarioId: currentScenarioId
        });

        setProjectCanvasData(() => response || {});
      }
    };

    !!currentJobId && !!currentScenarioId && _();
  }, [currentJobId, currentScenarioId, jobType, model?.id]);

  const validateNodes = async () => {
    let isValid = false;

    const thisProjectCanvasData = await getProjectCanvasData();
    if (Object.keys(thisProjectCanvasData || {})?.length > 0) {
      if ((thisProjectCanvasData?.nodes || [])?.length > 0) {
        const thisNodes =
          thisProjectCanvasData.nodes?.filter(
            (eachNode: $TSFixMe) => eachNode?.type === "DFSGROUP"
          ) || [];

        isValid = thisNodes?.length > 0;
      }
    }

    return isValid;
  };

  // Job context value - STARTS >>
  const value = useMemo(
    () => ({
      project,

      dataSourcesData,

      variablesData,
      refetchVariables,

      jobType,
      model,
      setModel,

      jobContextState,
      setJobContextState,
      jobRunConfigContextState,
      setJobRunConfigContextState,
      jobParametersContextState,
      setJobParametersContextState,
      jobDestinationsContextState,
      setJobDestinationsContextState,

      isFetchingDefaultJobNames,
      refetchJobNames,
      defaultJobNames,

      isFetchingJob: isFetchingJob && !!jobId,

      isJobFetched,
      jobData,
      refetchJob,

      scenariosData,
      refetchScenarios,
      defaultScenario,

      datasetsData,
      getProjectCanvasData,
      projectCanvasData,
      validateNodes,

      currentJobId,
      currentScenarioId,

      destinationsData,
      refetchDestinations,
      destinationLoading,

      refetchDatasets,

      isSaved,
      setIsSaved
    }),
    [
      project,

      dataSourcesData,

      jobType,
      model,
      setModel,

      variablesData,
      refetchVariables,

      jobContextState,
      setJobContextState,
      jobRunConfigContextState,
      setJobRunConfigContextState,
      jobParametersContextState,
      setJobParametersContextState,
      jobDestinationsContextState,
      setJobDestinationsContextState,

      isFetchingDefaultJobNames,
      refetchJobNames,
      defaultJobNames,

      isFetchingJob,
      isJobFetched,
      jobData,
      refetchJob,

      scenariosData,
      refetchScenarios,
      defaultScenario,

      datasetsData,
      getProjectCanvasData,
      projectCanvasData,
      validateNodes,

      currentJobId,
      currentScenarioId,

      destinationsData,
      refetchDestinations,
      destinationLoading,

      refetchDatasets,

      isSaved,
      setIsSaved
    ]
  );
  // << ENDS - Job context value

  return <JobContext.Provider value={value}>{children}</JobContext.Provider>;
};

export default JobContextProvider;
