import React, { useEffect } from "react";

// Packages
import { Header, HeaderGroup } from "@tanstack/react-table";
import { Virtualizer } from "@tanstack/react-virtual";
import { map } from "lodash";
import clsx from "clsx";

// MUI
import MuiTableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

// Components
import TableCell from "./TableCell";

// Contexts
import { useTableContext } from "../../context/useTableContext";

// Types
import { TData } from "../../Table.types";

// Styles
import useStyles from "./TableHead.styles";

type Props = {
  isStickyHeader?: true | false | undefined;
  rowVirtualizer: Virtualizer<HTMLDivElement, Element> | null;
  showSummary: boolean;
  isSummaryAvailable: boolean;
  isChartAvailable: boolean;
};

const TableHead = (props: Props) => {
  const { isStickyHeader, rowVirtualizer, showSummary, isSummaryAvailable, isChartAvailable } =
    props || {};

  const { table } = useTableContext();

  const classes = useStyles();

  // To rerender upon changes in dimension of cells in virtual-row
  useEffect(() => {}, [rowVirtualizer]);

  return (
    <MuiTableHead
      className={clsx(classes.root, {
        stickyHeader: isStickyHeader
      })}>
      {map(table?.getHeaderGroups(), (headerGroup: HeaderGroup<TData>, rowIndex: number) => (
        <TableRow key={`theadRow-${rowIndex}`} className={classes.tableRow}>
          {map(headerGroup?.headers, (header: Header<TData, string>, cellIndex: number) => (
            <TableCell
              key={`theadCell-${cellIndex}`}
              header={header}
              showSummary={showSummary}
              isSummaryAvailable={isSummaryAvailable}
              isChartAvailable={isChartAvailable}
            />
          ))}
        </TableRow>
      ))}
    </MuiTableHead>
  );
};

export default TableHead;
