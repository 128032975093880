import React, { useMemo } from "react";

// Packages
import clsx from "clsx";
import { find } from "lodash";

// MUI
import Tooltip from "@material-ui/core/Tooltip";

// Stores
import { useDataSourcesStore } from "stores/zustand/stores";
import { connectorsGetter } from "stores/zustand/stores.selectors";

// Components
import { thirdPartyTypeName } from "src/pages/DataSources/utils/DataSources.constants";

// Hooks
import useAttributes from "./useAttributes";

// Constants
import { NodeTypeNames } from "src/pages/private/ProjectsModule/utils";

// Styles
import useStyles from "./FileReadonly.styles";

// Types
import { NodeData } from "src/types";
import useNodeStyle from "../../../hooks/useNodeStyle";

type Props = {
  data: NodeData;
};

const FileReadonly = (props: Props) => {
  const { data } = props || {};

  const { classes, statusKey } = useNodeStyle({
    classes: useStyles({ status: data?.status }),
    status: data?.status
  });

  // Stores - STARTS >>
  const connectorsStore = useDataSourcesStore(connectorsGetter);
  // << ENDS - Stores

  const connector = useMemo(
    () =>
      find(connectorsStore, {
        name:
          data?.entityDSDetails?.type === thirdPartyTypeName
            ? data?.entityDSDetails?.tpConnectorType
            : data?.entityDSDetails?.type
      }) || {},
    [connectorsStore, data?.entityDSDetails?.type]
  );

  // Keeping hooks at the bottom so as to pass the above defined props to it.
  // Hooks - STARTS >>
  const { icon } = useAttributes({ data, connector });
  // << ENDS - Hooks

  const additionalStyles = useMemo(() => {
    let thisStyles = classes.defaultStyles;

    if ((data?.entityDSDetails?.id && data?.entityDSDetails?.purpose === "IMPORT") || data?.image) {
      thisStyles = classes.connectorNodeStyles;
    }

    return thisStyles;
  }, [data?.entityDSDetails, data?.image]);

  return (
    <Tooltip title={NodeTypeNames.File}>
      <div className={clsx(classes.root, additionalStyles, classes[statusKey])}>
        <div className={classes.container}>
          <div className={classes.icon} data-testid="sidebar-entity-icon">
            {icon}
          </div>
        </div>
      </div>
    </Tooltip>
  );
};

export default FileReadonly;
