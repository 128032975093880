import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { getEntityFeatures } from "services/Apis/wrappers";

interface IVariables {
  projectId?: string;
  scenarioId?: string;
  datasetsIds: string[];
}

export interface EntityFeaturesResponse {
  id: string;
  schema: any;
}

export const useGetEntityFeaturesV2 = ({
  datasetsIds,
  projectId,
  scenarioId
}: IVariables): UseQueryResult<EntityFeaturesResponse[]> => {
  return useQuery({
    queryKey: ["entities", datasetsIds, projectId, scenarioId],
    queryFn: async () => {
      if (!projectId) {
        throw "projectId is absent";
      }
      if (!scenarioId) {
        throw "scenarioId is absent";
      }

      if (datasetsIds?.length === 0) {
        throw "datasetId is absent";
      }
      return await Promise.all(
        datasetsIds?.map(async (datasetId) => {
          const featureData = await getEntityFeatures(datasetId, scenarioId);

          return {
            id: datasetId,
            schema: featureData
          };
        })
      );
    },
    enabled: !!projectId,
    cacheTime: Infinity
  });
};
