// Packages
import axios from "axios";
import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { get, toLower, startsWith, includes } from "lodash";

// Utils
import api from "services/AxiosClient/AxiosClient";
import { mimeTypes } from "src/pages/private/ProjectsModule/utils";

// Open API
import { DownloadEntityDto } from "@rapidcanvas/rc-api-core";
import { ViewFileConfig } from "src/pages/private/ProjectsModule/pages/ViewFile/utils/ViewFile.constants";

export const enum UsePreviewDatasetFileQueryKeys {
  PreviewDatasetFile = "previewDatasetFile"
}

interface IPreviewDatasetFileProps extends UseQueryOptions<unknown, unknown, any> {
  fileId?: string;
  fileName?: string;
  fileType?: string;
  payload?: DownloadEntityDto;
  isPopover?: boolean;
}

export const getFileContent = async (
  blob: Blob,
  fileType: string,
  isPopover: boolean = false
): Promise<string> => {
  const contentType = toLower(get(mimeTypes, fileType, ""));

  if (startsWith(contentType, "text/html")) {
    if (blob?.size === 0) {
      return "";
    }
    const url = URL.createObjectURL(new Blob([blob], { type: contentType }));
    if (isPopover) {
      window.open(url, "_blank");
    }
    return url;
  }

  if (includes(["json", "md"], fileType) || startsWith(contentType, "text/")) {
    return blob.text();
  }

  return "";
};

const usePreviewDatasetFile = (props: IPreviewDatasetFileProps): UseQueryResult<any> => {
  const { fileId, fileName, fileType, payload, isPopover = false, ...options } = props;

  const isInvalidProps = !fileId || !payload || !fileName || !fileType;

  return useQuery({
    queryKey: [
      UsePreviewDatasetFileQueryKeys.PreviewDatasetFile,
      fileId,
      payload?.projectRunEntryId
    ],
    queryFn: async () => {
      if (isInvalidProps) {
        throw new Error("Invalid payload!");
      }

      const { data } = await api.EntityControllerApi.getDownloadUrl(fileId, payload);
      const response = await axios.get(data, { responseType: "blob" });

      const fileSize = response?.data?.size ?? 0;
      if (fileSize > ViewFileConfig.MaxFileSize * 1024 * 1024) {
        return ViewFileConfig.MaxFileSizeExceededError;
      }

      return getFileContent(response.data, fileType, isPopover);
    },
    ...options
  });
};

export default usePreviewDatasetFile;
