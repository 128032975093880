import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";

import api from "services/AxiosClient/AxiosClient";
import {
  updateDataAppsQueryData,
  updateProjectsQueryData,
  UpdateQueriesDataActionEnum
} from "src/utils/helpers";
import useAddPredictionService from "src/hooks/useAddPredictionService";
import { EnvironmentsTypes } from "environmentsModule/utils/Environments.constants";
import { DEFAULT_NAME } from "environmentsModule/utils/Environments.constants";
import { DEFAULT_PRE_POST_PROCESS_CODE } from "pages/private/ProjectsModule/pages/PredictionService/utils/PredictionService.constants";
import { getFormattedPythonCode } from "pages/private/ProjectsModule/pages/PredictionService/utils/PredictionService.helpers";
import {
  DataappAskAIConfig,
  DataAppDto,
  EnvDto,
  CreateDataAppRequestDtoDataAppTypeEnum,
  DataAppDtoLaunchStatusEnum,
  CreateDataAppRequestDtoPythonVersionEnum
} from "@rapidcanvas/rc-api-core";
import { relaunchEnv } from "./useLaunchDataAppMutation";

type Props = {
  dataAppName: string;
  appTemplateId: string;
  projectId: string;
  recipeId?: string;
  description: string;
  isPrivate?: boolean;
  memInMbs?: number;
  diskInGbs?: number;
  cores?: number;
  metadata?: {
    [key: string]: string;
  };
  envType: EnvironmentsTypes;
  iconUrl?: string;
  dataAppType: CreateDataAppRequestDtoDataAppTypeEnum;
  askAIConfig?: DataappAskAIConfig;
  onSuccess: (response: DataAppDto) => void;
  pythonVersion?: CreateDataAppRequestDtoPythonVersionEnum;
};

export const useCreateDataAppMutation = (): UseMutationResult<
  DataAppDto,
  unknown,
  Props,
  unknown
> => {
  const queryClient = useQueryClient();
  const addPredictionService = useAddPredictionService();

  return useMutation({
    mutationFn: async ({
      dataAppName,
      appTemplateId,
      projectId,
      recipeId,
      description,
      metadata,
      askAIConfig,
      dataAppType,
      isPrivate,
      memInMbs,
      diskInGbs,
      cores,
      onSuccess,
      iconUrl,
      envType,
      pythonVersion
    }) => {
      const response = await api.fetchResponse(
        async () =>
          await api.DataAppControllerApi.createDataApp({
            name: dataAppName,
            displayName: dataAppName,
            appTemplateId,
            envType,
            projectId,
            description,
            params: {},
            iconUrl,
            groupId: recipeId,
            metadata,
            askAIConfig,
            dataAppType,
            isPrivate,
            diskInGbs,
            cores,
            memInMbs,
            pythonVersion
          })
      );
      if (response.askAIConfig?.customEnvId) {
        relaunchEnv(response.askAIConfig?.customEnvId);
      }

      let data;
      try {
        await api.fetchResponse(
          async () => await api.DataAppControllerApi.launch(response.id as string)
        );

        data = { ...response, launchStatus: DataAppDtoLaunchStatusEnum.Launching };
        onSuccess(data);
        return data;
      } catch {
        data = { ...response, launchStatus: DataAppDtoLaunchStatusEnum.Stopped };
        onSuccess(data);
        return data;
      }
    },
    onSuccess: async (data: DataAppDto, { projectId }) => {
      updateDataAppsQueryData({ queryClient, data, action: UpdateQueriesDataActionEnum.Create });
      updateProjectsQueryData({ queryClient, data: { id: projectId }, fetchData: true });

      // Default create Prediction Service
      const { modelName, predictionSvcName, shouldCreatePredictionSvc } =
        data?.predictionSvcMetadata ?? {};
      if (modelName && predictionSvcName && shouldCreatePredictionSvc) {
        const response = await api.fetchResponse(async () => await api.EnvControllerApi.findEnvs());
        const defaultEnv = response?.find(
          (env: EnvDto) => env?.name === DEFAULT_NAME && env?.defaultFlag
        );

        if (defaultEnv) {
          const formattedPythonCode = getFormattedPythonCode(
            DEFAULT_PRE_POST_PROCESS_CODE,
            predictionSvcName
          );
          const pythonFile = new File([formattedPythonCode], `${predictionSvcName}.py`, {
            type: "text/plain"
          });
          const payload = {
            modelName,
            name: predictionSvcName,
            displayName: predictionSvcName,
            envId: defaultEnv?.id,
            description: "",
            file: pythonFile,
            shouldDispatchEvent: false
          };
          addPredictionService.mutate(payload);
        }
      }
    }
  });
};
