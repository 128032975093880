import { useEffect, useState } from "react";
import _ from "lodash";
import { EnvUsageDto } from "@rapidcanvas/rc-api-core";

import { IPredictionService } from "../../Environment";

interface IProps {
  envUsage: EnvUsageDto[];
  isLoadingEnvUsage: boolean;
}

export const useExtractPredictionServices = ({ envUsage, isLoadingEnvUsage }: IProps) => {
  const [isLoadingPredictionServices, setIsLoadingPredictionServices] = useState(false);
  const [predictionServices, setPredictionServices] = useState<IPredictionService[]>([]);

  useEffect(() => {
    if (isLoadingEnvUsage || _.isEmpty(envUsage)) return;

    setIsLoadingPredictionServices(true);

    const allPredictionServices = _.chain(envUsage)
      .filter((env: any) => env.pdId)
      .map(({ pdCreated, pdCreator, pdId, pdName, pdUpdated, pdUpdater, pdModelName }: any) => ({
        pdModelName,
        id: pdId,
        pdCreated,
        pdCreator,
        pdId,
        pdName,
        pdUpdated,
        pdUpdater
      }))
      .orderBy("pdCreated", "desc")
      .value();

    setPredictionServices(allPredictionServices);
    setIsLoadingPredictionServices(false);
  }, [isLoadingEnvUsage, envUsage]);

  return { isLoadingPredictionServices, predictionServices };
};
