import React from "react";

// Packages
import { useParams } from "react-router-dom";

// MUI
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";

// Utils
import { RenderText } from "components/custom";

// Hooks
import { useGetReleaseNotesByName } from "src/hooks/api/releaseNotes";

// Components
import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import ReleaseNotesContent from "./ReleaseNotesContent";

const useStyles = makeStyles((theme) => ({
  container: {
    // New UX change
    // The value 94px is the height of both the NavBars (TopNavBar 50px + SubTopNavBar 44px).
    height: "calc(100vh - 94px)",
    padding: theme.spacing(2)
  }
}));

const ReleaseNotes = () => {
  const classes = useStyles();

  const { name } = useParams();

  // Query hooks - STARTS >>
  // Queries
  const { isLoading, data } = useGetReleaseNotesByName({ name });
  // << ENDS - Query hooks

  return (
    <>
      <SubTopNavBarWrapper
        subTopNavBarLeftSection={{
          component: (
            <RenderText color="textSecondary" isOverflowTooltip>
              {`Latest Release Notes${!!data?.description ? ` (${data?.description})` : ""}`}
            </RenderText>
          )
        }}
      />

      <Grid container className={classes.container}>
        <ReleaseNotesContent isLoading={isLoading} data={data} />
      </Grid>
    </>
  );
};

export default ReleaseNotes;
