// Packages
import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { isArray } from "lodash";

// Open API
import api from "services/AxiosClient/AxiosClient";
import { SegmentDto } from "@rapidcanvas/rc-api-core";

export const enum UseGetSegmentsQueryKeys {
  Segments = "segments"
}

interface ISegmentsProps extends UseQueryOptions<unknown, unknown, SegmentDto[]> {
  datasetId?: string;
  jobRunId?: string;
}

const useGetSegments = (props: ISegmentsProps): UseQueryResult<SegmentDto[]> => {
  const { datasetId, jobRunId, ...options } = props;

  return useQuery({
    queryKey: [UseGetSegmentsQueryKeys.Segments, datasetId, jobRunId],
    queryFn: async () => {
      if (!datasetId) {
        throw "Invalid payload!";
      }

      return await api.fetchResponse(
        async () => await api.SegmentControllerApi.findSegment(datasetId, "", "", jobRunId)
      );
    },
    select: (data) => (isArray(data) ? data : []),
    enabled: !!datasetId,
    ...options
  });
};

export default useGetSegments;
