import React, { useMemo, useRef } from "react";

// Packages
import { useParams } from "react-router-dom";
import { difference, includes, isEmpty, map } from "lodash";

// MUI
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Alert from "@material-ui/lab/Alert";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

// Utils
import { RenderText } from "components/custom";

// Open API
import { EnvDtoLaunchStatusEnum } from "@rapidcanvas/rc-api-core";

// Hooks
import { useGetEnvironment, useGetEnvironmentLogs } from "src/hooks/api";

// Components
import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import CommonLoader from "src/components/CommonLoader";

// Constants
import {
  EnvironmentsHelperText,
  EnvironmentLogModes
} from "../../Environments/utils/Environments.constants";

export const useStyles = makeStyles((theme) => ({
  root: {
    // New UX change
    // The value 94px is the height of both the NavBars (TopNavBar 50px + SubTopNavBar 44px).
    height: "calc(100vh - 94px)",
    padding: theme.spacing(2)
  },
  container: {
    width: "100%",
    backgroundColor: "#1e1e1e",
    overflow: "auto",
    "& .text": {
      fontFamily: `Menlo, Monaco, "Courier New", monospace`,
      color: "#d4d4d4"
    }
  }
}));

const EnvironmentLogsPage: React.FC = () => {
  const { envId } = useParams();

  const classes = useStyles();

  const { data: environment, isLoading: isEnvironmentLoading } = useGetEnvironment({
    id: envId,
    staleTime: 0, // Ensures data is always considered stale
    cacheTime: Infinity, // Keeps the data in memory indefinitely
    refetchOnMount: true
  });

  const isLiveLogs = useMemo(
    () =>
      includes(
        [
          EnvDtoLaunchStatusEnum.Launching,
          EnvDtoLaunchStatusEnum.Stopping,
          EnvDtoLaunchStatusEnum.Success
        ],
        environment?.launchStatus
      ),
    [environment?.launchStatus]
  );

  const { data: environmentLogs, isLoading: isEnvironmentLogsLoading } = useGetEnvironmentLogs({
    id: environment?.id,
    mode: !!isLiveLogs ? EnvironmentLogModes.Live : EnvironmentLogModes.Offline,
    refetchInterval: !!isLiveLogs ? 2000 : false
  });

  const previousLogsRef = useRef<string[]>([]);

  const filteredLogs = useMemo(() => {
    if (!environmentLogs) return previousLogsRef.current;

    // Find new logs by comparing the current and previous data
    const newLogs = difference(environmentLogs, previousLogsRef.current);

    // Update the ref with all logs seen so far
    previousLogsRef.current = [...previousLogsRef.current, ...newLogs];

    return previousLogsRef.current;
  }, [environmentLogs]);

  const isLoading = useMemo(
    () => !!isEnvironmentLoading || !!isEnvironmentLogsLoading,
    [isEnvironmentLoading, isEnvironmentLogsLoading]
  );

  return !!isLoading ? (
    <CommonLoader />
  ) : isEmpty(environment) ? (
    <Alert
      severity="error"
      style={{ margin: "auto", width: "60%", justifyContent: "center" }}
      data-testid="envLogsSomethingWentWrong">
      {EnvironmentsHelperText.SomethingWentWrong}
    </Alert>
  ) : (
    <>
      <SubTopNavBarWrapper
        subTopNavBarLeftSection={{
          component: (
            <RenderText color="textSecondary" data-testid="envLogsTitle">
              {environment?.name} Logs
            </RenderText>
          )
        }}
      />

      <Grid container className={classes.root}>
        <Grid container item xs={12}>
          {isEmpty(filteredLogs) ? (
            <Alert
              severity="info"
              style={{ margin: "auto", marginTop: 0, width: "60%", justifyContent: "center" }}
              data-testid="envLogsNoLogsFound">
              {EnvironmentsHelperText.NoLogsFound}
            </Alert>
          ) : (
            <Paper elevation={0} className={classes.container}>
              <Box px={3} py={2.5} fontSize="small">
                <List style={{ wordBreak: "break-word", padding: 0 }}>
                  {map(filteredLogs, (log, index) => (
                    <ListItem key={`envLog_${index}`} style={{ padding: 0 }}>
                      <Typography
                        variant="caption"
                        className="text"
                        data-testid={`envLogsLine_${index}`}>
                        {log}
                      </Typography>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Paper>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default EnvironmentLogsPage;
