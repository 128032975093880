import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import api from "services/AxiosClient/AxiosClient";
import { AIChatResponseDto } from "@rapidcanvas/rc-api-core";

export const AI_GUIDE_MESSAGES_KEY = "ai-guide-messages";

export const useGetAIGuideMessages = ({
  threadId,
  options
}: {
  threadId: string | undefined;
  options?: UseQueryOptions<AIChatResponseDto[]>;
}) => {
  return useQuery<AIChatResponseDto[]>({
    queryKey: [AI_GUIDE_MESSAGES_KEY, threadId],
    queryFn: async () => {
      const response = await api.fetchResponse(() => api.AiControllerApi.getMessages2(threadId!));
      return response;
    },
    enabled: !!threadId,
    ...options
  });
};
