import Alert from "@material-ui/lab/Alert/Alert";
import React from "react";
import { Button, makeStyles } from "@material-ui/core";

import useDataAppStatus from "hooks/api/dataapps/useDataAppStatus";
import { DataAppDtoLaunchStatusEnum } from "@rapidcanvas/rc-api-core";

const useStyles = makeStyles({
  alert: {
    padding: "0px 16px",
    marginTop: "8px",
    borderRadius: "0px 0px 4px 4px"
  },
  alertMessage: {
    padding: "6px 0"
  },
  runningStatus: {
    color: "#3b873e"
  },
  notRunningStatus: {
    color: "#c77700"
  },
  launchingStatus: {
    color: "#2196f3"
  },
  stopIcon: {
    color: "#1d4620"
  },
  runningIcon: {
    color: "#663d00"
  }
});

interface IProps {
  id: string;
  launchStatus: DataAppDtoLaunchStatusEnum;
}

const DataAppTileStatus: React.FC<IProps> = (props) => {
  const { id, launchStatus } = props;

  const { currentStatusInfo, loading } = useDataAppStatus(id, launchStatus);
  const classes = useStyles();

  if (!currentStatusInfo) {
    return null;
  }

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    currentStatusInfo?.onClick?.();
  };

  return (
    <Alert
      severity={currentStatusInfo.alertType}
      className={classes.alert}
      classes={{ message: classes.alertMessage }}
      icon={false}
      data-testid="dataAppStatusAlert"
      action={
        currentStatusInfo.buttonText && (
          <Button
            color="inherit"
            style={{ backgroundColor: "inherit", textTransform: "capitalize" }}
            data-testid="dataAppStatusUpdateBtn"
            size="small"
            disabled={loading}
            onClick={handleClick}>
            {loading ? null : currentStatusInfo.buttonText.toLocaleLowerCase()}
          </Button>
        )
      }>
      {currentStatusInfo.text}
    </Alert>
  );
};

export default DataAppTileStatus;
