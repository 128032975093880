import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "services/AxiosClient/AxiosClient";
import { AIChatRequestDto, AIChatResponseDto, ThreadResponseDto } from "@rapidcanvas/rc-api-core";
import { useAIGuideStore } from "stores/zustand/stores";
import { AI_GUIDE_MESSAGES_KEY } from "./useGetAIGuideMessages";
import { AI_GUIDE_THREADS_KEY } from "./useGetAIGuideThreads";
import { map } from "lodash";

interface IRequestDto extends AIChatRequestDto {
  controller?: any;
}

type IProps = { request: IRequestDto; threadId: string; inputNames?: string[] };

export const useSaveAIGuideChatMutation = (projectId: string) => {
  const [setGeneratingState, setIsGenerated] = useAIGuideStore((state) => [
    state.setGeneratingState,
    state.setIsGenerated
  ]);
  const queryClient = useQueryClient();
  return useMutation<any, unknown, IProps, IRequestDto>({
    mutationFn: async ({ threadId, request }: { request: IRequestDto; threadId: string }) => {
      const { controller, ...restProps } = request;
      const { query } = restProps;
      const currTime = Date.now();
      const currTimestamp = new Date(currTime).toISOString();
      setGeneratingState(threadId, query!, currTimestamp);
      const response = await api.fetchResponse(() =>
        api.AiControllerApi.chat1(
          threadId,
          restProps,
          controller
            ? {
                signal: controller.signal
              }
            : {}
        )
      );
      if (controller?.signal?.aborted) {
        setIsGenerated(threadId);
        return;
      }
      queryClient.setQueryData(
        [AI_GUIDE_MESSAGES_KEY, threadId],
        (prevData: AIChatResponseDto[] | undefined) => [
          ...(prevData || []),
          { ...response, isExpanded: true }
        ]
      );
      queryClient.setQueryData(
        ["projects", projectId, AI_GUIDE_THREADS_KEY],
        (prevData: ThreadResponseDto[] | undefined) => {
          if (!prevData) return [];

          return map(prevData, (thread) =>
            thread.threadId === threadId
              ? { ...thread, messagesCount: (thread.messagesCount || 0) + 1 }
              : thread
          );
        }
      );
      return response;
    },
    onSuccess: (_data, variables) => {
      const {
        threadId,
        request: { controller }
      } = variables;
      setIsGenerated(threadId);
      if (controller?.signal?.aborted) {
        return;
      }
    },
    onError: (_data, variables) => {
      const {
        threadId,
        request: { controller }
      } = variables;
      setIsGenerated(threadId);
      if (controller?.signal?.aborted) {
        return;
      }
    }
  });
};
