export const AuthSessionConfig = {
  refreshTokenInterval: 25 * 60 * 1000 // 25 mins.
};

export const PathRexExps = {
  auth: /auth/,
  tenant: /tenant-choice/,
  projects: /\/projects/,
  canvas: /canvas/,
  dataAppDeepLink: /\/apps/,
  dataAppLink: /dataapps-ui/
};

export enum MenuItemTypes {
  Notebook = "NOTEBOOK",
  Support = "SUPPORT"
}

export enum NotebookTypes {
  Both = "BOTH",
  User = "USER",
  Tenant = "TENANT",
  LaunchNotebook = "Notebook",
  LaunchNotebookUser = "Notebook (User)",
  LaunchNotebookTenant = "Notebook (Tenant)"
}

export enum MuiBreakpoints {
  Xs = "xs",
  Sm = "sm",
  Md = "md",
  Lg = "lg",
  Xl = "xl"
}

export enum PublicScreenLabels {
  SignIn = "Sign In",
  SignUp = "Sign Up",
  Email = "Email",
  Password = "Password",
  ConfirmPassword = "Confirm Password",
  JoinTenant = "Join Workspace",
  SignInLoading = '"Signing in...'
}

export enum ResetPasswordPathStatuses {
  Send,
  Sent
}

export enum ReleaseNotesConfig {
  CurrentReleaseName = "current_release_notes"
}

export enum listViewPages {
  PROJECTS = "projects",
  CONNECTORS = "connectors",
  DATAAPPS = "dataapps",
  ENVIRONMENT = "environments"
}

export const PagesViewConfiguration = "pagesViewConfiguration";

// @HACK: Below hard coded constants are used to display the icon in the top menu bar and favicon specifically for the Cafex client. This code should be removed once its no longer needed.
export const CafexConfig = {
  FaviconName: "Cafex",
  TenantId: "b45f2cbd-5d02-4edf-a15b-9d8e82a3c23b",
  TestTenantId: "af210bc0-4735-47d1-a1f4-91107ef5129c" // Tenant-name: prachi-automation
};

export const PatternRexExps = {
  Email:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  TrimmedEmail:
    /^\s*(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/,
  Password: new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{9,}$")
};
