import React, { useMemo, useState } from "react";

// Packages
import { map, values, isFunction } from "lodash";

// Icons
import { AiAssistedIcon } from "icons/NewUX/AiAssistedRecipeIcon";
import { ArtifactIcon } from "icons/NewUX/ArtifactIcon";
import { DatasetIcon } from "icons/NewUX/DatasetIcon";
import { ModelIcon } from "icons/NewUX/ModelIcon";
import { PlusIcon } from "icons/NewUX/PlusIcon";
import { RapidModelRecipeIcon } from "icons/NewUX/RapidModelRecipeIcon";
import { TemplateRecipeIcon } from "icons/NewUX/TemplateRecipeIcon";
import { FileIcon } from "icons/NewUX";

// Constants
import { NodeTypeNames, RecipeTypeNames } from "src/pages/private/ProjectsModule/utils";
import ApiConnectorIcon from "icons/NewUX/ApiConnectorIcon";
import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  makeStyles,
  Tooltip
} from "@material-ui/core";
import Drawer from "src/components/Drawer/CustomDrawer";
import AddProjectArtifactButton from "projectsModule/components/AddProjectArtifactButton";

export enum DatasetMenuKeys {
  Dataset = "DATASET",
  File = "FILE",
  Artifact = "ARTIFACT",
  Model = "MODEL"
}

export enum RecipeMenuKeys {
  TemplateRecipe = "TEMPLATE_RECIPE",
  AiAssistedRecipe = "AI_ASSISTED_RECIPE",
  RapidModelRecipe = "RAPID_MODEL_RECIPE",
  ApiConnectorRecipe = "API_CONNECTOR_RECIPE"
}

interface IProps {
  isAddTemplateRecipeDisabled: boolean;
  isAddAiAssistedRecipeDisabled: boolean;
  disabledAddRapidModelRecipeActionMessage: string;
  canAddStandardRecipe: boolean;
  canAddArtifacts: boolean;
  canAddModels: boolean;
  addArtifacts: () => void;
  addModel: () => void;
  addDataset: () => void;
  addFile: () => void;
  addTemplateRecipe: () => void;
  addAiAssistedRecipe: () => void;
  addRapidModelRecipe: () => void;
  onAddApiConnectorRecipe?: () => void;
  projectId?: string;
}

const useDrawerStyles = makeStyles({
  drawer: {
    width: 600,
    flexShrink: 0
  },
  drawerPaper: {
    width: 600,
    backgroundColor: "#e8e8e8"
  },
  title: {
    fontSize: "14px",
    color: "#809AAB"
  },
  header: {
    borderBottom: "1px solid #d3d3d3",
    padding: "4px 20px"
  },
  btnLabel: {
    justifyContent: "flex-start",
    fontWeight: 400
  }
});

const AddMenu: React.FC<IProps> = (props) => {
  const {
    // Datasets props
    addDataset,
    addFile,
    addArtifacts,
    addModel,
    canAddArtifacts,
    canAddModels,
    projectId,

    // Recipes props
    isAddTemplateRecipeDisabled,
    isAddAiAssistedRecipeDisabled,
    disabledAddRapidModelRecipeActionMessage,
    addTemplateRecipe,
    addAiAssistedRecipe,
    canAddStandardRecipe,
    addRapidModelRecipe,
    onAddApiConnectorRecipe
  } = props || {};

  const [open, setOpen] = useState(false);
  const { drawer, drawerPaper, header, title, btnLabel } = useDrawerStyles();

  // Meta-data of menu-items - STARTS >>
  const datasetMenuDetails = useMemo(
    () => ({
      [DatasetMenuKeys.Dataset]: {
        key: DatasetMenuKeys.Dataset,
        label: NodeTypeNames.Dataset,
        icon: <DatasetIcon width={20} height={16} viewBox="2 0 20 20" />,
        action: addDataset,
        button: null
      },
      [DatasetMenuKeys.Artifact]: {
        key: DatasetMenuKeys.Artifact,
        label: NodeTypeNames.Artifact,
        hide: !canAddArtifacts,
        icon: <ArtifactIcon width={20} height={20} />,
        action: addArtifacts,
        button: (
          <AddProjectArtifactButton
            key={DatasetMenuKeys.Artifact}
            projectId={projectId}
            isAddDrawer
          />
        )
      },
      [DatasetMenuKeys.Model]: {
        key: DatasetMenuKeys.Model,
        hide: !canAddModels,
        label: NodeTypeNames.Model,
        icon: <ModelIcon width={20} height={20} />,
        action: addModel,
        button: null
      },
      [DatasetMenuKeys.File]: {
        key: DatasetMenuKeys.File,
        label: NodeTypeNames.File,
        icon: <FileIcon width={20} height={20} viewBox="2 0 20 20" />,
        action: addFile,
        button: null
      }
    }),
    []
  );

  const recipeMenuDetails = useMemo(
    () => ({
      [RecipeMenuKeys.AiAssistedRecipe]: {
        key: RecipeMenuKeys.AiAssistedRecipe,
        label: RecipeTypeNames.AiAssisted,
        icon: <AiAssistedIcon width={20} height={20} />,
        action: addAiAssistedRecipe,
        isDisabled: isAddAiAssistedRecipeDisabled,
        hide: false,
        tooltip: isAddAiAssistedRecipeDisabled ? "No inputs to add recipe" : ""
      },
      [RecipeMenuKeys.RapidModelRecipe]: {
        key: RecipeMenuKeys.RapidModelRecipe,
        label: RecipeTypeNames.RapidModel,
        icon: <RapidModelRecipeIcon width={20} height={20} />,
        action: addRapidModelRecipe,
        isDisabled: !!disabledAddRapidModelRecipeActionMessage,
        hide: false,
        tooltip: disabledAddRapidModelRecipeActionMessage
      },
      [RecipeMenuKeys.TemplateRecipe]: {
        key: RecipeMenuKeys.TemplateRecipe,
        label: RecipeTypeNames.Template,
        icon: <TemplateRecipeIcon width={20} height={20} />,
        action: addTemplateRecipe,
        hide: !canAddStandardRecipe,
        isDisabled: isAddTemplateRecipeDisabled,
        tooltip: isAddTemplateRecipeDisabled ? "No inputs to add recipe" : ""
      },
      [RecipeMenuKeys.ApiConnectorRecipe]: {
        key: RecipeMenuKeys.ApiConnectorRecipe,
        label: "Code",
        icon: <ApiConnectorIcon style={{ marginRight: "3px" }} />,
        action: onAddApiConnectorRecipe,
        isDisabled: false,
        hide: !isFunction(onAddApiConnectorRecipe),
        tooltip: ""
      }
    }),
    [
      isAddTemplateRecipeDisabled,
      isAddAiAssistedRecipeDisabled,
      disabledAddRapidModelRecipeActionMessage,
      onAddApiConnectorRecipe
    ]
  );
  // ENDS - Meta-data of menu-items

  // Formatting to support MUI menu - STARTS >>
  const visibleDatasetMenuItems = values(datasetMenuDetails).filter(
    (menuItem: { [key: string]: any }) => !menuItem.hide
  );
  const datasetMenuItems = useMemo(
    () =>
      map(visibleDatasetMenuItems, (menuItem) => ({
        key: menuItem?.key,
        label: menuItem?.label,
        icon: menuItem?.icon,
        action: menuItem.action,
        button: menuItem?.button
      })),
    [datasetMenuDetails]
  );

  const visibleRecipeMenuItems = values(recipeMenuDetails).filter((recipe) => !recipe.hide);
  const recipeMenuItems = useMemo(
    () =>
      map(visibleRecipeMenuItems, (menuItem) => ({
        key: menuItem?.key,
        label: menuItem?.label,
        icon: menuItem?.icon,
        isDisabled: menuItem?.isDisabled,
        tooltip: menuItem?.tooltip,
        action: menuItem.action
      })),
    [recipeMenuDetails]
  );

  return (
    <>
      <IconButton color="default" style={{ padding: 0 }} onClick={() => setOpen(true)}>
        <PlusIcon width={30} height={30} color="#4646B5" fill="white" />
      </IconButton>
      <Drawer
        anchor="right"
        variant="temporary"
        keepMounted={false}
        open={open}
        titleStyle={{ padding: 0, borderBottom: "1px solid #E0E0E0", lineHeight: 1 }}
        classes={{
          paper: drawerPaper
        }}
        className={drawer}
        title={
          <div style={{ fontSize: "16px", padding: "16px", textTransform: "capitalize" }}>Add</div>
        }
        onClose={() => setOpen(false)}>
        <div
          style={{
            gap: "16px",
            display: "flex",
            padding: "16px",
            flexDirection: "column",
            justifyContent: "center"
          }}>
          <Card>
            <CardHeader className={header} title={<span className={title}>Elements</span>} />
            <CardContent style={{ padding: "12px" }}>
              <ButtonGroup style={{ width: "100%" }} variant="text" orientation="vertical">
                {map(datasetMenuItems, (item) =>
                  item.button ? (
                    item.button
                  ) : (
                    <Button
                      key={item.key}
                      classes={{ label: btnLabel }}
                      style={{ borderBottom: "none" }}
                      startIcon={item.icon}
                      onClick={() => item.action()}>
                      {item.label}
                    </Button>
                  )
                )}
              </ButtonGroup>
            </CardContent>
          </Card>
          <Card>
            <CardHeader className={header} title={<span className={title}>Recipes</span>} />
            <CardContent style={{ padding: "12px" }}>
              <ButtonGroup style={{ width: "100%" }} variant="text" orientation="vertical">
                {map(recipeMenuItems, (item) => (
                  <Tooltip
                    key={item.key}
                    title={item.tooltip ?? ""}
                    style={{ borderBottom: "none" }}>
                    <span>
                      <Button
                        disabled={item.isDisabled}
                        style={{ width: "100%" }}
                        classes={{ label: btnLabel }}
                        startIcon={item.icon}
                        onClick={() => item.action?.()}>
                        {item.label}
                      </Button>
                    </span>
                  </Tooltip>
                ))}
              </ButtonGroup>
            </CardContent>
          </Card>
        </div>
      </Drawer>
    </>
  );
};

export default AddMenu;
