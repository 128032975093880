import { RoleDto } from "@rapidcanvas/rc-api-core";
import { IUserTenant } from "src/hooks/api";
import { Tenant } from "src/types";
import create from "zustand";

type TenantsStore = {
  selectedTenant: Tenant;
  userTenants: IUserTenant[];
  roles: RoleDto[];
  updateTenantDescription: (newName: string, newDescription: string) => void;
  removeTenantProject: (tenantId: string) => void;
  editTenantProjectRole: (tenantId: string, newRole: string) => void;
  setUserTenants: (newTenants: IUserTenant[]) => void;
  setSelectedTenant: (selectedTenant: Tenant) => void;
  setRoles: (roles: RoleDto[]) => void;
  shouldTenantsRefresh: boolean | number;
  shouldUserTenantsRefetch: boolean | number;
  toggleTenantsRefresh: () => void;
  toggleUserTenantsRefresh: () => void;
  setShouldTenantsRefresh: (refresh: boolean) => void;
};

const useTenantsStore = create<TenantsStore>((set) => ({
  selectedTenant: {
    name: "",
    description: "",
    tenants: [],
    cpuGuarantee: 0,
    memGuarantee: 0,
    dataappsTtlInHrs: 0,
    licenseDetails: {}
  },
  userTenants: [],
  roles: [],
  updateTenantDescription: (newName, newDescription) => {
    set((state) => {
      return {
        selectedTenant: {
          ...state.selectedTenant,
          name: newName,
          description: newDescription,
          tenants: [...state.selectedTenant?.tenants],
          licenseDetails: state.selectedTenant?.licenseDetails
        }
      };
    });
  },
  removeTenantProject: (tenantId) => {
    set((state: $TSFixMe) => {
      const tenantFiltered = state.selectedTenant?.tenants.filter(
        (tenant: $TSFixMe) => tenant.id !== tenantId
      );
      return {
        selectedTenant: {
          ...state.selectedTenant,
          tenants: tenantFiltered
        }
      };
    });
  },
  editTenantProjectRole: (tenantId, newRole) => {
    set((state: $TSFixMe) => {
      const newState = state.selectedTenant?.tenants;
      const tenantsEdited = newState.map((tenant: $TSFixMe) => {
        if (tenant.id == tenantId) {
          return {
            ...tenant,
            role: newRole
          };
        }
        return tenant;
      });
      return {
        selectedTenant: {
          ...state.selectedTenant,
          tenants: tenantsEdited
        }
      };
    });
  },
  setUserTenants: (newTenants) => {
    set(() => ({
      userTenants: newTenants
    }));
  },
  setSelectedTenant: (tenant) => {
    set(() => ({
      selectedTenant: tenant
    }));
  },
  setRoles: (roles) => {
    set(() => ({
      roles
    }));
  },
  shouldTenantsRefresh: false,
  shouldUserTenantsRefetch: false,
  toggleTenantsRefresh: () => set(() => ({ shouldTenantsRefresh: Date.now() })),
  toggleUserTenantsRefresh: () => set(() => ({ shouldUserTenantsRefetch: Date.now() })),
  setShouldTenantsRefresh: (shouldTenantsRefresh) => set(() => ({ shouldTenantsRefresh }))
}));

export default useTenantsStore;
