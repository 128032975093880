import React, { useMemo } from "react";
import _, { isEmpty, join, map } from "lodash";
import { Link } from "react-router-dom";
import { Grid, Tooltip, Typography } from "@material-ui/core";

import Text from "src/components/Widget/Text";
import { Delete } from "../../../../assets/icons/Delete";
import { LinkCellWithMenu } from "../../../../components/Table/Cells/LinkCellWithMenu/LinkCellWithMenu.component";
import { OverflowTooltip } from "../../../../components";
import { dateFormat } from "utils/helpers/date.helpers";
import { generatePath, useParams } from "react-router";
import { WebPaths } from "src/routing/routes";
import { RecipeTypes } from "src/pages/private/ProjectsModule/utils";
import { ArtifactProducer } from "@rapidcanvas/rc-api-core";
import { InfoOutlined } from "@material-ui/icons";
import Table from "src/components/custom/TableV8/Table";
import { CellContext, Row, sortingFns } from "@tanstack/react-table";
import GettingStarted from "components/custom/GetttingStarted/GettingStarted";
import ImportDataApp from "icons/NewUX/GettingStartedIllustrations/ImportDataApp";
import { ArtifactsGettingStarted } from "../Artifacts.constants";
import { getDocsUrl } from "utils/helpers";
import AddProjectArtifactButton from "projectsModule/components/AddProjectArtifactButton";

const getRecipeLink = (value: any) => {
  switch (value.recipeType) {
    case RecipeTypes.AiAssisted:
      return generatePath(WebPaths.CodeRecipeContainer, {
        projectId: value.projectId,
        groupId: value.recipeId,
        scenarioId: value.scenarioId
      });
    case RecipeTypes.ApiConnector:
      return generatePath(WebPaths.APIConnectorRecipeContainer + "/:groupId", {
        projectId: value.projectId,
        groupId: value.recipeId,
        scenarioId: value.scenarioId
      });
    case RecipeTypes.RapidModel:
      return generatePath(WebPaths.AutoMLRecipeContainer, {
        projectId: value.projectId,
        groupId: value.recipeId,
        scenarioId: value.scenarioId
      });
    case RecipeTypes.Template:
      return generatePath(WebPaths.StandardRecipeDataContainer, {
        projectId: value.projectId,
        groupId: value.recipeId,
        scenarioId: value.scenarioId
      });
  }
};

export const getSourceVal = (value: ArtifactProducer, isProjectPath: boolean = false) => {
  if (!value) {
    return "Manual addition";
  }

  const recipeLink = value.recipeName ? (
    <Link
      style={{ color: " #003656", cursor: "pointer", textDecoration: "underline" }}
      to={getRecipeLink(value) ?? ""}>
      {value.recipeName}
    </Link>
  ) : null;

  const projectLink = value.projectName ? (
    <>
      {"("}
      <Link
        style={{ color: " #003656", cursor: "pointer", textDecoration: "underline" }}
        to={generatePath("/projects/:projectId", { projectId: value.projectId ?? "" })}>
        {value.projectName}
      </Link>
      {")"}
    </>
  ) : null;

  return (
    <>
      {recipeLink}
      {!isProjectPath && projectLink}
    </>
  );
};
export const getDestinationValList = (
  values: ArtifactProducer[],
  isProjectPath: boolean = false
): JSX.Element[] | string => {
  if (isEmpty(values)) {
    return "";
  }

  return values.map((value, index) => {
    const recipeLink = value.recipeName ? (
      <Link
        key={`recipe-${index}`}
        style={{ color: "#003656", cursor: "pointer", textDecoration: "underline" }}
        to={getRecipeLink(value) ?? ""}>
        {value.recipeName}
      </Link>
    ) : null;

    const projectLink = value.projectName ? (
      <>
        {"("}
        <Link
          key={`project-${index}`}
          style={{ color: "#003656", cursor: "pointer", textDecoration: "underline" }}
          to={generatePath("/projects/:projectId", { projectId: value.projectId ?? "" })}>
          {value.projectName}
        </Link>
        {")"}
      </>
    ) : null;

    return (
      <span key={`artifact-${index}`}>
        {recipeLink}
        {!isProjectPath && projectLink}
        {index < values.length - 1 && ", "}
      </span>
    );
  });
};

export const getSourceString = (value: any, isProjectPath: boolean = false) => {
  return value
    ? `${value?.recipeName} ${value?.projectName && !isProjectPath ? `(${value?.projectName})` : ""}`
    : "Manual addition";
};
export const getDestinationsString = (value: any[], isProjectPath: boolean = false) => {
  return isEmpty(value)
    ? ""
    : join(
        map(
          value,
          (item) =>
            `${item?.recipeName} ${item?.projectName && !isProjectPath ? `(${item?.projectName})` : ""}`
        ),
        ", "
      );
};

const ArtifactsTable = ({ data, onDelete, children }: $TSFixMe) => {
  const { projectId } = useParams();

  const sourceSortType = useMemo(
    () => (rowA: Row<any>, rowB: Row<any>) => {
      const valA = getSourceString(_.get(rowA.original, "producer"));
      const valB = getSourceString(_.get(rowB.original, "producer"));
      return valA.localeCompare(valB, undefined, { sensitivity: "base" });
    },
    []
  );

  const destinationSortType = (rowA: Row<any>, rowB: Row<any>) => {
    const valA = getDestinationsString(_.get(rowA.original, "consumers"));
    const valB = getDestinationsString(_.get(rowB.original, "consumers"));
    return valA.localeCompare(valB, undefined, { sensitivity: "base" });
  };

  const columns = [
    {
      id: "Name",
      accessorKey: "name",
      header: "Name",

      meta: { isTooltip: false },
      cell: ({ row }: CellContext<any, unknown>) =>
        !row?.original?.producer && !projectId ? (
          <LinkCellWithMenu
            url={
              !!projectId
                ? generatePath(WebPaths.ProjectArtifactsDetails, {
                    projectId,
                    artifactName: row.original.name
                  })
                : `/artifacts-and-models/artifact/${row.original.name}`
            }
            linkText={<Text value={row.original.name} />}
            menuButtons={[
              {
                label: "DELETE",
                icon: <Delete />,
                action: () => onDelete(row?.original?.name)
              }
            ]}
          />
        ) : (
          <Link
            className="name-link"
            to={
              !!projectId
                ? generatePath(WebPaths.ProjectArtifactsDetails, {
                    projectId,
                    artifactName: row.original.name
                  })
                : `/artifacts-and-models/artifact/${row.original.name}`
            }>
            <OverflowTooltip value={row.original.name} />
          </Link>
        )
    },
    {
      id: "Updated on",
      accessorKey: "updated",
      header: "Updated on",

      sortingFn: sortingFns.alphanumericCaseSensitive,
      cell: ({ getValue }: $TSFixMe) => <span>{dateFormat(getValue(), false)}</span>
    },
    {
      id: "Source",
      accessorKey: "producer",
      header: () => (
        <span style={{ fontSize: "14px", display: "flex", alignItems: "center", gap: "5px" }}>
          Source
          <Tooltip arrow title="Recipe where artifact is the output">
            <InfoOutlined color="disabled" fontSize="small" />
          </Tooltip>
        </span>
      ),

      sortingFn: sourceSortType,
      cell: ({ getValue }: CellContext<any, unknown>) =>
        getSourceVal(getValue() as ArtifactProducer)
    },
    {
      id: "Destinations",
      accessorKey: "consumers",
      header: () => (
        <span style={{ fontSize: "14px", gap: "5px", display: "flex", alignItems: "center" }}>
          Destinations
          <Tooltip arrow title="Recipes where artifact is the input.">
            <InfoOutlined color="disabled" fontSize="small" />
          </Tooltip>
        </span>
      ),

      sortingFn: destinationSortType,
      cell: ({ getValue }: CellContext<any, unknown>) =>
        getDestinationValList(getValue() as ArtifactProducer[])
    }
  ];

  return (
    <>
      {data.length ? (
        <Table
          data={data}
          columns={columns}
          sortingProps={{ orderByDefault: "Updated on", sortInverted: false }}
          stylesProps={{ size: "small", maxHeight: "calc(100vh - 248px)", isTheadSticky: true }}
        />
      ) : (
        children ?? (
          <GettingStarted
            outerBoxWidth="600px"
            boxes={[
              {
                icon: <ImportDataApp />,
                message: ArtifactsGettingStarted.topMessage,
                width: "600px",
                action: (
                  <Grid container direction="column" style={{ gap: "16px" }}>
                    <Typography style={{ fontSize: "15px", fontWeight: 500 }}>
                      Manage Artifacts
                    </Typography>
                    <Typography style={{ fontSize: "15px" }}>
                      Upload, organize, and reuse artifacts for seamless data processing.
                    </Typography>
                    <AddProjectArtifactButton projectId={projectId} />
                  </Grid>
                )
              }
            ]}
            functionality={{
              question: ArtifactsGettingStarted.functionality.question,
              answers: ArtifactsGettingStarted.functionality.answers,
              docsLink: `${getDocsUrl()}/basic/artifacts-and-models${!!projectId ? "#adding-artifacts-at-the-project-level" : ""}`
            }}
          />
        )
      )}
    </>
  );
};

export default ArtifactsTable;
