import { Grid, makeStyles } from "@material-ui/core";
import React, { useState } from "react";

import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

const useStyles = makeStyles({
  root: {
    height: "100%",
    borderRadius: 12,
    background: "#FFFFFF",
    border: "1px dashed #415F80",
    alignItems: "center",
    justifyContent: "center",
    display: "flex"
  },
  imageWrap: {
    height: "100%",
    background: "#FFFFFF",
    border: "1px dashed #415F80"
  },
  uploadBtn: {
    color: "#4646B5",
    borderRadius: "20px",
    borderColor: "#4646B5",
    "&:hover": {
      color: "#4646B5",
      borderColor: "#4646B5"
    }
  }
});

interface IProps {
  setCompletedCrop: React.Dispatch<React.SetStateAction<PixelCrop | undefined>>;
  imgRef: React.RefObject<HTMLImageElement>;
  boxWidth: number;
  imgSrc: string;
  aspect: number;
}
export const CustomImageCrop = ({ setCompletedCrop, imgRef, boxWidth, imgSrc, aspect }: IProps) => {
  const [crop, setCrop] = useState<Crop>();
  const classes = useStyles();

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  return (
    <ReactCrop
      crop={crop}
      onChange={(_, percentCrop) => setCrop(percentCrop)}
      onComplete={async (c) => setCompletedCrop(c)}
      aspect={aspect}>
      <Grid container className={classes.imageWrap} style={{ height: "500px", width: "100%" }}>
        <img
          ref={imgRef}
          alt="Crop Project"
          src={imgSrc}
          onLoad={onImageLoad}
          width={boxWidth}
          style={{
            aspectRatio: aspect.toString(),
            width: "100%",
            height: "100%"
          }}
        />
      </Grid>
    </ReactCrop>
  );
};
