import React from "react";

// Packages
import { Link } from "react-router-dom";

import { startCase, trim } from "lodash";

// MUI
import { Grid, Paper, Typography } from "@material-ui/core";

// Components
import ModalAgreement from "src/components/ModalAgreement/ModalAgreement";
import { PasswordInput } from "src/components";

// Constants
import { PatternRexExps, PublicScreenLabels } from "constants/index";

// Styles
import { useStyles } from "./SignIn.styles";
import { PasswordError } from "./PasswordError";
import { SignInError } from "./SignInError";
import { WelcomeMessage } from "./WelcomeMessage";
import { useSignInForm } from "./useSignInForm";
import { useSignInActions } from "./useSignInActions";
import { EmailInput } from "./EmailInput";
import { RCLogo } from "./RCLogo";
import { SignInButton } from "./SignInButton";

const EmailPasswordSignIn = ({
  email,
  showPrev,
  onPrev,
  disableEmailField
}: {
  email?: string;
  showPrev?: boolean;
  disableEmailField?: boolean;
  onPrev?: () => void;
} = {}) => {
  const classes = useStyles();

  const {
    register,
    setValue,
    handleSubmit,
    errors,
    clearBEErrors,
    isEmailPasswordErrorVisible,
    IsError500Visible,
    errorMessage,
    setErrorMessage,
    setIsEmailPasswordErrorVisible,
    setIsError500Visible
  } = useSignInForm({ email });

  const { isSignUpFlow, handleNavigate, displayAgreement, handleSignIn, isSigningIn } =
    useSignInActions({
      setErrorMessage,
      setIsEmailPasswordErrorVisible,
      setIsError500Visible
    });

  const title = startCase(isSignUpFlow ? PublicScreenLabels.JoinTenant : PublicScreenLabels.SignIn);

  return (
    <>
      {!!displayAgreement && <ModalAgreement onClose={handleNavigate} />}

      <Grid container className={classes.page}>
        <Paper variant="outlined" className={classes.root}>
          <Grid container direction="column" className={classes.container}>
            <RCLogo />
            <Grid item className={classes.titleContainer}>
              <Typography variant="h5" component="div" align="center" data-testid="signInTitle">
                {title}
              </Typography>
            </Grid>
            <WelcomeMessage isSignUpFlow={isSignUpFlow} />
            <Grid
              container
              component="form"
              className={classes.formContainer}
              onChange={clearBEErrors}
              onSubmit={handleSubmit(handleSignIn)}>
              <EmailInput
                defaultValue={email}
                disabled={!!isSigningIn || disableEmailField}
                {...register("email", {
                  required: true,
                  pattern: PatternRexExps.TrimmedEmail,
                  onBlur: (event) => {
                    setValue("email", trim(event?.target?.value), { shouldValidate: true });
                  }
                })}
                error={errors?.email}
              />
              <PasswordInput
                data-testid="signInPassword"
                size="small"
                color="primary"
                fullWidth
                autoComplete="off"
                disabled={!!isSigningIn}
                {...register("password", { required: true })}
                error={errors?.password || IsError500Visible || (!!errorMessage as any)}
                helperText={
                  <PasswordError
                    type={errors?.password?.type}
                    errorMessage={errorMessage}
                    isServerError={IsError500Visible}
                  />
                }
              />
              <Typography variant="body2" data-testid="signInForgotPasswordMessage">
                Forgot Password?{" "}
                <Link
                  id="signInForgotPasswordLink"
                  data-testid="signInForgotPasswordLink"
                  to="/auth/reset-password-link-send"
                  className={classes.link}>
                  Get help
                </Link>
              </Typography>
              <Grid container direction="row" wrap="nowrap" className={classes.buttons}>
                {showPrev && onPrev && <SignInButton text="Prev" onClick={onPrev} type="button" />}
                <SignInButton disabled={!!isSigningIn} loading={!!isSigningIn} />
              </Grid>
              <SignInError isVisible={isEmailPasswordErrorVisible} />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </>
  );
};

export default EmailPasswordSignIn;
