import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import clsx from "clsx";
import React from "react";

interface IProps {
  shouldDisplay: boolean;
  padding: number;
  colSpan: number;
  isTop: boolean;
  classes: ClassNameMap<any>;
}

const LoadingMore: React.FC<IProps> = ({ padding, colSpan, isTop, shouldDisplay, classes }) => {
  return (
    <>
      {padding > 0 && shouldDisplay && (
        <tr>
          <td
            data-testid={`table-loading-more-text`}
            colSpan={colSpan}
            className={clsx(
              classes.shimmerTdCell,
              classes.paddingCell,
              isTop ? classes.paddingTopCel : classes.paddingBottomCell
            )}
            style={{ height: `${padding}px`, textAlign: "center" }}>
            Loading more rows...
          </td>
        </tr>
      )}
    </>
  );
};

export default React.memo(LoadingMore);
