import React from "react";

import makeStyles from "@material-ui/core/styles/makeStyles";
import SplashIcon from "icons/NewUX/SplashIcon";
import { SplashSection } from "src/components";
import { DataSourcesHelperText, SplashSectionHelperText } from "./utils/DataSources.constants";

type Props = {
  onClick: () => void;
};

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    height: "calc(100vh - 64px - 254px)",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.grey[700]
  },
  gridIcon: {
    height: 215,
    width: 215,
    marginRight: 140
  }
}));

const SplashSectionWrapper = (props: Props) => {
  const classes = useStyles();

  return (
    <SplashSection
      gridContainerStyle={classes.gridContainer}
      gridIconStyle={classes.gridIcon}
      button={{
        color: "primary",
        dataTestId: "addNewConnector",
        onClick: props.onClick,
        label: `+ ${DataSourcesHelperText.NewDataSource}`
      }}
      titleSection={{
        title: SplashSectionHelperText.Title,
        subtitleLines: [SplashSectionHelperText.SubLine1, SplashSectionHelperText.SubLine2]
      }}
      icon={<SplashIcon />}
    />
  );
};

export { useStyles };
export default SplashSectionWrapper;
