import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

import api from "services/AxiosClient/AxiosClient";
import {
  FindProjectRunsJobTypeEnum,
  FindProjectRunsSubTypeEnum,
  ProjectRunDetailDto
} from "@rapidcanvas/rc-api-core";
import { IProjectReturn } from "./useRunDetails";

const QUERY_KEY_ALL_PROJECT_RUNS = "query-key-all-project-runs";

export interface IProjectRun {
  dto: IProjectReturn;
  lastRunEntries: Record<string, any>[];
  lastRunEntry: Record<string, any>;
}

const useGetAllProjectRuns = (
  projectId?: string,
  isManual?: boolean,
  options?: UseQueryOptions<ProjectRunDetailDto[]>
): UseQueryResult<ProjectRunDetailDto[]> => {
  return useQuery({
    queryKey: [QUERY_KEY_ALL_PROJECT_RUNS, projectId, isManual],
    queryFn: async () => {
      if (!projectId) {
        throw "projectId is absent";
      }

      const projectRuns = await api.fetchResponse(
        async () =>
          await api.ProjectRunControllerApi.findProjectRuns(
            projectId,
            FindProjectRunsJobTypeEnum.PredictionJob,
            isManual ? FindProjectRunsSubTypeEnum.Manual : FindProjectRunsSubTypeEnum.Scheduler
          )
      );

      return projectRuns;
    },
    ...options,
    refetchOnMount: true
  });
};

export default useGetAllProjectRuns;
export { QUERY_KEY_ALL_PROJECT_RUNS };
