import React from "react";

import { Button, CircularProgress, Grid, makeStyles } from "@material-ui/core";

import useHelpers from "../../../hooks/useHelpers";

import { SearchField } from "src/components";
import {
  DatasetHelperText,
  DatasetKeys,
  OntologyDatasetStatuses
} from "../../../utils/Dataset.constants";

const useStyles = makeStyles((theme) => ({
  root: {
    columnGap: theme.spacing(2),
    "& [class^='MuiButton-label']": {
      columnGap: 5
    }
  },

  // @REFACTOR
  // The below styles are added only to support New UX. It shall be removed soon.
  searchField: {
    "& [class^='MuiInputBase-root']": {
      background: "none",
      border: "1px solid #ccc",
      borderRadius: 4
    },
    "& input": {
      paddingTop: 10,
      paddingBottom: 10,
      fontSize: "small"
    }
  }
}));

const OntologyHeader = (props: $TSFixMe) => {
  const {
    datasetIsOntologyStore,
    dataset,
    searchTerm,
    setSearchTerm,
    refetchOntologySchema,
    setIsFetchingOntologySampleData,
    refetchOntologySampleData,
    updateDatasetLite
  } = props || {};

  const classes: $TSFixMe = useStyles();

  const { isOntologyProcessing } = useHelpers({ ontologyDatasetIndex: 0 });

  const onSearchChange = (event: $TSFixMe) => {
    setSearchTerm(event?.target?.value);
  };

  const onUpdateSchema = async () => {
    await updateDatasetLite();

    await refetchOntologySchema();

    setIsFetchingOntologySampleData(() => true);
    await refetchOntologySampleData();
  };

  return (
    <Grid container className={classes.root}>
      {datasetIsOntologyStore &&
        dataset?.[DatasetKeys.OntologyConfig]?.[DatasetKeys.IsSchemaOrOntologyDirty] && (
          <Button
            id="datasetDataTypeAction"
            variant="contained"
            color="primary"
            disabled={
              dataset?.[DatasetKeys.OntologyConfig]?.[DatasetKeys.Status] ===
              OntologyDatasetStatuses.Updating
            }
            onClick={onUpdateSchema}>
            {dataset?.[DatasetKeys.OntologyConfig]?.[DatasetKeys.Status] ===
            OntologyDatasetStatuses.Updating ? (
              <CircularProgress size={23} color="inherit" />
            ) : (
              DatasetHelperText.Apply
            )}
          </Button>
        )}
      <SearchField
        id="sqlConfigurationOntologySearch"
        placeholder="Search"
        className={classes.searchField}
        value={searchTerm}
        onChange={onSearchChange}
        disabled={isOntologyProcessing}
      />
    </Grid>
  );
};

export default OntologyHeader;
