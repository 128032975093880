import React, { useState } from "react";
import _ from "lodash";

import DataappPrompts from "../CreateDataApp/DataappPrompts";
import CreateDataAppForm from "../CreateDataApp/CreateDataAppForm";
import {
  CreateDataAppRequestDtoDataAppTypeEnum,
  DataAppDtoAppTemplateTypeEnum,
  DataAppDtoDataAppTypeEnum,
  DFSRunConfigGroupDto
} from "@rapidcanvas/rc-api-core";
import { Tabs, Tab, TabPanel } from "src/components";
import { dataAppConfigFields } from "../CreateDataApp/CreateDataAppForm";
import EditDataAppSettings from "../EditDataApp/EditDataAppSettings";

const TABS = {
  details: {
    key: "details",
    label: "Details"
  },
  settings: {
    key: "settings",
    label: "Settings"
  },
  starterPrompts: {
    key: "starterPrompts",
    label: "Starter Prompts"
  }
};

interface IProps {
  recipes: (DFSRunConfigGroupDto & {
    allowed: boolean;
    helpText: string;
  })[];
  type: "auto-create" | "import-zip";
  values: Record<string, any>;
  onInputChange: (e: any) => void;
  setValues: (values: Record<string, any>) => void;
  onHyperLinkError: (error: string) => void;
  errorMsgs: Record<string, string | undefined>;
  recipeLoading: boolean;
  disabledAddDataAppActionMessage?: string;
  disabledAskAICreateActionMessage?: string;
}

const CreateDataAppTabs: React.FC<IProps> = (props) => {
  const {
    recipes,
    values,
    type,
    errorMsgs,
    recipeLoading,
    disabledAddDataAppActionMessage,
    disabledAskAICreateActionMessage,
    onInputChange,
    setValues,
    onHyperLinkError
  } = props;

  const [tab, setTab] = useState(TABS.details.key);

  return (
    <div>
      <Tabs
        value={tab}
        onChange={setTab}
        textColor="primary"
        indicatorColor="primary"
        variant="scrollable">
        <Tab value={TABS.details.key} label={TABS.details.label} />
        {type === "auto-create" &&
          _.get(values, dataAppConfigFields.inputType) !==
            CreateDataAppRequestDtoDataAppTypeEnum.RapidModel && (
            <Tab value={TABS.settings.key} label={TABS.settings.label} />
          )}
        {type === "auto-create" &&
          _.get(values, dataAppConfigFields.inputType) !==
            CreateDataAppRequestDtoDataAppTypeEnum.RapidModel && (
            <Tab value={TABS.starterPrompts.key} label={TABS.starterPrompts.label} />
          )}
      </Tabs>
      <div
        style={{
          padding: "16px 0px 0px 0px",
          height: "calc(100vh - 170px)",
          minHeight: "calc(100vh - 170px)",
          overflowY: "auto",
          overflowX: "hidden"
        }}>
        <TabPanel index={TABS.details.key} value={tab}>
          <CreateDataAppForm
            recipes={recipes}
            errorMsgs={errorMsgs}
            values={values}
            dataAppType={type}
            disabledAddDataAppActionMessage={disabledAddDataAppActionMessage}
            disabledAskAICreateActionMessage={disabledAskAICreateActionMessage}
            recipeLoading={recipeLoading}
            setValues={setValues}
            handleInputChange={onInputChange}
          />
        </TabPanel>
        {type === "auto-create" &&
          _.get(values, dataAppConfigFields.inputType) !==
            CreateDataAppRequestDtoDataAppTypeEnum.RapidModel && (
            <TabPanel index={TABS.settings.key} value={tab}>
              <EditDataAppSettings
                dataAppType={DataAppDtoDataAppTypeEnum.Askai}
                appTemplateType={DataAppDtoAppTemplateTypeEnum.Reactjs}
                values={values}
                onInputChange={onInputChange}
                setValues={setValues}
                onHyperLinkError={onHyperLinkError}
              />
            </TabPanel>
          )}
        {type === "auto-create" &&
          _.get(values, dataAppConfigFields.inputType) !==
            CreateDataAppRequestDtoDataAppTypeEnum.RapidModel && (
            <TabPanel index={TABS.starterPrompts.key} value={tab}>
              <DataappPrompts
                suggestionPrompts={_.get(values, dataAppConfigFields.suggestionPrompts)}
                onChange={onInputChange}
              />
            </TabPanel>
          )}
      </div>
    </div>
  );
};

export default CreateDataAppTabs;
