import api from "services/AxiosClient/AxiosClient";
import { useMutation, UseMutationResult } from "@tanstack/react-query";
import axios from "axios";
import { download } from "utils/helpers/file.helpers";
import { handleResponse } from "services/Apis/Apis.service";

interface IVariable {
  id: string;
  name: string;
}

const useDownloadDataFiles = (): UseMutationResult<any, unknown, IVariable, unknown> => {
  return useMutation({
    mutationFn: async ({ id, name }) => {
      const data = await api.fetchResponse(() => api.DataFileControllerApi.exportDataFile(id));

      if (data.signedUrl) {
        await axios
          .get(data?.signedUrl, {
            responseType: "arraybuffer",
            headers: { ...data.headers, "Access-Control-Allow-Origin": "*" }
          })
          .then((response) => {
            download(response.data, name, response.headers["content-type"]);
          })
          .catch((error) => {
            handleResponse({
              errorMessage:
                error.response?.data?.msg || error.message || `Error in downloading files - ${name}`
            });
          });
      } else {
        handleResponse({ errorMessage: "Something went wrong !!" });
      }
    }
  });
};

export default useDownloadDataFiles;
