import React, { Dispatch, ReactNode, SetStateAction, useMemo } from "react";

// Packages
import { Link, generatePath } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

// MUI
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import MuiLink from "@material-ui/core/Link";

// Icons
import { FileImportIcon } from "icons/NewUX";

// Hooks
import { IEntity, QUERY_KEY_ENTITIES } from "src/hooks/api/entities/useEntities";

// Utils
import { dateFormat } from "utils/helpers/date.helpers";
import { WebPaths } from "src/routing/routes";

// Components

// Constants
import { SourceType } from "src/pages/private/ProjectsModule/pages/Dataset/utils/Dataset.constants";

// Styles
import useStyles from "./Source.styles";
import { Button, Tooltip } from "@material-ui/core";
import { MODE } from "./Source";
import { InfoOutlined } from "@material-ui/icons";
import Table from "src/components/custom/TableV8/Table";
import { CellContext, ColumnDef } from "@tanstack/react-table";
import { TData } from "src/components/custom/TableV8/typing";

type Props = {
  isJobPath: boolean;
  isDefaultScenario: boolean;
  dataset: IEntity;
  fileNames: ReactNode;
  connectorsMap: { [key: string]: any };
  mode: MODE | undefined;
  setMode: Dispatch<SetStateAction<MODE | undefined>>;
};

const SourceTable = (props: Props) => {
  const { isJobPath, dataset, fileNames, connectorsMap, isDefaultScenario, mode, setMode } =
    props || {};

  const classes = useStyles();

  const queryClient = useQueryClient();

  const onEdit = () => {
    queryClient.invalidateQueries([QUERY_KEY_ENTITIES]);
    setMode(MODE.EDIT);
  };

  const onAuditHistory = () => {
    setMode(MODE.AUDIT_HISTORY);
  };

  const columns = useMemo(
    () => [
      {
        id: "Source Type",
        accessorKey: "sourceType",
        header: "Source Type",
        cell: ({ row }: CellContext<IEntity, any>) => {
          const { dataSourceId } = row?.original || {};

          if (!dataSourceId) {
            return (
              <Grid container direction="row">
                <Box className={classes.sourceImage}>
                  <FileImportIcon viewBox="0 0 18 18" />
                </Box>
                <Box>Local File</Box>
              </Grid>
            );
          }

          const connector = connectorsMap[dataSourceId ?? SourceType.FileUpload];

          return (
            <Grid container direction="row">
              <Box className={classes.sourceImage}>{connector?.dataSourceType?.image}</Box>
              <Box>{connector?.dataSourceType?.displayName}</Box>
            </Grid>
          );
        }
      },
      ...(!!dataset?.dataSourceId
        ? [
            {
              id: "Connection Name",
              accessorKey: "dataSourceName",
              header: "Connection Name",
              cell: ({ row }: CellContext<IEntity, any>) => {
                const { dataSourceId, dataSourceName } = row?.original || {};

                return !!dataSourceId ? (
                  <Link
                    className={classes.link}
                    to={generatePath(WebPaths.DataConnector, { id: dataSourceId })}>
                    {dataSourceName}
                  </Link>
                ) : (
                  dataSourceName
                );
              }
            }
          ]
        : [
            {
              id: "File Names",

              header: () => (
                <span
                  style={{ fontSize: "14px", gap: "5px", display: "flex", alignItems: "center" }}>
                  File Names
                  <Tooltip arrow title="Click on file name to download">
                    <InfoOutlined color="disabled" fontSize="small" />
                  </Tooltip>
                </span>
              ),
              cell: () => fileNames
            }
          ]),
      {
        id: "Updated on",
        accessorKey: "updated",
        header: "Updated on",
        cell: ({ row }: CellContext<IEntity, any>) => {
          const { updated, created } = row.original || {};

          return <span>{(dateFormat(updated) || dateFormat(created)) ?? ""}</span>;
        }
      },
      ...(!!connectorsMap[dataset?.dataSourceId]?.dataSourceType?.isSql
        ? [
            {
              id: "Query",
              accessorKey: "query",
              header: "Query",
              cell: () =>
                mode === MODE.EDIT ? (
                  !!isJobPath || !isDefaultScenario ? (
                    "View"
                  ) : (
                    "Edit"
                  )
                ) : (
                  <MuiLink className={classes.link} onClick={onEdit}>
                    {!!isJobPath || !isDefaultScenario ? "View" : "Edit"}
                  </MuiLink>
                )
            }
          ]
        : []),

      {
        id: "Actions",
        accessorKey: "query",
        header: "Actions",
        cell: () => (
          <Button
            size="small"
            color="primary"
            variant="outlined"
            disabled={mode === MODE.AUDIT_HISTORY}
            onClick={onAuditHistory}>
            Audit History
          </Button>
        )
      }
    ],
    [isJobPath, dataset?.dataSourceId, isDefaultScenario, fileNames, connectorsMap, mode, setMode]
  );

  return <Table data={[dataset]} columns={columns as ColumnDef<TData, any>[]} />;
};

export default SourceTable;
