import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";

import api from "services/AxiosClient/AxiosClient";
import { AI_GUIDE_MESSAGES_KEY } from "./useGetAIGuideMessages";
import { filter } from "lodash";
import { ThreadResponseDto } from "@rapidcanvas/rc-api-core";
import { AI_GUIDE_THREADS_KEY } from "./useGetAIGuideThreads";

interface IVariables {
  threadId: string;
  projectId: string;
}

export const useDeleteAIGuideThread = (): UseMutationResult<
  unknown,
  unknown,
  IVariables,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ threadId, projectId }) => {
      const response = await api.fetchResponse(() => api.AiControllerApi.deleteThread1(threadId));
      queryClient.setQueryData([AI_GUIDE_MESSAGES_KEY, threadId!], []);
      queryClient.setQueryData(
        ["projects", projectId, AI_GUIDE_THREADS_KEY],
        (prevData: ThreadResponseDto[] | undefined) => {
          if (!prevData) return [];
          return filter(prevData, (thread) => thread.threadId !== threadId);
        }
      );
      return response;
    }
  });
};
