import { useMutation, UseMutationResult } from "@tanstack/react-query";

import { handleResponse, postAPIWithRethrow } from "services/Apis/Apis.service";
import { SUCCESSFULLY_COPIED_MSG } from "pages/Projects/CopyProject/utils/CopyDownloadProject.constants";
import { ICopyPayload } from "./useCopyProject";

const useDownloadProject = (): UseMutationResult<unknown, unknown, ICopyPayload, unknown> => {
  return useMutation({
    mutationFn: async (body) => {
      return await postAPIWithRethrow(`/v2/projects/export`, body);
    },
    onSuccess: () => {
      handleResponse({ successMessage: SUCCESSFULLY_COPIED_MSG });
    }
  });
};

export default useDownloadProject;
