// Packages

// Stores
import { useDrawerStore } from "stores/zustand/stores";
import { sideComponentSetter } from "stores/zustand/stores.selectors";

// Open API
import { DataSourceDto } from "@rapidcanvas/rc-api-core";

// Components

// Types
import { NodeData } from "src/types";
import DestinationModal from "src/components/Canvas/CanvasDrawerItems/DestinationModal";

type Props = {
  canAddStandardRecipe: boolean;
  data: NodeData;
  connector: DataSourceDto | any;
  disabledMessage: string;
};

const useActions = (props: Props) => {
  const { canAddStandardRecipe, data, connector, disabledMessage } = props || {};

  // Stores - STARTS >>
  const setSideDrawerStore = useDrawerStore(sideComponentSetter);
  // << ENDS - Stores

  const onClick = () => {
    setSideDrawerStore({
      sideComponent: null,
      sideComponentProps: null
    });

    if (!!disabledMessage) {
      return;
    }

    if (!!data?.jobProps) {
      if (!data?.isJobCanvasPath) {
        return;
      }
    }

    const drawerData = {
      ...data.parentDataset,
      connector,
      projectId: data.projectId,
      scenarioId: data?.scenarioId,
      jobProps: data?.jobProps
    };

    setSideDrawerStore({
      sideComponent: DestinationModal,
      sideComponentProps: { noHeader: true, data: drawerData, canAddStandardRecipe }
    });
  };

  const onDoubleClick = () => {
    return;
  };

  return { onClick, onDoubleClick };
};

export default useActions;
