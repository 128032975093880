import React, { useEffect, useMemo } from "react";
import { Grid, TextField, Tooltip, makeStyles } from "@material-ui/core";
import { useForm } from "react-hook-form";

import NewModal from "src/components/Modal/NewModal";
import { validateNameField } from "utils/helpers/form.helpers";
import { InfoOutlined } from "@material-ui/icons";
import _ from "lodash";

const useStyles = makeStyles({
  namesList: {
    flexWrap: "nowrap",
    gap: "8px",
    paddingBottom: "20px"
  },
  flex: {
    display: "flex",
    alignItems: "center",
    gap: "6px"
  }
});

type FormData = {
  [key: string]: string;
};

export const MODEL = "model";
export const ShowUpdateNameDialog = ({
  onClose,
  onSubmit,
  isSubmitDisabled,
  label,
  queryOutputs,
  isSubmitLoading,
  title
}: {
  onClose: () => void;
  onSubmit: (data: any) => void;
  isSubmitDisabled: boolean;
  queryOutputs: { name: string; isModel?: boolean }[];
  isSubmitLoading: boolean;
  label: string;
  title: string;
}) => {
  const classes = useStyles();
  const {
    handleSubmit,
    register,
    trigger,
    formState: { isValid, errors }
  } = useForm<FormData>({
    mode: "all",
    defaultValues: queryOutputs.reduce(
      (acc, output, index) => ({
        ...acc,
        [index?.toString()]: output.name?.replace(/[^a-zA-Z0-9-_ ]/g, "_")
      }),
      {}
    )
  });

  useEffect(() => {
    trigger();
  }, [trigger]);

  const modelIndex = useMemo(() => _.findIndex(queryOutputs, { isModel: true }), [queryOutputs]);

  const onFormSubmit = (data: any) => {
    const formValues: Record<string, string> = {};
    _.forEach(_.values(data), (val, index) => {
      const path = modelIndex && index === modelIndex ? MODEL : queryOutputs[index].name;
      formValues[path] = val;
    });

    onSubmit(formValues);
  };

  const header = (
    <div className={classes.flex}>
      <span>{title}</span>
      <Tooltip title="Unsupported characters will be replaced by underscore">
        <InfoOutlined style={{ width: "18px", height: "18px" }} />
      </Tooltip>
    </div>
  );

  return (
    <NewModal
      open
      header={header}
      onClose={onClose}
      loading={isSubmitLoading}
      onFinish={handleSubmit(onFormSubmit)}
      width="465px"
      height={350}
      maxHeight="500px"
      submitBtnTestId="addToRecipeBtn"
      background="#fff"
      submitButtonLabel="Add to Recipe"
      submitDisabled={isSubmitDisabled || !isValid}
      isDefaultButtonCustomTheming>
      <Grid container direction="column" className={classes.namesList}>
        {queryOutputs.map((output: { name: string; isModel?: boolean }, index: number) => {
          const key = index.toString();
          return (
            <TextField
              key={index}
              {...register(key, {
                required: "This field is required.",
                validate: {
                  isValid: (value) => {
                    const { error, isValid: isNameValid } = validateNameField({
                      fieldName: value,
                      fieldNameLabel: `output name`
                    });
                    return isNameValid ? true : error;
                  }
                }
              })}
              required
              fullWidth
              data-testid="output-name-textfield"
              size="small"
              error={!_.isEmpty(errors[key]?.message)}
              helperText={errors[key]?.message}
              label={output.isModel ? "Model Name" : label}
              variant="outlined"
              placeholder={`Provide value for ${output.name}`}
            />
          );
        })}
      </Grid>
    </NewModal>
  );
};
