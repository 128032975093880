import { Tooltip } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import { get, isFunction, map, values } from "lodash";
import React, { useMemo } from "react";
import { Menu } from "src/components/custom";
import { AiAssistedIcon } from "icons/NewUX/AiAssistedRecipeIcon";
import ApiConnectorIcon from "icons/NewUX/ApiConnectorIcon";
import { PlusIcon } from "icons/NewUX/PlusIcon";
import { TemplateRecipeIcon } from "icons/NewUX/TemplateRecipeIcon";

import { RecipeMenuKeys } from "src/pages/ViewData/AddMenu";

interface IProps {
  isUnbuilt: boolean;
  canAddStandardRecipe: boolean;
  canAddAiAssistedRecipe?: boolean;
  addTemplateRecipe: () => void;
  addAiAssistedRecipe?: () => void;
  onAddApiConnectorRecipe?: () => void;
}

const ArtifactAddMenu: React.FC<IProps> = (props) => {
  const { isUnbuilt, addTemplateRecipe, addAiAssistedRecipe, onAddApiConnectorRecipe } =
    props || {};
  const recipeMenuDetails = useMemo(
    () => ({
      [RecipeMenuKeys.AiAssistedRecipe]: {
        key: RecipeMenuKeys.AiAssistedRecipe,
        label: "AI-assisted",
        icon: <AiAssistedIcon viewBox="0 1 20 20" opacity={0.8} />,
        action: addAiAssistedRecipe,
        isDisabled: isUnbuilt,
        hide: false,
        testId: "add-ai-assisted-recipe-menu-item"
      },

      [RecipeMenuKeys.TemplateRecipe]: {
        key: RecipeMenuKeys.TemplateRecipe,
        label: "Template",
        icon: <TemplateRecipeIcon viewBox="1 2.5 16 16" opacity={0.8} />,
        action: addTemplateRecipe,
        isDisabled: isUnbuilt,
        hide: false,
        testId: "add-template-recipe-menu-item"
      },
      [RecipeMenuKeys.ApiConnectorRecipe]: {
        key: RecipeMenuKeys.ApiConnectorRecipe,
        label: "Code",
        icon: <ApiConnectorIcon />,
        action: onAddApiConnectorRecipe,
        isDisabled: isUnbuilt,
        hide: !isFunction(onAddApiConnectorRecipe),
        tooltip: ""
      }
    }),
    [isUnbuilt, onAddApiConnectorRecipe]
  );

  const recipeMenuItems = useMemo(() => {
    const visibleRecipeMenuItems = values(recipeMenuDetails).filter((menuItem) => !menuItem.hide);

    return map(visibleRecipeMenuItems, (menuItem) => ({
      key: menuItem?.key,
      label: menuItem?.label,
      icon: menuItem?.icon,
      isDisabled: menuItem?.isDisabled
    }));
  }, [recipeMenuDetails]);
  const menuItems = useMemo(
    () => [
      {
        label: (
          <>
            <span>Recipes</span>
            {isUnbuilt && (
              <Tooltip title="These actions are not applicable for Unbuilt artifacts">
                <InfoOutlined
                  style={{ width: 16, height: 16, marginLeft: "6px", marginBottom: "2px" }}
                />
              </Tooltip>
            )}
          </>
        ),
        isDisabled: !isUnbuilt,
        menuItems: recipeMenuItems,
        key: "recipe"
      }
    ],
    [recipeMenuItems]
  );

  return (
    <Menu
      buttonProps={{
        IconButtonProps: {
          color: "primary",
          size: "small",
          "data-testid": "add-menu-icon"
        },

        icon: <PlusIcon width={28} height={28} />
      }}
      menuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left"
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left"
        }
      }}
      onClick={(event) => {
        get(recipeMenuDetails, event.key)?.action();
      }}
      menuItems={menuItems}
    />
  );
};

export default ArtifactAddMenu;
