import { capitalize, get } from "lodash";
import React from "react";
import { Button, Typography, CircularProgress, Grid, makeStyles } from "@material-ui/core";
import { EnvDto } from "@rapidcanvas/rc-api-core";

import StopEnvironment from "src/pages/private/EnvironmentsModule/pages/Environments/components/StopEnvironment";
import { useStatusWithStopEnvState } from "src/pages/private/EnvironmentsModule/pages/Environments/hooks/useStatusWithStopEnvState";
import { EnvironmentStatusValuesMapping } from "src/pages/private/EnvironmentsModule/utils/Environments.constants";
import useStatusBarStyles from "src/pages/private/EnvironmentsModule/pages/common/StatusBar/StatusBar.styles";
import clsx from "clsx";

const useStatusStyles = makeStyles({
  btn: {
    height: "24px",
    fontSize: "13px",
    color: "#000000"
  },
  statusText: {
    textAlign: "center",
    display: "flex",
    fontSize: "13px",
    alignItems: "center",
    height: "100%"
  },
  statusTextGrid: {
    textAlign: "center",
    flex: 1
  },
  statusBar: {
    borderRadius: "6px",
    textTransform: "capitalize",
    fontWeight: 400,
    gap: "16px",
    textAlign: "center",
    padding: "4px 12px"
  }
});

export const StatusBarNew = ({ environment }: { environment: EnvDto }) => {
  const { launchStatus: status } = environment;
  const {
    confirmStopEnvironment,
    resetStopEnvironment,
    onClick,
    showConfirmStopEnvModal,
    statusText,
    showProgress,
    statusClass
  } = useStatusWithStopEnvState({ environment });

  const statusStyles: any = useStatusBarStyles();
  const styles = useStatusStyles();

  return (
    <>
      {!!showConfirmStopEnvModal && (
        <StopEnvironment
          data={showConfirmStopEnvModal}
          confirmStopEnvironment={confirmStopEnvironment}
          resetStopEnvironment={resetStopEnvironment}
        />
      )}
      <Grid
        container
        direction="row"
        wrap="nowrap"
        className={clsx(styles.statusBar, [statusStyles[statusClass]])}>
        <Grid item className={styles.statusTextGrid}>
          <Typography variant="body2" data-testid="envStatusBarText" className={styles.statusText}>
            {statusText}
          </Typography>
        </Grid>
        <Grid>
          {!!showProgress ? (
            <CircularProgress size="20px" />
          ) : (
            <Button
              size="small"
              onClick={onClick}
              style={{ color: "#000000 !important" }}
              className={styles.btn}>
              {!!status && capitalize(get(EnvironmentStatusValuesMapping, [status, "buttonText"]))}
            </Button>
          )}
        </Grid>
      </Grid>
    </>
  );
};
