import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

import { postAPIWithRethrow } from "services/Apis/Apis.service";
import { IEdaChartsData } from "./useGenerateEdaCharts";

const QUERY_KEY_EDA_CHARTS = "query-key-eda-charts";

const useGetEdaCharts = (
  itemId?: string,
  scenarioId?: string,
  projectRunEntryId?: string,
  options?: UseQueryOptions<IEdaChartsData["chartJson"]>
): UseQueryResult<IEdaChartsData["chartJson"]> => {
  return useQuery({
    queryKey: [QUERY_KEY_EDA_CHARTS, itemId, scenarioId, projectRunEntryId],
    queryFn: async () => {
      const results = await postAPIWithRethrow(
        `/v2/entities/${itemId}/eda-charts?readOnly=true`,
        {
          scenarioId,
          projectRunEntryId
        },
        {},
        true
      );

      return results?.edaChartsInfo?.chartJson ?? null;
    },
    ...options
  });
};

export default useGetEdaCharts;
export { QUERY_KEY_EDA_CHARTS };
