/* eslint-disable no-unused-vars */
import { useMutation } from "@tanstack/react-query";

import { postAPIWithRethrow } from "services/Apis/Apis.service";

type Props = {
  onSuccess?: (data: $TSFixMe) => void;
};

export const useCreateTenantMutation = ({ onSuccess }: Props = {}) => {
  return useMutation({
    mutationFn: ({
      name,
      description,
      licenseKey
    }: {
      name: string;
      description: string;
      licenseKey: string;
    }) => {
      return postAPIWithRethrow(`/tenants`, { licenseKey, name, description }).then(
        async (data: $TSFixMe) => {
          await onSuccess?.(data);
          return data;
        }
      );
    }
  });
};
