import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import _, { filter, last } from "lodash";
import { Badge, Button, Tooltip, Typography } from "@material-ui/core";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import shallow from "zustand/shallow";
import { StackIcon } from "icons/NewUX";

import AddMenu from "./AddMenu";
import CanvasSearch from "./CanvasSearch";
import MoreOptionsMenu from "./MoreOptionsMenu";
import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import { useGetProject } from "src/hooks/api";
import useRelaunchEnvironment from "hooks/api/environments/useRelaunchEnvironment";
import { useNodeMultiSelection } from "projectsModule/hooks";
import { AddArtifactsModal } from "../common/AddArtifactsModal";
import { AddModelsModal } from "../common/AddModelsModal";
import { ScenarioSettings } from "../../../assets/icons/ScenarioSettings";
import { checkIfDefaultScenario, NodeTypes } from "src/pages/private/ProjectsModule/utils";
import { createEntityWithRethrow } from "services/Apis/wrappers";
import { handleResponse } from "../../../services/Apis/Apis.service";
import { useAccessControlContext } from "src/routing/PrivateRoute/accessControlContext/useAccessControlContext";
import { useScenariosStore, useCanvasStore } from "../../../stores/zustand/stores";
import { WebPaths } from "src/routing/routes";
import { RunOptions } from "src/pages/private/ProjectsModule/components";
import PublishJobsModal from "src/pages/private/ProjectsModule/pages/Dag/components/PublishJobsModal/PublishJobsModal";
import NewThemeWrapper from "src/styles/NewThemeWrapper";

interface IProps {
  scenarioId: string;
  onAddDatasetAction: () => void;
  onAddFileAction: () => void;
  onRecipeRunsPopoverAction: (event: React.MouseEvent<HTMLButtonElement>) => void;
  setIsFromArtifactsModelModal: (val: boolean) => void;
}

const CanvasFlowHeader: React.FC<IProps> = (props) => {
  const {
    scenarioId,
    onAddDatasetAction,
    onAddFileAction,
    onRecipeRunsPopoverAction,
    setIsFromArtifactsModelModal
  } = props || {};

  const { projectId } = useParams();
  const relaunch = useRelaunchEnvironment();

  const { getSelectedNodesByType } = useNodeMultiSelection();

  const { data: project } = useGetProject({
    projectId,
    includeJobCount: true,
    refetchOnMount: true
  });

  useEffect(() => {
    if (project?.envId) {
      relaunch.mutate({ envId: project?.envId });
    }
  }, [project?.envId]);

  const [
    setNodeToFocus,
    setReloadTrigger,
    openRecipeRunsQueueStore,
    isPendingRecipeRunsInQueueStore
  ] = useCanvasStore(
    (state) => [
      state.setNodeToFocus,
      state.setReloadTrigger,
      state.openRecipeRunsQueue,
      state.isPendingRecipeRunsInQueue
    ],
    shallow
  );

  const { canRunProject, canViewScenarios, canAddStandardRecipe, canAddArtifacts, canAddModels } =
    useAccessControlContext();
  const navigate = useNavigate();

  const recipeRunsPopoverActionRef = useRef<HTMLButtonElement>(null);
  const [isAddArtifactModalOpen, setIsAddArtifactModalOpen] = useState(false);
  const [isAddModelsModalOpen, setIsAddModelsModalOpen] = useState(false);
  const [publishJobsModalType, setPublishJobsModalType] = useState<
    "scheduler" | "predictionScheduler" | null
  >(null);
  const [loading, setLoading] = useState(false);
  const [modelLoading, setModelLoading] = useState(false);
  const [scenarios] = useScenariosStore((state) => [state.scenarios]);
  const nodes = useCanvasStore((state) => state.nodes);

  const { entityNodes, artifactNodes, dfsGroupNodes, modelNodes, textNodes } = useMemo(() => {
    const groupBy = _.groupBy(nodes, (node) => (node as $TSFixMe)?.type?.toLowerCase());

    return {
      dfsGroupNodes: _.get(groupBy, "dfsgroup", []),
      entityNodes: filter(_.get(groupBy, "entity"), (item: any) => item.status !== "UNBUILT") ?? [],
      artifactNodes:
        filter(_.get(groupBy, "artifact"), (item: any) => item.status !== "UNBUILT") ?? [],
      modelNodes: filter(_.get(groupBy, "model"), (item: any) => item.status !== "UNBUILT") ?? [],
      textNodes: filter(_.get(groupBy, "file"), (item: any) => item.status !== "UNBUILT") ?? []
    };
  }, [nodes]);

  const isDefaultScenario = useMemo(
    () => checkIfDefaultScenario(null, scenarios, scenarioId),
    [scenarioId, scenarios]
  );

  const handleAddRecipeClick = useCallback(() => {
    sessionStorage.removeItem("configGroupId");
    navigate(`/projects/${projectId}/scenario/${scenarioId}/add-recipe`);
  }, [scenarioId, projectId]);

  const handleAddCodeRecipeClick = useCallback(() => {
    sessionStorage.removeItem("configGroupId");
    navigate(`/projects/${projectId}/scenario/${scenarioId}/add-code-recipe`);
  }, [scenarioId, projectId]);

  const handleAddAutoAiRecipeClick = useCallback(() => {
    sessionStorage.removeItem("configGroupId");
    navigate(`/projects/${projectId}/scenario/${scenarioId}/add-auto-ml-recipe`);
  }, [scenarioId, projectId]);

  const handleAddApiConnectorRecipe = () => {
    if (projectId && scenarioId) {
      navigate(generatePath(WebPaths.APIConnectorRecipeContainer, { projectId, scenarioId }));
    }
  };

  const handleScenarioSettings = useCallback(() => {
    if (!projectId || !scenarioId) {
      return;
    }

    navigate(generatePath(WebPaths.Scenario, { projectId, scenarioId }));
  }, [scenarioId, projectId]);

  const selectedEntities = getSelectedNodesByType({ type: NodeTypes.Dataset, getByKey: "id" });

  const createEntities = React.useCallback(
    async (entityNames: Array<string>, entityViewType: string) => {
      const entityBody = {
        entityMeta: {
          entityViewType,
          entityType: "EVENT"
        },
        projectId
      };
      try {
        const response = await Promise.all(
          entityNames.map(async (artifactName: string) => {
            return await createEntityWithRethrow({
              ...entityBody,
              name: artifactName
            });
          })
        );

        setReloadTrigger();

        const lastCreatedEntityName = last(response)?.name;
        if (!!lastCreatedEntityName) {
          setNodeToFocus(lastCreatedEntityName);
          setIsFromArtifactsModelModal(true);
        }
      } catch (error: $TSFixMe) {
        handleResponse({
          errorMessage:
            error.response?.data?.msg ||
            error.message ||
            `Error in adding ${entityViewType === "ARTIFACT" ? "artifacts" : "models"}`
        });
      }
    },
    [projectId, setReloadTrigger]
  );

  const onAddArtifacts = React.useCallback(
    async (artifactNames: Array<string>) => {
      const existingArtifactNames = artifactNodes?.map((artifact: $TSFixMe) => artifact.name);
      const newArtifacts = artifactNames.filter((name) => !existingArtifactNames.includes(name));
      setLoading(true);
      await createEntities(newArtifacts, "ARTIFACT");
      setLoading(false);
      setIsAddArtifactModalOpen(false);
    },
    [artifactNodes, createEntities]
  );

  const onAddModels = React.useCallback(
    async (modelNames: Array<string>) => {
      const existingModelNames = modelNodes?.map((model: $TSFixMe) => model.name);
      const newModels = modelNames.filter((name) => !existingModelNames.includes(name));
      setModelLoading(true);
      await createEntities(newModels, "MODEL");
      setModelLoading(false);
      setIsAddModelsModalOpen(false);
    },
    [createEntities, modelNodes]
  );

  useLayoutEffect(() => {
    if (!!openRecipeRunsQueueStore && !!recipeRunsPopoverActionRef?.current) {
      const event = new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window
      });

      recipeRunsPopoverActionRef?.current?.dispatchEvent(event);
    }
  }, [openRecipeRunsQueueStore, recipeRunsPopoverActionRef.current]);

  React.useEffect(() => () => setNodeToFocus(""), []);

  const currentScenario = useMemo(
    () => scenarios?.find((scenario) => (scenario as $TSFixMe).id === scenarioId) || {},
    [scenarioId, scenarios]
  );

  return (
    <>
      <AddArtifactsModal
        loading={loading}
        open={isAddArtifactModalOpen}
        selectedArtifacts={artifactNodes}
        shouldDisableInitialSelectedRows
        onAddArtifacts={onAddArtifacts}
        onClose={() => setIsAddArtifactModalOpen(false)}
      />

      <AddModelsModal
        loading={modelLoading}
        open={isAddModelsModalOpen}
        onAddModels={onAddModels}
        selectedModels={modelNodes}
        shouldDisableInitialSelectedRows
        onClose={() => setIsAddModelsModalOpen(false)}
      />

      {!!publishJobsModalType && (
        <NewThemeWrapper>
          <PublishJobsModal
            type={publishJobsModalType}
            onClose={() => setPublishJobsModalType(() => null)}
          />
        </NewThemeWrapper>
      )}

      <SubTopNavBarWrapper
        subTopNavBarLeftSection={{
          component: (
            <Typography variant="body2" color="textPrimary">
              {project?.name}
            </Typography>
          )
        }}
        subTopNavBarRightSection={{
          component: (
            <>
              <CanvasSearch setNodeToFocus={setNodeToFocus} nodes={nodes} />

              <Tooltip title="Recipe Queue">
                <Button
                  size="small"
                  color="primary"
                  variant="outlined"
                  style={{ padding: " 2px 6px", border: "1px solid #4646b5" }}
                  ref={recipeRunsPopoverActionRef}
                  onClick={onRecipeRunsPopoverAction}>
                  <Badge
                    color="error"
                    variant="dot"
                    invisible={!isPendingRecipeRunsInQueueStore}
                    overlap="circular"
                    badgeContent=" ">
                    <Typography color="primary">
                      <StackIcon color="#4646b5" />
                    </Typography>
                  </Badge>
                </Button>
              </Tooltip>

              {canRunProject && (
                <RunOptions
                  disabledMenuActionMessage={dfsGroupNodes.length < 1 ? "No recipes to run" : ""}
                />
              )}

              {isDefaultScenario ? (
                <AddMenu
                  // Datasets
                  projectId={projectId}
                  canAddArtifacts={canAddArtifacts}
                  canAddModels={canAddModels}
                  addDataset={onAddDatasetAction}
                  addFile={onAddFileAction}
                  addArtifacts={() => setIsAddArtifactModalOpen(true)}
                  addModel={() => setIsAddModelsModalOpen(true)}
                  // Recipes
                  isAddTemplateRecipeDisabled={
                    entityNodes.length < 1 &&
                    artifactNodes.length < 1 &&
                    modelNodes.length < 1 &&
                    textNodes.length < 1
                  }
                  isAddAiAssistedRecipeDisabled={
                    entityNodes.length < 1 && artifactNodes.length < 1 && modelNodes.length < 1
                  }
                  disabledAddRapidModelRecipeActionMessage={
                    entityNodes.length < 1
                      ? "No inputs to add recipe"
                      : selectedEntities?.length > 1
                        ? "Multiple datasets are selected as input. Please choose a single dataset to proceed."
                        : ""
                  }
                  canAddStandardRecipe={canAddStandardRecipe}
                  addTemplateRecipe={handleAddRecipeClick}
                  addAiAssistedRecipe={handleAddCodeRecipeClick}
                  addRapidModelRecipe={handleAddAutoAiRecipeClick}
                  onAddApiConnectorRecipe={handleAddApiConnectorRecipe}
                />
              ) : (
                <Button
                  color="primary"
                  size="small"
                  startIcon={<ScenarioSettings width={18} height={18} viewBox="0 0 24 24" />}
                  onClick={handleScenarioSettings}>
                  Scenario Settings
                </Button>
              )}

              {canViewScenarios && (
                <MoreOptionsMenu
                  menuActionLabel="Actions"
                  projectId={projectId}
                  scenarios={scenarios}
                  currentScenario={currentScenario}
                  publishJob={(type: "scheduler" | "predictionScheduler") =>
                    setPublishJobsModalType(type)
                  }
                />
              )}
            </>
          )
        }}
      />
    </>
  );
};

export default CanvasFlowHeader;
