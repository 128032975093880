import React from "react";

import { useCanvasStore } from "stores/zustand/stores";
import { validateNameField } from "utils/helpers/form.helpers";

export const useValidateRecipeName = () => {
  const nodes = useCanvasStore((state) => state.nodes);
  const dfsGroupNodes = React.useMemo(() => {
    return nodes?.filter(
      (node: $TSFixMe) => (node as $TSFixMe)?.type?.toLowerCase() === "dfsgroup"
    );
  }, [nodes]);

  const validateName = React.useCallback(
    (name: string) => {
      const nameExist = dfsGroupNodes.some(
        (item: $TSFixMe) => item.name === name || item.displayName === name
      );
      const { error } = validateNameField({
        fieldName: name,
        fieldNameLabel: `recipe name`
      });
      return { nameExist, error };
    },
    [dfsGroupNodes]
  );

  return { validateName };
};
