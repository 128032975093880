import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { CodeSuggestion } from "src/types";
import _ from "lodash";

interface CodeRecipeState {
  copilotThread: string | null | undefined;
  suggestions: CodeSuggestion[];
}

interface CodeRecipeMapState {
  recipes: Record<string, CodeRecipeState>;
  recipeThreadMap: Record<string, string>;
}

const initialState: CodeRecipeMapState = {
  recipes: {},
  recipeThreadMap: {}
};

const codeRecipeSlice = createSlice({
  name: "codeRecipe",
  initialState,
  reducers: {
    resetState: (state, action: PayloadAction<{ recipeId: string }>) => {
      const { recipeId } = action.payload;
      state.recipes = _.omit(state.recipes, recipeId);
      state.recipeThreadMap = _.omit(state.recipeThreadMap, recipeId);
    },
    setLoadingState: (
      state,
      action: PayloadAction<{
        recipeId: string;
        newSuggestion: CodeSuggestion;
        copilotThread: string | null | undefined;
      }>
    ) => {
      const { recipeId, newSuggestion, copilotThread } = action.payload;

      const existingSuggestions = _.get(state, `recipes.${recipeId}.suggestions`, []);
      _.set(state, `recipes.${recipeId}`, {
        copilotThread,
        suggestions: [...existingSuggestions, newSuggestion]
      });
    },
    setSuggestions: (
      state,
      action: PayloadAction<{
        recipeId: string;
        suggestions: CodeSuggestion[];
        copilotThread: string;
      }>
    ) => {
      const { recipeId, suggestions, copilotThread } = action.payload;

      _.set(state, `recipes.${recipeId}`, { suggestions, copilotThread });
      _.set(state, `recipeThreadMap.${recipeId}`, copilotThread);
    },
    setSuggestion: (
      state,
      action: PayloadAction<{
        recipeId: string;
        updateSuggestion: Partial<CodeSuggestion>;
        copilotThread?: string | null;
      }>
    ) => {
      const { recipeId, updateSuggestion, copilotThread } = action.payload;
      const recipe = _.get(state, `recipes.${recipeId}`);

      if (!recipe) return;

      const updatedSuggestions = recipe.suggestions.map((curr) =>
        curr.isLoading ? { ...curr, ...updateSuggestion } : curr
      );

      _.set(state, `recipes.${recipeId}.suggestions`, updatedSuggestions);

      if (copilotThread) {
        _.set(state, `recipes.${recipeId}.copilotThread`, copilotThread);
        _.set(state, `recipeThreadMap.${recipeId}`, copilotThread);
      }
    },
    setRecipeThreadMap: (state, action: PayloadAction<{ recipeId: string; threadId: string }>) => {
      const { recipeId, threadId } = action.payload;

      _.set(state, `recipeThreadMap.${recipeId}`, threadId);

      if (!_.has(state, `recipes.${recipeId}`)) {
        _.set(state, `recipes.${recipeId}`, { copilotThread: threadId, suggestions: [] });
      } else {
        _.set(state, `recipes.${recipeId}.copilotThread`, threadId);
      }
    }
  }
});

export const { setSuggestion, setLoadingState, resetState, setRecipeThreadMap, setSuggestions } =
  codeRecipeSlice.actions;

const persistConfig = {
  key: "codeRecipe",
  storage,
  whitelist: ["recipeThreadMap"]
};

export default persistReducer(persistConfig, codeRecipeSlice.reducer);
