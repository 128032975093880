import React from "react";

import Modal, { ModalVariants } from "src/components/custom/Modal/Modal";
import useDeleteProjectRun from "hooks/api/projects/useDeleteProjectRun";
import { useQueryClient } from "@tanstack/react-query";
import { QUERY_KEY_PROJECT_DETAILS } from "src/hooks/api/projects/useProjectDetails";

interface IProps {
  id: string;
  open: boolean;
  isManual: boolean;
  onSuccess: (id: string) => void;
  onCancel: () => void;
}

const DeletePredictionRunModal: React.FC<IProps> = (props) => {
  const queryClient = useQueryClient();
  const { id, isManual, open, onCancel, onSuccess } = props;
  const deleteProjectRun = useDeleteProjectRun();

  const handleSubmit = () => {
    deleteProjectRun.mutate(
      { id },
      {
        onSuccess: () => {
          onSuccess(id);
          queryClient.invalidateQueries([QUERY_KEY_PROJECT_DETAILS]);
        }
      }
    );
  };

  return open ? (
    <Modal
      open={open}
      variant={ModalVariants.Delete}
      title={isManual ? "Delete Manual Prediction" : "Delete Prediction Scheduler"}
      content={[
        isManual ? (
          <span>Do you want to delete this manual prediction?</span>
        ) : (
          <span>Do you want to delete this prediction scheduler?</span>
        ),
        isManual ? (
          <span>If you delete, your prediction will be deleted permanently!</span>
        ) : (
          <span>If you delete, your prediction scheduler will be deleted permanently!</span>
        )
      ]}
      isSubmitting={deleteProjectRun.isLoading}
      submitLabel="Yes, Delete"
      onClose={onCancel}
      onSubmit={handleSubmit}
    />
  ) : (
    <></>
  );
};

export default DeletePredictionRunModal;
