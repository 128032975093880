import React, { useEffect, useRef, useState } from "react";

// Packages
import { useParams } from "react-router";
import ReactFlow, { Background, BackgroundVariant, Controls, MiniMap } from "react-flow-renderer";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { find, first, isEmpty, isEqual } from "lodash";

// MUI
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

// Icons
import { AutoLayoutIcon, SaveIcon } from "icons/NewUX";

// Utils
import { handleResponse } from "services/Apis/Apis.service";
import { resetNodesSelectionStore } from "src/pages/private/ProjectsModule/utils/Dag.helpers";

// Open API
import { ProjectDto } from "@rapidcanvas/rc-api-core";
import { useGetAIGuideThreads } from "src/hooks/api";
import { ThreadResponseDtoTargetTypeEnum } from "@rapidcanvas/rc-api-core";

// Hooks
import useAutoLayout from "../DagFlow/hooks/useAutoLayout";
import useConnect from "../DagFlow/hooks/useConnect";
import useControlNodeDrag from "../DagFlow/hooks/useControlNodeDrag";
import useDagNodeAndEdgeTypes from "../DagFlow/hooks/useDagNodeAndEdgeTypes";
import useHighlightPath from "../DagFlow/hooks/useHighlightPath";
import useManageDagFlow from "../DagFlow/hooks/useManageDagFlow";
import useSaveNodes from "../DagFlow/hooks/useSaveNodes";

// Stores
import { RootState } from "stores/store";
import { useAIGuideStore, useViewPortStore } from "stores/zustand/stores";

// Components
import { Spinner } from "src/components";
import ExpandAllCollapseAllAction from "../ExpandAllCollapseAllAction";
import Dataset from "../Nodes/Dataset/Dataset";
import File from "../Nodes/File/File";
import Recipe from "../Nodes/Recipe/Recipe";
import Chart from "../Nodes/Chart/Chart";
import Artifact from "../Nodes/Artifact/Artifact";
import Model from "../Nodes/Model/Model";
import AIGuideBtn from "src/pages/Projects/AIGuide/AIGuideBtn";
import SnippetGenerator from "../SnippetGenerator";
import ToggleLineViewButton from "../ToggleLineViewButton";
import AIGuideDialog from "src/pages/Projects/AIGuide/common/AIGuideDialog";
import { AIGuideProps } from "src/pages/Projects/AIGuide/common/useAIGuideContext";
import DeselectNodesConfirmModal from "../DeleteNodes/DeselectNodesConfirmModal";
import DeleteNodes from "../DeleteNodes/DeleteNodes";

// Contexts
import { useDagContext } from "../../context/useDagContext";
import { useDagFlowContext } from "../DagFlow/context/useDagFlowContext";

// Constants
import { DagHelperText } from "../../utils/Dag.constants";

// Styles
import useStyles from "./DagFlowContainer.styles";

// Types
import { JobProps } from "../../Dag.types";
import Destination from "../Nodes/Destination/Destination";
import DagContextMenu from "../DagContextMenu";
import VectorDB from "../Nodes/VectorDB/VectorDB";

type Props = {
  projectId?: string;
  project: ProjectDto;
  jobProps?: JobProps;
  nodesDraggable?: boolean;
};

const proOptions = {
  account: "paid-pro",
  hideAttribution: true
};

const nodeTypes = {
  entity: Dataset,
  file: File,
  dfsgroup: Recipe,
  chart: Chart,
  artifact: Artifact,
  model: Model,
  destination: Destination,
  vector_store: VectorDB
};

const DagFlowContainer = (props: Props) => {
  const { scenarioId } = useParams() || {};
  const { projectId, project, jobProps, nodesDraggable } = props || {};
  const containerRef = useRef<HTMLDivElement | null>(null);

  const classes = useStyles();

  const selectedNodes = useSelector((state: RootState) => state.dag.selectedNodes);

  // States - STARTS >>
  const [isSaveNodesInProgress, setIsSaveNodesInProgress] = useState(false);

  // Using react-state here to check if focus is lost.
  // Ideal fix is to reset the react-router location's state object, which is a limitation from react-router.
  const [isNodeLostFocus, setIsNodeLostFocus] = useState(false);

  const [showDeselectNodesConfirmModal, setShowDeselectNodesConfirmModal] = useState(false);
  // << ENDS - States

  const [aiGuideParams, setAIGuideParams] = useState<AIGuideProps | null>(null);
  const [viewPortInfo, setViewPortInfo] = useViewPortStore((state) => [
    state.viewPortInfo,
    state.setViewPortInfo
  ]);
  const {
    data: threads,
    isLoading,
    refetch,
    isRefetching
  } = useGetAIGuideThreads({ projectId: projectId!, shouldSortAndFilter: true });

  const [getLatestGeneratingThreadId] = useAIGuideStore((state) => [
    state.getLatestGeneratingThreadId
  ]);
  const latestGeneratingThreadId = getLatestGeneratingThreadId();

  const savedViewPort = projectId && (viewPortInfo as any)?.[projectId];
  const viewPort = savedViewPort || { zoom: 1, x: 500, y: 250 };

  const lastActiveThread = first(threads);

  const { isFromArtifactsModelModal, setIsFromArtifactsModelModal } = useDagContext() || {};
  const {
    isFromDatasetPage,
    nodeActions,
    nodesStore,
    setNodeToFocusStore,
    edgeType,
    changeEdgeType,
    isRecipesRunning,
    resetNodesAndEdgesTimer,
    isDefaultScenario,
    isJobPath,
    resetSelectedNodes
  } = useDagFlowContext();

  const { edgeTypes } = useDagNodeAndEdgeTypes();
  const {
    nodes,
    setNodes,
    onNodesChange,

    edges,
    setEdges,
    onEdgesChange,

    resetNodesAndEdges,

    formatNodesToCanvas,

    minimap,
    isMinimap,
    nodeColorSelection
  } = useManageDagFlow();

  const { highlightPath } = useHighlightPath({ setNodes, setEdges });
  const { saveNodes } = useSaveNodes({ projectId });
  const [contextMenu, setContextMenu] = useState<{ x: number; y: number; width: number } | null>(
    null
  );
  const { autoLayout, autoLayoutLoading } = useAutoLayout({
    projectId,
    nodesStore,
    nodes,
    edges,
    formatNodesToCanvas,
    saveNodes
  });

  const handleContextMenuClick = (event: React.MouseEvent) => {
    event.preventDefault(); // Prevent default browser menu

    const menuHeight = 425;
    if (containerRef.current) {
      const { left, top, width, height } = containerRef.current.getBoundingClientRect();
      const menuWidth = Math.min(Math.max(width * 0.2, 165), 250);

      let posX = event.clientX - left;
      let posY = event.clientY - top;

      // Prevent overflow on the right
      if (posX + menuWidth > width) {
        posX = width - menuWidth - 5; // Adjust left position
      }

      // Prevent overflow on the bottom
      if (posY + menuHeight > height) {
        posY = height - menuHeight - 5; // Adjust top position
      }

      setContextMenu({ x: posX, y: posY, width: menuWidth });
    }
  };

  const handleClose = () => {
    setContextMenu(null);
  };
  const [showAIGuideDialog, setShowAIGuideDialog] = useState(false);

  const { onNodeDragStart, onNodeDragStop } = useControlNodeDrag({
    projectId,
    nodes,
    setNodes,
    edges
  });

  const { onConnect } = useConnect();

  const minimapStyles = clsx([classes.minimap, isMinimap ? classes.fadeIn : classes.fadeOut]);

  const isJobCanvasOld = () =>
    !!jobProps?.jobId &&
    !!jobProps?.canvasData &&
    !isEqual(
      {
        nodes: jobProps?.canvasData?.nodes || [],
        edges: jobProps?.canvasData?.edges || []
      },
      { nodes: nodes || [], edges: edges || [] }
    );

  const clearDatasetFocus = () => {
    handleClose();
    if ((!!isFromDatasetPage || !!isFromArtifactsModelModal) && !isNodeLostFocus) {
      setNodeToFocusStore("");

      // Using react-state here to check if focus is lost.
      // Ideal fix is to reset the react-router location's state object, which is a limitation from react-router.
      setIsNodeLostFocus(() => true);

      !!isFromArtifactsModelModal && setIsFromArtifactsModelModal?.(() => false);
    }
  };

  // @REFACTOR
  useEffect(() => {
    if (!!isFromDatasetPage || !!isFromArtifactsModelModal) {
      setIsNodeLostFocus(() => false);
    }
  }, [isFromDatasetPage, isFromArtifactsModelModal]);

  useEffect(() => {
    !!resetNodesAndEdgesTimer && resetNodesAndEdges();
  }, [resetNodesAndEdgesTimer]);

  const handlePaneMove = (_: any, data: any) => {
    projectId && setViewPortInfo(projectId, data);
  };

  const navigateToAIGuide = () => {
    refetch().then((response) => {
      if (response.isFetched) {
        const recentThread = latestGeneratingThreadId
          ? find(response.data, { threadId: latestGeneratingThreadId })
          : first(response.data);

        if (!recentThread) {
          handleResponse({ errorMessage: "No active chats found!" });
          return;
        }

        const queryParams = {
          projectId: projectId!,
          scenarioId: scenarioId!,
          datasetId:
            recentThread.targetType === ThreadResponseDtoTargetTypeEnum.Dataset
              ? recentThread.entityId!
              : undefined,
          chartId:
            recentThread.targetType === ThreadResponseDtoTargetTypeEnum.Chart
              ? recentThread?.targetInputs?.[0]?.chartId
              : undefined,
          modelId:
            recentThread.targetType === ThreadResponseDtoTargetTypeEnum.Model
              ? recentThread.entityId!
              : undefined,
          diamondId:
            recentThread.targetType === ThreadResponseDtoTargetTypeEnum.Chart
              ? recentThread.entityId!
              : undefined,
          chartName: recentThread?.targetInputs?.[0]?.chartName,
          targetType: recentThread.targetType!,
          datasetContext: recentThread.datasetContext
        };

        setAIGuideParams(queryParams);
        setShowAIGuideDialog(true);
      }
    });
  };

  const isAIGuideDisabled = !lastActiveThread;

  // Confirm deselect nodes - STARTS >>
  const promptConfirmDeselectNodes = () => {
    if (isEmpty(selectedNodes)) return;
    setShowDeselectNodesConfirmModal(() => true);
  };

  const resetConfirmDeselectNodes = () => {
    setShowDeselectNodesConfirmModal(() => false);
  };
  // << ENDS - Confirm deselect nodes

  return (
    <>
      {!!showDeselectNodesConfirmModal && (
        <DeselectNodesConfirmModal
          selectedNodes={selectedNodes}
          onDeselectNodes={() => {
            resetSelectedNodes();
            resetConfirmDeselectNodes();
          }}
          resetConfirmDeselectNodes={resetConfirmDeselectNodes}
        />
      )}

      <Grid container className={classes.canvasContainer}>
        <Grid item xs={12} innerRef={containerRef}>
          {contextMenu && !jobProps && isDefaultScenario && (
            <DagContextMenu contextMenu={contextMenu} setContextMenu={setContextMenu} />
          )}
          <ReactFlow
            // Metadata
            id="dag"
            className={classes.flowContainer}
            proOptions={proOptions}
            deleteKeyCode={null}
            // Config
            defaultZoom={viewPort.zoom}
            defaultPosition={[viewPort.x, viewPort.y]}
            snapToGrid
            {...(!savedViewPort || !!isJobPath ? { fitView: true } : {})}
            snapGrid={[180, 180]}
            fitViewOptions={{ minZoom: 0.5, maxZoom: 1.25 }}
            nodesDraggable={nodesDraggable && !nodeActions?.autoLayout}
            // Nodes & Edges
            nodes={nodes}
            edges={edges}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            // Actions
            // > Pane
            onPaneClick={() => {
              resetNodesAndEdges();
              promptConfirmDeselectNodes();
              resetNodesSelectionStore();
              clearDatasetFocus();
              handleClose;
            }}
            onPaneContextMenu={handleContextMenuClick}
            // > Node
            onNodesChange={onNodesChange}
            onNodeClick={clearDatasetFocus}
            onNodeDoubleClick={clearDatasetFocus}
            onNodeMouseEnter={(_event, node) => highlightPath(node, nodes, edges)}
            onNodeMouseLeave={resetNodesAndEdges}
            onNodeDragStart={(_event, node) => {
              clearDatasetFocus();
              onNodeDragStart(node);
            }}
            onNodeDragStop={onNodeDragStop}
            onNodeContextMenu={clearDatasetFocus}
            // > Edge
            onEdgesChange={onEdgesChange}
            onEdgeClick={clearDatasetFocus}
            onEdgeDoubleClick={clearDatasetFocus}
            onEdgeContextMenu={clearDatasetFocus}
            // > Others
            // @ts-ignore
            onConnect={onConnect}
            onMoveStart={minimap}
            onMove={handlePaneMove}
            // Styles`
            style={{
              ...(isJobCanvasOld() && !!jobProps?.canvasBackgroundColor
                ? { background: jobProps?.canvasBackgroundColor }
                : {})
            }}>
            {jobProps?.canvasInfo}
            <div className={classes.actionsContainer}>
              {isJobCanvasOld() && jobProps?.renderContent}

              {!!isDefaultScenario && !jobProps && !isEmpty(selectedNodes) && (
                <DeleteNodes
                  projectId={projectId}
                  scenarioId={scenarioId}
                  selectedNodes={selectedNodes}
                  resetSelectedNodes={resetSelectedNodes}
                />
              )}

              <ExpandAllCollapseAllAction setEdges={setEdges} setNodes={setNodes} />
              <ToggleLineViewButton
                isCurvedLine={edgeType !== "smoothstep"}
                onClick={changeEdgeType}
              />

              {!jobProps && (
                <>
                  <Tooltip
                    title={
                      !!nodeActions?.nodeDrag
                        ? DagHelperText.NodeDragInProgressText
                        : autoLayoutLoading
                          ? "Auto arrange action is in Progress..."
                          : `Auto arrange canvas nodes${
                              isRecipesRunning
                                ? ". This action is disabled currently as there is a running recipe in canvas."
                                : ""
                            }`
                    }>
                    <span>
                      <IconButton
                        size="small"
                        color="primary"
                        onClick={autoLayout}
                        disabled={isRecipesRunning || !!nodeActions?.nodeDrag || autoLayoutLoading}>
                        {autoLayoutLoading ? (
                          <Spinner size={12} noPadding />
                        ) : (
                          <Typography variant="body2" color="textPrimary">
                            <AutoLayoutIcon
                              width={20}
                              height={20}
                              viewBox="0 0 16 16"
                              {...(isRecipesRunning ? { opacity: 0.5 } : {})}
                            />
                          </Typography>
                        )}
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip
                    title={`Save canvas nodes orientation${
                      isRecipesRunning
                        ? ". This action is disabled currently as there is a running recipe in canvas."
                        : ""
                    }`}>
                    <span>
                      <IconButton
                        size="small"
                        color="primary"
                        disabled={isSaveNodesInProgress || isRecipesRunning}
                        onClick={() => {
                          setIsSaveNodesInProgress(true);
                          saveNodes({
                            payloadNodes: nodes,
                            onSuccess: () => {
                              handleResponse({
                                successMessage: `Canvas nodes saved successfully.`
                              });
                            },
                            onSettled: () => {
                              setIsSaveNodesInProgress(false);
                            }
                          });
                        }}>
                        {isSaveNodesInProgress ? (
                          <Spinner size={12} noPadding />
                        ) : (
                          <Typography variant="body2" color="textPrimary">
                            <SaveIcon width={21} height={21} viewBox="-2 -2 16 16" />
                          </Typography>
                        )}
                      </IconButton>
                    </span>
                  </Tooltip>
                  <SnippetGenerator projectId={projectId} projectName={project?.name} />
                </>
              )}
            </div>
            {!jobProps && !isLoading && !isAIGuideDisabled && isDefaultScenario && (
              <div className={classes.footerContainer}>
                <AIGuideBtn
                  onClick={navigateToAIGuide}
                  isLoading={isRefetching}
                  projectId={projectId!}
                />
              </div>
            )}
            {aiGuideParams && (
              <AIGuideDialog
                open={showAIGuideDialog && !!aiGuideParams}
                onClose={() => {
                  setAIGuideParams(null);
                  setShowAIGuideDialog(false);
                }}
                {...aiGuideParams}
                projectId={projectId!}
                scenarioId={scenarioId!}
              />
            )}
            <Background variant={BackgroundVariant.Dots} gap={5} color="#e5e5e6" />
            <Controls className={classes.controlsFlow} showInteractive={false} />
            <MiniMap
              nodeColor={nodeColorSelection}
              className={minimapStyles}
              nodeStrokeColor={nodeColorSelection}
              maskColor="#3232327a"
            />
          </ReactFlow>
        </Grid>
      </Grid>
    </>
  );
};

export default DagFlowContainer;
