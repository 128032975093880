import React from "react";
import clsx from "clsx";

import styles from "./AppLoadingScreen.module.scss";
import CommonLoader from "../CommonLoader";

const AppLoadingScreen = ({ requestPending }: $TSFixMe) => {
  return (
    <div
      className={clsx(styles.loadingScreenContainer, {
        [styles.loadingScreenContainerOut]: !requestPending
      })}>
      <CommonLoader />
      <span
        style={{
          fontSize: "15px",
          fontFamily: "Roboto, Helvetica, Arial, sans-serif",
          fontWeight: 400
        }}>
        Predictive AI + Generative AI platform for business leaders
      </span>
    </div>
  );
};

export default AppLoadingScreen;
