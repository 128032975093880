import React from "react";
import EditDataAppHeader from "./EditDataAppHeader";
import { DataAppDto, DataAppDtoLaunchStatusEnum } from "@rapidcanvas/rc-api-core";
import { Alert } from "@material-ui/lab";
import { Button, makeStyles } from "@material-ui/core";
import Spinner from "components/Spinner";
import _ from "lodash";
import { IStatusInfo } from "hooks/api/dataapps/useDataAppStatus";
import { generatePath, useNavigate } from "react-router";
import { WebPaths } from "routing/routes";

const useStyles = makeStyles({
  wrapper: {
    padding: "16px 16px 0px 16px"
  },
  alert: {
    borderRadius: "4px",
    padding: "0px 16px",
    alignItems: "center",
    height: "100%"
  },
  alertMessage: {
    whiteSpace: "pre",
    padding: "4px 0px"
  }
});

export const EditDataAppHeaderWrap = ({
  dataApp,
  loading,
  currentStatusInfo,
  status,
  extra,
  onSave,
  isProjectDataApps
}: {
  dataApp: DataAppDto;
  loading: boolean;
  currentStatusInfo: IStatusInfo | null;
  status?: DataAppDtoLaunchStatusEnum;
  extra?: React.ReactNode;
  onSave: (newName: string) => void;
  isProjectDataApps: boolean;
}) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    if (isProjectDataApps && dataApp.name && dataApp.projectId) {
      navigate(
        generatePath(WebPaths.ProjectDataApp, {
          projectId: dataApp.projectId,
          dataAppName: dataApp.name
        })
      );
    } else {
      if (dataApp.name) {
        navigate(
          generatePath(WebPaths.DataAppDashboard, {
            dataAppName: dataApp.name
          })
        );
      }
    }
  };

  const classes = useStyles();
  return (
    <EditDataAppHeader
      dataApp={dataApp}
      status={status}
      projectId={dataApp.projectId!}
      dataAppType={dataApp.dataAppType}
      dataAppName={dataApp.name!}
      dataAppDisplayName={dataApp.displayName ?? dataApp.name ?? ""}
      extra={
        <div style={{ display: "flex", gap: "8px" }}>
          {currentStatusInfo && (
            <Alert
              severity={currentStatusInfo.alertType}
              className={classes.alert}
              classes={{ message: classes.alertMessage }}
              icon={false}
              test-id="dataAppStatusAlert"
              action={
                currentStatusInfo?.buttonText && (
                  <Button
                    color="inherit"
                    size="small"
                    test-id="dataAppStatusUpdateBtn"
                    disabled={loading}
                    onClick={currentStatusInfo.onClick}
                    style={{ paddingTop: 0, paddingBottom: 0, textTransform: "capitalize" }}>
                    {loading ? (
                      <Spinner size={18} noPadding />
                    ) : (
                      currentStatusInfo?.buttonText.toLocaleLowerCase()
                    )}
                  </Button>
                )
              }>
              {currentStatusInfo.text}
            </Alert>
          )}
          {extra}
        </div>
      }
      onSave={onSave}
      onViewClick={handleNavigate}
    />
  );
};
