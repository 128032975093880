import React, { useMemo } from "react";

// Packages
import { includes } from "lodash";

// Components
import { Modal } from "src/components/custom";
import { ModalVariants } from "src/components/custom/Modal/Modal";

// Constants
import { CloneRecipeHelperText, CloneRecipePromptDetails } from "projectsModule/utils";

type Props = {
  isCloningRecipe: boolean;
  cloneRecipe: () => void;
  resetConfirmCloneRecipe: () => void;
};

const CloneRecipeConfirmModal = (props: Props) => {
  const { isCloningRecipe, cloneRecipe, resetConfirmCloneRecipe } = props || {};

  const disabledCloneActionMessage = useMemo(() => {
    if (!!isCloningRecipe) {
      return CloneRecipeHelperText.CloneRecipeActionInfo;
    }

    return "";
  }, [isCloningRecipe]);

  return (
    <Modal
      open
      variant={ModalVariants.Delete}
      title={CloneRecipePromptDetails.title}
      content={[
        CloneRecipePromptDetails.messageLine1,
        CloneRecipePromptDetails.messageLine2,
        CloneRecipePromptDetails.messageLine3
      ]}
      onClose={(_, reason: string) => {
        if (!includes(["escapeKeyDown", "backdropClick"], reason) || !!isCloningRecipe) {
          resetConfirmCloneRecipe();
        }
      }}
      onSubmit={cloneRecipe}
      isSubmitDisabled={!!disabledCloneActionMessage}
      submitActionInfo={disabledCloneActionMessage}
      isSubmitting={isCloningRecipe}
      cancelLabel={CloneRecipeHelperText.CancelActionLabel}
      submitLabel={CloneRecipeHelperText.SubmitActionLabel}
      hideCloseIcon
    />
  );
};

export default CloneRecipeConfirmModal;
