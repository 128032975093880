import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { ApiAccessKeyDto } from "@rapidcanvas/rc-api-core";
import api from "services/AxiosClient/AxiosClient";

export const QUERY_KEY_API_KEYS = "query-key-api-keys";

const useApiKeys = (
  options?: UseQueryOptions<ApiAccessKeyDto[], any>
): UseQueryResult<ApiAccessKeyDto[]> => {
  return useQuery({
    queryKey: [QUERY_KEY_API_KEYS],
    queryFn: async () => {
      const response = await api.fetchResponse(
        async () => await api.ApiAccessKeyControllerApi.getAllApiAccessKeys()
      );
      return response;
    },
    refetchOnMount: true,
    keepPreviousData: true,
    ...options
  });
};

export default useApiKeys;
