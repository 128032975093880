import React from "react";

// Components
import Search from "src/components/custom/Search/Search";
import SubTopNavBarBreadcrumbs from "./SubTopNavBarBreadcrumbs";
import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import { ToggleView } from "src/components/custom";

// Context
import { useProjectContext } from "src/pages/private/ProjectsModule/context/useProjectContext";
import { listViewPages, PagesViewConfiguration } from "constants/index";
import AddDataAppButton from "./AddDataAppButton";
import { useGetRole } from "src/hooks/useGetRole";

interface IProps {
  openCreateDataApp: boolean;
  isTilesView: boolean;
  onViewChange: (tilesView: boolean) => void;
  onSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick: (type: "auto-create" | "import-zip") => void;
  searchValue: string;
}

export const DataAppsHeader: React.FC<IProps> = (props) => {
  const { isTilesView, openCreateDataApp, onSearch, searchValue, onViewChange, onClick } = props;
  const { isRoleYieldsDataAppView } = useGetRole();

  // Project context
  const { project } = useProjectContext() || {};
  const pagesViewConfiguration = JSON.parse(localStorage.getItem(PagesViewConfiguration) || "{}");

  const toggleView = (bool: boolean) => {
    onViewChange(bool);
    localStorage.setItem(
      PagesViewConfiguration,
      JSON.stringify({ ...pagesViewConfiguration, [listViewPages.DATAAPPS]: bool })
    );
  };

  return (
    <SubTopNavBarWrapper
      subTopNavBarLeftSection={{
        component: (
          <SubTopNavBarBreadcrumbs project={project} openCreateDataApp={openCreateDataApp} />
        )
      }}
      subTopNavBarRightSection={{
        component: !openCreateDataApp && (
          <>
            <Search
              placeholder="Search DataApps"
              value={searchValue}
              onSearch={onSearch}
              data-testid="seachDataAppsField"
            />
            {!isRoleYieldsDataAppView && <AddDataAppButton onClick={onClick} />}
            <ToggleView
              isPrimaryView={isTilesView}
              setIsPrimaryView={toggleView}
              buttonProps={{ "data-testid": "dataAppToggleView" }}
            />
          </>
        )
      }}
    />
  );
};
