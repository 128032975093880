import { AxiosError } from "axios";
import { useMutation } from "@tanstack/react-query";

import { TextContentBlock } from "openai/resources/beta/threads/messages";
import { first } from "lodash";
import { useDispatch } from "react-redux";
import { setLoadingState, setSuggestion } from "src/stores/codeRecipeSlice";
import { SomethingWentWrongText } from "utils/helpers";
import api from "services/AxiosClient/AxiosClient";
import { useSSEMutation } from "src/hooks/useSSEMutation";

interface IVariables {
  threadId?: string | null;
  code: string;
  query: string;
  recipeId: string;
  model: string;
}

export const useFetchCodeSuggestions = () => {
  const dispatch = useDispatch();
  const sseMutation = useSSEMutation<string>();
  const handleError = ({
    errorMessage,
    threadId,
    recipeId
  }: {
    errorMessage: string;
    threadId?: string | null;
    recipeId: string;
  }) => {
    dispatch(
      setSuggestion({
        recipeId,
        copilotThread: threadId,
        updateSuggestion: {
          response: errorMessage || SomethingWentWrongText,
          isError: true,
          isLoading: false
        }
      })
    );
  };
  return useMutation<void, AxiosError, IVariables>({
    mutationFn: async ({ recipeId, threadId, code, query, model }) => {
      dispatch(
        setLoadingState({
          recipeId,
          newSuggestion: { query, code, isLoading: true },
          copilotThread: threadId
        })
      );
      let currentThreadId = threadId;

      // Step 1: Create a new thread if not exists
      if (!currentThreadId) {
        const threadResponse = await api.fetchResponse(() =>
          api.CodeAssistantControllerApi.createCodeAssistThread()
        );
        currentThreadId = (threadResponse as any).id;
      }

      // Step 2: Add user message with code + query
      await api.fetchResponse(() =>
        api.CodeAssistantControllerApi.createCodeAssistMessage(
          currentThreadId!,
          `${code ? `Here is some code:\n${code}\n\n` : ""}: Question: ${query}`
        )
      );

      const assistantResponse: any = await api.fetchResponse(() =>
        api.CodeAssistantControllerApi.createOrGetCodeAssistant(model)
      );
      const assistant_id = assistantResponse.id;
      // Step 4: Stream response updates
      let completeResponse = "";

      sseMutation.mutate({
        url: `/api/code/assist/${currentThreadId}/runs`,
        body: {
          assistant_id,
          stream: true,
          model
        },
        onSuccessCondition: (message: any) =>
          message.status === "completed" && message.object === "thread.message",
        onMessage: (message: any) => {
          try {
            if (message.object === "thread.message.delta") {
              const delta = message.delta;
              if (delta?.content) {
                const deltaContent = first(delta.content) as TextContentBlock;
                if (deltaContent && deltaContent.text) {
                  completeResponse += deltaContent.text.value;
                }
                dispatch(
                  setSuggestion({
                    recipeId,
                    copilotThread: currentThreadId,
                    updateSuggestion: { response: completeResponse }
                  })
                );
              }
            }
          } catch (error) {
            throw new Error(`Error parsing response stream`);
          }
        },
        onSuccess: (message: any) => {
          try {
            const completedMessage = first(message.content) as TextContentBlock;
            dispatch(
              setSuggestion({
                recipeId,
                copilotThread: currentThreadId,
                updateSuggestion: {
                  response: completedMessage.text.value,
                  messageId: message.id,
                  isLoading: false
                }
              })
            );
          } catch (error) {
            throw new Error(`Error parsing response stream`);
          }
        },
        onError: (error: Error) => {
          handleError({
            threadId: currentThreadId,
            recipeId,
            errorMessage:
              (error as AxiosError<Error>)?.response?.data?.message || (error as Error)?.message
          });
        }
      });
    },
    onError: (error, variables) => {
      const { recipeId, threadId } = variables;
      handleError({
        threadId,
        recipeId,
        errorMessage:
          (error as AxiosError<Error>)?.response?.data?.message || (error as Error)?.message
      });
    }
  });
};
