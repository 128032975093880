import React, { useEffect } from "react";
import {
  Grid,
  Box,
  Button,
  IconButton,
  FormControl,
  FormLabel,
  Switch,
  Tooltip,
  TextField
} from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";

import { dataAppConfigFields } from "./CreateDataAppForm";
import { safeParseJSON } from "src/utils/helpers";
import _ from "lodash";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { TrashIcon } from "icons/NewUX/TrashIcon";

interface IProps {
  readOnly?: boolean;
  value: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  mapping: string;
  updateHyperLinks: (parsedMapping: { [key: string]: string }) => void;
  updateHyperLinkError?: (error: string) => void;
}

const ColumnHyperlinks: React.FC<IProps> = ({
  updateHyperLinks,
  mapping,
  readOnly,
  value,
  onChange,
  updateHyperLinkError
}) => {
  const {
    control,
    setValue,
    trigger,
    watch,
    formState: { errors }
  } = useForm({
    defaultValues: {
      columnsHyperlinksMapping: Object.entries(
        safeParseJSON(mapping, {}) as { string: string }
      ).map(([key, value]) => ({
        key,
        value
      }))
    },
    mode: "onChange"
  });

  const columnsHyperlinksMapping = watch("columnsHyperlinksMapping");
  const errorValues = Object.values(errors);

  useEffect(() => {
    const firstHyperlinkError = errors?.columnsHyperlinksMapping?.[0];
    const firstError = firstHyperlinkError?.key?.message || firstHyperlinkError?.value?.message;
    updateHyperLinkError?.(firstError || "");
  }, [errorValues.length]);

  const { fields, prepend, remove } = useFieldArray({
    control,
    name: "columnsHyperlinksMapping"
  });

  useEffect(() => {
    const newMapping = Object.fromEntries(
      columnsHyperlinksMapping.map((field) => [field.key, field.value])
    );
    updateHyperLinks(newMapping);
  }, [JSON.stringify(columnsHyperlinksMapping)]);

  const addColumnHyperlink = () => {
    prepend({ key: "", value: "" });
  };

  return (
    <>
      <FormControl component="fieldset" disabled={readOnly}>
        <FormLabel component="legend">
          Allow Column Hyperlinks{" "}
          <Tooltip title="Allows users to create hyperlinks between columns in related tables">
            <InfoOutlined style={{ fontSize: "1rem", marginBottom: "2px", color: "#8a8989" }} />
          </Tooltip>
        </FormLabel>
        <Switch
          checked={value}
          name={dataAppConfigFields.allowColumnHyperLink}
          color="primary"
          onChange={onChange}
          data-testid="columnHyperlinks"
        />
      </FormControl>

      {value && (
        <>
          <Box my={1}>
            <Button
              variant="outlined"
              color="primary"
              onClick={addColumnHyperlink}
              disabled={readOnly}
              data-testid="addColumnHyperlinkButton">
              Add Column Hyperlink
            </Button>
          </Box>

          <Box>
            {!_.isEmpty(fields) && (
              <Box pt={1}>
                {_.map(fields, (field, index) => {
                  const columnNameError = errors?.columnsHyperlinksMapping?.[index]?.["key"];
                  const columnLinkError = errors?.columnsHyperlinksMapping?.[index]?.["value"];
                  return (
                    <Box key={`columnHyperlink.${field.id}`} mb={2}>
                      <Grid container alignItems="flex-start" spacing={2}>
                        <Grid item xs={5}>
                          <Controller
                            name={`columnsHyperlinksMapping.${index}.key`}
                            control={control}
                            rules={{
                              required: `The column name cannot be blank.`,
                              minLength: 1
                            }}
                            render={({ field: controllerField }) => (
                              <TextField
                                {...controllerField}
                                label={"Column Name"}
                                variant="outlined"
                                fullWidth
                                required
                                error={!!columnNameError}
                                helperText={columnNameError && <>{columnNameError?.message}</>}
                                size="small"
                                onChange={(e) => {
                                  setValue(`columnsHyperlinksMapping.${index}.key`, e.target.value);

                                  trigger();
                                }}
                              />
                            )}
                          />
                        </Grid>

                        <Grid item xs={5}>
                          <Controller
                            name={`columnsHyperlinksMapping.${index}.value`}
                            control={control}
                            rules={{
                              required: `The column link cannot be blank.`,
                              minLength: 1
                            }}
                            render={({ field: controllerField }) => (
                              <TextField
                                {...controllerField}
                                label={"Column Link"}
                                error={!!columnLinkError}
                                helperText={columnLinkError && <>{columnLinkError?.message}</>}
                                variant="outlined"
                                required
                                fullWidth
                                size="small"
                                onChange={(e) => {
                                  setValue(
                                    `columnsHyperlinksMapping.${index}.value`,
                                    e.target.value
                                  );
                                  trigger();
                                }}
                              />
                            )}
                          />
                        </Grid>

                        <Grid item xs={2}>
                          <IconButton color="secondary" onClick={() => remove(index)} size="small">
                            <TrashIcon viewBox="0 0 20 20" />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Box>
                  );
                })}
              </Box>
            )}
          </Box>
        </>
      )}
    </>
  );
};

export default ColumnHyperlinks;
