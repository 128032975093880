import React from "react";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  makeStyles,
  Tooltip,
  Typography
} from "@material-ui/core";
import { DraggableProvided } from "react-beautiful-dnd";

import OverflowTooltip from "src/components/OverflowTooltip";
import { DragHandle } from "src/assets/icons/DragHandle";
import { IColumn } from "../TableSettingsMenu";

const useStyles = makeStyles({
  listItem: {
    padding: "0 14px",
    borderTop: "1px solid #e1e1e1",

    "& label[class*='MuiFormControlLabel-root']": {
      marginBottom: "0",
      width: "100%",
      overflow: "hidden",
      "& > span": {
        overflow: "hidden",
        whiteSpace: "nowrap"
      }
    }
  }
});

interface IProps {
  item: IColumn;
  isColumnLimitReached: boolean;
  provided: DraggableProvided;
  style?: React.CSSProperties;
  onChange: (event: React.ChangeEvent<{}>, checked: boolean) => void;
}

const DragItem: React.FC<IProps> = ({
  provided,
  item: column,
  style,
  isColumnLimitReached,
  onChange
}) => {
  const classes = useStyles();
  return (
    <Grid
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={{
        ...style,
        ...provided.draggableProps.style,
        flexWrap: "nowrap"
      }}
      container
      justifyContent="space-between"
      alignItems="center"
      className={classes.listItem}>
      <FormControlLabel
        value={column.name}
        control={
          <Tooltip
            title={
              !column.checked && isColumnLimitReached
                ? "Selecting more columns than the maximum supported limit(50) will automatically deselect the previously chosen columns."
                : ""
            }>
            <Checkbox size={"small"} checked={column.checked} color="primary" />
          </Tooltip>
        }
        label={
          <OverflowTooltip
            value={<Typography variant="body2">{column.name}</Typography>}
            title={column.name}
            tooltipProps={{ placement: "left-start" }}
          />
        }
        labelPlacement="end"
        onChange={onChange}
      />
      <Grid {...provided.dragHandleProps}>
        <DragHandle />
      </Grid>
    </Grid>
  );
};

export default DragItem;
