import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";

import { handleResponse, postAPIWithRethrow } from "services/Apis/Apis.service";
import { updateProjectsQueryData, UpdateQueriesDataActionEnum } from "src/utils/helpers";
import { SUCCESSFULLY_COPIED_MSG } from "pages/Projects/CopyProject/utils/CopyDownloadProject.constants";

import useAuthStore from "stores/zustand/auth.store";

export interface IProjectRunData {
  name: string;
  projectId: string;
  modelEntityId: string;
}

export interface ICopyPayload {
  name: string;
  projectId: string;
  tenantId: string;
  dataAppIds: string[];
  projectRunIds: string[];
  envId: string;
  predictionServices: string[];
}

const useCopyProject = (): UseMutationResult<unknown, unknown, ICopyPayload, unknown> => {
  const queryClient = useQueryClient();

  const [tenantId] = useAuthStore((state) => [state.tenantId]);

  return useMutation({
    mutationFn: async (body) => {
      const data = await postAPIWithRethrow(`/v2/projects/copy?async=false`, body);
      return data;
    },
    onSuccess: (data) => {
      handleResponse({ successMessage: SUCCESSFULLY_COPIED_MSG });

      // Updating the projects query-data when a project is copied within or for the current tenant.
      if (tenantId === data?.tenantId) {
        // Adding some default value for seamless project card or list-item to render.
        const response = {
          id: data?.id,
          name: data?.name,
          description: "",
          recipeCount: 0,
          dataSetCount: 0,
          scenarioCount: 0,
          viewCount: 0,
          artifactsCount: 0,
          modelsCount: 0,
          jobCount: 0,
          predictionJobCount: 0,
          dataAppCount: 0
        };

        // Adding a new entry to the projects query-data first.
        updateProjectsQueryData({
          queryClient,
          data: response,
          action: UpdateQueriesDataActionEnum.Create
        });

        // Updating the entry for complete data.
        updateProjectsQueryData({
          queryClient,
          data: response,
          fetchData: true
        });
      }
    }
  });
};

export default useCopyProject;
