import { Grid, Popover } from "@material-ui/core";
import React from "react";
import { OverflowTooltip } from "src/components";
import { Entity } from "src/types";
import { useStyles } from "./InputDataset";
import FileEntity from "./FileEntity";

interface IProps {
  anchorEl: HTMLDivElement | null;
  handleClose: () => void;
  dataset: Entity;
}

const FilePreviewPopover: React.FC<IProps> = ({ anchorEl, handleClose, dataset }) => {
  const classes = useStyles();
  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left"
      }}
      PaperProps={{
        style: { maxWidth: "100%", maxHeight: "100%", overflowY: "hidden" }
      }}
      onClose={handleClose}>
      <Grid container direction="column" style={{ width: "500px", height: "500px" }}>
        <Grid className={classes.header} style={{ maxWidth: "500px", height: "45px" }}>
          <OverflowTooltip
            value={dataset.name}
            style={{ whiteSpace: "nowrap" }}
            testId="recipeDatasetName"
          />
        </Grid>
        <Grid
          style={{
            height: "calc(100% - 45px)",
            width: "500px",
            padding: "5px",
            background: "white"
          }}>
          <FileEntity dataset={dataset} isPopover />
        </Grid>
      </Grid>
    </Popover>
  );
};

export default FilePreviewPopover;
