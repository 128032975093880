// Packages
import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { isArray } from "lodash";

// Utils
import api from "services/AxiosClient/AxiosClient";

// Open API
import { DataSourceDashBoardDto } from "@rapidcanvas/rc-api-core";

export const enum UseGetConnectorsQueryKeys {
  Connectors = "connectors"
}

interface IConnectorsProps extends UseQueryOptions<unknown, unknown, DataSourceDashBoardDto[]> {}

const useGetConnectors = (props: IConnectorsProps): UseQueryResult<DataSourceDashBoardDto[]> => {
  const { ...options } = props;

  return useQuery({
    queryKey: [UseGetConnectorsQueryKeys.Connectors],
    queryFn: async () =>
      await api.fetchResponse(
        async () => await api.DataSourceControllerApi.getCurrentTenantDataSources()
      ),
    select: (data) => (isArray(data) ? data : []),
    staleTime: 0, // Ensures data is always considered stale
    cacheTime: Infinity, // Keeps the data in memory indefinitely
    ...options
  });
};

export default useGetConnectors;
