import React, { useMemo } from "react";
import { FormControl, InputLabel, makeStyles } from "@material-ui/core";
import { generatePath } from "react-router-dom";

import Select from "components/Select/Select";
import { OpenInNew } from "@material-ui/icons";
import { dataAppConfigFields } from "../CreateDataApp/CreateDataAppForm";
import { DataAppDto } from "@rapidcanvas/rc-api-core";
import { WebPaths } from "routing/routes";

const useStyles = makeStyles({
  link: {
    color: "#003656",
    "&:hover": {
      textDecoration: "none"
    }
  },

  icons: {
    fontSize: "1rem",
    marginBottom: "2px",
    color: "#8a8989"
  }
});

interface IProps {
  dataApp: DataAppDto;
}

const EditDataAppRecipe: React.FC<IProps> = (props) => {
  const { dataApp } = props;
  const { link, icons } = useStyles();
  const { projectId, scenarioId, groupId } = dataApp;

  const href = useMemo(() => {
    if (projectId && scenarioId && groupId) {
      return `${window.location.origin}${generatePath(WebPaths.AutoMLRecipeContainer, { projectId, scenarioId, groupId })}`;
    }
  }, [projectId, scenarioId, groupId]);

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
      <FormControl style={{ width: "100%" }}>
        <InputLabel shrink htmlFor={dataAppConfigFields.recipeId}>
          Recipe Name *
        </InputLabel>
        <Select
          required
          disabled
          values={[{ label: dataApp.groupDisplayName ?? "", value: dataApp.groupId ?? "" }]}
          data-testid="dataAppRecipeName"
          name={dataAppConfigFields.recipeId}
          variant="outlined"
          placeholder="Rapid Model Recipe not available"
          label="Recipe Name"
          value={dataApp.groupId}
        />
      </FormControl>
      <a className={link} href={href} rel="noreferrer" target="_blank">
        <OpenInNew className={icons} />
      </a>
    </div>
  );
};

export default EditDataAppRecipe;
