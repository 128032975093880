import { useQuery, UseQueryOptions, UseQueryResult, useMutation } from "@tanstack/react-query";

import {
  getDestinationsWithRethrow,
  createDestinationWithRethrow,
  updateDestinationWithRethrow,
  deleteDestinationWithRethrow
} from "services/Apis/wrappers/projects";

type PrefetchingJobDestinationsProps = { queryClient: $TSFixMe; jobId: string };

interface JobDestinationsProps extends UseQueryOptions<$TSFixMe> {
  jobId: string;

  // useQuery options
  cacheTime?: number;
}

export const prefetchJobDestinations = (props: PrefetchingJobDestinationsProps) => {
  const { queryClient, jobId } = props;

  queryClient.prefetchQuery({
    queryKey: ["destinations", jobId],
    queryFn: () => getDestinationsWithRethrow(jobId)
  });
};

export const useGetJobDestinations = (props: JobDestinationsProps): UseQueryResult<$TSFixMe> => {
  const {
    jobId,

    // useQuery options
    cacheTime = 0,
    ...options
  } = props;

  return useQuery({
    queryKey: ["destinations", jobId],
    queryFn: () => getDestinationsWithRethrow(jobId),
    select: (data: $TSFixMe) => (Array.isArray(data) ? data : []),
    cacheTime,
    enabled: !!jobId,
    ...options
  });
};

export const useCreateJobDestination = () => {
  return useMutation({
    mutationFn: async (payload: $TSFixMe) => await createDestinationWithRethrow(payload)
  });
};

export const useUpdateJobDestination = () => {
  return useMutation({
    mutationFn: async (payload: $TSFixMe) => await updateDestinationWithRethrow(payload)
  });
};

export const useDeleteJobDestination = () => {
  return useMutation({
    mutationFn: async (destinationId: string) => await deleteDestinationWithRethrow(destinationId)
  });
};
