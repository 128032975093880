import { OutlinedTextFieldProps, TextField } from "@material-ui/core";
import React from "react";
import { EmailIdError } from "./EmailIdError";
import { startCase } from "lodash";
import { PublicScreenLabels } from "constants/index";

interface EmailInputProps extends Partial<OutlinedTextFieldProps> {
  error?: any;
}

export const EmailInput = React.forwardRef((props: EmailInputProps, ref) => {
  const { disabled, error, ...restProps } = props;
  return (
    <TextField
      id="signInEmail"
      name="email"
      innerRef={ref}
      data-testid="signInEmail"
      label={startCase(PublicScreenLabels.Email)}
      variant="outlined"
      size="small"
      type="email"
      color="primary"
      fullWidth
      autoFocus
      autoComplete="email"
      disabled={disabled}
      {...restProps}
      error={!!error}
      helperText={<EmailIdError type={error?.type} />}
    />
  );
});
