import React, { useMemo } from "react";

// Packages
import _, { isFunction, map, values } from "lodash";

// Icons
import { AiAssistedIcon } from "icons/NewUX/AiAssistedRecipeIcon";
import { DatasetIcon } from "icons/NewUX/DatasetIcon";
import { PlusIcon } from "icons/NewUX/PlusIcon";
import { RapidModelRecipeIcon } from "icons/NewUX/RapidModelRecipeIcon";
import { TemplateRecipeIcon } from "icons/NewUX/TemplateRecipeIcon";
import { WorkflowIcon } from "icons/NewUX/WorkflowIcon";

// Components
import { IItem } from "src/components/custom/Menu/Menu";
import { Menu } from "src/components/custom";
import { Tooltip } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import ApiConnectorIcon from "icons/NewUX/ApiConnectorIcon";

enum DatasetMenuKeys {
  Dataset = "DATASET"
}

export enum RecipeMenuKeys {
  TemplateRecipe = "TEMPLATE_RECIPE",
  AiAssistedRecipe = "AI_ASSISTED_RECIPE",
  RapidModelRecipe = "RAPID_MODEL_RECIPE",
  ApiConnectorRecipe = "API_CONNECTOR_RECIPE"
}

enum SegmentMenuKeys {
  Segment = "SEGMENT"
}

interface IProps {
  isTextInput?: boolean;
  isAddDatasetHidden: boolean;
  isAddDatasetDisabled?: boolean;
  isUnbuilt: boolean;
  isAddSegmentDisabled?: boolean;
  canViewSegments?: boolean;
  canAddStandardRecipe: boolean;
  canAddAiAssistedRecipe?: boolean;
  canAddRapidModelRecipe?: boolean;
  addDataset?: () => void;
  addTemplateRecipe: () => void;
  addAiAssistedRecipe?: () => void;
  addRapidModelRecipe: () => void;
  addSegment?: () => void;
  menuProps?: any;
  onAddApiConnectorRecipe?: () => void;
}

const AddMenu: React.FC<IProps> = (props) => {
  const {
    // Dataset props
    isTextInput = false,
    isAddDatasetHidden = false,
    isAddDatasetDisabled,
    addDataset,

    // Recipes props
    isUnbuilt,
    addTemplateRecipe,
    addAiAssistedRecipe,
    addRapidModelRecipe,
    onAddApiConnectorRecipe,
    canAddStandardRecipe,
    canAddAiAssistedRecipe = true,
    canAddRapidModelRecipe = true,

    // Segment props
    isAddSegmentDisabled,
    canViewSegments,
    addSegment,
    menuProps
  } = props || {};

  // Meta-data of menu-items - STARTS >>
  const datasetMenuDetails = useMemo(
    () => ({
      [DatasetMenuKeys.Dataset]: {
        key: DatasetMenuKeys.Dataset,
        label: !!isTextInput ? (!!isUnbuilt ? "Add File" : "Update File") : "File",
        icon: <DatasetIcon viewBox="0 0 20 20" opacity={0.8} />,
        action: addDataset,
        isDisabled: isAddDatasetDisabled,
        isHidden: isAddDatasetHidden,
        "data-testid": "add-dataset-menu-item"
      }
    }),
    [isTextInput, isAddDatasetDisabled, isAddDatasetHidden]
  );

  const recipeMenuDetails = useMemo(
    () => ({
      [RecipeMenuKeys.AiAssistedRecipe]: {
        key: RecipeMenuKeys.AiAssistedRecipe,
        label: "AI-assisted",
        icon: <AiAssistedIcon viewBox="0 1 20 20" opacity={0.8} />,
        action: addAiAssistedRecipe,
        isDisabled: isUnbuilt,
        testId: "add-ai-assisted-recipe-menu-item",
        hide: !canAddAiAssistedRecipe
      },
      [RecipeMenuKeys.RapidModelRecipe]: {
        key: RecipeMenuKeys.RapidModelRecipe,
        label: "Rapid Model",
        icon: <RapidModelRecipeIcon viewBox="0.5 0.5 22 22" opacity={0.8} />,
        action: addRapidModelRecipe,
        isDisabled: isUnbuilt,
        testId: "add-rapid-model-recipe-menu-item",
        hide: !canAddRapidModelRecipe
      },
      [RecipeMenuKeys.TemplateRecipe]: {
        key: RecipeMenuKeys.TemplateRecipe,
        label: "Template",
        icon: <TemplateRecipeIcon viewBox="1 2.5 16 16" opacity={0.8} />,
        action: addTemplateRecipe,
        isDisabled: isUnbuilt,
        hide: !canAddStandardRecipe,
        testId: "add-template-recipe-menu-item"
      },
      [RecipeMenuKeys.ApiConnectorRecipe]: {
        key: RecipeMenuKeys.ApiConnectorRecipe,
        label: "Code",
        icon: <ApiConnectorIcon />,
        action: onAddApiConnectorRecipe,
        isDisabled: isUnbuilt,
        hide: !isFunction(onAddApiConnectorRecipe),
        tooltip: ""
      }
    }),
    [
      isUnbuilt,
      canAddStandardRecipe,
      canAddAiAssistedRecipe,
      canAddRapidModelRecipe,
      onAddApiConnectorRecipe
    ]
  );

  const segmentMenuDetails = useMemo(
    () => ({
      [SegmentMenuKeys.Segment]: {
        key: SegmentMenuKeys.Segment,
        label: "Segment",
        icon: <WorkflowIcon viewBox="-2 0 20 20" />,
        action: addSegment,
        isDisabled: isAddSegmentDisabled,
        hide: !canViewSegments
      }
    }),
    [isAddSegmentDisabled, canViewSegments]
  );
  // ENDS - Meta-data of menu-items

  // Formatting to support MUI menu - STARTS >>

  const datasetMenuItems = useMemo(() => {
    const visibleDatasetMenuItems = values(datasetMenuDetails).filter(
      (menuItem) => !menuItem.isHidden
    );

    return map(visibleDatasetMenuItems, (menuItem) => ({
      key: menuItem?.key,
      label: menuItem?.label,
      icon: menuItem?.icon,
      isDisabled: menuItem?.isDisabled
    }));
  }, [datasetMenuDetails]);

  const recipeMenuItems = useMemo(() => {
    const visibleRecipeMenuItems = values(recipeMenuDetails).filter((menuItem) => !menuItem.hide);

    return map(visibleRecipeMenuItems, (menuItem) => ({
      key: menuItem?.key,
      label: menuItem?.label,
      icon: menuItem?.icon,
      isDisabled: menuItem?.isDisabled
    }));
  }, [recipeMenuDetails]);

  const segmentMenuItems = useMemo(() => {
    const visibleSegmentMenuDetails = values(segmentMenuDetails).filter((segment) => !segment.hide);
    return map(visibleSegmentMenuDetails, (menuItem) => ({
      key: menuItem?.key,
      label: menuItem?.label,
      icon: menuItem?.icon,
      isDisabled: menuItem?.isDisabled
    }));
  }, [segmentMenuDetails]);
  // ENDS - Formatting to support MUI menu

  const menuItems = useMemo(
    () => [
      {
        menuItems: datasetMenuItems,
        key: "dataset"
      },
      {
        label: (
          <>
            <span>Recipes</span>
            {isUnbuilt && (
              <Tooltip title="These actions are not applicable for Unbuilt datasets">
                <InfoOutlined
                  style={{ width: 16, height: 16, marginLeft: "6px", marginBottom: "2px" }}
                />
              </Tooltip>
            )}
          </>
        ),
        isDisabled: !isUnbuilt,
        menuItems: recipeMenuItems,
        key: "recipe"
      },
      {
        menuItems: segmentMenuItems,
        key: "segment"
      }
    ],
    [datasetMenuItems, recipeMenuItems, segmentMenuItems]
  );

  return (
    <Menu
      buttonProps={{
        IconButtonProps: {
          color: "primary",
          size: "small",
          "data-testid": "add-menu-icon"
        },

        icon: <PlusIcon width={30.75} height={30.75} viewBox="0 0 30.75 30.75" />
      }}
      menuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "right"
        },
        ...menuProps
      }}
      menuItems={menuItems}
      onClick={(menuItem: IItem) =>
        (
          _.get(datasetMenuDetails, menuItem.key) ||
          _.get(recipeMenuDetails, menuItem.key) ||
          _.get(segmentMenuDetails, menuItem.key)
        )?.action(menuItem)
      }
    />
  );
};

export default AddMenu;
