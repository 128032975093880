import { WebPaths } from "./routes";

export const docsToRouteMapping = [
  {
    path: WebPaths.About,
    url: "/basic/projects/projects#generating-the-about-content-for-the-project"
  },
  {
    path: WebPaths.Scenarios,
    url: "/basic/projects/segments-and-scenarios#managing-scenarios"
  },
  {
    path: WebPaths.NewScenario,
    url: "/basic/projects/segments-and-scenarios#creating-a-scenario"
  },
  {
    path: WebPaths.Scenario,
    url: "/basic/projects/segments-and-scenarios#creating-a-scenario"
  },
  {
    path: WebPaths.Segment,
    url: "/basic/projects/segments-and-scenarios#creating-a-custom-segment"
  },
  {
    path: WebPaths.ViewData,
    url: "/basic/connectors/connect-to-external-dataconnectors#viewing-the-dataset-information"
  },
  {
    path: WebPaths.AppendDataset,
    url: "/basic/connectors/importing-dataset-s-from-the-local-system"
  },
  {
    path: WebPaths.ArtifactsAndModels,
    url: "/basic/artifacts-and-models#creating-an-artifact"
  },
  {
    path: WebPaths.ArtifactsDetails,
    url: "/basic/artifacts-and-models#project-level"
  },
  {
    path: WebPaths.ModelDetails,
    url: "/basic/artifacts-and-models#viewing-models"
  },
  {
    path: WebPaths.ProjectArtifactsAndModels,
    url: "/basic/artifacts-and-models#adding-artifacts-at-the-project-level"
  },
  {
    path: WebPaths.ProjectModelsDetails,
    url: "/basic/artifacts-and-models#adding-models-at-the-project-level"
  },
  {
    path: WebPaths.AuthPath,
    url: "/"
  },
  {
    path: WebPaths.AutoMLRecipeContainer,
    url: "/basic/projects/recipes/rapid-model-recipe"
  },
  {
    path: WebPaths.AutoMLNotebookContainer,
    url: "/basic/projects/recipes/rapid-model-recipe#editing-recipe-code-in-rapid-model-recipe"
  },
  {
    path: `${WebPaths.Dag}${WebPaths.Canvas}`,
    url: "/basic/projects/canvas-overview"
  },
  {
    path: WebPaths.Charts,
    url: "/basic/projects/canvas-overview#charts"
  },
  {
    path: WebPaths.CodeRecipeContainer,
    url: "/basic/projects/recipes/ai-assisted-recipe"
  },
  {
    path: WebPaths.CodeNotebookContainer,
    url: "/basic/projects/recipes/ai-assisted-recipe"
  },
  {
    path: `${WebPaths.APIConnectorRecipeContainer}/:groupId?`,
    url: "/basic/projects/recipes/code-recipe"
  },
  {
    path: WebPaths.APIConnectorNotebookContainer,
    url: "/basic/projects/recipes/code-recipe"
  },
  {
    path: `${WebPaths.JobRoutes}${WebPaths.CreateJob}`,
    url: "/basic/projects/jobs-overview#creating-a-scheduler"
  },
  {
    path: WebPaths.Dag,
    url: "/basic/projects/canvas-overview"
  },
  {
    path: WebPaths.Dashboard,
    url: "/getting-started/accessing-the-platform#platform-landing-page"
  },
  {
    path: WebPaths.DataAppDashboard,
    url: "/basic/projects/dataapps#dataapps-dashboard"
  },
  {
    path: WebPaths.DataAppDeeplink,
    url: "/basic/projects/dataapps"
  },
  {
    path: WebPaths.EditDataApp,
    url: "/basic/projects/dataapps#editing-a-dataapp"
  },
  {
    path: WebPaths.EditProjectDataApp,
    url: "/basic/projects/dataapps"
  },
  {
    path: WebPaths.DataAppLogs,
    url: "/basic/projects/dataapps#viewing-dataapp-logs"
  },
  {
    path: WebPaths.DataConnectors,
    url: "/basic/connectors/dataconnectors-overview"
  },
  {
    path: WebPaths.DataConnector,
    url: "/basic/connectors/connect-to-external-dataconnectors"
  },
  {
    path: WebPaths.Dataapps,
    url: "/basic/projects/dataapps"
  },
  {
    path: WebPaths.Dataset,
    url: "/basic/connectors/importing-dataset-s-from-the-local-system"
  },
  {
    path: WebPaths.EditPredictionJob,
    url: "/basic/projects/prediction-job/manual-prediction"
  },
  {
    path: WebPaths.EnvironmentConfig,
    url: "/basic/environments#editing-the-environment-details"
  },
  {
    path: WebPaths.EnvironmentLogs,
    url: "/basic/environments#creating-an-environment"
  },
  {
    path: WebPaths.Environments,
    url: "/basic/environments"
  },
  {
    path: `${WebPaths.JobRoutes}${WebPaths.JobArtifacts}`,
    url: "/basic/artifacts-and-models#managing-artifacts"
  },
  {
    path: `${WebPaths.JobRoutes}${WebPaths.JobAutoMLRecipe}`,
    url: "/basic/projects/recipes/rapid-model-recipe#building-an-ml-model-using-the-rapid-model-recipe"
  },
  {
    path: `${WebPaths.JobRoutes}${WebPaths.JobCanvas}`,
    url: "/basic/projects/jobs-overview"
  },
  {
    path: `${WebPaths.JobRoutes}${WebPaths.JobChart}`,
    url: "/basic/projects/canvas-overview#charts"
  },
  {
    path: `${WebPaths.JobRoutes}${WebPaths.JobCodeRecipe}`,
    url: "/basic/projects/recipes/ai-assisted-recipe"
  },
  {
    path: `${WebPaths.JobRoutes}${WebPaths.JobCompare}`,
    url: "/basic/projects/jobs-overview#comparing-the-canvas-of-the-scheduler-with-current-canvas-of-the-project"
  },
  {
    path: `${WebPaths.JobRoutes}${WebPaths.JobDataRoutes}`,
    url: "/basic/connectors/connect-to-external-dataconnectors#viewing-the-dataset-information"
  },
  {
    path: `${WebPaths.JobRoutes}${WebPaths.JobId}`,
    url: "/basic/projects/jobs-overview"
  },
  {
    path: `${WebPaths.JobRoutes}${WebPaths.JobModels}`,
    url: "/basic/projects/dataapps/prediction-service"
  },
  {
    path: WebPaths.JobRoutes,
    url: "/basic/projects/jobs-overview#managing-schedulers-in-a-project"
  },
  {
    path: `${WebPaths.JobRoutes}${WebPaths.JobRuns}`,
    url: "/basic/projects/jobs-overview#viewing-the-run-history-of-a-specific-scheduler"
  },
  {
    path: `${WebPaths.JobRoutes}${WebPaths.JobStandardRecipe}`,
    url: "/basic/projects/recipes/template-recipe#adding-a-template-recipe"
  },
  {
    path: WebPaths.PredictionJob,
    url: "/basic/projects/prediction-job/prediction-scheduler"
  },
  {
    path: WebPaths.PredictionLogs,
    url: "/basic/projects/prediction-job"
  },
  {
    path: WebPaths.ProjectDashboard,
    url: "/basic/projects/dataapps/model-dataapp#accessing-and-creating-a-model-dataapp-at-a-project-level"
  },
  {
    path: WebPaths.ProjectDataApp,
    url: "/basic/projects/dataapps/model-dataapp#accessing-and-creating-a-model-dataapp-at-a-project-level"
  },
  {
    path: WebPaths.Projects,
    url: "/basic/projects/projects"
  },
  {
    path: WebPaths.RecipeRunLogs,
    url: "/basic/projects/recipes/template-recipe#viewing-and-editing-the-recipe-details"
  },
  {
    path: WebPaths.ResetLink,
    url: "/getting-started/accessing-the-platform#resetting-the-password"
  },
  {
    path: WebPaths.ResetPasswordLinkSent,
    url: "/getting-started/accessing-the-platform#resetting-the-password"
  },
  {
    path: WebPaths.RunIdLogs,
    url: "/basic/projects/jobs-overview"
  },
  {
    path: WebPaths.SendResetPassword,
    url: ""
  },
  {
    path: WebPaths.SignIn,
    url: "/getting-started/accessing-the-platform#signing-in-to-the-platform-as-an-existing-user"
  },
  {
    path: WebPaths.SignUp,
    url: "/getting-started/accessing-the-platform#signing-up-to-the-rc-platform-as-a-new-user"
  },
  {
    path: WebPaths.StandardRecipeDataContainer,
    url: "/basic/projects/recipes/template-recipe#adding-a-template-recipe"
  },
  {
    path: WebPaths.StandardRecipeNotebookContainer,
    url: "/basic/projects/recipes/template-recipe#adding-a-template-recipe"
  },
  {
    path: WebPaths.TenantManagement,
    url: "/basic/workspaces"
  },
  {
    path: WebPaths.UserProfile,
    url: "/basic/workspaces#switching-between-workspaces-from-profile-settings"
  },
  {
    path: WebPaths.VariablesList,
    url: "/"
  },
  {
    path: WebPaths.ViewData,
    url: "/basic/connectors/connect-to-external-dataconnectors#viewing-the-dataset-information"
  },
  {
    path: WebPaths.ViewFile,
    url: "/basic/connectors/importing-text-files-from-the-local-system"
  }
];
