// Packages
import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { isArray } from "lodash";

// Open API
import api from "services/AxiosClient/AxiosClient";
import { RoleDto } from "@rapidcanvas/rc-api-core";

export const enum UseGetRolesQueryKeys {
  Roles = "roles"
}

const useGetRoles = (
  props: UseQueryOptions<unknown, unknown, RoleDto[]>
): UseQueryResult<RoleDto[]> => {
  const { ...options } = props;

  return useQuery({
    queryKey: [UseGetRolesQueryKeys.Roles],
    queryFn: async () =>
      await api.fetchResponse(async () => await api.RoleControllerApi.getAllRoles()),
    select: (data) => (isArray(data) ? data : []),
    enabled: false,
    ...options
  });
};

export default useGetRoles;
