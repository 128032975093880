import React from "react";

// Packages
import { includes } from "lodash";

// Components
import { Modal } from "src/components/custom";
import { ModalVariants } from "src/components/custom/Modal/Modal";

// Constants
import { ConfirmUpdateTextInputHelperText, ConfirmUpdateTextInputPromptDetails } from "../utils";

interface IProps {
  resetConfirmUpdateTextInput: () => void;
  updateTextInput: () => void;
}

const ConfirmUpdateTextInput: React.FC<IProps> = (props) => {
  const { resetConfirmUpdateTextInput, updateTextInput } = props;

  return (
    <Modal
      open
      variant={ModalVariants.Delete}
      title={ConfirmUpdateTextInputPromptDetails.title}
      content={[
        ConfirmUpdateTextInputPromptDetails.messageLine1,
        ConfirmUpdateTextInputPromptDetails.messageLine2
      ]}
      onClose={(_, reason: string) => {
        if (!includes(["escapeKeyDown", "backdropClick"], reason)) {
          resetConfirmUpdateTextInput();
        }
      }}
      cancelLabel={ConfirmUpdateTextInputHelperText.CancelLabel}
      submitLabel={ConfirmUpdateTextInputHelperText.SubmitLabel}
      onSubmit={updateTextInput}
      hideCloseIcon
    />
  );
};

export default ConfirmUpdateTextInput;
