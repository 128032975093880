import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { Button } from "@material-ui/core";
import clsx from "clsx";

type IPropsLoadableImage = {
  styles: Record<string, string>;
  src?: string;
  imageSubmitted?: string;
  noText: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  disabled?: boolean;
};

const LoadableImage: React.FC<IPropsLoadableImage> = ({
  styles,
  src,
  imageSubmitted,
  noText,
  setOpen,
  disabled
}) => {
  const [isLoadable, setIsLoadable] = useState<boolean>(true);

  const base64Src = useMemo(() => {
    const thisSrc = src?.split(";base64,")?.pop() || "";
    return `data:image/jpeg;base64,${thisSrc}`;
  }, [src]);

  const backgroundImageStyles = useMemo(
    () =>
      !!isLoadable
        ? {
            backgroundImage: `url(${base64Src})`
          }
        : !!src
          ? { backgroundImage: `url(${src})` }
          : {},
    [isLoadable, base64Src, src]
  );

  useEffect(() => {
    const img = new Image();

    // Check if the image loads successfully
    img.onload = () => {
      setIsLoadable(true);
    };

    // If the image fails to load, fallback to the alternative image
    img.onerror = () => {
      setIsLoadable(false);
    };

    img.src = base64Src; // Start loading the image

    // Cleanup
    return () => {
      img.onload = null;
      img.onerror = null;
    };
  }, [base64Src]);

  return (
    <Button
      id="preview-image-selector-button"
      data-testid="projectPreviewImage"
      style={backgroundImageStyles}
      className={clsx(styles.chooseImageBox, {
        [styles.chooseImageBoxSelected]: imageSubmitted,
        [styles.chooseImageBoxMargin]: !noText
      })}
      onClick={() => setOpen(true)}
      disabled={!!disabled}
    />
  );
};

export default LoadableImage;
