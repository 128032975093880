import React from "react";

import { startCase, toLower } from "lodash";

import Chip from "@material-ui/core/Chip";

import { ScenarioRunStepDtoStatusEnum } from "@rapidcanvas/rc-api-core";

import { ScenarioStatusColors, scenarioStatusesNames } from "./Scenarios.constants";

import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  chip: {
    width: 85,
    "&.info": { backgroundColor: ScenarioStatusColors.Info, color: "#000000de" },
    "&.warn": { backgroundColor: ScenarioStatusColors.Warn, color: "#000" },
    "&.error": { backgroundColor: ScenarioStatusColors.Error, color: "#fff" },
    "&.success": { backgroundColor: ScenarioStatusColors.Success, color: "#fff" }
  }
}));

export const getScenarioStatusTemplate = (status: string, renderChip?: boolean) => {
  const classes = useStyles();

  const label = scenarioStatusesNames[status] || startCase(toLower(status));

  switch (status) {
    case ScenarioRunStepDtoStatusEnum.Built:
    case ScenarioRunStepDtoStatusEnum.Success:
      if (renderChip) {
        return <Chip size="small" className={clsx(classes.chip, "success")} label={label} />;
      }

      return <span style={{ color: ScenarioStatusColors.Success }}>{label}</span>;

    case ScenarioRunStepDtoStatusEnum.Error:
      if (renderChip) {
        return <Chip size="small" className={clsx(classes.chip, "error")} label={label} />;
      }

      return <span style={{ color: ScenarioStatusColors.Error }}>{label}</span>;

    case ScenarioRunStepDtoStatusEnum.Building:
    case ScenarioRunStepDtoStatusEnum.Pending:
    case ScenarioRunStepDtoStatusEnum.Running:
      if (renderChip) {
        return <Chip size="small" className={clsx(classes.chip, "warn")} label={label} />;
      }

      return <span style={{ color: ScenarioStatusColors.Warn }}>{label}</span>;

    default:
      if (renderChip) {
        return <Chip size="small" label={label} className={clsx(classes.chip, "info")} />;
      }

      return <span>{label}</span>;
  }
};
