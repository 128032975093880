import api from "services/AxiosClient/AxiosClient";
import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { AIChatRequestDto, AIChatResponseDto } from "@rapidcanvas/rc-api-core";

interface IVariable {
  threadId: string;
  aitChatRequest: AIChatRequestDto;
  controller: AbortController;
}

const useEditChartChatMutation = (): UseMutationResult<
  AIChatResponseDto,
  unknown,
  IVariable,
  unknown
> => {
  return useMutation({
    mutationFn: async ({ threadId, aitChatRequest, controller }) => {
      return await api.fetchResponse(() =>
        api.AiControllerApi.chat1(threadId, aitChatRequest, { signal: controller.signal })
      );
    }
  });
};

export default useEditChartChatMutation;
