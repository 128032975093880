import React, { useState, useCallback, useMemo } from "react";
import _ from "lodash";
import shallow from "zustand/shallow";
import { useNavigate } from "react-router";
import {
  Box,
  Grid,
  Menu,
  makeStyles,
  Typography,
  IconButton,
  Avatar,
  Divider,
  Button,
  CircularProgress,
  MenuItem,
  ListItemIcon,
  useTheme
} from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import { useQueryClient } from "@tanstack/react-query";

import Analytics from "services/Analytics/Analytics";
import client from "services/AxiosClient/AxiosClient";
import useAuthStore from "stores/zustand/auth.store";
import { EVENTS } from "constants/analyticsEvents.constants";
import { OverflowTooltip } from "src/components";
import { UsageAndPolicyIcon } from "src/assets/icons/UsageAndPolicyIcon";
import { UserIcon } from "icons/NewUX/UserIcon";
import { logNotebooksOut } from "services/Notebook/Notebook.service";
import { postAPIWithRethrow } from "services/Apis/Apis.service";
import { shouldRefreshProjectsSetter } from "stores/zustand/stores.selectors";
import { useProjectsStore } from "stores/zustand/stores";

import { onLogout } from "src/utils/helpers";
import { WebPaths } from "src/routing/routes";

const useStyles = makeStyles((theme) => ({
  menuPaper: {
    borderRadius: "20px",
    maxWidth: "410px",
    background: "linear-gradient(34.82deg, #DCDCFF -1.9%, #FFE0CE 107.96%)",
    boxShadow: "0px 4px 4px 0px #3A3A3A40",
    border: "1px solid #FFFFFF",
    width: "250px"
  },
  userRole: {
    color: theme.palette.grey[500]
  },
  button: {
    textTransform: "none",
    fontWeight: 400
  },
  menuIcon: {
    minWidth: 24
  },
  divider: {
    marginTop: 12,
    marginBottom: 12,
    backgroundColor: theme.palette.text.primary,
    opacity: 0.3
  },
  email: {
    width: "100%",
    fontSize: "14px",
    display: "flex",
    justifyContent: "center"
  },
  avatar: {
    color: "black",
    backgroundColor: "#fff",
    boxShadow: "0px 4px 4px 0px #99999940",
    width: "60px",
    height: "60px"
  },
  wrap: {
    background: "#fff",
    border: "1px solid #C7C7C7",
    boxShadow: "0px 4px 4px 0px #00000040",
    borderRadius: "20px",
    width: "100%",
    padding: "10px 0px"
  },
  name: {
    maxWidth: "200px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontWeight: 400
  }
}));

export const TopNavBarUserMenu = () => {
  const queryClient = useQueryClient();

  const theme = useTheme();
  const classes = useStyles();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [userEmail, roleName, logoutTimer, logout] = useAuthStore(
    (state) => [state.userEmail, state.roleName, state.logoutTimer, state.logout],
    shallow
  );

  const name = useMemo(() => {
    return _.chain(userEmail).split("@").head().split(".").head().capitalize().value();
  }, [userEmail]);

  const setShouldProjectsRefresh = useProjectsStore(shouldRefreshProjectsSetter);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleClick = useCallback((e: $TSFixMe) => {
    setAnchorEl(e.currentTarget);
  }, []);

  const navigateToUserProfile = () => {
    handleClose();
    Analytics.track({
      category: EVENTS.navigateToUserProfile.category,
      action: EVENTS.navigateToUserProfile.action,
      label: EVENTS.navigateToUserProfile.category
    });
    navigate(WebPaths.UserProfile);
  };

  const navigateToServicesAgreementPage = () => {
    handleClose();
    Analytics.track({
      category: EVENTS.navigateToServicesAgreement.category,
      action: EVENTS.navigateToServicesAgreement.action,
      label: EVENTS.navigateToServicesAgreement.category
    });

    window.open("https://www.rapidcanvas.ai/services-agreement", "_blank", "noopener,noreferrer");
  };

  const handleLogout = useCallback(async () => {
    Analytics.track({
      category: EVENTS.logout.category,
      action: EVENTS.logout.action,
      label: EVENTS.logout.category
    });
    try {
      setIsLoggingOut(true);
      await postAPIWithRethrow("/logout", {});
      logout(logoutTimer);
      setShouldProjectsRefresh(false);
      client.removeToken();
      // Clearing or deleting all react-query queries across the platform.
      queryClient.clear();
      onLogout();

      // @TODO: This needs to be centralized.
      logNotebooksOut();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoggingOut(false);
    }
  }, [logout, logoutTimer, setShouldProjectsRefresh]);

  return (
    <Box display="flex" alignItems="center">
      <Divider orientation="vertical" flexItem className={classes.divider} />
      <IconButton test-id="navbar-user-menu-user-icon" onClick={handleClick}>
        <Typography color="textPrimary">
          <UserIcon color={theme.palette.text.primary} opacity={0.5} />
        </Typography>
      </IconButton>
      <Menu
        classes={{
          paper: classes.menuPaper
        }}
        onClose={handleClose}
        keepMounted
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}>
        <Box p="20px">
          <Grid container direction="column" alignItems="center" style={{ gap: "10px" }}>
            <Avatar className={classes.avatar}>{_.upperCase(_.head(userEmail))}</Avatar>
            <Typography variant="h6" className={classes.name}>
              <OverflowTooltip value={`Hi ${name}`} />
            </Typography>
            <Typography className={classes.email} component="div" test-id="user-menu-user-email">
              <OverflowTooltip value={userEmail} />
            </Typography>
            {roleName && (
              <Typography
                variant="body2"
                className={classes.userRole}
                test-id="user-menu-user-role">
                {roleName}
              </Typography>
            )}
            <div className={classes.wrap}>
              <MenuItem onClick={navigateToUserProfile} test-id="user-menu-profile-settings">
                <ListItemIcon className={classes.menuIcon}>
                  <SettingsIcon color="primary" fontSize="small" />
                </ListItemIcon>
                <Typography variant="body2" color="primary">
                  Profile Settings
                </Typography>
              </MenuItem>
              <MenuItem
                onClick={navigateToServicesAgreementPage}
                test-id="user-menu-services-agreement-btn">
                <Box ml="6px" mr="8px">
                  <UsageAndPolicyIcon />
                </Box>
                <Typography variant="body2">Privacy Policy</Typography>
              </MenuItem>
              <Box p="0px 16px">
                <Divider />
              </Box>
              <Box p="16px 16px 8px">
                <Button
                  variant="outlined"
                  color="primary"
                  disabled={isLoggingOut}
                  className={classes.button}
                  onClick={handleLogout}
                  test-id="user-menu-sign-out-btn"
                  endIcon={isLoggingOut ? <CircularProgress size={16} /> : undefined}>
                  Sign Out
                </Button>
              </Box>
            </div>
          </Grid>
        </Box>
      </Menu>
    </Box>
  );
};
