import React, { useMemo, PropsWithChildren } from "react";
import _ from "lodash";
import { Link } from "react-router-dom";

import DefaultButton from "components/Buttons/DefaultButton";
import Text from "components/Widget/Text";
import { Delete } from "src/assets/icons/Delete";
import { LinkCellWithMenu } from "components/Table/Cells/LinkCellWithMenu/LinkCellWithMenu.component";
import { TABS } from "./ModelDetails";
import { OverflowTooltip } from "src/components";
import { dateFormat } from "utils/helpers/date.helpers";
import { getSourceString, getSourceVal } from "../Artifacts/ArtifactsTable";
import { useParams } from "react-router";
import { generatePath } from "react-router";
import { WebPaths } from "src/routing/routes";
import Table from "src/components/custom/TableV8/Table";
import { Row } from "@tanstack/react-table";

interface IProps {
  data: any[];
  onDelete: (name: string) => void;
}

const ModelsTable: React.FC<PropsWithChildren<IProps>> = ({ data, children, onDelete }) => {
  const { projectId } = useParams();
  const sourceSortType = useMemo(
    () => (rowA: Row<any>, rowB: Row<any>) => {
      const valA = getSourceString(_.get(rowA.original, "producer")) || "";
      const valB = getSourceString(_.get(rowB.original, "producer")) || "";

      return valA.localeCompare(valB, undefined, { sensitivity: "base" });
    },
    []
  );

  const psSortType = useMemo(
    () => (rowA: Row<any>, rowB: Row<any>) => {
      const valA = _.get(rowA.original, "predictionServiceDetails.name", "Add");
      const valB = _.get(rowB.original, "predictionServiceDetails.name", "Add");

      return valA.localeCompare(valB, undefined, { sensitivity: "base" });
    },
    []
  );

  const columns = [
    {
      id: "Name",
      accessorKey: "name",
      header: "Name",

      meta: { isTooltip: false },
      cell: ({ row }: $TSFixMe) =>
        !row?.original?.producer && !projectId ? (
          <LinkCellWithMenu
            url={
              !!projectId
                ? generatePath(WebPaths.ProjectModelsDetails, {
                    projectId,
                    modelName: row.original?.name
                  })
                : `/artifacts-and-models/model/${row.original?.name}`
            }
            linkText={<Text value={row.original.name} />}
            menuButtons={[
              {
                label: "DELETE",
                icon: <Delete />,
                action: () => onDelete(row.original?.name)
              }
            ]}
          />
        ) : (
          <Link
            className="name-link"
            to={
              !!projectId
                ? generatePath(WebPaths.ProjectModelsDetails, {
                    projectId,
                    modelName: row.original?.name
                  })
                : `/artifacts-and-models/model/${row.original?.name}`
            }>
            <OverflowTooltip value={row.original?.name} />
          </Link>
        )
    },
    {
      id: "Created",
      accessorKey: "created",
      header: "Created",

      cell: ({ getValue }: $TSFixMe) => {
        return dateFormat(getValue());
      }
    },
    {
      id: "Prediction Service",
      accessorKey: "predictionServiceDetails",
      header: "Prediction Service",

      sortingFn: psSortType,
      cell: ({ row, getValue }: any) => {
        const value = getValue();
        return (
          <Link
            className="name-link"
            to={
              !!projectId
                ? generatePath(WebPaths.ProjectModelsDetails, {
                    projectId,
                    modelName: row.original?.name
                  })
                : `/artifacts-and-models/model/${row.original?.name}`
            }
            state={{ tab: TABS.predictionService.value, predictionServiceDetails: value }}>
            {value ? value.name : <DefaultButton>+ Add</DefaultButton>}
          </Link>
        );
      }
    },
    {
      id: "Source",
      accessorKey: "producer",
      header: "Source",

      sortingFn: sourceSortType,
      cell: ({ getValue }: $TSFixMe) => getSourceVal(getValue())
    }
  ];

  if (children) {
    return <>{children}</>;
  }

  return (
    <Table
      data={data}
      columns={columns}
      stylesProps={{ size: "small", maxHeight: "calc(100vh - 248px)", isTheadSticky: true }}
      sortingProps={{ orderByDefault: "Created", sortInverted: false }}
      infoProps={{ emptyTableMessage: "No models" }}
    />
  );
};

export default ModelsTable;
