import React, { useMemo, useState } from "react";
import clsx from "clsx";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, Divider, TextField, Typography, CircularProgress } from "@material-ui/core";

import { handleResponse } from "services/Apis/Apis.service";
import { validateNameField } from "utils/helpers/form.helpers";
import { useUpdateTenantMutation } from "src/hooks/api";
import {
  DescriptionCharacterLimit,
  DescriptionCharacterLimitMessage,
  DescriptionInfo
} from "src/pages/private/ProjectsModule/utils";
import { size } from "lodash";

const useStyles = makeStyles({
  tenantFormInput: {
    width: "100%",
    marginTop: "24px !important"
  },
  tenantFormInputLast: {
    marginBottom: "24px !important"
  },
  buttonGroup: {
    display: "flex",
    marginTop: "12px",
    gap: "12px"
  },

  subHeading: {
    fontSize: "14px",
    color: "#000000",
    opacity: 0.5
  }
});

const TenantEditForm = (props: { onClose: () => void; name: string; description: string }) => {
  const styles = useStyles();
  const { onClose, name, description } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors
  } = useForm({
    defaultValues: { tenantName: name, tenantDescription: description }
  });
  const [nameAlreadyExists, setNameAlreadyExists] = useState(false);

  const updateTenantMutation = useUpdateTenantMutation();
  const onSubmit = async (data: $TSFixMe) => {
    updateTenantMutation.mutate(
      {
        name: data.tenantName,
        description: data.tenantDescription
      },
      {
        onSuccess: () => {
          setNameAlreadyExists(false);
          onClose();
          handleResponse({ successMessage: `Workspace edited successfully.` });
        },
        onError: (error: any) => {
          const errorMessage = error?.response?.data?.msg || error.message;
          if (errorMessage === "Workspace with that name already exists") {
            setNameAlreadyExists(true);
            return;
          }
          handleResponse({ errorMessage });
        }
      }
    );
  };

  const errorInputName = useMemo(() => {
    if (errors.tenantName) return errors.tenantName.message;
    if (nameAlreadyExists) return "Workspace with that name already exists";
  }, [errors.tenantName, nameAlreadyExists]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography className={styles.subHeading}>Edit Workspace</Typography>
      <TextField
        {...register("tenantName", {
          // @ts-expect-error TS(2345) FIXME: Argument of type '{ required: true; nameExists: bo... Remove this comment to see the full error message
          nameExists: nameAlreadyExists,
          setValueAs: (val) => val?.trim(),
          validate: (value) => {
            const { error } = validateNameField({
              fieldName: value,
              fieldNameLabel: "workspace name"
            });
            return error;
          }
        })}
        size="small"
        className={styles.tenantFormInput}
        label="Workspace name"
        variant="outlined"
        error={!!errorInputName}
        helperText={errorInputName}
      />
      <Grid container direction="column">
        <TextField
          size="small"
          {...register("tenantDescription", {
            setValueAs: (val: string) => val?.trim(),
            onChange: (event) => {
              if (size(event.target.value) > DescriptionCharacterLimit) {
                setError("tenantDescription", {
                  type: "manual",
                  message: DescriptionCharacterLimitMessage
                });
              } else {
                clearErrors("tenantDescription");
              }
            }
          })}
          error={!!errors.tenantDescription}
          helperText={errors.tenantDescription && <>{errors.tenantDescription?.message}</>}
          className={clsx(styles.tenantFormInput)}
          label="Workspace description"
          variant="outlined"
        />
        <span
          style={{
            fontSize: "12px",
            fontWeight: 400,
            fontStyle: "italic",
            marginBottom: "12px",
            opacity: 0.7
          }}>
          {DescriptionInfo}
        </span>
      </Grid>
      <Divider />
      <Grid className={styles.buttonGroup}>
        <Button size="small" variant="outlined" color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          size="small"
          startIcon={updateTenantMutation.isLoading ? <CircularProgress size={16} /> : undefined}
          type="submit"
          variant="contained"
          disabled={updateTenantMutation.isLoading || !!errors.tenantDescription}
          color="primary">
          Save
        </Button>
      </Grid>
    </form>
  );
};

export default TenantEditForm;
