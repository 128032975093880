import React, { useEffect, useState } from "react";
import Form from "@rjsf/material-ui";
import validator from "@rjsf/validator-ajv8";
import { FormControlLabel, Grid, makeStyles, Switch, Typography } from "@material-ui/core";
import { JSONSchema7 } from "json-schema";
import { RegistryWidgetsType } from "@rjsf/utils";

import CustomStringField from "./StringField";
import ObjectFieldTemplate from "./ObjectFieldTemplate";
import DescriptionFieldTemplate from "./DescriptionFieldTemplate";

import uiSchema from "./configureChatbotUiSchema.json";
import schema from "./configureChatbotSchema.json";
import ChatbotPreview from "./ChatbotPreview";
import { useSaveChatbotPropertiesMutation, useGetAllChatbotProperties } from "src/hooks/api/";
import { handleResponse } from "services/Apis/Apis.service";
import StringField from "./StringField";
import SelectField from "./SelectField";
import { ChatbotPropertyDto } from "@rapidcanvas/rc-api-core";
import _ from "lodash";
import SubTopNavBar from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBar";
import CommonLoader from "src/components/CommonLoader";

const useStyles = makeStyles({
  root: {
    padding: "0px 24px",
    height: "calc(100vh - 94px)",
    overflow: "hidden",
    "& .rjsf > .form-group": {
      width: "100%"
    },
    "& .form-group": {
      marginBottom: "0 !important",
      width: "100%",
      "& .form-group": {
        width: "100%"
      },
      "& div": { width: "100%" }
    },
    "& button": {
      width: "100%"
    }
  },
  chatbotSettingsContainer: {
    height: "calc(100vh - 94px)",
    paddingBottom: "8px",
    paddingRight: "48px"
  },
  formWrap: {
    height: "calc(100vh - 154px)",
    overflow: "scroll"
  },
  previewWrap: {
    height: "calc(100vh - 94px)"
  },
  elementWrap: {
    width: "calc(100% - 32px)",
    paddingTop: "24px"
  },
  nameWrap: {
    width: "calc(100% - 32px)",
    paddingTop: "16px",
    paddingBottom: "16px"
  }
});

const widgets = {
  textField: CustomStringField,
  selectField: SelectField
} as RegistryWidgetsType;

const defaultValues = {
  appSettings: {
    "app-type": "floating",
    "is-staging": "true"
  },
  icon: "ai-chatbot",
  location: {
    position: "static"
  },
  dynamicPosition: {
    placement: "bottom-center"
  }
};

const formatFormData = (data: ChatbotPropertyDto) => {
  const properties = data?.properties;
  return {
    id: data?.id || null,
    appSettings: {
      "app-type": properties?.["app-type"] || null,
      "is-staging": properties?.["is-staging"] || null,
      "api-key": properties?.["api-key"] || null,
      "prediction-service": properties?.["prediction-service"] || null
    },
    userDetails: {
      "organization-party-id": properties?.["organization-party-id"] || null,
      "session-id": properties?.["session-id"] || null,
      "user-id": properties?.["user-id"] || null
    },
    icon: properties?.icon || null,
    dynamicPosition: {
      "element-id": properties?.["element-id"] || null,
      placement: properties?.placement || null
    },
    location: {
      position: properties?.position || null,
      height: properties?.height || null,
      width: properties?.width || null,
      left: properties?.left || null,
      top: properties?.top || null,
      bottom: properties?.bottom || null,
      right: properties?.right || null
    }
  };
};

const App: React.FC = () => {
  const classes = useStyles();
  const [isNewChatbot, setIsNewChatbot] = useState<boolean>(true);
  const [formData, setFormData] = useState<any>(defaultValues);
  const [name, setName] = useState<string>("");
  const saveChatbotPropertiesMutation = useSaveChatbotPropertiesMutation();
  const { isLoading, data: allChatbots } = useGetAllChatbotProperties();
  const allChatbotNameOptions =
    allChatbots?.map((properties) => ({ label: properties.name, value: properties.name })) || [];

  useEffect(() => {
    if (!isNewChatbot && name) {
      const currentProperty = _.find(allChatbots, { name });
      currentProperty && setFormData(formatFormData(currentProperty));
    }
  }, [isNewChatbot, name]);

  const handleSubmit = ({ formData }: any) => {
    const { id, dynamicPosition, appSettings, userDetails, location, ...restProps } = formData;

    const properties = {
      ...restProps,
      ...dynamicPosition,
      ...appSettings,
      ...userDetails,
      ...location
    };
    saveChatbotPropertiesMutation.mutate(
      {
        id,
        isNewRequest: isNewChatbot,
        request: { name, properties }
      },
      {
        onSuccess: () => {
          handleResponse({ successMessage: "Chatbot properties saved successfully" });
        }
      }
    );
  };

  if (isLoading) {
    return <CommonLoader />;
  }

  return (
    <Grid container className={classes.root} direction="row" wrap="nowrap">
      <SubTopNavBar />
      <Grid item xs={6} className={classes.chatbotSettingsContainer}>
        <Grid
          container
          direction="row"
          wrap="nowrap"
          className={classes.elementWrap}
          justifyContent="space-between"
          alignItems="center">
          <Typography variant="h6">Chatbot Settings</Typography>
          <FormControlLabel
            control={
              <Switch
                checked={isNewChatbot}
                size="small"
                onChange={(event) => setIsNewChatbot(event.target.checked)}
              />
            }
            label="Is New Chatbot"
          />
        </Grid>
        <Grid container direction="column" wrap="nowrap" className={classes.formWrap}>
          <Grid item className={classes.nameWrap}>
            {isNewChatbot ? (
              <StringField
                label="Name"
                required
                value={name}
                onChange={(val: any) => setName(val)}
              />
            ) : (
              <SelectField
                label="Name"
                required
                value={name}
                onChange={(val: any) => setName(val)}
                options={{
                  enumOptions: allChatbotNameOptions
                }}
              />
            )}
          </Grid>
          <Form
            schema={schema as JSONSchema7}
            uiSchema={uiSchema}
            formData={formData}
            onSubmit={handleSubmit}
            onChange={({ formData }: any) => {
              const { appSettings, userDetails, dynamicPosition, location, ...restProps } =
                formData;
              setFormData({
                ...restProps,
                appSettings,
                userDetails,
                dynamicPosition,
                location
              });
            }}
            validator={validator}
            widgets={widgets}
            templates={{
              ObjectFieldTemplate,
              DescriptionFieldTemplate
            }}
            showErrorList={false}
          />
        </Grid>
      </Grid>
      <Grid item xs={6} className={classes.previewWrap}>
        <ChatbotPreview formData={formData} />
      </Grid>
    </Grid>
  );
};

export default App;
