import React, { useRef } from "react";
import { makeStyles, Popover } from "@material-ui/core";

import GlobalSearchPopoverContent from "./GlobalSearchPopoverContent";

interface IProps {
  searchText: string;
  searchComponents: string[];
  anchorEl: HTMLDivElement | null;
  onClose: () => void;
  onSearchTextChange: (text: string) => void;
}

const useStyles = makeStyles(() => ({
  root: {
    width: "30%",
    borderRadius: "10px",
    border: "1px solid #6B6B6B",
    padding: "12px 0px 12px 12px",
    display: "flex",
    justifyContent: "center"
  }
}));

const GlobalSearchPopover: React.FC<IProps> = (props) => {
  const { searchText, anchorEl, searchComponents, onClose, onSearchTextChange } = props;
  const navigateRef = useRef<boolean>(false);
  const classes = useStyles();

  const handleNavigate = () => {
    onClose();
    navigateRef.current = true;
  };

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      data-testid="globalSearchPopover"
      getContentAnchorEl={null}
      disableAutoFocus
      TransitionProps={{
        onExited: () => {
          if (navigateRef.current) {
            onSearchTextChange("");
            navigateRef.current = false;
          }
        }
      }}
      disableEnforceFocus
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
      PaperProps={{
        classes: { root: classes.root }
      }}
      onClose={onClose}>
      <GlobalSearchPopoverContent
        searchText={searchText}
        searchComponents={searchComponents}
        onNavigate={handleNavigate}
        onSearchTextChange={onSearchTextChange}
      />
    </Popover>
  );
};

export default GlobalSearchPopover;
