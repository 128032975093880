import { Grid, InputAdornment, makeStyles, Popover, TextField } from "@material-ui/core";
import { filter, includes, isEmpty, map, toLower } from "lodash";
import React, { Dispatch, SetStateAction, useMemo, useState } from "react";
import { OverflowTooltip, Spinner } from "src/components";
import SearchIcon from "@material-ui/icons/Search";
import { useGetModelByName } from "src/hooks/api";
import Table from "src/components/custom/TableV8/Table";

interface IProps {
  anchorEl: HTMLDivElement | null;
  name: string;
  setAnchorEl: Dispatch<SetStateAction<HTMLDivElement | null>>;
}

const tableColumns = [
  {
    id: "File Name",
    accessorKey: "name",
    header: "File Name"
  }
];

const useStyles = makeStyles({
  entity: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    padding: "4px 6px",
    width: "inherit",
    justifyContent: "space-between",
    borderBottom: "0.5px solid #D1D1D1"
  },
  entityText: {
    fontWeight: 400,
    fontSize: "14px",
    width: "174px"
  },
  tableWrap: {
    padding: "1px",
    overflow: "auto",
    maxWidth: "500px"
  },
  flex: {
    display: "flex",
    alignItems: "center",
    gap: "6px"
  },
  header: {
    padding: "10px 26px",
    borderBottom: "0.5px solid #D1D1D1",
    fontWeight: 600,
    maxWidth: "350px"
  },
  search: {
    color: "#7c7c7c",
    minHeight: "34px",
    height: "100%",
    margin: "0px",
    width: "100%",
    borderTop: "1px solid #e1e1e1",
    borderBottom: "1px solid #e1e1e1",
    "& fieldset": {
      border: "none"
    },
    "& div[class*='MuiInputBase-root']": {
      height: "34px",
      width: "100%",
      color: "#7c7c7c"
    },
    "& input[class*='MuiInputBase-input']": {
      height: "34px",
      fontSize: "14px",
      width: "100%"
    }
  }
});

const ModelPopover: React.FC<IProps> = ({ anchorEl, setAnchorEl, name }) => {
  const classes = useStyles();
  const modelDetails = useGetModelByName(name);
  const [searchValue, setSearchValue] = useState("");

  const modelNames = useMemo(() => {
    if (modelDetails?.data) {
      return map(
        filter(modelDetails.data.files, (item) => includes(toLower(item), toLower(searchValue))),
        (item) => ({ name: item })
      );
    } else {
      return [];
    }
  }, [modelDetails?.data, searchValue]);

  const handleSearch = (value: string) => {
    setSearchValue(value);
  };
  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left"
      }}
      onClose={() => setAnchorEl(null)}>
      <div className={classes.header}>
        <OverflowTooltip value={name} />
      </div>

      <TextField
        onChange={(e) => handleSearch(e.target.value)}
        InputProps={{
          onKeyDown: (e) => e.stopPropagation(),
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon fontSize="small" />
            </InputAdornment>
          ),
          type: "search"
        }}
        placeholder="Search by file name"
        className={classes.search}
        id="column-search-input"
        name="column-search-input"
        variant="outlined"
      />
      {modelDetails.isLoading && <Spinner />}
      {!modelDetails.isLoading &&
        (isEmpty(modelNames) ? (
          <div
            style={{ justifyContent: "center", fontSize: "14px", color: "grey", padding: "20px" }}>
            {!!searchValue ? "No matching result found" : "No files present in this Model"}
          </div>
        ) : (
          <Grid container className={classes.tableWrap}>
            <Table
              data={modelNames}
              columns={tableColumns}
              infoProps={{ hideCount: true }}
              stylesProps={{ size: "small", hideSettings: true, maxHeight: "calc(100vh - 130px)" }}
              sortingProps={{ unsorted: true }}
            />
          </Grid>
        ))}
    </Popover>
  );
};

export default ModelPopover;
