import { FindProjectRunsJobTypeEnum } from "@rapidcanvas/rc-api-core";
import React from "react";

import Modal, { ModalVariants } from "src/components/custom/Modal/Modal";

type Props = {
  jobType: FindProjectRunsJobTypeEnum;
  handleClose: () => void;
  handleSubmit: () => void;
  isSubmitLoading?: boolean;
};

export const PublishJobModal = ({ handleClose, handleSubmit, isSubmitLoading, jobType }: Props) => (
  <Modal
    open={true}
    variant={ModalVariants.Delete}
    title={
      jobType === FindProjectRunsJobTypeEnum.PredictionJob
        ? "Republish Model Flow to Prediction Scheduler"
        : "Republish"
    }
    content={[
      jobType === FindProjectRunsJobTypeEnum.PredictionJob
        ? "This will republish the latest model flow to this Prediction Scheduler."
        : "This will republish the latest Project Canvas to this Scheduler.",
      "Are you sure?"
    ]}
    cancelLabel="No"
    submitLabel="Yes, Republish"
    isSubmitting={isSubmitLoading}
    onClose={handleClose}
    onSubmit={handleSubmit}
  />
);
