import api from "services/AxiosClient/AxiosClient";
import { useMutation, UseMutationResult } from "@tanstack/react-query";

interface IVariable {
  groupId: string;
  scenarioId?: string;
  hardStop?: boolean;
}

const useStopRecipe = (): UseMutationResult<any, unknown, IVariable, unknown> => {
  return useMutation({
    mutationFn: async ({ groupId, scenarioId, hardStop = true }) => {
      return await api.fetchResponse(() =>
        api.DfsRunConfigGroupControllerV2Api.stopGroup(groupId, scenarioId, hardStop)
      );
    }
  });
};

export default useStopRecipe;
