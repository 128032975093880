import React, { useEffect, useMemo, useState } from "react";

import { useNavigate } from "react-router-dom";
import { find, get } from "lodash";

import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Tooltip,
  Typography
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import StorageIcon from "@material-ui/icons/Storage";
import { DownloadRound } from "src/assets/icons/DownloadRound";

import { toastWrapper } from "services/ToastClient/toastWrapper";

import { useGetJobRun, useDownloadEntity } from "src/hooks/api";

import { EntityTypeEnum } from "src/types";
import { Spinner } from "src/components";
import { Modal } from "src/components/custom";
import { getIcon as getDefaultDataConnectorsIcon } from "pages/Projects/CanvasFlow/CanvasSearch";

import { IConnector, thirdPartyTypeName } from "src/pages/DataSources/utils/DataSources.constants";

import { JobRunStatuses, JobsHelperText } from "../../utils/Jobs.constants";

import { useStyles } from "./JobRunOutputModal.styles";
import { RecipeTypesPathMapping } from "src/pages/private/ProjectsModule/utils/Projects.constants";

const JobRunOutputModal = (props: $TSFixMe) => {
  const { connectorsStore, jobData, lastRunData, onViewOutputClose } = props || {};

  const classes = useStyles();

  const navigate = useNavigate();

  // States - STARTS >>
  const [downloadingEntity, setDownloadingEntity] = useState<$TSFixMe>("");
  const [runHistoryDatasets, setRunHistoryDatasets] = useState<$TSFixMe>([]);
  // << ENDS - States

  // Query hooks - STARTS >>
  // Mutations
  const { mutateAsync: downloadEntityMutation, reset: resetDownloadEntityMutation } =
    useDownloadEntity();

  // Queries
  const { isFetching, isFetched, data: jobRunData } = useGetJobRun({ jobRunId: lastRunData?.id });
  // << ENDS - Query hooks

  const isJobRunInProgress = useMemo(
    () =>
      [
        JobRunStatuses.Created,
        JobRunStatuses.Started,
        JobRunStatuses.EntityLoading,
        JobRunStatuses.Running
      ].includes(lastRunData?.status),
    [lastRunData?.status]
  );

  // Pre-fetching runHistoryDetails - STARTS >>
  useEffect(() => {
    if (isFetched && Object.keys(jobRunData || {})?.length > 0 && !isJobRunInProgress) {
      let thisDatasets: $TSFixMe = [];
      if (Object.keys(jobRunData?.entityDtos || {})?.length > 0) {
        thisDatasets = Object.entries(jobRunData?.entityDtos || {})?.map(
          ([, eachDataset]: $TSFixMe) => eachDataset
        );
      }

      const thisRunHistoryDatasets: $TSFixMe = [];
      if (Object.keys(jobRunData?.entryDto || {})?.length > 0) {
        let canListDatasets = (jobRunData?.entryDto?.outEntityInfo || [])?.length > 0;

        if (lastRunData?.status !== JobRunStatuses.SuccessWithWarn) {
          canListDatasets = canListDatasets && thisDatasets.length > 0;
        }

        if (canListDatasets) {
          (jobRunData?.entryDto?.outEntityInfo || [])?.forEach((eachDataset: $TSFixMe) => {
            let canListDataset = !!jobRunData?.entryDto?.id;

            const filteredDataset: $TSFixMe = thisDatasets?.find(
              (dataset: $TSFixMe) => dataset?.name === eachDataset?.name
            );

            if (lastRunData?.status !== JobRunStatuses.SuccessWithWarn) {
              canListDataset = canListDatasets && !!filteredDataset?.id;
            }

            if (canListDataset) {
              thisRunHistoryDatasets.push({
                ...(!!filteredDataset
                  ? filteredDataset
                  : {
                      name: eachDataset?.name,
                      type:
                        eachDataset?.viewType === EntityTypeEnum.NONE
                          ? EntityTypeEnum.ENTITY
                          : eachDataset?.viewType
                    }),
                runId: jobRunData?.entryDto?.id
              });
            }
          });
        }
      }

      setRunHistoryDatasets(() => thisRunHistoryDatasets);
    }
  }, [isFetched, jobRunData, isJobRunInProgress]);
  // << ENDS - Pre-fetching runHistoryDetails

  const inputs = useMemo(
    () => runHistoryDatasets.filter((dataset: $TSFixMe) => dataset.rootEntity),
    [runHistoryDatasets]
  );

  const outputs = useMemo(
    () => runHistoryDatasets.filter((dataset: $TSFixMe) => !dataset.rootEntity),
    [runHistoryDatasets]
  );

  const getPath = (dataset: $TSFixMe) => {
    let baseUrl = "";

    if (!jobData?.projectId || !jobData?.id || !jobData?.scenarioId || !lastRunData?.id) {
      return;
    }

    baseUrl = `/projects/${jobData?.projectId}/jobs/${jobData?.id}/scenario/${jobData?.scenarioId}/job-runs/${lastRunData?.id}`;

    switch (dataset?.type) {
      case EntityTypeEnum.ENTITY:
        if (!dataset?.id) {
          return;
        }

        baseUrl += `/entity/${dataset?.id}/data`;
        break;

      case EntityTypeEnum.DFSGROUP:
        if (!get(RecipeTypesPathMapping, dataset?.recipeType) || !dataset?.id) {
          return;
        }

        baseUrl += `/recipes/${get(RecipeTypesPathMapping, dataset?.recipeType)}/${dataset?.id}`;
        break;

      case EntityTypeEnum.CHART:
        if (!dataset?.id) {
          return;
        }

        baseUrl += `/charts/chart/${dataset?.id}`;
        break;

      case EntityTypeEnum.ARTIFACT:
        if (!dataset?.name) {
          return;
        }

        baseUrl += `/artifact/${dataset?.name}`;
        break;

      case EntityTypeEnum.MODEL:
        if (!dataset?.name) {
          return;
        }

        baseUrl += `/model/${dataset?.name}`;
        break;

      case EntityTypeEnum.FILE:
        if (!jobData?.projectId || !jobData?.id || !jobData?.scenarioId || !lastRunData?.id) {
          return;
        }

        baseUrl += `/file/${dataset?.id}/?tab=data`;
    }
    return baseUrl;
  };

  const getIcon = (dataset: $TSFixMe) => {
    if (!!dataset?.dataSourceId) {
      const connector: IConnector =
        find(connectorsStore, {
          name:
            dataset?.dataSourceType === thirdPartyTypeName
              ? dataset?.tpConnectorType
              : dataset?.dataSourceType
        }) || {};

      return connector?.type === "default" ? (
        connector?.image || <StorageIcon />
      ) : connector?.url ? (
        <img src={connector?.url} alt={connector?.displayName} />
      ) : (
        <StorageIcon />
      );
    } else {
      return getDefaultDataConnectorsIcon(dataset?.type);
    }
  };

  const getListItem = (dataset: $TSFixMe) => {
    const path = getPath(dataset) || "";

    return (
      <ListItem
        key={dataset?.name}
        {...(!!path
          ? {
              className: classes.link,
              onClick: () => navigate(path)
            }
          : {})}>
        <ListItemIcon className={classes.listIcon}>{getIcon(dataset)}</ListItemIcon>
        <ListItemText
          primary={
            <>
              <Tooltip title={path ? "" : JobsHelperText.JobRunOutputModalUnnavigableOutputInfo}>
                <Typography variant="body2">{dataset?.name}</Typography>
              </Tooltip>
            </>
          }
        />
        {dataset?.type === EntityTypeEnum.ENTITY && (
          <ListItemSecondaryAction>
            <IconButton
              disabled={
                !!(dataset?.id || dataset?.name) &&
                downloadingEntity === (dataset?.id || dataset?.name)
              }
              onClick={() => download(dataset)}>
              {!!(dataset?.id || dataset?.name) &&
              downloadingEntity === (dataset?.id || dataset?.name) ? (
                <Spinner size={24} />
              ) : (
                <DownloadRound />
              )}
            </IconButton>
          </ListItemSecondaryAction>
        )}
      </ListItem>
    );
  };

  const download = async (dataset: $TSFixMe) => {
    setDownloadingEntity(() => dataset?.id || dataset?.name);

    resetDownloadEntityMutation();

    const payload: $TSFixMe = {
      // Need the API without entityId. Back-end supports undefined. So, sending it specifically.
      entityId: dataset.id || undefined,
      scenarioId: jobRunData?.entryDto?.scenarioId,
      projectRunEntryId: lastRunData?.id || jobRunData?.entryDto?.id,
      ...(!dataset.id ? { entityName: dataset.name } : {})
    };

    await downloadEntityMutation(payload, {
      onSuccess: (data: $TSFixMe) => {
        !!data && window.open(data.replace(": //", "://"), "_blank");
      },
      onError: () => {
        toastWrapper({
          type: "error",
          content: "No file connected to this dataset!"
        });
      },
      onSettled: () => {
        setDownloadingEntity(() => "");
      }
    });
  };

  const handleClose = () => {
    onViewOutputClose();
  };

  return (
    <Modal
      open={true}
      title={
        <>
          <Typography style={{ fontWeight: 400 }} variant="h6">
            {jobData?.name || "Scheduler"}
          </Typography>
          <Typography variant="subtitle2">
            {`(Run Name ${lastRunData?.runId || lastRunData?.id || "Unknown"})`}
          </Typography>
          <Typography variant="caption" style={{ fontStyle: "italic" }}>
            {JobsHelperText.JobRunOutputModalTitleDescription}
          </Typography>
        </>
      }
      size="md"
      onClose={handleClose}
      hideSubmitAction={true}>
      {isJobRunInProgress ? (
        <Alert severity="info">
          The run is currently in progress. Please check the output once the execution is complete.
        </Alert>
      ) : isFetching ? (
        <Spinner />
      ) : runHistoryDatasets?.length === 0 ? (
        <Alert severity="info">No data found.</Alert>
      ) : (
        <Box className={classes.datasetsContainer}>
          <Paper>
            <List className={classes.entitiesListContainer}>
              <ListItem>
                <ListItemText
                  primaryTypographyProps={{ variant: "body2" }}
                  primary={`Inputs (${(inputs || [])?.length})`}
                  style={{ opacity: 0.5 }}
                />
                <ListItemSecondaryAction style={{ opacity: 0.5 }}>Download</ListItemSecondaryAction>
              </ListItem>
              <Divider />
              {(inputs || [])?.length === 0 ? (
                <ListItem>NA</ListItem>
              ) : (
                inputs?.map((eachDataset: $TSFixMe) => getListItem(eachDataset))
              )}
            </List>
          </Paper>
          <Paper>
            <List className={classes.entitiesListContainer}>
              <ListItem>
                <ListItemText
                  primaryTypographyProps={{ variant: "body2" }}
                  primary={`Outputs (${(outputs || [])?.length})`}
                  style={{ opacity: 0.5 }}
                />
                <ListItemSecondaryAction style={{ opacity: 0.5 }}>Download</ListItemSecondaryAction>
              </ListItem>
              <Divider />
              {(outputs || [])?.length === 0 ? (
                <ListItem>NA</ListItem>
              ) : (
                outputs?.map((eachDataset: $TSFixMe) => getListItem(eachDataset))
              )}
            </List>
          </Paper>
        </Box>
      )}
    </Modal>
  );
};

export default JobRunOutputModal;
