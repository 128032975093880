import { useQuery } from "@tanstack/react-query";

import { getAPIWithRethrow } from "services/Apis/Apis.service";

import type { UserSettings } from "src/types/User.type";

export const useGetUserSettings = ({
  onSuccess,
  refetchOnMount
}: {
  refetchOnMount: boolean;
  onSuccess: (settings: UserSettings) => void;
}) => {
  return useQuery<UserSettings>({
    queryKey: ["user-settings"],
    queryFn: async () => {
      return await getAPIWithRethrow("/user-settings");
    },
    onSuccess,
    refetchOnMount
  });
};
