import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import { isEmpty, isEqual, toLower, trim } from "lodash";

import api from "services/AxiosClient/AxiosClient";
import { getAPI } from "services/Apis/Apis.service";
import { NodeTypes } from "src/pages/private/ProjectsModule/utils";

import { UseGetConnectorsQueryKeys } from "../dataSources/useGetConnectors";

interface IVariables {
  stepId: string;
  stepType: string;
  nodeType: string;
}

const useDeleteCandidates = (): UseMutationResult<unknown, unknown, IVariables, unknown> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ stepId, stepType, nodeType }) => {
      try {
        let datasetData;

        const isDataset = isEqual(toLower(trim(nodeType)), toLower(trim(NodeTypes.Dataset)));
        if (!!isDataset) {
          [datasetData] = await getAPI(`/v2/entities?id=${stepId}`);
        }

        const deleteAssociatedPDs = ["group", "model", "entity"].includes(nodeType);
        const response = await api.fetchResponse(
          async () =>
            await api.ProjectsControllerV2Api.deleteStep(
              stepId,
              stepType,
              deleteAssociatedPDs,
              deleteAssociatedPDs
            )
        );

        if (!!isDataset) {
          if (!isEmpty(datasetData) && !!datasetData?.dataSourceId) {
            queryClient.refetchQueries([UseGetConnectorsQueryKeys.Connectors]);
          }
        }

        return response;
      } catch (error) {
        throw error;
      }
    }
  });
};

export default useDeleteCandidates;
