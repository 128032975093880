import { get, has } from "lodash";

import makeStyles from "@material-ui/core/styles/makeStyles";

import { NodeModelTheme } from "src/pages/private/ProjectsModule/utils";

type StyleProps = {
  status: string;
};

const useStyles = makeStyles({
  root: {
    width: 40,
    height: 40,
    borderRadius: 40 / 3,
    backgroundColor: ({ status }: StyleProps) =>
      has(NodeModelTheme, status)
        ? get(NodeModelTheme, [status, "backgroundColor"])
        : NodeModelTheme.backgroundColor,
    border: "1px solid",
    borderColor: ({ status }: StyleProps) =>
      has(NodeModelTheme, status)
        ? get(NodeModelTheme, [status, "borderColor"])
        : NodeModelTheme.borderColor,
    color: ({ status }: StyleProps) =>
      has(NodeModelTheme, status)
        ? get(NodeModelTheme, [status, "iconColor"])
        : NodeModelTheme.iconColor,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: ({ status }: StyleProps) =>
        has(NodeModelTheme, status)
          ? get(NodeModelTheme, [status, "onHover", "backgroundColor"])
          : NodeModelTheme.onHover.backgroundColor,
      border: "1px solid",
      borderColor: ({ status }: StyleProps) =>
        has(NodeModelTheme, status)
          ? get(NodeModelTheme, [status, "onHover", "borderColor"])
          : NodeModelTheme.onHover.borderColor,
      color: ({ status }: StyleProps) =>
        has(NodeModelTheme, status)
          ? get(NodeModelTheme, [status, "onHover", "iconColor"])
          : NodeModelTheme.onHover.iconColor
    },
    "&.highlighted": {
      boxShadow: `0px 0px 10px 5px ${NodeModelTheme.highlightColor}`
    },
    "&.active": {
      boxShadow: `0px 0px 5px 5px  ${NodeModelTheme.activeColor}`
    }
  },
  container: {
    height: "100%",
    position: "relative",
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "center",
    alignItems: "center"
  },
  icon: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&.active": {
      boxShadow: "0px 0px 48px 0px #003656"
    }
  },
  label: {
    top: 2,
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#000",
    whiteSpace: "nowrap",
    "&.active": {
      fontWeight: "bold",
      backgroundColor: NodeModelTheme.activeColor
    }
  }
});

export default useStyles;
