import React, { useMemo, useState } from "react";

// Packages
import { useIsMutating } from "@tanstack/react-query";

// MUI
import {
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Button,
  Typography,
  useTheme,
  makeStyles,
  Tooltip
} from "@material-ui/core";

// Icons
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Publish from "@material-ui/icons/Publish";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ArrowDownIcon } from "icons/NewUX/ArrowDownIcon";
import { WorkflowIcon } from "icons/NewUX/WorkflowIcon";

// Utils
import { capitalizeFirstLetter } from "utils/helpers/string.helpers";

// Hooks
import { UsePublishJobsQueryKeys } from "src/hooks/api";

// Components
import OverflowTooltip from "src/components/OverflowTooltip";
import ScenariosMenu from "./ScenariosMenu";
import { FindProjectRunsJobTypeEnum } from "@rapidcanvas/rc-api-core";
import useProjectDetails from "src/hooks/api/projects/useProjectDetails";
import { size } from "lodash";

const useStyles = makeStyles({
  root: {
    display: "flex",
    marginLeft: "auto",
    columnGap: 10,
    alignItems: "center",
    justifyContent: "flex-end"
  },
  moreAction: {
    minHeight: 28,
    fontWeight: 400
  },
  menuItemIcon: {
    minWidth: "auto",
    marginRight: 8
  }
});

interface IProps {
  projectId?: string;
  menuActionLabel: string;
  scenarios: any[];
  currentScenario: any;
  publishJob: (type: "scheduler" | "predictionScheduler") => void;
}

const MoreOptionsMenu = (props: IProps) => {
  const { menuActionLabel, projectId, scenarios, currentScenario, publishJob } = props || {};

  const theme = useTheme();
  const classes = useStyles();
  const { data } = useProjectDetails(projectId, {
    refetchOnMount: true,
    enabled: !!projectId
  });

  const hasSchedulers = size(data?.projectJobs) > 0;
  const hasPredictionSchedulers = size(data?.predictionJobs) > 0;
  // States - STARTS >>
  const [isScenariosMenuOpen, setIsScenariosMenuOpen] = useState(false);
  // ENDS - States

  const pendingPublishJobMutations = useIsMutating({
    mutationKey: [
      UsePublishJobsQueryKeys.PublishJobs,
      projectId,
      FindProjectRunsJobTypeEnum.ProjectJob
    ]
  });

  const pendingPublishPredictionJobMutations = useIsMutating({
    mutationKey: [
      UsePublishJobsQueryKeys.PublishJobs,
      projectId,
      FindProjectRunsJobTypeEnum.PredictionJob
    ]
  });

  // Drop-down code - STARTS >>
  const [anchorEl, setAnchorEl] = useState(null);

  const onOpen = (event: $TSFixMe) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
    setIsScenariosMenuOpen(false);
  };
  // ENDS - Drop-down

  const menuItems = useMemo(() => {
    if (!!isScenariosMenuOpen) {
      return [
        <MenuItem key="moreOptionsScenario" onClick={() => setIsScenariosMenuOpen(false)}>
          <ListItemIcon className={classes.menuItemIcon}>
            <ChevronLeftIcon />
          </ListItemIcon>
          <ListItemText
            primary="Scenarios"
            primaryTypographyProps={{
              variant: "body2",
              style: { opacity: 0.5 }
            }}
          />
        </MenuItem>
      ];
    }

    const isPublishingJob = pendingPublishJobMutations > 0;
    const isPublishingPredictionScheduler = pendingPublishPredictionJobMutations > 0; // change this

    return [
      <MenuItem key="moreOptionsScenario" disabled>
        <ListItemText
          primary="Scenario"
          primaryTypographyProps={{
            variant: "body2"
          }}
        />
      </MenuItem>,
      <MenuItem key="moreOptionsCurrentScenario" onClick={() => setIsScenariosMenuOpen(true)}>
        <ListItemIcon className={classes.menuItemIcon}>
          <WorkflowIcon />
        </ListItemIcon>
        <OverflowTooltip
          value={
            <Typography variant="body2" component="span">
              {capitalizeFirstLetter(currentScenario?.name)}
            </Typography>
          }
        />
        <ListItemSecondaryAction>
          <ChevronRightIcon fontSize="small" />
        </ListItemSecondaryAction>
      </MenuItem>,
      ...(hasSchedulers
        ? [
            <Tooltip
              key="publishJobInfo"
              title={isPublishingJob ? "Please wait. The republish action is in progress." : ""}>
              <span>
                <MenuItem
                  key="moreOptionsPublishingJob"
                  disabled={!!isPublishingJob}
                  onClick={() => {
                    publishJob("scheduler");
                    onClose();
                  }}>
                  <ListItemIcon className={classes.menuItemIcon}>
                    {isPublishingJob ? (
                      <CircularProgress size={16} />
                    ) : (
                      <Publish fontSize="small" />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary="Publish to Schedulers"
                    primaryTypographyProps={{
                      variant: "body2"
                    }}
                  />
                </MenuItem>
              </span>
            </Tooltip>
          ]
        : []),
      ...(hasPredictionSchedulers
        ? [
            <Tooltip
              key="publishPredictionJobInfo"
              title={
                isPublishingPredictionScheduler
                  ? "Please wait. The republish action is in progress."
                  : ""
              }>
              <span>
                <MenuItem
                  key="moreOptionsPublishingJob"
                  disabled={!!isPublishingJob}
                  onClick={() => {
                    publishJob("predictionScheduler");
                    onClose();
                  }}>
                  <ListItemIcon className={classes.menuItemIcon}>
                    {isPublishingPredictionScheduler ? (
                      <CircularProgress size={16} />
                    ) : (
                      <Publish fontSize="small" />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary="Publish to Prediction Schedulers"
                    primaryTypographyProps={{
                      variant: "body2"
                    }}
                  />
                </MenuItem>
              </span>
            </Tooltip>
          ]
        : [])
    ];
  }, [
    isScenariosMenuOpen,
    currentScenario?.name,
    hasSchedulers,
    pendingPublishJobMutations,
    pendingPublishPredictionJobMutations,
    hasPredictionSchedulers
  ]);

  return (
    <>
      {!!menuActionLabel ? (
        <Button
          color="primary"
          variant="outlined"
          size="small"
          style={{ borderColor: "#4646B5" }}
          className={classes.moreAction}
          endIcon={<ArrowDownIcon color={theme.palette.primary.main} />}
          onClick={onOpen}>
          {menuActionLabel}
        </Button>
      ) : (
        <Button color="primary" size="small" onClick={onOpen}>
          <ArrowDownIcon color={theme.palette.text.primary} opacity={0.3} style={{ margin: 9 }} />
        </Button>
      )}
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        getContentAnchorEl={null}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        onClose={onClose}
        PaperProps={{
          style: {
            marginTop: 6,
            width: 260,
            borderRadius: 12
          }
        }}>
        {menuItems}
        {isScenariosMenuOpen && (
          <ScenariosMenu projectId={projectId} scenarios={scenarios} onClose={onClose} />
        )}
      </Menu>
    </>
  );
};

export default MoreOptionsMenu;
