import React from "react";

// Packages
import { generatePath, Link } from "react-router-dom";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";

// Utils
import { RenderText } from "components/custom";

// Components
import EditName from "src/layout/NavBars/components/SubTopNavBar/EditName";
import { WebPaths } from "src/routing/routes";
import {
  SCHEDULER_TABS,
  TAB
} from "src/pages/private/ProjectsModule/pages/PredictionJob/components/PredictionRunScheduler/Schedulers";
import { ProjectRunDtoJobTypeEnum } from "@rapidcanvas/rc-api-core";

const FONT_SIZE = 14;

type Props = {
  project: $TSFixMe;
  isJobPath: boolean;
  jobData: $TSFixMe;
  jobRunId?: string;
  jobRunName?: string;
  isDefaultScenario: boolean;
  scenarioName?: string;
  chartName?: string;
  updateChartName: $TSFixMeFunction;
  shouldResetChartName: number | boolean;
};

const SubTopNavBarBreadcrumbs = (props: Props) => {
  const {
    project,
    isJobPath,
    jobData,
    jobRunId,
    jobRunName,
    isDefaultScenario,
    scenarioName,
    chartName,
    updateChartName,
    shouldResetChartName
  } = props || {};

  const breadcrumbs: React.ReactNode[] = [];

  breadcrumbs.push(
    !!project?.id ? (
      <Link key="projects" to={`/projects/${project?.id}/canvas`}>
        <RenderText>{project?.name || "Project"}</RenderText>
      </Link>
    ) : (
      <RenderText key="project-name" color="textSecondary">
        {project?.name || "Project"}
      </RenderText>
    )
  );

  if (isJobPath && !!project?.id) {
    if (jobData?.jobType === ProjectRunDtoJobTypeEnum.PredictionJob) {
      breadcrumbs.push(
        !!project?.id && (
          <Link
            key="jobs"
            state={{ [TAB]: SCHEDULER_TABS.PredictionScheduler }}
            to={generatePath(WebPaths.PredictionJob, { projectId: project?.id })}>
            <RenderText>Prediction Schedulers</RenderText>
          </Link>
        )
      );
    } else {
      breadcrumbs.push(
        !!project?.id && (
          <Link key="jobs" to={generatePath(WebPaths.JobRoutes, { projectId: project?.id })}>
            <RenderText>Schedulers</RenderText>
          </Link>
        )
      );
    }

    breadcrumbs.push(
      !!project?.id && !!jobData?.id ? (
        <Link key="jobs-name" to={`/projects/${project?.id}/jobs/${jobData?.id}`}>
          <RenderText>{jobData?.name || "Scheduler"}</RenderText>
        </Link>
      ) : (
        <RenderText key="jobs-name" color="textSecondary">
          {jobData?.name || "Scheduler"}
        </RenderText>
      )
    );

    breadcrumbs.push(
      project?.id && !!jobData?.id && !!jobRunId ? (
        <Link
          key="job-run-name"
          to={`/projects/${project?.id}/jobs/${jobData?.id}/job-runs/${jobRunId}/job-canvas`}>
          <RenderText>{`Run Name ${jobRunName || jobRunId || "Unknown"}`}</RenderText>
        </Link>
      ) : (
        <RenderText key="job-run-name" color="textSecondary">{`Run Name ${
          jobRunName || jobRunId || "Unknown"
        }`}</RenderText>
      )
    );

    breadcrumbs.push(
      <RenderText key="chart-name" color="textSecondary">
        {chartName}
      </RenderText>
    );
  } else {
    breadcrumbs.push(
      !isDefaultScenario ? (
        <RenderText key="scenario" color="textSecondary">
          {chartName}
          {!!scenarioName && ` (${scenarioName})`}
        </RenderText>
      ) : (
        <EditName
          key="edit-name"
          fieldNameLabel="chart name"
          inputValue={chartName}
          onSave={updateChartName}
          isResetName={shouldResetChartName}
          containerProps={{
            style: {
              width: ((chartName || "")?.length + 1) * (FONT_SIZE - 4) + 16
            }
          }}
        />
      )
    );
  }

  return (
    <Breadcrumbs>
      {breadcrumbs?.map((eachBreadcrumb: React.ReactNode) => eachBreadcrumb)}
    </Breadcrumbs>
  );
};

export default SubTopNavBarBreadcrumbs;
