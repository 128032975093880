import { getAPI, getAPIWithRethrow, postAPIWithRethrow, putAPIWithRethrow } from "../Apis.service";

export const getEnvironmentById = async (id: $TSFixMe) => await getAPIWithRethrow(`/v2/envs/${id}`);

export const relaunchEnvironment = async (
  id: string,
  notify: boolean,
  body: $TSFixMe,
  shouldDispatchEvent?: boolean
) =>
  await putAPIWithRethrow(
    `/v2/envs/${id}?relaunch=true&async=true&notify=${notify}`,
    body,
    undefined,
    shouldDispatchEvent
  );

export const getEnvironmentUsage = async (id: $TSFixMe) => await getAPI(`/v2/envs/${id}/usage`);

export const createDefaultEnvironment = async () =>
  await postAPIWithRethrow(`/tenants/create-default-env`, {});
