import React, { useEffect, useState } from "react";

interface IProps {
  src: string;
  fallbackSrc: string;
  title: string;
}

const LoadableImage: React.FC<IProps> = ({ src, fallbackSrc, title }) => {
  let base64Src = src?.split(";base64,")?.pop() || src;
  base64Src = base64Src ? `data:image/jpeg;base64,${base64Src}` : fallbackSrc;

  const [isLoadable, setIsLoadable] = useState<boolean>(true);

  useEffect(() => {
    const img = new Image();

    // Check if the image loads successfully
    img.onload = () => {
      setIsLoadable(true);
    };

    // If the image fails to load, fallback to the alternative image
    img.onerror = () => {
      setIsLoadable(false);
    };

    img.src = base64Src; // Start loading the image

    // Cleanup
    return () => {
      img.onload = null;
      img.onerror = null;
    };
  }, [base64Src]);

  return <img src={isLoadable ? base64Src : src} alt={title} />;
};

export default LoadableImage;
