import React from "react";

// Packages
import { includes } from "lodash";

// Components
import { Modal } from "src/components/custom";
import { ModalVariants } from "src/components/custom/Modal/Modal";

// Constants
import { DateFormatCloseModalPromptDetails } from "./DateFormatModal.constants";

interface IProps {
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmClose: React.FC<IProps> = (props) => {
  const { onConfirm, onCancel } = props;

  return (
    <Modal
      open
      variant={ModalVariants.Delete}
      title={DateFormatCloseModalPromptDetails.title}
      content={[
        DateFormatCloseModalPromptDetails.messageLine1,
        DateFormatCloseModalPromptDetails.messageLine2
      ]}
      onClose={(_, reason: string) => {
        if (!includes(["escapeKeyDown", "backdropClick"], reason)) {
          onCancel();
        }
      }}
      cancelLabel={DateFormatCloseModalPromptDetails.cancelLabel}
      onSubmit={onConfirm}
      hideCloseIcon
    />
  );
};

export default ConfirmClose;
