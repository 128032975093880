import React, { useMemo } from "react";
import _, { capitalize, find, includes, isEmpty, join, map, toNumber } from "lodash";
import { Grid, Typography, makeStyles, Card, Radio, Tooltip } from "@material-ui/core";
import { Link, generatePath } from "react-router-dom";

import { dateFormat } from "utils/helpers/date.helpers";
import { MoreOptions, OverflowTooltip } from "src/components";
import Modal, { ModalVariants } from "src/components/custom/Modal/Modal";
import {
  getJobRunStatusBadge,
  getJobStatusTemplate
} from "../private/ProjectsModule/pages/Jobs/utils/Jobs.helpers";
import { useJobsListHelper } from "./useJobsListHelper";
import {
  JobDeletePromptDetails,
  jobFrequency,
  JobRunStatuses
} from "../private/ProjectsModule/pages/Jobs/utils/Jobs.constants";
import { JobIcon } from "icons/NewUX/JobIcon";
import NewThemeWrapper from "src/styles/NewThemeWrapper";
import {
  lastRunEntryId,
  openLogsModal
} from "src/layout/NavBars/components/TopNavBar/TopNavBarNotifications/TopNavBarNotifications.constants";
import { RecipeTypesPathMapping } from "../private/ProjectsModule/utils/Projects.constants";
import { CardTitle } from "src/components/Card/CardTitle";
import { WebPaths } from "src/routing/routes";
import { ProjectRunDetailDto, ProjectRunDtoJobTypeEnum } from "@rapidcanvas/rc-api-core";
import { SCHEDULER_TABS } from "../private/ProjectsModule/pages/PredictionJob/components/PredictionRunScheduler/Schedulers";

const useStyles = makeStyles({
  gridItem: {
    width: ({ showSelectionName }: any) => (!!showSelectionName ? "65%" : "80%")
  },
  jobIcon: {
    marginRight: "12px"
  },
  container: {
    fontSize: "0.875rem",
    fontWeight: 400
  },
  containerLight: {
    fontSize: "0.875rem",
    fontWeight: 400,
    color: "rgba(0, 0, 0, 0.54)"
  },
  card: {
    background: ({ isHovered }: any) => (isHovered ? "#F4F4FF" : "#fff"),
    position: "relative",
    width: "100%",
    padding: ({ showSelectionName }: any) => (!!showSelectionName ? "8px 0px" : "8px 16px"),
    boxShadow: "none"
  },
  moreOptions: {
    position: "absolute",
    background: "#fff",
    top: "16px",
    right: "32px",
    width: "24px",
    height: "30px",
    borderRadius: "4px",
    zIndex: 1
  }
});

interface IFailedRecipeObj {
  failedRecipeId?: string;
  recipePath?: string;
}

interface IProps {
  data: ProjectRunDetailDto;
  value?: string;
  showSelectionName?: string;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const JobsList = ({ data, showSelectionName, value, disabled, onChange }: IProps) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const classes = useStyles({ isHovered, showSelectionName });

  const jobId = data?.dto?.id;
  const projectId = data?.dto?.projectId;
  const jobName = data?.dto?.name;
  const status = data?.dto?.status;
  const created = data?.lastRunEntry?.created;
  const updated = data?.lastRunEntry?.updated;
  const lastRunEntries = data?.lastRunEntries;
  const cronConfig = _.get(data?.dto?.scheduleInfo, "cronConfig");

  const {
    projectName,
    cardOptions,
    cancelDeleteJob,
    confirmDeleteJob,
    showConfirmScreen,
    isDeleting
  } = useJobsListHelper({ data });

  const jobFrequencyTemplate = useMemo(() => {
    if (!isEmpty(data?.dto?.scheduleInfo)) {
      const days = _.get(cronConfig, "days");
      const frequency = _.get(cronConfig, "frequency");
      const hr = _.get(cronConfig, "hr");
      const min = _.get(cronConfig, "min");

      if (!cronConfig || !frequency) {
        return "-";
      }

      const frequencyName = find(jobFrequency, { id: frequency })?.displayName || "";

      const hours = toNumber(hr);
      const minutes = toNumber(min);

      const timeString = `${hr} Hr${hours === 1 ? "" : "s"} ${min} Min${minutes === 1 ? "" : "s"}`;

      switch (frequency) {
        case "daily":
          return `${frequencyName || capitalize(frequency)} at ${timeString} UTC`;
        case "weekly":
          const dayString = !isEmpty(days) ? `on ${join(map(days, capitalize), ", ")}` : "";
          return `${frequencyName || capitalize(frequency)} ${dayString} at ${timeString} UTC`;
        default:
          if (!!frequencyName && !!data?.dto?.schedule) {
            return `${frequencyName}: ${data?.dto?.schedule} UTC`;
          } else if (!!frequencyName && !data?.dto?.schedule) {
            return `${frequencyName}: -`;
          } else if (!frequencyName && !!data?.dto?.schedule) {
            return `${find(jobFrequency, { id: "cron" })?.displayName}: ${data?.dto?.schedule} UTC`;
          } else {
            return "-";
          }
      }
    }

    if (!isEmpty(data?.dto?.schedule)) {
      return `${find(jobFrequency, { id: "cron" })?.displayName}: ${data?.dto?.schedule} UTC`;
    }

    return "";
  }, [data?.dto?.schedule, cronConfig]);

  const navigateTo = (entry: any) => {
    switch (entry?.status) {
      case JobRunStatuses.Failure:
      case JobRunStatuses.RecipeTimedOut: {
        const failedRecipeObject: IFailedRecipeObj = {};

        _.forEach(entry?.recipeRunInfo, (val, key) => {
          if (val?.status === "FAILURE") {
            const type = _.get(entry, ["allRecipeDetails", key, "recipeType"]);
            const recipePath = type ? _.get(RecipeTypesPathMapping, type) : "";
            _.setWith(
              failedRecipeObject,
              "failedRecipeId",
              _.get(entry?.recipeNameToId, key),
              Object
            );
            _.setWith(failedRecipeObject, "recipePath", recipePath, Object);
          }
        });

        if (failedRecipeObject?.recipePath && failedRecipeObject?.failedRecipeId) {
          return `/projects/${projectId}/jobs/${jobId}/scenario/${entry?.scenarioId}/job-runs/${entry?.id}/recipes/${failedRecipeObject.recipePath}/${failedRecipeObject.failedRecipeId}?${openLogsModal}=true`;
        }
        return `/projects/${projectId}/jobs/${jobId}/job-runs/${entry?.id}/job-canvas`;
      }
      case JobRunStatuses.Running:
      case JobRunStatuses.TimedOut: {
        return `/projects/${projectId}/jobs/${jobId}/job-runs?${lastRunEntryId}=${entry?.id}&${openLogsModal}=true`;
      }
      default: {
        return `/projects/${projectId}/jobs/${jobId}/job-runs/${entry?.id}/job-canvas`;
      }
    }
  };

  return (
    <>
      {showConfirmScreen && (
        <NewThemeWrapper>
          <Modal
            open={true}
            variant={ModalVariants.Delete}
            title="Delete Scheduler"
            content={[JobDeletePromptDetails.messageLine1, JobDeletePromptDetails.messageLine2]}
            onClose={cancelDeleteJob}
            onSubmit={confirmDeleteJob}
            isCancelDisabled={isDeleting}
            isSubmitDisabled={isDeleting}
            isSubmitting={isDeleting}
          />
        </NewThemeWrapper>
      )}
      <Card
        className={classes.card}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}>
        {isHovered && !showSelectionName && (
          <Grid className={classes.moreOptions}>
            <MoreOptions
              testId="jobMoreOptionsMenu"
              options={cardOptions}
              btnProps={{
                style: {
                  color: "#4646B5"
                }
              }}
            />
          </Grid>
        )}
        <Grid container direction="row" wrap="nowrap">
          <Grid
            container
            item
            direction="row"
            wrap="nowrap"
            alignItems="center"
            style={{ gap: "12px", width: "25%" }}>
            {!!showSelectionName && (
              <Grid data-testid="jobSelection" item style={{ flexShrink: 0 }}>
                <Radio
                  style={{ padding: 0 }}
                  name={showSelectionName}
                  checked={value === jobId}
                  disabled={disabled}
                  value={jobId}
                  onChange={onChange}
                />
              </Grid>
            )}
            <Grid data-testid="jobIcon" item style={{ flexShrink: 0 }}>
              <JobIcon />
            </Grid>
            <Grid item container direction="column">
              <Grid item className={classes.gridItem}>
                <CardTitle
                  variant="body1"
                  dataTestId="jobName"
                  title={jobName ?? "Unknown"}
                  highlightOnHover
                  link={
                    !!projectId && !!jobId
                      ? generatePath(`${WebPaths.JobRoutes}${WebPaths.JobId}`, {
                          projectId,
                          jobId
                        })
                      : undefined
                  }
                />
                <OverflowTooltip
                  style={{ whiteSpace: "nowrap" }}
                  value={`Last Run On: ${dateFormat(updated || created)}`}
                  tooltipContainerProps={{ className: classes.containerLight }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item container direction="column" style={{ width: "15%" }}>
            <Grid item className={classes.gridItem}>
              <Typography
                data-testid="jobProjects"
                component="div"
                color="textSecondary"
                variant="body2">
                Project
              </Typography>
              <CardTitle
                dataTestId="jobProjectName"
                highlightOnHover
                variant="body1"
                title={projectName}
                link={
                  !!projectId
                    ? generatePath(WebPaths.Dag, {
                        projectId
                      })
                    : undefined
                }
              />
            </Grid>
          </Grid>
          <Grid item container direction="column" style={{ width: "18%" }}>
            <Grid item className={classes.gridItem}>
              <Typography
                data-testid="jobFrequency"
                component="div"
                color="textSecondary"
                variant="body2">
                Frequency
              </Typography>
              <OverflowTooltip
                style={{ whiteSpace: "nowrap" }}
                value={jobFrequencyTemplate}
                tooltipContainerProps={{ className: classes.container }}
              />
            </Grid>
          </Grid>
          {!showSelectionName && (
            <Grid item container direction="column" style={{ width: "15%" }}>
              <Grid item>
                <Typography
                  data-testid="jobStatus"
                  component="div"
                  style={{ whiteSpace: "nowrap" }}
                  color="textSecondary"
                  variant="body2">
                  Scheduler Type
                </Typography>
                {data.dto?.jobType === ProjectRunDtoJobTypeEnum.PredictionJob
                  ? SCHEDULER_TABS.PredictionScheduler
                  : SCHEDULER_TABS.Scheduler}
              </Grid>
            </Grid>
          )}
          <Grid item container direction="column" style={{ width: "13%" }}>
            <Grid item className={classes.gridItem}>
              <Typography
                data-testid="jobStatus"
                component="div"
                style={{ whiteSpace: "nowrap" }}
                color="textSecondary"
                variant="body2">
                Scheduler Status
              </Typography>
              {status ? getJobStatusTemplate({ job: data?.dto, renderChip: true }) : "UNKNOWN"}
            </Grid>
          </Grid>
          <Grid item container direction="column" style={{ width: "14%" }}>
            <Typography
              data-testid="jobLastFiveRunStatus"
              component="span"
              color="textSecondary"
              variant="body2">
              Last 5 Run Status
            </Typography>
            {_.size(lastRunEntries) > 0 ? (
              <Grid container>
                {lastRunEntries
                  ?.slice(0, 5)
                  ?.sort((a: $TSFixMe, b: $TSFixMe) => a.created - b.created)
                  ?.map((entry: $TSFixMe, index: number) => {
                    const tooltipMoreInfo = !!entry?.endTime ? (
                      <>
                        <br />
                        On: {dateFormat(entry?.endTime)}
                      </>
                    ) : null;

                    const disabled = !includes(
                      [
                        JobRunStatuses.SuccessWithWarn,
                        JobRunStatuses.Success,
                        JobRunStatuses.TimedOut,
                        JobRunStatuses.RecipeTimedOut,
                        JobRunStatuses.Failure
                      ],
                      entry?.status
                    );

                    return (
                      <Tooltip
                        key={`lastRunStatus_${jobName}_${index}`}
                        title={
                          !!disabled
                            ? "Kindly wait for the run to complete before visiting the canvas."
                            : ""
                        }>
                        <span>
                          <Link
                            id={`lastRunStatus_${jobName}_${index}`}
                            to={navigateTo(entry)}
                            style={
                              !!disabled
                                ? { pointerEvents: "none", opacity: 0.5, textDecoration: "none" }
                                : {}
                            }>
                            {getJobRunStatusBadge(entry?.status, tooltipMoreInfo)}
                          </Link>
                        </span>
                      </Tooltip>
                    );
                  })}
              </Grid>
            ) : (
              "N/A"
            )}
          </Grid>
        </Grid>
      </Card>
    </>
  );
};
