import React from "react";

// Packages
import { generatePath, Link } from "react-router-dom";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";

// Utils
import { RenderText } from "components/custom";
import { WebPaths } from "src/routing/routes";
import {
  SCHEDULER_TABS,
  TAB
} from "../../../PredictionJob/components/PredictionRunScheduler/Schedulers";
import { ProjectRunDto, ProjectRunDtoJobTypeEnum } from "@rapidcanvas/rc-api-core";

type Props = {
  project: $TSFixMe;
  jobData?: ProjectRunDto;
  jobRunId: string;
  jobRunName?: string;
};

const SubTopNavBarBreadcrumbs = (props: Props) => {
  const { project, jobData, jobRunId, jobRunName } = props || {};

  const breadcrumbs: React.ReactNode[] = [];

  breadcrumbs.push(
    !!project?.id ? (
      <Link key="projects" to={generatePath(`${WebPaths.Dag}/canvas`, { projectId: project?.id })}>
        <RenderText>{project?.name || "Project"}</RenderText>
      </Link>
    ) : (
      <RenderText key="project-name" color="textSecondary">
        {project?.name || "Project"}
      </RenderText>
    )
  );

  if (jobData?.jobType === ProjectRunDtoJobTypeEnum.PredictionJob) {
    breadcrumbs.push(
      !!project?.id && (
        <Link
          key="jobs"
          state={{ [TAB]: SCHEDULER_TABS.PredictionScheduler }}
          to={generatePath(WebPaths.PredictionJob, { projectId: project?.id })}>
          <RenderText>Prediction Schedulers</RenderText>
        </Link>
      )
    );
  } else {
    breadcrumbs.push(
      !!project?.id && (
        <Link key="jobs" to={generatePath(WebPaths.JobRoutes, { projectId: project?.id })}>
          <RenderText>Schedulers</RenderText>
        </Link>
      )
    );
  }

  breadcrumbs.push(
    !!project?.id && !!jobData?.id ? (
      <Link
        key="job-name"
        to={generatePath(`${WebPaths.JobRoutes}${WebPaths.JobId}`, {
          projectId: project?.id,
          jobId: jobData?.id
        })}>
        <RenderText>{jobData?.name || "Scheduler"}</RenderText>
      </Link>
    ) : (
      <RenderText key="job-name" color="textSecondary">
        {jobData?.name || "Scheduler"}
      </RenderText>
    )
  );

  breadcrumbs.push(
    project?.id && !!jobData?.id && !!jobRunId ? (
      <Link
        key="job-run-name"
        to={generatePath(`${WebPaths.JobRoutes}${WebPaths.JobCanvas}`, {
          projectId: project?.id,
          jobId: jobData?.id,
          jobRunId
        })}>
        <RenderText>{`Run Name ${jobRunName || jobRunId || "Unknown"}`}</RenderText>
      </Link>
    ) : (
      <RenderText key="job-run-name" color="textSecondary">{`Run Name ${
        jobRunName || jobRunId || "Unknown"
      }`}</RenderText>
    )
  );

  breadcrumbs.push(
    <RenderText key="logs" color="textSecondary">
      Logs
    </RenderText>
  );

  return (
    <Breadcrumbs>
      {breadcrumbs?.map((eachBreadcrumb: React.ReactNode) => eachBreadcrumb)}
    </Breadcrumbs>
  );
};

export default SubTopNavBarBreadcrumbs;
