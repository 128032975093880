import { useMutation, UseMutationResult } from "@tanstack/react-query";

import api from "services/AxiosClient/AxiosClient";

interface IPayload {
  scenarioId?: string;
}

const useDeleteScenario = (): UseMutationResult<unknown, unknown, IPayload, unknown> =>
  useMutation({
    mutationFn: async ({ scenarioId }: IPayload) => {
      if (!scenarioId) {
        throw "Invalid payload!";
      }

      return await api.fetchResponse(
        async () => await api.ScenarioControllerApi.delete2(scenarioId)
      );
    }
  });

export default useDeleteScenario;
