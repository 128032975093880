import React, { useEffect, useState } from "react";

import { CircularProgress, makeStyles } from "@material-ui/core";

import { capitalize } from "utils/helpers/string.helpers";

import { DatasetSessionConfig, DatasetKeys } from "../../utils/Dataset.constants";

import { Field } from "src/components";

import DatasetControl from "./DatasetControl";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: (styleProps: $TSFixMe) => styleProps.rootAlignItems,
    columnGap: 20
  },
  formField: {
    marginBottom: theme.spacing(2)
  }
}));

const SqlConfiguration = (props: $TSFixMe) => {
  const {
    getDatasetSession,
    updateDatasetsSession,
    isFetchingSqlConfig,
    values,
    setValues,
    datasetIsFetchingOntologyDataStore,
    isDatasetCreated
  } = props || {};

  // Style >>
  const [styleProps, setStyleProps] = useState({
    rootAlignItems: "center"
  });

  const classes: $TSFixMe = useStyles(styleProps);

  useEffect(() => {
    const thisStyleProps = styleProps;
    thisStyleProps.rootAlignItems = isFetchingSqlConfig ? "center" : "flex-start";

    setStyleProps({ ...thisStyleProps });
  }, [isFetchingSqlConfig]);
  // << Style

  const onChange = (event: $TSFixMe) => {
    const { name, value } = event.target;

    values[name].value = value;

    setValues({
      ...values
    });

    let prevValues: $TSFixMe = {};
    const sqlConfigurationSession = sessionStorage.getItem(
      DatasetSessionConfig.SqlConfigurationSessionKey
    );

    if (sqlConfigurationSession) {
      prevValues = JSON.parse(sqlConfigurationSession)?.values || {};
    }

    // Update datasets in session >>
    const fields: $TSFixMe = {};
    fields[DatasetKeys.OntologyConfig] = {};
    fields[DatasetKeys.OntologyConfig][DatasetKeys.IsSqlFieldDirty] =
      prevValues?.[name]?.value !== value;

    updateDatasetsSession({ index: 0, fields: fields });
    // << Update datasets in session
  };

  const getErrorText = (field: $TSFixMe) => {
    const isRequired = values?.[field]?.required && !values?.[field]?.value;

    if (isRequired) {
      return "Required!";
    }

    return "";
  };

  return (
    <>
      <form
        id="sqlConfigurationForm"
        name="sqlConfigurationForm"
        autoComplete="sqlConfigurationForm-off"
        className={classes.root}>
        <DatasetControl
          getDatasetSession={getDatasetSession}
          datasetIsFetchingOntologyDataStore={datasetIsFetchingOntologyDataStore}
          isFetchingSqlConfig={isFetchingSqlConfig}
          isDatasetCreated={isDatasetCreated}
        />
        {isFetchingSqlConfig ? (
          <CircularProgress size={23} color="primary" />
        ) : (
          Object.keys(values)?.length > 0 &&
          Object.keys(values).map((field: $TSFixMe, index: number) => {
            return (
              <>
                <Field
                  key={`index_${index}`}
                  id={values?.[field]?.name || `sqlConfigurationFieldId_${index}`}
                  label={capitalize(values?.[field]?.name || "")}
                  variant="outlined"
                  // @ts-ignore
                  size="small"
                  // @ts-ignore
                  value={values?.[field]?.value || ""}
                  required={values?.[field]?.required || false}
                  error={!!getErrorText(field)}
                  helperText={getErrorText(field)}
                  onChange={onChange}
                  autoComplete={`sqlConfigurationFieldId_${index}-off`}
                  className={classes.formField}
                />
              </>
            );
          })
        )}
      </form>
    </>
  );
};

export default SqlConfiguration;
