import React, { ReactNode, useCallback } from "react";
import { Size, TableCell, TextField } from "@material-ui/core";
import Select from "src/components/Select/Select";
import { Cell } from "@tanstack/react-table";

export const DefaultTableCell = ({
  cell,
  rowToBeEdited,
  children,
  size,
  updateMyData,
  secondaryData,
  ...restProps
}: {
  cell: Cell<any, any>;
  rowToBeEdited?: string;
  children?: ReactNode;
  size: Size;
  updateMyData?: any;
  secondaryData?: any;
  className?: string;
}) => {
  const { column, row } = cell;

  if (column.columnDef.meta?.isEditable && row.original.id === rowToBeEdited) {
    return (
      <TableCell
        size={size}
        data-testid={`table-cell-${column.id}-${row.id}`}
        style={{ width: cell.column.getSize() }}
        {...restProps}>
        <EditableTableCell
          updateMyData={updateMyData}
          size={size}
          cell={cell}
          secondaryData={secondaryData}
          {...restProps}>
          {children}
        </EditableTableCell>
      </TableCell>
    );
  }
  return <TableCell {...restProps}>{children}</TableCell>;
};

const EditableTableCell = ({
  cell,
  secondaryData,
  children,
  ...restProps
}: {
  cell: Cell<any, any>;
  size: Size;
  children: ReactNode;
  updateMyData?: any;
  secondaryData?: any;
}) => {
  const type = cell.column.columnDef.meta?.type || "string";

  switch (type) {
    case "select":
      return (
        <EditableSelectField cell={cell} secondaryData={secondaryData} {...restProps}>
          {children}
        </EditableSelectField>
      );
    case "string":
    default:
      return <EditableTextField cell={cell} {...restProps} />;
  }
};

const EditableSelectField: any = ({
  cell,
  updateMyData,
  secondaryData,
  children
}: {
  cell: Cell<any, any>;
  children: ReactNode;
  size: Size;
  updateMyData?: any;
  secondaryData: any;
}) => {
  const {
    row: {
      index: rowIndex,
      original: { id: rowId },
      original
    },
    column: {
      id: columnId,
      columnDef: { meta }
      // header: Header
    },
    getValue
  } = cell;

  const {
    values,
    selectVariant = "filled",
    hideSelectInputLabel = false,
    selectStyles
  } = meta ?? {};

  const [value, setValue] = React.useState(getValue());

  let secondaryField: any = {};

  if ((secondaryData || [])?.length > 0) {
    secondaryField = secondaryData?.[rowIndex]?.[columnId] || {};
  }

  const onChange = (event: any) => {
    const updatedData = event.target.value;
    setValue(updatedData);
    updateMyData({ rowId: rowId || secondaryField?.id, columnId, value: updatedData, original });
  };

  if (secondaryData !== undefined) {
    if (!secondaryField?.isEditable) {
      return children;
    }
  }

  const renderSelectedValue = useCallback(
    (selectedValue: unknown) =>
      (values || secondaryField?.selectValues || [])?.find(
        (thisEntry: any) => thisEntry?.value === selectedValue
      )?.label || "",
    [values || secondaryField?.selectValues]
  );

  return (
    <Select
      variant={selectVariant}
      size="small"
      data-testid={`table-cell-editable-select-field${columnId}}`}
      hideInputLabel={hideSelectInputLabel}
      required
      fullWidth
      values={values || secondaryField?.selectValues || []}
      label={""}
      value={value}
      onChange={onChange}
      fixedMenuHeight={true}
      renderValue={renderSelectedValue}
      className={selectStyles}
    />
  );
};

const EditableTextField = ({ cell, size, updateMyData }: any) => {
  const {
    getValue,
    row: {
      original: { id: rowId },
      original
    },
    column: { id: columnId },
    columnDef: { header: Header }
  } = cell;
  const [value, setValue] = React.useState(getValue());
  const onChange = (event: any) => {
    const updatedData = event.target.value;
    setValue(updatedData);
    updateMyData({ rowId, columnId, value: updatedData, original });
  };

  return (
    <TextField
      id={columnId}
      data-testid={`table-cell-editable-text-field${columnId}}`}
      variant="outlined"
      label={Header}
      value={value}
      onChange={onChange}
      size={size}
    />
  );
};
