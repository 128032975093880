import React, { useMemo, useState } from "react";

// Packages
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { isEmpty, size } from "lodash";

// MUI
import ButtonGroup from "@material-ui/core/ButtonGroup";

// Icons
import { EyeIcon } from "icons/NewUX";
import { TrashIcon } from "icons/NewUX/TrashIcon";

// Utils
import { areAllKeysPresentAndNotNil } from "src/utils/helpers";
import { WebPaths } from "src/routing/routes";

// Components
import DeleteNodeModal from "src/pages/ViewData/DeleteNodeModal/DeleteNodeModal";
import DeleteNodesModal from "../../DeleteNodes/DeleteNodesModal";

// Types
import { NodeData } from "src/types";
import { Button } from "@material-ui/core";
import { useContextStyles } from "../useContextMenuStyles";

type Props = {
  closeContextMenu: () => void;
  isChartViewAction: boolean;
  isChartDeleteAction: boolean;
  data: NodeData;
  selectedNodes: NodeData[];
  resetSelectedNodes: () => void;
  children?: React.ReactNode;
};

const ContextMenu = (props: Props) => {
  const {
    closeContextMenu,
    isChartViewAction,
    isChartDeleteAction,
    data,
    selectedNodes,
    resetSelectedNodes,
    children
  } = props || {};

  const navigate = useNavigate();
  const { projectId, scenarioId } = useParams();

  const classes = useContextStyles();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [showConfirmDeleteNodesModal, setShowConfirmDeleteNodesModal] = useState(false);

  const onView = () => {
    if (!areAllKeysPresentAndNotNil(data, ["projectId", "scenarioId", "id"])) {
      return;
    }

    let path = generatePath(WebPaths.Charts, {
      projectId: data?.projectId,
      scenarioId: data?.scenarioId,
      chartId: data?.id
    });

    if (!!data?.jobProps) {
      if (
        !data?.isJobCanvasPath ||
        !areAllKeysPresentAndNotNil(data?.jobProps, ["jobId", "jobRunId"])
      ) {
        return;
      }

      path = generatePath(`${WebPaths.JobRoutes}${WebPaths.JobChart}`, {
        projectId: data.projectId,
        jobId: data?.jobProps?.jobId,
        scenarioId: data?.scenarioId,
        jobRunId: data?.jobProps?.jobRunId,
        chartId: data?.id
      });
    }

    !!path && navigate(path);
  };

  const onDelete = () => {
    setIsDeleteModalOpen(true);
  };

  // Delete nodes - STARTS >>
  const promptConfirmDeleteNodes = () => {
    setShowConfirmDeleteNodesModal(() => true);
  };

  const resetConfirmDeleteNodes = () => {
    setShowConfirmDeleteNodesModal(() => false);
  };
  // << ENDS - Delete nodes

  const buttonComponents = useMemo(() => {
    let items: React.ReactNode[] = [];

    if (!!data?.isJobCanvas) {
      if (!data?.isJobCanvasPath) {
        return [];
      }
    }

    if (size(selectedNodes) > 1) {
      items.push(
        <Button
          size="small"
          key="delete"
          data-testid="chartContextMenuDeleteSelected"
          startIcon={<TrashIcon viewBox="0 0 20 22" />}
          onClick={promptConfirmDeleteNodes}>
          Delete Selected
        </Button>
      );

      return items;
    }

    if (isChartViewAction) {
      items.push(
        <Button
          size="small"
          key="view"
          data-testid="chartPreview"
          className="context-menu-border"
          startIcon={<EyeIcon viewBox="0 0 20 20" />}
          onClick={onView}>
          Preview
        </Button>
      );
    }

    if (isChartDeleteAction) {
      items.push(
        <Button
          size="small"
          key="delete"
          data-testid="Delete"
          startIcon={<TrashIcon viewBox="0 0 20 22" />}
          onClick={onDelete}>
          Delete
        </Button>
      );
    }

    return items;
  }, [
    selectedNodes,
    data?.isJobCanvas,
    data?.isJobCanvasPath,
    isChartViewAction,
    isChartDeleteAction
  ]);

  return (
    <>
      {!!showConfirmDeleteNodesModal && (
        <DeleteNodesModal
          projectId={projectId}
          scenarioId={scenarioId}
          selectedNodes={selectedNodes}
          resetSelectedNodes={resetSelectedNodes}
          resetConfirmDeleteNodes={resetConfirmDeleteNodes}
        />
      )}

      {!!isDeleteModalOpen && (
        <DeleteNodeModal
          open
          nodeId={data?.id}
          nodeName={data?.label}
          nodeType="chart"
          onDeleteSuccess={resetSelectedNodes}
          onClose={() => {
            setIsDeleteModalOpen(false);
          }}
          onAfterSubmit={closeContextMenu}
        />
      )}

      {(React.isValidElement(children) || !isEmpty(buttonComponents)) && (
        <ButtonGroup variant="text" size="small" orientation="vertical" className={classes.root}>
          {!isEmpty(buttonComponents) && buttonComponents}
          {children}
        </ButtonGroup>
      )}
    </>
  );
};

export default ContextMenu;
