import { useMutation, UseMutationResult } from "@tanstack/react-query";

import api from "services/AxiosClient/AxiosClient";
import { IProjectReturn } from "./useRunDetails";
import {
  CreateProjectRunDtoStatusEnum,
  CreateProjectRunDtoSubTypeEnum,
  FindProjectRunsJobTypeEnum
} from "@rapidcanvas/rc-api-core";

export interface IProjectRunData {
  name: string;
  projectId: string;
  modelEntityId: string;
  isManual: boolean;
}

const useCreatePredictionJobRun = (): UseMutationResult<
  IProjectReturn,
  unknown,
  IProjectRunData,
  unknown
> => {
  return useMutation({
    mutationFn: async ({ isManual, ...rest }) => {
      const projectRuns = await api.fetchResponse(
        async () =>
          await api.ProjectRunControllerApi.createProjectRun({
            ...rest,
            schedule: "0 0 * * *",
            status: CreateProjectRunDtoStatusEnum.Inactive,
            jobType: FindProjectRunsJobTypeEnum.PredictionJob,
            subType: isManual
              ? CreateProjectRunDtoSubTypeEnum.Manual
              : CreateProjectRunDtoSubTypeEnum.Scheduler
          })
      );

      return projectRuns as IProjectReturn;
    }
  });
};

export default useCreatePredictionJobRun;
