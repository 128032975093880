import { useQuery } from "@tanstack/react-query";

import { getAPIWithRethrow } from "services/Apis/Apis.service";
import { Tenant } from "src/types";
import useTenantsStore from "stores/zustand/tenant-management.store";

export const QUERY_KEY_CURRENT_TENANT = "current-tenant";

export const useFetchCurrentTenant = () => {
  const [setSelectedTenant] = useTenantsStore((state) => [state.setSelectedTenant]);
  return useQuery<Tenant>({
    queryKey: [QUERY_KEY_CURRENT_TENANT],
    queryFn: async () => {
      const response = await getAPIWithRethrow("/tenants/current");
      const tenant = {
        ...response,
        tenants: response.users
      };
      setSelectedTenant(tenant);
      return tenant;
    }
  });
};
