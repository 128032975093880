import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";

import { Warning } from "src/assets/icons/Warning";
import NewModal from "src/components/Modal/NewModal";

export const DeleteQueryErrorModal = ({ onClose }: $TSFixMe) => {
  return (
    <NewModal
      open
      header="Delete Query"
      onClose={onClose}
      hideSubmit
      width="465px"
      height={350}
      background="#fff"
      submitBtnTestId="deleteQueryErrorModal"
      cancelButtonLabel="Cancel">
      <Grid container wrap="nowrap" style={{ gap: "16px" }}>
        <Warning />
        <Box display="flex" flexDirection="column" gridGap={16}>
          <Typography>
            Query cannot be deleted as one of the associated Outputs is added to the Recipe
          </Typography>
        </Box>
      </Grid>
    </NewModal>
  );
};
