// @REFACTOR: The file needs to be refactored.
import React, { useMemo } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Tooltip
} from "@material-ui/core";
import { generatePath, useNavigate } from "react-router-dom";
import { useIsMutating } from "@tanstack/react-query";
import { EntityDtoStatusEnum } from "@rapidcanvas/rc-api-core";
import { ExportIcon } from "icons/NewUX/ExportIcon";
import { TrashIcon } from "icons/NewUX/TrashIcon";
import AddMenu from "src/pages/ViewData/AddMenu";
import CachedIcon from "@material-ui/icons/Cached";

import { RenderText } from "components/custom";

import { useScenariosStore, useDrawerStore } from "../../../stores/zustand/stores";
import { sideComponentSetter } from "../../../stores/zustand/stores.selectors";

import DeleteNodeModal, {
  psMessage
} from "../../../pages/ViewData/DeleteNodeModal/DeleteNodeModal";

import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";

import FileReadonly from "src/pages/private/ProjectsModule/pages/Dag/components/Nodes/File/FileReadonly";
import DestinationDetails from "pages/private/ProjectsModule/pages/OutputDataset/components/DestinationDetails";
import NewThemeWrapper from "src/styles/NewThemeWrapper";
import SourceDetails from "pages/private/ProjectsModule/pages/OutputDataset/components/SourceDetails";
import useEntities from "hooks/api/entities/useEntities";
import useEntityDetails from "hooks/api/entities/useEntityDetails";
import {
  checkIfDefaultScenario,
  DatasetWebPathQueryParameters,
  NodeTypes,
  ViewFileWebPathQueryParameters
} from "src/pages/private/ProjectsModule/utils";
import { useStyles as useDrawerStyles } from "./components/styling";
import { WebPaths } from "src/routing/routes";
import _, { toLower, toUpper } from "lodash";
import { EyeIcon } from "icons/NewUX";
import MoreOptions from "src/layout/NavBars/components/SubTopNavBar/MoreOptions";
import CommonLoader from "src/components/CommonLoader";
import EntityDetails from "./components/EntityDetails";
import { areAllKeysPresentAndNotNil } from "src/utils/helpers";
import { useDownloadTextInput } from "src/pages/private/ProjectsModule/hooks";
import { ConfirmUpdateTextInput } from "src/pages/private/ProjectsModule/components";
import fileTypes from "constants/fileTypes.constants";
import { UseReloadDatasetsQueryKeys } from "src/hooks/api/entities/useReloadDatasets";
import ReloadDatasetModal from "src/pages/ViewData/ViewDataData/ReloadDatasetModal";
import { thirdPartyTypeName } from "src/pages/DataSources/utils/DataSources.constants";

const FileModalV2 = ({ data, canAddStandardRecipe }: $TSFixMe) => {
  const { cardMargin, drawerContent, content, datasetItemGap, flex } = useDrawerStyles();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
  const [showConfirmUpdateTextInputModal, setShowConfirmUpdateTextInputModal] =
    React.useState(false);
  const [showConfirmReloadTextInputModal, setShowConfirmReloadTextInputModal] =
    React.useState(false);

  const navigate = useNavigate();
  const [scenarios] = useScenariosStore((state) => [state.scenarios]);
  const setSideComponent = useDrawerStore(sideComponentSetter);
  const isDefaultScenario = useMemo(
    () => checkIfDefaultScenario(null, scenarios, data?.scenarioId),
    [data?.scenarioId, scenarios]
  );

  const { data: entity, isLoading } = useEntities({
    id: data.itemId,
    scenarioId: data?.scenarioId,
    ...(!!data?.isJobCanvas ? { jobRunId: data?.jobProps?.jobRunId } : {}),
    options: { refetchOnMount: true }
  });

  const { data: entityDetails, isLoading: isEntityDetailsLoading } = useEntityDetails(
    data.itemId,
    data.scenarioId,
    data.jobProps?.jobRunId,
    {
      enabled: !!data.itemId,
      refetchOnMount: true
    }
  );

  const fileTypeLabel = useMemo(() => {
    const fileTypeKey = toLower(entityDetails?.fileExtension) as keyof typeof fileTypes;
    return fileTypes?.[fileTypeKey]?.label;
  }, [entityDetails?.fileExtension]);

  const { download, isDownloading } = useDownloadTextInput({
    projectId: data?.projectId,
    isJobCanvas: !!data?.isJobCanvas,
    jobProps: data?.jobProps,
    data: {
      scenarioId: data?.scenarioId,
      datasetId: data?.itemId,
      fileType: toUpper(entityDetails?.fileExtension || ""),
      fileName: data?.displayName || data?.name
    }
  });

  const updateTextInput = () => {
    if (!areAllKeysPresentAndNotNil(data, ["projectId", "scenarioId", "itemId"])) {
      return;
    }

    const url = `${WebPaths.Dataset}/${data?.itemId}?${DatasetWebPathQueryParameters.IsAddFile}=true`;
    navigate(
      generatePath(url, {
        projectId: data?.projectId,
        scenarioId: data?.scenarioId
      })
    );
  };

  const pendingReloadTextInputsMutations = useIsMutating({
    mutationKey: [UseReloadDatasetsQueryKeys.ReloadDatasets, data?.itemId]
  });

  const isReloadingTextInputs = useMemo(
    () => pendingReloadTextInputsMutations > 0,
    [pendingReloadTextInputsMutations]
  );

  const handleAddTransformClick = () => {
    const url = _.replace(
      WebPaths.StandardRecipeDataContainer,
      "/:groupId?",
      `?entity=${data.itemId}`
    );
    const path = generatePath(url, {
      projectId: data.projectId,
      scenarioId: data?.scenarioId
    });

    navigate(path);
  };

  const handleAddAutoMLRecipeClick = () => {
    const url = _.replace(WebPaths.AutoMLRecipeContainer, "/:groupId?", `?entity=${data.itemId}`);
    const path = generatePath(url, {
      projectId: data.projectId,
      scenarioId: data?.scenarioId
    });

    navigate(path);
  };

  const handleAddApiConnectorRecipe = () => {
    const url = `${WebPaths.APIConnectorRecipeContainer}?entity=${data.itemId}`;
    navigate(
      generatePath(url, {
        projectId: data.projectId,
        scenarioId: data?.scenarioId
      })
    );
  };

  const getViewDataPath = (tab = ViewFileWebPathQueryParameters.Data) => {
    if (!areAllKeysPresentAndNotNil(data, ["projectId", "scenarioId", "id"])) {
      return;
    }

    let path = generatePath(`${WebPaths.ViewFile}/?${ViewFileWebPathQueryParameters.Tab}=${tab}`, {
      projectId: data?.projectId,
      scenarioId: data?.scenarioId,
      fileId: data?.id
    });

    if (!!data?.jobProps) {
      if (
        !data?.isJobCanvasPath ||
        !areAllKeysPresentAndNotNil(data?.jobProps, ["jobId", "jobRunId"])
      ) {
        return;
      }

      path = generatePath(
        `${WebPaths.JobRoutes}${WebPaths.JobFileRoutes}/?${ViewFileWebPathQueryParameters.Tab}=${tab}`,
        {
          projectId: data?.projectId,
          jobId: data?.jobProps?.jobId,
          scenarioId: data?.scenarioId,
          jobRunId: data?.jobProps?.jobRunId,
          fileId: data?.id
        }
      );
    }

    return path;
  };

  const goToViewData = (tab = ViewFileWebPathQueryParameters.Data) => {
    const path = getViewDataPath(tab);
    !!path && navigate(path);
  };

  const onDelete = () => {
    setIsDeleteModalOpen(true);
  };

  const isUnbuilt = useMemo(() => {
    return !entity?.status || !["BUILT"].includes(entity?.status);
  }, [entity?.status]);

  const onClose = () => {
    setSideComponent({
      sideComponent: null,
      sideComponentProps: null
    });
  };

  // Confirm update text input - STARTS >>
  const promptConfirmUpdateTextInput = () => {
    setShowConfirmUpdateTextInputModal(() => true);
  };

  const resetConfirmUpdateTextInput = () => {
    setShowConfirmUpdateTextInputModal(() => false);
  };

  const confirmUpdateTextInput = () => {
    resetConfirmUpdateTextInput();
    updateTextInput();
  };
  // << ENDS - Confirm update text input

  // Confirm reload text input - STARTS >>
  const handleReload = () => {
    setShowConfirmReloadTextInputModal(true);
  };

  const resetConfirmReloadTextInput = () => {
    setShowConfirmReloadTextInputModal(() => false);
  };
  // << ENDS - Confirm reload text input

  return (
    <NewThemeWrapper>
      <Box className={drawerContent}>
        {entity && (
          <DeleteNodeModal
            open={isDeleteModalOpen}
            onClose={() => {
              setIsDeleteModalOpen(false);
            }}
            deleteNote={
              <div>
                <span>Note: </span>
                {psMessage}
              </div>
            }
            projectId={data?.projectId}
            nodeId={data.itemId}
            nodeName={data.label}
            nodeType="file"
            onAfterSubmit={onClose}
          />
        )}

        {!!showConfirmReloadTextInputModal && (
          <ReloadDatasetModal
            open
            isTextInput
            id={data.itemId}
            name={data?.label}
            projectId={data.projectId}
            isFiveTran={entity?.dataSourceType === thirdPartyTypeName}
            onCancel={resetConfirmReloadTextInput}
            onSuccess={resetConfirmReloadTextInput}
          />
        )}

        {!!showConfirmUpdateTextInputModal && (
          <ConfirmUpdateTextInput
            resetConfirmUpdateTextInput={resetConfirmUpdateTextInput}
            updateTextInput={confirmUpdateTextInput}
          />
        )}

        <SubTopNavBarWrapper
          variant="drawer"
          onDrawerClose={onClose}
          subTopNavBarLeftSection={{
            component: (
              <>
                <FileReadonly data={data} />
                <RenderText
                  color="textSecondary"
                  isOverflowTooltip
                  linkTo={getViewDataPath()}
                  data-testid="sidebar-entity-name">
                  {data?.label}
                </RenderText>
              </>
            )
          }}
        />

        <div className={content} style={{ marginTop: 44 }}>
          {isLoading || isEntityDetailsLoading ? (
            <CommonLoader />
          ) : (
            <>
              <Card className={cardMargin}>
                <CardContent>
                  <div className={flex}>
                    <div style={{ display: "flex", gap: "10px" }} className={datasetItemGap}>
                      <Button
                        color="primary"
                        variant="outlined"
                        size="small"
                        onClick={() => goToViewData()}
                        data-testid="sidebar-entity-preview-icon"
                        startIcon={<EyeIcon viewBox="0 0 20 20" />}>
                        Preview
                      </Button>

                      {!data?.isJobCanvas && isDefaultScenario && (
                        <AddMenu
                          // Files
                          isTextInput={toLower(data?.type) === toLower(NodeTypes.File)}
                          isAddDatasetHidden={!!data?.isOutputDataset || !!data?.dataSourceType}
                          addDataset={() => {
                            if (data?.status === EntityDtoStatusEnum.Unbuilt) {
                              updateTextInput();
                            } else {
                              promptConfirmUpdateTextInput();
                            }
                          }}
                          // Recipes
                          isUnbuilt={isUnbuilt}
                          addTemplateRecipe={handleAddTransformClick}
                          addRapidModelRecipe={handleAddAutoMLRecipeClick}
                          onAddApiConnectorRecipe={handleAddApiConnectorRecipe}
                          canAddStandardRecipe={canAddStandardRecipe}
                          canAddAiAssistedRecipe={false}
                          canAddRapidModelRecipe={false}
                          menuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "center"
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "center"
                            }
                          }}
                        />
                      )}
                      {!!data.entityDSDetails &&
                        data?.entityDSDetails?.purpose === "IMPORT" &&
                        isDefaultScenario &&
                        !data?.isJobCanvas && (
                          <Tooltip
                            title={
                              isReloadingTextInputs
                                ? "Please wait. The reload text input action is in progress."
                                : entity?.dataSourceType === thirdPartyTypeName
                                  ? "Synchronizes the current text input with latest data in corresponding data connector"
                                  : "Synchronizes the current text input with remote storage and retrieves the latest data"
                            }>
                            <span>
                              <Button
                                variant="outlined"
                                size="small"
                                color="primary"
                                data-testid="sidebar-entity-reload-icon"
                                disabled={isReloadingTextInputs}
                                startIcon={
                                  isReloadingTextInputs ? (
                                    <CircularProgress size={16} />
                                  ) : (
                                    <CachedIcon style={{ width: "16px", height: "16px" }} />
                                  )
                                }
                                onClick={handleReload}>
                                Reload
                              </Button>
                            </span>
                          </Tooltip>
                        )}
                    </div>
                    {!data?.isJobCanvas && (
                      <div
                        style={{ display: "flex", gap: "10px", alignItems: "center" }}
                        className="moreIcon">
                        <Divider orientation="vertical" />

                        <MoreOptions
                          showTitle
                          options={[
                            ...(!!entityDetails?.fileExtension &&
                            !!entity?.status &&
                            ["BUILT"].includes(entity?.status)
                              ? [
                                  {
                                    label: !!fileTypeLabel
                                      ? `Export as ${fileTypeLabel}`
                                      : "Export",
                                    icon: <ExportIcon viewBox="0 0 22 24" />,
                                    action: () => download(),
                                    isDisabled: isDownloading,
                                    isLoading: isDownloading,
                                    testId: "sidebar-entity-raw-export-icon"
                                  }
                                ]
                              : []),
                            ...(isDefaultScenario && !data?.isJobCanvas
                              ? [
                                  {
                                    label: "Delete",
                                    icon: <TrashIcon viewBox="0 0 20 22" />,
                                    action: onDelete,
                                    testId: "sidebar-entity-delete-icon"
                                  }
                                ]
                              : [])
                          ]}
                        />
                      </div>
                    )}
                  </div>
                </CardContent>
              </Card>

              {entityDetails ? (
                <EntityDetails
                  projectId={data.projectId}
                  entityId={data.itemId}
                  scenarioId={data?.scenarioId}
                  isTextInput={toLower(data?.type) === toLower(NodeTypes.File)}
                  fileTypeLabel={fileTypeLabel}
                  entityDetails={entityDetails}
                  isOutputDataset={data?.isOutputDataset}
                  navigateToSource={() => goToViewData(ViewFileWebPathQueryParameters.Source)}
                  isSourceFileImport={
                    // File should be a root-node and shouldn't have been imported by a data-connector.
                    data?.isRootNode && !entityDetails?.dataSourceId && !data?.entityDSDetails?.id
                  }
                  cardMargin={cardMargin}
                />
              ) : (
                <CommonLoader />
              )}

              {!data?.jobProps && data?.isOutputDataset ? (
                <>
                  {entity && (
                    <DestinationDetails
                      entity={entity}
                      scenarioId={data?.scenarioId}
                      loading={isLoading}
                      disableExport={isUnbuilt}
                      isTextInput={toLower(data?.type) === toLower(NodeTypes.File)}
                    />
                  )}
                </>
              ) : (
                !!data?.entityDSDetails?.id &&
                data?.entityDSDetails?.purpose === "IMPORT" && (
                  <SourceDetails setSideComponent={setSideComponent} data={data} />
                )
              )}
            </>
          )}
        </div>
      </Box>
    </NewThemeWrapper>
  );
};

export default FileModalV2;
