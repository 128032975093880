import React, { useMemo, useState } from "react";
import _ from "lodash";
import { Switch, FormControlLabel, FormGroup, Tooltip, Typography } from "@material-ui/core";

import { DATAAPP_PERMISSIONS } from "pages/DataApps/utils/DataApps.constants";
import {
  DataappAskAIConfigDataAppPermissionsEnum,
  DataappAskAIConfigInputTypeEnum
} from "@rapidcanvas/rc-api-core";
import { InfoOutlined } from "@material-ui/icons";
import Modal, { ModalVariants } from "components/custom/Modal/Modal";
import HideSidePanelInfo from "./HideSidePanelInfo";

interface IDataAppPermissionsProps {
  permissions: DataappAskAIConfigDataAppPermissionsEnum[];
  inputType: DataappAskAIConfigInputTypeEnum;
  onChange: (permissions: DataappAskAIConfigDataAppPermissionsEnum[]) => void;
}

interface IPermission {
  label: string;
  tooltip: string;
  disabled: boolean;
  name: DataappAskAIConfigDataAppPermissionsEnum;
}

const DataAppPermissions: React.FC<IDataAppPermissionsProps> = (props) => {
  const { inputType, permissions, onChange } = props;
  const [show, setShow] = useState(false);

  const filtered = useMemo(() => {
    let allPermissions = _.map(DATAAPP_PERMISSIONS, (val, key) => ({
      ...val,
      disabled:
        key === DataappAskAIConfigDataAppPermissionsEnum.InputNameVisibility
          ? permissions.includes(DataappAskAIConfigDataAppPermissionsEnum.ChatInputSelection)
            ? true
            : false
          : false,
      name: key
    }));

    if (
      !_.includes(permissions, DataappAskAIConfigDataAppPermissionsEnum.ChatInputSelection) ||
      !_.includes(permissions, DataappAskAIConfigDataAppPermissionsEnum.InputNameVisibility)
    ) {
      allPermissions = _.map(allPermissions, (val) => ({
        ...val,
        disabled:
          val.name === DataappAskAIConfigDataAppPermissionsEnum.InputViewPdfApp
            ? true
            : val.disabled
      }));
    }

    if (inputType === DataappAskAIConfigInputTypeEnum.PredictionService) {
      return _.filter(
        allPermissions,
        ({ name }) =>
          !_.includes(
            [
              DataappAskAIConfigDataAppPermissionsEnum.InputViewPdfApp,
              DataappAskAIConfigDataAppPermissionsEnum.SlashOption,
              DataappAskAIConfigDataAppPermissionsEnum.UserCharts
            ],
            name
          )
      );
    }

    if (inputType === DataappAskAIConfigInputTypeEnum.RagFiles) {
      return _.filter(
        allPermissions,
        ({ name }) =>
          !_.includes(
            [
              DataappAskAIConfigDataAppPermissionsEnum.SlashOption,
              DataappAskAIConfigDataAppPermissionsEnum.UserCharts
            ],
            name
          )
      );
    }

    return _.filter(
      allPermissions,
      ({ name }) => name !== DataappAskAIConfigDataAppPermissionsEnum.InputViewPdfApp
    );
  }, [inputType, permissions]);

  const handleChange = (permission: DataappAskAIConfigDataAppPermissionsEnum, checked: boolean) => {
    if (checked) {
      if (permission === DataappAskAIConfigDataAppPermissionsEnum.ChatInputSelection) {
        onChange(
          _.uniq([
            ...permissions,
            DataappAskAIConfigDataAppPermissionsEnum.ChatInputSelection,
            DataappAskAIConfigDataAppPermissionsEnum.InputNameVisibility
          ])
        );
      } else {
        onChange([...permissions, permission]);
      }
    } else {
      if (permission === DataappAskAIConfigDataAppPermissionsEnum.ChatInputSelection) {
        setShow(true);
        onChange(
          _.without(
            permissions,
            DataappAskAIConfigDataAppPermissionsEnum.ChatInputSelection,
            DataappAskAIConfigDataAppPermissionsEnum.InputViewPdfApp
          )
        );
      } else if (permission === DataappAskAIConfigDataAppPermissionsEnum.InputNameVisibility) {
        onChange(
          _.without(
            permissions,
            DataappAskAIConfigDataAppPermissionsEnum.InputNameVisibility,
            DataappAskAIConfigDataAppPermissionsEnum.InputViewPdfApp
          )
        );
      } else {
        onChange(_.without(permissions, permission));
      }
    }
  };

  return (
    <>
      <Typography variant="body1" style={{ color: "#515151" }} gutterBottom>
        Consumer Permission
      </Typography>
      <FormGroup>
        {_.map(filtered, (val: IPermission) => {
          const child = (
            <FormControlLabel
              key={val.name}
              control={
                <Switch
                  color="primary"
                  disabled={val.disabled}
                  checked={permissions.includes(val.name)}
                  onChange={(__, checked) => handleChange(val.name, checked)}
                />
              }
              label={
                <span>
                  {val.label}
                  <Tooltip title={val.tooltip}>
                    <InfoOutlined
                      style={{
                        fontSize: "1rem",
                        marginBottom: "2px",
                        marginLeft: "4px",
                        color: "#8a8989"
                      }}
                    />
                  </Tooltip>
                </span>
              }
            />
          );

          if (
            val.name === DataappAskAIConfigDataAppPermissionsEnum.InputNameVisibility &&
            val.disabled
          ) {
            return (
              <Tooltip
                key={val.name}
                placement="top-start"
                title={`This permission depends on "Allow Input Selection" and cannot be modified`}>
                {child}
              </Tooltip>
            );
          }
          return child;
        })}
      </FormGroup>
      <HideSidePanelInfo inputType={inputType} />
      {show && (
        <Modal
          open={show}
          variant={ModalVariants.Delete}
          title="Alert!"
          content={[
            "Disabling input selection for consumer users will prevent them from associating any inputs with chats.",
            "Ensure that at least one input is set for chats within this DataApp after updating this permission"
          ]}
          cancelLabel="Close"
          hideSubmitAction
          onClose={() => setShow(false)}
        />
      )}
    </>
  );
};

export default DataAppPermissions;
