import React, { useCallback, useEffect, useMemo, useState } from "react";

// Packages
import { generatePath, useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import {
  clone,
  filter,
  find,
  get,
  includes,
  isEmpty,
  isNil,
  map,
  size,
  times,
  toLower,
  uniq,
  without
} from "lodash";

// MUI
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import { useTheme } from "@material-ui/core/styles";

// Icons
import { PlusIcon } from "icons/NewUX/PlusIcon";

// Open API
import { EnvDto } from "@rapidcanvas/rc-api-core";

// Utils
import { WebPaths } from "src/routing/routes";
import { sortEnvironmentsByDateAndStatus } from "../../utils/Environments.helpers";
import { setLocalStorageItem } from "src/services/LocalStorage/LocalStorage.service";

// Hooks
import {
  useGetEnvironments,
  UseGetEnvironmentsQueryKeys,
  useGetEnvironmentTypes
} from "src/hooks/api";

// Components
import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import CommonLoader from "src/components/CommonLoader";
import { Search } from "src/components";
import { PreviewPlaceholder, ToggleView } from "src/components/custom";
import NoDataFoundDefault from "src/pages/common/NoDataFoundDefault";
import NewEnvironmentModal from "./components/NewEnvironmentModal/NewEnvironmentModal";
import EnvironmentTypeFilter from "./components/EnvironmentTypeFilter";
import EnvironmentCards from "./components/EnvironmentCards";
import EnvironmentsTable from "./components/EnvironmentsTable/EnvironmentsTable";
import SplashSectionWrapper from "./components/SplashSectionWrapper";
import DeleteEnvironment from "../common/DeleteEnvironment/DeleteEnvironment";
import EnvironmentConfig from "../common/EnvironmentConfig/EnvironmentConfig";
import EnvironmentLogs from "../common/EnvironmentLogs/EnvironmentLogs";

// Constants
import { listViewPages, PagesViewConfiguration } from "constants/index";
import { EnvironmentsConfig } from "./utils/Environments.constants";

// Styles
import useStyles from "./Environments.styles";
import { IReturn } from "src/hooks/api/environments/useGetEnvironmentTypes";

const Environments = () => {
  const theme = useTheme();
  const classes = useStyles();

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const pagesViewConfiguration = JSON.parse(localStorage.getItem(PagesViewConfiguration) || "{}");
  const isCardsViewStorage = get(pagesViewConfiguration, listViewPages.ENVIRONMENT, true);

  // States - STARTS >>
  const [environmentTypes, setEnvironmentTypes] = useState<IReturn[]>([]);
  const [envTypeNames, setEnvTypeNames] = useState<string[]>([]);
  const [selectedEnvTypes, setSelectedEnvTypes] = useState<string[]>([]);

  const [isCardsView, setIsCardsView] = useState(isCardsViewStorage);
  const [searchValue, setSearchValue] = useState("");

  const [showNewEnvironmentModal, setShowNewEnvironmentModal] = useState(false);
  const [showEnvironmentLogsDrawer, setShowEnvironmentLogsDrawer] = useState(false);
  const [showEnvironmentConfigDrawer, setShowEnvironmentConfigDrawer] = useState(false);
  const [showConfirmDeleteEnvModal, setShowConfirmDeleteEnvModal] = useState(false);

  const [currentEnvironment, setCurrentEnvironment] = useState<EnvDto>({});
  // << ENDS - States

  // Query hooks - STARTS >>
  const { isLoading: isEnvironmentTypesLoading, data: environmentTypesData } =
    useGetEnvironmentTypes({
      onSuccess: (data) => {
        setEnvironmentTypes(() => data);

        const names = map(data, "name");
        setEnvTypeNames(() => names);
        setSelectedEnvTypes(() => names);
      },
      refetchOnMount: true
    });

  const {
    isLoading: isEnvironmentsLoading,
    refetch: refetchEnvironments,
    data: environmentsData
  } = useGetEnvironments({
    enabled: false
  });

  useEffect(() => {
    const data = queryClient.getQueryData([UseGetEnvironmentsQueryKeys.Environments]);

    if (isEmpty(data)) {
      refetchEnvironments();
    }
  }, []);

  const environments = useMemo(
    () => sortEnvironmentsByDateAndStatus(environmentsData),
    [environmentsData]
  );

  useEffect(() => {
    if (!isEmpty(currentEnvironment) && !!currentEnvironment?.id) {
      const env = find(environmentsData, { id: currentEnvironment?.id });
      if (!isNil(env)) setCurrentEnvironment(() => env);
    }
  }, [environments]);
  // << ENDS - Query hooks

  const toggleView = (value: boolean) => {
    setIsCardsView(value);

    setLocalStorageItem({
      key: PagesViewConfiguration,
      data: { ...pagesViewConfiguration, [listViewPages.ENVIRONMENT]: value },
      shouldJsonStringify: true
    });
  };

  const setNewEnvironmentModalHandler = useCallback(
    () => setShowNewEnvironmentModal(() => true),
    []
  );
  const resetNewEnvironmentModalHandler = useCallback(
    () => setShowNewEnvironmentModal(() => false),
    []
  );

  const onSearch = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event?.target?.value || "";
    setSearchValue(() => value);
  }, []);

  const clearSearch = useCallback(() => setSearchValue(() => ""), []);

  const onEnvTypeSelect = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, selectedEnvType?: string) => {
      const checked = !!(event?.target as HTMLInputElement)?.checked;

      if (!selectedEnvType) {
        setSelectedEnvTypes(checked ? envTypeNames : []);
      } else {
        setSelectedEnvTypes((prevSelected) => {
          const newSelected = clone(prevSelected);
          return checked
            ? uniq([...newSelected, selectedEnvType]) // Add if selected and keep unique
            : without(newSelected, selectedEnvType); // Remove if not selected
        });
      }
    },
    [envTypeNames]
  );

  const filteredEnvironments = useMemo(
    () =>
      filter(environments, (env: EnvDto) => {
        const nameMatches = !!searchValue
          ? includes(toLower(env?.name), toLower(searchValue))
          : true;

        const typeMatches = !isEmpty(environmentTypes)
          ? includes(selectedEnvTypes, env?.envType)
          : true;

        return !!nameMatches && !!typeMatches;
      }),
    [environments, searchValue, selectedEnvTypes, environmentTypes]
  );

  const onEdit = useCallback((envId?: string) => {
    if (!envId) {
      return;
    }

    navigate(generatePath(WebPaths.EnvironmentConfig, { envId }));
  }, []);

  // Environment config - STARTS >>
  const onEnvironmentConfig = useCallback((env?: EnvDto) => {
    setCurrentEnvironment(() => env || {});
    setShowEnvironmentConfigDrawer(() => true);
  }, []);

  const resetEnvironmentConfigDrawer = useCallback(() => {
    setCurrentEnvironment(() => ({}));
    setShowEnvironmentConfigDrawer(() => false);
  }, []);
  // << ENDS - Environment config

  // Environment logs - STARTS >>
  const onEnvironmentLogs = useCallback((env?: EnvDto) => {
    setCurrentEnvironment(() => env || {});
    setShowEnvironmentLogsDrawer(() => true);
  }, []);

  const resetEnvironmentLogsDrawer = useCallback(() => {
    setCurrentEnvironment(() => ({}));
    setShowEnvironmentLogsDrawer(() => false);
  }, []);
  // << ENDS - Environment logs

  // Delete environment - STARTS >>
  const promptConfirmDeleteEnvironment = useCallback((env?: EnvDto) => {
    setCurrentEnvironment(() => env || {});
    setShowConfirmDeleteEnvModal(() => true);
  }, []);

  const resetDeleteEnvironment = () => {
    setCurrentEnvironment(() => ({}));
    setShowConfirmDeleteEnvModal(() => false);
  };
  // << ENDS - Delete environment

  const availableWindowSize = window.innerWidth * (1 - EnvironmentsConfig.ExemptingWidth);
  const numberOfCards = Math.floor(
    (availableWindowSize + 24) / (EnvironmentsConfig.CardWidth + 24)
  );

  const startLoc = isCardsView
    ? (availableWindowSize + 24 - numberOfCards * (EnvironmentsConfig.CardWidth + 24)) / 2
    : 0;

  return (
    <>
      {!!showNewEnvironmentModal && (
        <NewEnvironmentModal
          isEnvironmentTypesLoading={isEnvironmentTypesLoading}
          environmentTypes={environmentTypes}
          isEnvironmentsLoading={isEnvironmentsLoading}
          environments={environments}
          onClose={resetNewEnvironmentModalHandler}
        />
      )}

      <EnvironmentConfig
        open={!!showEnvironmentConfigDrawer && !isEmpty(currentEnvironment)}
        environment={currentEnvironment}
        onClose={resetEnvironmentConfigDrawer}
      />

      <EnvironmentLogs
        open={!!showEnvironmentLogsDrawer && !isEmpty(currentEnvironment)}
        environment={currentEnvironment}
        onClose={resetEnvironmentLogsDrawer}
      />

      {!!showConfirmDeleteEnvModal && !isEmpty(currentEnvironment) && (
        <DeleteEnvironment
          environment={currentEnvironment}
          resetDeleteEnvironment={resetDeleteEnvironment}
        />
      )}

      <SubTopNavBarWrapper
        // subTopNavBarLeftSection={{
        //   backNavAction: null
        // }}
        subTopNavBarRightSection={{
          component: (
            <IconButton
              color="primary"
              size="small"
              onClick={setNewEnvironmentModalHandler}
              data-testid="envsNewEnvAction">
              <PlusIcon width={28} height={28} />
            </IconButton>
          )
        }}
      />

      {!!isEnvironmentsLoading ? (
        <CommonLoader />
      ) : (
        <Grid container className={classes.containerBackground}>
          <Grid
            container
            className={classes.headerContainer}
            {...(isCardsView
              ? {
                  style: {
                    padding: `0 ${startLoc - 92}px 0 ${startLoc}px`,
                    width: availableWindowSize
                  }
                }
              : { style: { margin: "0 5% 0 10%" } })}>
            <Grid item>
              <Typography variant="h5" className={classes.title} data-testid="envsTitle">
                Environments
              </Typography>
              <Typography variant="subtitle1" data-testid="envsSubTitle">
                Showing {size(filteredEnvironments)} Environments
              </Typography>
            </Grid>

            {!!isEnvironmentsLoading ? (
              <Grid className={classes.topActions}>
                <PreviewPlaceholder
                  width={300}
                  height={35}
                  simulateFormControl={false}
                  data-testid="envsTopActionsPreview"
                  {...{
                    style: {
                      borderRadius: theme.spacing(1.5)
                    }
                  }}
                />
              </Grid>
            ) : (
              !isEmpty(environments) && (
                <Grid className={classes.topActions}>
                  {(!!searchValue ? true : !isEmpty(filteredEnvironments)) && (
                    <Search
                      onSearch={onSearch}
                      value={searchValue}
                      placeholder="Search environments"
                    />
                  )}

                  {!!isEnvironmentTypesLoading ? (
                    <Box px={1.5}>
                      <CircularProgress size={20} />
                    </Box>
                  ) : (
                    !isEmpty(environmentTypesData) && (
                      <EnvironmentTypeFilter
                        environmentTypesData={environmentTypesData || []}
                        selectedEnvTypes={selectedEnvTypes}
                        onEnvTypeSelect={onEnvTypeSelect}
                      />
                    )
                  )}

                  {!isEmpty(filteredEnvironments) && (
                    <ToggleView isPrimaryView={!!isCardsView} setIsPrimaryView={toggleView} />
                  )}
                </Grid>
              )
            )}
          </Grid>
          <Grid item container direction="column" wrap="nowrap" xs={12} className={classes.root}>
            {!!isEnvironmentsLoading ? (
              <Grid item style={{ padding: `16px ${startLoc}px`, width: availableWindowSize }}>
                <Grid
                  container
                  justifyContent="flex-start"
                  style={{ gap: theme.spacing(3), width: availableWindowSize }}>
                  {!!isCardsView ? (
                    times(6, () => (
                      <PreviewPlaceholder
                        width={375}
                        height={370}
                        simulateFormControl={false}
                        data-testid="envCardPreview"
                        {...{
                          style: {
                            borderRadius: theme.spacing(1.5)
                          }
                        }}
                      />
                    ))
                  ) : (
                    <PreviewPlaceholder
                      width={1565}
                      height={700}
                      simulateFormControl={false}
                      data-testid="envsTablePreview"
                      {...{
                        style: {
                          borderRadius: theme.spacing(1.5)
                        }
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            ) : isEmpty(environments) ? (
              <SplashSectionWrapper onClick={setNewEnvironmentModalHandler} />
            ) : isEmpty(filteredEnvironments) ? (
              <NoDataFoundDefault
                title={
                  searchValue
                    ? `No environment found with keyword "${searchValue}".`
                    : "No environment found with applied filters."
                }
                subTitle={searchValue ? undefined : "Please change filter criteria to select."}
                onClear={clearSearch}
              />
            ) : (
              <Grid item style={{ padding: `16px ${startLoc}px`, width: availableWindowSize }}>
                {!!isCardsView ? (
                  <EnvironmentCards
                    environments={filteredEnvironments}
                    onEdit={onEdit}
                    onConfig={onEnvironmentConfig}
                    onLogs={onEnvironmentLogs}
                    onDelete={promptConfirmDeleteEnvironment}
                  />
                ) : (
                  <EnvironmentsTable
                    environments={filteredEnvironments}
                    searchValue={searchValue}
                    onEdit={onEdit}
                    onConfig={onEnvironmentConfig}
                    onLogs={onEnvironmentLogs}
                    onDelete={promptConfirmDeleteEnvironment}
                  />
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Environments;
