import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { TextContentBlock } from "openai/resources/beta/threads/messages";
import { orderBy } from "lodash";
import { setSuggestions } from "src/stores/codeRecipeSlice";
import api from "services/AxiosClient/AxiosClient";
import { useDispatch } from "react-redux";

export type CodeSuggestion = {
  query: string;
  isLoading?: boolean;
  response?: string;
  code: string;
  messageId?: string;
  isError?: boolean;
};

const extractCodeAndQuery = (content: string): { code: string; query: string } => {
  const match = content.match(/Here is some code:\n([\s\S]*?): Question: (.*)/);
  return match ? { code: match[1].trim(), query: match[2].trim() } : { code: "", query: content };
};

export const useGetThreadMessages = ({
  threadId,
  recipeId,
  enabled
}: {
  recipeId?: string;
  threadId?: string | null;
  enabled: boolean;
}) => {
  const dispatch = useDispatch();
  return useQuery<CodeSuggestion[], AxiosError>({
    queryKey: [recipeId, "threadMessages", threadId],
    queryFn: async () => {
      if (!threadId) throw new Error("Thread ID is required");
      const response: any = await api.fetchResponse(() =>
        api.CodeAssistantControllerApi.getCodeAssitThread(threadId)
      );

      const messages = response.data;
      const orderedMessages = orderBy(messages, "created_at");

      let lastQuery = "";
      let lastCode = "";

      const suggestions = orderedMessages
        .map((msg) => {
          const content = (msg.content?.[0] as TextContentBlock)?.text?.value || "";

          if (msg.role === "user") {
            const { code, query } = extractCodeAndQuery(content);
            lastQuery = query;
            lastCode = code;
            return null;
          } else if (msg.role === "assistant") {
            return {
              messageId: msg.id,
              query: lastQuery,
              code: lastCode,
              response: content,
              isLoading: false,
              isError: false
            };
          }
          return null;
        })
        .filter(Boolean) as CodeSuggestion[];
      dispatch(
        setSuggestions({
          recipeId: recipeId!,
          suggestions,
          copilotThread: threadId
        })
      );
      return suggestions;
    },

    enabled: enabled && !!threadId
  });
};
