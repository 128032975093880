import React, { useRef } from "react";
import MonacoEditor from "react-monaco-editor";
import { Grid, makeStyles, Typography } from "@material-ui/core";

import "./AskAICodeEditor.css";
import _ from "lodash";

type Props = {
  editorValue: string;
  errDetails?: {
    lineNo: number;
    lineOfCode: string;
    errorExplanation?: string;
    explanation?: string;
  } | null;
  errorDescription?: string | null;
  noMargin?: boolean;
};

const useStyles = makeStyles({
  editorWrap: {
    border: "1px solid #D1D1D1",
    margin: ({ noMargin }: any) => (noMargin ? "0px" : "0px 12px 8px 12px")
  },
  errorExplanation: {
    display: "flex",
    gap: "16px",
    alignItems: "center",
    padding: "4px 12px 0px 12px"
  },
  infoItem: {
    color: "rgba(0,0,0,0.6)",
    fontSize: "14px",
    lineHeight: 1.54
  }
});

export const AskAICodeEditor = ({ editorValue, errDetails, errorDescription, noMargin }: Props) => {
  const editorRef = useRef(null);
  const classes = useStyles({ noMargin });
  const handleEditorDidMount = (editor: any, monaco: any) => {
    editorRef.current = editor;
    if (!_.isEmpty(errDetails) && !(errDetails.lineNo === 0 && !errDetails.lineOfCode)) {
      const lineNumber = errDetails.lineNo;
      const hoverMessage = errDetails.lineOfCode;

      editor.deltaDecorations(
        [],
        [
          {
            range: new monaco.Range(lineNumber, 1, lineNumber, 1),
            options: {
              isWholeLine: true,
              className: "my-line-highlight",
              hoverMessage: { value: hoverMessage }
            }
          }
        ]
      );
    }
  };

  return (
    <Grid container direction="column" wrap="nowrap">
      {errorDescription && (
        <Grid item className={classes.errorExplanation}>
          <Typography className={classes.infoItem} data-testid="codeEditorErrorDesc">
            <b>More Details: </b>
            {errorDescription}
          </Typography>
        </Grid>
      )}
      <Grid item className={classes.editorWrap} data-testid="codeEditorWrap">
        <MonacoEditor
          language="python"
          height={`350px`}
          width="100%"
          theme="vs"
          value={editorValue}
          options={{
            readOnly: true,
            theme: "vs",
            wordWrap: "bounded",
            wordWrapColumn: 79,
            autoIndent: "full",
            tabSize: 4,
            minimap: { enabled: false },
            renderLineHighlight: "none",
            lineNumbers: "off",
            scrollBeyondLastLine: false,
            overviewRulerLanes: 0,
            padding: {
              top: 12
            }
          }}
          editorDidMount={handleEditorDidMount}
        />
      </Grid>
    </Grid>
  );
};
