import React, { memo } from "react";

import { Box, Grid, Size, TableCell } from "@material-ui/core";
import { useStyles } from "./TableHeader.styles";
import Select from "src/components/Select/Select";
import OverflowTooltip from "src/components/OverflowTooltip";

import { v4 as uuidv4 } from "uuid";
import { MoreOptions } from "src/components/MoreOptions/MoreOptions";
import { Column, flexRender, Header, TableState } from "@tanstack/react-table";
import { max } from "lodash";
import { getTextWidth } from "src/utils/helpers";
import { CommonConstants } from "src/components/custom/Table/utils/Table.constants";

import ArrowDropDownRoundedIcon from "@material-ui/icons/ArrowDropDownRounded";
import ArrowDropUpRoundedIcon from "@material-ui/icons/ArrowDropUpRounded";
import { TData } from "../typing";

const EditableSelectField = ({
  column,
  updateMyData
}: {
  column: Column<TData, unknown>;
  updateMyData: any;
}) => {
  const {
    id: columnId,
    columnDef: { meta, header }
  } = column;

  const {
    selectVariant = "filled",
    selectStyles = "",
    hideSelectInputLabel = false,
    values,
    meta: initialValue
  } = meta ?? {};
  const [value, setValue] = React.useState(initialValue);

  const onChange = (event: any) => {
    const updatedData = event?.target?.value;
    setValue(updatedData);
    updateMyData?.({ columnId, value: updatedData });
  };

  return (
    <Select
      id={`schemaDropDown-${uuidv4()}`}
      variant={selectVariant}
      data-testid={`table-header-schema-dropdown-${column.id}`}
      size="small"
      hideInputLabel={hideSelectInputLabel}
      required
      fullWidth
      values={values || []}
      label={header?.toString()}
      value={value}
      onChange={onChange}
      fixedMenuHeight={true}
      className={selectStyles}
    />
  );
};

const TableHeader = ({
  header,
  size,
  isLoading,
  columnOptionsCustomRender: ColumnOptionsCustomRender,
  updateMyData,
  className,
  index,
  scrollToTop,
  showTypes
}: {
  header: Header<TData, unknown>;
  isLoading?: boolean;
  size: Size;
  columnOptionsCustomRender: any;
  updateMyData: any;
  className: string;
  index: number;
  scrollToTop: () => void;
  state: TableState;
  showTypes: boolean;
}) => {
  const styles = useStyles();
  const { column } = header;
  const sortColumn = () => {
    header?.column?.toggleSorting();
    scrollToTop();
  };
  const resizeHandler = header.getResizeHandler();
  const isSorted = header.column.getIsSorted();
  const columnOption = header.column.columnDef.meta?.options;
  const dataType = header.column.columnDef.meta?.data?.dataType ?? "-";
  return (
    <TableCell
      className={
        column.columnDef.meta?.meta
          ? `${styles.schemaHeader} ${index === 0 ? styles.schemaFirstColumn : ""}`
          : `${isSorted ? styles.highlight : ""} ${className}`
      }
      size={size}
      data-testid={`table-header-${column.id}`}
      style={{
        position: "sticky",
        minWidth: column.getSize(),
        width: column.getSize(),
        maxWidth: column.getSize(),
        padding: 0
      }}>
      <Grid container direction="column">
        <Grid container>
          <Grid
            container
            alignItems="center"
            style={{ flexWrap: "nowrap", padding: "12px 24px 11px 16px" }}
            className={`${styles.headerContainer}  ${column.columnDef.meta?.meta ? styles.schemaHeaderContainer : ""}`}>
            <Grid
              container
              style={{
                cursor: header.column.getCanSort() ? "pointer" : "default",
                overflow: "auto",
                flexWrap: "nowrap",
                flex: 1
              }}
              onClick={header.column.getCanSort() ? sortColumn : undefined}>
              {header.column?.id === "select" ? (
                flexRender(header?.column?.columnDef?.header, header?.getContext())
              ) : (
                <OverflowTooltip
                  style={{
                    maxWidth: max([getTextWidth(header?.column?.columnDef?.header as string), 250]),
                    whiteSpace: "nowrap"
                  }}
                  value={flexRender(header?.column?.columnDef?.header, header?.getContext())}
                  tooltipContainerProps={{
                    className: CommonConstants.columnClassName
                  }}
                />
              )}

              {header?.column?.getCanSort() && (
                <>
                  {header?.column?.getIsSorted() === "asc" && (
                    <ArrowDropUpRoundedIcon data-testid={`table-header-sort-asc${column.id}`} />
                  )}
                  {header?.column?.getIsSorted() === "desc" && (
                    <ArrowDropDownRoundedIcon data-testid={`table-header-sort-desc${column.id}`} />
                  )}
                </>
              )}
            </Grid>
            {(ColumnOptionsCustomRender || !!columnOption?.length) && !isLoading && (
              <MoreOptions
                testId="table-custom-options-render"
                options={columnOption}
                menuProps={{
                  MenuListProps: {
                    disablePadding: true
                  }
                }}
                customMenuRender={
                  <ColumnOptionsCustomRender
                    columnId={header.column?.id}
                    name={column.columnDef.meta?.name}
                  />
                }
              />
            )}
          </Grid>
          {!!header.column?.columnDef.meta?.meta && (
            <Grid className={styles.schemaControlContainer}>
              {!!header.column?.columnDef.meta.isEditable ? (
                <EditableSelectField column={header.column} updateMyData={updateMyData} />
              ) : (
                <Box
                  data-testid={`table-column-meta-${header.column.id}`}
                  className={header.column?.columnDef.meta.selectStyles}>
                  {header.column?.columnDef.meta.meta}
                </Box>
              )}
            </Grid>
          )}
        </Grid>
        {showTypes && (
          <Grid
            container
            style={{
              color: " #7c7c7c",
              padding: "6px 24px 6px 16px",
              fontSize: "small",
              borderTop: "1px solid #e0e0e0",
              fontStyle: "italic",
              fontWeight: "bold",
              pointerEvents: "none"
            }}>
            <OverflowTooltip
              style={{
                flexGrow: 1,
                maxWidth: 250,
                whiteSpace: "nowrap"
              }}
              value={dataType}
              tooltipContainerProps={{
                className: CommonConstants.columnClassName
              }}
            />
          </Grid>
        )}
      </Grid>
      {header.column.id !== "selection" && (
        <Grid
          data-testid={`table-column-resizer-${header.column.id}`}
          onMouseDown={resizeHandler}
          onTouchStart={resizeHandler}
          style={{ height: showTypes ? "50%" : "100%" }}
          className={`${styles.resizer}  ${header.column?.columnDef.meta?.meta ? styles.schemaHeaderResizer : ""}`}
        />
      )}
    </TableCell>
  );
};

TableHeader.defaultProps = {
  size: "large"
};

export default memo(TableHeader);
