import React, { useMemo } from "react";

import { environmentsTypes } from "environmentsModule/utils/Environments.constants";

const EnvironmentTypeConfig = (props: $TSFixMe) => {
  const { envType, cores, memInMbs, diskInGbs } = props || {};

  const thisEnvType = useMemo(() => {
    if (!envType) {
      return "";
    }
    const output = environmentsTypes[envType] ?? envType;

    return `${output ? `${output} - ` : ""}`;
  }, [envType]);

  return (
    <>
      {thisEnvType}
      {`Core: ${cores} | Memory: ${(memInMbs / 1024).toFixed()} GB | Disk Space: ${diskInGbs} GB`}
    </>
  );
};

export default EnvironmentTypeConfig;
