import { useMutation, UseMutationResult } from "@tanstack/react-query";

import api from "services/AxiosClient/AxiosClient";

interface IPayload {
  projectIds: string[];
}

const useDeleteMultipleProject = (): UseMutationResult<unknown, unknown, IPayload, unknown> =>
  useMutation({
    mutationFn: async ({ projectIds }: IPayload) => {
      return await api.fetchResponse(
        async () => await api.ProjectControllerApi.deleteMultipleProjects(projectIds, true)
      );
    }
  });

export default useDeleteMultipleProject;
