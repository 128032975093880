// Packages
import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { isArray } from "lodash";
import { EnvironmentsTypes } from "environmentsModule/utils/Environments.constants";

// Utils
import api from "services/AxiosClient/AxiosClient";

export const enum UseGetEnvironmentTypesQueryKeys {
  EnvironmentTypes = "environmentTypes"
}

export interface IReturn {
  cores: number;
  diskInGbs: number;
  gpus: number;
  memInGbs: number;
  memInMbs: number;
  name: EnvironmentsTypes;
}
interface IEnvironmentTypesProps extends UseQueryOptions<IReturn[], unknown, any[]> {}

const useGetEnvironmentTypes = (props?: IEnvironmentTypesProps): UseQueryResult<IReturn[]> => {
  const { ...options } = props;

  return useQuery({
    queryKey: [UseGetEnvironmentTypesQueryKeys.EnvironmentTypes],
    queryFn: async () => {
      const response = await api.fetchResponse(
        async () => await api.EnvControllerApi.getAllEnvTypes()
      );
      return response as any;
    },
    select: (data) => (isArray(data) ? data : []),
    ...options
  });
};

export default useGetEnvironmentTypes;
