import React, { useMemo, useState } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Tooltip,
  Button,
  Grid,
  CircularProgress,
  useTheme
} from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { useNavigate, useLocation, generatePath } from "react-router-dom";

import { TrashIcon } from "icons/NewUX/TrashIcon";
import { RenderText } from "components/custom";

import DeleteNodeModal, { psMessage } from "src/pages/ViewData/DeleteNodeModal/DeleteNodeModal";
import DrawerAbout from "./components/DrawerAbout";
import DrawerAccordion from "./components/DrawerAccordion";
import Text from "components/Widget/Text";
import useEntities from "hooks/api/entities/useEntities";
import usePredictionServiceByModel from "src/hooks/usePredictionServiceByModel";
import { getErrorMessage } from "services/Apis/Apis.service";
import { TABS } from "pages/Library/ArtifactsAndModels/Models/ModelDetails";
import { checkIfDefaultScenario } from "src/pages/private/ProjectsModule/utils";
import { dateFormat } from "utils/helpers/date.helpers";
import { getUniqueEndpoint } from "pages/private/ProjectsModule/pages/PredictionService/utils/PredictionService.helpers";
import { handleResponse } from "services/Apis/Apis.service";
import { sideComponentSetter } from "stores/zustand/stores.selectors";
import { useScenariosStore, useDrawerStore } from "stores/zustand/stores";
import { useStyles } from "./components/styling";
import useCreateProjectRun from "hooks/api/projects/useCreatePredictionJobRun";
import useGetAllPredictionRuns from "hooks/api/projects/useGetAllProjectRuns";

import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import NewThemeWrapper from "src/styles/NewThemeWrapper";
import ModelReadonly from "src/pages/private/ProjectsModule/pages/Dag/components/Nodes/Model/ModelReadonly";
import { WebPaths } from "src/routing/routes";
import { EyeIcon, PredictionServiceIcon } from "icons/NewUX";
import AIGuideDialog from "src/pages/Projects/AIGuide/common/AIGuideDialog";
import MoreOptions from "src/layout/NavBars/components/SubTopNavBar/MoreOptions";
import {
  FindProjectRunsJobTypeEnum,
  ThreadResponseDtoTargetTypeEnum
} from "@rapidcanvas/rc-api-core";
import { AIGuideMiniIconButton } from "src/components/Buttons/AIGuideMiniIconButton";
import CommonLoader from "src/components/CommonLoader";
import _, { forEach, includes, some, toLower } from "lodash";
import ManualRunIcon from "icons/NewUX/ManualRunIcon";
import { MODELS } from "src/pages/private/ProjectsModule/pages/PredictionJob/components/SelectModel";
import {
  JOB_TYPE,
  PREDICTION_SCHEDULER_MODEL
} from "src/pages/private/ProjectsModule/pages/Jobs/components/Job/context/JobContextProvider";
import { ClockIcon } from "icons/NewUX/ClockIcon";
import ModelAddMenu from "./ModelAddMenu";
import { PredictionJobConstants } from "src/pages/private/ProjectsModule/pages/PredictionJob/utils/PredictionJob.constants";

export const ModelModalV2 = ({ data, canAddStandardRecipe }: $TSFixMe) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const predictionServiceResults = usePredictionServiceByModel(
    data.name,
    data?.scenarioId,
    data?.jobProps?.jobRunId
  );
  const theme = useTheme();
  const [showAIGuideDialog, setShowAIGuideDialog] = useState(false);

  const {
    data: model,
    isLoading,
    refetch
  } = useEntities({
    id: data?.itemId,
    scenarioId: data?.isJobCanvas ? data?.scenarioId : undefined,
    jobRunId: data?.isJobCanvas ? data?.jobProps?.jobRunId : undefined,
    options: {
      cacheTime: Infinity,
      refetchOnMount: true,
      onError: (e) => {
        handleResponse({
          errorMessage: getErrorMessage(e) || `Error in fetching artifact - ${data.name}`
        });
      }
    }
  });
  const createProjectRun = useCreateProjectRun();
  const predictionRunResult = useGetAllPredictionRuns(data?.projectId, true, {
    enabled: !!open,
    refetchOnMount: true
  });
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
  const setSideComponent = useDrawerStore(sideComponentSetter);
  const [scenarios] = useScenariosStore((state) => [state.scenarios]);

  const isDefaultScenario = useMemo(
    () => checkIfDefaultScenario(null, scenarios, data?.scenarioId),
    [data?.scenarioId, scenarios]
  );

  const uniqueEndpoint = useMemo(() => {
    const name = predictionServiceResults.data?.predictionServiceDetails?.name;
    return name ? getUniqueEndpoint(name) : null;
  }, [predictionServiceResults.data?.predictionServiceDetails?.name]);

  const name = useMemo(() => {
    let count = 1;
    const title = PredictionJobConstants.ManualName;

    forEach(predictionRunResult.data, ({ dto }) => {
      if (includes(toLower(dto?.name), toLower(title)) || dto?.name === `${title} ${count + 1}`) {
        count += 1;
      }
    });

    while (some(predictionRunResult.data, ({ dto }) => dto?.name === `${title} ${count}`)) {
      count += 1;
    }

    return `${title} ${count}`;
  }, [predictionRunResult.data]);

  const getNavigatePath = () => {
    if (!!data?.jobProps) {
      return generatePath(`${WebPaths.JobRoutes}${WebPaths.JobModels}`, {
        projectId: data.projectId,
        jobId: data?.jobProps?.jobId,
        scenarioId: data?.scenarioId,
        jobRunId: data?.jobProps?.jobRunId,
        modelName: data.name
      });
    } else {
      return generatePath(WebPaths.ProjectModelsDetails, {
        projectId: data.projectId,
        modelName: data.name
      });
    }
  };

  const handleViewModalClick = () =>
    navigate({ pathname: getNavigatePath() }, { state: { prevPath: location.pathname } });

  const handlePredictionServiceClick = () => {
    let path = generatePath(WebPaths.ModelDetails, {
      modelName: data.name
    });
    navigate(path, {
      state: {
        prevPath: location.pathname,
        tab: TABS.predictionService.value,
        predictionServiceDetails: predictionServiceResults.data?.predictionServiceDetails
      }
    });
  };

  const handleAddTransformClick = () => {
    const url = _.replace(
      WebPaths.StandardRecipeDataContainer,
      "/:groupId?",
      `?model=${data.itemId}`
    );
    const path = generatePath(url, {
      projectId: data.projectId,
      scenarioId: data?.scenarioId
    });

    navigate(path);
  };

  const handleManualPredictionClick = async () => {
    await createProjectRun.mutateAsync(
      { name, projectId: data?.projectId, modelEntityId: data?.id, isManual: true },
      {
        onSuccess: (result) => {
          if (result?.id) {
            navigate(
              generatePath(WebPaths.EditPredictionJob, {
                projectId: data?.projectId,
                projectRunId: result?.id
              }),
              {
                state: {
                  [MODELS]: [{ id: data?.id, name: data?.name, displayName: data?.name }]
                }
              }
            );
          }
        }
      }
    );
  };

  const handleAddCodeRecipeClick = () => {
    const url = _.replace(WebPaths.CodeRecipeContainer, "/:groupId?", `?model=${data.itemId}`);
    const path = generatePath(url, {
      projectId: data.projectId,
      scenarioId: data?.scenarioId
    });

    navigate(path);
  };

  const handleAddApiConnectorRecipe = () => {
    const url = `${WebPaths.APIConnectorRecipeContainer}?model=${data.itemId}`;
    navigate(
      generatePath(url, {
        projectId: data.projectId,
        scenarioId: data?.scenarioId
      })
    );
  };

  const handleDelete = () => {
    setIsDeleteModalOpen(true);
  };

  const onClose = () => {
    setSideComponent({
      sideComponent: null,
      sideComponentProps: null
    });
  };

  const navigateToAIGuide = () => {
    setShowAIGuideDialog(true);
  };

  const handlePredictionSchedulerClick = () => {
    navigate(
      `${generatePath(`${WebPaths.JobRoutes}${WebPaths.CreateJob}`, {
        projectId: data?.projectId
      })}?${JOB_TYPE}=${FindProjectRunsJobTypeEnum.PredictionJob}`,
      {
        state: {
          [PREDICTION_SCHEDULER_MODEL]: {
            id: data?.id,
            name: data?.name,
            displayName: data?.name
          }
        }
      }
    );
  };

  const isUnbuilt = useMemo(() => {
    return !data?.status || !["BUILT"].includes(data?.status);
  }, [data?.status]);

  const otherActionDetails = useMemo(
    () => ({
      "prediction-service": {
        key: "prediction-service",
        label: "Prediction Service",
        icon: predictionServiceResults.data?.predictionServiceDetails ? (
          <Edit style={{ fontSize: "16px" }} />
        ) : (
          <PredictionServiceIcon />
        ),

        action: handlePredictionServiceClick,
        isDisabled: isUnbuilt,
        testId: "prediction-service-menu-item"
      },

      "prediction-scheduler": {
        key: "prediction-scheduler",
        label: "Prediction Scheduler",
        icon: <ClockIcon />,
        tooltip: isUnbuilt
          ? "These actions is not applicable for Unbuilt Model"
          : data.isRootNode
            ? "This option is not applicable for models without any parent recipe"
            : "",
        action: handlePredictionSchedulerClick,
        isDisabled: isUnbuilt || data?.isRootNode,
        testId: "prediction-scheduler-menu-item"
      },
      "manual-prediction": {
        key: "manual-prediction",
        label: "Manual Prediction",
        icon: createProjectRun.isLoading ? (
          <CircularProgress size={theme.spacing(1.75)} />
        ) : (
          <ManualRunIcon />
        ),
        action: handleManualPredictionClick,
        tooltip: isUnbuilt
          ? "These actions is not applicable for Unbuilt Model"
          : data.isRootNode
            ? "This option is not applicable for models without any parent recipe"
            : "",
        isDisabled:
          predictionRunResult.isLoading ||
          createProjectRun.isLoading ||
          data?.isRootNode ||
          isUnbuilt
      }
    }),
    [
      data,
      isUnbuilt,
      predictionRunResult.isLoading,
      createProjectRun.isLoading,
      predictionServiceResults.isSuccess,
      predictionServiceResults.data?.predictionServiceDetails
    ]
  );

  return (
    <NewThemeWrapper>
      <Box className={classes.drawerContent}>
        {isDeleteModalOpen && (
          <DeleteNodeModal
            open={isDeleteModalOpen}
            onClose={() => {
              setIsDeleteModalOpen(false);
            }}
            deleteNote={
              <div>
                <span>Note: Deleting this might impact associated dataapps(if any). </span>
                {psMessage}
              </div>
            }
            projectId={data?.projectId}
            nodeId={data.itemId}
            nodeName={data.label}
            nodeType="model"
            onAfterSubmit={() =>
              setSideComponent({ sideComponent: null, sideComponentProps: null })
            }
          />
        )}
        <AIGuideDialog
          open={showAIGuideDialog}
          onClose={() => {
            setShowAIGuideDialog(false);
          }}
          projectId={data?.projectId!}
          scenarioId={data?.scenarioId!}
          modelId={data.itemId!}
          targetType={ThreadResponseDtoTargetTypeEnum.Model}
        />
        <SubTopNavBarWrapper
          variant="drawer"
          onDrawerClose={onClose}
          subTopNavBarLeftSection={{
            styleProps: {
              width: "auto"
            },
            component: (
              <>
                <ModelReadonly data={data} />
                <RenderText
                  color="textSecondary"
                  isOverflowTooltip
                  linkTo={getNavigatePath()}
                  data-testid="sidebar-model-name">
                  {data?.label}
                </RenderText>
              </>
            )
          }}
        />

        <div className={classes.content} style={{ marginTop: 44 }}>
          {isLoading ||
          predictionServiceResults.isLoading ||
          predictionServiceResults.isFetching ? (
            <CommonLoader />
          ) : (
            <>
              <Card className={classes.cardMargin}>
                <CardContent>
                  <Grid container direction="row" wrap="nowrap">
                    <Grid
                      container
                      item
                      style={{ width: "calc(100% - 80px)" }}
                      className={classes.datasetItemGap}
                      wrap="nowrap">
                      <Button
                        variant="outlined"
                        startIcon={<EyeIcon viewBox="0 0 20 20" />}
                        color="primary"
                        size="small"
                        onClick={handleViewModalClick}>
                        Preview
                      </Button>
                      {!data?.isJobCanvas && !!isDefaultScenario && !isUnbuilt && (
                        <AIGuideMiniIconButton
                          width={30}
                          height={30}
                          viewBox="2 4 61 32"
                          badgeStyleProps={{
                            marginRight: 0,

                            width: 30
                          }}
                          targetId={data?.itemId!}
                          projectId={data?.projectId!}
                          onClick={navigateToAIGuide}
                        />
                      )}

                      {!data?.isJobCanvas && isDefaultScenario && !isUnbuilt && (
                        <ModelAddMenu
                          isUnbuilt={isUnbuilt}
                          onAddApiConnectorRecipe={handleAddApiConnectorRecipe}
                          addTemplateRecipe={handleAddTransformClick}
                          addAiAssistedRecipe={handleAddCodeRecipeClick}
                          canAddStandardRecipe={canAddStandardRecipe}
                          otherActionDetails={otherActionDetails}
                        />
                      )}
                    </Grid>
                    {!data?.isJobCanvas && isDefaultScenario && (
                      <Grid
                        container
                        alignItems="center"
                        item
                        style={{ width: "80px" }}
                        className="moreIcon">
                        <MoreOptions
                          showTitle
                          options={[
                            {
                              label: "Delete",
                              icon: <TrashIcon viewBox="0 0 20 22" />,
                              action: handleDelete
                            }
                          ]}
                        />
                      </Grid>
                    )}
                  </Grid>
                </CardContent>
              </Card>
              {model?.markdown && (
                <Card className={classes.cardMargin}>
                  <DrawerAbout
                    markdownString={model?.markdown}
                    projectId={data.projectId}
                    elementId={data.itemId}
                    hiddenUpdateContentAction={!!data?.isJobCanvas}
                    type="MODEL"
                    onSuccess={refetch}
                  />
                </Card>
              )}
              <div className={classes.cardMargin}>
                <Card>
                  <CardContent>
                    <Box className={classes.detailsWrapper}>
                      <Box className={classes.detailItem}>
                        <Typography className={classes.detailName}>Created:</Typography>
                        <Typography>{dateFormat(model?.created)}</Typography>
                      </Box>
                      <Box className={classes.detailItem}>
                        <Typography className={classes.detailName}>Updated:</Typography>
                        <Typography>{dateFormat(model?.updated)}</Typography>
                      </Box>
                      <Box className={classes.detailItem}>
                        <Typography className={classes.detailName}>Build:</Typography>
                        <Typography>{dateFormat(model?.built)}</Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </div>
              {predictionServiceResults.data?.predictionServiceDetails && (
                <Card className={classes.cardMargin}>
                  <DrawerAccordion
                    title="PREDICTION SERVICE"
                    content={
                      <Box className={classes.detailsWrapper}>
                        <Box className={classes.detailItem}>
                          <span className={classes.detailName}>Name:</span>
                          <Tooltip
                            placement="top-start"
                            title={
                              <Text
                                value={predictionServiceResults.data?.predictionServiceDetails.name}
                              />
                            }>
                            <Link
                              style={{ maxWidth: "470px", marginBottom: "12px" }}
                              className={`${classes.link} ${classes.endpoint}`}
                              to={`/artifacts-and-models/model/${data.name}`}
                              state={{
                                tab: TABS.predictionService.value,
                                predictionServiceDetails:
                                  predictionServiceResults.data?.predictionServiceDetails
                              }}>
                              <Text
                                style={{
                                  overflow: "hidden",
                                  maxWidth: "475px",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis"
                                }}
                                value={predictionServiceResults.data?.predictionServiceDetails.name}
                              />
                            </Link>
                          </Tooltip>
                        </Box>
                        <Box className={classes.detailItem}>
                          <Typography className={classes.detailName}>Environment:</Typography>
                          <Typography>
                            {`${
                              predictionServiceResults.data?.predictionServiceDetails.envDetails
                                .name
                            } | Core: ${
                              predictionServiceResults.data?.predictionServiceDetails.envDetails
                                .cores
                            } | Memory: ${
                              predictionServiceResults.data?.predictionServiceDetails.envDetails
                                .memInMbs / 1024
                            } GB | Disk Space:  ${
                              predictionServiceResults.data?.predictionServiceDetails.envDetails
                                .diskInGbs
                            } GB`}
                          </Typography>
                        </Box>
                        <Box component="div" sx={{ textOverflow: "ellipsis" }}>
                          <span
                            style={{ position: "relative", bottom: "5px" }}
                            className={classes.detailName}>{`Unique Endpoint: `}</span>
                          <Tooltip title={uniqueEndpoint ?? ""}>
                            <span className={classes.endpoint}>{uniqueEndpoint}</span>
                          </Tooltip>
                        </Box>
                      </Box>
                    }
                  />
                </Card>
              )}
            </>
          )}
        </div>
      </Box>
    </NewThemeWrapper>
  );
};
