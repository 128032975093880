import React, { useEffect, useRef } from "react";
import SearchIcon from "@material-ui/icons/Search";
import { InputAdornment, ListSubheader, TextField } from "@material-ui/core";

interface IProps {
  value: string;
  placeholder?: string;
  style?: React.CSSProperties;
  onChange: (value: string) => void;
}

const SelectSearch: React.FC<IProps> = (props) => {
  const { value, placeholder, onChange } = props;
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <ListSubheader
      style={{
        position: "sticky",
        top: "0px",
        paddingTop: "8px",
        background: "#fff",
        ...props.style
      }}>
      <TextField
        size="small"
        placeholder={placeholder ?? "Type to search..."}
        fullWidth
        value={value}
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          inputRef,
          type: "search"
        }}
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => onChange(e.target.value)}
        onKeyDown={(e) => {
          if (e.key !== "Escape") {
            e.stopPropagation();
          }
        }}
      />
    </ListSubheader>
  );
};

export default SelectSearch;
