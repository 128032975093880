import { useMutation, UseMutationResult } from "@tanstack/react-query";

import api from "services/AxiosClient/AxiosClient";
import { handleResponse } from "services/Apis/Apis.service";
import { getPredictionJobOutputpathAndName } from "pages/private/ProjectsModule/pages/PredictionJob/utils/PredictionJob.helpers";

interface IVariables {
  lastRunEntryId: string;
}

const useDownloadPredictionJobOutput = (): UseMutationResult<
  unknown,
  unknown,
  IVariables,
  unknown
> => {
  return useMutation({
    mutationFn: async ({ lastRunEntryId }) => {
      const entryDetails = await api.fetchResponse(
        async () => await api.ProjectRunControllerApi.getProjectRunEntryDetails(lastRunEntryId)
      );

      const { path, name } = getPredictionJobOutputpathAndName(entryDetails?.entryDto);

      if (!path) {
        handleResponse({ errorMessage: "Unable to generate prediction output" });
        return;
      }

      const signedUrl = await api.fetchResponse(
        async () =>
          await api.EntityControllerApi.getDownloadUrl(lastRunEntryId, {
            projectRunEntryId: lastRunEntryId,
            name,
            fileType: "CSV"
          })
      );

      window.open(signedUrl.replace(": //", "://"), "_blank");

      return null;
    }
  });
};

export default useDownloadPredictionJobOutput;
