import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { PromptItem } from "./PromptItem";

const useStyles = makeStyles({
  promptsListWrap: {
    flexWrap: "nowrap",
    padding: "0px 18px",
    gap: "8px",
    listStyle: "decimal"
  }
});

interface IPromptListProps {
  prompts: Array<{ title: string; description: string }>;
  isGenerating: boolean;
  autoGenerateCode: (text: string) => void;
}

export const PromptsList: React.FC<IPromptListProps> = ({
  prompts,
  isGenerating,
  autoGenerateCode
}) => {
  const classes = useStyles();
  return (
    <Grid container direction="column" className={classes.promptsListWrap} component="ol">
      {prompts?.map((prompt, index) => {
        return (
          <PromptItem
            key={index}
            title={prompt.title}
            description={prompt.description}
            onClick={autoGenerateCode}
            isGenerating={isGenerating}
          />
        );
      })}
    </Grid>
  );
};
