import React from "react";

// Packages
import { generatePath, Link } from "react-router-dom";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";

// Utils
import { RenderText } from "components/custom";
import { Grid } from "@material-ui/core";
import { ArtifactIcon } from "icons/NewUX/ArtifactIcon";
import { ProjectRunDto, ProjectRunDtoJobTypeEnum } from "@rapidcanvas/rc-api-core";
import { WebPaths } from "src/routing/routes";
import {
  SCHEDULER_TABS,
  TAB
} from "src/pages/private/ProjectsModule/pages/PredictionJob/components/PredictionRunScheduler/Schedulers";

type Props = {
  project: $TSFixMe;
  isJobPath: boolean;
  jobData?: ProjectRunDto;
  jobRunId?: string;
  jobRunName?: string;
  artifactName?: string;
  isProjectNav?: boolean;
};

const SubTopNavBarBreadcrumbs = (props: Props) => {
  const {
    project,
    isJobPath,
    jobData,
    jobRunId,
    jobRunName,
    artifactName,
    isProjectNav = false
  } = props || {};

  const breadcrumbs: React.ReactNode[] = [];

  breadcrumbs.push(
    !!project?.id && (
      <Link key="projects" to={`/projects/${project?.id}/canvas`}>
        <RenderText>{project?.name || "Project"}</RenderText>
      </Link>
    )
  );
  if (isProjectNav) {
    breadcrumbs.push(
      <Link
        key="artifacts-and-models"
        to={`/projects/${project?.id}/artifacts-and-models/artifacts`}>
        <RenderText>Artifacts & Models</RenderText>
      </Link>
    );
  }

  if (isJobPath && !!project?.id) {
    if (jobData?.jobType === ProjectRunDtoJobTypeEnum.PredictionJob) {
      breadcrumbs.push(
        !!project?.id ? (
          <Link
            key="jobs"
            state={{ [TAB]: SCHEDULER_TABS.PredictionScheduler }}
            to={generatePath(WebPaths.PredictionJob, { projectId: project?.id })}>
            <RenderText>Prediction Schedulers</RenderText>
          </Link>
        ) : (
          <RenderText key="jobs" color="textSecondary">
            <RenderText>Prediction Schedulers</RenderText>
          </RenderText>
        )
      );
    } else {
      breadcrumbs.push(
        !!project?.id ? (
          <Link key="jobs" to={generatePath(WebPaths.JobRoutes, { projectId: project?.id })}>
            <RenderText>Schedulers</RenderText>
          </Link>
        ) : (
          <RenderText key="jobs" color="textSecondary">
            <RenderText>Schedulers</RenderText>
          </RenderText>
        )
      );
    }

    breadcrumbs.push(
      !!project?.id && !!jobData?.id ? (
        <Link key="job-name" to={`/projects/${project?.id}/jobs/${jobData?.id}`}>
          <RenderText>{jobData?.name || "Scheduler"}</RenderText>
        </Link>
      ) : (
        <RenderText key="job-name" color="textSecondary">
          {jobData?.name || "Scheduler"}
        </RenderText>
      )
    );

    breadcrumbs.push(
      project?.id && !!jobData?.id && !!jobRunId ? (
        <Link
          key="job-run-name"
          to={`/projects/${project?.id}/jobs/${jobData?.id}/job-runs/${jobRunId}/job-canvas`}>
          <RenderText>{`Run Name ${jobRunName || jobRunId || "Unknown"}`}</RenderText>
        </Link>
      ) : (
        <RenderText key="job-run-name" color="textSecondary">{`Run Name ${
          jobRunName || jobRunId || "Unknown"
        }`}</RenderText>
      )
    );
  }

  breadcrumbs.push(
    <Grid key="artifacts" container style={{ gap: 5 }} alignItems="center">
      <ArtifactIcon />
      <RenderText color="textSecondary">{artifactName}</RenderText>
    </Grid>
  );

  return (
    <Breadcrumbs>
      {breadcrumbs?.map((eachBreadcrumb: React.ReactNode) => eachBreadcrumb)}
    </Breadcrumbs>
  );
};

export default SubTopNavBarBreadcrumbs;
