import React, { useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";

import RefreshThreadBackdrop from "./RefreshThread/RefreshThreadBackdrop";
import useAuthStore from "stores/zustand/auth.store";
import { AskAIConversationGrid } from "./AskAIConversationGrid";
import { AskAIInitialView } from "./AskAIInitialView";
import { AskAiA11yBackdrop } from "src/components/custom";
import { GenerateCodeBar } from "./GenerateCodeBar/GenerateCodeBar";
import { useCodeRecipeContext } from "../../CodeRecipeContext/useCodeRecipeContext";

const useStyles = makeStyles({
  modalBody: {
    paddingTop: "18px",
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "nowrap"
  },

  inputsWrap: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "nowrap",
    height: "100%"
  },
  askAiA11yContainer: {
    position: "relative",
    flexGrow: 1
  }
});

export const AskAIContainer = ({ readonly }: { readonly: boolean }) => {
  const classes = useStyles();
  const [userInput, setUserInput] = useState("");
  const askAiEnabled = useAuthStore((state) => state.askAiEnabled);

  const {
    recipe,
    responses,
    setResponses,
    isAutoGenerateInProgress,
    inputDatasets,
    fetchSuggestions,
    threadId,
    autoGenerateOutputType,
    fetchSuggestionsApiInfo: { isFetchingSuggestions, fetchingSuggestionsQueryReset }
  } = useCodeRecipeContext();

  React.useEffect(() => {
    // Collapse all responses when unmounting the component
    return () => {
      setResponses((responses: any) =>
        responses?.map((response: any) => ({ ...response, isExpanded: false }))
      );
    };
  }, []);

  return (
    <Grid container direction="column" alignItems="center" className={classes.modalBody}>
      <Grid container direction="column" className={classes.inputsWrap}>
        {!askAiEnabled ? (
          <Grid item className={classes.askAiA11yContainer}>
            <AskAiA11yBackdrop styleProps={{ backgroundColor: "transparent" }} />
          </Grid>
        ) : (
          <RefreshThreadBackdrop open={!!threadId && !!recipe?.isCopied}>
            {responses?.length === 0 && !isAutoGenerateInProgress && !inputDatasets?.length && (
              <AskAIInitialView setUserInput={setUserInput} />
            )}
            <AskAIConversationGrid
              userInput={userInput}
              isFetchingSuggestions={isFetchingSuggestions}
              onUserInputChange={setUserInput}
            />
            <GenerateCodeBar
              disabled={isFetchingSuggestions || readonly}
              userInput={userInput}
              isFetchingSuggestions={isFetchingSuggestions}
              onUserInputChange={setUserInput}
              onFetchPromptSuggestions={fetchSuggestions}
              onReset={fetchingSuggestionsQueryReset}
              autoGenerateOutputType={autoGenerateOutputType}
            />
          </RefreshThreadBackdrop>
        )}
      </Grid>
    </Grid>
  );
};
