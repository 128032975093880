// Packages
import { generatePath, useNavigate } from "react-router-dom";

// Stores
import { useDrawerStore } from "stores/zustand/stores";
import { sideComponentSetter } from "stores/zustand/stores.selectors";

// Utils
import { areAllKeysPresentAndNotNil } from "src/utils/helpers";
import { WebPaths } from "src/routing/routes";

// Open API
import { DataSourceDto } from "@rapidcanvas/rc-api-core";

// Types
import { NodeData } from "src/types";
import VectorModalV2 from "src/components/Canvas/CanvasDrawerItems/VectorModalV2";

type Props = {
  canAddStandardRecipe: boolean;
  data: NodeData;
  connector: DataSourceDto | any;
  disabledMessage: string;
};

const useActions = (props: Props) => {
  const { data, connector, disabledMessage } = props || {};

  const navigate = useNavigate();

  // Stores - STARTS >>
  const setSideDrawerStore = useDrawerStore(sideComponentSetter);
  // << ENDS - Stores

  const onClick = () => {
    setSideDrawerStore({
      sideComponent: null,
      sideComponentProps: null
    });

    if (!!disabledMessage) {
      return;
    }

    if (!!data?.jobProps) {
      if (!data?.isJobCanvasPath) {
        return;
      }
    }

    const drawerData = {
      ...data,
      connector,
      scenarioId: data?.scenarioId
    };

    setSideDrawerStore({
      sideComponent: VectorModalV2,
      sideComponentProps: { noHeader: true, data: drawerData }
    });
  };

  const onDoubleClick = () => {
    setSideDrawerStore({
      sideComponent: null,
      sideComponentProps: null
    });

    if (!!disabledMessage) {
      return;
    }

    if (!areAllKeysPresentAndNotNil(data, ["projectId", "scenarioId", "id"])) {
      return;
    }

    let path = generatePath(WebPaths.ViewData, {
      projectId: data?.projectId,
      scenarioId: data?.scenarioId,
      entityId: data?.id,
      section: "data"
    });

    if (!!data?.jobProps) {
      if (
        !data?.isJobCanvasPath ||
        !areAllKeysPresentAndNotNil(data?.jobProps, ["jobId", "jobRunId"])
      ) {
        return;
      }

      path = generatePath(`${WebPaths.JobRoutes}${WebPaths.JobDataRoutes}`, {
        projectId: data?.projectId,
        jobId: data?.jobProps?.jobId,
        scenarioId: data?.scenarioId,
        jobRunId: data?.jobProps?.jobRunId,
        entityId: data?.id,
        section: "data"
      });
    }

    !!path && navigate(path);
  };

  return { onClick, onDoubleClick };
};

export default useActions;
