import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: 410
  },
  fieldsButtonGroup: {
    "& .fieldButton": {
      minWidth: 90,
      padding: 0,
      border: "none",
      "& div[class^='MuiInputBase-root']": {
        borderRadius: 0
      },
      "&:first-child": {
        "& div[class^='MuiInputBase-root']": {
          borderTopLeftRadius: theme.shape.borderRadius,
          borderBottomLeftRadius: theme.shape.borderRadius
        }
      },
      "&:last-child": {
        "& div[class^='MuiInputBase-root']": {
          borderTopRightRadius: theme.shape.borderRadius,
          borderBottomRightRadius: theme.shape.borderRadius
        }
      }
    }
  }
}));

export default useStyles;
