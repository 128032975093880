import { useMutation, UseMutationResult } from "@tanstack/react-query";

import { updateTenantDetailsWithRethrow } from "services/Apis/wrappers/tenants";
import useAuthStore from "stores/zustand/auth.store";
import useTenantsStore from "stores/zustand/tenant-management.store";

type IVariables = {
  name: string;
  description: string;
};

export const useUpdateTenantMutation = (): UseMutationResult<
  unknown,
  unknown,
  IVariables,
  unknown
> => {
  const updateTenantName = useAuthStore((state) => state.setTenantName);
  const [updateTenantDescription, toggleTenantsRefresh] = useTenantsStore((state) => [
    state.updateTenantDescription,
    state.toggleTenantsRefresh
  ]);
  return useMutation({
    mutationFn: async (body) => {
      const response = await updateTenantDetailsWithRethrow(body);
      updateTenantDescription(response.name, response.description);
      updateTenantName(response.name);
      response && toggleTenantsRefresh();
      return response;
    }
  });
};
