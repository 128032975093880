export const ArtifactsGettingStarted = {
  topMessage:
    "Artifacts are data entities that can store files, datasets, and outputs from processing workflows. They help manage data efficiently across projects.",

  functionality: {
    question: "How it works?",
    answers: [
      "Upload or create artifacts in various formats (ZIP, XLS, Parquet, etc.).",
      "Use artifacts as inputs for recipes and workflows.",
      "Manage and track artifact sources, destinations, and usage.."
    ]
  }
};
