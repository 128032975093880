import { makeStyles, Paper, Tooltip } from "@material-ui/core";
import React from "react";
import { CodeEditor } from "src/components";

interface IProps {
  isDefault: boolean;
  code: string;
  name: string;
  onEditorChange: (name: string, value: string) => void;
}

const useStyles = makeStyles({
  paper: {
    height: "100%",
    width: "100%"
  }
});
export const EnvironmentPackagesTab: React.FC<IProps> = ({
  name,
  onEditorChange,
  isDefault,
  code
}) => {
  const classes = useStyles();

  return (
    <Paper elevation={0} className={classes.paper}>
      <Tooltip
        title={!!isDefault ? "Cannot update libraries of the DEFAULT env." : ""}
        placement="top"
        arrow>
        <div style={{ height: "100%" }}>
          <CodeEditor
            name={name}
            height="100%"
            value={code}
            width="100%"
            scriptType="SYSTEM"
            onChange={onEditorChange}
            readOnly={!!isDefault}
            hideTestCode
          />
        </div>
      </Tooltip>
    </Paper>
  );
};
