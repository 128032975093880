import React from "react";
import _ from "lodash";
import { List, ListItem, ListSubheader, makeStyles, Tooltip } from "@material-ui/core";

export interface ISyntax {
  title: string;
  tooltip: string;
  code: string;
}
interface IProps {
  value: string;
  syntax: Record<string, ISyntax[]>;
  onChange: (val: ISelected) => void;
}

const useStyles = makeStyles((theme) => ({
  list: {
    display: "flex",
    gap: "10px",
    flexDirection: "column",
    margin: "8px"
  },
  listItem: {
    backgroundColor: "#f6f7f9",
    borderRadius: "6px",
    outlineOffset: "-1px",
    fontSize: "14px",
    "&:last-of-type": {
      marginBottom: " 8px"
    },

    "&:hover": {
      outline: "1px solid #4646B5",
      backgroundColor: "#F2f2ff"
    }
  },
  selected: {
    outline: "1px solid #4646B5",
    backgroundColor: "#F2f2ff"
  },
  subheader: {
    backgroundColor: "#fff",
    height: "30px",
    display: "flex",
    alignItems: "center",
    fontSize: "16px",
    color: theme.palette.primary.main,
    padding: 0,
    position: "relative",
    zIndex: 0
  }
}));

interface ISelected {
  title: string;
  key: string;
  code: string;
  tooltip: string;
}

const SyntaxList: React.FC<IProps> = (props) => {
  const { value, syntax, onChange } = props;

  const classes = useStyles();

  const handleClick = (val: ISelected) => {
    onChange(val);
  };

  return (
    <List dense>
      {_.map(syntax, (val, key) => (
        <li key={key}>
          <ul className={classes.list}>
            <ListSubheader className={classes.subheader}>{key}</ListSubheader>
            {_.map(val, ({ title, tooltip, code }) => (
              <Tooltip key={title} title={tooltip} placement="right">
                <ListItem
                  className={`${classes.listItem} ${value === title ? classes.selected : ""}`}
                  onClick={() => handleClick({ key, title, tooltip, code })}>
                  {title}
                </ListItem>
              </Tooltip>
            ))}
          </ul>
        </li>
      ))}
    </List>
  );
};

export default SyntaxList;
