import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";

import { Delete } from "../../assets/icons/Delete";
import { LinkCellWithMenu } from "../../components/Table/Cells/LinkCellWithMenu/LinkCellWithMenu.component";
import { Settings } from "../../assets/icons/Settings";
import { OverflowTooltip, TitleText } from "../../components";
import { capitalize } from "../../utils/helpers/string.helpers";
import { dateFormat } from "utils/helpers/date.helpers";
import { DataAppIcon } from "icons/NewUX/DataAppIcon";
import { JobIcon } from "icons/NewUX/JobIcon";
import { ImageIcon } from "icons/NewUX/ImageIcon";
import CopyDownloadIcon from "icons/NewUX/CopyDownloadIcon";
import { useProjectsStore } from "stores/zustand/stores";
import _, { includes, isEmpty } from "lodash";
import MarkFavorite from "./MarkFavorite";
import CommonLoader from "src/components/CommonLoader";
import Table from "src/components/custom/TableV8/Table";
import { OnChangeFn, RowSelectionState } from "@tanstack/react-table";

const useStyles = makeStyles({
  gap: {
    gap: "8px"
  },
  linkText: {
    color: "#008FE4"
  }
});

interface IProps {
  list?: any[];
  isLoading: boolean;
  onEdit: (id: string) => void;
  onImageOpen: (id: string) => void;
  onCopyOpen: (id: string) => void;
  onDelete: (id: string) => void;
  selectedProjects: RowSelectionState;
  setSelectedProjects: Dispatch<SetStateAction<RowSelectionState>>;
}
export const ProjectsList = ({
  list,
  onEdit,
  onDelete,
  onImageOpen,
  onCopyOpen,
  isLoading,
  selectedProjects,
  setSelectedProjects
}: IProps) => {
  const classes = useStyles();
  const [updatingProjectIds] = useProjectsStore((state) => [state.updatingProjectIds]);

  const handleProjectSelection: OnChangeFn<RowSelectionState> = (ids) => {
    setSelectedProjects(ids);
  };

  const [key, setKey] = useState("");

  const columns = [
    {
      id: "Name",
      accessorKey: "name",
      header: "Name",
      meta: { isTooltip: false },
      cell: ({ row, getValue }: $TSFixMe) => (
        <LinkCellWithMenu
          url={`/projects/${row?.original?.id}`}
          extra={
            <MarkFavorite
              isHovered={!!row?.original?.isFavorite}
              className={row?.original?.isFavorite ? undefined : "hiddenDiv"}
              projectId={row?.original?.id}
              value={row?.original?.isFavorite}
            />
          }
          linkText={
            <OverflowTooltip style={{ width: "100%", whiteSpace: "nowrap" }} value={getValue()} />
          }
          menuButtons={[
            {
              icon: <ImageIcon />,
              label: "Project Image",
              action: () => row?.original?.id && onImageOpen(row?.original?.id)
            },
            {
              icon: <Settings />,
              label: "Settings",
              action: () => row?.original?.id && onEdit(row?.original?.id)
            },
            {
              icon: <CopyDownloadIcon />,
              label: "Copy Project",
              action: () => row?.original?.id && onCopyOpen(row?.original?.id)
            },
            {
              icon: <Delete />,
              label: "Delete",
              disabled: includes(updatingProjectIds, row?.original?.id),
              tooltip: includes(updatingProjectIds, row?.original?.id)
                ? "Please wait until the project is getting updated"
                : "",
              action: () => row?.original?.id && onDelete(row?.original?.id)
            }
          ]}
        />
      )
    },
    {
      id: "Description",
      accessorKey: "description",
      header: "Description",

      cell: (info: any) => capitalize(info.getValue())
    },
    {
      id: "DataApp Count",
      accessorKey: "dataAppCount",
      header: "DataApp Count",

      cell: ({ row, getValue }: any) => {
        const value = getValue();
        return (
          <Grid item container direction="row" alignItems="center" className={classes.gap}>
            <DataAppIcon />
            <TitleText
              variant="inherit"
              className={!value ? classes.linkText : undefined}
              colorCode={value ? "#4A4A4A" : undefined}
              title={value ? `${value} DataApp${value !== 1 ? "s" : ""}` : "Create"}
              link={`/projects/${row?.original?.id}/project-dataapps`}
            />
          </Grid>
        );
      }
    },
    {
      id: "Scheduler Count",
      accessorKey: "jobCount",
      header: "Scheduler Count",

      cell: ({ row, getValue }: any) => {
        const value = getValue();
        return (
          <Grid item container direction="row" alignItems="center" className={classes.gap}>
            <JobIcon />
            <TitleText
              colorCode={value ? "#4A4A4A" : undefined}
              className={!value ? classes.linkText : undefined}
              variant="inherit"
              title={value ? `${value} Scheduler${value !== 1 ? "s" : ""}` : "Create Scheduler"}
              link={
                value
                  ? `/projects/${row?.original?.id}/jobs`
                  : `/projects/${row?.original?.id}/jobs/create-job`
              }
            />
          </Grid>
        );
      }
    },
    {
      id: "Updated by",
      accessorKey: "updater",
      header: "Updated by",
      isSortable: true
    },
    {
      id: "Updated on",
      accessorKey: "updatedAt",
      header: "Updated on",

      cell: ({ getValue }: any) => <span>{dateFormat(getValue())}</span>
    }
  ];

  useEffect(() => {
    setKey(Date.now().toString());
  }, [list]);

  return (
    <>
      {isLoading ? (
        <CommonLoader />
      ) : (
        !isEmpty(list) && (
          <Table
            key={key}
            data={list || []}
            getRowId={(item) => item.id}
            columns={columns}
            rowSelectionProps={{
              isSelectable: true,
              selectedRowIds: selectedProjects,
              onSelectedRowsChange: handleProjectSelection
            }}
            sortingProps={{ orderByDefault: "Updated on" }}
            infoProps={{ hideCount: true }}
            stylesProps={{ maxHeight: "calc(100vh - 339px)", isTheadSticky: true }}
          />
        )
      )}
    </>
  );
};
