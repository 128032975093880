import { get, has } from "lodash";

import makeStyles from "@material-ui/core/styles/makeStyles";

import { NodeFileTheme } from "src/pages/private/ProjectsModule/utils";

type StyleProps = {
  status: string;
};

const useStyles = makeStyles({
  root: {
    width: 28,
    height: 28,
    borderRadius: 28 / 3,
    "&:hover": {
      cursor: "help"
    }
  },
  defaultStyles: {
    backgroundColor: ({ status }: StyleProps) =>
      has(NodeFileTheme, status)
        ? get(NodeFileTheme, [status, "backgroundColor"])
        : NodeFileTheme.backgroundColor,
    border: "1px solid",
    borderColor: ({ status }: StyleProps) =>
      has(NodeFileTheme, status)
        ? get(NodeFileTheme, [status, "borderColor"])
        : NodeFileTheme.borderColor,
    color: ({ status }: StyleProps) =>
      has(NodeFileTheme, status)
        ? get(NodeFileTheme, [status, "iconColor"])
        : NodeFileTheme.iconColor
  },
  connectorNodeStyles: {
    backgroundColor: "#fff", // White color
    border: "1px solid",
    borderColor: ({ status }: StyleProps) =>
      has(NodeFileTheme, status)
        ? get(NodeFileTheme, [status, "borderColor"])
        : NodeFileTheme.borderColor
  },
  container: {
    height: "100%",
    position: "relative",
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "center",
    alignItems: "center"
  },
  icon: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& svg": {
      width: "80%",
      height: "80%"
    },
    "& img": {
      width: "90%",
      height: "90%"
    }
  }
});

export default useStyles;
