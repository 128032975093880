import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  publishJobsListContainer: {
    width: "100%",
    maxHeight: 380,
    overflowY: "auto",
    flexWrap: "nowrap",
    "& .publishJobsListItem": { marginRight: 0, marginBottom: 0, width: "100%" }
  },
  publishJobsModalInfo: {
    margin: 0,
    padding: 0,
    border: "none",
    "& [class^='MuiAlert-icon']": {
      marginRight: theme.spacing(1)
    },
    "& [class^='MuiAlert-message']": {
      paddingTop: theme.spacing(0.75)
    }
  }
}));

export default useStyles;
