import React, { useState, useCallback, useMemo } from "react";
import { Handle, Position } from "react-flow-renderer";
import { useNavigate, useParams, useLocation, generatePath } from "react-router-dom";

import Tooltip from "@material-ui/core/Tooltip";

import WarningIcon from "@material-ui/icons/Warning";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import BlockIcon from "@material-ui/icons/Block";
import { addEllipsis, handleClickClosure } from "../../../utils/helpers/common.helpers";

import { ChartIcon } from "src/assets/icons/ChartIcon";
import styles from "./ChartBlock.module.scss";
import { useDrawerStore, useCanvasStore, useScenariosStore } from "../../../stores/zustand/stores";
import { sideComponentSetter } from "../../../stores/zustand/stores.selectors";
import { ChartModal } from "../CanvasDrawerItems/components/Charts/ChartModal";
import clsx from "clsx";
import { shouldHighlight } from "../helpers/CanvasFlow.helpers";
import { WebPaths } from "src/routing/routes";

const ChartBlock = ({ data }: $TSFixMe) => {
  const [isElementHovered, setIsElementHovered] = useState(false);
  const setSideComponent = useDrawerStore(sideComponentSetter);
  const { scenarioId } = useParams<$TSFixMe>();
  const shouldBlockClickHandlerTrigger = useCanvasStore(
    (state) => state.shouldBlockClickHandlerTrigger
  );
  const nodeToFocus = useCanvasStore((state) => state.nodeToFocus);
  const scenarios = useScenariosStore((state) => state.scenarios);

  const navigate = useNavigate();

  const location = useLocation();
  const isJobCanvasPath = useMemo(() => /job-canvas/.test(location.pathname), [location.pathname]);

  const disabledBlockMessage = useMemo(() => {
    if (!!data?.isJobCanvas && ["UNBUILT", "EMPTY", "SKIPPED"].includes(data?.status)) {
      return `Certain click actions are restricted as supplementary details for the specified chart ${data?.label} are not accessible in the context of this scheduler run entry.`;
    }

    return "";
  }, [data?.isJobCanvas, data?.status]);

  const handleSingleClick = () => {
    if (!!disabledBlockMessage) {
      return;
    }

    if (!!data?.jobProps) {
      if (!isJobCanvasPath) {
        return;
      }
    }

    const isCustomScenario = !(
      scenarios?.find(
        (scenario: $TSFixMe) => scenario?.id === scenarioId || scenario?.id === data?.scenarioId
      ) as $TSFixMe
    )?.default;

    const dataToSet = {
      ...data,
      scenarioId: scenarioId || data?.scenarioId,
      isCustomScenario,
      isJobCanvas: !!data?.isJobCanvas,
      jobProps: data?.jobProps
    };
    setSideComponent({
      sideComponent: ChartModal,
      sideComponentProps: {
        noHeader: true,
        data: dataToSet
      }
    });
  };

  const handleDoubleClick = () => {
    if (!!disabledBlockMessage) {
      return;
    }

    if (data?.status !== "BUILT") {
      return;
    }

    let path = generatePath(WebPaths.Charts, {
      projectId: data.projectId,
      scenarioId: scenarioId || data?.scenarioId,
      chartId: data.itemId
    });

    if (!!data?.jobProps) {
      if (!isJobCanvasPath) {
        return;
      }

      path = generatePath(`${WebPaths.JobRoutes}${WebPaths.JobChart}`, {
        projectId: data.projectId,
        jobId: data?.jobProps?.jobId,
        scenarioId: data?.scenarioId,
        jobRunId: data?.jobProps?.jobRunId,
        chartId: data.itemId
      });
    }

    navigate(path);

    setSideComponent({
      sideComponent: null,
      sideComponentProps: null
    });
  };

  const handleMouseEnter = useCallback(() => {
    setIsElementHovered(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsElementHovered(false);
  }, []);

  const blockIcon = useMemo(() => {
    if (isElementHovered) {
      return <ChartIcon stroke="white" />;
    }
    if (data.status === "PENDING") {
      return <HourglassEmptyIcon />;
    }
    if (data.status === "BUILT") {
      return <ChartIcon />;
    }
    if (data.status === "UNBUILT") {
      return <BlockIcon />;
    }
    if (data.status === "EMPTY") {
      return <WarningIcon />;
    }
    if (data.status === "SKIPPED") {
      return <SkipNextIcon />;
    }

    return <ChartIcon />;
  }, [data.status, isElementHovered]);

  const blockStatus = useMemo(() => {
    if (data.status === "PENDING") {
      return "Pending...";
    }
    if (data.status === "BUILT") {
      return "Built";
    }
    if (data.status === "UNBUILT") {
      return "Unbuilt";
    }
    if (data.status === "EMPTY") {
      return "Empty";
    }
    if (data.status === "SKIPPED") {
      return "Skipped";
    }
    return "";
  }, [data.status]);

  return (
    <>
      <Handle type="target" position={Position.Left} />
      <Tooltip title={disabledBlockMessage} placement="top">
        <div
          role="button"
          tabIndex={0}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleClickClosure({
            shouldBlockClickHandlerTrigger:
              shouldBlockClickHandlerTrigger || data.shouldDisableBlockInteraction,
            handleDoubleClick,
            handleSingleClick
          })}
          onKeyPress={handleClickClosure({
            shouldBlockClickHandlerTrigger:
              shouldBlockClickHandlerTrigger || data.shouldDisableBlockInteraction,
            handleDoubleClick,
            handleSingleClick
          })}
          className={clsx(styles.chartBlockContainer, {
            [styles.highlighted]: shouldHighlight(data?.label, nodeToFocus),
            [styles.chartBlockContainerPending]: data.status === "PENDING",
            [styles.chartBlockContainerSuccess]: data.status === "BUILT",
            [styles.chartBlockContainerEmpty]: data.status === "EMPTY",
            [styles.chartBlockContainerSkipped]: data.status === "SKIPPED",
            [styles.chartBlockContainerUnbuilt]: data.status === "UNBUILT"
          })}>
          <div className={styles.relativeContainer}>
            <div className={styles.blockStatus}>{blockStatus}</div>
            <div className={styles.iconContainer}>{blockIcon}</div>
            <div className={styles.label}>{addEllipsis(data.label)}</div>
          </div>
        </div>
      </Tooltip>
      <Handle type="source" position={Position.Right} />
    </>
  );
};

export default ChartBlock;
