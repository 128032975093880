import React, { useMemo } from "react";
import { IconButton, Tooltip, makeStyles } from "@material-ui/core";

import { useCodeRecipeContext } from "../../../CodeRecipeContext/useCodeRecipeContext";
import { QueryInputIcon } from "icons/NewUX/QueryInputIcon";
import { filter, includes, isEmpty, size } from "lodash";
import { MAX_INPUT_COUNT } from "../GenerateCodeBar/GenerateCodeInputsDropdown";

const useStyles = makeStyles({
  queryBtn: {
    borderRadius: "4px",
    background: "#fff",
    padding: "6px"
  }
});

interface IProps {
  name: string;
}

const AutoSelectDataset: React.FC<IProps> = (props) => {
  const { name } = props;

  const classes = useStyles();
  const { pinnedNames, setInputNames, inputNames } = useCodeRecipeContext();
  const isSelected = useMemo(() => includes(inputNames, name), [inputNames, name]);
  const disabled = !isEmpty(pinnedNames) || size(inputNames) >= MAX_INPUT_COUNT;

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    if (isSelected) {
      setInputNames(filter(inputNames, (item) => item !== name));
    } else {
      setInputNames([...inputNames, name]);
    }
  };

  return (
    <Tooltip
      title={
        !isEmpty(pinnedNames)
          ? "can not select dataset if any dateset is pinned"
          : size(inputNames) >= MAX_INPUT_COUNT
            ? `Maximum of ${MAX_INPUT_COUNT} datasets can be selected at once. Kindly unselect one to proceed`
            : `${isSelected ? "Deselect" : "Select"} this dataset as input to run queries."`
      }>
      <div>
        <IconButton
          className={classes.queryBtn}
          disabled={disabled}
          test-id="ask-ai-output-query"
          onClick={handleClick}
          style={{ opacity: disabled ? 0.5 : 1 }}>
          <QueryInputIcon fill={isSelected ? "#4646B5" : undefined} />
        </IconButton>
      </div>
    </Tooltip>
  );
};

export default AutoSelectDataset;
