export enum SegmentConfig {
  SegmentNameFontSize = 14
}

export enum SegmentFormFields {
  Name = "name",
  RowLimit = "rowLimit",
  Description = "description",
  Groups = "groups"
}

export const segmentFormFieldsNameMapping = {
  [SegmentFormFields.Name]: "Segment Name",
  [SegmentFormFields.RowLimit]: "Row Limit",
  [SegmentFormFields.Description]: "Segment Description"
};

const nonStringOperators = [
  "Greater Than",
  "Less Than",
  "Equal To",
  "Not Equal To",
  "Greater Or Equal",
  "Lesser Or Equal",
  "Is Null",
  "Is Not Null"
];

const operators = [...nonStringOperators, "Contains"];

export const operatorsByDatatype: any = {
  STRING: ["Equal To", "Not Equal To", "Contains", "Is Null", "Is Not Null"],
  DOUBLE: nonStringOperators,
  LONG: nonStringOperators,
  FLOAT: nonStringOperators,
  TIMESTAMP: nonStringOperators,
  BOOLEAN: ["Equal To", "Not Equal To", "Is Null", "Is Not Null"],
  SET: ["Equal To", "Not Equal To", "Is Null", "Is Not Null"],
  LIST: ["Equal To", "Not Equal To", "Is Null", "Is Not Null"],
  MAP: ["Equal To", "Not Equal To", "Is Null", "Is Not Null"],
  BLOB: ["Equal To", "Not Equal To", "Is Null", "Is Not Null"],
  EMPTY: [],
  ANY: operators
};

export const defaultRules = [
  {
    type: "item",
    field: {
      type: "field",
      name: ""
    },
    operator: "",
    value: {
      type: "lit",
      value: "",
      dataType: "ANY"
    }
  }
];

export const defaultGroups = [
  {
    type: "group",
    groupType: "AND",
    groupElements: defaultRules
  }
];

export const defaultValues = {
  [SegmentFormFields.Name]: "",
  [SegmentFormFields.RowLimit]: undefined,
  [SegmentFormFields.Description]: "",
  [SegmentFormFields.Groups]: defaultGroups
};

export enum TestSegmentModal {
  title = "Segment Preview"
}

export enum SegmentLeavePagePromptDetails {
  title = "Confirm",

  messageLine1 = "If you leave you will lose any unsaved changes.",
  messageLine2 = "Do you still want to navigate away?"
}

export enum UpdateSegmentPromptDetails {
  title = "Update Segment",

  messageLine1 = "If you make any changes to the segment conditions associated with custom scenario(s), it will invalidate the existing canvas connections. To ensure the changes take effect, you will need to rerun the associated custom scenario(s).",
  messageLine2 = "Are you sure you want to update?",

  submitLabel = "Yes, Update"
}

export enum DeleteSegmentPromptDetails {
  title = "Delete Segment",

  messageLine1 = "Do you really want to delete this segment?",
  messageLine2 = "If you confirm you will delete the segment completely!"
}

export enum SegmentHelperText {
  UntitledSegment = "Untitled Segment",

  AddConditions = "Add Conditions",
  AddConditionsInfo = "Add or modify rules to define split",

  And = "AND",
  Or = "OR",

  CreateSegment = "Create Segment",

  AddGroup = "Add Group",
  AddRule = "Add Rule",

  Field = "Field",
  Operator = "Operator",
  Value = "Value",

  Cancel = "Cancel",
  Test = "Test",
  Save = "Save",
  Update = "Update",
  Delete = "Delete",

  SegmentCreated = "Segment created successfully!",
  SegmentUpdated = "Segment updated successfully!",
  SegmentNameUpdated = "Segment name updated successfully!",
  SegmentDeleted = "Segment deleted successfully!",

  Project = "Project",
  Jobs = "Schedulers",
  Job = "Scheduler",
  Dataset = "Dataset",
  Segment = "Segment",

  Unknown = "Unknown",

  NoDataFound = "No data found!"
}
