import React, { useEffect, useMemo, useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { isEmpty } from "lodash";
import { generatePath } from "react-router-dom";

import Drawer from "components/Drawer/CustomDrawer";
import usePredictionServiceLiveLogs from "../hooks/usePredictionServiceLiveLogs";
import { LogsContent } from "src/pages/Projects/common/ShowLogsModal/LogsContent";
import { ShowLogsDrawerTitle } from "src/pages/Projects/common/ShowLogsModal/ShowLogsDrawerTitle";
import { ToastifyAlert } from "src/components/ToastifyAlert/ToastifyAlert";
import { WebPaths } from "src/routing/routes";
import { checkEnvRelaunchByEnvId } from "environmentsModule/utils/environmentRelaunch.helpers";
import useExportPredictionServiceLiveLogs from "../hooks/useExportPredictionServiceLiveLogs";

export const TEMP_MSG = ["Fetching logs. Please wait..."];
export const UNBUILT_MSG = "Logs are not available because the recipe hasn't been executed yet.";
export const IS_TEST_LOGS = "isTestLogs";

export const useStyles = makeStyles({
  autoMlLogs: {
    flexWrap: "nowrap",
    width: "600px"
  },
  logStatuses: {
    gap: "16px",
    background: "#1e1e1e",
    padding: "8px 8px 0px 8px"
  },
  logStatusWithoutBckg: {
    gap: "16px",
    padding: "8px 8px 0px 8px"
  },
  drawer: {
    width: 600,
    flexShrink: 0
  },
  drawerPaper: {
    width: 600
  }
});

type Props = {
  type: any;
  message: string;
};

export const renderInfo = ({ message, type }: Props) => {
  return (
    <Grid
      container
      item
      xs={12}
      style={{
        padding: 16,
        display: "inline-block"
      }}>
      <ToastifyAlert type={type} message={message} />
    </Grid>
  );
};

interface IProps {
  id?: string;
  name: string;
  modelName: string;
  open: boolean;
  envId?: string;
  onClose: () => void;
  isTesting: boolean;
}

export const IS_PS_TEST_LOGS = "isTesting";

const PredictionServiceLiveLogsDrawer: React.FC<IProps> = (props) => {
  const { name, id, open, onClose, modelName, isTesting, envId } = props;
  const classes = useStyles();
  const exportLogsMutation = useExportPredictionServiceLiveLogs();

  const [searchValue, setSearchValue] = useState("");
  const { data, isLoading } = usePredictionServiceLiveLogs(id, isTesting, undefined, {
    enabled: !!id,
    refetchOnMount: true
  });

  useEffect(() => {
    checkEnvRelaunchByEnvId(envId);
  }, [envId]);

  const url = `${generatePath(WebPaths.PredictionServiceLogs, {
    id: id ?? "",
    modelName: modelName ?? ""
  })}?${IS_PS_TEST_LOGS}=${isTesting}`;

  const filteredLogs = useMemo(() => {
    return (data?.logs?.logData || [])?.filter((eachField) => {
      return searchValue ? eachField?.toLowerCase()?.includes(searchValue?.toLowerCase()) : true;
    });
  }, [searchValue, data?.logs?.logData]);

  const handleSearchValueChange = (event: any) => {
    setSearchValue(event.target.value);
  };

  const exportLogs = async () => {
    if (id) await exportLogsMutation.mutateAsync({ id, name });
  };

  const title = (
    <ShowLogsDrawerTitle
      title={name}
      titleWidth={"260px"}
      prefix={"Test"}
      url={url}
      exportDisabled={isTesting}
      exportDisabledMessage="Please wait until the prediction service test completes."
      onClose={onClose}
      exportLogs={exportLogs}
      exportLogLoading={exportLogsMutation.isLoading}
      searchValue={searchValue}
      onSearch={handleSearchValueChange}
    />
  );

  return (
    <Drawer
      anchor="right"
      variant="temporary"
      open={open}
      titleStyle={{ padding: 0, borderBottom: "1px solid #E0E0E0", lineHeight: 1 }}
      hideCloseButton
      classes={{
        paper: classes.drawerPaper
      }}
      className={classes.drawer}
      title={title}
      onClose={onClose}>
      <Grid container direction="column" justifyContent="center" className={classes.autoMlLogs}>
        {isLoading ? (
          <LogsContent logs={TEMP_MSG} />
        ) : isEmpty(data?.logs?.logData) ? (
          <LogsContent info="No logs found!" logs={[]} />
        ) : (
          <LogsContent logs={filteredLogs} />
        )}
      </Grid>
    </Drawer>
  );
};

export default PredictionServiceLiveLogsDrawer;
