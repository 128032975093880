import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { first } from "lodash";
import { CreateAIThreadRequestDto, ThreadResponseDto } from "@rapidcanvas/rc-api-core";
import api from "services/AxiosClient/AxiosClient";

export const CACHE_QUERY_KEY_FETCH_CHAT_THREAD_FOR_CHART_EDIT = "fetch-chat-thread-for-chart-edit";

const useCreateChatThreadForChartEdit = (
  createThreadDto: CreateAIThreadRequestDto,
  options?: UseQueryOptions<ThreadResponseDto, Error>
): UseQueryResult<ThreadResponseDto, Error> => {
  return useQuery<ThreadResponseDto, Error>({
    queryKey: [CACHE_QUERY_KEY_FETCH_CHAT_THREAD_FOR_CHART_EDIT, createThreadDto.target?.targetId],
    queryFn: async () => {
      if (!createThreadDto.context?.projectId) {
        throw "project id is required";
      }
      const exisitingThread = await api.AiControllerApi.getThreads1(
        createThreadDto.context?.projectId,
        createThreadDto.target?.targetId
      );

      const threadId = first(exisitingThread.data)?.threadId;
      if (threadId) {
        await api.AiControllerApi.deleteThread1(first(exisitingThread.data)?.threadId ?? "");
      }
      const data = await api.fetchResponse(
        async () => await api.AiControllerApi.createThread(createThreadDto)
      );
      return data;
    },
    ...options
  });
};

export default useCreateChatThreadForChartEdit;
