import React from "react";
import { Button, useTheme } from "@material-ui/core";

import MagicGenerateIcon from "src/assets/icons/MagicGenerateIcon";
import GettingStarted from "components/custom/GetttingStarted/GettingStarted";
import AboutPage from "icons/NewUX/GettingStartedIllustrations/AboutPage";

interface IProps {
  onGenerate: () => void;
}

const NoAboutContent: React.FC<IProps> = (props) => {
  const theme = useTheme();
  return (
    <GettingStarted
      boxes={[
        {
          message: "Use Generative AI to auto generate an About page for this project",
          icon: <AboutPage />,
          action: (
            <Button
              variant="outlined"
              color="primary"
              startIcon={<MagicGenerateIcon fill={theme.palette.primary.main} />}
              onClick={props.onGenerate}>
              Generate Content
            </Button>
          )
        }
      ]}
    />
  );
};

export default NoAboutContent;
