import React from "react";
import _ from "lodash";

import { PSInfo } from "../utils/PredictionService.constants";
import { PredictionServiceIcon } from "src/assets/icons/PredictionServiceIcon";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  item: {
    marginBottom: "16px"
  },
  wrapper: {
    alignItems: "center",
    display: "flex",
    margin: "auto",
    maxWidth: "500px",
    fontSize: "12px",
    gap: "20px",
    marginTop: "40px",
    flexDirection: "column"
  },
  heading: {
    fontSize: "16px"
  }
});

const PredictionServiceInfo: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <PredictionServiceIcon />
      <div className={classes.heading}>Getting Started</div>
      <div>
        {_.map(PSInfo, (value, index) => (
          <div className={classes.item} key={index}>
            {value}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PredictionServiceInfo;
