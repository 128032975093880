import React from "react";
import { Add, Remove } from "@material-ui/icons";
import { Button, Tooltip, makeStyles } from "@material-ui/core";

import { Spinner } from "src/components";

const useStyles = makeStyles({
  recipeBtn: {
    backgroundColor: "#fff",
    height: "26px"
  },
  actions: {
    gap: "16px"
  },
  queryBtn: {
    borderRadius: "4px",
    background: "#fff",
    padding: "4px"
  }
});

interface IProps {
  isRetryInProgress: boolean;
  isRecipeUpdating: boolean;
  isAddedToRecipe: boolean;
  disabled: boolean;
  onClick: () => void;
}

const AddRemoveToRecipeButton: React.FC<IProps> = (props) => {
  const { isRetryInProgress, isRecipeUpdating, isAddedToRecipe, disabled, onClick } = props;

  const classes = useStyles();

  return (
    <Tooltip title={isRetryInProgress ? "Please wait until retry query succeeds" : ""}>
      <div>
        <Button
          color="primary"
          size="small"
          className={classes.recipeBtn}
          startIcon={
            !isRecipeUpdating &&
            (isAddedToRecipe ? <Remove color="primary" /> : <Add color="primary" />)
          }
          disabled={disabled}
          onClick={(event): void => {
            event.stopPropagation();
            onClick();
          }}
          data-testid={`ask-ai-${isAddedToRecipe ? "remove-from" : "add-to"}-recipe-btn`}>
          {isRecipeUpdating ? <Spinner size={18} noPadding /> : "Recipe"}
        </Button>
      </div>
    </Tooltip>
  );
};

export default AddRemoveToRecipeButton;
