import { useMutation } from "@tanstack/react-query";

import { deleteAPIWithRethrow } from "services/Apis/Apis.service";

export const useDeleteCodeHistoryMutation = () => {
  return useMutation({
    mutationFn: ({ recipeId, historyIds }: { recipeId: string; historyIds: string[] }) => {
      return deleteAPIWithRethrow(`/v2/dfs-run-config-groups/${recipeId}/code-history`, {
        historyIds
      });
    }
  });
};
