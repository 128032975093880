import React, { useMemo, useState } from "react";

// Packages
import MonacoEditor from "react-monaco-editor";
import { useQueryClient } from "@tanstack/react-query";
import { find, forEach, has, isEmpty, keys, size, startCase, toLower, trim } from "lodash";

// MUI
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

// Icons
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

// Utils
import { toastWrapper } from "services/ToastClient/toastWrapper";
import {
  getLocalStorageItem,
  setLocalStorageItem
} from "src/services/LocalStorage/LocalStorage.service";

// Hooks
import { IEntity, QUERY_KEY_ENTITIES } from "src/hooks/api/entities/useEntities";
import { QUERY_KEY_ENTITY_DETAILS } from "src/hooks/api/entities/useEntityDetails";
import { UseGetDatasetDataInfiniteQueryKeys } from "src/hooks/api/projects/useEntityDataAndStats";
import { QUERY_KEY_EDA } from "src/hooks/api/entities/useGetEntityEda";
import {
  UseGetSchemaDataQueryKeys,
  useGetSchemaData
} from "src/hooks/api/projects/useEntityFeatures";
import { useUpdateEntity } from "src/hooks/api/entities/useUpdateEntities";
import { useGetConnectorsSchema, useGetConnectorSampleData } from "src/hooks/api";

// Components
import Modal, { ModalVariants } from "src/components/custom/Modal/Modal";
import SqlConfiguration from "./SqlConfiguration";
import SampleDataContainer from "./SampleDataContainer/SampleDataContainer";

// Types
import { SampleData, SchemaField } from "./Source.types";

// Constants
import { TableSessionConfig } from "constants/table.constants";

const useStyles = makeStyles((theme) => ({
  editorQueryFieldsContainer: {
    "& > [class^='MuiGrid-root']": {
      width: "100%",
      rowGap: 16
    },
    "& .title": {
      marginBottom: theme.spacing(1)
    },
    "& .queryFieldInfo": {
      marginLeft: theme.spacing(1)
    }
  }
}));

type Props = {
  userId: string | null | undefined;
  isJobPath: boolean;
  isDefaultScenario: boolean;
  dataset: IEntity | null | undefined;
};

const SqlInterface = (props: Props) => {
  const { userId, isJobPath, dataset, isDefaultScenario } = props || {};

  const classes = useStyles();

  const queryClient = useQueryClient();

  // States - STARTS >>
  const [editorQueryFields, setEditorQueryFields] = useState<{ [key: string]: SchemaField }>({});
  const [values, setValues] = useState<{ [key: string]: SchemaField }>({});

  const [sampleData, setSampleData] = useState<SampleData>({});

  const [confirmSave, setConfirmSave] = useState(false);
  const [isSavingQuery, setIsSavingQuery] = useState(false);
  // << ENDS - States

  const onConnectorsSchemaSucceed = async (data: any) => {
    const foundDataSourceType = find(
      data,
      (eachDataSourceType: { [key: string]: any }) =>
        eachDataSourceType?.type === dataset?.dataSourceType
    );

    if (foundDataSourceType) {
      const allFields = [...foundDataSourceType?.datasetFields];

      const thisEditorQueryField: { [key: string]: SchemaField } = {};
      const formFormFields: { [key: string]: SchemaField } = {};

      forEach(allFields, (field: SchemaField) => {
        let formField = field;

        if (/query/i.test(field?.name)) {
          thisEditorQueryField[field?.name] = {
            ...field,
            // @ts-ignore
            value: dataset?.dataSourceOptions?.[field?.name] ?? ""
          };
        } else {
          // @ts-ignore
          if (dataset?.dataSourceOptions?.[field?.name]) {
            // @ts-ignore
            formField.value = dataset?.dataSourceOptions?.[field?.name];
          }

          formFormFields[field?.name] = { ...formFormFields[field?.name], ...formField };
        }
      });

      setEditorQueryFields(() => thisEditorQueryField);

      if (size(keys(formFormFields)) > 0) {
        setValues(() => ({ ...formFormFields }));
      }
    }
  };

  const updateColumnVisibility = async () => {
    if (!userId || !dataset?.id) {
      return;
    }

    const userPreferencesInLocalStorage =
      getLocalStorageItem({ key: TableSessionConfig.TablePreferencesSessionKey }) || {};

    let thisUserPreferences = userPreferencesInLocalStorage || {};

    if (!has(userPreferencesInLocalStorage, userId)) {
      thisUserPreferences[userId] = {};
    }

    thisUserPreferences[userId][dataset?.id] = {};

    setLocalStorageItem({
      key: TableSessionConfig.TablePreferencesSessionKey,
      data: thisUserPreferences
    });
  };

  // Query hooks - STARTS >>
  // Mutations
  const {
    isLoading: isQueryRunning,
    isSuccess: isQueryRan,
    mutateAsync: runQueryMutation,
    reset: resetRunQueryMutation
  } = useGetConnectorSampleData({
    onSuccess: (data) => {
      if (data?.status === "SUCCESS") {
        if (size(keys(data?.data)) > 0) {
          setSampleData(() => data?.data);
        }
      } else {
        toastWrapper({
          type: "error",
          content: keys(data?.messages?.[0])?.[0] || "Something went wrong!"
        });
      }
    }
  });

  const { mutateAsync: saveQueryMutation, reset: resetSaveQueryMutation } = useUpdateEntity({
    onSuccess: async () => {
      await queryClient.invalidateQueries([UseGetSchemaDataQueryKeys.SchemaData]);
      await queryClient.invalidateQueries([QUERY_KEY_ENTITIES]);

      await updateColumnVisibility();

      await queryClient.invalidateQueries([QUERY_KEY_ENTITY_DETAILS]);
      await queryClient.invalidateQueries([UseGetDatasetDataInfiniteQueryKeys.InfiniteDatasetData]);

      queryClient.invalidateQueries([QUERY_KEY_EDA]);

      toastWrapper({
        type: "success",
        content: "Saved successfully!"
      });
    },
    onSettled: () => {
      resetRunQueryMutation();
      resetSaveQueryMutation();

      setIsSavingQuery(() => false);
      setConfirmSave(() => false);
    }
  });

  // Queries
  const { isLoading: isFetchingConnectorsSchema } = useGetConnectorsSchema({
    onSuccess: (data) => onConnectorsSchemaSucceed(data)
  });

  const { isLoading: isFetchingSchema, data: schemaData } = useGetSchemaData({
    entityId: dataset?.id
  });
  // << ENDS - Query hooks

  // SQL configuration  - STARTS >>
  const getPayloadConfig = () => {
    let isValid = true;

    const requestJson: { [key: string]: any } = {
      id: dataset?.dataSourceId,
      type: dataset?.dataSourceType,
      options: {}
    };

    if (size(keys(editorQueryFields)) > 0) {
      forEach(keys(editorQueryFields), (fieldKey: string) => {
        const thisValue: string = trim(editorQueryFields?.[fieldKey]?.value || "");

        if (editorQueryFields?.[fieldKey]?.required && !thisValue) {
          isValid = false;
          return;
        } else {
          if (thisValue) {
            requestJson.options[editorQueryFields?.[fieldKey]?.name] = thisValue;
          }
        }
      });
    }

    if (size(keys(values)) > 0) {
      forEach(keys(values), (fieldKey: string) => {
        const thisValue: string = trim(values?.[fieldKey]?.value || "");

        if (values?.[fieldKey]?.required && !thisValue) {
          isValid = false;
          return;
        } else {
          if (thisValue) {
            requestJson.options[values?.[fieldKey]?.name] = thisValue;
          }
        }
      });
    }

    return { isValid, requestJson };
  };

  const isInvalidConfig = useMemo(
    () => !getPayloadConfig()?.isValid,
    [dataset?.dataSourceId, dataset?.dataSourceType, editorQueryFields, values]
  );
  // << ENDS - SQL configuration

  const saveQuery = async () => {
    setIsSavingQuery(() => true);
    resetSaveQueryMutation();

    const payload = {
      id: dataset?.id,
      dataSourceId: dataset?.dataSourceId,
      dataSourceOptions: {
        ...(getPayloadConfig()?.requestJson?.options || {})
      }
    };

    await saveQueryMutation(payload);
  };

  const runQuery = async () => {
    resetRunQueryMutation();

    setSampleData(() => ({}));

    const payload = {
      id: dataset?.dataSourceId,
      type: dataset?.dataSourceType,
      dsOptions: {
        ...(getPayloadConfig()?.requestJson?.options || {})
      }
    };

    await runQueryMutation(payload);
  };

  const onEditorQueryFieldChange = (value: string, fieldKey: string) => {
    resetRunQueryMutation();

    const thisSqlEditorValue: { [key: string]: SchemaField } = editorQueryFields;
    thisSqlEditorValue[fieldKey].value = value;
    setEditorQueryFields(() => ({ ...thisSqlEditorValue }));
  };

  const isProcessing = useMemo(() => {
    let isDisabled = false;

    isDisabled = isDisabled || isQueryRunning;
    isDisabled = isDisabled || isSavingQuery;

    return isDisabled;
  }, [isQueryRunning, isSavingQuery]);

  const isRunQueryDisabled = useMemo(() => {
    let isDisabled = false;

    isDisabled = isDisabled || !dataset?.dataSourceId || !dataset?.dataSourceType;
    isDisabled = isDisabled || isInvalidConfig;
    isDisabled = isDisabled || isQueryRan;
    isDisabled = isDisabled || isProcessing;

    return isDisabled;
  }, [dataset?.dataSourceId, dataset?.dataSourceType, isInvalidConfig, isQueryRan, isProcessing]);

  const isSaveQueryDisabled = useMemo(() => {
    let isDisabled = false;

    isDisabled = isDisabled || !dataset?.id || !dataset?.dataSourceType;
    isDisabled = isDisabled || !isQueryRan;
    isDisabled = isDisabled || isProcessing;

    return isDisabled;
  }, [dataset?.id, dataset?.dataSourceType, isQueryRan, isProcessing]);

  const editorQueryFieldLabel = (fieldKey: string) => {
    if (!editorQueryFields[fieldKey]?.name) {
      return "Unknown";
    }

    let thisEditorQueryFieldLabel = startCase(editorQueryFields[fieldKey]?.name);

    if (editorQueryFields[fieldKey]?.required) {
      thisEditorQueryFieldLabel = `${thisEditorQueryFieldLabel} *`;
    }

    return thisEditorQueryFieldLabel;
  };

  return (
    <>
      {confirmSave && (
        <Modal
          open
          variant={ModalVariants.Delete}
          title="Replacing Data"
          content={[
            "This will replace the existing Data with the latest query Data. This action will render the current runs of the associated recipe(s) with this input dataset invalid, marking them as UNBUILT. To implement the changes, it is necessary to rerun the linked recipe(s).",
            <span key="note" style={{ color: "grey", fontStyle: "italic" }}>
              <b>Note:</b> In case the dataset has segment associated with it and this action causes
              the schema change,the segment will be deleted, and any custom scenarios using it will
              default to the entire dataset instead of the segment.
            </span>,
            "Are you sure?"
          ]}
          cancelLabel="No"
          submitLabel="Yes, Replace"
          isSubmitting={isSavingQuery}
          onClose={() => !isSavingQuery && setConfirmSave(() => false)}
          onSubmit={saveQuery}
          hideCloseIcon
        />
      )}

      {isFetchingConnectorsSchema ? (
        <Paper elevation={0} style={{ borderRadius: 12 }}>
          <Box p={4}>
            <Grid container>
              <CircularProgress color="secondary" style={{ margin: "auto" }} />
            </Grid>
          </Box>
        </Paper>
      ) : (
        <Grid container direction="column" style={{ rowGap: 16 }}>
          {!isEmpty(values) && (
            <Grid item>
              <SqlConfiguration
                isJobPath={isJobPath}
                isDefaultScenario={isDefaultScenario}
                values={values}
                setValues={setValues}
                isProcessing={isProcessing}
                resetRunQueryMutation={resetRunQueryMutation}
              />
            </Grid>
          )}
          {Object.keys(editorQueryFields)?.length > 0 && (
            <Grid container direction="row" className={classes.editorQueryFieldsContainer}>
              {(Object.keys(editorQueryFields) || [])?.map(
                (fieldKey: string, fieldIndex: number) => (
                  <Grid item>
                    <Typography
                      key={`sqlConfigurationEditorQueryField_${fieldIndex}`}
                      id="sqlConfigurationEditorQueryFieldLabel"
                      variant="body2"
                      color="textSecondary"
                      className="title">
                      {editorQueryFieldLabel(fieldKey)}
                      {toLower(trim(editorQueryFields[fieldKey]?.name)) === "query" && (
                        <Tooltip
                          id="sqlConfigurationEditorQueryFieldInfoTooltip"
                          title="Only select queries are supported on the SQL query interface"
                          arrow>
                          <InfoOutlinedIcon className="queryFieldInfo" fontSize="small" />
                        </Tooltip>
                      )}
                    </Typography>
                    <MonacoEditor
                      language="sql"
                      height="200px"
                      width="100%"
                      theme="vs-dark"
                      value={editorQueryFields[fieldKey]?.value || ""}
                      onChange={(value: string) => onEditorQueryFieldChange(value, fieldKey)}
                      options={{
                        readOnly: !!isJobPath || !isDefaultScenario || isProcessing,
                        fontSize: 12,
                        minimap: { enabled: false },
                        renderLineHighlight: "none",
                        lineNumbers: "off",
                        scrollBeyondLastLine: false,
                        padding: {
                          top: 12
                        }
                      }}
                    />
                  </Grid>
                )
              )}
            </Grid>
          )}

          {!isJobPath && isDefaultScenario && (
            <Grid container justifyContent="flex-end">
              <Button
                id="sqlConfigurationRunQuery"
                variant="contained"
                color="primary"
                disabled={isRunQueryDisabled}
                onClick={() => runQuery()}>
                {isQueryRunning ? <CircularProgress size={23} color="primary" /> : "Run Query"}
              </Button>
              <Button
                id="sqlConfigurationSaveQuery"
                variant="contained"
                color="primary"
                disabled={isSaveQueryDisabled}
                onClick={() => setConfirmSave(() => true)}
                style={{ marginLeft: 8 }}>
                {isSavingQuery ? <CircularProgress size={23} color="primary" /> : "Save Query"}
              </Button>
            </Grid>
          )}

          {(!!isQueryRunning || !isEmpty(sampleData)) && (
            <SampleDataContainer
              isFetchingSchema={isFetchingSchema}
              isFetchingSampleData={isQueryRunning}
              schemaData={schemaData}
              sampleData={sampleData}
              tableMaxHeight={isEmpty(values) ? 705 : 780}
            />
          )}
        </Grid>
      )}
    </>
  );
};

export default SqlInterface;
