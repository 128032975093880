import { useMemo, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

import { UpdateProjectRunDtoStatusEnum } from "@rapidcanvas/rc-api-core";

import { deleteJobWithRethrow } from "services/Apis/wrappers";
import { toastWrapper } from "services/ToastClient/toastWrapper";
import { useProjectsStore, useJobsStore } from "stores/zustand/stores";
import { projectsGetter, shouldRefreshJobsToggler } from "stores/zustand/stores.selectors";
import {
  prefetchDataSources,
  prefetchScenarios,
  prefetchJob,
  prefetchJobDestinations,
  useUpdateJob
} from "src/hooks/api";
import { prefetchVariables } from "src/hooks/api/projects/useGetVariables";
import { WebPaths } from "src/routing/routes";
import { JobsStatuses } from "../private/ProjectsModule/pages/Jobs/utils/Jobs.constants";
import { compact } from "lodash";
import React from "react";
import { CircularProgress, Grid } from "@material-ui/core";

export const useJobsListHelper = ({ data }: { data: $TSFixMe }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [showConfirmScreen, setShowConfirmScreen] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const currentProjects = useProjectsStore(projectsGetter) || [];
  const refreshRecentJobs = useJobsStore(shouldRefreshJobsToggler);

  const {
    dto: { id: jobId, projectId, status }
  } = data;

  const { isLoading: isUpdatingJobStatus, mutateAsync: updateJobStatusMutation } = useUpdateJob();

  const pauseJob = async () => {
    const payload = { id: jobId, status: UpdateProjectRunDtoStatusEnum.Inactive };
    await updateJobStatusMutation(payload, {
      onSuccess: () => {
        toastWrapper({ type: "success", content: "Scheduler paused successfully!" });
        refreshRecentJobs();
      }
    });
  };

  const resumeJob = async () => {
    const payload = { id: jobId, status: UpdateProjectRunDtoStatusEnum.Active };
    await updateJobStatusMutation(payload, {
      onSuccess: () => {
        toastWrapper({ type: "success", content: "Scheduler resumed successfully!" });
        refreshRecentJobs();
      }
    });
  };

  const prefetchJobQueries = ({ jobId }: $TSFixMe = {}) => {
    prefetchDataSources({ queryClient });
    prefetchVariables({ queryClient, projectId });

    if (!!jobId) {
      prefetchScenarios({ queryClient, projectId, jobId });
      prefetchJob({ queryClient, jobId });
      prefetchJobDestinations({ queryClient, jobId });
    } else {
      prefetchScenarios({ queryClient, projectId });
    }
  };

  // Delete job - STARTS >>
  const deleteJob = async () => {
    setIsDeleting(true);

    try {
      await deleteJobWithRethrow(jobId);

      toastWrapper({
        type: "success",
        content: "Scheduler deleted successfully!"
      });
      refreshRecentJobs();
    } catch (e) {
      console.error(e);
    } finally {
      setIsDeleting(false);
      setShowConfirmScreen(false);
    }
  };

  const promptConfirmDeleteJob = () => {
    setShowConfirmScreen(true);
  };

  const cancelDeleteJob = () => {
    setShowConfirmScreen(false);
  };

  const confirmDeleteJob = () => {
    deleteJob();
  };
  // << ENDS - Delete job

  const cardOptions = useMemo(
    () =>
      compact([
        {
          label: "Scheduler History",
          click: () =>
            navigate(
              generatePath(`${WebPaths.JobRoutes}${WebPaths.JobRuns}`, { projectId, jobId })
            ),
          disabled: isDeleting
        },
        {
          label: "Edit Scheduler",
          click: () => {
            prefetchJobQueries({ jobId });
            navigate(generatePath(`${WebPaths.JobRoutes}${WebPaths.JobId}`, { projectId, jobId }));
          },
          disabled: isDeleting
        },
        status === JobsStatuses.Active
          ? {
              label: (
                <Grid alignItems="center" container justifyContent="space-between">
                  <span>Pause</span> {isUpdatingJobStatus && <CircularProgress size={14} />}
                </Grid>
              ),
              click: pauseJob,
              disabled: isDeleting || isUpdatingJobStatus,
              doNotclose: true
            }
          : undefined,

        status === JobsStatuses.Inactive
          ? {
              label: (
                <Grid container alignItems="center" justifyContent="space-between">
                  <span>Resume</span> {isUpdatingJobStatus && <CircularProgress size={14} />}
                </Grid>
              ),
              click: resumeJob,
              disabled: isDeleting || isUpdatingJobStatus,
              doNotclose: true
            }
          : undefined,
        {
          label: "Delete Scheduler",
          click: promptConfirmDeleteJob,
          disabled: isDeleting
        }
      ]),
    [projectId, jobId, isDeleting, status, isUpdatingJobStatus]
  );

  const projectName = useMemo(
    () => currentProjects?.find((project: $TSFixMe) => project?.id === projectId)?.name || "",
    [currentProjects]
  );

  return {
    projectName,
    cardOptions,
    cancelDeleteJob,
    confirmDeleteJob,
    showConfirmScreen,
    isDeleting
  };
};
