import { Recipe } from "src/types";

export const extractNewRecipeName = ({ name, groups }: { groups: Recipe[]; name: string }) => {
  const entityName = name;
  const allRecipeNames = groups.reduce((acc: Array<string>, item) => {
    return [...acc, item.name?.toLowerCase(), item.displayName?.toLowerCase()];
  }, []);
  let maxRecipeNameLength = 64;
  let maxLength = 64 - " Recipe".length;
  let updatedEntityName =
    entityName && entityName?.length > maxLength ? entityName.substring(0, maxLength) : entityName;
  let updatedRecipeName = updatedEntityName ? `${updatedEntityName} Recipe` : `Recipe`;
  if (allRecipeNames?.length) {
    let i = 2;
    while (allRecipeNames?.includes(updatedRecipeName?.toLowerCase())) {
      updatedRecipeName = updatedEntityName ? `${updatedEntityName} Recipe ${i}` : `Recipe ${i}`;
      if (updatedRecipeName.length > maxRecipeNameLength) {
        const stripLength = updatedRecipeName.length - maxRecipeNameLength;
        updatedEntityName = updatedEntityName?.substring(0, updatedEntityName.length - stripLength);
        updatedRecipeName = updatedEntityName ? `${updatedEntityName} Recipe ${i}` : `Recipe ${i}`;
      }
      i = i + 1;
    }
  }
  return updatedRecipeName;
};
