import React, { useMemo } from "react";
import _ from "lodash";
import { FormControl, Grid, InputLabel, makeStyles, MenuItem, Select } from "@material-ui/core";
import { useLocation, useParams } from "react-router";

import { useAutoMLRecipeContext } from "../../AutoMLRecipeContext/useAutoMLRecipeContext";
import {
  Parameters,
  OutputDatasets,
  OutputCharts
} from "src/pages/Projects/common/TransformInputs/TransformInputs";
import { useStyles as useTransformInputStyles } from "src/pages/Projects/common/TransformInputs/TransformInputs";
import { FREQUENCY_OPTS, CUSTOM_PARAMETERS } from "../../utils/AddAutoMLRecipe.constants";
import { Spinner } from "src/components";
import { checkIfDefaultScenario } from "src/pages/private/ProjectsModule/utils";
import { useScenariosStore } from "stores/zustand/stores";
import { map } from "lodash";
import NumberInput from "./NumberInput";

const useStyles = makeStyles({
  parametersWrap: {
    width: "100%",
    "& label": {
      whiteSpace: "nowrap"
    },
    gap: "8px"
  },
  flexWrap: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    flexWrap: "nowrap",
    paddingTop: "8px"
  },
  helpTextContainer: {
    width: "25px"
  },
  formItem: {
    flex: 1
  }
});

const AutoMLTemplateInputs = ({
  inputs,
  selectedEntities,
  errors,
  values,
  handleInputChange
}: $TSFixMe) => {
  const location = useLocation();
  const { scenarioId } = useParams<$TSFixMe>();
  const isJobPath = useMemo(() => /jobs/.test(location.pathname), [location.pathname]);

  const classes = useStyles();
  const { allColumns, isEntityLoading, scenarioData } = useAutoMLRecipeContext();
  const scenarios = useScenariosStore((state) => state.scenarios);

  const columnNames = map(allColumns, "name") ?? null;

  const transformInputClasses = useTransformInputStyles();

  const isDefaultScenario = useMemo(
    () => checkIfDefaultScenario(scenarioData, scenarios, scenarioId),
    [scenarioData, scenarios, scenarioId]
  );

  const getCustomParameter = (input: $TSFixMe) => {
    const inputInfo = input?.metadata ? "metadata" : "display";
    const currentInput = input[inputInfo];

    switch (input?.name) {
      case CUSTOM_PARAMETERS.predictPeriod:
        return (
          <NumberInput
            isDefaultScenario={isDefaultScenario}
            input={input}
            errors={errors}
            currentInput={currentInput}
            values={values}
            isJobPath={isJobPath}
            onInputChange={handleInputChange}
          />
        );
      case CUSTOM_PARAMETERS.predictFrequency:
        return (
          <Grid
            key={`transform-parameter-${input.name}`}
            container
            className={transformInputClasses.inputBox}
            test-id="selectedTransformParameterContainer">
            <Grid className={classes.formItem}>
              <FormControl
                disabled={!isDefaultScenario}
                variant="outlined"
                fullWidth
                margin="dense"
                error={errors?.[input.name]}
                required={currentInput?.is_required}>
                <InputLabel
                  htmlFor={`transform-parameter-${input.name}`}
                  test-id="selectedTransformParameterInputLabel">
                  {currentInput?.input_name}
                </InputLabel>
                <Select
                  fullWidth
                  label={currentInput?.input_name}
                  value={
                    currentInput?.multiple
                      ? values[input.name]?.split(", ") || []
                      : values[input.name]
                  }
                  multiple={currentInput?.multiple}
                  onChange={
                    currentInput.multiple
                      ? (e) => {
                          handleInputChange({
                            // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
                            target: { value: e.target.value?.join(", "), name: input.name }
                          });
                        }
                      : handleInputChange
                  }
                  inputProps={{
                    name: input.name,
                    id: `transform-parameter-${input.name}`,
                    "test-id": "selectedTransformParameterField"
                  }}
                  disabled={isJobPath || !isDefaultScenario}>
                  {FREQUENCY_OPTS.map(({ value, label }) => (
                    <MenuItem
                      key={value}
                      value={value}
                      dense
                      test-id="selectedTransformParameterLabel">
                      {label || value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item className={classes.helpTextContainer} />
          </Grid>
        );
    }
  };

  const filteredParameters = useMemo(
    () =>
      (inputs as $TSFixMe)?.parameters?.filter(
        (input: $TSFixMe) =>
          ![
            "RC_PARAM_RECIPE_NAME",
            "RC_PARAM_PROJECT_NAME",
            "mode",
            "modelName",
            "targetCol",
            "futurePeriod"
          ].includes(input.name)
      ),
    [inputs]
  );

  return (
    <Grid container direction="column" className={classes.flexWrap}>
      {isEntityLoading ? (
        <Spinner />
      ) : (
        !!filteredParameters?.length && (
          <Grid container direction="column" className={classes.parametersWrap}>
            {filteredParameters.map((input: $TSFixMe, index: number) => {
              if (_.has(CUSTOM_PARAMETERS, input?.name)) {
                return getCustomParameter(input);
              }
              // Filter out backend only input parameter in all auto ml templates
              if (
                [
                  "RC_PARAM_RECIPE_ID",
                  "RC_PARAM_PROJECT_ID",
                  "RC_PARAM_RECIPE_NAME",
                  "RC_PARAM_PROJECT_NAME",
                  "mode",
                  "modelName",
                  "targetCol",
                  "freeze_col"
                ].includes(input.name)
              ) {
                return <React.Fragment key={index} />;
              }
              return (
                <Grid container key={index} direction="row">
                  <Grid className={classes.formItem}>
                    <Parameters
                      disabled={!isDefaultScenario}
                      isJobPath={isJobPath}
                      showHelpText={false}
                      errors={errors}
                      key={index}
                      input={input}
                      columnOptions={input.metadata.input_column ? columnNames : null}
                      values={values}
                      handleInputChange={handleInputChange}
                      selectedEntities={selectedEntities}
                      outputDatasets={[]}
                    />
                  </Grid>
                  <Grid item className={classes.helpTextContainer} />
                </Grid>
              );
            })}
          </Grid>
        )
      )}
      {!!(inputs as $TSFixMe).output_datasets?.length && (
        <Grid container direction="column" className={classes.flexWrap}>
          <Grid container direction="row">
            <Grid className={classes.formItem}>
              <OutputDatasets
                isJobPath={isJobPath}
                displayInfo={true}
                showHelpText={false}
                errors={errors}
                outputs={(inputs as $TSFixMe).output_datasets}
                values={values}
                handleInputChange={handleInputChange}
                disabled={!isDefaultScenario}
              />
            </Grid>
            <Grid className={classes.helpTextContainer} />
          </Grid>
        </Grid>
      )}
      {!!(inputs as $TSFixMe).output_charts?.length && (
        <Grid container direction="column" className={classes.flexWrap}>
          <Grid direction="row">
            <Grid container className={classes.formItem}>
              <OutputCharts
                isJobPath={isJobPath}
                displayInfo={true}
                errors={errors}
                showHelpText={false}
                inputs={(inputs as $TSFixMe).output_charts}
                values={values}
                handleInputChange={handleInputChange}
                disabled={!isDefaultScenario}
              />
            </Grid>
            <Grid className={classes.helpTextContainer} />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default React.memo(AutoMLTemplateInputs);
