import { useState } from "react";

// Packages
import axios from "axios";
import { get, head, toLower } from "lodash";

// Utils
import { toastWrapper } from "services/ToastClient/toastWrapper";
import { handleResponse, postAPI } from "services/Apis/Apis.service";
import { checkEnvRelaunch } from "environmentsModule/utils/environmentRelaunch.helpers";
import { download as downloadUtil } from "utils/helpers/file.helpers";

// Constants
import fileTypes from "constants/fileTypes.constants";

interface IProps {
  projectId?: string;
  isCheckEnvRelaunch?: boolean;
  isJobCanvas?: boolean;
  jobProps?: any;
  data?: any;
}

const useDownloadTextInput = (props: IProps) => {
  const { projectId, isCheckEnvRelaunch = true, isJobCanvas, jobProps, data } = props;

  const [isDownloading, setIsDownloading] = useState(false);

  const download = async () => {
    setIsDownloading(() => true);

    if (!data?.datasetId || !data?.fileType || !data?.fileName) {
      return;
    }

    !!isCheckEnvRelaunch && !!projectId && checkEnvRelaunch(projectId);

    try {
      let jobPayload = {};
      if (!!isJobCanvas) {
        jobPayload = !!jobProps?.jobRunId ? { projectRunEntryId: jobProps?.jobRunId } : {};
      }

      const payload = {
        scenarioId: data?.scenarioId || "",
        entityId: data?.datasetId || "",
        fileType: data?.fileType || "",
        downloadRaw: true,
        ...jobPayload
      };

      const signedUrl = await postAPI(`/v2/entities/${data?.datasetId}/download`, payload);

      await axios
        .get(signedUrl, { responseType: "arraybuffer" })
        .then((response) => {
          downloadUtil(
            response.data,
            data?.fileName,
            head(get(fileTypes, [toLower(data?.fileType), "mimeTypes"], []))
          );
        })
        .catch((error) => {
          handleResponse({
            errorMessage:
              error.response?.data?.msg ||
              error.message ||
              `Error download the file ${data?.fileName}.`
          });
        });
    } catch (error: $TSFixMe) {
      toastWrapper({
        type: "error",
        content: `Error download the file ${data?.fileName}.`
      });
    }

    setIsDownloading(() => false);
  };

  return { isDownloading, download };
};

export default useDownloadTextInput;
