import { useMutation, useQueryClient } from "@tanstack/react-query";

import api from "services/AxiosClient/AxiosClient";
import { AI_GUIDE_MESSAGES_KEY } from "../aiguide";
import { AIChatResponseDto } from "@rapidcanvas/rc-api-core";

export const useRemoveMessageFromOutputMutation = (threadId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ messageId }: { messageId: string }) => {
      const response = await api.fetchResponse(() =>
        api.AiControllerApi.removeOutput(threadId, messageId)
      );
      queryClient.setQueryData(
        [AI_GUIDE_MESSAGES_KEY, threadId!],
        (prevData: AIChatResponseDto[] | undefined) => [
          ...(prevData || []).map((message) =>
            message.askAIMessageId === messageId ? { ...message, transformId: undefined } : message
          )
        ]
      );
      return response;
    }
  });
};
