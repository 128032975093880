import { CircularProgress, Grid, IconButton, makeStyles, Tooltip } from "@material-ui/core";
import React, { useState } from "react";
import { OverflowTooltip } from "src/components";
import { READONLY_ENTITY } from "projectsModule/utils/Projects.constants";

import { DeleteNew } from "src/assets/icons/DeleteNew";
import ArrowExpandIcon from "icons/NewUX/ArrowExpandIcon";
import { ModelMini } from "src/types";
import { ArtifactIcon } from "icons/NewUX/ArtifactIcon";
import ModelPopover from "./ModelPopover";

interface IProps {
  model: ModelMini;
  isDefaultScenario: boolean;
  isJobPath: boolean;
  onDeleteModels: (modelName: string) => void;
}

const useStyles = makeStyles({
  entityLeftContent: {
    flexWrap: "nowrap",
    gap: "8px",
    alignItems: "center",
    minHeight: "48px",
    padding: "0px 16px",
    border: "0.5px #D1D1D1",
    borderStyle: "solid none;"
  },
  entity: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    padding: "4px 6px",
    width: "inherit",
    justifyContent: "space-between",
    borderBottom: "0.5px solid #D1D1D1",
    height: "35px"
  },
  entityText: {
    fontWeight: 400,
    fontSize: "14px",
    width: "174px"
  },
  tableWrap: {
    padding: "1px",
    overflow: "auto",
    maxWidth: "500px"
  },
  flex: {
    display: "flex",
    alignItems: "center",
    gap: "6px"
  }
});

const InputModels: React.FC<IProps> = ({ model, isDefaultScenario, isJobPath, onDeleteModels }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [hovered, setHovered] = useState(false);

  const handleDelete = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    onDeleteModels(model.name);
    setIsDeleting(true);
  };

  return (
    <>
      <Grid
        container
        className={classes.entity}
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}
        onMouseEnter={() => {
          setHovered(true);
        }}
        onMouseLeave={() => {
          setHovered(false);
        }}
        key={model.name}>
        <div className={classes.flex}>
          <ArtifactIcon height={14} width={14} viewBox="0 0 16 16" />
          <div className={classes.entityText} data-testid="code-recipe-input-artifact-name">
            <OverflowTooltip value={model.name} />
          </div>
        </div>
        {hovered && (
          <div style={{ display: "flex" }}>
            {!isJobPath && (
              <Tooltip title={!isDefaultScenario ? READONLY_ENTITY : ""}>
                <div>
                  <IconButton
                    size="small"
                    style={{ padding: "8px" }}
                    disabled={!isDefaultScenario || isDeleting}
                    onClick={handleDelete}
                    test-id="code-recipe-delete-input-model-btn">
                    {isDeleting ? <CircularProgress size={16} /> : <DeleteNew size="tiny" />}
                  </IconButton>
                </div>
              </Tooltip>
            )}
            <IconButton
              size="small"
              test-id="code-recipe-expand-input-model-btn"
              style={{ padding: "8px" }}>
              <ArrowExpandIcon width={16} height={16} />
            </IconButton>
          </div>
        )}
      </Grid>

      {!!anchorEl && (
        <ModelPopover anchorEl={anchorEl} name={model.name} setAnchorEl={setAnchorEl} />
      )}
    </>
  );
};

export default InputModels;
