import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import { isEmpty } from "lodash";

import api from "services/AxiosClient/AxiosClient";
import { getRedirectUrl } from "connectorsModule/utils/fivetran.helpers";
import { handleErrorWithRedirectToLogs } from "services/Apis/Apis.service";
import { postAPIWithRethrow } from "services/Apis/Apis.service";
import { updateConnectorsQueryData } from "src/utils/helpers";
import { thirdPartyTypeName } from "src/pages/DataSources/utils/DataSources.constants";

interface IVariables {
  id: string;
  name: string;
  iconUrl?: string;
}

interface IData {
  connectCardUrl: string;
  connectorId: string;
  groupId: string;
  responseEntity: string;
  status: string;
}

const useCreateConnector = (): UseMutationResult<IData, unknown, IVariables, unknown> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, name, iconUrl }) => {
      try {
        await api.client(`/v2/data-source/by-name/${name}`);
        handleErrorWithRedirectToLogs(`${name} data connector already exists.`);
        return null;
      } catch {
        const data = await postAPIWithRethrow(`/v2/data-source/create-connector/${id}`, {
          redirectUrl: getRedirectUrl(name)
        });

        const connectorResponse = await postAPIWithRethrow("/v2/data-source", {
          name,
          type: thirdPartyTypeName,
          tpConnectorType: id,
          iconUrl,
          options: {
            groupId: data.groupId,
            connectorId: data.connectorId,
            connectCardUrl: data.connectCardUrl
          }
        });

        if (!isEmpty(connectorResponse)) {
          if (!!connectorResponse?.id) {
            updateConnectorsQueryData({ queryClient, data: connectorResponse });
          }
        }

        return data;
      }
    },
    onSuccess: (result) => {
      window.open(result.connectCardUrl, "_self");
    }
  });
};

export default useCreateConnector;
