import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  statusContainer: {
    textTransform: "capitalize",
    fontWeight: 600,
    "& button": {
      padding: 0,
      color: "#003656"
    }
  },
  shutdown: {
    color: "#c77700",
    "& svg": { color: "#663d00" }
  },
  launching: {
    color: "#2196f3",
    "& svg": { color: "#0e3c61" }
  },
  stopping: {
    color: "#c77700",
    "& svg": { color: "#663d00" }
  },
  success: {
    color: "#3b873e",
    "& svg": { color: "#1d4620" }
  },
  failed: {
    color: "#f44336",
    "& svg": { color: "#621b16" }
  },
  link: {
    display: "inline-flex",
    color: "#003656",
    "&:hover": {
      color: "#003656",
      textDecoration: "underline"
    }
  },
  projectsChipContainer: { position: "relative", minHeight: theme.spacing(3) },
  projectsChip: {
    position: "absolute",
    right: 0,
    cursor: "pointer",
    backgroundColor: "white",
    "&:hover, &:active, &:focus": {
      backgroundColor: "white !important"
    }
  }
}));

export default useStyles;
