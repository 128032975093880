import api from "services/AxiosClient/AxiosClient";
import { useMutation, UseMutationResult } from "@tanstack/react-query";

interface IVariable {
  runConfigId: string;
}

const useResetNotebook = (): UseMutationResult<any, unknown, IVariable, unknown> => {
  return useMutation({
    mutationFn: async ({ runConfigId }) => {
      return await api.fetchResponse(() =>
        api.DfsRunConfigControllerV2Api.resetTemplate(runConfigId)
      );
    }
  });
};

export default useResetNotebook;
