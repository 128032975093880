import React, { useMemo, useState } from "react";

import { isEmpty, size } from "lodash";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import PreviewIconNew from "icons/NewUX/Dataset/PreviewIconNew";

import LoaderWrapper from "./LoaderWrapper";
import Header from "./Header";
import SampleData from "./SampleData";

import { SampleData as SampleDataType } from "../Source.types";
import CommonLoader from "src/components/CommonLoader";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    width: "100%",
    borderRadius: theme.spacing(1.5),
    "& > [class^='MuiGrid-root']": {
      padding: theme.spacing(2)
    }
  }
}));

type Props = {
  isFetchingSchema: boolean;
  isFetchingSampleData: boolean;
  schemaData: any;
  sampleData: SampleDataType;
  tableMaxHeight: number;
};

const SampleDataContainer = (props: Props) => {
  const { isFetchingSchema, isFetchingSampleData, schemaData, sampleData, tableMaxHeight } =
    props || {};

  const classes = useStyles();

  // States - STARTS >>
  const [searchTerm, setSearchTerm] = useState("");
  // << ENDS - States

  const loader = useMemo(() => {
    if (isFetchingSampleData) {
      return (
        <LoaderWrapper>
          <Typography color="primary">
            <PreviewIconNew />
          </Typography>
          <Typography variant="body2" color="primary">
            Fetching sample data...
          </Typography>
        </LoaderWrapper>
      );
    } else if (isFetchingSchema) {
      return <CommonLoader />;
    } else {
      return null;
    }
  }, [isFetchingSchema, isFetchingSampleData]);

  const noDataFoundMessage = (message: string) => (
    <LoaderWrapper>
      <Typography variant="body2" color="primary">
        {message}
      </Typography>
    </LoaderWrapper>
  );

  return (
    <Paper className={classes.root}>
      <Header
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        showSearchField={!isEmpty(sampleData)}
      />

      {!!loader ? (
        loader
      ) : size(sampleData?.columns) === 0 || size(sampleData?.rows) === 0 ? (
        noDataFoundMessage("No sample data found")
      ) : (
        <SampleData
          schemaData={schemaData}
          sampleData={sampleData}
          searchTerm={searchTerm}
          tableMaxHeight={tableMaxHeight}
        />
      )}
    </Paper>
  );
};

export default SampleDataContainer;
