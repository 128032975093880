import React from "react";
import { Box, Grid, makeStyles } from "@material-ui/core";

import AIGuideChatBox from "./AIGuideChatBox";
import { AskAICodeEditor } from "../../common/AskAICodeEditor";
import { ChartIconV3 } from "icons/NewUX/ChartIconV3";
import { SampleRows } from "../../AddCodeRecipe/CodeRecipeTabContainer/AskAIContainer/AskAIResponseContainer/SampleRows";
import Charts from "./Charts";

export type ChartDto = {
  name: string;
  dataFilePath: string;
  chartType: string;
};

export type ExtractedChartDto = {
  plotlyJson: string;
  name: string;
};

type IProps = {
  title: string;
  isCollapsed?: boolean;
  isGenerating?: boolean;
  chartList: (ChartDto | ExtractedChartDto)[];
  onToggle?: () => void;
  rightContainer?: React.ReactNode;
  shouldShowCode?: boolean;
  code?: string;
  isAddedToRecipe?: boolean;
  errDetails?: any;
  icon?: React.ReactNode;
  sampleRows?: number;
  askAIMessageId?: string;
  isEditChart?: boolean;
  subThreadId?: string;
  onClose?: () => void;
  parentAskAIMessageID?: string;
  parentThreadId?: string;
  isSaveDisabled?: boolean;
  isAiGuide?: boolean;
};

const useStyles = makeStyles({
  chartsWrap: {
    overflow: "auto",
    visibility: "hidden",
    opacity: 0,
    position: "absolute"
  },
  selectedChart: {
    display: "block",
    visibility: "visible",
    opacity: 1,
    position: "relative"
  },
  icon: {
    height: "32px",
    width: "24px !important",

    "& svg": {
      marginLeft: "-4px"
    }
  },
  chartWrap: {
    margin: "5px 12px 5px 12px",
    overflowY: "auto",
    borderRadius: "2px"
  }
});

const GroupedCharts = ({
  title,
  isCollapsed,
  isGenerating,
  chartList,
  onToggle: onToggleProp,
  rightContainer,
  shouldShowCode,
  code,
  isAddedToRecipe,
  errDetails,
  icon,
  sampleRows,
  askAIMessageId,
  isEditChart = false,
  subThreadId,
  onClose,
  parentAskAIMessageID,
  parentThreadId,
  isSaveDisabled = false,
  isAiGuide = false
}: IProps) => {
  const classes = useStyles();

  const onToggle = ({ onSuccess }: { onSuccess: () => void }) => {
    onSuccess?.();
    onToggleProp?.();
  };
  return (
    <AIGuideChatBox
      isCollapsed={isCollapsed}
      isExpandDisabled={false}
      isGenerating={isGenerating}
      onToggle={onToggle}
      rightContainer={rightContainer}
      isAddedToRecipe={isAddedToRecipe}
      icon={
        icon || (
          <Grid
            container
            item
            direction="column"
            alignItems="flex-start"
            justifyContent="center"
            wrap="nowrap"
            data-testid="ai-dataset-chart-icon"
            className={classes.icon}>
            <ChartIconV3 />
          </Grid>
        )
      }
      title={title}>
      {shouldShowCode && code ? (
        <AskAICodeEditor editorValue={code} errDetails={errDetails} />
      ) : (
        <Grid container direction="column" wrap="nowrap" data-testid="chartsWrap">
          <Charts
            isAddedtoRecipe={isAddedToRecipe}
            parentThreadId={parentThreadId}
            askAIMessageId={askAIMessageId}
            chartList={chartList}
            isEditChart={isEditChart}
            subThreadId={subThreadId}
            onClose={onClose}
            parentAskAIMessageID={parentAskAIMessageID}
            isSaveDisabled={isSaveDisabled}
            isAiGuide={isAiGuide}
          />
          {sampleRows && (
            <Box pt="8px" style={{ backgroundColor: "#fff" }}>
              <SampleRows sampleRows={sampleRows} />
            </Box>
          )}
        </Grid>
      )}
    </AIGuideChatBox>
  );
};

export default React.memo(GroupedCharts);
