import React from "react";
import _ from "lodash";
import { InfoOutlined, OpenInNew } from "@material-ui/icons";
import { FormControl, InputLabel, MenuItem, Select, Tooltip, makeStyles } from "@material-ui/core";
import {
  DataappAskAIConfigInputTypeEnum,
  DataappAskAIConfigLlmTypeEnum
} from "@rapidcanvas/rc-api-core";
import { dataAppConfigFields } from "./CreateDataAppForm";

const useStyles = makeStyles({
  link: {
    color: ({ disabled }: { disabled?: boolean }) => (disabled ? "#00000061" : "#003656"),
    "&:hover": {
      textDecoration: "none"
    }
  },
  flex: {
    display: "flex",
    gap: "8px",
    alignItems: "center"
  },
  icons: {
    fontSize: "1rem",
    marginBottom: "2px",
    color: "#8a8989"
  }
});

interface IProps {
  readOnly?: boolean;
  value: DataappAskAIConfigLlmTypeEnum;
  inputType?: DataappAskAIConfigInputTypeEnum;
  onChange?: (e: any) => void;
}

const SelectAskAILLMModel: React.FC<IProps> = (props) => {
  const { link, flex, icons } = useStyles({ disabled: props.readOnly });

  return (
    <FormControl variant="outlined" style={{ minWidth: "400px" }} disabled={props.readOnly}>
      <InputLabel id="dataAppSelectLLM" data-testid="dataAppSelectLLM">
        Models
      </InputLabel>
      <Select
        name={dataAppConfigFields.llmType}
        value={props.value}
        label="Models"
        required
        labelId="dataAppSelectLLM"
        onChange={props.onChange}
        displayEmpty
        inputProps={{ "aria-label": dataAppConfigFields.llmType }}
        data-testid="dataAppConfigLlmType">
        <MenuItem value={DataappAskAIConfigLlmTypeEnum.OpenaiGpt4O}>
          <div className={flex}>
            <span>OpenAI GPT-4o</span>
            <a
              className={link}
              href="https://platform.openai.com/docs/models/gpt-4o"
              target="_blank"
              rel="noreferrer">
              <OpenInNew className={icons} />
            </a>
            <Tooltip title="This is recommended for better accuracy of responses">
              <InfoOutlined className={icons} />
            </Tooltip>
          </div>
        </MenuItem>
        <MenuItem value={DataappAskAIConfigLlmTypeEnum.OpenaiGpt4Turbo}>
          <div className={flex}>
            <span>OpenAI GPT-4 Turbo</span>
            <a
              className={link}
              href="https://platform.openai.com/docs/models/gpt-4-turbo-and-gpt-4"
              rel="noreferrer"
              target="_blank">
              <OpenInNew className={icons} />
            </a>
          </div>
        </MenuItem>
        {props.inputType !== DataappAskAIConfigInputTypeEnum.RagFiles && (
          <MenuItem value={DataappAskAIConfigLlmTypeEnum.AzureOpenaiGpt4O}>
            <div className={flex}>
              <span>Azure OpenAI GPT-4o</span>
              <a
                className={link}
                href="https://learn.microsoft.com/en-us/azure/ai-services/openai/concepts/models#gpt-4o-and-gpt-4-turbo"
                rel="noreferrer"
                target="_blank">
                <OpenInNew className={icons} />
              </a>
            </div>
          </MenuItem>
        )}
        {props.inputType !== DataappAskAIConfigInputTypeEnum.RagFiles && (
          <MenuItem value={DataappAskAIConfigLlmTypeEnum.AnthropicClaude35Sonnet}>
            <div className={flex}>
              <span>Anthropic Claude 3.5 Sonnet</span>
              <i>(beta)</i>
              <a
                className={link}
                href="https://docs.anthropic.com/en/docs/intro-to-claude"
                rel="noreferrer"
                target="_blank">
                <OpenInNew className={icons} />
              </a>
              <Tooltip title="Integration with Claude is currently in beta stage. Responses might be slower and incorrect">
                <InfoOutlined className={icons} />
              </Tooltip>
            </div>
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
};

export default SelectAskAILLMModel;
