import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { isArray } from "lodash";

import { getDataSourcesSchemaWithRethrow } from "services/Apis/wrappers/projects";

export const enum UseGetConnectorsSchemaQueryKeys {
  ConnectorsSchema = "connectors-schema"
}

interface ConnectorsSchemaProps extends UseQueryOptions<$TSFixMe> {
  // useQuery options
  cacheTime?: number;
  enabled?: boolean;
}

export const useGetConnectorsSchema = (props: ConnectorsSchemaProps): UseQueryResult<$TSFixMe> => {
  const {
    // useQuery options
    cacheTime = 0,
    enabled = true,
    ...options
  } = props;

  return useQuery({
    queryKey: [UseGetConnectorsSchemaQueryKeys.ConnectorsSchema],
    queryFn: async () => await getDataSourcesSchemaWithRethrow(),
    select: (data) => (isArray(data) ? data : []),
    cacheTime,
    enabled,
    ...options
  });
};
