import React, { useMemo } from "react";
import { IconButton, Tooltip, makeStyles } from "@material-ui/core";
import { includes, size, without } from "lodash";

import PinnedIcon from "icons/NewUX/PinnedIcon";
import { useCodeRecipeContext } from "../../../CodeRecipeContext/useCodeRecipeContext";
import UnPinnedIcon from "icons/NewUX/UnPinnedIcon";
import { MAX_INPUT_COUNT } from "../GenerateCodeBar/GenerateCodeInputsDropdown";

export const useStyles = makeStyles({
  queryBtn: {
    borderRadius: "4px",
    background: "#fff",
    padding: "6px"
  }
});

interface IProps {
  name: string;
}

const PinnedDatasets: React.FC<IProps> = (props) => {
  const { name } = props;

  const classes = useStyles();
  const { pinnedNames, setPinnedNames, setInputNames } = useCodeRecipeContext();

  const pinned = useMemo(() => includes(pinnedNames, name), [pinnedNames, name]);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    const datasets = pinned ? without(pinnedNames, name) : [...pinnedNames, name];
    const updated =
      datasets?.length <= MAX_INPUT_COUNT ? datasets : datasets.slice(1, MAX_INPUT_COUNT + 1);

    setPinnedNames(updated);
    setInputNames(updated);
  };

  const disabled = useMemo(
    () => size(pinnedNames) >= MAX_INPUT_COUNT,

    [pinnedNames]
  );

  return (
    <Tooltip
      title={
        !pinned && disabled
          ? `Maximum of ${MAX_INPUT_COUNT} datasets can be pinned at once. Kindly unpin one to proceed`
          : "Pinning a dataset will run queries on pinned datasets instead of intermediate datasets"
      }>
      <div>
        <IconButton
          className={classes.queryBtn}
          disabled={!pinned && disabled}
          test-id="ask-ai-pin"
          style={{ opacity: !pinned && disabled ? 0.5 : 1 }}
          onClick={handleClick}>
          {pinned ? <PinnedIcon /> : <UnPinnedIcon color="#0f0f0f" />}
        </IconButton>
      </div>
    </Tooltip>
  );
};

export default PinnedDatasets;
