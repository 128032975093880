export enum TableConfig {
  maxColumnsLimitFromBackEnd = 200,
  DefaultColumnsCount = 200,
  MaxColumnsCount = 300,
  DefaultStartingRowIndex = 0,
  DefaultEndingRowIndex = 500
}

export enum TableDataHelperText {
  TableInfo = "The sorting, search, and filtering features are limited to the currently loaded rows."
}
