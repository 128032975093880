import React from "react";
import { Grid, Box, Typography, makeStyles, Theme } from "@material-ui/core";
import { isEmpty } from "lodash";

import CommonLoader from "src/components/CommonLoader";
import { EnvironmentProjectsTable } from "./EnvironmentProjectsTable";
import { Project } from "./EnvironmentTabs";

interface IProps {
  projects: Project[];
  isLoading: boolean;
}

export interface StylesProps {
  projects: Project[];
}

const useStyles = makeStyles<Theme, StylesProps>((theme) => ({
  root: {
    height: ({ projects }) => (isEmpty(projects) ? "inherit" : "initial"),
    rowGap: theme.spacing(2)
  }
}));

export const EnvironmentProjectsTab: React.FC<IProps> = ({ projects, isLoading }) => {
  const styles = useStyles({ projects });
  return (
    <Grid container className={styles.root}>
      {isLoading ? (
        <CommonLoader />
      ) : isEmpty(projects) ? (
        <>
          <Box m="auto">
            <Typography variant="body2" color="textSecondary" align="center" gutterBottom>
              The environment is not used in any project yet.
            </Typography>
            <Typography variant="body2" color="textSecondary" align="center">
              After you use it for any project, all of them will be listed here.
            </Typography>
          </Box>
        </>
      ) : (
        <>
          <Typography variant="body2" color="textSecondary">
            Once you create the environment, RapidCanvas will pull automatically the projects in
            which the environment is used. This will help to keep track of the projects related to
            this environment in case you need to change any setting.
          </Typography>
          <EnvironmentProjectsTable data={projects} />
        </>
      )}
    </Grid>
  );
};
