import { IconButton, Tooltip } from "@material-ui/core";
import { FileCopyOutlined, LibraryAddCheckOutlined } from "@material-ui/icons";
import React from "react";
import useCopyClipboard from "src/hooks/useCopyClipboard";

interface IProps {
  name: string;
  style?: Record<string, any>;
  tooltip?: string;
  className?: string;
}

const CopyNameButton: React.FC<IProps> = ({ name, style, tooltip, className }) => {
  const { handleCopyClick, isCopied } = useCopyClipboard(name);
  return (
    <Tooltip title={tooltip ?? "Copy name"}>
      <IconButton
        size="small"
        style={style}
        className={className}
        onClick={handleCopyClick}
        data-testid={`code-recipe-name-copy-${name}`}>
        {isCopied ? (
          <LibraryAddCheckOutlined fontSize="small" />
        ) : (
          <FileCopyOutlined style={{ width: "16px", height: "16px", color: "black" }} />
        )}
      </IconButton>
    </Tooltip>
  );
};

export default CopyNameButton;
