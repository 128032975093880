import React from "react";
import { Box, Grid, makeStyles, Typography } from "@material-ui/core";

import { Warning } from "src/assets/icons/Warning";
import NewModal from "src/components/Modal/NewModal";

const useStyles = makeStyles({
  queryText: {
    maxHeight: "350px",
    overflow: "auto"
  },
  warning: {
    flexShrink: 0
  }
});

export const ModalWarningDialog = ({
  handleSubmit,
  onClose
}: {
  onClose: () => void;
  handleSubmit: () => void;
}) => {
  const classes = useStyles();
  return (
    <NewModal
      open
      header={"Warning"}
      onClose={onClose}
      onFinish={handleSubmit}
      width="465px"
      height={350}
      background="#fff"
      submitButtonLabel="I, Understand"
      cancelButtonLabel="Cancel">
      <Grid container wrap="nowrap" style={{ gap: "16px" }}>
        <Grid item className={classes.warning}>
          <Warning />
        </Grid>
        <Box display="flex" flexDirection="column" gridGap={16}>
          <Typography className={classes.queryText}>
            Please note that model generation is experimental and might generate errors in some
            cases. We recommend you to validate and update the code as needed. For stable option,
            you can use the Rapid Model Recipe on Canvas
          </Typography>
        </Box>
      </Grid>
    </NewModal>
  );
};
