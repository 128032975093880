import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  subtopBarContainer: {
    // New UX change
    marginTop: -44,
    position: "fixed",
    width: "100%",
    // position: "sticky",
    // Covered margin-top as part of mainContainer.
    // top: "44px",

    height: "44px",
    background: "white",
    zIndex: 11,
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.05), 0px 1px 1px 0px rgba(0,0,0,0.05), 0px 1px 3px 0px rgba(0,0,0,0.05)"
  },
  subtopBar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "5px 16px",
    background: "white",
    height: "44px"
  },
  fullWidth: {
    width: "100%"
  },
  barButton: {
    textTransform: "capitalize",
    fontSize: "14px",
    fontWeight: 400,
    "&:last-child button": {
      marginRight: 0
    },
    "& svg": {
      marginRight: "4px",
      height: "16px",
      verticalAlign: "unset"
    }
  },
  barMainButton: {
    height: "30px",
    padding: "4px 12px",
    textTransform: "capitalize",
    fontSize: "14px",
    "&.MuiButton-outlined": {
      background: "#ffffff !important",
      borderColor: "#008fe4",
      color: "#008fe4",
      "&:disabled": {
        backgroundColor: "rgb(0 143 228 / 20%)",
        borderColor: "rgb(0 143 228 / 20%)",
        color: "rgb(0 143 228 / 20%)"
      }
    },
    // @REFACTOR
    // Applied start-with wild-card as this was ignoring this class new UX which will have hash at the end.
    "&:not([^='MuiButton-containedPrimary'])": {
      fontWeight: 400,
      background: "#f5f5f5"
    },
    "& svg": {
      marginRight: "4px",
      height: "16px",
      verticalAlign: "unset"
    },
    "&.MuiButton-containedPrimary": {
      "&:disabled": {
        backgroundColor: "rgb(0 143 228 / 20%)"
      }
    }
  },
  iconButton: {
    background: "none !important",
    padding: 0,
    minWidth: 0,
    marginRight: 10,
    "& svg": {
      marginRight: 0,
      height: "24px"
    }
  },
  barMenu: {
    "& .MuiList-root": {
      padding: 0,
      "& .MuiListItem-root": {
        padding: "9px 14px",
        fontSize: 14
      },
      "& .MuiListItem-root.disabled": {
        cursor: "not-allowed"
      }
    }
  },
  menuListDisabled: {
    cursor: "not-allowed"
  },
  menuListButton: {
    padding: "0 !important",
    "& button": {
      minWidth: "173px",
      justifyContent: "flex-start"
    }
  },
  buttonLoader: {
    justifyContent: "center !important"
  },
  barButtonDisabled: {
    "& svg": {
      "& path": {
        stroke: "rgba(0, 0, 0, 0.26)"
      }
    }
  },
  backButton: {
    minWidth: 0,
    padding: 0,
    marginRight: 6,
    "& path": {
      stroke: "rgb(0, 54, 86)"
    }
  },
  viewButtonsContainer: {
    display: "flex",
    alignItems: "center",
    gap: "9px"
  },
  viewButtonsIcons: {
    padding: "0",
    borderRadius: "4px"
  },
  opacityIcon: {
    opacity: "0.4"
  },
  actionsButtonContainer: {
    height: "36px",
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    justifyContent: "flex-end"
  },
  actionButton: {
    "&:not(:last-of-type)": {
      marginRight: "16px"
    }
  },
  moreOptionsButton: {
    background: "#ffffff",
    border: "1px solid #003656",
    borderRadius: "4px",
    width: "30px",
    height: "30px",
    transition:
      "backgroundColor 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, boxShadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    "&:hover": {
      background: "rgba(0, 54, 86, 0.04)"
    }
  },
  subtopBarMenuButton: {
    textTransform: "capitalize",
    height: "30px"
  },
  inputValueContainer: {
    width: "100%",
    "& svg": {
      height: 20,
      width: 20
    }
  },
  infoTooltipStyles: {
    margin: "0px 15px",
    cursor: "help",
    opacity: 0.5,
    verticalAlign: "bottom"
  }
});
