import React, { useState } from "react";

import { IconButton, Tooltip } from "@material-ui/core";

import { Visibility } from "@material-ui/icons";
import ArtifactPreviewModal from "./ArtifactPreviewModal";
import { getContentType } from "src/hooks/api/artifacts/usePreviewArtifactFile";

interface IProps {
  name: string;
  artifactName: string;
  type: string;
}

const ArtifactPreview: React.FC<IProps> = (props) => {
  const { name, artifactName, type } = props;
  const [open, setOpen] = useState(false);

  const onClose = () => {
    setOpen(false);
  };

  const handlePreview = () => {
    setOpen(true);
  };

  const disabled = !getContentType(type) && !name.toLowerCase().endsWith(".md");

  return (
    <>
      {open && (
        <ArtifactPreviewModal
          name={name}
          open={open}
          type={type}
          onClose={onClose}
          artifactName={artifactName}
        />
      )}
      <Tooltip title={disabled ? "File type is not Supported for Preview" : "Preview"}>
        <span>
          <IconButton size="small" color="primary" disabled={disabled} onClick={handlePreview}>
            <Visibility />
          </IconButton>
        </span>
      </Tooltip>
    </>
  );
};

export default ArtifactPreview;
