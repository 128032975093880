import React, { PropsWithChildren } from "react";
import { Button, ButtonProps, CircularProgress, Tooltip } from "@material-ui/core";

import useDownloadPredictionJobOutput from "hooks/api/projects/useDownloadPredictionJobOutput";
import { DownloadRound } from "src/assets/icons/DownloadRound";
import { UNSUCCESSFUL_PREDICTION_OUTPUT_MSG } from "../utils/PredictionJob.constants";

interface IProps extends ButtonProps {
  id: string;
}

const DownloadPredictions: React.FC<PropsWithChildren<IProps>> = (props) => {
  const { id, disabled, children, ...rest } = props;

  const downloadOutput = useDownloadPredictionJobOutput();

  const handleDownload = () => {
    downloadOutput.mutate({ lastRunEntryId: id });
  };

  return (
    <Tooltip title={disabled ? UNSUCCESSFUL_PREDICTION_OUTPUT_MSG : "Download"}>
      <span>
        <Button disabled={disabled || downloadOutput.isLoading} onClick={handleDownload} {...rest}>
          {downloadOutput.isLoading ? (
            <CircularProgress size={16} />
          ) : (
            children ?? <DownloadRound stroke={disabled ? "#00000042" : "#000000"} />
          )}
        </Button>
      </span>
    </Tooltip>
  );
};

export default DownloadPredictions;
