import { get, has } from "lodash";

import makeStyles from "@material-ui/core/styles/makeStyles";

import { ChartStatuses, NodeChartTheme } from "src/pages/private/ProjectsModule/utils";

type StyleProps = {
  status: string;
};

const mainContainerDimensions = 28;
const beforeElementDimensions = 24.5;
const reducedDimensions = (mainContainerDimensions - beforeElementDimensions) / 2; // 40px: main-container, 35px: before-element.

const beforeElementStyles = {
  content: '""',
  width: beforeElementDimensions,
  height: beforeElementDimensions,
  top: reducedDimensions,
  left: reducedDimensions,
  position: "absolute",
  display: "block",
  borderRadius: beforeElementDimensions / 3,
  transform: "rotate(45deg)"
};

const getIconMargin = (status: string) => {
  switch (status) {
    case ChartStatuses.Empty:
    case ChartStatuses.Built:
      return "0 0 15% 14%";

    default:
      return "0 0 10% 14%";
  }
};

const useStyles = makeStyles({
  root: {
    position: "relative",
    width: mainContainerDimensions,
    height: mainContainerDimensions,
    "&:before": {
      ...beforeElementStyles,
      backgroundColor: ({ status }: StyleProps) =>
        has(NodeChartTheme, status)
          ? get(NodeChartTheme, [status, "backgroundColor"])
          : NodeChartTheme.backgroundColor,
      border: "1px solid",
      borderColor: ({ status }: StyleProps) =>
        has(NodeChartTheme, status)
          ? get(NodeChartTheme, [status, "borderColor"])
          : NodeChartTheme.borderColor
    },
    "&:hover": {
      cursor: "help"
    }
  },
  container: {
    height: "100%",
    position: "relative",
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "center",
    alignItems: "center"
  },
  icon: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: reducedDimensions,
    "& svg": {
      margin: ({ status }: StyleProps) => getIconMargin(status),
      width: "70%",
      height: "70%"
    }
  }
});

export default useStyles;
