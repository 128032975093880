/* eslint-disable no-unused-vars */
import { useQuery } from "@tanstack/react-query";

import { getAPIWithRethrow } from "services/Apis/Apis.service";

type Props = {
  enabled?: boolean;
};

export const useGetStreamlitToken = ({ enabled }: Props) => {
  return useQuery<string>({
    queryKey: ["streamlit-token"],
    queryFn: () => {
      return getAPIWithRethrow(`/streamlit/access-token`);
    },
    enabled,
    cacheTime: 0
  });
};
