import _ from "lodash";
import { toast } from "react-toastify";
import { useMutation, UseMutationResult } from "@tanstack/react-query";

import api from "services/AxiosClient/AxiosClient";
import {
  PublishAppTemplateRequestDtoAppTypeEnum,
  PublishAppTemplateRequestDtoSourceEnum,
  SignedUrlRequestDtoSignedUrlObjectTypeEnum
} from "@rapidcanvas/rc-api-core";

interface IProps {
  templateId: string;
  appType: PublishAppTemplateRequestDtoAppTypeEnum;
}

const useDownloadDataAppZip = (): UseMutationResult<null, unknown, IProps, unknown> => {
  return useMutation({
    mutationFn: async ({ appType, templateId }) => {
      const toastId = toast.info("Downloading...");

      const template = await api.fetchResponse(
        async () => await api.AppTemplateControllerApi.getAppTemplate(templateId)
      );
      const name = template.name;
      if (!name) {
        toast.update(toastId, {
          render: "Error in Downloading...",
          type: "error",
          isLoading: false,
          autoClose: 5000
        });
        throw "Name is absent";
      }

      const data = await api.fetchResponse(
        async () =>
          await api.SignedUrlControllerApi.downloadFileUrl({
            fileName: `${name}.zip`,
            signedUrlObjectType:
              appType === PublishAppTemplateRequestDtoAppTypeEnum.Streamlit
                ? SignedUrlRequestDtoSignedUrlObjectTypeEnum.AppTemplateStreamlit
                : SignedUrlRequestDtoSignedUrlObjectTypeEnum.AppTemplateReactjs,
            metadata: {
              appType: _.lowerCase(appType),
              SOURCE: template.source ?? PublishAppTemplateRequestDtoSourceEnum.Tenant
            }
          })
      );
      if (data.signedUrl) {
        const a = document.createElement("a");
        a.setAttribute("href", data.signedUrl);
        a.setAttribute("download", name);
        a.click();
      }

      toast.update(toastId, {
        render: "Downloaded Successfully",
        type: "success",
        isLoading: false,
        autoClose: 5000
      });

      return null;
    }
  });
};

export default useDownloadDataAppZip;
