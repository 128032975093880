import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { PredictionServiceDto } from "@rapidcanvas/rc-api-core";

import api from "services/AxiosClient/AxiosClient";
import _ from "lodash";

export const CACHE_QUERY_KEY_FETCH_PREDICTION_SERVICES = "fetch-prediction-services";

const usePredictionServices = (
  projectId: string,
  options?: UseQueryOptions<any, Error>
): UseQueryResult<PredictionServiceDto[], Error> => {
  return useQuery<PredictionServiceDto[], Error>({
    queryKey: [CACHE_QUERY_KEY_FETCH_PREDICTION_SERVICES, projectId],
    queryFn: async () => {
      const data = await api.fetchResponse(
        async () => await api.PredictionServiceControllerApi.findPredictionServices()
      );

      return _.filter(data, (item) => item.projectId === projectId);
    },
    ...options
  });
};

export default usePredictionServices;
