import { Grid, Typography, IconButton, makeStyles } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import React from "react";

const useStyles = makeStyles({
  promptListHeader: {
    color: "#4646B5",
    fontWeight: 400,
    fontSize: "14px"
  }
});

interface IPromptHeaderProps {
  isExpanded: boolean;
  onToggle: () => void;
}

export const PromptsHeader: React.FC<IPromptHeaderProps> = ({ isExpanded, onToggle }) => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction="row"
      onClick={onToggle}
      alignItems="center"
      justifyContent="space-between">
      <Typography
        variant="subtitle2"
        className={classes.promptListHeader}
        data-testid="ai-prompts-header">
        List of Prompts
      </Typography>
      <IconButton size="small" data-testid="ai-query-expand-toggle-icon">
        {isExpanded ? <ExpandLess fontSize="small" /> : <ExpandMore fontSize="small" />}
      </IconButton>
    </Grid>
  );
};
