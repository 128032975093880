import { useEffect } from "react";
import { isString, throttle } from "lodash";

import api from "services/AxiosClient/AxiosClient";
import useCheckTokenValidation from "src/hooks/api/auth/useCheckTokenValidation";
import { AuthSessionConfig } from "constants/index";
import { useAuthSetAuthResponse } from "src/hooks/useAuthSetAuthResponse";
import { useRefreshToken } from "src/hooks/api";

type Props = {
  isUserLoggedIn?: boolean;
  logout?: () => void;
};

const useRefreshTokenHook = (props: Props) => {
  const { isUserLoggedIn, logout } = props || {};

  const { setAuthResponse } = useAuthSetAuthResponse();
  const { mutateAsync: refreshTokenMutation, reset: resetRefreshTokenMutation } = useRefreshToken();

  const { refetch } = useCheckTokenValidation({
    onSuccess: (data) => {
      if (data) {
        setAuthResponse({
          data
        });
      }
    },
    enabled: false
  });

  // Create a throttled function to trigger the mutation
  const throttledMutation = throttle(async (tokenFromStorage) => {
    resetRefreshTokenMutation();
    await refreshTokenMutation(
      { token: tokenFromStorage },
      {
        onSuccess: async (data) => {
          if (isString(data)) {
            api.setToken(data);
            await refetch();
          }
        },
        onError: (error) => {
          console.error(error);
          logout?.();
        }
      }
    );
  }, AuthSessionConfig.refreshTokenInterval);

  const stopPolling = (intervalId: NodeJS.Timeout | null) => {
    if (intervalId) {
      clearInterval(intervalId);
    }
    throttledMutation?.cancel();
  };

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;

    if (!!isUserLoggedIn) {
      intervalId = setInterval(() => {
        const token = api.getToken();
        if (!!token) {
          throttledMutation(token);
        }
      }, AuthSessionConfig.refreshTokenInterval);
    } else {
      stopPolling(intervalId);
    }

    return () => stopPolling(intervalId);
  }, [isUserLoggedIn, throttledMutation]);
};

export default useRefreshTokenHook;
