import React, { useMemo, useRef } from "react";

import { useLocation, Link } from "react-router-dom";
import { includes, isEmpty } from "lodash";

import {
  Container,
  Divider,
  IconButton,
  Typography,
  useTheme,
  makeStyles
} from "@material-ui/core";

import { MainMenuIcon } from "icons/NewUX/MainMenuIcon";
import { RcLogoIcon } from "icons/NewUX/RcLogoIcon";
import CafexIcon from "icons/NewUX/CafexIcon";
import { WebPaths } from "src/routing/routes";

import { capitalizeFirstLetter } from "utils/helpers/string.helpers";
import { useGetRole } from "src/hooks/useGetRole";

import useAuthStore from "stores/zustand/auth.store";

import { VIEW_DATA, getMenus } from "./helpers/TopNavBar.helpers";

import useMainMenu from "./useMainMenu";
import MainMenu from "../MainMenu/MainMenu";

import { CafexConfig } from "constants/index";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-flex !important",
    alignItems: "center",

    "& .divider": {
      marginTop: 9.5,
      marginBottom: 9.5,
      backgroundColor: theme.palette.text.primary,
      opacity: 0.3
    },
    "& .menuIcon": {
      width: theme.spacing(4),
      height: theme.spacing(4),
      backgroundColor: theme.palette.grey[200]
    }
  }
}));

const TopNavBarLeftSection = () => {
  const theme = useTheme();
  const mainMenuRef = useRef<HTMLButtonElement>(null);

  const classes = useStyles();

  const location = useLocation();

  const [tenantIdStore] = useAuthStore((state) => [state.tenantId]);

  const { isRoleYieldsDataAppView, isConsumer, isDataAppPowerUser } = useGetRole();

  // @REFACTOR
  const [section, sectionId] = location.pathname.split("/").filter((part: string) => Boolean(part));

  // @REFACTOR
  const menu = useMemo(
    () =>
      getMenus({
        shouldHideProjectNavigation: isDataAppPowerUser,
        section:
          section === VIEW_DATA
            ? "projects"
            : section ||
              (isRoleYieldsDataAppView || isConsumer || isDataAppPowerUser
                ? "dataapps-ui"
                : undefined)
      }) || {},
    [section, sectionId, isRoleYieldsDataAppView, isConsumer, isDataAppPowerUser]
  );
  // Main-menu - STARTS >>
  const { mainMenuButtonAnchorEl, onMainMenuOpen, onMainMenuClose, menuItems, notebookMenuItems } =
    useMainMenu({
      mainMenuRef
    });
  // << ENDS - Main-menu

  const getMenuLabel = (menu: $TSFixMe) => (
    <Typography variant="body2" color="textPrimary">
      {capitalizeFirstLetter(menu?.sectionName)}
    </Typography>
  );

  return (
    <>
      {/* Main-menu mounts here */}

      <MainMenu
        mainMenuButtonAnchorEl={mainMenuButtonAnchorEl}
        onMainMenuClose={onMainMenuClose}
        menuItems={menuItems}
        notebookMenuItems={notebookMenuItems}
      />

      {/* Need to apply prop disableGutters. */}
      <Container className={classes.root}>
        <Link to={WebPaths.Dashboard} data-testid="rcLogoLink">
          {/* @HACK: Applied a temporary workaround to display the icon in the top menu bar specifically for the Cafex client. This code should be removed once its no longer needed. */}
          {includes([CafexConfig.TenantId, CafexConfig.TestTenantId], tenantIdStore) ? (
            <CafexIcon />
          ) : (
            <RcLogoIcon />
          )}
        </Link>
        <Divider orientation="vertical" style={{ marginLeft: 12 }} flexItem className="divider" />

        <IconButton
          edge="start"
          ref={mainMenuRef}
          style={{ marginLeft: 0, cursor: "default" }}
          color="inherit"
          onMouseEnter={onMainMenuOpen}
          data-testid="mainMenuAction"
          aria-controls={Boolean(mainMenuButtonAnchorEl) ? "hover-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={Boolean(mainMenuButtonAnchorEl) ? "true" : undefined}>
          <MainMenuIcon color={theme.palette.text.primary} opacity={0.5} />
        </IconButton>
        <Divider orientation="vertical" style={{ marginRight: 12 }} flexItem className="divider" />
        {/* Disable prop for react-router Link is not available. Hence, opted conditional rendering. */}
        {!isEmpty(menu) &&
          (!!menu?.backButtonPath ? (
            <>
              <Link to={menu?.backButtonPath || WebPaths.Dashboard} data-testid="mainMenuLabelLink">
                {getMenuLabel(menu)}
              </Link>
            </>
          ) : (
            <>{getMenuLabel(menu)}</>
          ))}
      </Container>
    </>
  );
};

export default TopNavBarLeftSection;
