import React from "react";
import useStyles from "./Source.styles";

import useDownloadDataFiles from "src/hooks/api/entities/useDownloadDataFiles";
import { CircularProgress, Link } from "@material-ui/core";
import clsx from "clsx";
import { OverflowTooltip } from "src/components";

interface IProps {
  item: any;
}

const FileName: React.FC<IProps> = ({ item }) => {
  const classes = useStyles();
  const downloadDataFile = useDownloadDataFiles();
  const handleDownload = (id: string, name: string) => {
    downloadDataFile.mutate({ id, name });
  };
  return (
    <Link
      className={clsx(classes.link, downloadDataFile.isLoading ? classes.disabledLink : null)}
      component={"button"}
      underline={"none"}
      disabled={downloadDataFile.isLoading}
      style={{ width: "100%" }}
      onClick={() => handleDownload(item.id, item.name)}>
      <OverflowTooltip value={item.name} />
      {downloadDataFile.isLoading && (
        <span style={{ padding: "0 10px" }}>
          <CircularProgress size={14} />
        </span>
      )}
    </Link>
  );
};

export default FileName;
