import React, { Dispatch, SetStateAction } from "react";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import { SearchField } from "src/components";

const useStyles = makeStyles((theme) => ({
  // @REFACTOR
  // The below styles are added only to support New UX. It shall be removed soon.
  searchField: {
    "& [class^='MuiInputBase-root']": {
      background: "none",
      border: "1px solid #ccc",
      borderRadius: theme.spacing(0.5)
    },
    "& input": {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      fontSize: "small"
    }
  }
}));

type Props = {
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  showSearchField: boolean;
};

const Header = (props: Props) => {
  const { searchTerm, setSearchTerm, showSearchField = false } = props || {};

  const classes = useStyles();

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event?.target?.value);
  };

  return (
    <Grid container alignItems="center" justifyContent="space-between">
      <Grid item>
        <Typography>Sample Data</Typography>
      </Grid>
      {showSearchField && (
        <Grid item>
          <SearchField
            id="sqlConfigurationSearch"
            placeholder="Search"
            className={classes.searchField}
            value={searchTerm}
            onChange={onSearchChange}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default Header;
