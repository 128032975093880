import { Grid, Typography, makeStyles, Box, IconButton, Tooltip } from "@material-ui/core";
import { SendSharp } from "@material-ui/icons";
import React from "react";
import { CopyTextIcon } from "../../../CopyButton/CopyTextIcon";

const useStyles = makeStyles({
  promptTitle: {
    color: "#4646B5",
    fontWeight: 400
  },
  promptDescription: {
    fontStyle: "italic",
    color: "#515151",
    fontWeight: 400
  },
  promptWithCopy: {
    flexWrap: "nowrap",
    gap: "16px"
  },
  actions: { gap: "3px", flexWrap: "nowrap" }
});

export const PromptItem = ({
  title,
  description,
  isGenerating,
  onClick
}: {
  title: string;
  description: string;
  isGenerating: boolean;
  onClick: (text: string) => void;
}) => {
  const classes = useStyles();
  return (
    <Grid item wrap="nowrap" component="li">
      <Grid container direction="row" className={classes.promptWithCopy}>
        <Grid container direction="column" wrap="nowrap">
          <Typography
            variant="subtitle2"
            className={classes.promptTitle}
            data-testid="ai-prompt-title">
            {title}
          </Typography>
          <Typography
            variant="subtitle2"
            className={classes.promptDescription}
            data-testid="ai-prompt-description">
            {description}
          </Typography>
        </Grid>
        <Box>
          <Grid container className={classes.actions}>
            <CopyTextIcon text={title} data-testid="ai-prompt-copy-text-icon" />
            <Tooltip
              title={isGenerating ? "Please wait until existing query is executed" : "Run Query"}
              placement="top"
              arrow>
              <span>
                <IconButton
                  size="small"
                  disabled={isGenerating}
                  onClick={(e) => {
                    e.stopPropagation();
                    onClick(title);
                  }}
                  style={{ paddingLeft: 0, paddingRight: 0 }}>
                  <SendSharp
                    color="primary"
                    style={{
                      fontSize: "16px",
                      color: isGenerating ? "inherit" : "#4646b5"
                    }}
                  />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};
