// Package
import { capitalize } from "lodash";

// Open API
import {
  FindProjectRunsJobTypeEnum,
  ProjectRunDto,
  UpdateProjectRunDtoStatusEnum
} from "@rapidcanvas/rc-api-core";

// Services
import { ToastTypes, toastWrapper } from "services/ToastClient/toastWrapper";

// Hooks
import { useUpdateJob } from "hooks/api";

// Constants
import { JobsHelperText } from "../utils/Jobs.constants";
import { IProjectReturn } from "hooks/api/projects/useRunDetails";

interface IProps {
  onJobPaused?: (jobId?: string) => void;
  onJobResumed?: (jobId?: string) => void;
}

const useJobActions = (props?: IProps) => {
  const { onJobPaused, onJobResumed } = props || {};

  const { mutateAsync: updateJobStatusMutation, reset: resetUpdateJobStatusMutation } =
    useUpdateJob();

  const pauseJob = async (job: ProjectRunDto | IProjectReturn) => {
    if (!job?.id) return;

    resetUpdateJobStatusMutation();

    const payload = { id: job?.id, status: UpdateProjectRunDtoStatusEnum.Inactive };
    await updateJobStatusMutation(payload, {
      onSuccess: () => {
        toastWrapper({
          type: ToastTypes.Success,
          content:
            job?.jobType === FindProjectRunsJobTypeEnum.PredictionJob
              ? `${capitalize(JobsHelperText.PredictionScheduler)} paused successfully!`
              : `${JobsHelperText.Scheduler} paused successfully!`
        });
        onJobPaused?.(job?.id);
      }
    });
  };

  const resumeJob = async (job: ProjectRunDto | IProjectReturn) => {
    if (!job?.id) return;

    resetUpdateJobStatusMutation();

    const payload = { id: job?.id, status: UpdateProjectRunDtoStatusEnum.Active };
    await updateJobStatusMutation(payload, {
      onSuccess: () => {
        toastWrapper({
          type: ToastTypes.Success,
          content:
            job?.jobType === FindProjectRunsJobTypeEnum.PredictionJob
              ? `${capitalize(JobsHelperText.PredictionScheduler)} resumed successfully!`
              : `${JobsHelperText.Scheduler} resumed successfully!`
        });
        onJobResumed?.(job?.id);
      }
    });
  };

  return { pauseJob, resumeJob };
};

export default useJobActions;
