import React from "react";
import {
  Select,
  MenuItem,
  Grid,
  Typography,
  makeStyles,
  SelectProps as MuiSelectProps
} from "@material-ui/core";
import { WidgetProps } from "@rjsf/utils";

const useStyles = makeStyles({
  root: {
    gap: "4px"
  },
  label: {}
});

type SelectFieldProps = MuiSelectProps & {
  onChange: (value: string | number) => void;
  readonly?: boolean;
  options: {
    enumOptions?: any;
  };
};

const SelectField: React.FC<WidgetProps | SelectFieldProps> = ({
  id,
  options,
  label,
  required,
  disabled,
  readonly,
  value,
  defaultValue,
  onChange
}) => {
  const classes = useStyles();
  const { enumOptions } = options;

  return (
    <Grid container direction="column" wrap="nowrap" className={classes.root}>
      <Grid item>
        <Typography variant="body2" align="left" className={classes.label}>
          {label}
          {required && " *"}
        </Typography>
      </Grid>
      <Grid item>
        <Select
          labelId={`${id}-label`}
          id={id}
          defaultValue={defaultValue}
          variant="outlined"
          margin="dense"
          value={value || ""}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
            onChange(event.target.value as string)
          }
          fullWidth
          disabled={disabled || readonly}
          required={required}>
          {(enumOptions as any[]).map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </Grid>
  );
};

export default SelectField;
