import React, { useMemo } from "react";
import _, { isEqual, map, orderBy, size, toLower } from "lodash";

import { FilterMenu } from "src/components/Menu/FilterMenu";
import { Model } from "src/hooks/api/projects/useGetAllProjectModels";

export type SelectedModel = Pick<Model, "id" | "name">;
interface IProps {
  models: SelectedModel[];
  selectedModels: SelectedModel[];
  onSelect: (selectedModels: SelectedModel[]) => void;
}

const ModelFilter: React.FC<IProps> = (props) => {
  const { models, selectedModels, onSelect } = props;

  const options = useMemo(
    () =>
      orderBy(
        _.map(models, (model) => {
          return {
            label: model.name,
            value: model.id,
            selected: map(selectedModels, "id").includes(model.id)
          };
        }),
        (item) => toLower(item.label)
      ),
    [selectedModels]
  );

  const handleFilter = (values: string[]) => {
    const keyBy = _.keyBy(options, "id");

    onSelect(
      map(values, (val) => ({
        id: val,
        name: _.get(keyBy, [val, "label"])
      }))
    );
  };

  return (
    <FilterMenu
      tooltip="Filter by Model"
      isFiltered={!isEqual(size(selectedModels), size(options))}
      values={options}
      allLabel="All Models"
      onFilter={handleFilter}
      testId="modelsFilterBtn"
    />
  );
};

export default ModelFilter;
