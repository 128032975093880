// Packages
import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { isArray, sortBy, toLower } from "lodash";

// Open API
import {
  FindProjectRunsJobTypeEnum,
  FindProjectRunsSubTypeEnum,
  ProjectRunDashboardDto,
  ProjectRunDetailDto
} from "@rapidcanvas/rc-api-core";

// Utils
import api from "services/AxiosClient/AxiosClient";

export const enum UseGetJobsDashboardQueryKeys {
  JobsDashboard = "jobs-dashboard"
}

export const enum UseGetJobsQueryKeys {
  Jobs = "jobs",
  JobsNew = "jobsNew"
}

interface JobsProps extends UseQueryOptions<$TSFixMe> {
  projectId?: string;
  shouldRefreshJobsGetter?: $TSFixMe;
  jobType?: FindProjectRunsJobTypeEnum;
  subType?: FindProjectRunsSubTypeEnum;
  // useQuery options
  cacheTime?: number;
  enabled?: boolean;
  refetchOnMount?: boolean;
}

interface IJobsProps extends UseQueryOptions<unknown, unknown, ProjectRunDetailDto[], any> {
  projectId?: string;
  shouldSort?: boolean;
  jobType?: FindProjectRunsJobTypeEnum;
  subType?: FindProjectRunsSubTypeEnum;
}

export const useGetJobsDashboard = (
  options?: UseQueryOptions<ProjectRunDashboardDto>
): UseQueryResult<ProjectRunDashboardDto> =>
  useQuery({
    queryKey: [UseGetJobsDashboardQueryKeys.JobsDashboard],
    queryFn: async () => {
      const data = await api.fetchResponse(
        async () => await api.ProjectRunControllerApi.findDashboards()
      );
      return data;
    },
    ...options
  });

export const useGetJobs = (props: JobsProps): UseQueryResult<ProjectRunDetailDto[]> => {
  const {
    projectId,
    shouldRefreshJobsGetter,
    jobType,
    subType,

    // useQuery options
    ...options
  } = props;

  return useQuery({
    queryKey: [UseGetJobsQueryKeys.Jobs, projectId, jobType, subType],
    queryFn: async () => {
      if (!projectId) {
        throw "projectId is null";
      }

      const data = await api.fetchResponse(
        async () => await api.ProjectRunControllerApi.findProjectRuns(projectId, jobType, subType)
      );

      return data;
    },
    select: (data: ProjectRunDetailDto[]) => (Array.isArray(data) ? data : []),
    staleTime: 0, // Ensures data is always considered stale
    cacheTime: Infinity, // Keeps the data in memory indefinitely
    enabled: !!projectId || (!!projectId && !!shouldRefreshJobsGetter),
    ...options
  });
};

export const useGetJobsNew = (props: IJobsProps): UseQueryResult<ProjectRunDetailDto[]> => {
  const { projectId, shouldSort = true, jobType, subType, ...options } = props;

  return useQuery({
    queryKey: [UseGetJobsQueryKeys.JobsNew, projectId, jobType],
    queryFn: async () => {
      if (!projectId) {
        throw "projectId is null!";
      }

      return await api.fetchResponse(
        async () => await api.ProjectRunControllerApi.findProjectRuns(projectId, jobType, subType)
      );
    },
    select: (data) =>
      isArray(data) ? (!!shouldSort ? sortBy(data, (job) => toLower(job?.dto?.name)) : data) : [],
    enabled: !!projectId,
    ...options
  });
};
