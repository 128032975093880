/* eslint-disable no-unused-vars */
import { getAPIWithRethrow } from "services/Apis/Apis.service";
import { useQuery } from "@tanstack/react-query";

import { DfsTemplate } from "src/types";

export const useGetDfsTemplateByName = ({
  templateName,
  enabled
}: {
  templateName: string | null;
  enabled: boolean;
}) => {
  return useQuery<DfsTemplate>({
    queryKey: ["dfsTemplates", templateName],
    queryFn: () => {
      return getAPIWithRethrow(`/v2/dfs-templates`, {
        params: { name: templateName, source: "SYSTEM" }
      }).then((response) => response[0]);
    },
    enabled: enabled
  });
};
