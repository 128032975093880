import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

// Open API
import api from "services/AxiosClient/AxiosClient";
import {
  DataFileDto,
  GetDataFilesParentTypeEnum,
  GetDataFilesSubTypeEnum
} from "@rapidcanvas/rc-api-core";

export const enum UseGetDatasetFilesQueryKeys {
  DatasetFiles = "datasetFiles"
}

interface IDatasetFilesProps extends UseQueryOptions<unknown, unknown, DataFileDto[]> {
  id?: string;
  parentType?: GetDataFilesParentTypeEnum;
  subType?: GetDataFilesSubTypeEnum;
  datasetId?: string;
}

const useGetDatasetFiles = (props: IDatasetFilesProps): UseQueryResult<DataFileDto[]> => {
  const {
    id = undefined,
    parentType = GetDataFilesParentTypeEnum.Entity,
    subType = GetDataFilesSubTypeEnum.User,
    datasetId,
    ...options
  } = props;

  const isInvalidParams = !parentType || !subType || !datasetId;

  return useQuery({
    queryKey: [UseGetDatasetFilesQueryKeys.DatasetFiles],
    queryFn: async () => {
      if (!!isInvalidParams) {
        throw new Error("Invalid payload!");
      }

      return await api.fetchResponse(
        async () => await api.DataFileControllerApi.getDataFiles(id, parentType, subType, datasetId)
      );
    },
    onError: (error) => {
      console.error(error);
    },
    enabled: !!options?.enabled && !isInvalidParams,
    ...options
  });
};

export default useGetDatasetFiles;
