import { useEffect, useMemo, useState } from "react";

import { filter, includes, isEmpty, size, slice, some, toUpper } from "lodash";

import { RecipeRunData, RecipeRunDataStatusEnum } from "@rapidcanvas/rc-api-core";

import { QueuedRecipeRunsConfig } from "./RecipeRunsPopover.constants";

type Props = {
  showMore: boolean;
  recipeRunsQueue: RecipeRunData[];
};

const useHelpers = (props: Props) => {
  const { showMore, recipeRunsQueue } = props || {};

  // States - STARTS >>
  const [slicedRecipeRunsQueue, setSlicedRecipeRunsQueue] = useState<RecipeRunData[]>([]);
  // << ENDS - States

  const queuedRecipes = useMemo(
    () =>
      filter(recipeRunsQueue, (recipe) =>
        includes([toUpper(RecipeRunDataStatusEnum.InQueue)], toUpper(recipe?.status))
      ),
    [recipeRunsQueue]
  );

  const isRecipesRunning = useMemo(
    () =>
      !isEmpty(recipeRunsQueue) &&
      some(recipeRunsQueue, (recipe) =>
        includes([toUpper(RecipeRunDataStatusEnum.Running)], toUpper(recipe?.status))
      ),
    [recipeRunsQueue]
  );

  const hiddenRecipeRunsCount = useMemo(
    () => size(recipeRunsQueue) - size(slicedRecipeRunsQueue),
    [recipeRunsQueue, slicedRecipeRunsQueue]
  );

  useEffect(() => {
    const queue = showMore
      ? slice(recipeRunsQueue, 0, QueuedRecipeRunsConfig.MaxNoOfRecipeRunsInScroll)
      : recipeRunsQueue;

    setSlicedRecipeRunsQueue(() => queue);
  }, [recipeRunsQueue, showMore]);

  return { queuedRecipes, slicedRecipeRunsQueue, isRecipesRunning, hiddenRecipeRunsCount };
};

export default useHelpers;
