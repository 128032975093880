import React from "react";
import _, { map } from "lodash";
import { Alert } from "@material-ui/lab";
import { Card, Grid, makeStyles, TextField, Tooltip } from "@material-ui/core";

import DataAppEnvironment from "../CreateDataApp/DataAppEnvironment";
import DataAppMetaData from "../CreateDataApp/DataAppMetaData";
import DataAppModelControls from "../CreateDataApp/DataAppModelControls";
import DataAppSelectJob from "../CreateDataApp/DataAppSelectJob";
import DataAppSelectType from "../CreateDataApp/DataAppSelectType";
import DataAppUploadZip from "../CreateDataApp/DataAppUploadZip";
import EditDataAppRecipe from "./EditDataAppRecipe";
import PythonVersion from "../CreateDataApp/PythonVersion";
import SelectAskAIInputType from "../CreateDataApp/SelectAskAIInputType";
import SelectEnvironment from "src/pages/private/ProjectsModule/pages/PredictionService/components/SelectEnvironment";
import { DataAppAppTypeEnum } from "pages/DataApps/DataApps.type";
import { DescriptionInfo } from "projectsModule/utils/Projects.constants";
import { dataAppConfigFields } from "../CreateDataApp/CreateDataAppForm";
import {
  CreateDataAppRequestDtoDataAppTypeEnum,
  DataappAskAIConfigInputTypeEnum,
  DataappAskAIConfigLlmTypeEnum,
  DataAppDto,
  DataAppDtoDataAppTypeEnum,
  PublishAppTemplateRequestDtoAppTypeEnum
} from "@rapidcanvas/rc-api-core";
import PreviewImageSelector from "pages/Projects/ProjectSettings/PreviewImageSelector";
import { cdnImages, offlineImages } from "pages/DataApps/common/DataAppImages";

const useStyles = makeStyles({
  card: {
    minHeight: "calc(100vh - 202px)",
    overflowY: "auto",
    overflowX: "hidden",
    padding: "16px"
  },
  flex: {
    display: "flex",
    flexDirection: "column",
    gap: "32px"
  },
  link: {
    color: "#003656",
    "&:hover": {
      textDecoration: "none"
    }
  },

  icons: {
    fontSize: "1rem",
    marginBottom: "2px",
    color: "#8a8989"
  }
});

interface IProps {
  dataApp: DataAppDto;
  values: Record<string, any>;
  setValues: (values: Record<string, any>, name: string) => void;
  onInputChange: (e: any) => void;
  errorMsgs?: Record<string, string>;
}

const images = !!process.env.ENVIRONMENT ? map(cdnImages, (image) => `/${image}`) : offlineImages;

const EditDataAppDetails: React.FC<IProps> = (props) => {
  const { card, flex } = useStyles();
  const { dataApp, values, errorMsgs, onInputChange, setValues } = props;
  const { projectId, dataAppType, appTemplateType, askAIConfig } = dataApp;

  const handleInputValChange = (
    e: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>
  ) => {
    if (!e.target.name) {
      return;
    }

    setValues(
      {
        ...values,
        [e.target.name]: e.target.value,
        [dataAppConfigFields.llmType]:
          DataappAskAIConfigInputTypeEnum.RagFiles === e.target.value &&
          _.includes(
            [
              DataappAskAIConfigLlmTypeEnum.AzureOpenaiGpt4O,
              DataappAskAIConfigLlmTypeEnum.AnthropicClaude35Sonnet
            ],
            _.get(values, dataAppConfigFields.llmType)
          )
            ? DataappAskAIConfigLlmTypeEnum.OpenaiGpt4O
            : _.get(values, dataAppConfigFields.llmType)
      },
      e.target.name
    );
  };

  const handleEnvZipChange = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    onInputChange({
      target: { value: e.target.value, name: dataAppConfigFields.zipFile }
    } as React.ChangeEvent<HTMLInputElement>);
  };

  return (
    <Card className={card}>
      <Grid container spacing={2}>
        <Grid item xs={6} className={flex}>
          {dataAppType === DataAppDtoDataAppTypeEnum.Askai && askAIConfig && (
            <SelectAskAIInputType
              readOnly
              value={_.get(values, dataAppConfigFields.inputType)}
              onChange={onInputChange}
            />
          )}
          <Grid container direction="column">
            <Tooltip title={_.get(values, dataAppConfigFields.description) ?? ""}>
              <TextField
                fullWidth
                multiline
                maxRows={3}
                minRows={3}
                name={dataAppConfigFields.description}
                size="small"
                data-testid="dataAppDescription"
                value={_.get(values, dataAppConfigFields.description)}
                label="DataApp Description"
                variant="outlined"
                error={!!_.get(errorMsgs, dataAppConfigFields.description)}
                helperText={_.get(errorMsgs, dataAppConfigFields.description)}
                onChange={onInputChange}
              />
            </Tooltip>
            <span
              style={{
                fontSize: "12px",
                fontWeight: 400,
                fontStyle: "italic",
                opacity: 0.7
              }}>
              {DescriptionInfo}
            </span>
          </Grid>
          {dataAppType !== CreateDataAppRequestDtoDataAppTypeEnum.Custom &&
          appTemplateType === DataAppAppTypeEnum.REACTJS ? (
            _.get(values, dataAppConfigFields.inputType) ===
            DataappAskAIConfigInputTypeEnum.PredictionService ? (
              <Alert severity="info">
                Queries in AskAI within this DataApp utilize the environment linked to the
                Prediction Service being used
              </Alert>
            ) : (
              <SelectEnvironment
                tooltipInfoMsg="The chosen environment will be used for queries in AskAI within this DataApp"
                value={_.get(values, dataAppConfigFields.customEnvId)}
                name={dataAppConfigFields.customEnvId}
                label="Environment"
                projectId={dataApp.projectId}
                shouldSetDefaultValue
                onChange={onInputChange}
              />
            )
          ) : (
            <></>
          )}
          {dataAppType === CreateDataAppRequestDtoDataAppTypeEnum.RapidModel && (
            <EditDataAppRecipe dataApp={dataApp} />
          )}
          {dataAppType === CreateDataAppRequestDtoDataAppTypeEnum.Custom && (
            <Grid style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
              <DataAppEnvironment
                readOnly
                value={_.get(values, dataAppConfigFields.zipEnv)}
                onChange={handleInputValChange as any}
              />
              <DataAppUploadZip
                placeholder="Upload a New Zip File"
                value={_.get(values, dataAppConfigFields.zipFile)}
                onChange={handleEnvZipChange}
              />
              <DataAppSelectType
                readOnly
                value={_.get(values, dataAppConfigFields.appType)}
                onChange={handleInputValChange}
              />
              {_.get(values, dataAppConfigFields.appType) ===
                PublishAppTemplateRequestDtoAppTypeEnum.Streamlit && (
                <PythonVersion
                  value={_.get(values, dataAppConfigFields.pythonVersion)}
                  onChange={handleInputValChange as any}
                />
              )}
              <DataAppMetaData
                value={_.get(values, dataAppConfigFields.metadata)}
                onChange={onInputChange}
              />
            </Grid>
          )}

          {_.get(values, dataAppConfigFields.inputType) ===
            DataappAskAIConfigInputTypeEnum.JobCanvas && (
            <DataAppSelectJob
              readOnly
              style={{
                width: "175%",
                border: "1px solid #c7c7c7",
                padding: "16px",
                borderRadius: "4px"
              }}
              projectId={projectId!}
              value={_.get(values, dataAppConfigFields.projectRunId)}
              onChange={onInputChange}
            />
          )}
          {dataAppType === DataAppDtoDataAppTypeEnum.RapidModel && (
            <DataAppModelControls
              value={_.get(values, dataAppConfigFields.systemMessage) ?? ""}
              onChange={onInputChange}
            />
          )}
        </Grid>

        <Grid item xs={6} style={{ width: "auto" }}>
          <PreviewImageSelector
            images={images}
            noText
            onChange={(img: string) =>
              setValues(
                {
                  ...values,
                  [dataAppConfigFields.imageBase64]: img
                },
                dataAppConfigFields.imageBase64
              )
            }
            defaultImage={_.get(values, dataAppConfigFields.imageBase64)}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

export default EditDataAppDetails;
