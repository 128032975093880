import React, { useMemo } from "react";

// MUI
import Link from "@material-ui/core/Link";

// Icons
import { Delete } from "src/assets/icons/Delete";
import { PencilUnderlined } from "src/assets/icons/PencilUnderlined";

// Utils
import { dateFormat } from "utils/helpers/date.helpers";

// Components
import { LinkCellWithMenu } from "src/components/Table/Cells/LinkCellWithMenu/LinkCellWithMenu.component";
import Table from "src/components/custom/TableV8/Table";
import { CellContext } from "@tanstack/react-table";

type Props = {
  data: any;
  isReadOnly: boolean;
  onEdit: (segmentId?: string) => void;
  onDelete: (segmentId?: string) => void;
};

const SegmentsTable = (props: Props) => {
  const { data, isReadOnly, onEdit, onDelete } = props || {};

  const columns = useMemo(
    () => [
      {
        header: "Name",
        id: "Name",
        accessorKey: "name",
        meta: { isTooltip: false },
        cell: ({ row, getValue }: CellContext<any, unknown>) =>
          isReadOnly ? (
            <Link
              onClick={() => onEdit(row?.original?.id)}
              data-testid={`segments${row?.original?.id}EditAction`}>
              {getValue() as string}
            </Link>
          ) : (
            <LinkCellWithMenu
              linkText={getValue() as string}
              onClick={() => onEdit(row?.original?.id)}
              data-testid={`segments${row?.original?.id}MoreActions`}
              menuButtons={[
                {
                  icon: <PencilUnderlined />,
                  label: "Edit",
                  action: () => onEdit(row?.original?.id)
                },
                {
                  icon: <Delete />,
                  label: "Delete",
                  action: () => onDelete(row?.original?.id)
                }
              ]}
            />
          )
      },
      {
        header: "Description",
        id: "Description",
        accessorKey: "description"
      },
      {
        header: "Last updated on",
        id: "Last updated on",
        accessorKey: "updated",
        cell: ({ row, getValue }: CellContext<any, unknown>) => (
          <span data-testid={`segments${row?.original?.id}LastUpdatedDate`}>
            {dateFormat(getValue())}
          </span>
        )
      },
      {
        header: "Rows",
        id: "Rows",
        accessorKey: "rowLimit"
      }
    ],
    [isReadOnly]
  );

  return (
    <Table
      data={data}
      columns={columns}
      infoProps={{ emptyTableMessage: "No records" }}
      sortingProps={{ orderByDefault: "Last updated on" }}
      stylesProps={{ size: "small" }}
    />
  );
};

export default SegmentsTable;
