import React, { useState } from "react";
import _ from "lodash";

import DataappPrompts from "../CreateDataApp/DataappPrompts";
import EditDataAppDetails from "./EditDataAppDetails";
import EditDataAppSettings from "./EditDataAppSettings";
import { DataAppDto, DataAppDtoDataAppTypeEnum } from "@rapidcanvas/rc-api-core";
import { Tabs, Tab, TabPanel } from "src/components";
import { dataAppConfigFields } from "../CreateDataApp/CreateDataAppForm";

const TABS = {
  details: {
    key: "details",
    label: "Details"
  },
  settings: {
    key: "settings",
    label: "Settings"
  },
  starterPrompts: {
    key: "starterPrompts",
    label: "Starter Prompts"
  }
};

interface IProps {
  dataApp: DataAppDto;
  values: Record<string, any>;
  onInputChange: (e: any) => void;
  setValues: (values: Record<string, any>, name: string) => void;
  onHyperLinkError: (error: string) => void;
  errorMsgs?: Record<string, string>;
}

const EditDataAppTabs: React.FC<IProps> = (props) => {
  const { dataApp, values, errorMsgs, onInputChange, setValues, onHyperLinkError } = props;

  const [tab, setTab] = useState(TABS.details.key);

  return (
    <div>
      <Tabs
        value={tab}
        onChange={setTab}
        textColor="primary"
        indicatorColor="primary"
        variant="scrollable">
        <Tab value={TABS.details.key} label={TABS.details.label} />
        {dataApp.dataAppType === DataAppDtoDataAppTypeEnum.Askai && (
          <Tab value={TABS.settings.key} label={TABS.settings.label} />
        )}
        {dataApp.dataAppType === DataAppDtoDataAppTypeEnum.Askai && (
          <Tab value={TABS.starterPrompts.key} label={TABS.starterPrompts.label} />
        )}
      </Tabs>
      <div
        style={{
          padding: "16px 0px 0px 0px",
          height: "calc(100vh - 170px)",
          minHeight: "calc(100vh - 170px)",
          overflowY: "auto",
          overflowX: "hidden"
        }}>
        <TabPanel index={TABS.details.key} value={tab}>
          <EditDataAppDetails
            errorMsgs={errorMsgs}
            dataApp={dataApp}
            values={values}
            onInputChange={onInputChange}
            setValues={setValues}
          />
        </TabPanel>
        {dataApp.dataAppType === DataAppDtoDataAppTypeEnum.Askai && (
          <TabPanel index={TABS.settings.key} value={tab}>
            <EditDataAppSettings
              dataAppType={dataApp.dataAppType}
              appTemplateType={dataApp.appTemplateType}
              values={values}
              onInputChange={onInputChange}
              setValues={setValues}
              onHyperLinkError={onHyperLinkError}
            />
          </TabPanel>
        )}
        {dataApp.dataAppType === DataAppDtoDataAppTypeEnum.Askai && (
          <TabPanel index={TABS.starterPrompts.key} value={tab}>
            <DataappPrompts
              suggestionPrompts={_.get(values, dataAppConfigFields.suggestionPrompts)}
              onChange={onInputChange}
            />
          </TabPanel>
        )}
      </div>
    </div>
  );
};

export default EditDataAppTabs;
