import React from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { generatePath, Link, useParams } from "react-router-dom";
import { get } from "lodash";

import EditName from "layout/NavBars/components/SubTopNavBar/EditName";
import { RenderText } from "components/custom";
import { WebPaths } from "routing/routes";
import { useGetProject } from "hooks/api";

interface IProps {
  dataAppName: string;
  projectId: string;
  onSave: (newName: string) => void;
  shouldResetDataAppName?: boolean;
}

const FONT_SIZE = 14;

const SubTopNavBarBreadcrumbs: React.FC<IProps> = (props) => {
  const { dataAppName, shouldResetDataAppName, projectId, onSave } = props;

  const params = useParams();
  const isProjectDataApps = !!get(params, "projectId");
  const { data } = useGetProject({
    projectId,
    enabled: !!projectId && isProjectDataApps
  });

  const breadcrumbs: React.ReactNode[] = [];

  if (isProjectDataApps && data) {
    breadcrumbs.push(
      !!projectId ? (
        <Link key="projects" to={generatePath(`${WebPaths.Dag}/canvas`, { projectId })}>
          <RenderText>{data?.name || "Project"}</RenderText>
        </Link>
      ) : (
        <RenderText key="project-name" color="textSecondary">
          {data?.name || "Project"}
        </RenderText>
      )
    );
  }

  breadcrumbs.push(
    <Link
      key="dataApps"
      to={
        isProjectDataApps
          ? generatePath(WebPaths.ProjectDataApp, { projectId, dataAppName: "" })
          : WebPaths.Dataapps
      }
      data-testid="dataappBreadcrumb">
      <RenderText>DataApps</RenderText>
    </Link>
  );

  breadcrumbs.push(
    <EditName
      key="edit-name"
      data-testid="dataAppCreateNameBreadCrumb"
      fieldNameLabel="DataApp name"
      inputValue={dataAppName}
      onSave={onSave}
      isResetName={shouldResetDataAppName}
      containerProps={{
        style: {
          width: ((dataAppName || "")?.length + 1) * (FONT_SIZE - 4) + 16
        }
      }}
    />
  );

  return (
    <Breadcrumbs>
      {breadcrumbs?.map((eachBreadcrumb: React.ReactNode) => eachBreadcrumb)}
    </Breadcrumbs>
  );
};

export default SubTopNavBarBreadcrumbs;
