import {
  PublishAppTemplateRequestDto,
  PublishAppTemplateRequestDtoAppTypeEnum,
  PublishAppTemplateRequestDtoSourceEnum,
  SignedUrlRequestDtoSignedUrlObjectTypeEnum
} from "@rapidcanvas/rc-api-core";
import { useMutation, UseMutationResult } from "@tanstack/react-query";
import axios from "axios";
import _ from "lodash";

import api from "services/AxiosClient/AxiosClient";

interface IProps {
  payload: PublishAppTemplateRequestDto;
  file: File;
  source: PublishAppTemplateRequestDtoSourceEnum;
  appType: PublishAppTemplateRequestDtoAppTypeEnum;
}

const useUploadDataAppZip = (): UseMutationResult<string | null, unknown, IProps, unknown> => {
  return useMutation({
    mutationFn: async ({ payload, file, source, appType }) => {
      const newResponse = await api.fetchResponse(
        async () => await api.AppTemplateControllerApi.createAppTemplate(payload)
      );

      const data = await api.fetchResponse(
        async () =>
          await api.SignedUrlControllerApi.uploadFileUrl1({
            fileName: `${newResponse.name}.zip`,
            signedUrlObjectType:
              appType === PublishAppTemplateRequestDtoAppTypeEnum.Streamlit
                ? SignedUrlRequestDtoSignedUrlObjectTypeEnum.AppTemplateStreamlit
                : SignedUrlRequestDtoSignedUrlObjectTypeEnum.AppTemplateReactjs,
            metadata: {
              appType: _.lowerCase(appType),
              SOURCE: source
            }
          })
      );

      await axios.put(data.signedUrl!, file, {
        headers: data.headers
      });

      return newResponse.id ?? null;
    }
  });
};

export default useUploadDataAppZip;
