import Card from "@material-ui/core/Card";
import React, { useMemo, useState } from "react";
import { Grid, Tooltip, Typography, makeStyles, useTheme } from "@material-ui/core";
import { Link, useNavigate } from "react-router-dom";

import OverflowTooltip from "components/OverflowTooltip";
import styles from "./DataSourceTile.module.scss";
import { MoreOptions } from "src/components/MoreOptions/MoreOptions";
import { dataSourcesTypes } from "./utils/DataSources.constants";
import { dateFormat } from "utils/helpers/date.helpers";
import { CalendarToday, Person } from "@material-ui/icons";
import { StorageIcon } from "icons/NewUX/StorageIcon";
import RocketIcon from "icons/NewUX/RocketIcon";
import { map } from "lodash";
import { getStatusType } from "connectorsModule/utils/fivetran.helpers";
import { FivetranStatus } from "../private/ProjectsModule/pages/Dataset/utils/Dataset.constants";
import { Alert, Color } from "@material-ui/lab";

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: 12,
    "& .moreIcon": {
      display: "none"
    },
    "&:hover .moreIcon": {
      display: "block"
    }
  },
  titleText: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    width: "90%",
    display: "block",
    overflow: "hidden",
    color: "#003656"
  },
  containerFirstDataItem: {
    paddingTop: "8px"
  }
}));

const defaultOptions = [
  {
    label: "Create",
    click: () => {}
  },
  {
    label: "Edit",
    click: () => {}
  },
  {
    label: "Delete",
    click: () => {}
  }
];

export const DataSourceTile = ({ data, options = defaultOptions }: $TSFixMe) => {
  const { id, title, link, source, sourceLink, lastUpdate, updatedBy, metadata, status } = data;
  const theme = useTheme();

  const [isHovered, setIsHovered] = useState(false);
  const classes = useStyles();
  const navigate = useNavigate();

  const getDataSourceType = (): $TSFixMe => {
    return (
      dataSourcesTypes?.find(
        (eachDataSourceType: $TSFixMe) => eachDataSourceType?.name === source
      ) || {}
    );
  };

  const cardTitle = (
    <Typography component="span" variant="h6" className={classes.titleText}>
      <OverflowTooltip style={{ maxWidth: 300, whiteSpace: "nowrap" }} value={title} />
    </Typography>
  );

  const statusType = useMemo(() => getStatusType(status), [status]);

  return (
    <Card
      className={classes.root}
      style={{
        cursor: isHovered ? "pointer" : undefined,
        background: isHovered ? "#F4F4FF" : undefined,
        border: isHovered ? "1px solid #4646B5" : undefined
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => navigate(`${link}/${id}`)}>
      <Grid
        container
        alignItems="center"
        style={{ padding: theme.spacing(2) }}
        justifyContent="space-around">
        <Grid item className={styles.cardImg} data-testid="connectorImg">
          {sourceLink ? (
            <img src={sourceLink} alt={title} width={90} height={90} />
          ) : (
            getDataSourceType()?.image
          )}
        </Grid>
        <Grid container justifyContent="space-between" className={styles.infoCardTitle}>
          <Grid container>
            <Grid item md={11} xs={10}>
              <Link
                data-testid="connectorTitle"
                to={`${link}/${id}`}
                className={styles.title}
                onClick={(e) => e.stopPropagation()}>
                {cardTitle}
              </Link>
            </Grid>
            <Grid item md={1} xs={2} className="moreIcon">
              <MoreOptions options={options} />
            </Grid>
          </Grid>
          <Grid container wrap="nowrap" className={styles.dataContainer}>
            <Grid item xs={10} className={styles.details}>
              <Grid className={classes.containerFirstDataItem}>
                <Grid container alignItems="flex-end" style={{ columnGap: 10 }}>
                  <Grid item>
                    <Tooltip title="Type" style={{ marginLeft: "3px" }}>
                      <Typography component="span" variant="body2" color="textSecondary">
                        <StorageIcon />
                      </Typography>
                    </Tooltip>
                  </Grid>
                  <Grid item style={{ maxWidth: 215 }}>
                    <OverflowTooltip
                      style={{ maxWidth: 300, whiteSpace: "nowrap" }}
                      value={
                        <Typography component="span" variant="body2" data-testid="dataAppUpdater">
                          {source}
                        </Typography>
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid className={classes.containerFirstDataItem}>
                <Grid container alignItems="flex-end" style={{ columnGap: 10 }}>
                  <Grid item>
                    <Tooltip title="Project">
                      <Typography
                        component="span"
                        variant="body2"
                        style={{ marginLeft: "3px" }}
                        color="textSecondary">
                        <RocketIcon
                          height={16}
                          width={16}
                          fill="currentColor"
                          color="currentColor"
                        />
                      </Typography>
                    </Tooltip>
                  </Grid>
                  <Grid item style={{ maxWidth: 290 }}>
                    <Typography component="span" variant="body2" data-testid="dataAppProject">
                      {map(metadata, (value, key) => `${value} ${key}`)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid className={classes.containerFirstDataItem}>
                <Grid container alignItems="flex-end" style={{ columnGap: 10 }}>
                  <Grid item>
                    <Tooltip title="Updated by">
                      <Person style={{ fontSize: 20 }} />
                    </Tooltip>
                  </Grid>
                  <Grid item style={{ maxWidth: 215 }}>
                    <OverflowTooltip
                      style={{ maxWidth: 300, whiteSpace: "nowrap" }}
                      value={
                        <Typography component="span" variant="body2" data-testid="dataAppUpdater">
                          {updatedBy}
                        </Typography>
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid className={classes.containerFirstDataItem}>
                <Grid container alignItems="flex-end" style={{ columnGap: 10 }}>
                  <Grid item>
                    <Tooltip title="Last Updated on">
                      <CalendarToday style={{ fontSize: 20 }} />
                    </Tooltip>
                  </Grid>
                  <Grid item style={{ maxWidth: 215 }}>
                    <Typography component="span" variant="body2" data-testid="dataAppStoppedBy">
                      {dateFormat(lastUpdate)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Tooltip
        title={
          status === FivetranStatus.inActive
            ? "A data connector in an inactive state is not accessible for importing data."
            : status === FivetranStatus.syncPaused
              ? "There was an issue during automatic syncing. Please perform manual sync to maintain data consistency."
              : ""
        }>
        <Alert
          style={{ padding: "0px 16px", marginTop: "8px", textTransform: "capitalize" }}
          severity={statusType as Color}
          icon={false}
          data-testid="dataAppStatusAlert">
          {status}
        </Alert>
      </Tooltip>
    </Card>
  );
};

DataSourceTile.defaultProps = {
  buttonProps: null
};
