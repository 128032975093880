/* eslint-disable no-unused-vars */
import { RecipeRunData } from "@rapidcanvas/rc-api-core";
import React from "react";
import {
  DatasetCustomColumnsProps,
  Entities,
  EntityFeaturesResponse,
  Recipe,
  Scenario
} from "src/types";

type MyContextData = {
  jobData?: $TSFixMe;
  jobRunData?: $TSFixMe;
  recipe: Recipe | null;
  scenarioData: Scenario | null;
  setScenarioData: (scenario: Scenario) => void;
  isScenarioLoading: boolean;
  recipeDisplayName: string | null;
  setSelectedInputDatasets: (entities: Entities) => void;
  selectedInputDatasets: Entities;
  setRecipeDisplayName: (displayName: string) => void;
  inputDatasets: Entities;
  setInputDatasets: (entities: Entities) => void;
  setSelectedTransform: (transformData: $TSFixMe) => void;
  problemType: string | null;
  setProblemType: (val: string) => void;
  values: $TSFixMe;
  handleInputChange: (val: $TSFixMe) => void;
  errors: $TSFixMe;
  handleSave: () => void;
  handleRun: () => void;
  handleUpdateRecipeRunTimeout: ({ timeout }: { timeout: number }) => void;
  handleUpdateRecipeName: ({
    recipeName,
    onError
  }: {
    recipeName: string;
    onError: () => void;
  }) => void;
  templateData: $TSFixMe;
  isSaveDisabled: boolean;
  isSaveInProgress: boolean;
  isAddRecipeToQueue: boolean;
  isAddingRecipeToQueue: boolean;
  isRunDisabled: boolean;
  isRunInProgress: boolean;
  isRecipeTimeoutUpdating: boolean;
  saveDisabledMessage: string;
  runDisabledMessage: string;
  isRecipeRunSuccessful: boolean | null;
  isGetEntityFeaturesLoading: boolean;
  entityFeaturesMap: {
    [name: string]: EntityFeaturesResponse;
  } | null;
  setEntityFeaturesMap: (map: { [name: string]: EntityFeaturesResponse }) => void;
  isEntityLoading: boolean;
  currentEntityData: $TSFixMe;
  getDatasetCustomColumnsData: ({ columnNames }: DatasetCustomColumnsProps) => void;
  setIsRecipeRunSuccessful: (value: null | boolean) => void;
  allProblemTypes: any;
  isFetchingProblemTypes: boolean;
  isExperimental: boolean;
  setIsExperimental: (isExperimental: boolean) => void;
  setActualProblemType: (problemType: string) => void;
  suggestedTemplateName: string;
  setValues: any;
  numericColumns: any[];
  isNumericTarget: boolean;
  allColumns: any[];
  columnsLoading: boolean;
  recipeRunInQueue: RecipeRunData | undefined;
};

//@ts-expect-error
export const AutoMLRecipeContext = React.createContext<MyContextData>();
