import React, { useMemo, useRef, useState } from "react";
import _ from "lodash";
import { Box, Grid, IconButton, TextField } from "@material-ui/core";
import { PixelCrop } from "react-image-crop";
import { useIsFetching } from "@tanstack/react-query";

import NewModal from "src/components/Modal/NewModal";
import { CustomImage } from "src/pages/Projects/ProjectSettings/CustomImage/CustomImage";
import { DataAppType } from "../DataApps.type";
import { DropImageBox } from "src/pages/private/ProjectsModule/pages/ProjectSettings/components/CustomImage/DropImageBox";
import { QUERY_KEY_DATAAPPS_ACROSS_TENANTS } from "src/hooks/api/dataapps/useGetDataAppAcrossTenants";
import { UpdateDataAppRequestDto } from "@rapidcanvas/rc-api-core";
import { useUpdateDataAppMutation } from "src/hooks/api";
import { Close } from "@material-ui/icons";

interface IProps {
  open: boolean;
  dataApp: DataAppType;
  onClose: () => void;
  onSuccess: () => void;
}

const DataAppBrandingModal: React.FC<IProps> = (props) => {
  const { dataApp, open, onClose, onSuccess } = props;

  const imgRef = useRef<HTMLImageElement>(null);
  const [name, setName] = useState(dataApp.title ?? "");
  const [imgSrc, setImgSrc] = useState<string>("");
  const isFetching = useIsFetching({ queryKey: [QUERY_KEY_DATAAPPS_ACROSS_TENANTS] });
  const [currentImgSrc, setCurrentImgSrc] = useState(() => {
    const img = dataApp.brandLogo?.split(";base64,")?.pop();

    if (img) {
      return `data:image/jpeg;base64,${img}`;
    }

    return "";
  });
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();

  const updateDataApp = useUpdateDataAppMutation();

  const titleErrorMsg = useMemo(() => {
    if (!name) {
      return "";
    }

    if (_.size(name) < 3 || _.size(name) > 64) {
      return "DataApp Title needs to be between 3 and 64 characters";
    }

    if (
      !/^(?:[a-zA-Z0-9 _-]*)?$/.test(name) ||
      /^(?:[_ -]*)?$/.test(name) ||
      /^(?:[_-]*)?$/.test(name.substring(0, 1))
    ) {
      return "Title contains invalid characters. It needs to start with alphanumerics and can only contain alphanumerics, spaces, underscores and dashes.";
    }

    return "";
  }, [name]);

  const handleFilesUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setImgSrc(reader.result?.toString() || ""));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const getCroppedImage = () => {
    if (!imgRef.current || !completedCrop) {
      throw new Error("Image or crop not defined");
    }
    const image = imgRef.current;
    const crop = completedCrop;

    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    if (!ctx) {
      throw new Error("Unable to get canvas context");
    }
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return canvas.toDataURL("image/jpeg");
  };

  const handleSubmit = () => {
    const base64Image = imgRef.current
      ? getCroppedImage()
      : currentImgSrc
        ? currentImgSrc
        : undefined;
    updateDataApp.mutate(
      {
        id: dataApp.id,
        payload: { ...dataApp, brandLogo: base64Image, title: name } as UpdateDataAppRequestDto
      },
      {
        onSuccess: () => {
          onClose();
          onSuccess();
        }
      }
    );
  };

  const disabled = !dataApp.title && !dataApp.brandLogo && !imgRef.current && !name;

  return (
    <NewModal
      header="Branding"
      open={open}
      loading={updateDataApp.isLoading || !!isFetching}
      data-testid="predict-job-dataset-upload-modal"
      keepMounted={false}
      width="65%"
      background="#eeedeb"
      submitTooltip={disabled ? "Please add title and/or image to enable this action" : ""}
      submitDisabled={!!titleErrorMsg || disabled}
      submitButtonLabel="Save"
      cancelButtonLabel="Cancel"
      onFinish={handleSubmit}
      onClose={onClose}>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          padding: "8px",
          overflow: "auto"
        }}>
        <TextField
          variant="outlined"
          size="small"
          style={{ background: "#fff", width: "50%" }}
          label="DataApp Title"
          value={name}
          error={!!titleErrorMsg}
          helperText={titleErrorMsg}
          data-testid="dataAppTitle"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setName(event.target.value);
          }}
        />
        <Grid
          container
          style={{ minHeight: "250px", maxHeight: "calc(100vh - 250px)", width: "inherit" }}>
          {currentImgSrc && !imgSrc ? (
            <Grid
              container
              direction="row"
              style={{
                flexWrap: "nowrap",
                gap: "16px",
                padding: "16px 0px"
              }}>
              <Grid
                item
                style={{
                  borderRadius: "12px",
                  width: "244px",
                  height: "138px"
                }}>
                <DropImageBox onFilesUpload={handleFilesUpload} />
              </Grid>
              <Grid
                item
                style={{
                  height: "138px",
                  width: "244px",
                  borderRadius: "12px",
                  position: "relative"
                }}>
                <img
                  src={currentImgSrc}
                  style={{ borderRadius: "12px" }}
                  height="100%"
                  width="100%"
                  alt="Brand Logo"
                />
                <IconButton
                  aria-label="close"
                  color="primary"
                  size="small"
                  style={{ position: "absolute", top: 0, right: 0 }}
                  onClick={() => setCurrentImgSrc("")}>
                  <Close style={{ height: "20px", width: "20px" }} />
                </IconButton>
              </Grid>
            </Grid>
          ) : (
            <CustomImage
              aspect={2}
              setCompletedCrop={setCompletedCrop}
              imgRef={imgRef}
              imgSrc={imgSrc}
            />
          )}
        </Grid>
      </Box>
    </NewModal>
  );
};

export default DataAppBrandingModal;
