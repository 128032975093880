import { RefObject, UIEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { debounce } from "lodash";

import { ServerSideRenderingProps } from "../Table.types";

type Props = {
  maxHeight?: string;
  tableContainerRef: RefObject<HTMLDivElement> | null;
  tableRef: RefObject<HTMLTableElement> | null;
  rowCount?: number;
  serverSideRenderingProps?: ServerSideRenderingProps;
};

const useHook = (props: Props) => {
  const { maxHeight, tableContainerRef, tableRef, rowCount, serverSideRenderingProps } =
    props || {};
  const {
    isLoading,
    fetchNextPage,
    totalNoOfRowsAtServerSide = 0,
    totalNoOfRowsFetched = 0
  } = serverSideRenderingProps || {};

  const totalNoOfRowsFetchedPrev = useRef(0);

  // Retain scrollbar always - STARTS >>
  const [tableContainerMaxHeight, setTableContainerMaxHeight] = useState(maxHeight);

  const maxHeightInPx = useMemo(() => {
    if (!maxHeight) return 0;

    // Create a temporary element to evaluate the expression
    const tempElement = document.createElement("div");
    tempElement.style.position = "absolute";
    tempElement.style.visibility = "hidden";
    tempElement.style.height = maxHeight;

    // Append to the body to calculate its computed height
    document.body.appendChild(tempElement);
    const result = window.getComputedStyle(tempElement).height;
    document.body.removeChild(tempElement);

    return parseFloat(result);
  }, [maxHeight]);

  const revertCalcExpression = (computedHeightPx: number) =>
    // window.innerHeight is the viewport height in pixels.
    `calc(100vh - ${window.innerHeight - computedHeightPx}px)`;

  // Adjust Table height to ensure scrollbar
  const adjustTableContainerMaxHeight = useCallback(
    debounce(() => {
      if (!!tableContainerRef?.current && !!tableRef?.current) {
        setTableContainerMaxHeight(maxHeight);

        const tableContainerHeight = tableContainerRef?.current?.clientHeight;
        const tableHeight = tableRef?.current?.scrollHeight;

        if (
          tableHeight <= tableContainerHeight &&
          totalNoOfRowsFetchedPrev.current !== totalNoOfRowsFetched &&
          totalNoOfRowsFetched < totalNoOfRowsAtServerSide
        ) {
          setTableContainerMaxHeight(revertCalcExpression(tableHeight - 5));
        }
      }
    }, 300),
    [
      maxHeight,
      tableContainerRef?.current,
      tableRef?.current,
      totalNoOfRowsFetchedPrev.current,
      totalNoOfRowsFetched,
      totalNoOfRowsAtServerSide
    ]
  );

  useEffect(() => {
    adjustTableContainerMaxHeight(); // Adjust on mount

    window.addEventListener("resize", adjustTableContainerMaxHeight); // Re-adjust on resize

    return () => {
      window.removeEventListener("resize", adjustTableContainerMaxHeight);
    };
  }, [rowCount, totalNoOfRowsFetched, totalNoOfRowsAtServerSide, maxHeightInPx]);
  // ENDS - Retain scrollbar always

  // Server-side rendering - STARTS >>
  // Called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
  const fetchMoreOnBottomReached = useCallback(
    // Debouncing to not to call this method not more than once in 300 milliseconds.
    debounce((containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        const isVerticalScroll = scrollHeight > clientHeight && scrollTop > 0;

        // Once the user has scrolled within 500px of the bottom of the table, fetch more data if we can
        if (
          !!isVerticalScroll &&
          scrollHeight - scrollTop - clientHeight < 500 &&
          !isLoading &&
          totalNoOfRowsFetchedPrev.current !== totalNoOfRowsFetched &&
          totalNoOfRowsFetched < totalNoOfRowsAtServerSide
        ) {
          totalNoOfRowsFetchedPrev.current = totalNoOfRowsFetched;
          fetchNextPage?.();
        }
      }
    }, 300),
    [
      fetchNextPage,
      isLoading,
      rowCount,
      totalNoOfRowsFetchedPrev.current,
      totalNoOfRowsFetched,
      totalNoOfRowsAtServerSide
    ]
  );

  const onTableContainerScrolled = (event: UIEvent<HTMLDivElement>) =>
    fetchMoreOnBottomReached(event?.target as HTMLDivElement);

  // A check on mount and after a fetch to see if the table is already scrolled to the bottom and immediately needs to fetch more data
  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef?.current);
  }, [fetchMoreOnBottomReached]);
  // ENDS - Server-side rendering

  return { tableContainerMaxHeight, onTableContainerScrolled };
};

export default useHook;
