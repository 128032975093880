import { Button, Grid, makeStyles, TextField, Tooltip, Typography } from "@material-ui/core";
import { isEmpty, keyBy, map, omit, size, values } from "lodash";
import React, { useMemo, useState } from "react";
import Prompt from "./Prompt";
import { dataAppConfigFields } from "./CreateDataAppForm";
import { v4 as uuidv4 } from "uuid";

interface IProps {
  suggestionPrompts: string[];
  onChange: (e: any) => void;
}

export const useStyles = makeStyles({
  textArea: {
    background: "#62626217"
  },
  multiLine: {
    paddingTop: "12px"
  },
  promptGrid: {
    "&::-webkit-scrollbar": {
      "-webkit-appearance": "none",
      width: "6px",
      height: "6px"
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "6px",
      backgroundColor: "#7C7C7C",
      "-webkit-box-shadow": "0 0 1px rgba(255, 255, 255, 0.5)"
    }
  }
});

const MAX_CHAR_LIMIT = 500;
const MAX_NO_PROMPTS = 50;

const DataappPrompts: React.FC<IProps> = ({ suggestionPrompts, onChange }) => {
  const [value, setValue] = useState("");
  const [selectedPrompts, setSelectedPrompts] = useState<string[]>([]);
  const prompts = useMemo(() => keyBy(suggestionPrompts, () => uuidv4()), [suggestionPrompts]);

  const classes = useStyles();

  const [error, setError] = useState("");

  const handleAdd = () => {
    onChange({
      target: { value: [...values(prompts), value], name: dataAppConfigFields.suggestionPrompts }
    });

    setValue("");
  };

  const handleDelete = (id: string) => {
    onChange({
      target: { value: values(omit(prompts, [id])), name: dataAppConfigFields.suggestionPrompts }
    });
  };

  const handleMultipleDelete = () => {
    onChange({
      target: {
        value: values(omit(prompts, selectedPrompts)),
        name: dataAppConfigFields.suggestionPrompts
      }
    });
    setSelectedPrompts([]);
  };

  const handleEdit = (id: string, value: string) => {
    onChange({
      target: {
        value: values({ ...prompts, [id]: value }),
        name: dataAppConfigFields.suggestionPrompts
      }
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = event.target.value.trim();
    if (size(value) > MAX_CHAR_LIMIT) {
      setError(`Max ${MAX_CHAR_LIMIT} chars supported.`);
    }
    if (size(value) < 3) {
      setError("Min 3 chars are required.");
    }
    if (size(value) < MAX_CHAR_LIMIT + 1 && size(value) > 2) {
      setError("");
    }
    setValue(event.target.value);
  };
  return (
    <Grid
      container
      direction="column"
      style={{
        gap: "16px",
        borderRadius: "8px",
        background: "none",
        maxWidth: "1200px"
      }}>
      <Grid
        container
        direction="column"
        style={{
          border: "1px solid #d1d2d3",
          borderRadius: "8px",
          background: "#FFFFFF"
        }}>
        <Grid container direction="column" style={{ padding: "16px", width: "100%" }}>
          <Typography style={{ paddingBottom: "8px" }}>Create New Prompt</Typography>
          <Grid style={{ paddingTop: "10px" }}>
            <TextField
              fullWidth
              multiline
              minRows={3}
              maxRows={3}
              value={value}
              onChange={handleChange}
              variant="filled"
              placeholder="Type your prompt here"
              error={!!error}
              InputProps={{
                classes: {
                  root: classes.textArea,
                  focused: classes.textArea,
                  multiline: classes.multiLine
                }
              }}
              helperText={
                !!error ? (
                  error
                ) : (
                  <span
                    style={{
                      marginLeft: "auto",
                      textAlign: "right",
                      display: "block"
                    }}>{`${value.length}/${MAX_CHAR_LIMIT} char`}</span>
                )
              }
            />
          </Grid>
          <Grid container justifyContent="flex-end" style={{ padding: "10px 0" }}>
            <Tooltip
              title={
                !value
                  ? "Please type Prompt"
                  : size(prompts) > MAX_NO_PROMPTS - 1
                    ? `Maximum ${MAX_NO_PROMPTS} prompts allowed`
                    : ""
              }>
              <span>
                <Button
                  size="small"
                  variant="outlined"
                  disabled={!value || size(prompts) > MAX_NO_PROMPTS - 1 || !!error}
                  color="primary"
                  onClick={handleAdd}>
                  + Add Prompt
                </Button>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      {!isEmpty(prompts) && (
        <Grid
          container
          direction="column"
          style={{
            border: "1px solid #d1d2d3",
            borderRadius: "8px",
            background: "#FFFFFF"
          }}>
          <Grid container direction="column" style={{ padding: "16px", width: "100%" }}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              style={{
                paddingBottom: "8px",
                height: "40px"
              }}>
              <Typography variant="subtitle2">
                {!isEmpty(selectedPrompts)
                  ? `Selected ${size(selectedPrompts)} Starter ${size(selectedPrompts) === 1 ? "Prompt" : "Prompts"}`
                  : `Total ${size(prompts)} Starter ${size(prompts) === 1 ? "Prompt" : "Prompts"}`}
              </Typography>
              {!isEmpty(selectedPrompts) && (
                <Button size="small" variant="outlined" onClick={handleMultipleDelete}>
                  Delete
                </Button>
              )}
            </Grid>
            <Grid
              container
              direction="column"
              className={classes.promptGrid}
              style={{ gap: "15px", maxHeight: "340px", overflow: "auto", flexWrap: "nowrap" }}>
              {map(prompts, (item, id) => (
                <Prompt
                  id={id}
                  key={id}
                  item={item}
                  selectedPrompts={selectedPrompts}
                  setSelectedPrompts={setSelectedPrompts}
                  handleDelete={handleDelete}
                  handleEdit={handleEdit}
                />
              ))}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default DataappPrompts;
