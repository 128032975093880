export enum PublishJobsFormFields {
  Jobs = "schedulers"
}

export const PublishJobsFormFieldsNameMapping = {
  [PublishJobsFormFields.Jobs]: "Select Schedulers"
};

export enum PublishJobsCloseModalPromptDetails {
  title = "Confirm",

  messageLine1 = "If you leave you will lose any unsaved changes.",
  messageLine2 = "Do you really want to close this window?",

  cancelLabel = "No"
}

export enum PublishJobsModalHelperText {
  title = "Republish Canvas to Schedulers",
  predictionTitle = "Republish Model Flow to Prediction Scheduler",
  Info = "This will replace the existing scheduler canvas with the latest project canvas. Are you sure?",
  predictionInfo = "This will republish the latest model flow to this Prediction Scheduler. Are you sure?",
  cancelLabel = "No",
  submitLabel = "Yes, Republish"
}
