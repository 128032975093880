import React, { useMemo, useState } from "react";

// Packages
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { useIsMutating } from "@tanstack/react-query";
import { includes, isEmpty, size, toLower, toUpper } from "lodash";

// MUI
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ExportIcon } from "icons/NewUX/ExportIcon";

// Icons
import AddIcon from "@material-ui/icons/Add";
import CachedIcon from "@material-ui/icons/Cached";
import { EyeIcon } from "icons/NewUX";
import { TrashIcon } from "icons/NewUX/TrashIcon";
import { RapidModelRecipeIcon } from "icons/NewUX/RapidModelRecipeIcon";
import ApiConnectorIcon from "icons/NewUX/ApiConnectorIcon";
import { TemplateRecipeIcon } from "icons/NewUX/TemplateRecipeIcon";
import { DatasetIcon } from "icons/NewUX/DatasetIcon";

// Open API
import { EntityDtoStatusEnum } from "@rapidcanvas/rc-api-core";

// Utils
import { areAllKeysPresentAndNotNil } from "src/utils/helpers";
import { WebPaths } from "src/routing/routes";
import { useAccessControlContext } from "src/routing/PrivateRoute/accessControlContext/useAccessControlContext";
import {
  RecipeTypeNames,
  FileStatuses,
  ViewFileWebPathQueryParameters,
  DatasetWebPathQueryParameters
} from "src/pages/private/ProjectsModule/utils";

// Hooks
import useEntityDetails from "src/hooks/api/entities/useEntityDetails";
import { useDownloadTextInput } from "src/pages/private/ProjectsModule/hooks";
import { UseReloadDatasetsQueryKeys } from "src/hooks/api/entities/useReloadDatasets";

// Components
import { ConfirmUpdateTextInput } from "src/pages/private/ProjectsModule/components";
import DeleteNodeModal, { psMessage } from "src/pages/ViewData/DeleteNodeModal/DeleteNodeModal";
import NodeActionWrapper from "../NodeActionWrapper";
import ReloadDatasetModal from "src/pages/ViewData/ViewDataData/ReloadDatasetModal";
import DeleteNodesModal from "../../DeleteNodes/DeleteNodesModal";

// Types
import { NodeData } from "src/types";

// Constants
import fileTypes from "constants/fileTypes.constants";
import { thirdPartyTypeName } from "src/pages/DataSources/utils/DataSources.constants";

// Styles
import { useContextStyles } from "../useContextMenuStyles";

type Props = {
  open: boolean;
  onAddDestination: () => void;
  closeContextMenu: () => void;
  isDefaultScenario: boolean;
  data: NodeData;
  selectedNodes: NodeData[];
  resetSelectedNodes: () => void;
  children?: React.ReactNode;
};

const ContextMenu = (props: Props) => {
  const {
    onAddDestination,
    closeContextMenu,
    isDefaultScenario,
    data,
    open,
    selectedNodes,
    resetSelectedNodes,
    children
  } = props || {};

  const navigate = useNavigate();
  const { projectId, scenarioId } = useParams();

  const classes = useContextStyles();

  const { canAddStandardRecipe } = useAccessControlContext();
  // Need to skip rapid-model recipe for time being.
  const canAddRapidModelRecipe = false;

  const [showConfirmUpdateTextInputModal, setShowConfirmUpdateTextInputModal] = useState(false);
  const [showConfirmReloadTextInputModal, setShowConfirmReloadTextInputModal] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [showConfirmDeleteNodesModal, setShowConfirmDeleteNodesModal] = useState(false);

  const { data: entityDetails } = useEntityDetails(
    data.itemId,
    data.scenarioId,
    data.jobProps?.jobRunId,
    {
      enabled: !!data.itemId && open,
      refetchOnMount: true
    }
  );

  const isBuilt = useMemo(() => ["BUILT"].includes(data?.status), [data?.status]);

  const { download, isDownloading } = useDownloadTextInput({
    projectId: data?.projectId,
    isJobCanvas: !!data?.isJobCanvas,
    jobProps: data?.jobProps,
    data: {
      scenarioId: data?.scenarioId,
      datasetId: data?.itemId,
      fileType: toUpper(entityDetails?.fileExtension || ""),
      fileName: data?.displayName || data?.name
    }
  });

  const onView = () => {
    if (!areAllKeysPresentAndNotNil(data, ["projectId", "scenarioId", "id"])) {
      return;
    }

    let path = generatePath(
      `${WebPaths.ViewFile}/?${ViewFileWebPathQueryParameters.Tab}=${ViewFileWebPathQueryParameters.Data}`,
      {
        projectId: data?.projectId,
        scenarioId: data?.scenarioId,
        fileId: data?.id
      }
    );

    if (!!data?.jobProps) {
      if (
        !data?.isJobCanvasPath ||
        !areAllKeysPresentAndNotNil(data?.jobProps, ["jobId", "jobRunId"])
      ) {
        return;
      }

      path = generatePath(
        `${WebPaths.JobRoutes}${WebPaths.JobFileRoutes}/?${ViewFileWebPathQueryParameters.Tab}=${ViewFileWebPathQueryParameters.Data}`,
        {
          projectId: data?.projectId,
          jobId: data?.jobProps?.jobId,
          scenarioId: data?.scenarioId,
          jobRunId: data?.jobProps?.jobRunId,
          fileId: data?.id
        }
      );
    }

    !!path && navigate(path);
  };

  const isAddRecipeDisabled = useMemo(() => {
    return !data?.status || !includes([FileStatuses.Built], data?.status);
  }, [data?.status]);

  const isNavigableToRecipePage = useMemo(
    () => areAllKeysPresentAndNotNil(data, ["projectId", "scenarioId", "id"]),
    [data?.projectId, data?.scenarioId, data?.id]
  );

  const addRapidModelRecipe = () => {
    if (!areAllKeysPresentAndNotNil(data, ["projectId", "scenarioId", "id"])) {
      return;
    }

    navigate(
      `/projects/${data?.projectId}/scenario/${data?.scenarioId}/add-auto-ml-recipe?entity=${data?.id}`
    );
  };

  const handleAddApiConnectorRecipe = () => {
    const url = `${WebPaths.APIConnectorRecipeContainer}?entity=${data?.id}`;
    navigate(
      generatePath(url, {
        projectId: data?.projectId,
        scenarioId: data?.scenarioId
      })
    );
  };

  const handleAddTemplateRecipe = () => {
    if (!areAllKeysPresentAndNotNil(data, ["projectId", "scenarioId", "id"])) {
      return;
    }

    navigate(
      `/projects/${data?.projectId}/scenario/${data?.scenarioId}/add-recipe?entity=${data?.id}`
    );
  };

  const updateTextInput = () => {
    if (!areAllKeysPresentAndNotNil(data, ["projectId", "scenarioId", "id"])) {
      return;
    }

    const url = `${WebPaths.Dataset}/${data?.id}?${DatasetWebPathQueryParameters.IsAddFile}=true`;
    navigate(
      generatePath(url, {
        projectId: data?.projectId,
        scenarioId: data?.scenarioId
      })
    );
  };

  // Confirm update text input - STARTS >>
  const promptConfirmUpdateTextInput = () => {
    setShowConfirmUpdateTextInputModal(() => true);
  };

  const resetConfirmUpdateTextInput = () => {
    setShowConfirmUpdateTextInputModal(() => false);
  };

  const confirmUpdateTextInput = () => {
    resetConfirmUpdateTextInput();
    updateTextInput();
  };
  // << ENDS - Confirm update text input

  // Confirm reload text input - STARTS >>
  const handleReload = () => {
    setShowConfirmReloadTextInputModal(true);
  };

  const resetConfirmReloadTextInput = () => {
    setShowConfirmReloadTextInputModal(() => false);
  };
  // << ENDS - Confirm reload text input

  const pendingReloadTextInputsMutations = useIsMutating({
    mutationKey: [UseReloadDatasetsQueryKeys.ReloadDatasets, data?.id]
  });

  const isReloadingTextInputs = useMemo(
    () => pendingReloadTextInputsMutations > 0,
    [pendingReloadTextInputsMutations]
  );

  const onDelete = () => {
    setIsDeleteModalOpen(true);
  };

  // Delete nodes - STARTS >>
  const promptConfirmDeleteNodes = () => {
    setShowConfirmDeleteNodesModal(() => true);
  };

  const resetConfirmDeleteNodes = () => {
    setShowConfirmDeleteNodesModal(() => false);
  };
  // << ENDS - Delete nodes

  const buttonComponents = useMemo(() => {
    let items: React.ReactNode[] = [];

    if (!!data?.isJobCanvas) {
      if (!data?.isJobCanvasPath) {
        return [];
      }
    }

    if (size(selectedNodes) > 1) {
      items.push(
        <Button
          size="small"
          key="delete"
          data-testid="textInputContextMenuDeleteSelected"
          startIcon={<TrashIcon viewBox="0 0 20 22" />}
          onClick={promptConfirmDeleteNodes}>
          Delete Selected
        </Button>
      );

      return items;
    }

    items.push(
      <Button
        key="view"
        data-testid="Preview"
        className="context-menu-border"
        startIcon={<EyeIcon viewBox="0 0 20 20" />}
        size="small"
        onClick={onView}>
        Preview
      </Button>
    );

    if (!data.isJobCanvas) {
      if (!!isDefaultScenario && !!isBuilt) {
        if (!!canAddRapidModelRecipe) {
          items.push(
            <Button
              key={RecipeTypeNames.RapidModel}
              size="small"
              data-testid={`${RecipeTypeNames.RapidModel} Recipe`}
              startIcon={<RapidModelRecipeIcon viewBox="0.5 0.5 22 22" />}
              disabled={isAddRecipeDisabled}
              {...(!!isNavigableToRecipePage
                ? {
                    onClick: addRapidModelRecipe
                  }
                : {})}>
              {RecipeTypeNames.RapidModel} Recipe
            </Button>
          );
        }

        if (!!canAddStandardRecipe) {
          items.push(
            <Button
              key={RecipeTypeNames.Template}
              size="small"
              data-testid={`${RecipeTypeNames.Template} Recipe`}
              startIcon={<TemplateRecipeIcon width={16} height={16} viewBox="0 0 20 20" />}
              {...(!!isNavigableToRecipePage
                ? {
                    onClick: handleAddTemplateRecipe
                  }
                : {})}>
              {RecipeTypeNames.Template} Recipe
            </Button>
          );
        }

        items.push(
          <Button
            key={RecipeTypeNames.ApiConnector}
            size="small"
            data-testid={`${RecipeTypeNames.ApiConnector} Recipe`}
            className="context-menu-border"
            startIcon={<ApiConnectorIcon />}
            {...(!!isNavigableToRecipePage
              ? {
                  onClick: handleAddApiConnectorRecipe
                }
              : {})}>
            {RecipeTypeNames.ApiConnector} Recipe
          </Button>
        );
      }

      if (!!data?.isOutputDataset) {
        items.push(
          <Button
            key="addDestination"
            data-testid="Add Destination"
            size="small"
            startIcon={<AddIcon />}
            onClick={onAddDestination}>
            Add Destination
          </Button>
        );
      } else {
        if (!data?.entityDSDetails?.type) {
          items.push(
            <Button
              key="addFile"
              data-testid="fileContextMenuAddFile"
              size="small"
              startIcon={<DatasetIcon viewBox="0 0 20 20" />}
              onClick={() => {
                if (data?.status === EntityDtoStatusEnum.Unbuilt) {
                  updateTextInput();
                } else {
                  promptConfirmUpdateTextInput();
                }
              }}>
              {data?.status === EntityDtoStatusEnum.Unbuilt ? "Add File" : "Update File"}
            </Button>
          );
        } else {
          items.push(
            <NodeActionWrapper
              key="reload"
              title={
                isReloadingTextInputs
                  ? "Please wait. The reload text input action is in progress."
                  : ""
              }>
              <Button
                size="small"
                data-testid="Reload"
                startIcon={
                  isReloadingTextInputs ? (
                    <CircularProgress size={16} />
                  ) : (
                    <CachedIcon style={{ width: "16px", height: "16px" }} />
                  )
                }
                onClick={handleReload}
                disabled={!!isReloadingTextInputs}>
                Reload
              </Button>
            </NodeActionWrapper>
          );
        }
      }

      if (!!entityDetails?.fileExtension && !!isBuilt) {
        const fileTypeKey = toLower(entityDetails?.fileExtension) as keyof typeof fileTypes;
        const fileTypeLabel = fileTypes?.[fileTypeKey]?.label;

        items.push(
          <Button
            key="Export"
            className="context-menu-border"
            data-testid="Export"
            size="small"
            disabled={isDownloading}
            startIcon={
              isDownloading ? <CircularProgress size={16} /> : <ExportIcon viewBox="0 0 22 24" />
            }
            onClick={() => download()}>
            {!!fileTypeLabel ? `Export as ${fileTypeLabel}` : "Export"}
          </Button>
        );
      }

      if (!!isDefaultScenario) {
        items.push(
          <Button
            size="small"
            key="delete"
            data-testid="Delete"
            startIcon={<TrashIcon viewBox="0 0 20 22" />}
            onClick={onDelete}>
            Delete
          </Button>
        );
      }
    }

    return items;
  }, [
    selectedNodes,
    data?.isJobCanvas,
    data?.isJobCanvasPath,
    isDefaultScenario,
    isBuilt,
    canAddStandardRecipe,
    canAddRapidModelRecipe,
    isAddRecipeDisabled,
    isNavigableToRecipePage,
    isReloadingTextInputs,
    isDownloading,
    entityDetails
  ]);

  return (
    <>
      {!!showConfirmDeleteNodesModal && (
        <DeleteNodesModal
          projectId={projectId}
          scenarioId={scenarioId}
          selectedNodes={selectedNodes}
          resetSelectedNodes={resetSelectedNodes}
          resetConfirmDeleteNodes={resetConfirmDeleteNodes}
        />
      )}

      {!!showConfirmUpdateTextInputModal && (
        <ConfirmUpdateTextInput
          resetConfirmUpdateTextInput={resetConfirmUpdateTextInput}
          updateTextInput={confirmUpdateTextInput}
        />
      )}

      {!!showConfirmReloadTextInputModal && (
        <ReloadDatasetModal
          isTextInput
          open
          id={data?.itemId}
          name={data?.label}
          projectId={data?.projectId}
          isFiveTran={data?.entityDSDetails?.type === thirdPartyTypeName}
          onCancel={resetConfirmReloadTextInput}
          onSuccess={resetConfirmReloadTextInput}
        />
      )}

      {!!isDeleteModalOpen && (
        <DeleteNodeModal
          open
          nodeId={data?.id}
          nodeName={data?.label}
          nodeType="file"
          deleteNote={
            <div>
              <span>Note: </span>
              {psMessage}
            </div>
          }
          onDeleteSuccess={resetSelectedNodes}
          onClose={() => {
            setIsDeleteModalOpen(false);
          }}
          onAfterSubmit={closeContextMenu}
        />
      )}

      {(React.isValidElement(children) || !isEmpty(buttonComponents)) && (
        <ButtonGroup variant="text" size="small" orientation="vertical" className={classes.root}>
          {!isEmpty(buttonComponents) && buttonComponents}
          {children}
        </ButtonGroup>
      )}
    </>
  );
};

export default ContextMenu;
