import React, { useMemo, useState } from "react";

// Packages
import { generatePath, useLocation, useNavigate, useParams } from "react-router-dom";
import { forEach, includes, isEmpty, size, some, toLower } from "lodash";

// MUI
import ButtonGroup from "@material-ui/core/ButtonGroup";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Button, Tooltip } from "@material-ui/core";

// Icons
import { EyeIcon } from "icons/NewUX";
import PredictionServiceIcon from "icons/NewUX/PredictionServiceIcon";
import { TrashIcon } from "icons/NewUX/TrashIcon";

// Utils
import { areAllKeysPresentAndNotNil } from "src/utils/helpers";
import { WebPaths } from "src/routing/routes";
import { TemplateRecipeIcon } from "icons/NewUX/TemplateRecipeIcon";
import ApiConnectorIcon from "icons/NewUX/ApiConnectorIcon";
import { AiAssistedIcon } from "icons/NewUX/AiAssistedRecipeIcon";
import useCreateProjectRun from "hooks/api/projects/useCreatePredictionJobRun";
import useGetAllPredictionRuns from "hooks/api/projects/useGetAllProjectRuns";

// Components
import DeleteNodeModal, { psMessage } from "src/pages/ViewData/DeleteNodeModal/DeleteNodeModal";
import usePredictionServiceByModel from "src/hooks/usePredictionServiceByModel";
import { TABS } from "pages/Library/ArtifactsAndModels/Models/ModelDetails";
import AIGuideMiniButton from "src/components/Buttons/AIGuideMiniButton";
import DeleteNodesModal from "../../DeleteNodes/DeleteNodesModal";

// Constants
import { newTheme as theme } from "src/styles";
import { RecipeTypeNames } from "src/pages/private/ProjectsModule/utils";

// Types
import { NodeData } from "src/types";
import { useContextStyles } from "../useContextMenuStyles";
import {
  JOB_TYPE,
  PREDICTION_SCHEDULER_MODEL
} from "../../../../Jobs/components/Job/context/JobContextProvider";
import { FindProjectRunsJobTypeEnum } from "@rapidcanvas/rc-api-core";
import { MODELS } from "../../../../PredictionJob/components/SelectModel";
import { PredictionJobConstants } from "../../../../PredictionJob/utils/PredictionJob.constants";
import ManualRunIcon from "icons/NewUX/ManualRunIcon";
import { ClockIcon } from "icons/NewUX/ClockIcon";

type Props = {
  open?: boolean;
  closeContextMenu: () => void;
  isDefaultScenario: boolean;
  data: NodeData;
  children?: React.ReactNode;
  openAIGuideDialog: () => void;
  selectedNodes: NodeData[];
  resetSelectedNodes: () => void;
};

const ContextMenu = (props: Props) => {
  const {
    closeContextMenu,
    isDefaultScenario,
    data,
    children,
    openAIGuideDialog,
    open,
    selectedNodes,
    resetSelectedNodes
  } = props || {};

  const location = useLocation();
  const navigate = useNavigate();
  const { projectId, scenarioId } = useParams();

  const createProjectRun = useCreateProjectRun();
  const classes = useContextStyles();
  const predictionRunResult = useGetAllPredictionRuns(data?.projectId, true, {
    enabled: !!open,
    refetchOnMount: true
  });
  const isBuilt = useMemo(() => ["BUILT"].includes(data?.status), [data?.status]);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [showConfirmDeleteNodesModal, setShowConfirmDeleteNodesModal] = useState(false);

  const { isLoading, data: predictionService } = usePredictionServiceByModel(
    data?.name,
    data?.scenarioId,
    data?.jobProps?.jobRunId,
    { enabled: !!open && !!data.name }
  );

  const name = useMemo(() => {
    let count = 1;
    const title = PredictionJobConstants.ManualName;

    forEach(predictionRunResult.data, ({ dto }) => {
      if (includes(toLower(dto?.name), toLower(title)) || dto?.name === `${title} ${count + 1}`) {
        count += 1;
      }
    });

    while (some(predictionRunResult.data, ({ dto }) => dto?.name === `${title} ${count}`)) {
      count += 1;
    }

    return `${title} ${count}`;
  }, [predictionRunResult.data]);

  const onView = () => {
    if (!data?.name) {
      return;
    }

    let path = generatePath(WebPaths.ModelDetails, {
      modelName: data?.name
    });

    if (!!data?.jobProps) {
      if (
        !data?.isJobCanvasPath ||
        !areAllKeysPresentAndNotNil(data?.jobProps, ["jobId", "jobRunId"])
      ) {
        return;
      }

      path = generatePath(`${WebPaths.JobRoutes}${WebPaths.JobModels}`, {
        projectId: data.projectId,
        jobId: data?.jobProps?.jobId,
        scenarioId: data?.scenarioId,
        jobRunId: data?.jobProps?.jobRunId,
        modelName: data?.name
      });
    }

    !!path && navigate({ pathname: path }, { state: { prevPath: location.pathname } });
  };

  const addAiAssistedRecipe = () => {
    if (!areAllKeysPresentAndNotNil(data, ["projectId", "scenarioId", "id"])) {
      return;
    }

    navigate(
      `/projects/${data?.projectId}/scenario/${data?.scenarioId}/add-code-recipe?model=${data?.id}`
    );
  };

  const handleAddApiConnectorRecipe = () => {
    const url = `${WebPaths.APIConnectorRecipeContainer}?model=${data?.id}`;
    navigate(
      generatePath(url, {
        projectId: data?.projectId,
        scenarioId: data?.scenarioId
      })
    );
  };

  const handleAddTemplateRecipe = () => {
    if (!areAllKeysPresentAndNotNil(data, ["projectId", "scenarioId", "id"])) {
      return;
    }

    navigate(
      `/projects/${data?.projectId}/scenario/${data?.scenarioId}/add-recipe?model=${data?.id}`
    );
  };

  const handlePredictionServiceView = () => {
    if (!data?.name) {
      return;
    }

    navigate(
      generatePath(WebPaths.ModelDetails, {
        modelName: data?.name
      }),
      {
        state: {
          prevPath: location.pathname,
          tab: TABS.predictionService.value,
          predictionServiceDetails: predictionService?.predictionServiceDetails
        }
      }
    );
  };

  const navigateToAIGuide = () => {
    openAIGuideDialog();
    closeContextMenu();
  };

  const onDelete = () => {
    setIsDeleteModalOpen(true);
  };

  const handlePredictionSchedulerClick = () => {
    navigate(
      `${generatePath(`${WebPaths.JobRoutes}${WebPaths.CreateJob}`, {
        projectId: data?.projectId
      })}?${JOB_TYPE}=${FindProjectRunsJobTypeEnum.PredictionJob}`,
      {
        state: {
          [PREDICTION_SCHEDULER_MODEL]: {
            id: data?.id,
            name: data?.name,
            displayName: data?.name
          }
        }
      }
    );
  };

  const handleManualPredictionClick = () => {
    createProjectRun.mutate(
      { name, projectId: data?.projectId, modelEntityId: data?.id, isManual: true },
      {
        onSuccess: (result) => {
          if (result?.id) {
            navigate(
              generatePath(WebPaths.EditPredictionJob, {
                projectId: data?.projectId,
                projectRunId: result?.id
              }),
              {
                state: {
                  [MODELS]: [{ id: data?.id, name: data?.name, displayName: data?.name }]
                }
              }
            );
          }
        }
      }
    );
  };

  // Delete nodes - STARTS >>
  const promptConfirmDeleteNodes = () => {
    setShowConfirmDeleteNodesModal(() => true);
  };

  const resetConfirmDeleteNodes = () => {
    setShowConfirmDeleteNodesModal(() => false);
  };
  // << ENDS - Delete nodes

  const buttonComponents = useMemo(() => {
    let items: React.ReactNode[] = [];

    if (!!data?.isJobCanvas) {
      if (!data?.isJobCanvasPath) {
        return [];
      }
    }

    if (size(selectedNodes) > 1) {
      items.push(
        <Button
          size="small"
          key="delete"
          data-testid="modelContextMenuDeleteSelected"
          startIcon={<TrashIcon viewBox="0 0 20 22" />}
          onClick={promptConfirmDeleteNodes}>
          Delete Selected
        </Button>
      );

      return items;
    }

    items.push(
      <Button
        key="view"
        className="context-menu-border"
        data-testid="Preview"
        startIcon={<EyeIcon viewBox="0 0 20 20" />}
        size="small"
        onClick={onView}>
        Preview
      </Button>
    );

    if (!data?.isJobCanvas && !!isDefaultScenario && isBuilt) {
      items.push(
        <Button
          key="ps"
          size="small"
          disabled={isLoading}
          className={
            predictionService?.producer?.recipeType === "AUTO_ML"
              ? undefined
              : "context-menu-border"
          }
          data-testid="Prediction Service"
          startIcon={<PredictionServiceIcon />}
          onClick={handlePredictionServiceView}>
          Prediction Service
        </Button>
      );
      if (predictionService?.producer?.recipeType === "AUTO_ML") {
        items.push(
          <Tooltip
            key="psc"
            title={
              data.isRootNode
                ? "This option is not applicable for models without any parent recipe"
                : ""
            }>
            <span>
              <Button
                size="small"
                disabled={data.isRootNode}
                data-testid="Prediction Scheduler"
                startIcon={<ClockIcon />}
                onClick={handlePredictionSchedulerClick}>
                Prediction Scheduler
              </Button>
            </span>
          </Tooltip>
        );

        items.push(
          <Tooltip
            key="mp"
            title={
              data.isRootNode
                ? "This option is not applicable for models without any parent recipe"
                : ""
            }>
            <span>
              <Button
                size="small"
                data-testid="Manual Prediction"
                className="context-menu-border"
                disabled={
                  predictionRunResult.isLoading || createProjectRun.isLoading || data.isRootNode
                }
                startIcon={
                  createProjectRun.isLoading ? (
                    <CircularProgress size={theme.spacing(1.75)} />
                  ) : (
                    <ManualRunIcon />
                  )
                }
                onClick={handleManualPredictionClick}>
                Manual Prediction
              </Button>
            </span>
          </Tooltip>
        );
      }

      items.push(
        <AIGuideMiniButton
          key="ai-guide"
          width={16}
          className="context-menu-border"
          height={16}
          viewBox="0 0 14 14"
          badgeStyleProps={{
            marginTop: -2,
            marginRight: 4,
            marginLeft: 4
          }}
          onClick={navigateToAIGuide}
          targetId={data?.itemId!}
          projectId={data?.projectId!}
        />
      );
    }

    if (isBuilt && isDefaultScenario) {
      items.push(
        <Button
          key={RecipeTypeNames.AiAssisted}
          size="small"
          data-testid={`${RecipeTypeNames.AiAssisted} Recipe`}
          startIcon={<AiAssistedIcon viewBox="0 1 20 20" />}
          onClick={addAiAssistedRecipe}>
          {RecipeTypeNames.AiAssisted} Recipe
        </Button>
      );

      items.push(
        <Button
          key={RecipeTypeNames.Template}
          size="small"
          data-testid={`${RecipeTypeNames.Template} Recipe`}
          startIcon={<TemplateRecipeIcon width={16} height={16} viewBox="0 0 20 20" />}
          onClick={handleAddTemplateRecipe}>
          {RecipeTypeNames.Template} Recipe
        </Button>
      );

      items.push(
        <Button
          key={RecipeTypeNames.ApiConnector}
          size="small"
          data-testid={`${RecipeTypeNames.ApiConnector} Recipe`}
          className="context-menu-border"
          startIcon={<ApiConnectorIcon />}
          onClick={handleAddApiConnectorRecipe}>
          {RecipeTypeNames.ApiConnector} Recipe
        </Button>
      );
    }

    if (!data?.isJobCanvas) {
      items.push(
        <Button
          size="small"
          key="delete"
          data-testid="Delete"
          startIcon={<TrashIcon viewBox="0 0 20 22" />}
          onClick={onDelete}>
          Delete
        </Button>
      );
    }

    return items;
  }, [
    selectedNodes,
    data?.isJobCanvas,
    data?.isJobCanvasPath,
    isDefaultScenario,
    data.isRootNode,
    isLoading,
    createProjectRun.isLoading,
    predictionRunResult.isLoading,
    predictionRunResult.data
  ]);

  return (
    <>
      {!!showConfirmDeleteNodesModal && (
        <DeleteNodesModal
          projectId={projectId}
          scenarioId={scenarioId}
          selectedNodes={selectedNodes}
          resetSelectedNodes={resetSelectedNodes}
          resetConfirmDeleteNodes={resetConfirmDeleteNodes}
        />
      )}

      {!!isDeleteModalOpen && (
        <DeleteNodeModal
          open
          nodeId={data?.id}
          nodeName={data?.label}
          nodeType="model"
          deleteNote={
            <div>
              <span>Note: Deleting this might impact associated DataApps (if any). </span>
              {psMessage}
            </div>
          }
          onDeleteSuccess={resetSelectedNodes}
          onClose={() => {
            setIsDeleteModalOpen(false);
          }}
          onAfterSubmit={closeContextMenu}
        />
      )}

      {(React.isValidElement(children) || !isEmpty(buttonComponents)) && (
        <ButtonGroup variant="text" size="small" orientation="vertical" className={classes.root}>
          {!isEmpty(buttonComponents) && buttonComponents}
          {children}
        </ButtonGroup>
      )}
    </>
  );
};

export default ContextMenu;
