import React from "react";

export const PlayIcon = ({
  width = 11,
  height = 12,
  viewBox = "0 0 11 12",
  fill = "none",
  color = "currentColor"
} = {}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.38729e-08 11.0411V0.958858C-5.20347e-05 0.790194 0.046261 0.624501 0.134263 0.478512C0.222265 0.332523 0.34884 0.211405 0.501208 0.127389C0.653576 0.0433732 0.826342 -0.000567039 1.00206 5.52481e-06C1.17778 0.000578089 1.35023 0.045643 1.502 0.13065L10.505 5.17275C10.6556 5.2572 10.7806 5.37806 10.8675 5.52328C10.9543 5.6685 11 5.83303 11 6.00048C11 6.16793 10.9543 6.33245 10.8675 6.47768C10.7806 6.6229 10.6556 6.74376 10.505 6.82821L1.502 11.8693C1.35023 11.9544 1.17778 11.9994 1.00206 12C0.826342 12.0006 0.653576 11.9566 0.501208 11.8726C0.34884 11.7886 0.222265 11.6675 0.134263 11.5215C0.046261 11.3755 -5.20347e-05 11.2098 4.38729e-08 11.0411Z"
      fill={color}
    />
  </svg>
);
