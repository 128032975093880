import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router";
import { v4 as uuidv4 } from "uuid";
import { Grid, makeStyles, Box, Button, Typography, IconButton, Tooltip } from "@material-ui/core";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import { Clear } from "@material-ui/icons";

import { Tabs, Tab, TabPanel } from "src/components";
import { PreviewIconNew } from "src/assets/icons/PreviewIconNew";
import { ArtifactIcon } from "src/assets/icons/ArtifactIcon";
import { ModelsIcon } from "src/assets/icons/ModelsIcon";
import { ArtifactTable } from "src/pages/Library/ArtifactsAndModels/Artifacts/ArtifactTable";
import { ModelTable } from "src/pages/Library/ArtifactsAndModels/Models/ModelTable";
import ViewDataData from "src/pages/ViewData/ViewDataData/ViewDataData";
import { AddColumnTransforms } from "./AddColumnTransforms";
import { handleResponse } from "services/Apis/Apis.service";
import { useDrawerStore } from "stores/zustand/stores";
import { sideComponentSetter } from "stores/zustand/stores.selectors";
import { TransformationsView } from "../Transformations/TransformationsView";
import ChartFrame from "src/pages/Projects/Charts/ChartFrame";

import { StandardRecipeInputContainerProps } from "./StandardRecipeInputContainer.type";
import { RecipeWithNoDatasetsView } from "./RecipeWithNoDatasetsView";
import { READONLY_ENTITY } from "projectsModule/utils/Projects.constants";
import Chart from "../../Charts/Chart";
import { isEmpty, toLower } from "lodash";
import FileEntity from "../../common/FileEntity";
import FileEntityFromUrl from "../../common/FileEnityFromUrl";
import { useEntityData } from "src/hooks/api/projects/useEntityDataAndStats";

const useStyles = makeStyles(() => ({
  header: {
    flexWrap: "nowrap",
    borderBottom: "1px solid rgba(53,96,121,0.26)",
    justifyContent: "space-between",
    borderRadius: "12px 12px 0px 0px",
    borderWidth: "1px 1px 0px 1px",
    padding: "8px 16px"
  },
  recipeContainer: {
    flexWrap: "nowrap",
    width: "100%"
  },
  tabRoot: {
    minHeight: "44px",
    flex: 1,
    boxShadow: "none",
    borderRadius: "12px"
  },
  recipeBodyWrap: {
    height: "calc(100vh - 201px)",
    borderRadius: "6px"
  },
  tabHeader: {
    flexWrap: "nowrap",
    gap: "10px",
    display: "flex !important",
    alignItems: "center"
  },
  tabDetails: {
    padding: "16px",
    height: "100%"
  },
  viewDataContentContainer: {
    overflowY: "auto",
    height: "calc(100vh - 166px)",
    borderRadius: "0px 0px 4px 4px"
  },
  headerButtons: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0px 16px",
    gap: "16px"
  },
  entityTabsWrap: {
    width: "100%"
  },
  transformHeader: {
    fontSize: "14px !important",
    letterSpacing: "0.4px",
    fontWeight: 600
  },
  inputTabWrap: {
    flexWrap: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden"
  },
  transformBtn: {
    whiteSpace: "nowrap"
  }
}));

export const StandardRecipeInputContainer = ({
  selectedInputDatasets,
  selectedArtifacts,
  selectedModels,
  recipe,
  entityFeaturesMap,
  isGetEntityFeaturesLoading,
  dfsTemplates,
  appliedTransforms,
  isFetchingTemplates,
  onAppliedTransformChange,
  onDeleteAppliedTransform,
  removeInputDataset,
  removeArtifact,
  removeModel,
  previewTabs,
  setPreviewTabs,
  isEmptyRecipe,
  isDefaultScenario
}: StandardRecipeInputContainerProps) => {
  const classes = useStyles();

  const location = useLocation();
  const isJobPath = useMemo(() => /jobs/.test(location.pathname), [location.pathname]);
  const [isFileEntity, setIsFileEntity] = useState(
    selectedInputDatasets?.[0]?.entityMeta.entityViewType === "FILE"
  );

  const [currentEntityData, setCurrentEntityData] = useState<any>({});

  const { projectId, scenarioId, jobRunId } = useParams<$TSFixMe>();

  const initialValue = !!selectedInputDatasets?.[0]?.id
    ? selectedInputDatasets?.[0]?.id
    : !!selectedArtifacts?.[0]?.name
      ? `${selectedArtifacts?.[0]?.name}-artifact`
      : !!selectedModels?.[0]?.name
        ? `${selectedModels?.[0]?.name}-model`
        : "";
  const [value, setValue] = useState<string>(initialValue);

  const [currentEntityId, setCurrentEntityId] = useState<string | null>(
    selectedInputDatasets?.[0]?.id
  );

  const {
    isLoading: isEntityLoading,
    mutateAsync: datasetDataMutation,
    reset: resetDatasetDataMutation
  } = useEntityData({
    onSuccess: (datasetDataResponse) => {
      setCurrentEntityData(() => datasetDataResponse?.data);
    }
  });

  useEffect(() => {
    const _ = async () => {
      await resetDatasetDataMutation();
      await datasetDataMutation({
        entityId: currentEntityId,
        scenarioId,
        jobRunId,
        payload: {
          rowsStart: 0,
          rowsEnd: 100,
          cols: []
        }
      });
    };

    currentEntityId && !isFileEntity && _();
  }, [currentEntityId]);
  const setSideComponent = useDrawerStore(sideComponentSetter);

  const openTransformationsSidebar = (updatedAppliedTransforms?: $TSFixMe) => {
    const onClose = () => {
      setSideComponent({
        sideComponent: null,
        sideComponentProps: null
      });
    };

    setSideComponent({
      sideComponent: TransformationsView,
      sideComponentProps: {
        isJobPath,
        projectId,
        dfsTemplates,
        appliedTransforms: updatedAppliedTransforms || appliedTransforms,
        isDefaultScenario,
        groupId: recipe?.id,
        onAppliedTransformChange,
        selectedInputDatasets,
        configGroup: recipe,
        scenarioId,
        onDeleteAppliedTransform,
        title: (
          <Typography
            color="primary"
            className={classes.transformHeader}
            test-id="transformsDrawerTitle">
            TRANSFORMATIONS
          </Typography>
        ),
        onClose
      }
    });
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleChange = React.useCallback(
    (newValue: string) => {
      if (value === newValue) return;

      const entityTab = selectedInputDatasets?.find((dataset) => dataset.id === newValue);

      if (Boolean(entityTab)) {
        setCurrentEntityId(newValue);
        setIsFileEntity(entityTab?.entityMeta.entityViewType === "FILE");
      }
      setValue(newValue);
    },
    [selectedInputDatasets, value]
  );

  React.useEffect(() => {
    if (previewTabs.length) {
      const lastPreviewTab = previewTabs[previewTabs.length - 1];
      setValue(`${lastPreviewTab.name}-preview`);
    }
  }, [previewTabs]);

  const onAddColumnTransform = ({
    dfsTemplateId,
    datasetName,
    columnName
  }: {
    dfsTemplateId: string;
    datasetName: string;
    columnName: string;
  }) => {
    const dfsTemplateToAdd = dfsTemplates?.find(
      (currTemplate) => currTemplate.id === dfsTemplateId
    );

    const inputDatasetName = dfsTemplateToAdd?.inputs?.find(
      (input) => input?.metadata?.input_dataset
    )?.name;
    const inputColumnName = dfsTemplateToAdd?.inputs?.find(
      (input) => input?.metadata?.input_column
    )?.name;

    if (!dfsTemplateToAdd && !inputDatasetName) {
      handleResponse({ errorMessage: "Unable to add selected transformation" });
    }

    const newTranform = {
      ...dfsTemplateToAdd,
      id: uuidv4(),
      templateId: dfsTemplateToAdd?.id,
      expanded: true,
      //@ts-expect-error
      inputValues: { [inputDatasetName]: datasetName, [inputColumnName]: columnName }
    };

    openTransformationsSidebar([...appliedTransforms, newTranform]);
  };

  return (
    <Grid container direction="column" className={classes.recipeContainer}>
      <Grid container direction="row" className={classes.header}>
        <Tabs value={value} onChange={handleChange}>
          {selectedInputDatasets?.map(({ displayName, name, id }) => (
            <Tab
              key={name}
              value={id}
              test-id="addRecipeSelectedInputDatasetsTab"
              icon={<PreviewIconNew />}
              label={displayName}
              clearIcon={
                !isJobPath && (
                  <Tooltip title={!isDefaultScenario ? READONLY_ENTITY : ""}>
                    <div>
                      <IconButton
                        disabled={!isDefaultScenario}
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          removeInputDataset(id);
                        }}>
                        <Clear style={{ fontSize: 14 }} />
                      </IconButton>
                    </div>
                  </Tooltip>
                )
              }
            />
          ))}
          {selectedArtifacts?.map(({ name }) => (
            <Tab
              key={name}
              value={`${name}-artifact`}
              test-id="addRecipeSelectedArtifactsTab"
              icon={<ArtifactIcon />}
              label={name}
              clearIcon={
                !isJobPath && (
                  <Tooltip title={!isDefaultScenario ? READONLY_ENTITY : ""}>
                    <div>
                      <IconButton
                        disabled={!isDefaultScenario}
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          removeArtifact(name);
                        }}>
                        <Clear style={{ fontSize: 14 }} />
                      </IconButton>
                    </div>
                  </Tooltip>
                )
              }
            />
          ))}
          {selectedModels?.map(({ name }) => (
            <Tab
              key={name}
              value={`${name}-model`}
              test-id="addRecipeSelectedModelsTab"
              icon={<ModelsIcon />}
              label={name}
              clearIcon={
                !isJobPath && (
                  <Tooltip title={!isDefaultScenario ? READONLY_ENTITY : ""}>
                    <div>
                      <IconButton
                        disabled={!isDefaultScenario}
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          removeModel(name);
                        }}>
                        <Clear style={{ fontSize: 14 }} />
                      </IconButton>
                    </div>
                  </Tooltip>
                )
              }
            />
          ))}
          {previewTabs.length
            ? previewTabs?.map((item: $TSFixMe) => (
                <Tab
                  key={item.name}
                  test-id={`code-recipe-output-${toLower(item.type)}-tab`}
                  value={`${item.name}-preview`}
                  icon={<FlashOnIcon color="secondary" style={{ fontSize: "16px" }} />}
                  label={item.name}
                  clearIcon={
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        setPreviewTabs(previewTabs.filter((tab: any) => tab.name !== item.name));
                        setValue(initialValue);
                      }}>
                      <Clear style={{ fontSize: 14 }} />
                    </IconButton>
                  }
                />
              ))
            : null}
        </Tabs>
        <Grid className={classes.headerButtons}>
          <Button
            color="primary"
            variant="outlined"
            disabled={isFetchingTemplates}
            size="small"
            className={classes.transformBtn}
            test-id="standardRecipeAddTransformsBtn"
            onClick={() => openTransformationsSidebar()}>
            {`Transformations${
              appliedTransforms?.length > 0 ? ` (${appliedTransforms.length})` : ""
            }`}
          </Button>
        </Grid>
      </Grid>
      <Box p="0px 12px">
        <Grid className={classes.recipeBodyWrap}>
          {isEmptyRecipe && recipe?.id && isEmpty(previewTabs) ? (
            <RecipeWithNoDatasetsView
              isDefaultScenario={isDefaultScenario}
              onTransformsClick={openTransformationsSidebar}
            />
          ) : (
            <>
              {selectedInputDatasets.map((dataset: $TSFixMe, index: $TSFixMe) => {
                const entityFeatures = entityFeaturesMap?.[dataset.name];
                return (
                  <TabPanel key={index} value={value} index={dataset.id}>
                    <Box width="100%">
                      {dataset.id === value ? (
                        dataset.entityMeta.entityViewType !== "FILE" ? (
                          <div id="viewDataContainer" className={classes.viewDataContentContainer}>
                            <ViewDataData
                              readonly={!isDefaultScenario}
                              hideCount={false}
                              showSample
                              isLoadingData={isEntityLoading || isGetEntityFeaturesLoading}
                              responseData={currentEntityData || { columns: [], rows: [] }}
                              entityFeatures={entityFeatures}
                              maxHeight="calc(100vh - 210px)"
                              columnOptionsCustomRender={({
                                name,
                                onClose
                              }: {
                                name: string;
                                onClose: () => void;
                              }) => (
                                <AddColumnTransforms
                                  onClose={onClose}
                                  dfsTemplates={dfsTemplates}
                                  isDefaultScenario={isDefaultScenario}
                                  onAddTransform={(dfsTemplateId: string) => {
                                    onAddColumnTransform({
                                      dfsTemplateId,
                                      datasetName: dataset.name,
                                      columnName: name
                                    });
                                  }}
                                />
                              )}
                            />
                          </div>
                        ) : (
                          <Grid
                            style={{ paddingTop: "28px" }}
                            className={classes.viewDataContentContainer}>
                            <FileEntity dataset={dataset} />
                          </Grid>
                        )
                      ) : null}
                    </Box>
                  </TabPanel>
                );
              })}
              {selectedArtifacts.map((artifact: any) => {
                return (
                  <TabPanel
                    key={artifact?.name}
                    value={value}
                    index={`${artifact?.name}-artifact`}
                    test-id="standardRecipeSelectedArtifactDetails">
                    <ArtifactTable
                      artifactName={artifact?.name}
                      disableDelete
                      maxHeight="calc(100vh - 210px)"
                    />
                  </TabPanel>
                );
              })}
              {selectedModels.map((model: any, index: number) => {
                return (
                  <TabPanel
                    key={model?.name || `modelTabPanel-${index}`}
                    value={value}
                    index={`${model.name}-model`}
                    test-id="standardRecipeSelectedModelDetails">
                    <ModelTable modelName={model.name} maxHeight="calc(100vh - 210px)" />
                  </TabPanel>
                );
              })}
              {previewTabs?.map((item: $TSFixMe) => {
                if (item.type === "DATASET") {
                  return (
                    <TabPanel
                      value={value}
                      index={`${item.name}-preview`}
                      key={`${item.name}-preview`}>
                      <div id="viewDataContainer" className={classes.viewDataContentContainer}>
                        <ViewDataData
                          isLoadingData={false}
                          hideCount={false}
                          showSample={false}
                          responseData={item.data || { columns: [], rows: [] }}
                          entityFeatures={[]}
                        />
                      </div>
                    </TabPanel>
                  );
                }
                if (item.type === "FILE") {
                  return (
                    <TabPanel
                      value={value}
                      index={`${item.name}-preview`}
                      key={`${item.name}-preview`}>
                      <div id="viewDataContainer" className={classes.viewDataContentContainer}>
                        <FileEntityFromUrl
                          fileName={item.data.name}
                          filePath={item.data.dataFilePath}
                        />
                      </div>
                    </TabPanel>
                  );
                }
                if (item.type === "ARTIFACT") {
                  {
                    return (
                      <TabPanel
                        value={value}
                        style={{ height: "calc(100vh - 160px)" }}
                        boxProps={{ height: "100%" }}
                        index={`${item.name}-preview`}
                        key={`${item.name}-preview`}>
                        <Box p="16px" width="100%" height={"100%"} style={{ background: "white" }}>
                          <Grid>{`An artifact named ${item.name} has been generated. The actual artifact files will be displayed within the artifact in the canvas after the recipe execution`}</Grid>
                        </Box>
                      </TabPanel>
                    );
                  }
                }
                if (item.type === "MODEL") {
                  return (
                    <TabPanel
                      value={value}
                      boxProps={{ height: "100%" }}
                      style={{ height: "calc(100vh - 160px)" }}
                      index={`${item.name}-preview`}
                      key={`${item.name}-preview`}>
                      <Box p="16px" width="100%" height={"100%"} style={{ background: "white" }}>
                        <Grid>{`A model named ${item.name} has been generated. The actual model files will be displayed within the model in the canvas after the recipe execution`}</Grid>
                      </Box>
                    </TabPanel>
                  );
                }
                if (item.chartType) {
                  return (
                    <TabPanel key={item.name} value={value} index={`${item.name}-preview`}>
                      <Chart key={`${item.name}-preview`} data={item} expanded />
                    </TabPanel>
                  );
                } else {
                  return (
                    <TabPanel key={item.name} value={value} index={`${item.name}-preview`}>
                      <ChartFrame data={item} size={12} />
                    </TabPanel>
                  );
                }
              })}
            </>
          )}
        </Grid>
      </Box>
    </Grid>
  );
};
