import React, { useCallback, useEffect, useState } from "react";

// Packages
import { Route, Routes, generatePath, useNavigate, useParams } from "react-router-dom";

// Utils
import { WebPaths } from "src/routing/routes";
import { DatasetWebPathQueryParameters } from "../../utils";

// Hooks
import useDagPolling from "./hooks/useDagPolling";

// Pages
import CanvasFlowHeader from "src/pages/Projects/CanvasFlow/CanvasFlowHeader";

// Components
import { DagFlow } from "./components";
import { useSetAIGuideMessagesCount } from "src/pages/Projects/common/hooks/useSetAIGuideMessagesCount";

// Contexts
import DagContextProvider from "./context/DagContextProvider";
import { useDagContext } from "./context/useDagContext";
import CommonLoader from "src/components/CommonLoader";

const DagPage = () => {
  const navigate = useNavigate();

  const { projectId } = useParams() || {};

  const { scenarioId, isFromDatasetPage, setIsFromArtifactsModelModal } = useDagContext() || {};

  useSetAIGuideMessagesCount({ projectId: projectId! });

  // To poll canvas data upon closing data-upload modal, if there are UnBuilt datasets in the canvas.
  const { onDatasetCreated } = useDagPolling({
    projectId,
    scenarioId
  });

  const [recipeRunsPopoverAnchorEl, setRecipeRunsPopoverAnchorEl] =
    useState<HTMLButtonElement | null>(null);

  const onAddDatasetAction = useCallback(() => {
    if (projectId) {
      navigate(generatePath(WebPaths.Dataset, { projectId, scenarioId }));
    }
  }, [scenarioId, projectId]);

  const navigateToApiConnectorRecipe = useCallback(() => {
    if (projectId) {
      const url = `${WebPaths.APIConnectorRecipeContainer}`;
      navigate(
        generatePath(url, {
          projectId,
          scenarioId
        })
      );
    }
  }, [scenarioId, projectId]);

  const onAddFileAction = useCallback(() => {
    if (projectId) {
      navigate(
        generatePath(`${WebPaths.Dataset}?${DatasetWebPathQueryParameters.IsAddFile}=true`, {
          projectId,
          scenarioId
        })
      );
    }
  }, [scenarioId, projectId]);

  const onRecipeRunsPopoverAction = (event: React.MouseEvent<HTMLButtonElement>) => {
    setRecipeRunsPopoverAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    !!isFromDatasetPage && onDatasetCreated();
  }, [isFromDatasetPage]);

  return (
    <>
      <CanvasFlowHeader
        scenarioId={scenarioId}
        onAddDatasetAction={onAddDatasetAction}
        onAddFileAction={onAddFileAction}
        onRecipeRunsPopoverAction={onRecipeRunsPopoverAction}
        setIsFromArtifactsModelModal={setIsFromArtifactsModelModal}
      />

      <DagFlow
        onAddDatasetAction={onAddDatasetAction}
        onAddFileAction={onAddFileAction}
        navigateToApiConnectorRecipe={navigateToApiConnectorRecipe}
        recipeRunsPopoverAnchorEl={recipeRunsPopoverAnchorEl}
        setRecipeRunsPopoverAnchorEl={setRecipeRunsPopoverAnchorEl}
      />
    </>
  );
};

const DagRoutes = () => {
  const { isFetchingProjectScenarios, scenarioId } = useDagContext() || {};

  return !scenarioId || isFetchingProjectScenarios ? (
    <CommonLoader />
  ) : (
    <Routes>
      <Route path={WebPaths.Canvas} element={<DagPage />} />
    </Routes>
  );
};

const DagWrapper = () => {
  return (
    <DagContextProvider>
      <DagRoutes />
    </DagContextProvider>
  );
};

export default DagWrapper;
