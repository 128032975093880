import React from "react";

export const VectorDBIcon = ({
  width = 16,
  height = 16,
  viewBox = "",
  fill = "none",
  color = "currentColor"
} = {}) => (
  <svg
    width={width}
    height={height}
    viewBox={!!viewBox ? viewBox : `0 0 ${width} ${height}`}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.6667 1.33301H5.33341C3.86675 1.33301 2.66675 2.53301 2.66675 3.99967V7.39967C2.66675 8.46634 3.53341 9.33301 4.60008 9.33301H11.4667C12.5334 9.33301 13.3334 8.46634 13.4001 7.46634V3.99967C13.3334 2.53301 12.1334 1.33301 10.6667 1.33301ZM5.33341 7.99967C4.93341 7.99967 4.66675 7.73301 4.66675 7.33301C4.66675 6.93301 4.93341 6.66634 5.33341 6.66634C5.73341 6.66634 6.00008 6.93301 6.00008 7.33301C6.00008 7.73301 5.73341 7.99967 5.33341 7.99967ZM10.6667 5.33301H5.33341C4.60008 5.33301 4.00008 4.73301 4.00008 3.99967C4.00008 3.26634 4.60008 2.66634 5.33341 2.66634H10.6667C11.4001 2.66634 12.0001 3.26634 12.0001 3.99967C12.0001 4.73301 11.4001 5.33301 10.6667 5.33301ZM4.60008 10.6663C3.93341 10.6663 3.26675 10.4663 2.66675 10.0663V11.9997C2.66675 13.4663 3.86675 14.6663 5.33341 14.6663H10.6667C12.1334 14.6663 13.3334 13.4663 13.3334 11.9997V10.0663C12.8001 10.4663 12.1334 10.6663 11.4001 10.6663H4.60008ZM5.33341 13.333C4.93341 13.333 4.66675 13.0663 4.66675 12.6663C4.66675 12.2663 4.93341 11.9997 5.33341 11.9997C5.73341 11.9997 6.00008 12.2663 6.00008 12.6663C6.00008 13.0663 5.73341 13.333 5.33341 13.333Z"
      fill={color}
    />
  </svg>
);
