import React, { useMemo } from "react";
import clsx from "clsx";
import { Drawer, Button } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { useDrawerStore } from "../../stores/zustand/stores";
import {
  getSideComponent,
  getSideComponentProps,
  sideComponentSetter
} from "../../stores/zustand/stores.selectors";
import styles from "./index.module.scss";

const DrawerContainer = () => {
  const SideComponentElement = useDrawerStore(getSideComponent);
  const sideComponentProps = useDrawerStore(getSideComponentProps);
  const setSideComponent = useDrawerStore(sideComponentSetter);

  const handleDrawerClose = () => {
    sideComponentProps?.handleCloseAction && sideComponentProps?.handleCloseAction();
    setSideComponent({
      sideComponent: null,
      sideComponentProps: {
        anchor: sideComponentProps?.anchor,
        drawerPanelClassName: sideComponentProps?.drawerPanelClassName
      }
    });
  };

  const anchor = useMemo(() => {
    return sideComponentProps?.anchor || "right";
  }, [sideComponentProps]);

  return (
    <Drawer
      className={clsx(styles.drawerPanel, sideComponentProps?.drawerPanelClassName)}
      onClose={handleDrawerClose}
      open={!!SideComponentElement}
      anchor={anchor}>
      {anchor === "right" && !sideComponentProps?.noHeader && (
        <div className={styles.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            <CloseIcon />
          </IconButton>
          <p>{sideComponentProps?.title}</p>
          {sideComponentProps?.handleCancelAction && (
            <Button
              variant="outlined"
              color="primary"
              onClick={sideComponentProps?.handleCancelAction}>
              Cancel
            </Button>
          )}
          {sideComponentProps?.handleCreateAction && (
            <Button
              variant="contained"
              onClick={sideComponentProps?.handleCreateAction}
              disabled={sideComponentProps?.disabled}
              color="primary">
              {sideComponentProps?.createActionLabel || "Create"}
            </Button>
          )}
        </div>
      )}
      {anchor === "left" && !sideComponentProps?.noHeader && (
        <div
          className={clsx(styles.drawerHeader, {
            [styles.drawerHeaderAnchorLeft]: anchor === "left"
          })}>
          <p className={styles.drawerHeaderTitle}>{sideComponentProps?.title}</p>
          <IconButton className={styles.cancelIconButtonAnchorLeft} onClick={handleDrawerClose}>
            <CloseIcon />
          </IconButton>
        </div>
      )}
      {!!SideComponentElement && <SideComponentElement {...sideComponentProps} />}
    </Drawer>
  );
};

export default DrawerContainer;
