import React from "react";
import _ from "lodash";
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@material-ui/core";

import { PublishAppTemplateRequestDtoAppTypeEnum } from "@rapidcanvas/rc-api-core";
import { dataAppConfigFields } from "./CreateDataAppForm";

interface IProps {
  readOnly?: boolean;
  value: PublishAppTemplateRequestDtoAppTypeEnum;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const DataAppSelectType: React.FC<IProps> = (props) => {
  return (
    <FormControl component="fieldset" style={{ marginTop: "10px" }} disabled={props.readOnly}>
      <FormLabel component="legend">DataApp Type</FormLabel>
      <RadioGroup
        row={props.readOnly}
        aria-label={dataAppConfigFields.appType}
        name={dataAppConfigFields.appType}
        value={props.value}
        data-testid="dataAppConfigAppType"
        onChange={props.onChange}>
        <FormControlLabel
          value={PublishAppTemplateRequestDtoAppTypeEnum.Streamlit}
          control={<Radio />}
          data-testid="dataAppConfigAppTypeStreamlit"
          label="Streamlit"
        />
        <FormControlLabel
          value={PublishAppTemplateRequestDtoAppTypeEnum.Reactjs}
          control={<Radio />}
          data-testid="dataAppConfigAppTypeReactJS"
          label="React JS"
        />
      </RadioGroup>
    </FormControl>
  );
};

export default DataAppSelectType;
