import React, { useMemo, useState } from "react";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  SelectProps
} from "@material-ui/core";
import { map, get, isEmpty, find } from "lodash";
import { useLocation } from "react-router-dom";

import Text from "components/Widget/Text";
import { Model } from "hooks/api/projects/useGetAllProjectModels";
import { OverflowTooltip } from "src/components";

interface IProps extends Omit<SelectProps, "onChange"> {
  projectId: string;
  data: Model[];
  numOfDatasets?: number;
  onChange: (id: string, name: string, targetCol: string, isTimeSeriesRecipeModel: boolean) => void;
}

export const MODELS = "models";

const useStyles = makeStyles({
  selectRoot: {
    height: "34px",
    display: "flex",
    alignItems: "center",
    paddingTop: "2.5px !important",
    paddingBottom: "2.5px !important"
  },
  selectOutlined: {
    padding: "4px 10px !important",
    display: "flex",
    alignItems: "center"
  }
});

const SelectModel: React.FC<IProps> = (props) => {
  const { projectId, value, numOfDatasets, data, onChange, ...rest } = props;
  const { state } = useLocation();
  const [focused, setFocused] = useState(false);
  const classes = useStyles();

  const options = useMemo(() => {
    const models = data ?? get(state, MODELS) ?? [];

    return map(models, ({ id, displayName, name }) => (
      <MenuItem id={id} key={id} value={id}>
        <OverflowTooltip
          style={{ maxWidth: "100%", whiteSpace: "nowrap" }}
          value={<Text value={displayName ?? name} />}
        />
      </MenuItem>
    ));
  }, [state, data]);

  const handleChange = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    const record = find(data ?? get(state, MODELS), { id: e.target.value }) as Model;
    onChange(
      e.target.value as string,
      record?.name,
      record?.targetCol,
      record?.isTimeSeriesParentRecipe
    );
  };

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel
        test-id="model-label"
        style={{ transform: focused || value ? undefined : "translate(14px, 12px) scale(1)" }}>
        Model *
      </InputLabel>
      <Select
        id="model-id"
        label="Model *"
        classes={{ root: classes.selectRoot, outlined: value ? classes.selectOutlined : undefined }}
        test-id="model-id"
        name="Model"
        value={isEmpty(options) ? "" : value}
        required
        inputProps={{
          style: { height: 32, padding: "6px 10px" }
        }}
        onChange={handleChange}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        {...rest}>
        {options}
      </Select>
      {!!numOfDatasets && (
        <FormHelperText
          style={{
            margin: "6px auto",
            fontStyle: "italic"
          }}>{`This model needs ${numOfDatasets} dataset(s) to be selected`}</FormHelperText>
      )}
    </FormControl>
  );
};

export default SelectModel;
