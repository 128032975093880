import React from "react";
import { Box, Grid, makeStyles, Typography } from "@material-ui/core";

import { Warning } from "src/assets/icons/Warning";
import NewModal from "src/components/Modal/NewModal";

const useStyles = makeStyles({
  queryText: {
    maxHeight: "350px",
    overflow: "auto",
    wordBreak: "break-word"
  }
});

export const DeleteQueryModal = ({
  header = "Delete Query",
  userInput,
  handleSubmit,
  onClose,
  loading,
  deleteMsg
}: $TSFixMe) => {
  const classes = useStyles();
  return (
    <NewModal
      open
      header={header}
      onClose={onClose}
      onFinish={handleSubmit}
      width="465px"
      loading={loading}
      height={350}
      background="#fff"
      submitBtnTestId="deleteQuery"
      submitButtonLabel="Yes, Remove"
      cancelButtonLabel="Cancel">
      <Grid container wrap="nowrap" style={{ gap: "16px" }}>
        <Warning />
        <Box display="flex" flexDirection="column" gridGap={16}>
          <Typography className={classes.queryText} data-testid="delete-query-modal-message">
            {deleteMsg || (
              <div>
                This will delete the `<b>{userInput}</b>` and associated Outputs.
              </div>
            )}
          </Typography>
          <Typography>Are you sure?</Typography>
        </Box>
      </Grid>
    </NewModal>
  );
};
