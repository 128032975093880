import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import _ from "lodash";
import { DeleteQueryModal } from "../AskAIResponseContainer/DeleteQueryModal";
import { useCodeRecipeContext } from "../../../CodeRecipeContext/useCodeRecipeContext";
import { OUTPUT_TYPE } from "../../../CodeRecipeContext/CodeRecipeContextProvider";
import { STOPPED_QUERY_ID } from "../GenerateCodeBar/GenerateCodeQueryInput";
import { useDeleteCodeHistoryMutation } from "src/hooks/api";
import { PromptSuggestions } from "./PromptSuggestions";

export const AskAIPromptSuggestions = ({
  suggestedPrompts,
  queryInputs,
  isExpanded: isInitialExpanded,
  response,
  autoGenerateCode,
  isGenerating
}: {
  suggestedPrompts: string[];
  queryInputs: { name: string }[];
  isExpanded: boolean;
  response: any;
  autoGenerateCode: (text: string, inputNames: string[]) => void;
  isGenerating: boolean;
}) => {
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [isDeleteInProgress, setIsDeleteInProgress] = React.useState<boolean>(false);
  const cleanedPrompts = suggestedPrompts?.map((prompt: string) => {
    const [title, description] = prompt.replace(/Prompt \d+: /, "").split(". ");
    return { title, description };
  });
  const deleteCodeHistoryMutation = useDeleteCodeHistoryMutation();
  const { historyId } = response;
  const { recipeId, responses, setResponses } = useCodeRecipeContext();

  const [isExpanded, setIsExpanded] = useState(isInitialExpanded);

  const onDeleteQuery = () => {
    setIsDeleteInProgress(true);
    const responseOutputName = response.queryOutputs?.[0]?.name;
    const responseMapping = responses.reduce(
      (acc: any, currResponse: any) => {
        const queryInputNames = currResponse.queryInputs.map((queryInput: any) => queryInput.name);
        const currOutputName = currResponse.queryOutputs?.[0]?.name;
        if (acc.outputNames.find((outputName: string) => queryInputNames.includes(outputName))) {
          return {
            historyIds: [...acc.historyIds, currResponse.historyId],
            outputNames:
              currResponse.outputType === OUTPUT_TYPE.DATASET
                ? [...acc.outputNames, currOutputName]
                : acc.outputNames
          };
        } else {
          return acc;
        }
      },
      { historyIds: [historyId], outputNames: [responseOutputName] }
    );

    recipeId &&
      deleteCodeHistoryMutation.mutate(
        { recipeId, historyIds: responseMapping.historyIds },
        {
          onSuccess: () => {
            setResponses((currResponses: any) => {
              const filteredResponses = currResponses.filter(
                (currResponse: any) =>
                  !responseMapping.historyIds?.includes(currResponse.historyId) ||
                  currResponse.historyId === STOPPED_QUERY_ID
              );
              return filteredResponses.map((currResponse: any) =>
                currResponse.promptSuggestions
                  ? { ...currResponse, isExpanded: false }
                  : currResponse
              );
            });
            setIsDeleteInProgress(false);
            setShowDeleteModal(false);
          }
        }
      );
  };

  const handleToggle = () => {
    setIsExpanded((expanded) => !expanded);
  };

  return (
    <Grid container direction="column">
      <PromptSuggestions
        isGenerating={isGenerating}
        autoGenerateCode={autoGenerateCode}
        inputNames={_.map(queryInputs, "name")}
        setShowDeleteModal={setShowDeleteModal}
        isExpanded={isExpanded}
        promts={cleanedPrompts}
        handleToggle={handleToggle}
      />
      {showDeleteModal && (
        <DeleteQueryModal
          userInput={"Prompt Suggestions"}
          handleSubmit={() => onDeleteQuery?.()}
          onClose={() => setShowDeleteModal?.(false)}
          loading={isDeleteInProgress}
        />
      )}
    </Grid>
  );
};
