import {
  postAPI,
  getAPI,
  deleteAPI,
  postAPIWithRethrow,
  deleteAPIWithRethrow,
  getAPIWithRethrow,
  putAPIWithRethrow
} from "../Apis.service";

export const getRecipesByProjectId = async (projectId: $TSFixMe) =>
  await getAPI(`/v2/dfs-run-config-groups?projectId=${projectId}`);

export const addTransformGroup = async (payload: $TSFixMe, id: $TSFixMe) =>
  await postAPI(`/v2/dfs-run-config-groups/${id}`, payload);

export const addTransformGroupWithRethrow = async (payload: $TSFixMe, id: $TSFixMe) =>
  // // @ts-expect-error TS(2554) FIXME: Expected 3 arguments, but got 2.
  await postAPIWithRethrow(`/v2/dfs-run-config-groups/${id}`, payload);

export const updateTransformGroup = async (payload: $TSFixMe, id: $TSFixMe) =>
  await putAPIWithRethrow(`/v2/dfs-run-config-groups/${id}`, payload);

export const updateTransformGroupWithRethrow = async (payload: $TSFixMe, id: $TSFixMe) =>
  await putAPIWithRethrow(`/v2/dfs-run-config-groups/${id}`, payload);

export const getTransformGroupWithRethrow = async (
  groupId: $TSFixMe,
  scenarioId?: string,
  jobRunId?: string
) =>
  await getAPIWithRethrow(
    `/v2/dfs-run-config-groups?id=${groupId}${!!scenarioId ? `&scenarioId=${scenarioId}` : ``}${
      !!jobRunId ? `&projectRunEntryId=${jobRunId}` : ``
    }`
  );

export const runTransformGroupWithRethrow = async (
  groupId: $TSFixMe,
  scenarioId: $TSFixMe,
  options?: $TSFixMe,
  shouldDispatchEvent = true
) =>
  await postAPIWithRethrow(
    `/v2/dfs-run-config-groups/run/${groupId}${scenarioId ? `?scenarioId=${scenarioId}` : ""}`,
    {},
    options,
    shouldDispatchEvent
  );

// Transforms
export const deleteTransformById = async (id: $TSFixMe) =>
  // @ts-expect-error TS(2554) FIXME: Expected 2 arguments, but got 1.
  await deleteAPI(`/v2/dfs-run-configs/${id}`);

export const deleteTransformByIdWithRethrow = async (id: $TSFixMe) =>
  // @ts-expect-error TS(2554) FIXME: Expected 2 arguments, but got 1.
  await deleteAPIWithRethrow(`/v2/dfs-run-configs/${id}`);

export const saveTransformByIdWithRethrow = async (body: $TSFixMe, options?: any) =>
  // // @ts-expect-error TS(2554) FIXME: Expected 3 arguments, but got 2.
  await postAPIWithRethrow("/v2/dfs-run-configs", body, options);

export const updateTransformByIdWithRethrow = async (body: $TSFixMe) =>
  await putAPIWithRethrow("/v2/dfs-run-configs", body);

export const getTemplatesWithRethrow = async ({ projectId }: { projectId: string }) =>
  await getAPIWithRethrow(`/v2/dfs-templates?status=ACTIVE`, { params: { projectId } });

export const createTemplateWithRethrow = async (payload: $TSFixMe) =>
  await postAPIWithRethrow("/v2/dfs-templates", payload);

export const updateTemplateWithRethrow = async (payload: $TSFixMe) =>
  await putAPIWithRethrow("/v2/dfs-templates?id=" + payload.id, payload, {}, false);

export const getTemplateWithRethrow = async (templateId: string) =>
  await getAPIWithRethrow(`/v2/dfs-templates/${templateId}`);
