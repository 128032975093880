import React, { useRef, useState } from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { PixelCrop } from "react-image-crop";
import { map } from "lodash";
import Img1 from "src/assets/images/projectImages/preview_image1.png";
import Img2 from "src/assets/images/projectImages/preview_image2.png";
import Img3 from "src/assets/images/projectImages/preview_image3.png";
import Img4 from "src/assets/images/projectImages/preview_image4.png";
import Img5 from "src/assets/images/projectImages/preview_image5.png";
import Img6 from "src/assets/images/projectImages/preview_image6.png";
import Img7 from "src/assets/images/projectImages/preview_image7.png";
import Img8 from "src/assets/images/projectImages/preview_image8.png";
import Img9 from "src/assets/images/projectImages/preview_image9.png";
import Img10 from "src/assets/images/projectImages/preview_image10.png";
import Img11 from "src/assets/images/projectImages/preview_image11.png";
import Img12 from "src/assets/images/projectImages/preview_image12.png";
import Img13 from "src/assets/images/projectImages/preview_image13.png";
import Img14 from "src/assets/images/projectImages/preview_image14.png";
import Img15 from "src/assets/images/projectImages/preview_image15.png";
import Img16 from "src/assets/images/projectImages/preview_image16.png";
import Img17 from "src/assets/images/projectImages/preview_image17.png";
import Img18 from "src/assets/images/projectImages/preview_image18.png";
import Img19 from "src/assets/images/projectImages/preview_image19.png";
import Img20 from "src/assets/images/projectImages/preview_image20.png";
import Img21 from "src/assets/images/projectImages/preview_image21.png";
import Img22 from "src/assets/images/projectImages/preview_image22.png";
import Img23 from "src/assets/images/projectImages/preview_image23.png";
import Img24 from "src/assets/images/projectImages/preview_image24.png";
import Img25 from "src/assets/images/projectImages/preview_image25.png";
import Img26 from "src/assets/images/projectImages/preview_image26.png";
import Img27 from "src/assets/images/projectImages/preview_image27.png";
import Img29 from "src/assets/images/projectImages/preview_image29.png";

import Img30 from "src/assets/images/projectImages/preview_image30.jpg";
import Img31 from "src/assets/images/projectImages/preview_image31.jpg";
import Img32 from "src/assets/images/projectImages/preview_image32.jpg";
import Img33 from "src/assets/images/projectImages/preview_image33.jpg";
import Img34 from "src/assets/images/projectImages/preview_image34.jpg";
import Img35 from "src/assets/images/projectImages/preview_image35.jpg";
import Img36 from "src/assets/images/projectImages/preview_image36.jpg";
import Img37 from "src/assets/images/projectImages/preview_image37.jpg";
import Img38 from "src/assets/images/projectImages/preview_image38.jpg";
import Img39 from "src/assets/images/projectImages/preview_image39.jpg";
import Img40 from "src/assets/images/projectImages/preview_image40.jpg";
import Img41 from "src/assets/images/projectImages/preview_image41.jpg";
import Img42 from "src/assets/images/projectImages/preview_image42.jpg";
import Img43 from "src/assets/images/projectImages/preview_image43.jpg";
import Img44 from "src/assets/images/projectImages/preview_image44.jpg";
import Img45 from "src/assets/images/projectImages/preview_image45.jpg";
import Img46 from "src/assets/images/projectImages/preview_image46.jpg";
import Img47 from "src/assets/images/projectImages/preview_image47.jpg";
import Img48 from "src/assets/images/projectImages/preview_image48.jpg";
import Img49 from "src/assets/images/projectImages/preview_image49.jpg";
import Img50 from "src/assets/images/projectImages/preview_image50.jpg";
import Img51 from "src/assets/images/projectImages/preview_image51.jpg";
import Img54 from "src/assets/images/projectImages/preview_image54.jpg";
import Img52 from "src/assets/images/projectImages/preview_image52.jpg";
import Img53 from "src/assets/images/projectImages/preview_image53.jpg";
import Img55 from "src/assets/images/projectImages/preview_image55.jpg";

import Img56 from "src/assets/images/projectImages/preview_image56.jpg";
import Img57 from "src/assets/images/projectImages/preview_image57.jpg";
import Img58 from "src/assets/images/projectImages/preview_image58.jpg";
import Img59 from "src/assets/images/projectImages/preview_image59.jpg";
import Img60 from "src/assets/images/projectImages/preview_image60.jpg";
import Img61 from "src/assets/images/projectImages/preview_image61.jpg";
import Img62 from "src/assets/images/projectImages/preview_image62.jpg";

import Img63 from "src/assets/images/projectImages/preview_image63.jpg";
import Img64 from "src/assets/images/projectImages/preview_image64.jpg";
import Img65 from "src/assets/images/projectImages/preview_image65.jpg";
import Img66 from "src/assets/images/projectImages/preview_image66.jpg";

import imageSelectionIcon from "src/assets/images/checkmark-square.png";
import Modal from "src/components/custom/Modal/Modal";
import { makeStyles } from "@material-ui/core/styles";
import { Tabs, Tab } from "src/components";
import { CustomImage } from "./CustomImage/CustomImage";
import { DropImageBox } from "./CustomImage/DropImageBox";
import { images as cdnImages } from "src/pages/private/ProjectsModule/utils";
import { cdnImagePath } from "src/pages/private/ProjectsModule/utils";
import { imageToBase64simple } from "src/pages/private/ProjectsModule/pages/ProjectSettings/utils/ProjectSettings.helpers";

const useStyles = makeStyles({
  imageCount: {
    color: "#7C7C7C",
    fontSize: "14px",
    paddingBottom: "8px",
    lineHeight: "32px"
  },
  customImageBox: {
    height: "100%"
  },
  tabContainer: {
    height: "400px"
  },
  imagesWrap: {
    background: "#EEEDEB",
    gap: "8px"
  },
  title: {
    color: "#123553",
    marginBottom: "12px"
  },
  imageBox: {
    width: ({ previewType }: any) =>
      previewType === "project" || previewType === "mini-project" ? "200px" : "96px",
    height: ({ previewType }: any) =>
      previewType === "project" || previewType === "mini-project" ? "100px" : "120px",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
    backgroundPosition: "center center",
    backgroundSize: "200px 100px",
    backgroundRepeat: "no-repeat",
    backgroundClip: "padding-box",
    "&:hover::after": {
      content: '"CHOOSE"',
      position: "absolute",
      width: ({ previewType }: any) =>
        previewType === "project" || previewType === "mini-project" ? "200px" : "96px",
      height: ({ previewType }: any) =>
        previewType === "project" || previewType === "mini-project" ? "100px" : "120px",
      left: "0",
      top: "0",
      backgroundColor: "rgba(0, 54, 86, 0.64)",
      borderRadius: "4px",
      paddingTop: "22.5%",
      color: "white"
    }
  },
  selectedImage: {
    borderColor: "#f1957d",
    pointerEvents: "none",
    "&::after": {
      content: `url(${imageSelectionIcon})`,
      paddingTop: "20%",
      position: "absolute",
      backgroundColor: "rgba(0, 54, 86, 0.32)",
      width: ({ previewType }: any) =>
        previewType === "project" || previewType === "mini-project" ? "200px" : "96px",
      height: ({ previewType }: any) =>
        previewType === "project" || previewType === "mini-project" ? "100px" : "120px",
      borderRadius: "4px",
      border: "1px solid #f1957d"
    }
  },
  customImgGrid: {
    height: "138px",
    width: "244px",
    borderRadius: "12px",
    "& img": {
      borderRadius: "12px"
    }
  },
  imagesBox: {
    flexWrap: "nowrap",
    gap: "16px",
    padding: "16px 0px"
  }
});

export const offlineImages = [
  Img1,
  Img2,
  Img3,
  Img4,
  Img5,
  Img6,
  Img7,
  Img8,
  Img9,
  Img10,
  Img11,
  Img12,
  Img13,
  Img14,
  Img15,
  Img16,
  Img17,
  Img18,
  Img19,
  Img20,
  Img21,
  Img22,
  Img23,
  Img24,
  Img25,
  Img26,
  Img27,
  Img29,
  Img30,
  Img31,
  Img32,
  Img33,
  Img34,
  Img35,
  Img36,
  Img37,
  Img38,
  Img39,
  Img40,
  Img41,
  Img42,
  Img43,
  Img44,
  Img45,
  Img46,
  Img47,
  Img48,
  Img49,
  Img50,
  Img51,
  Img52,
  Img53,
  Img54,
  Img55,
  Img56,
  Img57,
  Img58,
  Img59,
  Img60,
  Img61,
  Img62,
  Img63,
  Img64,
  Img65,
  Img66
];

type Props = {
  images?: any[];
  previewType?: "project" | "mini-project" | "default";
  onSubmit: (imageSubmitted: string | undefined) => void;
  isSubmitting?: boolean;
  handleClose: any;
  currentImgSrc?: string;
};

const PreviewImageModal = ({
  previewType = "default",
  isSubmitting = false,
  onSubmit,
  handleClose,
  currentImgSrc
}: Props) => {
  const images = !!process.env.ENVIRONMENT ? map(cdnImages, (image) => `/${image}`) : offlineImages;
  const styles = useStyles({ previewType });
  const [selectedImage, setSelectedImage] = useState();
  const [value, setValue] = useState("default");
  const imgRef = useRef<HTMLImageElement>(null);
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();

  const handleSubmit = () => {
    if (value === "default") {
      if (!selectedImage) return;

      if (!/(http|https):\/\//.test(selectedImage)) {
        imageToBase64simple(selectedImage, onSubmit);
      } else {
        onSubmit(selectedImage);
      }
    } else {
      const base64Image = getCroppedImage();
      onSubmit(base64Image);
    }
  };

  const getCroppedImage = () => {
    if (!imgRef.current || !completedCrop) {
      throw new Error("Image or crop not defined");
    }
    const image = imgRef.current;
    const crop = completedCrop;

    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    if (!ctx) {
      throw new Error("Unable to get canvas context");
    }
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return canvas.toDataURL("image/jpeg");
  };
  const [imgSrc, setImgSrc] = useState<string>("");
  const onFilesUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setImgSrc(reader.result?.toString() || ""));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  return (
    <Modal
      open
      size="md"
      isSubmitting={isSubmitting}
      title="Choose Preview Image"
      submitLabel="Choose"
      isSubmitDisabled={value === "default" ? !selectedImage : !imgRef.current || !completedCrop}
      onClose={handleClose}
      onSubmit={handleSubmit}
      background="#EEEDEB"
      PaperProps={{
        style: {
          background: "#EEEDEB"
        }
      }}
      isNewTheme>
      <Grid container direction="column" className={styles.imagesWrap}>
        <Grid item>
          <Tabs value={value} onChange={setValue}>
            <Tab label="Default" value={"default"} />
            <Tab label="Custom" value={"custom"} />
          </Tabs>
        </Grid>
        <Grid item className={styles.tabContainer}>
          {value === "default" && (
            <Grid item>
              <Typography className={styles.imageCount}>Showing {images.length} images</Typography>
              <Grid container spacing={2}>
                {images.map((image: $TSFixMe, index: number) => {
                  const updatedImage = !!cdnImagePath ? `${cdnImagePath}${image}` : image;

                  return (
                    <Grid key={index} item xs style={{ flexGrow: 0 }}>
                      <Button
                        onClick={() =>
                          setSelectedImage(
                            selectedImage === updatedImage ? undefined : updatedImage
                          )
                        }
                        className={`${styles.imageBox} ${
                          selectedImage === updatedImage ? styles.selectedImage : ""
                        }`}
                        style={{ backgroundImage: `url(${updatedImage})` }}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          )}
          {value === "custom" && (
            <Grid container className={styles.customImageBox}>
              {currentImgSrc && !imgSrc ? (
                <Grid container direction="column">
                  <Grid container direction="row" className={styles.imagesBox}>
                    <Grid item className={styles.customImgGrid}>
                      <DropImageBox onFilesUpload={onFilesUpload} />
                    </Grid>
                    <Grid item className={styles.customImgGrid}>
                      <img src={currentImgSrc} height="100%" width="100%" alt="project" />
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <CustomImage
                  aspect={2}
                  setCompletedCrop={setCompletedCrop}
                  imgRef={imgRef}
                  imgSrc={imgSrc}
                />
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Modal>
  );
};

export default PreviewImageModal;
