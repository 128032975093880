import React from "react";

// Packages
import { generatePath, Link } from "react-router-dom";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";

// Utils
import { RenderText } from "components/custom";
import { WebPaths } from "src/routing/routes";
import { SCHEDULER_TABS } from "./Schedulers";

type Props = {
  project: { id: string; name: string };
  tab: SCHEDULER_TABS;
};

const SubTopNavBarBreadcrumbs = (props: Props) => {
  const { project, tab } = props || {};

  const breadcrumbs: React.ReactNode[] = [];

  breadcrumbs.push(
    !!project?.id ? (
      <Link key="projects" to={generatePath(`${WebPaths.Dag}/canvas`, { projectId: project?.id })}>
        <RenderText>{project?.name || "Project"}</RenderText>
      </Link>
    ) : (
      <RenderText key="projects" color="textSecondary">
        {project?.name || "Project"}
      </RenderText>
    )
  );

  breadcrumbs.push(
    <RenderText key="Predictions" color="textSecondary">
      Predictions
    </RenderText>
  );

  breadcrumbs.push(
    <RenderText key="tab" color="textSecondary">
      {tab}
    </RenderText>
  );

  return (
    <Breadcrumbs>
      {breadcrumbs?.map((eachBreadcrumb: React.ReactNode) => eachBreadcrumb)}
    </Breadcrumbs>
  );
};

export default SubTopNavBarBreadcrumbs;
