import React, { useRef } from "react";
import { Grid, Paper } from "@material-ui/core";

import api from "services/AxiosClient/AxiosClient";
import CommonLoader from "src/components/CommonLoader";

const ChartFrame = ({ data = {}, size }: $TSFixMe) => {
  const loader = useRef<HTMLDivElement>(null);

  const token = api.getToken();

  return (
    <Grid item xs={size}>
      <Paper>
        <div ref={loader}>
          <CommonLoader />
        </div>
        <iframe
          onLoad={() => (loader?.current as $TSFixMe)?.remove()}
          title={data.name || ""}
          style={{
            height: size === 12 ? "calc(100vh - 210px)" : "calc(50vh)",
            width: "100%",
            background: "white",
            border: "none",
            boxShadow: "none"
          }}
          src={`${data.configPath}&token=${token}`}
        />
      </Paper>
    </Grid>
  );
};

export default ChartFrame;
