import React from "react";

export const StorageIcon = ({
  width = 16,
  height = 16,
  viewBox = "0 0 16 16",
  fill = "none",
  color = "currentColor",
  opacity = 1
} = {}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    opacity={opacity}
    color={color}>
    <path
      d="M2 3V6V10V13C2 13.85 2.65 14.5 3.5 14.5H12.5C13.35 14.5 14 13.85 14 13V10V6V3C14 2.15 13.35 1.5 12.5 1.5H3.5C2.65 1.5 2 2.15 2 3ZM13 9.5H3V6.5H13V9.5ZM13 13C13 13.3 12.8 13.5 12.5 13.5H3.5C3.2 13.5 3 13.3 3 13V10.5H13V13ZM3 3C3 2.7 3.2 2.5 3.5 2.5H12.5C12.8 2.5 13 2.7 13 3V5.5H3V3Z"
      fill="#003656"
      fill-opacity="0.6"
    />
    <path
      d="M3.9 3.65C3.8 3.75 3.75 3.85 3.75 4C3.75 4.15 3.8 4.25 3.9 4.35C4 4.45 4.1 4.5 4.25 4.5C4.4 4.5 4.5 4.45 4.6 4.35C4.7 4.25 4.75 4.15 4.75 4C4.75 3.85 4.7 3.75 4.6 3.65C4.4 3.45 4.1 3.45 3.9 3.65ZM4.25 8.5C4.4 8.5 4.5 8.45 4.6 8.35C4.7 8.25 4.75 8.1 4.75 8C4.75 7.85 4.7 7.75 4.6 7.65C4.4 7.45 4.1 7.45 3.9 7.65C3.8 7.75 3.75 7.85 3.75 8C3.75 8.15 3.8 8.25 3.9 8.35C4 8.45 4.1 8.5 4.25 8.5ZM4.25 12.5C4.4 12.5 4.5 12.45 4.6 12.35C4.7 12.25 4.75 12.1 4.75 12C4.75 11.85 4.7 11.75 4.6 11.65C4.4 11.45 4.1 11.45 3.9 11.65C3.8 11.75 3.75 11.85 3.75 12C3.75 12.15 3.8 12.25 3.9 12.35C4 12.45 4.1 12.5 4.25 12.5Z"
      fill="#003656"
      fill-opacity="0.6"
    />
  </svg>
);
