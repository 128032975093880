import * as React from "react";
import { useNavigate } from "react-router-dom";

import { Tabs, Tab, Typography, Box, Button } from "@material-ui/core";
import { FlipToFront } from "@material-ui/icons";

import { useDrawerStore } from "../../../stores/zustand/stores";
import { sideComponentSetter } from "../../../stores/zustand/stores.selectors";

import { TransformItemsList } from "./components";
import styles from "./entity-modal.module.scss";

function TabPanel(props: $TSFixMe) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: $TSFixMe) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const EntityModal = ({ data }: $TSFixMe) => {
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);

  const setSideComponent = useDrawerStore(sideComponentSetter);

  const downloadCSV = () => {
    data.id && window.open(`/pipeline-outputs/${data.id}/download`);
  };

  const goToViewData = () => {
    navigate(`/pipeline/${data.pipelineId}/entities/data/${data.id}/data`);
    setSideComponent({
      sideComponent: null,
      sideComponentProps: null
    });
  };

  const handleChange = (__: $TSFixMe, newValue: $TSFixMe) => {
    setValue(newValue);
  };

  return (
    <>
      <div className={styles.actionsWrapper}>
        <Button variant="text" onClick={goToViewData} startIcon={<FlipToFront />}>
          View data
        </Button>
        <Button variant="text" onClick={downloadCSV} startIcon={<FlipToFront />}>
          Export
        </Button>
      </div>
      <Box className={styles.tabsWrapper} sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="ADD transform" {...a11yProps(0)} />
            <Tab label="Details" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <TransformItemsList />
        </TabPanel>
      </Box>
    </>
  );
};

export default EntityModal;
