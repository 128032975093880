import React from "react";

import { RecipeRunData } from "@rapidcanvas/rc-api-core";

type ContextTypes = {
  project: $TSFixMe;
  recipeRunsQueue: RecipeRunData[];
};

// @ts-ignore
export const ProjectContext = React.createContext<ContextTypes>();
