import React from "react";
import { List, ListItem, ListItemText } from "@material-ui/core";
import { startCase } from "lodash";
import { PublicScreenLabels } from "constants/index";

export const PasswordError = ({
  type,
  errorMessage,
  isServerError
}: {
  type?: string;
  errorMessage?: string | null;
  isServerError?: boolean;
}) => {
  const errorsList = [];

  if (type === "required") {
    errorsList.push(`${startCase(PublicScreenLabels.Password)} is required!`);
  }

  if (isServerError) {
    errorsList.push("Oops, something went wrong! Please try again.");
  }

  if (errorMessage) {
    errorsList.push(errorMessage);
  }

  if (errorsList.length === 0) {
    return null;
  }

  return (
    <List dense disablePadding>
      {errorsList.map((error, index) => (
        <ListItem key={`passwordError-${index}`} disableGutters>
          <ListItemText primary={error} disableTypography />
        </ListItem>
      ))}
    </List>
  );
};
