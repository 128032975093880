import { useQueryClient } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { UseGetProjectCanvasQueryKeys } from "src/hooks/api";
import { useDeleteRecipe } from "src/hooks/api/transforms/useDeleteRecipe";
import { useCanvasStore } from "stores/zustand/stores";

type IProps = {
  isDeletableRecipe: boolean;
  projectId: string | undefined;
  scenarioId: string | undefined;
  recipeId: string | undefined;
  handleBack: () => void;
};
export const useDeleteEmptyRecipe = ({
  isDeletableRecipe,
  projectId,
  scenarioId,
  recipeId,
  handleBack
}: IProps) => {
  const queryClient = useQueryClient();
  const deleteRecipeMutation = useDeleteRecipe();
  const navigate = useNavigate();
  const [isDeleteRecipeLoading, setIsDeleteRecipeLoading] = useState(false);
  const [isDeleteRecipeSuccess, setIsDeleteRecipeSuccess] = useState(false);
  const [setReloadTrigger] = useCanvasStore((state) => [state.setReloadTrigger]);

  const handleRecipeDelete = ({ onSuccess }: { onSuccess?: () => void } = {}) => {
    if (isDeletableRecipe && projectId && recipeId && !isDeleteRecipeLoading) {
      setIsDeleteRecipeLoading(true);
      deleteRecipeMutation.mutate(
        { projectId, recipeId: recipeId },
        {
          onSuccess: () => {
            setReloadTrigger();
            queryClient.invalidateQueries({
              queryKey: [UseGetProjectCanvasQueryKeys.ProjectCanvas, projectId, scenarioId],
              refetchType: "all"
            });
            onSuccess?.();
          },
          onSettled: () => {
            setIsDeleteRecipeLoading(false);
          }
        }
      );
    }
  };

  const onSaveSuccess = () => {
    navigate(`/projects/${projectId}/canvas`);
  };

  const onProjectClick = (event: any) => {
    if (isDeletableRecipe && !isDeleteRecipeSuccess) {
      event.preventDefault();
      handleRecipeDelete({
        onSuccess: onSaveSuccess
      });
    }
    window.onpopstate = null;
  };

  const onDeleteSuccess = () => {
    setIsDeleteRecipeSuccess(true);
    handleBack();
  };

  useEffect(() => {
    window.onpopstate = (e) => {
      onProjectClick(e);
    };
  });

  const onBack = () => {
    if (isDeletableRecipe && !isDeleteRecipeSuccess) {
      handleRecipeDelete({
        onSuccess: onSaveSuccess
      });
    }
  };

  return { onDeleteSuccess, onBack, onProjectClick };
};
