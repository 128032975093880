import { get } from "lodash";

import Analytics from "../services/Analytics/Analytics";
import env from "../services/AppEnvironment/AppEnvironment";

import { TRACKING_INFO } from "../utils/constants/analyticsEvents.constants";

export const initAnalytics = () => {
  const trackingId = get(TRACKING_INFO, env.getEnvironment());
  if (trackingId) {
    Analytics.initialize(atob(trackingId));
  }
};
