import React, { Dispatch, SetStateAction } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import AIGuideChatBox from "../../common/AIGuideChatBox";
import { ChartIconV3 } from "icons/NewUX/ChartIconV3";
import Chart from "../../common/Chart";

type IProps = {
  name: string;
  chartJson: string;
  askAIMessageId?: string;
  isExpanded: boolean;
  setIsExpanded: Dispatch<SetStateAction<boolean>>;
};

const useStyles = makeStyles({
  chartsWrap: {
    overflow: "auto",
    visibility: "hidden",
    opacity: 0,
    position: "absolute"
  },
  selectedChart: {
    display: "block",
    visibility: "visible",
    opacity: 1,
    position: "relative"
  },
  icon: {
    height: "32px",
    width: "24px !important",

    "& svg": {
      marginLeft: "-4px"
    }
  },
  chartWrap: {
    margin: "5px 12px 5px 12px",
    overflowY: "auto",
    borderRadius: "2px"
  }
});

const OriginalChart = ({
  name,
  chartJson,
  isExpanded,
  setIsExpanded,

  askAIMessageId
}: IProps) => {
  const classes = useStyles();

  const onToggle = ({ onSuccess }: { onSuccess: () => void }) => {
    setIsExpanded((prev) => !prev);
    onSuccess();
  };
  return (
    <AIGuideChatBox
      isCollapsed={!isExpanded}
      isExpandDisabled={false}
      isGenerating={false}
      onToggle={onToggle}
      icon={
        <Grid
          container
          item
          direction="column"
          alignItems="flex-start"
          justifyContent="center"
          wrap="nowrap"
          data-testid="ai-dataset-chart-icon"
          className={classes.icon}>
          <ChartIconV3 />
        </Grid>
      }
      title={name}>
      <Grid container direction="column" wrap="nowrap" data-testid="chartsWrap">
        <Chart
          askAIMessageId={askAIMessageId}
          plotlyJson={chartJson}
          name={name}
          isEditChart={true}
          isParentChart={true}
        />
      </Grid>
    </AIGuideChatBox>
  );
};

export default React.memo(OriginalChart);
