import { useMutation, UseMutationResult } from "@tanstack/react-query";

import api from "services/AxiosClient/AxiosClient";

export const enum UseRunProjectQueryKeys {
  RunProject = "runProject",
  RunProjectWithReloadDatasets = "runProjectWithReloadDatasets"
}

interface IPayload {
  projectId?: string;
  scenarioId?: string;
  reloadDatasets?: boolean;
}

interface IProps {
  projectId?: string;
  scenarioId?: string;
}

const useRunProject = (props?: IProps): UseMutationResult<unknown, unknown, IPayload, unknown> => {
  const { projectId, scenarioId } = props || {};

  return useMutation({
    mutationKey: [UseRunProjectQueryKeys.RunProject, projectId, scenarioId],
    mutationFn: async ({ projectId, scenarioId, reloadDatasets }: IPayload) => {
      if (!projectId || !scenarioId) {
        throw "Invalid payload!";
      }

      return await api.fetchResponse(
        async () =>
          await api.ProjectsControllerV2Api.runProject1(projectId, scenarioId, !!reloadDatasets)
      );
    }
  });
};

export const useRunProjectWithReloadDatasets = (
  props?: IProps
): UseMutationResult<unknown, unknown, IPayload, unknown> => {
  const { projectId, scenarioId } = props || {};

  return useMutation({
    mutationKey: [UseRunProjectQueryKeys.RunProjectWithReloadDatasets, projectId, scenarioId],
    mutationFn: async ({ projectId, scenarioId, reloadDatasets }: IPayload) => {
      if (!projectId || !scenarioId) {
        throw "Invalid payload!";
      }

      return await api.fetchResponse(
        async () =>
          await api.ProjectsControllerV2Api.runProject1(projectId, scenarioId, !!reloadDatasets)
      );
    }
  });
};

export default useRunProject;
