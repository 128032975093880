// Packages
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { find } from "lodash";

// Open API
import { EnvDto } from "@rapidcanvas/rc-api-core";

export enum EnvironmentActionsStatusEnum {
  Idle = "idle",
  Stopping = "stopping",
  Launching = "launching",
  Updating = "updating",
  Loading = "loading",
  Deleting = "deleting"
}

type EnvironmentsState = {
  environments: EnvDto[];
  statusByEnvironment: Record<string, EnvironmentActionsStatusEnum>;
};

export type SetEnvironmentStatusPayload = {
  id: string;
  status: EnvironmentActionsStatusEnum;
};

export enum MutatingEnvActionsEnum {
  Add = "add",
  Remove = "remove"
}

export type MutatingEnv = {
  id: string;
  action: MutatingEnvActionsEnum;
};

const initialState: EnvironmentsState = {
  environments: [],
  statusByEnvironment: {}
};

const environmentsSlice = createSlice({
  name: "environments",
  initialState,
  reducers: {
    setEnvironments(state, action: PayloadAction<EnvDto[]>) {
      state.environments = action.payload;
    },
    updateEnvironmentStatus(state, action: PayloadAction<EnvDto>) {
      const environment = find(state.environments, { id: action.payload.id });
      if (environment) {
        environment.launchStatus = action.payload.launchStatus;
      }
    },
    setEnvironmentStatus(state, action: PayloadAction<SetEnvironmentStatusPayload>) {
      const { id, status } = action.payload;
      state.statusByEnvironment[id] = status;
    },
    resetEnvironmentStatus(state, action: PayloadAction<{ id: string }>) {
      const { id } = action.payload;
      state.statusByEnvironment[id] = EnvironmentActionsStatusEnum.Idle;
    }
  }
});

export const {
  setEnvironments,
  updateEnvironmentStatus,
  setEnvironmentStatus,
  resetEnvironmentStatus
} = environmentsSlice.actions;
export default environmentsSlice.reducer;
