import React from "react";
import { Grid, Typography, GridSize, makeStyles, Divider, Tooltip } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { ObjectFieldTemplateProps } from "@rjsf/utils";

const useStyles = makeStyles({
  title: {
    marginBottom: "8px",
    flex: 1,
    whiteSpace: "nowrap"
  },
  divider: {
    marginBottom: "8px"
  },
  description: {
    paddingLeft: "8px"
  }
});

interface CustomObjectFieldTemplateProps extends ObjectFieldTemplateProps {
  xs?: GridSize;
}

const ObjectFieldTemplate: React.FC<CustomObjectFieldTemplateProps> = ({
  properties,
  schema,
  uiSchema
}) => {
  const classes = useStyles();
  const { title, description } = schema;
  const uiOptions = uiSchema?.["ui:options"];
  const xs = (uiOptions?.["xs"] || 12) as GridSize;

  return (
    <Grid container wrap="nowrap" direction="column">
      {(title || description) && (
        <Grid container direction="column" wrap="nowrap">
          <Grid container direction="row" wrap="nowrap">
            {title && (
              <Grid item className={classes.title}>
                <Typography variant="body1">{title}</Typography>
              </Grid>
            )}
            {description && (
              <Grid item className={classes.description}>
                <Tooltip title={description}>
                  <InfoIcon fontSize="small" style={{ color: "#9e9e9e" }} />
                </Tooltip>
              </Grid>
            )}
          </Grid>
          <Divider className={classes.divider} />
        </Grid>
      )}
      <Grid container direction="row" spacing={2}>
        {properties.map((prop, index) => (
          <Grid item container direction="row" xs={xs} key={index}>
            {prop.content}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default ObjectFieldTemplate;
