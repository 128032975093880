import React, { useEffect, useState, useMemo } from "react";

import { Tooltip, makeStyles } from "@material-ui/core";

import { DataSourcesHelperText } from "../../utils/DataSources.constants";
import Table from "src/components/custom/TableV8/Table";
import { CellContext } from "@tanstack/react-table";

const useStyles = makeStyles(() => ({
  tableContainer: {
    maxHeight: 400,
    overflow: "auto"
  },
  cell: {
    display: "inline-block",
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    verticalAlign: "middle"
  }
}));

const SampleData = (props: $TSFixMe) => {
  const { sampleData, unsorted } = props || {};

  const classes: $TSFixMe = useStyles();

  const [data, setData] = useState<$TSFixMe>([]);

  const getElementWithTooltip = ({ value = "" }: $TSFixMe) => (
    <Tooltip title={value.length >= 55 ? value : ""}>
      <span className={classes.cell}>{value}</span>
    </Tooltip>
  );

  const columns = useMemo(
    () =>
      (sampleData?.columns || [])?.map((column: $TSFixMe, index: number) => {
        return {
          id: column || `column_${index + 1}`,
          name: column,
          header: column || DataSourcesHelperText.Unknown,
          accessorKey: column,
          cell: ({ getValue }: CellContext<any, unknown>) =>
            getElementWithTooltip({ value: getValue() }),
          sortingFn: "alphanumeric"
        };
      }),
    [sampleData]
  );

  useEffect(() => {
    const columns = sampleData?.columns || [];
    const rows = sampleData?.rows || [];

    if (columns?.length === 0 || rows?.length === 0) {
      setData(() => []);
    } else {
      const outputRows = [
        ...rows?.reduce((rowAcc: $TSFixMe, row: $TSFixMe) => {
          rowAcc.push(
            (row?.cells || [])?.reduce((cellAcc: $TSFixMe, cell: $TSFixMe, cellIndex: $TSFixMe) => {
              cellAcc[columns[cellIndex]] =
                !!cell && typeof cell === "string" && ["nan"].includes(cell?.trim()?.toLowerCase())
                  ? ""
                  : cell;
              return cellAcc;
            }, {})
          );

          return rowAcc;
        }, [])
      ];

      setData(() => outputRows);
    }
  }, [sampleData]);

  return (
    <Table
      columns={columns}
      data={data}
      stylesProps={{
        size: "small",
        hideSettings: false,
        isTheadSticky: true,
        inheritHeight: true,
        maxHeight: "25vh"
      }}
      sortingProps={{ orderByDefault: unsorted ? undefined : columns[0]?.name || "Name", unsorted }}
    />
  );
};

export default SampleData;
