import { makeStyles } from "@material-ui/core";
import React from "react";
import { useParams } from "react-router";
import { EditDataAppHeaderWrap } from "../EditDataApp/EditDataAppHeaderWrap";
import useDataAppStatus from "hooks/api/dataapps/useDataAppStatus";
import _, { get } from "lodash";
import { DataAppDto } from "@rapidcanvas/rc-api-core";
import { useUpdateDataAppMutation } from "hooks/api";
import { dataAppConfigFields } from "../CreateDataApp/CreateDataAppForm";
import { handleResponse } from "services/Apis/Apis.service";
import api from "services/AxiosClient/AxiosClient";
import { DATAAPP_CODE_URL } from "utils/helpers/url.helpers";

const useStyles = makeStyles({
  wrapper: {
    padding: "16px 16px 0px 16px",
    height: "100%"
  }
});

export const EditDataAppCode = ({ dataApp }: { dataApp: DataAppDto }) => {
  const classes = useStyles();
  const params = useParams();
  const isProjectDataApps = !!get(params, "projectId");
  const token = api.getToken();
  const updateDataAppMutation = useUpdateDataAppMutation();

  const { currentStatusInfo, status, loading } = useDataAppStatus(
    dataApp.id!,
    dataApp.launchStatus
  );

  const handleUpdateName = (newVal: string) => {
    updateDataAppMutation.mutate(
      {
        id: dataApp.id!,
        payload: {
          ...dataApp,
          displayName: newVal ?? _.get(dataApp, dataAppConfigFields.dataAppName)
        }
      },
      {
        onSuccess: () => {
          handleResponse({ successMessage: "DataApp Name updated successfully." });
        }
      }
    );
  };

  const dataAppCodeEditorUrl = `${DATAAPP_CODE_URL}${dataApp.id}?token=${token}`;

  return (
    <div className={classes.wrapper}>
      <EditDataAppHeaderWrap
        dataApp={dataApp}
        isProjectDataApps={isProjectDataApps}
        currentStatusInfo={currentStatusInfo}
        status={status}
        loading={loading}
        onSave={(newVal) => handleUpdateName(newVal)}
      />
      <iframe
        title={dataApp.name}
        style={{
          // New UX change
          // Reducing 2% of height to accommodate other spacing or padding and avoid additional scrollbars.
          height: "98%",
          width: "100%",
          background: "white",
          border: "none",
          boxShadow: "none"
        }}
        src={dataAppCodeEditorUrl}
      />
    </div>
  );
};

export default EditDataAppCode;
