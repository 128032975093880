import { map, some, toLower, trim } from "lodash";
import { useMemo } from "react";
import { useProjectsStore } from "stores/zustand/stores";

export const useProjectNameSuggestion = ({ currName }: { currName: string }) => {
  const [projectList] = useProjectsStore((state) => [state.projectList]);

  const projectNameList = useMemo(() => map(projectList, "name") as string[], [projectList]);

  const generateSuggestion = (name: string): string[] => {
    const suggestions: string[] = [];
    let counter = 1;
    const maxNameLength = 64 - counter.toString().length;
    const baseName = name.length > maxNameLength ? name.slice(0, maxNameLength) : name;
    while (suggestions.length < 3) {
      const newName = `${baseName}${counter}`;
      if (!projectNameList.includes(newName)) {
        suggestions.push(newName);
      }
      counter++;
    }

    return suggestions;
  };

  const suggestionsList = useMemo(
    () =>
      some(projectNameList, (projectName) => toLower(projectName) === toLower(trim(currName)))
        ? generateSuggestion(currName)
        : [],
    [currName, projectNameList]
  );

  return {
    projectNameList,
    suggestionsList
  };
};
