import React, { useMemo } from "react";
import { Link } from "react-router-dom";

import { Typography, makeStyles } from "@material-ui/core";

import { dateFormat } from "utils/helpers/date.helpers";

import { DataSourceTabKeys } from "../utils/DataSources.constants";
import CommonLoader from "src/components/CommonLoader";
import { Table } from "src/components/custom/TableV8/Table";
import { CellContext } from "@tanstack/react-table";

const useStyles = makeStyles(() => ({
  root: {},
  link: {
    color: "#003656",
    background: "none",
    border: "none",
    "&:hover": {
      color: "#003656",
      textDecoration: "underline"
    }
  }
}));

const Datasets = (props: $TSFixMe) => {
  const { title, isDataSourceUsageLoading, usageDatasets, tab } = props || {};

  const classes = useStyles();

  const columns = useMemo(
    () => [
      ...(tab === DataSourceTabKeys.Jobs
        ? [
            {
              id: "Scheduler Name",
              accessorKey: "jobName",
              header: "Scheduler Name",
              cell: ({ row }: $TSFixMe) => {
                const { projectId, jobId, jobName } = row?.original || {};

                return !!projectId && !!jobId ? (
                  <Link className={classes.link} to={`/projects/${projectId}/jobs/${jobId}`}>
                    <Typography variant="subtitle2">{jobName}</Typography>
                  </Link>
                ) : (
                  jobName
                );
              }
            }
          ]
        : []),
      {
        id: "Dataset Name",
        accessorKey: "entityName",
        header: "Dataset Name",
        cell: ({ row }: CellContext<any, unknown>) => {
          const { projectId, jobId, defaultScenarioId, entityId, entityName } = row?.original || {};

          let path = "";
          if (tab === DataSourceTabKeys.Datasets) {
            if (!!projectId && !!defaultScenarioId && !!entityId) {
              path = `/view-data/projects/${projectId}/entity/${entityId}/scenario/${defaultScenarioId}/data`;
            }
          }

          if (tab === DataSourceTabKeys.Jobs) {
            if (!!projectId && !!jobId) {
              path = `/projects/${projectId}/jobs/${jobId}`;
            }
          }
          return !!path ? (
            <Link className={classes.link} to={path}>
              <Typography variant="subtitle2">{entityName}</Typography>
            </Link>
          ) : (
            entityName
          );
        }
      },
      {
        id: "Project Name",
        accessorKey: "projectName",
        header: "Project Name",
        cell: ({ row }: CellContext<any, unknown>) => {
          const { projectId, projectName } = row?.original;
          // eslint-disable-next-line no-extra-boolean-cast
          return !!projectId ? (
            <Link className={classes.link} to={`/projects/${projectId}`}>
              <Typography variant="subtitle2">{projectName}</Typography>
            </Link>
          ) : (
            projectName
          );
        }
      },
      ...(tab === DataSourceTabKeys.Datasets
        ? [
            {
              id: "Dataset Created on",
              accessorKey: "entityCreated",
              header: "Dataset Created on",

              cell: ({ getValue }: CellContext<any, unknown>) => (
                <span>{dateFormat(getValue())}</span>
              )
            }
          ]
        : []),
      ...(tab === DataSourceTabKeys.Jobs
        ? [
            {
              id: "Scheduler Created on",
              accessorKey: "jobCreated",
              header: "Scheduler Created on",
              cell: ({ getValue }: CellContext<any, unknown>) => (
                <span>{dateFormat(getValue())}</span>
              )
            }
          ]
        : [])
    ],
    [tab]
  );

  return (
    <>
      {isDataSourceUsageLoading ? (
        <CommonLoader />
      ) : (
        <Table
          data={usageDatasets}
          columns={columns}
          actionHeader={{
            title,
            actions: <></>
          }}
          sortingProps={{
            orderByDefault:
              tab === DataSourceTabKeys.Jobs ? "Scheduler Created on" : "Dataset Created on"
          }}
          stylesProps={{ size: "small", isTheadSticky: true }}
        />
      )}
    </>
  );
};

export default Datasets;
