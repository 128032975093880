import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

import api from "services/AxiosClient/AxiosClient";
import { ModelEntityDto } from "@rapidcanvas/rc-api-core";

const QUERY_KEY_ALL_MODELS = "query-key-all-models";

export type Model = Pick<
  ModelEntityDto,
  "id" | "name" | "displayName" | "parentRecipeDisplayName" | "parentRecipeName" | "parentRecipeId"
> & {
  targetCol?: string;
};

export type SelectModel = Pick<
  ModelEntityDto,
  "id" | "name" | "displayName" | "parentRecipeDisplayName" | "parentRecipeName" | "parentRecipeId"
> & {
  targetCol?: string;
};

const useGetAllModels = (
  projectId?: string,
  options?: UseQueryOptions<ModelEntityDto[]>
): UseQueryResult<ModelEntityDto[]> => {
  return useQuery<any>({
    queryKey: [QUERY_KEY_ALL_MODELS, projectId],
    queryFn: async () => {
      if (!projectId) {
        return "projectId is absent";
      }

      const modelDetails = await api.fetchResponse(
        async () => await api.ProjectsControllerV2Api.getAllModelsWithInputDataSets(projectId, true)
      );

      return modelDetails;
    },
    ...options,
    refetchOnMount: true
  });
};

export default useGetAllModels;
export { QUERY_KEY_ALL_MODELS };
