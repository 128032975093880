import React, { useMemo, useState } from "react";
import _, { filter, map, size, find } from "lodash";
import {
  Button,
  CircularProgress,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Tooltip
} from "@material-ui/core";
import { UseQueryResult } from "@tanstack/react-query";

import EmojiEventsIcon from "@material-ui/icons/EmojiEvents";
import PredictPeriod from "./PredictPeriod";
import PredictRecipeIcon from "icons/NewUX/PredictRecipeIcon";
import SelectModel from "./SelectModel";
import UploadDataset from "./UploadDataset";
import useGetAllProjectModels from "src/hooks/api/projects/useGetAllProjectModels";
import { IParentNodeDetails } from "hooks/api/projects/useGetModelParentCanvas";
import { InfoOutlined } from "@material-ui/icons";
import { Spinner } from "src/components";
import { getNumberSuffix } from "../utils/PredictionJob.helpers";

interface IProps {
  projectId: string;
  loading: boolean;
  disabled: boolean;
  model: { id: string; name: string; targetCol: string };
  projectRunId: string;
  modelLoading: boolean;
  modelResponse: UseQueryResult<IParentNodeDetails[]>;
  onModelChange: (model: { id: string; name: string; targetCol: string }) => void;
  onPredict: (modelName: string, futurePeriod?: string) => void;
}

const ModelDatasetSelector: React.FC<IProps> = (props) => {
  const {
    projectId,
    loading,
    disabled,
    model,
    projectRunId,
    modelLoading,
    modelResponse,
    onPredict,
    onModelChange
  } = props;

  const allModelResponse = useGetAllProjectModels(projectId, {
    onSuccess: (results) => {
      const record = find(results, ({ id }) => id === model.id);
      if (model.id && record?.name) {
        handleModelChange(model.id, record?.name, record?.targetCol);
      }
    }
  });

  const isTimeSeriesRecipe = useMemo(() => {
    const record = find(allModelResponse.data, ({ id }) => id === model.id);

    return record?.isTimeSeriesParentRecipe;
  }, [allModelResponse.data, model.id]);

  const [count, setCount] = useState<number>(0);
  const [futurePeriod, setFuturePeriod] = useState(isTimeSeriesRecipe ? 2 : 0);

  const data = useMemo(
    () =>
      map(
        filter(modelResponse.data, ({ type, root }) => root === true && type === "ENTITY"),
        ({ id, name, displayName }) => ({ id, name, displayName })
      ),
    [modelResponse.data]
  );

  const handleModelChange = (id: string, name: string, targetCol: string) => {
    onModelChange({ id, name, targetCol });
    setCount(0);
  };

  const handleCountChange = (change: 1 | -1) => {
    setCount((prevCount) => prevCount + change);
  };

  const handleClick = () => {
    if (futurePeriod) {
      onPredict(model.name, _.toString(futurePeriod));
    } else {
      onPredict(model.name);
    }
  };

  const isLoading = modelLoading || allModelResponse.isLoading;

  const showGenerate = useMemo(() => {
    if (isTimeSeriesRecipe) {
      return count === size(data) && !!futurePeriod;
    }

    return (!modelResponse.isLoading && !!data && count === size(data)) || disabled;
  }, [isTimeSeriesRecipe, futurePeriod, modelResponse.isLoading, data, disabled, count]);

  const combinedData = useMemo(
    () =>
      _.compact(
        _.values(_.merge(_.keyBy([model], "id"), _.keyBy(allModelResponse.data ?? [], "id")))
      ),
    [model, allModelResponse.data]
  );

  return (
    <Stepper orientation="vertical" data-testid="predict-job-stepper">
      <Step active key="model" data-testid="predict-job-stepper-select-model">
        <StepLabel>
          <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
            <EmojiEventsIcon />
            <span>Selected Model</span>
          </div>
        </StepLabel>
        <StepContent>
          <SelectModel
            disabled={true}
            data={combinedData}
            numOfDatasets={data?.length}
            value={model.id}
            projectId={projectId}
            onChange={handleModelChange}
          />
        </StepContent>
      </Step>
      {isLoading && (
        <Step active key="dataset" data-testid="predict-job-stepper-spinner">
          <StepLabel style={{ marginBottom: "12px" }}>Fetching Model Details</StepLabel>
          <StepContent>
            <Spinner padding={60} />
          </StepContent>
        </Step>
      )}
      {!isLoading &&
        map(data, ({ id, displayName }, index) => (
          <Step active key={id} data-testid={`predict-job-stepper-dataset-${id}`}>
            <StepLabel style={{ marginBottom: "12px" }}>{`Upload ${getNumberSuffix(
              index + 1
            )} Dataset `}</StepLabel>
            <StepContent>
              <UploadDataset
                targetCol={model.targetCol}
                entityId={id}
                disabled={disabled}
                projectId={projectId}
                name={displayName}
                projectRunId={projectRunId}
                onCountChange={handleCountChange}
              />
            </StepContent>
          </Step>
        ))}
      {!isLoading && isTimeSeriesRecipe && (
        <Step active key="futurePeriod" data-testid="predict-job-stepper-predict-period">
          <StepLabel>
            <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
              <span>Future Predict Period</span>
              <Tooltip title="The prediction frequency for the selected period will be automatically determined based on the corresponding time series recipe">
                <InfoOutlined fontSize="small" />
              </Tooltip>
            </div>
          </StepLabel>
          <StepContent>
            <PredictPeriod disabled={disabled} value={futurePeriod} onChange={setFuturePeriod} />
          </StepContent>
        </Step>
      )}
      {showGenerate && (
        <Step active key="predict">
          <StepLabel style={{ fontWeight: 400 }}>
            <Button
              variant="contained"
              size="small"
              data-testid="predict-job-generate-output-btn"
              disabled={disabled}
              startIcon={
                loading ? (
                  <CircularProgress size={16} style={{ color: "#fff" }} />
                ) : (
                  <PredictRecipeIcon />
                )
              }
              style={{ width: "100%" }}
              color="primary"
              onClick={handleClick}>
              {loading ? "Generating Prediction" : "Generate Prediction"}
            </Button>
          </StepLabel>
        </Step>
      )}
    </Stepper>
  );
};

export default ModelDatasetSelector;
