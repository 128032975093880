import React, { useState } from "react";
import { Button, CircularProgress } from "@material-ui/core";
import { useIsMutating } from "@tanstack/react-query";

import useReloadThread, { RELOAD_MUTATION_KEY } from "src/hooks/api/codeRecipe/useReloadThread";
import { Modal } from "src/components/custom";
import { ModalVariants } from "src/components/custom/Modal/Modal";

interface IProps {
  threadId: string;
  onReloadFinish: () => void;
}

const RefreshThreadButton: React.FC<IProps> = (props) => {
  const { threadId, onReloadFinish } = props;
  const reload = useReloadThread(threadId);
  const isMutating = useIsMutating({ mutationKey: [RELOAD_MUTATION_KEY, threadId] });
  const [open, setOpen] = useState(false);

  const handleReloadClick = () => {
    reload.mutate(
      { threadId },
      {
        onSuccess: () => {
          onReloadFinish();
          setOpen(false);
        }
      }
    );
  };

  const handleClick = () => {
    setOpen(true);
  };

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        color="primary"
        startIcon={reload.isLoading || !!isMutating ? <CircularProgress size={16} /> : undefined}
        disabled={reload.isLoading || !!isMutating}
        onClick={handleClick}>
        Refresh Chat
      </Button>
      {open && (
        <Modal
          open={open}
          variant={ModalVariants.Delete}
          title="Refresh Chat"
          content={[
            "This action will rerun all queries in the conversation history to fetch the latest results based on updated data in the input dataset(s) of this recipe. During this process, some previously successful queries might fail or return different responses.",
            "If needed, you may manually re-run any queries that require further attention.",
            "Are you sure you want to proceed?",
            <span>
              <b>Note:</b> This process may take some time to complete. Please do not refresh or
              navigate away from this page until it finishes.
            </span>
          ]}
          onClose={() => setOpen(false)}
          cancelLabel="No"
          onSubmit={handleReloadClick}
          isSubmitDisabled={reload.isLoading || !!isMutating}
          isSubmitting={reload.isLoading || !!isMutating}
        />
      )}
    </>
  );
};

export default RefreshThreadButton;
