import { Button, makeStyles, Menu, MenuItem } from "@material-ui/core";
import { useQueryClient } from "@tanstack/react-query";
import { useGetProjectCanvas, UseGetProjectCanvasQueryKeys } from "hooks/api";
import useGetPaginatedArtifacts from "hooks/api/artifacts/useGetPaginatedArtifacts";
import { ArtifactIcon } from "icons/NewUX/ArtifactIcon";

import _ from "lodash";
import CreateArtifact from "pages/Library/ArtifactsAndModels/CreateArtifact";
import { DatasetMenuKeys } from "pages/Projects/CanvasFlow/AddMenu";
import { AddArtifactsModal } from "pages/Projects/common/AddArtifactsModal";
import { NodeTypeNames } from "projectsModule/utils";
import React, { useMemo, useState } from "react";
import { handleResponse } from "services/Apis/Apis.service";
import { createEntityWithRethrow } from "services/Apis/wrappers";
import { useCanvasStore } from "stores/zustand/stores";
import shallow from "zustand/shallow";

interface IProps {
  projectId?: string;
  isAddDrawer?: boolean;
}

const useStyles = makeStyles({
  btnLabel: {
    justifyContent: "flex-start",
    fontWeight: 400
  }
});

const AddProjectArtifactButton: React.FC<IProps> = ({ projectId, isAddDrawer = false }) => {
  const [setReloadTrigger] = useCanvasStore((state) => [state.setReloadTrigger], shallow);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();

  const classes = useStyles();
  const [isAddArtifactModalOpen, setIsAddArtifactModalOpen] = useState(false);
  const { data: paginatedArtifactsData } = useGetPaginatedArtifacts(undefined, {
    enabled: showCreateModal
  });

  const projectCanvasData = useGetProjectCanvas({
    projectId,
    refetchOnMount: true,
    enabled: !!projectId
  });
  const { artifactNodes } = useMemo(() => {
    const groupBy = _.groupBy(projectCanvasData.data?.nodes, (node) =>
      (node as $TSFixMe)?.type?.toLowerCase()
    );

    return {
      artifactNodes: _.get(groupBy, "artifact", []),
      modelNodes: _.get(groupBy, "model", [])
    };
  }, [projectCanvasData.data]);

  const createEntities = React.useCallback(
    async (entityNames: Array<string>, entityViewType: string) => {
      const entityBody = {
        entityMeta: {
          entityViewType,
          entityType: "EVENT"
        },
        projectId
      };

      try {
        await Promise.all(
          entityNames.map(async (artifactName: string) => {
            return await createEntityWithRethrow({
              ...entityBody,
              name: artifactName
            });
          })
        );
        queryClient.invalidateQueries({ queryKey: [UseGetProjectCanvasQueryKeys.ProjectCanvas] });

        setReloadTrigger();
      } catch (error: $TSFixMe) {
        handleResponse({
          errorMessage: error.response?.data?.msg || error.message || "Error in adding artifacts"
        });
      }
    },
    [projectId, setReloadTrigger]
  );

  const onAddArtifacts = React.useCallback(
    async (artifactNames: Array<string>) => {
      const existingArtifactNames = artifactNodes?.map((artifact: $TSFixMe) => artifact.name);
      const newArtifacts = artifactNames.filter((name) => !existingArtifactNames.includes(name));
      setLoading(true);
      await createEntities(newArtifacts, "ARTIFACT");
      setLoading(false);
      setIsAddArtifactModalOpen(false);
    },
    [artifactNodes, createEntities]
  );

  const handleOpenCreationModal = () => {
    setShowCreateModal(true);
  };

  const handleClose = () => {
    setShowCreateModal(false);
  };
  const handleCreateSuccess = () => {
    queryClient.invalidateQueries({ queryKey: [UseGetProjectCanvasQueryKeys.ProjectCanvas] });
  };

  return (
    <>
      {showCreateModal && (
        <CreateArtifact
          open={true}
          onClose={handleClose}
          artifactList={paginatedArtifactsData?.data ?? []}
          projectId={projectId}
          onSuccess={handleCreateSuccess}
        />
      )}
      <AddArtifactsModal
        loading={loading}
        open={isAddArtifactModalOpen}
        selectedArtifacts={artifactNodes as any}
        shouldDisableInitialSelectedRows
        onAddArtifacts={onAddArtifacts}
        onClose={() => setIsAddArtifactModalOpen(false)}
      />

      {isAddDrawer ? (
        <Button
          key={DatasetMenuKeys.Artifact}
          classes={{ label: classes.btnLabel }}
          style={{ borderBottom: "none", width: "100%" }}
          startIcon={<ArtifactIcon width={20} height={20} />}
          onClick={!!projectId ? (e) => setAnchorEl(e.currentTarget) : handleOpenCreationModal}>
          {NodeTypeNames.Artifact}
        </Button>
      ) : (
        <Button
          color="primary"
          variant="outlined"
          size="medium"
          style={{ width: "fit-content" }}
          onClick={!!projectId ? (e) => setAnchorEl(e.currentTarget) : handleOpenCreationModal}>
          + Artifact
        </Button>
      )}

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
          style: { borderRadius: "20px" }
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        getContentAnchorEl={null}>
        <MenuItem
          style={{ fontSize: "14px" }}
          onClick={() => {
            handleOpenCreationModal();
            setAnchorEl(null);
          }}>
          Create New artifact
        </MenuItem>

        <MenuItem
          style={{ fontSize: "14px" }}
          onClick={() => {
            setIsAddArtifactModalOpen(true);
            setAnchorEl(null);
          }}>
          Add Existing
        </MenuItem>
      </Menu>
    </>
  );
};

export default AddProjectArtifactButton;
