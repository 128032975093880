import React from "react";
import PreviewImageModal from "../ProjectSettings/PreviewImageModal";
import useUpdateProject from "src/hooks/api/projects/useUpdateProject";
import { handleResponse } from "services/Apis/Apis.service";

export const ProjectImageModal = ({
  project,
  handleClose,
  onSuccess
}: {
  project: any;
  handleClose: () => void;
  onSuccess?: () => void;
}) => {
  const updateProjectMutation = useUpdateProject();

  const handleSubmit = (imageBase64: any) => {
    const payload = {
      ...project,
      image: imageBase64?.split(";base64,")[1] || imageBase64
    };

    updateProjectMutation.mutate(payload, {
      onSettled: () => {
        handleClose();
      },
      onSuccess: () => {
        handleResponse({ successMessage: `Project Image Updated Successfully.` });
        onSuccess?.();
      }
    });
  };

  const img = project?.image;

  let currentImgSrc = "";

  if (img) {
    if (/^(http|https):\/\//.test(img)) {
      currentImgSrc = img;
    } else if (img?.split(";base64,")?.pop()) {
      // It's a plain URL
      currentImgSrc = `data:image/jpeg;base64,${img.split(";base64,").pop()}`;
    } else {
      // Handle any other case if needed (e.g., default image or error handling)
      currentImgSrc = "";
    }
  }

  return (
    <PreviewImageModal
      isSubmitting={updateProjectMutation.isLoading}
      previewType={"project"}
      onSubmit={handleSubmit}
      handleClose={handleClose}
      currentImgSrc={currentImgSrc}
    />
  );
};
