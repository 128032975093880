import React from "react";

// Packages
import { Link } from "react-router-dom";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";

// Utils
import { RenderText } from "components/custom";

type Props = {
  project: $TSFixMe;
};

const SubTopNavBarBreadcrumbs = (props: Props) => {
  const { project } = props || {};

  const breadcrumbs: React.ReactNode[] = [];

  if (!!project?.id) {
    breadcrumbs.push(
      <Link key="projects" to={`/projects/${project?.id}/canvas`} data-testid="projectBreadcrumb">
        <RenderText>{project?.name || "Project"}</RenderText>
      </Link>
    );

    breadcrumbs.push(
      <Link
        key="project-dataapp"
        to={`/projects/${project?.id}/project-dataapps`}
        data-testid="artifactAndModelTitleBreadcrumb">
        <RenderText>Artifacts & Models</RenderText>
      </Link>
    );
  }

  return (
    <Breadcrumbs>
      {breadcrumbs?.map((eachBreadcrumb: React.ReactNode) => eachBreadcrumb)}
    </Breadcrumbs>
  );
};

export default SubTopNavBarBreadcrumbs;
