import _ from "lodash";

import { FivetranStatus } from "src/pages/private/ProjectsModule/pages/Dataset/utils/Dataset.constants";
import { SYNC_ID } from "pages/DataSources/DataSources";

export interface IThirdPartyDataSourceStatus {
  schemaStatus: string;
  setupState: string;
  syncState: string;
  historicalSync: boolean;
}

export const SYNCING_LIMIT_IN_SECONDS = 2 * 60;

const getFivetranConnectorStatus = (
  thirdPartyDataSourceStatus?: IThirdPartyDataSourceStatus,
  isSameConnector?: boolean,
  created?: number
) => {
  if (!thirdPartyDataSourceStatus) {
    return FivetranStatus.active;
  }

  if (isSameConnector && created && (Date.now() - created) / 1000 < SYNCING_LIMIT_IN_SECONDS) {
    return FivetranStatus.syncing;
  }

  const { historicalSync, schemaStatus, setupState, syncState } = thirdPartyDataSourceStatus;

  if (setupState === "connected" && schemaStatus === "ready") {
    if (historicalSync === false && syncState === "scheduled") {
      return FivetranStatus.active;
    }

    if (historicalSync === true) {
      switch (syncState) {
        case "syncing":
        case "scheduled":
          return FivetranStatus.syncing;
        case "rescheduled":
          return FivetranStatus.syncIssues;
        case "paused":
          return FivetranStatus.syncPaused;
      }
    }
  }

  return FivetranStatus.inActive;
};

const getStatusColor = (status: FivetranStatus): string => {
  if (_.includes([FivetranStatus.active, FivetranStatus.syncing], status)) {
    return "#008000";
  }

  if (status === FivetranStatus.inActive) {
    return "#ff0000";
  }

  return "#cb8f0a";
};

const getStatusType = (status: FivetranStatus): string => {
  if (_.includes([FivetranStatus.active, FivetranStatus.syncing], status)) {
    return "success";
  }

  if (status === FivetranStatus.inActive) {
    return "error";
  }

  return "warning";
};

const getRedirectUrl = (name: string) => {
  return `${window.location.origin}/data-connectors?${encodeURIComponent(`${SYNC_ID}=${name}`)}`;
};

export { getFivetranConnectorStatus, getStatusColor, getRedirectUrl, getStatusType };
