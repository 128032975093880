import React from "react";
import { useDrawerStore } from "stores/zustand/stores";
import { sideComponentSetter } from "stores/zustand/stores.selectors";
import {
  getErrorMessage,
  handleErrorWithRedirectToLogs,
  handleResponse
} from "services/Apis/Apis.service";
import { RecipeLogs } from "../ShowLogsModal/RecipeLogs";
import { useShowLogsModal } from "../ShowLogsModal/useShowLogsModal";
import { Grid } from "@material-ui/core";

export const useTransformErrorUtils = () => {
  const { openLogsSideBar } = useShowLogsModal();
  const setSideComponent = useDrawerStore(sideComponentSetter);

  const fetchAndRenderFullErrorLogs = (
    error: $TSFixMe,
    recipeData?: $TSFixMe,
    showField = true,
    shouldCombineErrors = false
  ) => {
    const baseErrorMsg = error?.response?.data?.msg || error?.message;
    const errorEntries = error?.response?.data?.entries?.filter(
      (entry: any) => entry?.extraInfo?.length > 0
    );
    if (shouldCombineErrors && errorEntries?.length > 0) {
      const combinedErrorMsg =
        errorEntries
          ?.map((errorEntry: $TSFixMe) => {
            const errorMessage =
              errorEntry?.extraInfo?.reduce(
                (extraInfoKeys: string, info: $TSFixMe) =>
                  [extraInfoKeys, ...Object.keys(info)].filter((val: any) => !!val).join(","),
                ""
              ) ?? "";
            return errorMessage;
          })
          ?.join("\n") || "";
      const concatenatedMsg = (
        <Grid style={{ whiteSpace: "pre-wrap" }}>
          {baseErrorMsg?.trimEnd()}
          <br />
          {combinedErrorMsg}
        </Grid>
      );
      handleErrorWithRedirectToLogs(concatenatedMsg, (event) => {
        if (recipeData) {
          setSideComponent({
            sideComponent: RecipeLogs,
            sideComponentProps: {
              ...recipeData,
              noHeader: true
            }
          });
        } else {
          event?.preventDefault();
          event?.stopPropagation();
          openLogsSideBar(concatenatedMsg, "error");
        }
      });
    } else if (errorEntries?.length > 0) {
      errorEntries?.map((errorEntry: $TSFixMe) => {
        errorEntry.extraInfo?.length > 0 &&
          handleErrorWithRedirectToLogs(
            `${baseErrorMsg}${
              showField && errorEntry?.field ? ` - ${errorEntry.field}` : ""
            }\n`.concat(
              errorEntry?.extraInfo?.reduce(
                (extraInfoKeys: string, info: $TSFixMe) =>
                  [extraInfoKeys, ...Object.keys(info)].join("\n"),
                ""
              ) ?? ""
            ),
            (event) => {
              if (recipeData) {
                setSideComponent({
                  sideComponent: RecipeLogs,
                  sideComponentProps: {
                    ...recipeData,
                    noHeader: true
                  }
                });
              } else {
                event?.preventDefault();
                event?.stopPropagation();
                const errorLogs = errorEntry?.extraInfo?.reduce(
                  (allEntries: string[], info: $TSFixMe) => [...allEntries, ...Object.keys(info)],
                  []
                );
                openLogsSideBar(errorLogs, "error");
              }
            }
          );
      });
    } else {
      handleResponse({ errorMessage: getErrorMessage(error) });
    }
  };

  const extractErrorMessage = (errorEntry: any, baseErrorMsg: string, showField: boolean) => {
    const errorMessage = `${baseErrorMsg?.trimEnd()}${
      showField && errorEntry?.field
        ? ` - ${errorEntry.field}`
        : `${baseErrorMsg.endsWith(".") ? "" : " - "}`
    }\n`.concat(
      errorEntry?.extraInfo?.reduce(
        (extraInfoKeys: string, info: $TSFixMe) => [extraInfoKeys, ...Object.keys(info)].join("\n"),
        ""
      ) ?? ""
    );
    return errorMessage;
  };
  const renderTestTransformErrors = (
    error: $TSFixMe,
    showField = true,
    shouldCombineErrors = false
  ) => {
    const baseErrorMsg = error?.response?.data?.msg || error?.message;
    const errorEntries = error?.response?.data?.entries?.filter(
      (entry: any) => entry?.extraInfo?.length > 0
    );
    if (shouldCombineErrors && errorEntries.length > 0) {
      const combinedErrorMsg =
        errorEntries
          ?.map((errorEntry: $TSFixMe) => {
            const errorMessage =
              errorEntry?.extraInfo?.reduce(
                (extraInfoKeys: string, info: $TSFixMe) =>
                  [extraInfoKeys, ...Object.keys(info)].filter((val: any) => !!val).join(", "),
                ""
              ) ?? "";
            return errorMessage;
          })
          ?.join("\n") || "";
      handleResponse({
        errorMessage: (
          <Grid style={{ whiteSpace: "pre-wrap" }}>
            {baseErrorMsg?.trimEnd()}
            <br />
            {combinedErrorMsg}
          </Grid>
        )
      });
    } else if (errorEntries.length > 0) {
      errorEntries?.map((errorEntry: $TSFixMe) => {
        const errorMessage = extractErrorMessage(errorEntry, baseErrorMsg, showField);
        handleResponse({ errorMessage });
      });
    } else {
      handleResponse({ errorMessage: getErrorMessage(error) });
    }
  };
  return { fetchAndRenderFullErrorLogs, renderTestTransformErrors };
};
