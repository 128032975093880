import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { EntityAuditHistoryDto } from "@rapidcanvas/rc-api-core";

import api from "services/AxiosClient/AxiosClient";

export const CACHE_QUERY_KEY_FETCH_DATASET_AUDIT_HISTORY = "fetch-dataset-audit_history";

const useEntityAuditHistory = (
  entityId: string,
  options?: UseQueryOptions<any, Error>
): UseQueryResult<EntityAuditHistoryDto[], Error> => {
  return useQuery<any, Error>({
    queryKey: [CACHE_QUERY_KEY_FETCH_DATASET_AUDIT_HISTORY, entityId],
    queryFn: async () => {
      const data = await api.fetchResponse(
        async () => await api.EntityControllerApi.findAuditHistory(entityId)
      );

      return data;
    },
    ...options
  });
};

export default useEntityAuditHistory;
