import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";

import api from "services/AxiosClient/AxiosClient";
import { updateEnvironmentsQueryData } from "src/utils/helpers";

import { isConnectorDatasets } from "src/pages/private/ProjectsModule/utils";
import { UseGetConnectorsQueryKeys } from "../dataSources/useGetConnectors";

interface IPayload {
  projectId?: string;
}

const useDeleteProject = (): UseMutationResult<unknown, unknown, IPayload, unknown> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ projectId }: IPayload) => {
      if (!projectId) {
        throw "Invalid payload!";
      }

      try {
        const projectData = await api.ProjectControllerApi.findProjectById(projectId, true);
        const envId = projectData?.data?.[0]?.envId;

        const projectCanvasData = await api.fetchResponse(
          async () => await api.ProjectsControllerV2Api.getProjectCanvas(projectId, true)
        );

        const response = await api.fetchResponse(
          async () => await api.ProjectControllerApi.deleteProjects(projectId, true)
        );

        if (!!envId) {
          updateEnvironmentsQueryData({
            queryClient,
            data: { id: envId },
            fetchData: true
          });
        }

        if (!!isConnectorDatasets(projectCanvasData)) {
          queryClient.refetchQueries([UseGetConnectorsQueryKeys.Connectors]);
        }

        return response;
      } catch (error) {
        throw error;
      }
    }
  });
};

export default useDeleteProject;
