/* eslint-disable no-unused-vars */
import { QueryClient, useMutation, useQueryClient } from "@tanstack/react-query";
import { runTransformGroupWithRethrow } from "services/Apis/wrappers";
import { QUERY_KEY_MODELS } from "../models";
import { QUERY_KEY_ARTIFACTS } from "../artifacts";

export const clearArtifactCache = async (queryClient: QueryClient) => {
  await queryClient.invalidateQueries({ queryKey: [QUERY_KEY_ARTIFACTS], refetchType: "all" });
};

export const clearModelCache = async (queryClient: QueryClient) => {
  await queryClient.invalidateQueries({ queryKey: [QUERY_KEY_MODELS], refetchType: "all" });
};

export const clearRunTransformGroupCache = (queryClient: QueryClient) => {
  clearArtifactCache(queryClient);
  clearModelCache(queryClient);
};

export const useRunTransformGroupMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ recipeId, scenarioId }: { recipeId: string; scenarioId: string }) => {
      return runTransformGroupWithRethrow(recipeId, scenarioId, {}, false);
    },
    onSuccess: () => clearRunTransformGroupCache(queryClient),
    cacheTime: 0
  });
};
