import React, { useCallback, useState, useMemo, useEffect } from "react";

// Packages
import { useNavigate } from "react-router-dom";
import shallow from "zustand/shallow";
import axios from "axios";
import { useForm } from "react-hook-form";
import { capitalize, isEmpty, map, startCase, trim } from "lodash";

// MUI
import {
  Grid,
  Paper,
  List,
  ListItem,
  ListItemText,
  FormControlLabel,
  Button,
  TextField,
  Checkbox,
  Link,
  CircularProgress,
  Typography
} from "@material-ui/core";

// Icons
import { Alert } from "@material-ui/lab";
import rapidCanvasLogo from "src/assets/images/rc-logo-dark-text.svg";

// Utils
import api from "services/AxiosClient/AxiosClient";

// Stores
import useNotificationStore from "stores/zustand/notification.store";
import { shouldRefreshProjectsToggler } from "stores/zustand/stores.selectors";
import { useProjectsStore } from "stores/zustand/stores";

// Hooks
import { useAuthSetAuthResponse } from "src/hooks/useAuthSetAuthResponse";

// API Hooks
import useQuery from "src/hooks/useQuery";

// APIs
import { postAPI } from "services/Apis/Apis.service";

// Components
import PasswordInput from "src/components/PasswordInput";

// Constants
import { PatternRexExps, PublicScreenLabels } from "constants/index";
import { loginPath } from "src/routing/routes";

// Styles
import { useStyles } from "./SignUp.styles";
import { useResolveSSOAuthMutation } from "src/hooks/api/auth/useResolveSSOAuthMutation";
import AppLoadingScreen from "src/components/Screens/AppLoadingScreen";

const SignUp = () => {
  const classes = useStyles();
  const [isInitializing, setIsInitializing] = useState(true);

  // @REFACTOR
  // Old code - STARTS >>
  const navigate = useNavigate();

  const toggleShouldProjectsRefresh = useProjectsStore(shouldRefreshProjectsToggler);

  const { setAuthResponse } = useAuthSetAuthResponse();
  const setNotification = useNotificationStore(
    useCallback((state: any) => state.setNotification, []),
    shallow
  );

  const queryParameters = useQuery();

  const [agreement, setAgreement] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [isSigningUp, setIsSigningUp] = useState(false);

  const [isPasswordRequiredErrorVisible, setIsPasswordRequiredErrorVisisble] = useState(false);
  const [isEmailInvalidFormatErrorVisible, setIsEmailInvalidFormatErrorVisible] = useState(false);
  const [isEmailRequiredErrorVisible, setIsEmailRequiredErrorVisible] = useState(false);
  const [isEmailOccupiedErrorVisible, setIsEmailOccupiedErrorVisible] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors }
  } = useForm({ defaultValues: { email: "", password: "", password_repeat: "" } });

  const formValueEmail = watch("email");
  const formValuePassword = watch("password");
  const formValueConfirmPassword = watch("password_repeat");

  const clearBEErrors = () => {
    setIsPasswordRequiredErrorVisisble(false);
    setIsEmailInvalidFormatErrorVisible(false);
    setIsEmailRequiredErrorVisible(false);
    setIsEmailOccupiedErrorVisible(false);
    setErrorMessage(null);
  };

  const resolveSSOAuthMutation = useResolveSSOAuthMutation();
  const invitationRequestDetails = queryParameters.get("invitation_details");
  useEffect(() => {
    resolveSSOAuthMutation.mutate(
      {
        ...(invitationRequestDetails ? { invitationRequestDetails } : {})
      },
      {
        onSuccess: (response) => {
          if (response.status === 203 && response.headers["location"]) {
            window.location.href = response.headers["location"];
            return;
          }
        },
        onSettled: () => {
          setIsInitializing(false);
        }
      }
    );
  }, [invitationRequestDetails]);

  const handleCredentialsSubmit = async (data: $TSFixMe) => {
    try {
      setIsSigningUp(true);

      setIsPasswordRequiredErrorVisisble(false);
      setIsEmailInvalidFormatErrorVisible(false);
      setIsEmailRequiredErrorVisible(false);
      setIsEmailOccupiedErrorVisible(false);

      const formData = new FormData();
      formData.append("email", data.email);
      formData.append("password", data.password);
      if (invitationRequestDetails) {
        formData.append("invitationDetails", invitationRequestDetails);
      }

      const response = await axios.post("/api/register/local", formData);

      if (response.status === 200) {
        const data = response.data;

        setAuthResponse({
          data
        });
        setIsSigningUp(false);
        api.init(response.data?.token);
        // @ts-expect-error TS(2554) FIXME: Expected 2 arguments, but got 1.
        postAPI("/v2/user-eula/");
        toggleShouldProjectsRefresh();
        setNotification({ type: "Dashboard", message: "Welcome to RapidCanvas" });
        navigate("/");
      }
    } catch (e: $TSFixMe) {
      if (e.response.status === 400) {
        if (e.response.data.email === "Email cannot be empty") {
          setIsEmailRequiredErrorVisible(true);
        } else if (e.response.data.email === "Email cannot have invalid format") {
          setIsEmailInvalidFormatErrorVisible(true);
        } else if (e.response.data.password) {
          setIsPasswordRequiredErrorVisisble(true);
        } else {
          setErrorMessage(e.response?.data?.msg || e.message);
        }
      } else if (e.response.status === 409) {
        setIsEmailOccupiedErrorVisible(true);
      } else {
        setErrorMessage(e.response?.data?.msg || e.message);
      }
      setIsSigningUp(false);
    }
  };

  const handleAgreementChange = (e: $TSFixMe) => {
    setAgreement(e.target.checked);
  };

  const onSignInClick = () => {
    const invitation_details = queryParameters.get("invitation_details");
    navigate({
      pathname: loginPath,
      search: invitation_details ? undefined : location.search
    });
  };
  // << ENDS - Old code

  const boldText = (text: string) => (
    <Typography variant="inherit" style={{ fontWeight: 500 }}>
      {text}
    </Typography>
  );

  // @REFACTOR
  // Can be refactored with a better approach from react-hook-form.
  const isAllFieldsEmpty = useMemo(
    () => !(!!formValueEmail && !!formValuePassword && !!formValueConfirmPassword),
    [formValueEmail, formValuePassword, formValueConfirmPassword]
  );

  const emailIdError = useMemo(() => {
    const errorsList = [];

    if (errors?.email?.type === "pattern" || !!isEmailInvalidFormatErrorVisible) {
      errorsList.push(`${startCase(PublicScreenLabels.Email)} format is invalid!`);
    }

    if (errors?.email?.type === "required" || !!isEmailRequiredErrorVisible) {
      errorsList.push(`${startCase(PublicScreenLabels.Email)} is required!`);
    }

    !!isEmailOccupiedErrorVisible &&
      errorsList.push(`${startCase(PublicScreenLabels.Email)} is already in use!`);

    if (!isEmpty(errorsList)) {
      return (
        <List dense disablePadding>
          {map(errorsList, (error: string | $TSFixMe, index: number) => (
            <ListItem key={`emailError-${index}`} disableGutters>
              <ListItemText primary={error} disableTypography style={{ marginTop: 0 }} />
            </ListItem>
          ))}
        </List>
      );
    }

    return "";
  }, [
    errors?.email?.type,
    isEmailInvalidFormatErrorVisible,
    isEmailRequiredErrorVisible,
    isEmailOccupiedErrorVisible
  ]);

  const passwordError = useMemo(() => {
    const errorsList = [];

    if (errors?.password?.type === "required" || !!isPasswordRequiredErrorVisible) {
      errorsList.push(`${startCase(PublicScreenLabels.Password)} is required!`);
    }

    if (errors?.password?.type === "pattern") {
      errorsList.push(
        `${startCase(
          PublicScreenLabels.Password
        )} should be at least 9 characters long, contain at least one lowercase, one uppercase, one digit and one special character.`
      );
    }

    if (!isEmpty(errorsList)) {
      return (
        <List dense disablePadding>
          {map(errorsList, (error: string | $TSFixMe, index: number) => (
            <ListItem key={`passwordError-${index}`} disableGutters>
              <ListItemText primary={error} disableTypography style={{ marginTop: 0 }} />
            </ListItem>
          ))}
        </List>
      );
    }

    return "";
  }, [errors?.password?.type, isPasswordRequiredErrorVisible]);

  const confirmPasswordError = useMemo(() => {
    const errorsList = [];

    if (errors?.password_repeat?.type === "validate" && !!errors?.password_repeat?.message) {
      errorsList.push(errors?.password_repeat?.message);
    }

    if (!isEmpty(errorsList)) {
      return (
        <List dense disablePadding>
          {map(errorsList, (error: string | $TSFixMe, index: number) => (
            <ListItem key={`confirmPasswordError-${index}`} disableGutters>
              <ListItemText primary={error} disableTypography style={{ marginTop: 0 }} />
            </ListItem>
          ))}
        </List>
      );
    }

    return "";
  }, [errors?.password_repeat?.type, errors?.password_repeat?.message]);

  const signUpError = useMemo(() => {
    const errorsList: $TSFixMe[] = [];

    !!errorMessage && errorsList.push(errorMessage);

    if (!isEmpty(errorsList)) {
      return (
        <List dense disablePadding>
          {map(errorsList, (error: string | $TSFixMe, index: number) => (
            <ListItem key={`signUpError-${index}`} disableGutters>
              <ListItemText primary={error} disableTypography style={{ margin: 0 }} />
            </ListItem>
          ))}
        </List>
      );
    }

    return "";
  }, [errorMessage]);

  if (
    isInitializing ||
    resolveSSOAuthMutation.isLoading ||
    resolveSSOAuthMutation.data?.status === 203
  ) {
    return <AppLoadingScreen requestPending />;
  }

  return (
    <>
      <Grid container className={classes.page}>
        <Grid container className={classes.section}>
          <Paper variant="outlined" className={classes.root}>
            <Grid container direction="column" className={classes.container}>
              <Grid item className={classes.rapidCanvasLogoContainer}>
                <img
                  src={rapidCanvasLogo}
                  width={250}
                  alt="RapidCanvas logo"
                  data-testid="signUpRapidCanvasLogo"
                />
              </Grid>
              <Grid item className={classes.titleContainer}>
                <Typography variant="h5" component="div" align="center" data-testid="signUpTitle">
                  {startCase(PublicScreenLabels.SignUp)}
                </Typography>
              </Grid>
              <Grid item className={classes.messageContainer}>
                <Typography
                  variant="h6"
                  component="div"
                  align="center"
                  style={{ fontWeight: 400 }}
                  data-testid="signUpMessageLine1">
                  Welcome to {boldText("RapidCanvas")}
                </Typography>
              </Grid>

              <Grid
                container
                component="form"
                className={classes.formContainer}
                onChange={clearBEErrors}
                onSubmit={handleSubmit(handleCredentialsSubmit)}>
                <TextField
                  id="signUpEmail"
                  data-testid="signUpEmail"
                  label={startCase(PublicScreenLabels.Email)}
                  variant="outlined"
                  size="small"
                  color="primary"
                  fullWidth
                  // eslint-disable-next-line
                  autoFocus
                  autoComplete="off"
                  disabled={!!isSigningUp}
                  {...register("email", {
                    required: true,
                    pattern: PatternRexExps.TrimmedEmail,
                    onBlur: (event) => {
                      setValue("email", trim(event?.target?.value), { shouldValidate: true });
                    }
                  })}
                  {...(!!emailIdError
                    ? {
                        error: true,
                        helperText: emailIdError
                      }
                    : {})}
                />
                <PasswordInput
                  // @ts-ignore
                  id="signUpPassword"
                  data-testid="signUpPassword"
                  variant="outlined"
                  size="small"
                  color="primary"
                  fullWidth
                  autoComplete="off"
                  disabled={!!isSigningUp}
                  {...register("password", {
                    required: true,
                    pattern: PatternRexExps.Password
                  })}
                  {...(!!passwordError
                    ? {
                        error: true,
                        helperText: passwordError
                      }
                    : {})}
                />
                <PasswordInput
                  // @ts-ignore
                  id="signUpConfirmPassword"
                  data-testid="signUpConfirmPassword"
                  variant="outlined"
                  size="small"
                  color="primary"
                  fullWidth
                  autoComplete="off"
                  label={capitalize(PublicScreenLabels.ConfirmPassword)}
                  disabled={!!isSigningUp}
                  {...register("password_repeat", {
                    validate: (value) => {
                      return value === formValuePassword || "The passwords do not match!";
                    }
                  })}
                  {...(!!confirmPasswordError
                    ? {
                        error: true,
                        helperText: confirmPasswordError
                      }
                    : {})}
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      id="signUpAgreementCheck"
                      data-testid="signUpAgreementCheck"
                      size="small"
                      color="default"
                      checked={agreement}
                      onChange={handleAgreementChange}
                    />
                  }
                  label={
                    <Typography variant="body2" data-testid="signUpTermsOfServiceMessage">
                      By signing up you agree to our{" "}
                      <Link
                        id="signUpTermsOfServiceLink"
                        data-testid="signUpTermsOfServiceLink"
                        color="primary"
                        href="https://rapidcanvas.ai/services-agreement/"
                        target="_blank"
                        rel="noreferrer">
                        Terms of service
                      </Link>
                    </Typography>
                  }
                />

                <Button
                  id="signUpAction"
                  data-testid="signUpAction"
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="small"
                  fullWidth
                  className={classes.action}
                  disabled={!!isSigningUp || isAllFieldsEmpty || !agreement}>
                  {!!isSigningUp ? (
                    <>
                      <CircularProgress size={16} style={{ marginRight: 5 }} />
                      Signing up...
                    </>
                  ) : (
                    startCase(PublicScreenLabels.SignUp)
                  )}
                </Button>

                {!!signUpError && (
                  <Alert
                    variant="outlined"
                    severity="error"
                    className={classes.errorContainer}
                    data-testid="signUpErrorContainer">
                    <Typography variant="caption">{signUpError}</Typography>
                  </Alert>
                )}
              </Grid>
            </Grid>
          </Paper>

          <Grid
            container
            justifyContent="center"
            alignItems="center"
            className={classes.signInMessageContainer}
            data-testid="signInMessageContainer">
            <Typography variant="body2">Already Existing User?</Typography>
            <Button
              data-testid="signInAction"
              variant="outlined"
              color="primary"
              size="small"
              className={classes.signInAction}
              onClick={onSignInClick}>
              {startCase(PublicScreenLabels.SignIn)}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SignUp;
