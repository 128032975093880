import React from "react";
import { Grid, Button, CircularProgress, Tooltip, useTheme } from "@material-ui/core";

import { TenantIcon } from "src/assets/icons/NewUX";

import { OverflowTooltip } from "src/components";
import { CellContext, ColumnDef } from "@tanstack/react-table";
import { TData } from "src/components/custom/TableV8/typing";

export const getTenantColumns = ({
  currentTenantId,
  joinNewTenant,
  switchingTenantId,
  switchTenant,
  showLeaveAndSwitchTenantModal,
  canLeaveAndSwitchTenant
}: any): ColumnDef<TData, any>[] => {
  const theme = useTheme();

  return [
    {
      id: "Workspace Name",
      accessorKey: "name",
      header: "Workspace Name",
      cell: (cell: CellContext<any, unknown>) => {
        return (
          <Grid container direction="row" style={{ gap: "12px" }}>
            <TenantIcon opacity={0.3} />
            <OverflowTooltip
              style={{ width: "85%", whiteSpace: "nowrap", color: theme.palette.text.primary }}
              value={cell?.getValue()}
            />
          </Grid>
        );
      },
      size: 250
    },
    {
      id: "Projects",
      accessorKey: "numberOfProjects",
      header: "Projects",

      meta: { type: "number" },
      size: 110
    },
    {
      id: "Users",
      accessorKey: "numberOfUsers",

      header: "Users",
      meta: { type: "string" },

      size: 110
    },
    {
      id: "Role",
      accessorKey: "userRole",

      header: "Role",
      size: 110,
      cell: (cell: CellContext<any, unknown>) => cell?.row?.original?.userRole || "Unknown"
    },
    {
      id: "Actions",
      accessorKey: "invitationStatus",
      enableSorting: false,
      header: "Actions",
      size: 110,
      cell: (cell: CellContext<any, unknown>) => {
        const tenantId = cell.row.original.id;
        const value = cell.getValue();
        const isCurrentTenant = tenantId === currentTenantId;
        const isInvitePending = value === "PENDING";
        const hasValidLicense = cell.row.original.hasValidLicense;
        const disabled =
          !hasValidLicense ||
          (isCurrentTenant && !canLeaveAndSwitchTenant) ||
          (!!switchingTenantId && switchingTenantId === tenantId);

        const btnText = isCurrentTenant ? (
          "Leave"
        ) : isInvitePending ? (
          "Accept"
        ) : !!switchingTenantId && switchingTenantId === tenantId ? (
          <CircularProgress size={24} />
        ) : (
          "Switch"
        );
        const buttonProps: any = isCurrentTenant
          ? {
              color: "secondary",
              variant: "outlined",
              style: { color: "#DA1D27", border: "1px solid #DA1D27" },
              disabled,
              onClick: () => showLeaveAndSwitchTenantModal(tenantId)
            }
          : isInvitePending
            ? {
                color: "primary",
                variant: "contained",
                disabled,
                onClick: () => joinNewTenant(tenantId)
              }
            : {
                color: "primary",
                variant: "outlined",
                disabled,
                onClick: () => switchTenant(tenantId, "/")
              };

        return (
          <Tooltip
            title={!hasValidLicense ? "License key is invalid for the destination workspace" : ""}>
            <div>
              <Button
                size="small"
                {...buttonProps}
                style={{ ...(buttonProps.style ?? {}), fontSize: 12, lineHeight: "17px" }}>
                {btnText}
              </Button>
            </div>
          </Tooltip>
        );
      }
    }
  ];
};
