import { Grid, makeStyles } from "@material-ui/core";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import React, { PropsWithChildren } from "react";

interface IProps {
  size?: Breakpoint;
  justifyContent?: "center" | "left" | "right";
}

const useStyles = makeStyles((theme) => ({
  space: (props: IProps) => {
    const propValueMapping = {
      xs: {
        columnGap: theme.spacing(0.5),
        rowGap: theme.spacing(0.5)
      },
      sm: {
        columnGap: theme.spacing(1),
        rowGap: theme.spacing(1)
      },
      md: {
        columnGap: theme.spacing(1.5),
        rowGap: theme.spacing(1.5)
      },
      lg: {
        columnGap: theme.spacing(2),
        rowGap: theme.spacing(2)
      },
      xl: {
        columnGap: theme.spacing(3),
        rowGap: theme.spacing(3)
      }
    };

    return {
      columnGap: propValueMapping[props.size ?? "sm"].columnGap,
      rowGap: propValueMapping[props.size ?? "sm"].rowGap,
      justifyContent:
        props.justifyContent === "left"
          ? "flex-start"
          : props.justifyContent === "center"
            ? "center"
            : "flex-end"
    };
  }
}));

const Space: React.FC<PropsWithChildren<IProps>> = ({
  size = "sm",
  justifyContent = "left",
  children
}) => {
  const classes = useStyles({ size, justifyContent });
  return (
    <Grid container alignItems="center" className={classes.space}>
      {children}
    </Grid>
  );
};

export default Space;
