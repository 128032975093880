import { FormControl, FormLabel, Grid, makeStyles } from "@material-ui/core";
import { UpdateDataAppRequestDtoPythonVersionEnum } from "@rapidcanvas/rc-api-core";
import React, { ChangeEvent } from "react";
import { Select } from "src/components";

interface IProps {
  value: string;
  onChange: (e: ChangeEvent<{ name?: string | undefined; value: unknown }>) => void;
}

const useStyles = makeStyles({
  root: {
    padding: "10.5px 14px 10.5px 14px"
  }
});

const PythonVersion: React.FC<IProps> = ({ value, onChange }) => {
  const classes = useStyles();
  return (
    <Grid>
      <FormControl component="fieldset" style={{ marginTop: "10px", width: "100%" }}>
        <FormLabel component="legend">Python Version</FormLabel>
        <Select
          size="small"
          style={{
            background: "white",
            border: "1px solid rgba(0, 0, 0, 0.38) ",
            borderRadius: "4px",
            width: "200px"
          }}
          classes={{ root: classes.root }}
          hideInputLabel
          inputProps={{ name: "pythonVersion" }}
          label="Python Version"
          name="pythonVersion"
          placeholder="Select Python Version"
          values={[
            {
              label: "3.8",
              value: UpdateDataAppRequestDtoPythonVersionEnum._38
            },
            {
              label: "3.10",
              value: UpdateDataAppRequestDtoPythonVersionEnum._310
            }
          ]}
          value={value}
          onChange={onChange}></Select>
      </FormControl>
    </Grid>
  );
};

export default PythonVersion;
