// Packages
import { useNavigate, useParams } from "react-router-dom";
import { UseFormGetValues } from "react-hook-form";

// Utils
import { ToastTypes, toastWrapper } from "services/ToastClient/toastWrapper";
import { areAllKeysPresentAndNotNil } from "src/utils/helpers";
import { payloadConditions } from "../utils/Segment.helpers";

// Open API
import { CreateSegmentDto } from "@rapidcanvas/rc-api-core";

// Hooks
import { useSaveSegment } from "src/hooks/api/segments";

// Types
import { DatasetFeaturesDataTypeMapping, Segment } from "../Segment.type";

// Constants
import { SegmentFormFields, SegmentHelperText } from "../utils/Segment.constants";

type Props = {
  getValues: UseFormGetValues<Segment>;
  datasetFeaturesDataTypeMapping?: DatasetFeaturesDataTypeMapping;
};

const useSaveSegmentHook = (props: Props) => {
  const { getValues, datasetFeaturesDataTypeMapping } = props || {};

  const navigate = useNavigate();
  const { entityId: datasetId } = useParams() || {};

  // Query hooks - STARTS >>
  // Mutations
  const {
    isLoading,
    mutateAsync: saveSegmentMutation,
    reset: resetSaveSegmentMutation
  } = useSaveSegment();
  // << ENDS - Query hooks

  const saveSegment = async () => {
    const values = getValues();

    if (
      !datasetId ||
      !areAllKeysPresentAndNotNil(values, [SegmentFormFields.Name, SegmentFormFields.Groups])
    ) {
      return;
    }

    await resetSaveSegmentMutation();

    const payload: CreateSegmentDto = {
      entityId: datasetId,
      name: values?.[SegmentFormFields.Name],
      description: values?.[SegmentFormFields.Description],
      rowLimit: values?.[SegmentFormFields.RowLimit],
      condition: { expression: payloadConditions(values, datasetFeaturesDataTypeMapping) }
    };

    await saveSegmentMutation(payload, {
      onSuccess: () => {
        toastWrapper({ type: ToastTypes.Success, content: SegmentHelperText.SegmentCreated });
        navigate(-1);
      }
    });
  };

  return { isSegmentSaving: isLoading, saveSegment };
};

export default useSaveSegmentHook;
