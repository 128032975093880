import React from "react";
import { Chip, makeStyles, Typography } from "@material-ui/core";

import { RECENT_SEARCH_KEY } from "src/layout/NavBars/utils/GlobalSearch.constants";

const useStyles = makeStyles(() => ({
  typography: {
    fontSize: "12px",
    marginBottom: "16px",
    marginTop: "24px",
    color: "#B2B2B2"
  },
  recentSearchesContainer: {
    marginTop: "10px",
    display: "flex",
    flexWrap: "wrap",
    gap: "8px"
  }
}));

interface IProps {
  onSearchTextChange: (val: string) => void;
}

export const getRecentSearches = (): string[] => {
  return JSON.parse(sessionStorage.getItem(RECENT_SEARCH_KEY) || "[]");
};

const RecentSearches: React.FC<IProps> = (props) => {
  const { onSearchTextChange } = props;

  const { typography, recentSearchesContainer } = useStyles();
  const recentSearches = getRecentSearches();

  return (
    <>
      {recentSearches?.length > 0 && (
        <div>
          <Typography className={typography} variant="body2">
            Recent Searches
          </Typography>
          <div className={recentSearchesContainer}>
            {recentSearches.map((search, index) => (
              <Chip
                key={index}
                size="small"
                label={search}
                clickable
                variant="outlined"
                color="primary"
                onClick={() => onSearchTextChange(search)}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default RecentSearches;
