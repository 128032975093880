import React, { useMemo } from "react";

import { filter, includes, join, map } from "lodash";

import {
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
  makeStyles
} from "@material-ui/core";

import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

import {
  DataConnectorNames,
  dataSourcesTypes
} from "src/pages/DataSources/utils/DataSources.constants";
import {
  validFileTypes,
  maximumNoOfFilesAllowed,
  maximumTotalFilesSizeAllowedInGb,
  DatasetHelperText,
  validFileTypesForFileTypeDataset
} from "../../utils/Dataset.constants";

import { formatStringsWithAnd } from "utils/helpers";
import { InfoPropsType } from "../../types/Props.type";
import useStoreSelectors from "../../hooks/useStoreSelectors";
import useAddFileQueryParam from "../../hooks/useAddFileQueryParam";
import useHelpers from "../../hooks/useHelpers";

const useStyles = makeStyles((theme) => ({
  dataSourcesFilesUploadInfoContainer: {
    fontStyle: "italic",
    "& .infoIcon": {
      marginTop: theme.spacing(0.25)
    },
    "& .infoTextContainer": {
      flex: 1,
      marginLeft: theme.spacing(1)
    },
    "& .infoText": {
      marginBottom: theme.spacing(1)
    }
  },
  list: {
    "& [class^='MuiListItem-root']": {
      alignItems: "baseline",
      paddingLeft: 0,
      "& [class^='MuiListItemText-root']": {
        "& [class^='MuiTypography-subtitle2']": {
          opacity: 0.6
        }
      },
      "& [class^='MuiListItemIcon-root']": {
        minWidth: "auto",
        marginRight: theme.spacing(1),
        "& [class^='MuiSvgIcon-root']": {
          fontSize: "x-small"
        }
      }
    }
  }
}));

const Info = (props: InfoPropsType) => {
  const { isDataSourcesFilesUpload } = props || {};

  const classes: $TSFixMe = useStyles();

  const isAddFile = useAddFileQueryParam();

  const {
    datasetCriterionStore,

    datasetFilesStore
  } = useStoreSelectors();

  const { isCriterionAppend } = useHelpers();

  const isDataSourcesFilesUploadInfo = useMemo(
    () => isDataSourcesFilesUpload && !!datasetCriterionStore?.value,
    [isDataSourcesFilesUpload, datasetCriterionStore, datasetFilesStore]
  );

  const acceptedFileTypes = useMemo(() => {
    const fileTypes = !!isAddFile ? validFileTypesForFileTypeDataset : validFileTypes;
    return join(
      map(fileTypes, (type) => `.${type}`),
      ", "
    );
  }, [isAddFile]);

  const supportedTextInputConnectorTypes = useMemo(() => {
    if (!isAddFile || !isDataSourcesFilesUpload) return "";

    const connectorTypes = [
      DataConnectorNames.GCP_STORAGE,
      DataConnectorNames.AZURE_BLOB,
      DataConnectorNames.S3_STORAGE
    ];

    const connectorTypeNames = map(
      filter(dataSourcesTypes, (dataSourcesType) =>
        includes(connectorTypes, dataSourcesType?.name)
      ),
      "displayName"
    );

    return formatStringsWithAnd(connectorTypeNames);
  }, [isAddFile, isDataSourcesFilesUpload]);

  if (!!isCriterionAppend && !isDataSourcesFilesUploadInfo) {
    return (
      <List dense className={classes.list}>
        <ListItem>
          <ListItemIcon>
            <FiberManualRecordIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            <Typography
              id="filesUploadInfoMaximumFileSizeAllowed"
              variant="subtitle2"
              color="textPrimary">
              {DatasetHelperText.MaximumFileSizeAllowed}:
            </Typography>
            <Typography
              variant="body2"
              color="textPrimary">{` ${maximumTotalFilesSizeAllowedInGb} GB`}</Typography>
          </ListItemText>
        </ListItem>
      </List>
    );
  }

  if (!isAddFile && !!isDataSourcesFilesUploadInfo) {
    return (
      <Grid container className={classes.dataSourcesFilesUploadInfoContainer}>
        <InfoOutlinedIcon className="infoIcon" fontSize="small" />
        <Grid item className="infoTextContainer">
          <Typography
            id="dataSourcesFilesUploadInfoLine1"
            variant="caption"
            component="div"
            color="textSecondary"
            className="infoText">
            {DatasetHelperText.UploadedDataColumnInfoLine1}
          </Typography>
          <Typography
            id="dataSourcesFilesUploadInfoLine2"
            variant="caption"
            component="div"
            color="textSecondary">
            {DatasetHelperText.UploadedDataColumnInfoLine2}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <List dense className={classes.list}>
      {!!supportedTextInputConnectorTypes && (
        <ListItem>
          <ListItemIcon>
            <FiberManualRecordIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            <Typography
              id="filesUploadTextInputSupportedConnectorTypesLabel"
              variant="subtitle2"
              color="textPrimary">
              {DatasetHelperText.TextInputConnectorTypesSupported}:
            </Typography>
            <Typography
              id="filesUploadTextInputSupportedConnectorTypesInfo"
              variant="body2"
              color="textPrimary">
              {supportedTextInputConnectorTypes}
            </Typography>
          </ListItemText>
        </ListItem>
      )}

      {(!isDataSourcesFilesUploadInfo || isAddFile) && (
        <ListItem>
          <ListItemIcon>
            <FiberManualRecordIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            <Typography
              id="filesUploadInfoFileTypesSupported"
              variant="subtitle2"
              color="textPrimary">
              {DatasetHelperText.FileTypesSupported}:
            </Typography>
            <Typography id="filesUploadInfoValidFileTypes" variant="body2" color="textPrimary">
              {acceptedFileTypes}
            </Typography>
          </ListItemText>
        </ListItem>
      )}

      {!isDataSourcesFilesUploadInfo && (
        <>
          <ListItem>
            <ListItemIcon>
              <FiberManualRecordIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              <Typography
                id="filesUploadInfoMaximumNoOfFilesAllowed"
                variant="subtitle2"
                color="textPrimary">
                {DatasetHelperText.MaximumNoOfFilesAllowed}:
              </Typography>
              <Typography
                variant="body2"
                color="textPrimary">{` ${maximumNoOfFilesAllowed} Nos.`}</Typography>
            </ListItemText>
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <FiberManualRecordIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              <Typography
                id="filesUploadInfoMaximumFileSizeAllowed"
                variant="subtitle2"
                color="textPrimary">
                {DatasetHelperText.MaximumFileSizeAllowed}:
              </Typography>
              <Typography
                variant="body2"
                color="textPrimary">{` ${maximumTotalFilesSizeAllowedInGb} GB`}</Typography>
            </ListItemText>
          </ListItem>
        </>
      )}

      {!isAddFile && !isDataSourcesFilesUploadInfo && (
        <ListItem>
          <ListItemIcon>
            <FiberManualRecordIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            <Typography id="filesUploadInfoExcelMultiSheetInfo" variant="body2" color="textPrimary">
              {DatasetHelperText.ExcelMultiSheetInfo}
            </Typography>
          </ListItemText>
        </ListItem>
      )}

      {!isAddFile && (
        <>
          <ListItem>
            <ListItemIcon>
              <FiberManualRecordIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              <Typography id="dataSourcesFilesUploadInfoLine1" variant="body2" color="textPrimary">
                {DatasetHelperText.UploadedDataColumnInfoLine1}
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <FiberManualRecordIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              <Typography id="dataSourcesFilesUploadInfoLine2" variant="body2" color="textPrimary">
                {DatasetHelperText.UploadedDataColumnInfoLine2}
              </Typography>
            </ListItemText>
          </ListItem>
        </>
      )}
    </List>
  );
};

export default Info;
