import React, { useMemo, useState } from "react";

// Packages
import { useNavigate, useParams } from "react-router-dom";
import { useFormContext } from "react-hook-form";

// MUI
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";

// Icons
import { TrashIcon } from "icons/NewUX/TrashIcon";

// Components
import DeleteSegment from "../components/DeleteSegment";
import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import SubTopNavBarBreadcrumbs from "../components/SubTopNavBarBreadcrumbs";
import ConfirmLeavePage from "./ConfirmLeavePage";
import TestModal from "./TestModal";
import UpdateModal from "./UpdateModal";

// Contexts
import { useSegmentContext } from "../context/useSegmentContext";

// Constants
import { SegmentHelperText } from "../utils/Segment.constants";

const Header = () => {
  const navigate = useNavigate();
  const { segmentId } = useParams() || {};

  const { isDefaultScenario, isJobPath, saveSegment, isSegmentSaving } = useSegmentContext();

  const {
    trigger,
    formState: { isDirty, isValid }
  } = useFormContext();

  // States - STARTS >>
  const [showLeavePageConfirmModal, setShowLeavePageConfirmModal] = useState<boolean | string>(
    false
  );
  const [isTestModal, setIsTestModal] = useState(false);
  const [showUpdateConfirmModal, setShowUpdateConfirmModal] = useState(false);
  const [showDeleteConfirmScreen, setShowDeleteConfirmScreen] = useState(false);
  const [isSegmentNameDirty, setIsSegmentNameDirty] = useState(false);
  // << ENDS - States

  // Confirm leave page - STARTS >>
  const promptConfirmLeavePage = () => {
    setShowLeavePageConfirmModal(() => true);
  };

  const resetLeavePage = () => {
    setShowLeavePageConfirmModal(() => false);
  };

  const onLeavePage = () => {
    navigate(-1);
    resetLeavePage();
  };

  // << ENDS - Confirm leave page

  // Test segment - STARTS >>
  const onTest = () => {
    trigger();

    if (!isValid) {
      return;
    }

    setIsTestModal(() => true);
  };

  const onTestClose = () => {
    setIsTestModal(() => false);
  };
  // << ENDS - Test segment

  const onSave = () => {
    trigger();

    if (!isValid) {
      return;
    }

    saveSegment();
  };

  // Update segment - STARTS >>
  const promptConfirmUpdateSegment = () => {
    setShowUpdateConfirmModal(() => true);
  };

  const resetUpdateSegment = () => {
    setShowUpdateConfirmModal(() => false);
  };

  const onUpdateAttempted = async () => {
    trigger();

    if (!isValid) {
      return;
    }

    promptConfirmUpdateSegment();
  };
  // << ENDS - Update segment

  // Delete segment - STARTS >>
  const promptConfirmDeleteSegment = () => {
    setShowDeleteConfirmScreen(() => true);
  };

  const resetDeleteSegment = () => {
    setShowDeleteConfirmScreen(() => false);
  };
  // << ENDS - Delete segment

  const disabledCancelActionMessage = useMemo(() => {
    if (!!isSegmentSaving) {
      return `Please wait. ${!!segmentId ? "Updating" : "Saving"} the segment.`;
    }

    return "";
  }, [segmentId, isSegmentSaving]);

  const disabledTestActionMessage = useMemo(() => {
    if (!isValid) {
      return "This action needs valid fields. Please input all the fields to enable this action.";
    }

    if (!!isSegmentSaving) {
      return `Please wait. ${!!segmentId ? "Updating" : "Saving"} the segment.`;
    }

    return "";
  }, [segmentId, isValid, isSegmentSaving]);

  const disabledSaveActionMessage = useMemo(() => {
    if (!isValid) {
      return "Enter all the required fields to enable this action.";
    }

    if (!!isSegmentSaving) {
      return "Please wait. Saving the segment.";
    }

    return "";
  }, [isValid, isSegmentSaving]);

  const disabledUpdateActionMessage = useMemo(() => {
    if (!isDirty) {
      return "Apply changes to enable this action.";
    }

    if (!isValid) {
      return "Enter all the required fields to enable this action.";
    }

    if (!!isSegmentSaving) {
      return "Please wait. Updating the segment.";
    }

    return "";
  }, [isDirty, isValid, isSegmentSaving]);

  const disabledDeleteActionMessage = useMemo(() => {
    if (!!isSegmentSaving) {
      return "Please wait. Updating the segment.";
    }

    return "";
  }, [isSegmentSaving]);

  return (
    <>
      {showLeavePageConfirmModal && (
        <ConfirmLeavePage onLeavePage={onLeavePage} resetLeavePage={resetLeavePage} />
      )}

      {isTestModal && <TestModal onClose={onTestClose} />}

      {showUpdateConfirmModal && <UpdateModal onClose={resetUpdateSegment} />}

      {showDeleteConfirmScreen && !!segmentId && (
        <DeleteSegment
          segmentId={segmentId}
          resetDeleteSegment={resetDeleteSegment}
          onDelete={() => navigate(-1)}
        />
      )}

      <SubTopNavBarWrapper
        subTopNavBarLeftSection={{
          // @HACK
          // Passing empty method as there is some limitation at SubTopNavBarWrapper component.
          backNavAction: () => {},
          component: (
            <SubTopNavBarBreadcrumbs
              isSegmentNameDirty={isSegmentNameDirty}
              setIsSegmentNameDirty={setIsSegmentNameDirty}
            />
          )
        }}
        subTopNavBarRightSection={{
          ...(!isJobPath && !!isDefaultScenario
            ? {
                ...(!!segmentId
                  ? {
                      moreOptions: [
                        {
                          label: SegmentHelperText.Delete,
                          icon: <TrashIcon viewBox="0 0 20 20" />,
                          disabled: !!disabledDeleteActionMessage,
                          tooltip: disabledDeleteActionMessage,
                          action: () => {
                            !disabledDeleteActionMessage && promptConfirmDeleteSegment();
                          }
                        }
                      ]
                    }
                  : {})
              }
            : {}),
          component: (
            <>
              <Tooltip title={disabledCancelActionMessage}>
                <div>
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    disabled={
                      !!disabledCancelActionMessage || (!!segmentId && !!isSegmentNameDirty)
                    }
                    onClick={() =>
                      !!isDirty || (!segmentId && !!isSegmentNameDirty)
                        ? promptConfirmLeavePage()
                        : navigate(-1)
                    }
                    data-testid="segmentCancelAction">
                    {SegmentHelperText.Cancel}
                  </Button>
                </div>
              </Tooltip>

              {!isJobPath && !!isDefaultScenario && (
                <>
                  <Tooltip title={disabledTestActionMessage}>
                    <div>
                      <Button
                        variant="outlined"
                        size="small"
                        color="primary"
                        disabled={!!disabledTestActionMessage}
                        onClick={onTest}
                        data-testid="segmentTestAction">
                        {SegmentHelperText.Test}
                      </Button>
                    </div>
                  </Tooltip>

                  {!!segmentId ? (
                    <Tooltip title={disabledUpdateActionMessage}>
                      <div>
                        <Button
                          variant="contained"
                          size="small"
                          color="primary"
                          disabled={!!disabledUpdateActionMessage || !isDirty}
                          onClick={onUpdateAttempted}
                          data-testid="segmentUpdateAction">
                          {SegmentHelperText.Update}
                        </Button>
                      </div>
                    </Tooltip>
                  ) : (
                    <Tooltip title={disabledSaveActionMessage}>
                      <div>
                        <Button
                          variant="contained"
                          size="small"
                          color="primary"
                          disabled={!!disabledSaveActionMessage || !isDirty}
                          onClick={onSave}
                          data-testid="segmentSaveAction">
                          {SegmentHelperText.Save}
                        </Button>
                      </div>
                    </Tooltip>
                  )}
                </>
              )}
            </>
          )
        }}
      />
    </>
  );
};

export default Header;
