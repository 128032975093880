import React, { PropsWithChildren } from "react";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core";

interface IProps {
  open: boolean;
}

const useStyles = makeStyles((theme) => ({
  backdrop: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    zIndex: theme.zIndex.drawer + 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },

  blurredContent: {
    height: "calc(100vh - 325px)"
  }
}));

const RefreshThreadBackdrop: React.FC<PropsWithChildren<IProps>> = (props) => {
  const { open, children } = props;
  const classes = useStyles();

  if (!open) {
    return <>{children}</>;
  }

  return (
    <div>
      <Alert
        severity="info"
        style={{ margin: "0px 10px 10px", paddingLeft: "8px", width: "fit-content" }}>
        This is a duplicated chat from the original project. Please use Refresh Chat button to rerun
        all queries and retrieve updated results
      </Alert>
      <div className={classes.blurredContent}>{children}</div>
      <div className={classes.backdrop} />
    </div>
  );
};

export default RefreshThreadBackdrop;
