import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { head } from "lodash";

import api from "services/AxiosClient/AxiosClient";
import { GroupLogDto } from "@rapidcanvas/rc-api-core";

const QUERY_KEY_RECIPE_LOGS = "query-key-recipe-logs";

const useRecipeLogs = (
  projectRunEntryId?: string,
  groupId?: string,
  limit?: number,
  options?: UseQueryOptions<string[]>
): UseQueryResult<string[]> => {
  return useQuery({
    queryKey: [QUERY_KEY_RECIPE_LOGS, projectRunEntryId, groupId, limit],
    queryFn: async () => {
      if (!groupId) {
        throw "groupId is absent";
      }

      const response = await api.fetchResponse<GroupLogDto>(
        async () =>
          await api.DfsRunConfigGroupControllerV2Api.getRunTransformProgress(
            groupId,
            undefined,
            undefined,
            undefined,
            projectRunEntryId,
            limit
          )
      );

      return head(response?.transformLogs)?.logs ?? [];
    },
    ...options,
    refetchOnMount: true,
    enabled: !!groupId && !!projectRunEntryId
  });
};

export default useRecipeLogs;
export { QUERY_KEY_RECIPE_LOGS };
