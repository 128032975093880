import { Button, Checkbox, Grid, Menu, MenuItem, TextField } from "@material-ui/core";
import { filter, includes, size } from "lodash";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import React, { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useStyles } from "./DataappPrompts";

interface IProps {
  id: string;
  item: string;
  selectedPrompts: string[];
  setSelectedPrompts: Dispatch<SetStateAction<string[]>>;
  handleDelete: (id: string) => void;
  handleEdit: (id: string, value: string) => void;
}

const Prompt: React.FC<IProps> = ({
  id,
  item,
  selectedPrompts,
  setSelectedPrompts,
  handleDelete,
  handleEdit
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [value, setValue] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const handleCheck = (__: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (checked) setSelectedPrompts((prev) => [...prev, id]);
    else {
      setSelectedPrompts((prev) => filter(prev, (item) => item !== id));
    }
  };
  const [error, setError] = useState("");
  const [hovered, setHovered] = useState(false);
  const isSelected = useMemo(() => includes(selectedPrompts, id), [selectedPrompts]);

  const handleSave = () => {
    handleEdit(id, value);
    setValue("");
    setIsEditing(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = event.target.value.trim();
    if (size(value) > 125) {
      setError("Max 125 chars supported.");
    }
    if (size(value) < 3) {
      setError("Min 3 chars are required.");
    }
    if (size(value) < 126 && size(value) > 2) {
      setError("");
    }
    setValue(event.target.value);
  };
  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      style={{
        background: isSelected ? "#E7E7FF" : hovered ? "#F2F2FF" : "none",
        padding: "8px 16px",
        border: "1px solid #d1d2d3",
        flexWrap: "nowrap"
      }}>
      <Grid
        container
        alignItems={"center"}
        style={{ width: isEditing ? "100%" : "calc(100% - 50px)" }}>
        <Checkbox
          size="small"
          style={{ marginTop: isEditing ? "-21px" : "0px" }}
          checked={isSelected}
          color="primary"
          onChange={handleCheck}
        />
        <Grid style={{ width: "calc(100% - 40px)" }}>
          {isEditing ? (
            <Grid container alignItems="center" style={{ gap: "5px", flexWrap: "nowrap" }}>
              <Grid style={{ width: "calc(100% - 150px)" }}>
                <TextField
                  multiline
                  fullWidth
                  value={value}
                  onChange={handleChange}
                  placeholder="Type your prompt here"
                  variant="filled"
                  error={!!error}
                  InputProps={{
                    classes: {
                      root: classes.textArea,
                      focused: classes.textArea,
                      multiline: classes.multiLine
                    }
                  }}
                  helperText={
                    !!error ? (
                      error
                    ) : (
                      <span
                        style={{
                          marginLeft: "auto",
                          textAlign: "right",
                          display: "block"
                        }}>{`${value.length}/125 char`}</span>
                    )
                  }
                />
              </Grid>
              <Button
                variant="contained"
                size="small"
                style={{ marginTop: "-21px" }}
                color="primary"
                disabled={!value || !!error}
                onClick={handleSave}>
                Save
              </Button>
              <Button
                variant="outlined"
                size="small"
                style={{ marginTop: "-21px" }}
                color="primary"
                onClick={() => setIsEditing(false)}>
                Cancel
              </Button>
            </Grid>
          ) : (
            <span style={{ wordBreak: "break-all", fontSize: "14px" }}>{item}</span>
          )}
        </Grid>
      </Grid>
      {hovered && !isEditing && (
        <>
          <Button size="small" onClick={(event) => setAnchorEl(event.currentTarget)}>
            <MoreVertIcon />
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left"
            }}>
            <MenuItem
              key={"edit"}
              onClick={() => {
                setValue(item);
                setIsEditing(true);
                setAnchorEl(null);
              }}>
              Edit
            </MenuItem>
            <MenuItem
              key={"delete"}
              onClick={() => {
                handleDelete(id);
                setAnchorEl(null);
              }}>
              Delete
            </MenuItem>
          </Menu>
        </>
      )}
    </Grid>
  );
};

export default Prompt;
