import React, { PropsWithChildren, useEffect } from "react";
import _ from "lodash";

import Analytics from "services/Analytics/Analytics";

interface IProps {
  path: string;
}

const AnalyticsWrapper: React.FC<PropsWithChildren<IProps>> = (props) => {
  useEffect(() => {
    Analytics.trackNavigation(_.get(props, "path"), document.title);
  }, [location]);

  return <>{props.children}</>;
};

export default AnalyticsWrapper;
