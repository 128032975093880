import { UseMutationOptions, UseMutationResult, useMutation } from "@tanstack/react-query";

import { getSqlSampleDataWithRethrow } from "services/Apis/wrappers/projects";

export const useGetConnectorSampleData = ({
  ...options
}: UseMutationOptions<$TSFixMe, Error, $TSFixMe>): UseMutationResult => {
  return useMutation({
    mutationFn: async (payload: $TSFixMe) => await getSqlSampleDataWithRethrow({ data: payload }),
    ...options
  });
};
