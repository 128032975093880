import { useRef } from "react";
import _ from "lodash";
import { QueryClient, UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams, useNavigate, generatePath } from "react-router-dom";

import api from "services/AxiosClient/AxiosClient";
import {
  handleErrorWithRedirectToLogs,
  handleSuccessWithRedirectToLogs
} from "services/Apis/Apis.service";
import { updateDataAppsAcrossTenantsQueryData, updateDataAppsQueryData } from "src/utils/helpers";
import { DATAAPP_INTERVAL_IN_SEC, isInProgress } from "./useDataAppStatusById";
import { DataAppDtoLaunchStatusEnum, EnvDtoLaunchStatusEnum } from "@rapidcanvas/rc-api-core";
import { WebPaths } from "src/routing/routes";
import { useShowLogsModal } from "pages/Projects/common/ShowLogsModal/useShowLogsModal";
import { useTransformErrorUtils } from "pages/Projects/common/hooks/useTransformErrorUtils";

type Props = { id: string; hideLogs?: boolean };

export interface ILaunchResponse {
  logs: string;
  name: string;
  launchStatus: DataAppDtoLaunchStatusEnum;
}

export const invalidateDataAppQueries = (
  queryClient: QueryClient,
  id: string,
  launchStatus: DataAppDtoLaunchStatusEnum,
  dataAppName?: string
) => {
  updateDataAppsAcrossTenantsQueryData({
    queryClient,
    data: { id, displayName: dataAppName, launchStatus }
  });

  updateDataAppsQueryData({
    queryClient,
    data: { id, launchStatus }
  });
};

export const relaunchEnv = async (envId: string) => {
  const environments = await api.fetchResponse(
    async () => await api.EnvControllerApi.findEnvById(envId),
    false
  );

  const first = _.head(environments);
  if (
    first &&
    first.id === envId &&
    !_.includes(
      [EnvDtoLaunchStatusEnum.Launching, EnvDtoLaunchStatusEnum.Success],
      first.launchStatus
    )
  ) {
    await api.fetchResponse(
      async () => await api.EnvControllerApi.updateEnv(envId, first, true, true, false),
      false
    );
  }
};

export const useLaunchDataAppMutation = (): UseMutationResult<
  ILaunchResponse,
  unknown,
  Props,
  unknown
> => {
  const { dataAppName, projectId } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { openLogsSideBar } = useShowLogsModal();
  const { fetchAndRenderFullErrorLogs } = useTransformErrorUtils();

  const fetchingRef = useRef<boolean>(false);

  return useMutation({
    mutationFn: async ({ id }) => {
      const response = await api.fetchResponse(
        async () => await api.DataAppControllerApi.launch(id),
        false
      );
      if (response.askAIConfig?.customEnvId) {
        relaunchEnv(response.askAIConfig?.customEnvId);
      }
      const launchStatus = response?.launchStatus;

      if (launchStatus && isInProgress(launchStatus)) {
        return await new Promise((resolve, reject) => {
          const interval = setInterval(async () => {
            if (!fetchingRef.current) {
              fetchingRef.current = true;

              try {
                const newResponse = await api.fetchResponse(
                  async () => await api.DataAppControllerApi.getDataAppsById(id),
                  false
                );

                fetchingRef.current = false;
                const newStatus = newResponse.launchStatus;
                if (newStatus && !isInProgress(newStatus)) {
                  clearInterval(interval);
                  const logsResponse = await api.fetchResponse(
                    async () => await api.DataAppControllerApi.logs(id, "offline"),
                    false
                  );

                  resolve({
                    launchStatus: newStatus,
                    logs: logsResponse?.logs as string,
                    name: response?.name as string
                  });
                }
              } catch (e: any) {
                clearInterval(interval);
                reject(e);
              }
            }
          }, DATAAPP_INTERVAL_IN_SEC * 1000);
        });
      } else {
        return {
          launchStatus: launchStatus as DataAppDtoLaunchStatusEnum,
          logs: "",
          name: response?.name as string
        };
      }
    },
    onSuccess: (response: ILaunchResponse, { id, hideLogs }) => {
      invalidateDataAppQueries(queryClient, id, response.launchStatus, dataAppName);
      if (!hideLogs) {
        const actions = [
          {
            label: "View Log",
            action: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              event?.preventDefault();
              event?.stopPropagation();
              const logLines = response?.logs ? response?.logs?.split("\n") : [];
              openLogsSideBar(logLines, "success");
            }
          },
          {
            label: "View DataApp",
            action: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              event?.preventDefault();
              event?.stopPropagation();
              const path = projectId
                ? generatePath(WebPaths.ProjectDataApp, { dataAppName: response.name, projectId })
                : generatePath(WebPaths.DataAppDashboard, { dataAppName: response.name });
              navigate(path);
            }
          }
        ];
        if (response.launchStatus === DataAppDtoLaunchStatusEnum.Running) {
          handleSuccessWithRedirectToLogs(`DataApp launched successfully.`, actions);
        } else {
          handleErrorWithRedirectToLogs(`Error in launching DataApp.`, (event) => {
            event?.preventDefault();
            event?.stopPropagation();
            const logLines = response?.logs ? response?.logs?.split("\n") : [];
            openLogsSideBar(logLines, "error");
          });
        }
      }
    },
    onError: (error: any, { id, hideLogs }) => {
      invalidateDataAppQueries(queryClient, id, DataAppDtoLaunchStatusEnum.Failure, dataAppName);
      if (!hideLogs && error?.response?.status !== 404) {
        fetchAndRenderFullErrorLogs(error);
      }
    }
  });
};
