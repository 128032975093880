import React from "react";

// Packages
import { Link } from "react-router-dom";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";

// Utils
import { useGetRole } from "src/hooks/useGetRole";
import { RenderText } from "components/custom";

type Props = {
  project: $TSFixMe;
  openCreateDataApp: boolean;
};

const SubTopNavBarBreadcrumbs = (props: Props) => {
  const { project, openCreateDataApp } = props || {};

  const { isConsumer, isDataAppPowerUser } = useGetRole();
  const breadcrumbs: React.ReactNode[] = [];

  if (!!project?.id) {
    if (isConsumer || isDataAppPowerUser) {
      breadcrumbs.push(<RenderText key="projects">{project?.name || "Project"}</RenderText>);
    } else {
      breadcrumbs.push(
        <Link key="projects" to={`/projects/${project?.id}/canvas`} data-testid="projectBreadcrumb">
          <RenderText>{project?.name || "Project"}</RenderText>
        </Link>
      );
    }

    breadcrumbs.push(
      <RenderText key="dataapps" color="textSecondary" data-testid="dataAppBreadcrumb">
        {openCreateDataApp ? "Create DataApp" : "DataApps"}
      </RenderText>
    );
  }

  return (
    <Breadcrumbs>
      {breadcrumbs?.map((eachBreadcrumb: React.ReactNode) => eachBreadcrumb)}
    </Breadcrumbs>
  );
};

export default SubTopNavBarBreadcrumbs;
