import { useQuery } from "@tanstack/react-query";

import { checkEnvRelaunch } from "environmentsModule/utils/environmentRelaunch.helpers";
import { orderBy } from "lodash";

import { ThreadResponseDto, ThreadResponseDtoFlowTypeEnum } from "@rapidcanvas/rc-api-core";
import api from "services/AxiosClient/AxiosClient";

export const AI_GUIDE_THREADS_KEY = "aiGuideThreads";
export const useGetAIGuideThreads = ({
  projectId,
  enabled,
  shouldCheckEnvRelaunch = false,
  onSuccess,
  shouldSortAndFilter = false
}: {
  projectId: string;
  enabled?: boolean;
  shouldCheckEnvRelaunch?: boolean;
  onSuccess?: (threads: ThreadResponseDto[]) => void;
  shouldSortAndFilter?: boolean;
}) => {
  return useQuery<ThreadResponseDto[]>({
    queryKey: ["projects", projectId, AI_GUIDE_THREADS_KEY],
    queryFn: async () => {
      projectId && shouldCheckEnvRelaunch && checkEnvRelaunch(projectId);
      const response = await api.fetchResponse(() => api.AiControllerApi.getThreads1(projectId));
      const filteredThreads = shouldSortAndFilter
        ? response?.filter((thread) => thread.flowType === ThreadResponseDtoFlowTypeEnum.AiGuide)
        : response;
      const sortedThreads = shouldSortAndFilter
        ? orderBy(filteredThreads, "updated", "desc")
        : filteredThreads;
      return sortedThreads;
    },
    cacheTime: 0,
    enabled: !!projectId && (enabled ?? true),
    staleTime: 0,
    onSuccess,
    refetchOnMount: true
  });
};
