import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import api from "services/AxiosClient/AxiosClient";
import { getTemplateWithRethrow } from "services/Apis/wrappers";
import { Recipe } from "src/types";

interface IReturn {
  recipe: Recipe;
  templates: $TSFixMe;
  configIds: Array<string>;
}

export const QUERY_KEY_RECIPE = "fetch-recipe-details";
export const useGetRecipe = ({
  recipeId,
  scenarioId,
  jobRunId,
  options
}: {
  recipeId?: string;
  scenarioId?: string;
  jobRunId?: string;
  options?: UseQueryOptions<IReturn>;
}) => {
  return useQuery<IReturn>({
    queryKey: [QUERY_KEY_RECIPE, recipeId],
    queryFn: async () => {
      const createdGroup = await api.fetchResponse(
        async () =>
          await api.DfsRunConfigGroupControllerV2Api.findGroups(
            recipeId,
            undefined,
            undefined,
            scenarioId,
            jobRunId
          ),
        false
      );
      const recipe = (Array.isArray(createdGroup) ? createdGroup[0] : createdGroup) as Recipe;
      const templateIds = recipe?.runConfigs?.map((config: $TSFixMe) => config.templateId) || [];
      const templates = await Promise.all(
        templateIds.map((templateId) => getTemplateWithRethrow(templateId))
      );
      const configIds = recipe?.runConfigs?.map((config) => config.id);
      return { recipe, templates, configIds };
    },
    enabled: !!recipeId,
    ...options
  });
};
