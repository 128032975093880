import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

import { postAPI } from "services/Apis/Apis.service";

const QUERY_KEY_JOIN_TENANT = "query-key-join-tenant";

const useJoinTenant = (
  invitationDetails: string,
  tenantId?: string,
  options?: UseQueryOptions<any>
): UseQueryResult<any> => {
  return useQuery({
    queryKey: [QUERY_KEY_JOIN_TENANT, invitationDetails, tenantId],
    queryFn: async () => {
      const response = await postAPI("/invitation/accept", { invitationDetails, tenantId });

      return response;
    },
    ...options
  });
};

export default useJoinTenant;
export { QUERY_KEY_JOIN_TENANT };
